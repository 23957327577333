import * as React from 'react';
import { SVGProps } from 'react';

const WalletDaiSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.16 21.821a5.99 5.99 0 0 0 1.02-2.022v-.243h1.89v-1.624h-1.648v-1.693h1.648v-1.624h-1.89v-.081c-.078-.323-.156-.566-.235-.812a5.503 5.503 0 0 0-1.72-2.335 4.98 4.98 0 0 0-2.197-.97 11.982 11.982 0 0 0-2.672-.166h-5.098v4.451H7.983v1.624h2.275v1.776H7.983v1.624h2.275v4.438h5.334c.816.008 1.63-.073 2.429-.241a6.056 6.056 0 0 0 1.804-.729 7.237 7.237 0 0 0 1.336-1.374l-.001.001Zm-9.097-10.112h3.293a6.994 6.994 0 0 1 2.589.324 3.778 3.778 0 0 1 1.96 1.624c.174.287.331.584.472.889l.078.162h-8.392V11.71Zm0 4.53h8.627v1.698h-8.627v-1.698Zm7.138 5.338c-.396.368-.884.62-1.412.728a8.78 8.78 0 0 1-2.353.243h-3.373v-2.993h8.314l-.08.162a4.294 4.294 0 0 1-1.097 1.86Z"
      fill="#F4B731"
    />
  </svg>
);

export default WalletDaiSVG;
