import { gql, request, useMutation } from '~reactQuery';

interface ChangeUserGuestData {
  documentNumber: string;
  email: string;
  passwd?: string;
  phone: string;
  pin: string;
  registerName?: string;
  socialName?: string;
  token: string;
}

interface ChangeUserGuestDataResponse {
  changeUser: {
    data: {
      user: {
        active: boolean;
        capu: string;
        createdAt: string;
        deletedAt: string;
        documentNumber: string;
        email: string;
        id: number;
        phone: string;
        registerName: string;
        socialName: string;
        updatedAt: string;
      };
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useChangeUserGuestMutation() {
  const mutation = gql`
    mutation ChangeUser($params: ChangeUserInput!) {
      changeUser(params: $params) {
        data {
          user {
            active
            capu
            createdAt
            deletedAt
            documentNumber
            email
            id
            phone
            registerName
            socialName
            updatedAt
          }
        }
        isValid
        messages
      }
    }
  `;

  const fetch = async (params: ChangeUserGuestData) => {
    if (!params?.documentNumber || !params.token) {
      return null;
    }

    const body = {
      registerName: params?.registerName,
      documentNumber: params?.documentNumber,
      email: params?.email,
      passwd: params?.passwd,
      phone: params?.phone,
      pin: params?.pin,
      socialName: params?.socialName,
    };

    const response = await request<ChangeUserGuestDataResponse>(
      mutation,
      {
        params: body,
      },
      { Authorization: `Bearer ${params?.token}` }
    );

    return response?.changeUser;
  };

  return useMutation('ChangeUserGuest', fetch);
}
