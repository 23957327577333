import * as React from 'react';
import { SVGProps } from 'react';

const CapAccountSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_368)">
      <path
        d="M3.16644 21.7005H20.8334"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6961 9.42448H2.30255L12.1681 2.12848L21.6961 9.42448Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1545 18.247V9.41998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4695 18.247V9.41998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83649 18.247V9.41998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_368">
        <rect
          width={props.width || '24'}
          height={props.height || '24'}
          fill={props.fill || 'white'}
        />
      </clipPath>
    </defs>
  </svg>
);

export default CapAccountSVG;
