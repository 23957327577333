import { transparentize } from 'polished';
import styled from 'styled-components';
import colors from '~styles/colors';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .container-loading {
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-container {
    padding: 25px 38px 0px 38px;

    .error-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      padding: 10px;
      border-radius: 10px;
      width: 100%;
      background-color: rgba(255, 99, 120, 0.1);
      margin-top: 8px;
    }

    .container-amount-wallet {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      .icon-amount-wallet {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
      }
    }

    .button-help {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
    }
  }

  .status-container {
    padding: 0 38px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .receipt-button {
      margin-top: 25px;
      display: flex;
      align-items: center;
    }
  }
`;
