import {
  useContext,
  createContext,
  cloneElement,
  forwardRef,
  isValidElement,
  Context,
  Ref,
  ReactElement,
  useMemo,
} from 'react';
import styled from 'styled-components';

export type Rules = {
  rulesMap: { [key: string]: string[] };
  role: string[];
  // validator?: ({ role, rulesMap, name }: Rules & { name: string }) => boolean;
};

const adminFlag = 'ADMIN';

const RoleContext: Context<Rules> = createContext({
  rulesMap: {},
  role: [''],
});

type ProviderProps = {
  children: ReactElement;
} & Rules;

type ConsumerProps = {
  children: ReactElement & { ref?: Ref<HTMLElement> };
  name: string;
};

export const PermissionGateProvider = ({
  children,
  role,
  rulesMap,
}: ProviderProps): ReactElement => {
  const value = useMemo(() => ({ role, rulesMap }), [role, rulesMap]);

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};

const hasPermission = ({
  role,
  rulesMap,
  name,
}: Rules & { name: string }): boolean => {
  const scope = rulesMap[name];

  if (!scope) return true;

  return scope.some((r) => role.includes(r));
};

export function usePermission(
  name: string | string[]
): Rules & { granted: boolean } {
  const {
    role,
    rulesMap,
    validator = hasPermission,
  }: Rules = useContext(RoleContext);

  let granted = false;

  if (typeof name === 'string') {
    granted = validator({ role, rulesMap, name });
  } else {
    granted = name.some((n) => role.includes(n));
  }
  return { granted, role, rulesMap };
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0.3;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  cursor: not-allowed !important;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
  }
`;

const Gate = (
  { children, name, ...other }: ConsumerProps,
  ref: Ref<HTMLElement>
) => {
  const { granted, role } = usePermission(name);

  if (!granted && !role.includes(adminFlag)) {
    return (
      <Wrapper key="gate-container">
        {cloneElement(children, { onClick: (e: any) => e.stopPropagation() })}
      </Wrapper>
    );
  }

  if (!isValidElement(children)) {
    //  console.error('Children prop is not a valid react element');
    return null;
  }

  return cloneElement(children, { ref, ...other });
};

export const PermissionGate = forwardRef(Gate);
