import { FlattenInterpolation, ThemeProps, css } from 'styled-components';

import { TooltipPosition } from './Tooltip.types';

export const positions = [
  'top',
  'top-left',
  'top-right',
  'bottom',
  'bottom-left',
  'bottom-right',
  'left',
  'right',
] as TooltipPosition[];

const containerTop = css`
  bottom: calc(100% + 9px);
  left: 50%;
  transform: translateX(-50%);
`;

const containerTopLeft = css`
  right: calc(100% - 24px);
  bottom: calc(100% + 9px);
`;

const containerTopRight = css`
  bottom: calc(100% + 9px);
  left: calc(100% - 24px);
`;

const containerBottom = css`
  top: calc(100% + 9px);
  left: 50%;
  transform: translateX(-50%);
`;

const containerBottomLeft = css`
  top: calc(100% + 9px);
  right: calc(100% - 24px);
`;

const containerBottomRight = css`
  top: calc(100% + 9px);
  left: calc(100% - 24px);
`;

const containerLeft = css`
  top: 50%;
  right: calc(100% + 9px);
  transform: translateY(-50%);
`;

const containerRight = css`
  top: 50%;
  left: calc(100% + 9px);
  transform: translateY(-50%);
`;

const containerPositions = {
  top: containerTop,
  'top-left': containerTopLeft,
  'top-right': containerTopRight,
  bottom: containerBottom,
  'bottom-left': containerBottomLeft,
  'bottom-right': containerBottomRight,
  left: containerLeft,
  right: containerRight,
};

export const getContainerPositionStyles = (
  position: TooltipPosition = 'top'
): FlattenInterpolation<ThemeProps<unknown>> => containerPositions[position];
