import * as React from 'react';
import { SVGProps } from 'react';

const WalletBlankSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="42"
    height="34"
    viewBox="0 0 42 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_850_1716)">
      <path
        d="M10.157 0.7612H31.8431C34.3638 0.791714 36.7693 1.82426 38.5314 3.63207C40.2935 5.43988 41.268 7.87514 41.241 10.403V23.597C41.268 26.1247 40.2936 28.5598 38.5317 30.3676C36.7699 32.1754 34.3646 33.208 31.8442 33.2388H10.157C7.63632 33.2083 5.2308 32.1758 3.46873 30.3679C1.70665 28.5601 0.73209 26.1249 0.759091 23.597V10.403C0.73209 7.87514 1.70665 5.43988 3.46873 3.63207C5.2308 1.82426 7.63632 0.791714 10.157 0.7612Z"
        fill="#F3F4F9"
        stroke="#E2E4EE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.241 21.7123H33.9897C32.6994 21.7086 31.4619 21.1982 30.5423 20.2906C29.6227 19.3829 29.0942 18.1502 29.07 16.8564C29.0457 15.5627 29.5278 14.3109 30.4127 13.3693C31.2977 12.4277 32.5153 11.8711 33.8045 11.8188C33.8666 11.8188 33.9283 11.8188 33.9897 11.8188H41.239"
        stroke="#E2E4EE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.8114 16.6447H34.2527"
        stroke="#E2E4EE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_850_1716">
        <rect width="42" height="34" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WalletBlankSVG;
