import styled from 'styled-components';
import { GenericButton } from '~components/Buttons';
import colors from '~styles/colors';

export const ContainerError = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .description {
    margin-right: 30px;

    .row-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
  }

  @media (max-width: 740px) {
    flex-direction: column-reverse;

    .description {
      margin-right: 0px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
`;

export const Button = styled(GenericButton)`
  width: 200px;
  background-color: ${colors.transparent};
  border: 1px solid ${colors.black};
  margin-top: 50px;
`;
