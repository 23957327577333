import { SVGProps } from 'react';

const ReverseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '18'}
      height={props.height || '17'}
      style={props.style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.30829 0.292063L4.30835 0.302414L4.32905 2.30231L5.32899 2.29196L10.3777 2.2397L1.50346 11.1139L0.796356 11.821L2.21057 13.2352L2.91768 12.5281L13.5243 1.9215L15.2567 0.189087L12.8068 0.214446L5.30829 0.292063ZM12.9051 15.929L13.905 15.9187L13.8843 13.9188L12.8844 13.9291L7.83571 13.9814L16.7099 5.10719L17.417 4.40008L16.0028 2.98587L15.2957 3.69298L4.68909 14.2996L2.95667 16.032L5.40654 16.0066L12.9051 15.929Z"
        fill={props.fill || '#272937'}
      />
    </svg>
  );
};

export default ReverseIcon;
