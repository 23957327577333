import { colors } from '~styles';
import styled, { css } from 'styled-components';

interface ItemListContainerProps {
  listType: string;
  disableHover: boolean;
}

export const ItemListContainer = styled.div<ItemListContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${colors.whiteLight};
  border-top: 1px solid rgba(195, 198, 219, 0.2);

  height: 54px;
  align-items: center;
  justify-content: space-between;
  align-items: center;

  ${({ listType }) =>
    listType === 'sub-item'
      ? css`
          padding: 15px 40px;
        `
      : css`
          padding: 15px 0px;
        `}

  ${({ disableHover }) =>
    disableHover
      ? css`
          .dot-hover {
            display: flex;
            cursor: pointer;
          }
        `
      : css`
          :hover {
            cursor: pointer;
          }
        `}

  .icon-container {
    margin-right: 15px;
    display: flex;
  }

  .display-end {
    display: flex;
  }
`;
