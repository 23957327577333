import * as Text from '~styles/text';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import SupportSVG from '~assets/svg/support';
import { handleOnClickHelp } from '~helpers/generic';
import LoadingSpinner from '~components/LoadingSpinner';
import colors from '~styles/colors';
import AuthValidate from '~components/AuthValidate';
import { ErrorToast } from '~components/Toast';
import { parseError } from '~reactQuery/index';
import { useState } from 'react';
import { useUserAuthTypes } from '~hooks/useUserAuthTypes';
import { useFormContext } from 'react-hook-form';
import { getSelectedUserOrganization } from '~helpers/device';
import { usePixTransferUsingRefund } from '~reactQuery/mutations/pix/usePixTransferUsingRefund';
import { RefundProps } from '../Refund.types';

const InsertPin = ({
  setCurrentRefundStep,
  transactionDetails,
}: RefundProps) => {
  const [hasError, setHasError] = useState({
    pin: '',
    twoFa: '',
  });
  const [loading, setLoading] = useState(false);
  const { auth } = useUserAuthTypes();
  const userOrganizationId = getSelectedUserOrganization();
  const { mutateAsync: makePixRefund, isLoading: loadingPixRefund } =
    usePixTransferUsingRefund();
  const { watch, getValues, setValue } = useFormContext();
  const formValues = getValues();
  const name =
    transactionDetails?.data?.requisite?.holderName ||
    transactionDetails?.data?.requisite?.key?.holderName;

  const makeTransferRefund = async (pin: string, twoFa?: string) => {
    const valueParsed =
      Number(
        formValues?.amount
          ?.replace('R$ ', '')
          ?.replace(/\./g, '')
          ?.replace(',', '.')
      ) * 100;

    setHasError({
      pin: '',
      twoFa: '',
    });

    if (!String(valueParsed)) return;

    if ((auth.includes('2-FA') && twoFa?.length !== 6) || pin.length !== 4) {
      return;
    }
    setLoading(true);

    try {
      let resp;

      const twoFA = twoFa ? { token: twoFa } : {};

      // eslint-disable-next-line prefer-const
      resp = await makePixRefund({
        authCode: transactionDetails?.authCode,
        description: transactionDetails?.data?.description || '',
        method: transactionDetails?.data?.method,
        // recovery: false,
        pin,
        refundReason: formValues?.refundReason || '',
        requisite: {
          keyType: 'refund',
        },
        ...twoFA,
        userOrganizationId: Number(userOrganizationId),
        value: Number(valueParsed.toFixed(2)),
      });

      if (resp) {
        setValue('authCode', resp?.authCode);
        setLoading(false);
        setCurrentRefundStep('status');
      }
    } catch (error: any) {
      setLoading(false);
      const errorKey = parseError(error);

      if (errorKey === 'NOT_FOUND_PIX_KEY') {
        ErrorToast({
          title: 'Chave PIX não encontrada',
          subtitle: '',
          autoCloseEnable: true,
        });
      }

      if (errorKey === 'SCOPE_NOT_REGISTERED') {
        ErrorToast({
          title: 'Escopo não encontrado',
          subtitle: '',
          autoCloseEnable: true,
        });
      }

      if (errorKey === 'INVALID_PIN') {
        setHasError((prev) => ({ ...prev, pin: 'Pin inválido' }));
      }

      if (errorKey === 'INVALID_2FA_TOKEN') {
        setHasError((prev) => ({ ...prev, twoFa: '2-FA inválido' }));
      }
    }
  };

  const isLoading = loadingPixRefund || loading;

  return (
    <>
      <ModalMultiStepHeader
        title={`Reembolsando ${watch('amount')} para ${name}`}
        description="Preencha as confirmações de segurança:"
      />

      <div className="content-container">
        {!isLoading ? (
          <AuthValidate
            authTypes={auth}
            onChangeCodes={(pin, twoFa) => makeTransferRefund(pin, twoFa)}
            pinErrorMessage={hasError.pin}
            twoFaErrorMessage={hasError.twoFa}
          />
        ) : (
          <div className="container-loading">
            <LoadingSpinner color={colors.primary} size={60} strokeSize={25} />
          </div>
        )}

        <ModalMultiStepBottom
          doubleButtonTextOne={
            <div className="button-help">
              <SupportSVG />
              <Text.Heading5>Preciso de Ajuda</Text.Heading5>
            </div>
          }
          doubleButtonOneAction={handleOnClickHelp}
        />
      </div>
    </>
  );
};

export default InsertPin;
