import '~styles/fonts.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import { QueryClientProvider } from 'react-query';
import GlobalStyles from '~styles/global';
import React, { useEffect } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import Routes from './routes/index';
import { ToastContainer } from 'react-toastify';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import Maintenance from '~pages/Maintenance/Maintenance.screen';
import { APP_ROUTES } from '~data/navigation';
import { Else, If, Then, When } from 'react-if';
import { ErrorBoundary } from 'react-error-boundary';
import AppErrorFallBack from '~pages/AppErrorFallBack/AppErrorFallBack.screen';
// import { captureException } from '@sentry/react';

import { queryClient } from './query';

const App: React.FC = () => {
  const { pathname } = window.location;
  const isExtractPage = pathname === APP_ROUTES.EXTRACT;

  const sendErrorsToSentry = (error: Error, info: React.ErrorInfo) => {
    // captureException(
    //   `Error message: ${error} - Error stack: ${info.componentStack}`
    // );
  };

  const isMaintenance = import.meta.env.VITE_SYSTEM_STATUS === 'maintenance';

  return (
    <ErrorBoundary fallback={<AppErrorFallBack />} onError={sendErrorsToSentry}>
      <QueryClientProvider client={queryClient}>
        <GlobalStyles isExtractPage={isExtractPage} />

        <If condition={isMaintenance}>
          <Then>
            <Maintenance />
          </Then>
          <Else>
            <Routes />
          </Else>
        </If>

        <ToastContainer
          className="custom-toast"
          position="bottom-right"
          newestOnTop
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
        />

        <When condition={!isMaintenance}>
          <ReactQueryDevtools initialIsOpen={false} />
        </When>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
