import * as Style from './BillModal.styles';
import * as Text from '~styles/text';
import { GenericRow, RoundButtonIcon } from '~components';
import { formatCurrencyFn } from '~helpers/format/currency';
import BillBarCodeIcon from '~assets/svg/billBarCode';
import colors from '~styles/colors';
import ListIcon from '~assets/svg/list';
import { useState } from 'react';
import BillDetailsModal from './BillDetailsModal.component';
import dayjs from 'dayjs';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';

const BillModal = () => {
  const {
    params: { slipByAuthCode, filteredLabelStatus },
  } = useOpenModalSideBar();

  const [openDetails, setOpenDetails] = useState(false);

  return (
    <Style.Container>
      <div className="header">
        <div className="title">
          <Text.Heading2>Boleto de depósito</Text.Heading2>
          <Text.Paragraph color={colors.grayMedium} marginTop={3}>
            {dayjs(slipByAuthCode?.emissionDate).format('DD/MM/YYYY')} •{' '}
            {dayjs(slipByAuthCode?.emissionDate).format('H:mm')}
          </Text.Paragraph>
        </div>
      </div>

      <div className="wrapper">
        <div className="details">
          <RoundButtonIcon
            buttonType="default"
            buttonSize="icon24v2"
            noHover
            style={{ cursor: 'default' }}
          >
            <BillBarCodeIcon />
          </RoundButtonIcon>
          <Text.Heading4 marginBottom={6} marginTop={20}>
            {slipByAuthCode?.payer.tradeName}
          </Text.Heading4>
          <Text.Heading1Medium marginBottom={24}>
            {formatCurrencyFn(
              'BRL',
              String(Number(slipByAuthCode?.amount.value))
            )}
          </Text.Heading1Medium>
          <Style.Status>
            <span className={`circle ${filteredLabelStatus?.[0]?.itemColor}`} />
            {filteredLabelStatus?.[0]?.label}
          </Style.Status>
        </div>

        <div className="buttons-wrapper">
          <GenericRow
            leftContentIconType="icon"
            leftComponent={<ListIcon />}
            rightContentType="arrow"
            title="Detalhes da movimentação"
            padding="18px 0px"
            onClick={() => setOpenDetails(true)}
          />
        </div>
      </div>
      <BillDetailsModal
        openDetails={openDetails}
        setOpenDetails={setOpenDetails}
      />
    </Style.Container>
  );
};

export default BillModal;
