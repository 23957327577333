import {
  Business,
  BusinessData,
  BusinessSize,
  BusinessType,
  Fields,
  PartnerData,
  Status,
} from '~reactQuery/types/business.types';
import { gql, request, useQuery } from '~reactQuery';

export interface GetBusinessInput {
  businessId?: number;
}

export interface GetOrganizationInput {
  userOrganizationId?: number;
}

export interface GetBusinessDataResponse {
  getBusiness: {
    id: number;
    declaredAnnualBilling: string;
    participationPercentage: string;
    documentNumber: string;
    businessName: string;
    tradingName: string;
    businessType: BusinessType;
    businessSize: BusinessSize;
    businessCnae: string;
    countryCode: string;
    phone: string;
    site: string;
    pep: string;
    businessEmail: string;
    zipCode: string;
    addressLine: string;
    buildingNumber: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
    numberOfPartners: number;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
    deletedAt: string;
    status: Status;
    companyStartDate: string;
    businessData: BusinessData;
    partnerData: [PartnerData];
    fields: [Fields];
    businessPartnerId: number;
    businessesPartners: [Business];
    isCryptoTrader: boolean;
  };
}

export const useOrgInfo = (params: GetOrganizationInput) => {
  const QUERY = gql`
    query GetOrganization($params: GetOrganizationInput) {
      getOrganization(params: $params) {
        data {
          id
          flags
          admin
          active
          createdAt
          updatedAt
          organizationsId
          organizations {
            id
            name
            country
            businessCode
            setup
            bankCompe
            branch
            account
            image
            deletedAt
            createdAt
            updatedAt
            walletsInternalCredentialsId
            banklyPartnersId
            walletsInternalCredentials {
              id
              internalPin
              sessionKey
              userBusinessId
            }
            partner {
              id
              companyKey
              grantType
            }
          }
          settings {
            id
            enablePin
            twoFA
            language
            notificationFlags
            createdAt
            updatedAt
          }
        }
        messages
        isValid
      }
    }
  `;

  const fetcher = async () => {
    if (!params.userOrganizationId) return null;
    const response = await request(QUERY, { params });
    return response.getOrganization.data;
  };

  return useQuery(['useOrgInfo'], fetcher, {
    cacheTime: 0,
    staleTime: 0,
  });
};

export const useGetBusiness = (params: GetBusinessInput) => {
  const QUERY = gql`
    fragment partnerDataFields on PartnerData {
      id
      isPowerOfAttorney
      powerOfAttorney
      powerOfAttorneyStartDate
      powerOfAttorneyEndDate
      pep
      memberQualification
      participationPercentage
      legalRepresent
      documentNumber
      fileFrontDocument
      fileBackDocument
      registerName
      socialName
      countryCode
      number
      zipCode
      addressLine
      buildingNumber
      complement
      neighborhood
      city
      state
      country
      birthDate
      motherName
      email
      documentType
      createdAt
      updatedAt
      deleted
      deletedAt
      status
      businessId
      gender
      fields {
        id
        field
        description
        status
        integratorHasApproved
        businessId
        businessDataId
        partnerDataId
        value
      }
    }

    fragment businessesPartnersFields on Business {
      id
      participationPercentage
      documentNumber
      businessName
      tradingName
      businessType
      businessSize
      declaredAnnualBilling
      businessCnae
      countryCode
      phone
      site
      businessEmail
      zipCode
      addressLine
      buildingNumber
      complement
      neighborhood
      city
      state
      country
      numberOfPartners
      createdAt
      updatedAt
      deleted
      deletedAt
      status
      fields {
        id
        field
        description
        status
        integratorHasApproved
        businessId
        businessDataId
        partnerDataId
        value
      }
      companyStartDate
      businessPartnerId
      businessData {
        id
        fileCNPJ
        fileQSA
        incomes {
          id
          income
          createdAt
          updatedAt
          deleted
          deletedAt
          businessDataId
        }
        fields {
          id
          field
          description
          status
          integratorHasApproved
          businessId
          businessDataId
          partnerDataId
          value
        }
        createdAt
        updatedAt
        deleted
        deletedAt
        status
        socialContract
        lastContractChange
        socialStatute
        lastElection
        preventionPolicy
        proofOfRegularity
        accountingBalance
      }
      partnerData {
        ...partnerDataFields
      }
    }

    query GetBusiness($params: getBusinessInput!) {
      getBusiness(params: $params) {
        id
        declaredAnnualBilling
        participationPercentage
        documentNumber
        businessName
        tradingName
        businessType
        businessSize
        businessCnae
        countryCode
        phone
        site
        businessEmail
        zipCode
        addressLine
        buildingNumber
        complement
        isCryptoTrader
        neighborhood
        city
        state
        country
        numberOfPartners
        createdAt
        updatedAt
        deleted
        deletedAt
        status
        companyStartDate
        businessData {
          id
          fileCNPJ
          fileQSA
          incomes {
            id
            income
            createdAt
            updatedAt
            deleted
            deletedAt
            businessDataId
          }
          createdAt
          updatedAt
          deleted
          deletedAt
          status
          socialContract
          lastContractChange
          socialStatute
          lastElection
          preventionPolicy
          proofOfRegularity
          accountingBalance
        }
        partnerData {
          id
          legalRepresent
          documentNumber
          fileFrontDocument
          fileBackDocument
          registerName
          socialName
          countryCode
          number
          zipCode
          addressLine
          buildingNumber
          complement
          neighborhood
          city
          state
          country
          birthDate
          motherName
          email
          documentType
          createdAt
          updatedAt
          deleted
          deletedAt
          status
          businessId
          fields {
            id
            field
            description
            status
            integratorHasApproved
            businessId
            businessDataId
            partnerDataId
            value
          }
          gender
        }

        businessPartnerId
        businessesPartners {
          ...businessesPartnersFields
        }
      }
    }
  `;

  const fetcher = async () => {
    if (!params.businessId) return null;
    const response = await request<GetBusinessDataResponse>(QUERY, { params });
    return response.getBusiness;
  };

  return useQuery(['useGetBusiness', params.businessId], fetcher);
};
