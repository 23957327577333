import * as Style from './QuickButtonDropdown.styles';
import * as Text from '~styles/text';
import { useEffect, useRef, useState } from 'react';
import { QuickButtonProps } from './QuickButtonDropdown.types';
import { When } from 'react-if';
import ArrowDownSimpleIcon from '~assets/svg/arrowDownSimple';
import { colors } from '~styles';
import { DropDown } from '~components/DropDown';
import { DropDownItem } from '~components/DropDown/DropDownItem';

const QuickButtonDropdown = (props: QuickButtonProps) => {
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else setIsRippling(false);
  }, [coords]);

  useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  return (
    <Style.Container ref={containerRef}>
      <Style.ContainerButton
        fitContent={props.fitContent}
        width={props.buttonWidth}
        backgroundColor={props.backgroundColorButton}
        backgroundColorHover={props.backgroundHoverButton}
        onClick={(e: any) => {
          if (props.disabled) {
            return;
          }
          if (props.onClick) {
            props.onClick();
          }
          const rect = e.target.getBoundingClientRect();
          setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
        }}
        disabled={props.disabled}
        style={props.style}
      >
        <When condition={isRippling}>
          <span
            className="ripple"
            style={{
              left: coords.x,
              top: coords.y,
            }}
          />
        </When>

        <div className="hover-animation">
          <When condition={!!props.icon}>{props.icon}</When>
          {props.children}
        </div>
      </Style.ContainerButton>

      <DropDown
        align="end"
        widthContainer={190}
        gap={12}
        content={
          <>
            {props.arrayDropdown.map((item) => (
              <DropDownItem
                key={item.text}
                title={item?.text}
                onClick={() => item?.onClick()}
              />
            ))}
          </>
        }
      >
        <Style.ContainerButtonDropdown
          backgroundColor={props.backgroundColorButton}
          backgroundColorHover={props.backgroundHoverButton}
        >
          <ArrowDownSimpleIcon
            fill={props.backgroundColorButton ? colors.white : colors.black}
          />
        </Style.ContainerButtonDropdown>
      </DropDown>
    </Style.Container>
  );
};

export default QuickButtonDropdown;
