import { SVGProps } from 'react';

const BarcodeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0.249969C0.585786 0.249969 0.25 0.585756 0.25 0.999969V4.99997C0.25 5.41418 0.585786 5.74997 1 5.74997C1.41421 5.74997 1.75 5.41418 1.75 4.99997V1.74997H4C4.41421 1.74997 4.75 1.41418 4.75 0.999969C4.75 0.585756 4.41421 0.249969 4 0.249969H1ZM1 15.75C0.585786 15.75 0.25 15.4142 0.25 15V11C0.25 10.5858 0.585786 10.25 1 10.25C1.41421 10.25 1.75 10.5858 1.75 11V14.25H4C4.41421 14.25 4.75 14.5858 4.75 15C4.75 15.4142 4.41421 15.75 4 15.75H1ZM19.75 0.999969C19.75 0.585756 19.4142 0.249969 19 0.249969H16C15.5858 0.249969 15.25 0.585756 15.25 0.999969C15.25 1.41418 15.5858 1.74997 16 1.74997H18.25V4.99997C18.25 5.41418 18.5858 5.74997 19 5.74997C19.4142 5.74997 19.75 5.41418 19.75 4.99997V0.999969ZM19 15.75C19.4142 15.75 19.75 15.4142 19.75 15V11C19.75 10.5858 19.4142 10.25 19 10.25C18.5858 10.25 18.25 10.5858 18.25 11V14.25H16C15.5858 14.25 15.25 14.5858 15.25 15C15.25 15.4142 15.5858 15.75 16 15.75H19ZM4.75 4.24997C5.16421 4.24997 5.5 4.58576 5.5 4.99997V11C5.5 11.4142 5.16421 11.75 4.75 11.75C4.33579 11.75 4 11.4142 4 11V4.99997C4 4.58576 4.33579 4.24997 4.75 4.24997ZM9 4.99997C9 4.58576 8.66421 4.24997 8.25 4.24997C7.83579 4.24997 7.5 4.58576 7.5 4.99997V11C7.5 11.4142 7.83579 11.75 8.25 11.75C8.66421 11.75 9 11.4142 9 11V4.99997ZM11.75 4.24997C12.1642 4.24997 12.5 4.58576 12.5 4.99997V11C12.5 11.4142 12.1642 11.75 11.75 11.75C11.3358 11.75 11 11.4142 11 11V4.99997C11 4.58576 11.3358 4.24997 11.75 4.24997ZM16 4.99997C16 4.58576 15.6642 4.24997 15.25 4.24997C14.8358 4.24997 14.5 4.58576 14.5 4.99997V11C14.5 11.4142 14.8358 11.75 15.25 11.75C15.6642 11.75 16 11.4142 16 11V4.99997Z"
      fill="#272937"
    />
  </svg>
);

export default BarcodeIcon;
