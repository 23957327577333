import {
  CurrencyPeriod,
  Flags,
  WalletType,
} from '~reactQuery/types/wallets.types';
import { gql, request, useQuery } from '~reactQuery';

import { CurrencyTypes } from 'src/@types/currency.types';

export interface CurrencyVariationInput {
  prefCurrency: CurrencyTypes;
  currencyISO: CurrencyTypes;
  period: CurrencyPeriod;
}

export interface CurrencyVariationResponse {
  currencyVariation: {
    from: string;
    to: string;
    prices: number[];
    dates: string[];
    variation: number;
  };
}

export const useCurrencyVariation = (params: CurrencyVariationInput) => {
  const QUERY = gql`
    query CurrencyVariation($params: CurrencyVariationInput) {
      currencyVariation(params: $params) {
        from
        to
        prices
        dates
        variation
      }
    }
  `;

  const fetcher = async () => {
    if (['USD', 'EUR', 'GBP', 'BRL'].includes(params.currencyISO)) return null;
    if (['eUSDT', 'tUSDT'].includes(params.currencyISO)) {
      params.currencyISO = 'USDT';
    }

    const response = await request<CurrencyVariationResponse>(QUERY, {
      params,
    });
    return response.currencyVariation;
  };

  return useQuery(
    [`CurrencyVariation-${params.currencyISO}`, params.period],
    fetcher
  );
};
