import { colors, metrics } from '~styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 20px;
  cursor: pointer;

  :hover {
    .right-container {
      margin-left: 3px;
      margin-right: -3px;
      transition: all ease-out 0.3s;
    }
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 16px;
  }

  .right-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 100%;
    transition: all ease-out 0.3s;
  }
`;
