import * as Text from '~styles/text';

import React, { useState } from 'react';
import { getSelectedUserOrganization, getSettingsId } from '~helpers/device';

import AuthValidate from '~components/AuthValidate';
import DialogBottom from '~components/Dialog/DialogBottom.component';
import DialogHeader from '~components/Dialog/DialogHeader.component';
import { parseError } from '~reactQuery/index';
import { useTwoFAGetRescueCodes } from '~reactQuery/mutations/settings/useTwoFAGetRescueCodes';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';

const AskForPins: React.FC = ({ setStepsAndParams }) => {
  const orgId = getSelectedUserOrganization();

  const { mutateAsync: getRescueCodes, isLoading } = useTwoFAGetRescueCodes();

  const [hasError, setHasError] = useState({
    pin: '',
    twoFa: '',
  });

  const [codes, setCodes] = useState({
    pin: '',
    twoFa: '',
  });

  const canGoNext = codes.pin.length === 4 && codes.twoFa.length === 6;

  const organization = getSettingsId();

  const goNext = async () => {
    if (canGoNext) {
      setCodes({
        pin: '',
        twoFa: '',
      });

      try {
        const resp = await getRescueCodes({
          pin: codes.pin,
          token: codes.twoFa,
          userOrganizationId: Number(orgId),
          settingsId: Number(organization),
        });

        if (resp?.data)
          setStepsAndParams('generateCodes', { rescueCodes: resp?.data });
      } catch (error: any) {
        const errorKey = parseError(error);

        if (errorKey === 'INVALID_PIN') {
          setHasError((prev) => ({ ...prev, pin: 'Pin inválido' }));
        }

        if (errorKey === 'INVALID_2FA_TOKEN') {
          setHasError((prev) => ({ ...prev, twoFa: '2-FA inválido' }));
        }

        // console.log('Get rescue Codes =>', error);
      }
    }
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Insira o código de validação 2FA"
        description="Insira o código gerado pelo seu App de autenticação."
        backButtonAction={() => setStepsAndParams('explainCodes')}
      />

      <div className="recovery-codes-body">
        <AuthValidate
          authTypes={['PIN', '2-FA']}
          onChangeCodes={(pin, twoFa) => setCodes({ pin, twoFa })}
          pinErrorMessage={hasError.pin}
          twoFaErrorMessage={hasError.twoFa}
        />
      </div>

      <ModalMultiStepBottom
        singleButtonText="Gerar códigos"
        singleButtonAction={goNext}
        singleButtonAlignment="flex-end"
        doubleButtonTextOne="Voltar"
        doubleButtonOneAction={() => setStepsAndParams('explainCodes')}
        singleButtonLoading={isLoading}
        disableSingleButton={!canGoNext}
      />
    </>
  );
};

export default AskForPins;
