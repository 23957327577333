import {
  BusinessSize,
  BusinessType,
  Status,
} from '~reactQuery/types/business.types';
import { gql, request, useMutation } from '~reactQuery';

interface CreateBusinessPartnerInput {
  processId: number;
  businessId: number;
  addressLine: string;
  buildingNumber: string;
  businessCnae: string;
  businessEmail: string;
  businessName: string;
  businessSize: BusinessSize;
  city: string;
  country: string;
  countryCode: string;
  documentNumber: string;
  neighborhood: string;
  phone: string;
  state: string;
  tradingName: string;
  zipCode: string;
  businessType: BusinessType;
  complement?: string;
  site?: string;
  numberOfPartners: number;
  companyStartDate?: string;
  declaredAnnualBilling: string;
}

interface CreateBusinessPartnerResponse {
  createBusinessPartner: {
    data: {
      id: number;
      documentNumber: string;
      businessName: string;
      tradingName: string;
      businessType: BusinessType;
      businessSize: BusinessSize;
      businessCnae: string;
      countryCode: string;
      phone: string;
      site: string;
      businessEmail: string;
      zipCode: string;
      addressLine: string;
      buildingNumber: string;
      complement: string;
      neighborhood: string;
      city: string;
      state: string;
      country: string;
      numberOfPartners: number;
      createdAt: string;
      updatedAt: string;
      deleted: boolean;
      deletedAt: string;
      status: Status;
      companyStartDate: string;
      businessDataId: number;
      businessPartnerId: number;
      declaredAnnualBilling: string;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useCreateBusinessPartnerMutation() {
  const mutation = gql`
    mutation CreateBusinessPartner($params: createBusinessPartnerInput!) {
      createBusinessPartner(params: $params) {
        data {
          id
          declaredAnnualBilling
          documentNumber
          businessName
          tradingName
          businessType
          businessSize
          businessCnae
          countryCode
          phone
          site
          businessEmail
          zipCode
          addressLine
          buildingNumber
          complement
          neighborhood
          city
          state
          country
          numberOfPartners
          createdAt
          updatedAt
          deleted
          deletedAt
          status
          companyStartDate
          businessDataId
          businessPartnerId
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: CreateBusinessPartnerInput) => {
    const response = await request<CreateBusinessPartnerResponse>(mutation, {
      params,
    });

    return response.createBusinessPartner;
  };

  return useMutation('CreateBusinessPartner', fetch);
}
