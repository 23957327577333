import BusinessBlackLogo from '~assets/images/business-black.svg';
import CapitualBlackLogo from '~assets/images/logo-black.svg';
import * as Styles from './FullReceipt.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import FullReceiptRow from '~components/FullReceiptRow';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Statement,
  useGetStatement,
} from '~reactQuery/queries/bank/useGetStatement';
import { getSelectedUserOrganization } from '~helpers/device';
import dayjs from 'dayjs';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { formatCpfOrCnpj } from '~helpers/format/numbers';
import { Else, If, Then, When } from 'react-if';
import LoadingSpinner from '~components/LoadingSpinner';
import { formatCurrencyFn } from '~helpers/format/currency';
import { CSVLink } from 'react-csv';
import { RoundButtonIcon } from '~components/Buttons';
import DownloadSVG from '~assets/svg/download';
import { useLocation } from 'react-router-dom';
import { EmptyTransactions } from '~components/Empty';
import { useGetBusinessInfo } from '~reactQuery/queries/business/useGetBusinessInfo';
import {
  convertStringToDecimal,
  formatToOFXDate,
  serialize,
} from '~helpers/format/ofx';
import { receiptTitleParser } from '~helpers/transaction/transactionUtils';
import { capitalizeName } from '~helpers/format/user';

const FullReceipt = () => {
  const [cashIn, setCashIn] = useState(0);
  const [cashOut, setCashOut] = useState(0);
  const [canceledTxTotalValue, setCanceledTxTotalValue] = useState(0);

  const [amountOfCashInTransactions, setAmountOfCashInTransactions] =
    useState(0);
  const [amountOfCashOutTransactions, setAmountOfCashOutTransactions] =
    useState(0);
  const [amountOfCanceledTx, setAmountOfCanceledTx] = useState(0);

  const [openDropDown, setOpenDropDown] = useState(false);
  const [csv, setCsv] = useState([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const userOrganizationId = getSelectedUserOrganization();

  const fromDate = params.get('fromDate');
  const toDate = params.get('toDate');

  const { data: accountInfo, isLoading: isLoadingAccountInfo } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  const { data: wallets = [], isLoading: isLoadingWallets } = useGetWallets({
    prefCurrency: 'BRL',
    flag: 'list',
    userOrganizationId: Number(userOrganizationId),
  });

  const { data: statement, isLoading: isLoadingStatements } = useGetStatement({
    page: 0,
    from:
      fromDate !== 'undefined'
        ? String(fromDate)
        : dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    to:
      toDate !== 'undefined'
        ? dayjs(toDate).add(1, 'day').format('YYYY-MM-DD')
        : dayjs().add(1, 'day').format('YYYY-MM-DD'),
    perPage: 100,
  });

  const { data: businessInfo, isLoading: businessInfoLoading } =
    useGetBusinessInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  const companyName = accountInfo?.name;
  const companyCNPJ = formatCpfOrCnpj(String(accountInfo?.documentNumber));
  const companyBranch = accountInfo?.branch;
  const companyAccount = accountInfo?.account;

  const overviewSubtitle = `${
    fromDate !== 'undefined'
      ? dayjs(fromDate).format('DD/MM/YYYY')
      : dayjs().subtract(1, 'month').format('DD/MM/YYYY')
  } - ${
    toDate !== 'undefined'
      ? dayjs(toDate).format('DD/MM/YYYY')
      : dayjs().format('DD/MM/YYYY')
  }`;

  const getWalletFiltered: any = useCallback(
    (walletAddress: string) => {
      if (walletAddress && wallets) {
        return wallets.filter((w) => w.walletAddress === walletAddress)?.[0];
      }
      return '';
    },
    [wallets]
  );

  const getTxParserDestination = (tx: any) => {
    const parsedTx = {
      destination: '',
    };

    if (tx.method === 'Transfer') {
      const isCapContaToWallet =
        tx?.data?.requisite?.type === 'EXCHANGE_CAPCONTA_TO_CAPWALLET';
      const isCapWalletToCapConta =
        tx?.data?.requisite?.type === 'EXCHANGE_CAPWALLET_TO_CAPCONTA';

      if (isCapContaToWallet) {
        parsedTx.destination =
          getWalletFiltered(tx.data?.requisite?.walletAddress)?.walletName ||
          '-';
      } else if (isCapWalletToCapConta) {
        parsedTx.destination = 'Cap Conta';
      } else {
        const title = receiptTitleParser(
          tx.status,
          String(tx?.data?.method).toUpperCase() === 'PIX'
            ? 'TRANSFER-PIX'
            : 'TRANSFER-TED',
          tx.direction,
          true
        );

        parsedTx.destination =
          tx.direction > 1
            ? 'Cap Conta'
            : `${
                tx.data?.requisite?.key?.holderName ||
                tx.data?.requisite?.holderName
              } ${
                tx.direction > 1
                  ? ''
                  : `(${formatCpfOrCnpj(
                      tx.data?.requisite?.key?.holderDocument ||
                        tx.data?.requisite?.holderDocument
                    )})`
              }`;

        if (tx.data?.requisite?.key) {
          parsedTx.destination += ` - Agência ${tx.data?.requisite?.key?.branch} - Conta ${tx.data?.requisite?.key?.account} - ${tx.data?.requisite?.key?.bank?.bankName}`;
        } else if (tx.data?.requisite) {
          parsedTx.destination += ` - Agência ${tx.data?.requisite?.branch} - Conta ${tx.data?.requisite?.account} - ${tx.data?.requisite?.bank?.bankName}`;
        }
      }
    }

    if (tx.method === 'BillSlip') {
      const isCashIn = tx.direction === 1;
      if (!isCashIn) {
        parsedTx.destination = '-';
      }
    }

    if (tx.method === 'Refund') {
      parsedTx.destination = 'Cap Conta';
    }

    if (tx.method === 'Bill') {
      parsedTx.destination = tx?.data?.recipientName || tx?.data?.assignor;
    }

    return parsedTx.destination;
  };

  function transactionToOFX(transaction: Statement[]): string {
    const header = {
      OFXHEADER: '100',
      DATA: 'OFXSGML',
      VERSION: '102',
      SECURITY: 'NONE',
      ENCODING: 'USASCII',
      CHARSET: '1252',
      COMPRESSION: 'NONE',
      OLDFILEUID: 'NONE',
      NEWFILEUID: 'NONE',
    };

    const ofxData = {
      OFX: {
        SIGNONMSGSRQV1: {
          SONRS: {
            STATUS: '0',
            DTSERVER: formatToOFXDate(new Date()),
            LANGUAGE: 'POR',
          },
        },
        BANKMSGSRSV1: {
          STMTTRNRS: {
            STATUS: {
              CODE: 0,
              SEVERITY: 'INFO',
            },
            STMTRS: {
              CURDEF: 'BRL',
              BANKACCTFROM: {
                BANKID: '332',
                ACCTID: accountInfo?.branch,
                ACCTTYPE: 'CHECKING',
              },
              BANKTRANLIST: {
                DTSTART: formatToOFXDate(new Date(fromDate as string)),
                DTEND: formatToOFXDate(new Date(toDate as string)),
                STMTTRN: transaction?.map((tx: Statement) => {
                  const transactionDate = formatToOFXDate(
                    new Date(tx?.timestamp)
                  );
                  const type = tx?.direction === -1 ? 'DEBIT' : 'CREDIT';
                  const name = getTxParserDestination(tx);
                  const value =
                    tx?.direction === -1
                      ? Number(-convertStringToDecimal(tx?.data?.value))
                      : Number(convertStringToDecimal(tx?.data?.value));
                  const description = tx?.data?.description
                    ? tx?.data?.description
                    : '-';

                  return {
                    TRNTYPE: type,
                    DTPOSTED: transactionDate,
                    TRNAMT: value,
                    FITID: tx?.authCode,
                    NAME: description,
                    MEMO: name,
                  };
                }),
              },
              LEDGERBAL: {
                BALAMT: '0.00',
                DTASOF: formatToOFXDate(new Date()),
              },
              AVAILBAL: {
                BALAMT: '0.00',
                DTASOF: formatToOFXDate(new Date()),
              },
            },
          },
        },
      },
    };

    return serialize(header, ofxData);
  }

  const totalIncomes = `+ ${formatCurrencyFn('BRL', String(cashIn / 100))} `;
  const totalExits = `- ${formatCurrencyFn('BRL', String(cashOut / 100))} `;
  const totalCanceledTxValue = `${formatCurrencyFn(
    'BRL',
    String(canceledTxTotalValue / 100)
  )} `;

  const totalBalance = formatCurrencyFn(
    'BRL',
    String((cashIn - cashOut) / 100)
  );

  const isLoading =
    isLoadingAccountInfo || isLoadingWallets || isLoadingStatements;

  const statementArray = useMemo(
    () => [...new Set(statement?.pages[0])],
    [statement?.pages]
  );

  const calculate = useCallback(() => {
    let cashInValue = 0;
    let cashOutValue = 0;

    let cashInAmount = 0;
    let cashOutAmount = 0;

    let cancelledTxAmount = 0;
    let cancelledTxValueAmount = 0;

    statementArray.forEach((tx) => {
      const { value } = tx.data;

      const isCanceledTx =
        tx.status === 'CANCELED' || tx.status === 'CANCELLED';

      if (isCanceledTx) {
        cancelledTxValueAmount += Number(value);
        cancelledTxAmount += 1;
      }

      if (tx.direction === 1 && !isCanceledTx) {
        cashInValue += Number(value);
        cashInAmount += 1;
      }

      if (tx.direction === -1 && !isCanceledTx) {
        cashOutValue += Number(value);
        cashOutAmount += 1;
      }
    });

    setCashIn(cashInValue);
    setCashOut(cashOutValue);
    setAmountOfCashInTransactions(cashInAmount);
    setAmountOfCashOutTransactions(cashOutAmount);
    setAmountOfCanceledTx(cancelledTxAmount);
    setCanceledTxTotalValue(cancelledTxValueAmount);
  }, [statementArray]);

  useEffect(() => {
    const root = document.getElementById('root');
    const rootHtml = document.getElementById('root-html');
    const rootBody = document.getElementById('root-body');

    if (!root || !rootHtml || !rootBody) return;

    root.style.overflowY = 'initial';
    rootHtml.style.overflowY = 'initial';
    rootBody.style.overflowY = 'initial';

    // eslint-disable-next-line consistent-return
    return () => {
      root.style.overflowY = 'hidden';
      rootHtml.style.overflowY = 'hidden';
      rootBody.style.overflowY = 'hidden';
    };
  }, []);

  useEffect(() => {
    if (isLoading) return;

    calculate();
  }, [calculate, isLoading]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setOpenDropDown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  const currentDate = dayjs().format('DD/MM/YYYY');
  const currentTime = dayjs().format('h:mm A');

  const csvData = [
    [
      'Data',
      'Id da transação',
      'Tipo',
      'Conta Associada',
      'Destino/Origem',
      'Descrição',
      'valor',
    ],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...Array.from(new Set(csv.map(JSON.stringify)), JSON.parse),
  ];

  const createOFXFileAndDownload = () => {
    const ofxContent = transactionToOFX(statementArray);
    const blob = new Blob([ofxContent], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${dayjs().format('DD-MM-YYYY')}.ofx`;
    a.click();
  };

  return (
    <Styles.Main isLoading={isLoading}>
      <If condition={isLoading}>
        <Then>
          <div className="loading-wrapper">
            <LoadingSpinner color={colors.primary} size={60} strokeSize={25} />
          </div>
        </Then>

        <Else>
          <div className="float-button">
            <RoundButtonIcon
              buttonSize="icon24v2"
              onClick={() => setOpenDropDown(!openDropDown)}
              buttonType="active"
            >
              <DownloadSVG />
            </RoundButtonIcon>

            {openDropDown && (
              <div className="dropdown" ref={containerRef}>
                <div className="item" onClick={() => window.print()}>
                  Baixar PDF
                </div>
                <CSVLink
                  data={csvData}
                  onClick={() => {
                    if (csv.length === 0) {
                      return false;
                    }
                    return true;
                  }}
                  filename={`relatorio - ${dayjs().format('DD-MM-YYYY')}.csv`}
                >
                  <div className="item">Baixar CSV</div>
                </CSVLink>
                <div className="item" onClick={createOFXFileAndDownload}>
                  Baixar OFX
                </div>
              </div>
            )}
          </div>

          <Styles.Header>
            <Styles.LogoWrapper>
              <CapitualBlackLogo />
              <BusinessBlackLogo />
            </Styles.LogoWrapper>

            <When condition={!!accountInfo}>
              <div className="company-info-container">
                <Text.Heading5>
                  <b>{companyName}</b>
                </Text.Heading5>
                <div className="company-details">
                  <Text.Heading5 display="inline-block">
                    <b>CNPJ</b> {companyCNPJ}
                  </Text.Heading5>
                  <Text.Heading5 display="inline-block">
                    <b>Agência</b> {companyBranch}
                  </Text.Heading5>
                  <Text.Heading5 display="inline-block">
                    <b>Conta</b> {companyAccount}
                  </Text.Heading5>
                </div>
                <div className="company-details">
                  <Text.Heading5 display="inline-block">
                    <b>Endereço:</b> {capitalizeName(businessInfo?.addressLine || '--')}, n{' '}
                    {capitalizeName(businessInfo?.buildingNumber || '--')} - cep{' '}
                    {capitalizeName(businessInfo?.zipCode || '--')} -{' '}
                    {capitalizeName(businessInfo?.neighborhood || '--')},{' '}
                    {capitalizeName(businessInfo?.city || '--')}, {businessInfo?.state}
                  </Text.Heading5>
                </div>
              </div>
            </When>
          </Styles.Header>

          <Styles.Overview>
            <div className="overview-title">
              <Text.Heading1Bold lineHeight={150}>
                Extrato de
                <br />
                Movimentações
              </Text.Heading1Bold>

              <Text.Heading5>{overviewSubtitle}</Text.Heading5>
            </div>
            <div className="overview-stats">
              <div className="stats-row">
                <Text.Paragraph color={colors.grayMedium}>
                  Total de transações canceladas
                </Text.Paragraph>
                {statementArray.length > 0 ? (
                  <Text.Heading5Bold>
                    {totalCanceledTxValue} ({amountOfCanceledTx} transações)
                  </Text.Heading5Bold>
                ) : (
                  '-'
                )}
              </div>

              <div className="stats-row">
                <Text.Paragraph color={colors.grayMedium}>
                  Total de entradas
                </Text.Paragraph>
                {statementArray.length > 0 ? (
                  <Text.Heading5Bold>
                    {totalIncomes} ({amountOfCashInTransactions} transações)
                  </Text.Heading5Bold>
                ) : (
                  '-'
                )}
              </div>

              <div className="stats-row">
                <Text.Paragraph color={colors.grayMedium}>
                  Total de saídas
                </Text.Paragraph>
                {statementArray.length > 0 ? (
                  <Text.Heading5Bold>
                    {totalExits} ({amountOfCashOutTransactions} transações)
                  </Text.Heading5Bold>
                ) : (
                  '-'
                )}
              </div>

              <div className="separator" />

              <div className="stats-row mt-10">
                <Text.Paragraph>Saldo final do período</Text.Paragraph>
                {statementArray.length > 0 ? (
                  <Text.Heading3Bold>
                    {totalBalance} (
                    {amountOfCashOutTransactions + amountOfCashInTransactions}{' '}
                    transações)
                  </Text.Heading3Bold>
                ) : (
                  '-'
                )}
              </div>
            </div>
          </Styles.Overview>

          <Styles.Table id="my-table">
            <thead>
              <tr>
                <th>
                  <Text.SmallBold>Data</Text.SmallBold>
                </th>
                <th>
                  <Text.SmallBold>Id da transação</Text.SmallBold>
                </th>
                <th className="hide">
                  <Text.SmallBold>Tipo</Text.SmallBold>
                </th>
                <th className="hide">
                  <Text.SmallBold>Conta associada</Text.SmallBold>
                </th>
                <th>
                  <Text.SmallBold>Destino/Origem</Text.SmallBold>
                </th>
                <th>
                  <Text.SmallBold>Descrição</Text.SmallBold>
                </th>
                <th className="mobile-hide">
                  <Text.SmallBold>Valor</Text.SmallBold>
                </th>
              </tr>
            </thead>
            {statementArray.length > 0 ? (
              <tbody>
                {statementArray?.map((tx) => {
                  return (
                    <FullReceiptRow
                      key={tx.authCode}
                      setCsv={setCsv}
                      tx={tx}
                      wallets={wallets}
                    />
                  );
                })}
              </tbody>
            ) : (
              <div className="empty-transaction">
                <EmptyTransactions noTransactionButton />
              </div>
            )}

            <tfoot>
              <tr>
                <td>
                  <div className="footer-space">&nbsp;</div>
                </td>
              </tr>
            </tfoot>
          </Styles.Table>
          <div className="footer">
            <Text.Paragraph color={colors.grayMedium}>
              © 2022 Capitual Instituição de Pagamentos LTDA. 34.942.560/0001-87
            </Text.Paragraph>
            <Text.Paragraph color={colors.grayMedium}>
              Extrato gerado no dia {currentDate} às {currentTime}
            </Text.Paragraph>
          </div>
        </Else>
      </If>
    </Styles.Main>
  );
};
export default FullReceipt;
