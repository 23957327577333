import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import GenericRow from '~components/GenericRow';
import { InputForm, RoundButtonIcon } from '~components/index';
import CapAccountSVG from '~assets/svg/capAccount';
import CalendarSVG from '~assets/svg/calendar';
import EditSVG from '~assets/svg/edit';
import { useState } from 'react';
import { When } from 'react-if';
import PlusSVG from '~assets/svg/plus';
import { suprimeCpfOrCnpj } from '~helpers/format/numbers';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { useFormContext } from 'react-hook-form';
import { RefundProps } from '../Refund.types';

const ConfirmDetails = ({
  setCurrentRefundStep,
  transactionDetails,
}: RefundProps) => {
  const { watch, setValue } = useFormContext();
  const [methodOfTransfer, setMethodOfTransfer] = useState(0);
  const [comment, setComment] = useState({
    open: false,
    text: '',
  });

  const isTedOrPixManual = transactionDetails?.data?.method;
  const name =
    transactionDetails?.data?.requisite?.holderName ||
    transactionDetails?.data?.requisite?.key?.holderName;
  const selectDataToDisplay = {
    cpfOrCnpj:
      transactionDetails?.data?.requisite?.holderDocument ||
      transactionDetails?.data?.requisite?.key?.holderDocument,
    bankName:
      transactionDetails?.data?.requisite?.bank?.bankName ||
      transactionDetails?.data?.requisite?.key?.bank?.bankName,
    branch:
      transactionDetails?.data?.requisite?.branch ||
      transactionDetails?.data?.requisite?.key?.branch,
    accountType:
      transactionDetails?.data?.requisite?.accountType ||
      transactionDetails?.data?.requisite?.key?.accountType ||
      'Conta',
    account:
      transactionDetails?.data?.requisite?.account ||
      transactionDetails?.data?.requisite?.key?.account,
  };

  const methods = ['PIX Manual', 'TED'];

  const selectTransferType = () => {
    setMethodOfTransfer((prev) => {
      if (prev === 1) return 0;
      return prev + 1;
    });
  };

  const handleNext = () => {
    setValue('refundReason', comment?.text);
    setCurrentRefundStep('insertPin');
  };

  return (
    <>
      <ModalMultiStepHeader
        title={`Reembolsando ${watch('amount')} para ${name}`}
        description={`Reembolsando para ${name} via Pix`}
      />

      <div className="content-container">
        <GenericRow
          overText="Origem"
          title="CapConta"
          rightContentType="component"
          rightComponent={
            <RoundButtonIcon buttonType="outlined" buttonSize="iconWallet">
              <CapAccountSVG />
            </RoundButtonIcon>
          }
          noHorizontalPadding
        />

        <GenericRow
          overText="Data do Reembolso"
          title="Hoje"
          rightContentType="component"
          rightComponent={
            <RoundButtonIcon
              buttonType="default"
              onClick={() => {}}
              buttonSize="icon24"
              noHover
            >
              <CalendarSVG />
            </RoundButtonIcon>
          }
          noHorizontalPadding
        />

        <GenericRow
          overText="Tipo de Transferência"
          title={isTedOrPixManual ? methods[methodOfTransfer] : 'PIX'}
          rightContentType="component"
          rightComponent={
            <RoundButtonIcon
              buttonType="default"
              onClick={selectTransferType}
              buttonSize="icon24"
              noHover={!isTedOrPixManual}
            >
              <EditSVG />
            </RoundButtonIcon>
          }
          noHorizontalPadding
        />

        <GenericRow
          title="CPF/CNPJ"
          rightContentType="text"
          rightText={suprimeCpfOrCnpj(
            String(onlyNumbers(selectDataToDisplay?.cpfOrCnpj))
          )}
          noHorizontalPadding
          padding="20px 0px 10px"
        />

        <When condition={isTedOrPixManual}>
          <GenericRow
            title="Instituição"
            rightContentType="text"
            rightText={`${selectDataToDisplay?.bankName?.slice(0, 40)} ${
              selectDataToDisplay?.bankName &&
              selectDataToDisplay?.bankName?.length > 40
                ? '...'
                : ''
            }`}
            noHorizontalPadding
            padding="10px 0px"
            noBorderTop
          />

          <GenericRow
            title="Agência"
            rightContentType="text"
            rightText={selectDataToDisplay?.branch}
            noHorizontalPadding
            padding="10px 0px"
            noBorderTop
          />

          <GenericRow
            title={selectDataToDisplay?.accountType}
            rightContentType="text"
            rightText={selectDataToDisplay?.account}
            noHorizontalPadding
            padding="10px 0px 20px"
            noBorderTop
          />
        </When>

        <GenericRow
          overText="Comentário"
          title={comment.text === '' ? 'Nenhuma nota' : comment.text}
          rightContentType="component"
          rightComponent={
            <RoundButtonIcon
              buttonType="default"
              onClick={() =>
                setComment((prev) => ({ ...prev, open: !prev.open }))
              }
              buttonSize="icon24"
            >
              <PlusSVG />
            </RoundButtonIcon>
          }
          noHorizontalPadding
        />

        <When condition={comment.open}>
          <div className="pl-10 pr-10">
            <InputForm
              marginBottom={10}
              defaultValue={comment.text}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={(e: any) =>
                setComment((prev) => ({ ...prev, text: e.target.value }))
              }
            />
          </div>
        </When>

        <ModalMultiStepBottom
          singleButtonText="Próximo"
          singleButtonWidth="100%"
          singleButtonAction={() => handleNext()}
        />
      </div>
    </>
  );
};

export default ConfirmDetails;
