import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

import blackLogo from '~assets/images/logo-black.png';
import colors from '~styles/colors';
import { formatCurrencyFn } from '~helpers/format/currency';

export interface WithdrawDetailsProps {
  details: {
    amount: string;
    amountToWithdraw: string;
    withdrawId: string;
    walletAddress: string;
    selectedWallet: {
      isoCode: string;
      walletName: string;
    };
    originWallet: {
      isoCode: string;
      walletName: string;
    };
    timestamp: string;
    areSameWallets: boolean;
  };
}

const styles = StyleSheet.create({
  body: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    fontWeight: 400,
  },
  title: {
    fontSize: 26,
    textAlign: 'left',
    fontWeight: 700,
    lineHeight: '120%',
  },
  logo: {
    width: 125,
    height: 33,
  },
  container: {
    width: '100%',
    marginTop: 30,
    padding: '25px 25px 25px 25px',
  },
  date: {
    marginTop: 15,
    marginBottom: 40,
    fontWeight: 400,
    fontSize: 16,
    color: colors.grayMedium,
  },
  valueContainer: {
    flexDirection: 'column',
    marginBottom: 20,
  },
  divider: {
    width: 50,
    border: '1px solid #C3C6DB',
    marginBottom: 30,
    marginTop: 30,
  },
  footer: {
    backgroundColor: colors.mainGray,
    padding: 20,
    borderRadius: 15,
    bottom: 15,
    position: 'absolute',
    width: '90%',
  },
  label: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '100%',
    color: colors.grayRegular,
    marginBottom: 10,
  },
  link: {
    color: colors.primary,
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '150%',
    marginTop: 12,
    marginBottom: 5,
    textAlign: 'center',
    textDecoration: 'none',
  },
  containerImg: {
    height: 33,
    top: 30,
    left: 25,
    width: '100%',
    zIndex: 99,
  },
  convertedValue: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '100%',
  },
  userName: {
    fontWeight: 700,
    fontSize: 18,
  },
  textNames: {
    fontWeight: 700,
    fontSize: 14,
    marginBottom: 7,
  },
  bankNameText: {
    fontWeight: 400,
    fontSize: 15,
    marginBottom: 20,
  },
  displayRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  marginLeft: {
    marginLeft: 20,
  },
  marginBottom: {
    marginBottom: 25,
  },
  colorGrayMedium: {
    color: colors.grayMedium,
  },
  view: {
    marginBottom: 20,
  },
  help: {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '150%',
    textAlign: 'center',
    marginBottom: 12,
  },
  helpText: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '150%',
    textAlign: 'center',
  },
});

export const WithdrawReceipt = ({ details }: WithdrawDetailsProps) => {
  const {
    amount,
    amountToWithdraw,
    timestamp,
    selectedWallet,
    walletAddress,
    withdrawId,
    originWallet,
    areSameWallets,
  } = details;

  const originWalletIsoCode = originWallet.isoCode;
  const originWalletName = originWallet.walletName;

  const selectedWalletIsoCode = selectedWallet.isoCode;
  const selectedWalletName = selectedWallet.walletName;

  return (
    <Document>
      <Page wrap style={styles.body} size={{ width: 375, height: 1048 }}>
        <View fixed style={styles.containerImg}>
          <Image src={blackLogo} style={styles.logo} />
        </View>
        <View style={styles.container}>
          <View>
            <Text style={styles.title}>
              Comprovante de
              {'\n'}
              Saque de Criptomoeda
            </Text>
          </View>
          <Text style={styles.date}>{timestamp}</Text>

          <View style={styles.valueContainer}>
            <Text style={styles.label}>Valor do Saque</Text>
            <Text style={styles.convertedValue}>
              {formatCurrencyFn(
                selectedWalletIsoCode,
                amountToWithdraw,
                false,
                false,
                false
              )}
            </Text>
          </View>

          {!areSameWallets && (
            <View>
              <Text style={styles.label}>Valor convertido</Text>
              <Text style={styles.textNames}>
                {formatCurrencyFn(
                  originWalletIsoCode,
                  amount,
                  false,
                  false,
                  false
                )}
              </Text>
            </View>
          )}

          <View style={styles.divider} />

          {!areSameWallets && (
            <View style={styles.view}>
              <View>
                <Text style={styles.label}>CapWallet de Origem</Text>
                <Text style={styles.textNames}>
                  {originWalletName} ({originWalletIsoCode})
                </Text>
              </View>
            </View>
          )}

          <View>
            <View>
              <Text style={styles.label}>CapWallet de Saque (Destino)</Text>
              <Text style={styles.textNames}>
                {selectedWalletName} ({selectedWalletIsoCode})
              </Text>
            </View>
          </View>

          <View style={styles.divider} />

          <View style={styles.view}>
            <View>
              <Text style={styles.label}>Endereço da Carteira de Destino</Text>
              <Text style={styles.textNames}>{walletAddress}</Text>
            </View>
          </View>

          <View>
            <View>
              <Text style={styles.label}>ID do Saque</Text>
              <Text style={styles.textNames}>{withdrawId}</Text>
            </View>
          </View>
        </View>

        <View fixed style={styles.footer}>
          <Text style={styles.help}>Precisa de ajuda?</Text>
          <Text style={styles.helpText}>
            Estamos aqui se você tiver alguma dúvida.
          </Text>
          <Text style={styles.link}>help.capitual.com</Text>
        </View>
      </Page>
    </Document>
  );
};
