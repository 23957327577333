import { SVGProps } from 'react';

const BellIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.248 4.62694C16.1002 3.5467 14.5729 2.96301 12.998 3.00182C11.4231 2.96301 9.8959 3.5467 8.74811 4.62694C7.59313 5.71394 6.91675 7.2149 6.8675 8.80017L6.86702 8.81569V8.83122V12.8932L5.14059 15.7707L4.23193 17.2852H5.99809H19.9981H21.7643L20.8556 15.7707L19.129 12.8932V8.83122V8.81569L19.1286 8.80017C19.0793 7.2149 18.4029 5.71394 17.248 4.62694ZM10.1188 6.08336C10.8879 5.35954 11.9128 4.97052 12.9685 5.00175L12.998 5.00262L13.0276 5.00175C14.0833 4.97052 15.1082 5.35954 15.8773 6.08336C16.6428 6.80382 17.0926 7.7974 17.129 8.84761V13.1702V13.4472L17.2716 13.6847L18.2319 15.2852H7.76424L8.72452 13.6847L8.86702 13.4472V13.1702V8.84762C8.90351 7.79741 9.35329 6.80383 10.1188 6.08336ZM9.99809 21H15.9981V19H9.99809V21Z"
      fill={props.fill || 'black'}
    />
  </svg>
);

export default BellIcon;
