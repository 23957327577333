import * as Text from '~styles/text';

import React, { useState } from 'react';
import { getSelectedUserOrganization, getSettingsId } from '~helpers/device';

import AuthValidate from '~components/AuthValidate';
import DialogBottom from '~components/Dialog/DialogBottom.component';
import DialogHeader from '~components/Dialog/DialogHeader.component';
import { parseError } from '~reactQuery/index';
import { useTwoFADisable } from '~reactQuery/mutations/settings/useTwoFADisable';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';

const AskPinDisableTwoFa: React.FC = ({ setStepsAndParams }) => {
  const orgId = getSelectedUserOrganization();
  const { mutateAsync: disableTwoFACode, isLoading } = useTwoFADisable();

  const organization = getSettingsId();

  const [recovery, setRecovery] = useState(false);
  const [codes, setCodes] = useState({
    pin: '',
    twoFa: '',
  });
  const [hasError, setHasError] = useState({
    pin: '',
    twoFa: '',
  });

  const disableTwoFA = async (pin: string, token: string) => {
    setHasError({
      pin: '',
      twoFa: '',
    });

    if (pin.length === 4 && token.length === 6) {
      try {
        await disableTwoFACode({
          token,
          pin,
          userOrganizationId: Number(orgId),
          settingsId: Number(organization),
          recovery,
        });

        setStepsAndParams('successDisable');
      } catch (error: any) {
        const errorKey = parseError(error);

        if (errorKey === 'INVALID_PIN') {
          setHasError((prev) => ({ ...prev, pin: 'Pin inválido' }));
        }

        if (errorKey === 'INVALID_2FA_TOKEN') {
          setHasError((prev) => ({ ...prev, twoFa: '2-FA inválido' }));
        }

        // console.log('Disable two fa error ->', error);
      }
    }
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Insira o código de validação para desabilitar seu 2FA"
        description="Digite o código de 6 dígitos gerado pelo seu aplicativo de autenticação ou um dos seus códigos de recuperação."
        backButtonAction={() => setStepsAndParams('showAdvice')}
      />

      <div className="disable-two-fa-body">
        <AuthValidate
          authTypes={['PIN', '2-FA']}
          onChangeCodes={(pin, twoFa) => setCodes({ pin, twoFa })}
          pinErrorMessage={hasError.pin}
          twoFaErrorMessage={hasError.twoFa}
          showTwoFaRecoveryOption
          setRecovery={setRecovery}
        />
      </div>

      <ModalMultiStepBottom
        doubleButtonTextOne="Voltar"
        singleButtonAlignment="flex-end"
        doubleButtonOneAction={() => setStepsAndParams('showAdvice')}
        singleButtonText="Desabilitar 2FA"
        singleButtonLoading={isLoading}
        singleButtonAction={() => disableTwoFA(codes.pin, codes.twoFa)}
      />
    </>
  );
};

export default AskPinDisableTwoFa;
