import { Fields, Incomes, Status } from '~reactQuery/types/business.types';
import { gql, request, useMutation } from '~reactQuery';

interface PutBusinessPartnerDataInput {
  businessDataId: number;
  fileCNPJ: string;
  fileQSA: string;
  socialContract?: string;
  lastContractChange?: string;
  socialStatute?: string;
  lastElection?: string;
}

interface PutBusinessPartnerDataResponse {
  putBusinessPartnerData: {
    data: {
      id: number;
      fileCNPJ: string;
      fileQSA: string;
      socialContract: string;
      lastContractChange: string;
      socialStatute: string;
      lastElection: string;
      incomes: [Incomes];
      fields: [Fields];
      createdAt: string;
      updatedAt: string;
      deleted: boolean;
      deletedAt: string;
      status: Status;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function usePutBusinessPartnerDataMutation() {
  const mutation = gql`
    mutation PutBusinessPartnerData($params: putBusinessDataInput!) {
      putBusinessPartnerData(params: $params) {
        isValid
        messages
        data {
          id
          fileCNPJ
          fileQSA
          socialContract
          lastContractChange
          socialStatute
          lastElection
          incomes {
            id
            income
            createdAt
            updatedAt
            deleted
            deletedAt
            businessDataId
          }
          fields {
            id
            field
            description
            status
            integratorHasApproved
            businessId
            businessDataId
            partnerDataId
            value
          }
          createdAt
          updatedAt
          deleted
          deletedAt
          status
        }
      }
    }
  `;

  const fetch = async (params: PutBusinessPartnerDataInput) => {
    const response = await request<PutBusinessPartnerDataResponse>(mutation, {
      params,
    });

    return response.putBusinessPartnerData;
  };

  return useMutation('PutBusinessPartnerData', fetch);
}
