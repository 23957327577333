import colors from '~styles/colors';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const DepositContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .double-button-one-container {
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
  }

  .wallet-icon {
    transform: scale(0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .check-select-container-right {
    display: flex;
    align-items: center;

    svg {
      margin-left: 9px;
    }

    .text {
      color: ${colors.grayLight};
    }

    .text-tooltip {
      color: ${colors.white};
    }
  }

  .billet-deposit-title {
    font-feature-settings: 'zero' on, 'ss02' on;
  }

  .container-deposit-generated {
    padding: 0px 44px 0px 35px;
    display: flex;
    height: 350px;
    display: flex;
    flex-direction: column;

    .top {
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    .container-warning-message {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      padding: 10px;
      background-color: ${transparentize(0.85, colors.grayLight)};
      border-radius: 10px;

      p {
        text-align: left;
        margin-left: 10px;
      }
    }

    .value {
      margin-bottom: 26px;
      font-size: 1.63rem !important;
    }

    .billet-value {
      margin-bottom: 26px;
      font-size: 1.63rem !important;
      font-feature-settings: 'zero' on, 'ss02' on;
    }

    .copy-value {
      color: ${colors.primary};
      &:hover {
        cursor: pointer;
      }
    }
  }

  .container-deposit-value {
    padding: 20px 44px 20px 35px;

    .without-value {
      color: ${colors.grayRegular} !important;
      border-color: ${colors.grayRegular} !important;
    }

    .observation-about-value-deposit {
      margin-bottom: 24px;
    }

    .observation-about-pin {
      margin-top: 8px;
    }

    .container-warning-message {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      padding: 10px;
      background-color: ${transparentize(0.85, colors.warning)};
      border-radius: 10px;
      margin-top: 24px;
      svg {
        path {
          stroke: ${colors.warning} !important;
        }
      }

      p {
        text-align: left;
        width: 94%;
        font-weight: 400;
      }
    }

    .margin-vertical {
      margin-bottom: 8px;
    }
  }

  .content-container {
    padding: 0 35px;

    .warning-message {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      width: 100%;
      padding: 10px;
      background-color: ${transparentize(0.85, colors.grayLight)};
      border-radius: 10px;
      margin-top: 30px;

      p {
        width: 94%;
      }
    }

    .container-qr-and-options {
      height: 60%;

      padding: 35px 0px;
      display: flex;
      justify-content: space-between;
      align-itens: center;

      .btt-currency {
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .qr-container {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 200px;
        max-height: 200px;
        margin-left: 5px;
        margin-right: 5px;
        padding: 10px;
        border: 2px solid ${colors.mainGray};
        border-radius: 10px;

        .loading-container {
          width: 200px;
          height: 200px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .container-title {
      margin-top: 35px;
      margin-bottom: 15px;
      gap: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;
