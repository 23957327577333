import React, { SVGProps } from 'react';

const LogListIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '16'}
      height={props.height || '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g opacity="0.3">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 5.25H4V3.75H2V5.25ZM2 8.75H4V7.25H2V8.75ZM4 12.25H2V10.75H4V12.25ZM6 5.25H14V3.75H6V5.25ZM14 8.75H6V7.25H14V8.75ZM6 12.25H14V10.75H6V12.25Z"
          fill={props.fill || '#272937'}
        />
      </g>
    </svg>
  );
};

export default LogListIcon;
