import { HeadBar, MobileMenu } from '~components';
import * as Text from '~styles/text';
import { useState } from 'react';
import { When } from 'react-if';
import * as Style from './Navbar.styles';

import { clearStorage, getSelectedUserOrganization } from '~helpers/device';
import { APP_ROUTES } from '~data/navigation';
import { colors } from '~styles';
import { formatCurrencyFn } from '~helpers/format/currency';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { useNavigate } from 'react-router-dom';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import { useQueryClient } from 'react-query';
import { useUserWalletsFunds } from '~reactQuery/queries/user/useUserWalletsFunds';
import { useGetNotifications } from '~reactQuery/queries/notifications/useGetNotifications';
import BellIcon from '~assets/svg/bell';
import SymbolLogo from '~assets/images/icon-business.svg';

import { NavbarProps } from './Navbar.types';

const Navbar: React.FC<NavbarProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { setOpenModalSideBar, setModalSidebarContent } = useOpenModalSideBar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const userOrganizationId = getSelectedUserOrganization();

  const handleOpenAreaPix = () => {
    setOpenModalSideBar(true);
    setModalSidebarContent('areaPix');
  };

  const handleOpenNotifications = () => {
    setModalSidebarContent('notifications');
    setOpenModalSideBar(true);
  };

  const { data: notifications = [] } = useGetNotifications({
    userOrganizationId: Number(userOrganizationId),
    showNotificationData: true,
  });

  const hasUnreadNotifcations = notifications?.some((n) => n.read === false);

  const { data: userFunds = 0, isLoading: loadingUserFunds } =
    useUserWalletsFunds('BRL', Number(userOrganizationId));
  const { data: capAccountInfo, isLoading: loadingCapAccountInfo } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  const renderTotalValue = () => {
    if (loadingUserFunds || loadingCapAccountInfo) return '0.00';

    if (!userFunds && !capAccountInfo?.available) return '0.00';

    if (!userFunds && capAccountInfo?.available) {
      return formatCurrencyFn(
        'BRL',
        Number.parseFloat(
          String(Number(capAccountInfo?.available) / 100)
        ).toFixed(2)
      );
    }

    if (!capAccountInfo?.available && userFunds) {
      return formatCurrencyFn('BRL', String(capAccountInfo?.available));
    }

    return formatCurrencyFn(
      'BRL',
      Number.parseFloat(
        String(userFunds + Number(capAccountInfo?.available) / 100)
      ).toFixed(2)
    );
  };

  const handleLogout = () => {
    clearStorage(queryClient);
    navigate('/auth');
  };

  const { data: userData } = useGetPartner();

  const renderUserName = () => {
    if (userData?.data.socialName) return userData?.data.socialName;

    if (userData?.data.registerName) return userData?.data.registerName;

    return 'Usuário';
  };

  const companyName = capAccountInfo?.name;

  const handleAlternateAccount = () => {
    localStorage.removeItem('@CapBiz:selectedUserOrganization');
    navigate(APP_ROUTES.SELECT_ORGANIZATIONS);
  };

  return (
    <>
      <Style.Container>
        <div className="logo-container">
          <SymbolLogo />

          <div className="total-currency">
            <Text.Smaller marginRight={15} color={colors.grayRegular}>
              Saldo Total:
            </Text.Smaller>
            <Text.Heading4Bold>{renderTotalValue()}</Text.Heading4Bold>
          </div>
        </div>

        <div className="right-container">
          <Style.NotifcationIconContainer
            onClick={handleOpenNotifications}
            hasUnreadNotification={hasUnreadNotifcations}
          >
            <BellIcon />
          </Style.NotifcationIconContainer>

          <a className="hamburger-menu" onClick={() => setIsOpen(!isOpen)}>
            <span />
            <span />
            <span />
          </a>
        </div>
      </Style.Container>

      <div id="navbar">
        <MobileMenu setIsOpen={setIsOpen} isOpen={isOpen} />
      </div>
    </>
  );
};

export default Navbar;
