import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerAnim = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

export const Container = styled.main`
  display: flex;
  width: 100%;
  height: calc(100% - 62px);
  justify-content: center;
  align-items: center;

  .go-back {
    cursor: pointer;
    width: 30px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;

    .orgs-grid-container {
      width: 80%;
      gap: 15px;
      align-items: flex-start;
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(auto-fit, minmax(210px, auto));
      grid-template-rows: repeat(1, 1fr);
    }

    .container-logo-back {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .go-back {
      position: absolute;
      margin-right: 150px;
      margin-bottom: 18px;

      :hover {
        cursor: pointer;
      }
    }
  }

  .content-pin-step {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    max-width: 600px;
  }

  .container-account-actions {
    display: flex;
    margin-top: 48px;
    margin-bottom: 48px;
    gap: 32px;

    .disable {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .action-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
    }
  }
`;

export const ContainerLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 77px);
`;
