import React, { useEffect, useState } from 'react';
import * as Style from './DeletePixKey.styles';
import { SetCurrentStepProps, StepParamsProps } from './DeletePixKey.types';
import ModalMultiStep from '~components/ModalMultiStep';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import ConfirmPin from './ModalSteps/2.ConfirmPin';
import CreatePixStatus from './ModalSteps/3.deletePixStatus';
import AreYouSure from './ModalSteps/1.AreYouSure';

function DeletePixKey() {
  const {
    openModalMultiStep,
    setOpenModalMultiStep,
    params: modalParams,
  } = useActionsOnModalMultiStep();
  const [currentStep, setCurrentStep] =
    useState<SetCurrentStepProps>('areYouSure');
  const [stepParams, setStepParams] = useState<StepParamsProps>({});

  useEffect(() => {
    if (openModalMultiStep) {
      setStepParams((prev) => ({ ...prev, ...modalParams }));
    }
  }, [modalParams, openModalMultiStep]);

  const setStepsAndParams = (step: SetCurrentStepProps, params?: object) => {
    if (params) {
      setStepParams((prev) => ({ ...prev, ...params } as StepParamsProps));
    }
    setCurrentStep(step);
  };

  const modalStepsAndDetails = {
    areYouSure: {
      component: (
        <AreYouSure
          key="areYouSure"
          setCurrentStep={setStepsAndParams}
          pixKey={String(stepParams.pixKey)}
        />
      ),
      progressValue: 1,
    },
    confirmPin: {
      component: (
        <ConfirmPin
          key="confirmPin"
          setCurrentStep={setStepsAndParams}
          pixKey={String(stepParams.pixKey)}
        />
      ),
      progressValue: 50,
    },
    deletePixKeyStatus: {
      component: (
        <CreatePixStatus
          statusOfCreation={stepParams?.statusOfCreation}
          key="deletePixKeyStatus"
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 100,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  // CLEAR STEP ON MODAL CLOSE
  useEffect(() => {
    if (!openModalMultiStep) {
      setCurrentStep('areYouSure');
    }
  }, [openModalMultiStep]);

  return (
    <Style.CreatePixKeyContainer>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={currentStepInfos.progressValue}
      >
        {currentStepInfos.component}
      </ModalMultiStep>
    </Style.CreatePixKeyContainer>
  );
}

export default DeletePixKey;
