import { SVGProps } from 'react';

const TrashSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width || '16'}
    height={props?.height || '17'}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 2.77979H7H9H9.75V4.27979H9H7H6.25V2.77979ZM4.25 6.27987H3V4.77987H4.25H5.75H10.25H11.75H13V6.27987H11.75V7.67426V7.70622L11.7473 7.73807L11.3254 12.6788C11.2481 13.5842 10.4905 14.2799 9.58175 14.2799H6.41825C5.50947 14.2799 4.75191 13.5843 4.6746 12.6788L4.25272 7.73807L4.25 7.70622V7.67426V6.27987ZM5.75 6.27987V7.6423L6.16916 12.5511C6.1802 12.6805 6.28842 12.7799 6.41825 12.7799H9.58175C9.71158 12.7799 9.8198 12.6805 9.83084 12.5511L10.25 7.6423V6.27987H5.75Z"
      fill={props?.fill || '#C85B69'}
    />
  </svg>
);

export default TrashSVG;
