import * as React from 'react';
import { SVGProps } from 'react';

const BluePapersSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={36}
    height={36}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.928 32.232A1.768 1.768 0 0 0 10.697 34H30.303a1.769 1.769 0 0 0 1.768-1.768V20.525c0-.737-.575-1.335-1.285-1.335h-8.358v-6.564c0-.737-.575-1.335-1.285-1.335H10.214c-.71 0-1.286.598-1.286 1.335v19.606ZM12 15.452a1 1 0 1 0 0 2h7.158a1 1 0 1 0 0-2H12Zm0 5.161a1 1 0 1 0 0 2h7.158a1 1 0 1 0 0-2H12Zm11.631 2.065a1 1 0 1 0 0 2H29a1 1 0 1 0 0-2H23.63ZM12 25.774a1 1 0 1 0 0 2h7.158a1 1 0 1 0 0-2H12ZM23.63 27.84a1 1 0 1 0 0 2H29a1 1 0 1 0 0-2H23.63Z"
      fill="#026FF4"
    />
    <path
      d="M30 17.668v-1.765a1 1 0 0 0-1-1h-3.306a1 1 0 0 1-1-1V3a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v23.806a1 1 0 0 0 1 1h1.6"
      stroke="#000"
      strokeWidth={2}
    />
  </svg>
);

export default BluePapersSVG;
