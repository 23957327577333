import * as Text from '~styles/text';
import * as Style from '../../../TransferBatch.styles';
import { ChangeEvent, useEffect, useState } from 'react';
import { getSelectedUserOrganization } from '~helpers/device';
import { LoadingSpinner } from '~components/index';
import colors from '~styles/colors';
import { useResolvePixKey } from '~reactQuery/mutations/pix/useResolvePixKey';
import {
  validaCNPJ,
  validateCPF,
  validateEmail,
  validatePhoneNumber,
  validatePixRandomKey,
} from '~validations/personalValidation';
import { Else, If, Then, When } from 'react-if';
import WarningIcon from '~assets/svg/warning';
import { SearchBeneficiaryProps } from '../../../TransferBatch.types';

const SearchBeneficiary = ({
  setBeneficiaryPerPix,
}: SearchBeneficiaryProps) => {
  const [searchPerson, setSearchPerson] = useState('');
  const userOrganization = getSelectedUserOrganization();
  const [errorOnSearchIdentify, setErrorOnSearchIdentify] = useState('');
  const { mutateAsync: resolvePixKey, isLoading: loadingResolvePixKey } =
    useResolvePixKey();

  const searchIdentify = async (value: string) => {
    setErrorOnSearchIdentify('');
    setBeneficiaryPerPix(null);

    const isValidToContinue =
      validaCNPJ(value) ||
      validateCPF(value) ||
      validatePixRandomKey(value) ||
      validateEmail(value) ||
      validatePhoneNumber(value);

    if (!value || !isValidToContinue) {
      return;
    }

    try {
      setErrorOnSearchIdentify('');
      const resolve = await resolvePixKey({
        key: value.trim(),
        userOrganizationId: Number(userOrganization),
        documentNumber: String(value),
      });

      const hasDocumentNumber = validateCPF(value)
        ? {
            documentNumber: value,
          }
        : {};

      const objectPix = {
        ...resolve,
        ...resolve?.key,
        ...hasDocumentNumber,
        name: resolve?.key?.holderName,
        keyValue: value,
      };

      const { key, ...newObject } = objectPix;

      setBeneficiaryPerPix(newObject);
    } catch (error) {
      const isKeyValid = validateCPF(value) || validatePhoneNumber(value);

      if (isKeyValid) {
        setBeneficiaryPerPix({
          keyValue: value,
        });
        return;
      }

      setErrorOnSearchIdentify(
        'Verifique se as informações inseridas estão corretas e tente novamente.'
      );
    }
  };

  const onChangeInputSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchPerson(event.target.value);

    searchIdentify(event.target.value || '');
  };

  const isLoading = loadingResolvePixKey;

  useEffect(() => {
    setBeneficiaryPerPix(null);
  }, []);

  return (
    <div className="content-add-beneficiary">
      <Text.Heading5Bold>Informações dos beneficiários</Text.Heading5Bold>

      <input
        className="input-search-beneficiary"
        value={searchPerson}
        placeholder="Chave Pix"
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChangeInputSearch(event)
        }
      />

      <If condition={isLoading}>
        <Then>
          <div className="loading-spinner">
            <LoadingSpinner
              color={colors.primary}
              size={30}
              strokeSize={12.5}
            />
          </div>
        </Then>

        <Else>
          <When condition={errorOnSearchIdentify}>
            <Style.ContainerError>
              <WarningIcon />
              <Text.Heading5 color={colors.danger} marginLeft={5}>
                {errorOnSearchIdentify}
              </Text.Heading5>
            </Style.ContainerError>
          </When>
        </Else>
      </If>
    </div>
  );
};

export default SearchBeneficiary;
