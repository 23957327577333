import * as React from 'react';
import { SVGProps } from 'react';

const SupportSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 10.6132V14.8258C5 15.7817 5.77489 16.5566 6.73077 16.5566V16.5566C7.68665 16.5566 8.46154 15.7817 8.46154 14.8258V12.6132C8.46154 11.5086 7.56611 10.6132 6.46154 10.6132H5ZM5 10.6132V9C5 5.68629 7.68629 3 11 3H14C17.3137 3 20 5.68629 20 9V10.6132M20 10.6132V14.8066C20 15.7731 19.2165 16.5566 18.25 16.5566V16.5566C17.2835 16.5566 16.5 15.7731 16.5 14.8066V12.6132C16.5 11.5086 17.3954 10.6132 18.5 10.6132H20ZM20 18V18.5C20 20.1569 18.6569 21.5 17 21.5H10.7692V20.5H13.5"
      stroke={props.stroke || '#272937'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SupportSVG;
