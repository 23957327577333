// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Fingerprint from 'fingerprintjs';
import { QueryClient } from 'react-query';
import UAParser from 'ua-parser-js';

// DEVICE INFOS
export const fingerprint = () =>
  String(new Fingerprint({ canvas: true }).get());

export const getLanguage = () => {
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language;
};

export const browserInfo = () => {
  const parser = new UAParser();
  return parser.getResult();
};

// TOKENS ACTIONS
export const setUserInfos = (token?: string, userId?: string) => {
  if (token && token !== 'null' && token !== 'undefined') {
    localStorage.setItem('@CapBiz:token', token);
  }

  if (userId && userId !== 'null' && userId !== 'undefined') {
    localStorage.setItem('@CapBiz:user-id', userId);
  }
};

export const setSelectedUserOrganization = (organization: string) => {
  if (organization) {
    localStorage.setItem(
      '@CapBiz:selectedUserOrganization',
      String(organization)
    );
  }
};

export const setSelectedOrg = (organization: string) => {
  if (organization) {
    localStorage.setItem('@CapBiz:selectedOrg', String(organization));
  }
};

export const setIsNewOrg = (bool: boolean) => {
  localStorage.setItem('@CapBiz:isNewOrg', String(bool));
};

export const setHideCpfCnpj = (bool: boolean) => {
  localStorage.setItem('@CapBiz:hideCpfCnpj', String(bool));
};

export const setDisabledReportIncome = (bool: boolean) => {
  localStorage.setItem('@CapBiz:isReportIncomeDisabled', String(bool));
};

export const getToken = () => {
  return localStorage.getItem('@CapBiz:token');
};

export const getUserId = () => {
  return localStorage.getItem('@CapBiz:user-id');
};

export const getSelectedUserOrganization = () => {
  return localStorage.getItem('@CapBiz:selectedUserOrganization');
};

export const getSelectedOrg = () => {
  return localStorage.getItem('@CapBiz:selectedOrg');
};

export const getIsNewOrg = () => {
  return localStorage.getItem('@CapBiz:isNewOrg');
};

export const getHideCpfCnpj = () => {
  return localStorage.getItem('@CapBiz:hideCpfCnpj');
};

export const getSettingsId = () => {
  return localStorage.getItem('@CapBiz:settings-id');
};

export const getDisabledReportIncome = () => {
  return localStorage.getItem('@CapBiz:isReportIncomeDisabled');
};

export const clearStorage = (queryClient?: QueryClient) => {
  localStorage.clear();

  if (queryClient) {
    queryClient.clear();
  }
};
