import * as Text from '~styles/text';

import { Else, If, Then, When } from 'react-if';
import React, { useEffect, useMemo, useState } from 'react';
import { validaCNPJ, validateCPF } from '~validations/personalValidation';
import * as StyleSelectKey from '~pages/Dashboard/TransferBatch/TransferBatch.styles';

import Arrow from '~assets/images/icons/kyc/status/arrow-right.svg';
import { BigInput } from '~components/Inputs';
import { Contacts } from '~reactQuery/types/contacts.types';
import DialogPopUp from '~components/DialogPopUp';
import { EmptyContacts } from '~components/Empty';
import LoadingSpinner from '~components/LoadingSpinner';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import OptionSelect from '~components/OptionSelect';
import { PixModalStepsProps } from '../Transfer.types';
import UserPic from '~components/UserPic';
import colors from '~styles/colors';
import { getSelectedUserOrganization } from '~helpers/device';
import { capitalize } from '~helpers/format/capitalize';
import { onlyLetters } from '~validations/stringsValidate';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { useFormContext } from 'react-hook-form';
import { useGetContacts } from '~reactQuery/queries/contacts/useGetContacts';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { useResolvePixKey } from '~reactQuery/mutations/pix/useResolvePixKey';
import SelectKeyType from '~pages/Dashboard/TransferBatch/ModalSteps/Components/AddBeneficiary/2.SelectKeyType';
import AlertModal from '~components/AlertModal';

import { BeneficiaryPerPix } from '~pages/Dashboard/TransferBatch/TransferBatch.types';
import { validatePixNumber } from '~helpers/validate/genericValidations';

function PixTabContent(props: PixModalStepsProps) {
  const { setPixStep: setCurrentStep } = props;
  const { setValue } = useFormContext();
  const { data: partnerInfos } = useGetPartner();
  const userOrganization = getSelectedUserOrganization();

  const [searchValue, setSearchValue] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogChoose, setDialogChoose] = useState(true);
  const [acceptedTypeCPF, setAcceptedTypeCPF] = useState(false);
  const [inputError, setInputError] = useState('');
  const [page, setPage] = useState(1);

  const [valuekeyType, setValuekeyType] = useState('');
  const [dialogChoosekeyType, setDialogChoosekeyType] = useState(false);
  const [beneficiaryPerPix, setBeneficiaryPerPix] =
    useState<BeneficiaryPerPix | null>(null);

  const userDocumentNumber = partnerInfos?.data.documentNumber;

  const { mutateAsync: resolvePixKey, isLoading: loadingResolvePixKey } =
    useResolvePixKey();
  const { data: contactsList, isLoading: loadingContacts } = useGetContacts({
    userOrganizationId: Number(userOrganization),
    page,
  });

  async function searchIdentify(search: string, keyType?: string) {
    if (!search) return;
    setInputError('');

    if (onlyLetters(searchValue, true)) {
      setValue('manualDetails.name', search);
      setValue('manualTransfer', true);

      setCurrentStep('manualTransfer');
      return;
    }

    if (validatePixNumber(search).length > 0 && !keyType && !acceptedTypeCPF) {
      const result = validatePixNumber(search);

      if (result[0] === 'cpf' && result[1] === 'phone') {
        setDialogChoosekeyType(true);
        setValuekeyType(search);

        return;
      }
    }

    if (acceptedTypeCPF) {
      setValue('manualDetails.docNum', onlyNumbers(search));
      setValue('manualTransfer', true);

      setCurrentStep('manualTransfer');
      return;
    }

    try {
      let newValue = search;

      if (keyType === 'phone') {
        newValue = `55${search}`;
      }

      const resolve = await resolvePixKey({
        key: validaCNPJ(search.trim())
          ? onlyNumbers(newValue.trim())
          : newValue.trim(),
        userOrganizationId: Number(userOrganization),
        documentNumber: String(userDocumentNumber),
      });

      if (keyType === 'phone') {
        setValue('keyResult', { resolve, keyValue: `55${search.trim()}` });
      } else {
        setValue('keyResult', { resolve, keyValue: search.trim() });
      }

      setCurrentStep('pixMethod');
    } catch (error) {
      if (validaCNPJ(search)) {
        setValue('manualDetails.docNum', onlyNumbers(search));
        setValue('manualTransfer', true);

        setCurrentStep('manualTransfer');
      } else if (validateCPF(search)) {
        setDialogOpen(true);
      } else {
        setInputError('Chave Pix não encontrada!');
      }
    }
  }

  function goNextWithContact(contact: Contacts) {
    if (contact?.name) {
      setValue('contact', contact);
      setValue('cpfOrCnpj', contact?.documentNumber);

      setCurrentStep('pixMethod');
    }
  }

  const contactsListFiltered = useMemo(() => {
    if (contactsList && searchValue !== '' && onlyLetters(searchValue, true)) {
      return contactsList?.pages?.flat()?.[0]?.contacts?.filter((value) => {
        return value.name
          .toLowerCase()
          .includes(searchValue?.toLowerCase() || '');
      });
    }

    return contactsList?.pages?.flat()?.[0]?.contacts;
  }, [contactsList, searchValue]);

  function changeInputText(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setSearchValue(e.target.value);
    setInputError('');
  }

  const nextPage = () => {
    setPage((prev) => {
      if (contactsList && contactsList?.totalPages === prev) return prev;
      return prev + 1;
    });
  };
  const prevPage = () => {
    setPage((prev) => {
      if (prev === 1) return 1;
      return prev - 1;
    });
  };

  const hasMoreThanOnePage = contactsList && contactsList?.totalPages > 1;

  useEffect(() => {
    if (acceptedTypeCPF) {
      searchIdentify(searchValue);
    }
  }, [acceptedTypeCPF]);

  return (
    <>
      <Text.Heading5 marginTop={18} marginBottom={25}>
        Digite um Nome, Chave Pix, CPF/CNPJ
      </Text.Heading5>

      <BigInput maxLength={40} onChange={changeInputText} error={inputError} />

      <Text.Heading3 marginTop={25} marginBottom={15}>
        Contatos & Favorecidos
      </Text.Heading3>
      <If condition={loadingContacts}>
        <Then>
          <div className="center-loading pt-40">
            <LoadingSpinner color={colors.primary} size={60} strokeSize={25} />
          </div>
        </Then>

        <Else>
          <If
            condition={
              contactsListFiltered && contactsListFiltered?.length >= 1
            }
          >
            <Then>
              <div className="display-contacts">
                {contactsListFiltered?.map((contact) => (
                  <OptionSelect
                    key={contact?.id}
                    enableAnimation
                    borderTop
                    arrowIcon
                    icon={<UserPic name={contact?.name} />}
                    title={capitalize(contact?.name)}
                    onClick={() => goNextWithContact(contact)}
                  />
                ))}
              </div>
              <When condition={hasMoreThanOnePage}>
                <div className="paginator">
                  <div className="flip-arrow">
                    <Arrow onClick={prevPage} />
                  </div>
                  <div className="page-index">
                    <Text.Smaller>{page}</Text.Smaller>
                  </div>
                  <div className="arrow">
                    <Arrow onClick={nextPage} />
                  </div>
                </div>
              </When>
            </Then>

            <Else>
              <div className="empty-contacts">
                <EmptyContacts />
              </div>
            </Else>
          </If>
        </Else>
      </If>

      <DialogPopUp
        openDialog={dialogOpen}
        title="Selecionar Tipo de Chave"
        description="Essa chave não foi localizada, caso deseje continuar para a transferência manual confirme se é um CPF."
        setModal={setDialogOpen}
        firstButtonText="Sair"
        firstButtonAction={() => setDialogOpen(false)}
        secondButtonText="Usar como CPF"
        secondButtonAction={() => {
          setAcceptedTypeCPF(true);
          setDialogOpen(false);
        }}
      />

      <When condition={dialogChoosekeyType}>
        <AlertModal
          setOpenAlert={() => {
            setDialogChoosekeyType(false);
            setBeneficiaryPerPix(null);
          }}
          title={`A chave que você está inserindo é um CPF ${'\n'} ou um celular?`}
          primaryButtonText="Voltar"
          primaryButtonAction={() => {
            setBeneficiaryPerPix({});
            setDialogChoosekeyType(false);
          }}
          secondaryButtonText="Confirmar"
          secondaryButtonAction={() => {
            searchIdentify(
              valuekeyType,
              beneficiaryPerPix?.keyType.toLowerCase()
            );
            setDialogChoosekeyType(false);
          }}
          secondaryButtonColor="secondary"
          secondaryButtonWidth="137px"
          bodyComponent={
            <StyleSelectKey.Container>
              <SelectKeyType
                beneficiaryPerPix={beneficiaryPerPix}
                setBeneficiaryPerPix={setBeneficiaryPerPix}
                key="selectKeyType"
              />
            </StyleSelectKey.Container>
          }
          noBorderTop
          paddingBottomTitle="0"
          paddingVertical={0.1}
          marginRight={50}
          disableSecondaryButton={!beneficiaryPerPix?.keyType}
        />
      </When>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => searchIdentify(searchValue)}
        disableSingleButton={searchValue.length < 5 || inputError.length > 0}
        singleButtonLoading={loadingResolvePixKey || loadingContacts}
      />
    </>
  );
}

export default PixTabContent;
