import * as React from 'react';

import { SVGProps } from 'react';

const AdminKingSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 28}
    height={props.height || 28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={14} cy={14} r={12} fill="#fff" />
    <circle
      cx={14}
      cy={14}
      r={13}
      stroke={props.stroke || '#000'}
      strokeOpacity={0.05}
      strokeWidth={props.strokeWidth || 2}
    />
    <path d="M8 10v8h12v-8l-3 3-3-3-3 3-3-3Z" fill="#026FF4" />
  </svg>
);

export default AdminKingSVG;
