import * as Text from '~styles/text';

import { FooterButton, UserPic } from '~components';
import {
  parsePartnerList,
  parsePartnersSchema,
  parserBusiness,
} from '~helpers/format/parses';
import { useMemo, useState } from 'react';

import AddUser from '~assets/images/icons/kyc/add-user.svg';
import BlackDocument from '~assets/images/icons/kyc/document-black.svg';
import DotsFloatingMenu from '~assets/images/icons/kyc/dots-vertical.svg';
import EditIcon from '~assets/images/icons/floating-menu/edit-black.svg';
import ItemList from '~components/ItemList';
import ItensMenu from '~components/ItensMenu';
import PlusBlue from '~assets/images/icons/kyc/plus-blue.svg';
import RedDelete from '~assets/images/icons/floating-menu/delete-red.svg';
import RegisterPartnersIcon from '~assets/images/icons/kyc/register-partner.svg';
import { RegisterPartnersProps } from '../Kyc.types';
import UserBlue from '~assets/images/icons/kyc/user-blue.svg';
import colors from '~styles/colors';
import { getBase64 } from '~helpers/format/file';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { parseError } from '~reactQuery/index';
import { useCreateBusinessPartnerDataMutation } from '~reactQuery/mutations/business/useCreateBusinessPartnerData';
import { useCreateBusinessPartnerIncomesMutation } from '~reactQuery/mutations/business/useCreateBusinessPartnerIncomes';
import { useCreateBusinessPartnerMutation } from '~reactQuery/mutations/business/useCreateBusinessPartner';
import { useCreatePartnerMutation } from '~reactQuery/mutations/kyc/useCreatePartner';
import { useDeleteBusinessPartnerMutation } from '~reactQuery/mutations/business/useDeleteBusinessPartner';
import { useDeletePartnerMutation } from '~reactQuery/mutations/kyc/useDeletePartnerData';
import { useFormContext } from 'react-hook-form';
import { usePartnersList } from '../store';
import { usePutBusinessMutation } from '~reactQuery/mutations/kyc/usePutBusiness';
import { usePutBusinessPartnerDataMutation } from '~reactQuery/mutations/business/usePutBusinessPartnerData';
import { usePutIncomesMutation } from '~reactQuery/mutations/business/usePutIncomes';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useUpdatePartnerDataMutation } from '~reactQuery/mutations/business/useUpdatePartnerData';
import { v4 } from 'uuid';
import { FloatingMenu } from '~components/FloatingMenu';

export const RegisterPartners = ({
  goToStep,
  setPartnerModal,
  gotToModalStep,
  stepParams,
  processId,
  partnersMethods,
  kycOwnerData,
  kycProcessData,
  openModal,
}: RegisterPartnersProps) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const { setField, field } = usePartnersList();
  const queryClient = useQueryClient();

  const {
    formState: { errors },
    getValues: getPartnersValues,
    clearErrors,
    setValue,
    unregister,
  } = partnersMethods;

  const [loading, setLoading] = useState(false);
  const [openModalMenu, setOpenModalMenu] = useState(false);
  const [itemToEdit, setItemToEdit] = useState();
  const [modalMenuPositions, setModalMenuPositions] = useState({
    posX: 0,
    posY: 0,
  });

  const numberOfPartners =
    getValues('numberOfPartners') > 0 ? getValues('numberOfPartners') + 1 : 1;
  const ownerDocNum = kycProcessData?.user?.documentNumber;

  // CREATE AND UPDATE PARTNERS LIST, ONLY PERSONAL PARTNERS
  const { mutateAsync: deletePartner } = useDeletePartnerMutation();
  const { mutateAsync: mutateAsyncCreate } = useCreatePartnerMutation();
  const { mutateAsync: mutateAsyncUpdate } = useUpdatePartnerDataMutation();
  const { mutateAsync: delBizPartner } = useDeleteBusinessPartnerMutation();
  const { mutateAsync: createBizPartner } = useCreateBusinessPartnerMutation();
  const { mutateAsync: putBizPartner } = usePutBusinessMutation();
  // DOCS
  const { mutateAsync: cBizPartData } = useCreateBusinessPartnerDataMutation();
  const { mutateAsync: putBizPartData } = usePutBusinessPartnerDataMutation();
  const { mutateAsync: cIncome } = useCreateBusinessPartnerIncomesMutation();
  const { mutateAsync: putIncomes } = usePutIncomesMutation();

  // PARSE THE STATUS AND PARTNERS LIST

  const partnersData = getPartnersValues('allPartners');

  const partnerParsedDataList = useMemo(() => {
    let partnersTypeBusinessParsed = [] as any;

    if (partnersData?.[1]?.list?.length > 0) {
      partnersTypeBusinessParsed = partnersData?.[1]?.list
        ?.filter((i: any) => !!i)
        ?.map((partner: any) => ({
          ...partner,
          partnerType: 'business',
          partnerName: partner?.businessName,
          partnerData: partner?.partnerData?.filter((i: any) => !!i),
        }));
    }
    if (partnersData?.[0]?.list?.length > 0) {
      const formattedDate = partnersData?.[0]?.list
        ?.filter((i: any) => !!i)
        ?.map((partner: any) => ({
          ...partner,

          partnerName: partner?.registerName,
          status: partner?.status,
        }));

      return [...formattedDate, ...partnersTypeBusinessParsed];
    }
    return [];
  }, [openModal, openModalMenu, partnersData]);

  const uploadPartners = async () => {
    setLoading(true);

    const mainBusinessId = kycProcessData?.business?.[0]?.id;

    const partnerPersonalParsedList = await parsePartnerList(
      partnersData?.[0]?.list
    );

    const bizPartnerParsed = partnersData?.[1]?.list?.map((partner: any) =>
      parserBusiness(partner)
    );

    const personalArrayPromises = [] as any;

    partnerPersonalParsedList?.forEach(async (partner: any) => {
      const fields = Object?.keys(partner)?.filter((key) => {
        return partner[key] === null;
      });

      fields.push('declaredAnnualBilling');

      const newPartnerObject = partner;

      for (const value of fields) {
        delete newPartnerObject[value];
      }

      await personalArrayPromises.push(
        (async () => {
          try {
            if (!partner?.partnerId && partner?.isSaved) {
              delete partner?.partnerId;
              delete partner?.isSaved;

              await mutateAsyncCreate({
                ...partner,
                processId,
              });
            } else if (partner?.partnerId) {
              delete partner?.isSaved;

              await mutateAsyncUpdate({
                ...newPartnerObject,
              });
            }
          } catch (error: any) {
            if (parseError(error) === 'PARTNER_ALREADY_EXISTS') {
              setLoading(false);
            }
          }
        })()
      );
    });

    const bizArrayPromises = [] as any;

    bizPartnerParsed?.forEach(async (partner: any) => {
      await bizArrayPromises.push(
        (async () => {
          const businessId = partner?.id;
          const businessData = partner?.businessData;
          const partnerData = partner?.partnerData;

          delete partner?.id;
          delete partner?.status;
          delete partner?.partnerData;
          delete partner?.uniqueFieldId;
          delete partner?.isSaved;
          delete partner?.fields;
          delete partner?.businessData;
          delete partner?.docStatus;

          let b64Files = {} as any;

          if (businessData) {
            const parseB64Files = Object.keys(businessData).map(async (key) => {
              const allowedKeys = [
                'fileCNPJ',
                'fileQSA',
                'incomes',
                'socialStatute',
                'socialContract',
                'lastElection',
                'lastContractChange',
              ];

              if (
                businessData[key] &&
                typeof businessData[key] !== 'string' &&
                allowedKeys.includes(key) &&
                !businessData[key]?.[0]?.id
              ) {
                return {
                  [key]: await getBase64(businessData[key][0]),
                };
              }
            });

            await Promise.all(parseB64Files).then(
              (e) =>
                (b64Files = {
                  ...b64Files,
                  ...e,
                })
            );
          }

          let businessDateValues = {} as any;

          Object.keys(b64Files).forEach((key) => {
            businessDateValues = {
              ...businessDateValues,
              ...b64Files[key],
            };
          });

          const incomes = [businessDateValues?.incomes] as [string];

          delete businessDateValues?.incomes;

          let createBizResp = undefined as any;

          try {
            if (businessId) {
              await putBizPartner({
                ...partner,
                businessId,
              });

              if (!businessData?.id) {
                let createPartDataResp = {} as any;

                if (
                  businessDateValues?.fileCNPJ &&
                  businessDateValues?.fileQSA
                ) {
                  createPartDataResp = await cBizPartData({
                    businessId: Number(businessId),
                    ...businessDateValues,
                  });
                }

                if (incomes && createPartDataResp?.data?.id) {
                  await cIncome({
                    businessDataId: Number(createPartDataResp?.data?.id),
                    incomes,
                  });
                }
              } else {
                await putBizPartData({
                  businessDataId: Number(businessData?.id),
                  ...businessDateValues,
                });

                if (incomes?.[0]?.length) {
                  await putIncomes({
                    businessDataId: Number(businessData?.id),
                    incomes,
                  });
                }
              }
            } else {
              createBizResp = await createBizPartner({
                ...partner,
                businessId: mainBusinessId,
                processId,
              });

              if (Object.keys(businessData).length > 0) {
                let createPartDataResp = {} as any;

                if (
                  businessDateValues?.fileCNPJ &&
                  businessDateValues?.fileQSA
                ) {
                  createPartDataResp = await cBizPartData({
                    businessId: Number(createBizResp.data?.id),
                    ...businessDateValues,
                  });
                }

                if (incomes && createPartDataResp?.data?.id) {
                  await cIncome({
                    businessDataId: Number(createPartDataResp?.data?.id),
                    incomes,
                  });
                }
              }
            }
          } catch (error: any) {
            // console.log(`catcher error: ${error}`);
            if (parseError(error) === 'PARTNER_ALREADY_EXISTS') {
              setLoading(false);
              return;
            }
          }

          if (partnerData?.length > 0) {
            const partnerPersonalParsedList = await parsePartnerList(
              partnerData
            );

            await partnerPersonalParsedList?.forEach(async (partner: any) => {
              const partnerId = partner?.partnerId;

              delete partner?.partnerId;
              delete partner?.isSaved;
              delete partner?.partnerOfPartner;
              delete partner?.status;
              delete partner?.partnerType;
              delete partner?.uniqueFieldId;

              try {
                if (!partnerId) {
                  await mutateAsyncCreate({
                    ...partner,
                    businessId: createBizResp?.data?.id || businessId,
                    processId,
                  });
                } else if (partnerId) {
                  await mutateAsyncUpdate({
                    ...partner,
                    partnerId,
                  });
                }
              } catch (error: any) {
                if (parseError(error) === 'PARTNER_ALREADY_EXISTS') {
                  setLoading(false);
                }
              }
            });
          }
        })()
      );
    });

    await Promise.allSettled([
      ...personalArrayPromises,
      ...bizArrayPromises,
    ]).then(() => {
      setTimeout(async () => {
        await queryClient.invalidateQueries();

        if (stepParams.statusScreen) {
          goToStep('status');
        } else {
          goToStep('finish');
        }

        gotToModalStep('start');
        setLoading(false);
      }, 2000);
    });
  };

  const removePartner = async (item: any) => {
    const ownerDataParsed = parsePartnersSchema(
      [kycOwnerData],
      'owner',
      ownerDocNum
    );

    const partnerIdToRemove = item?.partnerId;

    getThePositionToEdit(item);

    const isOwner =
      onlyNumbers(item?.documentNumber) === onlyNumbers(String(ownerDocNum)) &&
      !item.partnerOfPartner;
    if (field) {
      if (partnerIdToRemove) {
        try {
          await deletePartner({
            partnerId: Number(partnerIdToRemove),
          });

          if (!isOwner) {
            unregister(field);
            setValue(field, undefined);
          } else if (isOwner) {
            setValue(field, ownerDataParsed[0]);
          }
        } catch (error) {
          //   console.log('DeletePartner -> ', error);
        }
      } else if (item?.partnerType === 'business' && item?.businessId) {
        try {
          await delBizPartner({
            businessId: item?.businessId,
            soft: 0,
          });
          unregister(field);
          setValue(field, undefined);
        } catch (error) {
          //  console.log('Error on delBizPartner ->', error);
        }
      } else if (!isOwner) {
        unregister(field);
      } else if (isOwner) {
        setValue(field, ownerDataParsed[0]);
      }
      clearErrors(field);
    }

    setOpenModalMenu(false);
  };

  const editBusinessDocuments = (item: any) => {
    setOpenModalMenu(false);
    getThePositionToEdit(item);
    gotToModalStep('businessPartnerSendDocs');
    setPartnerModal(true);
  };

  const addPartner = (type: 'personal' | 'partnerOfPartner', item?: any) => {
    if (type === 'partnerOfPartner') {
      const bizIndex = allPartnerBizType.findIndex(
        (p) =>
          onlyNumbers(p.documentNumber) === onlyNumbers(item.documentNumber)
      );

      getThePositionToEdit(undefined, bizIndex);

      gotToModalStep('start');
    } else {
      getThePositionToEdit();
      gotToModalStep('start');
    }

    setPartnerModal(true);
  };

  const treeDotsAction = (e: any, index: number, item: any, type?: string) => {
    setItemToEdit(item);
    getThePositionToEdit(item);

    setModalMenuPositions({ posX: e?.pageX - 200, posY: e?.pageY + 10 });
    setOpenModalMenu(!openModalMenu);
  };

  const numOfPartnersPersonalAndBizFilled = partnerParsedDataList?.length;
  const emptyPartnersToFill =
    numOfPartnersPersonalAndBizFilled >= parseInt(numberOfPartners)
      ? 0
      : parseInt(numberOfPartners) - numOfPartnersPersonalAndBizFilled;

  const allPartnerBizType = partnerParsedDataList.filter(
    (p) => p?.businessType
  );
  const allPartnerPersonal = partnerParsedDataList.filter(
    (p) => !p?.partnerOfPartner && !p?.businessType
  );

  const getThePositionToEdit = (item?: any, bizIndex?: number) => {
    if (!item) {
      if (!isNaN(Number(bizIndex))) {
        const partnerOfPartnerIndex =
          allPartnerBizType?.[Number(bizIndex)]?.partnerData?.length;

        setField(
          `allPartners.[1].list.[${bizIndex}].partnerData.[${
            partnerOfPartnerIndex || 0
          }]`
        );
      } else {
        setField([
          `allPartners.[0].list.${allPartnerPersonal?.length}`,
          `allPartners.[1].list.${allPartnerBizType?.length}`,
        ]);
      }
    } else if (item.partnerOfPartner) {
      const array = [] as any;

      allPartnerBizType
        .map((bp) => bp.partnerData)
        .filter((e) => array.push(e?.map((p: any) => p?.uniqueFieldId)));

      const bizIndex = array.findIndex((bizPartner: string[]) => {
        return bizPartner?.includes(item?.uniqueFieldId);
      });

      const bizInCase = allPartnerBizType?.[bizIndex];

      const partnerOfPartnerIndex = bizInCase?.partnerData?.findIndex(
        (p: any) => {
          return (
            onlyNumbers(p.documentNumber) === onlyNumbers(item.documentNumber)
          );
        }
      );

      if (partnerOfPartnerIndex >= 0 && bizIndex >= 0) {
        setField(
          `allPartners.[1].list.[${bizIndex}].partnerData.[${partnerOfPartnerIndex}]`
        );
      }
    } else if (item.businessType) {
      const bizIndex = allPartnerBizType.findIndex(
        (p) =>
          onlyNumbers(p.documentNumber) === onlyNumbers(item.documentNumber)
      );
      setField(`allPartners.[1].list.${bizIndex}`);
    } else {
      const personalPartnerIndex = allPartnerPersonal.findIndex(
        (p) =>
          onlyNumbers(p.documentNumber) === onlyNumbers(item.documentNumber)
      );

      setField(`allPartners.[0].list.${personalPartnerIndex}`);
    }
  };

  const editPartner = (item: any, type: 'personal' | 'business') => {
    getThePositionToEdit(item);

    if (type === 'business') {
      gotToModalStep('registerPartnerTypeBusiness', true);
    } else {
      if (!item?.registerName) {
        gotToModalStep('partnerName');
        setOpenModalMenu(false);
        return setPartnerModal(true);
      }
      gotToModalStep('partnerForm');
    }
    setOpenModalMenu(false);
    setPartnerModal(true);
  };

  const parseSubtitle = (status: string) => {
    if (['FAILED', 'REPROVED', 'DENIED']?.includes(status)) {
      return { color: colors.danger, label: 'Não Aprovado' };
    }
    if (['WAITINGTOSEND']?.includes(status)) {
      return { color: colors.warning, label: 'Aguardando envio' };
    }
    if (['DONE', 'VERIFIED', 'APPROVED']?.includes(status)) {
      return { color: colors.success, label: 'Preenchido e Aprovado' };
    }
    if (['AWAITING', 'PENDING', 'UNDERCHECKING']?.includes(status)) {
      return { color: colors.warning, label: 'Preenchido' };
    }
    if (['UNFINISHED']?.includes(status)) {
      return { color: colors.danger, label: 'Campos Incompletos' };
    }
    return { color: '', label: '' };
  };

  const goBack = () => {
    goToStep(stepParams.statusScreen ? 'status' : 'sendDocs');
  };

  return (
    <>
      <div style={{ height: 48, width: 48 }}>
        <RegisterPartnersIcon style={{ borderRadius: 15 }} />
      </div>
      <Text.Heading1Medium marginTop={10} marginBottom={30}>
        {stepParams?.onlyShowOwner
          ? t('Dados dos Proprietário')
          : t('Dados dos Sócios')}
      </Text.Heading1Medium>
      <Text.Heading5 marginBottom={30} lineHeight={140}>
        {t(
          'Nesta etapa, será necessário preencher dados e anexar documentos de identificação (RG ou CNH) de cada sócio ou representante legal.'
        )}
      </Text.Heading5>

      {partnerParsedDataList?.map((item: any, index: any) => {
        if (stepParams?.onlyShowOwner && index !== 0) return null;
        if (stepParams?.hideOwner && index === 0) return null;

        return (
          <div key={`fullfil-menu-item${v4()}`}>
            <ItensMenu
              item={item}
              isOwner={item?.partnerType === 'owner'}
              expanded={item?.partnerType === 'business'}
              needReview={item?.partnerType === 'owner' && !item?.partnerId}
              status={item?.status}
              subtitleStatus={parseSubtitle(item?.status)?.label}
              rightIconAction={(e: any) => {
                treeDotsAction(e, index, item);
              }}
              containerMarginTop={10}
              title={String(item?.partnerName)}
              expandedDescription="Você adicionou uma empresa como sócia.  Finalize o cadastro se atentando as informações que estão com status pendente e após conclusão, aguardar a validação."
              expandedTooltipDescription="Tenha em mãos os principais documentos da empresa e informações sobre sócios para concluir esta etapa."
              rightIcon={<DotsFloatingMenu />}
              icon={
                <UserPic
                  width={45}
                  height={45}
                  borderRadius={15}
                  name={item?.partnerName}
                />
              }
            >
              <div className="mt-25" />
              <ItemList
                onClick={() => {
                  editPartner(item, 'business');
                }}
                listType="item"
                status={parseSubtitle(item?.status)?.label}
                statusColor={parseSubtitle(item?.status)?.color}
                arrowIcon
                icon={<BlackDocument />}
                label="Dados da Empresa"
              />
              <ItemList
                onClick={() => editBusinessDocuments(item)}
                listType="item"
                status={
                  parseSubtitle(item?.docStatus || item?.businessData?.status)
                    ?.label
                }
                statusColor={
                  parseSubtitle(item?.docStatus || item?.businessData?.status)
                    ?.color
                }
                arrowIcon
                icon={<BlackDocument />}
                label="Documentos"
              />
              <ItemList
                noHover
                listType="item"
                icon={<BlackDocument />}
                label="Dados e Documentos dos Sócios"
              />

              {item?.partnerData?.map((subItem: any, index: number) => {
                return (
                  <ItemList
                    key={`$partner-of-partner-${index}`}
                    onClick={(e: any) =>
                      treeDotsAction(
                        e,
                        index,
                        { ...subItem, businessId: item?.businessId },
                        'editSubPartner'
                      )
                    }
                    listType="sub-item"
                    status={parseSubtitle(subItem?.status)?.label}
                    statusColor={parseSubtitle(subItem?.status)?.color}
                    icon={<UserBlue />}
                    dotsOptions
                    label={subItem?.registerName}
                  />
                );
              })}

              {item?.numberOfPartners
                ? [
                    ...Array(
                      item?.numberOfPartners - (item?.partnerData?.length || 0)
                    ),
                  ]?.map((partner: any, index) => (
                    <ItemList
                      key={`list-partner-to-add${index}`}
                      onClick={() => {
                        addPartner('partnerOfPartner', item);
                      }}
                      listType="sub-item"
                      icon={<PlusBlue />}
                      arrowIcon
                      labelColor={colors.primary}
                      label={`Adicionar Sócio ${
                        1 + index + (item?.partnerData?.length || 0)
                      }/${item?.numberOfPartners} `}
                    />
                  ))
                : null}
            </ItensMenu>

            <FloatingMenu
              openModal={openModalMenu}
              setOpenModal={setOpenModalMenu}
              modalPosition={{
                left: modalMenuPositions.posX,
                top: modalMenuPositions.posY,
              }}
              itensList={[
                {
                  label: 'Editar Dados',
                  onClick: () => {
                    editPartner(itemToEdit, 'personal');
                  },
                  icon: <EditIcon />,
                  disabled: itemToEdit?.partnerType === 'business',
                },
                {
                  label:
                    itemToEdit?.partnerType !== 'owner'
                      ? 'Excluir Sócio'
                      : 'Restabelecer Dados',
                  onClick: () => {
                    removePartner(itemToEdit);
                  },
                  labelColor: colors.danger,
                  icon: <RedDelete />,
                  disabled: false,
                },
              ]}
            />
          </div>
        );
      })}
      {[...Array(emptyPartnersToFill)].map((item: any, index: any) => {
        if (stepParams?.onlyShowOwner) return null;

        return (
          <ItensMenu
            onClick={() => addPartner('personal')}
            key={`empty-partner-list${index}`}
            icon={<AddUser />}
            titleColor={colors.primary}
            containerMarginTop={10}
            arrowIcon
            title={`${t('Adicionar Sócio')} ${
              index + numOfPartnersPersonalAndBizFilled
            }/${numberOfPartners - 1}`}
          />
        );
      })}
      <FooterButton
        leftButtonContent="Voltar"
        genericButtonContent="Salvar Alterações"
        genericButtonType="primary"
        onClickLeftButton={goBack}
        onClickGenericButton={uploadPartners}
        loadingGenericButton={loading}
      />
    </>
  );
};
