import { colors } from '~styles';
import styled from 'styled-components';

export const PayBillsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .receipt-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 25px;
  }

  .content-container {
    padding: 0 35px;
  }

  .big-input {
    border-color: ${colors.grayRegular};
    border-width: 1px;
    background-color: ${colors.white};

    margin-top: 25px;
    height: 100%;

    &.pix-code {
      font-size: 17px !important;
    }
  }

  .double-button-one-container {
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
  }

  .payment-status {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .wallet-icon {
    transform: scale(0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pin-container {
    margin-top: 35px;

    .container-loading {
      width: 100%;
      padding-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .receipt-link {
    display: flex;
    align-items: center;
    margin-top: 25px;
    color: ${colors.newBlue};
    svg {
      margin-right: 13px;
    }
  }
`;
