import React, { SVGProps } from 'react';

const CriptoCoinIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '20'}
      height={props.height || '21'}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10.502C2 6.08367 5.58172 2.50195 10 2.50195C14.4183 2.50195 18 6.08367 18 10.502C18 14.9202 14.4183 18.502 10 18.502C5.58172 18.502 2 14.9202 2 10.502ZM10 0.501953C4.47715 0.501953 0 4.9791 0 10.502C0 16.0248 4.47715 20.502 10 20.502C15.5228 20.502 20 16.0248 20 10.502C20 4.9791 15.5228 0.501953 10 0.501953ZM11 4.50195V6.50195H14V8.50195H9.00001C7.89544 8.50195 7.00001 9.39738 7.00001 10.502C7.00001 11.6065 7.89544 12.502 9.00001 12.502H14V14.502H11V16.502H9V14.502C6.79087 14.5019 5.00001 12.7111 5.00001 10.502C5.00001 8.29282 6.79087 6.50196 9 6.50195V4.50195H11Z"
        fill={props.fill || '#272937'}
      />
    </svg>
  );
};

export default CriptoCoinIcon;
