import * as Style from './DetailsActivitySideBarModal.styles';
import * as Text from '~styles/text';
import colors, { alphabetPosition, randomColors } from '~styles/colors';
import dayjs from 'dayjs';
import BillBarCodeIcon from '~assets/svg/billBarCode';
import {
  GenericRow,
  RoundButtonIcon,
  LoadingSpinner,
  GenericButton,
} from '~components/index';
import { formatCurrencyFn } from '~helpers/format/currency';
import ListIcon from '~assets/svg/list';
import { useState } from 'react';
import MoreDetailsModal from './MoreDetails';
import ConvertedIcon from '~assets/svg/statement/convertIcon';
import TransferReceivedIcon from '~assets/svg/statement/transferReceived';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';
import { getSelectedUserOrganization } from '~helpers/device';
import { useCurrencyExchange } from '~reactQuery/queries/wallet/useCurrencyExchange';
import { Else, If, Then, When } from 'react-if';
import { formatDataShowMonth } from '~validations/stringsValidate';
import SpeechBubbleIcon from '~assets/svg/statement/speechBubble';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import ArrowLeftCurveIcon from '~assets/svg/arrowLeftCurve';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { usePermission } from '~helpers/permissionGate';

const DetailsActivitySideBarModal = () => {
  const { params } = useOpenModalSideBar();
  const { setSection, setOpenModalMultiStep, setParams } =
    useActionsOnModalMultiStep();
  const transactionDetails = params?.transactionDetails;
  const downloadSingle = params?.downloadSingle;
  const { granted: buttonsPixGranted } = usePermission('buttonsPix');
  const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState(false);

  const userOrganizationId = getSelectedUserOrganization();

  const { data: wallets, isLoading: loadingWallets } = useGetWallets({
    prefCurrency: 'BRL',
    flag: 'list',
    userOrganizationId: Number(userOrganizationId),
  });

  const getWalletFiltered = (walletAddress: string) => {
    if (walletAddress && wallets) {
      return wallets.filter((w) => w.walletAddress === walletAddress)?.[0];
    }

    return null;
  };

  const walletInfo = getWalletFiltered(
    transactionDetails?.data?.requisite?.walletAddress
  );

  function formatNumber(num: number): string {
    return (num / 100)?.toFixed(2);
  }

  const { data: exchangeResult, isLoading } = useCurrencyExchange(
    {
      amount: formatNumber(Number(transactionDetails?.data?.value)),
      fromCurrency: 'BRL',
      toCurrency: walletInfo?.currency?.isoCode || '',
      userOrganizationId: Number(userOrganizationId),
    },
    Boolean(transactionDetails?.data?.value)
  );

  const isLoadingSideBar = loadingWallets || isLoading;

  const transactionStatus: { [key: string]: string } = {
    DONE: 'Aprovado',
    ACTIVE: 'Aprovado',
    COMPLETED: 'Aprovado',

    IN_PROCESS: 'Em processamento',
    CREATED: 'Em processamento',
    APPROVED: 'Em processamento',

    UNDONE: 'Não Realizada',
    REPROVED: 'Reprovada',
    CANCELED: 'Cancelada',
    CANCELLED: 'Cancelada',
  };

  const handleTransactionTitle = () => {
    const transactionStatusForExchange: { [key: string]: string } = {
      DONE: 'Concluída',
      ACTIVE: 'Concluída',
      COMPLETED: 'Concluída',

      IN_PROCESS: 'Em processamento',
      CREATED: 'Em processamento',
      APPROVED: 'Em processamento',

      UNDONE: 'Não Realizada',
      REPROVED: 'Reprovada',
      CANCELED: 'Cancelada',
      CANCELLED: 'Cancelada',
    };

    if (
      transactionDetails?.data?.requisite?.type &&
      transactionDetails?.data?.requisite?.type?.includes('EXCHANGE')
    ) {
      return `Conversão ${
        transactionStatusForExchange[transactionDetails?.status]
      }`;
    }

    const translatorTitle: { [key: string]: string } = {
      'TRANSFER-PIX': 'Transferência',
      BILL: 'Pagamento de boleto',
      BILLSLIP: 'Depósito de boleto',
      TRANSFER:
        transactionDetails?.direction < 1
          ? 'Transferência Enviada'
          : 'Transferência Recebida',
      REFUND: 'Transferência Reembolsada',
    };

    return translatorTitle[transactionDetails?.method.toUpperCase()];
  };

  const handleTransactionIcon = () => {
    if (
      transactionDetails?.data?.requisite?.type &&
      transactionDetails?.data?.requisite?.type?.includes('EXCHANGE')
    ) {
      return (
        <RoundButtonIcon
          buttonType="default"
          buttonSize="icon24v2"
          noHover
          style={{ cursor: 'default' }}
        >
          <ConvertedIcon stroke={colors.grayMedium} />
        </RoundButtonIcon>
      );
    }

    const translator: { [key: string]: JSX.Element } = {
      'TRANSFER-PIX': (
        <RoundButtonIcon
          buttonType="default"
          buttonSize="icon24v2"
          noHover
          style={{ cursor: 'default' }}
        >
          <Text.Heading1Medium fontSize={1.7} color={colors.white}>
            {transactionDetails?.data?.requisite?.key?.holderName
              ?.slice(0, 1)
              .toUpperCase() || 'M'}
          </Text.Heading1Medium>
        </RoundButtonIcon>
      ),
      TRANSFER: (
        <RoundButtonIcon
          buttonType="default"
          buttonSize="icon24v2"
          noHover
          style={{ cursor: 'default' }}
        >
          <Text.Heading1Medium fontSize={1.7} color={colors.white}>
            {transactionDetails?.data?.requisite?.key?.holderName
              ?.slice(0, 1)
              .toUpperCase() ||
              transactionDetails?.data?.requisite?.holderName
                ?.slice(0, 1)
                .toUpperCase() ||
              'M'}
          </Text.Heading1Medium>
        </RoundButtonIcon>
      ),
      BILL: (
        <RoundButtonIcon
          buttonType="default"
          buttonSize="icon24v2"
          noHover
          style={{ cursor: 'default' }}
        >
          <BillBarCodeIcon />
        </RoundButtonIcon>
      ),
      BILLSLIP: (
        <RoundButtonIcon
          buttonType="default"
          buttonSize="icon24v2"
          noHover
          style={{ cursor: 'default' }}
        >
          <BillBarCodeIcon />
        </RoundButtonIcon>
      ),
      REFUND: (
        <RoundButtonIcon
          buttonType="default"
          buttonSize="icon24v2"
          noHover
          style={{ cursor: 'default' }}
        >
          <TransferReceivedIcon stroke={colors.grayMedium} />
        </RoundButtonIcon>
      ),
    };

    return translator[transactionDetails?.method.toUpperCase()];
  };

  const position =
    Number(
      alphabetPosition(
        String(transactionDetails?.data?.requisite?.key?.holderName?.[0])
      )
    ) || 1;

  const isTransfer =
    transactionDetails?.method.toUpperCase() === 'TRANSFER-PIX' ||
    (transactionDetails?.method.toUpperCase() === 'TRANSFER' &&
      !transactionDetails?.data?.requisite?.type?.includes('EXCHANGE'));

  const roundButtonBackground = isTransfer ? randomColors[position] : '';

  const transactionName =
    transactionDetails?.data?.recipientName ||
    transactionDetails?.data?.requisite?.holderName ||
    transactionDetails?.data?.requisite?.key?.holderName ||
    (transactionDetails?.data?.requisite?.type ===
      'EXCHANGE_CAPWALLET_TO_CAPCONTA' &&
      'Carteira CapConta') ||
    (transactionDetails?.data?.requisite?.type ===
      'EXCHANGE_CAPCONTA_TO_CAPWALLET' &&
      transactionDetails?.data?.requisite?.walletAddress &&
      `Carteira de ${walletInfo?.walletName}`);

  const isoCode = walletInfo?.currency?.isoCode;

  const renderInputResult = () => {
    return ` ${formatCurrencyFn(
      isoCode,
      String(exchangeResult),
      true
    )} ${isoCode}`;
  };

  const handleTransactionValue = () => {
    if (
      transactionDetails?.data?.requisite?.type ===
        'EXCHANGE_CAPCONTA_TO_CAPWALLET' &&
      transactionDetails?.data?.requisite?.walletAddress
    ) {
      return (
        <>
          <Text.Heading1Medium
            marginBottom={4}
            style={{ fontFeatureSettings: "'zero' on, 'ss02' on" }}
          >
            {renderInputResult()}
          </Text.Heading1Medium>
          <Text.Heading4 marginBottom={24} color={colors.grayMedium}>
            {typeof transactionDetails?.data?.value === 'string' &&
              formatCurrencyFn(
                'BRL',
                String(Number(transactionDetails.data.value) / 100)
              )}
          </Text.Heading4>
        </>
      );
    }
    return (
      <Text.Heading1Medium
        marginBottom={24}
        style={{ fontFeatureSettings: "'zero' on, 'ss02' on" }}
      >
        {typeof transactionDetails?.data?.value === 'string' &&
          formatCurrencyFn(
            'BRL',
            String(Number(transactionDetails.data.value) / 100)
          )}
      </Text.Heading1Medium>
    );
  };

  const currentDate = dayjs();
  const transactionDate = dayjs(transactionDetails?.timestamp);
  const differenceInDays = currentDate?.diff(transactionDate, 'day');
  const refundSmallerThan90days = differenceInDays < 90;
  
  return (
    <Style.Container roundButtonBackground={roundButtonBackground}>
      <If condition={isLoadingSideBar}>
        <Then>
          <div className="loading-container">
            <LoadingSpinner color={colors.primary} size={40} strokeSize={17} />
          </div>
        </Then>
        <Else>
          <div className="header">
            <div className="title">
              <Text.Heading2>{handleTransactionTitle()}</Text.Heading2>
              <Text.Paragraph color={colors.grayMedium} marginTop={3}>
                {formatDataShowMonth(transactionDetails?.timestamp)} •{' '}
                {dayjs(transactionDetails?.timestamp).format('H:mm')}
              </Text.Paragraph>
            </div>
          </div>

          <div className="wrapper">
            <div className="details">
              <div className="icon-details">{handleTransactionIcon()}</div>
              <Text.Heading4 marginBottom={6} marginTop={20}>
                {transactionName}
              </Text.Heading4>
              {handleTransactionValue()}
              <Style.Status>
                <span
                  className={`circle ${transactionDetails?.status?.toLowerCase()}`}
                />
                {transactionStatus[transactionDetails?.status]}
              </Style.Status>

              <When condition={transactionDetails?.data?.description}>
                <div className="decription-transaction">
                  <SpeechBubbleIcon />
                  <Text.Heading5 color={colors.grayMedium}>
                    {transactionDetails?.data?.description}
                  </Text.Heading5>
                </div>
              </When>

              <When
                condition={
                  transactionDetails?.direction >= 1 &&
                  buttonsPixGranted &&
                  refundSmallerThan90days &&
                  transactionDetails?.data?.method === 'pix'
                }
              >
                <div className="button-refund">
                  <GenericButton
                    onClick={() => {
                      setSection('refund');
                      setParams({ ...params });
                      setOpenModalMultiStep(true);
                    }}
                    buttonType="default"
                    leftIcon={<ArrowLeftCurveIcon style={{ marginTop: 6 }} />}
                  >
                    Reembolsar
                  </GenericButton>
                </div>
              </When>
            </div>

            <div className="buttons-wrapper">
              <GenericRow
                leftContentIconType="icon"
                leftComponent={<ListIcon />}
                rightContentType="arrow"
                title="Detalhes da movimentação"
                padding="18px 0px"
                onClick={() => setIsMoreDetailsOpen(true)}
              />

              {/* <GenericRow
                leftContentIconType="icon"
                leftComponent={<ListIcon />}
                rightContentType="arrowOpenNav"
                title="Ajuda"
                padding="18px 0px"
              /> */}
            </div>
          </div>

          <MoreDetailsModal
            openDetails={isMoreDetailsOpen}
            setOpenDetails={setIsMoreDetailsOpen}
            transactionDetails={transactionDetails}
            exchangeResult={exchangeResult}
            walletInfo={walletInfo}
            downloadSingle={downloadSingle}
          />
        </Else>
      </If>
    </Style.Container>
  );
};

export default DetailsActivitySideBarModal;
