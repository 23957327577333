import * as Style from './Accordion.styles';
import * as Text from '~styles/text';

import { AccordionProps } from './Accordion.types';
import ArrowDown from '~assets/images/icons/arrow-down-black.svg';

import ArrowUp from '~assets/images/icons/arrow-up-black.svg';
import { When } from 'react-if';

const Accordion = (props: AccordionProps) => {
  const {
    paddingVertical,
    onClick,
    leftIcon,
    subtitle,
    rightComponent,
    isOpen,
    children,
    style,
  } = props;

  return (
    <Style.Container paddingVertical={paddingVertical} style={style}>
      <Style.Header onClick={onClick}>
        <div className="left-side">
          <div className="container-icon">{leftIcon}</div>
          <Text.Heading3>{subtitle}</Text.Heading3>
        </div>
        <div className="right-side">
          <div className="container-component">{rightComponent}</div>
          <div className="container-arrow">
            {isOpen ? <ArrowUp /> : <ArrowDown />}
          </div>
        </div>
      </Style.Header>
      <When condition={isOpen}>{children}</When>
    </Style.Container>
  );
};

export default Accordion;
