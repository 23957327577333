const dashboardPath = '/dashboard';
const selectOrganizationPath = '/organizations';
const settingsPath = '/settings';

export const APP_ROUTES = {
  DASHBOARD: dashboardPath,
  ACTIVITIES: `${dashboardPath}/activities`,
  CAP_ACCOUNT: `${dashboardPath}/capaccount`,
  PIX: `${dashboardPath}/pixarea`,
  BILLS: `${dashboardPath}/bills`,
  CONTACTS: `${dashboardPath}/contacts`,
  CARDS: `${dashboardPath}/cards`,
  API: `${dashboardPath}/api`,
  CAP_PAY: `${dashboardPath}/cappay`,
  SELECT_ORGANIZATIONS: selectOrganizationPath,
  SETTINGS: settingsPath,
  FULL_RECEIPT: '/full-receipt',
  ERROR_404: `${dashboardPath}/404`,
  NOT_FOUND: `${dashboardPath}/*`,
  EXTRACT: `${dashboardPath}/extract`,
};
