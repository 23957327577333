import { gql, request, useMutation } from '~reactQuery/index';

interface PasswordResetInput {
  documentNumber: string;
  passwd: string;
  phone?: string;
  number?: string;
  zipCode?: string;
  addressLine?: string;
  buildingNumber?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  birthDate?: string;
  motherName?: string;
  email?: string;
}

interface PasswordResetResponse {
  passwordReset: {
    data: {
      id: number;
      capu: string;
      registerName: string;
      socialName: string;
      phone: string;
      email: string;
      documentNumber: string;
      deletedAt: string;
      createdAt: string;
      updatedAt: string;
      active: boolean;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function usePasswordReset(token: string) {
  const mutation = gql`
    mutation PasswordReset($params: PasswordResetInput!) {
      passwordReset(params: $params) {
        data {
          id
          capu
          registerName
          socialName
          phone
          email
          documentNumber
          deletedAt
          createdAt
          updatedAt
          active
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: PasswordResetInput) => {
    const response = await request<PasswordResetResponse>(
      mutation,
      {
        params,
      },
      { Authorization: `Bearer ${token}` }
    );
    return response.passwordReset;
  };

  return useMutation('PasswordResetMutation', fetch);
}
