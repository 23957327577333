import { colors } from '~styles';
import { breakpoints } from '~styles/metrics';
import { transparentize } from 'polished';
import { GeneralStyleProps } from 'src/@types/general';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0px;
  position: relative;

  .dropdown-content-transfer {
    position: fixed;
    margin-top: 170px;
    left: 116px;
    width: 185px;
    min-height: 40px;
    background-color: ${colors.white};
    border-radius: 10px;
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.02);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 0px;
    z-index: 1000;

    @media (max-width: ${breakpoints.tablet}px) {
     left: 30px;
     margin-top: 220px;
    }

    .button-dropdown {
      width: 100%;
      padding: 13px 13px;
      cursor: pointer;

      :hover {
        background-color: ${'#F8F9FB'};
        transition: all ease-out 0.3s;
      }
    }
  }
`;

export const ContainerButton = styled.div<GeneralStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  position: relative;
  width: ${(props) => {
    if (props.width) {
      return `${props.width}%`;
    }

    if (props.fitContent) {
      return 'fit-content';
    }

    return '100%';
  }};
  min-width: 103px;
  height: 42px;
  border-radius: 44px;
  background-color: ${(props) => props.backgroundColor || colors.gray};
  transition: all ease-out 0.3s;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  padding: 10px 15px 10px 15px;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;

  opacity: ${(props) => props.disabled && 0.5};

  :hover {
    background-color: ${(props) => props.backgroundColorHover || '#E7E8ED'};
    transition: all ease-out 0.3s;

    .hover-animation {
      margin-top: -2px;
      transition: all ease-out 0.3s;
    }
  }

  svg {
    margin-right: 8px;
    color: ${(props) => (props.backgroundColor ? colors.white : colors.black)};
  }

  .hover-animation {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all ease-out 0.3s;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    justify-content: flex-start;
    height: 100px;
    padding: 0;
    border-radius: 10px 0 0 10px;

    .hover-animation {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: 100%;

      padding: 15px;
    }
  }

  & > .ripple {
    width: 20px;
    height: 20px;
    position: absolute;
    background: ${transparentize(0.7, colors.newBlue)};
    display: block;
    content: '';
    border-radius: 9999px;
    opacity: 1;
    animation: 0.9s ease 1 forwards ripple-effect;
  }

  @keyframes ripple-effect {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(10);
      opacity: 0.375;
    }
    100% {
      transform: scale(35);
      opacity: 0;
    }
  }
`;

export const ContainerButtonDropdown = styled.div<GeneralStyleProps>`
  min-width: 48px;
  height: 42px;
  border-radius: 0px 18px 18px 0px;
  background-color: ${(props) => props.backgroundColor || colors.gray};
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-out 0.3s;
  cursor: pointer;

  svg {
    color: ${(props) => (props.backgroundColor ? colors.white : colors.black)};
  }

  :hover {
    background-color: ${(props) => props.backgroundColorHover || '#E7E8ED'};
    transition: all ease-out 0.3s;

    .hover-animation {
      margin-top: -2px;
      transition: all ease-out 0.3s;
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    height: 100px;
    border-radius: 0 10px 10px 0;
  }
`;
