import { ConfirmCredentialsPageProps } from '../PayWithPix.types';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { getSelectedUserOrganization, getSettingsId } from '~helpers/device';
import { useState } from 'react';
import { usePixTransferUsingBrCode } from '~reactQuery/mutations/pix/usePixTransferUsingBrCode';
import { useUserAuthTypes } from '~hooks/useUserAuthTypes';
import { parseError } from '~reactQuery/index';
import AuthValidate from '~components/AuthValidate';
import { LoadingSpinner } from '~components/index';
import colors from '~styles/colors';

function ConfirmCredentials(props: ConfirmCredentialsPageProps) {
  const [loading, setLoading] = useState(false);
  const { setCurrentStep, billData, description, qrCode, editedValue } = props;
  const { mutateAsync: pixTransferUsingBrCode } = usePixTransferUsingBrCode();
  const userOrganizationId = getSelectedUserOrganization();
  const settingsId = getSettingsId();
  const { auth } = useUserAuthTypes();

  const [hasError, setHasError] = useState({
    pin: '',
    twoFa: '',
  });

  async function validateAndGoNext(pin: string, twoFa: string) {
    if (!userOrganizationId || !settingsId) return;

    setHasError({
      pin: '',
      twoFa: '',
    });

    if ((auth.includes('2-FA') && twoFa?.length !== 6) || pin.length !== 4) {
      return;
    }
    setLoading(true);
    const twoFA = twoFa ? { token: twoFa } : {};

    const billDataValue = billData.data.key.payment.totalValue;

    try {
      const response = await pixTransferUsingBrCode({
        description,
        method: 'pix',
        pin,
        requisite: {
          keyType: 'brcode',
          qrCode,
        },
        userOrganizationId: Number(userOrganizationId),
        value: billDataValue <= 0 ? Number(editedValue) * 100 : billDataValue,
        settingsId: Number(settingsId),
        ...twoFA,
      });

      setLoading(false);

      setCurrentStep('payBillsStatus', {
        statusOfPayment: 'OK',
        billData,
        paymentResp: response,
      });
    } catch (error: any) {
      setLoading(false);
      const errorKey = parseError(error);

      if (errorKey === 'INVALID_PIN') {
        setHasError((prev) => ({ ...prev, pin: 'Pin inválido' }));
      } else if (errorKey === 'INVALID_2FA_TOKEN') {
        setHasError((prev) => ({ ...prev, twoFa: '2-FA inválido' }));
      }
    }
  }

  return (
    <>
      <ModalMultiStepHeader
        title="Confirmação de pagamento"
        description="Preencha as confirmações de segurança para finalizar."
        backButtonAction={() => setCurrentStep('paymentDetails')}
      />
      <div className="pin-container content-container">
        {!loading ? (
          <AuthValidate
            authTypes={auth}
            onChangeCodes={(pin, twoFa) => validateAndGoNext(pin, twoFa)}
            pinErrorMessage={hasError.pin}
            twoFaErrorMessage={hasError.twoFa}
          />
        ) : (
          <div className="container-loading">
            <LoadingSpinner color={colors.primary} size={60} strokeSize={25} />
          </div>
        )}
      </div>
    </>
  );
}

export default ConfirmCredentials;
