import { gql, request, useMutation } from '~reactQuery';

import { useQueryClient } from 'react-query';

interface TwoFADisableInput {
  pin: string;
  token: string;
  userOrganizationId: number;
  settingsId: number;
  recovery?: boolean;
}

interface TwoFADisableResponse {
  twoFADisable: {
    data: string;
    isValid: boolean;
    messages: string[];
  };
}

export function useTwoFADisable() {
  const queryClient = useQueryClient();

  const mutation = gql`
    mutation TwoFADisable($params: twoFADisableInput!) {
      twoFADisable(params: $params) {
        data
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: TwoFADisableInput) => {
    if (!params.settingsId) return null;

    const response = await request<TwoFADisableResponse>(mutation, {
      params,
    });
    return response.twoFADisable;
  };

  return useMutation('useTwoFADisable', fetch, {
    onSettled: () => {
      queryClient.invalidateQueries(['useGetPartner']);
    },
  });
}
