import ArrowDownIcon from '~assets/svg/arrowDown';
import ArrowTransferIcon from '~assets/svg/arrowTransfer';
import CopyPasteIcon from '~assets/svg/copyPaste';
import MoneyIcon from '~assets/svg/money';
import PayIcon from '~assets/svg/pay';
import QRCodeWithoutBorderIcon from '~assets/svg/qrCodeWithoutBorder';
import { SwapIcon } from '~assets/svg/swap';
import colors from '~styles/colors';

export const quickActions = (section: string) => {
  const transferColor = () => {
    if (section === 'transfer' || section === 'transferBatch') {
      return colors.white;
    }
    return '';
  };
  return [
    {
      name: 'Transferir',
      icon: <ArrowTransferIcon fill={transferColor()} />,
      section: 'transfer',
    },
    {
      name: 'Converter',
      icon: <SwapIcon fill={section === 'convert' ? colors.white : ''} />,
      section: 'convert',
    },
    {
      name: 'Depositar',
      icon: <ArrowDownIcon fill={section === 'deposit' ? colors.white : ''} />,
      section: 'deposit',
    },
    {
      name: 'Pagar',
      icon: (
        <PayIcon
          fill={
            section === 'payBills' || section === 'payWithPix'
              ? colors.white
              : ''
          }
        />
      ),
      section: 'payBills',
    },
  ];
};

export const pixQuickActions = (section: string, paramsModal: any) => [
  {
    name: 'Transferir',
    icon: (
      <ArrowTransferIcon
        fill={section === 'transfer' ? colors.white : undefined}
      />
    ),
    section: 'transfer',
  },
  {
    name: 'Copia e Cola',
    icon: (
      <CopyPasteIcon
        fill={
          section === 'payWithPix' && !paramsModal.pixQrCode
            ? colors.white
            : undefined
        }
      />
    ),
    section: 'payWithPix',
  },
  {
    name: 'QR Code',
    icon: (
      <QRCodeWithoutBorderIcon
        fill={
          section === 'payWithPix' && paramsModal.pixQrCode
            ? colors.white
            : undefined
        }
      />
    ),
    section: 'payWithPix',
  },
  {
    name: 'Cobrar',
    icon: <MoneyIcon />,
    section: '',
  },
];
