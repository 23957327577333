import { colors, metrics } from '~styles';
import styled, { css } from 'styled-components';

interface ContainerProps {
  noImg: boolean;
  noBorder: boolean;
  inReview?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: fit-content;

  .logo-container {
    display: flex;

    .admin-icon {
      position: absolute;
      margin-left: 52px;
      margin-top: 47px;
    }

    .logo-text {
      color: ${colors.white};
      font-size: 42px;
      width: 75px;
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;

      ${({ noImg }) =>
        noImg &&
        css`
          background-color: ${colors.primary};
          border-radius: 50%;
        `}
    }
  }

  .org-logo {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    object-fit: cover;
  }

  .card {
    width: 150px;

    ${({ noBorder, inReview }) =>
      !noBorder &&
      css`
        border: 1px solid #c3c6db;
        height: 150px;

        :hover {
          ${inReview ? 'cursor: not-allowed' : 'cursor: pointer'};
          background-color: ${colors.gray};
        }
      `}

    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    word-break: keep-all;
    width: 200px;
    flex-direction: column;
  }
`;
