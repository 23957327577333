import * as React from 'react';
import { SVGProps } from 'react';

const DisabledPhoneSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={45}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 10a2 2 0 0 1 2-2h15a2 2 0 0 1 2 2v24a2 2 0 0 1-2 2H15a2 2 0 0 1-2-2V10Zm2-4a4 4 0 0 0-4 4v24a4 4 0 0 0 4 4h15a4 4 0 0 0 4-4V10a4 4 0 0 0-4-4H15Zm7.5 24a1 1 0 1 0 0 2h.015a1 1 0 1 0 0-2H22.5Z"
      fill="#C3C6DB"
    />
  </svg>
);

export default DisabledPhoneSVG;
