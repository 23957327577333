import { colors } from '~styles';
import styled, { css } from 'styled-components';

export interface FadeProps {
  fadeIn: boolean;
}

export const ModalBackground = styled.div<FadeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  top: 0;
  left: 0;

  ${(props) =>
    props.fadeIn
      ? css`
          opacity: 1;
          transition: opacity 0.2s ease-in;
        `
      : css`
          opacity: 0;
          transition: opacity 0.2s ease-out;
        `}
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;
  border-radius: 10px;
  background-color: ${colors.white};
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.02);
  padding: 10px 0px;
  z-index: 2;
  position: absolute;
`;

interface ListItensProps {
  disabled: boolean;
}

export const ListItens = styled.div<ListItensProps>`
  width: 100%;
  padding: 13px 16px;
  display: flex;
  cursor: pointer;
  justify-content: left;
  align-items: center;

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          :hover {
            background-color: ${colors.gray};
          }
        `}
`;

export const Icon = styled.div`
  margin-right: 12px;
  display: flex;
`;
