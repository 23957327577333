import * as React from 'react';

const BnbCoinSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={44}
    height={36}
    viewBox="0 0 44 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={1} y={1} width={42} height={34} rx={10} fill="white" />
    <g clipPath="url(#clip0_1_1494)">
      <path
        d="M24.6433 25.2706V27.7358L22.4675 29L20.3567 27.7358V25.2706L22.4675 26.5348L24.6433 25.2706ZM13 16.7353L15.1108 18.0005V22.2356L18.7577 24.3857V26.8509L13 23.4997V16.7353ZM31.936 16.7353V23.4997L26.1144 26.8509V24.3857L29.7603 22.2356V18.0005L31.936 16.7353ZM26.1144 13.3852L28.2891 14.6463V17.1146L24.6433 19.2647V23.563L22.5315 24.8282L20.4207 23.563V19.2647L16.6469 17.1146V14.6463L18.8216 13.3821L22.4675 15.5343L26.1144 13.3852ZM16.6469 18.8905L18.7577 20.1517V22.6168L16.6469 21.3527V18.8905ZM28.2891 18.8905V21.3557L26.1784 22.6199V20.1517L28.2891 18.8905ZM15.1108 11.2442L17.2865 12.5054L15.1108 13.7644V16.2296L13 14.9654V12.5054L15.1108 11.2442ZM29.8242 11.2442L32 12.5054V14.9654L29.8242 16.2296V13.7644L27.7135 12.5054L29.8242 11.2442ZM22.4675 11.2442L24.6433 12.5054L22.4675 13.7644L20.3567 12.5054L22.4675 11.2442ZM22.4675 7L28.2891 10.3501L26.1784 11.6153L22.5315 9.46517L18.8175 11.6153L16.714 10.3501L22.4675 7Z"
        fill="#F3BA2F"
      />
    </g>
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_1_1494">
        <rect width={19} height={22} fill="white" transform="translate(13 7)" />
      </clipPath>
    </defs>
  </svg>
);
export default BnbCoinSVG;
