import * as React from 'react';
import { SVGProps } from 'react';

const ReceiveSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4V20"
      stroke="#272937"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M20 12.0014L12.7071 19.293C12.3166 19.6835 11.6835 19.6835 11.293 19.293L4 12"
      stroke="#272937"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

export default ReceiveSVG;
