import { useQueryClient } from 'react-query';
import { gql, request, useMutation } from '~reactQuery/index';

interface ParamsSignUpAdm {
  userOrganizationId: number;
  documentNumber: string;
  email: string;
  message?: string;
  phone: string;
  registerName: string;
  scopes: Array<string>;
  socialName?: string;
  urlCallback: string;
}

interface SignUpAdmData {
  signupAdm: {
    data: {
      exp: number;
      iat: number;
      user: {
        active: boolean;
        capu: string;
        documentNumber: string;
        email: string;
        id: number;
        phone: string;
        registerName: string;
        socialName: string;
        updatedAt: string;
        createdAt: string;
        deletedAt: string;
      };
    };
    isValid: boolean;
    messages: string[];
  };
}

export const useSignUpAdm = () => {
  const queryClient = useQueryClient();

  const mutation = gql`
    mutation SignupAdm($params: SignUpAdmInput!) {
      signupAdm(params: $params) {
        data {
          exp
          iat
          user {
            active
            capu
            createdAt
            deletedAt
            documentNumber
            email
            id
            phone
            registerName
            socialName
            updatedAt
          }
        }
        isValid
        messages
      }
    }
  `;

  const fetch = async (params: ParamsSignUpAdm) => {
    if (!params.userOrganizationId) return null;
    const response = await request<SignUpAdmData>(mutation, {
      params,
    });

    return response;
  };

  return useMutation('useSignUpAdm', fetch, {
    onSuccess: () => {
      queryClient.invalidateQueries(['useGetOrganizationPartners']);
    },
  });
};
