import * as React from 'react';
import { SVGProps } from 'react';

const WalletDshSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.913 9.927H10.74l-.512 2.867h5.588c2.742 0 3.553.999 3.528 2.656-.07.935-.25 1.858-.54 2.75-.441 1.265-1.308 2.666-4.561 2.666H8.828l-.506 2.866h6.155a8.543 8.543 0 0 0 4.074-.705 7.606 7.606 0 0 0 2.73-2.479 7.634 7.634 0 0 0 1.247-3.475c.77-4.183-.192-7.146-5.615-7.146Z"
      fill="#0F90FF"
    />
    <path
      d="M8.08 15.381a1.804 1.804 0 0 0-1.327.391c-.374.302-.612.74-.663 1.217 0 .028-.007.058-.009.086-.202.836-.269 1.178-.269 1.178h6.315c.477.051.956-.089 1.33-.39.373-.302.612-.74.664-1.219 0-.028.005-.058.007-.086.203-.837.27-1.177.27-1.177H8.08Z"
      fill="#0F90FF"
    />
  </svg>
);

export default WalletDshSVG;
