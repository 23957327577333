import { gql, request, useMutation } from '~reactQuery/index';

interface ParamsPutBusinessScopes {
  userOrganizationId: number;
  scopes: Array<string>;
}

interface PutBusinessScopesData {
  putBusinessScopes: {
    data: {
      active: boolean;
      admin: boolean;
      createdAt: string;
      flags: Array<string>;
      id: number;
      organizations: {
        id: number;
        name: string;
        country: string;
        businessCode: string;
        setup: string;
        bankCompe: string;
        branch: string;
        account: string;
        image: string;
        deletedAt: string;
        createdAt: string;
        updatedAt: string;
        walletsInternalCredentialsId: number;
        banklyPartnersId: number;
        walletsInternalCredentials: {
          id: number;
          internalPin: string;
          sessionKey: string;
          userBusinessId: number;
        };
        partner: {
          companyKey: string;
          grantType: string;
          id: number;
        };
        settings: {
          createdAt: string;
          enablePin: boolean;
          id: number;
          language: string;
          notificationFlags: Array<string>;
          twoFA: boolean;
          updatedAt: string;
        };
      };
      organizationsId: number;
      permissions: Array<string>;
      settings: {
        createdAt: string;
        enablePin: boolean;
        id: number;
        language: string;
        notificationFlags: Array<string>;
        twoFA: boolean;
        updatedAt: string;
      };
      updatedAt: string;
    };
    isValid: boolean;
    messages: string[];
  };
}

export const usePutBusinessScopes = () => {
  const mutation = gql`
    mutation PutBusinessScopes($params: PutBusinessScopesInput!) {
      putBusinessScopes(params: $params) {
        data {
          active
          admin
          createdAt
          flags
          id
          organizations {
            id
            name
            country
            businessCode
            setup
            bankCompe
            branch
            account
            image
            deletedAt
            createdAt
            updatedAt
            walletsInternalCredentialsId
            banklyPartnersId
            walletsInternalCredentials {
              id
              internalPin
              sessionKey
              userBusinessId
            }
            partner {
              companyKey
              grantType
              id
            }
            settings {
              createdAt
              enablePin
              id
              language
              notificationFlags
              twoFA
              updatedAt
            }
          }
          organizationsId
          permissions
          settings {
            id
            enablePin
            twoFA
            language
            notificationFlags
            createdAt
            updatedAt
          }
          updatedAt
        }
        isValid
        messages
      }
    }
  `;

  const fetch = async (params: ParamsPutBusinessScopes) => {
    if (!params.userOrganizationId) return null;
    const response = await request<PutBusinessScopesData>(mutation, {
      params,
    });

    return response;
  };

  return useMutation('usePutBusinessScopes', fetch);
};
