import * as Style from '../GuestRegister.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import { breakpoints } from '~styles/metrics';
import useWindowDimensions from '~hooks/windowDimension';
import { FooterButton } from '~components/Footers';
import { useNavigate } from 'react-router-dom';
import RegisterFinishedImage from '~assets/images/register-finished.svg';
import ApprovedSVG from '~assets/svg/approved';
import { CommonStepParams } from '../GuestRegister.types';

const Finish = ({ stepParams, goToStep }: CommonStepParams) => {
  const { width, height } = useWindowDimensions();
  const navigate = useNavigate();

  const onSubmit = () => navigate('/auth/sign-in');

  return (
    <Style.Container>
      <div className="main-icon">
        <ApprovedSVG />
      </div>

      <Text.Heading1Medium marginTop={10} marginBottom={30}>
        Cadastro Finalizado
      </Text.Heading1Medium>

      <Text.Heading5
        color={colors.black}
        marginBottom={30}
        lineHeight={140}
        width={breakpoints.desktop < width ? '100%' : ''}
      >
        Agora você faz parte da organização Master Consultoria e poderá
        desfrutar das funcionalidades que foram atribuídas a você.
      </Text.Heading5>

      <div className="enable-two-fa">
        <div className="justify-items-center">
          <RegisterFinishedImage />
        </div>
      </div>

      <FooterButton
        onClickGenericButton={() => onSubmit()}
        genericButtonType="secondary"
        genericButtonContent="Acessar CapBusiness"
      />
    </Style.Container>
  );
};

export default Finish;
