import { colors, metrics } from '~styles';
import { screen } from '~styles/metrics';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { GeneralStyleProps } from 'src/@types/general';

export const Container = styled.div<GeneralStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: ${(props) => props.paddingLeft || 0}px;
  padding-right: ${(props) => props.paddingRight || 0}px;
  border-radius: 5px;
  border-bottom: 1px solid ${transparentize(0.96, colors.black)};
  transition: all ease-out 0.4s;
  cursor: default;

  :hover {
    background-color: ${colors.gray};
    transition: all ease-out 0.4s;

    .icon-transaction {
      svg {
        margin-top: -3px;
        transition: all ease-out 0.4s;
      }
    }

    .receipt {
      right: 14px;
      transition: all ease-out 0.4s;
    }
  }

  .icon-transaction {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 36px;
    height: 36px;

    padding: 0px;
    margin-right: 10px;

    svg {
      transition: all ease-out 0.4s;
    }
  }

  .item-40 {
    width: 40%;
  }

  .item-35 {
    width: 35%;
  }

  .item-25 {
    width: 25%;

    &.right {
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }
  }

  .last-item {
    transition: all ease-out 0.4s;
  }

  .receipt {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 30px;
    border-radius: 10px;
    margin-left: 15px;
    background-color: ${transparentize(0.9, colors.primary)};

    &:hover {
      background-color: ${transparentize(0.8, colors.primary)};
    }

    transition: all ease-out 0.4s;
    z-index: 9;

    .tooltip-text {
      visibility: hidden;
      transition: all ease-out 0.3s;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: ${transparentize(0.15, colors.black)};
      border-radius: 8px;
      padding: 3px 10px;

      position: absolute;
      top: 55px;
      right: 0px;
      z-index: 999;

      opacity: 0;

      &.w-100 {
        width: 100px;
      }

      &.w-190 {
        width: 190px;
      }
    }

    :hover {
      .tooltip-text {
        visibility: visible;
        opacity: 1;
        transition: all ease-out 0.3s;
      }
    }
  }

  @media (min-width: 933px) and (max-width: 1290px) {
    .item-40 {
      width: 36%;
    }
  }

  @media (min-width: 701px) and (max-width: 932px) {
    .item-40 {
      width: 34%;
    }

    .item-25 {
      width: 35%;

      &.right {
        width: 30%;
      }
    }

    .item-35.middle {
      width: 30%;

      &.destination {
        width: 30%;
        display: flex;
      }
    }
  }

  @media (min-width: 631px) and (max-width: 700px) {
    :hover {
      background-color: ${colors.whiteLight};
      transition: all ease-out 0.4s;

      .icon-transaction {
        svg {
          margin-top: 0px;
          transition: all ease-out 0.4s;
        }
      }

      .last-item {
        margin-right: 0px;
        transition: all ease-out 0.4s;
      }

      .receipt {
        right: 0px;
        transition: all ease-out 0.4s;
      }
    }

    .item-25 {
      width: 35%;

      &.right {
        width: 30%;
      }
    }

    .item-35.middle {
      display: none;

      &.destination {
        width: 25%;
        display: flex;
      }
    }

    .receipt {
      display: none;
    }

    .wall {
      display: none;
    }
  }

  @media (max-width: 630px) {
    :hover {
      background-color: ${colors.whiteLight};
      transition: all ease-out 0.4s;

      .icon-transaction {
        svg {
          margin-top: 0px;
          transition: all ease-out 0.4s;
        }
      }

      .last-item {
        margin-right: 0px;
        transition: all ease-out 0.4s;
      }

      .receipt {
        right: 0px;
        transition: all ease-out 0.4s;
      }
    }

    .item-25 {
      width: 60%;

      &.right {
        width: 47%;
      }
    }

    .item-35.middle {
      display: none;
    }

    .receipt {
      display: none;
    }

    .wall {
      display: none;
    }
  }
`;
