import * as Style from '../ChangePin.styles';
import * as Text from '~styles/text';

import DialogBottom from '~components/Dialog/DialogBottom.component';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import React from 'react';
import ShinyHappyOutdoorsSVG from '~assets/svg/shinyHappyOutdoors';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

const SuccessChangePin: React.FC = () => {
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  return (
    <>
      <div className="change-pin-body">
        <ShinyHappyOutdoorsSVG />
        <Text.Heading2 marginTop={25}>PIN alterado com sucesso!</Text.Heading2>
        <Text.Heading5 marginTop={20}>
          Use seu novo PIN para confirmar todas as suas novas transações
          bancárias!
        </Text.Heading5>
      </div>

      <DialogBottom
        singleButtonWidth="10%"
        singleButtonText="Concluir"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => setOpenModalMultiStep(false)}
      />
    </>
  );
};

export default SuccessChangePin;
