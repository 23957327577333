import * as Text from '~styles/text';

import EndStepStatus from '~components/EndStepStatus';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import React from 'react';
import { StatusProps } from '../RecoveryPin.types';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

function Status(props: StatusProps) {
  const { orgName } = props;

  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  return (
    <>
      <div className="recovery-pin-body">
        <div className="justify-items-center">
          <EndStepStatus animType="success" />
          <Text.Heading1Bold
            textAlign="center"
            marginBottom={25}
            marginTop={25}
          >
            Novo PIN cadastrado com sucesso!
          </Text.Heading1Bold>
          <Text.Heading4 display="inline" textAlign="center">
            Use seu novo PIN para gerenciar a conta da {orgName}.
          </Text.Heading4>
        </div>
      </div>
      <ModalMultiStepBottom
        singleButtonText="Concluir"
        singleButtonWidth="1"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => setOpenModalMultiStep(false)}
      />
    </>
  );
}

export default Status;
