import AuthValidate from '~components/AuthValidate';
import { ConfirmPinPageProps } from '../PayBills.types';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { getSelectedUserOrganization } from '~helpers/device';
import { parseError } from '~reactQuery/index';
import { useBillConfirm } from '~reactQuery/mutations/payments/useBillConfirm';
import { useState } from 'react';
import { useUserAuthTypes } from '~hooks/useUserAuthTypes';
import { LoadingSpinner } from '~components/index';
import colors from '~styles/colors';

function ConfirmPin(props: ConfirmPinPageProps) {
  const [loading, setLoading] = useState(false);
  const { auth } = useUserAuthTypes();
  const { setCurrentStep, billData, description } = props;

  const { mutateAsync: billConfirm } = useBillConfirm();
  const userOrganizationId = getSelectedUserOrganization();

  const [hasError, setHasError] = useState({
    pin: '',
    twoFa: '',
  });

  async function validateAndGoNext(pin: string, twoFa?: string) {
    setHasError({
      pin: '',
      twoFa: '',
    });

    if ((auth.includes('2-FA') && twoFa?.length !== 6) || pin.length !== 4) {
      return;
    }
    setLoading(true);
    const twoFA = twoFa ? { token: twoFa } : {};

    try {
      const billPaymentResp = await billConfirm({
        userOrganizationId: Number(userOrganizationId),
        amount: billData?.data?.dataValidate?.amount,
        description: description || '',
        id: billData?.data?.dataValidate?.id,
        pin,
        ...twoFA,
      });

      setLoading(false);

      setCurrentStep('payBillsStatus', {
        statusOfPayment: 'OK',
        billPaymentResp,
      });
    } catch (error: any) {
      setLoading(false);
      const errorKey = parseError(error);

      if (errorKey === 'INVALID_PIN') {
        setHasError((prev) => ({ ...prev, pin: 'Pin inválido' }));
      }

      if (errorKey === 'INVALID_2FA_TOKEN') {
        setHasError((prev) => ({ ...prev, twoFa: '2-FA inválido' }));
      }
    }
  }

  return (
    <>
      <ModalMultiStepHeader
        title="Confirmação de pagamento"
        description="Preencha as confirmações de segurança para finalizar."
        backButtonAction={() => setCurrentStep('paymentDetails')}
      />
      <div className="pin-container content-container">
        {!loading ? (
          <AuthValidate
            authTypes={auth}
            onChangeCodes={(pin, twoFa) => validateAndGoNext(pin, twoFa)}
            pinErrorMessage={hasError.pin}
            twoFaErrorMessage={hasError.twoFa}
          />
        ) : (
          <div className="container-loading">
            <LoadingSpinner color={colors.primary} size={60} strokeSize={25} />
          </div>
        )}
      </div>
      <ModalMultiStepBottom />
    </>
  );
}

export default ConfirmPin;
