import { gql, request, useMutation } from '~reactQuery';

import { useQueryClient } from 'react-query';

interface DeleteBusinessPartnerInput {
  businessId: number;
  soft?: number;
}

interface DeleteBusinessPartnerResponse {
  deleteBusinessPartner: {
    data: string;
    messages: [string];
    isValid: boolean;
  };
}

export function useDeleteBusinessPartnerMutation() {
  const queryClient = useQueryClient();

  const mutation = gql`
    mutation DeleteBusinessPartner($params: deleteBusinessPartnerInput!) {
      deleteBusinessPartner(params: $params) {
        isValid
        messages
        data
      }
    }
  `;

  const fetch = async (params: DeleteBusinessPartnerInput) => {
    const response = await request<DeleteBusinessPartnerResponse>(mutation, {
      params,
    });
    return response.deleteBusinessPartner;
  };

  return useMutation('DeleteBusinessPartner', fetch, {
    onSettled: () => {
      queryClient.invalidateQueries(['getCustomerData', 'getPartnerData']);
    },
  });
}
