import { gql, request, useQuery } from '~reactQuery';

import { Actions } from '~reactQuery/types/wallets.types';

export interface GetLimitsInput {
  action: Actions;
  currency: string;
  userOrganizationId: number;
}

export interface GetLimitsResponse {
  limits: {
    minLimit: number;
    maxLimit: number;
  };
}

export const useGetLimits = (params: GetLimitsInput) => {
  const QUERY = gql`
    query Limits($params: LimitsInput!) {
      limits(params: $params) {
        minLimit
        maxLimit
      }
    }
  `;

  const fetcher = async () => {
    const response = await request<GetLimitsResponse>(QUERY, { params });
    return response.limits;
  };

  return useQuery(['getLimits'], fetcher, {
    cacheTime: 0,
    staleTime: 0,
  });
};
