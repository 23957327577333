const UploadSVG = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 10.4222L7.70711 9.71513L11 6.42224L11 15.008L11 16.008H13V15.008L13 6.42194L16.293 9.71433L17.0001 10.4214L18.4142 9.00704L17.707 8.29999L12.707 3.30086L11.9999 2.59387L11.2929 3.30092L6.29289 8.30092L5.58579 9.00802L7 10.4222ZM3 14.008V20.008V21.008H4H20H21V20.008V14.008H19V19.008H5V14.008H3Z"
      fill="#272937"
    />
  </svg>
);

export default UploadSVG;
