import { gql, request } from '~reactQuery';

import { CAPACCOUNT_QUERY_KEYS } from '~src/constants/capaccount';
import { StatementUnion } from '~reactQuery/types/bank.types';
import { useInfiniteQuery } from 'react-query';
import { getSelectedUserOrganization } from '~helpers/device';
import { useOrgInfo } from '../business/useGetBusiness';

export interface GetStatementInput {
  from: string;
  page?: number;
  perPage?: number;
  to: string;
  restrict?: boolean;
}

export interface Statement {
  authCode: string;
  data: StatementUnion;
  direction: number;
  method: string;
  status: string;
  timestamp: string;
}

export interface GetStatementDataResponse {
  statement: Statement[];
}

export const useGetStatement = (params: GetStatementInput) => {
  const orgId = getSelectedUserOrganization();
  const userOrganizationId = getSelectedUserOrganization();

  if (params.to && params.from) {
    params.to = `${params.to}T02:59:59`;
    params.from = `${params.from}T03:00:00`;
  }

  const { data: businessInfos } = useOrgInfo({
    userOrganizationId: Number(orgId),
  });

  const documentNumber = businessInfos?.organizations?.businessCode;

  const QUERY = gql`
    query Statement($params: StatementInput!) {
      statement(params: $params) {
        data {
          ... on Movement {
            value
            description
            method
            balance
            requisite {
              ... on BankAccount {
                bank {
                  bankName
                  bankIspb
                  bankCompe
                }
                branch
                account
                holderName
                holderDocument
                accountType
              }
              ... on PixKeyMovement {
                key {
                  bank {
                    bankName
                    bankIspb
                    bankCompe
                  }
                  branch
                  account
                  holderName
                  holderDocument
                  accountType
                  e2eId
                }
                keyType
              }
              ... on Exchange {
                type
                walletAddress
              }
            }
          }
          ... on BillPayment {
            description
            digitableLine
            originalValue
            fine
            interest
            discount
            value
            dueDate
            settleDate
            assignor
            recipientName
            recipientDocument
            balance
          }
          ... on CardTransaction {
            value
            description
            method
            details {
              cardId
              amount
              category
              city
              stateOrCountryCode
              merchantName
              transactionType
              zip
              authorizationTransactionId
              transactionStatus
              amountSettlement
              amountLocal
              markup
              iof
              dollarExchangeRate
              entryMode
              has3Ds
              acquirerCode
              merchantTerminalId
              currencyCode
              mcc
              cardAlias
              authorizationCode
              withdrawalFeeAmount
              deniedRules
              responseCode
              walletId
              isPre
              isPos
              isDebit
              numberOfInstallments
              correlationId
              document
              companyKey
              eventDateTime
            }
          }
          ... on Invoice {
            description
            value
            method
          }
          ... on BankSlipIssuanse {
            method
            name
            type
            index
            status
            value
            category
            bankBranch
            aggregateId
            bankAccount
            documentNumber
            balance
            billSlipDetails {
              barCode
              bankCode
              issueDate
              bankSlipId
              amountInCents
              digitableLine
              expirationDate
            }
          }
        }
        authCode
        direction
        method
        status
        timestamp
      }
    }
  `;

  const fetcher = async ({ pageParam = 1 }) => {
    if (!documentNumber) return null;

    const response = await request<GetStatementDataResponse>(QUERY, {
      params: {
        ...params,
        userOrganizationId: Number(userOrganizationId),
        documentNumber,
        page: params.page === 0 ? params.page : pageParam,
        to: params.to,
        from: params.from,
      },
    });

    return response.statement?.filter((s) => s?.data);
  };

  return useInfiniteQuery(
    [
      CAPACCOUNT_QUERY_KEYS.GET_CAPACCOUNT_STATEMENT,
      params.page,
      params.from,
      params.to,
      documentNumber,
      params.perPage,
    ],
    fetcher,
    {
      refetchInterval: 100000,
      retry: false,
      getNextPageParam: (lastPage, pages: any) => {
        if (pages?.[0]?.pages === pages.length) {
          return undefined;
        }
        return pages.length + 1;
      },
    }
  );
};
