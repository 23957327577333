import { InputPin } from '~components/Inputs';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import { breakpoints } from '~styles/metrics';
import useWindowDimensions from '~hooks/windowDimension';
import { FooterButton } from '~components/Footers';
import SecuritySVG from '~assets/svg/security';
import { When } from 'react-if';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTwo2faValidade } from '~reactQuery/mutations/user/useTwoFaValidate';

const Validate2FA = ({
  emailToken,
  setStep,
}: {
  emailToken: string;
  setStep: any;
}) => {
  const { width } = useWindowDimensions();

  const { mutateAsync: handleValidateCode, isLoading } = useTwo2faValidade();

  const schema = yup.object().shape({
    code: yup
      .string()
      .min(6, '2-FA incompleto')
      .required('Esse campo é obrigatório'),
  });

  const {
    setValue,
    getValues,
    formState: { errors },
    trigger,
    setError,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
    },
  });

  const onSubmit = async () => {
    try {
      trigger().then(async (isValid) => {
        if (isValid) {
          const token = getValues('code');

          try {
            const response = await handleValidateCode({
              isGuest: true,
              token,
              emailToken,
            });

            if (response.data.isValid) {
              setStep('reset');
            } else {
              setError('code', {
                message: 'Código Inválido',
              });
            }
          } catch (error) {
            console.error(error);
          }
        }
      });
    } catch (error) {
      //   console.log('TwoFa error: ', error);
    }
  };

  const loadingButton = isLoading;

  return (
    <>
      <div className="main-icon">
        <SecuritySVG />
      </div>

      <Text.Heading1Medium marginTop={10} marginBottom={30}>
        Insira o código de validação
      </Text.Heading1Medium>

      <Text.Heading5
        color={colors.black}
        marginBottom={30}
        lineHeight={140}
        width={breakpoints?.desktop < width ? '100%' : ''}
      >
        Para autenticarmos a sua solicitação, confirme o código de 6 dígitos
        gerado pelo seu aplicativo de autenticação.
      </Text.Heading5>

      <InputPin
        autoFocus
        size={6}
        secret
        onChange={(code) => {
          setValue('code', code);
          setError('code', { message: '' });
        }}
        onFinish={(code) => setValue('code', code)}
        error={!!errors.code?.message}
      />

      <When condition={!!errors.code?.message}>
        <Text.Heading5 marginTop={5} color={colors.danger}>
          Código Invalido
        </Text.Heading5>
      </When>

      <Text.Heading5 marginTop={20}>
        Se estiver com dificuldades para encontrar o código, você pode pedir
        ajuda no nosso chat do suporte.
      </Text.Heading5>

      <FooterButton
        onClickGenericButton={() => onSubmit()}
        leftButtonContent="Voltar"
        onClickLeftButton={() => setStep('warning')}
        genericButtonType="secondary"
        genericButtonContent="Habilitar 2FA"
        loadingGenericButton={loadingButton}
      />
    </>
  );
};

export default Validate2FA;
