import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  .company-content,
  .contact-content {
    width: 100%;

    .icon-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      
      svg {
        margin-right: 10px;
      }
    }

    .logo-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 16px;

      .text-container {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin-right: 32px;
      }
    }

    .side-content {
      display: flex;
      flex-direction: row;

      margin: 12px 0;
      
      .side {
        width: 50%;
        margin-top: 16px;

        .single-item {
          max-width: 70%;
        }
      }
    }

    .tip {
      display: flex;
      flex-direction: row;
      align-items: center;

      margin-top: 24px;

      svg {
        margin-right: 10px;
      }

      max-width: 90%;
    }

    /* .container-upload {
      position: relative;
      display: inline-block;
      .container-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .upload-icon {
        display: none;
      }
      :hover {
        .upload-icon {
          display: block;
          width: 43px;
          height: 43px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
        }
      }
    } */
  }

  @media (max-width: 1045px) {
    flex-direction: column;

    .contact-content {
      margin: 30px 0 0;
    }
  }
`;