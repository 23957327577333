import * as React from 'react';

import { SVGProps } from 'react';

const BuyCardSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.75 7.75h11.5l-1.637 6.958a2 2 0 0 1-1.947 1.542h-4.127a2 2 0 0 1-1.933-1.488L7.75 7.75Zm0 0-.75-3H4.75"
      stroke="#272937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 19a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM17.5 19a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
      stroke="#272937"
    />
  </svg>
);

export default BuyCardSVG;
