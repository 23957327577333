import * as React from 'react';
import { SVGProps } from 'react';

const WalletBnbSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m12.107 16.017 3.243-3.243 3.245 3.245 1.887-1.887L15.35 9l-5.13 5.13 1.887 1.887ZM7 17.35l1.887-1.887 1.887 1.887-1.887 1.887L7 17.35Zm5.107 1.333 3.243 3.243 3.245-3.245 1.888 1.886v.001L15.35 25.7l-5.13-5.13-.003-.003 1.89-1.884Zm7.819-1.332 1.887-1.887L23.7 17.35l-1.887 1.887-1.887-1.887Z"
      fill="#F3BA2F"
    />
    <path
      d="m17.264 17.349-1.914-1.915-1.415 1.415-.163.163-.335.335-.003.003.003.002 1.913 1.914 1.915-1.915v-.001l-.001-.001"
      fill="#F3BA2F"
    />
  </svg>
);

export default WalletBnbSVG;
