import * as Styled from './Notifications.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import { GenericButton, GenericRow, RoundButtonIcon } from '~components/index';
import BellIcon from '~assets/svg/bell';
import GearIcon from '~assets/svg/gearIcon';
import { NotificationItem } from '~components/NotificationItem';
import { useGetNotifications } from '~reactQuery/queries/notifications/useGetNotifications';
import { getSelectedUserOrganization } from '~helpers/device';
import { notificationParser } from '~helpers/notifications/notificationParser';
import { usePutNotifications } from '~reactQuery/mutations/notifications/usePutNotifications';
import AlertModal from '~components/AlertModal';
import { Else, If, Then, When } from 'react-if';
import { useEffect, useState } from 'react';
import { useDeleteNotification } from '~reactQuery/mutations/notifications/useDeleteNotification';
import { useNavigate } from 'react-router-dom';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';

const NotificationModal = () => {
  const userOrganizationId = getSelectedUserOrganization();
  const navigate = useNavigate();
  const { setOpenModalSideBar, setModalSidebarContent, openModalSideBar } =
    useOpenModalSideBar();

  const { mutateAsync: handleUpdateNotification } = usePutNotifications();
  const { mutateAsync: handleDeleteNotification } = useDeleteNotification();

  const handleCloseSidebar = () => {
    setOpenModalSideBar(false);
    setModalSidebarContent('noContent');
  };

  const { data: notifications = [] } = useGetNotifications({
    userOrganizationId: Number(userOrganizationId),
    showNotificationData: true,
  });

  const parsedNotifications = notificationParser(notifications);

  const [clearNotificationModalOpen, setClearNotificationModalOpen] =
    useState(false);

  const [viewAll, setViewAll] = useState(false);

  const notificationsItems = parsedNotifications.filter((n, idx) => {
    if (!viewAll) {
      return idx < 5;
    }

    return true;
  });

  const handleReadNotifications = async () => {
    notificationsItems
      .filter((n) => n.read === false)
      .forEach(async (n) => {
        await handleUpdateNotification({
          notificationId: n.id,
          read: true,
          userOrganizationId: Number(userOrganizationId),
        });
      });
  };

  const handleDeleteAllNotifications = async () => {
    notifications.forEach(async (n) => {
      await handleDeleteNotification({
        notificationId: n.id,
        userOrganizationId: Number(userOrganizationId),
      });
    });

    setClearNotificationModalOpen(false);
  };

  const onClickNotification = async (id: number, callback?: () => void) => {
    await handleUpdateNotification({
      notificationId: id,
      read: true,
      userOrganizationId: Number(userOrganizationId),
    });

    if (callback) {
      callback();
    }
  };

  const openNotificationSettings = () => {
    handleCloseSidebar();
    navigate('/settings?tab=notif');
  };

  useEffect(() => {
    if (!openModalSideBar) {
      handleReadNotifications();
    }
  }, [openModalSideBar, viewAll]);

  return (
    <Styled.Container>
      <div className="header">
        <Text.Heading2Bold>Notificações</Text.Heading2Bold>
      </div>

      <If condition={parsedNotifications.length === 0}>
        <Then>
          <div className="no-notifications">
            <RoundButtonIcon buttonSize="iconWallet" noHover>
              <BellIcon fill={colors.grayMedium} />
            </RoundButtonIcon>
            <Text.Heading3Bold color={colors.grayMedium}>
              Você não tem notificações
            </Text.Heading3Bold>
            <Text.Paragraph color={colors.grayRegular} width="320px">
              Fique ligado! As notificações sobre suas atividades serão
              mostradas aqui.
            </Text.Paragraph>
          </div>
        </Then>

        <Else>
          <div className="notifications-row">
            {notificationsItems.map((n) => {
              return (
                <NotificationItem
                  key={n.id}
                  icon={n.icon}
                  category={n.category}
                  date={n.createdAt}
                  viewed={n.read}
                  label={n.label}
                  description={n.description}
                  onClickNotification={() =>
                    onClickNotification(n.id, n.onClickNotification)
                  }
                />
              );
            })}

            <div className="clearNotifWrapper">
              <When condition={!viewAll && parsedNotifications.length > 5}>
                <a onClick={() => setViewAll(true)}>
                  <Text.ParagraphBold color={colors.primary}>
                    Ver mais notificações
                  </Text.ParagraphBold>
                </a>
              </When>

              <When condition={parsedNotifications.length}>
                <GenericButton
                  onClick={() => setClearNotificationModalOpen(true)}
                  buttonType="inline"
                >
                  Limpar todas as notificações
                </GenericButton>
              </When>
            </div>
          </div>
        </Else>
      </If>

      <GenericRow
        title="Gerenciar notificações"
        leftContentIconType="icon"
        leftComponent={<GearIcon width={16} height={16} />}
        rightContentType="arrow"
        onClick={openNotificationSettings}
      />

      <When condition={clearNotificationModalOpen}>
        <AlertModal
          title="Você tem certeza que deseja limpar todas as notificações?"
          bodyText="Ao limpar as notificações, todos os avisos existentes na lista serão apagadas permanentemente."
          primaryButtonText="Fechar"
          primaryButtonAction={() => setClearNotificationModalOpen(false)}
          secondaryButtonText="Limpar notificações"
          secondaryButtonAction={handleDeleteAllNotifications}
          setOpenAlert={setClearNotificationModalOpen}
          secondaryButtonWidth="fit-content"
          noBorderTop
          secondaryButtonColor="error"
        />
      </When>
    </Styled.Container>
  );
};

export default NotificationModal;
