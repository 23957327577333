import React from 'react';
import { Button, ContainerNotFound } from './NotFound.styles';
import * as Text from '~styles/text';
import NotFoundSVG from '~assets/svg/notFound';
import { useNavigate } from 'react-router-dom';
import { getSelectedUserOrganization, getToken } from '~helpers/device';

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const token = getToken();
  const selectedOrg = getSelectedUserOrganization();

  const redirectToStart = () => {
    if (!token) {
      navigate('/auth');
    }
    if (!selectedOrg) {
      navigate('/organizations');
    }
    navigate('/dashboard');
  };

  return (
    <ContainerNotFound>
      <div className="description">
        <Text.Heading2Bold fontSize={3.75}>Oops!</Text.Heading2Bold>
        <Text.Heading2Bold fontSize={1.83}>
          Página não encontrada.
        </Text.Heading2Bold>

        <Text.Heading4 marginTop={20}>
          A página que você acessou não existe. <br />
          Estamos trabalhando nisso.
        </Text.Heading4>

        <Button buttonType="primary" onClick={() => redirectToStart()}>
          <Text.Heading5>Retornar ao início</Text.Heading5>
        </Button>
      </div>
      <NotFoundSVG />
    </ContainerNotFound>
  );
};

export default NotFound;
