import * as React from 'react';

import { SVGProps } from 'react';

const EditSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.739 12.297V16.423C18.739 17.2834 18.3972 18.1085 17.7888 18.7169C17.1805 19.3252 16.3554 19.667 15.495 19.667H7.24292C6.81696 19.667 6.39523 19.5831 6.00171 19.42C5.60819 19.257 5.25062 19.018 4.94946 18.7168C4.64831 18.4156 4.4095 18.0579 4.24658 17.6644C4.08366 17.2708 3.99987 16.849 4 16.423V8.17101C4 7.31082 4.34156 6.48584 4.94971 5.8775C5.55786 5.26916 6.38273 4.92727 7.24292 4.927H11.3689"
      stroke={props.fill || '#272937'}
      strokeWidth={props.strokeWidth || '1.5'}
      strokeLinecap="round"
    />
    <path
      d="M11.207 15.1631L8.57997 15.4871C8.52581 15.4939 8.47084 15.4883 8.4192 15.4706C8.36756 15.4529 8.32059 15.4236 8.28199 15.385C8.24339 15.3464 8.21411 15.2995 8.19642 15.2479C8.17874 15.1962 8.17311 15.1412 8.17994 15.0871L8.50404 12.4601L16.5969 4.36707C16.8321 4.13202 17.1511 4 17.4835 4C17.816 4 18.1349 4.13202 18.37 4.36707L19.298 5.29507C19.533 5.53023 19.6651 5.84909 19.6651 6.18156C19.6651 6.51404 19.533 6.83292 19.298 7.06807L11.207 15.1631Z"
      stroke={props.fill || '#272937'}
      strokeWidth={props.strokeWidth || '1.5'}
      strokeLinecap="round"
    />
  </svg>
);

export default EditSVG;
