import * as Style from '../GuestRegister.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import { breakpoints } from '~styles/metrics';
import useWindowDimensions from '~hooks/windowDimension';
import { FooterButton } from '~components/Footers';
import { QuickButton } from '~components/Buttons';
import CopySVG from '~assets/svg/copy';
import DownArrow from '~assets/svg/downArrow';
import PrintSVG from '~assets/svg/print';
import { InfoToast } from '~components/Toast';
import SecuritySVG from '~assets/svg/security';
import { CommonStepParams } from '../GuestRegister.types';

const GetRecoveryCode = ({ stepParams, goToStep }: CommonStepParams) => {
  const { width } = useWindowDimensions();

  const textToWrite = document.getElementById('codes')?.innerText;

  const downloadCodes = () => {
    const element = document.createElement('a');

    const file = new Blob([String(textToWrite)], {
      type: 'text/plain;charset=utf-8',
    });

    element.href = URL.createObjectURL(file);
    element.download = 'backup_codes.txt';
    document.body.appendChild(element);
    element.click();

    InfoToast({
      title: 'Download de códigos de segurança',
      subtitle:
        'Guarde-os em um local seguro para usá-los somente quando necessário.',
      autoCloseEnable: true,
    });
  };

  const printCodes = () => {
    window.print();

    InfoToast({
      title: 'Impressão de códigos solicitada',
      subtitle:
        'Guarde-os em um local seguro para usá-los somente quando necessário.',
      autoCloseEnable: true,
    });
  };
  const copyCodes = () => {
    navigator.clipboard.writeText(String(textToWrite));

    InfoToast({
      title: 'Códigos de segurança copiados',
      subtitle:
        'Guarde-os em um local seguro para usá-los somente quando necessário.',
      autoCloseEnable: true,
    });
  };

  const onSubmit = () => goToStep('finish');

  return (
    <Style.Container>
      <div className="main-icon">
        <SecuritySVG />
      </div>

      <Text.Heading1Medium marginTop={10} marginBottom={30}>
        Códigos de recuperação
      </Text.Heading1Medium>

      <Text.Heading5
        color={colors.black}
        marginBottom={30}
        lineHeight={140}
        width={breakpoints.desktop < width ? '100%' : ''}
      >
        Aqui estão os códigos que você pode usar para fazer login, se perder ou
        não conseguir acessar seu dispositivo móvel.
      </Text.Heading5>

      <div className="recovery-codes">
        <div id="codes" className="justify-items-center">
          <ul>
            {stepParams?.rescueCodes?.map((code: string) => (
              <li key={code}>
                <Text.Heading3>
                  {`${code.slice(0, 3)} ${code.slice(3, 6)}`}
                </Text.Heading3>
              </li>
            ))}
          </ul>
        </div>

        <Text.Heading5 marginTop={20}>
          É importante proteger seus códigos de recuperação, mantendo-os em um
          local seguro e de fácil acesso. Se você perder seu dispositivo e não
          tiver seus códigos de recuperação, não será possível acessar sua conta
          novamente.
        </Text.Heading5>

        <div className="actions-buttons-container hidden-print">
          <div onClick={copyCodes}>
            <QuickButton icon={<CopySVG />}>
              <Text.Heading5>Copiar</Text.Heading5>
            </QuickButton>
          </div>

          <div onClick={downloadCodes}>
            <QuickButton icon={<DownArrow />}>
              <Text.Heading5>Baixar</Text.Heading5>
            </QuickButton>
          </div>
          <div onClick={printCodes}>
            <QuickButton icon={<PrintSVG />}>
              <Text.Heading5>Imprimir</Text.Heading5>
            </QuickButton>
          </div>
        </div>
      </div>

      <FooterButton
        onClickGenericButton={() => onSubmit()}
        leftButtonContent="Voltar"
        onClickLeftButton={() => goToStep('validate2FA')}
        genericButtonType="secondary"
        genericButtonContent="Já guardei meus códigos"
      />
    </Style.Container>
  );
};

export default GetRecoveryCode;
