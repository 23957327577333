const BillSVG = (props: React.SVGProps<SVGSVGElement>) => {
  const { fill = 'none', stroke = '#272937', height = 28, width = 25 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 25 28" fill={fill}>
      <path
        d="M4.75 20.9503V5.0088"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.74954 14.9615V5.0088"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M16.7504 14.9615V5.0088"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.7501 20.9503V5.0088"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.75 20.9503V5.0088"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default BillSVG;
