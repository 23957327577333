import { SVGProps } from 'react';

export const SwapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    fill={props.fill || '#272937'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.538 2.775a.75.75 0 1 0 .015 1.5l3.162-.032-5.772 5.771a.75.75 0 1 0 1.061 1.061l7.071-7.071a.75.75 0 0 0-.538-1.28l-5 .051Zm5.066 10.592a.75.75 0 1 0-.015-1.5l-3.162.032 5.772-5.771a.75.75 0 1 0-1.06-1.06l-7.072 7.07a.75.75 0 0 0 .538 1.28l5-.051Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);
