import * as React from 'react';
import { SVGProps } from 'react';

const WalletCakeSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.047 20.463c.027 1.837 1.25 3.372 2.936 4.407 1.72 1.056 4.04 1.684 6.558 1.684s4.84-.628 6.558-1.684c1.705-1.047 2.937-2.603 2.937-4.466V19.209c0-2.245-1.826-4.008-4.134-5.051l.658-3.643.001-.006c.26-1.564-.945-3.063-2.556-3.063a2.569 2.569 0 0 0-2.568 2.568v3.025a12.84 12.84 0 0 0-.955-.04c-.355 0-.692.017-1.015.04v-3.025a2.569 2.569 0 0 0-2.568-2.568c-1.6 0-2.838 1.497-2.49 3.095l.704 3.621c-2.307 1.048-4.066 2.814-4.066 5.047v1.254Z"
      fill="#633001"
    />
    <path
      d="M24.2 20.404c0 2.926-3.882 5.314-8.659 5.314-4.777 0-8.658-2.388-8.658-5.314V19.03h17.316v1.374Z"
      fill="#FEDC90"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.227 10.372a1.725 1.725 0 0 1 1.672-2.09c.955 0 1.732.776 1.732 1.732v3.94c.597-.059 1.194-.119 1.85-.119.598 0 1.195.06 1.792.12v-3.941c0-.956.776-1.732 1.732-1.732 1.074 0 1.91 1.015 1.731 2.09l-.776 4.3c2.508.955 4.24 2.627 4.24 4.537 0 2.926-3.882 5.315-8.659 5.315-4.777 0-8.658-2.389-8.658-5.315 0-1.91 1.672-3.582 4.18-4.538l-.836-4.299Z"
      fill="#D1884F"
    />
    <path
      d="M13.182 18.433c0 .776-.418 1.433-.955 1.433-.537 0-.955-.657-.955-1.433S11.69 17 12.227 17c.537 0 .955.657.955 1.433ZM19.81 18.433c0 .776-.418 1.433-.955 1.433-.537 0-.955-.657-.955-1.433S18.318 17 18.855 17c.537 0 .955.657.955 1.433Z"
      fill="#633001"
    />
  </svg>
);

export default WalletCakeSVG;
