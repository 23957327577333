import * as Text from '~styles/text';
import { GenericButton } from '~components';
import LockIcon from '~assets/images/lock-icon.svg';
import { breakpoints } from '~styles/metrics';
import { colors } from '~styles';
import { firstAccessUrl } from '~helpers/login/firstAccess';
import useWindowDimensions from '~hooks/windowDimension';

export const Infos = () => {
  const { width } = useWindowDimensions();

  const handleFirstAccess = () =>
    window.open(
      firstAccessUrl(`${window.origin}/auth/change-password`),
      '_blank'
    );

  return (
    <>
      <div className="medium-icon">
        <LockIcon />
      </div>
      <Text.Heading1Medium marginTop={30} marginBottom={30}>
        Suas credenciais de acesso ao CapBusiness precisam ser atualizadas
      </Text.Heading1Medium>

      <Text.Heading5
        color={colors.black}
        marginBottom={30}
        lineHeight={140}
        width={breakpoints.desktop < width ? '100%' : ''}
      >
        O CapBusiness agora está de cara nova, mas para continuarmos garantindo
        sua segurança, você precisa atualizar sua senha de acesso à conta
        empresarial. Vai ser bem rápido, e você só vai precisar seguir alguns
        passos:
        <br />
        <br />
        1. Ao clicar no botão abaixo “Atualizar Acesso”, você será
        redirecionado;
        <br />
        2. Faça login na sua conta usando e-mail e senha antigos;
        <br />
        3. Siga as instruções para dar continuidade e atualizar seus dados de
        login.
        <br />
        <br />
        Vamos te ajudar em todo o processo, e vai ser bem simples. Vamos lá?
      </Text.Heading5>

      <div className="buttons-wrapper">
        <GenericButton
          buttonType="secondary"
          width="auto"
          onClick={handleFirstAccess}
        >
          Atualizar acesso
        </GenericButton>
      </div>
    </>
  );
};
