import * as Style from './UserPic.styles';
import * as Text from '~styles/text';

import { Else, If, Then } from 'react-if';
import { alphabetPosition, randomColors } from '~styles/colors';

import { UserPicProps } from './UserPic.types';
import { colors } from '~styles';

const UserPic = (props: UserPicProps) => {
  const position = Number(alphabetPosition(String(props.name?.[0])));

  return (
    <Style.ProfileButton
      {...props}
      backgroundColor={randomColors[position] || colors.gray}
    >
      <If condition={!props.avatarUrl && !props.svgIcon}>
        <Then>
          <Text.Heading3Bold fontSize={props.fontSize} color={colors.white}>
            {props.name?.slice(0, 1).toUpperCase() || ' '}
          </Text.Heading3Bold>
        </Then>
        <Else>
          <If condition={!!props.svgIcon && !props.avatarUrl}>
            <Then>{props.svgIcon}</Then>
            <Else>
              <img
                alt="avatar"
                className="avatar"
                src={String(props.avatarUrl)}
              />
            </Else>
          </If>
        </Else>
      </If>
    </Style.ProfileButton>
  );
};

export default UserPic;
