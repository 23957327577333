import * as Text from '~styles/text';

import { Else, If, Then, When } from 'react-if';
import { GenericButton, LoadingSpinner } from '..';
import React, { useState } from 'react';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { formatCurrencyFn, getWalletIcon } from '~helpers/format/currency';

import ArrowDownRedSVG from '~assets/svg/arrowDownRed';
import ArrowUpGreenSVG from '~assets/svg/arrowUpGreen';
import DeleteWallet from '~pages/Dashboard/CapAccountAndWallets/DeleteWallet';
import DotsFloatingMenu from '~assets/images/icons/kyc/dots-vertical.svg';
import { DropDown } from '~components/DropDown';
import { DropDownDivider } from '~components/DropDown/DropDownDivider';
import { DropDownItem } from '~components/DropDown/DropDownItem';
import EditSVG from '~assets/svg/edit';
import EditWalletName from '~pages/Dashboard/CapAccountAndWallets/EditWalletName';
import FavoriteStarSVG from '~assets/svg/favoriteStar';
import RoundButtonIcon from '~components/Buttons/RoundButtonIcon';
import Star from '~assets/images/icons/16x16/star-no-filled.svg';
import StarFavoriteSVG from '~assets/svg/starFavorite';
import StartDisabledSVG from '~assets/svg/startDisabled';
import { SuccessToast } from '../Toast';
import Tooltip from '~components/Tooltip';
import Wallet from '~assets/images/icons/16x16/wallet.svg';
import { WalletItemProps } from './WalletRowList.types';
import { WalletType } from '~reactQuery/types/wallets.types';
import WorldBlocked from '~assets/images/icons/16x16/world-block.svg';
import WorldDisabledSVG from '~assets/svg/worldDisabled';
import WorldFavoriteSVG from '~assets/svg/worldFavorite';
import WorldSVG from '~assets/svg/world';
import colors from '~styles/colors';
import { getSelectedUserOrganization } from '~helpers/device';
import { sparkLineColorSelector } from '~data/wallets';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useCurrencyExchange } from '~reactQuery/queries/wallet/useCurrencyExchange';
import { useWalletsModify } from '~reactQuery/mutations/wallet/useWalletsModify';
import { usePermission } from '~helpers/permissionGate';

function WalletItem({
  wallet,
  variationsList,
  loadingVariations,
  openWallet,
}: WalletItemProps) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditWalletNameModal, setOpenEditWalletNameModal] = useState(false);
  const userOrganizationId = getSelectedUserOrganization();
  const { granted: convertGranted } = usePermission('convertButton');
  const { granted: criptoTransactionsGranted } =
    usePermission('criptoTransactions');
  const WalletIcon = getWalletIcon(wallet?.currency?.isoCode);

  const currencyVariation = variationsList?.filter((item) => {
    const walletIsoCode = wallet?.currency?.isoCode?.toUpperCase();
    const variationIsoCode = item?.isoCode?.toUpperCase();

    if (
      ['EUSDT', 'TUSDT'].includes(walletIsoCode) &&
      variationIsoCode === 'USDT'
    ) {
      return true;
    }
    return variationIsoCode === walletIsoCode;
  })?.[0];

  const { setOpenModalMultiStep, setSection, setParams } =
    useActionsOnModalMultiStep();

  const openConvertModalToBuy = () => {
    setSection('convert');
    // send destination wallet to the convert modal (when want to buy)
    setParams({ comingFrom: 'capWallet', destinationWallet: wallet });
    setOpenModalMultiStep(true);
  };

  const { mutateAsync: modifyWallet } = useWalletsModify();

  async function modifyWalletAction(field: string, walletParams: WalletType) {
    const isOwnerInfoPublic = !walletParams.isOwnerInfoPublic;
    const isPublic = !walletParams.isPublic;
    const isFavorite = !walletParams.isFavorite;

    try {
      await modifyWallet({
        walletAddress: walletParams.walletAddress,
        name: walletParams.walletName,
        userOrganizationId: Number(userOrganizationId),
        data: {
          isOwnerInfoPublic:
            field === 'isOwnerInfoPublic'
              ? Boolean(isOwnerInfoPublic)
              : Boolean(walletParams.isOwnerInfoPublic),
          isPublic:
            field === 'isPublic'
              ? Boolean(isPublic)
              : Boolean(walletParams.isPublic),
          isFavorite:
            field === 'isFavorite'
              ? Boolean(isFavorite)
              : Boolean(walletParams.isFavorite),
        },
      });

      if (field === 'isPublic') {
        SuccessToast({
          title: 'Alteração realizada com sucesso!',
          subtitle:
            isPublic
              ? `A sua carteira ${walletParams.walletName} agora pode receber transferências externas.`
              : `A sua carteira ${walletParams.walletName} não pode mais receber transferências externas.`,
          autoCloseEnable: true,
        });
      }

      if (field === 'isFavorite') {
        SuccessToast({
          title: 'Alteração realizada com sucesso!',
          subtitle:
            isFavorite
              ? `A carteira ${walletParams.walletName} foi adicionada às favoritas.`
              : `A carteira ${walletParams.walletName} foi removida das favoritas.`,
          autoCloseEnable: true,
        });
      }
    } catch (error) {
      //  console.log(error);
    }
  }

  const { data: valueInPrefCurrency } = useCurrencyExchange({
    fromCurrency: wallet?.currency?.isoCode,
    toCurrency: 'BRL',
    amount: '1',
    senderPays: false,
    forcePivot: true,
    userOrganizationId: Number(userOrganizationId),
  });

  const handleOpenModal = (
    item: boolean,
    setItem: (value: boolean) => void
  ) => {
    setItem(!item);
  };

  return (
    <>
      <tr className="">
        <td className="name-and-icon-td" onClick={openWallet}>
          <div className="flex items-center gap-20">
            <RoundButtonIcon
              buttonSize="iconWallet"
              buttonType="default"
              noHover
            >
              <div className="scale-icon display-center">{WalletIcon}</div>
            </RoundButtonIcon>
            <div className="">
              <Text.Heading5Bold>{wallet.walletName}</Text.Heading5Bold>
            </div>
          </div>
        </td>
        <td className="hide">
          <div>
            <Text.Heading5>
              {wallet.currency.isoCode.toUpperCase()}
            </Text.Heading5>
          </div>
        </td>
        <td className="hide">
          <If condition={!loadingVariations}>
            <Then>
              <If
                condition={
                  currencyVariation && currencyVariation?.prices?.length > 0
                }
              >
                <Then>
                  <Sparklines
                    svgWidth={80}
                    svgHeight={30}
                    data={currencyVariation?.prices}
                  >
                    <SparklinesLine
                      style={{ fill: 'none', strokeWidth: 3 }}
                      color={sparkLineColorSelector[wallet.currency.isoCode]}
                    />
                  </Sparklines>
                </Then>

                <Else>
                  <Text.Heading5> -------------- </Text.Heading5>
                </Else>
              </If>
            </Then>

            <Else>
              <LoadingSpinner
                color={colors.primary}
                size={30}
                strokeSize={12.5}
              />
            </Else>
          </If>
        </td>
        <td className="hide">
          <If condition={!loadingVariations && currencyVariation?.variation}>
            <Then>
              <Text.Heading5
                className={
                  currencyVariation?.variation > 0 ? 'text-green' : 'text-red'
                }
              >
                {currencyVariation?.variation > 0 ? (
                  <ArrowUpGreenSVG />
                ) : (
                  <ArrowDownRedSVG />
                )}
                {currencyVariation?.variation?.toFixed(2)?.replace('-', '')}%
              </Text.Heading5>
            </Then>

            <Else>
              <If condition={loadingVariations}>
                <Then>
                  <LoadingSpinner
                    color={colors.primary}
                    size={30}
                    strokeSize={12.5}
                  />
                </Then>

                <Else>
                  <Text.Heading5>----------</Text.Heading5>
                </Else>
              </If>
            </Else>
          </If>
        </td>
        <td className="mobile-hide">
          <Text.Heading5>
            {valueInPrefCurrency
              ? formatCurrencyFn('BRL', valueInPrefCurrency)
              : ''}
          </Text.Heading5>
        </td>
        <td className="mobile-hide">
          <div className="margin-16 ">
            <Tooltip
              variant="default"
              position="bottom"
              content="Carteira favorita"
            >
              {wallet.isFavorite ? <FavoriteStarSVG /> : <StartDisabledSVG />}
            </Tooltip>
            <Tooltip
              variant="default"
              position="bottom"
              content="Receber transações externas"
            >
              {wallet.isPublic ? <WorldSVG /> : <WorldDisabledSVG />}
            </Tooltip>
          </div>
        </td>
        <td>
          <GenericButton
            onClick={openConvertModalToBuy}
            buttonType="default"
            disabled={!convertGranted}
          >
            <Text.Heading5 color={colors.primary}>Comprar</Text.Heading5>
          </GenericButton>
        </td>

        <td>
          <DropDown
            content={
              <>
                <DropDownItem
                  icon={<Wallet />}
                  title="Abrir carteira"
                  onClick={openWallet}
                />
                <DropDownItem
                  icon={<EditSVG strokeWidth={2} width={18} height={18} />}
                  title="Alterar nome da carteira"
                  onClick={() =>
                    handleOpenModal(
                      openEditWalletNameModal,
                      setOpenEditWalletNameModal
                    )
                  }
                  isDisabled={!criptoTransactionsGranted}
                />
                <DropDownDivider />
                <DropDownItem
                  icon={
                    wallet.isFavorite === 1 ? <StarFavoriteSVG /> : <Star />
                  }
                  title={
                    wallet.isFavorite === 1
                      ? 'Remover das favoritas'
                      : 'Adicionar as favoritas'
                  }
                  onClick={() => modifyWalletAction('isFavorite', wallet)}
                  isDisabled={!criptoTransactionsGranted}
                />
                <DropDownItem
                  icon={
                    wallet.isPublic === 1 ? (
                      <WorldBlocked />
                    ) : (
                      <WorldFavoriteSVG />
                    )
                  }
                  title={
                    wallet.isPublic === 1
                      ? 'Não receber trans. ext.'
                      : 'Receber trans. ext.'
                  }
                  onClick={() => modifyWalletAction('isPublic', wallet)}
                  isDisabled={!criptoTransactionsGranted}
                />
              </>
            }
          >
            <RoundButtonIcon buttonType="flat">
              <div className="display-center" style={{ cursor: 'pointer' }}>
                <DotsFloatingMenu />
              </div>
            </RoundButtonIcon>
          </DropDown>
        </td>
      </tr>

      <When condition={openDeleteModal}>
        <DeleteWallet
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          wallet={wallet}
        />
      </When>

      <When condition={openEditWalletNameModal}>
        <EditWalletName
          openModal={openEditWalletNameModal}
          setOpenModal={setOpenEditWalletNameModal}
          wallet={wallet}
        />
      </When>
    </>
  );
}

export default WalletItem;
