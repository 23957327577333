import * as Styles from './SecuritySettings.styles';
import * as Text from '~styles/text';
import React, { useEffect } from 'react';
import { Sections, useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { GenericButton } from '~components';
import LockerSVG from '~assets/svg/locker';
import PinSVG from '~assets/svg/pin';
import ShieldSVG from '~assets/svg/shield';
import { When } from 'react-if';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { usePermission } from '~helpers/permissionGate';
import colors from '~styles/colors';
import NotificationsSvg from '~assets/images/icons/headbar/notifications';
import BuildingIcon from '~assets/svg/buildingIcon';
import AvatarSVG from '~assets/svg/avatar';
import useDetectDevice from '~hooks/detectDevice';

const SecuritySettings: React.FC = () => {
  const { setOpenModalMultiStep, setSection, openModalMultiStep } =
    useActionsOnModalMultiStep();
  const orgId = getSelectedUserOrganization();

  const detect = useDetectDevice();

  const setOpenModal = (sections: Sections) => {
    if (sections) {
      setOpenModalMultiStep(true);
      setSection(sections);
    }
  };

  const { data: partnerInfos, refetch: refetchPartnerInfos } = useGetPartner();
  const { data: accountInfos } = useGetCapAccountInfo({
    userOrganizationId: Number(orgId),
  });

  const isTwoFaActive = partnerInfos?.data?.settings?.twoFA;

  const { granted: twoFaGranted } = usePermission('twofaDelete');

  useEffect(() => {
    refetchPartnerInfos();
  }, [openModalMultiStep]);
  
  return (
    <Styles.SecurityContainer>
      <Styles.RowSecurity>
        <div className="label-container">
          <div className="icon-title-gap">
            <AvatarSVG fill={colors.grayMedium} />
            <Text.Heading2>Segurança da sua conta</Text.Heading2>
          </div>

          <Text.Heading5 marginTop={15} display="inline">
            Estes são os dados de segurança atrelados à sua conta pessoal{' '}
            <Text.Heading5Bold color={colors.black}>
              {partnerInfos?.data?.socialName}
            </Text.Heading5Bold>
          </Text.Heading5>
        </div>

        <div className="content-container">
          <Text.Heading2>Senha de acesso à conta</Text.Heading2>

          <Text.Heading5 color={colors.grayMedium} marginTop={5}>
            Essa é a senha para acessar sua conta. Lembre-se de mantê-la segura
            e de não compartilhá-
            <br />
            la com outras pessoas.
          </Text.Heading5>
          <GenericButton
            marginTop={25}
            width={detect.isMobile() ? '100%' : '160px'}
            buttonType="inline"
            borderRadius={200}
            onClick={() => setOpenModal('changePasswordModals')}
          >
            Alterar Senha
          </GenericButton>

          <Text.Heading2 marginTop={25}>
            Autenticação em dois fatores
          </Text.Heading2>

          <Text.Heading5 color={colors.grayMedium} marginTop={5}>
            Esse é o código de autenticação que dá dupla camada de segurança
            para sua conta.
          </Text.Heading5>

          <div className="buttons-container">
            <When condition={!isTwoFaActive}>
              <GenericButton
                marginTop={25}
                buttonType="inline"
                borderRadius={200}
                onClick={() => setOpenModal('enableTwoFaModals')}
                disabled={isTwoFaActive || !twoFaGranted}
              >
                Habilitar 2-FA
              </GenericButton>
            </When>
            <When condition={isTwoFaActive}>
              <GenericButton
                marginTop={25}
                buttonType="inline"
                borderRadius={200}
                onClick={() => setOpenModal('disableTwoFaModals')}
                disabled={!isTwoFaActive}
              >
                Desabilitar 2-FA
              </GenericButton>
            </When>
            <When condition={isTwoFaActive}>
              <GenericButton
                marginTop={25}
                buttonType="inline"
                borderRadius={200}
                onClick={() => setOpenModal('recoveryCodesModals')}
              >
                Novos códigos de recuperação
              </GenericButton>
            </When>
          </div>
        </div>
      </Styles.RowSecurity>

      <div className="divider" />

      <Styles.RowSecurity>
        <div className="label-container">
          <div className="icon-title-gap">
            <BuildingIcon fill={colors.grayMedium} />
            <Text.Heading2>Segurança da organização</Text.Heading2>
          </div>

          <Text.Heading5 marginTop={15} display="inline">
            Estes são os dados de segurança referentes a organização atual{' '}
            <Text.Heading5Bold color={colors.black}>
              {accountInfos?.name}
            </Text.Heading5Bold>
          </Text.Heading5>
        </div>

        <div className="content-container">
          <Text.Heading2>PIN de 4 dígitos</Text.Heading2>

          <Text.Heading5 color={colors.grayMedium} marginTop={5}>
            Esse é o seu PIN para acessar a organização atual e confirmar
            transações na plataforma.
          </Text.Heading5>

          <div className="flex row gap-10 mt-25">
            <GenericButton
              borderRadius={200}
              width="145px"
              buttonType="inline"
              onClick={() => setOpenModal('changePinModals')}
            >
              Alterar PIN
            </GenericButton>

            <GenericButton
              marginLeft={10}
              width="170px"
              borderRadius={200}
              buttonType="inline"
              onClick={() => setOpenModal('sendNewPin')}
            >
              Recuperar PIN
            </GenericButton>
          </div>
        </div>
      </Styles.RowSecurity>
    </Styles.SecurityContainer>
  );
};

export default SecuritySettings;
