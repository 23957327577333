import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ENUS from './locales/en-us.json';
import ESES from './locales/es-es.json';
import PTBR from './locales/pt-br.json';

const resources = {
  'pt-BR': PTBR,
  'en-US': ENUS,
  'es-ES': ESES,
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'pt-BR',
  fallbackLng: 'en-US',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
