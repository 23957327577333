import { SVGProps } from 'react';

export const ExchangeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    fill={props.fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 10.5L5.50442 9.09381C6.44298 7.77983 7.95833 7 9.57309 7H18.5M18.5 7L15.26 4M18.5 7L15.26 10"
      stroke={props.stroke || '#272937'}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.5 12.5L17.9966 14.254C17.0467 15.3622 15.66 16 14.2003 16H5M5 16L8.24 19M5 16L8.24 13"
      stroke={props.stroke || '#272937'}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
