import { request, useMutation } from '~reactQuery';

import { WalletData } from '~reactQuery/types/business.types';
import { gql } from 'graphql-request';
import { useQueryClient } from 'react-query';

interface WalletsCreateInput {
  currency: string;
  name: string;
  data: WalletData;
  owner?: number;
  userOrganizationId: number;
}

interface WalletsCreateResponse {
  walletsCreate: string;
}

export function UseWalletsCreate() {
  const queryClient = useQueryClient();

  const mutation = gql`
    mutation WalletsCreate($params: WalletsCreateInput!) {
      walletsCreate(params: $params)
    }
  `;

  const fetch = async (params: WalletsCreateInput) => {
    const response = await request<WalletsCreateResponse>(mutation, {
      params,
    });
    return response.walletsCreate;
  };

  return useMutation('WalletsCreate', fetch, {
    onSettled: () => {
      queryClient.invalidateQueries(['getWallets']);
    },
  });
}
