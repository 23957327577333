import * as Style from './CounterInput.styles';
import * as Text from '~styles/text';

import { forwardRef, useEffect, useState } from 'react';

import { CounterInputProps } from './CounterInput.types';
import { When } from 'react-if';
import { colors } from '~styles';

const CounterInput = forwardRef((props: CounterInputProps, _ref) => {
  const [value, setValue] = useState(props.defaultValue || 0);

  const increment = () => {
    setValue((prev) => prev + 1);
  };

  const decrement = () => {
    if (value < 1) return;
    setValue((prev) => prev - 1);
  };

  useEffect(() => {
    if (!props.hookFormSetValue) return;
    props.hookFormSetValue(props.fieldName, value);
  }, [value]);

  return (
    <Style.Container>
      <Text.Paragraph style={{ marginBottom: 7 }} color={colors.grayMedium}>
        {props.label}
      </Text.Paragraph>
      <Style.ContainerInput
        backgroundColorPlus
        backgroundColorMinus={value >= 1}
        errorColor={!!props.error}
      >
        <span onClick={decrement} className="minus">
          -
        </span>
        <input
          {...props.hookForm}
          onChange={(e) => {
            setValue(Number(e.target.value));
          }}
          type="number"
          className="count"
          value={value}
        />
        <span onClick={increment} className="plus">
          +
        </span>
      </Style.ContainerInput>
      <When condition={!!props.error}>
        <div className="error-container">
          <Text.Small color={colors.danger}>{props.error}</Text.Small>
        </div>
      </When>
    </Style.Container>
  );
});
CounterInput.displayName = 'CounterInput';

export default CounterInput;
