import { useMutation, request, gql } from '~reactQuery';

interface BillValidateInput {
  userOrganizationId: number;
  code: string;
}

export interface BillValidateResponse {
  data: {
    id: number;
    user: number;
    status: string;
    authCode: string;
    idValidate: string;
    dataValidate: {
      id: string;
      payer: {
        name: string;
        documentNumber: string;
      };
      amount: number;
      charges: {
        discountAmount: number;
        fineAmountCalculated: number;
        interestAmountCalculated: number;
      };
      dueDate: string;
      assignor: string;
      digitable: string;
      maxAmount: string;
      minAmount: string;
      recipient: {
        name: string;
        documentNumber: string;
      };
      nextSettle: boolean;
      settleDate: string;
      businessHours: {
        end: string;
        start: string;
      };
      originalAmount: number;
      allowChangeAmount: boolean;
    };
    dataConfirm: {
      authenticationCode: string;
      settleDate: string;
    };
    transactionResult: string;
    createdAt: string;
    updatedAt: string;
  };
  messages: Array<string>;
  isValid: boolean;
}

export function useBillValidate() {
  const mutation = gql`
    mutation BillValidate($params: BillValidateInput!) {
      billValidate(params: $params) {
        data {
          id
          user
          status
          authCode
          idValidate
          dataValidate {
            id
            payer {
              name
              documentNumber
            }
            amount
            charges {
              discountAmount
              fineAmountCalculated
              interestAmountCalculated
            }
            dueDate
            assignor
            digitable
            maxAmount
            minAmount
            recipient {
              name
              documentNumber
            }
            nextSettle
            settleDate
            businessHours {
              end
              start
            }
            originalAmount
            allowChangeAmount
          }
          dataConfirm {
            authenticationCode
            settleDate
          }
          transactionResult
          createdAt
          updatedAt
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: BillValidateInput) => {
    const response = await request<{ billValidate: BillValidateResponse }>(
      mutation,
      {
        params,
      }
    );
    return response.billValidate;
  };

  return useMutation('useBillValidate', fetch);
}
