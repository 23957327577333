import * as Text from '~styles/text';

import { CheckSelect, LoadingSpinner, RoundButtonIcon } from '~components';
import { Else, If, Then } from 'react-if';
import React, { useState } from 'react';
import { formatCurrencyFn, getWalletIcon } from '~helpers/format/currency';

import BankIcon from '~assets/images/icons/24x24/capconta.svg';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { PixModalStepsProps } from '../../Transfer.types';
import { capitalizeName } from '~helpers/format/user';
import colors from '~styles/colors';
import { getSelectedUserOrganization } from '~helpers/device';
import { useFormContext } from 'react-hook-form';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';

function ChooseOriginWallet(props: PixModalStepsProps) {
  const userOrganizationId = getSelectedUserOrganization();
  const { setValue, getValues } = useFormContext();

  const { setPixStep: setCurrentStep } = props;
  const [select, setSelected] = useState('');

  const formValues = getValues();

  const goBack = () => {
    if (
      (formValues?.manualTransfer && !formValues?.contact) ||
      formValues?.manualDetails?.fromButton
    ) {
      setCurrentStep('fillManualTransferInfo');
    } else if (formValues?.contact || formValues?.keyResult) {
      setCurrentStep('pixMethod');
    }
  };

  const goNext = () => {
    setValue('origemWallet', select);
    setCurrentStep('valueSended');
  };

  const { data: walletList, isLoading: loadingWallets } = useGetWallets({
    prefCurrency: 'BRL',
    flag: 'list',
    userOrganizationId: Number(userOrganizationId),
  });

  const nameCapitalized = capitalizeName(
    formValues?.keyResult?.resolve?.key?.holderName ||
      formValues?.contact?.name ||
      formValues?.manualDetails?.name
  );

  const transferMethod = formValues?.manualTransfer
    ? 'TED ou Pix Manual'
    : 'Pix';

  const { data: accountInfos, isLoading: loadingCapAccount } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  function selectOption(opt: string) {
    if (select === opt) {
      setSelected('');
    } else {
      setSelected(opt);
    }
  }

  return (
    <>
      <ModalMultiStepHeader
        title="Escolher a carteira de origem"
        description={`Transferindo para ${nameCapitalized} via ${transferMethod}`}
        backButtonAction={goBack}
      />

      <div className="content-container">
        <Text.Heading3 marginTop={25} marginBottom={15}>
          Carteiras disponíveis
        </Text.Heading3>

        <CheckSelect
          title="CapConta"
          text="BRL"
          onClick={() => selectOption('capAccount')}
          selected={select === 'capAccount'}
          iconType="component"
          iconComponent={
            <RoundButtonIcon buttonSize="icon24v2" buttonType="active">
              <BankIcon />
            </RoundButtonIcon>
          }
          rightText={formatCurrencyFn(
            'BRL',
            accountInfos?.available,
            false,
            false,
            true
          )}
        />

        <Text.Heading3 marginTop={25} marginBottom={15}>
          Indisponíveis para este método
        </Text.Heading3>

        <If condition={loadingWallets}>
          <Then>
            <LoadingSpinner size={60} strokeSize={25} color={colors.primary} />
          </Then>

          <Else>
            {walletList?.map((w) => {
              if (w.funds <= 0) return null;
              return (
                <CheckSelect
                  key={w.walletAddress}
                  disabled
                  title={w.walletName}
                  text={w.currency.isoCode}
                  iconType="component"
                  onClick={() => {}}
                  iconComponent={
                    <RoundButtonIcon
                      buttonSize="iconWallet"
                      buttonType="active"
                    >
                      <div className="wallet-icon">
                        {getWalletIcon(w.currency.isoCode)}
                      </div>
                    </RoundButtonIcon>
                  }
                  rightText={formatCurrencyFn(
                    w.currency.isoCode,
                    String(w.funds)
                  )}
                />
              );
            })}
          </Else>
        </If>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={select === '' || loadingCapAccount}
      />
    </>
  );
}

export default ChooseOriginWallet;
