import * as React from 'react';

import { SVGProps } from 'react';

const CloseSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    fill={props.fill || 'none'}
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m3.94 5 .53.53L6.94 8l-2.47 2.47-.53.53L5 12.06l.53-.53L8 9.06l2.47 2.47.53.53L12.06 11l-.53-.53L9.06 8l2.47-2.47.53-.53L11 3.94l-.53.53L8 6.94 5.53 4.47 5 3.94 3.94 5Z"
      fill={props.fill || '#C3C6DB'}
    />
  </svg>
);

export default CloseSVG;
