import { gql, request } from '~reactQuery';

import { useInfiniteQuery } from 'react-query';

export interface GetWalletsTransactionInput {
  filterBy?: string;
  fromDate?: string;
  page?: number;
  perPage?: number;
  toDate?: string;
  userOrganizationId: number;
  walletAddr?: string;
  disableQuery?: boolean;
}

export interface GetWalletsTransactionResponse {
  userTransactions: {
    pages: { pages: number; totalTxes: number; txes: JSON[] };
  };
}

export const useGetWalletsTransaction = (
  params: GetWalletsTransactionInput
) => {
  const QUERY = gql`
    query UserTransactions($params: UserTransactionsInput!) {
      userTransactions(params: $params) {
        txes
        totalTxes
        pages
      }
    }
  `;

  const fetcher = async ({ pageParam = 1 }) => {
    if (params.disableQuery) {
      return null;
    }
    delete params.disableQuery;

    const response = await request<GetWalletsTransactionResponse>(QUERY, {
      params: { ...params, page: pageParam },
    });

    return response.userTransactions;
  };

  return useInfiniteQuery(
    [
      'getWalletsTransactions',
      params.walletAddr,
      params.page,
      params.filterBy,
      params.toDate,
    ],
    fetcher,
    {
      refetchInterval: 60000,

      getNextPageParam: (lastPage, pages) => {
        if (pages?.[0]?.pages === pages.length) {
          return undefined;
        }
        return pages.length + 1;
      },
    }
  );
};
