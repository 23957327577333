import * as Styles from '../Convert.styles';
import * as Text from '~styles/text';
import { PDFDownloadLink } from '@react-pdf/renderer';

import EndStepStatus from '~components/EndStepStatus';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ReceiptIcon from '~assets/images/icons/transactions/receipt.svg';
import { StatusProps } from '../Convert.types';
import colors from '~styles/colors';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { formatCurrencyFn } from '~helpers/format/currency';
import { ConvertReceipt } from '~assets/pdfs/convert/ConvertReceipt';
import dayjs from 'dayjs';

function Status(props: StatusProps) {
  const { conversionStatus, conversionDetails } = props;

  const { originWallet, destinationWallet, amount, convertedAmount } =
    conversionDetails;

  const originIsoCode = originWallet.currency.isoCode;
  const destinationIsoCode = destinationWallet.currency.isoCode;

  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  const statusInfo = {
    NOT_OK: {
      icon: <EndStepStatus animType="error" />,
      title: 'Conversão não realizada',
      singleButtonText: 'Concluir',
      singleButtonAction: () => {},
      description:
        'Não foi possível realizar sua conversão. Tente novamente em alguns instantes.',
    },
    OK: {
      icon: <EndStepStatus animType="success" />,
      title: 'Conversão realizada!',
      singleButtonText: 'Concluir',
      singleButtonAction: () => {},
      description: `Você converteu ${formatCurrencyFn(
        originIsoCode,
        amount
      )} por ${formatCurrencyFn(
        destinationIsoCode,
        convertedAmount
      )} e o valor já está disponível em sua CapWallet ${
        destinationWallet.walletName
      }.`,
    },
  };

  return (
    <>
      <div className="convert-body">
        <Styles.StatusContentWrapper>
          {statusInfo[conversionStatus].icon}
          <Text.Heading1Bold marginTop={25}>
            {statusInfo[conversionStatus].title}
          </Text.Heading1Bold>
          <Text.Heading4 marginTop={25} textAlign="center">
            {statusInfo[conversionStatus].description}
          </Text.Heading4>
          {conversionStatus === 'OK' && (
            <PDFDownloadLink
              document={<ConvertReceipt convertDetails={conversionDetails} />}
              fileName={`convert-receipt-${dayjs().format(
                'DD-MM-YYYY hh:mm'
              )}.pdf`}
            >
              <div className="receipt-button">
                <ReceiptIcon />
                <Text.Heading5 marginLeft={10} color={colors.primary}>
                  Baixar comprovante
                </Text.Heading5>
              </div>
            </PDFDownloadLink>
          )}
        </Styles.StatusContentWrapper>
      </div>
      <ModalMultiStepBottom
        singleButtonText="Concluir"
        singleButtonAction={() => setOpenModalMultiStep(false)}
      />
    </>
  );
}

export default Status;
