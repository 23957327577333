import * as Text from '~styles/text';

import React, { useEffect, useState } from 'react';

import { EditPinProps } from '../RecoveryPin.types';
import { GenericButton } from '~components/index';
import { InputPin } from '~components/Inputs';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { SuccessToast } from '~components/Toast';
import UserPic from '~components/UserPic';
import colors from '~styles/colors';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useChangeOrganizationPin } from '~reactQuery/mutations/business/useChangeOrganizationPin';
import { usePinReset } from '~reactQuery/mutations/business/usePinReset';
import useTimer from '~hooks/useTimer';

function EditPin(props: EditPinProps) {
  const { setCurrentStep, stepParams, orgName, orgId, userOrganizationsId } =
    props;
  const [pins, setPins] = useState({
    newPin: '',
    oldPin: '',
  });
  const { timeLeft, restartTimer, counting } = useTimer(60);
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  const { mutateAsync: changeOrgPin, isLoading: loadingChangePin } =
    useChangeOrganizationPin();
  const { mutateAsync: sendEmailReset, isLoading: loadingSendPin } =
    usePinReset();

  useEffect(() => {
    restartTimer();
  }, []);

  async function changePin() {
    try {
      await changeOrgPin({
        newPin: pins.newPin,
        oldPin: pins.oldPin,
        userOrganizationId: userOrganizationsId,
      });

      restartTimer();

      setCurrentStep('status', { status: 'OK' });
    } catch (error) {
      // console.log(error);
    }
  }

  async function resendPinCode() {
    try {
      const resp = await sendEmailReset({
        userOrganizationId: userOrganizationsId,
        documentNumber: String(stepParams?.cpf?.replace(/\D/g, '')),
      });

      restartTimer();

      SuccessToast({
        title: 'PIN enviado',
        subtitle: `Um novo código PIN foi enviado para o e-mail: ${resp.data.email}`,
        autoCloseEnable: true,
      });
    } catch (error) {
      // console.log(error);
    }
  }

  return (
    <>
      <ModalMultiStepHeader
        title="Cadastrar novo PIN"
        description={`Seu novo PIN foi enviado para ${stepParams.email}. Você já pode utilizar ele ou gerar um novo PIN personalizado abaixo.`}
      />
      <div className="recovery-pin-body">
        <div className="divider" />
        <div className="flex row align-center mt-15 mb-15">
          <UserPic
            marginRight={15}
            width={35}
            height={35}
            borderRadius={10}
            name={orgName[0]}
          />
          <Text.Heading4>{orgName}</Text.Heading4>
        </div>
        <div className="divider" />

        <Text.Heading5 marginBottom={10} marginTop={25}>
          PIN Recebido por e-mail
        </Text.Heading5>

        <InputPin
          autoFocus
          size={4}
          secret
          onChange={(pin) => {
            setPins((prev) => ({ ...prev, oldPin: pin }));
          }}
          onFinish={(pin) => {
            setPins((prev) => ({ ...prev, oldPin: pin }));
          }}
        />

        <Text.Heading5 marginBottom={10} marginTop={25}>
          Novo PIN personalizado (opcional)
        </Text.Heading5>

        <InputPin
          size={4}
          secret
          onChange={(pin) => {
            setPins((prev) => ({ ...prev, newPin: pin }));
          }}
          onFinish={(pin) => {
            setPins((prev) => ({ ...prev, newPin: pin }));
          }}
        />
        <GenericButton
          onClick={resendPinCode}
          buttonType="default"
          marginBottom={10}
          marginTop={25}
          disabled={counting}
          buttonLoading={loadingSendPin}
          noPadding
        >
          <Text.Heading5 color={colors.primary}>
            {`Enviar PIN novamente ${counting ? `(${timeLeft})` : ''}`}
          </Text.Heading5>
        </GenericButton>
      </div>
      <ModalMultiStepBottom
        singleButtonText="Personalizar PIN"
        singleButtonWidth="183px"
        singleButtonAlignment="flex-end"
        doubleButtonTextOne="Usar PIN recebido"
        doubleButtonOneAction={() => setOpenModalMultiStep(false)}
        singleButtonAction={changePin}
        singleButtonLoading={loadingChangePin}
        disableSingleButton={pins.newPin.length < 4 || pins.oldPin.length < 4}
      />
    </>
  );
}

export default EditPin;
