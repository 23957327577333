const ExternalWalletReceiveSVG = (props: React.SVGProps<SVGSVGElement>) => {
  const { fill = 'none', stroke = '#11D593', height = 24, width = 24 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M22.25 4L20 6.25L17.75 4"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20 6L20 1"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14.6 7C14.9 6.7 15 6.4 15 6C15 4.3 11.9 3 8 3C4.1 3 1 4.3 1 6C1 7.7 4.1 9 8 9C8.8 9 9.6 8.9 10.3 8.8"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M1 6.5V9.8C1 11.5 4.1 13 8 13C8 13 8 13 8.1 13"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M1 10.5V13.8C1 15.5 4.1 17 8 17C8.2 17 8.4 17 8.7 17"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M1 14.5V17.8C1 19.5 4.1 21 8 21C9.5 21 11 20.8 12.1 20.4"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15 21C18.866 21 22 17.866 22 14C22 10.134 18.866 7 15 7C11.134 7 8 10.134 8 14C8 17.866 11.134 21 15 21Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default ExternalWalletReceiveSVG;
