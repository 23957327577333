import styled, { css } from 'styled-components';
import { ContainerCheckProps } from './CheckSelectSmall.types';

export const Container = styled.div<ContainerCheckProps>`
  border-radius: 8px;
  height: auto;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all ease-out 0.3s;
  display: flex;
  align-items: center;
`;

export const ContainerText = styled.div`
  width: 100%;
  height: 100%;

  .text-wrapper {
    display: flex;
    flex-direction: column;
  }
`;

export const ContainerIcon = styled.div<ContainerCheckProps>`
  .scale-icon {
    scale: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
    `}

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-right: 10px;
`;
