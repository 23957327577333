import * as Style from '../GuestRegister.styles';
import * as Text from '~styles/text';
import { useEffect, useState } from 'react';
import colors from '~styles/colors';
import { breakpoints } from '~styles/metrics';
import useWindowDimensions from '~hooks/windowDimension';
import { Else, If, Then } from 'react-if';
import LoadingSpinner from '~components/LoadingSpinner';
import QRCode from 'react-qr-code';
import { GenericButton } from '~components/Buttons';
import { FooterButton } from '~components/Footers';
import { copyText } from '~helpers/messages';
import SecuritySVG from '~assets/svg/security';
import CopySVG from '~assets/svg/copy';
import { useTwoFAGenerateSecret } from '~reactQuery/mutations/settings/useTwoFAGenerateSecret';
import { encode } from 'hi-base32';
import { CommonStepParams } from '../GuestRegister.types';

const Register2FA = ({ stepParams, goToStep }: CommonStepParams) => {
  const { width } = useWindowDimensions();
  const [twoFACode, setTwoFACode] = useState('');

  const { mutateAsync: generateTwoFACode, isLoading: isLoadingGenerate } =
    useTwoFAGenerateSecret();

  const generateCode = async () => {
    try {
      if (stepParams.partner && stepParams.token) {
        const resp = await generateTwoFACode({
          userOrganizationId: stepParams.partner?.userOrganizations[0]?.id,
          settingsId: stepParams.partner?.settings?.id,
          token: stepParams.token,
        });

        if (resp?.data) setTwoFACode(encode(resp.data));
      }
    } catch (error) {
      // consoe.log(error)
    }
  };

  const nameTwoFa = 'Capitual-Biz-Acc';

  const copyCode = () => {
    copyText(twoFACode, 'Código de autenticação de 2 fatores copiado');
  };

  const onSubmit = () => goToStep('validate2FA');

  useEffect(() => {
    generateCode();
  }, []);

  return (
    <Style.Container>
      <div className="main-icon">
        <SecuritySVG />
      </div>

      <Text.Heading1Medium marginTop={10} marginBottom={30}>
        Mais segurança em sua conta
      </Text.Heading1Medium>

      <Text.Heading5
        color={colors.black}
        marginBottom={30}
        lineHeight={140}
        width={breakpoints.desktop < width ? '100%' : ''}
      >
        Com o código de autenticação de dois fatores sua conta estará mais
        segura.
        <br />
        <br />
        Use um aplicativo de autenticação (como Authy ou Authenticator) e siga
        as instruções abaixo.
      </Text.Heading5>

      <div className="enable-two-fa">
        <If condition={isLoadingGenerate}>
          <Then>
            <div className="justify-items-center">
              <LoadingSpinner
                color={colors.primary}
                size={60}
                strokeSize={25}
              />
            </div>
          </Then>
          <Else>
            <div className="justify-items-center">
              <QRCode
                size={168}
                value={`otpauth://totp/${nameTwoFa}?secret=${twoFACode}`}
              />
            </div>
            <div className="container-codes">
              <Text.Heading4>{twoFACode}</Text.Heading4>
            </div>
            <div className="justify-items-center">
              <GenericButton
                onClick={copyCode}
                buttonType="default"
                leftIcon={<CopySVG height={14} />}
              >
                Copiar código
              </GenericButton>
            </div>
          </Else>
        </If>
      </div>

      <FooterButton
        onClickGenericButton={() => onSubmit()}
        leftButtonContent="Voltar"
        onClickLeftButton={() => goToStep('createPin')}
        genericButtonType="secondary"
        genericButtonContent="Próximo"
        loadingGenericButton={isLoadingGenerate}
      />
    </Style.Container>
  );
};

export default Register2FA;
