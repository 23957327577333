import * as React from 'react';

import { SVGProps } from 'react';

const WorldSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.143} width={30} height={30} rx={15} fill="#F3F4F9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.143 9a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm-4.072 3.72a4.665 4.665 0 0 1 7.05-1.312 1.36 1.36 0 0 1-.378.415 1.75 1.75 0 0 1-.984.318h-.044v.044c0 .95-.786 1.455-1.182 1.588l-.03.01v1.299h1.616v1.639l.053-.01c.797-.162 1.357.007 1.733.277.122.088.226.187.312.29a4.666 4.666 0 0 1-4.434 2.375v-1.782l-1.616-1.63V15.02l-2.096-2.3Z"
      fill="#272937"
    />
  </svg>
);

export default WorldSVG;
