import * as Text from '~styles/text';

import AuthValidate from '~components/AuthValidate';
import { CriptoWithdrawStepsProps } from '~src/common/modals/multiStep/Transfer/Transfer.types';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { WalletType } from '~reactQuery/types/wallets.types';
import { getSelectedUserOrganization } from '~helpers/device';
import { parseError } from '~reactQuery/index';
import { useFormContext } from 'react-hook-form';
import { useNewWithdraw } from '~reactQuery/mutations/withdraw/useNewWithdraw';
import { useState } from 'react';
import { useUserAuthTypes } from '~hooks/useUserAuthTypes';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { LoadingSpinner } from '~components/index';
import colors from '~styles/colors';

const ConfirmPin = ({ setCriptoWithdrawStep }: CriptoWithdrawStepsProps) => {
  const [loading, setLoading] = useState(false);
  const { auth } = useUserAuthTypes();
  const userOrganizationId = getSelectedUserOrganization();
  const { params } = useActionsOnModalMultiStep();
  const isComingFromCapWallet = params?.comingFrom === 'capWallet';

  const goBack = () => setCriptoWithdrawStep('review');

  const { getValues, setValue } = useFormContext();

  const [codes, setCodes] = useState({
    pin: '',
    twoFa: '',
  });
  const [hasError, setHasError] = useState({
    pin: '',
    twoFa: '',
  });

  const originWallet = getValues('originWallet') as WalletType;
  const selectedWallet = getValues('selectedWallet') as WalletType;
  const amountToConvert = getValues('amountToWithdraw');

  const walletAddressToWithdraw = getValues(
    'walletAddressToWithdraw'
  ) as string;

  const { mutateAsync: makeWithdraw, isLoading } = useNewWithdraw();

  const disableButton =
    (auth.includes('2-FA') && codes.twoFa?.length !== 6) ||
    codes.pin.length !== 4;

  const handleMakeWithdraw = async () => {
    const { pin, twoFa } = codes;

    setHasError({
      pin: '',
      twoFa: '',
    });

    if (disableButton) return;

    const twoFA = twoFa ? { token: twoFa } : {};
    setLoading(true);
    try {
      const withdrawId = await makeWithdraw({
        amount: String(amountToConvert),
        pin,
        ...twoFA,
        txData: {},
        amountInCurrency: 'to',
        userOrganizationId: Number(userOrganizationId),
        destination: walletAddressToWithdraw,
        type: 'crypto',
        comments: '',
        currency: selectedWallet?.currency?.isoCode,
        wallet: originWallet?.walletAddress,
      });
      setLoading(false);
      if (!withdrawId) {
        setValue('status', 'FAILED');
        setCriptoWithdrawStep('withdrawStatus');
        return;
      }

      setValue('timestamp', new Date());
      setValue('withdrawId', withdrawId);
      setValue('status', 'SUCCESS');
      setCriptoWithdrawStep('withdrawStatus');
    } catch (error: any) {
      setLoading(false);
      const errorKey = parseError(error);

      if (errorKey === 'INVALID_PIN') {
        setHasError((prev) => ({ ...prev, pin: 'Pin inválido' }));
      }

      if (errorKey === 'INVALID_2FA_TOKEN') {
        setHasError((prev) => ({ ...prev, twoFa: '2-FA inválido' }));
      }
    }
  };

  return (
    <>
      <ModalMultiStepHeader
        title={
          !isComingFromCapWallet
            ? 'Saque para Carteira de Cripto Externa'
            : 'Confirmação do Envio'
        }
        backButtonAction={goBack}
      />
      <div className="cripto-withdraw-container-step">
        <Text.Paragraph marginBottom={20}>
          Preencha as confirmações de segurança
        </Text.Paragraph>

        {!loading ? (
          <AuthValidate
            authTypes={auth}
            onChangeCodes={(pin, twoFa) => setCodes({ pin, twoFa })}
            pinErrorMessage={hasError.pin}
            twoFaErrorMessage={hasError.twoFa}
          />
        ) : (
          <div className="container-loading">
            <LoadingSpinner color={colors.primary} size={60} strokeSize={25} />
          </div>
        )}

        <ModalMultiStepBottom
          singleButtonText="Próximo"
          singleButtonWidth="100%"
          singleButtonAlignment="flex-end"
          singleButtonLoading={isLoading}
          disableSingleButton={disableButton || isLoading}
          singleButtonAction={handleMakeWithdraw}
        />
      </div>
    </>
  );
};

export default ConfirmPin;
