import * as Style from '../../Transfer.styles';
import * as Text from '~styles/text';

import { Else, If, Then } from 'react-if';

import EndStepStatus from '~components/EndStepStatus';
import LoadingSpinner from '~components/LoadingSpinner';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReceiptIcon from '~assets/images/icons/transactions/receipt.svg';
import { TransferReceipt } from '~assets/pdfs/transfer/TransferReceipt';
import { capitalizeName } from '~helpers/format/user';
import colors from '~styles/colors';
import dayjs from 'dayjs';
import { getSelectedUserOrganization } from '~helpers/device';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useCreateContact } from '~reactQuery/mutations/contacts/useCreateContact';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetBrlBanks } from '~reactQuery/queries/bank/useGetBrlBank';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetStatementSpecific } from '~reactQuery/queries/bank/useGetStatementSpecific';

function Status(props: any) {
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();
  const userOrg = getSelectedUserOrganization();
  const { getValues } = useFormContext();
  const formValues = getValues();

  const { mutateAsync: createContact } = useCreateContact();
  const { data: bankList } = useGetBrlBanks();
  const { data: accountDetails, isLoading: loadingGetAccount } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrg),
    });
  const { data: transferSpecificDetails } = useGetStatementSpecific({
    authCode: formValues?.transferResp?.authCode,
    userOrganizationId: Number(userOrg),
  });

  const showDownload = transferSpecificDetails && !loadingGetAccount;
  const closeModal = () => setOpenModalMultiStep(false);

  const pixKey = formValues?.keyResult?.keyValue;
  const methodOfTransfer = formValues?.methodOfTransfer;
  const name =
    formValues?.keyResult?.resolve?.key?.holderName ||
    formValues?.manualDetails?.name ||
    formValues?.contact?.name;

  async function saveAsContact() {
    const docNum =
      transferSpecificDetails?.data?.requisite?.key?.holderDocument ||
      transferSpecificDetails?.data?.requisite?.holderDocument;

    if (formValues?.saveContact && docNum) {
      const bankInfosFiltered = bankList?.filter(
        (b) =>
          b?.bankCompe === formValues?.manualDetails?.bankCode ||
          b?.bankIspb === formValues?.manualDetails?.bankIspb
      )?.[0];

      switch (methodOfTransfer) {
        case 'PIX':
          await createContact({
            userOrganizationId: Number(userOrg),
            name: String(name).toLocaleUpperCase(),
            documentNumber: docNum,
            contactData: {
              pixType: formValues?.keyResult?.resolve?.keyType,
              type: 'PIX_KEY',
              pixValue: pixKey,
            },
          });

          break;

        case 'PIX Manual':
          await createContact({
            userOrganizationId: Number(userOrg),
            name: String(name).toLocaleUpperCase(),
            documentNumber: docNum,
            contactData: {
              type: 'PIX_MANUAL',
              accountType: formValues?.manualDetails?.accountType,
              bankCode: bankInfosFiltered?.bankCompe,
              bankIspb: bankInfosFiltered?.bankIspb,
              branch: formValues?.manualDetails?.branch,
              accountNumber: formValues?.manualDetails?.accountNumber,
            },
          });

          break;

        case 'TED':
          await createContact({
            userOrganizationId: Number(userOrg),
            name: String(name).toLocaleUpperCase(),
            documentNumber: docNum,
            contactData: {
              type: 'TED',
              accountType: formValues?.manualDetails?.accountType,
              bankCode: bankInfosFiltered?.bankCompe,
              bankIspb: bankInfosFiltered?.bankIspb,
              branch: formValues?.manualDetails?.branch,
              accountNumber: formValues?.manualDetails?.accountNumber,
            },
          });

          break;

        default:
          break;
      }
    }
  }

  // Teste
  // DIGUIN
  const digo = 'lindo';

  useEffect(() => {
    saveAsContact();
  }, [transferSpecificDetails]);

  return (
    <>
      <div className="convert-container">
        <Style.StatusContentWrapper>
          <EndStepStatus animType="success" />
          <Text.Heading1Bold marginTop={25}>
            Transferência realizada!
          </Text.Heading1Bold>
          <Text.Heading4 marginTop={25} textAlign="center">
            {`Você transferiu ${formValues?.amount} para ${capitalizeName(
              name
            )} via ${methodOfTransfer}.`}
          </Text.Heading4>
          <div className="receipt-button">
            <If condition={showDownload}>
              <Then>
                <PDFDownloadLink
                  document={
                    <TransferReceipt
                      accountDetails={accountDetails}
                      transferDetails={transferSpecificDetails}
                      status={transferSpecificDetails?.status}
                      authCode={formValues?.transferResp?.authCode}
                    />
                  }
                  fileName={`transfer-voucher-${dayjs().format(
                    'DD-MM-YYYY'
                  )}.pdf`}
                >
                  {({ loading }) => {
                    return loading ? (
                      <LoadingSpinner color={colors.black} />
                    ) : (
                      <div className="receipt-button">
                        <ReceiptIcon />
                        <Text.Heading5 marginLeft={10} color={colors.primary}>
                          Baixar comprovante
                        </Text.Heading5>
                      </div>
                    );
                  }}
                </PDFDownloadLink>
              </Then>
              <Else>
                <LoadingSpinner color={colors.black} />
              </Else>
            </If>
          </div>
        </Style.StatusContentWrapper>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Concluir"
        singleButtonAction={closeModal}
      />
    </>
  );
}

export default Status;
