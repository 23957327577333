import { colors } from '~styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 62px;

  padding: 18px 30px 17px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  background: ${colors.white};

  span {
    font-family: caption;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.space-between {
      justify-content: space-between;
      width: 100%;
    }
  }
`;
