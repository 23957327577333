import * as Style from './InputSelect.styles';
import * as Text from '~styles/text';

import { Else, If, Then, When } from 'react-if';
import { forwardRef, useState } from 'react';

import ArrowDown from '~assets/images/icons/arrow-down-black.svg';
import { InputSelectProps } from './InputSelect.types';
import { colors } from '~styles';

const InputSelect = forwardRef<HTMLSelectElement, InputSelectProps>(
  (props, _ref) => {
    const [arrowToggle, setArrowToggle] = useState(false);

    return (
      <Style.SelectContainer {...props} arrowToggle={arrowToggle}>
        <Style.LabelContent
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <When condition={!!props.label}>
            <Text.Paragraph color={colors.grayMedium}>
              {props.label}
            </Text.Paragraph>
          </When>
        </Style.LabelContent>

        <div
          className="input-wrapper"
          onBlur={() => arrowToggle && setArrowToggle(false)}
        >
          <Style.Select
            ref={_ref}
            style={
              props.error
                ? {
                    backgroundColor: '#ff637810',
                    borderColor: colors.danger,
                  }
                : {}
            }
            required
            {...props}
            {...props.hookForm}
            onInput={(e) => {
              if (props.onChange) props.onChange(e);
              if (props.hookForm) props.hookForm.onChange(e);
              setArrowToggle(false);
            }}
            onClick={() => setArrowToggle(!arrowToggle)}
          >
            <option value="" disabled>
              Selecione
            </option>

            <If condition={!!props.sections}>
              <Then>
                {props?.sections?.map((s) => {
                  return (
                    <optgroup key={s.name} label={s.name}>
                      {s.option.map((opt) => {
                        return (
                          <option key={opt.value} value={opt.value}>
                            {opt.label}
                          </option>
                        );
                      })}
                    </optgroup>
                  );
                })}
              </Then>

              <Else>
                {props?.options?.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Else>
            </If>
          </Style.Select>
          <div
            style={props?.styleIcon}
            className={arrowToggle ? 'arrow active' : 'arrow'}
          >
            <ArrowDown />
          </div>
        </div>
        <When condition={!!props.error}>
          <Text.Small marginTop={5} color={colors.danger}>
            {props.error}
          </Text.Small>
        </When>
      </Style.SelectContainer>
    );
  }
);

InputSelect.displayName = 'InputSelect';

export default InputSelect;
