import * as Text from '~styles/text';

import React, { useState } from 'react';

import { AskCpfProps } from '../RecoveryPin.types';
import { BigInput } from '~components/Inputs';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import UserPic from '~components/UserPic';
import colors from '~styles/colors';
import { cpfMask } from '~helpers/format/masks';
import { usePinReset } from '~reactQuery/mutations/business/usePinReset';
import { validateCPF } from '~validations/personalValidation';

function AskCpf(props: AskCpfProps) {
  const { setCurrentStep, orgId, orgName, userOrganizationsId } = props;
  const [cpf, setCpf] = useState('');
  const { mutateAsync: sendEmailReset, isLoading: loadingSendPin } =
    usePinReset();

  async function goNext() {
    try {
      const resp = await sendEmailReset({
        userOrganizationId: userOrganizationsId,
        documentNumber: cpf.replace(/\D/g, ''),
      });

      setCurrentStep('editPin', { email: resp.data.email, cpf });
    } catch (error) {
      // console.log(error);
    }
  }

  return (
    <>
      <ModalMultiStepHeader
        title="Recuperar PIN"
        description="Digite o CPF para enviarmos um novo PIN."
      />
      <div className="recovery-pin-body">
        <div className="divider" />
        <div className="flex row align-center mt-15 mb-15">
          <UserPic
            marginRight={15}
            width={35}
            height={35}
            borderRadius={10}
            name={orgName?.[0] || 'U'}
          />
          <Text.Heading4>{orgName}</Text.Heading4>
        </div>
        <div className="divider" />
        <Text.Paragraph
          marginTop={30}
          marginBottom={7}
          color={colors.grayMedium}
        >
          Digite seu CPF
        </Text.Paragraph>
        <BigInput
          value={cpf}
          onChange={(e) => setCpf(cpfMask(e.target.value))}
          placeholder="000.000.000-00"
          maxLength={14}
        />
      </div>
      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={!validateCPF(cpf)}
        singleButtonLoading={loadingSendPin}
      />
    </>
  );
}

export default AskCpf;
