import * as yup from 'yup';

import { BusinessType } from '~reactQuery/types/business.types';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import {
  isAnValidSite,
  ageValidate,
} from '~helpers/validate/genericValidations';
import { validaCNPJ } from '~validations/personalValidation';

dayjs.extend(customParseFormat);

export const kycCompanySchema = yup
  .object()
  .shape({
    declaredAnnualBilling: yup
      .string()
      .typeError('Esse campo é obrigatório')
      .required('Esse campo é obrigatório'),
    participationPercentage: yup.string(),
    tradingName: yup
      .string()
      .typeError('Esse campo é obrigatório')
      .required('Esse campo é obrigatório')
      .min(4, 'Precisa ter ao menos 4 caracteres'),
    businessName: yup
      .string()
      .typeError('Esse campo é obrigatório')
      .required('Esse campo é obrigatório')
      .min(4, 'Precisa ter ao menos 4 caracteres'),
    documentNumber: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(18, 'Precisa ter ao menos 14 caracteres')
      .test('is-valid-date', 'CNPJ inválido', (value) => {
        return validaCNPJ(String(value));
      }),
    businessCnae: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(9, 'Precisa ter 7 caracteres'),
    companyStartDate: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(10, 'Precisa ter 8 caracteres')
      .test('is-valid-date', 'Insira uma data válida', (value) => {
        if (!dayjs(value, 'DD/MM/YYYY', true).isValid()) {
          return false;
        }
        return true;
      }),
    phone: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(14, 'Precisa ter ao menos 10 caracteres'),
    zipCode: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(10, 'Precisa ter ao menos 8 caracteres'),
    city: yup
      .string()
      .typeError('Esse campo é obrigatório')
      .required('Esse campo é obrigatório')
      .min(4, 'Precisa ter ao menos 4 caracteres'),
    site: yup
      .string()
      .typeError('Esse campo é obrigatório')
      .test('is-valid-link', 'Url inválida', (value) => {
        if (!value) return true;
        return isAnValidSite(value);
      }),
    state: yup
      .string()
      .typeError('Esse campo é obrigatório')
      .required('Esse campo é obrigatório')
      .min(2, 'Precisa ter 2 caracteres')
      .matches(/^[aA-zZ\s]+$/, 'Somente letras são permitidas'),
    addressLine: yup
      .string()
      .typeError('Esse campo é obrigatório')
      .required('Esse campo é obrigatório'),
    businessEmail: yup
      .string()
      .typeError('Esse campo é obrigatório')
      .email('Email inválido')
      .required('Esse campo é obrigatório'),
    buildingNumber: yup.mixed().nullable(),
    neighborhood: yup
      .string()
      .typeError('Esse campo é obrigatório')
      .required('Esse campo é obrigatório'),
    businessType: yup.string().required('Esse campo é obrigatório'),
    businessSize: yup.string().required('Esse campo é obrigatório'),
    country: yup.string().required('Esse campo é obrigatório'),
    countryCode: yup.string().required('Esse campo é obrigatório'),
  })
  .required();

export const kycDocsSchema = (businessType: BusinessType | '') => {
  return yup
    .object()
    .shape({
      fileCNPJ: yup
        .mixed()
        .test('required', 'Esse campo é obrigatório', (value) => {
          return value && value.length;
        }),
      fileQSA: yup
        .mixed()
        .test('required', 'Esse campo é obrigatório', (value) => {
          return value && value.length;
        }),
      incomes: yup
        .mixed()
        .test('required', 'Esse campo é obrigatório', (value) => {
          return value && value.length;
        }),
      ...(businessType === 'LTDA' && {
        lastContractChange: yup
          .mixed()
          .test('required', 'Esse campo é obrigatório', (value) => {
            return value && value.length;
          }),
        socialContract: yup
          .mixed()
          .test('required', 'Esse campo é obrigatório', (value) => {
            return value && value.length;
          }),
      }),
      ...(businessType === 'SA' && {
        lastElection: yup
          .mixed()
          .test('required', 'Esse campo é obrigatório', (value) => {
            return value && value.length;
          }),
        socialStatute: yup
          .mixed()
          .test('required', 'Esse campo é obrigatório', (value) => {
            return value && value.length;
          }),
      }),
    })
    .required();
};

export const kycPartnersSchema = yup
  .object()
  .shape({
    partnersList: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            socialName: yup
              .string()
              .required('Esse campo é obrigatório')
              .min(4, 'Esse é realmente seu nome completo?'),
            email: yup
              .string()
              .required('Esse campo é obrigatório')
              .email('Email inválido'),
            motherName: yup
              .string()
              .required('Esse campo é obrigatório')
              .min(4, 'Esse é realmente o nome completo da sua mãe?'),
            birthDate: yup
              .string()
              .required('Esse campo é obrigatório')
              .min(10, 'Precisa ter 8 caracteres')
              .test('is-valid-date', 'Data inválida', (value) => {
                if (!dayjs(value, 'DD/MM/YYYY', true).isValid()) {
                  return false;
                }
                return true;
              })
              .test(
                'age-test-over-18',
                'É preciso ter mais que 18 anos',
                (value) => {
                  return (
                    dayjs().diff(dayjs(value).format('DD/MM/YYYY'), 'years') >=
                    18
                  );
                }
              ),
            city: yup
              .string()
              .required('Esse campo é obrigatório')
              .min(3, 'Precisa ter ao menos 3 caracteres'),
            state: yup
              .string()
              .required('Esse campo é obrigatório')
              .min(2, 'Precisa ter 2 caracteres'),
            fileFrontDocument: yup
              .mixed()
              .test('required', 'Esse campo é obrigatório', (value) => {
                return value && value.length;
              }),
            fileBackDocument: yup
              .mixed()
              .test('required', 'Esse campo é obrigatório', (value) => {
                return value && value.length;
              }),
            addressLine: yup.string().required('Esse campo é obrigatório'),
            buildingNumber: yup.string().required('Esse campo é obrigatório'),
            neighborhood: yup.string().required('Esse campo é obrigatório'),
            documentType: yup.string().required('Esse campo é obrigatório'),
            countryCode: yup.string().required('Esse campo é obrigatório'),
            number: yup.string().required('Esse campo é obrigatório'),
            country: yup.string().required('Esse campo é obrigatório'),
            zipCode: yup.string().required('Esse campo é obrigatório'),
          })
          .required()
      )
      .required(),
  })
  .required();

export const kycPartnersTypeBizSchema = yup
  .object()
  .shape({
    partnersTypeBizList: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            tradingName: yup
              .string()
              .typeError('Esse campo é obrigatório')
              .required('Esse campo é obrigatório')
              .min(4, 'Precisa ter ao menos 4 caracteres'),
            businessName: yup
              .string()
              .typeError('Esse campo é obrigatório')
              .required('Esse campo é obrigatório')
              .min(4, 'Precisa ter ao menos 4 caracteres'),
            documentNumber: yup
              .string()
              .required('Esse campo é obrigatório')
              .min(18, 'Precisa ter ao menos 14 caracteres')
              .test('is-valid-cnpj', 'CNPJ inválido!', (value) => {
                return validaCNPJ(String(value));
              }),
            declaredAnnualBilling: yup
              .string()
              .typeError('Esse campo é obrigatório')
              .required('Esse campo é obrigatório'),
            businessCnae: yup
              .string()
              .required('Esse campo é obrigatório')
              .min(9, 'Precisa ter 7 caracteres'),
            companyStartDate: yup
              .string()
              .required('Esse campo é obrigatório')
              .min(10, 'Precisa ter 8 caracteres')
              .test('is-valid-date', 'Data inválida', (value) => {
                if (!dayjs(value, 'DD/MM/YYYY', true).isValid()) {
                  return false;
                }
                return true;
              }),
            phone: yup
              .string()
              .required('Esse campo é obrigatório')
              .min(14, 'Precisa ter ao menos 10 caracteres'),
            country: yup.string().required('Esse campo é obrigatório'),
            zipCode: yup
              .string()
              .required('Esse campo é obrigatório')
              .min(10, 'Precisa ter ao menos 8 caracteres'),
            city: yup
              .string()
              .typeError('Esse campo é obrigatório')
              .required('Esse campo é obrigatório')
              .min(4, 'Precisa ter ao menos 4 caracteres'),
            site: yup
              .string()
              .typeError('Esse campo é obrigatório')
              .test('is-valid-link', 'Insira uma Url válida!', (value) => {
                if (!value) return true;
                return isAnValidSite(value);
              }),
            state: yup
              .string()
              .typeError('Esse campo é obrigatório')
              .required('Esse campo é obrigatório')
              .min(2, 'Precisa ter 2 caracteres')
              .matches(/^[aA-zZ\s]+$/, 'Somente letras são permitidas'),
            addressLine: yup
              .string()
              .typeError('Esse campo é obrigatório')
              .required('Esse campo é obrigatório'),
            businessEmail: yup
              .string()
              .typeError('Esse campo é obrigatório')
              .email('Email inválido')
              .required('Esse campo é obrigatório'),
            buildingNumber: yup
              .number()
              .typeError('Tem que ser um número')
              .required('Esse campo é obrigatório'),
            neighborhood: yup
              .string()
              .typeError('Esse campo é obrigatório')
              .required('Esse campo é obrigatório'),
            countryCode: yup.string().required('Esse campo é obrigatório'),
            businessType: yup.string().required('Esse campo é obrigatório'),
            businessSize: yup.string().required('Esse campo é obrigatório'),
          })
          .required()
      )
      .required(),
  })
  .required();

export const partnerSingleValidation = (isPowerOfAttorneyCheck?: boolean) => {
  return yup
    .object()
    .shape({
      ...(isPowerOfAttorneyCheck && {
        powerOfAttorney: yup
          .mixed()
          .test('required', 'Esse campo é obrigatório', (value) => {
            return value && value.length;
          }),
        powerOfAttorneyStartDate: yup
          .string()
          .typeError('Esse campo é obrigatório')
          .required('Esse campo é obrigatório'),
        powerOfAttorneyEndDate: yup
          .string()
          .typeError('Esse campo é obrigatório')
          .required('Esse campo é obrigatório'),
      }),
      memberQualification: yup
        .string()
        .typeError('Esse campo é obrigatório')
        .required('Esse campo é obrigatório'),
      pep: yup
        .string()
        .typeError('Esse campo é obrigatório')
        .required('Esse campo é obrigatório'),
      declaredIncome: yup
        .string()
        .typeError('Esse campo é obrigatório')
        .required('Esse campo é obrigatório'),
      participationPercentage: yup
        .number()
        .typeError('Esse campo é obrigatório')
        .required('Esse campo é obrigatório'),
      socialName: yup
        .string()
        .typeError('Esse campo é obrigatório')
        .required('Esse campo é obrigatório')
        .min(2, 'Esse é realmente seu apelido?'),
      email: yup
        .string()
        .required('Esse campo é obrigatório')
        .email('Email inválido'),
      motherName: yup
        .string()
        .required('Esse campo é obrigatório')
        .matches(
          /^[aA-zZ\s]+$/,
          'Não são aceitos acento ou caracteres especiais'
        )
        .min(4, 'Esse realmente é o nome da sua mãe?'),
      birthDate: yup
        .string()
        .required('Esse campo é obrigatório')
        .min(10, 'Precisa ter 8 caracteres')
        .test('is-valid-date', 'Data inválida', (value) => {
          if (!dayjs(value, 'DD/MM/YYYY', true).isValid()) {
            return false;
          }
          return true;
        })
        .test('age-test-over-18', 'É preciso ter mais que 18 anos', (value) => {
          return ageValidate(String(value), 18);
        }),
      city: yup
        .string()
        .required('Esse campo é obrigatório')
        .min(3, 'Precisa ter ao menos 3 caracteres'),
      state: yup
        .string()
        .required('Esse campo é obrigatório')
        .min(2, 'Precisa ter 2 caracteres'),
      fileFrontDocument: yup
        .mixed()
        .test('required', 'Esse campo é obrigatório', (value) => {
          return value && value.length;
        }),
      fileBackDocument: yup
        .mixed()
        .test('required', 'Esse campo é obrigatório', (value) => {
          return value && value.length;
        }),
      addressLine: yup.string().required('Esse campo é obrigatório'),
      buildingNumber: yup.string().required('Esse campo é obrigatório'),
      neighborhood: yup.string().required('Esse campo é obrigatório'),
      documentType: yup.string().required('Esse campo é obrigatório'),
      countryCode: yup.string().required('Esse campo é obrigatório'),
      number: yup.string().required('Esse campo é obrigatório'),
      country: yup.string().required('Esse campo é obrigatório'),
      zipCode: yup.string().required('Esse campo é obrigatório'),
    })
    .required();
};
