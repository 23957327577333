import { gql, request, useQuery } from '~reactQuery';

export interface GetPartnerUserOutput {
  addressLine: string;
  birthDate: string;
  buildingNumber: string;
  businessId: number;
  city: string;
  complement: string;
  country: string;
  countryCode: string;
  createdAt: string;
  declaredIncome: string;
  deleted: boolean;
  deletedAt: string;
  documentNumber: string;
  documentType: string;
  email: string;
  fileBackDocument: string;
  fileFrontDocument: string;
  gender: string;
  id: number;
  isPowerOfAttorney: boolean;
  legalRepresent: boolean;
  memberQualification: string;
  motherName: string;
  neighborhood: string;
  number: string;
  occupation: string;
  participationPercentage: string;
  partnerDataId: string;
  pep: string;
  powerOfAttorney: string;
  powerOfAttorneyEndDate: string;
  powerOfAttorneyStartDate: string;
  registerName: string;
  socialName: string;
  state: string;
  status: string;
  updatedAt: string;
  zipCode: string;
}

export interface Response {
  getPartnerUser: {
    data: GetPartnerUserOutput;
    isValid: boolean;
    messages: string[];
  };
}

export interface Param {
  userOrganizationId: number;
}

export const useGetPartnerUser = (params: Param) => {
  const QUERY = gql`
    query GetPartnerUser($params: GetPartnerUserInput) {
      getPartnerUser(params: $params) {
        data
        isValid
        messages
      }
    }
  `;

  const fetcher = async () => {
    if (!params.userOrganizationId) return null;
    const response = await request<Response>(QUERY, {
      params
    });
    return response.getPartnerUser;
  };

  return useQuery(['useGetPartnerUser'], fetcher);
};
