import * as React from 'react';

import { SVGProps } from 'react';

const PixSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 30}
    height={props.height || 30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.028.5c-1.388 0-2.71.578-3.678 1.59L7.277 6.354 5.9 7.794l1.977.243a3.868 3.868 0 0 1 2.316 1.172L14 13.192l.723.757.723-.757 3.804-3.983.001-.001a3.867 3.867 0 0 1 2.76-1.22l2.305-.023-1.592-1.667-4.016-4.207A5.088 5.088 0 0 0 15.028.5Zm-3.39 7.326-.722.691.72-.693a5.95 5.95 0 0 0-1.78-1.275l2.94-3.077a3.089 3.089 0 0 1 2.232-.972c.829 0 1.632.344 2.233.971l2.755 2.886a5.952 5.952 0 0 0-2.213 1.47l-3.082 3.225-3.082-3.226ZM8.037 9.715A2.982 2.982 0 0 0 6.917 9.5h.001v1l-.002-1h.001H3.91l-.29.284-2.031 1.977A5.223 5.223 0 0 0 0 15.504c0 1.408.574 2.753 1.588 3.74v.001l2.031 1.973.291.283h3.008v-1l-.002 1h.001a2.98 2.98 0 0 0 1.12-.215 2.94 2.94 0 0 0 .955-.623l4.095-3.989c.123-.117.261-.22.411-.306l1.512-.868-1.513-.867a2.13 2.13 0 0 1-.41-.304l-4.095-3.992a2.942 2.942 0 0 0-.956-.621Zm-.44 2.054 3.829 3.731-3.829 3.73a.94.94 0 0 1-.307.2.982.982 0 0 1-.368.07H4.722l-1.738-1.689A3.223 3.223 0 0 1 2 15.502c0-.86.35-1.691.984-2.308L4.723 11.5H6.92a.98.98 0 0 1 .675.269ZM22.771 9.5H26.273l.294.302 1.928 1.977A5.332 5.332 0 0 1 30 15.5c0 1.391-.539 2.73-1.505 3.721l-1.928 1.977-.294.302H22.772a2.84 2.84 0 0 1-2.016-.852l-.003-.003-3.889-3.989a2.07 2.07 0 0 0-.386-.305l-1.4-.857 1.403-.851c.138-.084.266-.184.382-.3l3.889-3.989.004-.004a2.84 2.84 0 0 1 2.015-.85Zm-4.242 5.999 3.653 3.746.001.001a.841.841 0 0 0 .594.254h2.653l1.633-1.675A3.332 3.332 0 0 0 28 15.5c0-.877-.34-1.713-.937-2.325L25.43 11.5h-2.655a.84.84 0 0 0-.594.253l-3.652 3.746Zm-3.08 2.31-.723-.757-.723.757-3.804 3.98v.001a3.867 3.867 0 0 1-2.32 1.174l-1.98.24 1.378 1.443 4.07 4.263a5.088 5.088 0 0 0 3.678 1.59c1.39 0 2.711-.578 3.679-1.59l4.019-4.206 1.578-1.652-2.284-.039a3.935 3.935 0 0 1-2.765-1.223l-.002-.002-3.801-3.979Zm-3.806 5.364 3.083-3.225 3.081 3.225.003.003a6.02 6.02 0 0 0 2.21 1.461l-2.762 2.891c-.6.628-1.404.972-2.233.972a3.088 3.088 0 0 1-2.232-.972l-2.936-3.075a5.953 5.953 0 0 0 1.785-1.278l.001-.002Z"
      fill={props.fill || '#026FF4'}
    />
  </svg>
);

export default PixSVG;
