import * as Style from '../GuestRegister.styles';
import * as Text from '~styles/text';
import { BigInput, FooterButton } from '~components';
import RegisterPartnerIcon from '~assets/images/kyc/register-partner.svg';
import { cpfMask } from '~helpers/format/masks';
import { useNavigate } from 'react-router-dom';
import colors from '~styles/colors';
import WarningIcon from '~assets/images/icons/kyc/status/alert.svg';
import { useGetPartnerStatus } from '~reactQuery/queries/kyc/useGetPartnerStatus';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { When } from 'react-if';
import { useFormContext } from 'react-hook-form';
import { CommonStepParams } from '../GuestRegister.types';

export const Check = ({ stepParams, goToStep }: CommonStepParams) => {
  const navigate = useNavigate();

  const {
    register,
    watch,
    setValue,
    setError,
    formState: { errors },
    trigger,
    clearErrors,
  } = useFormContext();

  const cpf = watch('cpf');

  const {
    data: partnerData,
    refetch,
    remove,
    isLoading,
  } = useGetPartnerStatus({
    documentNumber: String(onlyNumbers(cpf)),
    token: String(stepParams.token),
  });

  const onSubmit = () => {
    clearErrors();

    trigger(['cpf']).then((isValid) => {
      if (isValid) {
        refetch().then(({ data }) => {
          if (data?.error) {
            setError('cpf', {
              type: 'manual',
              message: 'Tempo de registro expirado, peça um novo convite.',
            });
          } else {
            setValue('email', data?.data?.email);

            if (data?.data?.active) {
              goToStep('createPin', {
                partner: data?.data,
              });
            } else {
              goToStep('userForm', {
                partner: data?.data,
              });
            }
          }

          remove();
        });
      }
    });
  };

  const showError = Boolean(partnerData?.error);

  return (
    <Style.Container>
      <div className="main-icon">
        <RegisterPartnerIcon />
      </div>

      <Text.Heading1Medium marginBottom={30} fontSize={1.938} lineHeight={130}>
        Que bom ver você aqui!
      </Text.Heading1Medium>

      <Text.Heading4 marginBottom={10}>
        Você foi convidado(a) para fazer parte da equipe {stepParams.orgName},
        sua participação será fundamental para os negócios prosperarem.
      </Text.Heading4>

      <Text.Heading5 marginBottom={30} lineHeight={140}>
        Para continuar seu cadastro, digite o número do seu CPF:
      </Text.Heading5>

      <BigInput
        textAlign="center"
        placeHolder="000.000.000-00"
        {...register('cpf')}
        onChange={(e) => setValue('cpf', cpfMask(e.target.value))}
        error={errors.cpf?.message ? String(errors.cpf?.message) : ''}
      />

      <When condition={showError}>
        <Style.StatusContainer>
          <div className="warning-message">
            <div className="icon">
              <WarningIcon />
            </div>

            <Text.Small color={colors.danger} display="inline">
              Verificamos que o CPF digitado não foi encontrado ou o convite
              pode ter expirado. Entre em contato com o administrador e solicite
              o reenvio do convite.
            </Text.Small>
          </div>
        </Style.StatusContainer>
      </When>

      <FooterButton
        onClickGenericButton={onSubmit}
        onClickLeftButton={() => navigate('/auth/sign-in')}
        disableGenericButton={!cpf || cpf.length < 14}
        leftButtonContent="Cancelar"
        genericButtonType="secondary"
        genericButtonContent="Avançar"
        loadingGenericButton={isLoading}
      />
    </Style.Container>
  );
};

export default Check;
