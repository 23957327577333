import { SVGProps } from 'react';

const GroupPersonIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '18'}
      height={props.height || '19'}
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0076 0.0136719H11.0076C13.7934 0.0136719 16 2.24289 16 5.01367C16 6.47051 15.3864 7.77598 14.4019 8.68738C14.7177 8.82057 15.029 8.97706 15.3288 9.15988C16.8173 10.0675 18 11.6213 18 14.0137V15.0137H16V14.0137C16 12.406 15.2591 11.4599 14.2876 10.8675C13.4537 10.359 12.4389 10.1134 11.5773 10.039C11.3006 10.6679 10.8984 11.2278 10.4019 11.6874C10.7177 11.8206 11.029 11.9771 11.3288 12.1599C12.8173 13.0675 14 14.6213 14 17.0137V18.0137L13 18.0137L1 18.0137L0 18.0137V17.0137C0 14.6195 1.18812 13.066 2.67963 12.1592C2.97777 11.9779 3.2872 11.8226 3.60106 11.6902C2.61483 10.7786 2 9.47199 2 8.01367C2 5.23793 4.22673 3.01367 7.00759 3.01367C9.73734 3.01367 11.911 5.15408 11.9973 7.84708C13.1696 7.44043 14 6.33315 14 5.01367C14 3.34001 12.6814 2.01367 11.0076 2.01367H10.0076V0.0136719ZM7.00759 5.01367C8.6814 5.01367 10 6.34001 10 8.01367C10 9.68361 8.66994 11.0137 7 11.0137C5.33006 11.0137 4 9.68361 4 8.01367C4 6.34497 5.32882 5.01367 7.00759 5.01367ZM10.2876 13.8675C11.0376 14.3248 11.6502 14.993 11.8905 16.0137L2.10999 16.0137C2.35115 14.9942 2.96549 14.326 3.7186 13.8682C4.7498 13.2412 6.05543 13.0137 7.00732 13.0137C7.95907 13.0137 9.26048 13.2412 10.2876 13.8675Z"
        fill={props.color || '#9EA1B9'}
      />
    </svg>
  );
};

export default GroupPersonIcon;
