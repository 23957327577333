import * as Style from './Container.styles';

import { ContainerProps } from './Container.types';

const Container: React.FC<ContainerProps> = (props) => {
  const { children, ...rest } = props;

  return <Style.Container {...rest}>{children}</Style.Container>;
};

export default Container;
