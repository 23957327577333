import * as Text from '~styles/text';
import * as yup from 'yup';

import React, { useRef } from 'react';

import DialogBottom from '~components/Dialog/DialogBottom.component';
import DialogHeader from '~components/Dialog/DialogHeader.component';
import InfoSVG from '~assets/svg/info';
import { InputForm } from '~components/Inputs';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import colors from '~styles/colors';
import { getToken } from '~helpers/device';
import { t } from 'i18next';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useChangePassword } from '~reactQuery/mutations/user/useChangePassword';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { strongPasswordRegex } from '~validations/stringsValidate';

const AskPassword: React.FC = ({ setStepsAndParams }) => {
  const token = getToken();
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  const { mutateAsync: changePassword, isLoading: loadingChangePassword } =
    useChangePassword(String(token));

  const schema = useRef({
    oldPasswd: yup.string().required('Esse campo é obrigatório'),
    passwd: yup
      .string()
      .required('Esse campo é obrigatório')
      .matches(
        strongPasswordRegex,
        'Deve ao menos 8 caracteres, um maiúsculo, um minúsculo, um número e um caractere especial'
      ),
    confirmPassword: yup
      .string()
      .required('Esse campo é obrigatório')
      .matches(
        strongPasswordRegex,
        'Deve ao menos 8 caracteres, um maiúsculo, um minúsculo, um número e um caractere especial'
      )
      .oneOf([yup.ref('passwd'), null], 'Senhas não coincidem'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<any>({
    mode: 'onSubmit',
    resolver: yupResolver(yup.object(schema.current)),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await changePassword({
        oldPasswd: data.oldPasswd,
        newPasswd: data.passwd,
      });
      setStepsAndParams('successChangePassword');
    } catch (error: any) {
      reset();
      setValue('passwd', '');
      setValue('confirmPassword', '');
      setValue('oldPasswd', '');

      // console.log('Change Password Error -> ', error);
    }
  });

  return (
    <>
      <DialogHeader
        title="Alterar senha de acesso"
        description="Crie uma senha forte e deixe sua conta ainda mais segura."
      />

      <div className="change-password-body">
        <InputForm
          {...register('oldPasswd')}
          label={t('Senha atual')}
          type="password"
          error={errors.oldPasswd?.message}
          labelColor={colors.black}
          placeholder="Senha atual"
        />
        <InputForm
          marginTop={10}
          {...register('passwd')}
          label={t('Nova Senha')}
          type="password"
          error={errors.passwd?.message}
          labelColor={colors.black}
          placeholder="Nova Senha"
        />
        <div className="tip">
          <InfoSVG />
          <Text.Small color={colors.grayMedium}>
            Sua nova senha tem que ser diferente da anterior, ter mais de 8
            dígitos, com números e carácteres especiais.
          </Text.Small>
        </div>

        <InputForm
          labelColor={colors.black}
          marginTop={10}
          {...register('confirmPassword')}
          type="password"
          label={t('Repetir senha')}
          placeholder="Repetir nova senha"
          error={errors.confirmPassword?.message}
        />
      </div>

      <DialogBottom
        singleButtonText="Alterar senha"
        doubleButtonTextOne="Cancelar"
        singleButtonAlignment="flex-end"
        singleButtonAction={onSubmit}
        doubleButtonOneAction={() => setOpenModalMultiStep(false)}
        singleButtonLoading={loadingChangePassword}
        disableSingleButton={false}
      />
    </>
  );
};

export default AskPassword;
