import { CheckSelect } from '~components/index';
import { useState } from 'react';
import { LimitOptionsPageProps } from '../NewLimit.types';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { optionsList, translatorDescription } from '~data/limits';

const LimitOptions = (props: LimitOptionsPageProps) => {
  const { setCurrentStep } = props;

  const { params } = useActionsOnModalMultiStep();

  const [optionSelect, setOptionSelect] = useState<string>('');

  const goNext = () => {
    if (optionSelect !== '') {
      setCurrentStep('limitValue', {
        optionSelect,
      });
    }
  };

  const selectType = (keyType: string) => {
    if (optionSelect === keyType) {
      setOptionSelect('');
    } else {
      setOptionSelect(keyType);
    }
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Solicitar ajuste de limite"
        description={translatorDescription[params?.origin]}
      />

      <div className="content-container">
        {optionsList?.map((item) => {
          if (params?.origin !== 'pix' && item?.keyType === 'Pix noturno') {
            return null;
          }
          return (
            <CheckSelect
              iconType="component"
              disabled={!item.enabled}
              key={item.keyType}
              title={item.keyType}
              text={item.keyValue}
              iconComponent={item.icon}
              chipText={item.chipLabel}
              onClick={() => selectType(item.keyType)}
              selected={optionSelect === item.keyType}
            />
          );
        })}
      </div>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="120px"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={optionSelect?.length <= 0}
      />
    </>
  );
};

export default LimitOptions;
