import React, { SVGProps } from 'react';

function PixTedReceivedSVG(props: SVGProps<SVGSVGElement>) {
  const { fill = 'none', stroke = '#11D593', height = 24, width = 24 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M22.7178 9L19.7178 12L16.7178 9"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M19.7178 11L19.7178 4.00004"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.5 6.95801H3.5C2.39543 6.95801 1.5 7.85344 1.5 8.95801V17.958C1.5 19.0626 2.39543 19.958 3.5 19.958H20C21.1046 19.958 22 19.0626 22 17.958V17"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14.7178 13.458C14.7178 15.1149 13.3746 16.458 11.7178 16.458C10.0609 16.458 8.71777 15.1149 8.71777 13.458C8.71777 11.8012 10.0609 10.458 11.7178 10.458C13.3746 10.458 14.7178 11.8012 14.7178 13.458Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
export default PixTedReceivedSVG;
