import * as Style from '../SendNewPin.screen';
import * as Text from '~styles/text';
import * as yup from 'yup';

import React, { useState } from 'react';
import { InputPin } from '~components/Inputs';
import colors from '~styles/colors';
import { getSelectedUserOrganization } from '~helpers/device';
import { parseError } from '~reactQuery/index';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useChangeOrganizationPin } from '~reactQuery/mutations/business/useChangeOrganizationPin';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';

const NewPin: React.FC = ({ setStepsAndParams, stepParams }) => {
  const orgId = getSelectedUserOrganization();
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  const [pins, setPins] = useState({
    oldPin: stepParams?.oldPin || '',
    newPin: '',
    confirmPin: '',
  });

  const [erros, setErros] = useState({
    oldPin: '',
    newPin: '',
    confirmPin: '',
  });

  const { mutateAsync: changeOrgPin, isLoading: loadingChangePin } =
    useChangeOrganizationPin();

  const schema = yup.object({
    oldPin: yup.string().required('Esse campo é obrigatório'),
    newPin: yup.string().required('Esse campo é obrigatório'),
    confirmPin: yup
      .string()
      .required('Esse campo é obrigatório')
      .oneOf([pins.confirmPin, null], 'Os pins não coincidem'),
  });

  const changePin = async () => {
    let errorList = {};

    await schema.validate(pins, { abortEarly: false }).catch((err) => {
      err.inner.forEach((e: any) => {
        errorList = {
          ...errorList,
          [e.path]: e.message,
        };
      });

      setErros({ ...errorList });
    });

    if (Object.keys(errorList)?.length > 0) return;

    try {
      await changeOrgPin({
        userOrganizationId: Number(orgId),
        newPin: pins.newPin,
        oldPin: pins.oldPin,
      });

      setStepsAndParams('successRecovery');
    } catch (error: any) {
      const erroKey = parseError(error);

      if (erroKey === 'THE_CURRENT_PIN_PROVIDED_DOES_NOT_MATCH') {
        setStepsAndParams('askNewPin');
      }

      // console.log('Change Pin Error -> ', error);
    }
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Escolha um novo PIN"
        description="Crie um PIN forte e deixe sua conta ainda mais segura."
      />

      <div className="recovery-pin-body">
        <Text.Heading5 marginBottom={10} marginTop={25}>
          Novo PIN
        </Text.Heading5>

        <InputPin
          size={4}
          secret
          onChange={(pin) => {
            setPins((prev) => ({ ...prev, newPin: pin }));
          }}
          onFinish={(pin) => {
            setPins((prev) => ({ ...prev, newPin: pin }));
          }}
          error={erros?.newPin?.length > 1}
        />
        {erros?.newPin && (
          <Text.Heading5 marginTop={5} color={colors.danger}>
            {erros?.newPin}
          </Text.Heading5>
        )}

        <Text.Heading5 marginBottom={10} marginTop={25}>
          Repetir novo PIN
        </Text.Heading5>

        <InputPin
          size={4}
          secret
          onChange={(pin) => {
            setPins((prev) => ({ ...prev, confirmPin: pin }));
          }}
          onFinish={(pin) => {
            setPins((prev) => ({ ...prev, confirmPin: pin }));
          }}
          error={erros?.confirmPin?.length > 1}
        />
        {erros?.confirmPin && (
          <Text.Heading5 marginTop={5} color={colors.danger}>
            {erros?.confirmPin}
          </Text.Heading5>
        )}
      </div>

      <ModalMultiStepBottom
        singleButtonText="Confirmar novo PIN"
        doubleButtonTextOne="Cancelar"
        singleButtonAlignment="flex-end"
        singleButtonAction={changePin}
        doubleButtonOneAction={() => setOpenModalMultiStep(false)}
        singleButtonLoading={loadingChangePin}
      />
    </>
  );
};

export default NewPin;
