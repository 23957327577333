import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.locale('pt-br');
dayjs.extend(localizedFormat);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('pt-br', {
  relativeTime: {
    future: 'em %s',
    past: 'há %s',
    s: 'pouco',
    m: '1 min',
    mm: '%d min',
    h: 'uma hora',
    hh: '%d horas',
    d: 'um dia',
    dd: '%d dias',
    M: 'um mês',
    MM: '%d meses',
    y: 'um ano',
    yy: '%d anos',
  },
});

export default dayjs;

export const brDateToIso = (str: string) => {
  if (!str) return '';
  const date = String(str);
  return date.replace(/(\d{2})\/(\d{2})\/(\d{4})/g, '$3-$2-$1');
};

export const formatDate = (date: string, format: string) => {
  if (!date) return '';
  const formattedDate = dayjs(String(date)).format(format || 'LLL');
  return formattedDate;
};

export const getRelativeDate = (d: string | undefined, format?: string) => {
  if (!d) return '';

  return dayjs(d).isToday()
    ? dayjs(d).fromNow()
    : dayjs(d).format(format || 'DD MMM YYYY');
};

export const daysAmountFromMonth = (m: number | string) => {
  if (m === undefined || m === null || Number.isNaN(m) || m > 11 || m < 0)
    return 'invalid month';

  const months = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  const formatMonthArg = months.find((month) => month.includes(m as string));

  if (typeof m === 'string' && !formatMonthArg) return 'invalid month';

  const month = typeof m === 'string' ? months.indexOf(formatMonthArg!) : m;

  const d = new Date();
  const currYear = d.getFullYear();
  const isLeapYear =
    currYear % 100 === 0 ? currYear % 400 === 0 : currYear % 4 === 0;
  const monthsWith31 = [0, 2, 4, 6, 7, 9, 11];

  if (month === 1) return isLeapYear ? 29 : 28;
  if (monthsWith31.some((m2) => m2 === month)) return 31;

  return 30;
};
