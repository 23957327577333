import { gql, request, useQuery } from '~reactQuery';

export interface GetNotificationsResponse {
  getNotifications: {
    data: {
      id: number;
      key: 'STATEMENT' | 'PIX_KEY' | 'PIX_MANUAL' | 'TED';
      data: string;
      period?: {
        startDate: string;
        endDate: string;
      };
      read: boolean;
      status: 'PROCESSING' | 'PENDING' | 'DONE' | 'ERROR';
      createdAt: string;
      updatedAt: string;
      accountId: number;
    }[];
    messages: [string];
    isValid: boolean;
  };
}

export interface GetNotificationsParams {
  userOrganizationId: number;
  showNotificationData: boolean;
  id?: number;
  key?: 'STATEMENT' | 'PIX_KEY' | 'PIX_MANUAL' | 'TED';
  read?: boolean;
  status?: 'PROCESSING' | 'PENDING' | 'DONE' | 'ERROR';
}

export function useGetNotifications(
  params: GetNotificationsParams,
  enabled?: boolean
) {
  const QUERY = gql`
    query GetNotifications($params: NotificationsGetInput!) {
      getNotifications(params: $params) {
        data {
          ... on NotificationData {
            id
            key
            data
            period
            read
            status
            createdAt
            updatedAt
            accountId
          }
          ... on GlobalNotificationData {
            id
            key
            data
            active
            start
            finish
            createdAt
            updatedAt
          }
        }
        messages
        isValid
      }
    }
  `;

  const fetcher = async () => {
    const response = await request<GetNotificationsResponse>(QUERY, {
      params,
    });

    return response?.getNotifications?.data || null;
  };

  return useQuery(
    ['getNotifications', params.id, params.userOrganizationId],
    fetcher,
    {
      enabled,
    }
  );
}
