import { gql, request, useMutation } from '~reactQuery';

import { useQueryClient } from 'react-query';

interface CreateIncomesInput {
  processId: number;
  incomes: [string];
}

interface CreateIncomesResponse {
  createIncomes: {
    data: {
      id: number;
      income: string;
      createdAt: string;
      updatedAt: string;
      deleted: boolean;
      deletedAt: string;
      businessDataId: number;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useCreateIncomesMutation() {
  const queryClient = useQueryClient();

  const mutation = gql`
    mutation CreateIncomes($params: createIncomesInput!) {
      createIncomes(params: $params) {
        data {
          id
          income
          createdAt
          updatedAt
          deleted
          deletedAt
          businessDataId
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: CreateIncomesInput) => {
    const response = await request<CreateIncomesResponse>(mutation, {
      params,
    });
    return response.createIncomes;
  };

  return useMutation('CreateIncomes', fetch, {
    onSettled: () => queryClient.invalidateQueries(['getProcess']),
  });
}
