const InterrogationIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { stroke = '#9EA1B9', height = 19, width = 18 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 18 19">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9.50098C2 5.63498 5.13401 2.50098 9 2.50098C12.866 2.50098 16 5.63498 16 9.50098C16 13.367 12.866 16.501 9 16.501C5.13401 16.501 2 13.367 2 9.50098ZM9 0.500977C4.02944 0.500977 0 4.53041 0 9.50098C0 14.4715 4.02944 18.501 9 18.501C13.9706 18.501 18 14.4715 18 9.50098C18 4.53041 13.9706 0.500977 9 0.500977ZM6 8.07259C6 7.51641 6.11111 6.99815 6.35802 6.53046C6.60494 6.06276 6.95062 5.69619 7.41975 5.4181C7.87654 5.14001 8.41975 5.00096 9.06173 5.00096C9.65432 5.00096 10.1728 5.10209 10.6173 5.30433C11.0617 5.50658 11.3951 5.79731 11.642 6.15125C11.8765 6.51782 12 6.93495 12 7.39001C12 7.94619 11.8889 8.37596 11.6667 8.69197C11.4444 9.00799 11.1358 9.31136 10.716 9.60209C10.3457 9.8549 10.0741 10.0824 9.92593 10.2594C9.77778 10.449 9.7037 10.6639 9.7037 10.9167V11.081H8.07407V10.7271C8.07407 10.2467 8.17284 9.88018 8.38272 9.60209C8.59259 9.33664 8.92593 9.07119 9.38272 8.7931C9.66667 8.61613 9.87654 8.42653 10.0123 8.22428C10.1358 8.03467 10.2099 7.80714 10.2099 7.55433C10.2099 7.25096 10.0988 7.0108 9.90123 6.82119C9.69136 6.63158 9.41975 6.53046 9.07407 6.53046C8.67901 6.53046 8.35802 6.68214 8.12346 6.96023C7.87654 7.23832 7.76543 7.61754 7.76543 8.07259H6ZM7.91358 12.9771C7.91358 12.6864 8 12.4462 8.19753 12.2566C8.39506 12.067 8.64198 11.9659 8.93827 11.9659C9.23457 11.9659 9.46914 12.067 9.66667 12.2566C9.8642 12.4462 9.96296 12.6864 9.96296 12.9771C9.96296 13.2805 9.8642 13.5206 9.66667 13.7102C9.46914 13.9125 9.22222 14.001 8.93827 14.001C8.64198 14.001 8.40741 13.9125 8.20988 13.7102C8.01235 13.5206 7.91358 13.2805 7.91358 12.9771Z"
        fill={stroke}
      />
    </svg>
  );
};

export default InterrogationIcon;
