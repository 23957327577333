import { GeneralStyleProps } from 'src/@types/general';
import styled from 'styled-components';
import colors from './colors';

const baseHeading = styled.p<GeneralStyleProps>`
  display: ${(props) => props.display || 'flex'};
  align-self: ${(props) => props.alignSelf || 'auto'};
  justify-self: ${(props) => props.justifySelf || 'auto'};
  text-align: ${(props) => props.textAlign || 'auto'};
  width: ${(props) => props.width || 'auto'};
  margin: ${(props) => props.marginTop || 0}px
    ${(props) => props.marginRight || 0}px
    ${(props) => props.marginBottom || 0}px
    ${(props) => props.marginLeft || 0}px;
  color: ${(props) => props.color || colors.black};
  letter-spacing: ${(props) => props.letterSpacing || 0.4}px;
  -webkit-font-smoothing: antialiased !important;
  font-weight: ${(props) => props.fontWeight || 400};
`;

export const Heading1Regular = styled(baseHeading)`
  font-family: 'Helvetica Now Display Regular', sans-serif;
  font-size: ${(props) => props.fontSize || 1.813}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Heading1Medium = styled(baseHeading)`
  font-family: 'Helvetica Now Display Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 1.813}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Heading1Bold = styled(baseHeading)`
  font-family: 'Helvetica Now Display Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 1.813}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Heading2 = styled(baseHeading)`
  font-family: 'Helvetica Now Display Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 1.37}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Heading2Bold = styled(baseHeading)`
  font-family: 'Helvetica Now Display Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 1.37}rem;

  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Heading3 = styled(baseHeading)`
  font-family: 'Helvetica Now Display Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 1.18}rem;
  line-height: ${(props) => props.lineHeight || 130}%;
`;

export const Heading3Bold = styled(baseHeading)`
  font-family: 'Helvetica Now Display Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 1.18}rem;
  line-height: ${(props) => props.lineHeight || 130}%;
`;

export const Heading4 = styled(baseHeading)`
  font-family: 'Helvetica Now Display Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 1.063}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const Heading4Bold = styled(baseHeading)`
  font-family: 'Helvetica Now Display Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 1.063}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const Heading5 = styled(baseHeading)`
  font-family: 'Helvetica Now Display Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 0.93}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Heading5Bold = styled(baseHeading)`
  font-family: 'Helvetica Now Display Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 0.93}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Paragraph = styled(baseHeading)`
  font-family: 'Helvetica Now Display Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 0.875}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const ParagraphBold = styled(baseHeading)`
  font-family: 'Helvetica Now Display Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 0.875}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const Small = styled(baseHeading)`
  font-family: 'Helvetica Now Display Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 0.81}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const SmallBold = styled(baseHeading)`
  font-family: 'Helvetica Now Display Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 0.81}rem;
  line-height: ${(props) => props.lineHeight || 120}%;
`;

export const Smaller = styled(baseHeading)`
  font-family: 'Helvetica Now Display Medium', sans-serif;
  font-size: ${(props) => props.fontSize || 0.75}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
`;

export const SmallerBold = styled(baseHeading)`
  font-family: 'Helvetica Now Display Bold', sans-serif;
  font-size: ${(props) => props.fontSize || 0.75}rem;
  line-height: ${(props) => props.lineHeight || 135}%;
`;
