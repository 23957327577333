import * as React from 'react';
import { SVGProps } from 'react';

const WalletHtSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.855 15.014a9.988 9.988 0 0 0-.586-1.882 9.976 9.976 0 0 0-2.15-3.19 9.975 9.975 0 0 0-3.189-2.15 9.986 9.986 0 0 0-7.798 0 9.967 9.967 0 0 0-3.19 2.151 9.966 9.966 0 0 0-1.223 1.48c-.361.54-.671 1.112-.926 1.709a9.986 9.986 0 0 0 0 7.798 9.984 9.984 0 0 0 2.15 3.19 9.972 9.972 0 0 0 3.189 2.15 9.986 9.986 0 0 0 7.798 0 9.982 9.982 0 0 0 3.19-2.15 9.982 9.982 0 0 0 2.15-3.19 9.986 9.986 0 0 0 .585-5.915Z"
      fill="#2B3065"
    />
    <path
      d="M16.675 14.306c0-2.008-.986-3.738-1.737-4.3 0 0-.058-.032-.052.05-.061 3.88-2.06 4.932-3.158 6.348-2.534 3.27-.18 6.855 2.224 7.517 1.339.372-.311-.656-.524-2.825-.261-2.618 3.247-4.619 3.247-6.79Z"
      fill="#fff"
    />
    <path
      d="M17.827 15.629c-.016-.01-.038-.018-.052.007-.042.489-.544 1.533-1.181 2.494-2.162 3.254-.93 4.824-.238 5.668.403.488 0 0 1.005-.5a4.495 4.495 0 0 0 2.166-3.476c.197-2.313-1.202-3.77-1.7-4.193Z"
      fill="#fff"
    />
  </svg>
);

export default WalletHtSVG;
