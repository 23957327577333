import { SVGProps } from 'react';
import colors from '~styles/colors';

const ArrowDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    fill={props.fill || '#272937'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.713 13.693a.748.748 0 0 0 .28.057h.014a.748.748 0 0 0 .523-.22l4-4a.75.75 0 0 0-1.06-1.06l-2.72 2.72V3a.75.75 0 0 0-1.5 0v8.19L4.53 8.47a.75.75 0 0 0-1.06 1.06l4 4a.748.748 0 0 0 .243.163Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default ArrowDownIcon;
