import { UserPartnerResp } from '~reactQuery/types/business.types';

export const renderUserName = (userData: UserPartnerResp | undefined) => {
  if (userData?.socialName) return userData?.socialName;

  if (userData?.registerName) return userData?.registerName;

  return 'Usuário';
};

export const handleOnClickHelp = () => {
  window.open('https://help.capitual.com/', '_blank');
};
