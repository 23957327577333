import { colors } from '~styles';
import { screen } from '~styles/metrics';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  .message-sent {
    width: 130px;
    height: 130px;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: ${transparentize(0.9, colors.primary)};
    border-radius: 10px;
    margin: 30px 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 50px 50px 0 50px;
    transition: all ease-out 0.3s;

    .back-button {
      cursor: pointer;
    }
  }

  .scroll {
    overflow-y: scroll;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom-left-radius: 10px;
    border-top: 1px solid ${transparentize(0.7, colors.grayLight)};
    width: 100%;
    padding: 14px 20px;
    background-color: ${colors.whiteLight};

    .footer-btn {
      width: auto;

      @media ${screen.tablet} {
        width: 100%;
      }
    }

    .button-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      width: 155px;
    }
  }

  @media ${screen.tablet} {
    overflow-y: auto;

    .footer {
      border-top: none;
    }
    .content {
      padding: 30px 20px;
    }
  }
`;

export const HowItWorksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  @media ${screen.phone} {
    flex-direction: column;
    align-items: flex-start;
  }

  .how-it-works-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100px;

    .how-it-works-icon {
      width: 80px;
      height: 80px;
      align-items: center;
      justify-content: center;
      display: flex;
      background-color: ${transparentize(0.9, colors.primary)};
      border-radius: 10px;
    }

    @media ${screen.phone} {
      flex-direction: row;
      justify-content: flex-start;

      width: 100%;
      margin-top: 10px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 30px;
      }

      .how-it-works-icon {
        width: 60px;
        height: 60px;
      }
    }

    .how-it-works-text-container {
      width: 90px;

      @media ${screen.phone} {
        width: auto;
      }
    }
  }
`;

export const FormContainer = styled.div``;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 60%;
  margin-bottom: 5px;
  border-top: 1px solid rgba(195, 198, 219, 0.25);

  @media (max-width: 660px) {
    padding-top: 15px;
    max-width: 100%;
  }

  &.mobile {
    padding-top: 15px;
    max-width: 100%;
  }
`;
