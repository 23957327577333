import * as React from 'react';

import { SVGProps } from 'react';

const UniCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="M19.993 10.998c-.267-.038-.28-.05-.152-.063.241-.038.8.012 1.194.101.915.216 1.741.776 2.618 1.754l.229.267.33-.05c1.411-.23 2.86-.052 4.067.508.33.152.852.457.915.533.026.026.064.191.09.356.088.598.05 1.042-.14 1.386-.102.19-.102.24-.039.406.051.127.204.216.343.216.305 0 .623-.483.776-1.156l.063-.267.115.127c.648.724 1.156 1.728 1.232 2.44l.026.19-.115-.165a2.146 2.146 0 0 0-.61-.648c-.432-.292-.89-.381-2.097-.445-1.093-.063-1.715-.152-2.325-.355-1.043-.343-1.576-.788-2.809-2.428-.547-.724-.89-1.118-1.233-1.449-.75-.724-1.5-1.105-2.478-1.258Z"
      fill="#FF007A"
    />
    <path
      d="M29.461 12.612c.026-.483.089-.801.229-1.093.05-.115.101-.216.114-.216.013 0-.012.089-.05.19-.102.28-.115.674-.052 1.119.09.572.128.648.738 1.27.28.293.61.661.737.814l.216.28-.216-.204c-.267-.254-.877-.737-1.017-.8-.089-.051-.102-.051-.165.012-.051.051-.064.127-.064.496-.012.572-.089.928-.28 1.296-.101.19-.114.153-.025-.063.064-.166.076-.242.076-.788 0-1.106-.127-1.373-.902-1.818a9.497 9.497 0 0 0-.712-.368 2.807 2.807 0 0 1-.343-.166c.026-.025.776.191 1.068.318.445.178.521.19.572.178.038-.038.063-.14.076-.457ZM20.515 14.505c-.534-.737-.877-1.88-.801-2.732l.025-.267.127.025c.23.038.623.19.814.305.508.305.737.725.953 1.767.063.305.152.66.19.775.064.19.305.636.509.915.14.203.05.305-.267.28-.483-.051-1.131-.496-1.55-1.068ZM28.813 20.033c-2.516-1.017-3.406-1.893-3.406-3.38 0-.216.013-.394.013-.394.012 0 .101.076.216.165.508.406 1.08.584 2.669.813.928.14 1.461.242 1.944.407 1.538.508 2.491 1.55 2.72 2.961.063.407.025 1.182-.076 1.589-.09.317-.343.902-.407.915-.013 0-.038-.064-.038-.166-.026-.533-.292-1.042-.737-1.436-.534-.457-1.22-.8-2.898-1.474ZM27.034 20.453a3.013 3.013 0 0 0-.127-.534l-.064-.19.115.14c.165.19.292.42.406.737.09.241.09.317.09.711 0 .382-.013.47-.09.687a2.3 2.3 0 0 1-.533.851c-.458.47-1.055.725-1.907.84-.152.012-.584.05-.966.075-.953.051-1.588.153-2.16.356-.076.026-.153.051-.165.038-.026-.025.368-.254.686-.406.445-.216.902-.33 1.906-.509.496-.076 1.004-.178 1.131-.229 1.259-.393 1.881-1.372 1.678-2.567Z"
      fill="#FF007A"
    />
    <path
      d="M28.19 22.499c-.33-.725-.406-1.41-.228-2.059.025-.064.05-.127.076-.127.026 0 .102.038.178.089.153.101.47.28 1.284.724 1.03.56 1.614.991 2.02 1.487.356.432.572.928.674 1.538.064.343.025 1.17-.064 1.512-.28 1.08-.915 1.945-1.842 2.44-.14.077-.255.127-.267.127-.013 0 .038-.127.114-.28.305-.647.343-1.27.115-1.969-.14-.432-.433-.953-1.017-1.83-.7-1.017-.864-1.284-1.042-1.652ZM18.722 26.388c.94-.788 2.097-1.347 3.165-1.525.457-.076 1.22-.05 1.64.064.673.178 1.283.559 1.6 1.03.306.457.446.85.585 1.728.051.343.115.699.127.775.102.457.305.813.56 1.004.393.292 1.08.305 1.753.05.115-.037.216-.075.216-.063.026.026-.317.254-.546.369a1.8 1.8 0 0 1-.915.216c-.61 0-1.131-.318-1.55-.953-.09-.127-.267-.496-.42-.84-.445-1.029-.674-1.334-1.195-1.677-.457-.292-1.042-.356-1.486-.14-.585.28-.738 1.03-.331 1.487.165.191.47.344.724.382a.775.775 0 0 0 .877-.776c0-.305-.114-.482-.419-.622-.407-.178-.851.025-.839.42 0 .164.076.266.242.342.101.051.101.051.025.038-.369-.076-.457-.533-.165-.826.356-.356 1.105-.203 1.36.293.101.203.114.61.025.864-.216.559-.826.851-1.449.686-.42-.114-.597-.229-1.105-.75-.89-.915-1.233-1.093-2.504-1.283l-.241-.039.266-.254Z"
      fill="#FF007A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.037 5.94c2.962 3.596 7.524 9.188 7.753 9.493.19.254.114.495-.203.673a1.944 1.944 0 0 1-.725.204.917.917 0 0 1-.61-.267c-.114-.114-.61-.839-1.728-2.58a165.487 165.487 0 0 0-1.589-2.453c-.05-.025-.05-.025 1.5 2.745.978 1.741 1.296 2.364 1.296 2.44 0 .166-.05.255-.254.483-.343.382-.496.814-.61 1.716-.127 1.004-.47 1.716-1.449 2.923-.572.712-.66.839-.8 1.131-.178.356-.23.56-.255 1.017-.025.483.026.788.165 1.245.128.407.267.674.61 1.195.293.457.47.8.47.928 0 .101.026.101.484 0 1.093-.255 1.995-.687 2.49-1.22.306-.33.382-.509.382-.966 0-.292-.013-.356-.09-.534-.126-.28-.368-.508-.889-.864-.686-.47-.978-.852-1.055-1.36-.063-.432.013-.724.394-1.525.394-.826.496-1.17.56-2.008.038-.534.101-.75.254-.915.165-.178.305-.242.699-.293.648-.088 1.067-.254 1.398-.571.292-.267.42-.534.432-.928l.013-.292-.166-.178c-.597-.687-8.87-9.786-8.909-9.786-.012 0 .191.241.432.546ZM15.94 24.01a.525.525 0 0 0-.165-.699c-.216-.14-.546-.076-.546.115 0 .05.025.101.101.127.114.063.127.127.038.267s-.089.267.026.356c.178.14.42.063.546-.166ZM21.086 17.326c-.305.09-.597.42-.686.75-.051.203-.025.572.063.686.14.178.267.23.623.23.7 0 1.297-.306 1.36-.675.063-.305-.203-.724-.572-.915-.19-.101-.584-.14-.788-.076Zm.814.636c.101-.153.063-.318-.128-.432-.343-.216-.864-.039-.864.292 0 .165.267.343.521.343.166 0 .394-.102.47-.203Z"
      fill="#FF007A"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UniCoinSVG;
