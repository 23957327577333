import { SVGProps } from 'react';

const CardsSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.583 1.333a3.25 3.25 0 0 0-3.25 3.25v14a3.25 3.25 0 0 0 3.25 3.25h9a3.25 3.25 0 0 0 3.25-3.25v-14a3.25 3.25 0 0 0-3.25-3.25h-9Zm-1.75 3.25c0-.966.784-1.75 1.75-1.75h9c.967 0 1.75.784 1.75 1.75v14a1.75 1.75 0 0 1-1.75 1.75h-9a1.75 1.75 0 0 1-1.75-1.75v-14Zm6.25.5a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h1.5a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-1.5Z"
      fill={props.fill || 'currentColor'}
    />
  </svg>
);

export default CardsSVG;
