import * as Text from '~styles/text';
import colors from '~styles/colors';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ArrowLeftSVG from '~assets/svg/arrowLeft';
import InputCurrency from '~components/Inputs/InputCurrency/InputCurrency.component';
import { InputForm, InputSelect, InputSwitch } from '~components/Inputs';
import DropDownList from '~components/DropDownList/DropDownList.component';
import { useGetBrlBanks } from '~reactQuery/queries/bank/useGetBrlBank';
import { useMemo, ChangeEvent } from 'react';
import { accountsTypes } from '~data/bankUtils';
import { When } from 'react-if';
import { useUpdatePaymentList } from '~reactQuery/mutations/paymentList/useUpdatePaymentList';
import { ErrorToast, SuccessToast } from '~components/Toast';
import { capitalizeInitialsAndRemoveNumbers } from '~helpers/format/capitalize';
import { SavedListsProps } from '../SavedLists.types';

const EditBeneficiary = ({
  setStepSavedLists,
  selectedBeneficiaryInList,
  setSelectedBeneficiaryInList,
  selectedList,
}: SavedListsProps) => {
  const {
    mutateAsync: updatePaymentList,
    isLoading: loadingUpdatePaymentList,
  } = useUpdatePaymentList();
  const { data: brlBankList, isLoading: isLoadingBankList } = useGetBrlBanks();

  const parseBankList: Array<{
    value: string;
    label: string;
    bankCode?: string;
  }> = useMemo(() => {
    if (!brlBankList && isLoadingBankList)
      return [{ value: '', label: 'Carregando...' }];

    const mapBankList = (brlBankList || [])
      .map((bank) => ({
        value: bank.bankIspb,
        label: `${bank.bankName}`,
        bankCode: bank.bankCompe,
      }))
      .sort((a, b) => ((a.bankCode || '') < (b.bankCode || '') ? -1 : 1));

    return mapBankList;
  }, [brlBankList, isLoadingBankList]);

  const transferTypes = [
    { value: 'Pix Manual', label: 'Pix Manual' },
    { value: 'TED', label: 'TED' },
  ];

  const handleEditBeneficiary = async () => {
    const objectIndex = selectedList?.data?.listOfBeneficiaries?.findIndex(
      (item, index) => index === selectedBeneficiaryInList?.indexInArray
    );

    const updatedList = {
      ...selectedList,
      data: {
        ...selectedList?.data,
        listOfBeneficiaries: [...selectedList.data.listOfBeneficiaries],
      },
    };

    updatedList.data.listOfBeneficiaries[objectIndex] = {
      ...updatedList.data.listOfBeneficiaries[objectIndex],
      ...selectedBeneficiaryInList,
    };

    delete updatedList.data.listOfBeneficiaries[objectIndex]?.indexInArray;

    try {
      const response = await updatePaymentList({
        id: selectedList?.id,
        data: {
          nameList: selectedList?.data?.nameList,
          listOfBeneficiaries: updatedList?.data?.listOfBeneficiaries,
        },
      });

      if (response) {
        SuccessToast({
          title: 'Beneficiário alterado com sucesso!',
          autoCloseEnable: true,
        });
        setStepSavedLists('showAllSavedLists');
      }
    } catch (error) {
      ErrorToast({
        title: 'Não foi possível alterar o Beneficiário!',
        autoCloseEnable: true,
      });
    }
  };

  const disableNextWhenIsBankAccount =
    selectedBeneficiaryInList?.amount === 'R$ 0,00' ||
    !selectedBeneficiaryInList?.amount?.length ||
    !selectedBeneficiaryInList?.bank?.length ||
    !selectedBeneficiaryInList?.accountType?.length ||
    !selectedBeneficiaryInList?.transferType?.length ||
    !selectedBeneficiaryInList?.agency?.length ||
    !selectedBeneficiaryInList?.bankAccount?.length;

  const disableNextWhenPix =
    selectedBeneficiaryInList?.amount === 'R$ 0,00' ||
    !selectedBeneficiaryInList?.amount?.length ||
    !selectedBeneficiaryInList?.keyValue;

  const disableNext = !selectedBeneficiaryInList?.keyValue
    ? disableNextWhenIsBankAccount
    : disableNextWhenPix;

  return (
    <div>
      <div
        className="go-back-button-edit-beneficiary"
        onClick={() => setStepSavedLists('editList')}
      >
        <ArrowLeftSVG fill={colors.grayMedium} />
        <Text.Heading2Bold style={{ fontFeatureSettings: '"ss02", "zero"' }}>
          Ajustando beneficiário{' '}
          {capitalizeInitialsAndRemoveNumbers(
            selectedBeneficiaryInList?.fullName ||
              selectedBeneficiaryInList?.holderName ||
              ''
          )}
        </Text.Heading2Bold>
      </div>

      <InputCurrency
        value={selectedBeneficiaryInList?.amount}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const object = {
            ...selectedBeneficiaryInList,
            amount: event.target.value,
          };

          setSelectedBeneficiaryInList(object);
        }}
        className="input-value"
      />

      <When condition={!selectedBeneficiaryInList?.keyValue}>
        <div className="container-input">
          <Text.Heading5Bold marginTop={25}>
            Banco ou instituição Financeira
          </Text.Heading5Bold>

          <DropDownList
            list={parseBankList}
            selected={selectedBeneficiaryInList?.bankIspb}
            setSelected={(event: any) => {
              const object = {
                ...selectedBeneficiaryInList,
                bankIspb: event,
                bank: parseBankList?.filter(
                  (b: { value: string; label: string; bankCode?: string }) =>
                    b?.value === event
                )?.[0]?.bankCode,
              };

              setSelectedBeneficiaryInList(object);
            }}
            inputLabel="Selecionar Banco"
            label="Bancos"
          />
        </div>
      </When>

      <When condition={!selectedBeneficiaryInList?.keyValue}>
        <div className="row">
          <div className="item">
            <Text.Heading5Bold>Tipo de conta</Text.Heading5Bold>

            <InputSelect
              placeholder="Selecionar"
              className="input-select"
              options={accountsTypes}
              value={selectedBeneficiaryInList?.accountType}
              onChange={(event: any) => {
                const object = {
                  ...selectedBeneficiaryInList,
                  accountType: event.target.value,
                };

                setSelectedBeneficiaryInList(object);
              }}
            />
          </div>
          <div className="item">
            <Text.Heading5Bold>Tipo de transferência</Text.Heading5Bold>
            <InputSelect
              options={transferTypes}
              className="input-select"
              value={selectedBeneficiaryInList?.transferType}
              onChange={(event: any) => {
                const object = {
                  ...selectedBeneficiaryInList,
                  transferType: event.target.value,
                };

                setSelectedBeneficiaryInList(object);
              }}
            />
          </div>
        </div>
      </When>

      <When condition={!selectedBeneficiaryInList?.keyValue}>
        <div className="row">
          <div className="item">
            <Text.Heading5Bold>Agência</Text.Heading5Bold>
            <InputForm
              placeholder="1234"
              value={selectedBeneficiaryInList?.agency}
              onChange={(event: any) => {
                const object = {
                  ...selectedBeneficiaryInList,
                  agency: event.target.value,
                };

                setSelectedBeneficiaryInList(object);
              }}
            />
          </div>
          <div className="item">
            <Text.Heading5Bold>Conta com dígito</Text.Heading5Bold>
            <InputForm
              placeholder="12345678-9"
              value={selectedBeneficiaryInList?.bankAccount}
              onChange={(event: any) => {
                const object = {
                  ...selectedBeneficiaryInList,
                  bankAccount: event.target.value,
                };

                setSelectedBeneficiaryInList(object);
              }}
            />
          </div>
        </div>
      </When>

      <div className="row-switch">
        <Text.Heading5Bold>
          Salvar contato para próximas transações
        </Text.Heading5Bold>
        <InputSwitch
          className="input-switch"
          checked={selectedBeneficiaryInList?.saveForNextTransfer}
          onChange={() => {
            const object = {
              ...selectedBeneficiaryInList,
              saveForNextTransfer:
                !selectedBeneficiaryInList?.saveForNextTransfer,
            };

            setSelectedBeneficiaryInList(object);
          }}
        />
      </div>

      <ModalMultiStepBottom
        singleButtonText="Confirmar e voltar"
        singleButtonWidth="190px"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => handleEditBeneficiary()}
        className="multi-step-bottom"
        singleButtonLoading={loadingUpdatePaymentList}
        disableSingleButton={disableNext}
      />
    </div>
  );
};

export default EditBeneficiary;
