import { gql, request, useMutation } from '~reactQuery';

import { User } from '~reactQuery/types/business.types';

interface SignInInput {
  username: string;
  passwd: string;
}

interface SignInResponse {
  signIn: {
    data: {
      temporaryPassword: boolean;
      user: User;
      access_token: string;
      iat: number;
      exp: number;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useSignInMutation() {
  const mutation = gql`
    mutation SignIn($params: SignInInput!) {
      signIn(params: $params) {
        data {
          temporaryPassword
          user {
            id
            capu
            migrated
            registerName
            socialName
            phone
            email
            documentNumber
            deletedAt
            createdAt
            updatedAt
            active
            userOrganizations {
              id
              flags
              admin
              active
              createdAt
              updatedAt
              organizationsId
              organizations {
                id
                name
                country
                businessCode
                setup
                bankCompe
                branch
                account
                image
                deletedAt
                createdAt
                updatedAt
                walletsInternalCredentialsId
                banklyPartnersId
                walletsInternalCredentials {
                  id
                  internalPin
                  sessionKey
                  userBusinessId
                }
                partner {
                  id
                  companyKey
                  grantType
                }
                settings {
                  id
                  enablePin
                  twoFA
                  language
                  notificationFlags
                  createdAt
                  updatedAt
                }
              }
              settings {
                id
                enablePin
                twoFA
                language
                notificationFlags
                createdAt
                updatedAt
              }
            }
            settings {
              id
              enablePin
              twoFA
              language
              notificationFlags
              createdAt
              updatedAt
            }
          }
          access_token
          iat
          exp
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: SignInInput) => {
    const response = await request<SignInResponse>(mutation, {
      params,
    });
    return response.signIn;
  };

  return useMutation('useSignIn', fetch);
}
