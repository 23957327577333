import { SVGProps } from 'react';
import colors from '~styles/colors';

const PayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    fill={props.fill || '#272937'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.667 1.917a.75.75 0 0 1 .75.75v10.606a.75.75 0 0 1-1.5 0V2.667a.75.75 0 0 1 .75-.75Zm2.666 0a.75.75 0 0 1 .75.75v6.622a.75.75 0 1 1-1.5 0V2.667a.75.75 0 0 1 .75-.75Zm6.084.75a.75.75 0 0 0-1.5 0v6.622a.75.75 0 1 0 1.5 0V2.667ZM8 1.917a.75.75 0 0 1 .75.75v10.606a.75.75 0 0 1-1.5 0V2.667a.75.75 0 0 1 .75-.75Zm6.083.75a.75.75 0 0 0-1.5 0v10.606a.75.75 0 0 0 1.5 0V2.667Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default PayIcon;
