const CapContaConversionSVG = (props: React.SVGProps<SVGSVGElement>) => {
  const { fill = 'none', stroke = '#11D593', height = 24, width = 24 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M21.5 14.5C21.5 18.366 18.366 21.5 14.5 21.5C10.634 21.5 7.5 18.366 7.5 14.5C7.5 10.634 10.634 7.5 14.5 7.5C18.366 7.5 21.5 10.634 21.5 14.5Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.5 11.5L13 16"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.5 8.5L16 13"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default CapContaConversionSVG;
