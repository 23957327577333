import BluePapersSVG from '~assets/svg/bluePapers';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { ShowKeyExamplePageProps } from '../CreatePixKeys.types';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import { PixKeyTypes } from '~reactQuery/types/pix.types';
import ShieldFillSVG from '~assets/svg/shieldFill';
import { cnpjMask } from '~helpers/format/masks';

function ShowKeyExample(props: ShowKeyExamplePageProps) {
  const { setCurrentStep, keyType = 'EVP', value } = props;

  const labelsAndIcons: Record<
    PixKeyTypes,
    {
      title: string;
      description: string;
      key: string;
      aboveIconText: string;
      icon: JSX.Element;
    }
  > = {
    CNPJ: {
      title: 'Chave verificada',
      description: `Você irá receber transferências pelo pix para ${cnpjMask(
        value!
      )}`,
      key: cnpjMask(value!),
      aboveIconText:
        'Chave do tipo CNPJ será usada para transferências via pix',
      icon: <BluePapersSVG />,
    },
    EVP: {
      title: 'Registrar chave aleatória',
      description: '',
      key: '',
      aboveIconText:
        'Com uma chave aleatória, você poderá receber transferência Pix, sem precisar compartilhar seus dados.',
      icon: <ShieldFillSVG fill={colors.primary} />,
    },
  };

  function goNext() {
    setCurrentStep('confirmPin');
  }

  return (
    <>
      <ModalMultiStepHeader
        title={labelsAndIcons[keyType].title}
        description={labelsAndIcons[keyType].description}
        backButtonAction={() => setCurrentStep('possiblesKeys')}
      />
      <div className="create-pix-body">
        <div className="body-container-card">
          {labelsAndIcons[keyType].icon}
          <Text.Heading4>{labelsAndIcons[keyType].aboveIconText}</Text.Heading4>
          {keyType !== 'EVP' && (
            <div>
              <Text.Paragraph color={colors.grayMedium}>
                {keyType}
              </Text.Paragraph>
              <Text.Heading4Bold>
                {labelsAndIcons[keyType].key}
              </Text.Heading4Bold>
            </div>
          )}
        </div>
      </div>
      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="1"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
      />
    </>
  );
}

export default ShowKeyExample;
