import * as Styles from '../Convert.styles';
import * as Text from '~styles/text';

import { formatCurrencyFn, getWalletIcon } from '~helpers/format/currency';

import ArrowRightIcon from '~assets/images/icons/arrow-right-convert.svg';
import { DetailsProps } from '../Convert.types';
import InfoSVG from '~assets/svg/info';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { RoundButtonIcon } from '~components/Buttons';
import colors from '~styles/colors';
import { getSelectedUserOrganization } from '~helpers/device';
import { useCurrencyExchange } from '~reactQuery/queries/wallet/useCurrencyExchange';

function Details(props: DetailsProps) {
  const { setCurrentStep, conversionDetails } = props;

  const userOrganizationId = getSelectedUserOrganization();

  const originWallet = conversionDetails?.originWallet;
  const destinationWallet = conversionDetails?.destinationWallet;

  const originWalletIsoCode = originWallet?.currency.isoCode;
  const destinationWalletIsoCode = destinationWallet?.currency.isoCode;

  const { data: exchangeResult, isLoading } = useCurrencyExchange(
    {
      amount: conversionDetails?.amount || '',
      fromCurrency: originWalletIsoCode || '',
      toCurrency: destinationWalletIsoCode || '',
      userOrganizationId: Number(userOrganizationId),
    },
    true,
    5000
  );

  const goBack = () => {
    setCurrentStep('chooseWallets');
  };

  const goToPinStep = () => {
    setCurrentStep('pin');
  };

  const renderSubtitle = () => (
    <Text.Heading5 display="inline">
      Ao final da conversão,{' '}
      {isLoading
        ? '0.00'
        : formatCurrencyFn(
            destinationWalletIsoCode,
            String(exchangeResult || 0)
          )}{' '}
      estarão disponíveis em sua CapWallet {destinationWallet?.walletName}.
    </Text.Heading5>
  );

  const renderExchangeRatio = () => {
    return `1 ${destinationWalletIsoCode} ≈ ${formatCurrencyFn(
      originWalletIsoCode,
      String(Number(conversionDetails?.amount) / Number(exchangeResult) || 0),
      false,
      false
    )}`;
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Detalhes da conversão"
        description={renderSubtitle()}
        backButtonAction={goBack}
      />
      <div className="convert-body">
        <Styles.DetailsContentWrapper>
          <div className="wallets-content">
            <div className="origin-wallet-content">
              <div className="arrow-right-container">
                <ArrowRightIcon />
              </div>
              <RoundButtonIcon buttonSize="iconWallet" buttonType="outlined">
                <div className="scale-icon">
                  {getWalletIcon(originWalletIsoCode)}
                </div>
              </RoundButtonIcon>
              <Text.Heading5 fontWeight={500} marginTop={10}>
                {originWallet.currency.name}
              </Text.Heading5>
              <Text.Heading2 color={colors.grayMedium} marginTop={10}>
                -{' '}
                {formatCurrencyFn(
                  originWalletIsoCode,
                  conversionDetails.amount,
                  false,
                  true
                )}
              </Text.Heading2>
            </div>
            <div className="destination-wallet-content">
              <RoundButtonIcon buttonSize="iconWallet" buttonType="outlined">
                <div className="scale-icon">
                  {getWalletIcon(destinationWalletIsoCode)}
                </div>
              </RoundButtonIcon>
              <Text.Heading5 fontWeight={500} marginTop={10}>
                {destinationWallet.currency.name}
              </Text.Heading5>
              <Text.Heading2 marginTop={10}>
                +{' '}
                {exchangeResult &&
                  formatCurrencyFn(
                    destinationWalletIsoCode,
                    String(exchangeResult || 0),
                    false,
                    true
                  )}
              </Text.Heading2>
            </div>
          </div>

          <div className="warning-message">
            <InfoSVG />

            <Text.Small color={colors.grayMedium}>
              Atenção: Por conta da atividade do mercado, os valores recebidos
              podem sofrer razoáveis mudanças.
            </Text.Small>
          </div>
          <div className="details-content-wrapper">
            <div className="info">
              <Text.Paragraph color={colors.grayMedium}>Troca</Text.Paragraph>
              <Text.Heading5>
                {formatCurrencyFn(
                  originWalletIsoCode,
                  conversionDetails.amount
                )}
              </Text.Heading5>
            </div>
            <div className="info">
              <Text.Paragraph color={colors.grayMedium}>Recebe</Text.Paragraph>
              <Text.Heading5>
                {formatCurrencyFn(
                  destinationWalletIsoCode,
                  String(exchangeResult || 0)
                )}
              </Text.Heading5>
            </div>
            <div className="info">
              <Text.Paragraph color={colors.grayMedium}>
                Taxa de câmbio
              </Text.Paragraph>
              <Text.Heading5>{renderExchangeRatio()}</Text.Heading5>
            </div>
          </div>
          {/* <GenericRow
            title="Salvar este par como favorito"
            padding="18px 0"
            leftContentIconType="none"
            rightContentType="component"
            rightComponent={<InputSwitch />}
          /> */}
        </Styles.DetailsContentWrapper>
      </div>
      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonAction={goToPinStep}
      />
    </>
  );
}

export default Details;
