import { gql, request, useQuery } from '~reactQuery';

export interface GetBusinessInfoInput {
  userOrganizationId?: number;
}

export interface GetBusinessInfoDataResponse {
  getBusinessInfo:  {
    data: {
      documentNumber: string;
      businessName: string;
      tradingName: string;
      businessType: string;
      businessSize: string;
      businessCnae: string;
      countryCode: string;
      phone: string;
      site: string;
      businessEmail: string;
      zipCode: string;
      addressLine: string;
      buildingNumber: string;
      complement: string;
      neighborhood: string;
      city: string;
      state: string;
      country: string;
      numberOfPartners: number;
      companyStartDate: string;
      declaredAnnualBilling: string;
      participationPercentage: string;
      isCryptoTrader: string;
      status: string;
    },
    messages: boolean;
    isValid: [string];
  }
}

export const useGetBusinessInfo = (params: GetBusinessInfoInput) => {
  const QUERY = gql`
    query GetBusinessInfo($params: GetBusinessInfoInput) {
      getBusinessInfo(params: $params) {
        data {
          documentNumber
          businessName
          tradingName
          businessType
          businessSize
          businessCnae
          countryCode
          phone
          site
          businessEmail
          zipCode
          addressLine
          buildingNumber
          complement
          neighborhood
          city
          state
          country
          numberOfPartners
          companyStartDate
          declaredAnnualBilling
          participationPercentage
          isCryptoTrader
          status
        }
        messages
        isValid
      }
    }
  `;

  const fetcher = async () => {
    if (!params.userOrganizationId) return null;

    const response = await request<GetBusinessInfoDataResponse>(QUERY, { params });
    return response.getBusinessInfo.data;
  };

  return useQuery(['useGetBusinessInfo'], fetcher, {
    cacheTime: 0,
    staleTime: 0,
  });
};