const SpeechBubbleIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { fill = '#757893', height = 13, width = 13 } = props;

  return (
    <svg
      width={width || '13'}
      height={height || '13'}
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.24902 6.005C2.24902 3.65779 4.15181 1.755 6.49902 1.755C8.84623 1.755 10.749 3.65779 10.749 6.005C10.749 8.35221 8.84623 10.255 6.49902 10.255C6.04822 10.255 5.56516 10.1706 5.1468 10.0355L5.14493 10.0348C5.10946 10.0216 4.9434 9.95996 4.73336 9.96975C4.52166 9.97962 4.33299 10.0533 4.16796 10.1411C3.98093 10.2406 3.7985 10.3254 3.62208 10.3926C3.6489 10.2885 3.67086 10.1892 3.68827 10.0978L3.68851 10.0965C3.79656 9.52385 3.53123 9.04211 3.25146 8.74629C2.63926 8.00481 2.24902 7.02561 2.24902 6.005ZM6.49902 0.255005C3.32339 0.255005 0.749023 2.82937 0.749023 6.005C0.749023 7.41374 1.28799 8.73593 2.11654 9.72752C2.12962 9.74318 2.14334 9.7583 2.15765 9.77283C2.17372 9.78915 2.18651 9.80504 2.19605 9.81919C2.20258 9.82887 2.20696 9.8368 2.20979 9.84265C2.13441 10.2208 1.9813 10.6194 1.82623 10.8101C1.65931 11.0154 1.61293 11.2935 1.7042 11.5419C1.79547 11.7903 2.01087 11.9722 2.27103 12.0206C3.21943 12.197 4.13185 11.8506 4.806 11.5003C5.3295 11.657 5.92116 11.755 6.49902 11.755C9.67466 11.755 12.249 9.18064 12.249 6.005C12.249 2.82937 9.67466 0.255005 6.49902 0.255005ZM4.48828 5.33743C4.12013 5.33743 3.82168 5.63588 3.82168 6.00403C3.82168 6.37218 4.12013 6.67063 4.48828 6.67063H4.49907C4.86723 6.67063 5.16567 6.37218 5.16567 6.00403C5.16567 5.63588 4.86723 5.33743 4.49907 5.33743H4.48828ZM6.49602 5.33743C6.12786 5.33743 5.82942 5.63588 5.82942 6.00403C5.82942 6.37218 6.12786 6.67063 6.49602 6.67063H6.50052C6.86867 6.67063 7.16712 6.37218 7.16712 6.00403C7.16712 5.63588 6.86867 5.33743 6.50052 5.33743H6.49602ZM8.49003 5.33743C8.12188 5.33743 7.82343 5.63588 7.82343 6.00403C7.82343 6.37218 8.12188 6.67063 8.49003 6.67063H8.49902C8.86718 6.67063 9.16562 6.37218 9.16562 6.00403C9.16562 5.63588 8.86718 5.33743 8.49902 5.33743H8.49003Z"
        fill={fill || '#757893'}
      />
    </svg>
  );
};

export default SpeechBubbleIcon;
