import { SVGProps } from 'react';

const PlusCardSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 25}
    height={props.height || 24}
    fill={props.fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5 5v14M5.5 12h14"
      stroke={props.stroke || '#272937'}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlusCardSVG;
