import * as Style from '../../SignTemplate.styles';
import * as Text from '~styles/text';
import { useEffect } from 'react';
import { getSettingsId, setUserInfos } from '~helpers/device';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputPin } from '~components/Inputs';
import colors from '~styles/colors';
import useTimer from '~hooks/useTimer';
import { useTranslation } from 'react-i18next';
import { useValidateSignInTwoFA } from '~reactQuery/mutations/user/useValidateSignInTwoFa';
import useWindowDimensions from '~hooks/windowDimension';
import { handleOnClickHelp } from '~helpers/generic';

export const TwoFaAuthRecovery = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { state: navState } = useLocation();
  const { timeLeft, restartTimer, counting } = useTimer(
    navState?.prevTimeLeft || 0
  );
  const { mutateAsync: validateTwoFA, isLoading } = useValidateSignInTwoFA();

  const user = navState?.userDetails?.data?.user;

  const organization = getSettingsId();

  const goBack = () => {
    if (!timeLeft) {
      navigate('/');
    } else {
      navigate('/auth/two-fa-auth', {
        state: { prevTimeLeft: timeLeft, userDetails: navState?.userDetails },
      });
    }
  };

  const validateLogin = async (token: string) => {
    if (token?.length === 6 && !isLoading) {
      try {
        const resp = await validateTwoFA({
          settingsId: Number(organization),
          userId: user?.id,
          recovery: true,
          token,
        });

        setUserInfos(resp?.data?.access_token, String(user?.id));

        navigate('/');
      } catch (error) {
        //   console.log('error on login with TwoFA -> ', error);
      }
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      navigate('/');
    } else {
      restartTimer();
    }
  }, [counting]);

  if (localStorage.getItem('@CapBiz:twoFa') !== 'true') navigate('/');

  return (
    <Style.ScrollContainer justifyContent="center">
      <Style.ContentWrapper screenWidth={width}>
        <Text.Heading1Medium marginTop={10} marginBottom={30}>
          {t('Problemas com seu dispositivo de autenticação de dois fatores?')}
        </Text.Heading1Medium>

        <Text.Heading4 color={colors.black} marginBottom={10}>
          Código de Recuperação 2FA
        </Text.Heading4>

        <Text.Heading5 color={colors.black} marginBottom={30}>
          Se você não tem acesso ao seu dispositivo móvel, insira um dos seus
          códigos de recuperação para verificar sua identidade.
        </Text.Heading5>

        <InputPin size={6} secret autoFocus onFinish={validateLogin} />

        <Text.Paragraph
          color={colors.primary}
          style={{ cursor: 'pointer' }}
          marginTop={30}
          onClick={goBack}
        >
          {t('Usar código de autenticação 2FA')}
        </Text.Paragraph>

        <Text.Paragraph
          color={colors.primary}
          style={{ cursor: 'pointer' }}
          marginTop={10}
          onClick={handleOnClickHelp}
        >
          {t('Pedir ajuda do Suporte')}
        </Text.Paragraph>
      </Style.ContentWrapper>
    </Style.ScrollContainer>
  );
};

export default TwoFaAuthRecovery;
