import styled from 'styled-components';
import { colors } from '~styles';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-width: 200px;
  height: 144px;
  border: 1px solid rgba(39, 41, 55, 0.05);
  border-radius: 8px;

@media (max-width:576px){
  height: 100px;
}

  .title {
    color: ${colors.grayMedium};
  }
  .value {
    color: ${colors.black};
  }

  .container-description {
    width: 39px;
    height: 24px;
    border-radius: 22px;
    background: rgba(39, 41, 55, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;

    .description {
      color: ${colors.grayMedium};
    }
  }

`;

export const CardHeader = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const CardBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;
