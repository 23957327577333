import * as Style from './UsersSettings.styles';
import * as Text from '~styles/text';
import * as TextSecondary from '~styles/textSecondary';
import PersonIcon from '~assets/svg/person';
import { GenericButton, LoadingSpinner, Search } from '~components/index';
import { useState } from 'react';
import UserSettingsRowList from '~components/UserSettingsRowList/UserSettingsRowList.component';
import EmailIcon from '~assets/svg/email';
import { useGetOrganizationPartners } from '~reactQuery/queries/user/useGetOrganizationPartners';
import { getSelectedOrg } from '~helpers/device';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { InvitedUsersResp } from '~reactQuery/types/business.types';
import {
  permissionAnalyst,
  permissionManager,
} from '~src/constants/permissions';
import colors from '~styles/colors';
import { Else, If, Then, When } from 'react-if';

const UsersSettings: React.FC = () => {
  const [search, setSearch] = useState('');

  const orgId = getSelectedOrg();
  const { data: users = [], isLoading } = useGetOrganizationPartners();

  const { setSection, setParams, setOpenModalMultiStep } =
    useActionsOnModalMultiStep();

  const getCurrentOrg = (user: InvitedUsersResp) => {
    return user?.userOrganizations.filter(
      (org) => org?.organizationsId === Number(orgId)
    )[0];
  };

  const getPermissionType = (permissions: string[]) => {
    if (
      permissionAnalyst.every((perm) => permissions?.includes(perm)) &&
      permissionAnalyst?.length === permissions?.length
    ) {
      return 'Analista';
    }

    if (
      permissionManager.every((perm) => permissions?.includes(perm)) &&
      permissionManager?.length === permissions?.length
    ) {
      return 'Gerente';
    }

    return 'Personalizado';
  };

  const activeUsers = users?.filter((item) => item?.active === true);
  const usersPending = users?.filter((item) => item?.active === false);

  const activeUsersArray = activeUsers
    ?.map((user) => {
      const currOrg = getCurrentOrg(user);

      const name = `${user?.socialName || user?.registerName} ${
        Number(localStorage.getItem('@CapBiz:user-id')) === user.id
          ? '(Você)'
          : ''
      }`;

      return {
        ...user,
        name,
        admission: currOrg?.createdAt,
        permission: getPermissionType(currOrg?.permissions),
        userOrganizationId: currOrg?.id,
      };
    })
    .filter(
      (u) =>
        u?.name?.toLowerCase().includes(search.toLowerCase()) ||
        u?.email?.toLowerCase().includes(search.toLowerCase())
    );

  const usersPendingArray = usersPending
    ?.map((user) => {
      const currOrg = getCurrentOrg(user);

      const name = `${user?.socialName || user?.registerName} ${
        Number(localStorage.getItem('@CapBiz:user-id')) === user.id
          ? '(Você)'
          : ''
      }`;

      return {
        ...user,
        name,
        admission: currOrg?.createdAt,
        permission: getPermissionType(currOrg?.permissions),
        userOrganizationId: currOrg?.id,
        buttonContent: true,
      };
    })
    .filter(
      (u) =>
        u?.name?.toLowerCase().includes(search.toLowerCase()) ||
        u?.email?.toLowerCase().includes(search.toLowerCase())
    );

  const handleCreateNewUser = () => {
    setSection('createUserWithPermission');
    setParams({});
    return setOpenModalMultiStep(true);
  };

  const showUsersActive = activeUsersArray?.length > 0;
  const showUsersPending = usersPendingArray?.length > 0;

  return (
    <Style.UsersContainer>
      <Style.ContainerTitle>
        <PersonIcon />
        <TextSecondary.Heading4Medium>
          Gerenciamento de usuários
        </TextSecondary.Heading4Medium>
      </Style.ContainerTitle>

      <Text.Heading5 marginTop={15}>
        Tenha controle total das permissões de acesso dos membros da sua equipe.
      </Text.Heading5>

      <Style.Row>
        <GenericButton
          width="160px"
          buttonType="inline"
          onClick={() => handleCreateNewUser()}
          borderRadius={200}
        >
          Novo usuário
        </GenericButton>

        <Search
          onChange={(e) => setSearch(e.target.value)}
          placeHolder="Procure por nome ou e-mail..."
          className="input-search"
        />
      </Style.Row>
      <If condition={isLoading}>
        <Then>
          <div className="container-loading">
            <LoadingSpinner color={colors.primary} size={60} strokeSize={25} />
          </div>
        </Then>
        <Else>
          <When condition={showUsersActive}>
            <UserSettingsRowList hideStatusLabel users={activeUsersArray} />
          </When>

          <When condition={showUsersPending}>
            <Style.ContainerTitle style={{ marginTop: 40 }}>
              <EmailIcon width={18} height={14} />
              <TextSecondary.Heading4Medium>
                Usuários Pendentes
              </TextSecondary.Heading4Medium>
            </Style.ContainerTitle>

            <Text.Heading5 marginTop={15} marginBottom={24}>
              Cancele um convite ou reenvie, para acesso à aplicação.
            </Text.Heading5>
            <UserSettingsRowList users={usersPendingArray} />
          </When>
        </Else>
      </If>
    </Style.UsersContainer>
  );
};

export default UsersSettings;
