import ApprovedIcon from '~assets/images/icons/kyc/status/check.svg';
import ArrowRightIcon from '~assets/images/icons/kyc/status/arrow-right.svg';
import { BusinessType } from '~reactQuery/types/business.types';
import ContactInfo from '~assets/images/kyc/contact-info.svg';
import DocumentPhoto from '~assets/images/kyc/document-photo.svg';
import PendingIcon from '~assets/images/icons/kyc/partners/pending.svg';
import PersonalData from '~assets/images/kyc/personal-data.svg';
import ResidenceInfo from '~assets/images/kyc/residence-info.svg';
import colors from '~styles/colors';

export const necessaryDocs = [
  'Cartão CNPJ',
  'Quadro de Sócios e Administradores (QSA)',
  'Informe de Rendimentos dos últimos 12 meses',
  'RG ou CNH de pelo menos 80% do quadro societário (se houver)',
  'Contrato Social e última Alteração Contratual (Se Empresa LTDA)',
  'Estatuto Social e última Ata de Eleição (Se Empresa S/A)',
];

export const getRequiredDocs = (type: BusinessType, isCryptoTrader: boolean) => {
  const docs = [
    {
      title: 'Cartão CNPJ',
      fieldName: 'fileCNPJ',
    },
    {
      title: 'Quadro de Sócios e Administradores (QSA)',
      fieldName: 'fileQSA',
    },
    {
      title: 'Informe de Rendimentos',
      fieldName: 'incomes',
    },
  ];

  if (String(type) === 'SA') {
    docs.push(
      {
        title: 'Estatuto Social',
        fieldName: 'socialStatute',
      },
      {
        title: 'Última Ata de Eleição',
        fieldName: 'lastElection',
      },
    );
  }

  if (String(type) === 'LTDA') {
    docs.push(
      {
        title: 'Última Alteração contratual',
        fieldName: 'lastContractChange',
      },
      {
        title: 'Contrato Social',
        fieldName: 'socialContract',
      },
    );
  }

  if (isCryptoTrader) {
    docs.push(
      {
        title: 'Política de Prevenção à Lavagem de Dinheiro',
        fieldName: 'preventionPolicy',
      },    
      {
        title: 'Comprovante de Regularidade Receita Federal do Brasil (IN 1888/2019)',
        fieldName: 'proofOfRegularity',
      },
      {
        title: `Balanço contábil ${new Date().getFullYear() - 1}`,
        fieldName: 'accountingBalance',
      },
    );
  }

  return docs;
};

export const infoArray = [
  {
    title: 'Dados Pessoais',
    image: <PersonalData />,
  },
  {
    title: 'Informações de Contato',
    image: <ContactInfo />,
  },
  {
    title: 'Informações de Residência',
    image: <ResidenceInfo />,
  },
  {
    title: 'Fotos de Documento',
    image: <DocumentPhoto />,
  },
];

export const legalNatureOptions = [
  { value: 'MEI', label: 'MEI' },
  { value: 'EI', label: 'EI' },
  { value: 'EIRELI', label: 'EIRELI' },
  { value: 'SLU', label: 'SLU' },
  { value: 'LTDA', label: 'LTDA' },
  { value: 'SA', label: 'S/A' },
];

export const businessSize = [
  { value: 'MEI', label: 'MEI' },
  { value: 'ME', label: 'ME' },
  { value: 'EPP', label: 'EPP' },
];

export const declaredAnnualBillingOptions = [
  {
    value: 'EXEMPT_COMPANY',
    label: 'Empresa isenta',
  },
  {
    value: 'INACTIVE_COMPANY',
    label: 'Empresa inativa',
  },
  {
    value: 'UP_TO_FIFTY_THOUSAND',
    label: 'Até 50 mil',
  },
  {
    value: 'MORE_THAN_FIFTY_THOUSAND_UP_TO_ONE_HUNDRED_THOUSAND',
    label: 'De 50 mil a 100 mil',
  },
  {
    value:
      'MORE_THAN_ONE_HUNDRED_THOUSAND_UP_TO_TWO_HUNDRED_AND_FIFTY_THOUSAND',
    label: 'De 100 mil a 250 mil',
  },
  {
    value:
      'MORE_THAN_TWO_HUNDRED_AND_FIFTY_THOUSAND_UP_TO_FIVE_HUNDRED_THOUSAND',
    label: 'De 250 mil a 500 mil',
  },
  {
    value: 'MORE_THAN_FIVE_HUNDRED_THOUSAND_UP_TO_ONE_MILLION',
    label: 'De 500 mil a 1 milhão',
  },
  {
    value: 'MORE_THAN_ONE_MILLION_UP_TO_TWO_MILLION_AND_FIVE_HUNDRED_THOUSAND',
    label: 'De 1 milhão a 2 milhões e 500 mil',
  },
  {
    value: 'MORE_THAN_TWO_MILLION_AND_FIVE_HUNDRED_THOUSAND_UP_TO_FIVE_MILLION',
    label: 'De 2 milhões e 500 mil a 5 milhões',
  },
  {
    value: 'MORE_THAN_FIVE_MILLION_UP_TO_TEN_MILLION',
    label: 'De 5 milhões a 10 milhões',
  },
  {
    value: 'MORE_THAN_TEN_MILLION_UP_TO_TWENTY_FIVE_MILLION',
    label: 'De 10 milhões a 25 milhões',
  },
  {
    value: 'MORE_THAN_TWENTY_FIVE_MILLION_UP_TO_FIFTY_MILLION',
    label: 'De 25 milhões a 50 milhões',
  },
  {
    value: 'MORE_THAN_FIFTY_MILLION_UP_TO_ONE_HUNDRED_MILLION',
    label: 'De 50 milhões a 100 milhões',
  },
  {
    value: 'MORE_THAN_ONE_HUNDRED_MILLION_UP_TO_TWO_HUNDRED_AND_FIFTY_MILLION',
    label: 'De 100 milhões a 250 milhões',
  },
  {
    value: 'MORE_THAN_TWO_HUNDRED_AND_FIFTY_MILLION_UP_TO_FIVE_HUNDRED_MILLION',
    label: 'De 250 milhões a 500 milhões',
  },
  {
    value: 'MORE_THAN_FIVE_HUNDRED_MILLION',
    label: 'Mais de 500 milhões',
  },
];

export const declaredIncomeOptions = [
  {
    label: 'Inferior a mil',
    value: 'LESS_THAN_ONE_THOUSAND',
  },
  {
    label: 'De mil a 2 mil',
    value: 'FROM_ONE_THOUSAND_TO_TWO_THOUSAND',
  },
  {
    label: 'De 2 mil a 3 mil',
    value: 'FROM_TWO_THOUSAND_TO_THREE_THOUSAND',
  },
  {
    label: 'De 3 mil a 5 mil',
    value: 'FROM_THREE_THOUSAND_TO_FIVE_THOUSAND',
  },
  {
    label: 'De 5 mil a 10 mil',
    value: 'FROM_FIVE_THOUSAND_TO_TEN_THOUSAND',
  },
  {
    label: 'De 10 mil a 20 mil',
    value: 'FROM_TEN_THOUSAND_TO_TWENTY_THOUSAND',
  },
  {
    label: 'Acima de 20 mil',
    value: 'OVER_TWENTY_THOUSAND',
  },
];

export const occupationValues = [
  {
    label: 'OCP0001',
    value: 'OCP0001',
  },
];

export const defaultBusinessValues = {
  tradingName: '',
  companyName: '',
  companyCNPJ: '',
  companyCNAE: '',
  companyLegalNature: '',
  declaredAnnualBilling: '',
  participationPercentage: '',
  companyAmountOfPartners: 0,
  businessName: '',
  documentNumber: '',
  businessCnae: '',
  businessType: '',
  numberOfPartners: 0,
  isLegalRepresentative: false,
  businessSize: '',
  companyStartDate: '',
  countryCode: '',
  phone: '',
  site: '',
  businessEmail: '',
  country: '',
  zipCode: '',
  city: '',
  state: '',
  addressLine: '',
  buildingNumber: '',
  isCryptoTrader: false,
  neighborhood: '',
  complement: '',
  updatedAt: '',
};

export const defaultDocsValues = {
  fileCNPJ: '',
  fileQSA: '',
  incomes: '',
  lastElection: '',
  socialStatute: '',
  socialContract: '',
  lastContractChange: '',
  preventionPolicy: '',
  proofOfRegularity: '',
  accountingBalance: '',
};

export const defaultPartnersValues = [
  {
    registerName: '',
    socialName: '',
    documentNumber: '',
    birthDate: '',
    politicallyExposed: false,
    pep: '',
    legalRepresent: false,
    countryCode: '',
    number: '',
    country: '',
    zipCode: '',
    city: '',
    state: '',
    addressLine: '',
    buildingNumber: '',
    neighborhood: '',
    complement: '',
    fileFrontDocument: undefined,
    fileBackDocument: undefined,
    status: '',
  },
];

export const DDIOptions = [
  { value: '+55', label: 'Brasil (+55)' },
  { value: '+1', label: 'Estados Unidos (+1)' },
];

export const memberQualificationOptions = [
  {
    name: 'ADMINISTRAÇÃO PÚBLICA',
    option: [
      {
        label: 'Órgão Público do Poder Executivo Federal (101-5)',
        value: '101-5',
      },
      {
        label:
          'Órgão Público do Poder Executivo Estadual ou do Distrito Federal (102-3)',
        value: '102-3',
      },
      {
        label: 'Órgão Público do Poder Executivo Municipal (103-1)',
        value: '103-1',
      },
      {
        label: 'Órgão Público do Poder Legislativo Federal (104-0)',
        value: '104-0',
      },
      {
        label:
          'Órgão Público do Poder Legislativo Estadual ou do Distrito Federal (105-8)',
        value: '105-8',
      },
      {
        label: 'Órgão Público do Poder Legislativo Municipal (106-6)',
        value: '106-6',
      },
      {
        label: 'Órgão Público do Poder Legislativo Municipal (107-4)',
        value: '107-4',
      },
      {
        label: 'Órgão Público do Poder Judiciário Estadual (108-2)',
        value: '108-2',
      },
      { label: 'Autarquia Federal (110-4)', value: '110-4' },
      {
        label: 'Autarquia Estadual ou do Distrito Federal (111-2)',
        value: '111-2',
      },
      { label: 'Autarquia Municipal (112-0)', value: '112-0' },
      {
        label: 'Fundação Pública de Direito Público Federal (113-9)',
        value: '113-9',
      },
      {
        label:
          'Fundação Pública de Direito Público Estadual ou do Distrito Federal (114-7)',
        value: '114-7',
      },
      {
        label: 'Fundação Pública de Direito Público Municipal (115-5)',
        value: '115-5',
      },
      { label: 'Órgão Público Autônomo Federal (116-3)', value: '116-3' },
      {
        label: 'Órgão Público Autônomo Estadual ou do Distrito Federal (117-1)',
        value: '117-1',
      },
      { label: 'Órgão Público Autônomo Municipal (118-0)', value: '118-0' },
      { label: 'Comissão Polinacional (119-8)', value: '119-8' },
      {
        label:
          'Consórcio Público de Direito Público (Associação Pública) (121-0)',
        value: '121-0',
      },
      { label: 'Consórcio Público de Direito Privado (122-8)', value: '122-8' },
      { label: 'Estado ou Distrito Federal (123-6)', value: '123-6' },
      { label: 'Município (124-4)', value: '124-4' },
      {
        label: 'Fundação Pública de Direito Privado Federal (125-2)',
        value: '125-2',
      },
      {
        label:
          'Fundação Pública de Direito Privado Estadual ou do Distrito Federal (126-0)',
        value: '126-0',
      },
      {
        label: 'Fundação Pública de Direito Privado Municipal (127-9)',
        value: '127-9',
      },
      {
        label: 'Fundo Público da Administração Indireta Federal (128-7)',
        value: '128-7',
      },
      {
        label:
          'Fundo Público da Administração Indireta Estadual ou do Distrito Federal (129-5)',
        value: '129-5',
      },
      {
        label: 'Fundo Público da Administração Indireta Municipal (130-9)',
        value: '130-9',
      },

      {
        label: 'Fundo Público da Administração Direta Federal (131-7)',
        value: '131-7',
      },
      {
        label:
          'Fundo Público da Administração Direta Estadual ou do Distrito Federal (132-5)',
        value: '132-5',
      },
      {
        label: 'Fundo Público da Administração Direta Municipal (133-3)',
        value: '133-3',
      },
      { label: 'União (134-1)', value: '134-1' },
    ],
  },
  {
    name: 'ENTIDADES EMPRESARIAIS',
    option: [
      { label: 'Empresa Pública (201-1)', value: '201-1' },
      { label: 'Sociedade de Economia Mista (203-8)', value: '203-8' },
      { label: 'Sociedade Anônima Aberta (204-6)', value: '204-6' },
      { label: 'Sociedade Anônima Fechada (205-4)', value: '205-4' },
      { label: 'Sociedade Empresária Limitada (206-2)', value: '206-2' },
      {
        label: 'Sociedade Empresária em Nome Coletivo (207-0)',
        value: '207-0',
      },
      {
        label: 'Sociedade Empresária em Comandita Simples (208-9)',
        value: '208-9',
      },
      {
        label: 'Sociedade Empresária em Comandita por (209-7)',
        value: '209-7',
      },
      { label: 'Sociedade em Conta de Participação (212-7)', value: '212-7' },
      { label: 'Empresário Individual (213-5)', value: '213-5' },
      { label: 'Cooperativa (214-3)', value: '214-3' },
      { label: 'Consórcio de Sociedades (215-1)', value: '215-1' },
      { label: 'Grupo de Sociedades (216-0)', value: '216-0' },
      { label: 'Empresa Domiciliada no Exterior (221-6)', value: '221-6' },
      { label: 'Sociedade Simples Pura (223-2)', value: '223-2' },
      { label: 'Sociedade Simples Limitada (224-0)', value: '224-0' },
      { label: 'Sociedade Simples em Nome Coletivo (225-9)', value: '225-9' },
      {
        label: 'Sociedade Simples em Comandita Simples (226-7)',
        value: '226-7',
      },
      { label: 'Consórcio Simples (229-1)', value: '229-1' },
      {
        label:
          'Empresa Individual de Responsabilidade Limitada (de Natureza Empresária) (230-5)',
        value: '230-5',
      },
      {
        label:
          'Empresa Individual de Responsabilidade Limitada (de Natureza Simples) (231-3)',
        value: '231-3',
      },
      { label: 'Sociedade Unipessoal de Advogados (232-1)', value: '232-1' },
      { label: 'Cooperativas de Consumo (233-0)', value: '233-0' },
    ],
  },
  {
    name: 'ENTIDADES SEM FINS LUCRATIVOS',
    option: [
      {
        label: 'Serviço Notarial e Registral (Cartório) (303-4)',
        value: '303-4',
      },
      { label: 'Fundação Privada (306-9)', value: '306-9' },
      { label: 'Serviço Social Autônomo (307-7)', value: '307-7' },
      {
        label: 'Condomínio Edilício (308-5)',
        value: '308-5',
      },
      { label: 'Comissão de Conciliação Prévia (310-7)', value: '310-7' },
      { label: 'Entidade de Mediação e Arbitragem (311-5)', value: '311-5' },
      { label: 'Entidade Sindical (313-1)', value: '313-1' },
      {
        label:
          'Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras (320-4)',
        value: '320-4 ',
      },
      {
        label: 'Fundação ou Associação Domiciliada no Exterior (321-2)',
        value: '321-2',
      },
      { label: 'Organização Religiosa (322-0)', value: '322-0' },
      { label: 'Comunidade Indígena (323-9)', value: '323-9' },
      { label: 'Fundo Privado (324-7)', value: '324-7' },
      {
        label: 'Órgão de Direção Nacional de Partido Político (325-5)',
        value: '325-5',
      },
      {
        label: 'Órgão de Direção Regional de Partido Político (326-3)',
        value: '326-3',
      },
      {
        label: 'Órgão de Direção Local de Partido Político (327-1)',
        value: '327-1',
      },
      {
        label: 'Comitê Financeiro de Partido Político (328-0)',
        value: '328-0',
      },
      {
        label: 'Frente Plebiscitária ou Referendária (329-8)',
        value: '329-8',
      },
    ],
  },
  {
    name: 'PESSOAS FÍSICAS',
    option: [
      { label: 'Empresa Individual Imobiliária (401-4)', value: '401-4' },
      { label: 'Candidato a Cargo Político Eletivo (409-0)', value: '409-0' },
      { label: 'Produtor Rural (Pessoa Física) (412-0)', value: '412-0' },
    ],
  },
  {
    name: 'ORGANIZAÇÕES INTERNACIONAIS E OUTRAS INSTITUIÇÕES EXTRATERRITORIAIS',
    option: [
      { label: 'Organização Internacional (501-0)', value: '501-0' },
      {
        label: 'Representação Diplomática Estrangeira (502-9)',
        value: '502-9',
      },
      {
        label: 'Outras Instituições Extraterritoriais (503-7)',
        value: '503-7',
      },
    ],
  },
];

export const countryOptions = [
  { value: 'BR', label: 'Brasil' },
  { value: 'EUA', label: 'Estados Unidos' },
];

export const documentsOptions = [
  { value: 'RG', label: 'RG' },
  { value: 'CNH', label: 'CNH' },
];

export const politicallyExposedTypes = [
  {
    value: 'NONE',
    label: 'Não sou exposto politicamente',
  },
  {
    value: 'SELF',
    label: 'Sou exposto politicamente',
  },
  {
    value: 'RELATED',
    label: 'Tenho vínculo com alguém politicamente exposto',
  },
];

export const stepsStatusProgressBar = {
  start: 1,
  status: 0,
  companyData: 10,
  sendDocs: 55,
  registerPartners: 90,
  finish: 100,
  loading: 0,
};

export const stepOfProcessStatus = {
  approved: {
    icon: <ApprovedIcon />,
    message: 'Aprovado',
    color: colors.grayMedium,
    enableRedirect: false,
  },
  reproved: {
    icon: <ArrowRightIcon />,
    message: 'Não aprovado',
    color: colors.danger,
    enableRedirect: true,
  },
  pending: {
    icon: <PendingIcon />,
    color: colors.grayMedium,
    enableRedirect: true,
  },
  notSend: {
    icon: <ArrowRightIcon />,
    message: 'Pendente',
    color: colors.warning,
    enableRedirect: true,
  },
};
