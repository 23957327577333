import { colors, metrics } from '~styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 335px;
  padding: 0 18px;

  .outline-button {
    padding: 16px 50px;
    align-self: center;
  }
`;
