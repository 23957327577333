import { useQueryClient } from 'react-query';
import { gql, request, useMutation } from '~reactQuery/index';

interface ParamsDisableUserByAdmin {
  userId: number;
  userOrganizationId: number;
}

interface DisableUserByAdminResponse {
  disableUserByAdmin: {
    data: string;
    isValid: boolean;
    messages: string[];
  };
}

export const useDisableUserByAdmin = () => {
  const queryClient = useQueryClient();
  const mutation = gql`
    mutation DisableUserByAdmin($params: DisableUserInput!) {
      disableUserByAdmin(params: $params) {
        data
        isValid
        messages
      }
    }
  `;

  const fetch = async (params: ParamsDisableUserByAdmin) => {
    if (!params.userId) return null;
    const response = await request<DisableUserByAdminResponse>(mutation, {
      params,
    });

    return response;
  };

  return useMutation(['useDisableUserByAdmin'], fetch, {
    onSettled: () => {
      queryClient.invalidateQueries(['useGetOrganizationPartners']);
    },
  });
};
