import { cellphoneMask, cepMask, cnaeMask, cnpjMask, cpfMask } from './masks';
import { Business } from '~reactQuery/types/business.types';
import dayjs from 'dayjs';
import { defaultBusinessValues } from '~data/kyc';
import { getBase64 } from './file';
import { v4 } from 'uuid';

export const parsePartnersSchema = (
  partners: any,
  type: 'owner' | 'partner' | 'partnerOfPartner',
  ownerDocNum?: string
) => {
  if (!partners || partners?.length === 0) return undefined;

  const partnersList = partners?.map((partner: any) => {
    let countryCodeParsed = null;

    if (['partner', 'partnerOfPartner'].includes(type)) {
      countryCodeParsed = partner?.countryCode || null;
    } else {
      const partnerPhoneCountryCode = partner?.phone?.countryCode;

      if (partnerPhoneCountryCode && partnerPhoneCountryCode[0] === '+') {
        countryCodeParsed = partnerPhoneCountryCode;
      } else {
        countryCodeParsed = partnerPhoneCountryCode
          ? `+${partnerPhoneCountryCode}`
          : null;
      }
    }

    return {
      memberQualification: partner?.memberQualification,
      declaredIncome: partner?.declaredIncome,
      participationPercentage: partner?.participationPercentage,
      pep: partner?.pep,
      isPowerOfAttorney: partner?.isPowerOfAttorney,
      powerOfAttorneyStartDate:
        partner?.powerOfAttorneyStartDate &&
        dayjs(partner?.powerOfAttorneyStartDate.slice(0, 10)).format(
          'DD/MM/YYYY'
        ),
      powerOfAttorneyEndDate:
        partner?.powerOfAttorneyEndDate &&
        dayjs(partner?.powerOfAttorneyEndDate.slice(0, 10)).format(
          'DD/MM/YYYY'
        ),
      powerOfAttorney: partner?.powerOfAttorney,
      partnerId: partner?.id || partner?.partnerId || undefined,
      registerName: partner?.registerName,
      socialName: partner?.socialName,
      documentNumber: cpfMask(partner?.documentNumber),
      birthDate:
        partner?.birthDate &&
        dayjs(partner?.birthDate.slice(0, 10)).format('DD/MM/YYYY'),
      email: partner?.email,
      motherName: partner?.motherName,
      documentType: partner?.documentType,
      legalRepresent: ['partner', 'partnerOfPartner'].includes(type)
        ? partner?.legalRepresent
        : true,
      countryCode: countryCodeParsed,
      number: ['partner', 'partnerOfPartner'].includes(type)
        ? cellphoneMask(partner?.number)
        : cellphoneMask(partner?.phone?.number),
      country: ['partner', 'partnerOfPartner'].includes(type)
        ? partner?.country
        : partner?.address?.country,
      zipCode: ['partner', 'partnerOfPartner'].includes(type)
        ? cepMask(partner?.zipCode)
        : cepMask(partner?.address?.zipCode),
      city: ['partner', 'partnerOfPartner'].includes(type)
        ? partner?.city
        : partner?.address?.city,
      state: ['partner', 'partnerOfPartner'].includes(type)
        ? partner?.state
        : partner?.address?.state,
      addressLine: ['partner', 'partnerOfPartner'].includes(type)
        ? partner?.addressLine
        : partner?.address?.addressLine,
      buildingNumber: ['partner', 'partnerOfPartner'].includes(type)
        ? partner?.buildingNumber
        : partner?.address?.buildingNumber,
      neighborhood: ['partner', 'partnerOfPartner'].includes(type)
        ? partner?.neighborhood
        : partner?.address?.neighborhood,
      complement: ['partner', 'partnerOfPartner'].includes(type)
        ? partner?.complement || ''
        : partner?.address?.complement || '',
      fileFrontDocument: partner?.fileFrontDocument,
      fileBackDocument: partner?.fileBackDocument,
      status: partner?.status,
      fields: partner?.fields,
      updatedAt: partner?.updatedAt,
      partnerType: ownerDocNum === partner?.documentNumber ? 'owner' : type,
      uniqueFieldId: v4(),
      partnerOfPartner: type === 'partnerOfPartner',
    };
  });

  return partnersList;
};

export const parseBusinessData = (setValue: any, kycProcessData: any) => {
  if (kycProcessData?.id) {
    Object.keys(kycProcessData).forEach((key) => {
      switch (key) {
        case 'documentNumber':
          setValue(key, cnpjMask(kycProcessData?.[key]));
          break;
        case 'businessCnae':
          setValue(key, cnaeMask(kycProcessData?.[key]));
          break;
        case 'phone':
          setValue(key, cellphoneMask(kycProcessData?.[key]));
          break;
        case 'zipCode':
          setValue(key, cepMask(kycProcessData?.[key]));
          break;
        case 'companyStartDate':
          setValue(
            key,
            dayjs(kycProcessData?.[key]?.slice(0, 10)).format('DD/MM/YYYY')
          );
          break;
        case 'declaredAnnualBilling':
          setValue(key, kycProcessData?.[key]);
          break;

        default:
          if (Object.keys(defaultBusinessValues).includes(key)) {
            setValue(key, kycProcessData?.[key as keyof Business]);
          }
      }
    });
  }
};

export const parsePartnerList = async (partnerList: any) => {
  if (!partnerList?.length) return undefined;
  const result = [] as any;

  await Promise.all(
    partnerList?.map(async (partner: any) => {
      if (!partner?.documentNumber) return;
      const parse = {
        declaredIncome: partner?.declaredIncome,
        pep: partner?.pep,
        declaredAnnualBilling: partner?.declaredAnnualBilling,
        memberQualification: partner?.memberQualification,
        participationPercentage: partner?.participationPercentage,
        isPowerOfAttorney: String(partner?.isPowerOfAttorney),
        powerOfAttorneyStartDate:
          partner?.powerOfAttorneyStartDate &&
          `${dayjs(
            partner?.powerOfAttorneyStartDate?.slice(0, 10),
            'DD/MM/YYYY'
          ).format('YYYY-MM-DD')}T00:00:00.000Z`,
        powerOfAttorneyEndDate:
          partner?.powerOfAttorneyEndDate &&
          `${dayjs(
            partner?.powerOfAttorneyEndDate?.slice(0, 10),
            'DD/MM/YYYY'
          ).format('YYYY-MM-DD')}T00:00:00.000Z`,
        isSaved: partner?.isSaved,
        partnerId: partner?.partnerId ? Number(partner?.partnerId) : null,
        documentNumber: partner?.documentNumber?.replace(/[^\d]/g, ''),
        legalRepresent: String(partner?.legalRepresent),
        registerName: partner?.registerName,
        socialName: partner?.socialName,
        countryCode: ['+'].includes(partner?.countryCode?.[0])
          ? partner?.countryCode
          : `+${partner?.countryCode}`,
        number: partner?.number?.replace(/\D/g, ''),
        zipCode: partner?.zipCode?.replace(/\D/g, ''),
        addressLine: partner?.addressLine,
        buildingNumber: partner?.buildingNumber,
        complement: partner?.complement,
        neighborhood: partner?.neighborhood,
        city: partner?.city,
        state: partner?.state,
        birthDate: `${dayjs(
          partner?.birthDate?.slice(0, 10),
          'DD/MM/YYYY'
        ).format('YYYY-MM-DD')}T00:00:00.000Z`,
        country: partner?.country,
        documentType: partner?.documentType,
        motherName: partner?.motherName,
        email: partner?.email,
        fileFrontDocument:
          typeof partner?.fileFrontDocument !== 'string' &&
          partner?.fileFrontDocument?.[0]
            ? await getBase64(partner?.fileFrontDocument?.[0])
            : partner?.fileFrontDocument,
        fileBackDocument:
          typeof partner?.fileBackDocument !== 'string' &&
          partner?.fileBackDocument?.[0]
            ? await getBase64(partner?.fileBackDocument?.[0])
            : partner?.fileBackDocument,
        powerOfAttorney:
          typeof partner?.powerOfAttorney !== 'string' &&
          partner?.powerOfAttorney?.[0]
            ? await getBase64(partner?.powerOfAttorney?.[0])
            : partner?.powerOfAttorney,
      };
      result.push(parse);
    })
  );
  return result;
};

export const parserBusiness = (formValues: any, reverse?: boolean) => {
  return {
    isSaved: formValues?.isSaved,
    partnerData: parsePartnersSchema(
      formValues?.partnerData,
      'partnerOfPartner',
      ''
    )
      ?.filter((i: any) => !!i)
      ?.sort((a: any, b: any) => (a?.id > b?.id ? 1 : -1)),
    status: formValues?.status,
    id: formValues?.id,
    businessId: formValues?.id,
    businessData: formValues?.businessData,
    addressLine: formValues?.addressLine,
    buildingNumber: String(formValues?.buildingNumber),
    businessCnae: reverse
      ? cnaeMask(formValues?.businessCnae)
      : formValues?.businessCnae?.replace(/[^0-9]+/g, ''),
    businessEmail: formValues?.businessEmail,
    businessName: formValues?.businessName,
    businessSize: formValues?.businessSize,
    city: formValues?.city,
    country: formValues?.country,
    countryCode: ['+'].includes(formValues?.countryCode?.[0])
      ? formValues?.countryCode
      : `+${formValues?.countryCode}`,
    documentNumber: reverse
      ? cnpjMask(formValues?.documentNumber)
      : formValues?.documentNumber?.replace(/[^0-9]+/g, ''),
    neighborhood: formValues?.neighborhood,
    phone: reverse ? cellphoneMask(formValues?.phone) : formValues?.phone,
    state: formValues?.state,
    tradingName: formValues?.tradingName,
    zipCode: reverse ? cepMask(formValues?.zipCode) : formValues?.zipCode,
    businessType: formValues?.businessType,
    complement: formValues?.complement,
    site: formValues?.site,
    declaredAnnualBilling: formValues?.declaredAnnualBilling,
    participationPercentage: formValues?.participationPercentage,
    numberOfPartners: Number(formValues?.numberOfPartners),
    companyStartDate: reverse
      ? dayjs(formValues?.companyStartDate?.slice(0, 10), 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        )
      : `${dayjs(
          formValues?.companyStartDate?.slice(0, 10),
          'DD/MM/YYYY'
        ).format('YYYY-MM-DD')}T00:00:00.000Z`,
    uniqueFieldId: v4(),
    fields: formValues?.fields,
    isCryptoTrader: formValues?.isCryptoTrader,
  };
};
