import React from 'react';
import * as Style from './YourProfile.styles';
import * as Text from '~styles/text';
import * as TextSecondary from '~styles/textSecondary';
import colors from '~styles/colors';
import EditIcon from '~assets/images/icons/16x16/pencil.svg';
import PhoneIcon from '~assets/images/icons/24x24/phone.svg';
import { GenericButton } from '~components/Buttons';
import AvatarUpload from '~components/AvatarUpload';
import UserSVG from '~assets/images/icons/24x24/user2.svg';
import { useGetPartnerUser } from '~reactQuery/queries/settings/useGetPartnerUser';
import { getSelectedUserOrganization } from '~helpers/device';
import { formatDate } from '~helpers/format/date';
import { formatCpfOrCnpj } from '~helpers/format/numbers';
import { cepMask, formatPhone } from '~helpers/format/masks';

const YourProfile: React.FC = () => {

  const userOrganizationId = getSelectedUserOrganization();

  const { data: userData } = useGetPartnerUser({ userOrganizationId: Number(userOrganizationId) });

  return (
    <Style.Container>
      <div className='company-content'>
        <div className='icon-container'>
          <UserSVG />

          <TextSecondary.Heading4Medium>
            Suas informações
          </TextSecondary.Heading4Medium>
        </div>

        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Nome Completo
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {userData?.data?.registerName || '--'}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Nome Social
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {userData?.data?.socialName || '--'}
              </Text.Paragraph>
            </div>
          </div>
        </div>

        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Gênero
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {userData?.data?.gender || '--'}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Data de Nascimento
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {
                  userData?.data?.birthDate
                    ? formatDate(userData?.data?.birthDate, 'LL')
                    : '--'
                }
              </Text.Paragraph>
            </div>
          </div>
        </div>

        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                CPF
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {
                  userData?.data?.documentNumber
                    ? formatCpfOrCnpj(userData.data.documentNumber)
                    : '--'
                }
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Permissões
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                --
              </Text.Paragraph>
            </div>
          </div>
        </div>
      </div>

      <div className='contact-content'>
        <div className='icon-container'>
          <PhoneIcon />

          <TextSecondary.Heading4Medium>
            Seu contato
          </TextSecondary.Heading4Medium>
        </div>


        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                País/região
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {userData?.data?.country || '--'}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                CEP
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {cepMask(userData?.data?.zipCode || '--')}
              </Text.Paragraph>
            </div>
          </div>
        </div>

        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Cidade
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {userData?.data?.city || '--'}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Estado
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {userData?.data?.state || '--'}
              </Text.Paragraph>
            </div>
          </div>
        </div>

        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Endereço
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {userData?.data?.addressLine || '--'}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Número
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {userData?.data?.buildingNumber || '--'}
              </Text.Paragraph>
            </div>
          </div>
        </div>

        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Bairro
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {userData?.data?.neighborhood || '--'}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Complemento
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {userData?.data?.complement || '--'}
              </Text.Paragraph>
            </div>
          </div>
        </div>

        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Telefone
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {formatPhone(userData?.data?.number || '--')}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                E-mail
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {userData?.data?.email || '--'}
              </Text.Paragraph>
            </div>
          </div>
        </div>
      </div>
    </Style.Container>
  );
}

export default YourProfile;