import { gql, request, useMutation } from '~reactQuery';

import { Organizations } from '~reactQuery/types/business.types';

interface SignInOrganizationInput {
  organizationId: number;
  pin: string;
}

interface SignInOrganizationResponse {
  signInOrganization: {
    data: {
      organization: Organizations;
      temporaryPin: boolean;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useSignInOrganizationMutation() {
  const mutation = gql`
    mutation SignInOrganization($params: SignInOrganizationInput!) {
      signInOrganization(params: $params) {
        data {
          organization {
            id
            name
            country
            businessCode
            setup
            bankCompe
            branch
            account
            image
            deletedAt
            createdAt
            updatedAt
            walletsInternalCredentialsId
            banklyPartnersId
            walletsInternalCredentials {
              id
              internalPin
              sessionKey
              userBusinessId
            }
            partner {
              id
              companyKey
              grantType
            }
            settings {
              id
              enablePin
              twoFA
              language
              notificationFlags
              createdAt
              updatedAt
            }
          }
          temporaryPin
        }
      }
    }
  `;

  const fetch = async (params: SignInOrganizationInput) => {
    const response = await request<SignInOrganizationResponse>(mutation, {
      params,
    });
    return response.signInOrganization;
  };

  return useMutation('SignInOrganization', fetch);
}
