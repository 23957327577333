import * as Text from '~styles/text';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import EndStepStatus from '~components/EndStepStatus';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useFormContext } from 'react-hook-form';
import { RefundProps } from '../Refund.types';
import { Else, If, Then } from 'react-if';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetStatementSpecific } from '~reactQuery/queries/bank/useGetStatementSpecific';
import dayjs from 'dayjs';
import LoadingSpinner from '~components/LoadingSpinner';
import colors from '~styles/colors';
import ReceiptIcon from '~assets/images/icons/transactions/receipt.svg';
import { RefundSendReceipt } from '~assets/pdfs/refundSend/RefundSendReceipt';

const Status = ({ setCurrentRefundStep, transactionDetails }: RefundProps) => {
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();
  const { watch, getValues, reset } = useFormContext();
  const formValues = getValues();
  const userOrganization = getSelectedUserOrganization();
  const { data: accountDetails, isLoading: loadingGetAccount } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganization),
    });
  const { data: transferSpecificDetails } = useGetStatementSpecific({
    authCode: formValues?.authCode,
    userOrganizationId: Number(userOrganization),
  });

  const name =
    transactionDetails?.data?.requisite?.holderName ||
    transactionDetails?.data?.requisite?.key?.holderName;

  const showDownload = transferSpecificDetails && !loadingGetAccount;

  return (
    <div className="status-container">
      <EndStepStatus animType="success" />
      <Text.Heading1Bold marginTop={25}>Reembolso realizado!</Text.Heading1Bold>

      <Text.Heading4 marginTop={25} textAlign="center">
        Você reembolsou {watch('amount')} para {name} via Pix a partir da sua
        CapConta.
      </Text.Heading4>

      <div className="receipt-button">
        <If condition={showDownload}>
          <Then>
            <PDFDownloadLink
              document={
                <RefundSendReceipt
                  accountDetails={accountDetails}
                  transferDetails={transferSpecificDetails}
                  status={transferSpecificDetails?.status}
                  authCode={transferSpecificDetails?.authCode}
                  transactionOriginal={transactionDetails}
                />
              }
              fileName={`transfer-voucher-${dayjs().format('DD-MM-YYYY')}.pdf`}
            >
              {({ loading }) => {
                return loading ? (
                  <LoadingSpinner color={colors.black} />
                ) : (
                  <div className="receipt-button">
                    <ReceiptIcon />
                    <Text.Heading5 marginLeft={10} color={colors.primary}>
                      Baixar comprovante
                    </Text.Heading5>
                  </div>
                );
              }}
            </PDFDownloadLink>
          </Then>
          <Else>
            <LoadingSpinner color={colors.black} />
          </Else>
        </If>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Concluir"
        singleButtonWidth="122px"
        singleButtonAction={() => {
          reset();
          setOpenModalMultiStep(false);
          setCurrentRefundStep('amountToRefund');
        }}
      />
    </div>
  );
};

export default Status;
