import { gql, request, useMutation } from '~reactQuery/index';

import { PIX_QUERY_KEYS } from '~src/constants/pix';
import { ResolveData } from '~reactQuery/types/pix.types';
import { useIsMutating } from 'react-query';

interface ResolvePixKeyInput {
  userOrganizationId: number;
  key: string;
  documentNumber: string;
}

interface ResolvePixKeyResponse {
  resolvePixKey: {
    data: {
      key: ResolveData;
      keyType: string;
      e2eId: string;
    };
    messages: string[];
    isValid: boolean;
  };
}

export const useResolvePixKey = () => {
  const isMutating = Boolean(useIsMutating([PIX_QUERY_KEYS.RESOLVE_PIX_KEY]));

  const QUERY = gql`
    mutation ResolvePixKey($params: ResolvePixKeyInput!) {
      resolvePixKey(params: $params) {
        data {
          key {
            holderName
            holderDocument
            accountType
          }
          keyType
          e2eId
        }
        messages
        isValid
      }
    }
  `;

  const fetcher = async (params: ResolvePixKeyInput) => {
    if (isMutating) return null;

    const response = await request<ResolvePixKeyResponse>(QUERY, { params });
    return response.resolvePixKey?.data;
  };

  return useMutation([PIX_QUERY_KEYS.RESOLVE_PIX_KEY], fetcher);
};
