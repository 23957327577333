import * as Text from '~styles/text';
import colors from '~styles/colors';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ArrowLeftSVG from '~assets/svg/arrowLeft';
import { RoundButtonIcon, UserPic } from '~components/index';
import DotsFloatingMenu from '~assets/images/icons/kyc/dots-vertical.svg';
import { RowList } from '../SavedLists.styles';
import EditListIcon from '~assets/svg/editList';
import { DropDown } from '~components/DropDown';
import { DropDownItem } from '~components/DropDown/DropDownItem';
import DeleteIcon from '~assets/svg/delete';
import TrashSVG from '~assets/svg/trash';
import AddIcon from '~assets/svg/addSvg';
import { capitalizeInitialsAndRemoveNumbers } from '~helpers/format/capitalize';
import { SavedListsProps } from '../SavedLists.types';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

const EditList = ({
  selectedList,
  setStepSavedLists,
  setModalAddBeneficiaryIsVisible,
  setModalRenameListIsVisible,
  setModalDeleteListIsVisible,
  setSelectedBeneficiaryInList,
  setBeneficiaryPerPix,
  setStepTransferBatch,
}: SavedListsProps) => {
  const { setParams } = useActionsOnModalMultiStep();
  const handleTranslatorKeyType = (value: string) => {
    const translatorKeyType: { [key: string]: string } = {
      PHONE: 'Pix',
      CPF: 'Pix',
      CNPJ: 'Pix',
      EMAIL: 'Pix',
    };

    return translatorKeyType[value] ? translatorKeyType[value] : value;
  };

  const handleNext = () => {
    setStepTransferBatch('confirmPaymentList');
    setParams({
      origin: 'savedLists',
      recipientsList: selectedList?.data,
    });
  };

  return (
    <>
      <div
        className="go-back-button-edit-list"
        onClick={() => setStepSavedLists('showAllSavedLists')}
      >
        <ArrowLeftSVG />
      </div>

      <div className="align-row-edit-list">
        <div className="title-list">
          <Text.Heading2Bold>{selectedList?.data?.nameList}</Text.Heading2Bold>
          <Text.Smaller fontSize={0.8125} color={colors.grayMedium}>
            Lista personalizada
          </Text.Smaller>
        </div>
        <DropDown
          content={
            <>
              <DropDownItem
                icon={<AddIcon />}
                title="Novo beneficiário"
                onClick={() => {
                  setModalAddBeneficiaryIsVisible(true);
                  setBeneficiaryPerPix(null);
                }}
              />

              <DropDownItem
                icon={<EditListIcon width={16} height={16} />}
                title="Renomear lista"
                onClick={() => setModalRenameListIsVisible(true)}
              />

              <DropDownItem
                icon={<TrashSVG fill={colors.black} width={16} height={16} />}
                title="Remover beneficiário"
                onClick={() => setStepSavedLists('removeBeneficiary')}
              />

              <DropDownItem
                icon={<DeleteIcon />}
                title="Apagar lista"
                onClick={() => setModalDeleteListIsVisible(true)}
                isRed
              />
            </>
          }
        >
          <RoundButtonIcon buttonSize="icon24" onClick={() => {}}>
            <DotsFloatingMenu />
          </RoundButtonIcon>
        </DropDown>
      </div>

      {selectedList?.data?.listOfBeneficiaries?.map((item, index) => {
        const key = `${item?.holderName || item?.fullName || ''}-${
          item?.keyType || item?.documentNumber || ''
        }-${index}`;
        return (
          <RowList key={key}>
            <div className="left align-row">
              <div className="align-row" style={{ gap: 18 }}>
                <UserPic
                  name={item?.fullName || item?.holderName || ''}
                  borderRadius={40}
                />

                <div className="align-column">
                  <Text.Heading5Bold>
                    {capitalizeInitialsAndRemoveNumbers(
                      item?.fullName || item?.holderName || ''
                    )}
                  </Text.Heading5Bold>
                  <Text.ParagraphBold color={colors.grayMedium}>
                    {item?.amount}
                  </Text.ParagraphBold>
                  <Text.ParagraphBold
                    color={colors.grayMedium}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {handleTranslatorKeyType(item?.transferType) ||
                      handleTranslatorKeyType(item?.keyType)}
                  </Text.ParagraphBold>
                </div>
              </div>
            </div>

            <div className="right">
              <RoundButtonIcon
                buttonSize="icon24"
                onClick={() => {
                  setSelectedBeneficiaryInList({
                    ...item,
                    indexInArray: index,
                  });
                  setStepSavedLists('editBeneficiary');
                }}
              >
                <EditListIcon fill={colors.black} width={18} height={18} />
              </RoundButtonIcon>
            </div>
          </RowList>
        );
      })}

      <ModalMultiStepBottom
        singleButtonWidth="236px"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => handleNext()}
        singleButtonText="Resumo da Transação"
      />
    </>
  );
};

export default EditList;
