import { colors, metrics } from '~styles';

import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div<{
  size: number;
  borderColor?: string;
  checked?: boolean;
}>`
  .shadow {
    width: ${({ size }) => size * 1.78 || 40}px;
    height: ${({ size }) => size * 1.78 || 40}px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    :hover {
      background: ${({ borderColor }) =>
        transparentize(0.95, borderColor || colors.black)};
    }
  }

  .circle {
    width: ${({ size }) => size || 22}px;
    height: ${({ size }) => size || 22}px;
    border: ${({ checked, size }) => (checked ? size / 3 : 1.5)}px solid
      ${({ borderColor }) => borderColor || colors.black};
    border-radius: 50%;

    :hover {
      cursor: pointer;
    }
  }
`;
