import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import EndStepStatus from '~components/EndStepStatus';
import * as Text from '~styles/text';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import * as Style from '../TransferBatch.styles';
import colors from '~styles/colors';
import CloseIconRounded from '~assets/svg/closeIcon';
import CheckIconRounded from '~assets/svg/checkRounded';
import { formatCurrencyFn } from '~helpers/format/currency';
import { Else, If, Then, When } from 'react-if';
import { LoadingSpinner } from '~components/index';
import { useState } from 'react';
import AlertModal from '~components/AlertModal/AlertModal.component';
import {
  ListOfBeneficiariesTransferProps,
  StatusPaymentProps,
} from '../TransferBatch.types';

const StatusPayment = (props: StatusPaymentProps) => {
  const [modalTransfersIsVisible, setModalTransfersIsVisible] = useState(false);
  const { params, setParams, setOpenModalMultiStep } =
    useActionsOnModalMultiStep();

  const listTransfers =
    params?.listTransfers as ListOfBeneficiariesTransferProps;

  const { nameList } = params;
  const { setStepTransferBatch } = props;

  const transferRealized = listTransfers?.filter(
    (item) => item?.status === 'completed'
  );

  const transferFailed = listTransfers?.filter(
    (item) => item?.status === 'failed'
  );

  const totalTransfersRealized = transferRealized?.reduce(
    (accumulator: number, item) => {
      const amount = parseFloat(
        item?.amount?.replace('R$ ', '').replace(',', '.')
      );
      return accumulator + amount;
    },
    0
  );

  const totalTransfersFailed = transferFailed?.reduce(
    (accumulator: number, item) => {
      const amount = parseFloat(
        item?.amount?.replace('R$ ', '').replace(',', '.')
      );
      return accumulator + amount;
    },
    0
  );

  const allTransfersFailed = transferFailed?.length === listTransfers?.length;

  const translatorColor = (status: string) => {
    if (status === 'completed') {
      return colors.primary;
    }
    if (status === 'failed') {
      return colors.danger;
    }

    return colors.black;
  };

  const translatorTransferStatus: { [key: string]: string } = {
    failed: 'Falha',
    processing: 'Processando...',
    completed: 'Concluído',
    queue: 'Na fila',
  };

  const handleTryAgain = () => {
    const recipientsList = {
      listOfBeneficiaries: transferFailed,
      nameList,
    };

    setParams({ ...params, recipientsList });
    setStepTransferBatch('confirmPaymentList');
  };

  return (
    <>
      <div
        className="transfer-batch-status-container"
        style={{ paddingTop: 0 }}
      >
        <div className="justify-center">
          <If condition={!transferFailed || !transferRealized}>
            <Then>
              <div className="container-loading">
                <LoadingSpinner
                  color={colors.primary}
                  size={30}
                  strokeSize={12.5}
                />
              </div>
            </Then>
            <Else>
              <If condition={allTransfersFailed}>
                <Then>
                  <EndStepStatus animType="error" />

                  <Text.Heading1Bold marginTop={24}>
                    {transferFailed?.length}/{listTransfers?.length}{' '}
                    transferências falharam!
                  </Text.Heading1Bold>

                  <div className="description-status-transfer">
                    <Text.Heading5
                      marginTop={16}
                      textAlign="center"
                      display="inline"
                    >
                      Houve um erro ao realizar a transferência de{' '}
                      {formatCurrencyFn('BRL', String(totalTransfersFailed))}{' '}
                      para{' '}
                      <span style={{ fontWeight: 600 }}>
                        {nameList || listTransfers[0]?.fullName}
                      </span>
                      . Por favor, tente novamente.
                    </Text.Heading5>
                  </div>
                </Then>
                <Else>
                  <EndStepStatus animType="success" />

                  <Text.Heading1Bold marginTop={24}>
                    {transferRealized?.length}/{listTransfers?.length}{' '}
                    transferências realizadas!
                  </Text.Heading1Bold>

                  <Text.Heading5
                    marginTop={16}
                    textAlign="center"
                    display="inline"
                  >
                    Você transferiu{' '}
                    {formatCurrencyFn('BRL', String(totalTransfersRealized))}{' '}
                    para a lista{nameList ? ' ' : ''}
                    <span style={{ fontWeight: 600 }}>{nameList}</span>.
                  </Text.Heading5>
                  <Text.Heading5 textAlign="center">
                    Visualize o comprovante na tela de movimentações.
                  </Text.Heading5>
                </Else>
              </If>

              <If condition={transferFailed?.length > 0}>
                <Then>
                  <Style.TransferFailed>
                    <div className="left">
                      <CloseIconRounded />
                      <Text.Heading5Bold marginLeft={8} marginRight={8}>
                        Falhas
                      </Text.Heading5Bold>
                      <div className="number-of-failures">
                        <Text.Heading5Bold color={colors.grayMedium}>
                          {transferFailed?.length}
                        </Text.Heading5Bold>
                      </div>
                    </div>
                    <div className="right">
                      <Text.Heading5Bold
                        color={colors.primary}
                        onClick={handleTryAgain}
                      >
                        Tentar novamente
                      </Text.Heading5Bold>
                    </div>
                  </Style.TransferFailed>

                  <Style.TransferQueue style={{ marginTop: 4 }}>
                    {transferFailed?.map((item) => (
                      <div
                        className="item-transfer"
                        key={`${item.fullName}-${item.keyType}`}
                      >
                        <div className="left">
                          <Text.Heading5Bold
                            style={{ textDecorationLine: 'line-through' }}
                          >
                            {item?.fullName || item?.holderName}
                          </Text.Heading5Bold>
                          <Text.Small color={colors.redMatte}>
                            Erro durante a transação
                          </Text.Small>
                        </div>
                        <div className="right">
                          <Text.Heading5Bold
                            color={colors.grayMedium}
                            style={{ textDecorationLine: 'line-through' }}
                          >
                            {item?.amount}
                          </Text.Heading5Bold>
                        </div>
                      </div>
                    ))}
                  </Style.TransferQueue>
                </Then>
              </If>
            </Else>
          </If>
        </div>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Concluir"
        singleButtonWidth="121px"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => setOpenModalMultiStep(false)}
        doubleButtonTextOne={
          <div className="button-row-transfer-batch">
            <CheckIconRounded fill={colors.black} />
            <Text.Heading5>Ver confirmadas</Text.Heading5>
          </div>
        }
        doubleButtonOneAction={() => setModalTransfersIsVisible(true)}
        disableDoubleButtonOneAction={allTransfersFailed}
      />

      <When condition={modalTransfersIsVisible}>
        <AlertModal
          setOpenAlert={setModalTransfersIsVisible}
          title="Lista de confirmadas"
          secondaryButtonWidth="153px"
          noBorderTop
          paddingVertical={0}
          paddingBottomTitle="0"
          secondaryButtonColor="secondary"
          secondaryButtonText="Entendi"
          primaryButtonText="Fechar"
          bodyComponent={
            <Style.TransferRealized style={{ marginTop: 4 }}>
              {transferRealized?.map((item) => (
                <div
                  className="item-transfer"
                  key={`${item.fullName}-${item.keyType}`}
                >
                  <div className="left">
                    <Text.Heading5Bold>
                      {item?.fullName || item?.holderName || item?.keyValue}
                    </Text.Heading5Bold>
                    <Text.Small color={translatorColor(item?.status as string)}>
                      {
                        translatorTransferStatus[
                          item?.status ? item?.status : 'queue'
                        ]
                      }
                    </Text.Small>
                  </div>
                  <div className="right">
                    <Text.Heading5Bold color={colors.grayMedium}>
                      {item?.amount}
                    </Text.Heading5Bold>
                  </div>
                </div>
              ))}
            </Style.TransferRealized>
          }
          primaryButtonAction={() => setModalTransfersIsVisible(false)}
          secondaryButtonAction={() => setModalTransfersIsVisible(false)}
        />
      </When>
    </>
  );
};

export default StatusPayment;
