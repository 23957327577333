import { GenericRow, InputForm, RoundButtonIcon } from '~components';

import CalendarSVG from '~assets/svg/calendar';
import CapAccountSVG from '~assets/svg/capAccount';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { PaymentDetailsPageProps } from '../PayWithPix.types';
import { WalletsList } from '~data/wallets';
import { formatCurrencyFn } from '~helpers/format/currency';
import PlusSVG from '~assets/svg/plus';
import { When } from 'react-if';
import { useState } from 'react';

function SelectWallet(props: PaymentDetailsPageProps) {
  const { setCurrentStep, billData, selectedOption, qrCode, editedValue } =
    props;

  const [comment, setComment] = useState({
    open: false,
    text: '',
  });

  const getWalletIcon = (walletName: string) => {
    const icon = WalletsList.find(
      (wallet) => wallet?.name === walletName
    )?.icon;

    return <span className="wallet-icon">{icon || <CapAccountSVG />}</span>;
  };

  const billDataValue = billData.data.key.payment.totalValue;

  const labelAndConfigs = {
    description: (
      <p>
        Transferindo{' '}
        {formatCurrencyFn(
          'BRL',
          billDataValue <= 0 ? editedValue : String(billDataValue / 100),
          false,
          true
        )}{' '}
        para {billData.data.key.holderName}
      </p>
    ),
    content: [
      {
        overText: 'Origem',
        title: selectedOption.walletName,
        rightComponent: (
          <RoundButtonIcon buttonType="outlined" buttonSize="iconWallet">
            {getWalletIcon(selectedOption.walletName)}
          </RoundButtonIcon>
        ),
        rightContentType: 'component',
      },
      {
        overText: 'CPF',
        title: `•••.${billData.data.key.holderDocument.substring(
          3,
          6
        )}.${billData.data.key.holderDocument.substring(6, 9)}-••`,
      },
      {
        overText: 'Data do Pagamento',
        title: 'Agora',
        rightComponent: (
          <RoundButtonIcon
            buttonType="default"
            onClick={() => {}}
            buttonSize="icon24"
          >
            <CalendarSVG />
          </RoundButtonIcon>
        ),
        rightContentType: 'component',
      },
      { overText: 'Instituição', title: billData.data.key.bankName },
      {
        overText: 'Comentário',
        title: comment.text === '' ? 'Nenhuma nota' : comment.text,
        rightComponent: (
          <RoundButtonIcon
            buttonType="default"
            onClick={() =>
              setComment((prev) => ({ ...prev, open: !prev.open }))
            }
            buttonSize="icon24"
          >
            <PlusSVG />
          </RoundButtonIcon>
        ),
        rightContentType: 'component',
      },
    ],
  };

  const goNext = () => {
    setCurrentStep('confirmCredentials', {
      qrCode,
      editedValue,
      description: comment.text,
    });
  };

  const handleBackButton = () => {
    setCurrentStep('selectWallet');
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Detalhes do pagamento"
        description={labelAndConfigs.description}
        backButtonAction={handleBackButton}
      />

      <div className="content-container" style={{ marginTop: '30px' }}>
        {labelAndConfigs.content.map((content) => (
          <GenericRow key={content.overText} {...content} noHorizontalPadding />
        ))}

        <When condition={comment.open}>
          <div className="pl-10 pr-10">
            <InputForm
              marginBottom={10}
              defaultValue={comment.text}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={(e) =>
                setComment((prev) => ({ ...prev, text: e.target.value }))
              }
            />
          </div>
        </When>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
      />
    </>
  );
}

export default SelectWallet;
