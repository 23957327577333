import { colors } from '~styles';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const ConvertContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .convert-body {
    width: 100%;
    margin-top: 25px;

    .freq-wallet-pair-container {
      width: 100%;
      display: flex;
      padding: 0 0 20px 0;
    }

    .pair-container {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      padding: 6px 17px;
      border-radius: 32px;
      border: 1px solid ${colors.mainGray};
    }

    .conversion-area-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 38px 20px 38px;

      .originWallet-container {
        width: 100%;
        display: flex;

        border-top: 1px solid rgba(0, 0, 0, 0.05);
      }
    }

    .justify-items-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .double-button-one-container {
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
  }

  .body-container-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 20px;
    border: 2px solid ${colors.gray};
    border-radius: 15px;
  }
`;

export const ConversionAreaContainer = styled.div`
  padding: 0 38px;

  .select-wallet-container {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .scale-icon {
    scale: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .select-button {
    cursor: pointer;
  }

  .convert-row-origin {
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: 25px 0;
    justify-content: space-between;

    .input-container {
      width: 60%;
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.05);
    position: relative;

    .arrow-icon {
      width: 45px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .convert-row-destination {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .input-container {
      width: fit-content;
    }

    .input-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
  }
`;

export const ResumeContainer = styled.div`
  width: 100%;
  padding: 0 38px;

  .content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  .top-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .bottom-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const OriginWalletList = styled.div`
  width: 100%;
  padding: 0 38px;

  .origin-wallet-wrapper {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const DetailsContentWrapper = styled.div`
  width: 100%;
  padding: 0 38px;
  display: flex;
  flex-direction: column;

  .wallets-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 0 0 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    .arrow-right-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .scale-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      scale: 0.8;
    }

    .origin-wallet-content {
      display: flex;
      flex-direction: column;
    }

    .destination-wallet-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .warning-message {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
    padding: 10px;
    background-color: ${transparentize(0.85, colors.grayLight)};
    border-radius: 10px;
    margin-top: 20px;

    p {
      width: 94%;
    }
  }

  .details-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const PinContentWrapper = styled.div`
  width: 100%;
  padding: 0 38px;
  display: flex;
  flex-direction: column;

  .container-loading {
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StatusContentWrapper = styled.div`
  width: 100%;
  padding: 0 38px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .receipt-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 25px;
  }
`;
