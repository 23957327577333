import * as Text from '~styles/text';
import { Else, If, Then, When } from 'react-if';
import { formatCurrencyFn, getWalletIcon } from '~helpers/format/currency';
import { useEffect, useMemo, useState } from 'react';
import BankAccountIcon from '~assets/images/icons/24x24/capconta.svg';
import { BillReceipt } from '~assets/pdfs/bill/BillReceipt';
import BillSVG from '~assets/svg/statement/bill';
import { ConvertBankingReceipt } from '~assets/pdfs/convertBanking/ConvertBankingReceipt';
import { InvoiceReceipt } from '~assets/pdfs/invoice/InvoiceReceipt';
import RecipeSVG from '~assets/svg/recipe';
import { RoundButtonIcon } from '..';
import { TransferReceipt } from '~assets/pdfs/transfer/TransferReceipt';
import UserPic from '~components/UserPic';
import { WalletItemProps } from './Statement.types';
import { capitalizeName } from '~helpers/format/user';
import colors from '~styles/colors';
import dayjs from 'dayjs';
import { formatCpfOrCnpj } from '~helpers/format/numbers';
import { getSelectedUserOrganization } from '~helpers/device';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetStatementSpecific } from '~reactQuery/queries/bank/useGetStatementSpecific';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';
import { RefundReceipt } from '~assets/pdfs/refund/RefundReceipt';
import {
  receiptTitleParser,
  receiptTranslatorIconColor,
} from '~helpers/transaction/transactionUtils';
import BillIcon from '~assets/svg/statement/billIcon';
import TransferSendIcon from '~assets/svg/statement/transferSend';
import TransferReceivedIcon from '~assets/svg/statement/transferReceived';
import { ConvertIcon } from '~assets/svg/statement/convertIcon';
import BlockedIcon from '~assets/svg/statement/blocked';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import InterrogationIcon from '~assets/svg/statement/interrogation';
import { BillSlipReceipt } from '~assets/pdfs/bill/BillSlipReceipt';
import { useLocation } from 'react-router-dom';

function StatementItem({ tx, hideCpfCnpj, wallets }: WalletItemProps) {
  const userOrganizationId = getSelectedUserOrganization();
  const { setOpenModalSideBar, setModalSidebarContent, setParams } =
    useOpenModalSideBar();
  const location = useLocation();

  const [authCode, setAuthCode] = useState('');
  const [specificAuthCode, setSpecificAuthCode] = useState('');

  const getWalletFiltered = (walletAddress: string) => {
    if (walletAddress && wallets) {
      return wallets.filter((w) => w.walletAddress === walletAddress)?.[0];
    }
    return null;
  };

  const { data: transferSpecificDetails, isLoading: loadingDetails } =
    useGetStatementSpecific({
      authCode,
      userOrganizationId: Number(userOrganizationId),
    });

  const { data: accountDetails, isLoading: loadingGetAccount } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  const translatorIcon = (type: string, status: string, direction: number) => {
    const translator: any = {
      TRANSFER: (
        <ConvertIcon fill={receiptTranslatorIconColor(status, direction)} />
      ),
      EXCHANGE_CAPCONTA_TO_CAPWALLET: (
        <ConvertIcon fill={receiptTranslatorIconColor(status, direction)} />
      ),
      EXCHANGE_CAPWALLET_TO_CAPCONTA: (
        <ConvertIcon fill={receiptTranslatorIconColor(status, direction)} />
      ),
      PIX:
        direction < 1 ? (
          <TransferSendIcon
            stroke={receiptTranslatorIconColor(status, direction)}
          />
        ) : (
          <TransferReceivedIcon
            stroke={receiptTranslatorIconColor(status, direction)}
          />
        ),
      TED:
        direction < 1 ? (
          <TransferSendIcon
            stroke={receiptTranslatorIconColor(status, direction)}
          />
        ) : (
          <TransferReceivedIcon
            stroke={receiptTranslatorIconColor(status, direction)}
          />
        ),
      BILL: <BillIcon stroke={receiptTranslatorIconColor(status, direction)} />,
      BILLSLIP:
        direction < 1 ? (
          <BillIcon stroke={receiptTranslatorIconColor(status, direction)} />
        ) : (
          <TransferReceivedIcon
            stroke={receiptTranslatorIconColor(status, direction)}
          />
        ),
      REFUND: (
        <TransferReceivedIcon
          stroke={receiptTranslatorIconColor(status, direction)}
        />
      ),
    };

    if (
      status === 'CANCELED' ||
      status === 'CANCELLED' ||
      status === 'REPROVED' ||
      status === 'UNDONE'
    ) {
      return (
        <BlockedIcon stroke={receiptTranslatorIconColor(status, direction)} />
      );
    }

    return translator[type.toUpperCase()];
  };

  const parsedTX = useMemo(() => {
    if (tx) {
      let statementListParsed = {} as any;
      let show = true;

      let isCapContaToWallet = false;
      let isCapWalletToCapConta = false;

      switch (tx.method) {
        case 'Transfer':
          isCapContaToWallet =
            tx?.data?.requisite?.type === 'EXCHANGE_CAPCONTA_TO_CAPWALLET';
          isCapWalletToCapConta =
            tx?.data?.requisite?.type === 'EXCHANGE_CAPWALLET_TO_CAPCONTA';

          if (isCapContaToWallet) {
            const title = receiptTitleParser(tx.status, 'CAP-TO-WALLET', 1);

            statementListParsed = {
              title,
              icon: translatorIcon(tx?.data?.requisite?.type, tx.status, 1),
              from: {
                name: 'Cap Conta',
              },
              to: {
                walletIsoCode: getWalletFiltered(
                  tx.data.requisite.walletAddress
                )?.currency?.isoCode,
                name: getWalletFiltered(tx.data.requisite.walletAddress)
                  ?.walletName,
              },
            };
          } else if (isCapWalletToCapConta) {
            const title = receiptTitleParser(tx.status, 'WALLET-TO-CAP', 1);
            statementListParsed = {
              title,
              icon: translatorIcon(tx?.data?.requisite?.type, tx.status, 1),
              from: {
                walletIsoCode: getWalletFiltered(
                  tx.data.requisite.walletAddress
                )?.currency?.isoCode,
                name: getWalletFiltered(tx.data.requisite.walletAddress)
                  ?.walletName,
              },
              to: {
                name: 'Cap Conta',
              },
            };
          } else {
            const title = receiptTitleParser(
              tx.status,
              String(tx.data.method).toUpperCase() === 'PIX'
                ? 'TRANSFER-PIX'
                : 'TRANSFER-TED',
              tx.direction
            );

            statementListParsed = {
              title,
              icon: translatorIcon(tx.data.method, tx.status, tx.direction),
              from: {
                name: 'Cap Conta',
                docNum: '',
              },
              to: {
                name:
                  tx.direction > 1
                    ? 'Cap Conta'
                    : tx.data?.requisite?.key?.holderName ||
                      tx.data?.requisite?.holderName,
                docNum:
                  tx.direction > 1
                    ? ''
                    : formatCpfOrCnpj(
                        tx.data?.requisite?.key?.holderDocument ||
                          tx.data?.requisite?.holderDocument
                      ),
              },
            };
          }

          break;
        case 'Bill': {
          const title = receiptTitleParser(tx.status, 'BILL', -1);

          statementListParsed = {
            title,
            icon: translatorIcon(tx.method, tx.status, tx.direction),
            to: {
              name: tx?.data?.assignor,
              docNum: tx?.data?.recipientName,
            },
          };
          break;
        }

        case 'BillSlip': {
          const title = receiptTitleParser(tx.status, 'BILL-SLIP', -1);

          if (tx.direction === 0) {
            show = false;
          }
          statementListParsed = {
            title,
            icon: translatorIcon(tx.method, tx.status, tx.direction),
            from: {
              name: 'Cap Conta',
              docNum: '',
            },
            to: {
              name: `${tx?.data?.bankBranch} - ${tx?.data?.bankAccount}`,
              docNum: formatCpfOrCnpj(tx?.data?.documentNumber),
            },
          };
          break;
        }
        case 'Refund': {
          const title = receiptTitleParser(tx.status, 'REFUND', -1);

          statementListParsed = {
            title,
            icon: translatorIcon(tx.method, tx.status, tx.direction),
            to: {
              name: 'Cap Conta',
            },
          };
          break;
        }

        default:
          if (tx?.data?.method === 'Invoice') {
            statementListParsed = {
              title: 'Pagamento de Invoice',
              icon: (
                <BillSVG
                  stroke={receiptTranslatorIconColor(tx.status, tx.direction)}
                />
              ),
              from: {
                name: 'Cap Conta',
                docNum: '',
              },
            };
          }

          break;
      }

      return { ...statementListParsed, show };
    }
    return [];
  }, [tx, wallets]);

  const loading = !tx;

  const downloadSingle = () => {
    setAuthCode(tx?.authCode || '');
  };

  const generatePdfDocument = async () => {
    let blob = null;
    let fileName = '';
    let fileDate = '';

    if (!transferSpecificDetails) return;

    switch (transferSpecificDetails?.method) {
      case 'BillSlip':
        blob = await pdf(
          <BillSlipReceipt
            accountDetails={accountDetails}
            billDetails={transferSpecificDetails}
            authCode={tx?.authCode}
            value={tx?.data?.value}
          />
        ).toBlob();
        fileDate = dayjs(transferSpecificDetails?.timestamp).format(
          'DD/MM/YYYY h:mm A'
        );
        fileName = 'Boleto';
        break;

      case 'Bill':
        blob = await pdf(
          <BillReceipt
            accountDetails={accountDetails}
            billDetails={transferSpecificDetails}
            authCode={tx?.authCode}
          />
        ).toBlob();
        fileDate = dayjs(transferSpecificDetails?.timestamp).format(
          'DD/MM/YYYY h:mm A'
        );
        fileName = 'Boleto';
        break;

      case 'Refund':
        blob = await pdf(
          <RefundReceipt
            accountDetails={accountDetails}
            refundDetails={transferSpecificDetails}
            authCode={tx?.authCode}
          />
        ).toBlob();
        fileName = 'Reembolso';
        break;
      case 'Transfer':
        if (parsedTX.title === 'Conversão recebida') {
          blob = await pdf(
            <ConvertBankingReceipt
              accountDetails={accountDetails}
              convertDetails={{
                timestamp: tx?.timestamp,
                authCode: tx?.authCode,
                amount: tx?.data.value,
                originWallet: 'Cap Wallet',
                destinationWallet: 'Cap Conta',
                status: tx?.status,
              }}
            />
          ).toBlob();
          fileDate = dayjs(transferSpecificDetails?.timestamp).format(
            'DD/MM/YYYY h:mm A'
          );
          fileName = 'Conversão';
        } else if (parsedTX.title === 'Conversão enviada') {
          blob = await pdf(
            <ConvertBankingReceipt
              accountDetails={accountDetails}
              convertDetails={{
                status: tx?.status,
                timestamp: tx?.timestamp,
                authCode: tx?.authCode,
                amount: tx?.data.value,
                originWallet: 'Cap Conta',
                destinationWallet: `${
                  getWalletFiltered(tx?.data?.requisite?.walletAddress)
                    ?.walletName
                } - ${tx?.data?.requisite?.walletAddress}`,
              }}
            />
          ).toBlob();
          fileDate = dayjs(transferSpecificDetails?.timestamp).format(
            'DD/MM/YYYY h:mm A'
          );
          fileName = 'Conversão';
        } else {
          blob = await pdf(
            <TransferReceipt
              accountDetails={accountDetails}
              transferDetails={transferSpecificDetails}
              authCode={tx?.authCode}
              status={tx?.status}
            />
          ).toBlob();

          fileName = 'Transferência';
          fileDate = dayjs(transferSpecificDetails?.timestamp).format(
            'DD/MM/YYYY h:mm A'
          );
        }

        break;
      default:
        if (transferSpecificDetails?.method === 'Invoice') {
          blob = await pdf(
            <InvoiceReceipt
              accountDetails={accountDetails}
              invoiceDetails={transferSpecificDetails}
              authCode={tx?.authCode}
              status={tx?.status}
            />
          ).toBlob();
        }

        fileDate = dayjs(transferSpecificDetails?.timestamp).format(
          'DD/MM/YYYY h:mm A'
        );
        fileName = 'Invoice';

        break;
    }

    if (!blob) return;

    saveAs(blob, `${fileName}-${fileDate}.pdf`);
    setAuthCode('');
  };

  useEffect(() => {
    if (transferSpecificDetails?.authCode === authCode && !loadingGetAccount) {
      generatePdfDocument();
    }
  }, [transferSpecificDetails, loadingGetAccount]);

  useEffect(() => {
    const authCodeFromUrl = location.search.split('?authcode=')[1];

    setSpecificAuthCode(authCodeFromUrl);
  }, []);

  const handleOpenDetailsModal = () => {
    setModalSidebarContent('detailsActivityModal');
    setOpenModalSideBar(true);
    setParams({ transactionDetails: tx, downloadSingle });
  };

  useEffect(() => {
    if (specificAuthCode === tx?.authCode) {
      handleOpenDetailsModal();
    }
  }, [specificAuthCode, tx]);

  return (
    <If condition={loading}>
      <Then>{null}</Then>
      <Else>
        {parsedTX?.show && (
          <tr
            className="row-statement"
            onClick={() => handleOpenDetailsModal()}
          >
            <td>
              <div className="flex items-center gap-10">
                <div className="txIcon">{parsedTX?.icon}</div>
                <div>
                  <Text.Heading5>{parsedTX?.title}</Text.Heading5>
                </div>
              </div>
            </td>
            <td className="hide">
              <div className="flex row gap-10" style={{ minWidth: 110 }}>
                <BankAccountIcon />
                <div
                  style={{
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                  }}
                >
                  <Text.Heading5>Cap Conta</Text.Heading5>
                </div>
              </div>
            </td>
            <td className="hide">
              <div className="flex row gap-10">
                <If condition={parsedTX?.to?.name === 'Cap Conta'}>
                  <Then>
                    <div
                      className={
                        !parsedTX?.from?.walletIsoCode ? 'cap-account-svg' : ''
                      }
                    >
                      {parsedTX?.from?.walletIsoCode ? (
                        getWalletIcon(parsedTX?.from?.walletIsoCode)
                      ) : (
                        <div className="not-found-container">
                          <div className="circle">
                            <InterrogationIcon />
                          </div>
                        </div>
                      )}
                    </div>
                  </Then>

                  <Else>
                    <If condition={parsedTX?.to?.walletIsoCode}>
                      <Then>{getWalletIcon(parsedTX?.to?.walletIsoCode)}</Then>

                      <Else>
                        <If condition={parsedTX?.to?.name}>
                          <Then>
                            <UserPic name={parsedTX?.to?.name} />
                          </Then>

                          <Else>
                            {parsedTX?.to?.name ? (
                              <UserPic name="W" />
                            ) : (
                              <div className="not-found-container">
                                <div className="circle">
                                  <InterrogationIcon />
                                </div>
                              </div>
                            )}
                          </Else>
                        </If>
                      </Else>
                    </If>
                  </Else>
                </If>

                <div
                  style={{
                    maxWidth: '100%',
                    width: 'auto',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                  }}
                >
                  <Text.Heading5>
                    <When
                      condition={
                        parsedTX?.to?.name && parsedTX?.to?.name !== 'Cap Conta'
                      }
                    >
                      {capitalizeName(String(parsedTX?.to?.name).toLowerCase())}
                    </When>

                    <If
                      condition={
                        parsedTX?.to?.name &&
                        parsedTX?.to?.name === 'Cap Conta' &&
                        parsedTX?.from?.name
                      }
                    >
                      <Then>
                        {capitalizeName(
                          String(
                            parsedTX?.from?.name || parsedTX?.to?.name
                          ).toLowerCase()
                        )}
                      </Then>

                      <Else>
                        <If
                          condition={
                            parsedTX?.from?.docNum || parsedTX?.to?.name
                          }
                        >
                          <Then />
                          <Else>
                            <Text.Heading5 color={colors.grayRegular}>
                              Não encontrado
                            </Text.Heading5>
                          </Else>
                        </If>
                      </Else>
                    </If>
                  </Text.Heading5>

                  <When
                    condition={
                      parsedTX?.to?.name &&
                      parsedTX?.to?.name === 'Cap Conta' &&
                      !parsedTX?.from?.name
                    }
                  >
                    <Text.Heading5 color={colors.grayRegular}>
                      Não encontrado
                    </Text.Heading5>
                  </When>

                  <If condition={parsedTX?.to?.walletIsoCode}>
                    <Then>{null}</Then>
                    <Else>
                      <If
                        condition={
                          hideCpfCnpj &&
                          parsedTX?.to?.name &&
                          parsedTX?.to?.name !== 'Cap Conta'
                        }
                      >
                        <Then>
                          <div className="hide-doc" />
                        </Then>
                        <Else>
                          <Text.Heading5 color={colors.grayMedium}>
                            {parsedTX?.to?.docNum}
                          </Text.Heading5>
                        </Else>
                      </If>
                    </Else>
                  </If>
                </div>
              </div>
            </td>
            <td className="hide">
              <Text.Heading5 data-html2canvas-ignore="true">
                {tx?.timestamp
                  ? dayjs(tx?.timestamp).format('DD/MM/YYYY')
                  : '--/--/--'}
              </Text.Heading5>

              <Text.Heading5
                data-html2canvas-ignore="true"
                color={colors.grayMedium}
              >
                {tx?.timestamp ? dayjs(tx?.timestamp).format('h:mm A') : ''}
              </Text.Heading5>
            </td>
            <td className="mobile-hide" style={{ minWidth: 110 }}>
              <div>
                <Text.Heading5>
                  {tx?.direction && tx?.direction < 1 ? '- ' : '+ '}
                  {tx?.data?.value &&
                    formatCurrencyFn(
                      'BRL',
                      String(Number((tx?.data?.value as any) / 100))
                    )}
                </Text.Heading5>
              </div>
            </td>

            <td>
              <RoundButtonIcon
                buttonSize="icon24"
                onClick={(event: any) => {
                  event.stopPropagation();
                  downloadSingle();
                }}
              >
                <RecipeSVG stroke={colors.black} />
              </RoundButtonIcon>
            </td>
          </tr>
        )}
      </Else>
    </If>
  );
}

export default StatementItem;
