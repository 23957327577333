import * as Style from './RecoveryPin.styles';

import { SetCurrentStepProps, StepParamsProps } from './RecoveryPin.types';
import { useEffect, useState } from 'react';

import AskCpf from './ModalSteps/1.AskCpf';
import EditPin from './ModalSteps/2.EditPin';
import ModalMultiStep from '~components/ModalMultiStep';
import Status from './ModalSteps/3.Status';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

function RecoveryPin() {
  const { openModalMultiStep, setOpenModalMultiStep } =
    useActionsOnModalMultiStep();
  const { params } = useActionsOnModalMultiStep();
  const [currentStep, setCurrentStep] = useState<SetCurrentStepProps>('askCpf');
  const [stepParams, setStepParams] = useState<StepParamsProps>({});

  function setStepsAndParams(step: SetCurrentStepProps, paramsFn?: object) {
    if (paramsFn) {
      setStepParams((prev) => ({ prev, ...paramsFn } as StepParamsProps));
    }
    setCurrentStep(step);
  }

  const modalStepsAndDetails = {
    askCpf: {
      component: (
        <AskCpf
          orgId={params.organizationsId}
          userOrganizationsId={params?.userOrganizationsId}
          orgName={params.orgName}
          key="askCpf"
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 33,
    },
    editPin: {
      component: (
        <EditPin
          orgId={params.organizationsId}
          orgName={params.orgName}
          userOrganizationsId={params?.userOrganizationsId}
          stepParams={stepParams}
          key="EditPin"
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 66,
    },
    status: {
      component: (
        <Status
          orgName={params.orgName}
          stepParams={stepParams}
          key="Status"
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 100,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  // CLEAR STEP ON MODAL CLOSE
  useEffect(() => {
    if (!openModalMultiStep) setCurrentStep('askCpf');
  }, [openModalMultiStep]);

  return (
    <Style.RecoveryPinContainer>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={currentStepInfos.progressValue}
      >
        {currentStepInfos.component}
      </ModalMultiStep>
    </Style.RecoveryPinContainer>
  );
}

export default RecoveryPin;
