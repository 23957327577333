import * as React from 'react';

import { SVGProps } from 'react';

const DshCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="M25.265 10h-7l-.581 3.252h6.337c3.11 0 4.029 1.132 4.001 3.012a13.387 13.387 0 0 1-.611 3.119c-.502 1.434-1.484 3.022-5.174 3.022h-6.14l-.574 3.25h6.98a9.688 9.688 0 0 0 4.62-.798 8.626 8.626 0 0 0 3.096-2.812 8.657 8.657 0 0 0 1.414-3.941c.872-4.743-.218-8.104-6.367-8.104Z"
      fill="#0F90FF"
    />
    <path
      d="M15.247 16.186a2.046 2.046 0 0 0-1.504.443 2.057 2.057 0 0 0-.752 1.38c0 .032-.007.066-.01.098-.229.947-.305 1.335-.305 1.335h7.162c.541.059 1.083-.1 1.507-.443a2.06 2.06 0 0 0 .754-1.381c0-.033.006-.066.008-.098.23-.949.306-1.334.306-1.334h-7.166Z"
      fill="#0F90FF"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DshCoinSVG;
