const TransferSendIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { fill = 'none', stroke = '#272937', height = 20, width = 18 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 18 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18.002V19.002H8V18.002V16.002V15.002H10V16.002V18.002ZM6 17.002V18.002H4V17.002V16.002V15.002H6V16.002V17.002ZM14 17.002V18.002H12V17.002V16.002V15.002H14V16.002V17.002ZM1 12.502C0.447715 12.502 0 12.0542 0 11.502V1.00195C0 0.449669 0.447715 0.00195312 1 0.00195312H17C17.5523 0.00195312 18 0.449669 18 1.00195V11.502C18 12.0542 17.5523 12.502 17 12.502H1ZM2 2.00195V10.502H16V2.00195H2ZM9 4.00195C7.75736 4.00195 6.75 5.00931 6.75 6.25195C6.75 7.49459 7.75736 8.50195 9 8.50195C10.2426 8.50195 11.25 7.49459 11.25 6.25195C11.25 5.00931 10.2426 4.00195 9 4.00195Z"
        fill={stroke}
      />
    </svg>
  );
};

export default TransferSendIcon;
