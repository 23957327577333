import * as React from 'react';

import { SVGProps } from 'react';

const PrintSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25 3A.75.75 0 0 1 5 2.25h7a.75.75 0 0 1 .75.75v3.25H14a.75.75 0 0 1 .75.75v4A2.75 2.75 0 0 1 12 13.75H5A2.75 2.75 0 0 1 2.25 11V7A.75.75 0 0 1 3 6.25h1.25V3Zm7 .75v2.5h-5.5v-2.5h5.5Zm-7.5 4h9.5V11c0 .69-.56 1.25-1.25 1.25H5c-.69 0-1.25-.56-1.25-1.25V7.75Zm2.75 2.5a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4Z"
      fill="#272937"
    />
  </svg>
);

export default PrintSVG;
