import { colors, metrics } from '~styles';
import { transparentize } from 'polished';
import { GeneralStyleProps } from 'src/@types/general';
import styled, { css } from 'styled-components';
import { OptionSelectContainerProps } from './OptionSelect.types';

export const Container = styled.div<OptionSelectContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: ${(props) => props.paddingVertical || 6.5}px 5px
    ${(props) => props.paddingVertical || 6.5}px 5px;
  transition: all ease-out 0.3s;
  margin-top: ${(props) => props.marginTop || 0}px;
  width: 100%;
  height: ${(props) => props.height || 'auto'};

  ${(props) =>
    props.enabledBorder &&
    css`
      background-color: ${colors.gray};
      padding: 18px 15px 22px 15px;
      border: 1px solid #9ea1b9;
    `}

  .right-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition: all ease-out 0.3s;

    width: 80%;
  }
  border-radius: 10px;
  ${(props) =>
    props.borderTop &&
    css`
      border-top: 1px solid ${transparentize(0.7, colors.grayLight)};
      border-radius: 0;
    `}

  ${(props) =>
    props.enableAnimation &&
    css`
      cursor: pointer;
      :hover {
        transition: all ease-out 0.3s;

        .right-container {
          margin-top: -3px;
          transition: all ease-out 0.3s;
        }
      }
    `}
`;

export const IconContainer = styled.div<GeneralStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 15px;
  position: relative;
`;

export const ContainerStatus = styled.div<GeneralStyleProps>`
  position: absolute;
  bottom: -12px;
  right: -8px;
`;

export const TrashAndEditContainer = styled.div<GeneralStyleProps>`
  flex-direction: row;
  display: flex;
  align-items: center;
  height: 40px;
  position: relative;

  .tooltip-text {
    width: 75%;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    display: flex;
    background-color: ${transparentize(0.15, colors.black)};
    border-radius: 10px;
    padding: 4px 8px;
    position: absolute;
    z-index: 999;
    opacity: 1;
    bottom: -15px;
  }

  .on-select {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 40px;
    height: 40px;
    transition: all ease-out 0.3s;
    border-radius: 10px;
    margin-right: 5px;

    :hover {
      .tooltip-text {
        visibility: visible;
      }
      margin-top: -3px;
      transition: all ease-out 0.3s;
      cursor: pointer;
      background-color: ${transparentize(0.7, colors.grayLight)};
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 40px;
      height: 40px;
    }
  }
`;
