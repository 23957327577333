import * as React from 'react';

import { SVGProps } from 'react';

const DaiCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="M27.563 23.222c.55-.74.959-1.582 1.205-2.482v-.299H31v-1.993h-1.946v-2.079H31v-1.993h-2.232v-.1c-.092-.396-.184-.694-.277-.996a6.745 6.745 0 0 0-2.032-2.866 5.773 5.773 0 0 0-2.595-1.191c-1.041-.19-2.1-.26-3.156-.205h-6.02v5.465H12v1.994h2.687v2.18H12v1.994h2.687v5.448h6.3c.963.01 1.925-.09 2.868-.296a6.999 6.999 0 0 0 2.132-.895 8.74 8.74 0 0 0 1.578-1.687l-.002.001ZM16.818 10.81h3.89a7.966 7.966 0 0 1 3.058.397A4.506 4.506 0 0 1 26.08 13.2c.206.352.392.717.557 1.091l.092.2h-9.912v-3.682Zm0 5.56h10.19v2.085h-10.19v-2.085Zm8.431 6.554a3.435 3.435 0 0 1-1.667.894c-.91.229-1.845.329-2.78.297h-3.984V20.44h9.82l-.093.2a5.32 5.32 0 0 1-1.297 2.283h.001Z"
      fill="#F4B731"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DaiCoinSVG;
