import * as React from 'react';
import { SVGProps } from 'react';

const WalletBchSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.065 15.26a2.684 2.684 0 0 0 .381-3.057c-.76-1.561-2.909-1.53-4.482-1.153l-.82-2.457-1.51.502.81 2.43-1.011.336-.813-2.43-1.511.502.814 2.431-3.041 1.008.526 1.572 1.132-.376c.507-.168.805-.1.943.311l2.244 6.736c.188.562-.034.827-.337.927l-1.154.384.534 1.6 2.936-.975.83 2.49 1.52-.507-.83-2.489 1.013-.337.83 2.49 1.478-.493-.831-2.488.323-.107c3.106-1.03 3.633-2.9 2.939-4.978a2.849 2.849 0 0 0-2.911-1.878l-.002.005ZM12.99 13.53l1.08-.358c.753-.251 2.29-.62 2.693.618a1.609 1.609 0 0 1-.79 2.052l-1.994.663-.989-2.975Zm4.267 7.225-.006-.01-1.667.554-1.081-3.243 1.936-.65c.454-.15 2.208-.564 2.612.646.404 1.21-.171 2.167-1.784 2.7l-.01.003Z"
      fill="#8DC351"
    />
  </svg>
);

export default WalletBchSVG;
