import * as React from 'react';
import { SVGProps } from 'react';

const BlueShieldSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={45}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.09 7.088a1 1 0 0 1 .82 0l13.964 6.276a1 1 0 0 1 .588.845l-.998.067.998-.066v.006l.001.015.003.052.01.194c.007.168.015.41.02.716.01.613.006 1.485-.048 2.529-.108 2.082-.416 4.88-1.227 7.693-.808 2.806-2.137 5.697-4.33 7.897C29.67 35.538 26.616 37 22.5 37c-4.116 0-7.17-1.462-9.39-3.688-2.194-2.2-3.522-5.09-4.331-7.897-.81-2.813-1.119-5.61-1.227-7.693a37.814 37.814 0 0 1-.048-2.529 26.779 26.779 0 0 1 .03-.91l.003-.052v-.015l.001-.004v-.002l.998.066-.998-.066a1 1 0 0 1 .588-.846L22.09 7.088ZM9.51 14.934l-.006.291c-.01.575-.006 1.401.046 2.394.103 1.99.396 4.624 1.15 7.242.757 2.625 1.957 5.165 3.826 7.038C16.369 33.747 18.912 35 22.5 35s6.131-1.253 7.974-3.1c1.87-1.874 3.069-4.414 3.825-7.039.755-2.618 1.048-5.252 1.151-7.242a35.836 35.836 0 0 0 .04-2.684L22.5 9.095 9.51 14.934Z"
      fill="#026FF4"
    />
  </svg>
);

export default BlueShieldSVG;
