import { colors, metrics } from '~styles';
import styled, { css } from 'styled-components';

interface StylesProps {
  selected: boolean;
}

interface StyledContainerProps {
  visible: boolean;
  isOpen?: boolean;
}

export const ContainerDropDown = styled.div<StyledContainerProps>`
  background-color: ${colors.white};

  display: ${({ visible }) => (!visible ? 'none' : 'flex')};
  flex-direction: column;
  z-index: 99;
  position: relative;

  .search-arrow {
    width: 16px;
    height: 16px;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
    transition: transform 0.3s ease-in-out;
  }

  .container-list {
    background-color: ${colors.white};
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.03);
    border: 1px solid ${colors.grayOpacity20p};
    max-height: 200px;
    padding: 10px 0px;
    top: 60px;
    left: 50%;
    transform: translatex(-50%);
    width: 101%;
    border-radius: 10px;
    overflow: auto;
    position: absolute;

    &::-webkit-scrollbar {
      width: 5px;
      position: absolute;
      right: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #9ea1b94d;
    }
  }
`;

export const ItemList = styled.div<StylesProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ selected }) =>
    selected
      ? css`
          background-color: ${colors.gray};
        `
      : css`
          background-color: ${colors.white};
        `}

  width: 100%;
  margin-bottom: 3px;
  gap: 5px;
  padding: 13px 16px;

  .tail {
    overflow: hidden;
    color: ${colors.black};
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px;
  }

  :hover {
    cursor: pointer;
    background-color: ${colors.gray};
  }
`;
