import * as React from 'react';
import { SVGProps } from 'react';

const WalletBttSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.143 14.983a9.988 9.988 0 0 0-.587-1.882 9.959 9.959 0 0 0-2.15-3.19 9.966 9.966 0 0 0-3.189-2.15 9.986 9.986 0 0 0-7.798 0 9.967 9.967 0 0 0-3.19 2.151 9.967 9.967 0 0 0-1.223 1.48c-.36.54-.67 1.112-.926 1.709a9.986 9.986 0 0 0 0 7.798 9.98 9.98 0 0 0 2.15 3.19 9.967 9.967 0 0 0 3.189 2.15 9.986 9.986 0 0 0 7.798 0 9.965 9.965 0 0 0 3.19-2.15 9.965 9.965 0 0 0 2.15-3.19 9.986 9.986 0 0 0 .586-5.916Zm-9.816 10.792c-4.845 0-8.764-3.93-8.764-8.765 0-4.835 3.93-8.765 8.764-8.765 4.835 0 8.766 3.93 8.766 8.765 0 4.835-3.93 8.765-8.766 8.765Z"
      fill="#000"
    />
    <path
      d="M15.483 25.014h.237c.04 0 .081 0 .122-.01h.011c.041 0 .072 0 .113-.011h.03c.032 0 .063 0 .093-.01h.031c.031 0 .063-.01.104-.01h.02c.041 0 .082-.011.123-.011.042 0 .073-.01.114-.02h.02c.031 0 .062-.01.092-.01h.021c.042-.011.073-.011.114-.021.081-.01.164-.031.236-.052h.02c.031-.01.072-.01.103-.02h.01c.083-.02.155-.042.237-.062a9.2 9.2 0 0 1-2.293-.216c-1.133-.247-2.212-.689-3.128-1.45a6.338 6.338 0 0 1-2.284-4.928 5.988 5.988 0 0 1 1.41-3.827 6.11 6.11 0 0 1 4.546-2.16h.237v-1.369h-.267c-.52.01-1.036.072-1.543.185a7.42 7.42 0 0 0-1.482.504 7.584 7.584 0 0 0-1.358.824 7.242 7.242 0 0 0-1.183 1.131c-.33.39-.616.814-.854 1.266a7.231 7.231 0 0 0-.566 1.367 7.478 7.478 0 0 0-.277 1.431l-.031.452v.267c.01.525.072 1.04.185 1.544.113.515.288 1.008.504 1.482.258.565.72 1.398 1.41 2.026a7.956 7.956 0 0 0 4.937 1.708h.186Z"
      fill="#000"
    />
    <path
      d="M17.725 23.697a10.463 10.463 0 0 1-1.512-.165c-2.12-.38-3.94-1.677-4.608-3.631-.814-2.377.431-4.949 2.797-5.73.451-.151.924-.228 1.4-.227.607 0 1.213.124 1.77.35l.575-1.224c-.173-.07-.348-.135-.524-.195a6.06 6.06 0 0 0-1.822-.278 5.77 5.77 0 0 0-1.82.298 5.721 5.721 0 0 0-2.027 1.173 5.894 5.894 0 0 0-.771.833 5.8 5.8 0 0 0-1.163 3.128 5.848 5.848 0 0 0 .308 2.304c.262.762.683 1.46 1.235 2.047.505.545 1.09.957 1.8 1.338.741.39 1.657.596 2.613.73.617.082 1.44.103 1.924.103.422-.154.596-.227.823-.32a7.71 7.71 0 0 0 .587-.297c.195-.114.226-.135.555-.35a.567.567 0 0 0 .082-.062c-1.131.196-1.42.185-2.222.175Z"
      fill="#000"
    />
    <path
      d="M20.257 22.164c-.32.02-.773.05-1.287.05-.915 0-2.037-.08-2.993-.4-1.482-.493-2.88-1.759-2.88-3.323a2.816 2.816 0 0 1 2.818-2.819c1.06 0 1.975.587 2.459 1.44l1.224-.606a3.977 3.977 0 0 0-.731-.977 4.172 4.172 0 0 0-6.811 1.354 4.173 4.173 0 0 0-.318 1.598c.002.575.125 1.143.36 1.667.216.473.515.916.896 1.327.668.72 1.583 1.296 2.561 1.625.936.308 2.315.422 3.652.33.37-.032.648-.063 1.121-.165.457-.36.87-.771 1.235-1.224-.32.03-.906.092-1.306.123Z"
      fill="#000"
    />
  </svg>
);

export default WalletBttSVG;
