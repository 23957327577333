import * as React from 'react';

import { SVGProps } from 'react';

const BusdCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="m21.924 8-6.418 6.437 2.61 2.618 6.419-6.437-2.61-2.617ZM14.323 15.628l-2.61 2.617 2.61 2.617 2.61-2.616-2.61-2.618ZM29.616 15.653 19.34 25.957l2.594 2.601L32.21 18.254l-2.594-2.601ZM25.826 11.85 15.55 22.157l2.594 2.6 10.275-10.304-2.593-2.601Z"
      fill="#F0B90B"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BusdCoinSVG;
