import * as Style from './TableItem.styles';
import * as Text from '~styles/text';

import { Else, If, Then, When } from 'react-if';
import { formatCurrencyFn, getWalletIcon } from '~helpers/format/currency';
import { useEffect, useMemo, useState } from 'react';
import { saveAs } from 'file-saver';
import BankAccountIcon from '~assets/images/icons/24x24/capconta.svg';
import { BillReceipt } from '~assets/pdfs/bill/BillReceipt';
import BillSVG from '~assets/svg/statement/bill';
import { ConvertBankingReceipt } from '~assets/pdfs/convertBanking/ConvertBankingReceipt';
import ConvertSVG from '~assets/svg/statement/convert';
import MoneyReceivedSVG from '~assets/svg/statement/moneyReceived';
import PixTedReceivedSVG from '~assets/svg/statement/pix-ted-received';
import PixTedSentSVG from '~assets/svg/statement/pix-ted-sent';
import ReceiptIcon from '~assets/images/icons/transactions/receipt.svg';
import { TransferReceipt } from '~assets/pdfs/transfer/TransferReceipt';
import UserPic from '~components/UserPic';
import { breakpoints } from '~styles/metrics';
import { capitalizeName } from '~helpers/format/user';
import { colors } from '~styles';
import dayjs from 'dayjs';
import { formatCpfOrCnpj } from '~helpers/format/numbers';
import { getSelectedUserOrganization } from '~helpers/device';
import { pdf } from '@react-pdf/renderer';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetStatementSpecific } from '~reactQuery/queries/bank/useGetStatementSpecific';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';
import { receiptTitleParser } from '~helpers/transaction/transactionUtils';

const TableItem: React.FC = (tx: any) => {
  const [width, setWidth] = useState(0);
  const [authCode, setAuthCode] = useState('');

  const userOrganizationId = getSelectedUserOrganization();

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const { data: transferSpecificDetails, isLoading: loadingDetails } =
    useGetStatementSpecific({
      authCode,
      userOrganizationId: Number(userOrganizationId),
    });

  const { data: accountDetails, isLoading: loadingGetAccount } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  const { data: wallets, isLoading: loadingWallets } = useGetWallets({
    prefCurrency: 'BRL',
    flag: 'list',
    userOrganizationId: Number(userOrganizationId),
  });

  const getWalletFiltered = (walletAddress: string) => {
    if (walletAddress && wallets) {
      return wallets.filter((w) => w.walletAddress === walletAddress)?.[0];
    }
    return null;
  };

  const parsedTX = useMemo(() => {
    if (tx) {
      let statementListParsed = {} as any;
      let show = true;
      let isCapContaToWallet = false;
      let isCapWalletToCapConta = false;

      switch (tx.method) {
        case 'Transfer':
          isCapContaToWallet =
            tx?.data?.requisite?.type === 'EXCHANGE_CAPCONTA_TO_CAPWALLET';
          isCapWalletToCapConta =
            tx?.data?.requisite?.type === 'EXCHANGE_CAPWALLET_TO_CAPCONTA';

          if (isCapContaToWallet) {
            const title = receiptTitleParser(tx.status, 'CAP-TO-WALLET', 1);

            statementListParsed = {
              title,
              icon: <ConvertSVG fill={colors.grayMedium} />,
              from: {
                name: 'Cap Conta',
              },
              to: {
                walletIsoCode: getWalletFiltered(
                  tx.data.requisite.walletAddress
                )?.currency?.isoCode,
                name: getWalletFiltered(tx.data.requisite.walletAddress)
                  ?.walletName,
              },
            };
          } else if (isCapWalletToCapConta) {
            const title = receiptTitleParser(tx.status, 'WALLET-TO-CAP', 1);

            statementListParsed = {
              title,
              icon: <ConvertSVG fill={colors.grayMedium} />,
              from: {
                walletIsoCode: getWalletFiltered(
                  tx.data.requisite.walletAddress
                )?.currency?.isoCode,
                name: getWalletFiltered(tx.data.requisite.walletAddress)
                  ?.walletName,
              },
              to: {
                name: 'Cap Conta',
              },
            };
          } else {
            const title = receiptTitleParser(
              tx.status,
              String(tx?.data?.method).toUpperCase() === 'PIX'
                ? 'TRANSFER-PIX'
                : 'TRANSFER-TED',
              tx.direction
            );
            statementListParsed = {
              title,
              icon:
                tx.direction < 1 ? (
                  <PixTedSentSVG />
                ) : (
                  <PixTedReceivedSVG stroke={colors.success} />
                ),
              from: {
                name: 'Cap Conta',
                docNum: '',
              },
              to: {
                name:
                  tx.direction > 1
                    ? 'Cap Conta'
                    : tx.data?.requisite?.key?.holderName ||
                      tx.data?.requisite?.holderName,
                docNum:
                  tx.direction > 1
                    ? ''
                    : formatCpfOrCnpj(
                        tx.data?.requisite?.key?.holderDocument ||
                          tx.data?.requisite?.holderDocument
                      ),
              },
            };
          }

          break;
        case 'Bill': {
          const title = receiptTitleParser(tx.status, 'BILL', -1);

          statementListParsed = {
            title,
            icon: <BillSVG />,

            to: {
              name: tx?.data?.assignor,
              docNum: tx?.data?.recipientName,
            },
          };
          break;
        }

        case 'BillSlip': {
          const title = receiptTitleParser(tx.status, 'BILL-SLIP', 1);

          if (tx.direction === 0) {
            show = false;
          }
          statementListParsed = {
            title,
            icon: <BillSVG />,
            from: {
              name: 'Cap Conta',
              docNum: '',
            },
            to: {
              name: tx?.data?.assignor,
              docNum: tx?.data?.recipientName,
            },
          };
          break;
        }
        case 'Refund': {
          const title = receiptTitleParser(tx.status, 'REFUND', 1);
          statementListParsed = {
            title: 'Transferência Reembolsada',
            icon: <MoneyReceivedSVG fill={colors.success} />,
            to: {
              name: 'Cap Conta',
            },
          };
          break;
        }

        default:
          if (tx?.data?.method === 'Invoice') {
            statementListParsed = {
              title: 'Pagamento de Invoice',
              icon: <BillSVG />,
              from: {
                name: 'Cap Conta',
                docNum: '',
              },
            };
          }

          break;
      }

      return { ...statementListParsed, show };
    }
    return null;
  }, [tx, wallets]);

  const loading = !tx || loadingWallets;

  const downloadSingle = () => {
    setAuthCode(tx?.authCode || '');
  };

  const generatePdfDocument = async () => {
    let blob = null;
    let fileName = '';

    if (!transferSpecificDetails) return;

    switch (transferSpecificDetails?.method) {
      case 'Bill':
        blob = await pdf(
          <BillReceipt
            accountDetails={accountDetails}
            billDetails={transferSpecificDetails}
            authCode={tx?.authCode}
          />
        ).toBlob();
        fileName = 'Boleto';
        break;
      case 'Transfer':
        if (parsedTX.title === 'Conversão CapWallet Para CapConta') {
          blob = await pdf(
            <ConvertBankingReceipt
              accountDetails={accountDetails}
              convertDetails={{
                timestamp: tx?.timestamp,
                authCode: tx?.authCode,
                amount: tx?.data?.value,
                originWallet: 'Cap Wallet',
                destinationWallet: 'Cap Conta',
                status: tx?.status,
              }}
            />
          ).toBlob();
          fileName = 'Conversão';
        } else if (parsedTX.title === 'Conversão CapConta Para CapWallet') {
          blob = await pdf(
            <ConvertBankingReceipt
              accountDetails={accountDetails}
              convertDetails={{
                status: tx?.status,
                timestamp: tx?.timestamp,
                authCode: tx?.authCode,
                amount: tx?.data?.value,
                originWallet: 'Cap Conta',
                destinationWallet: `${
                  getWalletFiltered(tx?.data?.requisite?.walletAddress)
                    ?.walletName
                } - ${tx?.data?.requisite?.walletAddress}`,
              }}
            />
          ).toBlob();
          fileName = 'Conversão';
        } else {
          blob = await pdf(
            <TransferReceipt
              accountDetails={accountDetails}
              transferDetails={transferSpecificDetails}
              authCode={tx?.authCode}
              status={tx?.status}
            />
          ).toBlob();
          fileName = 'Transferência';
        }

        break;
      default:
        break;
    }

    if (!blob) return;

    saveAs(blob, `${fileName}-${dayjs().format('DD-MM-YYYY')}.pdf`);
    setAuthCode('');
  };

  useEffect(() => {
    if (transferSpecificDetails?.authCode === authCode && !loadingGetAccount) {
      generatePdfDocument();
    }
  }, [transferSpecificDetails, loadingGetAccount]);

  return (
    <If condition={loading}>
      <Then>{null}</Then>
      <Else>
        {parsedTX?.show && (
          <Style.Container
            paddingLeft={30}
            paddingRight={breakpoints.phone > width ? 0 : 30}
          >
            <div className="icon-transaction">{parsedTX?.icon}</div>

            <div className="column item-40">
              <Text.Heading5>{parsedTX?.title}</Text.Heading5>
              {tx.timestamp ? (
                <Text.Small color={colors.grayRegular}>
                  {dayjs(tx.timestamp).format('h:mm A')} •{' '}
                  {dayjs(tx.timestamp).format('DD/MM/YYYY')}
                </Text.Small>
              ) : (
                <Text.Small color={colors.grayRegular}>--/--/--</Text.Small>
              )}
            </div>

            <div className="row item-35 middle align-center destination">
              <If condition={parsedTX?.to?.name === 'Cap Conta'}>
                <Then>
                  <BankAccountIcon />
                </Then>

                <Else>
                  <If condition={parsedTX?.to?.walletIsoCode}>
                    <Then>{getWalletIcon(parsedTX?.to?.walletIsoCode)}</Then>

                    <Else>
                      <If condition={parsedTX?.to?.name}>
                        <Then>
                          <div>
                            <UserPic name={parsedTX?.to?.name} />
                          </div>
                        </Then>

                        <Else>
                          {parsedTX?.to?.name ? (
                            <div>
                              <UserPic name="W" />
                            </div>
                          ) : (
                            <BankAccountIcon />
                          )}
                        </Else>
                      </If>
                    </Else>
                  </If>
                </Else>
              </If>

              <div className="column ml-10">
                <Text.Heading5>
                  {parsedTX?.to?.name
                    ? capitalizeName(String(parsedTX?.to?.name).toLowerCase())
                    : 'Cap Conta'}
                </Text.Heading5>
                <When condition={parsedTX?.to?.name}>
                  <Text.Small color={colors.grayRegular}>
                    {parsedTX?.to?.docNum}
                  </Text.Small>
                </When>
              </div>
            </div>

            <div className="row item-25 right">
              <div className="column align-center justify-center last-item">
                <Text.Heading5
                  color={tx.direction < 1 ? colors.grayMedium : colors.success}
                >
                  {tx.direction < 1 ? '- ' : '+ '}
                  {formatCurrencyFn(
                    'BRL',
                    String(Number(tx?.data?.value) / 100)
                  )}
                </Text.Heading5>
              </div>

              <a className="receipt" onClick={downloadSingle}>
                <ReceiptIcon />

                <div className="tooltip-text">
                  <Text.Smaller color={colors.white}>Comprovante</Text.Smaller>
                </div>
              </a>
            </div>
          </Style.Container>
        )}
      </Else>
    </If>
  );
};

export default TableItem;
