import { SVGProps } from 'react';

const NotificationsSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 18}
    height={props.height || 18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.25 1.627A5.986 5.986 0 0 0 9 .002 5.986 5.986 0 0 0 2.87 5.8l-.001.016v4.077l-1.727 2.878-.908 1.514h17.532l-.908-1.514-1.727-2.878V5.8a5.987 5.987 0 0 0-1.881-4.173ZM6.12 3.083a3.986 3.986 0 0 1 2.85-1.081h.06a3.986 3.986 0 0 1 4.1 3.846v4.599l.143.238.96 1.6H3.767l.96-1.6.143-.238V5.848A3.986 3.986 0 0 1 6.12 3.083ZM6 18h6v-2H6v2Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default NotificationsSvg;
