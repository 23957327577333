import { gql, request, useMutation } from '~reactQuery';

interface CreatePartnerInput {
  partnerId: number;
}

interface CreatePartnerResponse {
  deletePartnerData: {
    data: boolean;
    messages: [string];
    isValid: boolean;
  };
}

export function useDeletePartnerMutation() {
  const mutation = gql`
    mutation deletePartnerData($params: deletePartnerDataInput!) {
      deletePartnerData(params: $params) {
        data
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: CreatePartnerInput) => {
    const response = await request<CreatePartnerResponse>(mutation, {
      params,
    });
    return response.deletePartnerData;
  };

  return useMutation('deletePartnerData', fetch);
}
