import * as React from 'react';

import { SVGProps } from 'react';

const DogeCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.98 27.31h6.242S31 28.047 31 18.15c0-9.513-8.016-9.479-9.565-9.472H15.979v8.3h-2.2v2.033h2.2v8.3Zm3.515-15.188h2.463c.922 0 5.556.371 5.564 6.096.007 5.656-4.666 5.65-5.417 5.649h-2.61V19.01h3.875v-2.033h-3.875v-4.856Z"
      fill="#C2A633"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DogeCoinSVG;
