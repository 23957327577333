import {
  BusinessSize,
  BusinessType,
  Status,
} from '~reactQuery/types/business.types';
import { gql, request, useMutation } from '~reactQuery';

import { useQueryClient } from 'react-query';

interface CreateBusinessInput {
  processId: number;
  addressLine: string;
  buildingNumber: string;
  businessCnae: string;
  businessEmail: string;
  businessName: string;
  declaredAnnualBilling: string;
  participationPercentage: string;
  businessSize: BusinessSize;
  city: string;
  country: string;
  countryCode: string;
  documentNumber: string;
  neighborhood: string;
  phone: string;
  state: string;
  tradingName: string;
  zipCode: string;
  businessType: BusinessType;
  complement?: string;
  site?: string;
  numberOfPartners: number;
  companyStartDate: string;
}

interface CreateBusinessResponse {
  createBusiness: {
    data: {
      id: number;
      documentNumber: string;
      participationPercentage: string;
      declaredAnnualBilling: string;
      businessName: string;
      tradingName: string;
      businessType: BusinessType;
      businessSize: BusinessSize;
      businessCnae: string;
      countryCode: string;
      phone: string;
      site: string;
      businessEmail: string;
      zipCode: string;
      addressLine: string;
      buildingNumber: string;
      complement: string;
      neighborhood: string;
      city: string;
      state: string;
      country: string;
      numberOfPartners: number;
      createdAt: string;
      updatedAt: string;
      deleted: boolean;
      deletedAt: string;
      status: Status;
      companyStartDate: string;
      isCryptoTrader: boolean;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useCreateBusinessMutation() {
  const queryClient = useQueryClient();

  const mutation = gql`
    mutation CreateBusiness($params: createBusinessInput!) {
      createBusiness(params: $params) {
        data {
          id
          declaredAnnualBilling
          participationPercentage
          documentNumber
          businessName
          tradingName
          businessType
          businessSize
          businessCnae
          countryCode
          phone
          site
          businessEmail
          zipCode
          addressLine
          buildingNumber
          complement
          neighborhood
          city
          state
          country
          numberOfPartners
          createdAt
          updatedAt
          deleted
          deletedAt
          status
          companyStartDate
          isCryptoTrader
        }
        isValid
        messages
      }
    }
  `;

  const fetch = async (params: CreateBusinessInput) => {
    const response = await request<CreateBusinessResponse>(mutation, {
      params,
    });
    return response.createBusiness;
  };

  return useMutation('CreateBusiness', fetch, {
    onSettled: () => {
      queryClient.invalidateQueries(['getProcess']);
    },
  });
}
