import * as Style from './PartnersSteps.styles';
import * as Text from '~styles/text';
import * as yup from 'yup';

import { BigInput, GenericButton } from '~components';
import { useEffect, useState } from 'react';

import AlertModal from '~components/AlertModal';
import ArrowBackIcon from '~assets/images/icons/kyc/arrow-back.svg';
import { ModalProps } from '../Kyc.types';
import { When } from 'react-if';
import { colors } from '~styles';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { splitByLastDot } from '~helpers/format/file';
import { usePartnersList } from '../store';
import { useTranslation } from 'react-i18next';

export const PartnerName = ({
  gotToModalStep,
  partnersMethods,
}: ModalProps) => {
  const { t } = useTranslation();
  const { field: currentField } = usePartnersList();

  const [showAlert, setShowAlert] = useState(false);
  const [field, setField] = useState('');

  const {
    formState: { errors },
    getValues,
    register,
    setError,
    setValue,
  } = partnersMethods;

  useEffect(() => {
    setField(Array.isArray(currentField) ? currentField[0] : currentField);
  }, [currentField]);

  const getIndex = onlyNumbers(String(field));
  const currentPersonalPartner = getValues(String(field));

  const disableGoBack =
    currentPersonalPartner?.isSaved ||
    currentPersonalPartner?.partnerId ||
    currentPersonalPartner?.partnerType === 'owner';

  const isPartnerOfPartner = field.includes('partnerData');

  const errorIncase = isPartnerOfPartner
    ? errors?.allPartners?.[getIndex?.[0]]?.list?.[getIndex?.[1]]
        ?.partnerData?.[getIndex?.[2]]?.registerName
    : errors?.allPartners?.[getIndex?.[0]]?.list?.[getIndex?.[1]]?.registerName;

  const nameSchema = yup.object().shape({
    registerName: yup
      .string()
      .min(4, 'Esse é realmente seu nome?')
      .matches(/^[aA-zZ\s]+$/, 'Não são aceitos acento ou caracteres especiais')
      .required('Esse campo é obrigatório'),
  });

  const onSubmit = async () => {
    try {
      await nameSchema.validate({
        registerName: getValues(`${field}.registerName`),
      });
      gotToModalStep('documentNumber');
    } catch (err: any) {
      setError(`${field}.registerName`, {
        type: 'required',
        message: err.message,
      });
    }
  };

  const onAcceptTerms = () => {
    if (!currentPersonalPartner?.partnerId) {
      const newArray = getValues()?.allPartners?.[getIndex?.[0]]?.list?.filter(
        (item: any) => {
          return item !== currentPersonalPartner;
        }
      );

      setValue(splitByLastDot(field), newArray);
    }

    gotToModalStep('businessPartnerName');
  };

  if (field.length <= 0) return null;

  return (
    <>
      <When condition={showAlert}>
        <Style.ModalContainer>
          <AlertModal
            setOpenAlert={setShowAlert}
            primaryButtonAction={() => setShowAlert(false)}
            secondaryButtonAction={onAcceptTerms}
            primaryButtonText="Sair"
            secondaryButtonText="Continuar"
            title={t('Seu sócio é uma empresa?')}
            bodyText={t(
              'Para cadastrar sócio empresarial, tenha em mãos a razão social e CNPJ da empresa. Após verificação, será solicitado documentos e informações adicionais pelo nosso suporte. Clique no botão continuar, caso queira dar prosseguimento no cadastro.'
            )}
          />
        </Style.ModalContainer>
      </When>

      <Style.Container>
        <div className="content">
          <When condition={!disableGoBack}>
            <div
              className="back-button"
              onClick={() => gotToModalStep('start')}
            >
              <ArrowBackIcon />
            </div>
          </When>
          <Text.Heading5 color={colors.grayMedium} marginTop={30}>
            {t('Adicionar Sócio')}
          </Text.Heading5>

          <Text.Heading2Bold marginTop={10} marginBottom={30}>
            {t('Para começar, qual o nome completo do seu sócio?')}
          </Text.Heading2Bold>

          <BigInput
            autoFocus
            {...register(`${field}.registerName`)}
            paddingBottom={104}
            placeHolder="Luís Andrade Cavalcante"
            error={errorIncase?.message}
          />

          <Text.Heading5 marginTop={25} lineHeight={140}>
            {t(
              'Importante: É necessário que o nome do sócio seja escrito exatamente como no documento de identificação.'
            )}
          </Text.Heading5>
          <When condition={!disableGoBack && !isPartnerOfPartner}>
            <Text.Paragraph
              marginTop={20}
              onClick={() => setShowAlert(true)}
              color={colors.primary}
              style={{ cursor: 'pointer' }}
            >
              {t('Seu sócio é uma empresa?')}
            </Text.Paragraph>
          </When>
        </div>

        <div className="footer">
          <div className="footer-btn">
            <GenericButton
              buttonType="secondary"
              width="100%"
              onClick={onSubmit}
            >
              Próximo
            </GenericButton>
          </div>
        </div>
      </Style.Container>
    </>
  );
};
