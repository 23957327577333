import { Else, If, Then } from 'react-if'
import AuthValidate from '~components/AuthValidate'
import LoadingSpinner from '~components/LoadingSpinner'
import ModalMultiStep from '~components/ModalMultiStep'
import colors from '~styles/colors'
import { Heading2, Heading5 } from '~styles/text'
import { AskPinTwoFaProps } from './AskPinTwoFa.types'
import * as S from './AskPinTwoFa.styles'

const AskPinTwoFa = (props: AskPinTwoFaProps) => {
  const {
    isOpen,
    setIsOpen,
    title,
    description,
    auth,
    loading,
    hasError,
    onChange
  } = props

  return (
    <ModalMultiStep
      openModal={isOpen}
      setOpenModal={setIsOpen}
      modalHeight='fit-content'
      noProgressBar
    >
      <S.Container>
        <Heading2>{title}</Heading2>

        <Heading5 marginTop={8} marginBottom={30}>
          {description}
        </Heading5>

        <If condition={loading}>
          <Then>
            <LoadingSpinner
              color={colors.primary}
              size={40}
              strokeSize={17}
            />
          </Then>
          <Else>
            <AuthValidate
              authTypes={auth}
              onChangeCodes={(pin, twoFa) => onChange(pin, twoFa)}
              pinErrorMessage={hasError.pin}
              twoFaErrorMessage={hasError.twoFa}
            />
          </Else>
        </If>
      </S.Container>
    </ModalMultiStep>
  )
}

export default AskPinTwoFa