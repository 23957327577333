import { BigInput } from '~components/Inputs';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { PixModalStepsProps } from '../../Transfer.types';
import React from 'react';
import { useFormContext } from 'react-hook-form';

function ManualTransfer(props: PixModalStepsProps) {
  const { setPixStep: setCurrentStep } = props;
  const { watch, getValues, register } = useFormContext();

  const formValues = getValues();

  const goNext = () => {
    if (!formValues?.manualDetails?.docNum) {
      setCurrentStep('manualTransferDocument');
    } else {
      setCurrentStep('fillManualTransferInfo');
    }
  };
  const goBack = () => {
    if (formValues?.contact || formValues?.keyResult) {
      setCurrentStep('pixMethod');
    } else {
      setCurrentStep('chooseTransferMethod');
    }
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Para quem é a transferência?"
        description="Digite o nome completo do titular da conta:"
        backButtonAction={goBack}
      />

      <div className="content-container mt-20">
        <BigInput
          {...register('manualDetails.name')}
          defaultValue={getValues('manualDetails.name')}
          paddingBottom={104}
          placeHolder="Nome Completo"
        />
      </div>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={
          watch('manualDetails.name')?.length < 5 ||
          !watch('manualDetails.name')
        }
      />
    </>
  );
}

export default ManualTransfer;
