import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import { APP_ROUTES } from '~data/navigation';
import Activities from '~pages/Dashboard/Activities';
import Api from '~pages/Dashboard/Api';
import { AuthRoutes } from './auth';
import Bills from '~pages/Dashboard/Bills';
import CapAccountAndWallets from '~pages/Dashboard/CapAccountAndWallets';
import CapPay from '~pages/Dashboard/CapPay';
import Contacts from '~pages/Dashboard/Contacts';
import DashboardPage from '~containers/Dashboard';
import Home from '~pages/Dashboard/Home/Home.screen';
import { KycRoutes } from './kyc';
import Organizations from '~pages/Organizations';
import PixArea from '~pages/Dashboard/PixArea';
import PrivateRouter from './rule/private';
import Settings from '~pages/Settings/Settings.screen';
import FullReceipt from '~pages/FullReceipt';
import NotFound from '~pages/NotFound/NotFound.screen';
import Extract from '~pages/Dashboard/Extract';
import { getSelectedUserOrganization, getToken } from '~helpers/device';
import { useEffect, useLayoutEffect } from 'react';
import { PermissionGateProvider, usePermission } from '~helpers/permissionGate';
import { useGetGlobalNotificationsWarning } from '~reactQuery/queries/notifications/useGetGlobalNotification';
import { When } from 'react-if';
import SystemBanner from '~components/SystemBanner/SystemBanner.component';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { rules } from '~data/permissionList';
import { ModalMultiStepSections } from '~src/common/modals/multiStep/multiStepList';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const token = getToken();
  const { setSection, setOpenModalMultiStep, setParams, section } =
    useActionsOnModalMultiStep();

  const { data: partnerInfos } = useGetPartner(!token);
  const organizationSelected = getSelectedUserOrganization();

  const scopesFiltered = partnerInfos?.data?.userOrganizations?.filter(
    (item: any) => item?.id === Number(organizationSelected)
  );

  const userPermissions = scopesFiltered?.[0]?.permissions || [];

  useEffect(() => {
    document.onkeydown = function handleKeyDown(evt) {
      if (evt.key === 'Escape') {
        setOpenModalMultiStep(false);
        setSection('default');
        setParams({});
      }
    };
  }, []);

  useLayoutEffect(() => {
    const body = document.getElementById('page-wrapper');

    if (body) body.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <PermissionGateProvider role={userPermissions} rulesMap={rules}>
      <>
        {children}

        {ModalMultiStepSections[section as keyof typeof ModalMultiStepSections]}
      </>
    </PermissionGateProvider>
  );
};

const RouterRoot = () => {
  const token = getToken();
  const { granted: pixPageGranted } = usePermission('pixPage');

  const { data: globalWarnings } = useGetGlobalNotificationsWarning();

  const parseJsonString = (jsonString: string) => {
    if (!jsonString) return undefined;

    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return undefined;
    }
  };

  return (
    <>
      <When condition={globalWarnings?.length}>
        <SystemBanner
          type="info"
          text={
            parseJsonString(globalWarnings?.[0]?.data)
              ? parseJsonString(globalWarnings?.[0]?.data)?.text
              : ''
          }
          link=""
          messageLink=""
        />
      </When>

      <BrowserRouter>
        <Wrapper>
          <Routes>
            <Route path="auth/*" element={<AuthRoutes />} />

            <Route
              path={APP_ROUTES.DASHBOARD}
              element={
                <PrivateRouter>
                  <DashboardPage />
                </PrivateRouter>
              }
            >
              <Route
                path={APP_ROUTES.DASHBOARD}
                element={
                  <PrivateRouter>
                    <Home />
                  </PrivateRouter>
                }
              />
              <Route
                path={APP_ROUTES.ACTIVITIES}
                element={
                  <PrivateRouter>
                    <Activities />
                  </PrivateRouter>
                }
              />
              <Route
                path={APP_ROUTES.CAP_ACCOUNT}
                element={
                  <PrivateRouter>
                    <CapAccountAndWallets />
                  </PrivateRouter>
                }
              />
              {/* <Route
            path={APP_ROUTES.CARDS}
            element={
              <PrivateRouter>
                <Cards />
              </PrivateRouter>
            }
          /> */}
              <Route
                path={APP_ROUTES.PIX}
                element={
                  <PrivateRouter>
                    {pixPageGranted ? (
                      <PixArea />
                    ) : (
                      <Navigate to={APP_ROUTES.DASHBOARD} replace />
                    )}
                  </PrivateRouter>
                }
              />
              <Route
                path={APP_ROUTES.BILLS}
                element={
                  <PrivateRouter>
                    <Bills />
                  </PrivateRouter>
                }
              />
              <Route
                path={APP_ROUTES.CONTACTS}
                element={
                  <PrivateRouter>
                    <Contacts />
                  </PrivateRouter>
                }
              />
              <Route
                path={APP_ROUTES.API}
                element={
                  <PrivateRouter>
                    <Api />
                  </PrivateRouter>
                }
              />
              <Route
                path={APP_ROUTES.CAP_PAY}
                element={
                  <PrivateRouter>
                    <CapPay />
                  </PrivateRouter>
                }
              />

              <Route
                path={APP_ROUTES.EXTRACT}
                element={
                  <PrivateRouter>
                    <Extract />
                  </PrivateRouter>
                }
              />

              <Route
                path={APP_ROUTES.ERROR_404}
                element={
                  <PrivateRouter>
                    <NotFound />
                  </PrivateRouter>
                }
              />

              <Route
                path={APP_ROUTES.NOT_FOUND}
                element={<Navigate to={APP_ROUTES.ERROR_404} replace />}
              />
            </Route>

            <Route
              path={APP_ROUTES.FULL_RECEIPT}
              element={
                <PrivateRouter>
                  <FullReceipt />
                </PrivateRouter>
              }
            />

            <Route
              path="/kyc/*"
              element={
                <PrivateRouter>
                  <KycRoutes />
                </PrivateRouter>
              }
            />

            <Route element={<DashboardPage />}>
              <Route path="/settings" element={<Settings />} />
            </Route>

            <Route
              path="/organizations/*"
              element={
                <PrivateRouter>
                  <Organizations />
                </PrivateRouter>
              }
            />

            <Route
              path="/"
              element={
                token ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <Navigate to="/auth" replace />
                )
              }
            />

            <Route path="*" element={<Navigate to="/404" replace />} />

            {token ? (
              <Route element={<DashboardPage />}>
                <Route path="/404" element={<NotFound />} />
              </Route>
            ) : (
              <Route path="/404" element={<NotFound />} />
            )}
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </>
  );
};

export default RouterRoot;
