import { getSelectedOrg, getSelectedUserOrganization } from '~helpers/device';
import { gql, request, useQuery } from '~reactQuery';

import { InvitedUsersResp } from '~reactQuery/types/business.types';

interface GetOrganizationPartnersResponse {
  getOrganizationPartners: {
    data: InvitedUsersResp[];
    messages: [string];
    isValid: boolean;
  };
}

export function useGetOrganizationPartners() {
  const orgId = getSelectedOrg();

  const mutation = gql`
    query GetOrganizationPartners($params: GetOrganizationPartnersInput) {
      getOrganizationPartners(params: $params) {
        data {
          id
          registerName
          socialName
          capu
          documentNumber
          email
          phone
          active
          settings {
            id
            enablePin
            twoFA
            language
            notificationFlags
            createdAt
            updatedAt
          }
          userOrganizations {
            id
            flags
            admin
            active
            createdAt
            updatedAt
            organizationsId
            organizations {
              id
              name
              country
              businessCode
              setup
              bankCompe
              branch
              account
              image
              deletedAt
              createdAt
              updatedAt
              walletsInternalCredentialsId
              banklyPartnersId
              walletsInternalCredentials {
                id
                internalPin
                sessionKey
                userBusinessId
              }
              partner {
                id
                companyKey
                grantType
              }
              settings {
                createdAt
                enablePin
                id
                language
                notificationFlags
                twoFA
                updatedAt
              }
            }
            settings {
              createdAt
              enablePin
              id
              language
              notificationFlags
              twoFA
              updatedAt
            }
            permissions
          }
          userOrOrgCustomEvents {
            id
            customEventsId
            deletedAt
            createdAt
            status
          }
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async () => {
    if (!orgId) return null;

    const response = await request<GetOrganizationPartnersResponse>(mutation, {
      params: {
        organizationId: Number(orgId),
      },
    });

    return response.getOrganizationPartners.data;
  };

  return useQuery(['useGetOrganizationPartners'], fetch);
}
