export const objToOfx = (obj: Record<string, any>): string => {
  let out = '';

  Object.keys(obj).forEach((name) => {
    const item = obj[name];
    const start = `<${name}>`;
    const end = `</${name}>`;

    if (item instanceof Object) {
      if (Array.isArray(item)) {
        item.forEach((it) => {
          out += `${start}\n${objToOfx(it)}${end}\n`;
        });
        return;
      }
      out += `${start}\n${objToOfx(item)}${end}\n`;
    } else {
      out += `${start + item}\n`;
    }
  });

  return out;
};

export const serialize = (
  header: Record<string, any>,
  body: Record<string, any>
): string => {
  let out = '';

  const headers = [
    'OFXHEADER',
    'DATA',
    'VERSION',
    'SECURITY',
    'ENCODING',
    'CHARSET',
    'COMPRESSION',
    'OLDFILEUID',
    'NEWFILEUID',
  ];

  headers.forEach((name) => {
    out += `${name}:${header[name]}\n`;
  });
  out += '\n';

  out += objToOfx({ OFX: body });
  return out;
};


export const convertStringToDecimal=(str: string)=>{
  const numericValue = parseFloat(str?.replace(/[^0-9]/g, ''));
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(numericValue)) {
    return (numericValue / 100).toFixed(2);
  }
  return '0.00';
}

export const formatToOFXDate =(date: Date)=> {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}${month}${day}${hours}${minutes}${seconds}`;
}