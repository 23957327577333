import { colors } from '~styles';
import * as Text from '~styles/text';

import * as Style from './SwitchOptions.styles';
import { SwitchOptionsProps } from './SwitchOptions.types';

const SwitchOptions = (props: SwitchOptionsProps) => {
  const { selectedOption, setSelectedOption } = props;

  return (
    <Style.Container>
      <div
        style={
          selectedOption === 1
            ? {
                backgroundColor: colors.white,
                opacity: 1,
                transition: 'all ease-out 0.3s',
              }
            : {}
        }
        onClick={() => setSelectedOption(1)}
        className="container-select"
      >
        <Text.Heading5>Enviar via SMS</Text.Heading5>
      </div>

      <div
        style={
          selectedOption === 2
            ? {
                backgroundColor: colors.white,
                opacity: 1,
                transition: 'all ease-out 0.3s',
              }
            : {}
        }
        onClick={() => setSelectedOption(2)}
        className="container-select"
      >
        <Text.Heading5>Enviar via E-mail</Text.Heading5>
      </div>
    </Style.Container>
  );
};

export default SwitchOptions;
