import * as React from 'react';

import { SVGProps } from 'react';

const AddIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width || '16'}
    height={props?.height || '16'}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 9V13H9V9L13 9V7L9 7V3H7V7L3 7V9L7 9Z"
      fill={props?.fill || '#272937'}
    />
  </svg>
);

export default AddIcon;
