import * as Style from './InputForm.styles';
import * as Text from '~styles/text';

import { forwardRef, useRef } from 'react';

import InfoIcon from '~assets/images/icons/info.svg';
import { InputFormProps } from './InputForm.types';
import { When } from 'react-if';
import { colors } from '~styles';

const InputForm = forwardRef<HTMLInputElement, InputFormProps>(
  (props, _ref) => {
    const componentRef = useRef<HTMLDivElement>(null);
    const componentWidth = Number(componentRef?.current?.offsetWidth);

    const isInputShort = componentWidth < 400;
    const isErrorBig = (props.error ? props.error.length : 0) >= 40;
    const hasErrorToBeInBottom =
      isInputShort || isErrorBig || props?.makeErrorInBottom;

    return (
      <Style.Container
        {...props}
        ref={componentRef}
        errorInBottom={hasErrorToBeInBottom}
      >
        <Style.TopContent>
          <div className="label-container">
            <When condition={!!props.label}>
              <Text.Paragraph color={props.labelColor || colors.grayMedium}>
                {props.label}
              </Text.Paragraph>
            </When>
          </div>

          <When condition={!!props.error && !hasErrorToBeInBottom}>
            <div className="error-container">
              <Text.Small color={colors.danger}>{props.error}</Text.Small>
            </div>
          </When>
        </Style.TopContent>

        <Style.Input
          ref={_ref}
          {...props}
          onWheel={(e: any) => e.target.blur()}
          onChange={(event) => {
            if (props.onChange) {
              props.onChange(event);
            }
            const { value } = event.target;
            if (props.maskFunction) {
              event.target.value = props.maskFunction(value);
            }

            return event;
          }}
        />

        <Style.BottomContent>
          <When condition={!!props.error && hasErrorToBeInBottom}>
            <div className="error-container">
              <Text.Small color={colors.danger}>{props.error}</Text.Small>
            </div>
          </When>

          <When condition={!!props.helperText}>
            <div className="helper-container">
              <div className="helper-icon">
                <InfoIcon />
              </div>
              <Text.Small color={colors.grayRegular} marginLeft={6}>
                {props.helperText}
              </Text.Small>
            </div>
          </When>
        </Style.BottomContent>
      </Style.Container>
    );
  }
);

InputForm.displayName = 'InputForm';

export default InputForm;
