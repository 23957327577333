import { request, useMutation } from '~reactQuery';

import { gql } from 'graphql-request';

interface ChangePasswordInput {
  oldPasswd: string;
  newPasswd: string;
}

interface ChangePasswordResponse {
  changePasswd: {
    data: {
      id: number;
      capu: string;
      registerName: string;
      socialName: string;
      phone: string;
      email: string;
      documentNumber: string;
      deletedAt: string;
      createdAt: string;
      updatedAt: string;
      active: boolean;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useChangePassword(token: string) {
  const mutation = gql`
    mutation ChangePasswd($params: ChangePasswdInput!) {
      changePasswd(params: $params) {
        messages
        isValid
        data {
          id
          capu
          registerName
          socialName
          phone
          email
          documentNumber
          deletedAt
          createdAt
          updatedAt
          active
        }
      }
    }
  `;

  const fetch = async (params: ChangePasswordInput) => {
    if (!token) return null;

    const response = await request<ChangePasswordResponse>(
      mutation,
      {
        params,
      },
      { Authorization: `Bearer ${token}` }
    );
    return response.changePasswd;
  };

  return useMutation('changePasswordMutation', fetch);
}
