import * as Style from '../SignUp.styles';
import * as Text from '~styles/text';
import { GenericButton } from '~components';
import MessageIcon from '~assets/images/icons/sign/message.svg';
import { breakpoints } from '~styles/metrics';
import colors from '~styles/colors';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useTimer from '~hooks/useTimer';
import useWindowDimensions from '~hooks/windowDimension';
import { useFormContext } from 'react-hook-form';
import { useGetCustomerDataEmail } from '~reactQuery/mutations/user/useGetCustomerDataEmail';

export const QuestionsAlertMsg = () => {
  const { restartTimer, counting, timeLeft } = useTimer(60);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { watch } = useFormContext();

  const { mutateAsync: sendEmail, isLoading: loadingSendEmail } =
    useGetCustomerDataEmail();

  const baseURL = window.location.origin;
  const urlCallback = `${baseURL}/auth/sign-up`;

  const cpf = watch('cpf');
  const email = watch('email');

  async function resendEmail() {
    try {
      await sendEmail({
        documentNumber: cpf.replace(/\D/g, ''),
        urlCallback,
      });

      restartTimer();
    } catch (error) {
      //  console.log('Resend email ->', error);
    }
  }

  useEffect(() => {
    restartTimer();
  }, []);

  return (
    <Style.Container>
      <div className="main-icon">
        <MessageIcon />
      </div>

      <Text.Heading1Medium marginTop={10} marginBottom={30}>
        Identificamos sua conta e está tudo certo. Agora podemos prosseguir!
      </Text.Heading1Medium>

      <Text.Heading5
        color={colors.black}
        lineHeight={140}
        width={breakpoints.desktop < width ? '100%' : ''}
        display="inline"
      >
        Enviamos um e-mail para
        <strong> {email} </strong> , com todas as instruções necessárias para
        você continuar o processo de cadastro no CapBusiness.
      </Text.Heading5>

      <div style={{ marginTop: 30 }} className="buttons-wrapper">
        <GenericButton
          buttonType="inline"
          width="auto"
          onClick={() => navigate('/auth/sign-in')}
        >
          Voltar para o início
        </GenericButton>

        <GenericButton
          buttonLoading={loadingSendEmail}
          disabled={counting}
          buttonType="default"
          width="auto"
          onClick={resendEmail}
        >
          {`Não recebi o e-mail ${counting ? `( ${timeLeft} )` : ''}`}
        </GenericButton>
      </div>
    </Style.Container>
  );
};
