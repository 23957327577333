import * as React from 'react';

import { SVGProps } from 'react';

const ConvertSettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '21'}
    height={props.height || '23'}
    viewBox="0 0 21 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7091 0.715041L15.0019 0.00793457L13.5877 1.42215L14.2948 2.12926L16.5877 4.42212H1.00195H0.00195312V6.42212H1.00195H16.5877L14.2948 8.71505L13.5877 9.42215L15.002 10.8364L15.7091 10.1293L19.7091 6.12923C19.8966 5.94169 20.002 5.68734 20.002 5.42212C20.002 5.1569 19.8966 4.90255 19.7091 4.71501L15.7091 0.715041ZM4.29485 22.1293L5.00196 22.8364L6.41617 21.4221L5.70906 20.715L3.41618 18.4222H19.002H20.002V16.4222H19.002H3.41616L5.70906 14.1293L6.41617 13.4221L5.00195 12.0079L4.29484 12.715L0.294844 16.7151C0.107307 16.9026 0.00195312 17.157 0.00195312 17.4222C0.00195312 17.6874 0.107311 17.9417 0.294849 18.1293L4.29485 22.1293Z"
      fill={props.fill || '#026FF4'}
    />
  </svg>
);

export default ConvertSettingsIcon;
