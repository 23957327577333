import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

import blackLogo from '~assets/images/logo-black.png';
import colors from '~styles/colors';
import { formatCurrencyFn, isFiatCurrency } from '~helpers/format/currency';
import { ConversionDetails } from '~src/common/modals/multiStep/Convert/Convert.types';
import dayjs from 'dayjs';

interface ConvertPixProps {
  convertDetails?: ConversionDetails;
}

const styles = StyleSheet.create({
  body: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    fontWeight: 400,
  },
  title: {
    fontSize: 26,
    textAlign: 'left',
    fontWeight: 700,
    lineHeight: '120%',
  },
  logo: {
    width: 125,
    height: 33,
  },
  container: {
    width: '100%',
    marginTop: 30,
    padding: '25px 25px 25px 25px',
  },
  date: {
    marginTop: 15,
    marginBottom: 40,
    fontWeight: 400,
    fontSize: 16,
    color: colors.grayMedium,
  },
  valueContainer: {
    flexDirection: 'column',
    marginBottom: 20,
  },
  divider: {
    width: 50,
    border: '1px solid #C3C6DB',
    marginBottom: 30,
    marginTop: 30,
  },
  footer: {
    backgroundColor: colors.mainGray,
    padding: 20,
    borderRadius: 15,
    bottom: 15,
    position: 'absolute',
    width: '90%',
  },
  label: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '100%',
    color: colors.grayRegular,
    marginBottom: 10,
  },
  link: {
    color: colors.primary,
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '150%',
    marginTop: 12,
    marginBottom: 5,
    textAlign: 'center',
    textDecoration: 'none',
  },
  containerImg: {
    height: 33,
    top: 30,
    left: 25,
    width: '100%',
    zIndex: 99,
  },
  convertedValue: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '100%',
  },
  userName: {
    fontWeight: 700,
    fontSize: 18,
  },
  textNames: {
    fontWeight: 700,
    fontSize: 14,
    marginBottom: 7,
  },
  bankNameText: {
    fontWeight: 400,
    fontSize: 15,
    marginBottom: 20,
  },
  displayRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  marginLeft: {
    marginLeft: 20,
  },
  marginBottom: {
    marginBottom: 25,
  },
  colorGrayMedium: {
    color: colors.grayMedium,
  },
  view: {
    marginBottom: 20,
  },
  help: {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '150%',
    textAlign: 'center',
    marginBottom: 12,
  },
  helpText: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '150%',
    textAlign: 'center',
  },
});

export const ConvertReceipt = ({ convertDetails }: ConvertPixProps) => {
  const amount = convertDetails?.amount;
  const valueConverted = convertDetails?.convertedAmount;
  const date = dayjs(convertDetails?.timestamp).format('LLL');

  const authCode = convertDetails?.authCode;

  const originWallet = convertDetails?.originWallet;
  const destinationWallet = convertDetails?.destinationWallet;

  const originWalletIsoCode = originWallet?.currency.isoCode;
  const destinationWalletIsoCode = destinationWallet?.currency.isoCode;

  const ratio = `1 ${originWalletIsoCode} = ${formatCurrencyFn(
    destinationWalletIsoCode,
    convertDetails?.ratio,
    false,
    isFiatCurrency(destinationWalletIsoCode as any)
  )}`;

  return (
    <Document>
      <Page wrap style={styles.body} size={{ width: 375, height: 1048 }}>
        <View fixed style={styles.containerImg}>
          <Image src={blackLogo} style={styles.logo} />
        </View>
        <View style={styles.container}>
          <View>
            <Text style={styles.title}>
              Comprovante de
              {'\n'}
              Conversão Interna
            </Text>
          </View>
          <Text style={styles.date}>{date}</Text>

          <View style={styles.valueContainer}>
            <Text style={styles.label}>Valor Convertido</Text>
            <Text style={styles.convertedValue}>
              {formatCurrencyFn(
                originWalletIsoCode,
                amount,
                false,
                isFiatCurrency(originWalletIsoCode as any)
              )}
            </Text>
          </View>

          <View style={styles.view}>
            <Text style={styles.label}>
              Valor aproximado em {destinationWalletIsoCode}
            </Text>
            <Text style={styles.textNames}>
              {formatCurrencyFn(
                destinationWalletIsoCode,
                valueConverted,
                false,
                isFiatCurrency(destinationWalletIsoCode as any)
              )}
            </Text>
          </View>
          <View>
            <Text style={styles.label}>
              Cotação {originWalletIsoCode}/{destinationWalletIsoCode}
            </Text>
            <Text style={styles.textNames}>{ratio}</Text>
          </View>

          <View style={styles.divider} />

          <View style={styles.view}>
            <View>
              <Text style={styles.label}>Enviado por</Text>
              <Text style={styles.userName}>{convertDetails?.userName}</Text>
            </View>
          </View>

          <View>
            <View>
              <Text style={styles.label}>CapWallet de Origem</Text>
              <Text style={styles.textNames}>
                Carteira {originWallet?.walletName} ({originWalletIsoCode})
              </Text>
            </View>
          </View>

          <View style={styles.divider} />

          <View>
            <View>
              <Text style={styles.label}>CapWallet de Destino</Text>
              <Text style={styles.textNames}>
                Carteira {destinationWallet?.walletName} (
                {destinationWalletIsoCode})
              </Text>
            </View>
          </View>
        </View>

        <View fixed style={styles.footer}>
          {authCode && (
            <View style={styles.marginBottom}>
              <Text style={styles.label}>Protocolo</Text>
              <Text>{authCode}</Text>
            </View>
          )}

          <Text style={styles.help}>Precisa de ajuda?</Text>
          <Text style={styles.helpText}>
            Estamos aqui se você tiver alguma dúvida.
          </Text>
          <Text style={styles.link}>help.capitual.com</Text>
        </View>
      </Page>
    </Document>
  );
};
