import { CurrencyBigInputStyled } from '~src/common/modals/multiStep/Transfer/Transfer.styles';
import { gql, request, useQuery } from '~reactQuery';

import { Actions } from '~reactQuery/types/wallets.types';

export interface GetLimitsAndQuotasInput {
  userOrganizationId: number;
}

interface Currency {
  id: number;
  type: string;
  precision: number;
  name: string;
  symbol: string;
  isoCode: string;
  isPivot: number;
  separator: string;
  decimal: string;
  ask: number;
  bid: number;
}

export interface GetLimitsAndQuotasResponse {
  getLimitsAndQuotas: {
    limits: {
      pix: {
        minLimit: number;
        maxLimit: number;
      };
      ted: {
        minLimit: number;
        maxLimit: number;
      };
      exchange: {
        minLimit: number;
        maxLimit: number;
      };
      crypto: {
        minLimit: number;
        maxLimit: number;
      };
      boleto: {
        minLimit: number;
        maxLimit: number;
      };
    };
    quotas: {
      pix: {
        hourly: {
          limit: number;
          used: number;
          available: number;
        };
        daily: {
          limit: number;
          used: number;
          available: number;
        };
        weekly: {
          limit: number;
          used: number;
          available: number;
        };
        monthly: {
          limit: number;
          used: number;
          available: number;
        };
        yearly: {
          limit: number;
          used: number;
          available: number;
        };
        today: {
          limit: number;
          used: number;
          available: number;
        };
        lifetime: {
          limit: number;
          used: number;
          available: number;
        };
        currency: Currency;
      };
      ted: {
        hourly: {
          limit: number;
          used: number;
          available: number;
        };
        daily: {
          limit: number;
          used: number;
          available: number;
        };
        weekly: {
          limit: number;
          used: number;
          available: number;
        };
        monthly: {
          limit: number;
          used: number;
          available: number;
        };
        yearly: {
          limit: number;
          used: number;
          available: number;
        };
        today: {
          limit: number;
          used: number;
          available: number;
        };
        lifetime: {
          limit: number;
          used: number;
          available: number;
        };
        currency: Currency;
      };
      crypto: {
        hourly: {
          limit: number;
          used: number;
          available: number;
        };
        daily: {
          limit: number;
          used: number;
          available: number;
        };
        weekly: {
          limit: number;
          used: number;
          available: number;
        };
        monthly: {
          limit: number;
          used: number;
          available: number;
        };
        yearly: {
          limit: number;
          used: number;
          available: number;
        };
        today: {
          limit: number;
          used: number;
          available: number;
        };
        lifetime: {
          limit: number;
          used: number;
          available: number;
        };
        currency: Currency;
      };
      exchange: {
        hourly: {
          limit: number;
          used: number;
          available: number;
        };
        daily: {
          limit: number;
          used: number;
          available: number;
        };
        weekly: {
          limit: number;
          used: number;
          available: number;
        };
        monthly: {
          limit: number;
          used: number;
          available: number;
        };
        yearly: {
          limit: number;
          used: number;
          available: number;
        };
        today: {
          limit: number;
          used: number;
          available: number;
        };
        lifetime: {
          limit: number;
          used: number;
          available: number;
        };
        currency: Currency;
      };
      boleto: {
        hourly: {
          limit: number;
          used: number;
          available: number;
        };
        daily: {
          limit: number;
          used: number;
          available: number;
        };
        weekly: {
          limit: number;
          used: number;
          available: number;
        };
        monthly: {
          limit: number;
          used: number;
          available: number;
        };
        yearly: {
          limit: number;
          used: number;
          available: number;
        };
        today: {
          limit: number;
          used: number;
          available: number;
        };
        lifetime: {
          limit: number;
          used: number;
          available: number;
        };
        currency: Currency;
      };
    };
  };
}

export const useGetLimitsAnQuotas = (params: GetLimitsAndQuotasInput) => {
  const QUERY = gql`
    query GetLimitsAndQuotas($params: GetLimitsAndQuotasInput) {
      getLimitsAndQuotas(params: $params) {
        limits {
          pix {
            minLimit
            maxLimit
          }
          ted {
            minLimit
            maxLimit
          }
          exchange {
            minLimit
            maxLimit
          }
          crypto {
            minLimit
            maxLimit
          }
          boleto {
            minLimit
            maxLimit
          }
        }
        quotas {
          pix {
            hourly {
              limit
              used
              available
            }
            daily {
              limit
              used
              available
            }
            weekly {
              limit
              used
              available
            }
            monthly {
              limit
              used
              available
            }
            yearly {
              limit
              used
              available
            }
            today {
              limit
              used
              available
            }
            lifetime {
              limit
              used
              available
            }
            currency {
              id
              type
              precision
              name
              symbol
              isoCode
              isPivot
              separator
              decimal
              ask
              bid
            }
          }
          ted {
            hourly {
              limit
              used
              available
            }
            daily {
              limit
              used
              available
            }
            weekly {
              limit
              used
              available
            }
            monthly {
              limit
              used
              available
            }
            yearly {
              limit
              used
              available
            }
            today {
              limit
              used
              available
            }
            lifetime {
              limit
              used
              available
            }
            currency {
              id
              type
              precision
              name
              symbol
              isoCode
              isPivot
              separator
              decimal
              ask
              bid
            }
          }
          crypto {
            hourly {
              limit
              used
              available
            }
            daily {
              limit
              used
              available
            }
            weekly {
              limit
              used
              available
            }
            monthly {
              limit
              used
              available
            }
            yearly {
              limit
              used
              available
            }
            today {
              limit
              used
              available
            }
            lifetime {
              limit
              used
              available
            }
            currency {
              id
              type
              precision
              name
              symbol
              isoCode
              isPivot
              separator
              decimal
              ask
              bid
            }
          }
          exchange {
            hourly {
              limit
              used
              available
            }
            daily {
              limit
              used
              available
            }
            weekly {
              limit
              used
              available
            }
            monthly {
              limit
              used
              available
            }
            yearly {
              limit
              used
              available
            }
            today {
              limit
              used
              available
            }
            lifetime {
              limit
              used
              available
            }
            currency {
              id
              type
              precision
              name
              symbol
              isoCode
              isPivot
              separator
              decimal
              ask
              bid
            }
          }
          boleto {
            hourly {
              limit
              used
              available
            }
            daily {
              limit
              used
              available
            }
            weekly {
              limit
              used
              available
            }
            monthly {
              limit
              used
              available
            }
            yearly {
              limit
              used
              available
            }
            today {
              limit
              used
              available
            }
            lifetime {
              limit
              used
              available
            }
            currency {
              id
              type
              precision
              name
              symbol
              isoCode
              isPivot
              separator
              decimal
              ask
              bid
            }
          }
        }
      }
    }
  `;

  const fetcher = async () => {
    const response = await request<GetLimitsAndQuotasResponse>(QUERY, {
      params: {
        userOrganizationId: params?.userOrganizationId,
      },
    });

    return response.getLimitsAndQuotas;
  };

  return useQuery(['getLimitsAndQuotas', params?.action], fetcher, {
    cacheTime: 0,
    staleTime: 0,
  });
};
