import * as Style from './UserSettingsRowList.styles';
import * as Text from '~styles/text';

import { LoadingSpinner } from '~components';
import UserItem from './UserSettingsItem.component';
import colors from '~styles/colors';
import { InvitedUsersResp } from '~reactQuery/types/business.types';

function UserSettingsRowList({
  users,
  onScroll,
  isFetchingNextPage,
  hideStatusLabel,
}: {
  users?: InvitedUsersResp[];
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
  isFetchingNextPage?: boolean;
  hideStatusLabel?: boolean;
}) {
  return (
    <Style.Container>
      <div className="rounded-div">
        <div className="tableFixHead" onScroll={onScroll}>
          <table id="my-table" className="w-full border-collapse">
            <thead>
              <tr>
                <th className="hide">
                  <Text.Paragraph color={colors.grayMedium}>
                    Usuário
                  </Text.Paragraph>
                </th>
                <th>
                  <Text.Paragraph color={colors.grayMedium}>
                    Data da adição
                  </Text.Paragraph>
                </th>
                <th>
                  <Text.Paragraph color={colors.grayMedium}>
                    Permissões
                  </Text.Paragraph>
                </th>
                {!hideStatusLabel && (
                  <th>
                    <Text.Paragraph color={colors.grayMedium}>
                      Status
                    </Text.Paragraph>
                  </th>
                )}

                <th>
                  <span />
                </th>
              </tr>
            </thead>
            <tbody className="table-content">
              {users?.map((item, index) => {
                return (
                  <UserItem
                    hideStatus={hideStatusLabel}
                    item={item}
                    key={item.id}
                    index={index}
                  />
                );
              })}
            </tbody>
          </table>
          {isFetchingNextPage && (
            <div className="load-more">
              <LoadingSpinner
                color={colors.primary}
                size={30}
                strokeSize={12.5}
              />
            </div>
          )}
        </div>
      </div>
    </Style.Container>
  );
}

export default UserSettingsRowList;
