import { Navigate, useLocation } from 'react-router-dom';
import React, { memo, useEffect } from 'react';
import {
  getIsNewOrg,
  getSelectedUserOrganization,
  getToken,
  setIsNewOrg,
} from '~helpers/device';

import LoadingSpinner from '~components/LoadingSpinner';
import colors from '~styles/colors';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { useGetProcess } from '~reactQuery/queries/kyc/useGetProcess';

interface PrivateRouter {
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouter> = ({ children }): JSX.Element => {
  const organizationSelected = getSelectedUserOrganization();
  const { pathname } = useLocation();
  const isNewOrg = getIsNewOrg();
  const token = getToken();

  const { data: process, isLoading: isLoadingProcess } = useGetProcess(!token);

  const { data: partnerInfos, isLoading: loadingPartnerInfos } = useGetPartner(
    !token
  );

  const userOrganizations = partnerInfos?.data?.userOrganizations;

  const isKycPage = pathname.includes('/kyc');
  const isOrgScreen = pathname.includes('/organizations');
  const isDashScreen = pathname.includes('/dashboard');
  const isFullReceiptScreen = pathname.includes('/full-receipt');

  const loading = isLoadingProcess || loadingPartnerInfos;

  const redirectToKyc =
    (!process?.[0]?.id && !userOrganizations?.[0]?.id) || isNewOrg === 'true';
  const redirectToOrganizations =
    (process?.[0]?.id || userOrganizations?.[0]?.id) && !organizationSelected;

  useEffect(() => {
    if (!isKycPage) setIsNewOrg(false);
  }, [pathname]);

  if (loading) {
    return (
      <div className="loading-container-fullscreen">
        <LoadingSpinner size={60} strokeSize={25} color={colors.primary} />
      </div>
    );
  }

  if (!token) return <Navigate to="/auth" />;

  if (redirectToKyc) {
    return isKycPage ? children : <Navigate to="/kyc" replace />;
  }

  if (redirectToOrganizations) {
    return isOrgScreen ? children : <Navigate to="/organizations" replace />;
  }

  if (organizationSelected) {
    return isDashScreen || isFullReceiptScreen ? (
      children
    ) : (
      <Navigate to="/dashboard" replace />
    );
  }

  return children;
};

export default memo(PrivateRoute);
