import * as Text from '~styles/text';
import {
  AddBeneficiarySteps,
  AddBeneficiaryComponentsProps,
  BeneficiaryPerPix,
} from '~src/common/modals/multiStep/TransferBatch/TransferBatch.types';
import {
  ContainerFormSavedLists,
  ContainerSavedLists,
} from './SavedLists.styles';
import ShowAllSavedLists from './steps/1.ShowAllSavedLists';
import EditList from './steps/2.EditList';
import EditBeneficiary from './steps/3.EditBeneficiary';
import RemoveBeneficiary from './steps/4.RemoveBeneficiary';
import { useEffect, useState } from 'react';
import SearchBeneficiary from '../AddBeneficiary/1.SearchBeneficiary';
import SelectKeyType from '../AddBeneficiary/2.SelectKeyType';
import SelectTypeTransfer from '../AddBeneficiary/3.SelectTypeTransfer';
import AlertModal from '~components/AlertModal/AlertModal.component';
import { When } from 'react-if';
import { BigInput } from '~components/Inputs';
import colors from '~styles/colors';
import { useUpdatePaymentList } from '~reactQuery/mutations/paymentList/useUpdatePaymentList';
import { ErrorToast, SuccessToast } from '~components/Toast';
import { useDeletePaymentList } from '~reactQuery/mutations/paymentList/useDeletePaymentList';
import ValueAddBeneficiary from './steps/5.ValueAddBeneficiary';
import InformationAddBeneficiary from './steps/6.InformationAddBeneficiary';
import { SavedListsSteps, TabSavedListsProps } from './SavedLists.types';

export const SavedLists = (props: TabSavedListsProps) => {
  const [stepSavedLists, setStepSavedLists] =
    useState<SavedListsSteps>('showAllSavedLists');
  const [selectedList, setSelectedList] = useState<any>(null);
  const [selectedBeneficiaryInList, setSelectedBeneficiaryInList] =
    useState<any>(null);
  const [stepAddBeneficiary, setStepAddBeneficiary] =
    useState<AddBeneficiarySteps>('searchBeneficiary');
  const [newNameList, setNewNameList] = useState<string>(
    selectedList?.data?.nameList
  );
  const [errorOnRenameList, setErrorOnRenameList] = useState('');
  const [beneficiaryPerPix, setBeneficiaryPerPix] =
    useState<BeneficiaryPerPix | null>(null);
  const [modalAddBeneficiaryIsVisible, setModalAddBeneficiaryIsVisible] =
    useState(false);
  const [modalRenameListIsVisible, setModalRenameListIsVisible] =
    useState(false);
  const [modalDeleteListIsVisible, setModalDeleteListIsVisible] =
    useState(false);
  const [isLoadingSelectPix, setIsLoadingSelectPix] = useState(false);

  const {
    mutateAsync: updatePaymentList,
    isLoading: loadingUpdatePaymentList,
  } = useUpdatePaymentList();

  const {
    mutateAsync: deletePaymentList,
    isLoading: loadingDeletePaymentList,
  } = useDeletePaymentList();

  const handleRenamePaymentList = async () => {
    setErrorOnRenameList('');
    if (!newNameList) {
      setErrorOnRenameList('É necessário um nome para a lista.');
      return;
    }
    if (newNameList === selectedList?.data?.nameList) {
      setErrorOnRenameList('A lista já possui esse nome.');
      return;
    }

    try {
      const response = await updatePaymentList({
        id: selectedList?.id,
        data: {
          nameList: newNameList,
          listOfBeneficiaries: selectedList?.data?.listOfBeneficiaries,
        },
      });

      if (response) {
        SuccessToast({
          title: 'Nome da lista alterado com sucesso!',
          autoCloseEnable: true,
        });

        setModalRenameListIsVisible(false);
        setStepSavedLists('showAllSavedLists');
      }
    } catch (error) {
      ErrorToast({
        title: 'Não foi possível remover a lista!',
        autoCloseEnable: true,
      });
    }
  };

  const handleDeletePaymentList = async () => {
    try {
      const response = await deletePaymentList({
        id: selectedList?.id,
      });

      if (response) {
        SuccessToast({
          title: 'Lista removida com sucesso!',
          autoCloseEnable: true,
        });

        setModalDeleteListIsVisible(false);
        setStepSavedLists('showAllSavedLists');
      }
    } catch (error) {
      ErrorToast({
        title: 'Não foi possível remover a lista!',
        autoCloseEnable: true,
      });
    }
  };

  const handleAddBeneficiary = async ({
    amount,
    saveForNextTransfer,
    fullName,
  }: {
    amount: string;
    saveForNextTransfer: boolean;
    fullName: string;
  }) => {
    try {
      const listOfBeneficiaries = selectedList?.data?.listOfBeneficiaries;

      const indexByFullName = listOfBeneficiaries?.findIndex(
        (item: any) =>
          item?.fullName === beneficiaryPerPix?.fullName &&
          item?.keyType === beneficiaryPerPix?.keyType
      );

      const indexByHolderName = listOfBeneficiaries?.findIndex(
        (item: any) =>
          item?.holderName === beneficiaryPerPix?.holderName &&
          item?.keyType === beneficiaryPerPix?.keyType
      );

      const objectIndex = indexByFullName || indexByHolderName;

      if (listOfBeneficiaries[objectIndex]) {
        ErrorToast({
          title: 'Já possui beneficiário na lista!',
          autoCloseEnable: true,
        });
        return;
      }

      if (listOfBeneficiaries?.length > 19) {
        ErrorToast({
          title: 'Limite de 20 contatos excedida!',
          autoCloseEnable: true,
        });
        return;
      }

      listOfBeneficiaries.push({
        ...beneficiaryPerPix,
        amount,
        saveForNextTransfer,
        fullName: beneficiaryPerPix?.fullName || fullName || '',
      });

      const response = await updatePaymentList({
        id: selectedList?.id,
        data: {
          nameList: selectedList?.data?.nameList,
          listOfBeneficiaries,
        },
      });

      if (response) {
        SuccessToast({
          title: 'Beneficiário adicionado com sucesso!',
          autoCloseEnable: true,
        });
        setStepAddBeneficiary('searchBeneficiary');
        setBeneficiaryPerPix({});
        setStepSavedLists('showAllSavedLists');
      }
    } catch (error) {
      ErrorToast({
        title: 'Não foi possível adicionar a lista!',
        autoCloseEnable: true,
      });
    }
  };

  const isLoading = loadingDeletePaymentList;

  const handlers = {
    selectedList,
    setSelectedList,
    stepSavedLists,
    setStepSavedLists,
    setModalAddBeneficiaryIsVisible,
    setModalRenameListIsVisible,
    setModalDeleteListIsVisible,
    isLoading,
    beneficiaryPerPix,
    setBeneficiaryPerPix,
    handleAddBeneficiary,
    selectedBeneficiaryInList,
    setSelectedBeneficiaryInList,
    setTabSelected: props?.setTabSelected,
    setModalAttachListIsVisible: props?.setModalAttachListIsVisible,
    setStepTransferBatch: props?.setStepTransferBatch,
  };

  const handleNextSearchBeneficiary = () => {
    if (beneficiaryPerPix?.keyValue) {
      setBeneficiaryPerPix({
        ...beneficiaryPerPix,
        fullName: beneficiaryPerPix?.holderName || '',
      });
      setStepAddBeneficiary('selectKeyType');
    }
  };

  const handleNextSelectKeyType = () => {
    setStepAddBeneficiary('selectTypeTransfer');
  };

  const handleNextSelectTypeTransfer = () => {
    if (
      beneficiaryPerPix?.keyType === 'PHONE' &&
      beneficiaryPerPix?.transferType === 'TED ou Pix Manual'
    ) {
      ErrorToast({
        title: 'Celular não pode ser enviado por TED ou Pix Manual.',
        subtitle: 'Verifique o tipo da sua chave pix.',
        autoCloseEnable: true,
      });
      return;
    }

    if (beneficiaryPerPix?.transferType === 'PIX') {
      setBeneficiaryPerPix({
        ...beneficiaryPerPix,
        keyValue:
          beneficiaryPerPix?.keyType === 'PHONE'
            ? `55${beneficiaryPerPix?.keyValue}`
            : beneficiaryPerPix?.keyValue,
      });
      setStepSavedLists('valueAddBeneficiary');
    }

    if (beneficiaryPerPix?.transferType === 'TED ou Pix Manual') {
      const { e2eId, keyType, keyValue, ...modifyBeneficiary } =
        beneficiaryPerPix;

      setBeneficiaryPerPix(modifyBeneficiary);
      setStepSavedLists('informationAddBeneficiary');
    }

    setStepAddBeneficiary('searchBeneficiary');
    setModalAddBeneficiaryIsVisible(false);
  };

  const addBeneficiaryComponents: AddBeneficiaryComponentsProps = {
    searchBeneficiary: {
      title: 'Adicionar Beneficiário',
      primaryButtonText: 'Fechar',
      secondaryButtonText: 'Adicionar',
      primaryButtonFunction: () => {
        setModalAddBeneficiaryIsVisible(false);
        setBeneficiaryPerPix(null);
        setStepAddBeneficiary('searchBeneficiary');
      },
      secondaryButtonFunction: () => handleNextSearchBeneficiary(),
      disabled: !beneficiaryPerPix,
      component: (
        <SearchBeneficiary
          beneficiaryPerPix={beneficiaryPerPix}
          setBeneficiaryPerPix={setBeneficiaryPerPix}
          key="searchBeneficiary"
        />
      ),
      progressValue: 25,
    },
    selectKeyType: {
      title: `A chave que você está inserindo é um CPF ${'\n'} ou um celular?`,
      primaryButtonText: 'Voltar',
      primaryButtonFunction: () => {
        setBeneficiaryPerPix({});
        setStepAddBeneficiary('searchBeneficiary');
      },
      secondaryButtonText: 'Confirmar',
      secondaryButtonFunction: () => handleNextSelectKeyType(),
      disabled: !beneficiaryPerPix?.keyType || isLoadingSelectPix,
      component: (
        <SelectKeyType
          beneficiaryPerPix={beneficiaryPerPix}
          setBeneficiaryPerPix={setBeneficiaryPerPix}
          isLoadingSelectPix={isLoadingSelectPix}
          setIsLoadingSelectPix={setIsLoadingSelectPix}
          key="selectKeyType"
        />
      ),
      progressValue: 50,
    },
    selectTypeTransfer: {
      title: `Transferindo para ${
        beneficiaryPerPix?.holderDocument || beneficiaryPerPix?.keyValue
      }`,
      primaryButtonText: 'Voltar',
      primaryButtonFunction: () => setStepAddBeneficiary('selectKeyType'),
      secondaryButtonText: 'Confirmar',
      secondaryButtonFunction: () => handleNextSelectTypeTransfer(),
      disabled: !beneficiaryPerPix?.transferType,
      component: (
        <SelectTypeTransfer
          beneficiaryPerPix={beneficiaryPerPix}
          setBeneficiaryPerPix={setBeneficiaryPerPix}
          key="selectTypeTransfer"
        />
      ),
      progressValue: 75,
    },
  };

  const translatorSavedListsSteps: { [key: string]: JSX.Element } = {
    showAllSavedLists: <ShowAllSavedLists {...handlers} />,
    editList: <EditList {...handlers} />,
    editBeneficiary: <EditBeneficiary {...handlers} />,
    removeBeneficiary: <RemoveBeneficiary {...handlers} />,
    valueAddBeneficiary: <ValueAddBeneficiary {...handlers} />,
    informationAddBeneficiary: <InformationAddBeneficiary {...handlers} />,
  };

  useEffect(() => {
    setStepSavedLists('showAllSavedLists');
  }, []);

  return (
    <ContainerSavedLists>
      <ContainerFormSavedLists className="container-form-payment-batch">
        {translatorSavedListsSteps[stepSavedLists]}
      </ContainerFormSavedLists>

      <When condition={modalAddBeneficiaryIsVisible}>
        <AlertModal
          setOpenAlert={() => {
            setModalAddBeneficiaryIsVisible(false);
            setBeneficiaryPerPix(null);
            setStepAddBeneficiary('searchBeneficiary');
          }}
          title={addBeneficiaryComponents[stepAddBeneficiary]?.title}
          primaryButtonText={
            addBeneficiaryComponents[stepAddBeneficiary]?.primaryButtonText
          }
          primaryButtonAction={
            addBeneficiaryComponents[stepAddBeneficiary]?.primaryButtonFunction
          }
          secondaryButtonText={
            addBeneficiaryComponents[stepAddBeneficiary]?.secondaryButtonText
          }
          secondaryButtonAction={
            addBeneficiaryComponents[stepAddBeneficiary]
              ?.secondaryButtonFunction
          }
          secondaryButtonColor="secondary"
          secondaryButtonWidth="137px"
          bodyComponent={
            addBeneficiaryComponents[stepAddBeneficiary]?.component
          }
          noBorderTop
          paddingBottomTitle="0"
          paddingVertical={20}
          marginRight={50}
          disableSecondaryButton={
            addBeneficiaryComponents[stepAddBeneficiary]?.disabled
          }
        />
      </When>

      <When condition={modalRenameListIsVisible}>
        <AlertModal
          setOpenAlert={setModalRenameListIsVisible}
          title="Renomear Lista Salva"
          secondaryButtonWidth="153px"
          noBorderTop
          paddingVertical={0}
          paddingBottomTitle="0"
          secondaryButtonColor="secondary"
          secondaryButtonText="Salvar"
          primaryButtonText="Fechar"
          bodyComponent={
            <>
              <Text.Heading5>
                Nomeie a sua lista de beneficiários salva no campo abaixo:
              </Text.Heading5>
              <BigInput
                defaultValue={selectedList?.data?.nameList}
                className="input-alert-rename-list"
                onChange={(event: any) => setNewNameList(event.target.value)}
              />
              {errorOnRenameList && (
                <Text.Heading5 color={colors.danger}>
                  {errorOnRenameList}
                </Text.Heading5>
              )}
            </>
          }
          primaryButtonAction={() => setModalRenameListIsVisible(false)}
          secondaryButtonAction={() => handleRenamePaymentList()}
          buttonLoading={loadingUpdatePaymentList}
          disableSecondaryButton={loadingUpdatePaymentList}
        />
      </When>

      <When condition={modalDeleteListIsVisible}>
        <AlertModal
          setOpenAlert={setModalDeleteListIsVisible}
          title="Tem certeza que deseja apagar esta lista?"
          secondaryButtonText="Apagar lista"
          secondaryButtonAction={() => handleDeletePaymentList()}
          primaryButtonText="Fechar"
          primaryButtonAction={() => setModalDeleteListIsVisible(false)}
          secondaryButtonWidth="153px"
          bodyComponent={
            <Text.Heading5>
              Ao apagar a lista salva, todos os beneficiários nela cadastrada
              também serão excluídos e essa ação não poderá ser revertida
              posteriormente.
            </Text.Heading5>
          }
          noBorderTop
          paddingVertical={0}
          paddingBottomTitle="0"
          secondaryButtonColor="error"
          buttonLoading={isLoading}
          disableSecondaryButton={isLoading}
        />
      </When>
    </ContainerSavedLists>
  );
};
