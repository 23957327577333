import { useState } from 'react';
import * as Style from './Message.styles';
import { MessageProps } from './Message.types';
import BlueInfoAlert from '~assets/svg/blueInfoAlert';
import {
  getDisabledReportIncome,
  setDisabledReportIncome,
} from '~helpers/device';
import { When } from 'react-if';

const Message = (props: MessageProps) => {
  const reportIncome = getDisabledReportIncome();

  const [isMessageDisabled, setIsMessageDisabled] = useState(
    Boolean(reportIncome)
  );

  const handleDisableMessage = () => {
    setIsMessageDisabled(true);
    setDisabledReportIncome(true);
  };

  return (
    <When condition={!isMessageDisabled}>
      <Style.Container style={props.style} className={props.className}>
        <Style.LeftContainer>
          {props.icon || <BlueInfoAlert className="icon" />}

        <Style.Paragraph className="text1">{props.text}</Style.Paragraph>
      </Style.LeftContainer>

      <Style.Button
        buttonType="inline"
        onClick={() => {
          if (props.buttonText && props.onClick) {
            props.onClick();
          } else {
            handleDisableMessage();
          }
        }}
      >
        <Style.Paragraph>{props.buttonText || 'Fechar'}</Style.Paragraph>
      </Style.Button>
    </Style.Container>
  </When>
  )};

export default Message;
