import CheckSVG from '~assets/svg/check';
import Chip from '~components/Chip';
import * as Text from '~styles/text';
import * as Styles from './PasswordChecker.styles';
import { colors } from '~styles';
import { PasswordCheckerTypes } from './PasswordChecker.types';
import {
  hasCharacterEspecial,
  hasLowerCase,
  hasNumber,
  hasUpper,
} from '~validations/stringsValidate';

const PasswordChecker = ({ password, marginTop }: PasswordCheckerTypes) => {
  const verifyIsDisabled = (isActive: boolean) => {
    if (!isActive) {
      return colors.grayLight;
    }

    return colors.black;
  };

  return (
    <Styles.Container marginTop={marginTop}>
      <Chip
        leftIcon={<CheckSVG fill={verifyIsDisabled(password?.length >= 8)} />}
        borderColor="#F3F4F9"
        bgColor="#F3F4F9"
        textComponent={
          <Text.Small color={verifyIsDisabled(password?.length >= 8)}>
            8 Caracteres
          </Text.Small>
        }
        height={26}
        width={130}
      />
      <Chip
        leftIcon={<CheckSVG fill={verifyIsDisabled(hasUpper(password))} />}
        borderColor="#F3F4F9"
        bgColor="#F3F4F9"
        textComponent={
          <Text.Small color={verifyIsDisabled(hasUpper(password))}>
            Letra maiúscula
          </Text.Small>
        }
        height={26}
        width={140}
      />
      <Chip
        leftIcon={<CheckSVG fill={verifyIsDisabled(hasLowerCase(password))} />}
        borderColor="#F3F4F9"
        bgColor="#F3F4F9"
        textComponent={
          <Text.Small color={verifyIsDisabled(hasLowerCase(password))}>
            Letra minúscula
          </Text.Small>
        }
        height={26}
        width={140}
      />
      <Chip
        leftIcon={<CheckSVG fill={verifyIsDisabled(hasNumber(password))} />}
        borderColor="#F3F4F9"
        bgColor="#F3F4F9"
        textComponent={
          <Text.Small color={verifyIsDisabled(hasNumber(password))}>
            Número
          </Text.Small>
        }
        height={26}
        width={100}
      />
      <Chip
        leftIcon={
          <CheckSVG fill={verifyIsDisabled(hasCharacterEspecial(password))} />
        }
        borderColor="#F3F4F9"
        bgColor="#F3F4F9"
        textComponent={
          <Text.Small color={verifyIsDisabled(hasCharacterEspecial(password))}>
            Caractere especial
          </Text.Small>
        }
        height={26}
        width={160}
      />
    </Styles.Container>
  );
};

export default PasswordChecker;
