import * as Style from './Extract.styles';
import * as Text from '~styles/text';
import * as TextSecondary from '~styles/textSecondary';

import {
  Container,
  EmptyTransactions,
  LoadingSpinner,
} from '~components/index';
import { getSelectedUserOrganization } from '~helpers/device';
import { useCallback, useEffect, useState } from 'react';

import DownloadSVG from '~assets/svg/download';
import colors from '~styles/colors';
import CardActivities from '~components/CardActivities';
import CashOutIcon from '~assets/svg/cashOut';
import CashInIcon from '~assets/svg/cashIn';
import MovementsIcon from '~assets/svg/statement/movements';
import StatementExtract from '~components/StatementExtract/StatementExtract.component';
import { Else, If, Then } from 'react-if';
import { useGetNotifications } from '~reactQuery/queries/notifications/useGetNotifications';
import { useLocation } from 'react-router-dom';
import { GetStatementDataResponse } from '~reactQuery/queries/bank/useGetStatement';
import { formatCurrencyFn } from '~helpers/format/currency';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';

const Extract = () => {
  const location = useLocation();

  const [notificationId, setNotificationId] = useState(0);

  const [cashIn, setCashIn] = useState(0);
  const [cashOut, setCashOut] = useState(0);

  const [amountOfCashInTransactions, setAmountOfCashInTransactions] =
    useState(0);
  const [amountOfCashOutTransactions, setAmountOfCashOutTransactions] =
    useState(0);

  const userOrganizationId = getSelectedUserOrganization();

  const [csv, setCsv] = useState([]);

  const { data: notification, isLoading } = useGetNotifications(
    {
      userOrganizationId: Number(userOrganizationId),
      showNotificationData: true,
      id: notificationId,
      key: 'STATEMENT',
    },
    Boolean(notificationId)
  );

  const startDate = dayjs(notification?.[0]?.period?.startDate).format(
    'DD [de] MMM, YYYY'
  );
  const endDate = dayjs(notification?.[0]?.period?.endDate).format(
    'DD [de] MMM, YYYY'
  );

  const statement = notification?.[0]
    .data as unknown as GetStatementDataResponse['statement'];

  const totalIncomes = formatCurrencyFn('BRL', String(cashIn / 100));
  const totalExits = formatCurrencyFn('BRL', String(cashOut / 100));
  const totalMoviment = formatCurrencyFn(
    'BRL',
    String((cashIn + cashOut) / 100)
  );
  const amountOfTransactions = statement?.length;

  const calculate = useCallback(() => {
    let cashInValue = 0;
    let cashOutValue = 0;

    let cashInAmount = 0;
    let cashOutAmount = 0;

    statement?.forEach((tx) => {
      const { value } = tx.data;

      const isCanceledTx =
        tx.status === 'CANCELED' || tx.status === 'CANCELLED';

      if (tx.direction === 1 && !isCanceledTx) {
        cashInValue += Number(value);
        cashInAmount += 1;
      }

      if (tx.direction === -1 && !isCanceledTx) {
        cashOutValue += Number(value);
        cashOutAmount += 1;
      }
    });

    setCashIn(cashInValue);
    setCashOut(cashOutValue);
    setAmountOfCashInTransactions(cashInAmount);
    setAmountOfCashOutTransactions(cashOutAmount);
  }, [statement]);

  const csvData = [
    [
      'Detalhes da transação',
      'Id da transação',
      'Tipo',
      'Conta Associada',
      'Destino/Origem',
      'Descrição',
      'valor',
    ],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...Array.from(new Set(csv.map(JSON.stringify)), JSON.parse),
  ];

  useEffect(() => {
    const notifId = location.search.split('?notificationId=')[1];

    setNotificationId(Number(notifId));
  }, []);

  useEffect(() => {
    if (isLoading || statement?.length === 0) return;

    calculate();
  }, [calculate, statement, isLoading]);

  const changeStyleBeforePrinting = () => {
    const root = document.getElementById('root');
    const rootHtml = document.getElementById('root-html');
    const rootBody = document.getElementById('root-body');

    const pageWrapper = document.getElementById('page-wrapper');
    const dashContainer = document.getElementById('dash-container');

    if (!root || !rootHtml || !rootBody || !pageWrapper || !dashContainer)
      return;

    root.style.overflowY = 'initial';
    rootHtml.style.overflowY = 'initial';
    rootBody.style.overflowY = 'initial';
    pageWrapper.style.height = 'unset';
    dashContainer.style.overflowY = 'initial';

    setTimeout(() => {
      root.style.overflowY = 'hidden';
      rootHtml.style.overflowY = 'hidden';
      rootBody.style.overflowY = 'hidden';
      pageWrapper.style.height = 'calc(100% - 75px';
      dashContainer.style.overflowY = 'auto';
    }, 1000);
  };

  const handleClickToDownload = () => {
    changeStyleBeforePrinting();
    window.print();
  };

  return (
    <Style.Container>
      <Container>
        <Style.Content>
          <If condition={isLoading}>
            <Then>
              <div className="container-loading">
                <LoadingSpinner
                  color={colors.primary}
                  size={60}
                  strokeSize={25}
                />
              </div>
            </Then>

            <Else>
              <Text.Heading1Medium>Extrato Detalhado</Text.Heading1Medium>

              <TextSecondary.Heading4Regular className="subtitle-activities">
                {startDate} até {endDate}
              </TextSecondary.Heading4Regular>

              <div className="filter-list">
                <div className="gap-10 center-all">
                  <div
                    className="button-v2"
                    data-hide-for-print="hidden-for-print"
                    onClick={handleClickToDownload}
                  >
                    <DownloadSVG />
                    <Text.Heading5>Baixar extrato em PDF</Text.Heading5>
                  </div>

                  <CSVLink
                    data={csvData}
                    onClick={() => {
                      if (csv.length === 0) {
                        return false;
                      }
                      return true;
                    }}
                    filename={`relatorio-completo-${dayjs().format(
                      'DD-MM-YYYY'
                    )}.csv`}
                  >
                    <div
                      className="button-v2"
                      data-hide-for-print="hidden-for-print"
                    >
                      <DownloadSVG />
                      <Text.Heading5>Baixar extrato em CSV</Text.Heading5>
                    </div>
                  </CSVLink>
                </div>
              </div>

              <div className="container-cards">
                {/* <CardActivities
                  title="Saldo inicial"
                  value="R$ 24.312,44"
                  icon={<CashIcon />}
                /> */}

                <CardActivities
                  title="Total de saídas"
                  value={totalExits}
                  description={String(amountOfCashOutTransactions)}
                  icon={<CashOutIcon fill={colors.redMatte} />}
                />

                <CardActivities
                  title="Total de entradas"
                  value={totalIncomes}
                  description={String(amountOfCashInTransactions)}
                  icon={<CashInIcon fill={colors.greenMatte} />}
                />

                <CardActivities
                  title="Total movimentado"
                  value={totalMoviment}
                  description={String(amountOfTransactions)}
                  icon={<MovementsIcon />}
                />
                {/* 
                <CardActivities
                  title="Saldo final"
                  value="R$ 8.204,95"
                  icon={<CashIcon />}
                /> */}
              </div>

              {statement?.length < 0 ? (
                <div className="empty-transactions">
                  <EmptyTransactions noTransactionButton />
                </div>
              ) : (
                <StatementExtract statementList={statement} setCsv={setCsv} />
              )}
            </Else>
          </If>
        </Style.Content>
      </Container>
    </Style.Container>
  );
};

export default Extract;
