import * as React from 'react';

import { SVGProps } from 'react';

const KcsCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m18.374 18.446 5.515 5.596 3.48-3.532a1.558 1.558 0 0 1 2.167.058c.592.6.617 1.567.057 2.198L25 27.427a1.57 1.57 0 0 1-2.224 0l-6.627-6.725V24.7c0 .882-.705 1.598-1.575 1.598S13 25.58 13 24.699V12.19c0-.883.705-1.598 1.575-1.598.869 0 1.574.715 1.574 1.597v3.997l6.626-6.723a1.569 1.569 0 0 1 2.224 0l4.597 4.66c.56.632.535 1.598-.057 2.198-.592.601-1.545.627-2.167.058l-3.48-3.531-5.518 5.6Zm5.517-1.599c-.637 0-1.212.39-1.457.987a1.616 1.616 0 0 0 .342 1.743 1.56 1.56 0 0 0 1.717.347 1.6 1.6 0 0 0 .974-1.478 1.61 1.61 0 0 0-.461-1.13 1.565 1.565 0 0 0-1.115-.47v.001Z"
      fill="#23AF91"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default KcsCoinSVG;
