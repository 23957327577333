import * as Style from './Wallet.styles';
import * as Text from '~styles/text';

import React, { useState } from 'react';

import WalletItem from './WalletItem.component';
import { WalletProps } from './WalletRowList.types';
import { WalletType } from '~reactQuery/types/wallets.types';
import colors from '~styles/colors';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useCurrenciesVariations } from '~reactQuery/queries/wallet/useCurrenciesVariations';

function Wallet({
  walletList,
  withSearch,
  title,
  onlyNoFunds,
  variationsList,
  loadingVariations,
  handleOpenWallet,
}: WalletProps) {
  const [searchText, setSearchText] = useState('');

  return (
    <>
      {title ? (
        <Text.Heading2 marginBottom={20} marginTop={40}>
          {title}
        </Text.Heading2>
      ) : null}
      <Style.Container>
        <div className="rounded-div">
          {withSearch && (
            <div className="">
              <input
                onChange={(e) => setSearchText(e.target.value)}
                type="text"
                className=""
                placeholder="Buscar Wallet"
              />
            </div>
          )}
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th>
                  <Text.Paragraph color={colors.grayMedium}>
                    Nome da Carteira
                  </Text.Paragraph>
                </th>
                <th className="hide">
                  <Text.Paragraph color={colors.grayMedium}>
                    Moeda
                  </Text.Paragraph>
                </th>
                <th className="hide">
                  <Text.Paragraph color={colors.grayMedium}>
                    Mercado
                  </Text.Paragraph>
                </th>
                <th className="hide">
                  <Text.Paragraph color={colors.grayMedium}>
                    Var 24h.
                  </Text.Paragraph>
                </th>
                <th className="mobile-hide">
                  <Text.Paragraph color={colors.grayMedium}>
                    Cotação
                  </Text.Paragraph>
                </th>
                <th className="mobile-hide">
                  <Text.Paragraph color={colors.grayMedium}>
                    Preferências
                  </Text.Paragraph>
                </th>
                <th className="padding-left-20">
                  <Text.Paragraph color={colors.grayMedium}>
                    Ações
                  </Text.Paragraph>
                </th>
                <th>
                  <Text.Paragraph color={colors.grayMedium}>
                    Mais
                  </Text.Paragraph>
                </th>
              </tr>
            </thead>
            <tbody>
              {walletList
                ?.filter((value: WalletType) => {
                  if (searchText === '') {
                    return value;
                  }
                  if (
                    value.walletName
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  ) {
                    return value;
                  }
                  return null;
                })
                .sort((a: WalletType, b: WalletType) =>
                  a.currency.id > b.currency.id ? 1 : -1
                )
                .map((wallet: WalletType) => {
                  if (onlyNoFunds && wallet.funds > 0) return null;
                  return (
                    <WalletItem
                      openWallet={() => handleOpenWallet(wallet)}
                      variationsList={variationsList?.data}
                      loadingVariations={loadingVariations}
                      key={wallet.walletAddress}
                      wallet={wallet}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
      </Style.Container>
    </>
  );
}

export default Wallet;
