export const ConvertIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { stroke = '#272937', height = 18, width = 16 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 16 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7071 1.29475L12 0.587646L10.5858 2.00186L11.2929 2.70897L12.5858 4.00186H1C0.447716 4.00186 2.38419e-07 4.44958 2.38419e-07 5.00186V7.00186V8.00186H2V7.00186V6.00186H12.5858L11.2929 7.29475L10.5858 8.00186L12 9.41607L12.7071 8.70897L15.7071 5.70897C16.0976 5.31844 16.0976 4.68528 15.7071 4.29475L12.7071 1.29475ZM3.29289 16.709L4 17.4161L5.41421 16.0019L4.70711 15.2948L3.41421 14.0019H15C15.5523 14.0019 16 13.5541 16 13.0019V11.0019V10.0019H14V11.0019V12.0019H3.41421L4.70711 10.709L5.41421 10.0019L4 8.58765L3.29289 9.29475L0.292894 12.2948C-0.0976312 12.6853 -0.0976312 13.3184 0.292894 13.709L3.29289 16.709Z"
        fill={stroke}
      />
    </svg>
  );
};

export default ConvertIcon;
