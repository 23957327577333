import { useQueryClient } from 'react-query';
import { gql, request, useMutation } from '~reactQuery';

interface DeleteNotificationInput {
  userOrganizationId: number;
  notificationId: number;
}

export interface DeleteNotificationResponse {
  deleteNotifications: {
    data: boolean;
  };
  messages: Array<string>;
  isValid: boolean;
}

export function useDeleteNotification() {
  const queryClient = useQueryClient();
  const mutation = gql`
    mutation DeleteNotifications($params: NotificationsDeleteInput!) {
      deleteNotifications(params: $params) {
        data
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: DeleteNotificationInput) => {
    const response = await request<DeleteNotificationResponse>(mutation, {
      params,
    });
    return response.deleteNotifications;
  };

  return useMutation('useDeleteNotification', fetch, {
    onSuccess: () => {
      queryClient.invalidateQueries('getNotifications');
    },
  });
}
