import * as Text from '~styles/text';

import { CheckSelectSmall, InputForm } from '~components/Inputs';

import { CriptoWithdrawStepsProps } from '~src/common/modals/multiStep/Transfer/Transfer.types';
import { CurrencyTypes } from '~src/@types/currency.types';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { WalletType } from '~reactQuery/types/wallets.types';
import { coinList } from '~data/wallets';
import { getWalletIcon } from '~helpers/format/currency';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

const SelectWalletToWithdraw = ({
  setCriptoWithdrawStep,
}: CriptoWithdrawStepsProps) => {
  const goBack = () => setCriptoWithdrawStep('chooseTransferMethod');
  const [search, setSearch] = useState('');
  const [selectedWallet, setSelectedWallet] = useState<WalletType>();

  const { setValue } = useFormContext();

  const wallets = coinList.map((w) => ({
    id: w.id,
    owner: 0,
    walletName: w.title,
    walletCurrency: '',
    isPublic: 1,
    isOwnerInfoPublic: 1,
    isFavorite: 0,
    enabled: 1,
    ordering: 1,
    walletAddress: w.name,
    funds: 0,
    createdAt: '',
    updatedAt: '',
    priceInPrefCurrency: 0,
    currency: {
      id: w.id,
      precision: 1,
      isoCode: w.name.toUpperCase() as CurrencyTypes,
      name: w.title,
      type: 'crypto',
      symbol: '',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
    visible: w.visible,
  }));

  const filteredWallets = wallets.filter((w) => {
    const query = search.toLowerCase();
    const walletIsoCode = w.currency.isoCode.toLowerCase();
    const walletName = w.currency.name.toLowerCase();

    if (walletIsoCode.includes(query) || walletName.includes(query))
      return true;

    return false;
  });

  const handleSelectWallet = (w: WalletType) => {
    setSelectedWallet(w);
  };

  const handleGoToNextStep = () => {
    setValue('selectedWallet', selectedWallet);
    setCriptoWithdrawStep('chooseTransferMethod');
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Selecione uma moeda para saque"
        backButtonAction={goBack}
      />
      <div className="cripto-withdraw-container-step">
        <Text.Paragraph marginBottom={20}>
          Escolha uma moeda ou um token para realizar o saque
        </Text.Paragraph>
        <InputForm
          value={search}
          placeholder="Pesquisar carteira"
          onChange={(e) => setSearch(e.target.value)}
        />

        <Text.Heading3 marginTop={20} marginBottom={20}>
          Criptomoedas
        </Text.Heading3>

        <div className="wallet-list">
          {filteredWallets.map((w) => {
            if (!w.visible) return null;

            return (
              <CheckSelectSmall
                text=""
                iconType="component"
                key={String(w.walletAddress)}
                title={w.walletName}
                icon={getWalletIcon(w.currency.isoCode as CurrencyTypes)}
                onClick={() => handleSelectWallet(w)}
                selected={w.walletAddress === selectedWallet?.walletAddress}
              />
            );
          })}
        </div>
        <ModalMultiStepBottom
          singleButtonText="Próximo"
          singleButtonWidth="100%"
          singleButtonAlignment="flex-end"
          disableSingleButton={!selectedWallet}
          singleButtonAction={handleGoToNextStep}
        />
      </div>
    </>
  );
};

export default SelectWalletToWithdraw;
