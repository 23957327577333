import * as Text from '~styles/text';
import * as Style from './AddWalletButton.styles';
import { AddWalletButtonProps } from './AddWalletButton.types';
import PlusSVG from '~assets/svg/plus';

const AddWalletButton = (props: AddWalletButtonProps) => {
  const { onClick, width } = props;

  return (
    <Style.Container width={width} onClick={onClick}>
      <div className="circle">
        <PlusSVG />
      </div>
      <Text.Paragraph marginTop={10}>Criar Carteira</Text.Paragraph>
    </Style.Container>
  );
};

export default AddWalletButton;
