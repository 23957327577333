import colors from '~styles/colors';
import { FlattenInterpolation, ThemeProps, css } from 'styled-components';

import { TooltipVariant } from './Tooltip.types';

export const variants = [
  'attention',
  'critical',
  'informative',
  'success',
  'default',
] as TooltipVariant[];

const tooltipTokens = {
  attention: {
    color: '#141414',
    backgroundColor: `${colors.warning}D9`,
  },
  critical: {
    color: colors.white,
    backgroundColor: `${colors.danger}D9`,
  },
  default: {
    color: colors.white,
    backgroundColor: `${colors.black}D9`,
  },
  informative: {
    color: colors.white,
    backgroundColor: `${colors.primary}D9`,
  },
  success: {
    color: colors.white,
    backgroundColor: `${colors.success}D9`,
  },
};

const tooltipVariantIsValid = (variant: unknown): variant is TooltipVariant => {
  return variants.includes(variant as TooltipVariant);
};

export const getTooltipVariant = (
  variant: TooltipVariant
): FlattenInterpolation<ThemeProps<unknown>> => {
  const variantToken = tooltipVariantIsValid(variant) ? variant : 'informative';

  return css`
    color: ${tooltipTokens[variantToken].color};
    background-color: ${tooltipTokens[variantToken].backgroundColor};
  `;
};
