const CloseRoundedSVG = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.00403C7.58172 4.00403 4 7.58575 4 12.004C4 16.4223 7.58172 20.004 12 20.004C16.4183 20.004 20 16.4223 20 12.004C20 7.58575 16.4183 4.00403 12 4.00403ZM2 12.004C2 6.48118 6.47715 2.00403 12 2.00403C17.5228 2.00403 22 6.48118 22 12.004C22 17.5269 17.5228 22.004 12 22.004C6.47715 22.004 2 17.5269 2 12.004ZM10.5858 12.0039L7.29322 8.71138L8.70743 7.29717L12 10.5897L15.2926 7.29717L16.7068 8.71138L13.4142 12.0039L16.7069 15.2967L15.2927 16.7109L12 13.4182L8.70727 16.7109L7.29305 15.2967L10.5858 12.0039Z"
      fill="#C3C6DB"
    />
  </svg>
);

export default CloseRoundedSVG;
