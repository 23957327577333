import { screen } from '~styles/metrics';

import styled from 'styled-components';
import colors from '~styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .container-loading {
    width: 100%;
    min-height: calc(100vh - 630px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty-transactions {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: calc(100vh - 640px);
    background-color: ${colors.white};
    border-radius: 10px;
  }

  @media ${screen.tablet} {
    .scroll {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const ContainerTitleTransactions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
`;
