import { SVGProps } from 'react';

const ShieldSecuritySVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '139'}
    height={props.height || '131'}
    viewBox="0 0 139 131"
    fill="none"
    {...props}
  >
    <g id="Ativo 2 1" clipPath="url(#clip0_20801_3063)">
      <g id="Camada 1">
        <g id="Group">
          <path
            id="Vector"
            d="M26.7079 50.5019V65.4061H0.00201416V50.5019C0.00201416 43.7131 5.06783 38.1083 11.6249 37.2621C12.189 37.1895 12.7671 37.1504 13.3535 37.1504C17.0398 37.1504 20.377 38.6444 22.7954 41.0601C25.211 43.4785 26.7051 46.8157 26.7051 50.5019H26.7079Z"
            fill="#3F4251"
          />
          <path
            id="Vector_2"
            d="M82.7418 37.1479L75.3833 65.2836L71.4512 80.3107L63.1879 111.901C63.1879 116.936 61.1465 121.499 57.8456 124.797C54.4972 128.148 49.8531 130.198 44.7314 130.139C40.0482 130.086 35.809 128.241 32.6198 125.269C28.9782 121.876 26.705 117.014 26.705 111.669V50.5023C26.705 46.816 25.211 43.4788 22.7954 41.0604C20.377 38.6448 17.0398 37.1507 13.3535 37.1507H82.7418V37.1479Z"
            fill="#CED6EF"
          />
          <path
            id="Vector_3"
            d="M71.4513 80.3106L63.1879 111.901C63.1879 116.936 61.1465 121.499 57.8456 124.797C54.4973 128.148 49.8531 130.198 44.7315 130.139C40.0482 130.086 35.809 128.24 32.6199 125.269L64.5172 48.7148L71.4513 80.3134V80.3106Z"
            fill="#F3F4F9"
          />
          <g id="Group_2">
            <path
              id="Vector_4"
              d="M76.7349 82.1201C56.7202 82.1201 40.4951 59.9998 40.4951 32.7158V13.3071L76.7349 0.00585938V82.1201Z"
              fill="#0F90FF"
            />
            <path
              id="Vector_5"
              d="M76.869 82.1201C96.8837 82.1201 113.109 59.9998 113.109 32.7158V13.3071L76.869 0.00585938V82.1201Z"
              fill="#006FF4"
            />
            <path
              id="Vector_6"
              d="M107.188 17.619V34.0117C107.188 56.9838 93.5745 75.619 76.7433 75.7391C76.7182 75.7419 76.6903 75.7391 76.6623 75.7391C76.6344 75.7391 76.6065 75.7419 76.5813 75.7419C73.4341 75.7167 70.4013 75.0465 67.5472 73.8233C55.1368 68.5006 46.1362 52.6888 46.1362 34.0117V17.619L76.5813 6.44571V6.38428L76.6623 6.415L76.7433 6.38428V6.44571L93.1165 12.4554L107.188 17.619Z"
              fill="#CED6EF"
            />
            <path
              id="Vector_7"
              d="M107.188 17.6191V34.0118C107.188 56.9839 93.5744 75.6191 76.7433 75.7392C76.7182 75.742 76.6902 75.7392 76.6623 75.7392C76.6344 75.7392 76.6064 75.742 76.5813 75.742C73.434 75.7169 70.4012 75.0466 67.5472 73.8234L93.1164 12.4556L107.188 17.6191Z"
              fill="#F3F4F9"
            />
          </g>
          <path
            id="Vector_8"
            d="M99.6735 55.3894V110.789C99.6735 118.181 95.5293 124.605 89.4358 127.863C86.7185 129.318 83.6159 130.139 80.3206 130.139H64.4865C64.4865 130.139 64.4697 130.139 64.4613 130.139H45.1866C50.1296 130.078 54.6006 128.048 57.8484 124.797C61.1521 121.496 63.1907 116.936 63.1907 111.901V55.6183C63.1907 45.5593 71.2362 37.2233 81.2925 37.1479C91.4325 37.0753 99.6707 45.2689 99.6707 55.3894H99.6735Z"
            fill="#0264DC"
          />
          <path
            id="Vector_9"
            d="M132.579 50.6304V78.8778H96.0962V50.5019C96.0962 46.8157 94.6021 43.4785 92.1865 41.0601C89.7681 38.6444 86.4309 37.1504 82.7446 37.1504H119.099C126.547 37.1504 132.582 43.1852 132.582 50.6332L132.579 50.6304Z"
            fill="#CED6EF"
          />
          <path
            id="Vector_10"
            d="M121.847 107.608C121.847 120.052 111.757 130.142 99.3132 130.142C95.7694 130.142 92.4183 129.324 89.4357 127.866C95.5292 124.607 99.6735 118.184 99.6735 110.792V85.083C111.953 85.2701 121.85 95.2873 121.85 107.611L121.847 107.608Z"
            fill="#F3BA2F"
          />
          <path
            id="Vector_11"
            d="M99.6735 85.0803V110.789C99.6735 118.181 95.5293 124.604 89.4358 127.863C81.9404 124.202 76.7824 116.508 76.7824 107.608C76.7824 95.1645 86.8722 85.0747 99.3161 85.0747C99.4362 85.0747 99.5535 85.0747 99.6735 85.0803Z"
            fill="#3F4251"
          />
          <g id="Group_3">
            <path
              id="Vector_12"
              d="M104.323 48.7119H138.421"
              stroke="#3F4251"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_13"
              d="M104.323 58.7261H138.421"
              stroke="#3F4251"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_14"
              d="M104.323 68.7432H138.421"
              stroke="#3F4251"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <path
            id="Vector_15"
            d="M26.7079 50.5019V65.4061H0.00201416V65.1632L11.6249 37.2621C12.189 37.1895 12.7671 37.1504 13.3535 37.1504C17.0398 37.1504 20.377 38.6444 22.7954 41.0601C25.211 43.4785 26.7051 46.8157 26.7051 50.5019H26.7079Z"
            fill="#3F4251"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_20801_3063">
        <rect
          width="139"
          height="130.136"
          fill="white"
          transform="translate(0.00201416 0.00585938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ShieldSecuritySVG;
