import * as Text from '~styles/text';
import { Dispatch, SetStateAction, useEffect } from 'react';
import DangerError from '~assets/images/icons/danger.svg';
import { GenericButton } from '~components';
import { PossiblesSteps } from '../ForgotPassword.types';
import { breakpoints } from '~styles/metrics';
import { colors } from '~styles';
import useTimer from '~hooks/useTimer';
import useWindowDimensions from '~hooks/windowDimension';
import { handleOnClickHelp } from '~helpers/generic';

export const Failed = ({
  setStep,
}: {
  setStep: Dispatch<SetStateAction<PossiblesSteps>>;
}) => {
  const { width } = useWindowDimensions();

  const { counting, timeLeft, restartTimer } = useTimer(60);

  useEffect(() => {
    restartTimer();
  }, []);

  const handleTryAgain = () => {
    setStep('reset');
  };

  return (
    <>
      <div className="medium-icon">
        <DangerError />
      </div>
      <Text.Heading1Medium marginTop={20} marginBottom={30}>
        Atualização de senha não foi concluída
      </Text.Heading1Medium>

      <Text.Heading5
        color={colors.black}
        marginBottom={20}
        lineHeight={140}
        width={breakpoints.desktop < width ? '100%' : ''}
      >
        Opa! Alguma informação não está de acordo com o que temos em nosso banco
        de dados. Talvez se trate apenas de algum erro de digitação, então tente
        realizar a atualização de senha novamente.
      </Text.Heading5>

      <Text.Heading5
        color={colors.black}
        marginBottom={30}
        lineHeight={140}
        width={breakpoints.desktop < width ? '100%' : ''}
      >
        E caso você exceda os limites de tentativa ou esteja precisando de ajuda
        para concluir essa etapa, sinta-se a vontade para entrar em contato com
        nosso suporte.
      </Text.Heading5>

      <div className="buttons-wrapper">
        <GenericButton
          buttonType="inline"
          width="auto"
          onClick={handleTryAgain}
          disabled={counting}
        >
          Tentar novamente {counting ? `${`( ${timeLeft} )`}` : null}
        </GenericButton>

        <GenericButton
          buttonType="default"
          width="auto"
          onClick={handleOnClickHelp}
        >
          Precisa de ajuda?
        </GenericButton>
      </div>
    </>
  );
};
