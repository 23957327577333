import * as React from 'react';

import { SVGProps } from 'react';

const MoneyIconSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '14'}
    height={props.height || '10'}
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.752 1.01002V0.26001L13.0019 0.260023L1.00194 0.260235L0.251953 0.260248V1.01024V9.01023V9.76023H1.00195H13.002H13.752V9.01023V1.01002ZM1.75195 8.26023V1.76022L12.252 1.76004V8.26023H1.75195ZM5.00195 5.01002C5.00195 3.90545 5.89738 3.01002 7.00195 3.01002C8.10652 3.01002 9.00195 3.90545 9.00195 5.01002C9.00195 6.11459 8.10652 7.01002 7.00195 7.01002C5.89738 7.01002 5.00195 6.11459 5.00195 5.01002Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default MoneyIconSettings;
