import { colors } from '~styles';
import styled from 'styled-components';

export const RecoveryPinContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .divider {
    border-width: 0.5px 0px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.05);
  }

  .recovery-pin-body {
    width: 100%;
    margin-top: 25px;
    padding: 0 38px;

    .justify-items-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .double-button-one-container {
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
  }

  .body-container-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 20px;
    border: 2px solid ${colors.gray};
    border-radius: 15px;
  }
`;
