import * as Text from '~styles/text';

import { CreatePixStatusPageProps } from '../CreatePixKeys.types';
import EndStepStatus from '~components/EndStepStatus';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import RandomKeySVG from '~assets/images/icons/pix/randomKey';
import TransferIcon from '~assets/images/icons/pix/transfer.svg';
import colors from '~styles/colors';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

function CreatePixStatus(props: CreatePixStatusPageProps) {
  const { statusOfCreation, pixKey } = props;

  function makeOneTransfer() {}
  function createAnotherKey() {}

  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  const infosOfStatus = {
    NOT_OK: {
      icon: <EndStepStatus animType="error" />,
      title: 'Sua chave não pode ser cadastrada',
      singleButtonText: 'Fechar',
      singleButtonAction: () => setOpenModalMultiStep(false),
      doubleButtonTextOne: 'Cadastrar outra chave',
      buttonIcon: <RandomKeySVG fill={colors.black} />,
      doubleButtonOneAction: createAnotherKey,
      description:
        'Identificamos que essa chave está atrelada a outra instituição bancária. Para utilizar será necessário remover da mesma e gerar uma nova chave no Capitual.',
    },
    OK: {
      icon: <EndStepStatus animType="success" />,
      title: 'Chave Pix cadastrada!',
      singleButtonText: 'Fechar',
      singleButtonAction: () => setOpenModalMultiStep(false),
      doubleButtonTextOne: '',
      buttonIcon: <TransferIcon />,
      // doubleButtonOneAction: makeOneTransfer,
      description: '',
    },
  };

  if (!statusOfCreation) return <p>give the statusOfCreation ex: OK</p>;

  return (
    <>
      <ModalMultiStepHeader />
      <div className="create-pix-body">
        <div className="justify-items-center">
          {infosOfStatus[statusOfCreation].icon}
          <Text.Heading1Bold
            textAlign="center"
            marginBottom={25}
            marginTop={25}
          >
            {infosOfStatus[statusOfCreation].title}
          </Text.Heading1Bold>

          {statusOfCreation === 'OK' ? (
            <Text.Heading4 textAlign="center" display="inline-block">
              Sua Chave Pix <strong>{pixKey}</strong> foi cadastrada. Agora você
              pode usá-la para receber e fazer novas transações bancárias.
            </Text.Heading4>
          ) : (
            <Text.Heading4 textAlign="center" display="inline-block">
              {infosOfStatus[statusOfCreation].description}
            </Text.Heading4>
          )}
        </div>
      </div>
      <ModalMultiStepBottom
        singleButtonText={infosOfStatus[statusOfCreation].singleButtonText}
        singleButtonWidth="1"
        singleButtonAction={infosOfStatus[statusOfCreation].singleButtonAction}
        // doubleButtonTextOne={(
        //   <div className="double-button-one-container">
        //     {infosOfStatus[statusOfCreation].buttonIcon}
        //     <Text.Heading5>
        //       {infosOfStatus[statusOfCreation].doubleButtonTextOne}
        //     </Text.Heading5>
        //   </div>
        // )}
      />
    </>
  );
}

export default CreatePixStatus;
