import * as React from 'react';
import { SVGProps } from 'react';

const WalletUsdSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.62 23.343v-1.167a4.142 4.142 0 0 1-2.719-.947 3.131 3.131 0 0 1-.976-2.42h2.51c-.008.398.1.79.311 1.126a1.023 1.023 0 0 0 .819.365v-2.26a6.21 6.21 0 0 1-2.689-1.13 2.393 2.393 0 0 1-.82-1.903 2.61 2.61 0 0 1 .99-2.123 4.228 4.228 0 0 1 2.666-.84v-.889h1.092v.874c.904-.03 1.79.264 2.5.826a2.916 2.916 0 0 1 .944 2.192h-2.394a1.525 1.525 0 0 0-.303-.918 1.003 1.003 0 0 0-.777-.329v2.079a6.594 6.594 0 0 1 2.803 1.218 2.539 2.539 0 0 1 .825 1.993 2.722 2.722 0 0 1-.937 2.224 4.813 4.813 0 0 1-2.807.88v1.154l-1.038-.005Zm.166-7.7v-1.871h-.055a1.45 1.45 0 0 0-.894.226.81.81 0 0 0-.297.685.684.684 0 0 0 .27.566c.295.192.624.325.969.391l.007.002Zm.81 4.668c.347.023.693-.058.994-.233a.786.786 0 0 0 .338-.695.774.774 0 0 0-.286-.633 3.01 3.01 0 0 0-1.047-.453v2.014ZM11.928 12.09v6.293a1.467 1.467 0 0 1-1.461 1.465 1.46 1.46 0 0 1-1.462-1.465V12.09h-2.41v6.293c0 1.029.408 2.015 1.134 2.743a3.863 3.863 0 0 0 5.471 0 3.885 3.885 0 0 0 1.134-2.744V12.09h-2.406Z"
      fill="#639C55"
    />
  </svg>
);

export default WalletUsdSVG;
