import * as React from 'react';

const TrxCoinSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={44}
    height={36}
    viewBox="0 0 44 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={1} y={1} width={42} height={34} rx={10} fill="white" />
    <path
      d="M31.8555 16.0145C31.7226 15.3691 31.5262 14.7385 31.2691 14.1318C31.0157 13.5342 30.7057 12.9623 30.3433 12.4238C29.9839 11.8918 29.5739 11.3958 29.1192 10.9426C28.6657 10.4878 28.1694 10.0779 27.6372 9.71852C27.0979 9.35791 26.5264 9.04798 25.93 8.79268C24.6966 8.26958 23.3706 8 22.0309 8C20.6912 8 19.3652 8.26958 18.1318 8.79268C16.9392 9.29573 15.8559 10.0263 14.9426 10.9434C14.4879 11.3963 14.0779 11.8921 13.7185 12.4238C13.3579 12.9633 13.0479 13.5351 12.7927 14.1318C12.2696 15.3652 12 16.6912 12 18.0309C12 19.3706 12.2696 20.6966 12.7927 21.93C13.0397 22.5265 13.3589 23.0919 13.7185 23.6372C14.0779 24.1694 14.4878 24.6657 14.9426 25.1192C15.396 25.574 15.8923 25.9839 16.4246 26.3433C16.9589 26.7028 17.5353 27.012 18.1318 27.2691C19.3652 27.7922 20.6912 28.0618 22.0309 28.0618C23.3706 28.0618 24.6966 27.7922 25.93 27.2691C26.5265 27.0221 27.0919 26.7028 27.6372 26.3433C28.1694 25.9839 28.6657 25.574 29.1192 25.1192C29.574 24.6657 29.9839 24.1694 30.3433 23.6372C30.7028 23.1028 31.012 22.5265 31.2691 21.93C32.0605 20.0634 32.2651 18.0002 31.8555 16.0145Z"
      fill="#FF060A"
    />
    <g clipPath="url(#clip0_1_1581)">
      <path
        d="M28.5023 15.9162C27.8929 15.3535 27.0499 14.4943 26.3634 13.8849L26.3227 13.8565C26.2551 13.8022 26.1789 13.7596 26.0973 13.7306C24.4418 13.4218 16.7373 11.9816 16.5869 11.9999C16.5448 12.0058 16.5046 12.0211 16.4691 12.0446L16.4305 12.0751C16.383 12.1233 16.3469 12.1817 16.3249 12.2457L16.3148 12.2721V12.4163V12.4387C17.1821 14.8538 20.6068 22.7656 21.2812 24.6221C21.3218 24.7481 21.399 24.9877 21.5432 24.9999H21.5757C21.6529 24.9999 21.9819 24.5652 21.9819 24.5652C21.9819 24.5652 27.8644 17.4315 28.4596 16.6718C28.5366 16.5782 28.6047 16.4776 28.6627 16.3712C28.6776 16.2879 28.6706 16.2023 28.6424 16.1225C28.6143 16.0428 28.566 15.9717 28.5023 15.9162ZM23.4912 16.747L26.0018 14.6649L27.4744 16.0218L23.4912 16.747ZM22.5162 16.6109L18.1937 13.0684L25.1873 14.3582L22.5162 16.6109ZM22.9062 17.5391L27.3302 16.8262L22.2724 22.9199L22.9062 17.5391ZM17.6066 13.4218L22.1546 17.2812L21.4965 22.924L17.6066 13.4218Z"
        fill="white"
      />
    </g>
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_1_1581">
        <rect
          width={13}
          height={13}
          fill="white"
          transform="translate(16 12)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default TrxCoinSVG;
