import * as Text from '~styles/text';
import { GenericRow, RoundButtonIcon } from '~components';
import colors from '~styles/colors';
import CopySVG from '~assets/svg/copy';
import { formatCurrencyFn } from '~helpers/format/currency';
import dayjs from 'dayjs';
import BillIcon from '~assets/svg/statement/billIcon';
import { copyText } from '~helpers/messages';
import {
  formatDataShowMonth,
  removeNumbersFromString,
} from '~validations/stringsValidate';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { TransactionDetailsTypes } from '../../Activities.types';

const DepositBill = ({
  transactionDetails,
}: {
  transactionDetails: TransactionDetailsTypes;
}) => {
  const userOrganizationId = getSelectedUserOrganization();

  const { data: capAccountInfo } = useGetCapAccountInfo({
    userOrganizationId: Number(userOrganizationId),
  });

  return (
    <>
      <div className="detailsRow">
        <Text.Paragraph>Valor</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph color={colors.grayMedium}>
            {typeof transactionDetails?.data?.value === 'string' &&
              formatCurrencyFn(
                'BRL',
                String(Number(transactionDetails.data.value) / 100)
              )}
          </Text.Paragraph>
        </div>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Agência</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>
          {transactionDetails?.data?.bankBranch}
        </Text.Paragraph>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Conta</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>
          {transactionDetails?.data?.bankAccount}
        </Text.Paragraph>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Data e Horário</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>
          {formatDataShowMonth(transactionDetails?.timestamp)} •{' '}
          {dayjs(transactionDetails?.timestamp).format('H:mm')}
        </Text.Paragraph>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Descrição</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph color={colors.grayMedium}>
            {transactionDetails?.data?.description || '----'}
          </Text.Paragraph>
        </div>
      </div>

      <div className="detailsDivider">
        <div className="icon">
          <BillIcon width={18} height={16} />
        </div>
        <Text.Paragraph>Documento</Text.Paragraph>
        <hr />
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Favorecido</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph color={colors.grayMedium}>
            {removeNumbersFromString(String(capAccountInfo?.name))}
          </Text.Paragraph>
        </div>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Vencimento</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>
          {formatDataShowMonth(transactionDetails?.data?.settleDate)}
        </Text.Paragraph>
      </div>

      <GenericRow
        title="Protocolo"
        description={transactionDetails?.authCode}
        noHorizontalPadding
        leftContentIconType="none"
        rightComponent={
          <RoundButtonIcon
            buttonSize="icon16"
            onClick={() =>
              copyText(
                transactionDetails?.authCode,
                'Código de Protocolo copiado com sucesso!'
              )
            }
          >
            <CopySVG />
          </RoundButtonIcon>
        }
        rightContentType="component"
      />
    </>
  );
};

export default DepositBill;
