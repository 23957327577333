import { gql, request, useMutation } from '~reactQuery/index';
import { queryClient } from '~src/query';

interface DeleteSlipInput {
  authCode: string;
  pin: string;
  recovery?: boolean;
  settingsId: number;
  token?: string;
  userOrganizationId: number;
}

interface DeleteSlipResponse {
  deleteSlip: {
    data: string;
    isValid: boolean;
    messages: string[];
  };
}

export const useDeleteSlip = () => {
  const mutation = gql`
    mutation DeleteSlip($params: DeleteSlipInput!) {
      deleteSlip(params: $params) {
        data
        messages
        isValid
      }
    }
  `;

  const fetcher = async (params: DeleteSlipInput) => {
    if (!params.userOrganizationId) return null;

    const response = await request<DeleteSlipResponse>(mutation, {
      params,
    });

    return response.deleteSlip;
  };

  return useMutation('useDeleteSlip', fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(['useGetAllSlips']);
    },
  });
};
