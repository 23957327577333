import * as React from 'react';
import { SVGProps } from 'react';

const ArrowLeftSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7072 4.70714L13.4143 4.00003L12.0001 2.58582L11.293 3.29292L3.29298 11.2929L2.58594 12L3.29292 12.7071L11.2915 20.7071L11.9986 21.4142L13.4129 20.0002L12.7059 19.293L6.41401 13H20.0001H21.0001V11H20.0001H6.4143L12.7072 4.70714Z"
      fill={props.fill || '#757893'}
    />
  </svg>
);

export default ArrowLeftSVG;
