import * as Style from './EmptyContacts.styles';
import * as Text from '~styles/text';

import EmptyContactsIcon from '~assets/images/icons/empty/no-contact.svg';
import { EmptyContactsProps } from './EmptyContacts.types';
import { colors } from '~styles';

const EmptyContacts: React.FC<EmptyContactsProps> = (props) => {
  return (
    <Style.Container>
      <EmptyContactsIcon />

      <Text.Heading2Bold
        color={colors.grayRegular}
        marginTop={18}
        textAlign="center"
      >
        Nenhum contato
      </Text.Heading2Bold>
      <Text.Paragraph
        color={colors.grayLight}
        marginTop={14}
        textAlign="center"
      >
        Seus contatos & favorecidos serão listados aqui.
      </Text.Paragraph>

      {/* <a className="outline-button">
        <Text.ParagraphBold color={colors.primary}>
          Adicionar contatos
        </Text.ParagraphBold>
      </a> */}
    </Style.Container>
  );
};

export default EmptyContacts;
