import * as Text from '~styles/text';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { GenericButton } from '~components';
import MessageIcon from '~assets/images/icons/sign/message.svg';
import { PossiblesSteps } from '../ForgotPassword.types';
import { SuccessToast } from '~components/Toast';
import { breakpoints } from '~styles/metrics';
import { colors } from '~styles';
import { useSendNotification } from '~reactQuery/mutations/user/useSendNotifications';
import useTimer from '~hooks/useTimer';
import useWindowDimensions from '~hooks/windowDimension';

export const VerifyEmail = ({
  setStep,
  userEmail,
}: {
  userEmail: string;
  setStep: Dispatch<SetStateAction<PossiblesSteps>>;
}) => {
  const { width } = useWindowDimensions();

  const { timeLeft, restartTimer, counting } = useTimer(60);

  const { mutateAsync: handleSendNotification, isLoading: loadingResend } =
    useSendNotification();

  useEffect(() => {
    restartTimer();
  }, []);

  async function resendNotification() {
    const baseURL = window.location.origin;
    const urlCallback = `${baseURL}/auth/forgot-password`;
    try {
      await handleSendNotification({
        email: userEmail,
        notificationKey: 'password_reset',
        notificationService: 'business',
        urlCallback,
        notificationType: 'email',
      });

      restartTimer();

      SuccessToast({
        title: 'Email Reenviado com sucesso',
        autoCloseEnable: true,
      });
    } catch (error) {
      //  console.log(error);
    }
  }

  return (
    <>
      <MessageIcon />
      <Text.Heading1Medium marginTop={10} marginBottom={30}>
        Verifique seu e-mail
      </Text.Heading1Medium>

      <Text.Paragraph>
        {`Enviamos para
        ${userEmail}
        um email com orientações para a troca de senha.`}
      </Text.Paragraph>

      <Text.Heading5
        color={colors.black}
        marginBottom={40}
        lineHeight={140}
        marginTop={10}
        width={breakpoints.desktop < width ? '100%' : ''}
      >
        Lembre-se que não vamos pedir dados pessoais fora das nossas aplicações.
      </Text.Heading5>

      <div className="buttons-wrapper">
        <GenericButton
          buttonType="inline"
          width="auto"
          onClick={() => setStep('start')}
          marginRight={10}
        >
          Voltar para o início
        </GenericButton>

        <GenericButton
          buttonType="default"
          width="auto"
          onClick={resendNotification}
          buttonLoading={loadingResend}
          disabled={counting}
        >
          {` Não recebi o e-mail ${counting ? `( ${timeLeft} )` : ''}`}
        </GenericButton>
      </div>
    </>
  );
};
