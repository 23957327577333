import * as Text from '~styles/text';
import colors from '~styles/colors';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ArrowLeftSVG from '~assets/svg/arrowLeft';
import CloseSVG from '~assets/svg/close';
import { RowList } from '../SavedLists.styles';
import CheckBox from '~components/CheckBox/CheckBox.component';
import { useEffect, useState } from 'react';
import { useUpdatePaymentList } from '~reactQuery/mutations/paymentList/useUpdatePaymentList';
import { ErrorToast, SuccessToast } from '~components/Toast';
import { SavedListsProps, SelectedList } from '../SavedLists.types';
import { BeneficiaryPerPix } from '../../../../TransferBatch.types';
import { When } from 'react-if';
import AlertModal from '~components/AlertModal';

const RemoveBeneficiary = ({
  selectedList,
  setStepSavedLists,
}: SavedListsProps) => {
  const [newList, setNewList] = useState<SelectedList>(selectedList);
  const [modalDeleteBeneficiaryIsVisible, setModalDeleteBeneficiaryIsVisible] =
    useState(false);
  const {
    mutateAsync: updatePaymentList,
    isLoading: loadingUpdatePaymentList,
  } = useUpdatePaymentList();

  const handleSelectBeneficiary = (
    selected: BeneficiaryPerPix,
    indexInArray: number
  ) => {
    const objectIndex = newList?.data?.listOfBeneficiaries?.findIndex(
      (item, index) =>
        item?.fullName === selected?.fullName && index === indexInArray
    );

    const updatedList = {
      ...newList,
      data: {
        ...newList.data,
        listOfBeneficiaries: [...newList.data.listOfBeneficiaries],
      },
    };

    updatedList.data.listOfBeneficiaries[objectIndex] = {
      ...updatedList.data.listOfBeneficiaries[objectIndex],
      isCheckedToRemove: !selected?.isCheckedToRemove,
    };

    setNewList(updatedList);
  };

  useEffect(() => {
    setNewList(selectedList);
  }, []);

  const handleRemoveBeneficiaries = async () => {
    let getArray = newList?.data?.listOfBeneficiaries?.filter(
      (item) => !item?.isCheckedToRemove
    );

    getArray = getArray.map((item) => {
      const { isCheckedToRemove, ...updatedItem } = item;
      return updatedItem;
    });

    try {
      const response = await updatePaymentList({
        id: selectedList?.id,
        data: {
          nameList: selectedList?.data?.nameList,
          listOfBeneficiaries: getArray,
        },
      });

      if (response) {
        SuccessToast({
          title: 'Beneficiários removidos com sucesso!',
          autoCloseEnable: true,
        });
        setStepSavedLists('showAllSavedLists');
      }
    } catch (error) {
      ErrorToast({
        title: 'Não foi possível remover o Beneficiário!',
        autoCloseEnable: true,
      });
    }
  };

  const handleTranslatorKeyType = (value: string) => {
    const translatorKeyType: { [key: string]: string } = {
      PHONE: 'Pix',
      CPF: 'Pix',
      EMAIL: 'Pix',
      CNPJ: 'Pix',
    };

    return translatorKeyType[value] ? translatorKeyType[value] : value;
  };
  const beneficiariesArrayToRemove = newList?.data?.listOfBeneficiaries.filter(
    (item) => item?.isCheckedToRemove === true
  );

  return (
    <>
      <div
        className="go-back-button-edit-list"
        onClick={() => setStepSavedLists('editList')}
      >
        <ArrowLeftSVG />
      </div>

      <div className="align-row-edit-list">
        <div className="title-list">
          <Text.Heading2Bold>{newList?.data?.nameList}</Text.Heading2Bold>
          <Text.Smaller fontSize={0.8125} color={colors.grayMedium}>
            Lista personalizada
          </Text.Smaller>
        </div>
      </div>

      {newList?.data?.listOfBeneficiaries?.map((item, index) => {
        const key = `${item.holderName}-${item.keyType}-${index}`;
        return (
          <RowList key={key}>
            <div className="left align-row">
              <div className="align-row" style={{ gap: 18 }}>
                <CheckBox
                  checked={item?.isCheckedToRemove}
                  onClick={() => handleSelectBeneficiary(item, index)}
                />

                <div className="align-column">
                  <Text.Heading5Bold>
                    {item?.fullName || item?.holderName}
                  </Text.Heading5Bold>
                  <Text.Paragraph color={colors.grayMedium}>
                    {item?.amount}
                  </Text.Paragraph>
                  <Text.Paragraph color={colors.grayMedium}>
                    {handleTranslatorKeyType(item?.transferType) ||
                      handleTranslatorKeyType(item?.keyType)}
                  </Text.Paragraph>
                </div>
              </div>
            </div>

            <div className="right" />
          </RowList>
        );
      })}

      <ModalMultiStepBottom
        singleButtonText="Remover beneficiários"
        singleButtonWidth="220px"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => setModalDeleteBeneficiaryIsVisible(true)}
        doubleButtonOneAction={() => setStepSavedLists('editList')}
        doubleButtonTextOne={
          <div className="button-row-transfer-batch" style={{ gap: 6 }}>
            <CloseSVG width={30} height={30} fill={colors.black} />
            <Text.Heading5>Cancelar</Text.Heading5>
          </div>
        }
        singleButtonLoading={loadingUpdatePaymentList}
        disableDoubleButtonOneAction={loadingUpdatePaymentList}
        disableSingleButton={beneficiariesArrayToRemove?.length === 0}
      />

      <When condition={modalDeleteBeneficiaryIsVisible}>
        <AlertModal
          setOpenAlert={setModalDeleteBeneficiaryIsVisible}
          title={
            beneficiariesArrayToRemove?.length > 1
              ? 'Deseja remover estes beneficiários?'
              : 'Deseja remover este beneficiário?'
          }
          secondaryButtonText={
            beneficiariesArrayToRemove?.length > 1
              ? 'Remover beneficiários'
              : 'Remover beneficiário'
          }
          secondaryButtonAction={() => {
            setModalDeleteBeneficiaryIsVisible(false);
            handleRemoveBeneficiaries();
          }}
          primaryButtonText="Fechar"
          primaryButtonAction={() => setModalDeleteBeneficiaryIsVisible(false)}
          secondaryButtonWidth="219px"
          bodyComponent={
            beneficiariesArrayToRemove?.length > 1 ? (
              <Text.Heading5>
                Ao confirmar a remoção, os beneficiários selecionados não
                estarão mais na listagem para transferência em lote.
              </Text.Heading5>
            ) : (
              <Text.Heading5>
                Ao confirmar a remoção, o beneficiário não estará mais na
                listagem para a transferência em lote.
              </Text.Heading5>
            )
          }
          noBorderTop
          secondaryButtonColor="error"
          paddingVertical="20"
          paddingHorizontal="30"
          paddingBottomTitle="0px"
        />
      </When>
    </>
  );
};

export default RemoveBeneficiary;
