import { gql, request, useMutation } from '~reactQuery/index';
import { NotificationFlags } from '~reactQuery/types/business.types';

interface PutSettingsParams {
  settingsId: number;
  enablePin?: boolean;
  twoFA?: boolean;
  language?: string;
  notificationFlags: [NotificationFlags];
  internalNotificationFlags: [NotificationFlags];
}

interface PutSettingsResponse {
  putSettings: {
    data: string;
    messages: string[];
    isValid: boolean;
  };
}

export const usePutSettings = () => {
  const mutation = gql`
    mutation PutSettings($params: PutSettingsInput!) {
      putSettings(params: $params) {
        data {
          id
          enablePin
          twoFA
          language
          notificationFlags
          internalNotificationFlags
          createdAt
          updatedAt
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: PutSettingsParams) => {
    if (!params.settingsId) return null;

    const response = await request<PutSettingsResponse>(mutation, {
      params,
    });

    return response.putSettings;
  };

  return useMutation(['usePutSettings'], fetch);
};
