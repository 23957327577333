import ShieldFillSVG from '~assets/svg/shieldFill';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { AreYouSurePageProps } from '../DeletePixKey.types';
import * as Text from '~styles/text';
import colors from '~styles/colors';

function AreYouSure(props: AreYouSurePageProps) {
  const { setCurrentStep, pixKey } = props;

  const goToNextStep = () => {
    setCurrentStep('confirmPin');
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Quer mesmo excluir essa Chave Pix?"
        description="Por se tratar de uma Chave aleatória, você não poderá recuperá-la posteriormente."
      />
      <div className="delete-pix-body">
        <div className="body-container-card">
          <ShieldFillSVG />
          <div>
            <Text.Paragraph color={colors.grayMedium} marginBottom={4}>
              Chave Aleatória
            </Text.Paragraph>
            <Text.Heading4Bold>{pixKey}</Text.Heading4Bold>
          </div>
        </div>
      </div>
      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="1"
        singleButtonAlignment="flex-end"
        singleButtonAction={goToNextStep}
      />
    </>
  );
}

export default AreYouSure;
