import * as Style from './PixArea.styles';
import * as Text from '~styles/text';

import Container from '~components/Container';
import QuickActions from '~components/QuickActions';
import { breakpoints } from '~styles/metrics';
import useWindowDimensions from '~hooks/windowDimension';
import { PixKeyCards } from './PixKeyCards';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import colors from '~styles/colors';
import { useMemo } from 'react';
import { getHideCpfCnpj, getSelectedUserOrganization } from '~helpers/device';
import { useNavigate } from 'react-router-dom';
import {
  useGetStatement,
  Statement as StatementType,
} from '~reactQuery/queries/bank/useGetStatement';
import dayjs from 'dayjs';
import { APP_ROUTES } from '~data/navigation';
import LoadingSpinner from '~components/LoadingSpinner';
import Statement from '~components/StatementRowList/Statement.component';
import { EmptyTransactions } from '~components/Empty';
import { Else, If, Then } from 'react-if';
import { pixQuickActions } from '~data/quickAction';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';

const PixArea = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const userOrganizationId = getSelectedUserOrganization();
  const { section, params: paramsModal } = useActionsOnModalMultiStep();

  const hideDoc = getHideCpfCnpj() === 'true';
  const dayPlusOne = dayjs().add(1, 'day').format('YYYY-MM-DD');

  const {
    data: statementListPages,
    isLoading: isLoadingStatements,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
  } = useGetStatement({
    to: dayPlusOne,
    from: '2000-11-01',
    perPage: 30,
  });

  const { data: wallets, isLoading: loadingWallets } = useGetWallets({
    prefCurrency: 'BRL',
    flag: 'list',
    userOrganizationId: Number(userOrganizationId),
  });

  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  const receivedStatement: StatementType[] = useMemo(() => {
    const statementList: StatementType[] =
      statementListPages?.pages?.flat()?.filter(notEmpty) || [];

    return statementList;
  }, [statementListPages, isFetchingNextPage, isFetching]);

  const statementFiltered = useMemo(() => {
    if (receivedStatement && Array.isArray(receivedStatement)) {
      return receivedStatement.filter(
        (item: any) => item.data.method === 'pix'
      );
    }
    return [];
  }, [receivedStatement]);

  const notLoginAndNotData =
    statementListPages?.pages.length === 0 && !isLoadingStatements;

  const handleScroll = async (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - 10 < e.target.clientHeight;

    if (
      bottom &&
      Number(
        statementListPages?.pages?.[
          Number(statementListPages?.pages?.length) - 1
        ]?.length
      ) > 0 &&
      !isFetchingNextPage
    ) {
      await fetchNextPage();
    }
  };

  const quickActions = pixQuickActions(section, paramsModal);

  const isLoading = isLoadingStatements || loadingWallets;

  return (
    <Style.Container>
      <Container>
        <Text.Heading1Bold marginBottom={breakpoints.tablet < width ? 0 : 25}>
          Área Pix
        </Text.Heading1Bold>

        <QuickActions quickActions={quickActions} />

        <PixKeyCards />

        <Style.ContainerTitleTransactions className="heading">
          <Text.Heading2>Movimentações Pix</Text.Heading2>

          <a
            className="outline-button"
            onClick={() => navigate(APP_ROUTES.ACTIVITIES)}
          >
            <Text.ParagraphBold color={colors.primary}>
              Ver Todas
            </Text.ParagraphBold>
          </a>
        </Style.ContainerTitleTransactions>

        <If condition={isLoading}>
          <Then>
            <div className="container-loading">
              <LoadingSpinner
                color={colors.primary}
                size={60}
                strokeSize={25}
              />
            </div>
          </Then>
          <Else>
            <If condition={notLoginAndNotData}>
              <Then>
                <div className="empty-transactions">
                  <EmptyTransactions noTransactionButton />
                </div>
              </Then>

              <Else>
                <Statement
                  setPdf={() => { }}
                  statementList={statementFiltered}
                  handleScroll={handleScroll}
                  isFetchingNextPage={isFetchingNextPage}
                  hideCpfCnpj={hideDoc}
                  wallets={wallets}
                />
              </Else>
            </If>
            <div />
          </Else>
        </If>
      </Container>
    </Style.Container>
  );
};

export default PixArea;
