import * as Text from '~styles/text';
import * as Style from './SystemBanner.styles';
import WarningIconSVG from '~assets/svg/waningIcon';
import colors from '~styles/colors';
import { SystemBannerProps } from './SystemBanner.types';
import colorsSecondary from '~styles/colorsSecondary';

const SystemBanner: React.FC<SystemBannerProps> = (props) => {
  const { type = 'info', text, icon, link, messageLink } = props;

  const translatorBackgroundColor: { [key: string]: any } = {
    info: colors.primary,
    success: colors.greenMatte,
    warning: colorsSecondary.warning,
    danger: colorsSecondary.dangerDark,
  };

  const translatorColor: { [key: string]: any } = {
    info: colors.white,
    success: colors.white,
    warning: colors.black,
    danger: colors.white,
  };

  return (
    <Style.Container
      style={{ backgroundColor: translatorBackgroundColor[type] }}
    >
      <div className="icon">
        {icon || <WarningIconSVG fill={translatorColor[type]} />}
      </div>
      <Text.Heading4Bold
        color={translatorColor[type]}
        marginLeft={16}
        display="inline"
        style={{
          fontVariantNumeric: 'slashed-zero',
          fontFeatureSettings: "'ss02' on",
        }}
      >
        {text} <a href={link} style={{color: translatorColor[type], textDecoration: 'underline'}}>{messageLink}</a>
      </Text.Heading4Bold>
    </Style.Container>
  );
};

export default SystemBanner;
