import { colors } from '~styles';
import { screen } from '~styles/metrics';
import styled from 'styled-components';

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 193px);

  @media ${screen.tablet} {
    .scroll {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;
