import * as Style from '../../Transfer.styles';
import * as Text from '~styles/text';

import {
  CheckSelect,
  ErrorToast,
  LoadingSpinner,
  SuccessToast,
} from '~components';
import { Else, If, Then, When } from 'react-if';
import React, { useEffect, useState } from 'react';
import { cnpjMask, cpfMask, formatPhone } from '~helpers/format/masks';

import BankIcon from '~assets/images/icons/24x24/barcode.svg';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { PixModalStepsProps } from '../../Transfer.types';
import { capitalizeName } from '~helpers/format/user';

import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { suprimeCpfOrCnpj } from '~helpers/format/numbers';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useFormContext } from 'react-hook-form';
import { useGetBrlBanks } from '~reactQuery/queries/bank/useGetBrlBank';
import { useResolvePixKey } from '~reactQuery/mutations/pix/useResolvePixKey';
import { validaCNPJ } from '~validations/personalValidation';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import AlertModal from '~components/AlertModal/AlertModal.component';
import { useDeleteContact } from '~reactQuery/mutations/contacts/useDeleteContact';
import colors from '~styles/colors';
import { usePermission } from '~helpers/permissionGate';

function PixKeyIdentified(props: PixModalStepsProps) {
  const [loading, setLoading] = useState(false);
  const [invalidSelectedAccount, setInvalidSelectedAccount] = useState(false);
  const [modalAlertPixNotValid, setModalAlertPixNotValid] = useState(false);
  const { setPixStep: setCurrentStep } = props;
  const { setValue, getValues, reset } = useFormContext();
  const { params, setParams } = useActionsOnModalMultiStep();
  const { granted: criptoTransactionsGranted } =
    usePermission('criptoTransactions');

  const { granted: buttonsPixGranted } = usePermission('buttonsPix');

  const { data: partnerInfos } = useGetPartner();

  const { mutateAsync: resolvePixKey, isLoading: loadingResolvePixKey } =
    useResolvePixKey();

  const { mutateAsync: deleteContact, isLoading: loadingDeleteContact } =
    useDeleteContact();

  const userOrganizationId = getSelectedUserOrganization();

  const userDocumentNumber = partnerInfos?.data.documentNumber;

  const formValues = getValues();
  const [selectedAccount, setSelectedAccount] = useState<{
    selectOpt: string;
    option: any;
  }>({
    selectOpt: '',
    option: {},
  });

  const { data: bankList, isLoading: loadingBankList } = useGetBrlBanks();

  const tedAndPixManualAccounts = (
    formValues?.contact || params?.contact
  )?.contactData?.filter((acc: any) =>
    ['PIX_MANUAL', 'TED'].includes(acc.type)
  );
  const pixAccounts = (
    formValues?.contact || params?.contact
  )?.contactData?.filter((acc: any) => ['PIX_KEY'].includes(acc.type));

  const hasPix = pixAccounts?.length >= 1 || formValues?.keyResult;
  const hasAccounts = tedAndPixManualAccounts?.length >= 1;
  const showManualTransfer =
    (formValues?.contact || params?.contact)?.documentNumber ||
    ['CPF', 'CNPJ'].includes(formValues?.keyResult?.resolve?.keyType);

  const nameSelector =
    formValues?.keyResult?.resolve?.key?.holderName ||
    (formValues?.contact || params?.contact)?.name;

  const holderDocNum = ['EVP', 'EMAIL', 'TELEFONE'].includes(
    formValues?.keyResult?.resolve?.keyType
  )
    ? formValues?.keyResult?.resolve?.key?.holderDocument
    : (formValues?.contact || params?.contact)?.documentNumber ||
      formValues?.keyResult?.keyValue;

  const keyMasked = (pixType: string, type: string) => {
    switch (type) {
      case 'CPF':
        return cpfMask(pixType);

      case 'PHONE':
        return String(formatPhone(pixType)).replace('+55', '');

      case 'CNPJ':
        return cnpjMask(pixType);
      default:
        return pixType;
    }
  };

  const goBack = () => {
    setCurrentStep('chooseTransferMethod');

    const pixQrCode = params?.pixQrCode;

    setParams({});

    setParams({
      buttonsPixGranted,
      criptoTransactionsGranted,
      pixQrCode,
    });
  };
  const goNext = () => {
    if (selectedAccount.selectOpt === 'TED ou Pix Manual') {
      setValue('manualTransfer', true);
    } else {
      setValue('manualTransfer', false);
    }

    if (
      selectedAccount.selectOpt === 'PIX_KEY' &&
      (formValues?.contact || params?.contact)
    ) {
      setValue('keyResult', {
        keyValue: selectedAccount.option?.pixValue,
        resolve: {
          keyType: selectedAccount.option?.pixType,
        },
      });
    } else if (!formValues?.keyResult) {
      setValue('manualDetails', {
        ...selectedAccount.option,
        name: nameSelector,
        docNum: onlyNumbers(holderDocNum),
      });
    }

    setCurrentStep('chooseOriginWallet');
  };

  const goToManualTransfer = () => {
    setValue('manualTransfer', true);
    setValue('manualDetails', {
      docNum: onlyNumbers(holderDocNum),
      name: nameSelector,
      fromButton: true,
    });
    setCurrentStep('fillManualTransferInfo');
  };

  const handleValidatePix = async (option: any) => {
    try {
      setInvalidSelectedAccount(false);
      setLoading(true);

      const resolve = await resolvePixKey({
        key: validaCNPJ(option?.pixValue?.trim())
          ? onlyNumbers(option?.pixValue?.trim())
          : option?.pixValue?.trim(),
        userOrganizationId: Number(userOrganizationId),
        documentNumber: String(userDocumentNumber),
      });
      setLoading(false);

      if (!resolve) {
        setInvalidSelectedAccount(true);
        setModalAlertPixNotValid(true);
      }
    } catch (error) {
      setLoading(false);
      setInvalidSelectedAccount(true);
      setModalAlertPixNotValid(true);
    }
  };

  const setSelected = (
    option: any,
    selectOpt: 'PIX_KEY' | 'TED ou Pix Manual'
  ) => {
    if (selectedAccount.option === option) {
      setSelectedAccount({ selectOpt: '', option: {} });
    } else {
      setSelectedAccount({ selectOpt, option });

      if (option?.pixValue) {
        handleValidatePix(option);
      }
    }

    setSelectedAccount({ selectOpt, option });

    if (option?.pixValue) {
      handleValidatePix(option);
    }
  };

  useEffect(() => {
    if (formValues?.contact || params?.contact) {
      const contact = formValues?.contact || params?.contact;
      reset();
      setValue('contact', contact);
    } else if (formValues?.keyResult) {
      const keyResult = formValues?.keyResult;
      reset();
      setValue('keyResult', keyResult);
    }
  }, []);

  const translatorType = (value: string) => {
    const translator: any = {
      pix_manual: 'Pix',
      ted: 'TED',
      doc: 'DOC',
      pix: 'Pix',
    };

    return value ? `• ${translator[value?.toLowerCase()]}` : '';
  };

  const handleDeleteContact = async (selectedContact: any) => {
    try {
      const response = await deleteContact({
        contactDataId: Number(selectedContact?.option?.contactsId), // contactDataId quando é enviado remove apenas a chave pix
        contactId: Number(selectedContact?.option?.id),
        userOrganizationId: Number(userOrganizationId),
      });

      if (!response) {
        return ErrorToast({
          title: 'Erro ao excluir chave pix!',
          autoCloseEnable: true,
        });
      }
      setCurrentStep('chooseTransferMethod');
      return SuccessToast({
        title: 'Chave pix excluída com sucesso!',
        autoCloseEnable: true,
      });
    } catch (error) {
      return ErrorToast({
        title: 'Erro ao excluir chave pix!',
        autoCloseEnable: true,
      });
    }
  };
  const isLoading = loading || loadingResolvePixKey;

  return (
    <>
      <ModalMultiStepHeader
        title={capitalizeName(nameSelector)}
        backButtonAction={goBack}
      />

      <div className="bank-list-content">
        <Style.BankList>
          <If condition={hasPix}>
            <Then>
              <Text.Heading5 marginBottom={20}>
                Chave Pix identificada
              </Text.Heading5>

              <div className="mb-20">
                {pixAccounts?.length >= 1 ? (
                  pixAccounts?.map((pixContact: any) => {
                    return (
                      <CheckSelect
                        key={pixContact?.pixValue}
                        title={keyMasked(
                          pixContact?.pixValue,
                          pixContact?.pixType
                        )}
                        text={suprimeCpfOrCnpj(holderDocNum)}
                        onClick={() => setSelected(pixContact, 'PIX_KEY')}
                        iconType="bankIcon"
                        selected={selectedAccount.option === pixContact}
                      />
                    );
                  })
                ) : (
                  <CheckSelect
                    title={formValues?.keyResult?.resolve?.key?.holderName}
                    text={suprimeCpfOrCnpj(holderDocNum)}
                    iconType="bankIcon"
                    onClick={() =>
                      setSelected(formValues?.keyResult, 'PIX_KEY')
                    }
                    selected={selectedAccount.option === formValues?.keyResult}
                  />
                )}
              </div>
            </Then>

            <Else>{null}</Else>
          </If>

          <When condition={hasAccounts}>
            <Text.Heading3 marginBottom={15}>
              Outras contas deste contato
            </Text.Heading3>
            <div className="bank-list-wrapper">
              <If condition={loadingBankList}>
                <Then>
                  <div className="align-center">
                    <LoadingSpinner
                      color={colors.primary}
                      size={20}
                      strokeSize={8}
                    />
                  </div>
                </Then>

                <Else>
                  {tedAndPixManualAccounts?.map((acc: any) => {
                    const getBankName = bankList?.filter(
                      (bank) =>
                        bank?.bankIspb === acc?.bankIspb ||
                        bank?.bankCompe === acc?.bankCode
                    )?.[0]?.bankName;

                    return (
                      <CheckSelect
                        key={`${acc?.accountNumber}-${acc?.id}`}
                        title={getBankName ? `${getBankName}` : ''}
                        text={`Agência ${acc?.branch} • Conta ${
                          acc?.accountNumber
                        } ${translatorType(acc?.type)}`}
                        iconType="bankIcon"
                        onClick={() => setSelected(acc, 'TED ou Pix Manual')}
                        selected={selectedAccount.option === acc}
                      />
                    );
                  })}
                </Else>
              </If>
            </div>
          </When>
        </Style.BankList>
      </div>

      <ModalMultiStepBottom
        doubleButtonTextOne={
          <When condition={showManualTransfer}>
            <div className="double-button-one-container">
              <BankIcon />
              <Text.Heading5>TED ou Pix Manual</Text.Heading5>
            </div>
          </When>
        }
        doubleButtonOneAction={
          showManualTransfer ? goToManualTransfer : () => {}
        }
        singleButtonText="Próximo"
        singleButtonWidth="1"
        singleButtonAction={goNext}
        disableSingleButton={
          selectedAccount.selectOpt === '' ||
          isLoading ||
          invalidSelectedAccount
        }
        singleButtonLoading={isLoading}
      />
      <When condition={modalAlertPixNotValid}>
        <AlertModal
          setOpenAlert={setModalAlertPixNotValid}
          title="Atenção: Chave Pix Selecionada Inválida."
          primaryButtonText="Fechar"
          secondaryButtonText="Remover Chave"
          primaryButtonAction={() => setModalAlertPixNotValid(false)}
          secondaryButtonAction={() => handleDeleteContact(selectedAccount)}
          secondaryButtonColor="error"
          secondaryButtonWidth="194px"
          bodyComponent={
            <Text.Heading5 display="inline-block">
              A chave Pix selecionada{' '}
              <strong> {selectedAccount?.option?.pixValue} </strong> não está
              vinculada a uma conta bancária, impedindo a transação. Deseja
              excluir a chave incorreta e tentar novamente?
            </Text.Heading5>
          }
          buttonLoading={false}
        />
      </When>
    </>
  );
}

export default PixKeyIdentified;
