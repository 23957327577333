import { gql, request, useMutation } from '~reactQuery';

import { AccessFlags } from '~reactQuery/types/business.types';

interface ChangeOrganizationPinInput {
  userOrganizationId: number;
  newPin: string;
  oldPin: string;
}

interface ChangeOrganizationPinResponse {
  changeOrganizationPin: {
    data: {
      id: number;
      twoFA: boolean;
      enablePin: boolean;
      flags: AccessFlags;
      admin: boolean;
      active: boolean;
      createdAt: string;
      updatedAt: string;
      userId: number;
      organizationsId: number;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useChangeOrganizationPin() {
  const mutation = gql`
    mutation ChangeOrganizationPin($params: ChangeOrganizationPinInput!) {
      changeOrganizationPin(params: $params) {
        data {
          id
          flags
          admin
          active
          createdAt
          updatedAt
          userId
          organizationsId
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: ChangeOrganizationPinInput) => {
    const response = await request<ChangeOrganizationPinResponse>(mutation, {
      params,
    });
    return response.changeOrganizationPin;
  };

  return useMutation('ChangeOrganizationPinMutation', fetch);
}
