import { Flags, WalletType } from '~reactQuery/types/wallets.types';
import { gql, request, useQuery } from '~reactQuery';

import { CurrencyTypes } from 'src/@types/currency.types';

export interface GetWalletsInput {
  prefCurrency: CurrencyTypes;
  flag: Flags;
  userOrganizationId: number;
}

export interface GetWalletsResponse {
  wallets: WalletType[];
}

export const useGetWallets = (params: GetWalletsInput) => {
  const QUERY = gql`
    query Wallets($params: WalletsInput) {
      wallets(params: $params) {
        id
        owner
        walletName
        walletCurrency
        isPublic
        isOwnerInfoPublic
        isFavorite
        enabled
        ordering
        walletAddress
        funds
        createdAt
        updatedAt
        priceInPrefCurrency
        currency {
          id
          type
          precision
          name
          symbol
          isoCode
          separator
          decimal
          ask
          bid
        }
        enabledTransfer
        enabledPay
        enabledConvert
        enabledDeposit
        enabledWithdraw
      }
    }
  `;

  const fetcher = async () => {
    const response = await request<GetWalletsResponse>(QUERY, { params });
    return response.wallets;
  };

  return useQuery(['getWallets'], fetcher, {
    refetchInterval: 60000,
  });
};
