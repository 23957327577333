import { colors } from '~styles';
import * as Text from '~styles/text';
import { ChangeEvent, forwardRef } from 'react';
import { When } from 'react-if';
import * as Style from './InputCurrency.styles';
import { BigInputProps } from './InputCurrency.types';
import CurrencyJS from 'currency.js';

const InputCurrency = forwardRef<HTMLInputElement, BigInputProps>(
  (props, _ref) => {
    const currencyMask = (event: ChangeEvent<HTMLInputElement>) => {
      const formatedInput = CurrencyJS(
        event.target.value.replace('.', '').replace(',', ''),
        {
          fromCents: true,
          precision: 2,
          symbol: '',
          separator: '.',
          decimal: ',',
        }
      ).format();

      return `R$ ${formatedInput}`;
    };

    return (
      <Style.ContainerInput>
        <Style.Input
          ref={_ref}
          {...props}
          onChange={(event) => {
            event.target.value = currencyMask(event);

            if (props.onChange) {
              props.onChange(event);
            }
          }}
          style={props.styleInput}
        />
        <When condition={!!props.error}>
          <div>
            <Text.Small color={colors.danger}>{props.error}</Text.Small>
          </div>
        </When>
      </Style.ContainerInput>
    );
  }
);

InputCurrency.displayName = 'InputCurrency';

export default InputCurrency;
