import * as React from 'react';

import { SVGProps } from 'react';

const BchCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="M27.01 15.801a3.317 3.317 0 0 0 .47-3.76c-.935-1.92-3.58-1.882-5.515-1.419L20.954 7.6l-1.857.617.996 2.99-1.244.413-1-2.99-1.86.618 1.002 2.992-3.741 1.24.647 1.933 1.393-.462c.623-.207.99-.122 1.16.383l2.761 8.287c.231.692-.042 1.018-.414 1.14l-1.421.473.657 1.968 3.613-1.199 1.02 3.063 1.872-.623-1.023-3.062 1.248-.415 1.02 3.063 1.82-.605-1.024-3.062.399-.132c3.821-1.268 4.469-3.568 3.615-6.125a3.506 3.506 0 0 0-1.4-1.764 3.491 3.491 0 0 0-2.182-.545l-.003.005Zm-7.474-2.126 1.327-.44c.928-.31 2.82-.763 3.315.76.19.466.194.987.014 1.456-.18.47-.534.852-.986 1.069l-2.454.815-1.216-3.66Zm5.249 8.89-.007-.012-2.051.681-1.33-3.99 2.382-.8c.559-.185 2.717-.694 3.213.795.497 1.488-.21 2.666-2.194 3.323l-.013.003Z"
      fill="#93C65C"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BchCoinSVG;
