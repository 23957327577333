import ModalMultiStep from '~components/ModalMultiStep/ModalMultiStep.component';
import * as Style from './UserPermission.styles';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import NewUserInfomartion from './ModalSteps/1.NewUserInformation.component';
import { useEffect, useState } from 'react';
import DefinePermissions from './ModalSteps/2.DefinePermissions';
import {
  UserPermissionModalProps,
  UserPermissionSteps,
} from './UserPermission.types';
import ConfirmRegisterUser from './ModalSteps/3.1.ConfirmRegisterUser';
import PersonalizeUserPermission from './ModalSteps/3.2.PersonalizeUserPermission';
import SuccessUserRegistered from './ModalSteps/4.SuccessUserRegistered';
import { useSignUpAdm } from '~reactQuery/mutations/settings/useSignUpAdm';
import { getSelectedUserOrganization } from '~helpers/device';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { parseError } from '~reactQuery/index';
import { usePutBusinessScopes } from '~reactQuery/mutations/settings/usePutBusinessScopes';
import { useQueryClient } from 'react-query';

const UserPermission = () => {
  const userOrganizationId = getSelectedUserOrganization();
  const [stepUserPermission, setStepUserPermission] =
    useState<UserPermissionSteps>('newUserInformation');
  const { openModalMultiStep, setOpenModalMultiStep, params } =
    useActionsOnModalMultiStep();
  const [errors, setErrors] = useState({
    registerName: '',
    documentNumber: '',
    phone: '',
    email: '',
  });
  const [registerName, setRegisterName] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [userPermission, setUserPermission] = useState({
    permission: '',
    scopes: [],
  });
  const [hasError, setHasError] = useState({
    pin: '',
    twoFa: '',
  });
  const queryClient = useQueryClient();

  const { mutateAsync: requestSignUpAdm, isLoading } = useSignUpAdm();
  const {
    mutateAsync: requestPutBusinessScopes,
    isLoading: isLoadingPutBusinessScopes,
  } = usePutBusinessScopes();

  const handleUpdateUserPermissions = async () => {
    const response = await requestPutBusinessScopes({
      userOrganizationId: Number(params?.userOrganizationId),
      scopes: userPermission?.scopes,
    });
    await queryClient.invalidateQueries(['useGetOrganizationPartners']);

    if (response?.putBusinessScopes?.data) {
      setStepUserPermission('successUserRegistered');
    }
  };

  const handleFinish = async () => {
    try {
      const baseURL = window.location.origin;
      const urlCallback = `${baseURL}/auth/guest-register`;

      if (params?.type === 'updateUserPermissions') {
        await handleUpdateUserPermissions();
      } else {
        const response = await requestSignUpAdm({
          userOrganizationId: Number(userOrganizationId),
          documentNumber: onlyNumbers(documentNumber),
          email,
          message,
          phone: onlyNumbers(phone),
          registerName,
          scopes: userPermission?.scopes,
          socialName: registerName,
          urlCallback,
        });

        await queryClient.invalidateQueries(['useGetOrganizationPartners']);

        if (response?.signupAdm?.data) {
          setStepUserPermission('successUserRegistered');
        }
      }
    } catch (error: any) {
      // console.log('SignUpAdm error =>', error);

      const errorKey = parseError(error);

      if (errorKey === 'INVALID_PIN') {
        setHasError((prev) => ({ ...prev, pin: 'Pin inválido' }));
      }

      if (errorKey === 'INVALID_2FA_TOKEN') {
        setHasError((prev) => ({ ...prev, twoFa: '2-FA inválido' }));
      }
    }
  };

  const handleCancelAndResetFields = () => {
    setStepUserPermission('newUserInformation');
    setErrors({
      registerName: '',
      documentNumber: '',
      phone: '',
      email: '',
    });
    setRegisterName('');
    setDocumentNumber('');
    setPhone('');
    setEmail('');
    setMessage('');
    setUserPermission({ permission: '', scopes: [] });
    setOpenModalMultiStep(false);
  };

  const props: UserPermissionModalProps = {
    setStepUserPermission,
    errors,
    setErrors,
    registerName,
    setRegisterName,
    documentNumber,
    setDocumentNumber,
    phone,
    setPhone,
    email,
    setEmail,
    message,
    setMessage,
    userPermission,
    setUserPermission,
    hasError,
    handleFinish,
    handleCancelAndResetFields,
  };

  const userPermissionModalSteps: any = {
    newUserInformation: {
      component: <NewUserInfomartion params={params} {...props} />,
      progressValue: 1,
    },
    definePermissions: {
      component: <DefinePermissions params={params} {...props} />,
      progressValue: 50,
    },
    personalizeUserPermissions: {
      component: <PersonalizeUserPermission params={params} {...props} />,
      progressValue: 62.5,
    },
    confirmRegisterUser: {
      component: <ConfirmRegisterUser params={params} {...props} />,
      progressValue: 75,
    },
    successUserRegistered: {
      component: <SuccessUserRegistered params={params} {...props} />,
      progressValue: 100,
    },
  };

  useEffect(() => {
    if (params?.type === 'updateUserPermissions') {
      setStepUserPermission('definePermissions');
    } else {
      setStepUserPermission('newUserInformation');
    }
  }, [openModalMultiStep]);

  return (
    <Style.Container>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={
          userPermissionModalSteps[stepUserPermission]?.progressValue
        }
      >
        <div className="container">
          {userPermissionModalSteps[stepUserPermission]?.component}
        </div>
      </ModalMultiStep>
    </Style.Container>
  );
};

export default UserPermission;
