import { colors } from '~styles';
import { screen } from '~styles/metrics';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

interface CloseProps {
  circleSize: 'small' | 'bigger';
  invertColors: boolean;
  marginTop: string;
  marginBottom: string;
}

export const CloseBtn = styled.button<CloseProps>`
  margin-right: ${({ marginTop }) => marginTop || '20px'};
  margin-top: ${({ marginBottom }) => marginBottom || '20px'};
  position: absolute;
  right: 0;

  @media ${screen.phone} {
    margin-right: 20px;
    margin-top: 20px;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ circleSize }) =>
    circleSize === 'small'
      ? css`
          width: 28.5px;
          height: 28.5px;
        `
      : css`
          width: 38px;
          height: 38px;
        `}

  border: none;
  color: ${colors.black};
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(4px);

  ${({ invertColors, circleSize }) =>
    invertColors
      ? css`
          background-color: ${circleSize === 'small'
            ? colors.black
            : transparentize(0.99, colors.white)};
          svg {
            path {
              stroke: ${colors.white};
              fill: ${colors.white};
            }
          }
        `
      : css`
          background-color: ${circleSize === 'bigger'
            ? transparentize(0.95, colors.black)
            : colors.white};
          svg {
            path {
              stroke: ${colors.black};
              fill: ${colors.black};
            }
          }
        `}

  transition: all ease-out 0.3s;

  :hover {
    transition: all ease-out 0.3s;

    ${({ circleSize, invertColors }) =>
      invertColors
        ? css`
            background-color: ${circleSize === 'small'
              ? colors.naturalBlack
              : transparentize(0.75, colors.white)};
          `
        : css`
            background-color: ${circleSize === 'small'
              ? colors.gray
              : transparentize(0.85, colors.black)};
          `}
    transform: scale(1.1579);
    svg {
      transition: all ease-out 0.3s;
    }
  }
`;
