import * as Text from '~styles/text';

import { Else, If, Then } from 'react-if';

import BarcodeIcon from '~assets/svg/barcode';
import { BillReceipt } from '~assets/pdfs/bill/BillReceipt';
import EndStepStatus from '~components/EndStepStatus';
import LoadingSpinner from '~components/LoadingSpinner';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PayBillsPageProps } from '../PayBills.types';
import ReceiptIcon from '~assets/images/icons/transactions/receipt.svg';
import colors from '~styles/colors';
import dayjs from 'dayjs';
import { formatCurrencyFn } from '~helpers/format/currency';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetStatementSpecific } from '~reactQuery/queries/bank/useGetStatementSpecific';

function PayBillsStatus(props: PayBillsPageProps) {
  const {
    statusOfPayment = 'OK',
    setCurrentStep,
    setOpenModalMultiStep,
    billPaymentResp,
  } = props;

  function newPayment() {
    setCurrentStep('possiblesKeys', {}, true);
  }
  const userOrg = getSelectedUserOrganization();

  const { data: accountDetails, isLoading: loadingGetAccount } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrg),
    });

  const { data: billSpecificDetails, isLoading: isLoadingSpecificDetails } =
    useGetStatementSpecific({
      authCode: billPaymentResp?.data?.authCode,
      userOrganizationId: Number(userOrg),
    });

  const amount = billPaymentResp?.data?.dataValidate?.amount;

  const infosOfStatus = {
    OK: {
      icon: <EndStepStatus animType="success" />,
      title: 'Pagamento realizado!',
      singleButtonText: 'Finalizar',
      singleButtonAction: () => {
        setOpenModalMultiStep(false);
      },
      doubleButtonTextOne: 'Novo Pagamento',
      buttonIcon: <BarcodeIcon />,
      doubleButtonOneAction: newPayment,
      description: `Você pagou ${formatCurrencyFn(
        'BRL',
        String(amount),
        false,
        false,
        true
      )} para ${
        billPaymentResp?.data?.dataValidate?.recipient?.name ||
        billPaymentResp?.data?.dataValidate?.assignor
      } `,
    },
    NOT_OK: {
      icon: <EndStepStatus animType="error" />,
      title: 'Não foi possível realizar o pagamento. Tente novamente.',
      description: '',
      singleButtonText: 'Finalizar',
      singleButtonAction: () => {
        setOpenModalMultiStep(false);
      },
      doubleButtonTextOne: 'Novo Pagamento',
      buttonIcon: <BarcodeIcon />,
      doubleButtonOneAction: newPayment,
    },
  };

  const showDownload =
    billSpecificDetails && !isLoadingSpecificDetails && !loadingGetAccount;

  return (
    <>
      <ModalMultiStepHeader />
      <div className="payment-status content-container">
        {infosOfStatus?.[statusOfPayment]?.icon}
        <Text.Heading1Bold textAlign="center" marginBottom={25} marginTop={25}>
          {infosOfStatus?.[statusOfPayment]?.title}
        </Text.Heading1Bold>
        <Text.Heading4 textAlign="center">
          {infosOfStatus?.[statusOfPayment]?.description}
        </Text.Heading4>

        <If condition={showDownload}>
          <Then>
            <PDFDownloadLink
              document={
                <BillReceipt
                  accountDetails={accountDetails}
                  billDetails={billSpecificDetails}
                  authCode={billPaymentResp?.data?.authCode}
                />
              }
              fileName={`bill-voucher-${dayjs().format('DD-MM-YYYY')}.pdf`}
            >
              {({ loading }) => {
                return loading ? (
                  <LoadingSpinner color={colors.black} />
                ) : (
                  <div className="receipt-button">
                    <ReceiptIcon />
                    <Text.Heading5 marginLeft={10} color={colors.primary}>
                      Baixar comprovante
                    </Text.Heading5>
                  </div>
                );
              }}
            </PDFDownloadLink>
          </Then>
          <Else>
            <LoadingSpinner color={colors.black} />
          </Else>
        </If>
      </div>
      <ModalMultiStepBottom
        singleButtonText={infosOfStatus?.[statusOfPayment]?.singleButtonText}
        singleButtonWidth="1"
        singleButtonAction={
          infosOfStatus?.[statusOfPayment]?.singleButtonAction
        }
        doubleButtonTextOne={
          <div className="double-button-one-container">
            {infosOfStatus?.[statusOfPayment]?.buttonIcon}
            <Text.Heading5>
              {infosOfStatus?.[statusOfPayment]?.doubleButtonTextOne}
            </Text.Heading5>
          </div>
        }
        doubleButtonOneAction={
          infosOfStatus?.[statusOfPayment]?.doubleButtonOneAction
        }
      />
    </>
  );
}

export default PayBillsStatus;
