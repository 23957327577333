import colors from '~styles/colors';

import { LoadingSpinnerProps } from './LoadingSpinner.types';
import { TailSpin } from 'react-loader-spinner';

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { size, strokeSize, color, velocity } = props;

  return (
    <TailSpin
      height={size || 30}
      width={size || 30}
      color={color || colors.primary}
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      visible
    />
  );
};

export default LoadingSpinner;
