import styled from 'styled-components';
import colors from '~styles/colors';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .container {
    .double-button-one-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }

    .content-success-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100% !important;
      height: 470px !important;
      text-align: center;
    }
  }
`;
export const ContentModal = styled.div`
  padding: 32px 32px 0px 32px;

  .row {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  .column {
    display: flex;
    flex-direction: column;
    width: 100%;

    input {
      border-radius: 0px !important;
      outline: none !important;
      border-bottom: ${colors.grayRegular} solid 1px;
      padding-left: 0px;
      background-color: transparent !important;
      font-feature-settings: 'ss02' on, 'zero' on;
    }
  }

  .row-define-permissions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 79px;
    border-top: 0.1px solid ${colors.gray};
    cursor: pointer;

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      gap: 15px;
      .column {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
    .right {
      .dropwdown {
        padding: 10px 24px;
        background-color: ${colors.gray};
        border-radius: 44px;
        display: flex;
        align-items: center;
        gap: 11px;
      }
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  input {
    border-radius: 0px !important;
    outline: none !important;
    border-bottom: ${colors.grayRegular} solid 1px;
    padding-left: 0px;
    background-color: transparent !important;
    font-feature-settings: 'ss02' on, 'zero' on;

    &:focus {
      outline: none !important;
      background-color: transparent !important;
    }
  }
`;

export const CircleCheckbox = styled.div<{ background: string; type: string }>`
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: 1.5px solid ${colors.black};
  background-color: ${(props) => {
    if (props.background === 'analyst' && props.type === 'circle-analyst') {
      return colors.black;
    }
    if (props.background === 'manager' && props.type === 'circle-manager') {
      return colors.black;
    }
    return colors.transparent;
  }};
`;
