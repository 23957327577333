import * as Text from '~styles/text';
import * as yup from 'yup';
import { BigInput, GenericButton } from '~components';
import { Dispatch, SetStateAction } from 'react';
import LockIcon from '~assets/images/lock-icon.svg';
import { PossiblesSteps } from '../ForgotPassword.types';
import { breakpoints } from '~styles/metrics';
import { colors } from '~styles';
import { parseError } from '~reactQuery/index';
import { useForm } from 'react-hook-form';
import { useSendNotification } from '~reactQuery/mutations/user/useSendNotifications';
import useWindowDimensions from '~hooks/windowDimension';
import { yupResolver } from '@hookform/resolvers/yup';

export const Start = ({
  setStep,
  setUserEmail,
}: {
  setUserEmail: (value: string) => void;
  setStep: Dispatch<SetStateAction<PossiblesSteps>>;
}) => {
  const { width } = useWindowDimensions();

  const schema = yup.object({
    email: yup
      .string()
      .required('Esse campo é obrigatório')
      .email('Email incorreto'),
  });

  const { mutateAsync: handleSendNotification, isLoading } =
    useSendNotification();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<yup.InferType<typeof schema>>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async ({ email }) => {
    const baseURL = window.location.origin;
    const urlCallback = `${baseURL}/auth/forgot-password`;

    try {
      await handleSendNotification({
        email,
        notificationKey: 'password_reset',
        notificationService: 'business',
        urlCallback,
        notificationType: 'email',
      });

      setUserEmail(email);

      setStep('verifyEmail');
    } catch (error: any) {
      if (parseError(error) === 'CURRENT_USER_BLOCKED') setStep('userBlocked');
    }
  });

  return (
    <>
      <div className="medium-icon">
        <LockIcon />
      </div>

      <Text.Heading1Medium marginTop={10} marginBottom={30}>
        Esqueceu sua senha?
      </Text.Heading1Medium>

      <Text.Heading5
        color={colors.black}
        marginBottom={30}
        lineHeight={140}
        width={breakpoints.desktop < width ? '100%' : ''}
      >
        Para recuperar sua senha, preencha o campo abaixo com seu e-mail
        cadastrado. Você receberá um link de recuperação.
      </Text.Heading5>

      <BigInput
        {...register('email')}
        placeHolder="seu@mail.com"
        error={errors.email?.message}
      />

      <GenericButton
        buttonLoading={isLoading}
        buttonType="secondary"
        width="auto"
        onClick={onSubmit}
        marginTop={30}
      >
        Enviar Link
      </GenericButton>
    </>
  );
};
