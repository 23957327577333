import { useEffect, useState } from 'react';
import ModalMultiStep from '~components/ModalMultiStep';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import * as Style from './Send.styles';
import { SetCurrentStepProps, StepParamsProps } from './Send.types';
import SendOptions from './ModalSteps/1.SendOptions';

function PayBills() {
  const { openModalMultiStep, setOpenModalMultiStep } =
    useActionsOnModalMultiStep();
  const [currentStep, setCurrentStep] =
    useState<SetCurrentStepProps>('sendOptions');
  const [stepParams, setStepParams] = useState<StepParamsProps>({});

  function setStepsAndParams(step: SetCurrentStepProps, params?: object) {
    if (params) {
      setStepParams((prev) => ({ prev, ...params } as StepParamsProps));
    }
    setCurrentStep(step);
  }

  const modalStepsAndDetails = {
    sendOptions: {
      component: (
        <SendOptions
          key="sendOptions"
          setCurrentStep={setStepsAndParams}
          keyType={stepParams?.keyType}
        />
      ),
      progressValue: 15,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  // CLEAR STEP ON MODAL CLOSE
  useEffect(() => {
    if (!openModalMultiStep) setCurrentStep('sendOptions');
  }, [openModalMultiStep]);

  return (
    <Style.SendContainer>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={currentStepInfos.progressValue}
      >
        {currentStepInfos.component}
      </ModalMultiStep>
    </Style.SendContainer>
  );
}

export default PayBills;
