import MessageIcon from '~assets/images/icons/sign/message.svg';
import QrCodeToCapAccount from '~assets/images/kyc/qrcode-to-capaccount.svg';
import { GenericButton } from '~components';
import useWindowDimensions from '~hooks/windowDimension';
import * as Text from '~styles/text';
import { useNavigate } from 'react-router-dom';
import * as Style from '../SignUp.styles';

export const UserHasNoAccount = () => {
  const navigate = useNavigate();
  const { height } = useWindowDimensions();

  const marginStyles = () => {
    if (height <= 690) return { marginTop: 100, marginBottom: 90 };
    return {};
  };

  return (
    <div style={marginStyles()}>
      <Style.Container>
        <div className="main-icon">
          <MessageIcon />
        </div>

        <Text.Heading1Medium
          marginBottom={30}
          marginTop={10}
          fontSize={1.938}
          lineHeight={130}
        >
          Sua CapConta não foi validada
        </Text.Heading1Medium>

        <Text.Heading5 marginBottom={30}>
          Para continuar e ter acesso ao CapBusiness, você precisa ter uma
          CapConta validada. Quer resolver isso agora?
        </Text.Heading5>

        <div className="instructions-container">
          <QrCodeToCapAccount />

          <div className="instructions">
            <div className="instructions-item">
              <div className="number-item">1</div>

              <Text.Heading5>
                Com seu smartphone, escaneie o QR Code ao lado e instale o
                aplicativo do Capitual;
              </Text.Heading5>
            </div>
            <div className="instructions-item">
              <div className="number-item">2</div>

              <Text.Heading5>
                Inicie o cadastro da sua conta ou faça login com seu e-mail e
                senha;
              </Text.Heading5>
            </div>
            <div className="instructions-item">
              <div className="number-item">3</div>

              <Text.Heading5>
                Siga as instruções na página inicial do App e valide sua
                CapConta.
              </Text.Heading5>
            </div>
          </div>
        </div>

        <GenericButton
          buttonType="inline"
          onClick={() => navigate('/auth/sign-in')}
        >
          <Text.Heading5>Voltar para o início</Text.Heading5>
        </GenericButton>
      </Style.Container>
    </div>
  );
};

export default UserHasNoAccount;
