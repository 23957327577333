import { useQueryClient } from 'react-query';
import { gql, request, useMutation } from '~reactQuery/index';

interface ParamsPutUserOrgCustomEvents {
  userOrgCustomEventsId: number;
  userOrganizationsId: number;
  invitedUserOrganizationsId: number;
  status: string;
}

interface PutUserOrgCustomEventsResponse {
  putUserOrgCustomEvents: {
    data: string;
    isValid: boolean;
    messages: string[];
  };
}

export const usePutUserOrgCustomEvents = () => {
  const queryClient = useQueryClient();
  const mutation = gql`
    mutation PutUserOrgCustomEvents($params: UserOrgCustomEventsInput!) {
      putUserOrgCustomEvents(params: $params) {
        data
        isValid
        messages
      }
    }
  `;

  const fetch = async (params: ParamsPutUserOrgCustomEvents) => {
    if (!params.userOrgCustomEventsId) return null;
    const response = await request<PutUserOrgCustomEventsResponse>(mutation, {
      params,
    });

    return response;
  };

  return useMutation(['usePutUserOrgCustomEvents'], fetch, {
    onSettled: () => {
      queryClient.invalidateQueries(['useGetOrganizationPartners']);
    },
  });
};
