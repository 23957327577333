import { colors, metrics } from '~styles';
import styled from 'styled-components';
import { screen } from '~styles/metrics';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 65px;
  background-color: ${colors.white};
  padding: 17px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .total-currency {
    @media ${screen.tablet} {
      margin-left: 16px;
    }
  }

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      cursor: pointer;
    }
  }

  .hamburger-menu {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 27px;
      margin-right: 10px;
    }
  }

  .right-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .hamburger-menu {
      display: flex;
      flex-direction: column;

      width: 18px;
      height: 24px;

      margin-left: 20px;

      span {
        width: 100%;
        height: 2px;
        background-color: ${colors.black};
        margin: 2.5px 0px;
      }
    }
  }

  #navbar {
    position: absolute;
    width: 100%;
    top: 60px;
    z-index: 99999999;
  }
`;

export const NotifcationIconContainer = styled.a<{
  hasUnreadNotification: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover {
    background-color: ${colors.gray};
    transition: all ease-out 0.3s;

    svg {
      margin-top: 0px !important;
      transition: all ease-out 0.3s;
    }
  }

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    background-color: ${(props) =>
      props.hasUnreadNotification ? colors.primary : 'transparent'};
    border-radius: 50%;
    top: 5px;
    right: 5px;
  }
`;
