import * as React from 'react';
import { SVGProps } from 'react';

const WalletFtmSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.86 11.024v-.184c0-1.152-.284-1.753-1.085-2.304-.067-.05-.15-.1-.234-.15l-.5-.3-.552.918.501.3c.067.05.134.083.184.117l.017.017c.233.167.367.283.45.45.1.217.15.518.15.985v.15h1.07ZM9.703 24.58c-.25-.168-.384-.301-.467-.468-.1-.217-.15-.518-.15-.985v-.15H8v.183c0 1.152.284 1.753 1.085 2.304.067.05.15.1.234.15l.5.3.552-.917-.501-.3c-.05-.034-.117-.068-.167-.118Z"
      fill="#1969FF"
    />
    <path
      d="M22.274 10.49c-.034-.301-.234-.568-.551-.735L16.831 7.2c-.517-.267-1.302-.267-1.836 0l-4.891 2.571c-.268.15-.451.35-.518.601h-.033V23.427c.016.334.217.618.55.802l4.892 2.57c.267.134.584.201.918.201.334 0 .651-.067.918-.2l4.892-2.571c.35-.184.55-.484.568-.802V10.523l-.017-.034Zm-11.636 7.495 4.374 2.287c.517.267 1.302.267 1.836 0l4.374-2.304v5.31l-4.558 2.403-.05.017c-.233.117-.467.234-.684.234h-.05c-.234-.017-.484-.134-.735-.268l-4.507-2.354v-5.325Zm4.875-4.958-4.642-2.438 4.642-2.437c.2-.117.617-.117.818 0l4.64 2.437-4.64 2.438c-.2.117-.618.117-.818 0Zm-.134 1.469v4.758l-4.524-2.37 4.524-2.388Zm1.085 4.758v-4.758l4.524 2.37-4.524 2.388Zm-5.826-3.473v-4.09l3.89 2.037-3.89 2.053Zm6.678-2.053 3.906-2.053v4.106l-3.906-2.053Z"
      fill="#1969FF"
    />
  </svg>
);

export default WalletFtmSVG;
