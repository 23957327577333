import * as Style from './Modal.styles';

import { AnimatePresence } from 'framer-motion';
import CloseIcon from '~assets/images/icons/big-close.svg';
import KycModalImage from '~assets/images/kyc/kyc-modal.png';
import { ModalProps } from './Modal.types';
import { When } from 'react-if';
import { breakpoints } from '~styles/metrics';
import { colors } from '~styles';
import useWindowDimensions from '~hooks/windowDimension';

const Modal = (props: ModalProps) => {
  const { width } = useWindowDimensions();

  return (
    <AnimatePresence>
      {props.openModal ? (
        <Style.ModalBackground
          key="fade"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Style.ModalContainer>
            <Style.ImageContainer>
              <Style.CloseBtn onClick={props.closeModal}>
                <CloseIcon />
              </Style.CloseBtn>
              <img src={KycModalImage} alt="kyc-socio-modal" />
            </Style.ImageContainer>

            <When condition={breakpoints.tablet >= width}>
              <Style.CloseBtn className="black" onClick={props.closeModal}>
                <CloseIcon color={colors.black} />
              </Style.CloseBtn>
            </When>

            <Style.BodyContainer>{props.children}</Style.BodyContainer>
          </Style.ModalContainer>
        </Style.ModalBackground>
      ) : null}
    </AnimatePresence>
  );
};

export default Modal;
