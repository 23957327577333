import { create } from 'zustand';

export type Sections =
  | 'createPix'
  | 'payBills'
  | 'payWithPix'
  | 'send'
  | 'convert'
  | 'deletePixKey'
  | 'recoveryPin'
  | 'default'
  | 'transfer'
  | 'deposit'
  | 'changePasswordModals'
  | 'changePinModals'
  | 'enableTwoFaModals'
  | 'disableTwoFaModals'
  | 'sendNewPin'
  | 'recoveryCodesModals'
  | 'createUserWithPermission'
  | 'transferBatch'
  | 'newLimit'
  | 'depositBill'
  | 'refund';

interface OpenModalMultiStep {
  openModalMultiStep: boolean;
  setOpenModalMultiStep: (boolean: boolean) => void;
  setSection: (value: Sections) => void;
  setParams: (value: object) => void; // set in the end of the function when open modal
  section: Sections;
  params: any;
}

export const useActionsOnModalMultiStep = create<OpenModalMultiStep>((set) => ({
  openModalMultiStep: false,
  section: 'default',
  params: {},
  setParams: (value) => set(() => ({ params: { ...value } })),
  setSection: (value) => set(() => ({ params: {}, section: value })),
  setOpenModalMultiStep: (value) => set(() => ({ openModalMultiStep: value })),
}));
