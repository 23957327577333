import { gql, request, useQuery } from '~reactQuery/index';

import { CAPACCOUNT_QUERY_KEYS } from '~src/constants/capaccount';

interface BalanceHistory {
  from: string;
  userOrganizationId: number;
  to: string;
}

interface Balance {
  balance: number;
  currency: string;
  balanceBrl: number;
  price: number;
}

interface HistoryEntry {
  acessoBalance: number;
  walletsBalance: Balance[];
}

interface BalancesHistoryData {
  id: number;
  user: null | any; 
  history: {
    balances: HistoryEntry[];
    dates: string[];
  };
  createdAt: string;
  updatedAt: string;
}

interface BalancesHistoryResponse {
  balancesHistory: {
    data: BalancesHistoryData[];
    isValid: boolean;
    messages: string[];
  };
}

export const useGetBalanceHistory = (params: BalanceHistory) => {
  const QUERY = gql`
    query Data($params: BalancesHistory!) {
      balancesHistory(params: $params) {
        data {
          id
          user
          history
          createdAt
          updatedAt
        }
        isValid
        messages
      }
    }
  `;

  const fetcher = async () => {
    if (!params.userOrganizationId) return null;

    const response = await request<BalancesHistoryResponse>(QUERY, {
      params,
    });
    return response?.balancesHistory?.data || [];
  };

  return useQuery([CAPACCOUNT_QUERY_KEYS.GET_CAPACCOUNT_INFO, params.userOrganizationId], fetcher, {
    refetchInterval: 60000,
  });
};