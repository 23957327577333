import { useRef, useState } from 'react';

export const useNativeCarouselScrolling = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const onScroll = (e: any) => setScrollPosition(e.target.scrollLeft);

  const next = () => {
    if (!containerRef.current) return;

    const endOfTheDiv = containerRef.current.scrollWidth;

    if (scrollPosition + 500 >= endOfTheDiv) return;

    setScrollPosition((previous) => previous + 500);
    containerRef.current.scrollTo({
      top: 0,
      left: scrollPosition + 500,
      behavior: 'smooth',
    });
  };

  const prev = () => {
    if (!containerRef.current) return;

    if (scrollPosition === 0) return;

    setScrollPosition((previous) => previous - 500);

    containerRef.current.scrollTo({
      top: 0,
      left: scrollPosition - 500,
      behavior: 'smooth',
    });
  };

  const scrollWidth = containerRef.current?.scrollWidth ?? 0;
  const clientWidth = containerRef.current?.clientWidth ?? 0;
  const scrollLeft = containerRef.current?.scrollLeft ?? 0;

  const showPrevButton = scrollLeft !== 0;
  const showNextButton = scrollWidth - clientWidth > scrollLeft;

  return {
    containerRef,
    onScroll,
    next,
    prev,
    showPrevButton,
    showNextButton,
  };
};
