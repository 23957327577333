import { gql, request, useQuery } from '~reactQuery';

import { CurrencyPeriod } from '~reactQuery/types/wallets.types';
import { CurrencyTypes, VariationList } from 'src/@types/currency.types';

export interface CurrenciesVariationsInput {
  convert: string;
  symbol?: CurrencyTypes[];
  period: CurrencyPeriod;
}

export interface CurrenciesVariationsResponse {
  currenciesVariations: {
    quote: string;
    data: VariationList[];
  };
}

export const useCurrenciesVariations = (params: CurrenciesVariationsInput) => {
  const QUERY = gql`
    query CurrenciesVariations($params: CurrenciesVariationsInput) {
      currenciesVariations(params: $params) {
        quote
        data
      }
    }
  `;

  const fetcher = async () => {
    if (!params.symbol) return null;
    const response = await request<CurrenciesVariationsResponse>(QUERY, {
      params,
    });
    return response.currenciesVariations;
  };

  return useQuery(['CurrenciesVariations', params.period], fetcher, {
    refetchInterval: 60000,
  });
};
