import * as Style from './CheckSelect.styles';
import * as Text from '~styles/text';

import { Else, If, Then } from 'react-if';

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import BankIcon from '~assets/images/icons/bank.svg';
import BlueIconFiled from '~assets/images/icons/kyc/blue-check-full-rounded.svg';
import { CheckSelectProps } from './CheckSelect.types';
import { RoundButtonIcon } from '~components/Buttons';
import { colors } from '~styles';
import { formatCurrencyFn } from '~helpers/format/currency';
import { forwardRef } from 'react';
import { getSelectedUserOrganization } from '~helpers/device';
import { useCurrencyExchange } from '~reactQuery/queries/wallet/useCurrencyExchange';

const CheckSelect = forwardRef((props: CheckSelectProps, _ref) => {
  // TODO: Remove this logic to outside, this is a quick workaround

  const userOrganizationId = getSelectedUserOrganization();

  const { data: valueInPrefCurrency } = useCurrencyExchange(
    {
      amount: String(props.wallet?.funds) || '1',
      fromCurrency: props.wallet?.currency?.isoCode || '',
      toCurrency: 'BRL',
      userOrganizationId: Number(userOrganizationId),
    },
    Boolean(props.walletList && props.wallet)
  );

  const renderWalletRightText = () => {
    if (!props.wallet || !props.walletList) return '';
    return (
      <div className="wallet-righto-text">
        <Text.Heading5>
          ≈ {formatCurrencyFn('BRL', String(valueInPrefCurrency))}
        </Text.Heading5>
        <Text.Paragraph color={colors.grayMedium}>
          {formatCurrencyFn(
            props.wallet.currency.isoCode,
            String(props.wallet.funds || 0)
          )}
        </Text.Paragraph>
      </div>
    );
  };

  // when selected we're going to use roundButtonIcon or BlueIconFileed Icon
  const renderSelectedBehavior = () => {
    if (props.walletList) {
      return (
        <RoundButtonIcon buttonSize="iconWallet" selected>
          <div className="scale-icon">{props.iconComponent}</div>
        </RoundButtonIcon>
      );
    }

    return <BlueIconFiled />;
  };

  const renderSelectBehavior = () => {
    if (props.walletList) {
      return (
        <RoundButtonIcon buttonSize="iconWallet">
          <div className="scale-icon">{props.iconComponent}</div>
        </RoundButtonIcon>
      );
    }

    if (props.iconType !== 'component' && !props.walletList) {
      return <BankIcon />;
    }

    return props.iconComponent;
  };

  return (
    <Style.Container
      onClick={props.disabled ? () => {} : props.onClick}
      disabled={props.disabled}
    >
      <Style.BodyContainer className="move-top">
        <Style.ContainerIcon
          disabled={props.disabled}
          title={props.title}
          currencyCoin={props.text}
        >
          <If condition={!!props.selected && !props.disabled}>
            <Then>{renderSelectedBehavior()}</Then>
            <Else>{renderSelectBehavior()}</Else>
          </If>
        </Style.ContainerIcon>
        <Style.ContainerText>
          <div className="text-wrapper">
            <div className="container-title-and-chip">
              <Text.Heading5
                color={props.disabled ? colors.grayLight : colors.black}
              >
                {props.title}
              </Text.Heading5>
              {props.chipText ? (
                <div
                  className={
                    props.disabled
                      ? 'chip-container disabled'
                      : 'chip-container'
                  }
                >
                  <Text.Smaller
                    color={props.disabled ? colors.black : colors.newBlue}
                  >
                    {props.chipText}
                  </Text.Smaller>
                </div>
              ) : null}
            </div>
            <Text.Small
              color={props.disabled ? colors.grayLight : colors.grayMedium}
              marginTop={5}
            >
              {props.text}
            </Text.Small>
          </div>
        </Style.ContainerText>
        {(props.rightText || props.walletList) && (
          <div className="right-text">
            {props.walletList && props.wallet
              ? renderWalletRightText()
              : props.rightText}
          </div>
        )}
      </Style.BodyContainer>
    </Style.Container>
  );
});

CheckSelect.displayName = 'CheckSelect';

export default CheckSelect;
