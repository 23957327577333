import * as Text from '~styles/text';

import {
  CheckSelect,
  LoadingSpinner,
  RoundButtonIcon,
  Search,
} from '~components/index';
import { Else, If, Then } from 'react-if';
import { formatCurrencyFn, getWalletIcon } from '~helpers/format/currency';
import { useEffect, useMemo, useState } from 'react';

import BankIcon from '~assets/images/icons/24x24/capconta.svg';
import { DepositOptionsPageProps } from '../Deposit.types';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import colors from '~styles/colors';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';
import InfoSVG from '~assets/svg/info';
import InfoSecondary from '~assets/svg/infoSecondary';
import Tooltip from '~components/Tooltip';

function DepositOptions(props: DepositOptionsPageProps) {
  const { setCurrentStep } = props;
  const [optionSelect, setOptionSelect] = useState<any>('');
  const [search, setSearch] = useState('');
  const userOrganizationId = getSelectedUserOrganization();

  function goNext() {
    if (optionSelect !== '') {
      if (optionSelect === 'capAccount') {
        setCurrentStep('bankDeposit');
      } else {
        setCurrentStep('criptoDepositQR', { walletSelected: optionSelect });
      }
    }
  }

  function selectBehavior(keyType: any) {
    if (optionSelect === keyType) {
      setOptionSelect('');
    } else {
      setOptionSelect(keyType);
    }
  }

  const { data: accountInfos } = useGetCapAccountInfo({
    userOrganizationId: Number(userOrganizationId),
  });

  const { data: walletList, isLoading: loadingWallets } = useGetWallets({
    prefCurrency: 'BRL',
    flag: 'list',
    userOrganizationId: Number(userOrganizationId),
  });

  const walletListFiltered = useMemo(() => {
    if (walletList) {
      return walletList
        .filter(
          (w) =>
            w.walletName
              .toLocaleUpperCase()
              .includes(search.toLocaleUpperCase()) ||
            w.currency.isoCode
              .toLocaleUpperCase()
              .includes(search.toLocaleUpperCase())
        )
        .filter(
          (w) =>
            !['USD', 'BRL', 'EUR', 'GBP', 'XAU'].includes(w.currency.isoCode)
        );
    }
    return [];
  }, [walletList, search]);

  useEffect(() => {
    if (props.setStepParams) {
      props.setStepParams({});
    }
  }, []);

  return (
    <>
      <ModalMultiStepHeader
        title="Qual carteira deseja depositar?"
        description="Deposite a partir de transferências bancárias ou por meio da blockchain."
        backButtonAction={() => setCurrentStep('depositOptions')}
      />
      <div className="content-container">
        <Search
          containerStyle={{ marginBottom: 25, marginTop: 10 }}
          onChange={(e) => setSearch(e.target.value)}
          placeHolder="Pesquisar wallet"
        />
        <Text.Heading3 marginTop={25} marginBottom={15}>
          Transferência bancária
        </Text.Heading3>

        <CheckSelect
          title="CapConta"
          text="BRL"
          onClick={() => selectBehavior('capAccount')}
          selected={optionSelect === 'capAccount'}
          iconType="component"
          iconComponent={
            <RoundButtonIcon buttonSize="icon24v2" buttonType="active">
              <BankIcon />
            </RoundButtonIcon>
          }
          rightText={
            <div className="check-select-container-right">
              <Text.Paragraph className="text">Indisponível</Text.Paragraph>
              <Tooltip
                position="bottom-left"
                content={
                  <Text.SmallerBold className="text-tooltip">
                    Para depositar em sua conta bancária, altere o método de
                    depósito para boleto ou transferência bancária.
                  </Text.SmallerBold>
                }
                variant="default"
                className="container-tooltip"
              >
                <InfoSecondary width={14} height={14} />
              </Tooltip>
            </div>
          }
          disabled
        />

        <Text.Heading3 marginTop={25} marginBottom={15}>
          Rede de Criptomoeda
        </Text.Heading3>

        <If condition={loadingWallets}>
          <Then>
            <LoadingSpinner size={60} strokeSize={25} color={colors.primary} />
          </Then>

          <Else>
            {walletListFiltered?.map((w) => {
              return (
                <CheckSelect
                  key={w.walletAddress}
                  title={w.walletName}
                  text={w.currency.isoCode}
                  iconType="component"
                  onClick={() => selectBehavior(w)}
                  selected={optionSelect === w}
                  iconComponent={
                    <RoundButtonIcon
                      buttonSize="iconWallet"
                      buttonType="active"
                    >
                      <div className="wallet-icon">
                        {getWalletIcon(w.currency.isoCode)}
                      </div>
                    </RoundButtonIcon>
                  }
                  rightText={formatCurrencyFn(
                    w.currency.isoCode,
                    String(w.funds)
                  )}
                />
              );
            })}
          </Else>
        </If>
      </div>
      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={optionSelect.length <= 0}
      />
    </>
  );
}

export default DepositOptions;
