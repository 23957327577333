import * as React from 'react';
import { SVGProps } from 'react';

const FavoriteStarSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.143} width={30} height={30} rx={15} fill="#FEF9EB" />
    <path
      d="M15.736 9.466a.511.511 0 0 0-.918 0l-1.458 2.956a.511.511 0 0 1-.385.28l-3.259.47a.511.511 0 0 0-.284.873l2.36 2.301c.12.118.175.287.146.453l-.558 3.247a.511.511 0 0 0 .742.54l2.917-1.533a.512.512 0 0 1 .476 0l2.917 1.532a.511.511 0 0 0 .742-.54l-.558-3.246a.512.512 0 0 1 .148-.453l2.358-2.298a.511.511 0 0 0-.283-.872l-3.26-.474a.512.512 0 0 1-.385-.28l-1.458-2.956Z"
      fill="#F3BA2F"
    />
  </svg>
);

export default FavoriteStarSVG;
