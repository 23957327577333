import * as Style from './PayWithPix.styles';

import { SetCurrentStepProps, StepParamsProps } from './PayWithPix.types';
import { useEffect, useState } from 'react';

import ConfirmCredentials from './ModalSteps/4.ConfirmCredentials';
import ModalMultiStep from '~components/ModalMultiStep';
import PayWithPixStatus from './ModalSteps/5.PayWithPixStatus';
import PaymentDetails from './ModalSteps/3.PaymentDetails';
import SelectWallet from './ModalSteps/2.SelectWallet';
import ShowKeyExample from './ModalSteps/1.ShowKeyExample';
import { WalletType } from '~reactQuery/types/wallets.types';
import { getSelectedUserOrganization } from '~helpers/device';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';

function PayWithPix() {
  const { openModalMultiStep, setOpenModalMultiStep, params } =
    useActionsOnModalMultiStep();

  const [currentStep, setCurrentStep] =
    useState<SetCurrentStepProps>('showKeyExample');
  const [stepParams, setStepParams] = useState<StepParamsProps>(
    {} as StepParamsProps
  );

  const userOrganizationId = getSelectedUserOrganization();

  const { data: capAccountInfo, refetch: refetchCapAccountInfo } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  const capAccountWallet: WalletType = {
    id: 0,
    owner: 0,
    createdAt: '',
    updatedAt: '',
    walletAddress: 'CAPCONTA',
    walletCurrency: 'BRL',
    walletName: 'CapConta',
    funds: Number(capAccountInfo?.available) || 0,
    isOwnerInfoPublic: 0,
    priceInPrefCurrency: 0,
    isFavorite: 0,
    isPublic: 0,
    currency: {
      id: 0,
      type: 'fiat',
      name: 'CapConta',
      symbol: 'R$',
      isoCode: 'BRL',
      precision: 2,
    },
    ordering: 1,
    enabled: 1,
    enabledTransfer: true,
    enabledConvert: true,
    enabledPay: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  };

  function setStepsAndParams(
    step: SetCurrentStepProps,
    paramsFn?: object,
    cleanParams?: boolean
  ) {
    if (cleanParams) {
      setStepParams({} as StepParamsProps);
    } else if (paramsFn) {
      setStepParams((prev) => ({ ...prev, ...paramsFn } as StepParamsProps));
    }
    setCurrentStep(step);
  }

  const modalStepsAndDetails = {
    showKeyExample: {
      component: (
        <ShowKeyExample
          key="showKeyExample"
          setCurrentStep={setStepsAndParams}
          readerFromFile={params.pixQrCode}
        />
      ),
      progressValue: 30,
    },
    selectWallet: {
      component: (
        <SelectWallet
          key="selectWallet"
          qrCode={stepParams?.qrCode}
          billData={stepParams?.billData}
          editedValue={stepParams?.editedValue}
          capAccountWallet={capAccountWallet}
          refetchCapAccount={refetchCapAccountInfo}
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 45,
    },
    paymentDetails: {
      component: (
        <PaymentDetails
          key="paymentDetails"
          qrCode={stepParams?.qrCode}
          billData={stepParams?.billData}
          editedValue={stepParams?.editedValue}
          selectedOption={stepParams?.selectedOption}
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 60,
    },
    confirmCredentials: {
      component: (
        <ConfirmCredentials
          key="confirmCredentials"
          description={stepParams?.description}
          qrCode={stepParams?.qrCode}
          billData={stepParams?.billData}
          editedValue={stepParams?.editedValue}
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 75,
    },
    payBillsStatus: {
      component: (
        <PayWithPixStatus
          key="payWithPixStatus"
          billData={stepParams?.billData}
          paymentResp={stepParams?.paymentResp}
          editedValue={stepParams?.editedValue}
          statusOfPayment={stepParams?.statusOfPayment}
          setCurrentStep={setStepsAndParams}
          setOpenModalMultiStep={setOpenModalMultiStep}
        />
      ),
      progressValue: 100,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  // CLEAR STEP ON MODAL CLOSE
  useEffect(() => {
    if (!openModalMultiStep) setCurrentStep('showKeyExample');
  }, [openModalMultiStep]);

  return (
    <Style.Container>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={currentStepInfos.progressValue}
      >
        {currentStepInfos.component}
      </ModalMultiStep>
    </Style.Container>
  );
}

export default PayWithPix;
