import styled from 'styled-components';
import colors from '~styles/colors';

export const ContainerMaintenance = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .description {
    margin-right: 30px;
  }

  @media (max-width: 740px) {
    flex-direction: column-reverse;

    .description {
      margin-right: 0px;
      margin-top: 20px;
    }
  }
`;
