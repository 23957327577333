import { colors } from '~styles';
import { screen } from '~styles/metrics';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { FooterStyleProps } from './FooterButton.types';

export const FooterWrapper = styled.div<FooterStyleProps>`
  width: 100%;
  height: 77px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  position: absolute;
  bottom: 0;
  right: 0;

  ${(props) =>
    props.hasTopBorder &&
    css`
      border-top: 1px solid ${transparentize(0.7, colors.grayLight)};
    `}

  .buttons-wrapper {
    width: 100%;
    height: 100%;
    padding: 0px 70px 0px 50px;
    display: flex;
    align-items: center;
    justify-content: ${(props) =>
      props.hasLeftButton ? 'space-between' : 'flex-end'};
  }

  @media ${screen.tablet} {
    .buttons-wrapper {
      padding: 0px 70px 0px 20px;
    }
  }

  .generic-button-left {
    justify-content: flex-start;
  }

  .leftButton {
    font-size: 0.93rem;
    cursor: pointer;
    outline: none;
    border: transparent;
    display: flex;
    align-items: center;
  }
`;
