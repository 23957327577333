import { MutationCache, QueryCache, QueryClient } from 'react-query';
import { handleErrors } from '~reactQuery/handleErrors/handleError';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: async (error: any) => {
      await handleErrors(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: async (error: any) => {
      await handleErrors(error);
    },
  }),
});
