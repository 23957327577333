import * as Text from '~styles/text';
import { GenericRow, RoundButtonIcon } from '~components';
import colors from '~styles/colors';
import CopySVG from '~assets/svg/copy';
import { formatCurrencyFn } from '~helpers/format/currency';
import dayjs from 'dayjs';
import { copyText } from '~helpers/messages';
import {
  formatDataShowMonth,
  removeNumbersFromString,
} from '~validations/stringsValidate';
import { useCurrencyExchange } from '~reactQuery/queries/wallet/useCurrencyExchange';
import { getSelectedUserOrganization } from '~helpers/device';
import CoinPairIcon from '~assets/svg/statement/coinPair';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import {
  TransactionDetailsTypes,
  WalletInfoTypes,
} from '../../Activities.types';

const Exchange = ({
  transactionDetails,
  exchangeResult,
  walletInfo,
}: {
  transactionDetails: TransactionDetailsTypes;
  exchangeResult: number | null | undefined;
  walletInfo: WalletInfoTypes | null;
}) => {
  function formatNumber(num: number): string {
    return (num / 100)?.toFixed(2);
  }
  const userOrganizationId = getSelectedUserOrganization();
  const { data: userData } = useGetPartner();

  const { data: capAccountInfo } = useGetCapAccountInfo({
    userOrganizationId: Number(userOrganizationId),
  });

  let CAPB = null;

  userData?.data?.userOrganizations?.map((org) => {
    if (org?.organizations?.account === capAccountInfo?.account) {
      CAPB = org?.organizations?.walletsInternalCredentials?.userBusinessId;
      return CAPB;
    }
    return null;
  });

  const { data: oneUnitInBrl } = useCurrencyExchange(
    {
      amount: formatNumber(Number(100)),
      fromCurrency: String(walletInfo?.currency?.isoCode || ''),
      toCurrency: 'BRL',
      userOrganizationId: Number(userOrganizationId),
    },
    Boolean(transactionDetails?.data?.value)
  );

  const renderValueInCrypto = () => {
    if (walletInfo?.currency?.isoCode) {
      return ` ${formatCurrencyFn(
        walletInfo?.currency?.isoCode,
        String(exchangeResult),
        true
      )} ${walletInfo?.currency?.isoCode}`;
    }
    return 'Não encontrado';
  };

  const renderValueQuotation = () => {
    if (walletInfo?.currency?.isoCode) {
      return `1 ${walletInfo?.currency?.isoCode} = ${formatCurrencyFn(
        'BRL',
        String(Number(oneUnitInBrl))
      )}`;
    }
    return 'Não encontrado';
  };

  return (
    <>
      <div className="detailsRow">
        <Text.Paragraph>Valor em cripto</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph color={colors.grayMedium}>
            {renderValueInCrypto()}
          </Text.Paragraph>
        </div>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Cotação</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph color={colors.grayMedium}>
            {renderValueQuotation()}
          </Text.Paragraph>
        </div>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Valor em real</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph color={colors.grayMedium}>
            {typeof transactionDetails?.data?.value === 'string' &&
              formatCurrencyFn(
                'BRL',
                String(Number(transactionDetails.data.value) / 100)
              )}
          </Text.Paragraph>
        </div>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Data e Horário</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>
          {formatDataShowMonth(transactionDetails?.timestamp)} •{' '}
          {dayjs(transactionDetails?.timestamp).format('H:mm')}
        </Text.Paragraph>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Descrição</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph color={colors.grayMedium}>
            {transactionDetails?.data?.description || '----'}
          </Text.Paragraph>
        </div>
      </div>

      <div className="detailsDivider">
        <div className="icon">
          <CoinPairIcon />
        </div>
        <Text.Paragraph>Favorecido</Text.Paragraph>
        <hr />
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Nome</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph color={colors.grayMedium}>
            {removeNumbersFromString(String(capAccountInfo?.name))}
          </Text.Paragraph>
        </div>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>CAP-B</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>{CAPB}</Text.Paragraph>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Carteira de origem</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph color={colors.grayMedium}>
            {(transactionDetails?.data?.requisite.type ===
              'EXCHANGE_CAPCONTA_TO_CAPWALLET' &&
              'Carteira CapConta') ||
              (transactionDetails?.data?.requisite.type ===
                'EXCHANGE_CAPWALLET_TO_CAPCONTA' &&
              walletInfo?.walletName !== undefined
                ? `Carteira de ${walletInfo?.walletName}`
                : 'Não encontrado')}
          </Text.Paragraph>
        </div>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Carteira de destino</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph color={colors.grayMedium}>
            {(transactionDetails?.data?.requisite.type ===
              'EXCHANGE_CAPWALLET_TO_CAPCONTA' &&
              'Carteira CapConta') ||
              (transactionDetails?.data?.requisite.type ===
                'EXCHANGE_CAPCONTA_TO_CAPWALLET' &&
              walletInfo?.walletName !== undefined
                ? `Carteira de ${walletInfo?.walletName}`
                : 'Não encontrado')}
          </Text.Paragraph>
        </div>
      </div>

      <GenericRow
        title="Protocolo"
        description={transactionDetails?.authCode}
        noHorizontalPadding
        leftContentIconType="none"
        rightComponent={
          <RoundButtonIcon
            buttonSize="icon16"
            onClick={() =>
              copyText(
                transactionDetails?.authCode,
                'Código de Protocolo copiado com sucesso!'
              )
            }
          >
            <CopySVG />
          </RoundButtonIcon>
        }
        rightContentType="component"
      />
    </>
  );
};

export default Exchange;
