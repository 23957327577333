import * as React from 'react';
import { SVGProps } from 'react';

const WalletGbpSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m10.49 22.117.254-.135c.27-.143.522-.319.75-.522.204-.185.382-.396.53-.629a3.764 3.764 0 0 0 .425-1.968c0-.226-.02-.453-.059-.677h-1.9v-2.353h1.278a7.94 7.94 0 0 1-.323-1.971 4.653 4.653 0 0 1 .346-1.819c.223-.532.56-1.008.987-1.394a4.294 4.294 0 0 1 1.496-.871c.602-.2 1.233-.3 1.867-.293a5.626 5.626 0 0 1 1.72.255c.52.162 1.003.42 1.427.762.418.346.76.776 1.002 1.262.257.532.41 1.108.452 1.697l.044.53H18l-.037-.449a1.627 1.627 0 0 0-1.138-1.642 1.614 1.614 0 0 0-.708-.056 2.026 2.026 0 0 0-.788.147 1.733 1.733 0 0 0-.971.991c-.09.23-.136.473-.135.72.028.724.157 1.442.383 2.13h3.036v2.335h-2.471c.015.112.022.225.022.338a5.04 5.04 0 0 1-.43 2.2c-.1.205-.219.401-.354.586.2.005.4.022.599.05.23.031.478.071.747.12l.796.146.767.147c.233.046.44.082.618.113.112.021.225.034.338.038a3.938 3.938 0 0 0 1.822-.469l.709-.37v2.902l-.285.136c-.682.31-1.423.47-2.172.466a8.018 8.018 0 0 1-2.202-.445 5.57 5.57 0 0 0-4.959.398l-.696.332v-2.738Z"
      fill="#986AC4"
    />
  </svg>
);

export default WalletGbpSVG;
