import * as React from 'react';

import { SVGProps } from 'react';

const FilterSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 5.75H3v-1.5h10v1.5Zm-3 6H6v-1.5h4v1.5Zm-5-3h6v-1.5H5v1.5Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default FilterSVG;
