import InfoIcon from '~assets/images/icons/info.svg';
import { colors } from '~styles';
import * as Text from '~styles/text';
import { forwardRef, useRef } from 'react';
import { When } from 'react-if';
import * as Style from './InputPhone.styles';
import { InputPhoneProps } from './InputPhone.types';

const InputPhone = forwardRef((props: InputPhoneProps, _ref) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const componentWidth = Number(componentRef?.current?.offsetWidth);

  const isInputShort = componentWidth < 400;
  const isErrorBig = (props.error ? props.error.length : 0) >= 40;
  const hasErrorToBeInBottom = isInputShort || isErrorBig;

  return (
    <Style.Container
      {...props}
      ref={componentRef}
      errorInBottom={hasErrorToBeInBottom}
    >
      <Style.TopContent>
        <div className="label-container">
          <When condition={!!props.label}>
            <Text.Paragraph color={colors.grayMedium}>
              {props.label}
            </Text.Paragraph>
          </When>
        </div>

        <When condition={!!props.error && !hasErrorToBeInBottom}>
          <div className="error-container">
            <Text.Small color={colors.danger}>{props.error}</Text.Small>
          </div>
        </When>
      </Style.TopContent>

      <div className="input-container">
        <Style.Input
          {...props}
          {...props.hookForm}
          onChange={(event) => {
            if (props.hookForm) {
              props.hookForm.onChange(event);
            }

            const { value } = event.target;

            if (!props.maskFunction) {
              return value;
            }

            const maskedValue = props.maskFunction(value);
            event.target.value = maskedValue;
            return maskedValue;
          }}
        />
        <div>{props.ddi || '+55'}</div>
      </div>

      <Style.BottomContent>
        <When condition={!!props.error && hasErrorToBeInBottom}>
          <div className="error-container">
            <Text.Small color={colors.danger}>{props.error}</Text.Small>
          </div>
        </When>

        <When condition={!!props.helperText}>
          <div className="helper-container">
            <div className="helper-icon">
              <InfoIcon />
            </div>
            <Text.Small color={colors.grayRegular} marginLeft={6}>
              {props.helperText}
            </Text.Small>
          </div>
        </When>
      </Style.BottomContent>
    </Style.Container>
  );
});

InputPhone.displayName = 'InputPhone';

export default InputPhone;
