import {
  ButtonCloseToast,
  IconInformationContainer,
  ToastCustom,
} from './Toast.styles';
import { Heading1Medium, Paragraph } from '~styles/text';
import { Slide, ToastOptions, toast } from 'react-toastify';
import SmallClose from '~assets/images/icons/small-close.svg';
import { When } from 'react-if';

import DangerOutline from '../../assets/images/icons/danger-outline.svg';

import SuccessIconOutline from '../../assets/images/icons/success-toast-icon.svg';
import InfoToastSVG from '~assets/svg/infoToast';

interface IToastComponentProps extends ToastOptions {
  title: string;
  subtitle?: string;
  autoCloseEnable?: boolean;
}

const ToastComponent: React.FC<{
  title: string;
  subtitle: string | undefined;
}> = ({ title, subtitle }) => {
  return (
    <ToastCustom>
      <Heading1Medium fontSize={1.063}>{title}</Heading1Medium>

      <When condition={!!subtitle}>
        <Paragraph marginTop={3}>{subtitle}</Paragraph>
      </When>
    </ToastCustom>
  );
};

export const ErrorToast = ({
  title,
  subtitle,
  autoCloseEnable,
}: IToastComponentProps) => {
  toast.error(
    () => {
      return <ToastComponent title={title} subtitle={subtitle} />;
    },
    {
      icon: () => (
        <IconInformationContainer>
          <DangerOutline />
        </IconInformationContainer>
      ),

      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      hideProgressBar: true,
      autoClose: autoCloseEnable ? 3000 : false,
      bodyStyle: {
        alignItems: subtitle ? 'flex-start' : 'center',
      },
      transition: Slide,
      position: 'bottom-right',
      toastId: title,
      closeButton: () => (
        <ButtonCloseToast>
          <SmallClose />
        </ButtonCloseToast>
      ),
    }
  );
};

export const SuccessToast = ({
  title,
  subtitle,
  autoCloseEnable,
}: IToastComponentProps) => {
  toast.success(
    () => {
      return <ToastComponent title={title} subtitle={subtitle} />;
    },
    {
      icon: () => (
        <IconInformationContainer>
          <SuccessIconOutline />
        </IconInformationContainer>
      ),
      closeOnClick: true,
      pauseOnHover: true,
      position: 'bottom-right',
      draggable: true,
      progressStyle: {
        height: '3px',
      },
      hideProgressBar: true,
      autoClose: autoCloseEnable ? 3000 : false,
      transition: Slide,
      toastId: title,
      closeButton: () => (
        <ButtonCloseToast>
          <SmallClose />
        </ButtonCloseToast>
      ),
    }
  );
};

export const InfoToast = ({
  title,
  subtitle,
  autoCloseEnable,
}: IToastComponentProps) => {
  toast.info(
    () => {
      return <ToastComponent title={title} subtitle={subtitle} />;
    },
    {
      icon: () => (
        <IconInformationContainer>
          <InfoToastSVG />
        </IconInformationContainer>
      ),

      closeOnClick: true,
      position: 'bottom-right',
      pauseOnHover: true,
      draggable: true,
      progressStyle: {
        height: '3px',
      },
      hideProgressBar: true,
      autoClose: autoCloseEnable ? 3000 : false,
      transition: Slide,
      closeButton: () => (
        <ButtonCloseToast>
          <SmallClose />
        </ButtonCloseToast>
      ),
    }
  );
};
