export type Status =
  | 'PENDING'
  | 'DONE'
  | 'VERIFIED'
  | 'AWAITING'
  | 'REPROVED'
  | 'APPROVED'
  | 'DENIED'
  | 'UNDERCHECKING';

export type BusinessSize = 'ME' | 'MEI' | 'EPP';

export type BusinessType = 'MEI' | 'EI' | 'EIRELI' | 'SLU' | 'LTDA' | 'SA';

export type DocumentType = 'RG' | 'CNH';

export type AccessFlags =
  | 'ALL'
  | 'TED'
  | 'PIX'
  | 'BILLS'
  | 'EVENTS'
  | 'WALLETS_LIST'
  | 'WALLETS_TRANSFER';

export interface WalletData {
  isOwnerInfoPublic: boolean;
  isPublic: boolean;
  isFavorite: boolean;
}

// eslint-disable-next-line no-shadow
export enum NotificationFlags {
  ALL = 'ALL',
  PROMOTION = 'PROMOTION',
  TRANSACTION = 'TRANSACTION',
  NEWS = 'NEWS',
  DEFAULT = 'DEFAULT',
}

interface WalletsInternalCredentials {
  id: number;
  pass: string;
  internalPin: string;
  sessionKey: string;
  userBusinessId: number;
  organizations: Organizations;
}

export interface ProcessResponse {
  process:
    | {
        id: number;
        userId: number;
        createdAt: string;
        updatedAt: string;
        deleted: boolean;
        deletedAt: string;
        processStatus: Status;
        business: Business;
      }
    | undefined
    | null;
}

interface BanklyPartners {
  id: number;
  companyKey: string;
  grantType: string;
  organizations: Organizations;
}

export type IntegratorType =
  | 'BUSINESS_ACCOUNT'
  | 'BUSINESS_ACCOUNT_PAYMENT'
  | 'ORGANIZATION'
  | 'WALLETS'
  | 'DATA_VALID_BUSINESS'
  | 'DATA_VALID_PARTNERDATA'
  | 'BIZ_BANKING'
  | 'BUSINESS_DOCUMENTS'
  | 'BUSINESS_PARTNERS';

export interface IntegratorStatus {
  createdAt: string;
  id: number;
  processId: number;
  response: JSON;
  status: Status;
  type: IntegratorType;
  updatedAt: string;
}

export interface User {
  id: number;
  capu: string;
  migrated: boolean;
  registerName: string;
  socialName: string;
  phone: string;
  email: string;
  documentNumber: string;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
  userOrganizations: [UserOrganizations];
  settings: Settings;
}

export interface UserPartnerResp {
  id: number;
  registerName: string;
  socialName: string;
  capu: string;
  documentNumber: string;
  email: string;
  phone: string;
  active: boolean;
  settings: Settings;
  userOrganizations: UserOrganizations[];
}
export interface UserOrganizations {
  id: number;
  flags: [AccessFlags];
  admin: boolean;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  organizationsId: number;
  organizations: Organizations;
  settings: Settings;
  permissions: string[];
}

export interface PartnerDataInput {
  id: number;
  legalRepresent: string;
  politicallyExposed: string;
  documentNumber: string;
  fileFrontDocument: string;
  fileBackDocument: string;
  registerName: string;
  socialName: string;
  countryCode: string;
  phone: string;
  number: string;
  zipCode: string;
  addressLine: string;
  buildingNumber: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  birthDate: string;
  motherName: string;
  email: string;
  documentType: DocumentType;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  deletedAt: string;
  status: Status;
  businessId: number;
}
export interface BusinessInput {
  id: number;
  documentNumber: string;
  businessName: string;
  tradingName: string;
  businessType: BusinessType;
  businessSize: BusinessSize;
  declaredAnnualBilling: string;
  businessCnae: string;
  countryCode: string;
  phone: string;
  site: string;
  businessEmail: string;
  zipCode: string;
  addressLine: string;
  buildingNumber: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  partnerData: PartnerDataInput;
  processId: number;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  deletedAt: string;
  status: Status;
}

export interface Incomes {
  id: number;
  income: string;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  deletedAt: string;
  businessDataId: number;
}

export interface Settings {
  id: number;
  enablePin: boolean;
  twoFA: boolean;
  language: string;
  notificationFlags: [NotificationFlags];
  internalNotificationFlags: [NotificationFlags];
  createdAt: string;
  updatedAt: string;
}

export interface Organizations {
  id: number;
  name: string;
  country: string;
  businessCode: string;
  setup: string;
  bankCompe: string;
  branch: string;
  account: string;
  image: string;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
  walletsInternalCredentialsId: number;
  banklyPartnersId: number;
  walletsInternalCredentials: WalletsInternalCredentials;
  partner: BanklyPartners;
  settings: Settings;
}

export interface Fields {
  id: number;
  field: string;
  description: string;
  status: Status;
  integratorHasApproved: boolean;
  businessId: number;
  businessDataId: number;
  partnerDataId: number;
}
export interface BusinessData {
  id: number;
  fileCNPJ: string;
  fileQSA: string;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  deletedAt: string;
  socialContract: string;
  lastContractChange: string;
  socialStatute: string;
  lastElection: string;
  preventionPolicy: string;
  proofOfRegularity: string;
  accountingBalance: string;
  status: Status;
  incomes: [Incomes];
  fields: [Fields];
}

export interface PartnerData {
  id: number;
  declaredIncome: string;
  isPowerOfAttorney: boolean;
  powerOfAttorney: string;
  powerOfAttorneyStartDate?: string;
  powerOfAttorneyEndDate?: string;
  memberQualification: string;
  participationPercentage: string;
  legalRepresent: string;
  politicallyExposed: string;
  pep: string;
  documentNumber: string;
  fileFrontDocument: string;
  fileBackDocument: string;
  registerName: string;
  socialName: string;
  countryCode: string;
  number: string;
  zipCode: string;
  addressLine: string;
  buildingNumber: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  birthDate: string;
  motherName: string;
  email: string;
  documentType: DocumentType;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  deletedAt: string;
  status: Status;
  businessId: number;
  fields: [Fields];
}
export interface Business {
  id: number;
  declaredAnnualBilling: string;
  declaredIncome: string;
  participationPercentage: string;
  documentNumber: string;
  businessName: string;
  tradingName: string;
  businessType: BusinessType;
  businessSize: BusinessSize;
  businessCnae: string;
  countryCode: string;
  phone: string;
  site: string;
  businessEmail: string;
  zipCode: string;
  addressLine: string;
  buildingNumber: string;
  complement: string;
  isCryptoTrader?: boolean;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  numberOfPartners: number;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  deletedAt: string;
  status: Status;
  companyStartDate: string;
  businessDataId: number;
  businessData: BusinessData;
  partnerData: [PartnerData];
  fields: [Fields];
}

export interface CreatePartnerDataInput {
  processId?: number;
  legalRepresent?: string;
  politicallyExposed?: string;
  documentNumber?: string;
  fileFrontDocument?: string;
  fileBackDocument?: string;
  registerName?: string;
  socialName?: string;
  countryCode?: string;
  phone?: string;
  number?: string;
  zipCode?: string;
  addressLine?: string;
  buildingNumber?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  country?: string;
  birthDate?: string;
  motherName?: string;
  email?: string;
  documentType?: 'RG' | 'CNH';
  partner?: [CreatePartnerDataInput];
}

export interface Phone {
  countryCode: string;
  number: string;
}

export interface Address {
  zipCode: string;
  addressLine: string;
  buildingNumber: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
}

export interface InvitedUsersResp {
  name: string;
  admission: string;
  permission: string;
  userOrganizationId: number;
  buttonContent: boolean;
  id: number;
  registerName: string;
  socialName: string;
  capu: null | string;
  documentNumber: string;
  email: string;
  phone: string;
  active: boolean;
  settings: {
    id: number;
    enablePin: boolean;
    twoFA: boolean;
    language: string;
    notificationFlags: string[];
    createdAt: string;
    updatedAt: string;
  };
  userOrganizations: UserOrganizations[];
  userOrOrgCustomEvents: UserOrOrgCustomEvent[];
}

export interface UserOrOrgCustomEvent {
  id: number;
  customEventsId: number;
  deletedAt: string;
  createdAt: string;
  status: string;
}
