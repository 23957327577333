import * as Text from '~styles/text';

import React, { useMemo } from 'react';

import DropDownList from '~components/DropDownList';
import { Input } from '~components/Inputs/InputForm/InputForm.styles';
import { InputSelect } from '~components/Inputs';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { PixModalStepsProps } from '../../Transfer.types';
import { accountsTypes } from '~data/bankUtils';
import { capitalizeName } from '~helpers/format/user';
import { useFormContext } from 'react-hook-form';
import { useGetBrlBanks } from '~reactQuery/queries/bank/useGetBrlBank';
import colors from '~styles/colors';

function FillManualTransferInfo(props: PixModalStepsProps) {
  const { setPixStep: setCurrentStep } = props;

  const { getValues, register, watch, setValue } = useFormContext();
  const formValues = getValues();
  const { data: brlBankList, isLoading: loadingBankList } = useGetBrlBanks();

  const nameCapitalized = capitalizeName(
    formValues?.keyResult?.resolve?.key?.holderName ||
      formValues?.name ||
      formValues?.contact?.name ||
      formValues?.manualDetails?.name
  );
  const isFilled =
    watch('manualDetails.account')?.length < 1 ||
    watch('manualDetails.branch')?.length < 1 ||
    watch('manualDetails.accountNumber')?.length < 1 ||
    !watch('manualDetails.bankIspb');

  const goBack = () => {
    if (formValues?.contact || formValues?.manualDetails?.fromButton) {
      setCurrentStep('pixMethod');
      return;
    }
    if (formValues?.manualDetails?.docNum) {
      setCurrentStep('manualTransferDocument');
      return;
    }
    setCurrentStep('manualTransfer');
  };
  const goNext = () => setCurrentStep('chooseOriginWallet');

  const parseBankList = useMemo(() => {
    if (!brlBankList) return [{ value: '', label: 'Carregando...' }];

    const mapBankList = brlBankList
      ?.map((bank) => ({
        value: bank.bankIspb,
        label: `${bank.bankName}`,
        bankCode: bank.bankCompe,
        bankIspb: bank.bankIspb,
      }))
      .sort((a, b) => (a.bankCode < b.bankCode ? -1 : 1));

    return mapBankList;
  }, [brlBankList]);

  const blockInvalidChar = (e: any) => {
    const allowedChars = [
      '-',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '0',
      'Backspace',
    ];

    if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'v') {
      return;
    }

    if (!allowedChars.includes(e.key) && e.key !== undefined) {
      e.preventDefault();
    }
  };

  const setBankCode = (code: string) =>
    setValue('manualDetails.bankIspb', code);

  return (
    <>
      <ModalMultiStepHeader
        title="Novo TED ou Pix Manual"
        description={`Transferindo para ${nameCapitalized}`}
        backButtonAction={goBack}
      />

      <div className="content-container">
        <Text.Paragraph
          marginBottom={7}
          marginTop={25}
          color={colors.grayMedium}
        >
          Banco ou Instituição Financeira
        </Text.Paragraph>

        <DropDownList
          list={parseBankList}
          selected={watch('manualDetails.bankIspb')}
          setSelected={setBankCode}
          inputLabel="Pesquisar instituição"
        />

        <Text.Paragraph
          marginBottom={7}
          marginTop={25}
          color={colors.grayMedium}
        >
          Tipo de Conta
        </Text.Paragraph>

        <InputSelect
          defaultValue="CHECKING"
          {...register('manualDetails.accountType')}
          options={accountsTypes}
        />

        <div className="row justify-space-between">
          <div className="column w-48">
            <Text.Paragraph
              marginBottom={7}
              marginTop={25}
              color={colors.grayMedium}
            >
              Agência
            </Text.Paragraph>

            <Input
              {...register('manualDetails.branch')}
              onKeyDown={blockInvalidChar}
              placeholder="0000"
              type="number"
              min={0}
              onWheel={(e: any) => e.target.blur()}
            />
          </div>

          <div className="column w-48">
            <Text.Paragraph
              marginBottom={7}
              marginTop={25}
              color={colors.grayMedium}
            >
              Conta com dígito
            </Text.Paragraph>

            <Input
              {...register('manualDetails.accountNumber')}
              onKeyDown={blockInvalidChar}
              placeholder="0000"
              type="text"
              onWheel={(e: any) => e.target.blur()}
              min={0}
            />
          </div>
        </div>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={isFilled}
        singleButtonLoading={loadingBankList}
      />
    </>
  );
}

export default FillManualTransferInfo;
