import * as React from 'react';
import { SVGProps } from 'react';

const WalletEthSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m14.928 20.322 5.305-3.146-5.305-8.828-5.307 8.83 5.307 3.144Z"
      fill="#808696"
    />
    <path
      d="m14.928 26.16 5.309-7.498-5.31 3.143-5.305-3.147 5.306 7.502Z"
      fill="#808696"
    />
  </svg>
);

export default WalletEthSVG;
