import * as Style from '../Kyc.styles';
import * as Text from '~styles/text';

import ClipIcon from '~assets/images/icons/kyc/clip.svg';
import { FooterButton } from '~components';
import { StartProps } from '../Kyc.types';
import { When } from 'react-if';
import { necessaryDocs } from '~data/kyc';
import { parseError } from '~reactQuery';
import { useCreateProcessMutation } from '~reactQuery/mutations/kyc/useCreateProcess';
import { useGetCustomerData } from '~reactQuery/queries/user/useGetCustomerData';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const Start = ({ goToStep, kycProcessData }: StartProps) => {
  const { t } = useTranslation();
  const { state: navState } = useLocation();

  const { mutateAsync: createKycProcess, isLoading: loadingCreateProcess } =
    useCreateProcessMutation();
  const { data: kycOwnerData, isLoading: loadingOwnerData } =
    useGetCustomerData(kycProcessData?.user?.documentNumber || '');

  const ownerName = kycOwnerData?.socialName;

  const loadingButton = loadingCreateProcess || loadingOwnerData;

  const createProcessAndGoNext = async () => {
    if (kycProcessData?.id) {
      goToStep('companyData');
    } else {
      try {
        if (!navState?.newOrg) {
          await createKycProcess();
        }
        goToStep('companyData');
      } catch (error: any) {
        if (parseError(error) === 'USER_ALREADY_HAVE_INITIATE_A_PROCESS') {
          goToStep('companyData');
        }
      }
    }
  };

  return (
    <>
      <Text.Heading1Medium marginBottom={20}>
        {t('Sua empresa está muito')}
        <br />
        {t('Perto da revolução financeira ;)')}
      </Text.Heading1Medium>
      <When condition={!!ownerName}>
        <Text.Heading4 display="inline-block">
          {t('Olá')}, {`${ownerName}!`}
        </Text.Heading4>
      </When>

      <Text.Heading4 marginBottom={30} lineHeight={140}>
        {t('Agradecemos o seu interesse na conta PJ multimoedas do Capitual.')}{' '}
        {t(`Vamos coletar algumas informações importantes da sua empresa e será necessário
        que você esteja com os seguintes documentos em mãos:`)}
      </Text.Heading4>

      {necessaryDocs.map((d) => (
        <Style.DocItem key={d}>
          <ClipIcon style={{ minWidth: 24 }} />
          <Text.Heading5 marginLeft={10}>{t(d)}</Text.Heading5>
        </Style.DocItem>
      ))}
      <FooterButton
        loadingGenericButton={loadingButton}
        onClickGenericButton={createProcessAndGoNext}
        genericButtonType="secondary"
        genericButtonContent="Vamos Começar"
      />
    </>
  );
};
