import { gql, request, useMutation } from '~reactQuery';

import { Status } from '~reactQuery/types/business.types';
import { useQueryClient } from 'react-query';

export interface CreateBusinessDataInput {
  processId: number;
  fileCNPJ: string;
  fileQSA: string;
  socialContract?: string;
  lastContractChange?: string;
  socialStatute?: string;
  lastElection?: string;
  preventionPolicy?: string;
  proofOfRegularity?: string;
  accountingBalance?: string;
}

interface CreateBusinessDataResponse {
  createBusinessData: {
    data: {
      id: number;
      fileCNPJ: string;
      fileQSA: string;
      socialContract?: string;
      lastContractChange?: string;
      socialStatute?: string;
      lastElection?: string;
      preventionPolicy?: string;
      proofOfRegularity?: string;
      accountingBalance?: string;
      incomes: {
        id: number;
        income: string;
        createdAt: string;
        updatedAt: string;
        deleted: boolean;
        deletedAt: string;
        businessDataId: number;
      };
      fields: {
        id: number;
        field: string;
        description: string;
        status: boolean;
        integratorHasApproved: boolean;
        businessId: number;
        businessDataId: number;
        partnerDataId: number;
      };
      createdAt: string;
      updatedAt: string;
      deleted: boolean;
      deletedAt: string;
      status: Status;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useCreateBusinessDataMutation() {
  const queryClient = useQueryClient();

  const mutation = gql`
    mutation CreateBusinessData($params: createBusinessDataInput!) {
      createBusinessData(params: $params) {
        data {
          id
          fileCNPJ
          fileQSA
          socialContract
          lastContractChange
          socialStatute
          lastElection
          preventionPolicy
          proofOfRegularity
          accountingBalance
          incomes {
            id
            income
            createdAt
            updatedAt
            deleted
            deletedAt
            businessDataId
          }
          fields {
            id
            field
            description
            status
            integratorHasApproved
            businessId
            businessDataId
            partnerDataId
          }
          createdAt
          updatedAt
          deleted
          deletedAt
          status
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: CreateBusinessDataInput) => {
    const response = await request<CreateBusinessDataResponse>(mutation, {
      params,
    });

    return response.createBusinessData;
  };

  return useMutation('CreateBusinessData', fetch, {
    onSettled: () => {
      queryClient.invalidateQueries(['getProcess']);
    },
  });
}
