import React, { useEffect, useState } from 'react';
import * as Style from './OrganizationsSettings.styles';
import * as Text from '~styles/text';
import * as TextSecondary from '~styles/textSecondary';
import colors from '~styles/colors';

import { useGetBusinessInfo } from '~reactQuery/queries/business/useGetBusinessInfo';
import { usePutOrganizationImage } from '~reactQuery/mutations/settings/usePutOrganizationImage';
import { getSelectedUserOrganization, getSelectedOrg } from '~helpers/device';
import Dialog from '~components/Dialog';

import InfoIcon from '~assets/images/icons/24x24/info.svg';
import BriefCaseIcon from '~assets/images/icons/24x24/briefcase.svg';
import PhoneIcon from '~assets/images/icons/24x24/phone.svg';
import { GenericButton } from '~components/Buttons';
import AvatarUpload from '~components/AvatarUpload';
import { formatDate } from '~helpers/format/date';
import { cepMask, cnaeMask, cnpjMask, formatPhone } from '~helpers/format/masks';
import { capitalizeName } from '~helpers/format/user';
import { getBase64 } from '~helpers/format/file';
import { ErrorToast, SuccessToast } from '~components/Toast';
import { useDeleteOrganizationImage } from '~reactQuery/mutations/settings/useDeleteOrganizationImage';
import DialogHeader from '~components/Dialog/DialogHeader.component';
import DialogBottom from '~components/Dialog/DialogBottom.component';
import { When } from 'react-if';

const OrganizationsSettings: React.FC = () => {
  const [isDialogModalVisible, setIsDialogModalVisible] = useState(false);

  const userOrganizationId = getSelectedUserOrganization();
  const orgId = getSelectedOrg();

  const [file, setFile] = useState<string | ArrayBuffer>('');

  const { data: businessInfo, isLoading: businessInfoLoading } = useGetBusinessInfo({
    userOrganizationId: Number(userOrganizationId),
  });
  const {
    mutateAsync: requestPutOrganizationImage,
    isLoading: isLoadingPutOrganizationImage,
  } = usePutOrganizationImage();
  const {
    mutateAsync: requestDeleteOrganizationImage,
    isLoading: isLoadingDeleteOrganizationImage,
  } = useDeleteOrganizationImage();

  const handleChange = async (selectedFile: any) => {
    if (!selectedFile) return;

    const image = selectedFile.target.files[0];
    const imageBase64 = await getBase64(image);

    try {
      const response = await requestPutOrganizationImage({
        organizationId: Number(orgId),
        organizationImage: String(imageBase64),
      })

      if(!response) {
        // eslint-disable-next-line consistent-return
        return ErrorToast({
          title: 'Erro ao enviar a imagem',
          subtitle: 'Verifique se o formato da imagem e o tamanho estão corretos.',
          autoCloseEnable: true,
        });
      }

      // eslint-disable-next-line consistent-return
      return SuccessToast({
        title: 'Logo atualizada com sucesso!',
        autoCloseEnable: true,
      });
    } catch (error: any) {
      SuccessToast({
        title: 'Erro ao enviar a imagem',
        subtitle: 'Verifique se o formato da imagem e o tamanho estão corretos.',
        autoCloseEnable: true,
      });
    }
  };

  // eslint-disable-next-line consistent-return
  const DeleteImage = () => {
    try {
      const response = requestDeleteOrganizationImage({
        organizationId: Number(orgId),
      });

      if (!response) {
        return ErrorToast({
          title: 'Erro ao deletar a imagem',
          subtitle: 'Tente novamente mais tarde.',
          autoCloseEnable: true,
        });
      }

      setIsDialogModalVisible(false);
      return SuccessToast({
        title: 'Logo removida.',
        autoCloseEnable: true,
      })
    } catch (error: any) {
      SuccessToast({
        title: 'Erro ao deletar a imagem',
        subtitle: 'Tente novamente mais tarde.',
        autoCloseEnable: true,
      });
    }
  }
  
  const countryCode = businessInfo?.countryCode || '-';
  const phoneNumber = businessInfo?.phone || '-';
  const phone = countryCode + phoneNumber;

  return (
    <>
    <Style.Container>
      <div className='company-content'>
        <div className='icon-container'>
          <BriefCaseIcon />

          <TextSecondary.Heading4Medium>
            Perfil da empresa
          </TextSecondary.Heading4Medium>
        </div>

        <div className='logo-container'>

          <AvatarUpload onUpload={handleChange} />

          <div className='text-container'>
            <Text.Heading4>
              Logotipo da empresa
            </Text.Heading4>

            <Text.Paragraph color={colors.grayMedium}>
              Foto JPG ou PNG com tamanho máx. de 5MB.
            </Text.Paragraph>
          </div>

          <GenericButton buttonType='gray' onClick={() => setIsDialogModalVisible(true)}>
            <Text.Paragraph color={colors.grayMedium}>
              Remover
            </Text.Paragraph>
          </GenericButton>
        </div>

        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Nome Fantasia
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {businessInfo?.tradingName || '--'}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Razão Social
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {businessInfo?.businessName || '--'}
              </Text.Paragraph>
            </div>
          </div>
        </div>

        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                CNAE
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {cnaeMask(businessInfo?.businessCnae || '--')}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Data de Abertura
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {businessInfo?.companyStartDate ? formatDate(businessInfo?.companyStartDate, 'LL') : '--'}
              </Text.Paragraph>
            </div>
          </div>
        </div>

        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                CNPJ
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {cnpjMask(businessInfo?.documentNumber || '--')}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Natureza Jurídica
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {businessInfo?.businessSize || '--'}
              </Text.Paragraph>
            </div>
          </div>
        </div>

        <div className="tip">
          <InfoIcon />
          <Text.Paragraph color={colors.grayMedium}>
            Fez alguma alteração cadastral na sua empresa? Entre em contato com o nosso chat de suporte para atualizar os documentos.
          </Text.Paragraph>
        </div>
      </div>

      <div className='contact-content'>
        <div className='icon-container'>
          <PhoneIcon />

          <TextSecondary.Heading4Medium>
            Contato da empresa
          </TextSecondary.Heading4Medium>
        </div>


        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                País/região
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {businessInfo?.country || '--'}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                CEP
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {cepMask(businessInfo?.zipCode || '--')}
              </Text.Paragraph>
            </div>
          </div>
        </div>

        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Cidade
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {businessInfo?.city}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Estado
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {businessInfo?.state}
              </Text.Paragraph>
            </div>
          </div>
        </div>

        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Endereço
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {capitalizeName(businessInfo?.addressLine || '--')}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Número
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {capitalizeName(businessInfo?.buildingNumber || '--')}
              </Text.Paragraph>
            </div>
          </div>
        </div>

        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Bairro
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {capitalizeName(businessInfo?.neighborhood || '--')}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Complemento
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {capitalizeName(businessInfo?.complement || '--')}
              </Text.Paragraph>
            </div>
          </div>
        </div>

        <div className='side-content'>
          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                Telefone
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {formatPhone(phone)}
              </Text.Paragraph>
            </div>
          </div>

          <div className='side'>
            <div className='single-item'>
              <Text.Heading4>
                E-mail
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                {businessInfo?.businessEmail || '--'}
              </Text.Paragraph>
            </div>
          </div>
        </div>
      </div>
    </Style.Container>

    <When condition={isDialogModalVisible}>
      <Dialog
        openModal={isDialogModalVisible}
        setOpenModal={setIsDialogModalVisible}
        >
         <DialogHeader
            title="Você tem certeza que deseja remover a imagem?"
            description="Ao confirmar, a imagem será removida e voltará para o padrão de exibição."
          />

          <DialogBottom
            singleButtonText="Remover"
            doubleButtonTextOne="Cancelar"
            singleButtonAlignment="flex-end"
            singleButtonAction={() => DeleteImage()}
            doubleButtonOneAction={() => setIsDialogModalVisible(false)}
            singleButtonLoading={isLoadingDeleteOrganizationImage}
            disableSingleButton={false}
          />
        </Dialog>
      </When>
    </>
  );
}

export default OrganizationsSettings;