import AdaCoinSVG from '~assets/svg/coins/adaCoin';
import AxsCoinSVG from '~assets/svg/coins/axsCoin';
import BchCoinSVG from '~assets/svg/coins/bchCoin';
import BnbCoinSVG from '~assets/svg/coins/bnbCoin';
import BtcCoinSVG from '~assets/svg/coins/btcCoin';
import BttCoinSVG from '~assets/svg/coins/bttCoin';
import BusdCoinSVG from '~assets/svg/coins/busdCoin';
import CakeCoinSVG from '~assets/svg/coins/cakeCoin';
import ChzCoinSVG from '~assets/svg/coins/chzCoin';
import DaiCoinSVG from '~assets/svg/coins/daiCoin';
import DogeCoinSVG from '~assets/svg/coins/dogeCoin';
import DotCoinSVG from '~assets/svg/coins/dotCoin';
import DshCoinSVG from '~assets/svg/coins/dshCoin';
import EthCoinSVG from '~assets/svg/coins/ethCoin';
import FtmCoinSVG from '~assets/svg/coins/ftmCoin';
import HtCoinSVG from '~assets/svg/coins/htCoin';
import KcsCoinSVG from '~assets/svg/coins/kcsCoin';
import LinkCoinSVG from '~assets/svg/coins/linkCoin';
import LtcCoinSVG from '~assets/svg/coins/ltcCoin';
import MaticCoinSVG from '~assets/svg/coins/maticCoin';
import ShibCoinSVG from '~assets/svg/coins/shibCoin';
import TrxCoinSVG from '~assets/svg/coins/trxCoin';
import UniCoinSVG from '~assets/svg/coins/uniCoin';
import UsdcCoinSVG from '~assets/svg/coins/usdcCoin';
import UsdtCoinSVG from '~assets/svg/coins/usdtCoin';
import WalletAdaSVG from '~assets/images/icons/wallets/WalletAda';
import WalletAxsSVG from '~assets/images/icons/wallets/WalletAxs';
import WalletBchSVG from '~assets/images/icons/wallets/WalletBch';
import WalletBnbSVG from '~assets/images/icons/wallets/WalletBnb';
import WalletBrlSVG from '~assets/images/icons/wallets/WalletBrl';
import WalletBtcSVG from '~assets/images/icons/wallets/WalletBtc';
import WalletBttSVG from '~assets/images/icons/wallets/WalletBtt';
import WalletBusdSVG from '~assets/images/icons/wallets/WalletBusd';
import WalletCakeSVG from '~assets/images/icons/wallets/WalletCake';
import WalletChzSVG from '~assets/images/icons/wallets/WalletChz';
import WalletDaiSVG from '~assets/images/icons/wallets/WalletDai';
import WalletDogeSVG from '~assets/images/icons/wallets/WalletDoge';
import WalletDotSVG from '~assets/images/icons/wallets/WalletDot';
import WalletDshSVG from '~assets/images/icons/wallets/WalletDsh';
import WalletEthSVG from '~assets/images/icons/wallets/WalletEth';
import WalletEurSVG from '~assets/images/icons/wallets/WalletEur';
import WalletFtmSVG from '~assets/images/icons/wallets/WalletFtm';
import WalletGbpSVG from '~assets/images/icons/wallets/WalletGbp';
import WalletHtSVG from '~assets/images/icons/wallets/WalletHt';
import WalletKcsSVG from '~assets/images/icons/wallets/WalletKcs';
import WalletLinkSVG from '~assets/images/icons/wallets/WalletLink';
import WalletLtcSVG from '~assets/images/icons/wallets/WalletLtc';
import WalletMaticSVG from '~assets/images/icons/wallets/WalletMatic';
import WalletShibSVG from '~assets/images/icons/wallets/WalletShib';
import WalletTrxSVG from '~assets/images/icons/wallets/WalletTrx';
import WalletUniSVG from '~assets/images/icons/wallets/WalletUni';
import WalletUsdSVG from '~assets/images/icons/wallets/WalletUsd';
import WalletUsdcSVG from '~assets/images/icons/wallets/WalletUsdc';
import WalletUsdtSVG from '~assets/images/icons/wallets/WalletUsdt';
import WalletXauSVG from '~assets/images/icons/wallets/WalletXau';
import WalletXrpSVG from '~assets/images/icons/wallets/WalletXrp';
import XrpCoinSVG from '~assets/svg/coins/xrpCoin';
import { CurrencyTypes } from '~src/@types/currency.types';

export const walletListMoc = [
  {
    id: 4620,
    owner: 258,
    walletName: 'Binance Chain',
    walletCurrency: '18',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-8F4258EZ-AJXUC6-A5',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 18,
      type: 'crypto',
      precision: 8,
      name: 'Binance Chain',
      symbol: '?',
      isoCode: 'BNB',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
  {
    id: 4611,
    owner: 258,
    walletName: 'Brazilian Real',
    walletCurrency: '8',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-XFQAU2KB-VQGQDD-3E',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 8,
      type: 'fiat',
      precision: 2,
      name: 'Brazilian Real',
      symbol: 'R$ ',
      isoCode: 'BRL',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
  {
    id: 4607,
    owner: 258,
    walletName: 'Dash',
    walletCurrency: '3',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-CBMAFMRV-WSTH8W-C1',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 3,
      type: 'crypto',
      precision: 8,
      name: 'Dash',
      symbol: 'Э ',
      isoCode: 'DSH',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
  {
    id: 4619,
    owner: 258,
    walletName: 'Dogecoin',
    walletCurrency: '17',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-9VJK1VS1-QCJS97-3D',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 17,
      type: 'crypto',
      precision: 8,
      name: 'Dogecoin',
      symbol: '?',
      isoCode: 'DOGE',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
  {
    id: 4606,
    owner: 258,
    walletName: 'Dollar',
    walletCurrency: '2',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-Z3W3XHYJ-R25HRB-3B',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 2,
      type: 'fiat',
      precision: 2,
      name: 'Dollar',
      symbol: '$',
      isoCode: 'USD',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
  {
    id: 4608,
    owner: 258,
    walletName: 'Ethereum',
    walletCurrency: '4',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-B9QZK4QD-BY4NHQ-EC',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 4,
      type: 'crypto',
      precision: 6,
      name: 'Ethereum',
      symbol: 'Ξ',
      isoCode: 'ETH',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
  {
    id: 4609,
    owner: 258,
    walletName: 'Euro',
    walletCurrency: '5',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-F3QGXGHN-9MJMRD-73',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 5,
      type: 'fiat',
      precision: 2,
      name: 'Euro',
      symbol: '€',
      isoCode: 'EUR',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
  {
    id: 4613,
    owner: 258,
    walletName: 'Gold',
    walletCurrency: '11',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-6M96GFM8-27S4W8-4D',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 11,
      type: 'metal',
      precision: 6,
      name: 'Gold',
      symbol: 'g',
      isoCode: 'XAU',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
  {
    id: 4612,
    owner: 258,
    walletName: 'Litecoin',
    walletCurrency: '10',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-MU3UAC5G-2WCMMA-AF',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 10,
      type: 'crypto',
      precision: 8,
      name: 'Litecoin',
      symbol: 'Ł',
      isoCode: 'LTC',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
  {
    id: 4610,
    owner: 258,
    walletName: 'Pound Sterling',
    walletCurrency: '6',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-UWYLLYMT-8UXV9D-A8',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 6,
      type: 'fiat',
      precision: 2,
      name: 'Pound Sterling',
      symbol: '£',
      isoCode: 'GBP',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
  {
    id: 4621,
    owner: 258,
    walletName: 'Ripple',
    walletCurrency: '19',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-HNEUA8BY-LNV8TF-2A',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 19,
      type: 'crypto',
      precision: 6,
      name: 'Ripple',
      symbol: '?',
      isoCode: 'XRP',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
  {
    id: 4614,
    owner: 258,
    walletName: 'Tether',
    walletCurrency: '12',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-GJFG6UU1-SA1DBX-5A',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 12,
      type: 'crypto',
      precision: 2,
      name: 'Tether',
      symbol: '₮',
      isoCode: 'eUSDT',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
  {
    id: 4618,
    owner: 258,
    walletName: 'Tron',
    walletCurrency: '16',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-FAXNFK9A-T6MVF7-72',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 16,
      type: 'crypto',
      precision: 6,
      name: 'Tron',
      symbol: '?',
      isoCode: 'TRX',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
  {
    id: 4622,
    owner: 258,
    walletName: 'Tron USDT',
    walletCurrency: '20',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-SMC149PZ-XVF2JA-60',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 20,
      type: 'crypto',
      precision: 6,
      name: 'Tron USDT',
      symbol: '?',
      isoCode: 'TUSDT',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
  {
    id: 4617,
    owner: 258,
    walletName: 'USD Coin',
    walletCurrency: '15',
    isPublic: 0,
    isOwnerInfoPublic: 1,
    isFavorite: null,
    enabled: 1,
    ordering: 0,
    walletAddress: 'CAP-WNSALT91-X5SXCW-0D',
    funds: 0,
    createdAt: '2022-09-19T23:55:07.000Z',
    updatedAt: '2022-09-19T23:55:07.000Z',
    priceInPrefCurrency: 0,
    currency: {
      id: 15,
      type: 'crypto',
      precision: 2,
      name: 'USD Coin',
      symbol: '$?',
      isoCode: 'USDC',
    },
    enabledTransfer: true,
    enabledPay: true,
    enabledConvert: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  },
];

export const WalletsList = [
  {
    id: 1,
    name: 'ada',
    icon: <WalletAdaSVG />,
    title: 'Cardano',
    color: '',
    disable: true,
  },
  {
    id: 2,
    name: 'eth',
    icon: <WalletEthSVG />,
    title: 'Ethereum',
    color: '',
    disable: false,
  },
  {
    id: 3,
    name: 'eur',
    icon: <WalletEurSVG />,
    title: 'Euro',
    color: '',
    disable: false,
  },
  {
    id: 4,
    name: 'gbp',
    icon: <WalletGbpSVG />,
    title: 'Libra Esterlina',
    color: '',
    disable: false,
  },
  {
    id: 5,
    name: 'ltc',
    icon: <WalletLtcSVG />,
    title: 'Litecoin',
    color: '',
    disable: false,
  },
  {
    id: 6,
    name: 'usd',
    icon: <WalletUsdSVG />,
    title: 'Dólar Americano',
    color: '',
    disable: false,
  },
  {
    id: 7,
    name: 'usdc',
    icon: <WalletUsdcSVG />,
    title: 'USD Coin',
    color: '',
    disable: false,
  },
  {
    id: 8,
    name: 'eusdt',
    icon: <WalletUsdtSVG />,
    title: 'Tether',
    color: '',
    disable: false,
  },
  {
    id: 9,
    name: 'xau',
    icon: <WalletXauSVG />,
    title: 'Ouro',
    color: '',
    disable: false,
  },
  {
    id: 10,
    name: 'brl',
    icon: <WalletBrlSVG />,
    title: 'Real brasileiro',
    color: '',
    disable: false,
  },
  {
    id: 11,
    name: 'btc',
    icon: <WalletBtcSVG />,
    title: 'Bitcoin',
    color: '',
    disable: false,
  },
  {
    id: 12,
    name: 'bch',
    icon: <WalletBchSVG />,
    title: 'Bitcoin Cash',
    color: '',
    disable: false,
  },
  {
    id: 13,
    name: 'doge',
    icon: <WalletDogeSVG />,
    title: 'Dogecoin',
    color: '',
    disable: false,
  },
  {
    id: 14,
    name: 'busd',
    icon: <WalletBusdSVG />,
    title: 'Binance USD',
    color: '',
    disable: false,
  },
  {
    id: 15,
    name: 'dai',
    icon: <WalletDaiSVG />,
    title: 'Dai',
    color: '',
    disable: false,
  },
  {
    id: 16,
    name: 'dsh',
    icon: <WalletDshSVG />,
    title: 'Dash',
    color: '',
    disable: false,
  },
  {
    id: 17,
    name: 'dot',
    icon: <WalletDotSVG />,
    title: 'Polkadot',
    color: '',
    disable: true,
  },
  {
    id: 18,
    name: 'bnb',
    icon: <WalletBnbSVG />,
    title: 'Binance Coin',
    color: '',
    disable: false,
  },
  {
    id: 19,
    name: 'link',
    icon: <WalletLinkSVG />,
    title: 'Chainlink',
    color: '',
    disable: false,
  },
  {
    id: 20,
    name: 'uni',
    icon: <WalletUniSVG />,
    title: 'Uniswap',
    color: '',
    disable: false,
  },
  {
    id: 21,
    name: 'xrp',
    icon: <WalletXrpSVG />,
    title: 'Ripple',
    color: '',
    disable: true,
  },
  {
    id: 22,
    name: 'axs',
    icon: <WalletAxsSVG />,
    title: 'Axie Infinity',
    color: '',
    disable: false,
  },
  {
    id: 23,
    name: 'btt',
    icon: <WalletBttSVG />,
    title: 'Bit Torrent',
    color: '',
    disable: true,
  },
  {
    id: 24,
    name: 'cake',
    icon: <WalletCakeSVG />,
    title: 'Pancake Swap',
    color: '',
    disable: false,
  },
  {
    id: 25,
    name: 'chz',
    icon: <WalletChzSVG />,
    title: 'Chiliz',
    color: '',
    disable: false,
  },
  {
    id: 26,
    name: 'shib',
    icon: <WalletShibSVG />,
    title: 'Shiba Inu',
    color: '',
    disable: true,
  },
  {
    id: 27,
    name: 'matic',
    icon: <WalletMaticSVG />,
    title: 'Polygon',
    color: '',
    disable: false,
  },
  {
    id: 28,
    name: 'ftm',
    icon: <WalletFtmSVG />,
    title: 'Fantom',
    color: '',
    disable: false,
  },
  {
    id: 29,
    name: 'ht',
    icon: <WalletHtSVG />,
    title: 'Huobi Token',
    color: '',
    disable: true,
  },
  {
    id: 30,
    name: 'kcs',
    icon: <WalletKcsSVG />,
    title: 'KuCoin Token',
    color: '',
    disable: true,
  },
  {
    id: 31,
    name: 'trx',
    icon: <WalletTrxSVG />,
    title: 'Tron',
    color: '',
    disable: false,
  },
  {
    id: 32,
    name: 'tusdt',
    icon: <WalletUsdtSVG />,
    title: 'Tether',
    color: '',
    disable: false,
  },
];

export const sparkLineColorSelector = {
  BTC: '#ffb151',
  USD: '#639c55',
  DSH: '#0c90ff',
  ETH: '#808696',
  EUR: '#3b5ba0',
  GBP: '#986ac4',
  BRL: '#639c55',
  BCH: '#8cc251',
  LTC: '#B5B2B2',
  XAU: '#f0d365',
  eUSDT: '#4faf95',
  BUSD: '#efb90a',
  DAI: '#f4b631',
  USDC: '#357ccd',
  AXS: '#0055d4',
  LINK: '#2A5ADA',
  UNI: '#ff007a',
  CHZ: '#d5364d',
  MATIC: '#8247E5',
  FTM: '#1968ff',
  BNB: '#f3ba2e',
  TRX: '#FF060A',
  SHIB: '#FFA409',
  XRP: '',
  BTT: '',
  CAKE: '',
  DOGE: '',
  DOT: '',
  USDT: '',
  HT: '',
  KCS: '',
};

export const currenciesList = [
  'BUSD',
  'BTC',
  'BRL',
  'DAI',
  'DSH',
  'USD',
  'ETH',
  'EUR',
  'FTM',
  'XAU',
  'LTC',
  'GBP',
  'USDC',
  'BCH',
  'AXS',
  'LINK',
  'UNI',
  'CHZ',
  'MATIC',
  'BNB',
  'XRP',
  'DOT',
  'CAKE',
  'SHIB',
  'BTT',
  'HT',
  'KCS',
  'TRX',
  'DOGE',
  'USDT',
] as CurrencyTypes[];

export const cryptoNetworks = [
  {
    name: 'BTC',
    network: 'BTC',
  },
  {
    name: 'DSH',
    network: 'DASH',
  },
  {
    name: 'BCH',
    network: 'BCH',
  },
  {
    name: 'LTC',
    network: 'LTC',
  },
  {
    name: 'DOGE',
    network: 'DOGE',
  },
  {
    name: 'CAKE',
    network: 'BEP-20/BSC',
  },
  {
    name: 'BNB',
    network: 'BEP-20/BSC',
  },
  {
    name: 'TRX',
    network: 'TRC-20',
  },
  {
    name: 'tUSDT',
    network: 'TRC-20',
  },
  {
    name: 'USDC',
    network: 'ERC-20/ETH',
  },
  {
    name: 'ETH',
    network: 'ERC-20/ETH',
  },
  {
    name: 'AXS',
    network: 'ERC-20/ETH',
  },
  {
    name: 'LINK',
    network: 'ERC-20/ETH',
  },
  {
    name: 'UNI',
    network: 'ERC-20/ETH',
  },
  {
    name: 'MATIC',
    network: 'ERC-20/ETH',
  },
  {
    name: 'FTM',
    network: 'ERC-20/ETH',
  },
  {
    name: 'eUSDT',
    network: 'ERC-20/ETH',
  },
  {
    name: 'BUSD',
    network: 'ERC-20/ETH',
  },
  {
    name: 'DAI',
    network: 'ERC-20/ETH',
  },
  {
    name: 'CHZ',
    network: 'ERC-20/ETH',
  },
];

export const coinList = [
  {
    id: 1,
    name: 'ada',
    icon: (width?: number, height?: number) => (
      <AdaCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Cardano',
    visible: false,
  },
  {
    id: 2,
    name: 'eth',
    icon: (width?: number, height?: number) => (
      <EthCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Ethereum',
    visible: true,
  },
  {
    id: 3,
    name: 'ltc',
    icon: (width?: number, height?: number) => (
      <LtcCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Litecoin',
    visible: true,
  },
  {
    id: 4,
    name: 'usdc',
    icon: (width?: number, height?: number) => (
      <UsdcCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'USD Coin',
    visible: true,
  },
  {
    id: 5,
    name: 'eusdt',
    icon: (width?: number, height?: number) => (
      <UsdtCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Tether',
    visible: true,
  },
  {
    id: 6,
    name: 'btc',
    icon: (width?: number, height?: number) => (
      <BtcCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Bitcoin',
    visible: true,
  },
  {
    id: 27,
    name: 'bch',
    icon: (width?: number, height?: number) => (
      <BchCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Bitcoin Cash',
    visible: true,
  },
  {
    id: 7,
    name: 'doge',
    icon: (width?: number, height?: number) => (
      <DogeCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Dogecoin',
    visible: true,
  },
  {
    id: 8,
    name: 'busd',
    icon: (width?: number, height?: number) => (
      <BusdCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Binance USD',
    visible: true,
  },
  {
    id: 9,
    name: 'dai',
    icon: (width?: number, height?: number) => (
      <DaiCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Dai',
    visible: true,
  },
  {
    id: 10,
    name: 'dsh',
    icon: (width?: number, height?: number) => (
      <DshCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Dash',
    visible: true,
  },
  {
    id: 11,
    name: 'dot',
    icon: (width?: number, height?: number) => (
      <DotCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Polkadot',
    visible: false,
  },
  {
    id: 12,
    name: 'bnb',
    icon: (width?: number, height?: number) => (
      <BnbCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Binance Coin',
    visible: true,
  },
  {
    id: 13,
    name: 'link',
    icon: (width?: number, height?: number) => (
      <LinkCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Chainlink',
    visible: true,
  },
  {
    id: 14,
    name: 'uni',
    icon: (width?: number, height?: number) => (
      <UniCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Uniswap',
    visible: true,
  },
  {
    id: 15,
    name: 'xrp',
    icon: (width?: number, height?: number) => (
      <XrpCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Ripple',
    visible: false,
  },
  {
    id: 16,
    name: 'axs',
    icon: (width?: number, height?: number) => (
      <AxsCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Axie Infinity',
    visible: true,
  },
  {
    id: 17,
    name: 'btt',
    icon: (width?: number, height?: number) => (
      <BttCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Bit Torrent',
    visible: false,
  },
  {
    id: 18,
    name: 'cake',
    icon: (width?: number, height?: number) => (
      <CakeCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Pancake Swap',
    visible: true,
  },
  {
    id: 19,
    name: 'chz',
    icon: (width?: number, height?: number) => (
      <ChzCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Chiliz',
    visible: true,
  },
  {
    id: 20,
    name: 'shib',
    icon: (width?: number, height?: number) => (
      <ShibCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Shiba Inu',
    visible: false,
  },
  {
    id: 21,
    name: 'matic',
    icon: (width?: number, height?: number) => (
      <MaticCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Polygon',
    visible: true,
  },
  {
    id: 22,
    name: 'ftm',
    icon: (width?: number, height?: number) => (
      <FtmCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Fantom',
    visible: true,
  },
  {
    id: 23,
    name: 'ht',
    icon: (width?: number, height?: number) => (
      <HtCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Huobi Token',
    visible: false,
  },
  {
    id: 24,
    name: 'kcs',
    icon: (width?: number, height?: number) => (
      <KcsCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'KuCoin Token',
    visible: false,
  },
  {
    id: 25,
    name: 'trx',
    icon: (width?: number, height?: number) => (
      <TrxCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Tron',
    visible: true,
  },
  {
    id: 26,
    name: 'tusdt',
    icon: (width?: number, height?: number) => (
      <UsdtCoinSVG width={width || 44} height={height || 36} />
    ),
    title: 'Tether',
    visible: true,
  },
];
