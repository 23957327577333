import * as Style from './SideBar.styles';
import * as Text from '~styles/text';

import { Else, If, Then, When } from 'react-if';
import { EmptyContacts } from '~components/Empty';

import AliceCarousel from 'react-alice-carousel';
import MastercardIcon from '~assets/images/icons/card/mastercard';
import OptionSelect from '~components/OptionSelect';
import PlusCardSVG from '~assets/svg/plusCard';
import { SideBarProps } from './SideBar.types';
import UserPic from '~components/UserPic';
import { capitalize } from '~helpers/format/capitalize';
import colors from '~styles/colors';
import { getSelectedUserOrganization } from '~helpers/device';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useGetContacts } from '~reactQuery/queries/contacts/useGetContacts';
import { useState, useMemo } from 'react';
import { PermissionGate, usePermission } from '~helpers/permissionGate';

const getItems = () =>
  Array(2)
    .fill(0)
    .map((_, ind) => ({ id: ind }));

const SideBar: React.FC<SideBarProps> = (props) => {
  const [items, setItems] = useState(getItems);
  const [hasPurchases, setHasPurchases] = useState(true);

  const { setSection, setOpenModalMultiStep, setParams } =
    useActionsOnModalMultiStep();

  const { granted: buttonsPixGranted } = usePermission('buttonsPix');
  const { granted: payBillsGranted } = usePermission('payBillsButton');
  const { granted: criptoTransactionsGranted } =
    usePermission('criptoTransactions');

  const userOrganizationId = getSelectedUserOrganization();

  const { data: contactsList, isLoading: loadingContacts } = useGetContacts({
    userOrganizationId: Number(userOrganizationId),
    page: 1,
    perPage: 100,
  });

  const contactsListFiltered = useMemo(() => {
    return contactsList?.pages?.flat()?.[0]?.contacts;
  }, [contactsList]);

  const openTransfer = (contact: any) => {
    setOpenModalMultiStep(true);
    setSection('transfer');
    setParams({
      contact,
    });
  };

  const hasContacts = contactsListFiltered && contactsListFiltered?.length > 0;

  return (
    <Style.Container>
      <div className="head-title pr-20 pl-20">
        <div className="container-coming-soon">
          <Text.Heading2 color={colors.black}>CapCard</Text.Heading2>
          <Text.Small color={colors.black}>(Em breve)</Text.Small>
        </div>

        <Text.Heading5Bold
          color={colors.primary}
          style={{ cursor: 'not-allowed' }}
          marginRight={10}
        >
          Ver Cartões
        </Text.Heading5Bold>
      </div>
      <PermissionGate name="cardSection">
        <Style.CardContainer>
          <AliceCarousel
            mouseTracking
            responsive={{
              420: { items: 2.5 },
            }}
            paddingLeft={24}
            paddingRight={25}
            infinite={false}
            items={items.map(({ id }) => (
              <>
                <div
                  className={`card-item ${id > 0 && 'virtual-card'}`}
                  key={id}
                >
                  <div className="column">
                    <Text.Heading4 color={id > 0 ? colors.white : colors.black}>
                      8707
                    </Text.Heading4>
                    <Text.Smaller
                      marginTop={4}
                      color={
                        id > 0 ? colors.whiteOpacity50p : colors.blackOpacity50p
                      }
                    >
                      {id > 0 ? 'Virtual' : 'Físico'}
                    </Text.Smaller>
                  </div>

                  <MastercardIcon />
                </div>

                <When condition={Number(items[items.length - 1].id) === id}>
                  <div className="plus-button-container">
                    <div className="plus-button">
                      <PlusCardSVG />
                    </div>

                    <Text.Paragraph
                      textAlign="center"
                      color={colors.naturalBlack}
                    >
                      Add Cartão
                    </Text.Paragraph>
                  </div>
                </When>
              </>
            ))}
          />
        </Style.CardContainer>
      </PermissionGate>

      <div className="pr-20 pl-20">
        {/* <Text.ParagraphBold
          color={colors.grayRegular}
          marginTop={14}
          marginBottom={22}
        >
          Últimas compras
        </Text.ParagraphBold>

        <If condition={hasPurchases}>
          <Then>
            <TableMini
              icon={<StreamingIcon />}
              titleTransaction="Amazonprimebr"
              hourTransaction="18:02  •  Assinatura"
              value="R$ 10,99"
              dateTransaction="Hoje"
            />
            <TableMini
              icon={<StreamingIcon />}
              titleTransaction="Amazonprimebr"
              hourTransaction="18:02  •  Assinatura"
              value="R$ 10,99"
              dateTransaction="Hoje"
            />
            <TableMini
              icon={<StreamingIcon />}
              titleTransaction="Amazonprimebr"
              hourTransaction="18:02  •  Assinatura"
              value="R$ 10,99"
              dateTransaction="Hoje"
            />

            <a className="outline-button">
              <Text.ParagraphBold textAlign="center" color={colors.primary}>
                Todas as Compras
              </Text.ParagraphBold>
            </a>
          </Then>
          <Else>
            <div className="empty-purchases">
              <EmptyPurchases />
            </div>
          </Else>
        </If> */}

        {/* <div className="head-title mt-28">
          <TransferIcon color={colors.grayRegular} />

          <Text.Heading5Bold>Pagamento Rápido</Text.Heading5Bold>
        </div> */}

        <Text.Heading2 marginTop={18} marginBottom={27} color={colors.black}>
          Pagamento Rápido
        </Text.Heading2>
        <PermissionGate name="fastPaymentSection">
          <If condition={hasContacts}>
            <Then>
              {/* <Text.ParagraphBold color={colors.grayRegular} marginBottom={12}>
              Transações frequentes
            </Text.ParagraphBold>

            <OptionSelect
              arrowIcon
              enableAnimation
              icon={<BankIcon />}
              title="Master Contabilidade"
              subtitle="Nu Pagamentos S. A. - IP"
            /> */}

              <div className="container-contacts">
                {contactsListFiltered?.map((contact) => (
                  <OptionSelect
                    key={contact?.id}
                    enableAnimation
                    borderTop
                    arrowIcon
                    icon={<UserPic name={contact?.name} />}
                    title={capitalize(contact?.name)}
                    onClick={() => openTransfer(contact)}
                  />
                ))}
              </div>

              {/* <a className="outline-button mt-15">
              <Text.ParagraphBold textAlign="center" color={colors.primary}>
                Ver Contatos & Favorecidos
              </Text.ParagraphBold>
            </a> */}
            </Then>
            <Else>
              {!loadingContacts && (
                <div className="empty-contacts">
                  <EmptyContacts />
                </div>
              )}
            </Else>
          </If>
        </PermissionGate>
      </div>
    </Style.Container>
  );
};

export default SideBar;
