import { colors } from '~styles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 3px;
  background-color: ${colors.mainGray};
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;

  .container-select {
    display: flex;
    padding: 6px;
    width: 50%;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
  }
`;
