import * as Style from '../SignTemplate.styles';
import * as Text from '~styles/text';
import * as yup from 'yup';
import { GenericButton, InputForm } from '~components';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import WarningIcon from '~assets/images/icons/kyc/status/alert.svg';
import { breakpoints } from '~styles/metrics';
import { colors } from '~styles';
import { setUserInfos } from '~helpers/device';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useSignInMutation } from '~reactQuery/mutations/user/useSignIn';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '~hooks/windowDimension';
import { yupResolver } from '@hookform/resolvers/yup';
import { When } from 'react-if';

export const SignIn = () => {
  const [showFirstAccess, setShowFirstAccess] = useState(false);

  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutateAsync: LoginMutation, isLoading: isLoadingSignIn } =
    useSignInMutation();

  const schema = yup.object({
    username: yup
      .string()
      .email('Email inválido')
      .required('Email obrigatório'),
    passwd: yup
      .string()
      .min(6, 'Senha deve ter no mínimo 6 caracteres')
      .required(),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<yup.InferType<typeof schema>>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const isSignIn = pathname.includes('/auth/sign-in');

  const onSubmit = handleSubmit(async ({ username, passwd }) => {
    try {
      setShowFirstAccess(false);
      queryClient.clear();

      const loginResp = await LoginMutation({ username, passwd });

      if (loginResp?.data?.access_token) {
        setUserInfos(
          loginResp?.data?.access_token,
          String(loginResp?.data?.user?.id)
        );

        navigate('/');
      } else {
        localStorage.setItem('@CapBiz:twoFa', 'true');

        navigate('/auth/two-fa-auth', { state: { userDetails: loginResp } });
      }

      localStorage.setItem(
        '@CapBiz:settings-id',
        String(loginResp?.data?.user?.settings?.id)
      );

      localStorage.setItem(
        '@CapBiz:user-documentNumber',
        String(loginResp?.data?.user?.documentNumber)
      );

      reset();

    } catch (error: any) {
      setShowFirstAccess(true);

      //  console.error('loginResp Error => ', error);
    }
  });

  const handleEnter = (event: KeyboardEvent) => {
    if (isSignIn) {
      if (event.key === 'Enter') {
        onSubmit();
      }
    }
  };

  useEffect(() => {
    if (isSignIn) {
      document.addEventListener('keydown', handleEnter);
    } else {
      document.removeEventListener('keydown', handleEnter);
    }
  }, [isSignIn]);

  const handleFirstAccess = () => navigate('/auth/change-password');

  return (
    <Style.ScrollContainer justifyContent="center">
      <Style.ContentWrapper screenWidth={width}>
        <Text.Heading1Medium marginTop={10} marginBottom={30}>
          {t('Boas-vindas ao CapBusiness!')}
        </Text.Heading1Medium>

        <Text.Heading5
          color={colors.black}
          marginBottom={20}
          lineHeight={140}
          width={breakpoints.desktop < width ? '80%' : ''}
        >
          Entre com e-mail e senha para ter acesso à conta empresarial.
        </Text.Heading5>

        <div className="input-group">
          <InputForm
            {...register('username')}
            autoFocus
            label="E-mail"
            marginTop={20}
            error={errors.username?.message}
          />
          <InputForm
            {...register('passwd')}
            type="password"
            label="Senha"
            error={errors.passwd?.message}
          />
        </div>

        <When condition={showFirstAccess}>
          <div className="warning-message">
            <div className="icon">
              <WarningIcon />
            </div>

            <Text.Small color={colors.danger} display="inline">
              Não encontramos nenhuma conta com essas credenciais. Se você já
              utilizava o CapBusiness e está com problemas para fazer login,{' '}
              <span className="link" onClick={handleFirstAccess}>
                clique aqui e atualize seus dados.
              </span>
            </Text.Small>
          </div>
        </When>

        <div className="forget-pass-wrapper">
          <Text.Paragraph
            color={colors.primary}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/auth/forgot-password')}
          >
            {t('Esqueceu sua senha?')}
          </Text.Paragraph>
        </div>

        <div className="buttons-wrapper">
          <GenericButton
            buttonType="secondary"
            width="auto"
            onClick={onSubmit}
            buttonLoading={isLoadingSignIn}
          >
            {t('Login')}
          </GenericButton>
          <GenericButton
            buttonType="inline"
            width="auto"
            onClick={() => navigate('/auth/sign-up')}
          >
            {t('Não tenho conta')}
          </GenericButton>
        </div>
      </Style.ContentWrapper>
    </Style.ScrollContainer>
  );
};

export default SignIn;
