import EmptyAssetsIcon from '~assets/images/icons/empty/no-assets.svg';
import { colors } from '~styles';
import * as Text from '~styles/text';
import * as Style from './EmptyAssets.styles';
import { EmptyAssetsProps } from './EmptyAssets.types';

const EmptyAssets: React.FC<EmptyAssetsProps> = (props) => {
  return (
    <Style.Container>
      <EmptyAssetsIcon />

      <Text.Heading2Bold
        color={colors.grayRegular}
        marginTop={18}
        textAlign="center"
      >
        Você não tem ativos
      </Text.Heading2Bold>
      <Text.Paragraph
        color={colors.grayLight}
        marginTop={14}
        textAlign="center"
        marginLeft={20}
        marginRight={20}
      >
        Informações sobre seus ativos e patrimônio líquido serão mostradas aqui.
      </Text.Paragraph>
    </Style.Container>
  );
};

export default EmptyAssets;
