import { ContentWrapper, ScrollContainer } from '../SignTemplate.styles';
import { Failed } from './Steps/5.Failed';
import { PossiblesSteps } from './ForgotPasswordGuest.types';
import { ResetPassword } from './Steps/3.Reset';
import { Success } from './Steps/6.Success';
import { UserBlocked } from './Steps/4.UserBlocked';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useWindowDimensions from '~hooks/windowDimension';
import { Warning } from './Steps/1.Warning';
import Validate2FA from './Steps/2.2fa';
import * as Styles from '../SignTemplate.styles';

const ForgotPasswordGuest = () => {
  const { width } = useWindowDimensions();
  const location = useLocation();
  const navigate = useNavigate();

  const parsedToken = location?.search?.substring(5);

  const [step, setStep] = useState<PossiblesSteps>('warning');

  useEffect(() => {
    if (!parsedToken) {
      navigate('/auth/sign-in');
    }
  }, []);

  const recoverySteps: Record<
    PossiblesSteps,
    { content: JSX.Element; progress: number }
  > = {
    warning: { content: <Warning setStep={setStep} />, progress: 40 },
    '2fa': {
      content: <Validate2FA setStep={setStep} emailToken={parsedToken} />,
      progress: 60,
    },
    reset: {
      content: <ResetPassword setStep={setStep} emailToken={parsedToken} />,
      progress: 80,
    },
    userBlocked: { content: <UserBlocked />, progress: 100 },
    failed: { content: <Failed setStep={setStep} />, progress: 100 },
    success: { content: <Success />, progress: 100 },
  };

  return (
    <ScrollContainer justifyContent="center">
      <Styles.ProgressContainer>
        <div
          className="bar"
          style={{ width: `${recoverySteps[step].progress || 1}%` }}
        />
      </Styles.ProgressContainer>

      <ContentWrapper screenWidth={width}>
        {recoverySteps[step].content}
      </ContentWrapper>
    </ScrollContainer>
  );
};

export default ForgotPasswordGuest;
