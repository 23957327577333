import { SVGProps } from 'react';

const ClosedEyeSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 34}
    height={props.height || 34}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.1 13.247a10.092 10.092 0 0 1 3.9 3.772 9.323 9.323 0 0 1-7.928 4.776c-1.403 0-2.895-.386-4.135-1.042M23.516 11 10.5 23m7.172-10.743a8.348 8.348 0 0 0-.744-.033A9.322 9.322 0 0 0 9 17c.406.68.886 1.312 1.431 1.886"
      stroke={props.stroke || '#272937'}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);

export default ClosedEyeSVG;
