import * as Text from '~styles/text';
import * as yup from 'yup';
import { Dispatch, SetStateAction, useCallback, useMemo, useRef } from 'react';
import { FooterButton, InputForm, InputSelect } from '~components';
import {
  cellphoneMask,
  cepMask,
  cpfMask,
  dateMask,
} from '~helpers/format/masks';
import LockIcon from '~assets/images/lock-icon.svg';
import { PossiblesSteps } from '../ForgotPassword.types';
import { breakpoints } from '~styles/metrics';
import { colors } from '~styles';
import dayjs from 'dayjs';
import { stateOptions } from '~data/brazilianStates';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { usePasswordReset } from '~reactQuery/mutations/user/usePasswordReset';
import useWindowDimensions from '~hooks/windowDimension';
import { yupResolver } from '@hookform/resolvers/yup';
import { strongPasswordRegex } from '~validations/stringsValidate';
import { ageValidate } from '~helpers/validate/genericValidations';

export const ResetPassword = ({
  setStep,
  token,
}: {
  setStep: Dispatch<SetStateAction<PossiblesSteps>>;
  token: string;
}) => {
  const { mutateAsync: resetPassword, isLoading: loadingResetPassword } =
    usePasswordReset(String(token));

  const navigate = useNavigate();

  const schema = useRef({
    documentNumber: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(14, 'Deve conter 11 números'),
    passwd: yup
      .string()
      .required('Esse campo é obrigatório')
      .matches(
        strongPasswordRegex,
        'Deve ao menos 8 caracteres, um maiúsculo, um minúsculo, um número e um caractere especial'
      ),
    confirmPassword: yup
      .string()
      .required('Esse campo é obrigatório')
      .matches(
        strongPasswordRegex,
        'Deve ao menos 8 caracteres, um maiúsculo, um minúsculo, um número e um caractere especial'
      )
      .oneOf([yup.ref('passwd')], 'Senhas não coincidem'),
  });

  const { width } = useWindowDimensions();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    mode: 'onSubmit',
    resolver: yupResolver(yup.object(schema.current)),
  });

  const questions = useMemo(() => {
    return [
      {
        component: (
          <InputForm
            {...register('phone')}
            label="Telefone"
            error={errors.phone?.message}
            maskFunction={cellphoneMask}
          />
        ),
        validation: {
          phone: yup.string().required('Telefone é um campo obrigatório'),
        },
      },
      {
        component: (
          <InputForm
            {...register('zipCode')}
            label="CEP"
            error={errors.zipCode?.message}
            maskFunction={cepMask}
            maxLength={10}
          />
        ),
        validation: {
          zipCode: yup.string().required('CEP é um campo obrigatório'),
        },
      },
      {
        component: (
          <InputForm
            {...register('buildingNumber')}
            label="Número  da residência"
            error={errors.buildingNumber?.message}
            maxLength={4}
          />
        ),
        validation: {
          buildingNumber: yup
            .string()
            .required('Número da residência é um campo obrigatório'),
        },
      },
      {
        component: (
          <InputForm
            {...register('neighborhood')}
            label="Bairro"
            error={errors.neighborhood?.message}
            maxLength={50}
          />
        ),
        validation: {
          neighborhood: yup.string().required('Bairro é um campo obrigatório'),
        },
      },
      {
        component: (
          <InputForm
            {...register('city')}
            label="Cidade"
            error={errors.city?.message}
            maxLength={40}
          />
        ),
        validation: {
          city: yup.string().required('Cidade é um campo obrigatório'),
        },
      },
      {
        component: (
          <InputSelect
            {...register('state')}
            label="Estado"
            options={stateOptions}
            error={errors.state?.message}
          />
        ),
        validation: {
          state: yup.string().required('Estado é um campo obrigatório'),
        },
      },
      {
        component: (
          <InputForm
            {...register('birthDate')}
            label="Data de nascimento"
            error={errors.birthDate?.message}
            maskFunction={dateMask}
          />
        ),
        validation: {
          birthDate: yup
            .string()
            .required('Esse campo é obrigatório')
            .min(10, 'Mínimo 8 caracteres')
            .test('is-valid-date', 'Data inválida', (value) => {
              if (!dayjs(value, 'DD/MM/YYYY', true).isValid()) {
                return false;
              }
              return true;
            })
            .test(
              'age-test-over-18',
              'É preciso ter mais que 18 anos',
              (value) => {
                return ageValidate(String(value), 18);
              }
            ),
        },
      },
      {
        component: (
          <InputForm
            {...register('motherName')}
            label="Nome da mãe"
            error={errors.motherName?.message}
            maxLength={50}
          />
        ),
        validation: {
          motherName: yup
            .string()
            .required('Nome da mãe é um campo obrigatório'),
        },
      },
    ];
  }, [
    errors.birthDate?.message,
    errors.buildingNumber?.message,
    errors.city?.message,
    errors.motherName?.message,
    errors.neighborhood?.message,
    errors.phone?.message,
    errors.state?.message,
    errors.zipCode?.message,
    register,
    stateOptions,
  ]);

  const question1 = useMemo(() => {
    const questionNumber = Math.floor(Math.random() * questions.length);
    return questionNumber;
  }, [questions.length]);

  const question2 = useMemo(() => {
    const questionNumber = Math.floor(Math.random() * (questions.length - 1));

    return questionNumber;
  }, [questions.length]);

  const renderQuestions = useCallback(() => {
    const question1Item = questions[question1];
    const question2Item = questions.filter((_, index) => index !== question1)[
      question2
    ];
    schema.current = {
      ...schema.current,
      ...question1Item.validation,
      ...question2Item.validation,
    };
    return {
      question1: question1Item,
      question2: question2Item,
    };
  }, [question1, question2, questions]);

  const onSubmit = handleSubmit(async (data) => {
    // remove confirmPassword from object

    delete data.confirmPassword;

    const parsedDocNumber = data.documentNumber
      .replaceAll('.', '')
      .replace('-', '');

    const parsedCep = data.zipCode
      ? data.zipCode.replaceAll('.', '').replace('-', '')
      : '';

    const parsedTel = data.phone ? data.phone.replaceAll(/\D/g, '') : '';
    delete data.phone;

    const parsedBirthDate = data.birthDate
      ? `${data.birthDate.split('/').reverse().join('-')}T00:00:00.000Z`
      : '';

    const parsedData = {
      ...data,
      documentNumber: parsedDocNumber,
      zipCode: parsedCep,
      number: parsedTel,
      birthDate: parsedBirthDate,
    };

    try {
      await resetPassword(parsedData);

      setStep('success');
    } catch (error: any) {
      if (error.message.includes('UNKNOWN_USER')) {
        setStep('failed');
      }
    }
  });

  return (
    <>
      <div className="footer-avoid header-avoid">
        <div className="main-icon">
          <LockIcon />
        </div>
        <Text.Heading1Medium marginTop={10} marginBottom={30}>
          Vamos alterar a senha?
        </Text.Heading1Medium>

        <Text.Heading5
          color={colors.black}
          marginBottom={30}
          lineHeight={140}
          width={breakpoints.desktop < width ? '100%' : ''}
        >
          Esta senha vai servir para você acessar sua conta empresarial, então
          escolha
          <br />
          uma senha forte, e não a reutilize em outras contas.
          <br />
          <br />
          Para alterar sua senha, preencha os dados solicitados abaixo:
        </Text.Heading5>
        <div className="input-group">
          <InputForm
            {...register('documentNumber')}
            label="CPF"
            error={errors.documentNumber?.message}
            maskFunction={cpfMask}
          />

          {renderQuestions().question1.component}
          {renderQuestions().question2.component}

          <InputForm
            {...register('passwd')}
            autoFocus
            label={t('Senha')}
            type="password"
            error={errors.passwd?.message}
          />
          <InputForm
            {...register('confirmPassword')}
            type="password"
            autoFocus
            label={t('Confirmar senha')}
            error={errors.confirmPassword?.message}
          />
        </div>
      </div>
      <FooterButton
        onClickGenericButton={onSubmit}
        loadingGenericButton={loadingResetPassword}
        leftButtonContent="Voltar"
        onClickLeftButton={() => navigate('/')}
        genericButtonType="secondary"
        genericButtonContent="Cadastrar senha nova"
      />
    </>
  );
};
