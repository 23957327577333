import dayjs from 'dayjs';
import { validateBr } from 'js-brasil';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { cpfMask } from '~helpers/format/masks';

export const isAnValidSite = (url: string) => {
  const isValid = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
    url,
  );

  return isValid;
};

export function ageValidate(date: string, age: number) {
  const dateFormatted = 'DD/MM/YYYY';
  const dateLimit = dayjs().subtract(age, 'years');
  const BirthDateFormatted = dayjs(date, dateFormatted);
  return BirthDateFormatted.isValid() && BirthDateFormatted.isBefore(dateLimit);
}

const PIX_KEY_CPF = 'cpf';
const PIX_KEY_PHONE = 'phone';

export function validatePixNumber(pixKey: string) {
	const keyTypes = [];

  // eslint-disable-next-line no-param-reassign
  pixKey = pixKey.trim();

	if (validateBr.cpf(pixKey)) {
		keyTypes.push(PIX_KEY_CPF);
	}

  if (isValidPhoneNumber(pixKey, 'BR') && cpfMask(pixKey) !== pixKey) {
    keyTypes.push(PIX_KEY_PHONE);
  }

	return keyTypes;
}