import * as Style from './PayBills.styles';

import { SetCurrentStepProps, StepParamsProps } from './PayBills.types';
import { useEffect, useState } from 'react';

import ConfirmPin from './ModalSteps/5.ConfirmPin';
import ModalMultiStep from '~components/ModalMultiStep';
import PayBillsStatus from './ModalSteps/6.PayBillsStatus';
import PaymentDetails from './ModalSteps/4.PaymentDetails';
import PossiblesKeys from './ModalSteps/1.PossiblesKeys';
import SelectWallet from './ModalSteps/3.SelectWallet';
import ShowKeyExample from './ModalSteps/2.ShowKeyExample';
import { WalletType } from '~reactQuery/types/wallets.types';
import { getSelectedUserOrganization } from '~helpers/device';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { usePermission } from '~helpers/permissionGate';

function PayBills() {
  const { openModalMultiStep, setOpenModalMultiStep, params } =
    useActionsOnModalMultiStep();
  const { granted: buttonsPixGranted } = usePermission('buttonsPix');
  const [currentStep, setCurrentStep] =
    useState<SetCurrentStepProps>('possiblesKeys');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [stepParams, setStepParams] = useState<StepParamsProps>({});
  const { granted: payBillsGranted } = usePermission('payBillsButton');
  const userOrganizationId = getSelectedUserOrganization();

  const { data: capAccountInfo, refetch: refetchCapAccountInfo } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  const capAccountWallet: WalletType = {
    id: 0,
    owner: 0,
    createdAt: '',
    updatedAt: '',
    walletAddress: 'CAPCONTA',
    walletCurrency: 'BRL',
    walletName: 'CapConta',
    funds: Number(capAccountInfo?.available) || 0,
    isOwnerInfoPublic: 0,
    priceInPrefCurrency: 0,
    isFavorite: 0,
    isPublic: 0,
    currency: {
      id: 0,
      type: 'fiat',
      name: 'CapConta',
      symbol: 'R$',
      isoCode: 'BRL',
      precision: 2,
    },
    ordering: 1,
    enabled: 1,
    enabledTransfer: true,
    enabledConvert: true,
    enabledPay: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  };

  function setStepsAndParams(
    step: SetCurrentStepProps,
    paramsFn?: object,
    cleanParams?: boolean
  ) {
    if (cleanParams) {
      setStepParams({} as StepParamsProps);
    } else if (paramsFn) {
      setStepParams((prev) => ({ ...prev, ...paramsFn } as StepParamsProps));
    }
    setCurrentStep(step);
  }

  const modalStepsAndDetails = {
    possiblesKeys: {
      component: (
        <PossiblesKeys
          key="possiblesKeys"
          setCurrentStep={setStepsAndParams}
          buttonsPixGranted={buttonsPixGranted}
          payBillsGranted={payBillsGranted}
        />
      ),
      progressValue: 15,
    },
    showKeyExample: {
      component: (
        <ShowKeyExample
          keyType={stepParams?.keyType}
          key="showKeyExample"
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 30,
    },
    selectWallet: {
      component: (
        <SelectWallet
          keyType={stepParams?.keyType}
          billData={stepParams?.billData}
          capAccountWallet={capAccountWallet}
          refetchCapAccount={refetchCapAccountInfo}
          key="selectWallet"
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 45,
    },
    paymentDetails: {
      component: (
        <PaymentDetails
          keyType={stepParams?.keyType}
          billData={stepParams?.billData}
          selectedOption={stepParams?.selectedOption}
          key="paymentDetails"
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 60,
    },
    confirmPin: {
      component: (
        <ConfirmPin
          description={stepParams?.description}
          billData={stepParams?.billData}
          key="confirmPin"
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 75,
    },
    payBillsStatus: {
      component: (
        <PayBillsStatus
          statusOfPayment={stepParams?.statusOfPayment}
          billPaymentResp={stepParams?.billPaymentResp}
          key="createPixStatus"
          setCurrentStep={setStepsAndParams}
          setOpenModalMultiStep={setOpenModalMultiStep}
        />
      ),
      progressValue: 100,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  // CLEAR STEP ON MODAL CLOSE
  useEffect(() => {
    if (!openModalMultiStep) setCurrentStep('possiblesKeys');
  }, [openModalMultiStep]);

  return (
    <Style.PayBillsContainer>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={currentStepInfos.progressValue}
      >
        {currentStepInfos.component}
      </ModalMultiStep>
    </Style.PayBillsContainer>
  );
}

export default PayBills;
