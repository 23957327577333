import { SVGProps } from 'react';

const NotFoundSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 344}
    height={props.height || 302}
    fill="none"
    {...props}
  >
    <path
      d="M314.784 221.193C314.784 224.332 314.182 227.479 312.978 230.447L284.861 299.737L15.569 300.164L73.5818 160.083C81.9597 139.852 110.617 139.86 118.988 160.09L119.22 160.658C121.822 166.944 121.807 173.821 119.243 179.936C116.869 185.601 113.7 193.175 110.461 200.917C103.474 217.6 115.726 236.013 133.812 236.013C138.951 236.013 143.839 234.459 147.931 231.716C152.015 228.973 155.311 225.037 157.254 220.278L208.085 95.9741C216.417 75.599 245.216 75.4504 253.762 95.7341C255.053 98.8048 255.701 102.07 255.701 105.339C255.701 108.608 255.072 111.774 253.822 114.799L235.089 160.197L219.694 197.503C219.572 197.8 219.458 198.097 219.343 198.402C217.175 204.288 217.32 210.788 219.762 216.59C224.029 226.709 233.318 231.761 242.614 231.761C251.91 231.761 261.297 226.652 265.519 216.461L267.328 211.977C275.554 191.586 304.337 191.377 312.86 211.641C314.144 214.693 314.788 217.943 314.788 221.197L314.784 221.193Z"
      fill="#F3F4F9"
    />
    <path
      d="M11.3821 289.668H22.7012L124.71 42.2095H113.387L11.3821 289.668Z"
      fill="#272937"
    />
    <path
      d="M0.463074 289.668H11.786L113.791 42.2095H102.472L0.463074 289.668Z"
      fill="#CED6EF"
    />
    <path
      d="M332.138 93.6045C332.138 97.9972 331.833 102.314 331.243 106.543C331.212 106.798 331.174 107.053 331.136 107.309C327.879 129.55 316.788 149.247 300.775 163.519C284.233 178.252 262.429 187.209 238.53 187.209C234.712 187.209 230.952 186.984 227.256 186.535C180.875 180.972 144.925 141.483 144.925 93.6045C144.925 41.9085 186.838 0 238.534 0C240.709 0 242.869 0.0761972 245.006 0.220972C248.992 0.491472 252.912 1.01342 256.745 1.76778C299.727 10.2409 332.138 48.1376 332.138 93.6006V93.6045Z"
      fill="#F3BA2F"
    />
    <path
      d="M319.23 200.913C319.23 226.927 298.139 248.018 272.125 248.018C246.111 248.018 225.02 226.927 225.02 200.913C225.02 195.899 225.805 191.068 227.26 186.534C230.956 186.984 234.716 187.209 238.533 187.209C262.429 187.209 284.237 178.252 300.779 163.519C311.999 172.133 319.234 185.677 319.234 200.913H319.23Z"
      fill="#CED6EF"
    />
    <path
      d="M300.775 163.519C284.233 178.252 262.429 187.209 238.53 187.209C234.712 187.209 230.952 186.984 227.256 186.535C233.329 167.55 251.121 153.808 272.121 153.808C282.899 153.808 292.836 157.427 300.771 163.519H300.775Z"
      fill="#DBA72A"
    />
    <path
      d="M95.241 112.875L93.8314 103.182L101.645 81.4738C102.971 77.462 107.295 75.2866 111.307 76.6124L115.803 78.0983C117.331 78.6012 118.157 80.2509 117.654 81.7786L107.985 111.046C106.659 115.058 102.335 117.233 98.3232 115.907L95.241 112.875Z"
      fill="#FF5B00"
    />
    <path
      d="M115.822 57.8983L106.648 83.8587"
      stroke="#FF5B00"
      strokeWidth="6.96443"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M119.617 62.5618L113.003 83.977"
      stroke="#FF5B00"
      strokeWidth="6.96443"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.987 67.5907L96.9209 93.2196"
      stroke="#FF5B00"
      strokeWidth="6.96443"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M96.7419 90.846C95.7247 90.0155 94.1893 90.4574 93.774 91.7033L81.8987 127.173C81.1291 129.695 82.5502 132.358 85.0723 133.128L95.9647 136.446C98.4868 137.216 101.15 135.795 101.919 133.273L108.461 111.808C111.063 103.27 106.251 94.2444 97.7134 91.6423L96.7419 90.846Z"
      fill="#FF5B00"
    />
    <path
      d="M107.753 80.7233L111.067 70.1471C111.353 69.2365 111.429 68.265 111.22 67.3354C111.06 66.623 110.728 65.8648 110.065 65.3124"
      stroke="#3F4251"
      strokeWidth="0.944845"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M119.384 59.8263L115.136 76.5554"
      stroke="#3F4251"
      strokeWidth="0.944845"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M93.37 205.532L53.6251 189.085L93.0096 93.9086L120.549 105.305C127.284 108.091 130.489 115.821 127.702 122.555L93.3665 205.53L93.37 205.532Z"
      fill="#0264DC"
    />
    <path
      d="M76.847 172.069C63.5489 172.069 52.7687 182.849 52.7687 196.147C52.7687 209.445 63.5489 220.225 76.847 220.225H145.985C159.283 220.225 170.063 209.445 170.063 196.147C170.063 182.849 159.283 172.069 145.985 172.069H76.847Z"
      fill="#0264DC"
    />
    <path
      d="M246.691 214.526L212.055 298.16H85.9678L131.431 188.371C135.519 178.504 145.146 172.073 155.825 172.073H218.319C240.202 172.073 255.065 194.311 246.691 214.526Z"
      fill="#0264DC"
    />
    <path
      d="M246.691 214.526L212.055 298.16H158.847L211.061 172.073H218.319C240.202 172.073 255.065 194.311 246.691 214.526Z"
      fill="#0264DC"
    />
    <path
      d="M263.835 289.667H0.607849V301.394H263.835V289.667Z"
      fill="#CED6EF"
    />
    <path d="M38.615 289.667H0.607849V301.394H38.615V289.667Z" fill="#CED6EF" />
    <path
      d="M332.138 93.6044C332.138 97.9972 331.833 102.314 331.243 106.543L229.63 106.825L209.331 106.878C205.285 106.889 202.527 102.779 204.074 99.0335L245.007 0.220947C248.992 0.491447 252.912 1.0134 256.745 1.76775C299.728 10.2409 332.138 48.1376 332.138 93.6006V93.6044Z"
      fill="#F3F4F9"
    />
    <path
      d="M92.1929 172.073H107.227"
      stroke="#3F4251"
      strokeWidth="1.21154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M151.962 280.851L143.295 285.408L120.222 285.271C116.001 285.359 112.507 282.006 112.42 277.781L112.324 273.045C112.29 271.437 113.566 270.108 115.178 270.073L145.996 269.441C150.217 269.353 153.711 272.706 153.798 276.931L151.966 280.848L151.962 280.851Z"
      fill="#FF5B00"
    />
    <path
      d="M94.0295 275.376L121.464 273.033"
      stroke="#FF5B00"
      strokeWidth="6.96443"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M95.062 280.398L122.493 278.059"
      stroke="#FF5B00"
      strokeWidth="6.96443"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M98.8072 284.852L126.242 282.513"
      stroke="#FF5B00"
      strokeWidth="6.96443"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M105.085 287.824L132.516 285.484"
      stroke="#FF5B00"
      strokeWidth="6.96443"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M125.468 289.42L165.594 289.321C168.227 289.241 170.299 287.043 170.223 284.41L169.884 273.03C169.804 270.397 167.605 268.325 164.973 268.401L142.544 269.072C133.625 269.338 126.608 276.783 126.874 285.705L125.472 289.424L125.468 289.42Z"
      fill="#FF5B00"
    />
    <path
      d="M112.865 274.436L97.1574 276.211C97.1574 276.211 92.9932 276.581 92.3837 278.189"
      stroke="#3F4251"
      strokeWidth="0.944845"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M114.755 279.282L100.548 281.221C100.548 281.221 96.3839 281.591 95.7744 283.198"
      stroke="#3F4251"
      strokeWidth="0.944845"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M118.409 283.279L106.465 284.742C106.465 284.742 102.301 285.111 101.691 286.719"
      stroke="#3F4251"
      strokeWidth="0.944845"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M233.28 288.589H208.79V183.967C208.79 177.402 214.12 172.072 220.685 172.072C240.698 172.072 256.943 188.321 256.943 208.331V264.922C256.943 277.982 246.34 288.589 233.276 288.589H233.28Z"
      fill="#006FF4"
    />
    <path
      d="M256.947 245.995V268.024C256.947 279.743 247.43 289.26 235.711 289.26H140.43V262.583C140.43 253.428 147.863 245.995 157.018 245.995H256.947Z"
      fill="#006FF4"
    />
    <path
      d="M0.607849 301.394H310.01"
      stroke="#3F4251"
      strokeWidth="1.21154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M218.395 245.9H207.289"
      stroke="#3F4251"
      strokeWidth="1.21154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M201.97 153.808V174.274C201.97 174.274 164.512 174.758 164.569 173.981C164.626 173.204 179.176 124.708 179.176 124.708L197.437 126.777"
      fill="#FF5B00"
    />
    <path
      d="M179.713 115.241C181.793 113.53 184.22 112.174 186.937 111.29C193.204 109.252 199.726 110.162 205.003 113.256C204.908 113.576 204.862 113.747 204.862 113.747L211.046 132.758C213.488 140.271 210.234 148.356 203.59 152.086C202.721 152.573 201.791 152.989 200.812 153.316C200.751 153.335 200.69 153.358 200.629 153.377C194.995 155.21 189.154 154.661 184.205 152.284"
      fill="#FF5B00"
    />
    <path
      d="M202.096 153.088C201.159 153.43 200.176 153.693 199.159 153.861C199.094 153.872 199.033 153.88 198.968 153.891C193.112 154.806 187.436 153.335 182.925 150.2"
      stroke="#3F4251"
      strokeWidth="1.21154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M183.081 125.104L183.782 134.313L180.49 135.383C180.49 135.383 177.244 126.75 171.815 135.147L176.46 150.112C171.248 155.831 170.032 155.427 170.032 155.427L162.474 125.245C159.331 115.584 164.615 105.205 174.277 102.062L183.272 99.1364L207.041 112.52L206.058 117.443L192.072 122.182"
      fill="#0264DC"
    />
    <path
      d="M178.73 145.544C177.953 146.184 177.042 146.695 176.025 147.026C171.667 148.443 166.984 146.058 165.567 141.7C164.15 137.342 166.535 132.659 170.893 131.242C173.008 130.556 175.195 130.762 177.054 131.653"
      fill="#FF5B00"
    />
    <path
      d="M160.096 176.213H196.766L206.489 172.43L202.515 163.195C202.496 163.149 202.451 163.119 202.401 163.119H168.566L160.096 176.213Z"
      fill="#0264DC"
    />
    <path
      d="M178.323 189.796L207.228 172.434L203.125 162.719C203.102 162.669 203.056 162.639 203.007 162.639H173.873L178.319 189.799L178.323 189.796Z"
      fill="#006FF4"
    />
    <path
      d="M163.209 189.796L158.763 172.434L162.866 162.719C162.889 162.669 162.935 162.639 162.984 162.639H170.337L163.209 189.799V189.796Z"
      fill="#006FF4"
    />
    <path
      d="M207.712 114.429L201.951 116.281L162.816 128.865C162.222 129.055 161.59 128.709 161.426 128.103C158.645 117.87 162.752 107.255 171.091 101.411C173.069 100.024 175.29 98.9079 177.709 98.1268C190.327 94.0732 203.826 100.702 208.405 112.997C208.622 113.583 208.31 114.235 207.716 114.425L207.712 114.429Z"
      fill="#F3F4F9"
    />
    <path
      d="M201.951 116.281L162.816 128.865C162.222 129.055 161.59 128.709 161.426 128.103C158.645 117.869 162.752 107.255 171.091 101.411C171.141 101.396 171.194 101.38 171.24 101.361C183.858 97.3076 197.357 103.937 201.936 116.231C201.94 116.246 201.948 116.258 201.951 116.277V116.281Z"
      fill="#CED6EF"
    />
    <path
      d="M211.914 107.141C208.192 108.688 203.921 106.924 202.374 103.201C200.827 99.4792 202.591 95.2083 206.314 93.6615"
      fill="#006FF4"
    />
    <path
      d="M235.82 79.4425V87.5219H254.69V95.3754H264.408V87.5219H269.437V79.4425H264.408V55.8256H252.035L235.82 79.4425ZM244.972 79.4425L254.69 65.1481V79.4425H244.972ZM272.136 74.6965V76.5045C272.136 80.4595 272.814 83.9059 274.283 86.7874C275.695 89.7254 277.673 91.9289 280.271 93.4544C282.87 94.9799 285.808 95.7144 289.142 95.7144C292.419 95.7144 295.357 94.9799 297.956 93.4544C300.555 91.9289 302.532 89.7254 304.001 86.7874C305.414 83.9059 306.148 80.4595 306.148 76.5045V74.6965C306.148 70.7415 305.414 67.3515 304.001 64.4136C302.532 61.5321 300.555 59.3286 297.956 57.8031C295.357 56.2776 292.419 55.4866 289.142 55.4866C285.808 55.4866 282.87 56.2776 280.271 57.8031C277.673 59.3286 275.695 61.5321 274.283 64.4136C272.814 67.3515 272.136 70.7415 272.136 74.6965ZM294.283 66.6735C295.526 68.651 296.204 71.4195 296.204 75.0355V76.1655C296.204 79.838 295.526 82.6629 294.283 84.5839C292.984 86.5049 291.289 87.4654 289.142 87.4654C286.995 87.4654 285.243 86.5049 284 84.5839C282.701 82.6629 282.079 79.838 282.079 76.1655V75.0355C282.079 71.4195 282.701 68.651 284 66.6735C285.243 64.7526 286.995 63.7356 289.142 63.7356C291.289 63.7356 292.984 64.7526 294.283 66.6735ZM308.872 79.4425V87.5219H327.743V95.3754H337.461V87.5219H342.489V79.4425H337.461V55.8256H325.087L308.872 79.4425ZM318.025 79.4425L327.743 65.1481V79.4425H318.025Z"
      fill="#CED6EF"
    />
    <path
      d="M118.241 220.229L124.71 205.77"
      stroke="#3F4251"
      strokeWidth="1.21154"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NotFoundSVG;
