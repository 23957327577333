import * as Text from '~styles/text';
import * as Style from './InputConvert.styles';
import { colors } from '~styles';
import { forwardRef, useRef, useState } from 'react';
import { When } from 'react-if';
import { InputConvertProps } from './InputConvert.types';
import CurrencyInput from 'react-currency-input-field';

// eslint-disable-next-line no-unused-vars
const InputConvert = forwardRef<HTMLInputElement, InputConvertProps>(
  (props, _ref) => {
    const placeholder = props.prefix ? `${props.prefix} 0.00` : '0.00';
    const inputRef = useRef<any>(null);
    const [hasValue, setHasValue] = useState(props.value);

    return (
      <Style.ContainerInput>
        <div className="inputWrapper">
          {props.prefix && hasValue && (
            <Text.Heading1Bold className="text" marginRight={10}>
              {props.prefix}
            </Text.Heading1Bold>
          )}
          <CurrencyInput
            ref={inputRef}
            intlConfig={{ locale: 'en-US' }}
            id="input-example"
            allowNegativeValue={false}
            name="input-name"
            autoComplete="off"
            placeholder={placeholder}
            className="input"
            decimalsLimit={props.precision}
            maxLength={11}
            value={props.value}
            disabled={props.disabled}
            onValueChange={(value) => {
              setHasValue(value !== undefined);
              if (props.onChange) {
                props.onChange(value as any);
              }
            }}
          />
        </div>

        <When condition={!!props.error}>
          <div>
            <Text.Small color={colors.danger} marginBottom={5}>
              {props.error}
            </Text.Small>
          </div>
        </When>
      </Style.ContainerInput>
    );
  }
);

InputConvert.displayName = 'InputConvert';

export default InputConvert;
