import * as Style from '../Kyc.styles';
import * as Text from '~styles/text';

import { Controller, useFormContext } from 'react-hook-form';
import {
  FooterButton,
  InputForm,
  InputPhone,
  InputSelect,
  InputSwitch,
} from '~components';
import {
  businessSize,
  declaredAnnualBillingOptions,
  legalNatureOptions,
} from '~data/kyc';
import {
  cellphoneMask,
  cepMask,
  cnaeMask,
  cnpjMask,
  dateMask,
} from '~helpers/format/masks';

import { CompanyDataFormProps } from '../Kyc.types';
import CompanyDataIcon from '~assets/images/icons/kyc/company-data.svg';
import ContactIcon from '~assets/images/icons/contact.svg';
import CounterInput from '~components/Inputs/CounterInput';
import FileIcon from '~assets/images/icons/file.svg';
import { When } from 'react-if';
import { colors } from '~styles';
import { parserBusiness } from '~helpers/format/parses';
import { useCreateBusinessMutation } from '~reactQuery/mutations/business/useCreateBusiness';
import { useEffect, useState } from 'react';
import { usePutBusinessMutation } from '~reactQuery/mutations/kyc/usePutBusiness';
import { useTranslation } from 'react-i18next';
import { containsOnlyNumbers } from '~validations/stringsValidate';

export const CompanyDataForm = ({
  goToStep,
  stepParams,
  processData,
}: CompanyDataFormProps) => {
  const [buildingNumber, setBuildingNumber] = useState('');
  const [errorOnBuildingNumber, setErrorOnBuildingNumber] = useState('');
  const { t } = useTranslation();

  const {
    control,
    formState: { errors, dirtyFields },
    setValue,
    watch,
    getValues,
    trigger,
    handleSubmit,
    register,
  } = useFormContext();

  const natureOptionsWithoutPartners = ['MEI', 'EI', 'EIRELI'];
  const DDIOptions = [{ value: '+55', label: 'Brasil (+55)' }];
  const countryOptions = [{ value: 'BR', label: 'Brasil' }];

  const { mutateAsync: createBusiness, isLoading: loadingCreateBiz } =
    useCreateBusinessMutation();
  const { mutateAsync: updateBusiness, isLoading: loadingUpdateBiz } =
    usePutBusinessMutation();

  const bizInCase = 0;
  const businessType = watch('businessType');
  const zipCode = watch('zipCode');
  const hasCity = watch('city');

  const noPartners = natureOptionsWithoutPartners.includes(businessType);
  const fromStatusScreen = stepParams.statusScreen;
  const loadingSubmit = loadingCreateBiz || loadingUpdateBiz;

  const goBack = () => {
    if (fromStatusScreen) {
      goToStep('status');
      return;
    }
    goToStep('start');
  };

  useEffect(() => {
    if (noPartners) {
      setValue('isLegalRepresentative', true);
      setValue('numberOfPartners', 0);
    }
  }, [businessType, noPartners, setValue]);

  const setAddrValues = (obj: any) => {
    if (obj) {
      setValue('state', obj?.uf);
      setValue('city', obj?.localidade);
      setValue('addressLine', obj?.logradouro);
      setValue('neighborhood', obj?.bairro);
      trigger([
        'state',
        'city',
        'addressLine',
        'neighborhood',
        'buildingNumber',
      ]);
    }
  };

  useEffect(() => {
    if (zipCode && zipCode?.length > 9 && !hasCity) {
      try {
        fetch(
          `https://viacep.com.br/ws/${getValues('zipCode')?.replace(
            /[.-]/g,
            ''
          )}/json/`
        ).then((resp) => {
          resp.json().then(setAddrValues);
        });
      } catch (error) {
        //  console.log('Get cep infos-> ', error);
      }
    }
  }, [zipCode]);

  const nextStep = handleSubmit(async (formValues) => {
    const valuesParsed = parserBusiness(formValues);

    try {
      if (valuesParsed?.uniqueFieldId) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete valuesParsed.uniqueFieldId;
      }

      if (!processData?.business?.[bizInCase]?.id) {
        await createBusiness({
          ...valuesParsed,
          processId: Number(processData?.id),
        });
      } else if (processData?.business?.[bizInCase]?.id) {
        await updateBusiness({
          ...valuesParsed,
          businessId: Number(processData?.business?.[bizInCase]?.id),
        });
      }

      if (fromStatusScreen) {
        goToStep('status');
      } else {
        goToStep('sendDocs');
      }
    } catch (error) {
      // console.log('Send BusinessData Error -> ', error);
    }
  });

  const validateToNextStep = () => {
    setErrorOnBuildingNumber('');
    if (!buildingNumber) {
      return setErrorOnBuildingNumber('Campo é obrigatório');
    }
    if (!containsOnlyNumbers(buildingNumber)) {
      return setErrorOnBuildingNumber('Tem que ser um número');
    }

    return nextStep();
  };

  useEffect(() => {
    if (getValues()?.buildingNumber) {
      setBuildingNumber(getValues()?.buildingNumber);
    }
  }, []);

  return (
    <>
      <div style={{ height: 48, width: 48 }}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <CompanyDataIcon style={{ borderRadius: 15 }} />
      </div>
      <Text.Heading1Medium marginTop={10} marginBottom={30}>
        Dados da Empresa
      </Text.Heading1Medium>

      <Text.Heading5 color={colors.black} marginBottom={40} lineHeight={140}>
        {t(
          'Antes de continuar, verifique se os dados inseridos estão exatamente iguais aos dados de registro da sua empresa, para evitar divergências.'
        )}
      </Text.Heading5>

      <Style.SubtitleWrapper marginBottom={40}>
        <div className="icon-wrapper">
          <FileIcon />
        </div>
        <Text.Heading4 marginLeft={10}>{t('Sobre a empresa')}</Text.Heading4>
      </Style.SubtitleWrapper>

      <Style.FormContainer>
        <Controller
          name="tradingName"
          render={({ field }) => (
            <InputForm
              {...field}
              autoFocus
              label={t('Nome Fantasia')}
              error={errors[field.name]?.message}
              marginTop={20}
              marginBottom={20}
            />
          )}
          control={control}
        />

        <Controller
          name="businessName"
          render={({ field }) => (
            <InputForm
              {...field}
              label={t('Razão Social')}
              error={errors[field.name]?.message}
              marginBottom={20}
            />
          )}
          control={control}
        />

        <div className="input-group">
          <Controller
            name="documentNumber"
            render={({ field }) => (
              <InputForm
                {...field}
                label={t('CNPJ')}
                error={errors[field.name]?.message}
                marginBottom={20}
                maskFunction={cnpjMask}
              />
            )}
            control={control}
          />

          <Controller
            name="businessCnae"
            render={({ field }) => (
              <InputForm
                {...field}
                label={t('CNAE')}
                error={errors[field.name]?.message}
                helperText="Usar principal em caso de vários"
                maskFunction={cnaeMask}
                marginBottom={20}
              />
            )}
            control={control}
          />

          <Controller
            name="businessType"
            render={({ field }) => (
              <InputSelect
                {...field}
                label={t('Natureza Jurídica')}
                options={legalNatureOptions}
                error={errors[field.name]?.message}
              />
            )}
            control={control}
          />

          <Controller
            name="businessSize"
            render={({ field }) => (
              <InputSelect
                {...field}
                label={t('Porte da Empresa')}
                options={businessSize}
                error={errors[field.name]?.message}
              />
            )}
            control={control}
          />

          <Controller
            name="declaredAnnualBilling"
            render={({ field }) => (
              <InputSelect
                {...field}
                label={t('Faturamento Anual Declarado')}
                options={declaredAnnualBillingOptions}
                placeholder="Selecione"
                error={errors[field.name]?.message}
              />
            )}
            control={control}
          />

          <Controller
            name="companyStartDate"
            render={({ field }) => (
              <InputForm
                {...field}
                label={t('Data da Abertura')}
                placeholder="00/00/0000"
                error={errors[field.name]?.message}
                helperText="A data que consta no cartão CNPJ"
                maskFunction={dateMask}
              />
            )}
            control={control}
          />

          <When condition={!noPartners}>
            <Controller
              name="numberOfPartners"
              render={({ field }) => (
                <CounterInput
                  {...field}
                  label={t('Quantidade de Sócios')}
                  hookFormSetValue={setValue}
                  fieldName="numberOfPartners"
                  error={errors[field.name]?.message}
                  defaultValue={getValues('numberOfPartners')}
                />
              )}
              control={control}
            />
          </When>
        </div>

        <When condition={dirtyFields.businessType && !noPartners}>
          <Style.Line marginTop={20} marginBottom={20} />

          <div className="toggle-content-wrapper">
            <Text.Heading5>
              {t('Eu sou o Representante Legal desta empresa')}
            </Text.Heading5>

            <Controller
              name="isLegalRepresentative"
              render={({ field }) => (
                <InputSwitch
                  checked={getValues('isLegalRepresentative')}
                  {...field}
                />
              )}
              control={control}
            />
          </div>

          <Style.Line marginTop={20} />
        </When>

        <Style.Line marginTop={20} marginBottom={20} />

        <div className="toggle-content-wrapper">
          <Text.Heading5>
            {t(
              'Minha empresa realiza trade de criptomoedas, transações peer-to-peer (p2p) ou atividades semelhantes.'
            )}
          </Text.Heading5>

          <Controller
            name="isCryptoTrader"
            render={({ field }) => (
              <InputSwitch
                checked={getValues('isCryptoTrader')}
                {...field}
              />
            )}
            control={control}
          />
        </div>

        <Style.Line marginTop={20} />

        <Style.SubtitleWrapper marginTop={30} marginBottom={30}>
          <div className="icon-wrapper">
            <ContactIcon />
          </div>
          <Text.Heading4 marginLeft={10}>{t('Contato')}</Text.Heading4>
        </Style.SubtitleWrapper>

        <div className="input-group">
          <Controller
            name="countryCode"
            render={({ field }) => (
              <InputSelect
                {...field}
                label={t('Código do País')}
                options={DDIOptions}
                error={errors[field.name]?.message}
              />
            )}
            control={control}
          />

          <Controller
            name="phone"
            render={({ field }) => (
              <InputPhone
                {...field}
                label={t('Telefone')}
                ddi={watch('countryCode')}
                placeholder="00 00000-0000"
                error={errors[field.name]?.message}
                maskFunction={cellphoneMask}
              />
            )}
            control={control}
          />
        </div>

        <Controller
          name="site"
          render={({ field }) => (
            <InputForm
              {...field}
              label={t('Site (opcional)')}
              marginTop={20}
              marginBottom={20}
              error={errors[field.name]?.message}
            />
          )}
          control={control}
        />

        <Controller
          name="businessEmail"
          render={({ field }) => (
            <InputForm
              {...field}
              label={t('E-mail Comercial da Empresa')}
              error={errors[field.name]?.message}
            />
          )}
          control={control}
        />

        <Style.SubtitleWrapper marginTop={30} marginBottom={30}>
          <div className="icon-wrapper">
            <FileIcon />
          </div>
          <Text.Heading4 marginLeft={10}>{t('Endereço')}</Text.Heading4>
        </Style.SubtitleWrapper>

        <div className="input-group">
          <Controller
            name="country"
            render={({ field }) => (
              <InputSelect
                {...field}
                label={t('País/Região')}
                placeholder="Selecione"
                options={countryOptions}
                error={errors[field.name]?.message}
              />
            )}
            control={control}
          />

          <Controller
            name="zipCode"
            render={({ field }) => (
              <InputForm
                {...field}
                label={t('CEP')}
                placeholder="00.000-000"
                error={errors[field.name]?.message}
                maskFunction={cepMask}
              />
            )}
            control={control}
          />

          <Controller
            name="city"
            render={({ field }) => (
              <InputForm
                {...field}
                label={t('Cidade')}
                error={errors[field.name]?.message}
              />
            )}
            control={control}
          />

          <Controller
            name="state"
            render={({ field }) => (
              <InputForm
                {...field}
                label={t('Estado (Sigla)')}
                maxLength={2}
                error={errors[field.name]?.message}
              />
            )}
            control={control}
          />
        </div>

        <Controller
          name="addressLine"
          render={({ field }) => (
            <InputForm
              {...field}
              label={t('Endereço')}
              marginTop={20}
              marginBottom={20}
              error={errors[field.name]?.message}
            />
          )}
          control={control}
        />

        <div className="input-group">
          <InputForm
            {...register('buildingNumber')}
            label={t('Número')}
            value={buildingNumber}
            error={errorOnBuildingNumber}
            onChange={(event: any) => setBuildingNumber(event.target.value)}
            maxLength={4}
          />

          <Controller
            name="neighborhood"
            render={({ field }) => (
              <InputForm
                {...field}
                label={t('Bairro')}
                error={errors[field.name]?.message}
              />
            )}
            control={control}
          />

          <Controller
            name="complement"
            render={({ field }) => (
              <InputForm
                {...field}
                error={errors[field.name]?.message}
                label={t('Complemento (opcional)')}
              />
            )}
            control={control}
          />
        </div>
      </Style.FormContainer>

      <Text.Paragraph
        color={colors.black}
        marginTop={20}
        marginBottom={40}
        lineHeight={140}
        display="block"
      >
        {t(
          'Ao selecionar Concordar e Continuar abaixo, confirmo que li e concordo com os'
        )}{' '}
        <a
          href="https://capitual.com/legal/terms-of-use"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          {t('Termos de Uso')}
        </a>
        , {t('a')}{' '}
        <a
          href="https://capitual.com/legal/privacy-policy"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          {t('Política de Privacidade')}
        </a>
        , {t('a')}{' '}
        <a
          href="https://capitual.com/legal/kyc"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          {t('Política KYC')}
        </a>{' '}
        {t('e também a')}{' '}
        <a
          href="https://capitual.com/legal/prohibited-businesses"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          {t('Lista de Negociações Proibidas do Capitual')}
        </a>
      </Text.Paragraph>

      <FooterButton
        onClickGenericButton={validateToNextStep}
        onClickLeftButton={goBack}
        leftButtonContent="Voltar"
        genericButtonType="secondary"
        genericButtonContent="Concordar e Continuar"
        loadingGenericButton={loadingSubmit}
      />
    </>
  );
};
