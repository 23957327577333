import * as Style from '../Transfer.styles';
import * as Text from '~styles/text';

import { ChooseTransferMethodProps, Tabs } from '../Transfer.types';

import CriptoTabContent from './1.2.CriptoTabContent.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import PartnerExchangeTabContent from './1.3.PartnerExchangeTabContent.component';
import PixTabContent from './1.1.PixTabContent.component';
import { When } from 'react-if';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

function ChooseTransferMethod(props: ChooseTransferMethodProps) {
  const {
    setSelectedTab,
    setPixStep,
    selectedTab,
    setCriptoWithdrawStep,
    buttonsPixGranted,
    criptoTransactionsGranted,
  } = props;
  const { params, setSection, setParams } = useActionsOnModalMultiStep();
  const receivedParams = params;
  const { setValue } = useFormContext();
  const handleTabStep = (tab: Tabs) => setSelectedTab(tab);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (params?.destinationWallet) {
      setValue('selectedWallet', params?.destinationWallet);
      return handleTabStep('criptoWithdraw');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    setSection('send');
    setParams(receivedParams);
  };

  const handleDefineStep = (hasPermission: boolean, value: Tabs) => {
    if (hasPermission) {
      handleTabStep(value);
    }
  };

  const handleDefineTabIsDisabled = (hasPermission: boolean, tab: Tabs) => {
    if (!hasPermission) {
      return 'disabled';
    }

    if (selectedTab === tab) {
      return 'active';
    }

    return '';
  };

  const renderTabStep = () => {
    if (params?.isComingFrom === 'capConta') {
      return (
        <Text.Heading5
          onClick={() => handleTabStep('pix')}
          className={selectedTab === 'pix' ? 'active' : ''}
        >
          Pix/TED
        </Text.Heading5>
      );
    }

    if (!params?.destinationWallet) {
      // eslint-disable-next-line consistent-return
      return (
        <>
          <Text.Heading5
            onClick={() => handleDefineStep(buttonsPixGranted, 'pix')}
            className={handleDefineTabIsDisabled(buttonsPixGranted, 'pix')}
          >
            Pix/TED
          </Text.Heading5>

          <Text.Heading5
            onClick={() =>
              handleDefineStep(criptoTransactionsGranted, 'criptoWithdraw')
            }
            className={handleDefineTabIsDisabled(
              criptoTransactionsGranted,
              'criptoWithdraw'
            )}
          >
            Rede de Criptomoeda
          </Text.Heading5>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <ModalMultiStepHeader
        title={
          params?.destinationWallet
            ? 'Para qual rede você quer enviar?'
            : 'Transferir'
        }
        description={
          params?.destinationWallet &&
          'Após escolher a rede, cole o endereço da carteira para o envio.'
        }
        backButtonAction={params?.destinationWallet && goBack}
      />
      <Style.TabMenu style={params?.destinationWallet && { marginTop: 15 }}>
        {renderTabStep()}

        {/* <Text.Heading5
          onClick={() => handleTabStep('exchange')}
          className={tabStep === 'exchange' ? 'active' : ''}
        >
          Exchange Parceira
        </Text.Heading5> */}
      </Style.TabMenu>

      <div className="content-container">
        <When condition={selectedTab === 'pix'}>
          <PixTabContent setPixStep={setPixStep} />
        </When>

        <When condition={selectedTab === 'criptoWithdraw'}>
          <CriptoTabContent setCriptoWithdrawStep={setCriptoWithdrawStep} />
        </When>

        <When condition={selectedTab === 'exchange'}>
          <PartnerExchangeTabContent />
        </When>
      </div>
    </>
  );
}

export default ChooseTransferMethod;
