import { SVGProps } from 'react';

const BlueBarcodeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '30'}
    height={props.height || '25'}
    viewBox="0 0 30 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0.611113C0.447715 0.611113 0 1.05883 0 1.61111V7.83333C0 8.38562 0.447715 8.83333 1 8.83333C1.55228 8.83333 2 8.38562 2 7.83333V2.61111H5.66667C6.21895 2.61111 6.66667 2.1634 6.66667 1.61111C6.66667 1.05883 6.21895 0.611113 5.66667 0.611113H1ZM1 24.3889C0.447715 24.3889 0 23.9412 0 23.3889V17.1667C0 16.6144 0.447715 16.1667 1 16.1667C1.55228 16.1667 2 16.6144 2 17.1667V22.3889H5.66667C6.21895 22.3889 6.66667 22.8366 6.66667 23.3889C6.66667 23.9412 6.21895 24.3889 5.66667 24.3889H1ZM30 1.61111C30 1.05883 29.5523 0.611113 29 0.611113H24.3333C23.781 0.611113 23.3333 1.05883 23.3333 1.61111C23.3333 2.1634 23.781 2.61111 24.3333 2.61111H28V7.83333C28 8.38562 28.4477 8.83333 29 8.83333C29.5523 8.83333 30 8.38562 30 7.83333V1.61111ZM29 24.3889C29.5523 24.3889 30 23.9412 30 23.3889V17.1667C30 16.6144 29.5523 16.1667 29 16.1667C28.4477 16.1667 28 16.6144 28 17.1667V22.3889H24.3333C23.781 22.3889 23.3333 22.8366 23.3333 23.3889C23.3333 23.9412 23.781 24.3889 24.3333 24.3889H29ZM6.83333 6.83333C7.38562 6.83333 7.83333 7.28105 7.83333 7.83333V17.1667C7.83333 17.719 7.38562 18.1667 6.83333 18.1667C6.28105 18.1667 5.83333 17.719 5.83333 17.1667V7.83333C5.83333 7.28105 6.28105 6.83333 6.83333 6.83333ZM13.2778 7.83333C13.2778 7.28105 12.8301 6.83333 12.2778 6.83333C11.7255 6.83333 11.2778 7.28105 11.2778 7.83333V17.1667C11.2778 17.719 11.7255 18.1667 12.2778 18.1667C12.8301 18.1667 13.2778 17.719 13.2778 17.1667V7.83333ZM17.7222 6.83333C18.2745 6.83333 18.7222 7.28105 18.7222 7.83333V17.1667C18.7222 17.719 18.2745 18.1667 17.7222 18.1667C17.1699 18.1667 16.7222 17.719 16.7222 17.1667V7.83333C16.7222 7.28105 17.1699 6.83333 17.7222 6.83333ZM24.1667 7.83333C24.1667 7.28105 23.719 6.83333 23.1667 6.83333C22.6144 6.83333 22.1667 7.28105 22.1667 7.83333V17.1667C22.1667 17.719 22.6144 18.1667 23.1667 18.1667C23.719 18.1667 24.1667 17.719 24.1667 17.1667V7.83333Z"
      fill={props.fill || '#026FF4'}
    />
  </svg>
);

export default BlueBarcodeIcon;
