import * as Style from './Menu.styles';
import * as Text from '~styles/text';

import { useLocation, useNavigate } from 'react-router-dom';
import { clearStorage, getSelectedUserOrganization } from '~helpers/device';
import { useQueryClient } from 'react-query';
import { APP_ROUTES } from '~data/navigation';

import BigClose from '~assets/images/icons/big-close.svg';

import SymbolLogo from '~assets/images/icon-business.svg';
import BizLogo from '~assets/images/logo-business.svg';
import LogoutIcon from '~assets/images/icons/16x16/logout.svg';
import SettingsIcon from '~assets/images/icons/16x16/settings.svg';
import SwapIcon from '~assets/images/icons/16x16/swap.svg';
import IncomeIcon from '~assets/svg/income';
import { colors } from '~styles';
import { menuOptions } from '~data/menu';
import { transparentize } from 'polished';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import { usePermission } from '~helpers/permissionGate';

const setItemColor = (
  currentPage: string,
  itemRoute: string,
  comingSoon?: boolean
) => {
  if (comingSoon) {
    return transparentize(0.7, colors.black);
  }

  if (currentPage === itemRoute) {
    return colors.primary;
  }
  return colors.black;
};

const Menu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setOpenModalSideBar, setParams } = useOpenModalSideBar();
  const currentPage = pathname;

  const onClickMenu = (route?: string) => {
    setOpenModalSideBar(false);
    setParams({});
    // We need it because of animation closing sidemodal purposes
    setTimeout(() => {
      navigate(String(route));
    }, 175);
  };

  function goHome() {
    navigate('/dashboard');
  }

  const { granted: billPageGranted } = usePermission('billPage');
  const { granted: activitiesPageGranted } = usePermission('activitiesPage');
  const { granted: capAccountPageGranted } = usePermission('capAccountPage');
  const { granted: pixPageGranted } = usePermission('pixPage');

  return (
    <Style.Container data-hide-for-print="hidden-for-print">
      <div onClick={goHome} className="logo-container">
        <SymbolLogo />
      </div>

      <Style.ListMenu>
        {menuOptions.map((item) => {
          let customItem = item;

          if (item?.title === 'Boletos e Cobranças') {
            customItem = { ...item, comingSoon: !billPageGranted };
          }
          if (item?.title === 'Atividades') {
            customItem = { ...item, comingSoon: !activitiesPageGranted };
          }
          if (item?.title === 'CapConta e Carteiras') {
            customItem = { ...item, comingSoon: !capAccountPageGranted };
          }

          if (item?.title === 'Área Pix') {
            customItem = { ...item, comingSoon: !pixPageGranted };
          }

          return (
            <li
              key={customItem.id}
              onClick={() =>
                !customItem.comingSoon && onClickMenu(customItem.route)
              }
              className={currentPage === customItem.route ? 'active' : ' '}
            >
              <a className={customItem.comingSoon ? 'soon' : ''}>
                {customItem.icon}
                <div
                  className={`tooltip-text ${
                    customItem.title.length > 15 ? 'w-190' : 'w-100'
                  }`}
                >
                  <Text.Heading5 color={colors.white}>
                    {customItem.title}
                  </Text.Heading5>
                </div>
              </a>
            </li>
          );
        })}
      </Style.ListMenu>
    </Style.Container>
  );
};

const MobileMenu = ({ isOpen, setIsOpen }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentPage = pathname;
  const queryClient = useQueryClient();

  const onClickMenu = (route?: string) => {
    setIsOpen(false);

    // We need it because of animation closing sidemodal purposes
    setTimeout(() => {
      navigate(String(route));
    }, 175);
  };

  const { granted: billPageGranted } = usePermission('billPage');
  const { granted: activitiesPageGranted } = usePermission('activitiesPage');
  const { granted: capAccountPageGranted } = usePermission('capAccountPage');
  const { granted: pixPageGranted } = usePermission('pixPage');

  const handleLogout = () => {
    clearStorage(queryClient);
    navigate('/auth');
  };

  const handleAlternateAccount = () => {
    localStorage.removeItem('@CapBiz:selectedUserOrganization');
    navigate(APP_ROUTES.SELECT_ORGANIZATIONS);
  };

  return (
    <Style.MobileContainer className={isOpen ? 'active' : ''}>
      <div className="header-container">
        <div className="logo-container">
          <BizLogo />
        </div>

        <a className="close" onClick={() => setIsOpen(false)}>
          <BigClose />
        </a>
      </div>

      <Style.ListMobileMenu>
        {menuOptions.map((item) => {
          let customItem = item;

          if (item?.title === 'Boletos e Cobranças') {
            customItem = { ...item, comingSoon: !billPageGranted };
          }
          if (item?.title === 'Atividades') {
            customItem = { ...item, comingSoon: !activitiesPageGranted };
          }
          if (item?.title === 'CapConta e Carteiras') {
            customItem = { ...item, comingSoon: !capAccountPageGranted };
          }

          if (item?.title === 'Área Pix') {
            customItem = { ...item, comingSoon: !pixPageGranted };
          }

          return (
            <li
              key={customItem.id}
              className={currentPage === customItem.route ? 'active' : ' '}
              onClick={() =>
                !customItem.comingSoon && onClickMenu(customItem.route)
              }
            >
              <a>
                {customItem.icon}
                <Text.Heading5
                  color={setItemColor(
                    currentPage,
                    customItem.route,
                    customItem.comingSoon
                  )}
                >
                  {customItem.title}
                </Text.Heading5>
              </a>
            </li>
          );
        })}
      </Style.ListMobileMenu>

      <Style.ListMobileMenu className="no-border-bottom">
        <li
          className={`${
            currentPage === '/settings' ? 'active' : ''
          } list-menu-bottom`}
        >
          <a onClick={() => onClickMenu('/settings')}>
            <SettingsIcon />

            <Text.Heading4 color={setItemColor(currentPage, '/settings')}>
              Configurações
            </Text.Heading4>
          </a>
        </li>

        <li className="list-menu-bottom">
          <a onClick={() => handleAlternateAccount()}>
            <SwapIcon />

            <Text.Heading4>Alternar Conta</Text.Heading4>
          </a>
        </li>

        <li className="list-menu-bottom logout">
          <a onClick={() => handleLogout()}>
            <LogoutIcon />

            <Text.Heading4 color={colors.danger}>Sair</Text.Heading4>
          </a>
        </li>
      </Style.ListMobileMenu>
    </Style.MobileContainer>
  );
};

export { Menu, MobileMenu };
