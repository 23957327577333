import * as Text from '~styles/text';
import * as Style from '../GuestRegister.styles';
import colors from '~styles/colors';
import { FooterButton, InputForm, PasswordChecker } from '~components';
import { breakpoints } from '~styles/metrics';
import useWindowDimensions from '~hooks/windowDimension';
import { t } from 'i18next';
import { cellphoneMask } from '~helpers/format/masks';
import UserIcon from '~assets/images/icons/24x24/user.svg';
import SupportIcon from '~assets/images/icons/24x24/support.svg';
import DocumentSVG from '~assets/svg/document';
import { CommonStepParams } from '../GuestRegister.types';
import { useFormContext } from 'react-hook-form';

const UserForm = ({ stepParams, goToStep }: CommonStepParams) => {
  const { width } = useWindowDimensions();
  const {
    register,
    watch,
    formState: { errors },
    trigger,
  } = useFormContext();

  const password = watch('passwd');

  const onSubmit = () => {
    trigger().then((isValid) => {
      if (isValid) {
        goToStep('createPin');
      }
    });
  };

  const verifyIsDisabled = (isActive: boolean) => {
    if (!isActive) {
      return colors.grayLight;
    }

    return colors.black;
  };

  return (
    <Style.Container>
      <div className="footer-avoid container-form-userguest">
        <div className="main-icon">
          <DocumentSVG />
        </div>

        <Text.Heading1Medium marginTop={10} marginBottom={30}>
          Vamos começar! Crie seu cadastro agora mesmo
        </Text.Heading1Medium>

        <Text.Heading5
          color={colors.black}
          marginBottom={30}
          lineHeight={140}
          width={breakpoints.desktop < width ? '100%' : ''}
        >
          Seu CPF foi verificado com sucesso. Revise seus dados antes de
          continuar.
        </Text.Heading5>

        <div className="item-separation mb-30">
          <UserIcon />

          <Text.Heading4 marginLeft={10}>Sobre você</Text.Heading4>
        </div>

        <div className="input-container">
          <InputForm
            {...register('fullName')}
            label="Nome Completo"
            error={errors?.fullName?.message}
            autoFocus
          />
        </div>

        <div className="input-container">
          <InputForm
            {...register('socialName')}
            label="Nome Social"
            helperText="Nome pelo qual gostaria de ser chamado."
            error={errors?.socialName?.message}
          />
        </div>

        <div className="input-container">
          <InputForm
            {...register('passwd')}
            label={t('Nova Senha')}
            type="password"
            error={errors?.passwd?.message}
          />
          <PasswordChecker password={password} />
        </div>

        <div className="input-container">
          <InputForm
            {...register('confirmPassword')}
            type="password"
            label={t('Confirmar senha')}
            error={errors?.confirmPassword?.message}
          />
        </div>

        <div className="item-separation mt-30 mb-30">
          <SupportIcon />

          <Text.Heading4 marginLeft={10}>Contato</Text.Heading4>
        </div>

        <div className="input-container">
          <InputForm
            {...register('phone')}
            label={t('Telefone')}
            error={errors?.phone?.message}
            maskFunction={cellphoneMask}
          />
        </div>
        <div className="input-container">
          <InputForm
            {...register('email')}
            label={t('E-mail')}
            error={errors?.email?.message}
            disabled
          />
        </div>

        <Text.Paragraph marginTop={20} marginBottom={100} display="inline">
          Ao selecionar Concordar e Continuar abaixo, confirmo que li e concordo
          com os{' '}
          <a href="https://capitual.com/legal/terms-of-use">Termos de Uso</a>, a{' '}
          <a href="https://capitual.com/legal/privacy-policy">
            Política de Privacidade
          </a>
          , a <a href="https://capitual.com/legal/kyc">Política KYC</a> e também
          a Lista de{' '}
          <a href="https://capitual.com/legal/prohibited-businesses">
            Negociações Proibidas
          </a>{' '}
          do Capitual.
        </Text.Paragraph>
      </div>

      <FooterButton
        onClickGenericButton={() => onSubmit()}
        leftButtonContent="Voltar"
        onClickLeftButton={() => goToStep('check')}
        genericButtonType="secondary"
        genericButtonContent="Concordar e Continuar"
      />
    </Style.Container>
  );
};

export default UserForm;
