import ArrowPointLeftSVG from '~assets/svg/arrowPointLeft';
import * as Style from '../SearchRecipient.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import InputCurrency from '~components/Inputs/InputCurrency/InputCurrency.component';
import { InputForm, InputSwitch } from '~components/Inputs';
import { formatCpfOrCnpj } from '~helpers/format/numbers';
import { TransferByPixFormProps } from '../SearchRecipient.types';
import { useFormContext } from 'react-hook-form';
import { When } from 'react-if';
import { ErrorToast, GenericButton } from '~components/index';

const TransferByPixForm = (props: TransferByPixFormProps) => {
  const {
    setStepPersonWillReceive,
    selectedContact,
    listOfBeneficiaries,
    setListOfBeneficiaries,
  } = props;

  const {
    watch,
    register,
    reset,
    formState: { errors },
  } = useFormContext();

  const handleGoBack = () => {
    reset();
    setStepPersonWillReceive('listContacts');
  };

  const name = selectedContact?.name || selectedContact?.holderName;

  const onRemoveBeneficiary = async () => {
    if (!listOfBeneficiaries || listOfBeneficiaries.length === 0) {
      ErrorToast({
        title: 'Lista já está vazia!',
        autoCloseEnable: true,
      });
      return;
    }

    setListOfBeneficiaries((prevList) => {
      const updatedList = prevList?.filter(
        (_, index) => index !== selectedContact?.indexInArray
      );
      return updatedList;
    });

    setStepPersonWillReceive('listOfBeneficiaries');
  };

  return (
    <Style.FormDataContainer>
      <div className="go-back-button" onClick={() => handleGoBack()}>
        <ArrowPointLeftSVG fill={colors.grayMedium} />
        <Text.Heading2Bold style={{ fontFeatureSettings: '"ss02", "zero"' }}>
          Transferindo para{' '}
          {selectedContact?.documentNumber
            ? formatCpfOrCnpj(selectedContact?.documentNumber)
            : name}
        </Text.Heading2Bold>
      </div>

      <InputCurrency
        defaultValue={selectedContact?.amount || 'R$ 0,00'}
        {...register('amount')}
        className={
          watch('amount') !== 'R$ 0,00'
            ? 'input-value'
            : 'input-value without-value'
        }
        error={errors?.amount?.message as string}
      />

      <div className="row-switch">
        <Text.Heading5Bold>
          Salvar contato para próximas transações
        </Text.Heading5Bold>
        <InputSwitch
          hookForm={{ ...register('saveForNextTransfer') }}
          className="input-switch"
        />
      </div>

      <div className="hidden-div">
        <InputForm
          defaultValue={selectedContact?.e2eId}
          {...register('e2eId')}
          error={errors?.e2eId?.message}
        />
      </div>

      <div className="hidden-div">
        <InputForm
          defaultValue={selectedContact?.accountType}
          {...register('accountType')}
          error={errors?.accountType?.message}
        />
      </div>

      <div className="hidden-div">
        <InputForm
          defaultValue={selectedContact?.keyValue}
          {...register('keyValue')}
          error={errors?.keyValue?.message}
        />
      </div>

      <div className="hidden-div">
        <InputForm
          defaultValue={selectedContact?.holderDocument}
          {...register('holderDocument')}
          error={errors?.holderDocument?.message}
        />
      </div>

      <div className="hidden-div">
        <InputForm
          defaultValue={selectedContact?.keyType}
          {...register('keyType')}
          error={errors?.keyType}
        />
      </div>

      <div className="hidden-div">
        <InputForm
          defaultValue={selectedContact?.holderName}
          {...register('holderName')}
          error={errors?.holderName?.message}
        />
      </div>

      <div className="hidden-div">
        <InputForm
          defaultValue={selectedContact?.name}
          {...register('fullName')}
          error={errors?.fullName?.message}
        />
      </div>

      <When condition={typeof selectedContact?.indexInArray !== 'undefined'}>
        <GenericButton
          width="100%"
          buttonType="inlineError"
          onClick={() => onRemoveBeneficiary()}
          buttonLoading={false}
          marginTop={42}
        >
          Remover Beneficiário
        </GenericButton>
      </When>
    </Style.FormDataContainer>
  );
};

export default TransferByPixForm;
