const PixTedSentSVG = (props: React.SVGProps<SVGSVGElement>) => {
  const { fill = 'none', stroke = '#757893', height = 24, width = 24 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M16.7178 7.00001L19.7178 4.00001L22.7178 7.00001"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M19.7178 5.00004L19.7178 12"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.5 6.95807H3.5C2.39543 6.95807 1.5 7.8535 1.5 8.95807V17.9581C1.5 19.0626 2.39543 19.9581 3.5 19.9581H20C21.1046 19.9581 22 19.0626 22 17.9581V17.0001"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14.7178 13.4581C14.7178 15.115 13.3746 16.4581 11.7178 16.4581C10.0609 16.4581 8.71777 15.115 8.71777 13.4581C8.71777 11.8012 10.0609 10.4581 11.7178 10.4581C13.3746 10.4581 14.7178 11.8012 14.7178 13.4581Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default PixTedSentSVG;
