const ConvertSVG = (props: React.SVGProps<SVGSVGElement>) => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 25}
      height={height || 24}
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M3.2594 10.3L4.46471 8.61257C5.59098 7.03579 7.4094 6.1 9.34711 6.1H20.0594M20.0594 6.1L16.1714 2.5M20.0594 6.1L16.1714 9.7"
        stroke={fill || '#0F90FF'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M21.2594 12.7L19.4553 14.8047C18.3154 16.1346 16.6513 16.9 14.8998 16.9H3.85941M3.85941 16.9L7.74741 20.5M3.85941 16.9L7.74741 13.3"
        stroke={fill || '#0F90FF'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default ConvertSVG;
