import * as Style from './Chip.styles';
import * as Text from '~styles/text';

import { ChipProps } from './Chip.types';

const Chip = (props: ChipProps) => {
  return (
    <Style.Container {...props} onClick={props.onClick}>
      <div className="c-pointer">{props.leftIcon}</div>
      <div className="horizontal-margins">{props.textComponent}</div>
      <div className="c-pointer">{props.rightIcon}</div>
    </Style.Container>
  );
};

export default Chip;
