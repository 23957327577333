import { WalletsList, sparkLineColorSelector } from '~data/wallets';

import { CurrencyTypes } from '~src/@types/currency.types';
import c from 'currency.js';

export const currencyPrecisions = {
  ETH: 6,
  BTC: 8,
  LTC: 8,
  XRP: 8,
  DSH: 8,
  EUSDT: 2,
  BUSD: 2,
  DAI: 2,
  USDC: 2,
  XAU: 6,
  AXS: 6,
  BTT: 6,
  CAKE: 6,
  CHZ: 6,
  DOGE: 8,
  BCH: 6,
  UNI: 6,
  LINK: 6,
  BNB: 6,
  DOT: 6,
  ADA: 6,
  USDT: 6,
  SHIB: 6,
  MATIC: 6,
  FTM: 6,
  HT: 6,
  KCS: 6,
  TRX: 6,
  TUSDT: 6,
};

const cryptoFormatLogic = (
  value: number | string,
  isoCode: string,
  removeSymbol: boolean,
  invertSymbol: boolean
) => {
  const precision =
    currencyPrecisions[isoCode as keyof typeof currencyPrecisions];

  const result = c(value, { precision }).format({ symbol: '', precision });
  // NO symbol
  if (removeSymbol) return result;

  // symbol first or in the last
  return invertSymbol ? `${isoCode} ${result}` : `${result} ${isoCode}`;
};

const fiatFormatLogic = (
  value: string,
  isoCode: string,
  removeSymbol: boolean,
  fromCents?: boolean
) => {
  const symbol = {
    BRL: 'R$ ',
    EUR: '€ ',
    USD: '$ ',
    GBP: '£ ',
  };
  if (isoCode === 'BRL') {
    return c(value, { fromCents }).format({
      symbol: removeSymbol ? '' : symbol[isoCode],
      decimal: ',',
      separator: '.',
    });
  }

  return c(value).format({
    symbol: removeSymbol ? '' : symbol[isoCode as keyof typeof symbol],
    decimal: '.',
    separator: ',',
  });
};

export const isFiatCurrency = (isoCode: CurrencyTypes) => {
  return ['BRL', 'USD', 'EUR', 'GBP'].includes(isoCode);
};

export const formatCurrencyFn = (
  isoCode: string | CurrencyTypes | undefined,
  value?: string | undefined,
  removeSymbol?: boolean,
  invertSymbol?: boolean,
  fromCents?: boolean
) => {
  try {
    if (!value || !isoCode) {
      return '--';
    }

    if (isFiatCurrency(isoCode as CurrencyTypes)) {
      return fiatFormatLogic(
        value,
        isoCode.toLocaleUpperCase(),
        removeSymbol || false,
        fromCents
      );
    }

    return cryptoFormatLogic(
      value,
      isoCode.toLocaleUpperCase(),
      removeSymbol || false,
      invertSymbol || false
    );
  } catch (error) {
    return '--';
  }
};

export const getWalletIcon = (walletIsoCode: CurrencyTypes) => {
  if (!walletIsoCode) return null;
  const wallet = WalletsList.filter(
    (w) => w.name.toUpperCase() === walletIsoCode.toUpperCase()
  );
  return wallet[0] ? wallet[0].icon : null;
};

export const getWalletColor = (walletIsoCode: CurrencyTypes) => {
  return sparkLineColorSelector[walletIsoCode];
};

export const formatExponentialNumber = (
  value: string | number | null | undefined,
  precision: number
) => {
  if (
    typeof value !== 'string' ||
    typeof precision !== 'number' ||
    value === ''
  ) {
    return '-';
  }
  return Number.parseFloat(value).toFixed(precision);
};

export const isExponentialNumber = (value: string) =>
  !!value?.toString().includes('e-') || !!value?.toString().includes('e+');

export const moneyMask = (value: string) => {
  let valueMod = '';

  valueMod = value.replace('.', '').replace(',', '').replace(/\D/g, '');

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(valueMod) / 100
  );

  return `R$ ${result === 'NaN' ? 0 : result}`;
};

export const removeMoneyMask = (value: string, brFormat?: boolean) => {
  if (brFormat) {
    const clearString = Number(
      value.replace('.', '').replace(',', '.').replace('R$ ', '')
    );

    return Number.isNaN(clearString) ? String(0) : String(clearString);
  }
  const clearString = Number(value.replace(',', ''));

  return Number.isNaN(clearString) ? 0 : clearString;
};

export const percentageRange = (value: number) => {
  if (value >= 0 && value <= 100) {
    return value;
  }
  if (value > 100) {
    return 100;
  }

  return 0;
};
