import { colors } from '~styles';
import { screen } from '~styles/metrics';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { GeneralStyleProps } from '~src/@types/general';

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  overflow-x: none;

  .mt-210 {
    margin-top: 210px;
  }

  .scroll {
    overflow-y: scroll;
  }

  @media ${screen.tablet} {
    .mt-mobile-45 {
      margin-top: 45px;
    }

    .scroll {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .cap-wallet-container {
    overflow-x: none;

    .heading {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-right: 9px;
      }
    }
  }

  .outline-button {
    padding: 30px 20px;
    align-self: center;
  }

  .empty-transactions,
  .empty-payments-scheduled {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 310px;
    padding: 49px 0;
    margin-top: 41px;
    background-color: ${colors.white};
    border-radius: 10px;
  }
`;

export const MainCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  width: 100%;
  height: 330px;
  margin-top: 25px;

  .coming-soon-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 44px;
    height: 44px;
    background: ${transparentize(0.8, colors.primary)};
    border-radius: 50%;
  }

  .coming-soon-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;
    z-index: 10;
    width: 482px;
    height: 161px;
    background-color: ${transparentize(0.1, colors.gray)};
    padding: 18px 24px;
    border-radius: 8px;
  }

  .coming-soon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    width: 100%;
    height: 330px;
    top: 0;

    /* filter: blur(4px);
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px); */
  }

  .total-value2 {
    width: 100% !important;
  }

  .total-value,
  .total-value2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    width: 65%;
    height: 330px;
    border-radius: 10px;
    border: 1px solid ${transparentize(0.9, colors.black)};
    background-color: ${colors.white};
    margin-bottom: 0px;

    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .profits {
    width: 33%;
    height: 330px;
    border-radius: 10px;
    background-color: ${colors.white};
    border: 1px solid ${transparentize(0.9, colors.black)};
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.02);
  }

  .total-value,
  .total-value2,
  .profits {
    .text-container {
      position: absolute;
      top: 0;

      padding: 20px 30px;
      z-index: 9;

      .tip-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        position: relative;
        height: auto;

        .wall-top {
          position: absolute;
          top: -20px;

          width: 180px;
          height: 19px;
          background-color: ${colors.white};
        }

        .move-back {
          transition: all ease-out 0.2s;
        }

        .move-to-wall {
          margin-top: -20px;

          transition: all ease-out 0.2s;
        }

        .chart-date-text {
          height: 19px;
          margin-bottom: -20px;

          transition: all ease-out 0.2s;

          &.visible {
            visibility: visible;
            margin-bottom: -10px;

            transition: all ease-out 0.2s;
          }
        }

        .wall-bottom {
          position: absolute;
          top: 17px;

          width: 250px;
          height: 16px;
          background-color: ${colors.white};
        }

        .tooltip-text {
          visibility: hidden;
          transition: all ease-out 0.3s;

          display: flex;
          align-items: center;
          justify-content: center;

          background-color: ${transparentize(0.15, colors.black)};
          border-radius: 10px;
          padding: 7px 9px 5px;

          width: 240px;
          height: 64px;
          position: absolute;
          top: -48px;
          left: 10px;
          z-index: 999;

          opacity: 0;
        }

        .tip-icon-container {
          display: flex;
          align-items: center;
          cursor: pointer;

          :hover {
            .tooltip-text {
              visibility: visible;
              opacity: 1;
              transition: all ease-out 0.3s;
            }
          }
        }
      }

      .text-content {
        position: absolute;
        top: 45px;

        width: auto;
      }

      .yield-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .yield-icon-container {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .yield-smaller-container {
          padding: 2px 6px;
          background-color: ${colors.gray};
          margin-left: 6px;
          border-radius: 5px;
        }
      }
    }
  }

  .profits .text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    padding: 20px;
    position: relative;

    .tip-container {
      .tooltip-text {
        padding: 15px 6px;
        top: -48px;
        left: 0px;
      }
    }

    .profits-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .moviments-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .moviments-items {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        padding: 12px 0;
        border-top: 1px solid rgba(0, 0, 0, 0.04);

        .left-moviments-container {
          display: flex;
          flex-direction: row;
          align-items: center;

          svg {
            margin-right: 9px;
          }
        }
      }
    }
  }

  .empty-assets {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 49px 0;

    width: 100%;
    height: 330px;
    background-color: ${colors.white};
    border-radius: 10px;
  }

  @media (max-width: 700px) {
    .total-value {
      width: 100%;
      margin-bottom: 20px;
    }

    .profits {
      width: 100%;
    }
  }
`;

export const ScrollContainer = styled.div<GeneralStyleProps>`
  display: flex;
  flex-direction: row;
  padding: 0 30px;
  width: 100%;
  z-index: 9;

  position: absolute;
  top: ${(props) => props.marginTop || 570}px;
  left: 0px;
  background: ${colors.white};
  margin-top:20px;
  
  @media ${screen.tablet} {
    position: relative;
    top: 25px;
    margin-left: -30px;
    width: 118%;

    &.empty-container {
      width: 100%;
      margin-left: 0px;
    }
  }

  .wallets-row {
    width: inherit;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    gap: 15px;
    padding-bottom: 15px;
  }

  .next-btn {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 75px;
    height: 210px;
    right: 0px;
    top: 3px;
    z-index: 99999999999;
    background-image: linear-gradient(
      ${colors.transparent} 0%,
      ${colors.white} 50%,
      ${colors.white} 100%
    );

    opacity: 1;
    transition: all ease-out 0.2s;

    @media (max-width: 900px) {
      display: none;
    }
  }

  .prev-btn {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 75px;
    height: 210px;
    left: 0px;
    top: 3px;
    z-index: 99999999999;

    background-image: linear-gradient(
      ${colors.transparent} 0%,
      ${colors.white} 50%,
      ${colors.white} 100%
    );
    opacity: 1;
    transition: all ease-out 0.2s;

    @media (max-width: 900px) {
      display: none;
    }
  }

  .alice-carousel__dots {
    display: none;
  }

  .empty-wallets {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin: 0 30px;
    padding: 40px 0;
    background-color: ${colors.white};
    border-radius: 10px;
  }

  @media ${screen.tablet} {
    .empty-wallets {
      margin: 0;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  min-width: 210px;
  height: 210px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.02);
  background: ${colors.white};
  border-radius: 9px;
`;
