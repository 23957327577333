import {
  PixKeyRequisite,
  TedRequisiteSchema,
  TransferMethodPixEnum,
  TransferMethodTedEnum,
} from '~reactQuery/types/pix.types';
import { gql, request, useMutation } from '~reactQuery/index';

import { CAPACCOUNT_QUERY_KEYS } from '~src/constants/capaccount';
import { PIX_QUERY_TRANSFER } from '~src/constants/pix';
import { getSettingsId } from '~helpers/device';
import { useQueryClient } from 'react-query';

interface PixTransferUsingPixKeyInput {
  userOrganizationId: number;
  value: number;
  description: string;
  method: TransferMethodPixEnum;
  requisite: PixKeyRequisite;
  pin: string;
  token?: string;
}

interface PixTransferUsingPixKeyResponse {
  pixTransferUsingPixKey: {
    authCode: string;
    value: number;
    description: string;
    method: TransferMethodTedEnum;
    requisite: TedRequisiteSchema;
  };
}

export const usePixTransferUsingPixKey = () => {
  const organization = getSettingsId();
  const queryClient = useQueryClient();

  const QUERY = gql`
    mutation PixTransferUsingPixKey($params: PixKeyTransferInput!) {
      pixTransferUsingPixKey(params: $params) {
        authCode
        value
        description
        method
        requisite {
          keyType
          addressingKeyValue
          e2eId
        }
      }
    }
  `;

  const fetcher = async (params: PixTransferUsingPixKeyInput) => {
    const response = await request<PixTransferUsingPixKeyResponse>(QUERY, {
      params: {
        ...params,
        settingsId: Number(organization),
      },
    });
    return response.pixTransferUsingPixKey;
  };

  return useMutation([PIX_QUERY_TRANSFER.PIX_TRANSFER_BY_KEY], fetcher, {
    onSettled: () => {
      queryClient.invalidateQueries([
        CAPACCOUNT_QUERY_KEYS.GET_CAPACCOUNT_INFO,
      ]);
    },
  });
};
