import styled from 'styled-components';

export const Container = styled.div`
  .content {
    padding: 0 38px;
  }
`;

export const OriginWalletList = styled.div`
  width: 100%;
  padding: 0 38px;

  .origin-wallet-wrapper {
    width: 100%;
    margin-bottom: 10px;
  }
`;
