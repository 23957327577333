import styled from 'styled-components';
import { transparentize } from 'polished';
import { colors } from '~styles';

export const Container = styled.div`
  .container-loading {
    width: 100%;
    min-height: calc(100vh - 320px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty-transactions {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: calc(100vh - 360px);
    background-color: ${colors.white};
    border-radius: 10px;
  }

  .load-more {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TabMenu = styled.div`
  display: flex;
  flex-direction: row;

  padding: 0;
  margin-top: 25px;
  border-bottom: 1px solid ${transparentize(0.9, colors.black)};

  & p {
    padding: 15px 13px;
    cursor: pointer;
    color: ${colors.grayMedium};

    &.active {
      color: ${colors.black};
      border-bottom: 2px solid ${colors.black};
    }
  }
`;
