import * as React from 'react';
import { SVGProps } from 'react';

const WalletXauSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.13 17.087H8.42a.42.42 0 0 0-.388.26l-1.524 3.638a.421.421 0 0 0 .388.585h7.759a.422.422 0 0 0 .387-.585l-1.523-3.638a.422.422 0 0 0-.389-.26ZM23.576 17.087h-4.71a.422.422 0 0 0-.39.26l-1.524 3.638a.424.424 0 0 0 .224.553.433.433 0 0 0 .164.032h7.76a.42.42 0 0 0 .388-.585l-1.525-3.638a.42.42 0 0 0-.388-.26M18.35 11.092h-4.71a.423.423 0 0 0-.388.26l-1.518 3.637a.424.424 0 0 0 .388.585h7.755a.417.417 0 0 0 .388-.26.424.424 0 0 0 0-.325L18.74 11.35a.423.423 0 0 0-.39-.259ZM8.14 7.685c.144.555.193 1.13.146 1.702a5.089 5.089 0 0 1-.146 1.703 5.118 5.118 0 0 1-.146-1.703 5.114 5.114 0 0 1 .146-1.702Z"
      fill="#F0D365"
    />
    <path
      d="M8.14 9.242a5.046 5.046 0 0 1 1.698.146 5.076 5.076 0 0 1-1.698.146 5.072 5.072 0 0 1-1.697-.146 5.04 5.04 0 0 1 1.697-.146ZM22.697 23.998a5.09 5.09 0 0 1 .146 1.703 5.084 5.084 0 0 1-.146 1.702 5.081 5.081 0 0 1-.146-1.702 5.087 5.087 0 0 1 .146-1.703Z"
      fill="#F0D365"
    />
    <path
      d="M22.697 25.555a5.038 5.038 0 0 1 1.697.146 5.071 5.071 0 0 1-1.697.146A5.071 5.071 0 0 1 21 25.701a5.038 5.038 0 0 1 1.697-.146Z"
      fill="#F0D365"
    />
  </svg>
);

export default WalletXauSVG;
