import { colors } from '~styles';
import styled from 'styled-components';

export const Dialog = styled.div`
  display: flex;
  position: relative;
  width: 460px;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${colors.white};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 9999999999999;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 12px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  top: 0;
  left: 0;
`;

export const Header = styled.div`
  width: 100%;
  height: 70px;
  padding: 30px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .close-padding {
    width: 28.5px;
    height: 28.5px;
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

export const Body = styled.div`
  width: 100%;
  padding: 30px 20px;
  min-height: 50px;
  display: flex;
  justify-content: flex-start;
`;

export const Bottom = styled.div`
  width: 100%;
  padding: 20px 20px;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
`;
