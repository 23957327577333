import axios from 'axios';
import * as yup from 'yup';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';

export function validateCPF(cpf: string) {
  let Soma = 0;
  let Resto;

  const strCPF = String(cpf).replace(/[^\d]/g, '');

  if (strCPF.length !== 11) return false;

  if (
    [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
    ].indexOf(strCPF) !== -1
  )
    return false;
  let i = 0;
  for (i = 1; i <= 9; i += 1)
    Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);

  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;

  if (Resto !== parseInt(strCPF.substring(9, 10), 10)) return false;

  Soma = 0;

  for (i = 1; i <= 10; i += 1)
    Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);

  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;

  if (Resto !== parseInt(strCPF.substring(10, 11), 10)) return false;

  return true;
}

export const nameAndCpfMatch = async (cpf: number, name: string) => {
  const resp = await axios.get(
    `https://settle.capitual.net/api/v1.0/namecheck/${cpf}?name=${name}`
  );

  return resp.data;
};

export function validaCNPJ(cnpj: string) {
  const cnpjRegex = /^[0-9]{14}$/;

  if (!cnpjRegex.test(onlyNumbers(cnpj))) {
    return false;
  }

  return true;
}

export function validateEmail(email: string) {
  return yup.string().email().isValidSync(email);
}

export function validatePixRandomKey(pixKey: string) {
  const pixKeyRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

  if (!pixKeyRegex.test(pixKey)) {
    return false;
  }

  return true;
}

export function validatePhoneNumber(value: string): boolean {
  const phoneNumber = value.replace(/\D/g, '');

  const defaultNumber = /^[1-9]\d{10}$/;
  return defaultNumber.test(phoneNumber);
}

export function validateEmaiDomainLength(email: string): boolean {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex.test(email);
}
