import * as React from 'react';

import { SVGProps } from 'react';

const ExitSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 50}
    height={props.height || 50}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={50} height={50} rx={25} fill={props.fill || 'transparent'} />
    <path
      d="M28.172 17h-7.884A3.3 3.3 0 0 0 17 20.289v9.907a3.287 3.287 0 0 0 3.288 3.287h7.884M29.668 21.711l3.582 3.582-3.48 3.48M33.25 25.293H21.908"
      stroke={props.stroke || '#272937'}
      strokeWidth={1.515}
      strokeLinecap="round"
    />
  </svg>
);

export default ExitSvg;
