import * as React from 'react';

import { SVGProps } from 'react';

const ApprovedSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_14687_112136)">
      <rect
        opacity="0.1"
        width={props.width || 48}
        height={props.height || 48}
        fill="#0F90FF"
      />
      <path
        d="M15 23.7231L22 30L34 18"
        stroke="#026FF4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14687_112136">
        <rect width="48" height="48" rx="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ApprovedSVG;
