import * as Style from './GenericButton.styles';

import { Else, If, Then, When } from 'react-if';

import { GenericButtonProps } from './GenericButton.types';
import { LoadingSpinner } from '~components';
import { colors } from '~styles';

const GenericButton = (props: GenericButtonProps) => {
  const {
    buttonType,
    buttonLoading,
    onClick,
    children,
    leftIcon,
    disabled,
    width,
    noPadding,
    hasLeftIcon
  } = props;

  return (
    <Style.Container
      className={buttonType || 'primary'}
      disabled={disabled || buttonLoading}
      style={{ width }}
      {...props}
      onClick={onClick}
      noPadding={noPadding}
    >
      <If condition={buttonLoading}>
        <Then>
          <LoadingSpinner
            color={
              buttonType === 'inline' || buttonType === 'default'
                ? colors.black
                : ''
            }
          />
        </Then>
        <Else>
          <div className="move-top">
            <When condition={!!leftIcon && buttonType === 'default' || hasLeftIcon}>
              <div className="icon-wrapper">{leftIcon}</div>
            </When>
            {children}
          </div>
        </Else>
      </If>
    </Style.Container>
  );
};

export default GenericButton;
