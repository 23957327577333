import { SVGProps } from 'react';

const MailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7.02C3 5.91543 3.89543 5.02 5 5.02H19C20.1046 5.02 21 5.91543 21 7.02V17.02C21 18.1246 20.1046 19.02 19 19.02H5C3.89543 19.02 3 18.1246 3 17.02V7.02ZM5.03786 7.02L12 11.8065L18.9621 7.02H5.03786ZM19 9.42103L12.5665 13.844L12 14.2335L11.4335 13.844L5 9.42103V17.02H19V9.42103Z"
      fill="#757893"
    />
  </svg>
);

export default MailIcon;
