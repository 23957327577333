import * as Style from '../RecoveryCodes.styles';
import * as Text from '~styles/text';
import React from 'react';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ShieldSecuritySVG from '~assets/svg/shieldSecurity';

const ExplainCodes: React.FC = ({ setStepsAndParams }) => {
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  return (
    <>
      <div className="recovery-codes-body">
        <ShieldSecuritySVG />
        <Text.Heading2 marginTop={25}>
          Gerar novos códigos de recuperação
        </Text.Heading2>
        <Text.Heading5 marginTop={20}>
          Os códigos de recuperação pré-definidos são importantes para você
          usá-los quando não tiver acesso ao seu dispositivo mobile.
          <br />
          <br />
          Ao clicar em `&#34;`próximo`&#34;` e gerar novos códigos de
          recuperação, você concorda que os códigos antigos serão invalidados.
          Guarde os novos códigos em um lugar seguro.
        </Text.Heading5>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        doubleButtonTextOne="Cancelar"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => setStepsAndParams('askForPin')}
        doubleButtonOneAction={() => setOpenModalMultiStep(false)}
      />
    </>
  );
};

export default ExplainCodes;
