import colors from '~styles/colors';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 20px 80px 20px;

  .chips-list {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 25px 0px;
  }

  .container-accordion-column {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .content-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 7px;
    }

    .item {
      width: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    .container-picker {
      display: flex;
      flex-direction: column;

      .react-datepicker-wrapper {
      }

      .react-datepicker__input-container {
      }

      .react-datepicker__input-container input {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23bbbbbb" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');

        background-position: right 6.4% bottom 50%;

        background-repeat: no-repeat;

        ::placeholder {
          font-family: 'Helvetica Now Display';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 135%;

          letter-spacing: 0.025em;

          color: ${({ placeHolderColor }) => placeHolderColor || '#9ea1b9'};
        }

        :-ms-input-placeholder {
          font-family: 'Helvetica Now Display';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 135%;

          letter-spacing: 0.025em;

          color: #9ea1b9;
        }

        ::-ms-input-placeholder {
          font-family: 'Helvetica Now Display';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 135%;

          letter-spacing: 0.025em;

          color: #9ea1b9;
        }

        width: 100%;
        padding: 14px 20px;
        border: 1px solid #9ea1b9;
        border-radius: 8px;

        flex-grow: 0;
      }
    }
  }

  .margin-top-20 {
    margin-top: 25px;
  }

  .container-wallet-list {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .tooLongPeriodContent {
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    gap: 20px;
  }
`;

export const FixedBottom = styled.div<{ isMobile?: boolean }>`
  display: flex;
  height: 80px;
  position: fixed;
  justify-content: center;
  align-items: center;
  bottom: 0;
  padding: 20px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '418px')};
  background-color: ${colors.white};
`;
