import * as Style from './CardActivities.styles';
import * as Text from '~styles/text';
import * as TextSecondary from '~styles/textSecondary';
import { CardActivitiesProps } from './CardActivities.types';
import { When } from 'react-if';

const CardActivities = (props: CardActivitiesProps) => {
  const { style, icon, title, value, description } = props;

  return (
    <Style.Container style={style}>
      <Style.CardHeader>
        <Text.Small className="title">{title}</Text.Small>
        {icon}
      </Style.CardHeader>
      <Style.CardBody>
        <TextSecondary.Heading5Medium className="value">
          {value}
        </TextSecondary.Heading5Medium>

        <When condition={description}>
          <div className="container-description">
            <Text.Smaller className="description">{description}</Text.Smaller>
          </div>
        </When>
      </Style.CardBody>
    </Style.Container>
  );
};

export default CardActivities;
