import * as React from 'react';
import { SVGProps } from 'react';

const WalletEurSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.852 21.221a4.791 4.791 0 0 1-2.008.413 4.74 4.74 0 0 1-2.346-.588 4.161 4.161 0 0 1-1.632-1.624 4.36 4.36 0 0 1-.374-.905h5.702l.728-1.23h-6.626c0-.077-.005-.152-.005-.231 0-.236.016-.471.048-.704h5.856l.73-1.232h-6.23c.221-.477.526-.91.899-1.28a4.364 4.364 0 0 1 3.205-1.303 5.453 5.453 0 0 1 4.055 1.91l1.77-1.688a7.653 7.653 0 0 0-5.786-2.675 7.227 7.227 0 0 0-3.604.947 6.681 6.681 0 0 0-2.572 2.489 6.445 6.445 0 0 0-.658 1.607H7.93l-.73 1.23h1.597c-.02.23-.033.462-.033.7v.235h-.839l-.73 1.232h1.687a6.52 6.52 0 0 0 1.848 3.58 6.88 6.88 0 0 0 5.067 1.967 7.56 7.56 0 0 0 2.986-.556 9.371 9.371 0 0 0 2.779-1.996l-1.713-1.793a7.233 7.233 0 0 1-1.997 1.495Z"
      fill="#3B5BA0"
    />
  </svg>
);

export default WalletEurSVG;
