import {
  GetContactsData,
  GetContactsFilters,
} from '~reactQuery/types/contacts.types';
import { gql, request, useInfiniteQuery, useQuery } from '~reactQuery/index';

import { CONTACTS_QUERY_KEYS } from '~src/constants/contacts';

interface GetContactsInput {
  page?: number;
  perPage?: number;
  filter?: GetContactsFilters;
  userOrganizationId: number;
  fav?: boolean;
}

interface GetContactsResponse {
  getContacts: {
    data: GetContactsData;
    messages: [string];
    isValid: boolean;
  };
}

export const useGetContacts = (params: GetContactsInput) => {
  const QUERY = gql`
    query GetContacts($params: GetContactsInput!) {
      getContacts(params: $params) {
        data {
          contacts {
            id
            userOrganizationId
            name
            documentNumber
            fav
            contactData {
              id
              type
              accountType
              pixType
              pixValue
              bankCode
              bankIspb
              branch
              accountNumber
              isFromCapBiz
              capBizContact
              contactsId
            }
            deletedAt
            createdAt
            updatedAt
          }
          totalPages
          total
        }
        messages
        isValid
      }
    }
  `;

  const fetcher = async () => {
    const response = await request<GetContactsResponse>(QUERY, { params });

    return response.getContacts.data;
  };

  return useInfiniteQuery(
    [CONTACTS_QUERY_KEYS.GET_CONTACTS, params.page, params.perPage],
    fetcher,
    {
      refetchInterval: 60000,
      getNextPageParam: (lastPage, pages: any) => {
        if (pages?.[0]?.pages === pages.length) {
          return undefined;
        }
        return pages.length + 1;
      },
    }
  );
};
