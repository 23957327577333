import { useState } from 'react';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import * as Text from '~styles/text';
import { getWalletIcon } from '~helpers/format/currency';
import { CriptoWithdrawStepsProps } from '~src/common/modals/multiStep/Transfer/Transfer.types';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';
import { getSelectedUserOrganization } from '~helpers/device';
import { WalletType } from '~reactQuery/types/wallets.types';
import { useFormContext } from 'react-hook-form';
import { CheckSelect, InputForm } from '~components/index';

const OriginWallet = ({ setCriptoWithdrawStep }: CriptoWithdrawStepsProps) => {
  const goBack = () => setCriptoWithdrawStep('chooseTransferMethod');
  const [search, setSearch] = useState('');
  const [selectedWallet, setSelectedWallet] = useState<WalletType>();

  const { setValue } = useFormContext();

  const userOrgId = getSelectedUserOrganization();

  const { data: wallets = [] } = useGetWallets({
    flag: 'withdraw',
    userOrganizationId: Number(userOrgId),
    prefCurrency: 'BRL',
  });

  const criptoWallets = wallets.filter(
    (w) => w.currency.type === 'crypto' && w.funds !== 0
  );

  const filteredWallets = criptoWallets.filter((w) => {
    const query = search.toLowerCase();
    const walletIsoCode = w.currency.isoCode.toLowerCase();
    const walletName = w.currency.name.toLowerCase();

    if (walletIsoCode.includes(query) || walletName.includes(query))
      return true;

    return false;
  });

  const handleSelectWallet = (w: WalletType) => {
    setSelectedWallet(w);
  };

  const handleGoToNextStep = () => {
    setValue('originWallet', selectedWallet);
    setCriptoWithdrawStep('amountToWithdraw');
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Escolher a carteira de origem"
        backButtonAction={goBack}
      />
      <div className="cripto-withdraw-container-step">
        <Text.Paragraph marginBottom={20}>
          De que carteria deseja realizar seu saque?
        </Text.Paragraph>
        <InputForm
          value={search}
          placeholder="Pesquisar carteira"
          onChange={(e) => setSearch(e.target.value)}
        />

        <Text.Heading3 marginTop={20} marginBottom={20}>
          Carteiras disponíveis
        </Text.Heading3>

        {filteredWallets.map((w) => (
          <CheckSelect
            key={w.walletAddress}
            title={w.walletName}
            iconComponent={getWalletIcon(w.currency.isoCode)}
            onClick={() => handleSelectWallet(w)}
            iconType="component"
            wallet={w}
            walletList
            text={w.currency.isoCode}
            selected={w.walletAddress === selectedWallet?.walletAddress}
          />
        ))}

        <ModalMultiStepBottom
          singleButtonText="Próximo"
          singleButtonWidth="100%"
          singleButtonAlignment="flex-end"
          disableSingleButton={!selectedWallet}
          singleButtonAction={handleGoToNextStep}
        />
      </div>
    </>
  );
};

export default OriginWallet;
