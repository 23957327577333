import styled from 'styled-components';

export const SendContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .content-container {
    padding: 0 35px;
  }
`;
