import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { cnpjMask, cpfMask } from './masks';

export const suprimeCpfOrCnpj = (docNum: string) => {
  if (!docNum) return '';

  if (docNum.length <= 6) {
    return `***.${docNum[0]}${docNum[1]}${docNum[2]}.${docNum[3]}${docNum[4]}${docNum[5]}-**`;
  }

  if (docNum?.length >= 14) {
    return `****${docNum[5]}${docNum[6]}${docNum[7]}/${docNum[8]}${docNum[9]}${docNum[10]}*****`;
  }
  return `***.${docNum[3]}${docNum[4]}${docNum[5]}.${docNum[6]}${docNum[7]}${docNum[8]}-**`;
};

export const formatCpfOrCnpj = (docNum: string) => {
  if (!docNum) return '';

  if (onlyNumbers(docNum).length >= 14) {
    return cnpjMask(docNum);
  }
  return cpfMask(docNum);
};

export function formatDocumentComingFromAcesso(input: string): string {
  const cleanInput = input.replace(/[*\s]/g, '');

  if (cleanInput?.length === 6) {
    return `***${cleanInput.replace(/(\d{3})(\d{3})/g, '$1.$2')}**`;
  }
  if (cleanInput?.length > 6) {
    return `***${cleanInput.replace(/^(\d{2})(\d{3})(\d{4})$/, '$1.$2/$3')}**`;
  }
  return input;
}
