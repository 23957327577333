export const capitalize = (string: string) => {
  const str = string.split(' ');

  const parsedString = str
    .map((name) => {
      if (name === '' || name === ' ') return '';
      if (name.length === 1) return name.toLowerCase();
      if (name.toUpperCase() === 'LTDA') return name.toUpperCase();

      return name[0].toUpperCase() + name.slice(1).toLowerCase();
    })
    .join(' ');

  return parsedString || string;
};


export const capitalizeInitialsAndRemoveNumbers = (text: string) => {
  const words = text?.split(' ');
  let capitalizedText = '';

  for (let i = 0; i < words.length; i += 1) {
    const word = words[i];
    if (!/\d/.test(word)) {
      if (word.length > 0) {
        const initial = word.charAt(0).toUpperCase();
        const remaining = word.slice(1).toLowerCase();
        const capitalizedWord = initial + remaining;
        capitalizedText += `${capitalizedWord} `;
      }
    }
  }

  return capitalizedText.trim();
}
