import * as Style from '../RecoveryCodes.styles';
import * as Text from '~styles/text';

import CopySVG from '~assets/svg/copy';
import DownArrow from '~assets/svg/downArrow';
import { InfoToast } from '~components/Toast';
import PrintSVG from '~assets/svg/print';
import { QuickButton } from '~components/Buttons';
import React from 'react';
import { getSelectedUserOrganization } from '~helpers/device';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';

const GenerateCodes: React.FC = ({ stepParams, setStepsAndParams }) => {
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  const orgId = getSelectedUserOrganization();
  const { data: accountInfos } = useGetCapAccountInfo({
    userOrganizationId: Number(orgId),
  });

  const codes = stepParams?.rescueCodes || [];

  const textToWrite = document.getElementById('codes')?.innerText;

  const downloadCodes = () => {
    const element = document.createElement('a');

    const file = new Blob([String(textToWrite)], {
      type: 'text/plain;charset=utf-8',
    });

    element.href = URL.createObjectURL(file);
    element.download = `backup_codes_${accountInfos?.account || ''}.txt`;
    document.body.appendChild(element);
    element.click();

    InfoToast({
      title: 'Download de códigos de segurança',
      subtitle:
        'Guarde-os em um local seguro para usá-los somente quando necessário.',
      autoCloseEnable: true,
    });
  };

  const printCodes = () => {
    window.print();

    InfoToast({
      title: 'Impressão de códigos solicitada',
      subtitle:
        'Guarde-os em um local seguro para usá-los somente quando necessário.',
      autoCloseEnable: true,
    });
  };
  const copyCodes = () => {
    navigator.clipboard.writeText(String(textToWrite));

    InfoToast({
      title: 'Códigos de segurança copiados',
      subtitle:
        'Guarde-os em um local seguro para usá-los somente quando necessário.',
      autoCloseEnable: true,
    });
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Códigos de recuperação"
        description="Aqui estão os códigos que você pode usar para fazer login, se perder ou não conseguir acessar seu dispositivo móvel."
        backButtonAction={() => setStepsAndParams('askForPin')}
      />

      <div className="recovery-codes-body">
        <div id="codes" className="justify-items-center">
          <ul>
            {codes.map((code: string) => (
              <li key={code}>
                <Text.Heading3>
                  {`${code.slice(0, 3)} ${code.slice(3, 6)}`}
                </Text.Heading3>
              </li>
            ))}
          </ul>
        </div>

        <Text.Heading5 marginTop={20}>
          É importante proteger seus códigos de recuperação, mantendo-os em um
          local seguro e de fácil acesso. Se você perder seu dispositivo e não
          tiver seus códigos de recuperação, não será possível acessar sua conta
          novamente.
        </Text.Heading5>

        <div className="actions-buttons-container hidden-print">
          <div onClick={copyCodes}>
            <QuickButton icon={<CopySVG />}>
              <Text.Heading5>Copiar</Text.Heading5>
            </QuickButton>
          </div>

          <div onClick={downloadCodes}>
            <QuickButton icon={<DownArrow />}>
              <Text.Heading5>Baixar</Text.Heading5>
            </QuickButton>
          </div>
          <div onClick={printCodes}>
            <QuickButton icon={<PrintSVG />}>
              <Text.Heading5>Imprimir</Text.Heading5>
            </QuickButton>
          </div>
        </div>
      </div>

      <ModalMultiStepBottom
        singleButtonWidth="80%"
        singleButtonText="Já guardei meus códigos"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => setOpenModalMultiStep(false)}
      />
    </>
  );
};

export default GenerateCodes;
