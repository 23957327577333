import { useQueryClient } from 'react-query';
import { gql, request, useQuery } from '~reactQuery';

export interface ScheduledStatementInput {
  params: {
    page: number;
    perPage: number;
    from: string;
    to: string;
    userOrganizationId: number;
    documentNumber: string;
    restrict: boolean;
  };
  enabled: boolean;
  callback: () => void;
}

export interface ScheduledStatementResponse {
  scheduledStatement: {
    data: boolean;
  };
}

export const useScheduledStatement = (params: ScheduledStatementInput) => {
  const queryClient = useQueryClient();
  const QUERY = gql`
    query ScheduledStatement($params: StatementInput!) {
      scheduledStatement(params: $params) {
        data
      }
    }
  `;

  const fetcher = async () => {
    const response = await request<ScheduledStatementResponse>(QUERY, {
      params: params.params,
    });
    return response.scheduledStatement.data;
  };

  return useQuery(['scheduledStatement'], fetcher, {
    enabled: params.enabled,
    onSuccess: () => {
      queryClient.invalidateQueries(['getNotifications']);
    },
    onSettled: () => {
      params.callback();
    },
  });
};
