import { colors } from '~styles';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;

  table {
    border-collapse: collapse;
  }

  thead tr {
    position: sticky !important;
    top: 0;
    background-color: ${colors.white};
  }

  tr {
    z-index: 1;
  }

  tbody {
    border-top: 1px solid ${transparentize(0.92, colors.grayMedium)};
  }

  th {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  td {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }

  td:first-child {
    padding-left: 10px;
    padding-right: 0;
  }

  td:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    margin-right: 15px;
  }

  .tableFixHead {
    max-height: 350px;
    overflow: auto;
    padding-bottom: 20px;
    border-top: 1px solid ${transparentize(0.92, colors.grayMedium)};

    .loading-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .txIcon {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .display-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .scale-icon {
    transform: scale(0.8);
  }

  .gap-20 {
    gap: 20px;
  }

  .gap-10 {
    gap: 10px;
  }

  .text-green {
    color: ${colors.success};
  }

  .text-red {
    color: ${colors.danger};
  }

  .padding-left-20 {
    padding-left: 20px;
  }

  .align-center {
    justify-content: center;
    display: grid;
  }

  .hover {
    cursor: pointer;
  }

  .margin-16 {
    gap: 16px;
    display: flex;
    padding-left: 5px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .w-full {
    width: 100%;
  }

  .border-collapse {
    border-collapse: collapse;
  }

  .rounded-div {
    width: 100%;
  }

  .end-td {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  .loading-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .row-item {
  }


  .column {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: 6px;
  }

  .flat {
    width: 36px;
    height: 36px;
    background-color: ${colors.mainGray} !important;
  }

  .icon-dropdown {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;
