import { GeneralStyleProps } from 'src/@types/general';
import { colors } from '~styles';
import styled from 'styled-components';

export const ContainerInput = styled.div``;

export const Input = styled.textarea<GeneralStyleProps>`
  width: 100%;
  height: 80px;
  font-family: 'Helvetica Now Display Medium';
  font-weight: 500;
  font-size: 31px;
  border: 2px solid ${colors.grayMedium};
  border-radius: 8px;
  padding: 16px;
  background-color: ${colors.white};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  padding-bottom: ${(props) => props.paddingBottom || 0}px;
  resize: none;
  overflow: hidden;

  :focus {
    border: 2px solid ${colors.black};

    padding-bottom: ${(props) => props.paddingBottom || 0}px;
  }

  ::placeholder {
    color: ${colors.grayRegular};
    opacity: 1;
  }
`;
