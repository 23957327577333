import * as Text from '~styles/text';
import { Else, If, Then } from 'react-if';
import { formatCurrencyFn, getWalletIcon } from '~helpers/format/currency';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import BankAccountIcon from '~assets/images/icons/24x24/capconta.svg';
import { BillReceipt } from '~assets/pdfs/bill/BillReceipt';
import BillSVG from '~assets/svg/statement/bill';
import { ConvertBankingReceipt } from '~assets/pdfs/convertBanking/ConvertBankingReceipt';
import ConvertSVG from '~assets/svg/statement/convert';
import { InvoiceReceipt } from '~assets/pdfs/invoice/InvoiceReceipt';
import MoneyReceivedSVG from '~assets/svg/statement/moneyReceived';
import PixTedReceivedSVG from '~assets/svg/statement/pix-ted-received';
import PixTedSentSVG from '~assets/svg/statement/pix-ted-sent';
import { TransferReceipt } from '~assets/pdfs/transfer/TransferReceipt';
import UserPic from '~components/UserPic';
import { WalletItemProps } from './StatementExtract.types';
import { capitalizeName } from '~helpers/format/user';
import colors from '~styles/colors';
import dayjs from 'dayjs';
import { formatCpfOrCnpj } from '~helpers/format/numbers';
import { getSelectedUserOrganization } from '~helpers/device';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetStatementSpecific } from '~reactQuery/queries/bank/useGetStatementSpecific';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';
import { RefundReceipt } from '~assets/pdfs/refund/RefundReceipt';
import { receiptTitleParser } from '~helpers/transaction/transactionUtils';

function StatementExtractItem({ tx, setCsv, hideCpfCnpj }: WalletItemProps) {
  const userOrganizationId = getSelectedUserOrganization();

  const [authCode, setAuthCode] = useState('');

  const { data: wallets, isLoading: loadingWallets } = useGetWallets({
    prefCurrency: 'BRL',
    flag: 'list',
    userOrganizationId: Number(userOrganizationId),
  });

  const getWalletFiltered = (walletAddress: string) => {
    if (walletAddress && wallets) {
      return wallets.filter((w) => w.walletAddress === walletAddress)?.[0];
    }
    return null;
  };

  const { data: transferSpecificDetails, isLoading: loadingDetails } =
    useGetStatementSpecific({
      authCode,
      userOrganizationId: Number(userOrganizationId),
    });

  const { data: accountDetails, isLoading: loadingGetAccount } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  const parsedTX = useMemo(() => {
    if (tx) {
      let statementListParsed = {} as any;
      let show = true;

      // eslint-disable-next-line default-case

      let isCapContaToWallet = false;
      let isCapWalletToCapConta = false;

      switch (tx.method) {
        case 'Transfer':
          isCapContaToWallet =
            tx?.data?.requisite?.type === 'EXCHANGE_CAPCONTA_TO_CAPWALLET';
          isCapWalletToCapConta =
            tx?.data?.requisite?.type === 'EXCHANGE_CAPWALLET_TO_CAPCONTA';

          if (isCapContaToWallet) {
            const title = receiptTitleParser(tx.status, 'CAP-TO-WALLET', 1);
            statementListParsed = {
              title,
              icon: <ConvertSVG fill={colors.grayMedium} />,
              from: {
                name: 'Cap Conta',
              },
              to: {
                walletIsoCode: getWalletFiltered(
                  tx.data.requisite.walletAddress
                )?.currency?.isoCode,
                name: getWalletFiltered(tx.data.requisite.walletAddress)
                  ?.walletName,
              },
            };
          } else if (isCapWalletToCapConta) {
            const title = receiptTitleParser(tx.status, 'WALLET-TO-CAP', 1);
            statementListParsed = {
              title,
              icon: <ConvertSVG fill={colors.grayMedium} />,
              from: {
                walletIsoCode: getWalletFiltered(
                  tx.data.requisite.walletAddress
                )?.currency?.isoCode,
                name: getWalletFiltered(tx.data.requisite.walletAddress)
                  ?.walletName,
              },
              to: {
                name: 'Cap Conta',
              },
            };
          } else {
            const title = receiptTitleParser(
              tx.status,
              String(tx.data.method).toUpperCase() === 'PIX'
                ? 'TRANSFER-PIX'
                : 'TRANSFER-TED',
              tx.direction
            );

            statementListParsed = {
              title,
              icon:
                tx.direction < 1 ? (
                  <PixTedSentSVG />
                ) : (
                  <PixTedReceivedSVG stroke={colors.success} />
                ),
              from: {
                name: 'Cap Conta',
                docNum: '',
              },
              to: {
                name:
                  tx.direction > 1
                    ? 'Cap Conta'
                    : tx.data?.requisite?.key?.holderName ||
                      tx.data?.requisite?.holderName,
                docNum:
                  tx.direction > 1
                    ? ''
                    : formatCpfOrCnpj(
                        tx.data?.requisite?.key?.holderDocument ||
                          tx.data?.requisite?.holderDocument
                      ),
              },
            };
          }

          break;
        case 'Bill': {
          const title = receiptTitleParser(tx.status, 'BILL', -1);
          statementListParsed = {
            title,
            icon: <BillSVG />,

            to: {
              name: tx?.data?.assignor,
              docNum: tx?.data?.recipientName,
            },
          };
          break;
        }

        case 'BillSlip': {
          const title = receiptTitleParser(tx.status, 'BILL-SLIP', -1);

          if (tx.direction === 0) {
            show = false;
          }
          statementListParsed = {
            title,
            icon: <BillSVG />,
            from: {
              name: 'Cap Conta',
              docNum: '',
            },
            to: {
              name: tx?.data?.assignor,
              docNum: tx?.data?.recipientName,
            },
          };
          break;
        }
        case 'Refund': {
          const title = receiptTitleParser(tx.status, 'REFUND', -1);

          statementListParsed = {
            title,
            icon: <MoneyReceivedSVG fill={colors.success} />,
            to: {
              name: 'Cap Conta',
            },
          };
          break;
        }

        default:
          if (tx?.data?.method === 'Invoice') {
            statementListParsed = {
              title: 'Pagamento de Invoice',
              icon: <BillSVG />,
              from: {
                name: 'Cap Conta',
                docNum: '',
              },
            };
          }

          break;
      }

      return { ...statementListParsed, show };
    }
    return null;
  }, [tx, wallets]);

  const loading = !tx || loadingWallets;

  const downloadSingle = () => {
    setAuthCode(tx?.authCode || '');
  };

  const generatePdfDocument = async () => {
    let blob = null;
    let fileName = '';
    let fileDate = '';

    if (!transferSpecificDetails) return;

    switch (transferSpecificDetails?.method) {
      case 'Bill':
        blob = await pdf(
          <BillReceipt
            accountDetails={accountDetails}
            billDetails={transferSpecificDetails}
            authCode={tx?.authCode}
          />
        ).toBlob();
        fileDate = dayjs(transferSpecificDetails?.timestamp).format(
          'DD/MM/YYYY h:mm A'
        );
        fileName = 'Boleto';
        break;

      case 'Refund':
        blob = await pdf(
          <RefundReceipt
            accountDetails={accountDetails}
            refundDetails={transferSpecificDetails}
            authCode={tx?.authCode}
          />
        ).toBlob();
        fileName = 'Reembolso';
        break;
      case 'Transfer':
        if (parsedTX.title === 'Conversão CapWallet Para CapConta') {
          blob = await pdf(
            <ConvertBankingReceipt
              accountDetails={accountDetails}
              convertDetails={{
                timestamp: tx?.timestamp,
                authCode: tx?.authCode,
                amount: tx?.data.value,
                originWallet: 'Cap Wallet',
                destinationWallet: 'Cap Conta',
                status: tx?.status,
              }}
            />
          ).toBlob();
          fileDate = dayjs(transferSpecificDetails?.timestamp).format(
            'DD/MM/YYYY h:mm A'
          );
          fileName = 'Conversão';
        } else if (parsedTX.title === 'Conversão CapConta Para CapWallet') {
          blob = await pdf(
            <ConvertBankingReceipt
              accountDetails={accountDetails}
              convertDetails={{
                status: tx?.status,
                timestamp: tx?.timestamp,
                authCode: tx?.authCode,
                amount: tx?.data.value,
                originWallet: 'Cap Conta',
                destinationWallet: `${
                  getWalletFiltered(tx?.data?.requisite?.walletAddress)
                    ?.walletName
                } - ${tx?.data?.requisite?.walletAddress}`,
              }}
            />
          ).toBlob();
          fileDate = dayjs(transferSpecificDetails?.timestamp).format(
            'DD/MM/YYYY h:mm A'
          );
          fileName = 'Conversão';
        } else {
          blob = await pdf(
            <TransferReceipt
              accountDetails={accountDetails}
              transferDetails={transferSpecificDetails}
              authCode={tx?.authCode}
              status={tx?.status}
            />
          ).toBlob();
          fileName = 'Transferência';
          fileDate = dayjs(transferSpecificDetails?.timestamp).format(
            'DD/MM/YYYY h:mm A'
          );
        }

        break;
      default:
        if (transferSpecificDetails?.data?.method === 'Invoice') {
          blob = await pdf(
            <InvoiceReceipt
              accountDetails={accountDetails}
              invoiceDetails={transferSpecificDetails}
              authCode={tx?.authCode}
              status={tx?.status}
            />
          ).toBlob();
        }

        fileDate = dayjs(transferSpecificDetails?.timestamp).format(
          'DD/MM/YYYY h:mm A'
        );
        fileName = 'Invoice';

        break;
    }

    if (!blob) return;

    saveAs(blob, `${fileName}-${fileDate}.pdf`);
    setAuthCode('');
  };

  useEffect(() => {
    if (transferSpecificDetails?.authCode === authCode && !loadingGetAccount) {
      generatePdfDocument();
    }
  }, [transferSpecificDetails, loadingGetAccount]);

  useEffect(() => {
    if (parsedTX?.show) {
      setCsv((prev: any) => {
        return [
          ...prev,
          [
            parsedTX?.title,
            tx?.authCode,
            parsedTX?.title,
            parsedTX?.from?.name || '-',
            `${capitalizeName(String(parsedTX?.to?.name).toLowerCase())}${
              parsedTX?.to?.docNum ? ` - ${parsedTX?.to?.docNum}` : ''
            }`,
            tx?.data?.description || '-',
            `${Number(tx?.direction) < 1 ? '- ' : '+ '}${formatCurrencyFn(
              'BRL',
              String(Number(Number(tx?.data?.value) / 100))
            )}`,
          ],
        ];
      });
    }
  }, []);

  const hideInformation = (content: ReactNode) => {
    if (!hideCpfCnpj) return content;

    return <div className="hide-doc" />;
  };

  return (
    <If condition={loading}>
      <Then>{null}</Then>
      <Else>
        {parsedTX?.show && (
          <tr className="">
            <td>
              <div className="flex items-center gap-10">
                <div className="txIcon">{parsedTX?.icon}</div>
                <div>
                  <Text.Heading5>{parsedTX?.title}</Text.Heading5>
                </div>
              </div>
            </td>
            <td>
              <Text.Heading5 data-html2canvas-ignore="true">
                {tx.timestamp
                  ? dayjs(tx.timestamp).format('DD/MM/YYYY')
                  : '--/--/--'}
              </Text.Heading5>

              <Text.Heading5
                data-html2canvas-ignore="true"
                color={colors.grayMedium}
              >
                {tx.timestamp ? dayjs(tx.timestamp).format('h:mm A') : ''}
              </Text.Heading5>
            </td>
            <td>
              <div className="flex row gap-10">
                <If condition={parsedTX?.from?.name === 'Cap Conta'}>
                  <Then>
                    <BankAccountIcon />
                  </Then>

                  <Else>
                    <If condition={parsedTX?.from?.walletIsoCode}>
                      <Then>
                        {getWalletIcon(parsedTX?.from?.walletIsoCode)}
                      </Then>

                      <Else>
                        <If condition={parsedTX?.from?.name}>
                          <Then>
                            <UserPic name={parsedTX?.from?.name} />
                          </Then>

                          <Else>
                            {parsedTX?.from?.name ? (
                              <UserPic name="W" />
                            ) : (
                              <p> -------------------- </p>
                            )}
                          </Else>
                        </If>
                      </Else>
                    </If>
                  </Else>
                </If>

                <div
                  style={{
                    maxWidth: 250,
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                  }}
                >
                  <Text.Heading5>
                    {parsedTX?.from?.name ? parsedTX?.from?.name : ''}
                  </Text.Heading5>
                </div>
              </div>
            </td>
            <td>
              <div className="flex row gap-10">
                <If condition={parsedTX?.to?.name === 'Cap Conta'}>
                  <Then>
                    <div className="cap-account-svg">
                      <BankAccountIcon />
                    </div>
                  </Then>

                  <Else>
                    <If condition={parsedTX?.to?.walletIsoCode}>
                      <Then>{getWalletIcon(parsedTX?.to?.walletIsoCode)}</Then>

                      <Else>
                        <If condition={parsedTX?.to?.name}>
                          <Then>
                            <UserPic name={parsedTX?.to?.name} />
                          </Then>

                          <Else>
                            {parsedTX?.to?.name ? (
                              <UserPic name="W" />
                            ) : (
                              <p> -------------------- </p>
                            )}
                          </Else>
                        </If>
                      </Else>
                    </If>
                  </Else>
                </If>

                <div
                  style={{
                    maxWidth: 250,
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                  }}
                >
                  <Text.Heading5>
                    {parsedTX?.to?.name
                      ? capitalizeName(String(parsedTX?.to?.name).toLowerCase())
                      : ''}
                  </Text.Heading5>
                  <If condition={parsedTX?.to?.walletIsoCode}>
                    <Then>{null}</Then>
                    <Else>
                      <If
                        condition={
                          hideCpfCnpj &&
                          parsedTX?.to?.name &&
                          parsedTX?.to?.name !== 'Cap Conta'
                        }
                      >
                        <Then>
                          <div className="hide-doc" />
                        </Then>
                        <Else>
                          <Text.Heading5 color={colors.grayMedium}>
                            {parsedTX?.to?.docNum}
                          </Text.Heading5>
                        </Else>
                      </If>
                    </Else>
                  </If>
                </div>
              </div>
            </td>

            <td>
              <div className="statement-description">
                <Text.Heading5 className="text-description">
                  {tx?.data?.description || '-'}
                </Text.Heading5>
              </div>
            </td>

            <td className="mobile-hide">
              <div>
                <Text.Small>
                  {tx.direction < 1 ? '- ' : '+ '}
                  {formatCurrencyFn('BRL', String(Number(tx.data.value / 100)))}
                </Text.Small>
                {/* <Text.Heading5 color={colors.grayMedium}>
                   {formatCurrencyFn(prefCurrency, valueInPrefCurrency)}
                 </Text.Heading5> */}
              </div>
            </td>
          </tr>
        )}
      </Else>
    </If>
  );
}

export default StatementExtractItem;
