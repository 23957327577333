import * as React from 'react';

import { SVGProps } from 'react';

const HtCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="M31.855 16.015a9.988 9.988 0 0 0-.586-1.883 9.976 9.976 0 0 0-2.15-3.19 9.98 9.98 0 0 0-3.189-2.15 9.986 9.986 0 0 0-7.798 0 9.969 9.969 0 0 0-3.19 2.151 9.97 9.97 0 0 0-1.223 1.48c-.361.54-.671 1.112-.926 1.709a9.986 9.986 0 0 0 0 7.798 9.982 9.982 0 0 0 2.15 3.19 9.971 9.971 0 0 0 3.189 2.15 9.986 9.986 0 0 0 7.798 0 9.982 9.982 0 0 0 3.19-2.15 9.982 9.982 0 0 0 2.15-3.19 9.986 9.986 0 0 0 .585-5.915Z"
      fill="#2B3065"
    />
    <path
      d="M23.675 15.306c0-2.008-.986-3.738-1.737-4.3 0 0-.058-.032-.052.05-.061 3.88-2.06 4.932-3.158 6.348-2.534 3.27-.18 6.855 2.224 7.517 1.339.372-.311-.656-.524-2.825-.261-2.618 3.247-4.619 3.247-6.79Z"
      fill="#fff"
    />
    <path
      d="M24.827 16.628c-.016-.01-.038-.017-.052.008-.042.489-.544 1.533-1.181 2.494-2.162 3.254-.93 4.824-.238 5.668.403.488 0 0 1.005-.5a4.495 4.495 0 0 0 2.166-3.476c.197-2.313-1.202-3.77-1.7-4.194Z"
      fill="#fff"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HtCoinSVG;
