import * as Style from '../../Transfer.styles';
import * as Text from '~styles/text';

import AuthValidate from '~components/AuthValidate';
import { ErrorToast } from '~components/Toast';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { PixKeys } from '~reactQuery/types/pix.types';
import { PixModalStepsProps } from '../../Transfer.types';
import { capitalizeName } from '~helpers/format/user';
import { getSelectedUserOrganization } from '~helpers/device';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { parseError } from '~reactQuery/index';
import { useFormContext } from 'react-hook-form';
import { useGetBrlBanks } from '~reactQuery/queries/bank/useGetBrlBank';
import { usePixTransferUsingPixKey } from '~reactQuery/mutations/pix/usePixTransferUsingPixKey';
import { usePixTransferUsingPixManual } from '~reactQuery/mutations/pix/usePixTransferUsingPixManual';
import { usePixTransferUsingTed } from '~reactQuery/mutations/pix/usePixTransferUsingTed';
import { useState } from 'react';
import { useUserAuthTypes } from '~hooks/useUserAuthTypes';
import { LoadingSpinner } from '~components/index';
import colors from '~styles/colors';

function Pin(props: PixModalStepsProps) {
  const [loading, setLoading] = useState(false);
  const { setPixStep: setCurrentStep } = props;
  const userOrganization = getSelectedUserOrganization();
  const { getValues, setValue } = useFormContext();
  const { auth } = useUserAuthTypes();
  const formValues = getValues();

  const { data: bankList } = useGetBrlBanks();
  const { mutateAsync: makePixByKey, isLoading: loadingPix } =
    usePixTransferUsingPixKey();
  const { mutateAsync: makePixManual, isLoading: loadingManual } =
    usePixTransferUsingPixManual();
  const { mutateAsync: makePixTed, isLoading: loadingPixTed } =
    usePixTransferUsingTed();

  const nameCapitalized = capitalizeName(
    formValues?.keyResult?.resolve?.key?.holderName ||
      formValues?.manualDetails?.name ||
      formValues?.contact?.name
  );

  const isLoading = loadingPix || loadingManual || loadingPixTed || loading;

  const goBack = () => setCurrentStep('confirmDetails');

  const [hasError, setHasError] = useState({
    pin: '',
    twoFa: '',
  });

  const makeTransfer = async (pin: string, twoFa?: string) => {
    const valueParsed =
      Number(
        formValues?.amount
          ?.replace('R$ ', '')
          ?.replace(/\./g, '')
          ?.replace(',', '.')
      ) * 100;

    if (!String(valueParsed)) return;

    setHasError({
      pin: '',
      twoFa: '',
    });

    if ((auth.includes('2-FA') && twoFa?.length !== 6) || pin.length !== 4) {
      return;
    }

    setLoading(true);

    const methodOfTransfer = formValues?.methodOfTransfer;

    try {
      let resp;

      const twoFA = twoFa ? { token: twoFa } : {};

      if (methodOfTransfer === 'PIX') {
        const keyType = String(
          formValues?.keyResult?.resolve?.keyType
        ).toLocaleLowerCase() as PixKeys;
        const key = formValues?.keyResult?.keyValue;

        resp = await makePixByKey({
          method: 'pix',
          value: Number(valueParsed.toFixed(2)),
          description: formValues?.description || '',
          userOrganizationId: Number(userOrganization),
          pin,
          ...twoFA,
          requisite: {
            key: ['cpf', 'cnpj', 'phone'].includes(keyType)
              ? onlyNumbers(key)
              : key,
            keyType,
          },
        });
      }

      const bankInfosFiltered = bankList?.filter(
        (b) =>
          b?.bankCompe === formValues?.manualDetails?.bankCode ||
          b?.bankIspb === formValues?.manualDetails?.bankIspb
      )?.[0];

      if (methodOfTransfer === 'TED' && bankInfosFiltered) {
        resp = await makePixTed({
          userOrganizationId: Number(userOrganization),
          value: Number(valueParsed.toFixed(2)),
          description: formValues?.description || '',
          method: 'ted',
          requisite: {
            bank: {
              bankCompe:
                bankInfosFiltered?.bankCompe || bankInfosFiltered?.bankIspb,
              bankIspb: bankInfosFiltered?.bankIspb,
              bankName: bankInfosFiltered?.bankName,
            },
            branch: formValues?.manualDetails?.branch,
            account: onlyNumbers(formValues?.manualDetails?.accountNumber),
            holderName: formValues?.manualDetails?.name,
            holderDocument: formValues?.manualDetails?.docNum,
            accountType: formValues?.manualDetails?.accountType,
          },
          pin,
          ...twoFA,
        });
      }

      if (methodOfTransfer === 'PIX Manual' && bankInfosFiltered) {
        resp = await makePixManual({
          userOrganizationId: Number(userOrganization),
          value: Number(valueParsed.toFixed(2)),
          description: formValues?.description || '',
          method: 'pix',
          requisite: {
            key: {
              bank: {
                bankCompe:
                  bankInfosFiltered?.bankCompe || bankInfosFiltered?.bankIspb,
                bankIspb: bankInfosFiltered?.bankIspb,
                bankName: bankInfosFiltered?.bankName,
              },
              branch: formValues?.manualDetails?.branch,
              account: onlyNumbers(formValues?.manualDetails?.accountNumber),
              holderName: formValues?.manualDetails?.name,
              holderDocument: formValues?.manualDetails?.docNum,
              accountType: formValues?.manualDetails?.accountType,
            },
            keyType: 'bankaccount',
          },
          pin,
          ...twoFA,
        });
      }
      if (resp) {
        setLoading(false);
        setValue('transferResp', resp);
        setCurrentStep('status');
      }
    } catch (error: any) {
      setLoading(false);
      const errorKey = parseError(error);

      if (errorKey === 'NOT_FOUND_PIX_KEY') {
        ErrorToast({
          title: 'Chave Pix não encontrada',
          subtitle: '',
          autoClose: true,
        });
      }

      if (errorKey === 'INVALID_PIN') {
        setHasError((prev) => ({ ...prev, pin: 'Pin inválido' }));
      }

      if (errorKey === 'INVALID_2FA_TOKEN') {
        setHasError((prev) => ({ ...prev, twoFa: '2-FA inválido' }));
      }
    }
  };

  return (
    <>
      <ModalMultiStepHeader
        title={`Transferindo ${formValues?.amount} para ${nameCapitalized}`}
        description="Preencha as confirmações de segurança"
        backButtonAction={goBack}
      />

      <Style.PinContentWrapper>
        {!isLoading ? (
          <AuthValidate
            authTypes={auth}
            onChangeCodes={(pin, twoFa) => makeTransfer(pin, twoFa)}
            pinErrorMessage={hasError.pin}
            twoFaErrorMessage={hasError.twoFa}
          />
        ) : (
          <div className="container-loading">
            <LoadingSpinner color={colors.primary} size={60} strokeSize={25} />
          </div>
        )}
      </Style.PinContentWrapper>
    </>
  );
}

export default Pin;
