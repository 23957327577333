import { SVGProps } from 'react';

const MastercardSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 31}
    height={props.height || 18}
    viewBox="0 0 31 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={9} cy={9} r={9} fill="#EA2218" />
    <circle cx={22} cy={9} r={9} fill="#FA9C1E" />
    <path
      d="M15.5 15.225A8.97 8.97 0 0 0 18 9a8.97 8.97 0 0 0-2.5-6.225A8.97 8.97 0 0 0 13 9a8.97 8.97 0 0 0 2.5 6.225Z"
      fill={props.fill || '#FF5E00'}
    />
  </svg>
);

export default MastercardSVG;
