import { colors } from '~styles';
import styled from 'styled-components';

export const RecoveryPinContainer = styled.div`
  .recovery-pin-body {
    width: 100%;
    margin-top: 25px;
    padding: 0 38px;

    .justify-items-center {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .container-success-recovery-pin{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    text-align: center;
  }
`;

export const CenterContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
