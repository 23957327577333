import * as React from 'react';

import { SVGProps } from 'react';

const LtcCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="m21.097 22.851.995-3.91 2.363-.898.588-2.3-.02-.06-2.324.884L24.37 10H19.62l-2.19 8.572-1.832.696-.597 2.37 1.831-.694L15.541 26H28.19L29 22.85h-7.902"
      fill="#B5B2B2"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LtcCoinSVG;
