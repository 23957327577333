import { gql, request, useQuery } from '~reactQuery/index';

interface ParamsGenerateIncomes {
  userOrganizationId: number;
  calendar?: number;
}

interface GetGenerateIncomesResponse {
  data: Array<{
    generateIncomes: string;
  }>;
}

export const useGetGenerateIncomes = (params: ParamsGenerateIncomes) => {
  const QUERY = gql`
    query Query($params: GenerateIncomesInput!) {
      generateIncomes(params: $params)
    }
  `;

  const fetch = async () => {
    if (!params.userOrganizationId) return null;
    const response = await request<GetGenerateIncomesResponse>(QUERY, {
      params,
    });

    return response;
  };

  return useQuery(['useGenerateIncomes'], fetch, {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};
