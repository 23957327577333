import * as React from 'react';
import { SVGProps } from 'react';

const DisabledMailSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={45}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.076 14.032A1.51 1.51 0 0 1 12.5 13h20c.66 0 1.226.435 1.424 1.032L22.5 22.03l-11.424-7.997ZM9 14.483V29.5c0 1.927 1.573 3.5 3.5 3.5h20c1.927 0 3.5-1.573 3.5-3.5V14.512m-2 1.909V29.5c0 .823-.677 1.5-1.5 1.5h-20c-.823 0-1.5-.677-1.5-1.5V16.42l10.927 7.65a1 1 0 0 0 1.146 0L34 16.42Zm2-1.938A3.507 3.507 0 0 0 32.5 11h-20A3.507 3.507 0 0 0 9 14.483"
      fill="#C3C6DB"
    />
  </svg>
);

export default DisabledMailSVG;
