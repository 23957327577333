import { ChangeEvent, DragEvent, useEffect, useRef, useState } from 'react';
import * as Styled from './InputCSV.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import UploadSVG from '~assets/svg/upload';
import FileSVG from '~assets/svg/file';
import CloseRoundedSVG from '~assets/closeRounded';
import { InputCSVProps } from './InputCSV.types';

const InputCSV = ({ onUploadFile, setProgressUpload }: InputCSVProps) => {
  const inputRef = useRef<any>(null);
  const [file, setFile] = useState<File>();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!file) return () => {};

    let p = 0;
    const interval = setInterval(() => {
      if (p < 100) {
        p += 1;
        if (setProgressUpload) {
          setProgressUpload(p);
        }

        setProgress(p);
      }
    }, 20);

    return () => {
      clearInterval(interval);
    };
  }, [file]);

  const handleRemoveFile = () => {
    setFile(undefined);
    if (setProgressUpload) {
      setProgressUpload(0);
    }
    setProgress(0);
  };

  const handleClickToSelectFile = () => {
    if (!inputRef) return;

    inputRef?.current?.click();
  };

  const handleDragOver = (event: DragEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const f = event.target.files?.[0];
    if (f && f.type === 'text/csv') {
      setFile(f);
      onUploadFile(f);
    }
  };

  const handleDrop = (event: DragEvent<HTMLInputElement>) => {
    event.preventDefault();
    const f = event.dataTransfer?.files?.[0];
    if (f && f.type === 'text/csv') {
      setFile(f);
      onUploadFile(f);
    }
  };

  return (
    <Styled.Container
      onClick={handleClickToSelectFile}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      hasFile={!!file}
      progress={progress}
    >
      {file ? (
        <div className="uploaded-container">
          <div className="header">
            <div className="left-content">
              <FileSVG />
              <Text.Small fontWeight={500}>{file.name}</Text.Small>
            </div>
            <div className="icon" onClick={handleRemoveFile}>
              <CloseRoundedSVG />
            </div>
          </div>

          <div className="footer">
            <div className="loading-backdrop">
              <div className="loading" />
            </div>
            <Text.Small>{progress}%</Text.Small>
          </div>
        </div>
      ) : (
        <>
          <input
            ref={inputRef}
            type="file"
            accept="text/csv"
            onChange={handleFileChange}
          />

          <div className="icon-container">
            <UploadSVG />
          </div>
          <Text.Paragraph display="inline-block">
            Arraste e solte ou{' '}
            <span className="blue-text">escolha o arquivo</span> para enviar
          </Text.Paragraph>
          <Text.Small color={colors.grayRegular}>
            Formato suportado: CSV
          </Text.Small>
        </>
      )}
    </Styled.Container>
  );
};

export default InputCSV;
