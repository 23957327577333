import * as React from 'react';

import { SVGProps } from 'react';

const AvatarSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0171 2.00598C6.44895 2.00598 2 6.45246 2 12.006C2 17.5583 6.44772 22.006 12 22.006C17.5523 22.006 22 17.5583 22 12.006C22 6.45742 17.5901 2.00598 12.0171 2.00598ZM4 12.006C4 7.5595 7.55105 4.00598 12.0171 4.00598C16.4781 4.00598 20 7.55454 20 12.006C20 13.8062 19.4181 15.4597 18.4303 16.7904C18.298 16.3445 18.1009 15.9409 17.8474 15.5798C17.3568 14.881 16.6936 14.3957 16.0047 14.0601C15.4558 13.7927 14.8693 13.61 14.2947 13.4872C15.5456 12.7197 16.375 11.34 16.375 9.75598C16.375 7.33242 14.4438 5.38098 12.0064 5.38098C9.57395 5.38098 7.625 7.32746 7.625 9.75598C7.625 11.34 8.45443 12.7197 9.70529 13.4872C9.13073 13.61 8.5442 13.7927 7.99534 14.0601C7.30639 14.3957 6.64322 14.881 6.1526 15.5798C5.89912 15.9409 5.70201 16.3445 5.56966 16.7904C4.58194 15.4597 4 13.8062 4 12.006ZM7.375 18.5516C8.6773 19.4692 10.271 20.006 12 20.006C13.729 20.006 15.3227 19.4692 16.625 18.5516V18.1935C16.625 17.5393 16.4537 17.0754 16.2105 16.729C15.9607 16.3732 15.5947 16.0851 15.1289 15.8581C14.1733 15.3927 12.9442 15.256 12 15.256C11.0558 15.256 9.8267 15.3927 8.87114 15.8581C8.40526 16.0851 8.03931 16.3732 7.78951 16.729C7.54635 17.0754 7.375 17.5393 7.375 18.1935V18.5516ZM12.0064 7.38098C10.676 7.38098 9.625 8.4345 9.625 9.75598C9.625 11.0787 10.6773 12.131 12 12.131C13.3227 12.131 14.375 11.0787 14.375 9.75598C14.375 8.42954 13.3318 7.38098 12.0064 7.38098Z"
      fill={props.fill || '#757893'}
    />
  </svg>
);

export default AvatarSVG;
