import * as Style from './ChangePassword.styles';

import { SetCurrentStepProps, StepParamsProps } from './ChangePassword.types';
import { useEffect, useState } from 'react';

import AskPassword from './ModalSteps/1.AskPasswords.screen';
import Dialog from '~components/Dialog';
import ModalMultiStep from '~components/ModalMultiStep';
import SuccessChangePassword from './ModalSteps/2.Success.screen';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

function ChangePassWordModalSteps() {
  const { openModalMultiStep, setOpenModalMultiStep } =
    useActionsOnModalMultiStep();

  const [currentStep, setCurrentStep] =
    useState<SetCurrentStepProps>('askPassword');
  const [stepParams, setStepParams] = useState<StepParamsProps>({});

  function setStepsAndParams(step: SetCurrentStepProps, params?: object) {
    if (params) {
      setStepParams((prev) => ({ prev, ...params } as StepParamsProps));
    }
    setCurrentStep(step);
  }

  const modalStepsAndDetails = {
    askPassword: {
      component: (
        <AskPassword key="askPassword" setStepsAndParams={setStepsAndParams} />
      ),
      progressValue: 50,
    },
    successChangePassword: {
      component: <SuccessChangePassword key="successChangePassword" />,
      progressValue: 100,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  // CLEAR STEP ON MODAL CLOSE
  useEffect(() => {
    if (!openModalMultiStep) setCurrentStep('askPassword');
  }, [openModalMultiStep]);

  return (
    <Style.ChangePasswordContainer>
      <Dialog
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
      >
        {currentStepInfos.component}
      </Dialog>
    </Style.ChangePasswordContainer>
  );
}

export default ChangePassWordModalSteps;
