import styled, { css } from 'styled-components';
import colors from '~styles/colors';

export const MenuContainer = styled.div<{
  widthContainer?: number;
}>`
  z-index: 9999999;

  .menu {
    display: flex;
    flex-direction: column;
  }

  .menu-button {
    border: none;
  }

  /* .menu-item {
    width: ${(props) => props.widthContainer || 248}px;
  } */

  .szh-menu {
    border: 1px solid ${colors.blackOpacity10p};
    box-shadow: none;
    border-radius: 10px;
    width: ${(props) => props.widthContainer || 248}px;
  }
`;
