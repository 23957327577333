import { SVGProps } from 'react';

const GiftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    className={props.className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.78571 5C8.61626 5 8.42735 5.0744 8.26896 5.2518C8.10726 5.43291 8 5.7005 8 6C8 6.2995 8.10726 6.56709 8.26896 6.7482C8.42735 6.9256 8.61626 7 8.78571 7H10.674C10.605 6.81789 10.5249 6.62839 10.4327 6.44079C9.9736 5.5058 9.42109 5 8.78571 5ZM6 6C6 6.3407 6.05387 6.67909 6.15892 7H4C3.44772 7 3 7.44772 3 8V12C3 12.5523 3.44772 13 4 13H5V19C5 19.5523 5.44772 20 6 20H11H13H18C18.5523 20 19 19.5523 19 19V13H20C20.5523 13 21 12.5523 21 12V8C21 7.44772 20.5523 7 20 7H17.8411C17.9461 6.67909 18 6.3407 18 6C18 5.23863 17.731 4.48881 17.2229 3.91977C16.7115 3.34702 15.992 3 15.2143 3C13.5887 3 12.5788 4.13562 12 5.13246C11.4212 4.13562 10.4113 3 8.78571 3C8.00797 3 7.28847 3.34702 6.77709 3.91977C6.26902 4.48881 6 5.23863 6 6ZM13.326 7H15.2143C15.3837 7 15.5726 6.9256 15.731 6.7482C15.8927 6.56709 16 6.2995 16 6C16 5.7005 15.8927 5.43291 15.731 5.2518C15.5726 5.0744 15.3837 5 15.2143 5C14.5789 5 14.0264 5.5058 13.5673 6.44079C13.4751 6.62839 13.395 6.81789 13.326 7ZM13 9V11H17H19V9H15.2143H13ZM8.78571 9H11V11H7H5V9H8.78571ZM13 18H17V13H13V18ZM11 13V18H7V13H11Z"
      fill={props.fill || '#026FF4'}
    />
  </svg>
);

export default GiftIcon;