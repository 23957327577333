import * as React from 'react';
import { SVGProps } from 'react';

const WalletBusdSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m15.931 8.93-5.213 5.228 2.12 2.126 5.213-5.228-2.12-2.127ZM9.757 15.126l-2.12 2.125 2.12 2.126 2.12-2.125-2.12-2.126ZM22.179 15.145l-8.346 8.37 2.106 2.113 8.347-8.37-2.107-2.113ZM19.1 12.057l-8.346 8.37 2.107 2.113 8.346-8.37-2.107-2.113Z"
      fill="#F0B90B"
    />
  </svg>
);

export default WalletBusdSVG;
