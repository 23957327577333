import { gql, request, useMutation } from '~reactQuery';

interface Beneficiary {
  accountType: string;
  amount: string;
  e2eId?: string;
  fullName: string;
  holderDocument?: string;
  holderName?: string;
  keyType?: string;
  saveForNextTransfer: boolean;
  agency?: string;
  bank?: string;
  bankAccount?: string;
  documentNumber?: string;
  transferType?: string;
}

interface CreatePaymentListInput {
  userOrganizationId: number;
  perUser: boolean;
  data: {
    nameList: string;
    listOfBeneficiaries: Array<Beneficiary>;
  };
}

export interface CreatePaymentListResponse {
  data: {
    accountId: null | number;
    createdAt: null | string;
    data: {
      id: number;
      accountId: number;
      data: {
        nameList: string;
        listOfBeneficiaries: Array<Beneficiary>;
      };
      userOrganizationId: number;
      createdAt: string;
      updatedAt: string;
      deletedAt: null | string;
    };
    deletedAt: null | string;
    id: null | number;
    updatedAt: null | string;
    userOrganizationId: null | number;
  };
}

export function useCreatePaymentList() {
  const mutation = gql`
    mutation CreatePaymentList($params: CreatePaymentListInput) {
      createPaymentList(params: $params) {
        accountId
        createdAt
        data
        deletedAt
        id
        updatedAt
        userOrganizationId
      }
    }
  `;

  const fetch = async (params: CreatePaymentListInput) => {
    const response = await request<CreatePaymentListResponse>(mutation, {
      params,
    });
    return response;
  };

  return useMutation('useCreatePaymentList', fetch);
}
