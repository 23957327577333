import { useEffect, useState } from 'react';
import * as Style from './SignTemplate.styles';
import * as Text from '~styles/text';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { getSelectedUserOrganization, getToken } from '~helpers/device';

import BusinessWhiteLogo from '~assets/images/logo-business-white.svg';
import { GenericButton } from '~components';
import { When } from 'react-if';
import { breakpoints } from '~styles/metrics';
import useWindowDimensions from '~hooks/windowDimension';
import AlertModal from '~components/AlertModal';
import { handleOnClickHelp } from '~helpers/generic';

const Login = () => {
  const [modalIsVisible, setModaIsVisible] = useState(false);

  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const token = getToken();
  const selectedOrg = getSelectedUserOrganization();

  const showLeftImage = breakpoints.desktop < width;

  const isHome = useMatch('/auth/sign-in');

  function goHome() {
    if (!isHome) {
      navigate('/');
    }
  }

  useEffect(() => {
    if (token) {
      if (!selectedOrg) {
        navigate('/organizations');
      } else {
        navigate('/dashboard');
      }
    }
  }, [navigate, selectedOrg, token]);

  const redirectToOldBIz = () => {
    window.location.href = import.meta.env.VITE_PLATAFORMURL;
  };

  return (
    <Style.LoginContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <When condition={showLeftImage}>
        <Style.SideImage>
          <Style.LogoContainer onClick={goHome}>
            <BusinessWhiteLogo />
          </Style.LogoContainer>
        </Style.SideImage>
      </When>

      <Style.Content>
        <Style.Header>
          <GenericButton buttonType="default" onClick={handleOnClickHelp}>
            Ajuda
          </GenericButton>
        </Style.Header>

        <Outlet />
      </Style.Content>

      <When condition={modalIsVisible}>
        <AlertModal
          setOpenAlert={setModaIsVisible}
          title="Acessando a Plataforma Antiga"
          primaryButtonText="Cancelar"
          secondaryButtonText="Sim, estou ciente."
          primaryButtonAction={() => setModaIsVisible(false)}
          secondaryButtonAction={() => redirectToOldBIz()}
          secondaryButtonColor="secondary"
          secondaryButtonWidth="190px"
          bodyComponent={
            <>
              <Text.Heading4 marginBottom={30}>
                Caso você precise acessar a plataforma antiga, basta clicar no
                botão abaixo para ser redirecionado. Porém, por favor, fique
                ciente de que as credenciais de login e senha da plataforma
                antiga são diferentes da nova.
              </Text.Heading4>

              <Text.Heading4 marginBottom={30}>
                A plataforma antiga não possui as mesmas funcionalidades e
                comodidades da plataforma mais nova, e é por isso que
                recomendamos ficar nela a menos que seja extremamente
                necessário.
              </Text.Heading4>

              <Text.Heading4>
                Antes de prosseguir, você está ciente de que precisará usar
                credenciais diferentes na plataforma antiga?
              </Text.Heading4>
            </>
          }
        />
      </When>
    </Style.LoginContainer>
  );
};

export default Login;
