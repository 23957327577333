import { useEffect, useState } from 'react';
import { When } from 'react-if';
import { colors } from '~styles';
import * as Text from '~styles/text';
import * as Style from './FloatingMenu.styles';
import { FloatingMenuProps } from './FloatingMenu.types';

export const FloatingMenu: React.FC<FloatingMenuProps> = (props) => {
  const { openModal, setOpenModal, itensList } = props;

  const [showAnim, setShowAnim] = useState(false);

  useEffect(() => {
    if (openModal) {
      setShowAnim(true);
    }
  }, [openModal]);

  const closeModal = () => {
    setShowAnim(false);
    setTimeout(() => {
      setOpenModal(!openModal);
    }, 300);
  };

  return (
    <When condition={openModal}>
      <Style.ModalBackground onClick={closeModal} fadeIn={showAnim}>
        <Style.ModalContainer
          style={props.modalPosition}
          onClick={(e) => e.stopPropagation()}
        >
          {itensList?.map((item) => {
            return (
              <Style.ListItens
                key={`${item.label}`}
                disabled={!!item.disabled}
                onClick={item.disabled ? () => {} : item.onClick}
              >
                <Style.Icon>{item.icon}</Style.Icon>
                <Text.Heading5 color={item.labelColor || colors.black}>
                  {item.label}
                </Text.Heading5>
              </Style.ListItens>
            );
          })}
        </Style.ModalContainer>
      </Style.ModalBackground>
    </When>
  );
};
