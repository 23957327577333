import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 16px;

  /* align-items: center; */
  /* position: absolute;
  z-index: 1000;
  left: 0;
  top: 0; */


  .icon{
    min-width: 24px;
    min-height: 24px;
  }
`;
