import React, { SVGProps } from 'react';

const ArrowDownSimpleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '10'}
      height={props.height || '8'}
      viewBox="0 0 10 8"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.57566V0.445313L5 5.48778L10 0.445312V2.57566L5.53257 7.08104L5 7.61813L4.46743 7.08104L0 2.57566Z"
        fill={props.fill || '#272937'}
      />
    </svg>
  );
};

export default ArrowDownSimpleIcon;
