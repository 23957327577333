import ModalMultiStep from '~components/ModalMultiStep/ModalMultiStep.component';
import * as Style from './TransferBatch.styles';
import * as Text from '~styles/text';
import { useState } from 'react';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import SelectRecipientsOrSeeLists from './ModalSteps/1.SelectRecipientsOrSeeLists';
import ConfirmPaymentList from './ModalSteps/2.ConfirmPaymentList';
import ConfirmPin from './ModalSteps/3.ConfirmPin';
import StatusPayment from './ModalSteps/4.StatusPayment';
import {
  ListOfBeneficiariesTransferProps,
  TransferBatchSteps,
} from './TransferBatch.types';
import AlertModal from '~components/AlertModal';
import { When } from 'react-if';

export const TransferBatch = () => {
  const { openModalMultiStep, setOpenModalMultiStep, setParams, params } =
    useActionsOnModalMultiStep();
  const [stepTransferBatch, setStepTransferBatch] =
    useState<TransferBatchSteps>('selectRecipientsOrSeeLists');
  const [tabSelected, setTabSelected] = useState<'inBatch' | 'savedLists'>(
    'inBatch'
  );
  const [listOfBeneficiaries, setListOfBeneficiaries] =
    useState<ListOfBeneficiariesTransferProps>([]);
  const [closeModalVisible, setCloseModalVisible] = useState({
    visible: false,
    comingFrom: 'closeModal',
  });

  const batchPaymentComponents = {
    selectRecipientsOrSeeLists: {
      component: (
        <SelectRecipientsOrSeeLists
          key="selectRecipientsOrSeeLists"
          setStepTransferBatch={setStepTransferBatch}
          tabSelected={tabSelected}
          setTabSelected={setTabSelected}
          setCloseModalVisible={setCloseModalVisible}
          listOfBeneficiaries={listOfBeneficiaries}
          setListOfBeneficiaries={setListOfBeneficiaries}
        />
      ),
      progressValue: 25,
    },
    confirmPaymentList: {
      component: (
        <ConfirmPaymentList
          key="confirmPaymentList"
          setStepTransferBatch={setStepTransferBatch}
          setCloseModalVisible={setCloseModalVisible}
        />
      ),
      progressValue: 50,
    },
    confirmPin: {
      component: (
        <ConfirmPin
          key="confirmPin"
          setStepTransferBatch={setStepTransferBatch}
        />
      ),
      progressValue: 70,
    },
    statusPayment: {
      component: (
        <StatusPayment
          key="statusPayment"
          setStepTransferBatch={setStepTransferBatch}
        />
      ),
      progressValue: 100,
    },
  };

  const onCloseModal = () => {
    if (
      tabSelected === 'inBatch' &&
      stepTransferBatch === 'selectRecipientsOrSeeLists' &&
      listOfBeneficiaries?.length > 0
    ) {
      return setCloseModalVisible({ comingFrom: 'closeModal', visible: true });
    }
    return setOpenModalMultiStep(false);
  };

  const getFunctionsCloseModal = () => {
    setListOfBeneficiaries([]);
    if (closeModalVisible?.comingFrom === 'closeModal') {
      setOpenModalMultiStep(false);
      return;
    }

    if (closeModalVisible?.comingFrom === 'savedLists') {
      setTabSelected('savedLists');
      setCloseModalVisible({ comingFrom: 'closeModal', visible: false });
      return;
    }

    if (closeModalVisible?.comingFrom === 'confirmPaymentList') {
      setStepTransferBatch('selectRecipientsOrSeeLists');
      setParams({
        origin: 'confirmPaymentList',
        sendTo: params?.origin,
      });
      setCloseModalVisible({ comingFrom: 'closeModal', visible: false });
      return;
    }

    setCloseModalVisible({ comingFrom: 'closeModal', visible: false });
  };

  return (
    <Style.Container>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        onClose={() => onCloseModal()}
        barProgressPercent={
          batchPaymentComponents[stepTransferBatch]?.progressValue
        }
        classNameBar="bar-percentage"
      >
        {batchPaymentComponents[stepTransferBatch]?.component}
      </ModalMultiStep>

      <When condition={closeModalVisible?.visible}>
        <AlertModal
          setOpenAlert={(event: boolean) =>
            setCloseModalVisible({ ...closeModalVisible, visible: event })
          }
          title="Os dados inseridos serão perdidos. Você tem certeza que quer sair?"
          secondaryButtonText="Sair"
          secondaryButtonAction={() => getFunctionsCloseModal()}
          primaryButtonText="Voltar"
          primaryButtonAction={() =>
            setCloseModalVisible({ comingFrom: 'closeModal', visible: false })
          }
          secondaryButtonWidth="153px"
          bodyComponent={
            <Text.Heading5>
              Ao sair os beneficiários que não foram salvos em uma lista serão
              perdidos e depois será necessário adicioná-los novamente. Salve a
              lista de beneficiários antes de continuar essa ação.
            </Text.Heading5>
          }
          noBorderTop
          paddingVertical={0}
          paddingBottomTitle="0"
          secondaryButtonColor="error"
        />
      </When>
    </Style.Container>
  );
};
