import Kyc from '~pages/Kyc';
import { Navigate, Route, Routes } from 'react-router-dom';

export const KycRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Kyc />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
