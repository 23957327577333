import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from '@react-pdf/renderer';
import HelveticaNowDisplayRegular from '../../fonts/HelveticaNowDisplay-Regular.ttf';
import HelveticaNowDisplayMedium from '../../fonts/HelveticaNowDisplay-Medium.ttf';
import HelveticaNowDisplayBold from '../../fonts/HelveticaNowDisplay-Bold.ttf';
import { accountsTypes } from '~data/bankUtils';
import blackLogo from '~assets/images/logo-black.png';
import colors from '~styles/colors';
import dayjs from 'dayjs';
import { formatCurrencyFn } from '~helpers/format/currency';
import { suprimeCpfOrCnpj } from '~helpers/format/numbers';
import { getTxStatus } from '~helpers/transaction/transactionUtils';

interface TransferVoucherPixProps {
  authCode?: string;
  accountDetails: any;
  transferDetails?: any;
  status?: string;
  transactionOriginal?: any;
}

const styles = StyleSheet.create({
  body: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    fontWeight: 400,
  },
  title: {
    fontSize: 26,
    textAlign: 'left',
    fontWeight: 700,
    lineHeight: '120%',

    color: '#272937',
  },
  logo: {
    width: 125,
    height: 33,
  },
  container: {
    width: '100%',
    marginTop: 30,
    padding: '25px 25px 25px 25px',
  },
  date: {
    marginTop: 15,
    marginBottom: 40,
    fontWeight: 500,
    fontSize: 16,
    color: colors.grayMedium,
  },
  valueContainer: {
    flexDirection: 'column',
  },
  divider: {
    width: 50,
    height: 1,
    backgroundColor: '#C3C6DB',
    marginBottom: 30,
    marginTop: 30,
    // border: '1px solid #C3C6DB',
  },
  footer: {
    backgroundColor: colors.mainGray,
    padding: 20,
    borderRadius: 15,
    bottom: 15,
    position: 'absolute',
    width: '90%',
  },
  label: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '100%',
    color: colors.grayRegular,
  },
  link: {
    color: colors.primary,
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '150%',
    marginBottom: 5,
    textAlign: 'center',
  },
  containerImg: {
    height: 33,
    top: 30,
    left: 25,
    width: '100%',
    zIndex: 99,
  },
  transferValue: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '100%',
    marginBottom: 7,

    color: '#272937',
  },
  textNames: {
    fontWeight: 700,
    fontSize: 18,
    marginBottom: 7,

    color: '#272937',
  },
  bankNameText: {
    fontWeight: 500,
    fontSize: 15,
    marginBottom: 20,

    color: '#272937',
  },
  text: {
    fontWeight: 500,
    fontSize: 15,

    color: '#272937',
  },
  displayRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  marginLeft: {
    marginLeft: 20,
  },
  marginBottom: {
    marginBottom: 20,
  },
  colorGrayMedium: {
    color: colors.grayMedium,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  copyIcon: {
    width: 16,
    height: 16,
  },
  footerText: {
    fontWeight: 500,
    fontSize: 14,

    color: '#757893',
  },
  footerTitle: {
    fontWeight: 500,
    fontSize: 15,

    textAlign: 'center',
    marginTop: 29,
  },
  footerSubtitle: {
    fontWeight: 500,
    fontSize: 15,

    textAlign: 'center',
  },
});

export const RefundSendReceipt = ({
  transferDetails,
  accountDetails,
  authCode,
  transactionOriginal,
  status,
}: TransferVoucherPixProps) => {
  const dataToDisplay = {
    method: transferDetails?.data?.method,
    value: transferDetails?.data?.value,
    senderBranch: accountDetails?.branch,
    senderAcc: accountDetails?.account,
    senderName: accountDetails?.name || '',
    senderDocumentNumber: accountDetails?.documentNumber || '',
    accType:
      transferDetails?.data?.requisite?.key?.accountType ||
      transferDetails?.data?.requisite?.accountType,
    receiverName:
      transferDetails?.data?.requisite?.key?.holderName ||
      transferDetails?.data?.requisite?.holderName,
    receiverDocNum:
      transferDetails?.data?.requisite?.key?.holderDocument ||
      transferDetails?.data?.requisite?.holderDocument,
    receiverBankName:
      transferDetails?.data?.requisite?.key?.bank?.bankName ||
      transferDetails?.data?.requisite?.bank?.bankName,
    receiverBranch:
      transferDetails?.data?.requisite?.key?.branch ||
      transferDetails?.data?.requisite?.branch,
    receiverAcc:
      transferDetails?.data?.requisite?.key?.account ||
      transferDetails?.data?.requisite?.account,
    e2eId:
      transferDetails?.data?.requisite?.key?.e2eId ||
      transferDetails?.data?.requisite?.e2eId,
    description: transferDetails?.data?.description || '-',
    amountOriginal: transactionOriginal?.data?.value,
    typeAccountOriginal: transactionOriginal?.data?.requisite?.key?.accountType,
    dateTransactionOriginal: transactionOriginal?.timestamp,
    descriptionOriginal: transactionOriginal?.data?.description,
    e2eIdOriginal: transactionOriginal?.data?.requisite?.key?.e2eId,
  };

  const typeOfAccount = accountsTypes.filter((a) =>
    [String(dataToDisplay?.accType).toLocaleUpperCase()]?.includes(a?.value)
  )?.[0]?.label;

  const isCashIn = transferDetails?.direction === 1;

  return (
    <Document>
      <Page wrap style={styles.body} size={{ width: 375, height: 1500 }}>
        <View fixed style={styles.containerImg}>
          <Image src={blackLogo} style={styles.logo} />
        </View>
        <View style={styles.container}>
          <View>
            <Text style={styles.title}>Comprovante de</Text>
            <Text style={styles.title}>reembolso</Text>
          </View>
          <Text style={styles.date}>
            {dayjs(transferDetails?.timestamp)
              .locale('pt-br')
              .format('DD [de] MMMM [de] YYYY [às] HH:mm:ss')}
          </Text>
          <View style={styles.valueContainer}>
            <Text style={styles.label}>Valor reembolsado</Text>
            <Text style={styles.transferValue}>
              {formatCurrencyFn(
                'BRL',
                dataToDisplay?.value,
                false,
                false,
                true
              )}
            </Text>
          </View>

          <View style={{ marginBottom: 5 }}>
            <Text style={styles.label}>Motivo do reembolso</Text>
            <Text style={styles.text}>{dataToDisplay?.description}</Text>
          </View>

          <View>
            <Text style={styles.label}>Tipo de transferência</Text>
            <Text style={styles.text}>Pix</Text>
          </View>

          <View style={styles.divider} />

          <View>
            <Text style={styles.label}>
              {isCashIn ? 'Recebido por' : 'Enviado por'}
            </Text>
            <Text style={styles.textNames}>{dataToDisplay?.senderName}</Text>
            <Text style={{ ...styles.marginBottom, ...styles.colorGrayMedium }}>
              {suprimeCpfOrCnpj(dataToDisplay?.senderDocumentNumber)}
            </Text>
          </View>

          <View>
            <Text style={{ ...styles.label, marginBottom: 0 }}>
              Instituição
            </Text>
            <Text style={styles.bankNameText}>
              Acesso Soluções de Pagamento S. A.
            </Text>
          </View>

          <View style={styles.displayRow}>
            <View>
              <Text style={{ ...styles.label, marginBottom: 5 }}>Agência</Text>
              <Text style={styles.text}>{dataToDisplay?.senderBranch}</Text>
            </View>
            <View style={styles.marginLeft}>
              <Text style={{ ...styles.label, marginBottom: 5 }}>Conta</Text>
              <Text style={styles.text}>{dataToDisplay?.senderAcc}</Text>
            </View>
          </View>
          <View style={styles.divider} />

          <View>
            <Text style={styles.label}>
              {isCashIn ? 'Enviado por' : 'Recebido por'}
            </Text>
            <Text style={styles.textNames}>{dataToDisplay?.receiverName}</Text>
            <Text style={{ ...styles.marginBottom, ...styles.colorGrayMedium }}>
              {suprimeCpfOrCnpj(dataToDisplay?.receiverDocNum)}
            </Text>
          </View>

          <View>
            <Text style={{ ...styles.label, marginBottom: 0 }}>
              Instituição
            </Text>
            <Text style={styles.bankNameText}>
              {dataToDisplay?.receiverBankName}
            </Text>
          </View>

          <View style={styles.displayRow}>
            <View style={styles.marginBottom}>
              <Text style={{ ...styles.label, marginBottom: 5 }}>Agência</Text>
              <Text style={styles.text}>{dataToDisplay?.receiverBranch}</Text>
            </View>
            <View style={styles.marginLeft}>
              <Text style={{ ...styles.label, marginBottom: 5 }}>Conta</Text>
              <Text style={styles.text}>{dataToDisplay?.receiverAcc}</Text>
            </View>
          </View>

          <View>
            <Text style={{ ...styles.label, marginBottom: 5 }}>
              Tipo de conta
            </Text>
            <Text style={styles.text}>{typeOfAccount}</Text>
          </View>

          <View style={styles.divider} />

          <View style={{ marginBottom: 10 }}>
            <Text style={{ ...styles.label, marginBottom: 5 }}>
              Valor da transferência original
            </Text>
            <Text style={styles.text}>
              {formatCurrencyFn(
                'BRL',
                dataToDisplay?.amountOriginal,
                false,
                false,
                true
              )}
            </Text>
          </View>

          {dataToDisplay?.typeAccountOriginal && (
            <View style={{ marginBottom: 10 }}>
              <Text style={{ ...styles.label, marginBottom: 5 }}>
                Tipo da transferência
              </Text>
              <Text style={styles.text}>
                {dataToDisplay?.typeAccountOriginal}
              </Text>
            </View>
          )}

          <View style={{ marginBottom: 10 }}>
            <Text style={{ ...styles.label, marginBottom: 5 }}>
              Data e horário
            </Text>
            <Text style={styles.text}>
              {dayjs(dataToDisplay?.dateTransactionOriginal)
                .locale('pt-br')
                .format('DD [de] MMMM [de] YYYY [às] HH:mm:ss')}
            </Text>
          </View>

          {dataToDisplay?.descriptionOriginal && (
            <View style={{ marginBottom: 10 }}>
              <Text style={{ ...styles.label, marginBottom: 5 }}>
                Descrição
              </Text>
              <Text style={styles.text}>
                {dataToDisplay?.descriptionOriginal}
              </Text>
            </View>
          )}

          <View>
            <Text style={{ ...styles.label, marginBottom: 5 }}>
              E2E-ID da transferência original
            </Text>
            <Text style={styles.text}>{dataToDisplay?.e2eIdOriginal}</Text>
          </View>
        </View>

        <View fixed style={styles.footer}>
          <View style={styles.marginBottom}>
            <Text style={styles.label}>Protocolo</Text>

            <View style={styles.row}>
              <Text style={styles.footerText}>{authCode}</Text>
            </View>
          </View>
          {dataToDisplay?.e2eId ? (
            <View>
              <Text style={styles.label}>E2E-ID</Text>

              <View style={styles.row}>
                <Text style={styles.footerText}>{dataToDisplay?.e2eId}</Text>
              </View>
            </View>
          ) : null}

          <Text style={styles.footerTitle}>Precisa de ajuda?</Text>
          <Text style={styles.footerSubtitle}>
            Estamos aqui se você tiver alguma dúvida.
          </Text>
          <Text style={styles.link}>help.capitual.com</Text>
        </View>
      </Page>
    </Document>
  );
};
