import * as React from 'react';

import { SVGProps } from 'react';

const DownloadSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M7 18h10"
      stroke={props.stroke || '#272937'}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M12 4v10"
      stroke={props.stroke || '#272937'}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="m7 10 5 5 5-5"
      stroke={props.stroke || '#272937'}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </svg>
);

export default DownloadSVG;
