import * as Style from './QuickActions.styles';
import * as Text from '~styles/text';
import { Sections, useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { QuickActionsProps } from './QuickActions.types';
import { QuickButton } from '~components/Buttons';
import React, { useEffect } from 'react';
import { colors } from '~styles';
import { usePermission } from '~helpers/permissionGate';
import { QuickButtonDropdown } from '~components/Buttons/QuickButtonDropdown';
import { Else, If, Then } from 'react-if';

const QuickActions: React.FC<QuickActionsProps> = (props) => {
  const { quickActions } = props;

  const {
    setOpenModalMultiStep,
    section,
    setSection,
    params,
    setParams,
    openModalMultiStep,
  } = useActionsOnModalMultiStep();
  const { granted: transferGranted } = usePermission('transferButton');
  const { granted: buttonsPixGranted } = usePermission('buttonsPix');
  const { granted: convertGranted } = usePermission('convertButton');
  const { granted: payBillsGranted } = usePermission('payBillsButton');
  const { granted: depositGranted } = usePermission('depositButton');
  const { granted: criptoTransactionsGranted } =
    usePermission('criptoTransactions');
  const { granted: paymentButtonGranted } = usePermission('paymentButton');

  const openModal = (itemSection: Sections, itemName: string) => {
    if (itemName === 'QR Code') {
      setSection(itemSection);

      setParams({ pixQrCode: true });
      return setOpenModalMultiStep(true);
    }

    if (itemSection === 'depositBill') {
      setSection('deposit');
      setParams({ origin: 'billsScreen' });
      return setOpenModalMultiStep(true);
    }

    if (itemSection === 'transfer' && itemName === 'Transferir') {
      setSection(itemSection);
      setParams({
        pixQrCode: false,
      });
      return setOpenModalMultiStep(true);
    }

    if (itemSection === 'deposit') {
      setSection(itemSection);
      setParams({
        pixQrCode: false,
      });
      return setOpenModalMultiStep(true);
    }

    if (
      (itemSection === 'payBills' && itemName === 'Pagar') ||
      (itemSection === 'payWithPix' && itemName === 'Pagar')
    ) {
      setSection(itemSection);
      setParams({
        pixQrCode: false,
      });
      return setOpenModalMultiStep(true);
    }

    setSection(itemSection);
    setParams({
      pixQrCode: false,
    });
    return setOpenModalMultiStep(true);
  };

  const verifySectionWhenPayWithPix = (
    itemName: string,
    itemSection: string | undefined
  ) => {
    if (
      itemName === 'QR Code' &&
      itemSection === 'payWithPix' &&
      params.pixQrCode
    ) {
      return true;
    }

    if (
      itemName === 'Copia e Cola' &&
      itemSection === 'payWithPix' &&
      !params.pixQrCode
    ) {
      return true;
    }

    if (itemName === 'Pagar' && itemSection === 'payWithPix') {
      return true;
    }

    return false;
  };

  function handleChangeColorBySection(
    itemName: string,
    itemSection: string | undefined
  ) {
    if (section !== itemSection) {
      if (section === 'transferBatch' && itemSection === 'transfer') {
        return true;
      }
      return false;
    }

    if (section === 'payWithPix') {
      return verifySectionWhenPayWithPix(itemName, itemSection);
    }

    return true;
  }

  const handleGranted = (name: string) => {
    const translator: { [key: string]: boolean } = {
      Transferir: transferGranted,
      'Copia e Cola': buttonsPixGranted,
      'QR Code': buttonsPixGranted,
      Converter: convertGranted,
      Depositar: depositGranted,
      'Novo Boleto de Depósito': payBillsGranted,
      Pagar: paymentButtonGranted,
    };

    return typeof translator[name] !== 'undefined' ? translator[name] : true;
  };

  useEffect(() => {
    if (!openModalMultiStep) {
      setSection('default');
    }
  }, [openModalMultiStep]);

  return (
    <Style.Container>
      <div className="button-container">
        {quickActions.map((item) => {
          return (
            <If key={item.name} condition={item.section === 'transfer'}>
              <Then>
                <div className="button-item">
                  <QuickButtonDropdown
                    icon={item.icon}
                    backgroundColorButton={
                      handleChangeColorBySection(item.name, item.section)
                        ? colors.primary
                        : undefined
                    }
                    backgroundHoverButton={
                      handleChangeColorBySection(item.name, item.section)
                        ? colors.primaryDark
                        : undefined
                    }
                    disabled={!item.section || !handleGranted(item.name)}
                    onClick={
                      item.section && handleGranted(item.name)
                        ? () => openModal(item.section as Sections, item.name)
                        : () => {}
                    }
                    arrayDropdown={[
                      {
                        text: 'Transferência em Lote',
                        onClick:
                          item.section && handleGranted(item.name)
                            ? () =>
                                openModal(
                                  'transferBatch' as Sections,
                                  item.name
                                )
                            : () => {},
                      },
                      {
                        text: 'Transferir',
                        onClick:
                          item.section && handleGranted(item.name)
                            ? () =>
                                openModal(item.section as Sections, item.name)
                            : () => {},
                      },
                    ]}
                  >
                    <Text.Heading5
                      color={
                        handleChangeColorBySection(item.name, item.section)
                          ? colors.white
                          : undefined
                      }
                    >
                      {item.name}
                    </Text.Heading5>
                  </QuickButtonDropdown>
                </div>
              </Then>

              <Else>
                <div
                  key={item.name}
                  className="button-item"
                  onClick={
                    item.section && handleGranted(item.name)
                      ? () => openModal(item.section as Sections, item.name)
                      : () => {}
                  }
                >
                  <QuickButton
                    icon={item.icon}
                    backgroundColorButton={
                      handleChangeColorBySection(item.name, item.section)
                        ? colors.primary
                        : undefined
                    }
                    backgroundHoverButton={
                      handleChangeColorBySection(item.name, item.section)
                        ? colors.primaryDark
                        : undefined
                    }
                    disabled={!item.section || !handleGranted(item.name)}
                  >
                    <Text.Heading5
                      color={
                        handleChangeColorBySection(item.name, item.section)
                          ? colors.white
                          : undefined
                      }
                    >
                      {item.name}
                    </Text.Heading5>
                  </QuickButton>
                </div>
              </Else>
            </If>
          );
        })}
      </div>
    </Style.Container>
  );
};

export default QuickActions;
