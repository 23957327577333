import * as Style from './DisableTwoFa.styles';

import { SetCurrentStepProps, StepParamsProps } from './DisableTwoFa.types';
import { useEffect, useState } from 'react';

import AskPinDisableTwoFa from './ModalSteps/2.AskPinForDisable.screen';
import Dialog from '~components/Dialog';
import ModalMultiStep from '~components/ModalMultiStep';
import ShowAdviceDisableTwoFa from './ModalSteps/1.ShowAdvice.screen';
import SuccessDisableTwoFa from './ModalSteps/3.SuccessDisable.screen';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

function DisableTwoFaModals() {
  const { openModalMultiStep, setOpenModalMultiStep } =
    useActionsOnModalMultiStep();

  const [currentStep, setCurrentStep] =
    useState<SetCurrentStepProps>('showAdvice');
  const [stepParams, setStepParams] = useState<StepParamsProps>({});

  function setStepsAndParams(step: SetCurrentStepProps, params?: object) {
    if (params) {
      setStepParams((prev) => ({ prev, ...params } as StepParamsProps));
    }
    setCurrentStep(step);
  }

  const modalStepsAndDetails = {
    showAdvice: {
      component: (
        <ShowAdviceDisableTwoFa
          key="showAdviceDisableTwoFa"
          setStepsAndParams={setStepsAndParams}
        />
      ),
      progressValue: 33,
      modalHeight: 530,
    },
    askPinForDisable: {
      component: (
        <AskPinDisableTwoFa
          key="askPinDisableTwoFa"
          setStepsAndParams={setStepsAndParams}
        />
      ),
      progressValue: 66,
    },
    successDisable: {
      component: <SuccessDisableTwoFa key="successDisableTwoFa" />,
      progressValue: 100,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  // CLEAR STEP ON MODAL CLOSE
  useEffect(() => {
    if (!openModalMultiStep) setCurrentStep('showAdvice');
  }, [openModalMultiStep]);

  return (
    <Style.DisableTwoFaContainer>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={currentStepInfos.progressValue}
      >
        {currentStepInfos.component}
      </ModalMultiStep>
    </Style.DisableTwoFaContainer>
  );
}

export default DisableTwoFaModals;
