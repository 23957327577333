import { SuccessToast } from '~components/Toast';
import { UserPartnerResp } from '~reactQuery/types/business.types';

export const copyText = (copiedText: string, successText: string) => {
  navigator.clipboard.writeText(copiedText);

  SuccessToast({
    title: successText,
    autoCloseEnable: true,
  });
};

export const initFreshDeskChat = (userData: UserPartnerResp) => {
  window?.fcWidget?.setExternalId(userData?.capu);

  window?.fcWidget?.user.setFirstName(userData?.registerName);

  window?.fcWidget?.user.setEmail(userData?.email);

  window?.fcWidget?.user.setProperties({
    plan: 'Pro',
    status: 'Active',
  });
};
