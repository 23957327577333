import * as React from 'react';

import { SVGProps } from 'react';

const CheckSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 12}
    height={props.height || 10}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m11.56 1.958-.509.55-6 6.5-.52.565-.552-.534-3-2.9-.54-.521 1.043-1.079.54.522 2.447 2.366 5.48-5.936.509-.55 1.102 1.017Z"
      fill={props.fill || '#fff'}
    />
  </svg>
);

export default CheckSVG;
