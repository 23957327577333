import { colors } from '~styles';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { ContainerCheckProps } from './CheckSelect.types';

export const Container = styled.div<ContainerCheckProps>`
  border: 1px solid
    ${({ disabled }) => (disabled ? colors.grayRegular : colors.grayMedium)};
  border-radius: 8px;
  padding: 20px;
  height: auto;
  margin: 10px 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all ease-out 0.3s;
  display: flex;
  align-items: center;

  .scale-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    scale: 0.8;
  }

  ${({ disabled }) =>
    disabled
      ? ''
      : css`
          &:hover {
            background-color: ${colors.gray};
            transition: all ease-out 0.3s;

            .move-top {
              -webkit-transform: translateY(-2px);
              transform: translateY(-2px);
              transition: all ease-out 0.3s;
              height: 100%;
            }
          }
        `};
`;

export const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .right-text {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .wallet-righto-text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const ContainerText = styled.div`
  width: 100%;
  height: 100%;

  .text-wrapper {
    display: flex;
    flex-direction: column;
  }

  .container-title-and-chip {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  .chip-container {
    margin-left: 5px;
    border-radius: 19px;
    padding: 1px 10px 3px;
    gap: 10px;
    background-color: ${transparentize(0.9, colors.newBlue)};
  }
  .disabled {
    background-color: ${transparentize(0, colors.mainGray)};
  }
`;

export const ContainerIcon = styled.div<ContainerCheckProps>`
  margin-right: 15px;

  ${({ disabled, currencyCoin }) => {
    if (disabled) {
      return css`
        svg {
          path {
            fill: ${currencyCoin === 'BRL'
              ? colors.transparent
              : colors.grayLight} !important;
          }
        }
      `;
    }
    return css``;
  }};
`;
