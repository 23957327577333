import * as React from 'react';

import { SVGProps } from 'react';

const DownArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m7.25 9.19 3.72-3.72.53-.53L12.56 6l-.53.53-5 5-.53.53-.53-.53-5-4.999-.53-.53L1.5 4.94l.53.53L5.75 9.19V.25h1.5v8.94Z"
      fill="#272937"
    />
  </svg>
);

export default DownArrow;
