import * as Style from '../../Kyc.styles';
import * as Text from '~styles/text';

import {
  GenericButton,
  InputForm,
  InputPhone,
  InputSelect,
  InputSwitch,
} from '~components';
import {
  businessSize,
  declaredAnnualBillingOptions,
  legalNatureOptions,
} from '~data/kyc';
import {
  cellphoneMask,
  cepMask,
  cnaeMask,
  dateMask,
} from '~helpers/format/masks';
import { moneyMask, percentageRange } from '~helpers/format/currency';

import ArrowBackIcon from '~assets/images/icons/kyc/arrow-back.svg';
import { BusinessPartnerFormProps } from '../../Kyc.types';
import ContactIcon from '~assets/images/icons/contact.svg';
import CounterInput from '~components/Inputs/CounterInput';
import FileIcon from '~assets/images/icons/file.svg';
import { When } from 'react-if';
import { colors } from '~styles';
import { kycCompanySchema } from '~pages/Kyc/validation/kyc.schema';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { useEffect } from 'react';
import { usePartnersList } from '../../store';
import { useTranslation } from 'react-i18next';

export const BusinessPartnerForm = ({
  partnerFormMethods,
  gotToModalStep,
  setOpenModal,
}: BusinessPartnerFormProps) => {
  const { field: fieldArray } = usePartnersList();
  const { t } = useTranslation();

  const {
    setValue,
    watch,
    getValues,
    trigger,
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = partnerFormMethods;

  const field = Array.isArray(fieldArray) ? fieldArray[1] : fieldArray;

  const getIndex = onlyNumbers(String(field));

  const errorIncase =
    errors?.allPartners?.[getIndex?.[0]]?.list?.[getIndex?.[1]];

  const natureOptionsWithoutPartners = ['MEI', 'EI', 'EIRELI'];
  const DDIOptions = [{ value: '+55', label: 'Brasil (+55)' }];
  const countryOptions = [{ value: 'BR', label: 'Brasil' }];

  const nameInTitle = getValues(`${field}.businessName`);

  const setAddrValues = (obj: any) => {
    setValue(`${field}.state`, obj?.uf);
    setValue(`${field}.city`, obj?.localidade);
    setValue(`${field}.addressLine`, obj?.logradouro);
    setValue(`${field}.neighborhood`, obj?.bairro);
    trigger([
      `${field}.state`,
      `${field}.city`,
      `${field}.addressLine`,
      `${field}.neighborhood`,
      `${field}.state`,
      `${field}.buildingNumber`,
    ]);
  };

  const noPartners = natureOptionsWithoutPartners.includes(
    watch(`${field}.businessType`)
  );

  useEffect(() => {
    if (noPartners) {
      setValue(`${field}.isLegalRepresentative`, true);
      setValue(`${field}.numberOfPartners`, 1);
    }
  }, [watch(`${field}.businessType`)]);

  useEffect(() => {
    if (getValues(`${field}.zipCode`)?.length > 9) {
      trigger([
        `${field}.businessType`,
        `${field}.country`,
        `${field}.businessSize`,
        `${field}.countryCode`,
      ]);
      if (getValues(`${field}.city`)) return;
      try {
        fetch(
          `https://viacep.com.br/ws/${getValues(`${field}.zipCode`)?.replace(
            /[.-]/g,
            ''
          )}/json/`
        ).then((resp) => {
          resp.json().then(setAddrValues);
        });
      } catch (error) {
        //  console.log('Get cep infos-> ', error);
      }
    }
  }, [watch(`${field}.zipCode`)]);

  const saveBizPart = async (data: any) => {
    const partnerBizInCase = getValues(field);

    try {
      await kycCompanySchema.validate(partnerBizInCase, { abortEarly: false });

      setValue(field, {
        ...partnerBizInCase,
        isSaved: true,
        status: 'WAITINGTOSEND',
      });

      setOpenModal(false);

      clearErrors(field);
    } catch (err: any) {
      err?.inner?.forEach((e: any) => {
        setError(`${field}.${e.path}`, {
          type: 'required',
          message: e.message,
        });
      });
    }
  };

  return (
    <Style.PartnersTypeBusinessContainer>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => gotToModalStep('businessPartnerCnpj')}
      >
        <ArrowBackIcon />
      </div>

      <Text.Heading5 color={colors.grayMedium} marginTop={30}>
        {t('Adicionar Sócio Empresarial')}
      </Text.Heading5>

      <Text.Heading2Bold marginBottom={20}>
        {`Complete os campos abaixo com as informações de ${nameInTitle}.`}
      </Text.Heading2Bold>

      <Text.Heading5 marginBottom={20} color={colors.danger}>
        Antes de continuar, verifique se os dados inseridos são iguais aos dados
        de registro da sua empresa. Assim, a abertura da sua conta vai ser ainda
        mais rápida!
      </Text.Heading5>

      <Style.SubtitleWrapper marginBottom={40}>
        <div className="icon-wrapper">
          <FileIcon />
        </div>
        <Text.Heading4 marginLeft={10}>{t('Sobre a empresa')}</Text.Heading4>
      </Style.SubtitleWrapper>

      <Style.FormContainer>
        <InputForm
          {...register(`${field}.tradingName`)}
          error={errorIncase?.tradingName?.message}
          autoFocus
          label={t('Nome Fantasia')}
          marginTop={20}
          marginBottom={20}
        />

        <div className="input-group">
          <InputForm
            {...register(`${field}.businessCnae`)}
            error={errorIncase?.businessCnae?.message}
            label={t('CNAE')}
            helperText="Usar principal em caso de vários"
            maskFunction={cnaeMask}
            marginBottom={20}
          />

          <InputSelect
            {...register(`${field}.businessType`)}
            error={errorIncase?.businessType?.message}
            label={t('Natureza Jurídica')}
            options={legalNatureOptions}
          />

          <InputSelect
            {...register(`${field}.businessSize`)}
            error={errorIncase?.businessSize?.message}
            label={t('Porte da Empresa')}
            options={businessSize}
          />

          <InputSelect
            {...register(`${field}.declaredAnnualBilling`)}
            label={t('Faturamento Anual Declarado')}
            placeholder="Selecione"
            options={declaredAnnualBillingOptions}
            error={errorIncase?.declaredAnnualBilling?.message}
          />

          <InputForm
            {...register(`${field}.companyStartDate`)}
            error={errorIncase?.companyStartDate?.message}
            label={t('Data da Abertura')}
            placeholder="00/00/0000"
            helperText="A data que consta no cartão CNPJ"
            maskFunction={dateMask}
          />

          <InputForm
            {...register(`${field}.participationPercentage`)}
            label={t('Percentual de Participação')}
            placeholder="0,00 %"
            min={0}
            max={100}
            type="number"
            error={errorIncase?.participationPercentage?.message}
            maskFunction={percentageRange}
          />

          <When condition={!noPartners}>
            <CounterInput
              {...register(`${field}.numberOfPartners`)}
              error={errorIncase?.numberOfPartners?.message}
              label={t('Quantidade de Sócios')}
              hookFormSetValue={setValue}
              defaultValue={getValues(`${field}.numberOfPartners`)}
              fieldName={`${field}.numberOfPartners`}
            />
          </When>
        </div>

        <When condition={!noPartners}>
          <Style.Line marginTop={20} marginBottom={20} />
          <div className="toggle-content-wrapper">
            <Text.Heading5>
              {t('Eu sou o Representante Legal desta empresa')}
            </Text.Heading5>

            <InputSwitch
              {...register(`${field}.isLegalRepresentative`)}
              error={errorIncase?.isLegalRepresentative?.message}
              checked={getValues(`${field}.isLegalRepresentative`)}
            />
          </div>
          <Style.Line marginTop={20} />
        </When>

        <Style.SubtitleWrapper marginTop={30} marginBottom={30}>
          <div className="icon-wrapper">
            <ContactIcon />
          </div>
          <Text.Heading4 marginLeft={10}>{t('Contato')}</Text.Heading4>
        </Style.SubtitleWrapper>

        <div className="input-group">
          <InputSelect
            {...register(`${field}.countryCode`)}
            error={errorIncase?.countryCode?.message}
            label={t('Código do País')}
            options={DDIOptions}
          />

          <InputPhone
            hookForm={{
              ...register(`${field}.phone`),
            }}
            ddi={watch && watch(`${field}.countryCode`)}
            error={errorIncase?.phone?.message}
            label={t('Telefone')}
            placeholder="00 00000-0000"
            maskFunction={cellphoneMask}
          />
        </div>

        <InputForm
          {...register(`${field}.site`)}
          error={errorIncase?.site?.message}
          label={t('Site (opcional)')}
          marginTop={20}
          marginBottom={20}
        />

        <InputForm
          {...register(`${field}.businessEmail`)}
          error={errorIncase?.businessEmail?.message}
          label={t('E-mail Comercial da Empresa')}
        />

        <Style.SubtitleWrapper marginTop={30} marginBottom={30}>
          <div className="icon-wrapper">
            <FileIcon />
          </div>
          <Text.Heading4 marginLeft={10}>{t('Endereço')}</Text.Heading4>
        </Style.SubtitleWrapper>

        <div className="input-group">
          <InputSelect
            {...register(`${field}.country`)}
            error={errorIncase?.country?.message}
            label={t('País/Região')}
            placeholder="Selecione"
            options={countryOptions}
          />

          <InputForm
            {...register(`${field}.zipCode`)}
            error={errorIncase?.zipCode?.message}
            label={t('CEP')}
            placeholder="00.000-000"
            maskFunction={cepMask}
          />

          <InputForm
            {...register(`${field}.city`)}
            error={errorIncase?.city?.message}
            label={t('Cidade')}
          />

          <InputForm
            {...register(`${field}.state`)}
            error={errorIncase?.state?.message}
            label={t('Estado (Sigla)')}
            maxLength={2}
          />
        </div>

        <InputForm
          {...register(`${field}.addressLine`)}
          error={errorIncase?.addressLine?.message}
          label={t('Endereço')}
          marginTop={20}
          marginBottom={20}
        />

        <div className="input-group">
          <InputForm
            {...register(`${field}.buildingNumber`)}
            error={errorIncase?.buildingNumber?.message}
            label={t('Número')}
            maxLength={4}
          />

          <InputForm
            {...register(`${field}.neighborhood`)}
            error={errorIncase?.neighborhood?.message}
            label={t('Bairro')}
          />

          <InputForm
            {...register(`${field}.complement`)}
            error={errorIncase?.complement?.message}
            label={t('Complemento (opcional)')}
          />
        </div>
      </Style.FormContainer>

      <Text.Paragraph
        color={colors.black}
        marginTop={20}
        marginBottom={40}
        lineHeight={140}
        display="block"
      >
        {t(
          'Ao selecionar Concordar e Continuar abaixo, confirmo que li e concordo com os'
        )}{' '}
        <a
          href="https://capitual.com/legal/terms-of-use"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          {t('Termos de Uso')}
        </a>
        , {t('a')}{' '}
        <a
          href="https://capitual.com/legal/privacy-policy"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          {t('Política de Privacidade')}
        </a>
        , {t('a')}{' '}
        <a
          href="https://capitual.com/legal/kyc"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          {t('Política KYC')}
        </a>{' '}
        {t('e também a')}{' '}
        <a
          href="https://capitual.com/legal/prohibited-businesses"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          {t('Lista de Negociações Proibidas do Capitual')}
        </a>
        .
      </Text.Paragraph>

      <div className="footer">
        <div className="footer-btn">
          <GenericButton
            buttonType="secondary"
            width="100%"
            onClick={handleSubmit(saveBizPart)}
          >
            Próximo
          </GenericButton>
        </div>
      </div>
    </Style.PartnersTypeBusinessContainer>
  );
};
