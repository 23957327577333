import styled from 'styled-components';
import colors from '~styles/colors';
import { transparentize } from 'polished';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  
  width: 100%;
  padding: 24px;
  background-color: ${colors.whiteLight};
  border-radius: 8px;
  
  .header-card {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 16px;
  }

  .column-card {
    display: flex;
    flex-direction: column;

    width: 100%;
    border-bottom: 1px solid ${transparentize(0.95, colors.black)};

    .row-card {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 16px 0 20px 0;

      .row-left {
        display: flex;
        align-items: center;

        svg {
          margin-left: 8px;
        }
      }
      
      .row-right {
        display: flex;
        align-items: flex-end;

        .value {
          font-feature-settings: 'zero' on, 'ss02' on;
        }
      }
    }
  }
`;

export const ProgressContainer = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: ${transparentize(0.95, colors.black)};

  .bar {
    transition: width 0.3s ease-out;
    max-width: 100%;
    height: 4px;
    background-color: ${colors.black};
    margin-top: -2px;
  }
`;
