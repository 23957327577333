import * as Text from '~styles/text';

import { BigInput, ErrorToast } from '~components';
import { Else, If, Then } from 'react-if';
import { moneyMask } from '~helpers/format/currency';
import { useEffect, useState } from 'react';

import InputCurrency from '~components/Inputs/InputCurrency';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import QrcodeDecoder from 'qrcode-decoder';
import { ShowKeyExamplePageProps } from '../PayWithPix.types';
import { getSelectedUserOrganization } from '~helpers/device';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useDecodeQrCodePix } from '~reactQuery/queries/pix/useDecodeQrCodePix';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import QRCodeIcon from '~assets/svg/qrCode';
import QRCodeWithoutBorderIcon from '~assets/svg/qrCodeWithoutBorder';

function ShowKeyExample(props: ShowKeyExamplePageProps) {
  const [qrCodeInput, setQrCodeInput] = useState('');
  const [editedValue, setEditedValue] = useState<number | string>('');
  const [showEditValueModal, setShowEditValueModal] = useState(false);
  const { setCurrentStep, readerFromFile } = props;
  const { setSection, setParams, params } = useActionsOnModalMultiStep();

  const qr = new QrcodeDecoder();

  const [file, setFile] = useState<{ name: string; src: string | ArrayBuffer }>(
    {
      name: '',
      src: '',
    }
  );

  const userOrganizationId = getSelectedUserOrganization();
  const { data: partnerInfos } = useGetPartner();
  const userDocumentNumber = partnerInfos?.data.documentNumber;

  const { data: capAccountInfo } = useGetCapAccountInfo({
    userOrganizationId: Number(userOrganizationId),
  });

  const { isFetching: loadingValidate, refetch } = useDecodeQrCodePix(
    {
      documentNumber: String(userDocumentNumber),
      qrCode: qrCodeInput,
      userOrganizationId: Number(userOrganizationId),
    },
    qrCodeInput.length < 100
  );

  function goNext(validateResponse: any) {
    setCurrentStep('selectWallet', {
      billData: validateResponse,
      qrCode: qrCodeInput,
      editedValue,
    });
  }

  async function validateAndGoNext() {
    const response = await refetch();
    if (response.data?.isValid) {
      const value = response.data.data.key.payment.totalValue;

      if (value <= 0 && !editedValue) {
        setShowEditValueModal(true);
      } else if (
        value > 0 ||
        (editedValue &&
          Number(editedValue) <= Number(capAccountInfo?.available) / 100)
      ) {
        goNext(response.data);
      } else if (
        Number(editedValue) >
        Number(capAccountInfo?.available) / 100
      ) {
        ErrorToast({
          title: 'Saldo da CapConta insuficiente',
          subtitle: '',
          autoCloseEnable: true,
        });
      }
    }
  }

  let img1: HTMLImageElement | null = document.querySelector('#fileQr');

  useEffect(() => {
    if (img1) {
      validateAndGoNext();
    }
  }, [qrCodeInput, img1]);

  const handleDecodeAndGoNext = async () => {
    if (img1) {
      const code = await qr.decodeFromImage(img1);

      if (code) {
        setQrCodeInput(code.data);
      } else {
        setQrCodeInput('');

        setFile({
          name: '',
          src: '',
        });

        ErrorToast({
          title: 'Qr code inválido',
          subtitle: '',
          autoCloseEnable: true,
        });
      }
    }

    if (showEditValueModal && Number(editedValue) > 0) {
      validateAndGoNext();
    }
  };

  const handleChange = (selectedFile: any) => {
    const input = selectedFile.currentTarget;

    const reader = new FileReader();
    reader.onload = function () {
      const dataURL = reader.result;
      if (dataURL) setFile({ name: input.files[0].name, src: dataURL });
    };
    reader.readAsDataURL(input.files[0]);
  };

  const acceptFileTypes = 'image/jpg,image/jpeg,image/png';

  const getTitle = () => {
    if (showEditValueModal) {
      return 'Qual valor deseja transferir?';
    }
    if (readerFromFile) {
      return 'Insira o Pix QrCode';
    }
    return 'Insira o Pix copia e cola';
  };

  const getDescription = () => {
    if (showEditValueModal) {
      return (
        <p>
          O saldo disponível na <strong>CapConta</strong> é de{' '}
          <strong>{moneyMask(String(capAccountInfo?.available))}</strong>.
        </p>
      );
    }
    if (readerFromFile) {
      return 'Faça upload abaixo do seu QrCode';
    }
    return 'Digite abaixo seu código de identificação';
  };

  function handleBackButtonAction() {
    if (showEditValueModal || (readerFromFile && showEditValueModal)) {
      setShowEditValueModal(false);
      setQrCodeInput('');
      setFile({
        name: '',
        src: '',
      });
      img1 = null;
    } else if (readerFromFile) {
      // eslint-disable-next-line no-useless-return
      return;
    } else {
      setSection('payBills');
      setParams(params);
    }
  }

  const handleAttachPixQRCode = () => {
    setParams({ ...params, pixQrCode: true, isComingFrom: 'pixCopyPaste' });
  };

  const handleBackButtonWhenAttachPixQRCode = () => {
    if (params?.isComingFrom === 'pixCopyPaste') {
      setParams({ ...params, pixQrCode: false, isComingFrom: '' });
    }
  };

  return (
    <>
      <ModalMultiStepHeader
        title={getTitle()}
        description={getDescription()}
        backButtonAction={
          readerFromFile
            ? params?.isComingFrom && handleBackButtonWhenAttachPixQRCode
            : params?.isComingFrom === 'payBills' && handleBackButtonAction
        }
      />

      <If condition={!showEditValueModal}>
        <Then>
          <div className="content-container">
            <If condition={readerFromFile}>
              <Then>
                <input
                  className="insert-qr-code"
                  accept={acceptFileTypes}
                  type="file"
                  onChange={handleChange}
                />
                {file.name && (
                  <Text.Small marginTop={-25} marginLeft={15}>
                    {file.name}
                  </Text.Small>
                )}

                <img
                  style={{ display: 'none' }}
                  id="fileQr"
                  src={String(file.src)}
                  alt="your"
                />
              </Then>
              <Else>
                <BigInput
                  onChange={(e) => setQrCodeInput(e.target.value)}
                  className="big-input pix-code"
                  maskFunction={(value: string) => value}
                  maxLength={300}
                  rows={5}
                />
              </Else>
            </If>
          </div>
        </Then>

        <Else>
          <div className="content-container" style={{ marginTop: '30px' }}>
            <InputCurrency
              onChange={(e) =>
                setEditedValue(
                  String(e.target.value)
                    .replace(/\./g, '')
                    .replace(',', '.')
                    .replace('R$ ', '')
                )
              }
              placeholder="R$ 0,00"
            />
          </div>
        </Else>
      </If>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="1"
        singleButtonAlignment="flex-end"
        disableSingleButton={
          showEditValueModal
            ? !editedValue || Number(editedValue) === 0
            : qrCodeInput?.length < 100 && file.name.length === 0
        }
        singleButtonAction={
          readerFromFile ? handleDecodeAndGoNext : validateAndGoNext
        }
        singleButtonLoading={loadingValidate}
        doubleButtonOneAction={() => handleAttachPixQRCode()}
        doubleButtonTextOne={
          !readerFromFile && (
            <>
              <QRCodeWithoutBorderIcon width={20} height={20} />
              <Text.Heading5 marginLeft={8}>Escanear QR Code</Text.Heading5>
            </>
          )
        }
      />
    </>
  );
}

export default ShowKeyExample;
