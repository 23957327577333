import { colors } from '~styles';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 12px 25px 12px 30px;

  width: 100%;
  height: 75px;

  background: ${colors.white};
  border-bottom: 1px solid ${transparentize(0.9, colors.black)};

  .right-container {
    display: flex;

    .total-currency {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;

      .column {
        display: flex;
        flex-direction: column;

        margin-left: 15px;
      }
    }

    .vertical-bar {
      width: 1px;
      height: 45px;
      background-color: ${transparentize(0.8, colors.black)};
      margin-left: 25px;
      margin-right: 8px;
    }

    a {
      display: flex;
      align-items: center;

      width: 48px;
      height: 48px;
      padding: 0px 8px;
      margin: 0 3px;
      border-radius: 50%;
      transition: all ease-out 0.3s;
      background-color: ${colors.gray};

      svg {
        transition: all ease-out 0.3s;
      }

      &:hover {
        transition: all ease-out 0.3s;
        filter: brightness(0.9);

        svg {
          margin-top: -3px;
          transition: all ease-out 0.3s;
        }
      }
    }
  }
`;

export const NotifcationIconContainer = styled.a<{ hasUnreadNotification: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover {
    background-color: ${colors.gray};
    transition: all ease-out 0.3s;

    svg {
      margin-top: 0px !important;
      transition: all ease-out 0.3s;
    }
  }

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    background-color:  ${props => props.hasUnreadNotification ? colors.primary : 'transparent'};
    border-radius: 50%;
    top: 5px;
    right: 5px;
  }
`;
