import React, { SVGProps } from 'react';

const GearIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '12'}
      height={props.height || '12'}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 0V1.30888C7.50218 1.42819 8.19558 1.72406 8.7864 2.1527L9.71201 1.22709L10.7727 2.28775L9.8471 3.21332C10.2758 3.8042 10.5718 4.4977 10.6911 5.25H12V6.75H10.6911C10.5718 7.50231 10.2758 8.1958 9.8471 8.78668L10.7727 9.71225L9.71201 10.7729L8.7864 9.8473C8.19558 10.2759 7.50218 10.5718 6.75 10.6911V12H5.25V10.6911C4.49758 10.5718 3.80397 10.2758 3.21304 9.84689L2.28717 10.7728L1.22651 9.7121L2.15249 8.78611C1.72397 8.19536 1.42817 7.50206 1.30888 6.75H1.19209e-07L0 5.25H1.30888C1.4282 4.49776 1.72411 3.80431 2.1528 3.21346L1.22699 2.28765L2.28765 1.22699L3.21346 2.1528C3.80431 1.72411 4.49776 1.4282 5.25 1.30888V0H6.75ZM6 2.75C4.20507 2.75 2.75 4.20507 2.75 6C2.75 7.79493 4.20507 9.25 6 9.25C7.79493 9.25 9.25 7.79493 9.25 6C9.25 4.20507 7.79493 2.75 6 2.75Z"
        fill={props.fill || '#272937'}
      />
    </svg>
  );
};

export default GearIcon;
