import * as Text from '~styles/text';
import { GenericRow, RoundButtonIcon } from '~components';
import colors from '~styles/colors';
import CopySVG from '~assets/svg/copy';
import { formatCurrencyFn } from '~helpers/format/currency';
import dayjs from 'dayjs';
import {
  formatDataShowMonth,
  removeNumbersFromString,
} from '~validations/stringsValidate';
import { copyText } from '~helpers/messages';
import CapAccountSVG from '~assets/svg/capAccount';
import { getSelectedUserOrganization } from '~helpers/device';
import { cnpjMask, cpfMask } from '~helpers/format/masks';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import TransferSendIcon from '~assets/svg/statement/transferSend';
import TransferReceivedIcon from '~assets/svg/statement/transferReceived';
import { TransactionDetailsTypes } from '../../Activities.types';
import { Else, If, Then } from 'react-if';
import { formatCpfOrCnpj } from '~helpers/format/numbers';

const Transfer = ({
  transactionDetails,
}: {
  transactionDetails: TransactionDetailsTypes;
}) => {
  const userOrganizationId = getSelectedUserOrganization();
  const { data: userData } = useGetPartner();

  const { data: capAccountInfo } = useGetCapAccountInfo({
    userOrganizationId: Number(userOrganizationId),
  });

  let CAPB = null;

  userData?.data?.userOrganizations?.map((org) => {
    if (org?.organizations?.account === capAccountInfo?.account) {
      CAPB = org?.organizations?.walletsInternalCredentials?.userBusinessId;
      return CAPB;
    }
    return null;
  });

  return (
    <>
      <div className="detailsRow">
        <Text.Paragraph>Valor</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>
          {typeof transactionDetails?.data?.value === 'string' &&
            formatCurrencyFn(
              'BRL',
              String(Number(transactionDetails.data.value) / 100)
            )}
        </Text.Paragraph>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Tipo de transferência</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>
          {transactionDetails?.data?.method?.toUpperCase()}
        </Text.Paragraph>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Descrição</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>
          {transactionDetails?.data?.description || '----'}
        </Text.Paragraph>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Data e Horário</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>
          {formatDataShowMonth(transactionDetails?.timestamp)} •{' '}
          {dayjs(transactionDetails?.timestamp).format('H:mm')}
        </Text.Paragraph>
      </div>

      <If condition={transactionDetails.direction < 1}>
        <Then>
          <div className="detailsDivider">
            <div className="icon">
              <TransferReceivedIcon />
            </div>
            <Text.Paragraph>Origem</Text.Paragraph>
            <hr />
          </div>

          <div className="detailsRow">
            <Text.Paragraph>Nome</Text.Paragraph>
            <Text.Paragraph color={colors.grayMedium}>
              {removeNumbersFromString(String(capAccountInfo?.name))}
            </Text.Paragraph>
          </div>

          <div className="detailsRow">
            <Text.Paragraph>CAP-B</Text.Paragraph>
            <Text.Paragraph color={colors.grayMedium}>{CAPB}</Text.Paragraph>
          </div>

          <div className="detailsDivider">
            <div className="icon">
              <TransferSendIcon />
            </div>
            <Text.Paragraph>Destino</Text.Paragraph>
            <hr />
          </div>

          <div className="detailsRow">
            <Text.Paragraph>Nome</Text.Paragraph>
            <Text.Paragraph color={colors.grayMedium}>
              {removeNumbersFromString(
                transactionDetails?.data?.requisite?.key?.holderName
              ) ||
                removeNumbersFromString(
                  transactionDetails?.data?.requisite?.holderName
                )}
            </Text.Paragraph>
          </div>

          <div className="detailsRow">
            <Text.Paragraph>CPF/CNPJ</Text.Paragraph>
            <Text.Paragraph color={colors.grayMedium}>
              {formatCpfOrCnpj(
                transactionDetails?.data?.requisite?.key?.holderDocument
              )}
            </Text.Paragraph>
          </div>
        </Then>

        <Else>
          <div className="detailsDivider">
            <div className="icon">
              <CapAccountSVG width={20} height={20} />
            </div>
            <Text.Paragraph>Origem</Text.Paragraph>
            <hr />
          </div>

          <div className="detailsRow">
            <Text.Paragraph>Nome</Text.Paragraph>
            <div className="item-right">
              <Text.Paragraph color={colors.grayMedium}>
                {removeNumbersFromString(
                  transactionDetails?.data?.requisite?.key?.holderName
                )}
              </Text.Paragraph>
            </div>
          </div>

          <div className="detailsRow">
            <Text.Paragraph>CPF/CNPJ</Text.Paragraph>
            <Text.Paragraph color={colors.grayMedium}>
              {formatCpfOrCnpj(
                transactionDetails?.data?.requisite?.key?.holderDocument
              )}
            </Text.Paragraph>
          </div>
        </Else>
      </If>

      <GenericRow
        title="Protocolo"
        description={transactionDetails?.authCode}
        noHorizontalPadding
        leftContentIconType="none"
        rightComponent={
          <RoundButtonIcon
            buttonSize="icon16"
            onClick={() =>
              copyText(
                transactionDetails?.authCode,
                'Código de Protocolo copiado com sucesso!'
              )
            }
          >
            <CopySVG />
          </RoundButtonIcon>
        }
        rightContentType="component"
      />
    </>
  );
};

export default Transfer;
