import { transparentize } from 'polished';
import styled from 'styled-components';
import colors from '~styles/colors';
import { screen } from '~styles/metrics';

export const LimitsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .default{
   background-color: ${colors.mainGray};
  }

  .divider {
    border-width: 0.5px 0px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.05);
    margin: 24px 0;
  }

  .info-message {
    display: flex;
    flex-direction: row;
    gap: 11px;
    width: 100%;
    padding: 10px;
    background-color: ${transparentize(0.9, colors.grayLight)};
    border-radius: 10px;
    margin-top: 10px;

    svg {
      min-width:16px;
      min-height:16px;
    }
  }

  .warning-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 11px;
    width: 100%;
    padding: 10px;
    background-color: ${transparentize(0.9, colors.danger)};
    border-radius: 10px;
    margin-top: 10px;

    p {
  
    }
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const ContainerCards = styled.div`
  display: flex;

  margin-top: 24px;
  margin-bottom:24px;
  gap: 16px;
  max-width:calc(100vw - 153px);
  padding-bottom: 5px;

  @media ${screen.tablet} {
    flex-wrap: wrap;
    max-width:100%;
  }
`

export const Card = styled.div`
  min-width:311px;
  min-height: 136px;
  border: 1px solid rgba(39, 41, 55, 0.05);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 35px;

  .min420{
    min-width: 420px !important;
  }

  .card-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  .card-bottom{
    display: flex;
    flex-direction: column;
  }
`

export const ProgressContainer = styled.div`
  width: 100%;
  height: 6px;
  background-color: ${transparentize(0.7, colors.grayLight)};
  border-radius: 8px;
  margin-top:10px;
  .bar {
    transition: width 0.3s ease-out;
    max-width: 100%;
    height: 6px;
    background-color: ${colors.black};
    border-radius: 8px;
  }
`;

