import { SVGProps } from 'react';

const CopyPasteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 16}
    height={props.width || 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.75 1.25H6.72a.75.75 0 0 0 0 1.5h6.53v6.53a.75.75 0 0 0 1.5 0V1.25Zm-2.64 2.64H1.25v10.86h10.86V3.89Zm-1.5 1.5v7.86H2.75V5.39h7.86Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default CopyPasteIcon;
