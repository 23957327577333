import dayjs from 'dayjs';
import BellIcon from '~assets/svg/bell';
import InfoSVG from '~assets/svg/info';
import InfoAlertSVG from '~assets/svg/infoAlert';
import DocumentIcon from '~assets/svg/notifications/Document';
import TransferIcon from '~assets/svg/notifications/Transfer';
import BillSVG from '~assets/svg/statement/bill';
import WarningIconSVG from '~assets/svg/waningIcon';
import { formatCurrencyFn } from '~helpers/format/currency';
import { getRelativeDate } from '~helpers/format/date';
import { GetNotificationsResponse } from '~reactQuery/queries/notifications/useGetNotifications';
import colors from '~styles/colors';

export const notificationParser = (
  notifications: GetNotificationsResponse['getNotifications']['data']
) => {
  const getCategory = (key: string) => {
    const transferKeys = ['TED', 'PIX_KEY', 'PIX_MANUAL'];
    const documentKeys = ['STATEMENT'];
    const inviteUser = ['INVITED_USER', 'INVITED_USER_EXPIRED'];
    const campaignKeys = ['CAMPAIGN'];
    const infoKeys = ['INFO'];
    const warningKeys = ['WARNING'];
    const bankslipKeys = [
      'BANKSLIP_CREATED',
      'BANKSLIP_PAYMENT',
      'REMINDER_BILL_EMISSION',
    ];

    if (transferKeys.includes(key)) {
      return 'Transferências';
    }

    if (documentKeys.includes(key)) {
      return 'Documentos';
    }

    if (inviteUser.includes(key)) {
      return 'Usuários';
    }

    if (campaignKeys.includes(key)) {
      return 'Campanha';
    }

    if (infoKeys.includes(key)) {
      return 'Informação';
    }

    if (warningKeys.includes(key)) {
      return 'Aviso';
    }

    if (bankslipKeys.includes(key)) {
      return 'Boletos';
    }

    return 'Notificação';
  };

  const getIcon = (key: string, read: boolean) => {
    const isTransferKey = ['TED', 'PIX_KEY', 'PIX_MANUAL'].includes(key);
    const isDocumentKey = ['STATEMENT'].includes(key);
    const isCampaignKey = ['CAMPAIGN'].includes(key);
    const isInfoKey = ['INFO'].includes(key);
    const isWarningKey = ['WARNING'].includes(key);
    const isInviteUserKey = ['INVITED_USER', 'INVITED_USER_EXPIRED'].includes(
      key
    );
    const isBankslip = [
      'BANKSLIP_CREATED',
      'BANKSLIP_PAYMENT',
      'REMINDER_BILL_EMISSION',
    ].includes(key);

    if (isTransferKey) {
      return <TransferIcon color={read ? undefined : colors.primary} />;
    }

    if (isDocumentKey) {
      return <DocumentIcon color={read ? undefined : colors.primary} />;
    }

    if (isCampaignKey) {
      return <InfoAlertSVG color={read ? undefined : colors.primary} />;
    }

    if (isInfoKey) {
      return <InfoSVG color={read ? undefined : colors.primary} />;
    }

    if (isWarningKey) {
      return <WarningIconSVG color={read ? undefined : colors.primary} />;
    }
    if (isInviteUserKey) {
      return <BellIcon color={read ? undefined : colors.primary} />;
    }

    if (isBankslip) {
      return <BillSVG color={read ? undefined : colors.primary} />;
    }

    return <BellIcon color={read ? undefined : colors.primary} />;
  };

  const getLabel = (key: string) => {
    switch (key) {
      case 'TED':
      case 'PIX_KEY':
      case 'PIX_MANUAL':
        return 'Transferência Recebida';
      case 'STATEMENT':
        return 'Extrato detalhado disponível';
      case 'INVITED_USER':
        return 'Convite Enviado';
      case 'INVITED_USER_EXPIRED':
        return 'Convite Expirado';
      case 'CAMPAIGN':
        return 'Campanha Recebida';
      case 'INFO':
        return 'Informação';
      case 'WARNING':
        return 'Aviso Importante';
      case 'BANKSLIP_CREATED':
        return 'Boleto Criado';
      case 'BANKSLIP_PAYMENT':
        return 'Boleto Pago';
      case 'REMINDER_BILL_EMISSION':
        return 'Lembrete de Emissão de Boleto';
      default:
        return 'Notificação';
    }
  };

  const getDescription = (key: string, d: any, period: any) => {
    const isTransferKey = ['TED', 'PIX_KEY', 'PIX_MANUAL'].includes(key);
    const isDocumentKey = ['STATEMENT'].includes(key);

    if (isTransferKey) {
      const value = formatCurrencyFn('BRL', d?.amount?.value);
      const sender = d?.channel?.sender?.name || 'remetente desconhecido';

      return `Você recebeu uma transferência de ${value} de ${sender} e já está disponível na conta BRL.`;
    }

    if (isDocumentKey) {
      const from = dayjs(period?.startDate).format('DD [de] MMM, YYYY');
      const to = dayjs(period?.endDate).format('DD [de] MMM, YYYY');

      return `Seu relatório (${from} - ${to}) está pronto e disponível para download.`;
    }

    if (key === 'INVITED_USER') {
      return 'Você convidou um novo usuário.';
    }

    if (key === 'INVITED_USER_EXPIRED') {
      return 'O convite enviado expirou.';
    }

    if (key === 'CAMPAIGN') {
      return d?.message;
    }

    if (key === 'INFO') {
      return d?.message;
    }

    if (key === 'WARNING') {
      return d?.message;
    }

    if (key === 'BANKSLIP_CREATED') {
      return `Você criou um boleto no valor de ${formatCurrencyFn(
        d?.amount?.currency,
        d?.amount?.value
      )}, que vencerá em ${dayjs(d?.dueDate).format('DD/MM/YYYY')}.`;
    }

    if (key === 'BANKSLIP_PAYMENT') {
      return `Você pagou um boleto no valor de ${formatCurrencyFn(
        d?.amount?.currency,
        d?.amount?.value
      )} para ${d?.recipientFinal?.name}.`;
    }

    if (key === 'REMINDER_BILL_EMISSION') {
      return 'Você ainda não pagou um boleto.';
    }

    return '';
  };

  const onClickNotification = (key: string, d: any) => {
    const notificationType = getCategory(key);
    const authCode = d?.authenticationCode;

    if (notificationType === 'Transferências' && authCode) {
      return () => {
        const { origin } = window;
        window.open(
          `${origin}/dashboard/activities?authcode=${authCode}`,
          '_self'
        );
      };
    }

    return undefined;
  };

  const parsedData = notifications?.map((n) => {
    const parsedCreatedAt = getRelativeDate(
      n?.createdAt,
      'dddd, D MMMM [ás] HH:mm'
    );

    let data = '';
    try {
      if (typeof n?.data === 'string') {
        data = JSON.parse(n?.data);
      } else if (typeof n?.data === 'object') {
        data = n?.data;
      }
    } catch (e) {
      data = '';
    }

    const dataObj = {
      id: n?.id,
      read: n?.read || false,
      category: getCategory(n?.key),
      createdAt: parsedCreatedAt || new Date(),
      icon: getIcon(n?.key, n?.read),
      label: getLabel(n?.key),
      description: getDescription(n?.key, data, n?.period),
      onClickNotification: onClickNotification(n?.key, data),
    };

    return dataObj;
  });

  return parsedData.reverse();
};
