import styled from 'styled-components';
import colors from '~styles/colors';
import { screen } from '~styles/metrics';

export const ScrollContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  z-index: 9;
  gap: 15px;
  background: ${colors.white};
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: calc(100vw - 153px);

  @media ${screen.tablet} {
    overflow-x: scroll;
    transition: scroll ease-out 0.3s;
    max-width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const PixCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-width: 247px;
  height: 210px;

  border: 1px solid ${colors.blackOpacity10p};
  border-radius: 10px;
  padding: 12px 12px 25px 25px;
  margin-bottom: 15px;
`;

export const IconAndOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .key-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 44px;
    height: 44px;

    border-radius: 50%;
    margin-top: 13px;
    background: ${colors.blueOpacity10p};
  }

  .right-content {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
`;

export const NameAndValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

export const AddNewKey = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 247px;
  height: 210px;

  border: 1px solid ${colors.blackOpacity10p};
  border-radius: 10px;
  gap: 10px;
  margin-bottom: 15px;

  cursor: pointer;
  transition: all ease-out 0.3s;

  :hover {
    background: ${colors.gray};

    .plus-button {
      background: #e7e8ed;
    }
  }

  .plus-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;
    padding: 12px;
    border-radius: 50px;
    background: ${colors.gray};

    margin-bottom: 10px;
    transition: all ease-out 0.3s;

    svg {
      color: ${colors.black};
      transition: all ease-out 0.3s;
    }

    :hover {
      background: #e7e8ed;
    }
  }
`;
