import { request, useMutation } from '~reactQuery';

import { getSettingsId } from '~helpers/device';
import { gql } from 'graphql-request';
import { useQueryClient } from 'react-query';

interface NewWithdrawInput {
  amount: string;
  amountInCurrency?: string;
  comments?: string;
  currency?: string;
  deductFeeFromAmount?: boolean;
  destination: string;
  pin: string;
  simulate?: boolean;
  txData: object;
  type: string;
  userOrganizationId: number;
  wallet: string;
  token?: string;
}

interface NewWithdrawResponse {
  newWithdraw: string;
}

export function useNewWithdraw() {
  const queryClient = useQueryClient();
  const organization = getSettingsId();

  const mutation = gql`
    mutation NewWithdraw($params: NewWithdrawInput!) {
      newWithdraw(params: $params)
    }
  `;

  const fetch = async (params: NewWithdrawInput) => {
    const response = await request<NewWithdrawResponse>(mutation, {
      params: {
        ...params,
        settingsId: Number(organization),
      },
    });
    return response.newWithdraw;
  };

  return useMutation('newWithdraw', fetch, {
    onSettled: () => {
      queryClient.invalidateQueries(['getWallets']);
    },
  });
}
