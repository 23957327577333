import * as Style from '../SignUp.styles';
import * as Text from '~styles/text';
import { BigInput, FooterButton } from '~components';
import RegisterPartnerIcon from '~assets/images/kyc/register-partner.svg';
import { StepPropsWithMail } from '../SignUp.types';
import { cpfMask } from '~helpers/format/masks';
import { parseError } from '~reactQuery/index';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useGetCustomerDataEmail } from '~reactQuery/mutations/user/useGetCustomerDataEmail';

export const InformCPF = (props: StepPropsWithMail) => {
  const { goToStep } = props;

  const { mutateAsync: sendEmail, isLoading: loadingSendEmail } =
    useGetCustomerDataEmail();

  const navigate = useNavigate();
  const {
    register,
    setValue,
    trigger,
    getValues,
    clearErrors,
    formState: { errors }
  } = useFormContext();

  const baseURL = window.location.origin;
  const urlCallback = `${baseURL}/auth/sign-up`;

  const onSubmit = async () => {
    clearErrors();

    trigger('cpf').then(async (isValid) => {
      const cpf = getValues('cpf');
      try {
        if (isValid) {
          const resp = await sendEmail({
            documentNumber: cpf.replace(/\D/g, ''),
            urlCallback,
          });
          if (resp?.email) {
            goToStep('questionsAlertMsg');

            setValue('email', resp.email);
          } else {
            goToStep('userHasNoAccount');
          }
        }
      } catch (error: any) {
        if (parseError(error) === 'USER_ALREADY_REGISTERED') {
          setValue('cpf', '');
        }
        //   console.log('send mail error -->', error);
      };
    });
  };

  return (
    <Style.Container>
      <div className="main-icon">
        <RegisterPartnerIcon />
      </div>

      <Text.Heading1Medium marginBottom={30} fontSize={1.938} lineHeight={130}>
        Que bom ver você aqui!
      </Text.Heading1Medium>

      <Text.Heading4 marginBottom={10}>
        Oferecemos uma conta empresarial multifuncional e segura. Agora seu
        negócio vai ter todas as ferramentes financeiras para prosperar.
      </Text.Heading4>

      <Text.Heading5 marginBottom={30} lineHeight={140}>
        Para prosseguir, digite o número do seu CPF:
      </Text.Heading5>

      <BigInput
        {...register('cpf')}
        textAlign="center"
        placeHolder="000.000.000-00"
        error={errors.cpf?.message as string}
        maskFunction={cpfMask}
        onChange={() => clearErrors()}
      />

      <FooterButton
        onClickGenericButton={() => onSubmit()}
        onClickLeftButton={() => navigate('/auth/sign-in')}
        // disableGenericButton={!getValues('cpf') || getValues('cpf').length < 14}
        leftButtonContent="Voltar"
        genericButtonType="secondary"
        genericButtonContent="Avançar"
        loadingGenericButton={loadingSendEmail}
      />
    </Style.Container>
  );
};

export default InformCPF;
