import { screen } from '~styles/metrics';
import styled from 'styled-components';
import colors from '~styles/colors';

export const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;

  .default {
    background-color: ${colors.mainGray};
  }

  .container-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 278px;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;

  .input-search {
    width: 380px;
    background-color: transparent;
    input {
      background-color: transparent;
    }
    path {
      stroke: ${colors.black};
    }
  }

  @media (max-width: 630px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;

    .input-search {
      width: 100%;
    }
  }
`;
