import { colors } from '~styles';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const Transactions = styled.div`
  .transaction-header {
    display: flex;
    flex-direction: row;

    margin: 41px 0px 15px 0px;
    padding: 15px 35px;
    border-top: 1px solid ${transparentize(0.9, colors.black)};
    border-bottom: 1px solid ${transparentize(0.9, colors.black)};

    .item-41 {
      width: 41.5%;
    }

    .item-33 {
      width: 33%;
    }

    .item-24 {
      width: 23.5%;

      &.right {
        display: flex;
        justify-content: flex-end;

        padding-right: 0px;
      }
    }

    @media (min-width: 631px) and (max-width: 700px) {
      margin: 41px 5px 15px 5px;

      .item-41 {
        width: 51%;
      }

      .item-33 {
        display: none;

        &.destination {
          width: 30%;
          display: block;
        }
      }

      .item-24 {
        width: 30%;

        &.right {
          padding: 0;
        }
      }
    }

    @media (max-width: 630px) {
      margin: 41px 5px 15px 5px;

      .item-41 {
        width: 50%;
      }

      .item-33 {
        display: none;
      }

      .item-24 {
        width: 50%;

        &.right {
          padding: 0;
        }
      }
    }
  }
`;
