import * as Style from '../GuestRegister.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import { breakpoints } from '~styles/metrics';
import { InputPin } from '~components/Inputs';
import useWindowDimensions from '~hooks/windowDimension';
import { FooterButton } from '~components/Footers';
import SecuritySVG from '~assets/svg/security';
import { useChangeUserGuestMutation } from '~reactQuery/mutations/kyc/useChangeUserGuest';
import * as yup from 'yup';
import { CommonStepParams } from '../GuestRegister.types';
import { When } from 'react-if';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const CreatePin = ({ stepParams, goToStep }: CommonStepParams) => {
  const { width } = useWindowDimensions();

  const { mutateAsync: changeUserGuestData, isLoading: isLoadingChangeUser } =
    useChangeUserGuestMutation();

  const { partner } = stepParams;

  const schema = yup.object().shape({
    newPin: yup
      .string()
      .min(4, 'O PIN deve ter 4 dígitos')
      .required('Esse campo é obrigatório'),
    confirmPin: yup
      .string()
      .oneOf([yup.ref('newPin')], 'Os Pins não coincidem')
      .min(4, 'O PIN deve ter 4 dígitos')
      .required('Esse campo é obrigatório'),
  });

  const {
    setValue,
    formState: { errors },
    trigger,
    getValues,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      newPin: '',
      confirmPin: '',
    },
  });

  const onSubmit = async () => {
    trigger().then(async (isValid) => {
      const pin = getValues('newPin');

      if (!partner?.active) {
        goToStep('register2FA', {
          pin,
        });
      } else {
        try {
          if (isValid && stepParams.token) {
            if (partner) {
              await changeUserGuestData({
                registerName: partner?.registerName,
                documentNumber: partner?.documentNumber,
                email: partner?.email,
                phone: partner?.phone,
                socialName: partner?.registerName,
                token: stepParams.token,
                pin,
              });
            }

            goToStep('finish');
          }
        } catch (error) {
          // console.log(error);
        }
      }
    });
  };

  const goPrev = () => {
    if (!partner?.active) {
      goToStep('userForm');
    } else {
      goToStep('check');
    }
  };

  return (
    <Style.Container>
      <div className="main-icon">
        <SecuritySVG />
      </div>

      <Text.Heading1Medium marginTop={10} marginBottom={30}>
        Segurança em sua conta
      </Text.Heading1Medium>

      <Text.Heading5
        color={colors.black}
        marginBottom={30}
        lineHeight={140}
        width={breakpoints.desktop < width ? '100%' : ''}
      >
        Para aprimorar a segurança da sua conta, solicitamos que você crie
        alguns códigos que serão exigidos para realizar ações importantes na
        nossa plataforma. Para iniciarmos, crie um código PIN de 4 dígitos.
      </Text.Heading5>

      <Text.Heading5 marginBottom={10} marginTop={25}>
        Crie seu PIN
      </Text.Heading5>

      <InputPin
        size={4}
        secret
        onChange={(confirmPin) => setValue('newPin', confirmPin)}
        onFinish={(pin) => setValue('newPin', pin)}
        error={!!errors?.newPin?.message}
      />

      <When condition={!!errors?.newPin?.message}>
        <Text.Heading5 marginTop={5} color={colors.danger}>
          {errors?.newPin?.message}
        </Text.Heading5>
      </When>

      <Text.Heading5 marginBottom={10} marginTop={25}>
        Repetir PIN
      </Text.Heading5>

      <InputPin
        size={4}
        secret
        onChange={(confirmPin) => setValue('confirmPin', confirmPin)}
        onFinish={(confirmPin) => setValue('confirmPin', confirmPin)}
        error={!!errors?.confirmPin?.message}
      />

      <When condition={!!errors?.confirmPin?.message}>
        <Text.Heading5 marginTop={5} color={colors.danger}>
          {errors?.confirmPin?.message}
        </Text.Heading5>
      </When>

      <FooterButton
        onClickGenericButton={onSubmit}
        onClickLeftButton={goPrev}
        loadingGenericButton={isLoadingChangeUser}
        leftButtonContent="Voltar"
        genericButtonType="secondary"
        genericButtonContent="Confirmar novo PIN"
      />
    </Style.Container>
  );
};

export default CreatePin;
