import { BillModal } from '~pages/Dashboard/Bills/BillModal';
import DetailsActivitySideBarModal from '~pages/Dashboard/Activities/DetailsActivitySideBarModal/DetailsActivitySideBarModal.component';
import NotificationModal from '~pages/Notifications/Notifications.component';
import { ModalSideBarContents } from '~hooks/useOpenSideBar';
import { ReactNode } from 'react';
import { CapContaModal } from '~pages/Dashboard/CapAccountAndWallets/CapContaModal';
import WalletModal from '~pages/Dashboard/CapAccountAndWallets/WalletModal/WalletModal.component';
import { AreaPixSideBarModal } from '~pages/Dashboard/Activities/FilterActivitySideBarModal';

export const modalContent: Record<ModalSideBarContents, ReactNode | null> = {
  areaPix: <AreaPixSideBarModal />,
  capContaModal: <CapContaModal />,
  walletModal: <WalletModal />,
  billModal: <BillModal />,
  detailsActivityModal: <DetailsActivitySideBarModal />,
  notifications: <NotificationModal />,
  noContent: null,
};
