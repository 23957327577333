import * as Style from '../Kyc.styles';
import * as Text from '~styles/text';

import { Controller, useForm } from 'react-hook-form';
import { FooterButton, InputDocForm } from '~components';
import {
  KycCompanySchemaTypes,
  KycDocsSchemaTypes,
  SendDocsProps,
  DocsList,
} from '~pages/Kyc/Kyc.types';
import { defaultDocsValues, getRequiredDocs } from '~data/kyc';
import { formatFileName, getBase64 } from '~helpers/format/file';
import { useEffect, useState } from 'react';

import CompanyDataIcon from '~assets/images/icons/kyc/company-data.svg';
import { kycDocsSchema } from '../validation/kyc.schema';
import { useCreateBusinessDataMutation } from '~reactQuery/mutations/business/useCreateBusinessData';
import { useCreateIncomesMutation } from '~reactQuery/mutations/kyc/useCreateIncomes';
import { usePutBusinessDataMutation } from '~reactQuery/mutations/kyc/usePutBusinessData';
import { usePutIncomesMutation } from '~reactQuery/mutations/business/usePutIncomes';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

export const SendDocs = ({
  goToStep,
  stepParams,
  businessType,
  isCryptoTrader,
  processData,
}: SendDocsProps) => {
  const [requiredDocs, setRequiredDocs] = useState<any>([]);
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const bizInCase = 0;

  const {
    control,
    getValues,
    trigger,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultDocsValues,
    mode: 'onSubmit',
    resolver: yupResolver(kycDocsSchema(businessType)),
  });

  const { mutateAsync: createBusinessData, isLoading: isLoadingCreateBiz } =
    useCreateBusinessDataMutation();
  const { mutateAsync: updateBusinessData, isLoading: isLoadingUpdateBiz } =
    usePutBusinessDataMutation();
  const { mutateAsync: createIncomes, isLoading: isLoadingCreateIncomes } =
    useCreateIncomesMutation();
  const { mutateAsync: updateIncomes, isLoading: isLoadingUpdateIncomes } =
    usePutIncomesMutation();

  const dataFields = processData?.business?.[bizInCase]?.businessData?.fields;
  const fromStatusScreen = stepParams.statusScreen;

  const loadingSubmit =
    isLoadingCreateBiz ||
    isLoadingUpdateBiz ||
    isLoadingCreateIncomes ||
    isLoadingUpdateIncomes;

  const getFileInfo = (fieldName: string) => {
    return dataFields
      ?.filter((singleField) => String(singleField?.field) === fieldName)
      ?.map((fields) => fields)?.[0];
  };

  useEffect(() => {
    // SET DOCS NAME IF EXISTS
    const filesList = processData?.business?.[bizInCase]?.businessData;

    if (businessType)
      setRequiredDocs(getRequiredDocs(businessType, isCryptoTrader));

    if (filesList?.id) {
      const fileQSA = filesList?.fileQSA;
      const fileCNPJ = filesList?.fileCNPJ;
      const incomes = filesList?.incomes?.[0]?.income;
      const socialContract = filesList?.socialContract;
      const lastContractChange = filesList?.lastContractChange;
      const lastElection = filesList?.lastElection;
      const socialStatute = filesList?.socialStatute;
      const preventionPolicy = filesList?.preventionPolicy;
      const proofOfRegularity = filesList?.proofOfRegularity;
      const accountingBalance = filesList?.accountingBalance;

      if (fileQSA) setValue('fileQSA', formatFileName('QSA', fileQSA));
      if (fileCNPJ) setValue('fileCNPJ', formatFileName('CNPJ', fileCNPJ));
      if (incomes) setValue('incomes', formatFileName('Rendimento', incomes));

      if (isCryptoTrader) {
        if (preventionPolicy)
          setValue(
            'preventionPolicy',
            formatFileName('Política de Prevenção', preventionPolicy)
          );
        if (proofOfRegularity)
          setValue(
            'proofOfRegularity',
            formatFileName('Comprovante de regularidade', proofOfRegularity)
          );
        if (accountingBalance)
          setValue(
            'accountingBalance',
            formatFileName('Balanço contábil', accountingBalance)
          );
      }

      switch (businessType) {
        case 'LTDA':
          if (socialContract) {
            setValue(
              'socialContract',
              formatFileName('Contrato-Social', socialContract)
            );
          }

          if (lastContractChange) {
            setValue(
              'lastContractChange',
              formatFileName('Última-Alteração', lastContractChange)
            );
          }
          break;

        case 'SA':
          if (lastElection) {
            setValue(
              'lastElection',
              formatFileName('Última-Eleição', lastElection)
            );
          }

          if (socialStatute) {
            setValue(
              'socialStatute',
              formatFileName('Estatuto-Social', socialStatute)
            );
          }
          break;

        default:
      }
    }
  }, [processData, businessType]);

  const goBack = () => goToStep(fromStatusScreen ? 'status' : 'companyData');

  const onSubmit = async (
    filesDocs: (KycCompanySchemaTypes & KycDocsSchemaTypes) | any
  ) => {
    let businessDateValues = {} as any;
    let businessIncomeValue = {} as any;

    const isNotString = (file: File | undefined) => {
      if (!file) {
        return false;
      }
      return typeof file !== 'string';
    };

    if (isNotString(filesDocs.fileQSA)) {
      const fileQSA = (await getBase64(filesDocs.fileQSA[0])) as string;
      businessDateValues = {
        ...businessDateValues,
        fileQSA,
      };
    }

    if (isNotString(filesDocs.fileCNPJ)) {
      const fileCNPJ = (await getBase64(filesDocs.fileCNPJ[0])) as string;
      businessDateValues = {
        ...businessDateValues,
        fileCNPJ,
      };
    }

    if (isNotString(filesDocs.incomes)) {
      const incomes = await getBase64(filesDocs.incomes[0]);
      businessIncomeValue = {
        incomes: [String(incomes)],
        ...businessIncomeValue,
      };
    }

    if (isNotString(filesDocs.preventionPolicy)) {
      const preventionPolicy = (await getBase64(
        filesDocs.preventionPolicy[0]
      )) as string;
      businessDateValues = {
        ...businessDateValues,
        preventionPolicy,
      };
    }

    if (isNotString(filesDocs.proofOfRegularity)) {
      const proofOfRegularity = (await getBase64(
        filesDocs.proofOfRegularity[0]
      )) as string;
      businessDateValues = {
        ...businessDateValues,
        proofOfRegularity,
      };
    }

    if (isNotString(filesDocs.accountingBalance)) {
      const accountingBalance = (await getBase64(
        filesDocs.accountingBalance[0]
      )) as string;
      businessDateValues = {
        ...businessDateValues,
        accountingBalance,
      };
    }

    if (businessType === 'LTDA') {
      if (isNotString(filesDocs.socialContract)) {
        const socialContract = await getBase64(filesDocs.socialContract[0]);
        businessDateValues = {
          ...businessDateValues,
          socialContract,
        };
      }

      if (isNotString(filesDocs.lastContractChange)) {
        const lastContractChange = await getBase64(
          filesDocs.lastContractChange[0]
        );
        businessDateValues = {
          ...businessDateValues,
          lastContractChange,
        };
      }
    }

    if (businessType === 'SA') {
      if (isNotString(filesDocs.lastElection)) {
        const lastElection = await getBase64(filesDocs.lastElection[0]);
        businessDateValues = {
          ...businessDateValues,
          lastElection,
        };
      }

      if (isNotString(filesDocs.socialStatute)) {
        const socialStatute = await getBase64(filesDocs.socialStatute[0]);
        businessDateValues = {
          ...businessDateValues,
          socialStatute,
        };
      }
    }

    try {
      const processId = Number(processData?.id);
      const businessDataId = Number(
        processData?.business?.[bizInCase]?.businessData?.id
      );

      if (!businessDataId) {
        await createBusinessData({
          processId,
          ...businessDateValues,
        });

        await createIncomes({
          processId,
          ...businessIncomeValue,
        });

        await queryClient.invalidateQueries();
      } else {
        await updateBusinessData({
          businessDataId,
          ...businessDateValues,
        });

        if (String(businessIncomeValue?.incomes?.[0]).startsWith('data:')) {
          await updateIncomes({
            businessDataId,
            ...businessIncomeValue,
          });
        }
        await queryClient.invalidateQueries();
      }

      if (fromStatusScreen) {
        goToStep('status');
      } else {
        goToStep('registerPartners');
      }
    } catch (error: any) {
      //  console.log('Error on Send Business Docs ->', error);
    }
  };

  return (
    <>
      <div style={{ height: 48, width: 48 }}>
        <CompanyDataIcon style={{ borderRadius: 15 }} />
      </div>
      <Text.Heading1Medium marginTop={10} marginBottom={30}>
        {t('Documentos da Empresa')}
      </Text.Heading1Medium>

      <Text.Heading5 marginBottom={30} lineHeight={140}>
        {t(
          'Ótimo! Estamos ainda mais perto de concluir a sua solicitação. Insira abaixo os documentos solicitados, e lembre-se:'
        )}{' '}
        {t('é necessário que sejam as versões mais recentes de cada um.')}
      </Text.Heading5>

      <Style.FormContainer>
        {requiredDocs?.map((doc: { title: string; fieldName: DocsList }) => {
          return (
            <Controller
              key={doc.title}
              name={doc.fieldName}
              render={({ field }) => (
                <InputDocForm
                  {...field}
                  getValues={getValues}
                  setValue={setValue}
                  docTitle={t(doc.title)}
                  trigger={trigger}
                  status={getFileInfo(doc?.fieldName)?.status}
                  error={
                    getFileInfo(doc?.fieldName)?.description ||
                    errors?.[doc.fieldName]?.message
                  }
                />
              )}
              control={control}
            />
          );
        })}
      </Style.FormContainer>

      <FooterButton
        onClickGenericButton={handleSubmit(onSubmit)}
        leftButtonContent="Voltar"
        genericButtonContent="Continuar"
        onClickLeftButton={goBack}
        genericButtonType="secondary"
        loadingGenericButton={loadingSubmit}
      />
    </>
  );
};
