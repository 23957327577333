import {
  PixKeyRequisiteSchema,
  PixManualRequisite,
  TransferMethodPixEnum,
} from '~reactQuery/types/pix.types';
import { gql, request, useMutation } from '~reactQuery/index';

import { CAPACCOUNT_QUERY_KEYS } from '~src/constants/capaccount';
import { PIX_QUERY_TRANSFER } from '~src/constants/pix';
import { getSettingsId } from '~helpers/device';
import { useQueryClient } from 'react-query';

interface PixTransferUsingPixManualInput {
  userOrganizationId: number;
  value: number;
  description: string;
  method: TransferMethodPixEnum;
  settingsId: number;
  requisite: PixManualRequisite;
  pin: string;
  token?: string;
}

interface PixTransferUsingPixManualResponse {
  pixTransferUsingPixManual: {
    authCode: string;
    value: number;
    description: string;
    method: TransferMethodPixEnum;
    requisite: PixKeyRequisiteSchema;
  };
}

export const usePixTransferUsingPixManual = () => {
  const queryClient = useQueryClient();
  const organization = getSettingsId();

  const QUERY = gql`
    mutation PixTransferUsingPixManual($params: PixManualTransferInput!) {
      pixTransferUsingPixManual(params: $params) {
        authCode
        value
        description
        method
        requisite {
          keyType
          addressingKeyValue
          e2eId
        }
      }
    }
  `;

  const fetcher = async (params: PixTransferUsingPixManualInput) => {
    const response = await request<PixTransferUsingPixManualResponse>(QUERY, {
      params: {
        ...params,
        settingsId: Number(organization),
      },
    });
    return response.pixTransferUsingPixManual;
  };

  return useMutation([PIX_QUERY_TRANSFER.PIX_TRANSFER_MANUAL], fetcher, {
    onSettled: () => {
      queryClient.invalidateQueries([
        CAPACCOUNT_QUERY_KEYS.GET_CAPACCOUNT_INFO,
      ]);
    },
  });
};
