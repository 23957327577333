import { GenericButton } from '~components';
import useWindowDimensions from '~hooks/windowDimension';
import { When } from 'react-if';
import * as Style from './FooterButton.styles';
import { FooterButtonProps } from './FooterButton.types';

const FooterButton = (props: FooterButtonProps) => {
  const {
    onClickGenericButton,
    onClickLeftButton,
    leftButtonContent,
    genericButtonOnLeft,
    hasTopBorder,
    genericButtonType,
    genericButtonWidth,
    disableGenericButton,
    loadingGenericButton,
    genericButtonContent,
    genericButtonLeftIcon,
    hideGenericButton,
  } = props;
  const { width } = useWindowDimensions();
  return (
    <Style.FooterWrapper
      screenWidth={width}
      hasLeftButton={!!onClickLeftButton}
      hasTopBorder={!!hasTopBorder}
    >
      <div
        className={`buttons-wrapper
        ${genericButtonOnLeft ? 'generic-button-left' : ''}`}
      >
        <When condition={!!onClickLeftButton}>
          <button
            type="button"
            className="leftButton"
            onClick={onClickLeftButton}
          >
            {leftButtonContent}
          </button>
        </When>

        {!hideGenericButton && (
          <GenericButton
            buttonType={genericButtonType || 'primary'}
            width={genericButtonWidth || 'auto'}
            onClick={onClickGenericButton || (() => {})}
            disabled={disableGenericButton}
            buttonLoading={loadingGenericButton}
            leftIcon={genericButtonLeftIcon}
          >
            {genericButtonContent || 'Começar'}
          </GenericButton>
        )}
      </div>
    </Style.FooterWrapper>
  );
};

export default FooterButton;
