import * as Style from '../ChangePassword.styles';
import * as Text from '~styles/text';

import ChangePasswordSVG from '~assets/svg/changePassword';
import DialogBottom from '~components/Dialog/DialogBottom.component';
import React from 'react';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

const SuccessChangePassword: React.FC = () => {
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  return (
    <>
      <div className="change-password-body">
        <ChangePasswordSVG />
        <Text.Heading2 marginTop={25}>
          Oba! Senha alterada com sucesso!
        </Text.Heading2>
        <Text.Heading5 marginTop={20}>
          Sua senha de acesso foi alterada, agora você pode fazer login na sua
          conta com mais segurança!
        </Text.Heading5>
      </div>

      <DialogBottom
        singleButtonWidth="10%"
        singleButtonText="Concluir"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => setOpenModalMultiStep(false)}
      />
    </>
  );
};

export default SuccessChangePassword;
