import styled from 'styled-components';
import colors from '~styles/colors';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .circle {
    width: 56px;
    height: 56px;
    background-color: ${colors.gray};
    border-radius: 53px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
