import * as React from 'react';

import { SVGProps } from 'react';

const UsdcCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="M22.857 12.753c.097-1.095-.388-1.004-1.362-1.004-.68.183-.388 1.094-.388 1.545 0 .092-.298.092-.388.092a2.82 2.82 0 0 0-1.568.807c-.42.429-.69.98-.767 1.571-.194 2.829 2.724 2.465 4.764 3.194a1.226 1.226 0 0 1 .674.68 1.194 1.194 0 0 1-.033.95c-.018.036-.035.07-.056.106a2.349 2.349 0 0 1-3.173.316c-.12-.094-.23-.2-.328-.316-.196-.274-.098-.912-.681-.912-.387 0-1.361-.182-1.361.456.075.521.298 1.011.642 1.413.345.402.798.7 1.307.86 1.459.456.972 0 1.069 1.46 0 .548.778.456 1.263.456.876-.092.298-1.185.584-1.643a2.972 2.972 0 0 0 1.884-.865c.501-.504.8-1.17.84-1.874.053-.45-.04-.906-.267-1.3a2.146 2.146 0 0 0-.997-.891c-1.167-.548-2.53-.365-3.697-.912a1.111 1.111 0 0 1-.5-.719 1.087 1.087 0 0 1 .186-.853c.151-.206.366-.36.613-.435.971-.274 2.333-.092 2.625 1.002.096.183.778 0 1.166 0 1.07-.182-.298-1.916-.779-2.19-1.176-.632-1.37.19-1.274-.995h.002Zm9.14 5.29a9.194 9.194 0 0 0-1.125-4.342 9.348 9.348 0 0 0-3.055-3.32 11.401 11.401 0 0 0-2.82-1.37.398.398 0 0 0-.292.04.389.389 0 0 0-.182.23.356.356 0 0 0-.011.095c-.097 1.187 0 1.187 1.166 1.643a7.706 7.706 0 0 1 2.527 1.766 7.576 7.576 0 0 1 1.618 2.603 7.496 7.496 0 0 1-.349 5.973 8.052 8.052 0 0 1-4.573 3.892c-.584.184-.388.822-.388 1.277 0 .366.297.548.68.366a8.906 8.906 0 0 0 1.945-.822 9.166 9.166 0 0 0 3.559-3.35A8.993 8.993 0 0 0 32 18.045l-.002-.002Zm-12.544-8.21c.097-.366-.097-1.003-.583-.73a9.456 9.456 0 0 0-4.47 2.828 9.08 9.08 0 0 0-1.904 3.178 8.985 8.985 0 0 0 .578 7.206 9.136 9.136 0 0 0 2.386 2.844c.571.463 1.193.862 1.853 1.19.487.182 2.138 1.186 2.234.273.194-1.46-.487-1.185-1.556-1.826a7.764 7.764 0 0 1-2.948-2.648 7.618 7.618 0 0 1-1.235-3.739c-.1-1.324.165-2.65.766-3.838a7.425 7.425 0 0 1 2.646-2.915 11.437 11.437 0 0 1 1.848-.912c.486-.09.388-.548.388-.911"
      fill="#2775CA"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UsdcCoinSVG;
