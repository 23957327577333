import * as React from 'react';
import { SVGProps } from 'react';

const FavoriteOutlineSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00009 2.5C8.65331 2.5 9.23142 2.92273 9.42948 3.54519L10.3038 6.2931H13.0001C13.6409 6.2931 14.2109 6.70018 14.4189 7.3063C14.6269 7.91242 14.4268 8.58371 13.921 8.97713L11.6069 10.777L12.3783 13.6053C12.5502 14.2357 12.2943 14.9048 11.7457 15.2596C11.1971 15.6144 10.4819 15.5732 9.97756 15.1579L8.00009 13.5294L6.02262 15.1579C5.51828 15.5732 4.8031 15.6144 4.25448 15.2596C3.70586 14.9048 3.45 14.2357 3.62191 13.6053L4.39328 10.777L2.07918 8.97713C1.57336 8.58371 1.37332 7.91242 1.58128 7.3063C1.78925 6.70018 2.35928 6.2931 3.00009 6.2931H5.69637L6.5707 3.54519C6.76876 2.92273 7.34687 2.5 8.00009 2.5ZM6.79319 7.7931H5.44332H3.00009L4.92866 9.2931L6.10354 10.2069L5.75252 11.494L5.06906 14L7.07419 12.3487L8.00009 11.5862L8.92599 12.3487L10.9311 14L10.2477 11.494L9.89664 10.2069L11.0715 9.2931L13.0001 7.7931H10.5569H9.20699L8.78714 6.47358L8.00009 4L7.21304 6.47358L6.79319 7.7931Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default FavoriteOutlineSVG;
