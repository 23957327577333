import { colors } from '~styles';
import styled from 'styled-components';

export const ChangePasswordContainer = styled.div`
  .tip {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 20px;
  }

  .change-password-body {
    width: 100%;
    margin-top: 25px;
    padding: 0 38px;

    .justify-items-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
