const TransferReceivedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { fill = 'none', stroke = '#272937', height = 20, width = 18 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 18 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.00195V0.00195312H8V1.00195V3.00195V4.00195H10V3.00195V1.00195ZM6 2.00195V1.00195H4V2.00195V3.00195V4.00195H6V3.00195V2.00195ZM14 2.00195V1.00195H12V2.00195V3.00195V4.00195H14V3.00195V2.00195ZM1 6.50195C0.447715 6.50195 0 6.94967 0 7.50195V18.002C0 18.5542 0.447715 19.002 1 19.002H17C17.5523 19.002 18 18.5542 18 18.002V7.50195C18 6.94967 17.5523 6.50195 17 6.50195H1ZM2 17.002V8.50195H16V17.002H2ZM9 15.002C7.75736 15.002 6.75 13.9946 6.75 12.752C6.75 11.5093 7.75736 10.502 9 10.502C10.2426 10.502 11.25 11.5093 11.25 12.752C11.25 13.9946 10.2426 15.002 9 15.002Z"
        fill={stroke}
      />
    </svg>
  );
};

export default TransferReceivedIcon;
