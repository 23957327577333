import * as React from 'react';

import { SVGProps } from 'react';

const BuildingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '24'}
    height={props.height || '25'}
    viewBox="0 0 24 25"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3.01998H3V4.01998V19.02H2V21.02H3H4L12 21.02L13 21.02H14L20 21.02H21L22 21.02V19.02H21V8.01997V7.01997H20H16.5H14V4.01998V3.01998H13H4ZM12 8.01997V19.02H5V5.01998H12V7.01997V8.01997ZM19 9.01997V19.02L14 19.02V9.01997H16.5H19ZM6 9.01998H11V7.01998H6V9.01998ZM11 13.02H6V11.02H11V13.02ZM15 11.02V13.02H18V11.02H15ZM15 17.02V15.02H18V17.02H15ZM6 17.02H11V15.02H6V17.02Z"
      fill={props.fill || '#4757e4'}
    />
  </svg>
);

export default BuildingIcon;
