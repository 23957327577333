import * as Text from '~styles/text';
import colors from '~styles/colors';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ArrowLeftSVG from '~assets/svg/arrowLeft';
import InputCurrency from '~components/Inputs/InputCurrency/InputCurrency.component';
import { InputForm, InputSelect, InputSwitch } from '~components/Inputs';
import DropDownList from '~components/DropDownList/DropDownList.component';
import { useGetBrlBanks } from '~reactQuery/queries/bank/useGetBrlBank';
import { useMemo, useState, useEffect, ChangeEvent } from 'react';
import { accountsTypes } from '~data/bankUtils';
import { When } from 'react-if';
import { SavedListsProps } from '../SavedLists.types';

const InformationAddBeneficiary = ({
  setStepSavedLists,
  beneficiaryPerPix,
  setBeneficiaryPerPix,
  handleAddBeneficiary,
}: SavedListsProps) => {
  const [errorInput, setErrorInput] = useState({
    fullName: '',
    amount: '',
    bankIspb: '',
    accountType: '',
    transferType: '',
    agency: '',
    bankAccount: '',
  });

  const { data: brlBankList, isLoading: isLoadingBankList } = useGetBrlBanks();

  const parseBankList: Array<{
    value: string;
    label: string;
    bankCode?: string;
  }> = useMemo(() => {
    if (!brlBankList && isLoadingBankList)
      return [{ value: '', label: 'Carregando...' }];

    const mapBankList = (brlBankList || [])
      .map((bank) => ({
        value: bank.bankIspb,
        label: `${bank.bankName}`,
        bankCode: bank.bankCompe,
      }))
      .sort((a, b) => ((a.bankCode || '') < (b.bankCode || '') ? -1 : 1));

    return mapBankList;
  }, [brlBankList, isLoadingBankList]);

  const transferTypes = [
    { value: 'Pix Manual', label: 'Pix Manual' },
    { value: 'TED', label: 'TED' },
  ];

  const isNotValidToContinue =
    !beneficiaryPerPix?.fullName ||
    !beneficiaryPerPix?.amount ||
    beneficiaryPerPix?.amount === 'R$ 0,00' ||
    !beneficiaryPerPix?.bankIspb ||
    !beneficiaryPerPix?.accountType ||
    !beneficiaryPerPix?.transferType ||
    !beneficiaryPerPix?.agency ||
    !beneficiaryPerPix?.bankAccount;

  const handleNext = () => {
    setErrorInput({
      fullName: '',
      amount: '',
      bankIspb: '',
      accountType: '',
      transferType: '',
      agency: '',
      bankAccount: '',
    });

    let errors = {
      fullName: '',
      amount: '',
      bankIspb: '',
      accountType: '',
      transferType: '',
      agency: '',
      bankAccount: '',
    };

    if (!beneficiaryPerPix?.fullName) {
      errors = {
        ...errors,
        fullName: 'Um nome é necessário',
      };
    }
    if (!beneficiaryPerPix?.amount || beneficiaryPerPix?.amount === 'R$ 0,00') {
      errors = {
        ...errors,
        amount: 'Um valor é necessário',
      };
    }

    if (!beneficiaryPerPix?.bankIspb) {
      errors = {
        ...errors,
        bankIspb: 'Um banco é necessário',
      };
    }

    if (!beneficiaryPerPix?.accountType) {
      errors = {
        ...errors,
        accountType: 'Selecionar tipo da conta é necessário',
      };
    }

    if (!beneficiaryPerPix?.transferType) {
      errors = {
        ...errors,
        transferType: 'Selecionar tipo da transferência é necessário',
      };
    }

    if (!beneficiaryPerPix?.agency) {
      errors = {
        ...errors,
        agency: 'Uma agência é necessário',
      };
    }

    if (!beneficiaryPerPix?.bankAccount) {
      errors = {
        ...errors,
        bankAccount: 'Uma conta é necessário',
      };
    }

    setErrorInput(errors);

    if (isNotValidToContinue) {
      return;
    }

    handleAddBeneficiary({
      amount: beneficiaryPerPix?.amount,
      saveForNextTransfer: beneficiaryPerPix?.saveForNextTransfer,
    });
  };

  useEffect(() => {
    const object = {
      ...beneficiaryPerPix,
      transferType: '',
      accountType: '',
    };

    setBeneficiaryPerPix(object);
  }, []);

  return (
    <div>
      <div
        className="go-back-button-edit-beneficiary"
        onClick={() => setStepSavedLists('editList')}
      >
        <ArrowLeftSVG fill={colors.grayMedium} />
        <Text.Heading2Bold style={{ fontFeatureSettings: '"ss02", "zero"' }}>
          Transferindo para {beneficiaryPerPix?.documentNumber}
        </Text.Heading2Bold>
      </div>

      <InputCurrency
        value={beneficiaryPerPix?.amount || 'R$ 0,00'}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const object = {
            ...beneficiaryPerPix,
            amount: event.target.value,
          };

          setBeneficiaryPerPix(object);
        }}
        error={errorInput?.amount}
        className="input-value"
      />

      <Text.Heading5Bold marginTop={20}>
        Nome completo do beneficiário
      </Text.Heading5Bold>
      <InputForm
        value={beneficiaryPerPix?.fullName}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const object = {
            ...beneficiaryPerPix,
            fullName: event.target.value,
          };

          setBeneficiaryPerPix(object);
        }}
        error={errorInput?.fullName}
      />

      <When condition={!beneficiaryPerPix?.e2eId}>
        <div className="container-input">
          <Text.Heading5Bold marginTop={25}>
            Banco ou instituição Financeira
          </Text.Heading5Bold>

          <DropDownList
            list={parseBankList}
            selected={beneficiaryPerPix?.bankIspb || ''}
            setSelected={(event: string) => {
              const object = {
                ...beneficiaryPerPix,
                bank: parseBankList?.filter(
                  (b: { value: string; label: string; bankCode?: string }) =>
                    b?.value === event
                )?.[0]?.bankCode,
                bankIspb: parseBankList?.filter(
                  (b: { value: string; label: string; bankCode?: string }) =>
                    b?.value === event
                )?.[0]?.value,
              };

              setBeneficiaryPerPix(object);
            }}
            inputLabel="Selecionar Banco"
            label="Bancos"
          />
        </div>
      </When>

      <When condition={!beneficiaryPerPix?.e2eId}>
        <div className="row">
          <div className="item">
            <Text.Heading5Bold>Tipo de conta</Text.Heading5Bold>

            <InputSelect
              defaultValue=""
              placeholder="Selecionar"
              className="input-select"
              options={accountsTypes}
              value={beneficiaryPerPix?.accountType}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const object = {
                  ...beneficiaryPerPix,
                  accountType: event.target.value,
                };

                setBeneficiaryPerPix(object);
              }}
              error={errorInput?.accountType}
            />
          </div>
          <div className="item">
            <Text.Heading5Bold>Tipo de transferência</Text.Heading5Bold>
            <InputSelect
              defaultValue=""
              options={transferTypes}
              className="input-select"
              value={beneficiaryPerPix?.transferType}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const object = {
                  ...beneficiaryPerPix,
                  transferType: event.target.value,
                };

                setBeneficiaryPerPix(object);
              }}
              error={errorInput?.transferType}
            />
          </div>
        </div>
      </When>

      <When condition={!beneficiaryPerPix?.e2eId}>
        <div className="row">
          <div className="item">
            <Text.Heading5Bold>Agência</Text.Heading5Bold>
            <InputForm
              placeholder="1234"
              value={beneficiaryPerPix?.agency}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const object = {
                  ...beneficiaryPerPix,
                  agency: event.target.value,
                };

                setBeneficiaryPerPix(object);
              }}
              error={errorInput?.agency}
            />
          </div>
          <div className="item">
            <Text.Heading5Bold>Conta com dígito</Text.Heading5Bold>
            <InputForm
              placeholder="12345678-9"
              value={beneficiaryPerPix?.bankAccount}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const object = {
                  ...beneficiaryPerPix,
                  bankAccount: event.target.value,
                };

                setBeneficiaryPerPix(object);
              }}
              error={errorInput?.bankAccount}
            />
          </div>
        </div>
      </When>

      <div className="row-switch">
        <Text.Heading5Bold>
          Salvar contato para próximas transações
        </Text.Heading5Bold>
        <InputSwitch
          className="input-switch"
          checked={beneficiaryPerPix?.saveForNextTransfer}
          onChange={() => {
            const object = {
              ...beneficiaryPerPix,
              saveForNextTransfer: !beneficiaryPerPix?.saveForNextTransfer,
            };

            setBeneficiaryPerPix(object);
          }}
        />
      </div>

      <ModalMultiStepBottom
        singleButtonText="Confirmar e voltar"
        singleButtonWidth="190px"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => handleNext()}
        className="multi-step-bottom"
      />
    </div>
  );
};

export default InformationAddBeneficiary;
