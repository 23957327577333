import styled, { css } from 'styled-components';

import { StyleProps } from './GenericRow.types';
import colors from '~styles/colors';
import { transparentize } from 'polished';

export const Container = styled.div<StyleProps>`
  width: 100%;
  margin-top: ${(props) => props.marginTop || 0}px;
  border-top: ${(props) => (props.noBorderTop ? '' : '1px solid #00000015')};
  display: flex;
  align-items: ${(props) => props.alignItems ?? 'center'};
  transition: background-color 0.3s ease;

  cursor: ${({ enableOnClick }) => (enableOnClick ? 'pointer' : 'default')};

  ${({ enableOnClick }) => {
    if (enableOnClick) {
      return css`
        &:hover {
          transition: background-color 0.3s ease;
          background-color: ${transparentize(0.95, colors.grayRegular)};
        }
      `;
    }
    return css``;
  }}

  padding-left: 10px;
  padding-right: 10px;

  ${({ leftContentType }) => {
    if (leftContentType === 'userPic') {
      return css`
        padding-top: 10px;
        padding-bottom: 10px;
      `;
    }
    return css`
      padding-top: 18px;
      padding-bottom: 18px;
    `;
  }}

  justify-content: space-around;
  padding: ${({ padding }) => padding || ''};

  ${({ noHorizontalPadding }) =>
    noHorizontalPadding &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}

  .left-content {
    ${({ leftContentType }) => {
      if (leftContentType === 'icon') {
        return css`
          height: 100%;
          display: flex;
          align-items: center;
          margin-right: 10px;
        `;
      }

      if (leftContentType === 'none') {
        return css`
          margin-right: 0px;
        `;
      }

      return css`
        margin-right: 10px;
      `;
    }}
  }

  .center-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    word-break: break-word;
    overflow-wrap: break-word;
  }

  .second-right-content {
    margin-right: 15px;
  }

  .right-content {
    ${({ rightContentType }) => {
      if (rightContentType === 'arrow') {
        return css`
          width: 16px;
          height: 16px;
        `;
      }

      if (rightContentType === 'component') {
        return css`
          width: auto;
          height: auto;
        `;
      }

      if (rightContentType === 'menu') {
        return css`
          width: 36px;
          height: 36px;
          cursor: pointer;
        `;
      }

      return css`
        white-space: nowrap;
      `;
    }}

    cursor:pointer;
  }
`;
