import { clearStorage } from '~helpers/device';
import i18n from '../../i18n';

export const formatErrorMessage = (type: string) => {
  const { t } = i18n;

  const redirectToStart = () => {
    window.location.href = '/auth';
    window.location.reload();
  };

  const ErrorConstants: Record<
    string,
    {
      title: string;
      description: string;
      action?: () => void;
    }
  > = {
    USER_ALREADY_HAVE_INITIATE_A_PROCESS: {
      title: t('Processo já iniciado'),
      description: '',
    },
    PROCESS_NOT_INITIALIZED_FOR_THIS_USER: {
      title: t('Processo não iniciado para este usuário'),
      description: '',
    },
    INVALID_PROCESSID_OR_NOT_EXIST: {
      title: t('Processo inválido ou não existe'),
      description: '',
    },
    PROCESS_ALREADY_INITIALIZED_FOR_THIS_USER: {
      title: t('Processo já inicializado para este usuário'),
      description: '',
    },
    PROCESS_ALREADY_HAVE_BUSINESS: {
      title: t('Processo já têm um negócio'),
      description: '',
    },
    BUSINESS_PROCESS_NOT_INITIALIZED: {
      title: t('Processo de negócios não inicializado'),
      description: '',
    },
    INVALID_BUSINESS_NAME: {
      title: t('Nome da empresa inválida'),
      description: '',
    },
    INVALID_TRADING_NAME: {
      title: t('Nome fantasia inválido'),
      description: '',
    },
    INVALID_BUSINESS_TYPE: {
      title: t('Tipo de negócio inválido'),
      description: '',
    },
    INVALID_BUSINESS_SIZE: {
      title: t('Tamanho de negócio inválido'),
      description: '',
    },
    PARTNER_ALREADY_EXISTS: {
      title: t('O parceiro já existe'),
      description: '',
    },
    BUSINESS_TYPE_MEI_ALLOWS_ONE_PARTNER_ONLY: {
      title: t('O tipo de negócio MEI permite apenas um parceiro'),
      description: '',
    },
    PARTNER_DOES_NOT_EXISTS: {
      title: t('Parceiro não existe'),
      description: '',
    },
    PARTNER_DOES_NOT_EXISTS_OR_INVALID: {
      title: t('Parceiro não existe ou inválido'),
      description: '',
    },
    PARTNER_DATA_PROCESS_NOT_INITIALIZED: {
      title: t('Processo de dados do parceiro não inicializado'),
      description: '',
    },
    INVALID_REGISTER_NAME: {
      title: t('Nome de registro inválido'),
      description: '',
    },
    INVALID_MOTHER_NAME: {
      title: t('Nome da mãe inválido'),
      description: '',
    },
    BUSINESS_DATA_NOT_INITIALIZED_FOR_THIS_USER: {
      title: t('Dados comerciais não inicializados para este usuário'),
      description: '',
    },
    BUSINESS_DATA_ALREADY_INITIALIZED_FOR_THIS_USER: {
      title: t('Dados comerciais já inicializados para este usuário'),
      description: '',
    },
    BUSINESS_DATA_PROCESS_NOT_INITIALIZED: {
      title: t('Processo de dados comerciais não inicializado'),
      description: '',
    },
    BUSINESS_DATA_DOES_NOT_EXISTS: {
      title: t('Dados comerciais não existem'),
      description: '',
    },
    INCOMES_NOT_INITIALIZED_FOR_THIS_USER: {
      title: t('Rendimentos não inicializados para este usuário'),
      description: '',
    },
    INCOMES_ALREADY_INITIALIZED_FOR_THIS_USER: {
      title: t('Renda já inicializada para este usuário'),
      description: '',
    },
    INVALID_FIELD_OR_STEP_ID: {
      title: t('ID do campo ou do step inválido'),
      description: '',
    },
    INVALID_DATE: {
      title: t('Data inválida'),
      description: '',
    },
    INVALID_TOKEN: {
      title: t('Token inválido'),
      description: '',
      action: () => {
        clearStorage();
        redirectToStart();
      },
    },
    USER_NOT_FOUND: {
      title: t('Usuário não encontrado'),
      description: '',
    },
    FAILED_WHEN_UPLOAD_TO_BUCKET: {
      title: t('Falha no upload'),
      description: '',
    },
    LEGAL_REPRESENTATIVE_NOT_DEFINED: {
      title: t('Representante legal não definido'),
      description: '',
    },
    ERROR_CREATING_SEND_DATA: {
      title: t('Erro ao criar dados de envio'),
      description: '',
    },
    DOCUMENT_NUMBER_NOT_FOUND: {
      title: t('Número do documento não encontrado'),
      description: '',
    },
    BUSINESS_CANCEL_FAILED: {
      title: t('Falha no cancelamento do negócio'),
      description: '',
    },
    DATAVALID_TOKEN_NOT_FOUND: {
      title: t('Token de validação não encontrado'),
      description: '',
    },
    NO_TOKEN_PROVIDED: {
      title: t('Token não informado'),
      description: '',
    },
    INVALID_CREDENTIAL: {
      title: t('Credencial inválida'),
      description: '',
    },
    USERID_NOT_FOUND: {
      title: t('ID do usuário não encontrado'),
      description: '',
    },
    PASSWORD_NOT_PROVIDED: {
      title: t('Senha não fornecida'),
      description: '',
    },
    INVALID_PASSWORD: {
      title: t('Senha inválida'),
      description: '',
    },
    INACTIVE_USER: {
      title: t('Usuário inativo'),
      description: '',
    },
    NON_EXISTING_CAPU: {
      title: t('CAPU não existente'),
      description: '',
    },
    INACTIVE: {
      title: t('Inativo'),
      description: '',
    },
    INVALID_PIN: {
      title: t('PIN inválido'),
      description: '',
    },
    REQUIRED_PIN: {
      title: t('PIN necessário'),
      description: '',
    },
    PIN_NEEDED: {
      title: t('PIN necessário'),
      description: '',
    },
    USER_ALREADY_REGISTERED: {
      title: t('Usuário já registrado'),
      description: '',
    },
    CAPU_NOT_PROVIDED: {
      title: t('CAPU não fornecido'),
      description: '',
    },
    COULD_NOT_CREATE_USER_IN_OLD_BUSINESS: {
      title: t('Não pode criar usuário no antigo negócio'),
      description: '',
    },
    THE_NEW_PASSWORD_MUST_BE_DIFFERENT_FROM_THE_OLD: {
      title: t('A nova senha deve ser diferente da antiga'),
      description: '',
    },
    PASSWORD_IS_THE_SAME: {
      title: t('A nova senha deve ser diferente da antiga'),
      description: '',
    },
    THE_NEW_PIN_MUST_BE_DIFFERENT_FROM_THE_OLD: {
      title: t('O novo pin deve ser diferente do antigo'),
      description: '',
    },
    UNKNOWN_USER: {
      title: t('Não foi possível localizar o usuário'),
      description: 'Algum dado está incorreto',
    },
    INVALID_DOCUMENT_NUMBER: {
      title: t('Número do documento inválido'),
      description: '',
    },
    INVALID_QUANTITY_OF_IDENTIFIERS: {
      title: t('Quantidade inválida de identificadores'),
      description: '',
    },
    THE_CURRENT_PASSWORD_PROVIDED_DOES_NOT_MATCH: {
      title: t('A senha atual fornecida não corresponde'),
      description: '',
    },
    NO_AUTHORIZATION: {
      title: t('Sem autorização'),
      description: '',
    },
    MAX_ATTEMPTS_REACHED: {
      title: t('Máximo de tentativas alcançadas'),
      description: '',
    },
    PASSWORDS_PROVIDED_MUST_BE_DIFFERENT: {
      title: t('As senhas fornecidas devem ser diferentes'),
      description: '',
    },
    DATA_CHANGE_ERROR: {
      title: t('Erro de mudança de dados'),
      description: '',
    },
    PARTNER_NOT_FOUND: {
      title: t('Parceiro não encontrado'),
      description: '',
    },
    PARTNER_ID_NOT_PROVIDED: {
      title: t('ID do parceiro não fornecido'),
      description: '',
    },
    PARTNER_STATUS_NOT_PROVIDED: {
      title: t('Status de parceiro não fornecido'),
      description: '',
    },
    ORGANIZATION_ID_NOT_PROVIDED: {
      title: t('ID da organização não fornecida'),
      description: '',
    },
    USER_NOT_VALID_OR_INEXISTENT: {
      title: t('E-mail incorreto'),
      description: t('Clique em “Não tenho conta” para se registrar!'),
    },
    BUSINESS_DONT_EXISTS: {
      title: t('Empresa não encontrada'),
      description: '',
    },
    INVALID_RELATION_WITH_ORG_X_USER: {
      title: t('Relação invalida entre usuário e organização'),
      description: '',
      action: () => {
        clearStorage();
        redirectToStart();
      },
    },
    INVALID_USERORGANIZATIONID: {
      title: t('Id de organização inválida'),
      description: '',
    },
    PIN_NOT_PROVIDED: {
      title: t('Pin não informado'),
      description: '',
    },
    THE_CURRENT_PIN_PROVIDED_DOES_NOT_MATCH: {
      title: t('Pin inválido'),
      description: '',
    },
    INVALID_DOCUMENT_NUMBER_DOESNT_MATCH: {
      title: t('O CPF informado não corresponde a esse usuário'),
      description: '',
    },
    CURRENT_USER_BLOCKED: {
      title: t('Usuário Inativo'),
      description: 'Para mais informações, entre em contato com o suporte',
    },
    UnderLimit: {
      title: t('Valor do TED abaixo do Mínimo'),
      description: '',
    },
    ERROR_VALIDATE_BILL: {
      title: t('Error ao validar Boleto'),
      description: '',
    },
    ERROR_CONFIRM_BILL: {
      title: t('Error ao pagar Boleto'),
      description: '',
    },
    INVALID_PIX_KEY: {
      title: t('Chave Pix Inválida'),
      description: '',
    },
    NOT_FOUND_TXES: {
      title: t('Período sem Transações'),
      description: '',
    },
    'jwt expired': {
      title: t('Sessão expirada'),
      description: '',
      action: () => {
        clearStorage();
        redirectToStart();
      },
    },
    'invalid signature': {
      title: t('Sessão expirada'),
      description: '',
      action: () => {
        clearStorage();
        redirectToStart();
      },
    },
    INVALID_2FA_TOKEN: {
      title: t('2-FA Inválido'),
      description: '',
    },
    REQUIRED_2FA_TOKEN: {
      title: t('Insira o 2-FA'),
      description: '',
    },
    ALREADY_MADE_FIRST_LOGIN_CONTROL: {
      title: t('Primeiro acesso já realizado'),
      description: '',
    },
    INVALID_2FA_RESCUE_CODE: {
      title: t('Código de recuperação inválido'),
      description: '',
    },
    QuotaEnded: {
      title: t('Os seus limites de cotas foram excedidos.'),
      description: '',
    },
    QuotaQtyEnded: {
      title: t(
        'O valor desejado excede os limites de sua conta para essa operação.'
      ),
      description: '',
    },
    NetworkError: {
      title: t('Encontramos um problema ao tentar acessar a sua internet.'),
      description: 'Verifique a sua conexão e em seguida tente novamente.',
    },
    NOT_FOUND_QR_CODE: {
      title: t('Código Inválido'),
      description: '',
    },
    PREVIOUS_STEP_MISSING: {
      title: 'Tempo de validação expirado',
      description: '',
    },
    INVALID_PARAMETERS: {
      title: 'Os dados preenchidos estão incorretos',
      description: '',
    },
    TRANSACTION_NOT_AUTHORIZED: {
      title: 'Sem permissão para esta transação',
      description: '',
    },
    INSUFFICIENT_BALANCE: {
      title: 'Saldo insuficiente',
      description: '',
    },
    SENDER_ACCOUNT_STATUS_NOT_ALLOW_CASH_OUT: {
      title: 'Sem permissão para esta transação',
      description: '',
    },
    INVALID_RECIPIENT_ACCOUNT: {
      title: 'Conta de destino inválida',
      description: '',
    },
    SENDER_ACCOUNT_NOT_FOUND: {
      title:
        'Conta do pagador não encontrada para a realização da transferência',
      description: '',
    },
    RECIPIENT_ACCOUNT_NOT_FOUND: {
      title: 'Conta não encontrada',
      description: '',
    },
    CASH_OUT_NOT_ALLOWED_OUT_OF_BUSINESS_PERIOD: {
      title: 'Transação fora de horário comercial',
      description: '',
    },
    'CASH OUT NOT ALLOWED OUT OF BUSINESS PERIOD': {
      title: 'Transação fora de horário comercial',
      description: '',
    },
    CASHOUT_LIMIT_NOT_ENOUGH: {
      title: 'Limite de saque excedido',
      description: '',
    },
    TIMEOUT: {
      title: 'Tempo expirou',
      description: '',
    },
    INVALID_BANK_BRANCH: {
      title: 'Agência inválida',
      description: '',
    },
    INVALID_BANK_ACCOUNT: {
      title: 'Conta inválida',
      description: '',
    },
    INVALID_AUTHENTICATION_CODE: {
      title: 'Código de autenticação inválido',
      description: '',
    },
    NOT_FOUND_FUND_TRANSFER: {
      title: 'Saldo insuficiente',
      description: '',
    },
    RECIPIENT_ACCOUNT_DOES_NOT_MATCH_THE_DOCUMENT: {
      title: 'O documento informado não corresponde a conta destino',
      description: '',
    },
    SENDER_ACCOUNT_DOES_NOT_MATCH_THE_DOCUMENT: {
      title: 'O documento informado não pertence à conta do pagador',
      description: '',
    },
    SCOUTER_QUANTITY: {
      title: 'Limite de boletos emitidos atingido',
      description: '',
    },
    RATELIMIT_ERROR_LOGINATTEMPT: {
      title:
        'Limite de tentativas de login excedidas. Aguarde alguns minutos e tente novamente',
      description: '',
    },
    NOTIFICATION_NOT_FOUND: {
      title: 'Notificações não encontradas',
      description: '',
    },
    EXPIRED_2FA_TOKEN: {
      title: 'Token 2FA expirado',
      description: '',
    },
    INVALID_POWER_OF_ATTORNEY_END_DATE: {
      title: 'Data de término da procuração está inválida.',
      description: '',
    },
  };

  return ErrorConstants[type];
};
