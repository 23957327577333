import * as React from 'react';
import { SVGProps } from 'react';

const WalletUniSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.294 9.874c-.232-.034-.243-.045-.132-.056.21-.033.696.011 1.038.089.796.188 1.514.674 2.277 1.525l.199.232.287-.044c1.227-.2 2.487-.045 3.537.442.287.132.74.398.795.464.022.022.055.166.078.31.077.519.044.905-.122 1.204-.088.166-.088.21-.033.353.044.11.177.188.298.188.266 0 .542-.42.674-1.005l.056-.232.1.11c.563.63 1.005 1.503 1.071 2.122l.022.166-.1-.144a1.866 1.866 0 0 0-.53-.564c-.376-.254-.773-.331-1.823-.386-.95-.056-1.492-.133-2.023-.31-.906-.298-1.37-.685-2.442-2.11-.475-.63-.773-.973-1.072-1.26-.652-.63-1.304-.962-2.155-1.094Z"
      fill="#FF007A"
    />
    <path
      d="M21.527 11.277c.022-.42.078-.696.2-.95.043-.1.088-.188.099-.188.01 0-.011.077-.045.166-.088.243-.099.585-.044.972.078.497.11.564.641 1.105.243.254.53.575.641.707l.188.244-.188-.177c-.232-.221-.762-.641-.884-.697-.077-.044-.088-.044-.144.012-.044.044-.055.11-.055.43-.01.498-.077.807-.243 1.128-.088.166-.1.132-.022-.056.055-.143.066-.21.066-.685 0-.961-.11-1.193-.784-1.58a8.216 8.216 0 0 0-.62-.32 2.46 2.46 0 0 1-.298-.144c.023-.022.674.166.929.276.386.155.453.166.497.155.033-.033.055-.122.066-.398ZM13.748 12.924c-.464-.641-.763-1.636-.696-2.376l.022-.232.11.022c.199.033.542.165.707.265.442.265.641.63.83 1.536.054.265.132.575.165.674.055.166.265.553.442.796.122.177.044.265-.232.243-.42-.044-.984-.431-1.348-.928ZM20.964 17.73c-2.188-.883-2.962-1.646-2.962-2.939 0-.188.011-.342.011-.342.011 0 .089.066.188.143.442.354.94.509 2.32.708.808.121 1.272.21 1.692.353 1.337.442 2.166 1.348 2.365 2.575.055.354.022 1.028-.067 1.382-.077.276-.298.784-.353.795-.011 0-.034-.055-.034-.143-.022-.465-.254-.907-.64-1.25-.465-.397-1.061-.695-2.52-1.281ZM19.417 18.096a2.612 2.612 0 0 0-.11-.465l-.056-.165.1.121c.143.166.254.365.353.641.078.21.078.276.078.62 0 .33-.012.408-.078.596a2 2 0 0 1-.464.74c-.398.41-.917.63-1.658.73-.132.01-.508.044-.84.066-.828.044-1.38.133-1.878.31-.067.021-.133.044-.144.033-.022-.023.32-.221.597-.354.387-.188.785-.287 1.657-.442.431-.066.874-.155.984-.2 1.094-.342 1.636-1.193 1.459-2.231Z"
      fill="#FF007A"
    />
    <path
      d="M20.423 19.875c-.288-.63-.354-1.227-.2-1.79.023-.056.045-.111.067-.111.022 0 .088.033.155.077.132.089.409.244 1.116.63.895.486 1.403.862 1.757 1.293.31.376.497.807.586 1.337.055.299.022 1.017-.055 1.316-.244.939-.796 1.69-1.603 2.121-.121.067-.22.11-.232.11-.011 0 .033-.11.1-.242.265-.564.298-1.105.099-1.713-.122-.376-.376-.829-.884-1.592-.608-.884-.752-1.116-.906-1.436ZM12.19 23.256c.817-.685 1.823-1.17 2.751-1.326.398-.066 1.061-.044 1.426.056.585.154 1.116.486 1.392.895.265.398.387.74.508 1.503.045.298.1.607.111.674.088.398.265.707.486.873.343.254.94.265 1.525.044.1-.033.188-.066.188-.055.022.022-.276.22-.475.32a1.564 1.564 0 0 1-.796.188c-.53 0-.983-.276-1.348-.829-.077-.11-.232-.43-.365-.729-.386-.895-.585-1.16-1.038-1.459-.398-.254-.907-.31-1.293-.121-.509.243-.641.895-.288 1.293.144.165.41.298.63.331a.674.674 0 0 0 .763-.674c0-.265-.1-.42-.365-.541-.354-.155-.74.022-.73.364 0 .144.067.232.21.299.089.044.089.044.023.033-.32-.067-.398-.464-.144-.719.31-.309.962-.176 1.183.255.088.176.099.53.022.751-.188.486-.719.74-1.26.597-.365-.1-.52-.2-.962-.652-.773-.796-1.072-.95-2.177-1.116l-.21-.034.232-.22Z"
      fill="#FF007A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.376 5.475c2.575 3.128 6.543 7.99 6.742 8.255.165.221.1.431-.177.586a1.691 1.691 0 0 1-.63.177.797.797 0 0 1-.53-.232c-.1-.1-.531-.73-1.504-2.243-.74-1.16-1.37-2.122-1.38-2.133-.045-.022-.045-.022 1.303 2.387.851 1.514 1.127 2.055 1.127 2.121 0 .144-.044.221-.22.42-.299.332-.432.708-.531 1.492-.11.873-.409 1.492-1.26 2.542-.497.619-.575.73-.696.984-.155.309-.2.486-.221.884-.022.42.022.685.144 1.083.11.353.232.585.53 1.039.254.397.409.696.409.806 0 .089.022.089.42 0 .95-.22 1.735-.596 2.166-1.06.265-.288.331-.443.331-.84 0-.255-.01-.31-.077-.465-.11-.243-.32-.442-.774-.751-.596-.41-.85-.74-.917-1.183-.055-.375.011-.63.343-1.326.342-.718.43-1.017.486-1.746.033-.464.088-.652.221-.795.144-.155.265-.21.608-.255.563-.077.928-.22 1.216-.497.254-.232.364-.464.375-.807l.011-.254-.143-.155C13.228 12.913 6.034 5 6 5c-.011 0 .165.21.375.475ZM9.77 21.19a.456.456 0 0 0-.143-.608c-.188-.122-.476-.066-.476.1 0 .044.022.088.089.11.1.055.11.11.033.232-.077.122-.077.232.022.31.155.121.365.055.475-.144ZM14.245 15.377c-.265.077-.52.365-.597.652-.044.177-.022.497.055.597.122.154.232.199.542.199.608 0 1.127-.265 1.182-.586.056-.265-.177-.63-.497-.796a1.123 1.123 0 0 0-.685-.066Zm.707.553c.088-.133.055-.277-.11-.376-.299-.188-.752-.033-.752.254 0 .144.232.298.453.298.144 0 .343-.088.409-.177Z"
      fill="#FF007A"
    />
  </svg>
);

export default WalletUniSVG;
