import * as React from 'react';
import { SVGProps } from 'react';

const RandomKeySVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.802 4.553a.75.75 0 0 1-.005 1.06l-.923.913 1.654 1.636a.75.75 0 0 1 0 1.067l-2.558 2.53a.75.75 0 0 1-1.055 0l-1.665-1.647-1.977 1.956a4.717 4.717 0 0 1 .92 2.775 4.69 4.69 0 0 1-.36 1.82c-.241.577-.594 1.1-1.04 1.541-.444.44-.973.79-1.553 1.026a4.809 4.809 0 0 1-3.66-.012 4.772 4.772 0 0 1-1.547-1.036l-.006-.006a4.704 4.704 0 0 1-1.341-3.327 4.706 4.706 0 0 1 1.4-3.304 4.799 4.799 0 0 1 6.112-.522l2.525-2.5 2.557-2.53 1.462-1.446a.75.75 0 0 1 1.06.006Zm-1.994 3.029-1.491 1.475 1.125 1.114 1.492-1.475-1.126-1.114Zm-6.06 5.008a3.292 3.292 0 0 0-2.323-.927 3.29 3.29 0 0 0-2.285.948 3.206 3.206 0 0 0-.954 2.25c-.008.843.319 1.656.912 2.264.301.302.661.543 1.058.708a3.308 3.308 0 0 0 2.517.008c.4-.163.761-.402 1.066-.703.304-.301.545-.659.709-1.052a3.19 3.19 0 0 0-.7-3.496Z"
      fill={props.fill || '#026FF4'}
    />
  </svg>
);

export default RandomKeySVG;
