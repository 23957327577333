const CoinPairIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { stroke = '#272937', height = 14, width = 20 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 20 14" fill="none">
      <path
        d="M6.62801 12.0059C3.51974 12.0059 1 9.54343 1 6.50586C1 3.46829 3.51974 1.00586 6.62801 1.00586M19 6.50586C19 9.54343 16.4803 12.0059 13.372 12.0059C10.2637 12.0059 7.74399 9.54343 7.74399 6.50586C7.74399 3.46829 10.2637 1.00586 13.372 1.00586C16.4803 1.00586 19 3.46829 19 6.50586Z"
        stroke="#272937"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default CoinPairIcon;
