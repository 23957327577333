import { SVGProps } from 'react';

const ListIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6.74801H4V5.24801H2V6.74801ZM2 10.248H4V8.74801H2V10.248ZM4 13.748H2V12.248H4V13.748ZM6 6.74801H14V5.24801H6V6.74801ZM14 10.248H6V8.74801H14V10.248ZM6 13.748H14V12.248H6V13.748Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default ListIcon;
