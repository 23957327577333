import { useState } from 'react';
import BlueBuildSVG from '~assets/svg/blueBuild';
import BlueShieldSVG from '~assets/svg/blueShield';
import DisabledMailSVG from '~assets/svg/disabledMail';
import DisabledPhoneSVG from '~assets/svg/disabledPhone';
import { CheckSelect } from '~components/index';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { PixKeyTypes } from '~reactQuery/types/pix.types';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { PossiblesKeysPageProps } from '../CreatePixKeys.types';
import { getSelectedUserOrganization } from '~helpers/device';
import { cnpjMask } from '~helpers/format/masks';

function PossiblesKeys(props: PossiblesKeysPageProps) {
  const { setCurrentStep } = props;
  const [optionSelect, setOptionSelect] = useState({ keyType: '', value: '' });

  const { data: userData } = useGetPartner();

  const userOrganizationId = getSelectedUserOrganization();

  const currentOrganizaiton = userData?.data.userOrganizations.filter(
    (org) => org.id === Number(userOrganizationId)
  )?.[0];

  const cnpj = currentOrganizaiton?.organizations?.businessCode || '';

  function goNext() {
    if (optionSelect.keyType) {
      setCurrentStep('showKeyExample', {
        keyType: optionSelect.keyType,
        value: optionSelect.value,
      });
    }
  }

  function selectKey(keyType: PixKeyTypes, value?: string) {
    if (optionSelect.keyType === keyType) {
      setOptionSelect({ keyType: '', value: '' });
    } else {
      setOptionSelect({ keyType, value: value || '' });
    }
  }

  const possiblesKeysList = [
    {
      keyType: 'CNPJ',
      title: 'CNPJ',
      keyValue: cnpj,
      enabled: true,
      chipLabel: 'Recomendado',
      icon: <BlueBuildSVG />,
    },
    {
      keyType: 'EVP',
      title: 'Chave Aleatória',
      keyValue: '',
      enabled: true,
      chipLabel: '',
      icon: <BlueShieldSVG />,
    },
    {
      keyType: 'Email',
      title: 'Email',
      keyValue: '',
      enabled: false,
      chipLabel: 'Em Breve',
      icon: <DisabledMailSVG />,
    },
    {
      keyType: 'Celular',
      title: 'Celular',
      keyValue: '',
      enabled: false,
      chipLabel: 'Em Breve',
      icon: <DisabledPhoneSVG />,
    },
  ];

  return (
    <>
      <ModalMultiStepHeader
        title="Registrar ou trazer chaves"
        description="Escolha o tipo de chave que deseja cadastrar"
      />
      <div className="create-pix-body">
        {possiblesKeysList?.map((item) => {
          return (
            <CheckSelect
              iconType="component"
              disabled={!item.enabled}
              key={item.keyType}
              title={item.title}
              text={
                item.keyType === 'CNPJ'
                  ? cnpjMask(item.keyValue)
                  : item.keyValue
              }
              iconComponent={item.icon}
              chipText={item.chipLabel}
              onClick={() =>
                selectKey(item.keyType as PixKeyTypes, item.keyValue)
              }
              selected={optionSelect.keyType === item.keyType}
            />
          );
        })}
      </div>
      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="1"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={!optionSelect.keyType}
      />
    </>
  );
}

export default PossiblesKeys;
