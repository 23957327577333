import { colors } from '~styles';
import styled from 'styled-components';

export const RecoveryCodesContainer = styled.div`
  .recovery-codes-body {
    width: 100%;
    margin-top: 25px;
    padding: 0 38px;

    @media print {
      .hidden-print {
        display: none !important;
      }
    }

    .actions-buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 15px;
      margin-top: 25px;
      padding-left: 10px;
      padding-right: 10px;
    }

    ul {
      list-style-position: outside;

      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      gap: 50px;

      -webkit-column-rule: 1px single grey;
      -moz-column-rule: 1px single grey;
      column-rule: 1px single grey;

      li {
        margin-bottom: 5px;
      }
    }

    .justify-items-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .container-codes {
      min-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      background-color: ${colors.whiteLight};
      border-radius: 50px;
      margin-top: 35px;
      margin-bottom: 30px;
      padding: 13px 20px;
    }
  }
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
