import * as React from 'react';

import { SVGProps } from 'react';

const CakeCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.563 21.988c.03 2.114 1.44 3.881 3.38 5.073C16.924 28.277 19.596 29 22.495 29c2.9 0 5.572-.723 7.55-1.939 1.963-1.205 3.381-2.997 3.381-5.142v-1.375c0-2.585-2.101-4.616-4.758-5.816l.757-4.195v-.006C29.726 8.727 28.338 7 26.483 7a2.958 2.958 0 0 0-2.956 2.956v3.483c-.361-.027-.73-.045-1.1-.045-.409 0-.797.018-1.17.046V9.956A2.958 2.958 0 0 0 18.3 7c-1.841 0-3.267 1.724-2.867 3.564l.81 4.169c-2.655 1.206-4.68 3.239-4.68 5.81v1.445Z"
      fill="#633001"
    />
    <path
      d="M32.463 21.919c0 3.369-4.469 6.119-9.969 6.119-5.5 0-9.969-2.75-9.969-6.119v-1.581h19.938v1.581Z"
      fill="#FEDC90"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.376 10.369c-.275-1.238.687-2.406 1.925-2.406 1.1 0 1.994.893 1.994 1.993v4.538c.687-.069 1.374-.138 2.13-.138.688 0 1.376.07 2.063.138V9.956c0-1.1.894-1.993 1.994-1.993 1.238 0 2.2 1.168 1.994 2.406l-.894 4.95c2.887 1.1 4.881 3.025 4.881 5.225 0 3.369-4.469 6.119-9.969 6.119-5.5 0-9.968-2.75-9.968-6.12 0-2.2 1.925-4.124 4.812-5.224l-.962-4.95Z"
      fill="#D1884F"
    />
    <path
      d="M19.778 19.65c0 .894-.48 1.65-1.1 1.65-.618 0-1.1-.756-1.1-1.65 0-.894.482-1.65 1.1-1.65.62 0 1.1.756 1.1 1.65ZM27.41 19.65c0 .894-.482 1.65-1.1 1.65-.62 0-1.1-.756-1.1-1.65 0-.894.48-1.65 1.1-1.65.618 0 1.1.756 1.1 1.65Z"
      fill="#633001"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CakeCoinSVG;
