import * as React from 'react';
import { SVGProps } from 'react';

const InfoToastSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={36} height={36} fill="none" {...props}>
    <rect width={36} height={36} fill="#026FF4" fillOpacity={0.1} rx={18} />
    <path
      fill="#026FF4"
      fillRule="evenodd"
      d="M10.75 18a7.25 7.25 0 1 1 14.5 0 7.25 7.25 0 0 1-14.5 0ZM18 9.25a8.75 8.75 0 1 0 0 17.5 8.75 8.75 0 0 0 0-17.5ZM17 15a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1 3.25a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default InfoToastSVG;
