import { breakpoints, screen } from '~styles/metrics';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .loading-container {
    height: ${window.screen.height - 300}px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${screen.tablet} {
    .scroll {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const CardWalletsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, auto));
  grid-template-rows: repeat(1, 1fr);
  gap: 15px;

  .capAccount {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media (max-width: ${breakpoints.phone}px) {
    grid-template-columns: auto;
    .capAccount {
      grid-column-start: 1;
      grid-column-end: 1;
    }
  }
`;
