import { When } from 'react-if';
import CloseButton from '~components/Buttons/CloseButton';
import { GenericButton } from '~components/Buttons';
import { colors } from '~styles';
import * as Text from '~styles/text';
import * as Style from './AlertModal.styles';
import { IAlertModalProps } from './AlertModal.types';

const AlertModal = (props: IAlertModalProps) => {
  const {
    title,
    setOpenAlert,
    bodyText,
    bodyComponent,
    primaryButtonAction,
    primaryButtonText,
    primaryButtonWidth,
    secondaryButtonColor,
    secondaryButtonWidth,
    secondaryButtonAction,
    secondaryButtonText,
    paddingVertical,
    paddingHorizontal,
    noBorderBottom,
    noBorderTop,
    buttonLoading,
    disableSecondaryButton,
  } = props;

  return (
    <Style.ModalBackGround>
      <Style.ModalContainer>
        <Style.TitleCloseBtnContainer>
          <Text.Heading4Bold marginLeft={30} marginTop={5}>
            {title}
          </Text.Heading4Bold>
          <div className="close-padding">
            <CloseButton
              closeIconSize="small"
              onClick={() => setOpenAlert(false)}
              marginTop="0px"
              marginBottom="0px"
            />
          </div>
        </Style.TitleCloseBtnContainer>
        <Style.BodyContainer
          paddingVertical={paddingVertical}
          paddingHorizontal={paddingHorizontal}
          noBorderBottom={noBorderBottom}
          noBorderTop={noBorderTop}
        >
          <When condition={!!bodyText}>
            <Text.Heading5 color={colors.grayMedium}>{bodyText}</Text.Heading5>
          </When>

          <When condition={!!bodyComponent}>{bodyComponent}</When>
        </Style.BodyContainer>
        <Style.BottomContainer>
          <GenericButton
            buttonType="default"
            width={primaryButtonWidth || '100px'}
            onClick={primaryButtonAction}
          >
            {primaryButtonText}
          </GenericButton>
          <GenericButton
            buttonType={secondaryButtonColor || 'primary'}
            width={secondaryButtonWidth || '175px'}
            onClick={secondaryButtonAction}
            buttonLoading={buttonLoading}
            disabled={disableSecondaryButton}
          >
            {secondaryButtonText}
          </GenericButton>
        </Style.BottomContainer>
      </Style.ModalContainer>
    </Style.ModalBackGround>
  );
};

export default AlertModal;
