import * as React from 'react';
import { SVGProps } from 'react';

const WalletUsdtSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.864 17.643c-.494.048-.99.07-1.485.069a7.806 7.806 0 0 1-1.547-.069c-3.02-.134-5.249-.674-5.249-1.282s2.227-1.148 5.25-1.283v2.093c.203 0 .742.067 1.55.067.495.003.99-.02 1.483-.067v-2.165c3.03.135 5.25.677 5.25 1.283 0 .68-2.291 1.217-5.252 1.354Zm0-2.841v-1.81h4.17v-2.843H9.596v2.842h4.237v1.816c-3.427.136-5.992.879-5.992 1.689s2.565 1.489 5.992 1.687v5.94h3.03v-5.94c3.433-.134 5.991-.811 5.991-1.687-.067-.812-2.624-1.553-5.991-1.69v-.004Z"
      fill="#50AF95"
    />
  </svg>
);

export default WalletUsdtSVG;
