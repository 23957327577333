import * as React from 'react';
import { SVGProps } from 'react';

const ArrowDownRedSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m4.143 4 8 8m0 0V5.6m0 6.4h-6.4"
      stroke="#FF6378"
      strokeWidth={1.5}
    />
  </svg>
);

export default ArrowDownRedSVG;
