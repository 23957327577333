import * as Style from './HeadBar.styles';
import * as Text from '~styles/text';

import {
  clearStorage,
  getSelectedOrg,
  getSelectedUserOrganization,
  getUserId,
} from '~helpers/device';

import { APP_ROUTES } from '~data/navigation';
import BankInfo from '../BankInfo';

import DashboardIcon from '~assets/images/icons/16x16/dashboard.svg';
import { DropDown } from '~components/DropDown';
import { DropDownDivider } from '~components/DropDown/DropDownDivider';
// import { DropDownDivider } from '~components/DropDown/DropDownDivider';
import { DropDownItem } from '~components/DropDown/DropDownItem';
import { HeadBarProps } from './HeadBar.types';
import LogoutIcon from '~assets/images/icons/16x16/logout.svg';
import PixIcon from '~assets/images/icons/menu/pix';
import SettingsIcon from '~assets/images/icons/16x16/settings.svg';
import SwapIcon from '~assets/images/icons/16x16/swap.svg';
import UserPic from '~components/UserPic';
import { colors } from '~styles';
import { formatCurrencyFn } from '~helpers/format/currency';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { useNavigate } from 'react-router-dom';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import { useQueryClient } from 'react-query';
import { useUserWalletsFunds } from '~reactQuery/queries/user/useUserWalletsFunds';
import IncomeIcon from '~assets/svg/income';
import BellIcon from '~assets/svg/bell';
import { useGetNotifications } from '~reactQuery/queries/notifications/useGetNotifications';
import { renderUserName } from '~helpers/generic';
import { useGetOrganizationPartners } from '~reactQuery/queries/user/useGetOrganizationPartners';
import { InvitedUsersResp } from '~reactQuery/types/business.types';

const HeadBar: React.FC<HeadBarProps> = ({
  setModalToOldBizIsVisible,
  setModalIncomeIsVisible,
}) => {
  const { setOpenModalSideBar, setModalSidebarContent } = useOpenModalSideBar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const userOrganizationId = getSelectedUserOrganization();
  const { data: userData } = useGetPartner();
  const { data: registeredUsers } = useGetOrganizationPartners();
  const orgId = getSelectedOrg();
  const userId = getUserId();

  const handleOpenAreaPix = () => {
    setOpenModalSideBar(true);
    setModalSidebarContent('areaPix');
  };

  const handleOpenNotifications = () => {
    setModalSidebarContent('notifications');
    setOpenModalSideBar(true);
  };

  const { data: notifications } = useGetNotifications({
    userOrganizationId: Number(userOrganizationId),
    showNotificationData: true,
  });

  const hasUnreadNotifcations = notifications?.some((n) => n.read === false);

  const { data: userFunds = 0, isLoading: loadingUserFunds } =
    useUserWalletsFunds('BRL', Number(userOrganizationId));
  const { data: capAccountInfo, isLoading: loadingCapAccountInfo } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  const renderTotalValue = () => {
    if (loadingUserFunds || loadingCapAccountInfo) return '0.00';

    if (!userFunds && !capAccountInfo?.available) return '0.00';

    if (!userFunds && capAccountInfo?.available) {
      return formatCurrencyFn(
        'BRL',
        Number.parseFloat(
          String(Number(capAccountInfo?.available) / 100)
        ).toFixed(2)
      );
    }

    if (!capAccountInfo?.available && userFunds) {
      return formatCurrencyFn('BRL', String(capAccountInfo?.available));
    }

    return formatCurrencyFn(
      'BRL',
      Number.parseFloat(
        String(userFunds + Number(capAccountInfo?.available) / 100)
      ).toFixed(2)
    );
  };

  const handleLogout = () => {
    clearStorage(queryClient);
    navigate('/auth');
  };

  const companyName = capAccountInfo?.name;

  const handleAlternateAccount = () => {
    localStorage.removeItem('@CapBiz:selectedUserOrganization');
    navigate(APP_ROUTES.SELECT_ORGANIZATIONS);
  };

  const getUrlAvatarImage = registeredUsers?.reduce(
    (avatarUrl: string | null, user: InvitedUsersResp) => {
      if (avatarUrl) {
        return avatarUrl;
      }

      if (user.id === Number(userId)) {
        const userOrganization = user.userOrganizations.find(
          (userOrganizationItem) => {
            return (
              userOrganizationItem.organizationsId === Number(orgId) &&
              userOrganizationItem.organizations.image
            );
          }
        );

        if (userOrganization) {
          return userOrganization.organizations.image;
        }
      }

      return avatarUrl;
    },
    null
  );

  return (
    <Style.Container data-hide-for-print="hidden-for-print">
      <BankInfo />

      <div className="right-container">
        <div className="total-currency">
          <Text.Smaller marginRight={15} color={colors.grayRegular}>
            Saldo Total:
          </Text.Smaller>
          <Text.Heading4Bold>{renderTotalValue()}</Text.Heading4Bold>
        </div>

        <Style.NotifcationIconContainer
          onClick={handleOpenNotifications}
          hasUnreadNotification={Boolean(hasUnreadNotifcations)}
        >
          <BellIcon />
        </Style.NotifcationIconContainer>
        {/* <a onClick={handleOpenAreaPix}>
          <PixIcon />
        </a> */}
        <DropDown
          align="end"
          content={
            <>
              <DropDownItem
                icon={
                  <UserPic name={companyName} avatarUrl={getUrlAvatarImage} />
                }
                title={renderUserName(userData?.data)}
                subtitle={capAccountInfo?.name}
                onClick={() => {}}
                disableHover
              />
              <DropDownDivider />
              <DropDownItem
                icon={<SettingsIcon />}
                title="Configurações"
                onClick={() => navigate('/settings')}
              />
              <DropDownItem
                icon={<SwapIcon />}
                title="Alternar Conta"
                onClick={handleAlternateAccount}
              />
              <DropDownItem
                icon={<IncomeIcon />}
                title="Informe de Rendimentos"
                onClick={() => setModalIncomeIsVisible(true)}
              />
              <DropDownItem
                icon={<LogoutIcon />}
                title="Sair"
                onClick={handleLogout}
                isRed
              />
            </>
          }
        >
          <UserPic
            marginLeft={14}
            width={48}
            height={48}
            name={companyName}
            avatarUrl={getUrlAvatarImage}
          />
        </DropDown>
      </div>
    </Style.Container>
  );
};

export default HeadBar;
