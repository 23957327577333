import { BigInput } from '~components';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { ShowKeyExamplePageProps } from '../PayBills.types';
import { formatCodeBar } from '~helpers/format/masks';
import { getSelectedUserOrganization } from '~helpers/device';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { useBillValidate } from '~reactQuery/mutations/payments/useBillValidate';
import { useState } from 'react';

function ShowKeyExample(props: ShowKeyExamplePageProps) {
  const { setCurrentStep, keyType } = props;
  const [inputValue, setInputValue] = useState('');

  const { mutateAsync: billValidate, isLoading: loadingValidate } =
    useBillValidate();

  const userOrganizationId = getSelectedUserOrganization();

  async function goNext() {
    const response = await billValidate({
      userOrganizationId: Number(userOrganizationId),
      code: onlyNumbers(inputValue),
    });
    if (response.isValid) {
      setCurrentStep('selectWallet', { keyType, billData: response });
    }
  }

  const labelsAndConfigs = {
    'Pagar um boleto': {
      title: 'Digite o código de barras',
      description: '',
      rows: 3,
      formatter: formatCodeBar,
    },
    'Pagar com Pix': {
      title: 'Insira o Pix copia e cola',
      description: 'Digite a baixo seu código de identificação',
      rows: 5,
      formatter: (value: string) => value,
    },
  };

  const renderPlaceholder =
    keyType === 'Pagar com Pix' ? 'Pix Copia e cola' : 'Linha digitável';

  return (
    <>
      <ModalMultiStepHeader
        title="Digite o código de barras"
        description=""
        backButtonAction={() => setCurrentStep('possiblesKeys')}
      />
      <div className="content-container">
        <BigInput
          onChange={(e) => setInputValue(e.target.value)}
          className="big-input"
          maskFunction={formatCodeBar}
          maxLength={180}
          placeHolder={renderPlaceholder}
          rows={labelsAndConfigs[keyType].rows}
        />
      </div>
      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="1"
        singleButtonAlignment="flex-end"
        disableSingleButton={inputValue?.length < 10}
        singleButtonAction={goNext}
        singleButtonLoading={loadingValidate}
      />
    </>
  );
}

export default ShowKeyExample;
