import { create } from 'zustand';

export type UserAuthTypes = ('PIN' | '2-FA')[];

interface UserAuthTypesProps {
  auth: UserAuthTypes;
  setAuthTypes: (auth: UserAuthTypes) => void;
}

export const useUserAuthTypes = create<UserAuthTypesProps>((set) => ({
  auth: ['PIN'],
  setAuthTypes: (auth) => set((prev) => ({ auth })),
}));
