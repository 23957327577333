import { colors, colorsSecondary } from '~styles';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  .txIcon {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  th {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .display-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .scale-icon {
    transform: scale(0.8);
  }

  .gap-20 {
    gap: 20px;
  }

  .gap-10 {
    gap: 10px;
  }

  .text-green {
    color: ${colors.success};
  }

  .text-red {
    color: ${colors.danger};
  }

  .padding-left-20 {
    padding-left: 20px;
  }

  .align-center {
    justify-content: center;
    display: grid;
  }

  .hover {
    cursor: pointer;
  }

  .margin-16 {
    gap: 16px;
    display: flex;
    padding-left: 5px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .w-full {
    width: 100%;
  }

  .border-collapse {
    border-collapse: collapse;
  }

  .rounded-div {
    width: 100%;
  }

  .hide {
    @media (max-width: 900px) {
      display: none;
    }
  }

  .mobile-hide {
    @media (max-width: 500px) {
      display: none;
    }
  }

  td {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }

  .tableFixHead {
    overflow: auto;
    height: calc(100vh - 320px);

    border-top: 1px solid ${transparentize(0.92, colors.grayMedium)};

    thead tr {
      position: sticky;
      top: 0;
      background-color: ${colors.white};
    }

    .loading-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  tbody {
    border-top: 1px solid ${transparentize(0.92, colors.grayMedium)};
  }

  td:first-child {
    padding-left: 10px;
    padding-right: 0;
  }

  .end-td {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  .loading-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .hide-doc {
    width: 120px;
    height: 18px;
    max-height: 18px;
    border-radius: 4px;
    background: ${colors.gray};
  }

  .cap-account-svg {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .row-statement {
    cursor: pointer;
  }

  .not-found-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    .circle {
      width: 36px;
      height: 36px;
      border-radius: 18px;
      background-color: ${colorsSecondary.grayOpacity10p};
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
