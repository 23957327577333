import * as Style from './LimitsSettings.styles';
import * as Text from '~styles/text';
import * as TextSecondary from '~styles/textSecondary';
import PixAndTedIcon from '~assets/svg/pixAndTed';
import colors from '~styles/colors';
import BillIcon from '~assets/svg/statement/billIcon';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetLimitsAnQuotas } from '~reactQuery/queries/settings/useGetLimitsAndQuotas';
import { LimitsCard } from './LimitsCard';
import { formatCurrencyFn } from '~helpers/format/currency';
import { daysAmountFromMonth } from '~helpers/format/date';
import CriptoCoinIcon from '~assets/svg/criptoCoin';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { UseGetBillsEmissionMonthly } from '~reactQuery/queries/bills/useGetBillsEmissionMonthly';
import {
  LimitsAndQuotasTypes,
  LimitsTypes,
  QuotasTypes,
  TypeLimit,
} from './LimitsSettings.types';

const LimitsSettings: React.FC = () => {
  const userOrganizationId = getSelectedUserOrganization();

  const { data, isLoading } = useGetLimitsAnQuotas({
    userOrganizationId: Number(userOrganizationId),
  });

  const { data: billsEmission, isLoading: isLoadingBillsEmission } =
    UseGetBillsEmissionMonthly({
      userOrganizationId: Number(userOrganizationId),
    });

  const { setOpenModalMultiStep, setSection, setParams } =
    useActionsOnModalMultiStep();

  const handleOpenModal = (
    origin: string,
    limits: LimitsTypes | undefined,
    quotas: QuotasTypes | undefined
  ) => {
    setSection('newLimit');
    setParams({ origin, limits, quotas });
    return setOpenModalMultiStep(true);
  };

  const handleTransactionalLimit = (
    item: LimitsAndQuotasTypes | undefined,
    typeLimit: TypeLimit
  ) => {
    const isTransactionalUnlimited =
      item?.limits?.[typeLimit]?.maxLimit === -1 ||
      item?.limits?.[typeLimit]?.maxLimit === 0;

    const currency = item?.quotas?.[typeLimit]?.currency?.isoCode;

    if (isTransactionalUnlimited) {
      return {
        value: '',
        text: 'Indefinido',
      };
    }

    return {
      value: formatCurrencyFn(
        currency,
        String(item?.limits?.[typeLimit]?.maxLimit)
      ),
      text: 'por vez',
    };
  };

  const handleDailyUsedLimit = (dailyQuotas: QuotasTypes | undefined) => {
    const isDailyUnlimited =
      dailyQuotas?.daily?.used === -1 ||
      dailyQuotas?.daily?.limit === -1 ||
      dailyQuotas?.daily?.limit === 0;

    const currency = dailyQuotas?.currency?.isoCode;

    return isDailyUnlimited
      ? ''
      : formatCurrencyFn(currency, String(dailyQuotas?.daily?.used));
  };

  const handleDailyMaxLimit = (dailyQuotas: QuotasTypes | undefined) => {
    const isDailyUnlimited =
      dailyQuotas?.daily?.used === -1 ||
      dailyQuotas?.daily?.limit === -1 ||
      dailyQuotas?.daily?.limit === 0;

    const currency = dailyQuotas?.currency?.isoCode;

    return isDailyUnlimited
      ? 'Indefinido'
      : formatCurrencyFn(currency, String(dailyQuotas?.daily?.limit));
  };

  const handleMonthUsedLimit = (monthlyQuotas: QuotasTypes | undefined) => {
    const isMonthlyUnlimited =
      monthlyQuotas?.monthly?.used === -1 ||
      monthlyQuotas?.daily?.limit === -1 ||
      monthlyQuotas?.daily?.limit === 0;

    const currency = monthlyQuotas?.currency?.isoCode;

    return isMonthlyUnlimited
      ? ''
      : formatCurrencyFn(currency, String(monthlyQuotas?.monthly?.used));
  };

  const handleMonthMaxLimit = (monthlyQuotas: QuotasTypes | undefined) => {
    const currentMonth = new Date().getMonth();
    const currency = monthlyQuotas?.currency?.isoCode;

    const isMonthlyUnlimited =
      monthlyQuotas?.monthly?.used === -1 ||
      monthlyQuotas?.daily?.limit === -1 ||
      monthlyQuotas?.daily?.limit === 0;

    return isMonthlyUnlimited
      ? 'Indefinido'
      : formatCurrencyFn(
          currency,
          String(
            Number(monthlyQuotas?.daily?.limit) *
              Number(daysAmountFromMonth(currentMonth))
          )
        );
  };

  const calculatePercentage = (
    value: number | undefined,
    maxValue: number | undefined,
    dailyQuotas?: number | undefined
  ): number => {
    const currentMonth = new Date().getMonth();

    if (!value || !maxValue || !dailyQuotas) return 0;

    if (maxValue === -1) {
      const percentage = dailyQuotas
        ? (value / (dailyQuotas * Number(daysAmountFromMonth(currentMonth)))) *
          100
        : (value / Number(daysAmountFromMonth(currentMonth))) * 100;

      if (percentage > 0) {
        return percentage;
      }

      return 0;
    }

    const percentage = (value / maxValue) * 100;
    return percentage;
  };

  return (
    <Style.LimitsContainer>
      <Style.ContainerTitle>
        <PixAndTedIcon fill={colors.grayMedium} />
        <TextSecondary.Heading4Medium>
          Transferências bancárias
        </TextSecondary.Heading4Medium>
      </Style.ContainerTitle>

      <Text.Heading5 marginTop={15}>
        Conheça seus limites para transferências interbancárias, incluindo Pix,
        Pix copia e cola, Pix QR Code e TED.
      </Text.Heading5>

      <Style.ContainerCards>
        <LimitsCard
          title="Pix"
          TransactionalValue={handleTransactionalLimit(data, 'pix')?.value}
          MaxTransactionalValue={handleTransactionalLimit(data, 'pix')?.text}
          DailyValue={handleDailyUsedLimit(data?.quotas?.pix)}
          MaxDailyValue={handleDailyMaxLimit(data?.quotas?.pix)}
          MonthlyValue={handleMonthUsedLimit(data?.quotas?.pix)}
          MaxMonthlyValue={handleMonthMaxLimit(data?.quotas?.pix)}
          ProgressBarDaily={calculatePercentage(
            data?.quotas?.pix.daily?.used,
            data?.quotas?.pix.daily?.limit
          )}
          ProgressBarMonthly={calculatePercentage(
            data?.quotas?.pix.monthly?.used,
            data?.quotas?.pix.monthly?.limit,
            data?.quotas?.pix.daily?.limit
          )}
          hasNightly
          linkButton={() =>
            handleOpenModal('pix', data?.limits?.pix, data?.quotas?.pix)
          }
          isLoading={isLoading}
        />

        <LimitsCard
          title="TED"
          TransactionalValue={handleTransactionalLimit(data, 'ted')?.value}
          MaxTransactionalValue={handleTransactionalLimit(data, 'ted')?.text}
          DailyValue={handleDailyUsedLimit(data?.quotas?.ted)}
          MaxDailyValue={handleDailyMaxLimit(data?.quotas?.ted)}
          MonthlyValue={handleMonthUsedLimit(data?.quotas?.ted)}
          MaxMonthlyValue={handleMonthMaxLimit(data?.quotas?.ted)}
          ProgressBarDaily={calculatePercentage(
            data?.quotas?.ted.daily?.used,
            data?.quotas?.ted.daily?.limit
          )}
          ProgressBarMonthly={calculatePercentage(
            data?.quotas?.ted.monthly?.used,
            data?.quotas?.ted.monthly?.limit,
            data?.quotas?.ted.daily?.limit
          )}
          linkButton={() =>
            handleOpenModal('ted', data?.limits?.ted, data?.quotas?.ted)
          }
          isLoading={isLoading}
        />
      </Style.ContainerCards>

      <div className="divider" />

      <Style.ContainerTitle>
        <CriptoCoinIcon fill={colors.grayMedium} />
        <TextSecondary.Heading4Medium>
          Criptomoedas
        </TextSecondary.Heading4Medium>
      </Style.ContainerTitle>

      <Text.Heading5 marginTop={15}>
        Os limites relativos a movimentações com criptomoedas são determinados
        com base em seu valor equivalente em dólar americano (USD).
      </Text.Heading5>

      <Style.ContainerCards>
        <LimitsCard
          title="Transferências e Conversões"
          TransactionalValue={handleTransactionalLimit(data, 'exchange')?.value}
          MaxTransactionalValue={
            handleTransactionalLimit(data, 'exchange')?.text
          }
          DailyValue={handleDailyUsedLimit(data?.quotas?.exchange)}
          MaxDailyValue={handleDailyMaxLimit(data?.quotas?.exchange)}
          MonthlyValue={handleMonthUsedLimit(data?.quotas?.exchange)}
          MaxMonthlyValue={handleMonthMaxLimit(data?.quotas?.exchange)}
          ProgressBarDaily={calculatePercentage(
            data?.quotas?.exchange.daily?.used,
            data?.quotas?.exchange.daily?.limit
          )}
          ProgressBarMonthly={calculatePercentage(
            data?.quotas?.exchange.monthly?.used,
            data?.quotas?.exchange.monthly?.limit,
            data?.quotas?.exchange.daily?.limit
          )}
          linkButton={() =>
            handleOpenModal(
              'exchange',
              data?.limits?.exchange,
              data?.quotas?.exchange
            )
          }
          isLoading={isLoading}
        />

        <LimitsCard
          title="Saques e Retiradas"
          TransactionalValue={handleTransactionalLimit(data, 'crypto')?.value}
          MaxTransactionalValue={handleTransactionalLimit(data, 'crypto')?.text}
          DailyValue={handleDailyUsedLimit(data?.quotas?.crypto)}
          MaxDailyValue={handleDailyMaxLimit(data?.quotas?.crypto)}
          MonthlyValue={handleMonthUsedLimit(data?.quotas?.crypto)}
          MaxMonthlyValue={handleMonthMaxLimit(data?.quotas?.crypto)}
          ProgressBarDaily={calculatePercentage(
            data?.quotas?.crypto.daily?.used,
            data?.quotas?.crypto.daily?.limit
          )}
          ProgressBarMonthly={calculatePercentage(
            data?.quotas?.crypto.monthly?.used,
            data?.quotas?.crypto.monthly?.limit,
            data?.quotas?.crypto.daily?.limit
          )}
          linkButton={() =>
            handleOpenModal(
              'cripto',
              data?.limits?.crypto,
              data?.quotas?.crypto
            )
          }
          isLoading={isLoading}
        />
      </Style.ContainerCards>

      <div className="divider" />

      <Style.ContainerTitle>
        <BillIcon stroke={colors.grayMedium} />
        <TextSecondary.Heading4Medium>
          Boletos e cobranças
        </TextSecondary.Heading4Medium>
      </Style.ContainerTitle>

      <Text.Heading5 marginTop={15}>
        Conheça seus limites relativos à emissão e pagamentos de boletos e
        cobranças.
      </Text.Heading5>

      <Style.ContainerCards>
        <LimitsCard
          title="Pagamento de boletos"
          TransactionalValue={handleTransactionalLimit(data, 'boleto')?.value}
          MaxTransactionalValue={handleTransactionalLimit(data, 'boleto')?.text}
          DailyValue={handleDailyUsedLimit(data?.quotas?.boleto)}
          MaxDailyValue={handleDailyMaxLimit(data?.quotas?.boleto)}
          MonthlyValue={handleMonthUsedLimit(data?.quotas?.boleto)}
          MaxMonthlyValue={handleMonthMaxLimit(data?.quotas?.boleto)}
          ProgressBarDaily={calculatePercentage(
            data?.quotas?.boleto.daily?.used,
            data?.quotas?.boleto.daily?.limit
          )}
          ProgressBarMonthly={calculatePercentage(
            data?.quotas?.boleto.monthly?.used,
            data?.quotas?.boleto.monthly?.limit,
            data?.quotas?.boleto.daily?.limit
          )}
          linkButton={() =>
            handleOpenModal('bill', data?.limits?.boleto, data?.quotas?.boleto)
          }
          isLoading={isLoading}
        />

        <LimitsCard
          title="Boletos de depósito"
          BillValue={billsEmission?.total}
          isLoading={isLoadingBillsEmission}
        />
      </Style.ContainerCards>
    </Style.LimitsContainer>
  );
};

export default LimitsSettings;
