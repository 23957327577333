import { colors, metrics } from '~styles';
import styled, { css } from 'styled-components';

export const Container = styled.div<{
  width?: number;
  height?: number;
  bgColor?: string;
  borderColor?: string;
  onClick?: () => void;
  marginTop?: number;
}>`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 5px 10px;
  border-radius: 32px;
  margin-top: ${({ marginTop }) => marginTop || 0}px;

  ${({ borderColor }) =>
    borderColor
      ? css`
          border: 1px solid ${borderColor};
        `
      : css``}

  ${({ onClick }) =>
    onClick
      ? css`
          cursor: pointer;
        `
      : css``}

  height: ${({ height }) => height || 31}px;

  ${({ width }) =>
    width
      ? css`
          width: ${width}px;
        `
      : css``}

  .horizontal-margins {
    margin-left: 5px;
    margin-right: 5px;
  }

  .c-pointer {
    cursor: pointer;
    display: flex;

    align-items: center;
  }
`;
