import * as React from 'react';
import { SVGProps } from 'react';

const WalletUsdcSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.525 12.027c.092-1.055-.368-.966-1.29-.966-.643.175-.367 1.053-.367 1.487 0 .089-.282.089-.367.089a2.655 2.655 0 0 0-2.21 2.29c-.185 2.724 2.578 2.374 4.509 3.077a1.16 1.16 0 0 1 .716 1.115 1.167 1.167 0 0 1-.162.556 2.199 2.199 0 0 1-3.004.305 2.07 2.07 0 0 1-.31-.305c-.186-.264-.093-.878-.646-.878-.366 0-1.288-.175-1.288.44a2.697 2.697 0 0 0 1.845 2.188c1.381.44.921 0 1.012 1.407 0 .527.737.44 1.196.44.83-.09.283-1.143.553-1.583a2.794 2.794 0 0 0 1.784-.834 2.808 2.808 0 0 0 .795-1.805 2.053 2.053 0 0 0-1.197-2.11c-1.105-.528-2.395-.351-3.5-.878a1.058 1.058 0 0 1-.47-1.122c.03-.141.09-.274.173-.392a1.13 1.13 0 0 1 .58-.42c.92-.263 2.21-.088 2.486.966.09.177.736 0 1.104 0 1.012-.176-.283-1.845-.738-2.11-1.113-.608-1.297.184-1.206-.958h.002Zm8.654 5.096a8.977 8.977 0 0 0-1.066-4.183 8.948 8.948 0 0 0-2.892-3.197 10.717 10.717 0 0 0-2.67-1.32.372.372 0 0 0-.45.26.346.346 0 0 0-.01.092c-.092 1.143 0 1.143 1.104 1.582a7.296 7.296 0 0 1 3.925 4.208 7.335 7.335 0 0 1-.33 5.753 7.66 7.66 0 0 1-4.33 3.749c-.553.176-.368.791-.368 1.23 0 .352.282.528.644.352a8.353 8.353 0 0 0 1.842-.791 8.74 8.74 0 0 0 3.369-3.228 8.773 8.773 0 0 0 1.234-4.505l-.002-.002ZM13.302 9.214c.091-.352-.092-.966-.552-.703a8.935 8.935 0 0 0-4.232 2.724 8.776 8.776 0 0 0-2.245 6.588 8.79 8.79 0 0 0 3.248 6.154c.541.445 1.13.83 1.755 1.144.46.177 2.024 1.143 2.114.264.185-1.405-.46-1.142-1.472-1.759a7.395 7.395 0 0 1-2.79-2.55 7.424 7.424 0 0 1-1.17-3.601 7.133 7.133 0 0 1 .725-3.697 7.109 7.109 0 0 1 2.505-2.808c.555-.345 1.14-.639 1.749-.878.46-.087.367-.527.367-.878"
      fill="#2775CA"
    />
  </svg>
);

export default WalletUsdcSVG;
