import { SVGProps } from 'react';

const InfoSecondary = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17">
      <rect
        x="1"
        y="1.01001"
        width={props.width || '14'}
        height={props.height || '14'}
        rx="6"
        fill={props.fill || '#9EA1B9'}
      />
      <path
        d="M9 11.51C9 12.0623 8.55228 12.51 8 12.51C7.44772 12.51 7 12.0623 7 11.51C7 10.9577 7.44772 10.51 8 10.51C8.55228 10.51 9 10.9577 9 11.51Z"
        fill="white"
      />
      <path
        d="M10.4237 5.09309C10.2038 4.75927 9.88958 4.49226 9.48881 4.29883C9.09055 4.10729 8.62673 4.01001 8.10989 4.01001C7.5533 4.01001 7.05764 4.12634 6.63782 4.35612C6.2175 4.58615 5.89283 4.88124 5.67257 5.23324C5.45107 5.587 5.33862 5.94264 5.33862 6.29063C5.33862 6.47653 5.41609 6.6499 5.5679 6.80572C5.72046 6.96229 5.91025 7.04164 6.13201 7.04164C6.51058 7.04164 6.76807 6.81963 6.89944 6.38427C7.01903 6.02412 7.16633 5.74946 7.33694 5.56769C7.49727 5.39621 7.75738 5.30921 8.10989 5.30921C8.41388 5.30921 8.65369 5.39445 8.84385 5.57008C9.03126 5.74319 9.12265 5.94991 9.12265 6.20188C9.12367 6.32384 9.09296 6.44398 9.03352 6.55049C8.97005 6.66393 8.89125 6.76807 8.79935 6.86C8.70195 6.95753 8.54124 7.10419 8.32149 7.29649C8.06551 7.52088 7.85905 7.71731 7.70862 7.8804C7.54884 8.05424 7.42071 8.2547 7.33004 8.47272C7.23502 8.69748 7.18663 8.96625 7.18663 9.27061C7.18663 9.53073 7.25922 9.73218 7.4025 9.86919C7.54428 10.0048 7.72091 10.074 7.92762 10.074C8.32287 10.074 8.57133 9.85816 8.64529 9.45388C8.68277 9.28603 8.7111 9.16857 8.73003 9.10163C8.7472 9.04083 8.7719 8.97866 8.80374 8.91635C8.83458 8.85593 8.88372 8.78711 8.94953 8.71202C9.01835 8.63292 9.11111 8.53991 9.22506 8.43611C9.6509 8.05515 9.9515 7.77937 10.1182 7.61628C10.2902 7.4483 10.44 7.24622 10.5639 7.01594C10.6915 6.78015 10.7558 6.50261 10.7558 6.19122C10.7558 5.79785 10.6442 5.4283 10.4242 5.09322"
        fill="white"
      />
    </svg>
  );
};

export default InfoSecondary;
