import { gql, request, useMutation } from '~reactQuery';

interface PasswordResetGuestInput {
  emailToken: string;
  passwd: string;
  confirmPasswd: string;
  documentNumber: string;
}

interface PasswordResetGuestResponse {
  passwordResetGuest: {
    data: {
      id: number;
      capu: string;
      registerName: string;
      socialName: string;
      phone: string;
      email: string;
      documentNumber: string;
      deletedAt: string;
      createdAt: string;
      updatedAt: string;
      active: boolean;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function usePasswordResetGuest() {
  const mutation = gql`
    mutation PasswordResetGuest($params: PasswordResetGuestInput!) {
      passwordResetGuest(params: $params) {
        data {
          id
          capu
          registerName
          socialName
          phone
          email
          documentNumber
          deletedAt
          createdAt
          updatedAt
          active
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: PasswordResetGuestInput) => {
    const response = await request<PasswordResetGuestResponse>(
      mutation,
      {
        params: {
          documentNumber: params.documentNumber,
          passwd: params.passwd,
          confirmPasswd: params.confirmPasswd,
        },
      },
      {
        Authorization: `Bearer ${params.emailToken}`,
      }
    );
    return response.passwordResetGuest;
  };

  return useMutation('usePasswordResetGuest', fetch);
}
