import { colors } from '~styles';
import { transparentize } from 'polished';
import { GeneralStyleProps } from 'src/@types/general';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ topToolTip?: boolean }>`
  width: 95%;
  height: 28px;
  position: absolute;
  bottom: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  ${({ topToolTip }) =>
    topToolTip &&
    css`
      width: 100%;
      position: static;
      gap: 10px;
    `}
`;

export const Button = styled.div<
  GeneralStyleProps & { topToolTip?: boolean; selected: boolean }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 28px;
  border-radius: 10px;
  margin-right: 10px;

  background-color: ${(props) =>
    props.selected ? colors.mainGray : colors.transparent};

  cursor: pointer;

  :hover {
    background-color: ${(props) =>
      props.backgroundColorHover || transparentize(0.7, colors.primary)};
  }

  ${({ topToolTip }) =>
    topToolTip &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      border-radius: 0;
      width: fit-content;
      background-color: ${colors.transparent};

      :hover {
        background-color: ${colors.transparent};
      }
    `}
`;
