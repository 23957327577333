import * as Style from './CreateWallet.styles';
import * as Text from '~styles/text';
import {
  BigInput,
  CheckSelectSmall,
  InputForm,
  InputSwitch,
} from '~components/Inputs';
import { useState } from 'react';
import { GenericRow } from '~components';
import ModalMultiStep from '~components/ModalMultiStep';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import StarIcon from '~assets/images/icons/16x16/star-no-filled.svg';
import { SuccessToast } from '~components/Toast';
import { WalletsList } from '~data/wallets';
import { When } from 'react-if';
import WorldIcon from '~assets/svg/globe';
import { getSelectedUserOrganization } from '~helpers/device';
import { UseWalletsCreate } from '~reactQuery/mutations/wallet/useWalletsCreate';
import {
  CreateWalletProps,
  WalletTypeCapAccount,
} from '../CapAccountAndWallets.types';
import AdaCoinSVG from '~assets/svg/coins/adaCoin';

function CreateWallet({ openModal, setOpenModal }: CreateWalletProps) {
  const [search, setSearch] = useState('');
  const [step, setStep] = useState(0);
  const [selectedWalletName, setSelectedWalletName] = useState('');
  const [isFavorite, setIsFavorite] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const userOrganizationId = getSelectedUserOrganization();

  const emptyWallet: WalletTypeCapAccount = {
    id: 0,
    color: '',
    icon: <AdaCoinSVG />,
    name: '',
    title: '',
    disable: false,
  };

  const [destinationWallet, setDestinationWallet] =
    useState<WalletTypeCapAccount>(emptyWallet);

  const handleOnClickWallet = (w: WalletTypeCapAccount) => {
    setDestinationWallet(w);
  };

  const filteredWallets = WalletsList.filter((w) => {
    const query = search.toLowerCase();
    const walletIsoCode = w.name.toLowerCase();
    const walletName = w.title.toLowerCase();

    if (walletIsoCode.includes(query) || walletName.includes(query)) {
      return true;
    }

    return false;
  });

  const goNextStep = () => setStep(step + 1);
  const goBackStep = () => setStep(step - 1);

  const { mutateAsync: createWalletMutate, isLoading: loadingCreateWallet } =
    UseWalletsCreate();

  async function handleCreateWallet() {
    try {
      await createWalletMutate({
        currency: destinationWallet.name,
        name: selectedWalletName,
        userOrganizationId: Number(userOrganizationId),
        data: {
          isFavorite,
          isPublic,
          isOwnerInfoPublic: true,
        },
      });

      setOpenModal(false);

      SuccessToast({
        title: 'Carteira criada com sucesso!',
        subtitle: `Sua carteira de ${destinationWallet.title} já está pronta ser utilizada, aproveite!`,
        autoCloseEnable: true,
      });
    } catch (error) {
      // console.log(error);
    }
  }

  return (
    <Style.Container>
      <ModalMultiStep
        openModal={openModal}
        setOpenModal={setOpenModal}
        barProgressPercent={step === 0 ? 30 : 70}
      >
        <When condition={step === 0}>
          <ModalMultiStepHeader
            title="Nova Carteira"
            description="Qual a moeda da carteira que você deseja criar?"
          />

          <Style.OriginWalletList>
            <InputForm
              marginBottom={35}
              marginTop={20}
              placeholder="Pesquisar moeda"
              value={search}
              onChange={(e) => setSearch((e.target as HTMLInputElement).value)}
            />

            <Text.Heading3 marginBottom={20}>Criptomoedas</Text.Heading3>
            {filteredWallets.map((w) => {
              if (w.disable) return null;

              return (
                <div className="origin-wallet-wrapper">
                  <CheckSelectSmall
                    key={w.name}
                    title={w.title}
                    text={w.name.toLocaleUpperCase()}
                    iconType="bankIcon"
                    selected={w.id === destinationWallet.id}
                    onClick={() => handleOnClickWallet(w)}
                    icon={w.icon}
                  />
                </div>
              );
            })}
          </Style.OriginWalletList>

          <ModalMultiStepBottom
            singleButtonText="Próximo"
            singleButtonWidth="100%"
            singleButtonAction={goNextStep}
            disableSingleButton={!destinationWallet.name}
          />
        </When>

        <When condition={step === 1}>
          <ModalMultiStepHeader
            title={`Carteira de ${destinationWallet.title}`}
            description="Título da carteira"
            backButtonAction={goBackStep}
          />

          <div className="content mt-15">
            <BigInput
              paddingBottom={64}
              placeHolder="Nome da Carteira"
              value={selectedWalletName}
              onChange={(e) => setSelectedWalletName(e.target.value)}
            />

            <Text.Heading5 fontSize={1.188} marginTop={25} marginBottom={15}>
              Configurar
            </Text.Heading5>

            <GenericRow
              title="Carteira Favorita"
              leftContentIconType="icon"
              leftComponent={<StarIcon />}
              rightContentType="component"
              rightComponent={
                <InputSwitch
                  checked={isFavorite}
                  onChange={() => setIsFavorite(!isFavorite)}
                />
              }
              noHorizontalPadding
            />

            <GenericRow
              title="Receber transferências externas"
              description="Receba de outros usuários do Capitual compartilhando seu CAP-U."
              leftContentIconType="icon"
              leftComponent={<WorldIcon />}
              rightContentType="component"
              rightComponent={
                <InputSwitch
                  checked={isPublic}
                  onChange={() => setIsPublic(!isPublic)}
                />
              }
              noHorizontalPadding
            />
          </div>

          <ModalMultiStepBottom
            singleButtonText="Próximo"
            singleButtonWidth="100%"
            singleButtonAction={handleCreateWallet}
            disableSingleButton={selectedWalletName === ''}
            singleButtonLoading={loadingCreateWallet}
          />
        </When>
      </ModalMultiStep>
    </Style.Container>
  );
}

export default CreateWallet;
