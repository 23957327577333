import * as Style from './BankInfo.styles';
import * as Text from '~styles/text';

import BankAccountIcon from '~assets/images/icons/24x24/capconta.svg';
import { BankInfoProps } from './BankInfo.types';
import CopyIcon from '~assets/images/icons/copy.svg';
import PixIcon from '~assets/images/icons/24x24/pix-v2.svg';
import SelectArrowRight from '~assets/images/icons/select-arrow-right.svg';
import { Else, If, Then, When } from 'react-if';
import { colors } from '~styles';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { useGetPixKey } from '~reactQuery/queries/pix/useGetPixKey';
import { useState } from 'react';
import { SuccessToast } from '..';
import { motion, AnimatePresence } from 'framer-motion';

const BankInfo: React.FC<BankInfoProps> = () => {
  const [isOpened, setIsOpened] = useState(false);

  const userOrganizationId = getSelectedUserOrganization();

  const { data: capAccountInfo } = useGetCapAccountInfo({
    userOrganizationId: Number(userOrganizationId),
  });

  const { data: partnerInfos } = useGetPartner();
  const userDocumentNumber = partnerInfos?.data.documentNumber;

  const { data: pixKeys } = useGetPixKey({
    documentNumber: userDocumentNumber,
    userOrganizationId: Number(userOrganizationId),
  });

  const openModalInfo = () => setIsOpened(!isOpened);

  const hasPixKeyCNPJ = pixKeys?.filter((key) => key?.type === 'CNPJ')?.[0]
    ?.value;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(
      'Olá! Aqui estão os dados da minha conta bancária do Capitual:\n\n' +
        `Banco: ${332} - Acesso Soluções de Pagamento S.A.\n` +
        `Agência: ${capAccountInfo?.branch}\n` +
        `Conta: ${capAccountInfo?.account}`
    );
    SuccessToast({
      title: 'Dados bancários copiados com sucesso!',
      autoCloseEnable: true,
    });
  };

  return (
    <AnimatePresence>
      <If condition={!isOpened}>
        <Then>
          <motion.div
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 1, opacity: 1 }}
            exit={{ x: 0, opacity: 0 }}
            transition={{ ease: 'easeIn', duration: 0.2 }}
          >
            <Style.BankInfoButton onClick={openModalInfo}>
              <Text.Small fontSize={0.813} color={colors.grayRegular}>
                Ver dados Bancários
              </Text.Small>

              <SelectArrowRight />
            </Style.BankInfoButton>
          </motion.div>
        </Then>

        <Else>
          <motion.div
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 1, opacity: 1 }}
            exit={{ x: 0, opacity: 0 }}
            transition={{ ease: 'easeOut', duration: 0.2 }}
          >
            <Style.Container>
              <div className="icon-container">
                <BankAccountIcon />
              </div>

              <Text.Paragraph>
                Banco &nbsp;
                <strong>{332}</strong>
              </Text.Paragraph>

              <span>&nbsp;&nbsp;</span>

              <Text.Paragraph>
                Ag &nbsp;
                <strong>{capAccountInfo?.branch}</strong>
              </Text.Paragraph>

              <span>&nbsp;&nbsp;</span>

              <Text.Paragraph>
                Cc &nbsp;
                <strong>{capAccountInfo?.account}</strong>
              </Text.Paragraph>

              <a onClick={handleCopyToClipboard}>
                <CopyIcon />
              </a>

              <When condition={hasPixKeyCNPJ}>
                <div className="vertical-hr" />
                <div className="icon-container">
                  <PixIcon />
                </div>

                <Text.Paragraph>
                  Chave CNPJ &nbsp;
                  <Text.ParagraphBold>{hasPixKeyCNPJ}</Text.ParagraphBold>
                </Text.Paragraph>

                <a
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `Chave CNPJ: ${hasPixKeyCNPJ}`
                    );
                  }}
                >
                  <CopyIcon />
                </a>

                <div className="vertical-hr" />
              </When>
              <div className="arrow-back-button" onClick={openModalInfo}>
                <SelectArrowRight />
              </div>
            </Style.Container>
          </motion.div>
        </Else>
      </If>
    </AnimatePresence>
  );
};

export default BankInfo;
