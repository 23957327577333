import { InputPin } from '~components/Inputs';
import * as Style from '../GuestRegister.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import { breakpoints } from '~styles/metrics';
import useWindowDimensions from '~hooks/windowDimension';
import { FooterButton } from '~components/Footers';
import SecuritySVG from '~assets/svg/security';
import { useChangeUserGuestMutation } from '~reactQuery/mutations/kyc/useChangeUserGuest';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { useTwoFAEnable } from '~reactQuery/mutations/settings/useTwoFAEnable';
import { CommonStepParams } from '../GuestRegister.types';
import { When } from 'react-if';
import * as yup from 'yup';
import { useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const Validate2FA = ({ stepParams, goToStep }: CommonStepParams) => {
  const { width } = useWindowDimensions();
  const formNewUser = useFormContext();

  const { mutateAsync: changeUserGuestData, isLoading: isLoadingChangeUser } =
    useChangeUserGuestMutation();

  const { mutateAsync: enableTwoFACode, isLoading: isLoadingEnable } =
    useTwoFAEnable();

  const schema = yup.object().shape({
    code: yup
      .string()
      .min(6, '2-FA incompleto')
      .required('Esse campo é obrigatório'),
  });

  const {
    setValue,
    getValues,
    formState: { errors },
    trigger,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
    },
  });

  const handleRegisterUser = async (rescueCodes: Array<string>) => {
    const { fullName, passwd, phone, socialName } = formNewUser.getValues();

    try {
      if (stepParams.partner && stepParams.pin) {
        if (!stepParams.partner?.active) {
          await changeUserGuestData({
            documentNumber: stepParams.partner?.documentNumber,
            email: stepParams.partner?.email,
            phone: `55${onlyNumbers(phone)}`,
            pin: stepParams.pin,
            token: String(stepParams.token),
            registerName: fullName,
            socialName,
            passwd,
          });
        } else {
          await changeUserGuestData({
            registerName: stepParams.partner?.registerName,
            documentNumber: stepParams.partner?.documentNumber,
            email: stepParams.partner?.email,
            phone: stepParams.partner?.phone,
            pin: stepParams.pin,
            socialName: stepParams.partner?.registerName,
            token: String(stepParams.token),
          });
        }

        goToStep('getRecoveryCode', { rescueCodes });
      }
    } catch (error) {
      // console.log(error)
    }
  };

  const onSubmit = async () => {
    try {
      trigger().then(async (isValid) => {
        if (isValid) {
          const token = getValues('code');

          if (stepParams.partner && stepParams.token) {
            const enableResp = await enableTwoFACode({
              userOrganizationId: stepParams.partner?.userOrganizations[0]?.id,
              settingsId: stepParams.partner?.settings?.id,
              tokenAuth: stepParams.token,
              token,
            });

            if (enableResp?.data?.rescueCodes) {
              handleRegisterUser(enableResp?.data?.rescueCodes);
            }
          }
        }
      });
    } catch (error) {
      //   console.log('TwoFa error: ', error);
    }
  };

  const loadingButton = isLoadingChangeUser || isLoadingEnable;

  return (
    <Style.Container>
      <div className="main-icon">
        <SecuritySVG />
      </div>

      <Text.Heading1Medium marginTop={10} marginBottom={30}>
        Insira o código de validação
      </Text.Heading1Medium>

      <Text.Heading5
        color={colors.black}
        marginBottom={30}
        lineHeight={140}
        width={breakpoints?.desktop < width ? '100%' : ''}
      >
        Digite o código de 6 dígitos gerado pelo seu aplicativo de autenticação.
      </Text.Heading5>

      <InputPin
        autoFocus
        size={6}
        secret
        onChange={(code) => setValue('code', code)}
        onFinish={(code) => setValue('code', code)}
        error={!!errors.code?.message}
      />

      <When condition={!!errors.code?.message}>
        <Text.Heading5 marginTop={5} color={colors.danger}>
          Código Invalido
        </Text.Heading5>
      </When>

      <Text.Heading5 marginTop={20}>
        Se estiver com dificuldades para encontrar o código, você pode pedir
        ajuda no nosso chat do suporte.
      </Text.Heading5>

      <FooterButton
        onClickGenericButton={() => onSubmit()}
        leftButtonContent="Voltar"
        onClickLeftButton={() => goToStep('register2FA')}
        genericButtonType="secondary"
        genericButtonContent="Habilitar 2FA"
        loadingGenericButton={loadingButton}
      />
    </Style.Container>
  );
};

export default Validate2FA;
