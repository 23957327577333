import ArrowPointLeftSVG from '~assets/svg/arrowPointLeft';
import * as Style from '../SearchRecipient.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import { useEffect, useMemo } from 'react';
import InputCurrency from '~components/Inputs/InputCurrency/InputCurrency.component';
import { InputForm, InputSelect, InputSwitch } from '~components/Inputs';
import { useGetBrlBanks } from '~reactQuery/queries/bank/useGetBrlBank';
import { accountsTypes } from '~data/bankUtils';
import { formatCpfOrCnpj } from '~helpers/format/numbers';
import DropDownList from '~components/DropDownList/DropDownList.component';
import { TransferByBankDataFormProps } from '../SearchRecipient.types';
import { useFormContext } from 'react-hook-form';
import { When } from 'react-if';
import { ErrorToast, GenericButton } from '~components/index';

const TransferByBankDataForm = (props: TransferByBankDataFormProps) => {
  const {
    setStepPersonWillReceive,
    selectedContact,
    listOfBeneficiaries,
    setListOfBeneficiaries,
  } = props;
  const { data: brlBankList, isLoading: isLoadingBankList } = useGetBrlBanks();

  const {
    watch,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useFormContext();

  const parseBankList: Array<{
    value: string;
    label: string;
    bankCode?: string;
  }> = useMemo(() => {
    if (!brlBankList && isLoadingBankList)
      return [{ value: '', label: 'Carregando...' }];

    const mapBankList = (brlBankList || [])
      .map((bank) => ({
        value: bank.bankIspb,
        label: `${bank.bankName}`,
        bankCode: bank.bankCompe,
      }))
      .sort((a, b) => ((a.bankCode || '') < (b.bankCode || '') ? -1 : 1));

    return mapBankList;
  }, [brlBankList, isLoadingBankList]);

  const transferTypes = [
    { value: 'Pix Manual', label: 'Pix Manual' },
    { value: 'TED', label: 'TED' },
  ];

  const handleGoBack = () => {
    reset();
    setStepPersonWillReceive('listContacts');
  };
  const bankIspbInList = parseBankList?.filter(
    (b: { value: string; label: string; bankCode?: string }) =>
      b?.bankCode === selectedContact?.bank
  )?.[0]?.value;

  const bankCodeInList = parseBankList?.filter(
    (b: { value: string; label: string; bankCode?: string }) =>
      b?.value === selectedContact?.bankIspb
  )?.[0]?.bankCode;

  const setBankIspb = (code: string) => {
    setValue('bankIspb', code);
    setValue(
      'bank',
      parseBankList?.filter(
        (b: { value: string; label: string; bankCode?: string }) =>
          b?.value === code
      )?.[0]?.bankCode
    );
  };

  const setBankCode = (code: string) => {
    setValue('bank', code);
    setValue(
      'bankIspb',
      parseBankList?.filter(
        (b: { value: string; label: string; bankCode?: string }) =>
          b?.value === code
      )?.[0]?.value
    );
  };

  useEffect(() => {
    if (selectedContact?.bank) {
      setBankIspb(bankIspbInList || '');
    }

    if (selectedContact?.bankIspb) {
      setBankCode(bankCodeInList || '');
    }
  }, []);

  const onRemoveBeneficiary = async () => {
    if (!listOfBeneficiaries || listOfBeneficiaries.length === 0) {
      ErrorToast({
        title: 'Lista já está vazia!',
        autoCloseEnable: true,
      });
      return;
    }

    setListOfBeneficiaries((prevList) => {
      const updatedList = prevList?.filter(
        (_, index) => index !== selectedContact?.indexInArray
      );
      return updatedList;
    });

    setStepPersonWillReceive('listOfBeneficiaries');
  };

  return (
    <Style.FormDataContainer>
      <div className="go-back-button" onClick={() => handleGoBack()}>
        <ArrowPointLeftSVG fill={colors.grayMedium} />
        <Text.Heading2Bold style={{ fontFeatureSettings: '"ss02", "zero"' }}>
          Transferindo para{' '}
          {selectedContact?.documentNumber
            ? formatCpfOrCnpj(selectedContact?.documentNumber)
            : selectedContact?.fullName || selectedContact?.name}
        </Text.Heading2Bold>
      </div>

      <InputCurrency
        defaultValue={selectedContact?.amount || 'R$ 0,00'}
        {...register('amount')}
        className={
          watch('amount') !== 'R$ 0,00'
            ? 'input-value'
            : 'input-value without-value'
        }
        error={errors?.amount?.message as string}
      />

      <Text.Heading5Bold marginTop={20}>
        Nome completo do beneficiário
      </Text.Heading5Bold>
      <InputForm
        defaultValue={selectedContact?.fullName || selectedContact?.name}
        placeholder="Luís Santos"
        {...register('fullName')}
        error={errors?.fullName?.message}
      />

      <div className={selectedContact?.documentNumber ? 'hidden-div' : ''}>
        <Text.Heading5Bold marginTop={20}>
          Documento do beneficiário
        </Text.Heading5Bold>
        <InputForm
          defaultValue={formatCpfOrCnpj(selectedContact?.documentNumber || '')}
          placeholder="000.000.000-00"
          {...register('documentNumber')}
          error={errors?.documentNumber?.message}
          maxLength={18}
          maskFunction={formatCpfOrCnpj}
        />
      </div>

      <div className="bank-list-input">
        <Text.Heading5Bold marginTop={25}>
          Banco ou instituição Financeira
        </Text.Heading5Bold>

        <DropDownList
          list={parseBankList || []}
          selected={
            watch('bankIspb') ||
            selectedContact?.bankIspb ||
            selectedContact?.bankCode ||
            bankIspbInList
          }
          setSelected={setBankIspb}
          inputLabel="Selecionar Banco"
          label="Bancos"
          removeBorders
          removePadding
        />

        <InputForm
          defaultValue={
            watch('bank') || selectedContact?.bank || bankCodeInList
          }
          {...register('bank')}
          style={{ display: 'none' }}
        />
      </div>

      <div className="row">
        <div className="item">
          <Text.Heading5Bold>Tipo de conta</Text.Heading5Bold>

          <InputSelect
            defaultValue={selectedContact?.accountType || ''}
            {...register('accountType')}
            placeholder="Selecionar"
            options={accountsTypes}
            className="input-select"
            error={errors?.accountType?.message}
          />
        </div>
        <div className="item">
          <Text.Heading5Bold>Tipo de transferência</Text.Heading5Bold>
          <InputSelect
            defaultValue={selectedContact?.transferType || ''}
            {...register('transferType')}
            options={transferTypes}
            className="input-select"
            error={errors?.transferType?.message}
          />
        </div>
      </div>

      <div className="row">
        <div className="item">
          <Text.Heading5Bold>Agência</Text.Heading5Bold>
          <InputForm
            placeholder="1234"
            {...register('agency')}
            error={errors?.agency?.message}
            defaultValue={selectedContact?.agency || ''}
          />
        </div>
        <div className="item">
          <Text.Heading5Bold>Conta com dígito</Text.Heading5Bold>
          <InputForm
            placeholder="12345678-9"
            {...register('bankAccount')}
            error={errors?.bankAccount?.message}
            defaultValue={selectedContact?.bankAccount || ''}
          />
        </div>
      </div>

      <div className="row-switch">
        <Text.Heading5Bold>
          Salvar contato para próximas transações
        </Text.Heading5Bold>
        <InputSwitch
          hookForm={{ ...register('saveForNextTransfer') }}
          className="input-switch"
        />
      </div>

      <When condition={typeof selectedContact?.indexInArray !== 'undefined'}>
        <GenericButton
          width="100%"
          buttonType="inlineError"
          onClick={() => onRemoveBeneficiary()}
          buttonLoading={false}
          marginTop={42}
        >
          Remover Beneficiário
        </GenericButton>
      </When>
    </Style.FormDataContainer>
  );
};

export default TransferByBankDataForm;
