import { request, useMutation } from '~reactQuery';
import { gql } from 'graphql-request';

type NotificationKeys =
  | 'block_user'
  | 'password_reset'
  | 'migrated_user_new_password'
  | 'create_user'
  | 'slip_emission';

type NotificationTypes = 'email' | 'push' | 'sms';

type NotificationService = 'business' | 'capsrv' | 'banking' | 'kyc';

interface SendNotificationInput {
  email: string;
  urlCallback?: string;
  notificationKey: NotificationKeys;
  notificationType: NotificationTypes;
  notificationService: NotificationService;
  documentNumber?: string;
  args?: object;
  attachments?: object;
}

interface SendNotificationResponse {
  sendNotification: {
    data: string;
    messages: [string];
    isValid: boolean;
  };
}

export function useSendNotification() {
  const mutation = gql`
    mutation SendNotification($params: SendNotificationInput!) {
      sendNotification(params: $params) {
        data
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: SendNotificationInput) => {
    const response = await request<SendNotificationResponse>(mutation, {
      params,
    });
    return response.sendNotification;
  };

  return useMutation('PasswordResetMutation', fetch);
}
