import * as React from 'react';
import { SVGProps } from 'react';

const EditListIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7929 4.79288C16.0118 3.57392 17.9882 3.57392 19.2071 4.79288C20.4261 6.01183 20.4261 7.98814 19.2071 9.20709L17.7071 10.7071L9.41421 19H21V21H7H4H3V20V17V16.5858L3.29289 16.2929L13.2929 6.29288L14.7929 4.79288ZM6.58579 19L15.5858 9.99998L14 8.4142L5 17.4142V19H6.58579ZM15.4142 6.99998L17 8.58577L17.7929 7.79287C18.2308 7.35497 18.2308 6.64499 17.7929 6.20709C17.355 5.76919 16.645 5.76919 16.2071 6.20709L15.4142 6.99998Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default EditListIcon;
