import { Business, Status } from '~reactQuery/types/business.types';
import { gql, request, useQuery } from '~reactQuery';

export interface GetPartnerDataInput {
  processId: number;
}

export interface GetPartnerDataResponse {
  getPartnerData: [
    {
      id: string;
      declaredIncome: string;
      isPowerOfAttorney: boolean;
      powerOfAttorney: string;
      powerOfAttorneyStartDate: string;
      powerOfAttorneyEndDate: string;
      legalRepresent: boolean;
      memberQualification: string;
      pep: string;
      participationPercentage: string;
      politicallyExposed: string;
      documentNumber: string;
      fileFrontDocument: string;
      fileBackDocument: string;
      registerName: string;
      socialName: string;
      countryCode: string;
      number: string;
      zipCode: string;
      addressLine: string;
      buildingNumber: string;
      complement: string;
      neighborhood: string;
      city: string;
      state: string;
      country: string;
      birthDate: string;
      motherName: string;
      email: string;
      documentType: string;
      createdAt: string;
      updatedAt: string;
      deleted: string;
      deletedAt: string;
      status: Status;
      businessId: Business;
      fields: {
        id: string;
        field: string;
        description: string;
        status: Status;
        integratorHasApproved: boolean;
        businessId: number;
        businessDataId: number;
        partnerDataId: number;
      };
    }
  ];
}

export const useGetPartnerData = (
  params: GetPartnerDataInput,
  disableQuery?: boolean
) => {
  const QUERY = gql`
    query GetPartnerData($params: getPartnerDataInput!) {
      getPartnerData(params: $params) {
        id
        declaredIncome
        isPowerOfAttorney
        powerOfAttorney
        powerOfAttorneyStartDate
        powerOfAttorneyEndDate
        memberQualification
        pep
        participationPercentage
        legalRepresent
        documentNumber
        fileFrontDocument
        fileBackDocument
        registerName
        socialName
        countryCode
        number
        zipCode
        addressLine
        buildingNumber
        complement
        neighborhood
        city
        state
        country
        birthDate
        motherName
        email
        documentType
        createdAt
        updatedAt
        deleted
        deletedAt
        status
        businessId
        fields {
          id
          field
          description
          status
          integratorHasApproved
          businessId
          businessDataId
          partnerDataId
        }
        partners {
          id
          legalRepresent
          documentNumber
          fileFrontDocument
          fileBackDocument
          registerName
          socialName
          countryCode
          number
          zipCode
          addressLine
          buildingNumber
          complement
          neighborhood
          city
          state
          country
          birthDate
          motherName
          email
          documentType
          createdAt
          updatedAt
          deleted
          deletedAt
          status
          businessId
        }
      }
    }
  `;

  const fetcher = async () => {
    if (disableQuery || !params.processId) return [];

    const response = await request<GetPartnerDataResponse>(QUERY, { params });
    return response.getPartnerData;
  };

  return useQuery(['getPartnerData', params.processId], fetcher, {
    cacheTime: 0,
    optimisticResults: false,
  });
};
