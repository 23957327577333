import { useMutation, request, gql } from '~reactQuery';
import { Status } from '~reactQuery/types/business.types';

interface CreateBusinessPartnerDataInput {
  businessId: number;
  fileCNPJ: string;
  fileQSA: string;
  socialContract?: string;
  lastContractChange?: string;
  socialStatute?: string;
  lastElection?: string;
}

interface CreateBusinessPartnerDataResponse {
  createBusinessPartnerData: {
    data: {
      id: number;
      fileCNPJ: string;
      fileQSA: string;
      socialContract: string;
      lastContractChange: string;
      socialStatute: string;
      lastElection: string;
      incomes: {
        id: number;
        income: string;
        createdAt: string;
        updatedAt: string;
        deleted: boolean;
        deletedAt: string;
        businessDataId: number;
      };
      fields: {
        id: number;
        field: string;
        description: string;
        status: boolean;
        integratorHasApproved: boolean;
        businessId: number;
        businessDataId: number;
        partnerDataId: number;
      };
      createdAt: string;
      updatedAt: string;
      deleted: boolean;
      deletedAt: string;
      status: Status;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useCreateBusinessPartnerDataMutation() {
  const mutation = gql`
    mutation CreateBusinessPartnerData(
      $params: createBusinessPartnerDataInput!
    ) {
      createBusinessPartnerData(params: $params) {
        isValid
        messages
        data {
          id
          fileCNPJ
          fileQSA
          socialContract
          lastContractChange
          socialStatute
          lastElection
          incomes {
            id
            income
            createdAt
            updatedAt
            deleted
            deletedAt
            businessDataId
          }
          fields {
            id
            field
            description
            status
            integratorHasApproved
            businessId
            businessDataId
            partnerDataId
            value
          }
          createdAt
          updatedAt
          deleted
          deletedAt
          status
        }
      }
    }
  `;

  const fetch = async (params: CreateBusinessPartnerDataInput) => {
    const response = await request<CreateBusinessPartnerDataResponse>(
      mutation,
      {
        params,
      }
    );

    return response.createBusinessPartnerData;
  };

  return useMutation('CreateBusinessPartnerData', fetch);
}
