import * as React from 'react';

import { SVGProps } from 'react';

const DotCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="M21.6 7.662a7.632 7.632 0 0 0-7.6 7.601c0 .84.136 1.673.4 2.47.199.541.786.833 1.338.665.533-.204.816-.787.646-1.332a5.013 5.013 0 0 1-.296-1.937 5.483 5.483 0 1 1 5.813 5.637s-1.067.065-1.597.13c-.196.028-.39.067-.581.116a.07.07 0 0 1-.1 0 .07.07 0 0 1 0-.085l.165-.901 1.002-4.507a1.042 1.042 0 1 0-2.038-.436s-2.383 11.03-2.383 11.13a1.002 1.002 0 0 0 .741 1.223h.056a1 1 0 0 0 1.226-.747.258.258 0 0 1 0-.05c.03-.13.33-1.597.33-1.597a2.704 2.704 0 0 1 2.238-2.128c.23-.036 1.197-.1 1.197-.1a7.596 7.596 0 0 0-.556-15.152Z"
      fill="#000"
    />
    <path
      d="M22.062 25.188a1.267 1.267 0 0 0-1.499.982l-.004.019a1.262 1.262 0 0 0 .967 1.502h.035a1.241 1.241 0 0 0 1.499-.915l.004-.015v-.07a1.313 1.313 0 0 0-1.002-1.503Z"
      fill="#FF4CAB"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DotCoinSVG;
