import * as Style from './PartnersSteps.styles';
import * as Text from '~styles/text';

import { CheckSelect, GenericButton } from '~components';

import ArrowBackIcon from '~assets/images/icons/kyc/arrow-back.svg';
import { HowToFinishProps } from '../Kyc.types';
import { colors } from '~styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const HowToFinishRegister = ({ gotToModalStep }: HowToFinishProps) => {
  const [selected, setSelected] = useState('');
  const { t } = useTranslation();

  const selectItem = (value: string) => {
    if (selected === value) {
      setSelected('');
    } else {
      setSelected(value);
    }
  };

  const goNextStep = () => {
    if (selected === 'link') {
      gotToModalStep('sendEmailOrSms');
    } else {
      gotToModalStep('partnerForm');
    }
  };

  return (
    <Style.Container>
      <div className="content">
        <div
          className="back-button"
          onClick={() => gotToModalStep('documentNumber')}
        >
          <ArrowBackIcon />
        </div>

        <Text.Heading5 color={colors.grayMedium} marginTop={30}>
          {t('Adicionar Sócio')}
        </Text.Heading5>

        <Text.Heading2Bold marginTop={10} marginBottom={25}>
          {t('Como você quer preencher os dados do sócio?')}
        </Text.Heading2Bold>

        <CheckSelect
          text={t(
            'É necessário que a documentação esteja com você para adição manual, inclusive fotos do documento.'
          )}
          title={t('Inserir dados manualmente')}
          iconType="bankIcon"
          selected={selected === 'documents'}
          onClick={() => selectItem('documents')}
        />
      </div>

      <div className="footer">
        <div className="footer-btn">
          <GenericButton
            buttonType="secondary"
            width="100%"
            onClick={goNextStep}
            disabled={selected.length < 1}
          >
            Próximo
          </GenericButton>
        </div>
      </div>
    </Style.Container>
  );
};
