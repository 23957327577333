import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import { useFormContext } from 'react-hook-form';
import EndStepStatus from '~components/EndStepStatus';
import { formatCurrencyFn } from '~helpers/format/currency';
import { WalletType } from '~reactQuery/types/wallets.types';
import * as Text from '~styles/text';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ReceiptIcon from '~assets/images/icons/transactions/receipt.svg';
import {
  WithdrawDetailsProps,
  WithdrawReceipt,
} from '~assets/pdfs/withdraw/WithdrawReceipt';
import dayjs from 'dayjs';
import colors from '~styles/colors';

const WithdrawStatus = () => {
  const { getValues } = useFormContext();
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  const withdrawStatus = getValues('status') as 'SUCCESS' | 'FAILED';

  const amountToWithdraw = getValues('amountToWithdraw') as string;
  const amount = getValues('amount') as string;

  const originWallet = getValues('originWallet') as WalletType;
  const timestamp = getValues('timestamp') as string;
  const withdrawId = getValues('withdrawId') as string;

  const selectedWallet = getValues('selectedWallet') as WalletType;

  const originIsoCode = originWallet.currency.isoCode;
  const originWalletName = originWallet.currency.name;

  const selectedWalletIsoCode = selectedWallet.currency.isoCode;
  const selectedWalletName = selectedWallet.currency.name;

  const walletToWithdrawAddress = getValues(
    'walletAddressToWithdraw'
  ) as string;

  const areSameWallets = originIsoCode === selectedWalletIsoCode;

  const statusInfo = {
    FAILED: {
      icon: <EndStepStatus animType="error" />,
      title: 'Saque não realizado.',
      description:
        'Não foi possível realizar seu saque. Tente novamente em alguns instantes.',
    },
    SUCCESS: {
      icon: <EndStepStatus animType="success" />,
      title: 'Saque realizado!',
      description: `Você realizou um saque de ${formatCurrencyFn(
        originIsoCode,
        amountToWithdraw
      )} para ${walletToWithdrawAddress}.`,
    },
  };

  const date = dayjs(timestamp).format('LLL');

  const details: WithdrawDetailsProps['details'] = {
    amount,
    amountToWithdraw,
    walletAddress: walletToWithdrawAddress,
    selectedWallet: {
      isoCode: selectedWalletIsoCode,
      walletName: selectedWalletName,
    },
    originWallet: {
      isoCode: originIsoCode,
      walletName: originWalletName,
    },
    timestamp: date,
    withdrawId,
    areSameWallets,
  };

  return (
    <div className="status-container">
      {statusInfo[withdrawStatus].icon}

      <Text.Heading1Bold marginTop={25}>
        {statusInfo[withdrawStatus].title}
      </Text.Heading1Bold>

      <Text.Heading5 marginTop={25} textAlign="center">
        {statusInfo[withdrawStatus].description}
      </Text.Heading5>

      {withdrawStatus === 'SUCCESS' && (
        <PDFDownloadLink
          document={<WithdrawReceipt details={details} />}
          fileName={`crypto-withdraw-receipt-${dayjs().format(
            'DD-MM-YYYY hh:mm'
          )}.pdf`}
        >
          <div className="receipt-button">
            <ReceiptIcon />
            <Text.Heading5 marginLeft={10} color={colors.primary}>
              Baixar comprovante
            </Text.Heading5>
          </div>
        </PDFDownloadLink>
      )}

      <ModalMultiStepBottom
        singleButtonText="Concluir"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => setOpenModalMultiStep(false)}
      />
    </div>
  );
};

export default WithdrawStatus;
