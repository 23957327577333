import * as Style from '../../SignTemplate.styles';
import * as Text from '~styles/text';
import { useEffect, useState } from 'react';
import { getSettingsId, setUserInfos } from '~helpers/device';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputPin } from '~components/Inputs';
import colors from '~styles/colors';
import useTimer from '~hooks/useTimer';
import { useTranslation } from 'react-i18next';
import { useValidateSignInTwoFA } from '~reactQuery/mutations/user/useValidateSignInTwoFa';
import useWindowDimensions from '~hooks/windowDimension';
import WarningIcon from '~assets/images/icons/kyc/status/alert.svg';
import { parseError } from '~reactQuery/index';
import { When } from 'react-if';

export const TwoFaAuth = () => {
  const [lockedTime, setLockedTime] = useState<string | null>();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const organization = getSettingsId();

  const { state: navState } = useLocation();

  const { timeLeft, restartTimer, counting } = useTimer(
    navState?.prevTimeLeft || 7200
  );

  const { mutateAsync: validateTwoFA, isLoading } = useValidateSignInTwoFA();

  const { timeLeft: timeLeftLocked, restartTimer: restartTimerLocked } =
    useTimer(lockedTime ? Number(lockedTime) : 60);

  const user = navState?.userDetails?.data?.user;

  const validateLogin = async (token: string) => {
    if (token?.length === 6 && !isLoading) {
      try {
        const resp = await validateTwoFA({
          settingsId: Number(organization),
          userId: user?.id,
          token,
        });

        setUserInfos(resp?.data?.access_token, String(user?.id));

        navigate('/');
      } catch (error: any) {
        const errorKey = parseError(error);

        if (errorKey?.includes('PREVIOUS_STEP_MISSING')) {
          navigate('/');
        }

        if (errorKey?.includes('USER_BLOCKED_TIMEOUT')) {
          const blockTime = errorKey?.split('_');
          setLockedTime(blockTime[Number(blockTime?.length) - 1]);
        }

        //   console.log('error on login with TwoFA -> ', error);
      }
    }
  };

  const goRecovery = () =>
    navigate('/auth/two-fa-auth/forgot', {
      state: {
        prevTimeLeft: timeLeft,
        userDetails: navState?.userDetails,
      },
    });

  useEffect(() => {
    if (timeLeft === 0) {
      navigate('/');
    } else {
      restartTimer();
    }
  }, [counting]);

  useEffect(() => {
    setLockedTime(null);
  }, []);

  useEffect(() => {
    if (timeLeftLocked === 0) {
      setLockedTime(null);
    }
  }, [timeLeftLocked]);

  useEffect(() => {
    if (lockedTime) {
      restartTimerLocked();
    }
  }, [lockedTime]);

  if (localStorage.getItem('@CapBiz:twoFa') !== 'true') navigate('/');

  return (
    <Style.ScrollContainer justifyContent="center">
      <Style.ContentWrapper screenWidth={width}>
        <Text.Heading1Medium marginTop={10} marginBottom={30}>
          {t('Código de autenticação 2FA')}
        </Text.Heading1Medium>

        <Text.Heading5 color={colors.black} marginBottom={30}>
          Insira o código de 6 dígitos gerado pelo seu aplicativo de
          autenticação de dois fatores para confirmar o login.
        </Text.Heading5>

        <InputPin
          size={6}
          secret
          autoFocus
          onFinish={validateLogin}
          disabled={!!lockedTime}
        />
        <When condition={lockedTime}>
          <div className="warning-message-two-factor">
            <div className="icon">
              <WarningIcon />
            </div>

            <Text.Small color={colors.danger} display="inline">
              Tente novamente em {timeLeftLocked} segundos
            </Text.Small>
          </div>
        </When>

        <Text.Paragraph
          color={colors.primary}
          style={{ cursor: 'pointer' }}
          marginTop={30}
          onClick={goRecovery}
        >
          {t('Não tenho mais acesso a este código')}
        </Text.Paragraph>
      </Style.ContentWrapper>
    </Style.ScrollContainer>
  );
};

export default TwoFaAuth;
