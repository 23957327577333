import * as Text from '~styles/text';
import * as Style from '../../../TransferBatch.styles';
import colors from '~styles/colors';
import PixAndTedIcon from '~assets/svg/pixAndTed';
import BankIcon from '~assets/svg/bank';
import { SelectTypeTransferProps } from '../../../TransferBatch.types';

const SelectTypeTransfer = ({
  beneficiaryPerPix,
  setBeneficiaryPerPix,
}: SelectTypeTransferProps) => {
  const handleSelectTypeTransfer = (value: string) => {
    if (value === 'PIX') {
      const updatedBeneficiary = {
        ...beneficiaryPerPix,
        fullName: beneficiaryPerPix?.holderName || '',
        transferType: value,
      };

      return setBeneficiaryPerPix(updatedBeneficiary);
    }

    const updatedBeneficiary = {
      ...beneficiaryPerPix,
      transferType: value,
    };

    return setBeneficiaryPerPix(updatedBeneficiary);
  };

  return (
    <div className="content-add-beneficiary">
      <Text.Heading5Bold marginBottom={24}>
        Selecione o tipo de transferência
      </Text.Heading5Bold>

      <div
        className="row-type-key"
        onClick={() => handleSelectTypeTransfer('PIX')}
      >
        <div className="left">
          <PixAndTedIcon fill={colors.black} width={24} height={24} />
          <Text.Heading5Bold>PIX</Text.Heading5Bold>
        </div>
        <div className="right">
          <Style.CircleCheckbox
            background="PIX"
            type={beneficiaryPerPix?.transferType}
          />
        </div>
      </div>

      <div
        className="row-type-key"
        onClick={() => handleSelectTypeTransfer('TED ou Pix Manual')}
      >
        <div className="left">
          <BankIcon fill={colors.black} width={24} height={24} />
          <Text.Heading5Bold>TED ou Pix Manual</Text.Heading5Bold>
        </div>
        <div className="right">
          <Style.CircleCheckbox
            background="TED ou Pix Manual"
            type={beneficiaryPerPix?.transferType}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectTypeTransfer;
