import * as Text from '~styles/text';

import { Else, If, Then } from 'react-if';

import BarcodeIcon from '~assets/svg/barcode';
import EndStepStatus from '~components/EndStepStatus';
import LoadingSpinner from '~components/LoadingSpinner';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PayBillsPageProps } from '../PayWithPix.types';
import ReceiptIcon from '~assets/images/icons/transactions/receipt.svg';
import colors from '~styles/colors';
import dayjs from 'dayjs';
import { formatCurrencyFn } from '~helpers/format/currency';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetStatementSpecific } from '~reactQuery/queries/bank/useGetStatementSpecific';
import { TransferReceipt } from '~assets/pdfs/transfer/TransferReceipt';

function PayWithPixStatus(props: PayBillsPageProps) {
  const {
    setCurrentStep,
    setOpenModalMultiStep,
    billData,
    paymentResp,
    editedValue,
  } = props;

  function newPayment() {
    setCurrentStep('showKeyExample', {}, true);
  }
  const userOrg = getSelectedUserOrganization();

  const { data: accountDetails, isLoading: loadingGetAccount } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrg),
    });

  const { data: transferSpecificDetails } = useGetStatementSpecific({
    authCode: paymentResp.pixTransferUsingBrCode?.authCode,
    userOrganizationId: Number(userOrg),
  });

  const billDataValue = billData.data.key.payment.totalValue;

  const infosOfStatus = {
    icon: <EndStepStatus animType="success" />,
    title: 'Pagamento realizado!',
    singleButtonText: 'Finalizar',
    singleButtonAction: () => {
      setOpenModalMultiStep(false);
    },
    doubleButtonTextOne: 'Novo Pagamento',
    buttonIcon: <BarcodeIcon />,
    doubleButtonOneAction: newPayment,
    description: (
      <p>
        Você pagou{' '}
        <strong>
          {formatCurrencyFn(
            'BRL',
            String(paymentResp.pixTransferUsingBrCode.value),
            false,
            false,
            true
          )}
        </strong>{' '}
        para <strong>{billData.data.key.holderName}</strong>
      </p>
    ),
  };

  const showDownload = transferSpecificDetails && !loadingGetAccount;

  return (
    <>
      <ModalMultiStepHeader />
      <div className="payment-status content-container">
        {infosOfStatus.icon}
        <Text.Heading1Bold textAlign="center" marginBottom={25} marginTop={25}>
          {infosOfStatus.title}
        </Text.Heading1Bold>
        <Text.Heading4 textAlign="center">
          {infosOfStatus.description}
        </Text.Heading4>

        <If condition={showDownload}>
          <Then>
            <PDFDownloadLink
              document={
                <TransferReceipt
                  accountDetails={accountDetails}
                  transferDetails={transferSpecificDetails}
                  status={transferSpecificDetails?.status}
                  authCode={paymentResp.pixTransferUsingBrCode?.authCode}
                />
              }
              fileName={`transfer-voucher-${dayjs().format('DD-MM-YYYY')}.pdf`}
            >
              {({ loading }) => {
                return loading ? (
                  <LoadingSpinner color={colors.black} />
                ) : (
                  <div className="receipt-button">
                    <ReceiptIcon />
                    <Text.Heading5 marginLeft={10} color={colors.primary}>
                      Baixar comprovante
                    </Text.Heading5>
                  </div>
                );
              }}
            </PDFDownloadLink>
          </Then>
          <Else>
            <LoadingSpinner color={colors.black} />
          </Else>
        </If>
      </div>
      <ModalMultiStepBottom
        singleButtonText={infosOfStatus.singleButtonText}
        singleButtonWidth="1"
        singleButtonAction={infosOfStatus.singleButtonAction}
        doubleButtonTextOne={
          <div className="double-button-one-container">
            {infosOfStatus.buttonIcon}
            <Text.Heading5>{infosOfStatus.doubleButtonTextOne}</Text.Heading5>
          </div>
        }
        doubleButtonOneAction={infosOfStatus.doubleButtonOneAction}
      />
    </>
  );
}

export default PayWithPixStatus;
