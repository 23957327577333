import * as Style from '../SendNewPin.styles';
import * as Text from '~styles/text';

import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import React from 'react';
import ShinyHappyMorning from '~assets/svg/shinyHappyMorning';
import { getSelectedUserOrganization } from '~helpers/device';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { usePinReset } from '~reactQuery/mutations/business/usePinReset';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import RecoverPinSVG from '~assets/svg/recoverPin';

const SendNewPin: React.FC = ({ setStepsAndParams }) => {
  const orgId = getSelectedUserOrganization();
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  const { mutateAsync: sendEmailReset, isLoading: loadingSendPin } =
    usePinReset();

  const { data: partnerInfos, isLoading: loadingGetPartner } = useGetPartner();

  const cpf = partnerInfos?.data?.documentNumber;

  async function goNext() {
    if (cpf && cpf?.length > 0) {
      try {
        const resp = await sendEmailReset({
          userOrganizationId: Number(orgId),
          documentNumber: String(cpf),
        });

        setStepsAndParams('askNewPin', {
          email: resp.data.email || partnerInfos?.data?.email,
          cpf,
        });
      } catch (error) {
        // console.log(error);
      }
    }
  }

  return (
    <>
      <ModalMultiStepHeader title="Recuperar PIN de 4 dígitos" />
      <div className="recovery-pin-body">
        <RecoverPinSVG />

        <Text.Heading5 marginTop={10}>
          Esqueceu o seu PIN? Fique tranquilo, estamos aqui para facilitar o
          processo de recuperação em apenas alguns passos simples. A segurança
          dos nossos serviços é fundamental, e sabemos que situações como esta
          podem acontecer com qualquer um.
        </Text.Heading5>

        <Text.Heading5 marginTop={20}>
          Portanto, não se preocupe, pois vamos auxiliá-lo por meio de um
          procedimento rápido e eficiente para que você possa recuperar seu PIN
          com facilidade.
        </Text.Heading5>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Vamos começar"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        singleButtonWidth="180px"
        doubleButtonOneAction={() => setOpenModalMultiStep(false)}
        singleButtonLoading={loadingSendPin || loadingGetPartner}
      />
    </>
  );
};

export default SendNewPin;
