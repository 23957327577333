import * as S from '../Organizations.styles';
import * as Text from '~styles/text';

import { clearStorage, setIsNewOrg } from '~helpers/device';

import BuildingSVG from '~assets/svg/building';
import CardOrganization from '~components/CardOrganization';
import ExitSvg from '~assets/svg/exit';
import PlusSVG from '~assets/svg/plus';
import { RoundButtonIcon } from '~components/Buttons';
import { SelectOrgProps } from '../Organizations.types';
import UserSVG from '~assets/svg/user';
import { When } from 'react-if';
import { capitalize } from '~helpers/format/capitalize';
import { truncate } from '~helpers/format/truncate';
import { useCreateProcessMutation } from '~reactQuery/mutations/kyc/useCreateProcess';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

const SelectOrg = (props: SelectOrgProps) => {
  const { partnerInfos, setOrgId, setCurrentStep, kycProcessData } = props;

  const { mutateAsync: createKycProcess } = useCreateProcessMutation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // all orgs approved by all steps
  const userOrganizations = partnerInfos?.data?.userOrganizations;

  // short by the last process id
  const lastProcess = kycProcessData?.sort((a, b) =>
    a.id > b.id ? -1 : 1
  )?.[0];

  // enable to create when last process was === DONE or when no processStatus
  const enableAddOrg =
    !lastProcess?.processStatus ||
    ['DONE'].includes(String(lastProcess?.processStatus));
  // show card of new organization when last process == AWAITING or PENDING
  const showCardOfUncompletedOrg = ['AWAITING', 'PENDING', 'DENIED'].includes(
    String(lastProcess?.processStatus)
  );
  // when in review disable actions , only display is allowed
  const isLastOrgInReview = String(lastProcess?.processStatus) === 'AWAITING';

  const addNewOrg = async () => {
    if (enableAddOrg) {
      await createKycProcess();
    }
    setIsNewOrg(true);

    navigate('/kyc', { state: { newOrg: true } });
  };

  function selectOrganizationId(orgId: string) {
    setCurrentStep(1);
    setOrgId(Number(orgId));
  }

  function logout() {
    clearStorage(queryClient);
    navigate('/auth');
  }

  return (
    <S.Container>
      <div className="content">
        <Text.Heading1Regular
          marginTop={52}
          marginBottom={52}
          textAlign="center"
        >
          Olá, que bom te ver aqui <br /> Para começar, selecione uma
          Organização.
        </Text.Heading1Regular>

        <div className="orgs-grid-container">
          {userOrganizations?.map((org) => {
            // when org is inactivated disabled actions
            if (!org?.active) {
              return (
                <CardOrganization
                  onClick={() => {}}
                  key={org?.organizationsId}
                  isAdmin={org?.admin}
                  name={truncate(capitalize(org?.organizations?.name), 20)}
                  cardImage=""
                  noBorder
                  disable
                />
              );
            }

            return (
              <CardOrganization
                onClick={() =>
                  selectOrganizationId(String(org?.organizationsId))
                }
                organizationId={org.organizationsId}
                key={org?.organizationsId}
                isAdmin={org?.admin}
                name={truncate(capitalize(org?.organizations?.name), 20)}
                cardImage={org?.organizations?.image}
              />
            );
          })}

          <When condition={showCardOfUncompletedOrg}>
            <CardOrganization
              onClick={isLastOrgInReview ? () => {} : addNewOrg}
              key="new-org"
              isAdmin={false}
              name={truncate(
                capitalize(
                  lastProcess?.business[0]?.tradingName || 'Nova Organização'
                ),
                20
              )}
              cardImage=""
              svgImage={<BuildingSVG />}
              inReview={isLastOrgInReview}
              chipText={
                isLastOrgInReview ? 'Em processo de análise' : 'Em andamento'
              }
            />
          </When>
        </div>

        <div className="container-account-actions">
          <div className="action-item disable">
            <RoundButtonIcon
              noHover
              onClick={() => {}}
              buttonSize="iconWallet"
              buttonType="default"
              disabled
            >
              <UserSVG />
            </RoundButtonIcon>
            <Text.Paragraph>Minha conta</Text.Paragraph>
          </div>

          <div
            className={!enableAddOrg ? 'action-item disable' : 'action-item'}
          >
            <RoundButtonIcon
              onClick={!enableAddOrg ? () => {} : addNewOrg}
              buttonSize="iconWallet"
              buttonType="default"
              disabled={!enableAddOrg}
            >
              <PlusSVG />
            </RoundButtonIcon>
            <Text.Paragraph>Nova Organização</Text.Paragraph>
          </div>

          <div className="action-item">
            <RoundButtonIcon
              onClick={logout}
              buttonSize="iconWallet"
              buttonType="default"
            >
              <ExitSvg />
            </RoundButtonIcon>
            <Text.Paragraph>Sair</Text.Paragraph>
          </div>
        </div>
      </div>
    </S.Container>
  );
};

export default SelectOrg;
