import * as Style from './QuickButton.styles';
import { useEffect, useState } from 'react';
import { QuickButtonProps } from './QuickButton.types';
import { When } from 'react-if';

const QuickButton = (props: QuickButtonProps) => {
  const {
    fitContent,
    buttonWidth,
    backgroundColorButton,
    backgroundHoverButton,
    disabled,
    icon,
    children,
  } = props;

  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else {
      setIsRippling(false);
    }
  }, [coords]);

  useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  return (
    <Style.Container
      fitContent={fitContent}
      width={buttonWidth}
      backgroundColor={backgroundColorButton}
      backgroundColorHover={backgroundHoverButton}
      onClick={(e: any) => {
        if (!disabled) {
          const rect = e.target.getBoundingClientRect();
          setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
        }
      }}
      disabled={disabled}
    >
      <When condition={isRippling}>
        <span
          className="ripple"
          style={{
            left: coords.x,
            top: coords.y,
          }}
        />
      </When>

      <div className="hover-animation">
        <When condition={!!icon}>{icon}</When>
        {children}
      </div>
    </Style.Container>
  );
};

export default QuickButton;
