import { gql, request, useMutation } from '~reactQuery/index';

import { User } from '~reactQuery/types/business.types';
import { setUserInfos } from '~helpers/device';

interface SignUpInput {
  capu?: string;
  documentNumber: string;
  passwd: string;
  email: string;
  phone: string;
  registerName?: string;
  socialName?: string;
}

interface SignUpResponse {
  signUp: {
    data: {
      temporaryPassword: boolean;
      user: User;
      access_token: string;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useSignUpMutation(token: string) {
  const mutation = gql`
    mutation SignUp($params: SignUpInput!) {
      signUp(params: $params) {
        data {
          access_token
          temporaryPassword
          user {
            id
            registerName
            capu
            socialName
            phone
            email
            documentNumber
            deletedAt
            createdAt
            updatedAt
            active
          }
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: SignUpInput) => {
    const response = await request<SignUpResponse>(
      mutation,
      {
        params,
      },
      { Authorization: `Bearer ${token}` }
    );
    return response.signUp;
  };

  return useMutation('useSignUp', fetch, {
    onSuccess: (resp) => {
      localStorage.setItem(
        '@CapBiz:settings-id',
        String(resp?.data?.user?.settings?.id)
      );

      localStorage.setItem(
        '@CapBiz:user-documentNumber',
        String(resp?.data?.user?.documentNumber)
      );

      setUserInfos(resp.data.access_token, String(resp.data.user.id));
    },
  });
}
