import { ClientError } from 'graphql-request';
import { ErrorToast } from '~components/Toast';
import { ErrorsConstantsAvoid } from './errorsConstantsAvoid';
import { formatErrorMessage } from './errorsConstants';
import { parseError } from '~reactQuery';
// import { captureException } from '@sentry/react';

export const handleErrors = async (error: ClientError) => {
  const errorKey = parseError(error);
  const avoidToast =
    !ErrorsConstantsAvoid.includes(errorKey) &&
    !['CancelledError2', 'CancelledError'].includes(error.constructor.name);

  if (formatErrorMessage(errorKey) && avoidToast) {
    ErrorToast({
      title: formatErrorMessage(errorKey).title,
      subtitle: formatErrorMessage(errorKey).description,
      autoCloseEnable: true,
    });

    formatErrorMessage(errorKey)?.action?.();
  } else if (avoidToast) {
    // captureException(error);

    ErrorToast({
      title: 'Ocorreu um erro, tente novamente',
      subtitle: '',
      autoCloseEnable: true,
    });
  }
};
