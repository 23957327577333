import * as Style from '../EnableTwoFa.styles';
import * as Text from '~styles/text';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import React from 'react';
import ShinyHappyOutdoorsSVG from '~assets/svg/shinyHappyOutdoors';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

const SuccessActiveTwoFa: React.FC = () => {
  const { setOpenModalMultiStep, setSection } = useActionsOnModalMultiStep();

  const goToRescueCodes = () => {
    setOpenModalMultiStep(false);

    setTimeout(() => {
      setSection('recoveryCodesModals');
      setOpenModalMultiStep(true);
    }, 500);
  };

  return (
    <>
      <div className="enable-tow-fa-body">
        <ShinyHappyOutdoorsSVG />
        <Text.Heading2 marginTop={25}>Você ativou o 2FA!</Text.Heading2>
        <Text.Heading5 marginTop={20}>
          Agora ao fazer login na sua conta, além da sua senha e PIN, vamos
          pedir o código de autenticação!
        </Text.Heading5>
      </div>

      <ModalMultiStepBottom
        singleButtonWidth="80%"
        singleButtonText="Concluir"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => setOpenModalMultiStep(false)}
      />
    </>
  );
};

export default SuccessActiveTwoFa;
