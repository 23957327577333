import { gql, request, useMutation } from '~reactQuery';

interface GetCustomerDataEmailResponse {
  getCustomerDataEmail: {
    data: {
      email: string;
    };
  };
}

interface GetCustomerDataEmailInput {
  documentNumber: string;
  urlCallback: string;
  disableQuery?: boolean;
}

export function useGetCustomerDataEmail() {
  const QUERY = gql`
    query GetCustomerDataEmail($params: getCustomerDataEmailInput!) {
      getCustomerDataEmail(params: $params) {
        data {
          email
        }
      }
    }
  `;

  const fetcher = async ({
    documentNumber,
    urlCallback,
    disableQuery,
  }: GetCustomerDataEmailInput) => {
    if (documentNumber.length < 11 || disableQuery) return null;

    const response = await request<GetCustomerDataEmailResponse>(QUERY, {
      params: {
        urlCallback,
        documentNumber,
      },
    });

    return response?.getCustomerDataEmail?.data || null;
  };

  return useMutation('getCustomerDataEmail', fetcher);
}
