import * as Text from '~styles/text';
import * as Style from '../../../TransferBatch.styles';
import PersonIcon from '~assets/svg/person';
import colors from '~styles/colors';
import PhoneSVG from '~assets/svg/phone';
import { SelectKeyTypeProps } from '../../../TransferBatch.types';
import { useResolvePixKey } from '~reactQuery/mutations/pix/useResolvePixKey';
import { getSelectedUserOrganization } from '~helpers/device';
import {
  validateCPF,
  validatePhoneNumber,
} from '~validations/personalValidation';
import { Else, If, Then } from 'react-if';
import LoadingSpinner from '~components/LoadingSpinner';

const SelectKeyType = ({
  beneficiaryPerPix,
  setBeneficiaryPerPix,
  isLoadingSelectPix,
  setIsLoadingSelectPix,
}: SelectKeyTypeProps) => {
  const { mutateAsync: resolvePixKey } = useResolvePixKey();
  const userOrganization = getSelectedUserOrganization();

  const searchIdentify = async (value: string, type: string) => {
    const isValidToContinue = validateCPF(value) || validatePhoneNumber(value);

    if (!value || !isValidToContinue) {
      return;
    }

    try {
      const resolve = await resolvePixKey({
        key: type === 'PHONE' ? `55${value.trim()}` : value.trim(),
        userOrganizationId: Number(userOrganization),
        documentNumber: String(value),
      });
      const hasDocumentNumber = validateCPF(value)
        ? {
            documentNumber: value,
          }
        : {};

      const objectPix = {
        ...resolve,
        ...resolve?.key,
        ...hasDocumentNumber,
        name: resolve?.key?.holderName,
        keyValue: value,
        keyType: type,
      };

      const { key, ...newObject } = objectPix;

      setBeneficiaryPerPix(newObject);
    } catch (error) {
      const isKeyValid = validateCPF(value) || validatePhoneNumber(value);

      if (isKeyValid) {
        setBeneficiaryPerPix({
          keyValue: value,
          keyType: type,
        });
      }
    }
  };

  const handleSelectKeyType = async (value: string) => {
    setIsLoadingSelectPix(true);
    await searchIdentify(beneficiaryPerPix?.keyValue || '', value);
    setIsLoadingSelectPix(false);
  };

  return (
    <div className="content-add-beneficiary">
      <Text.Heading5Bold marginBottom={24}>
        Não conseguimos identificar o tipo número que você digitou.
      </Text.Heading5Bold>
      <If condition={isLoadingSelectPix}>
        <Then>
          <div className="loading-spinner">
            <LoadingSpinner
              color={colors.primary}
              size={30}
              strokeSize={12.5}
            />
          </div>
        </Then>
        <Else>
          <div
            className="row-type-key"
            onClick={() => handleSelectKeyType('CPF')}
          >
            <div className="left">
              <PersonIcon fill={colors.black} width={24} height={24} />
              <Text.Heading5Bold>CPF</Text.Heading5Bold>
            </div>
            <div className="right">
              <Style.CircleCheckbox
                background="CPF"
                type={beneficiaryPerPix?.keyType || ''}
              />
            </div>
          </div>

          <div
            className="row-type-key"
            onClick={() => handleSelectKeyType('PHONE')}
          >
            <div className="left">
              <PhoneSVG fill={colors.black} width={24} height={24} />
              <Text.Heading5Bold>Celular</Text.Heading5Bold>
            </div>
            <div className="right">
              <Style.CircleCheckbox
                background="PHONE"
                type={beneficiaryPerPix?.keyType || ''}
              />
            </div>
          </div>
        </Else>
      </If>
    </div>
  );
};

export default SelectKeyType;
