import { APP_ROUTES } from './navigation';
import Activities from '~assets/images/icons/menu/activities.svg';
import Api from '~assets/images/icons/menu/api.svg';
import Bills from '~assets/images/icons/menu/bills.svg';
import CapWallets from '~assets/images/icons/menu/cap-wallets.svg';
import Cappay from '~assets/images/icons/menu/cappay.svg';
import Cards from '~assets/images/icons/menu/cards';
import Contacts from '~assets/images/icons/menu/contacts.svg';
import Dashboard from '~assets/images/icons/menu/dashboard.svg';
import PixIcon from '~assets/images/icons/menu/pix';

interface Option {
  id: number | string;
  icon: JSX.Element;
  title: string;
  route: string;
  comingSoon?: boolean;
}

export const menuOptions: Option[] = [
  {
    id: 1,
    icon: <Dashboard />,
    title: 'Dashboard',
    route: APP_ROUTES.DASHBOARD,
  },
  {
    id: 2,
    icon: <Activities />,
    title: 'Atividades',
    route: APP_ROUTES.ACTIVITIES,
  },
  {
    id: 3,
    icon: <CapWallets />,
    title: 'CapConta e Carteiras',
    route: APP_ROUTES.CAP_ACCOUNT,
  },
  {
    id: 4,
    icon: <PixIcon />,
    title: 'Área Pix',
    route: APP_ROUTES.PIX,
  },
  {
    id: 5,
    icon: <Bills />,
    title: 'Boletos e Cobranças',
    route: APP_ROUTES.BILLS,
  },
  {
    id: 7,
    icon: <Cards />,
    title: 'Cartões (em breve)',
    route: APP_ROUTES.CARDS,
    comingSoon: true,
  },
  {
    id: 6,
    icon: <Contacts />,
    title: 'Contatos e Favorecidos (em breve)',
    route: APP_ROUTES.CONTACTS,
    comingSoon: true,
  },
  {
    id: 8,
    icon: <Api />,
    title: 'API (em breve)',
    route: APP_ROUTES.API,
    comingSoon: true,
  },
  {
    id: 9,
    icon: <Cappay />,
    title: 'CapPay (em breve)',
    route: APP_ROUTES.CAP_PAY,
    comingSoon: true,
  },
];
