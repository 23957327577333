import { PixKey, PixKeyTypes } from '~reactQuery/types/pix.types';
import { gql, request, useMutation } from '~reactQuery/index';

import { PIX_QUERY_KEYS } from '~src/constants/pix';
import { getSettingsId } from '~helpers/device';
import { useQueryClient } from 'react-query';

interface CreatePixKeyInput {
  userOrganizationId: number;
  value?: string;
  type: PixKeyTypes;
  documentNumber: string;
  pin: string;
  token?: string;
}

interface CreatePixKeyResponse {
  createPix: {
    data: PixKey;
    messages: string[];
    isValid: boolean;
  };
}

export const useCreatePixKey = () => {
  const organization = getSettingsId();
  const queryClient = useQueryClient();

  const QUERY = gql`
    mutation CreatePix($params: CreatePixInput!) {
      createPix(params: $params) {
        data {
          id
          type
          value
          createdAt
          updatedAt
          deletedAt
          userOrganizationId
        }
        messages
        isValid
      }
    }
  `;

  const fetcher = async (params: CreatePixKeyInput) => {
    const response = await request<CreatePixKeyResponse>(QUERY, {
      params: {
        ...params,
        settingsId: Number(organization),
      },
    });
    return response.createPix.data;
  };

  return useMutation([PIX_QUERY_KEYS.CREATE_PIX_KEY], fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries([PIX_QUERY_KEYS.GET_PIX_KEY]);
    },
  });
};
