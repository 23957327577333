import * as Text from '~styles/text';
import { useMemo } from 'react';
import { APP_ROUTES } from '~data/navigation';
import { LoadingSpinner, EmptyTransactions } from '..';
import { TableItem } from '~components/Table';
import { Transactions } from './TransactionsList.styles';
import { colors } from '~styles';
import dayjs from 'dayjs';
import { useGetStatement } from '~reactQuery/queries/bank/useGetStatement';
import { useNavigate } from 'react-router-dom';
import { Else, If, Then, When } from 'react-if';

const TransactionList = () => {
  const navigate = useNavigate();

  const dayMoreOne = dayjs().add(1, 'day').format('YYYY-MM-DD');

  const { data: statement, isLoading } = useGetStatement({
    to: dayMoreOne,
    from: '2000-11-01',
    perPage: 5,
  });

  const statementList = useMemo(() => {
    return statement?.pages?.flat().filter((_, idx) => idx < 5);
  }, [statement]);

  return (
    <>
      <Transactions>
        <div className="transaction-header">
          <div className="item-41">
            <Text.Paragraph color={colors.grayMedium}>Tipo</Text.Paragraph>
          </div>
          <div className="item-33 middle destination">
            <Text.Paragraph color={colors.grayMedium}>Destino</Text.Paragraph>
          </div>
          <div className="item-24 right">
            <Text.Paragraph color={colors.grayMedium}>Valor</Text.Paragraph>
          </div>
        </div>

        <When condition={isLoading}>
          <div className="empty-transactions">
            <div className="container-loading">
              <LoadingSpinner
                color={colors.primary}
                size={60}
                strokeSize={25}
              />
            </div>
          </div>
        </When>

        <If condition={statementList?.length === 0 && !isLoading}>
          <Then>
            <div className="empty-transactions">
              <EmptyTransactions noTransactionButton />
            </div>
          </Then>

          <Else>
            <When condition={statementList?.length}>
              {statementList?.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`${item?.authCode}-${index}`}>
                  <TableItem {...item} />
                </div>
              ))}
            </When>
          </Else>
        </If>
      </Transactions>

      <a
        className="outline-button"
        onClick={() => navigate(APP_ROUTES.ACTIVITIES)}
      >
        <Text.ParagraphBold color={colors.primary}>
          Todas as Movimentações
        </Text.ParagraphBold>
      </a>
    </>
  );
};

export default TransactionList;
