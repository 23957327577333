import * as React from 'react';

import { SVGProps } from 'react';

const FtmCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="M31 11.164v-.21c0-1.317-.325-2.005-1.241-2.635a3.256 3.256 0 0 0-.267-.172l-.573-.344-.63 1.05.572.344c.077.058.153.096.21.134l.02.02c.267.19.42.324.515.515.115.248.172.592.172 1.126v.172H31ZM14.807 26.669c-.286-.191-.439-.344-.534-.535-.115-.248-.172-.592-.172-1.126v-.172H12.86v.21c0 1.317.324 2.005 1.241 2.635.076.057.172.114.267.172l.573.343.63-1.05-.573-.343c-.057-.039-.133-.077-.19-.134Z"
      fill="#1969FF"
    />
    <path
      d="M29.186 10.553c-.039-.344-.268-.65-.63-.84L22.96 6.79c-.592-.305-1.49-.305-2.1 0l-5.596 2.94c-.305.173-.515.402-.592.688h-.038v14.933c.02.381.248.706.63.916l5.595 2.94c.306.153.669.23 1.05.23.382 0 .745-.077 1.05-.23l5.595-2.94c.401-.21.63-.554.65-.916v-14.76l-.02-.039Zm-13.31 8.574 5.003 2.616c.592.305 1.49.305 2.1 0l5.004-2.635v6.072l-5.213 2.75-.058.018c-.267.134-.534.268-.782.268h-.058c-.267-.02-.554-.153-.84-.306l-5.156-2.692v-6.091Zm5.576-5.672-5.308-2.787 5.308-2.788c.23-.134.707-.134.936 0l5.308 2.788-5.308 2.787c-.23.134-.707.134-.936 0Zm-.153 1.68v5.443l-5.174-2.712 5.174-2.73Zm1.242 5.443v-5.442l5.174 2.711-5.174 2.73Zm-6.665-3.972v-4.678l4.45 2.33-4.45 2.348Zm7.638-2.349 4.469-2.348v4.697l-4.469-2.349Z"
      fill="#1969FF"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FtmCoinSVG;
