import { SVGProps } from 'react';

const UmbrellaSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '151'}
    height={props.height || '127'}
    viewBox="0 0 151 127"
    fill="none"
    {...props}
  >
    <g id="Ativo 3 1" clipPath="url(#clip0_20801_6762)">
      <g id="Camada 1">
        <g id="Group">
          <path
            id="Vector"
            d="M141.369 90.3927V126.019H24.6014V80.6854L50.2005 50.9775V76.4548C50.2005 79.4904 51.4295 82.2372 53.4173 84.2251C55.4052 86.213 58.1549 87.4448 61.1877 87.4448C67.259 87.4448 72.1777 82.5233 72.1777 76.4548V72.0967C72.1777 66.0283 77.0993 61.1067 83.1649 61.1067H84.6629C90.7313 61.1067 95.6501 66.0283 95.6501 72.0967V102.541C95.6501 108.612 100.572 113.531 106.637 113.531H107.725C113.793 113.531 118.712 108.612 118.712 102.541V90.3927C118.712 84.3242 123.633 79.4027 129.702 79.4027H130.382C136.45 79.4027 141.369 84.3242 141.369 90.3927Z"
            fill="#F3F4F9"
          />
          <path
            id="Vector_2"
            d="M114.685 95.8608C114.685 112.673 101.056 126.302 84.2438 126.302C83.1847 126.302 82.137 126.248 81.1062 126.144C65.7666 124.572 53.8025 111.614 53.8025 95.8608C53.8025 79.0487 67.4317 65.4194 84.2438 65.4194C91.3912 65.4194 97.9665 67.8831 103.16 72.0118C110.183 77.5875 114.685 86.1988 114.685 95.8608Z"
            fill="#DBA72A"
          />
          <path
            id="Vector_3"
            d="M99.1813 81.7785L85.0877 116.374C84.8073 116.385 84.527 116.391 84.2438 116.391C83.5302 116.391 82.8251 116.354 82.1285 116.283C71.7841 115.224 63.7136 106.486 63.7136 95.8608C63.7136 84.5224 72.9055 75.3306 84.2438 75.3306C89.0635 75.3306 93.498 76.9928 97.0009 79.7764C97.7768 80.3909 98.5045 81.0592 99.1813 81.7785Z"
            fill="#F3BA2F"
          />
          <path
            id="Vector_4"
            d="M104.76 95.8155C104.76 106.888 96.0097 115.915 85.0452 116.36L99.1586 81.7134C102.633 85.3947 104.76 90.3559 104.76 95.8155Z"
            fill="#F3BA2F"
          />
          <path
            id="Vector_5"
            d="M114.685 95.8151C114.685 112.653 101.036 126.302 84.1985 126.302C83.1366 126.302 82.0888 126.248 81.0552 126.143L85.0423 116.359C96.0069 115.918 104.757 106.887 104.757 95.8151C104.757 90.3555 102.63 85.3943 99.1558 81.713L103.14 71.9321C110.174 77.5135 114.682 86.139 114.682 95.8151H114.685Z"
            fill="#F3BA2F"
          />
          <path
            id="Vector_6"
            d="M81.2789 38.4751L112.788 115.822C115.736 123.057 123.99 126.534 131.225 123.586C138.46 120.638 141.938 112.384 138.99 105.149L136.065 97.9674"
            stroke="#3F4251"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_7"
            d="M24.4004 80.1728L150.399 28.8416C128.382 16.0704 100.954 13.1707 75.5192 23.2716C75.2756 23.365 75.0349 23.4641 74.7942 23.5604C49.1556 34.0039 31.29 55.4148 24.4004 80.17V80.1728Z"
            fill="#0264DC"
          />
          <path
            id="Vector_8"
            d="M150.402 28.8414L87.5003 54.3753L75.5192 23.2713C80.4294 21.3231 85.4105 19.8591 90.417 18.8538C91.3741 18.6612 92.3341 18.4857 93.2941 18.3271C103.811 16.5912 114.376 16.8687 124.5 18.9586C124.894 19.0407 125.287 19.1228 125.681 19.2106C134.371 21.1277 142.717 24.3842 150.402 28.8414Z"
            fill="#0F90FF"
          />
          <path
            id="Vector_9"
            d="M55.7847 33.8454C55.7847 38.4583 52.0439 42.199 47.431 42.199C47.1393 42.199 46.8533 42.1849 46.5701 42.1566C42.3622 41.7261 39.0773 38.1695 39.0773 33.8454C39.0773 29.2324 42.8181 25.4917 47.431 25.4917C49.3934 25.4917 51.1972 26.1685 52.6216 27.3012C54.55 28.8303 55.7847 31.1948 55.7847 33.8454Z"
            fill="#CED6EF"
          />
          <path
            id="Vector_10"
            d="M126.862 55.0834C126.862 58.4447 124.135 61.1716 120.773 61.1716C120.561 61.1716 120.351 61.1603 120.145 61.1405C117.078 60.8262 114.685 58.2351 114.685 55.0834C114.685 51.7221 117.412 48.9951 120.773 48.9951C122.203 48.9951 123.517 49.4878 124.557 50.3147C125.961 51.4304 126.862 53.1521 126.862 55.0834Z"
            fill="#F3F4F9"
          />
          <path
            id="Vector_11"
            d="M24.6014 101.668V126.019H0.00201416C0.0756397 112.556 11.0147 101.666 24.4938 101.666C24.5306 101.666 24.5646 101.666 24.6014 101.666V101.668Z"
            fill="#F3F4F9"
          />
          <path
            id="Vector_12"
            d="M48.9857 126.019H24.6014V101.668C38.0324 101.725 48.912 112.593 48.9857 126.019Z"
            fill="#CED6EF"
          />
          <path
            id="Vector_13"
            d="M25.6719 74.0024L96.7857 45.0308"
            stroke="#F3F4F9"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_14"
            d="M124.596 17.138C124.596 17.7524 124.565 18.3613 124.5 18.9588C114.376 16.8689 103.811 16.5914 93.294 18.3273C92.3341 18.4859 91.3741 18.6614 90.417 18.854C90.3604 18.2905 90.332 17.7185 90.332 17.138C90.332 7.67707 98.0032 0.00585938 107.464 0.00585938C116.925 0.00585938 124.596 7.67707 124.596 17.138Z"
            fill="#F3F4F9"
          />
          <path
            id="Vector_15"
            d="M124.5 18.9586C123.591 27.5643 116.311 34.2699 107.464 34.2699C98.6177 34.2699 91.2778 27.5105 90.417 18.8538C91.3741 18.6612 92.3341 18.4857 93.2941 18.3271C103.811 16.5912 114.376 16.8687 124.5 18.9586Z"
            fill="#0264DC"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_20801_6762">
        <rect
          width="150.4"
          height="126.296"
          fill="white"
          transform="translate(0.00201416 0.00585938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default UmbrellaSVG;
