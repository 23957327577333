import ChangePasswordSuccess from '~assets/images/change-password-success.svg';
import { GenericButton } from '~components';
import useWindowDimensions from '~hooks/windowDimension';
import { colors } from '~styles';
import { breakpoints } from '~styles/metrics';
import * as Text from '~styles/text';
import { useNavigate } from 'react-router-dom';

export const Success = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  return (
    <>
      <ChangePasswordSuccess />
      <Text.Heading1Medium marginTop={30} marginBottom={30}>
        Senha alterada com sucesso!
      </Text.Heading1Medium>

      <Text.Heading5
        color={colors.black}
        marginBottom={10}
        lineHeight={140}
        width={breakpoints.desktop < width ? '100%' : ''}
      >
        Utilize sua nova senha para realizar login na conta empresarial.
      </Text.Heading5>

      <Text.Heading5
        color={colors.black}
        marginBottom={30}
        lineHeight={140}
        width={breakpoints.desktop < width ? '100%' : ''}
      >
        Lembre-se, que não solicitamos dados pessoais fora das nossas
        aplicações.
      </Text.Heading5>

      <div className="buttons-wrapper">
        <GenericButton
          buttonType="inline"
          width="auto"
          onClick={() => navigate('/auth/sign-in')}
        >
          Fazer login
        </GenericButton>
      </div>
    </>
  );
};
