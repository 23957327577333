import * as React from 'react';

const FileSVG = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00005 3.00403C5.3432 3.00403 4.00006 4.34717 4.00005 6.00402L4 18.004C3.99999 19.6608 5.34313 21.004 6.99999 21.004L16.9568 21.004C18.608 21.004 19.9488 19.6696 19.9568 18.0184L20 9.00883H20V9.00403V8.00403C20 7.73881 19.8946 7.48446 19.7071 7.29692L15.7071 3.29692C15.5196 3.10939 15.2652 3.00403 15 3.00403H14H7.00005ZM6.00005 6.00402C6.00005 5.45174 6.44777 5.00403 7.00005 5.00403H13V9.00403C13 9.55631 13.4477 10.004 14 10.004H17.9952L17.9568 18.0088C17.9541 18.5592 17.5072 19.004 16.9568 19.004L7 19.004C6.44771 19.004 6 18.5563 6 18.004L6.00005 6.00402ZM15 8.00403V5.41824L17.5858 8.00403H15ZM7.99999 13.004H16V11.004H7.99999V13.004ZM11 9.00403H7.99999V7.00403H11V9.00403ZM7.99999 17.004H16V15.004H7.99999V17.004Z"
      fill="#272937"
    />
  </svg>
);

export default FileSVG;
