import * as Style from './PixKeyCards.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';

import { Else, If, Then } from 'react-if';
import { ReactNode } from 'react';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetPixKey } from '~reactQuery/queries/pix/useGetPixKey';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';

import DeleteSVG from '~assets/svg/delete';
import RandomKeyIcon from '~assets/images/icons/pix/randomKey';
import CNPJKeyIcon from '~assets/images/icons/pix/cnpj';
import MenuSVG from '~assets/images/icons/menu';
import PlusCardSVG from '~assets/svg/plusCard';
import CopyPasteIcon from '~assets/svg/copyPaste';
import { DropDown } from '~components/DropDown';
import { DropDownItem } from '~components/DropDown/DropDownItem';
import { GenericRowProps } from '~components/GenericRow/GenericRow.types';
import { SuccessToast } from '~components/Toast';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { PermissionGate, usePermission } from '~helpers/permissionGate';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

const PixKeyCards = () => {
  const { data: partnerInfos } = useGetPartner();
  const { granted: buttonsPixGranted } = usePermission('buttonsPix');
  const userOrganizationId = getSelectedUserOrganization();

  const { setOpenModalMultiStep, setSection, setParams } =
    useActionsOnModalMultiStep();

  const userDocumentNumber = partnerInfos?.data.documentNumber;

  const { data: pixKeys = [] } = useGetPixKey({
    documentNumber: userDocumentNumber,
    userOrganizationId: Number(userOrganizationId),
  });

  const hasPixKeys = pixKeys && pixKeys?.length > 0;

  const handleCopyPixKeyToClipboard = (key: string) => {
    navigator.clipboard.writeText(key);

    SuccessToast({
      title: 'Chave Pix copiada com sucesso!',
      autoCloseEnable: true,
    });
  };

  const openDeletePixKeyModal = (key: string) => {
    setSection('deletePixKey');

    setParams({ pixKey: key });
    setOpenModalMultiStep(true);
  };

  const openCreatePixKeyModal = () => {
    setSection('createPix');

    setOpenModalMultiStep(true);
  };

  const renderRightContent: Record<
    GenericRowProps['rightContentType'],
    ReactNode
  > = {
    text: undefined,
    arrow: undefined,
    arrowOpenNav: undefined,
    component: undefined,
    menu: <MenuSVG />,
  };

  return (
    <Style.ScrollContainer>
      <If condition={hasPixKeys}>
        <Then>
          {pixKeys?.map((key) => (
            <Style.PixCard key={key.id}>
              <Style.IconAndOptions>
                <div className="key-icon">
                  {key.type === 'CNPJ' ? <CNPJKeyIcon /> : <RandomKeyIcon />}
                </div>

                <div>
                  <DropDown
                    content={
                      <>
                        <DropDownItem
                          icon={<CopyPasteIcon />}
                          title="Copiar Chave"
                          onClick={() => handleCopyPixKeyToClipboard(key.value)}
                        />

                        <DropDownItem
                          icon={<DeleteSVG />}
                          title="Excluir esta chave"
                          onClick={() => openDeletePixKeyModal(key.value)}
                          isRed
                          isDisabled={!buttonsPixGranted}
                        />
                      </>
                    }
                  >
                    <div className="right-content">
                      {renderRightContent.menu}
                    </div>
                  </DropDown>
                </div>
              </Style.IconAndOptions>

              <Style.NameAndValue>
                <Text.Heading4>
                  {key.type === 'EVP' ? 'Aleatória' : key.type}
                </Text.Heading4>
                <Text.Paragraph>
                  {key.type === 'EVP'
                    ? `${key.value.substring(0, 12)}...${key.value.substring(
                      key.value.length - 7
                    )}`
                    : key.value}
                </Text.Paragraph>
              </Style.NameAndValue>
            </Style.PixCard>
          ))}

          <PermissionGate name="createKeyPix">
            <Style.AddNewKey onClick={openCreatePixKeyModal}>
              <div className="plus-button">
                <PlusCardSVG />
              </div>

              <Text.Paragraph color={colors.naturalBlack}>
                Cadastrar nova chave
              </Text.Paragraph>
            </Style.AddNewKey>
          </PermissionGate>
        </Then>

        <Else>
          <PermissionGate name="createKeyPix">
            <Style.AddNewKey onClick={openCreatePixKeyModal}>
              <div className="plus-button">
                <PlusCardSVG />
              </div>

              <Text.Paragraph color={colors.naturalBlack}>
                Cadastrar nova chave
              </Text.Paragraph>
            </Style.AddNewKey>
          </PermissionGate>
        </Else>
      </If>
    </Style.ScrollContainer>
  );
};

export default PixKeyCards;
