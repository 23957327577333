import * as Style from './FooterDashboard.styles';
import * as Text from '~styles/text';

import { Else, If, Then, When } from 'react-if';

import CapitualLogo from '~assets/images/logo-black.svg';
import { FooterDashboardProps } from './FooterDashboard.types';
import { breakpoints } from '~styles/metrics';
import { colors } from '~styles';
import useWindowDimensions from '~hooks/windowDimension';
import { handleOnClickHelp } from '~helpers/generic';

const FooterDashboard: React.FC<FooterDashboardProps> = () => {
  const { width } = useWindowDimensions();

  return (
    <Style.Container data-hide-for-print="hidden-for-print">
      <If condition={breakpoints.tablet < width}>
        <Then>
          <div className="row">
            <CapitualLogo />
            <Text.Paragraph marginLeft={20} color={colors.grayMedium}>
              Capitual © {new Date().getFullYear()}
            </Text.Paragraph>
          </div>

          <div className="row">
            <When
              condition={
                breakpoints.tablet + 100 < width && breakpoints.tablet < width
              }
            >
              <Text.ParagraphBold onClick={handleOnClickHelp}>
                Central de Ajuda
              </Text.ParagraphBold>
              <span>&nbsp;•&nbsp;</span>
            </When>
            <Text.Paragraph color={colors.grayMedium}>
              Capitual Instituição de Pagamentos LTDA. 34.942.560/0001-87
            </Text.Paragraph>
          </div>
        </Then>

        <Else>
          <div className="row space-between">
            <CapitualLogo />

            <Text.ParagraphBold>suporte@capitual.com</Text.ParagraphBold>
          </div>
        </Else>
      </If>
    </Style.Container>
  );
};

export default FooterDashboard;
