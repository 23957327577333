import * as Styled from './NotificationItem.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import { QuickButton } from '..';
import { ReactNode } from 'react';

interface NotificationsTypes {
  category: string;
  date: string | Date;
  label: string;
  description: string;
  buttonLabel?: string;
  buttonIcon?: ReactNode;
  secondaryButtonLabel?: string;
  secondaryButtonIcon?: ReactNode;
  viewed?: boolean;
  icon: ReactNode;
  onPrimaryButtonClick?: () => void;
  onClickNotification?: () => void;
}

const NotificationItem = ({
  category,
  date,
  description,
  label,
  buttonLabel,
  buttonIcon,
  secondaryButtonLabel,
  secondaryButtonIcon,
  viewed,
  icon,
  onPrimaryButtonClick,
  onClickNotification,
}: NotificationsTypes) => {
  return (
    <Styled.Container onClick={onClickNotification}>
      <div>
        <div className="notif-icon">
          {icon}

          {!viewed ? <span /> : null}
        </div>
      </div>

      <div>
        <Text.Paragraph marginBottom={8} color={colors.grayMedium}>
          {category} • {date}
        </Text.Paragraph>

        <Text.Heading4 marginBottom={4}>{label}</Text.Heading4>

        <Text.Paragraph color={colors.grayMedium}>{description}</Text.Paragraph>

        <div className="buttons-row">
          {buttonLabel && (
            <div onClick={onPrimaryButtonClick}>
              <QuickButton fitContent icon={buttonIcon || null}>
                {buttonLabel}
              </QuickButton>
            </div>
          )}

          {secondaryButtonLabel && (
            <QuickButton fitContent icon={secondaryButtonIcon || null}>
              {secondaryButtonLabel}
            </QuickButton>
          )}
        </div>
      </div>
    </Styled.Container>
  );
};

export default NotificationItem;
