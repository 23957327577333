import * as Style from './InputSwitch.styles';

import { InputSwitchProps } from './InputSwitch.types';
import { forwardRef } from 'react';

const InputSwitch = forwardRef((props: InputSwitchProps, _ref) => {
  const { checked, ...rest } = props;

  return (
    <Style.Container {...rest} onClick={() => {}}>
      <Style.Input type="checkbox" {...rest} defaultChecked={checked} {...props.hookForm} />
    </Style.Container>
  );
});

InputSwitch.displayName = 'InputSwitch';

export default InputSwitch;
