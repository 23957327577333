import * as React from 'react';
import { SVGProps } from 'react';

const WorldDisabledSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity={0.5}>
      <rect x={0.143} width={30} height={30} rx={15} fill="#F3F4F9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.143 15a6 6 0 0 1 10.445-4.03l-4.086 4.086v-1.273l.03-.01c.397-.133 1.183-.638 1.183-1.588v-.044h.044c.262 0 .657-.08.984-.318a1.36 1.36 0 0 0 .379-.415 4.665 4.665 0 0 0-7.051 1.311l2.096 2.3v1.222l.573.578-2.627 2.627A5.985 5.985 0 0 1 9.143 15Zm3.219 5.318a6 6 0 0 0 8.099-8.099l-3.342 3.342v1.16l.053-.01c.797-.162 1.357.007 1.733.277.122.088.226.187.312.29a4.666 4.666 0 0 1-4.434 2.375v-1.756l-2.421 2.421Z"
        fill="#C3C6DB"
      />
      <path
        fill="#C3C6DB"
        d="m20.529 10.03-1.062-1.062L9.11 19.323l1.062 1.062z"
      />
    </g>
  </svg>
);

export default WorldDisabledSVG;
