import { SVGProps } from 'react';

const BankIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '25'}
    height={props.height || '25'}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00195 20.0059V11.0059M12.002 20.0059V11.0059M18.002 20.0059V11.0059"
      stroke={props.fill || 'black'}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M12.002 5.00586L20.002 11.0059H4.00195L12.002 5.00586Z"
      stroke={props.fill || 'black'}
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M5.00195 19.0059H19.002"
      stroke={props.fill || 'black'}
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);

export default BankIcon;
