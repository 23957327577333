export const billLabelStatus = [
  { status: 'PROCESSED', label: 'Em aberto', itemColor: 'open' },
  { status: 'REGISTERED', label: 'Em aberto', itemColor: 'open' },
  { status: 'ACCEPTED', label: 'Em aberto', itemColor: 'open' },
  {
    status: 'MARKEDCONCILIATION',
    label: 'Cancelado',
    itemColor: 'canceled',
  },
  { status: 'CONCILIATED', label: 'Em aberto', itemColor: 'open' },
  { status: 'SETTLED', label: 'Pago', itemColor: 'paied' },
  { status: 'CANCELLED', label: 'Cancelado', itemColor: 'canceled' },
  {
    status: 'CANCELLEDBYRECIPIENT',
    label: 'Cancelado',
    itemColor: 'canceled',
  },
  { status: 'CANCELLEDBYDEADLINE', label: 'Vencido', itemColor: 'expired' },
  {
    status: 'BLOCKEDFORPAYMENT',
    label: 'Cancelado',
    itemColor: 'canceled',
  },
  { status: 'UNITBENEFICIARY', label: 'Cancelado', itemColor: 'canceled' },
];
