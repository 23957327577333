import * as React from 'react';
import { SVGProps } from 'react';

const ExcelSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '18'}
    height={props.height || '19'}
    viewBox="0 0 18 19"
    fill="none"
  >
    <g opacity="0.2">
      <rect
        x="4.41809"
        y="0.910156"
        width="13.4913"
        height="17.0977"
        fill="url(#pattern0)"
      />
      <g opacity="0.2">
        <path
          d="M16.4606 1.00574H5.76414C5.39246 1.00574 5.09116 1.33636 5.09116 1.74421V15.9315C5.09116 16.3393 5.39246 16.67 5.76414 16.67H16.4606C16.8322 16.67 17.1335 16.3393 17.1335 15.9315V1.74421C17.1335 1.33636 16.8322 1.00574 16.4606 1.00574Z"
          fill="white"
        />
      </g>
    </g>
    <g opacity="0.12">
      <rect
        x="4.73459"
        y="0.840576"
        width="12.7312"
        height="16.2637"
        fill="url(#pattern1)"
      />
      <g opacity="0.12">
        <path
          d="M16.4606 1.00555H5.76423C5.39255 1.00555 5.09125 1.33618 5.09125 1.74402V15.9313C5.09125 16.3392 5.39255 16.6698 5.76423 16.6698H16.4606C16.8323 16.6698 17.1336 16.3392 17.1336 15.9313V1.74402C17.1336 1.33618 16.8323 1.00555 16.4606 1.00555Z"
          fill="white"
        />
      </g>
    </g>
    <path
      d="M11.3296 1.0144H5.78746C5.69982 1.01323 5.61287 1.03131 5.5317 1.06757C5.45053 1.10383 5.37679 1.15755 5.31482 1.22555C5.25285 1.29355 5.2039 1.37446 5.17085 1.46353C5.13781 1.5526 5.12133 1.64802 5.12239 1.74418V4.92394H11.3613L11.3296 1.0144Z"
      fill="#21A366"
    />
    <path
      d="M16.4603 1.0144H11.3299V4.92394H17.1333V1.73549C17.1323 1.63965 17.1141 1.54497 17.0797 1.45687C17.0453 1.36876 16.9954 1.28895 16.9329 1.22198C16.8705 1.15502 16.7966 1.10223 16.7155 1.06661C16.6344 1.03099 16.5477 1.01325 16.4603 1.0144Z"
      fill="#33C481"
    />
    <path
      d="M17.1254 8.83344H11.3299V12.7517H17.1254V8.83344Z"
      fill="#107C41"
    />
    <path
      d="M11.33 12.7517V8.83344H5.09108V15.9314C5.09002 16.0276 5.1065 16.123 5.13954 16.2121C5.17259 16.3011 5.22154 16.3821 5.28351 16.4501C5.34548 16.5181 5.41922 16.5718 5.50038 16.608C5.58155 16.6443 5.66851 16.6624 5.75614 16.6612H16.4605C16.5485 16.6624 16.6358 16.6443 16.7174 16.6082C16.799 16.572 16.8733 16.5184 16.9359 16.4506C16.9985 16.3827 17.0482 16.3018 17.0821 16.2127C17.116 16.1236 17.1335 16.028 17.1335 15.9314V12.7517H11.33Z"
      fill="#185C37"
    />
    <path
      d="M11.3379 4.91522H5.09103V8.83345H11.3379V4.91522Z"
      fill="#107C41"
    />
    <path
      d="M17.1254 4.91522H11.3299V8.83345H17.1254V4.91522Z"
      fill="#21A366"
    />
    <g opacity="0.48">
      <rect y="3.36029" width="11.2111" height="12.302" fill="url(#pattern2)" />
      <g opacity="0.48">
        <path
          d="M8.87535 4.42889H2.19306C1.82138 4.42889 1.52008 4.75952 1.52008 5.16736V12.4999C1.52008 12.9078 1.82138 13.2384 2.19306 13.2384H8.87535C9.24703 13.2384 9.54833 12.9078 9.54833 12.4999V5.16736C9.54833 4.75952 9.24703 4.42889 8.87535 4.42889Z"
          fill="white"
        />
      </g>
    </g>
    <g opacity="0.24">
      <rect
        x="1.31442"
        y="4.17694"
        width="8.5508"
        height="9.3829"
        fill="url(#pattern3)"
      />
      <g opacity="0.24">
        <path
          d="M8.87532 4.42889H2.19303C1.82135 4.42889 1.52005 4.75952 1.52005 5.16736V12.4999C1.52005 12.9078 1.82135 13.2384 2.19303 13.2384H8.87532C9.247 13.2384 9.5483 12.9078 9.5483 12.4999V5.16736C9.5483 4.75952 9.247 4.42889 8.87532 4.42889Z"
          fill="white"
        />
      </g>
    </g>
    <path
      d="M8.87535 4.42883H2.19306C1.82138 4.42883 1.52008 4.75946 1.52008 5.1673V12.4999C1.52008 12.9077 1.82138 13.2383 2.19306 13.2383H8.87535C9.24703 13.2383 9.54833 12.9077 9.54833 12.4999V5.1673C9.54833 4.75946 9.24703 4.42883 8.87535 4.42883Z"
      fill="#107C41"
    />
    <path
      d="M3.59454 11.2226L4.99593 8.83344L3.71331 6.45296H4.75049L5.44722 7.96465C5.50024 8.07047 5.54526 8.18089 5.58181 8.29479C5.6256 8.17858 5.67584 8.06543 5.73224 7.95596L6.52399 6.44427H7.47407L6.1202 8.83344L7.47407 11.24H6.46065L5.66891 9.56322C5.62675 9.49564 5.59219 9.42271 5.56598 9.34602C5.53966 9.42109 5.50789 9.49373 5.47097 9.56322L4.63172 11.24L3.59454 11.2226Z"
      fill="white"
    />
    <g opacity="0.5">
      <path

        opacity="0.5"
        d="M8.87535 4.42883H2.19306C1.82138 4.42883 1.52008 4.75946 1.52008 5.1673V12.4999C1.52008 12.9077 1.82138 13.2383 2.19306 13.2383H8.87535C9.24703 13.2383 9.54833 12.9077 9.54833 12.4999V5.1673C9.54833 4.75946 9.24703 4.42883 8.87535 4.42883Z"
        fill="url(#paint0_linear_17512_5324)"
      />
    </g>
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use

          transform="scale(0.0138889 0.0120482)"
        />
      </pattern>
      <pattern
        id="pattern1"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use

          transform="scale(0.0147059 0.0126582)"
        />
      </pattern>
      <pattern
        id="pattern2"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use transform="scale(0.0166667)" />
      </pattern>
      <pattern
        id="pattern3"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use transform="scale(0.0217391)" />
      </pattern>
      <linearGradient
        id="paint0_linear_17512_5324"
        x1="2.91354"
        y1="3.85543"
        x2="8.91798"
        y2="13.328"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopOpacity="0.7" />
      </linearGradient>
      <image
        id="image0_17512_5324"
        width="72"
        height="83"

      />
      <image
        id="image1_17512_5324"
        width="68"
        height="79"

      />
      <image
        id="image2_17512_5324"
        width="60"
        height="60"

      />
      <image
        id="image3_17512_5324"
        width="46"
        height="46"
      />
    </defs>
  </svg>
);

export default ExcelSvg;
