import { gql, request, useMutation } from '~reactQuery';

import { useQueryClient } from 'react-query';

interface CreateProcessResponse {
  createProcess: {
    data: {
      id: number;
      userId: number;
      createdAt: string;
      updatedAt: string;
      deleted: boolean;
      deletedAt: string;
      processStatus: string;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useCreateProcessMutation() {
  const queryClient = useQueryClient();

  const mutation = gql`
    mutation CreateProcess {
      createProcess {
        data {
          id
          userId
          createdAt
          updatedAt
          deleted
          deletedAt
          processStatus
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async () => {
    const response = await request<CreateProcessResponse>(mutation, {});
    return response.createProcess;
  };

  return useMutation('useCreateProcess', fetch, {
    onSettled: () => {
      queryClient.invalidateQueries(['getProcess']);
    },
  });
}
