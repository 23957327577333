import * as Style from './PartnersSteps.styles';
import * as Text from '~styles/text';

import { BigInput, GenericButton } from '~components';

import ArrowBackIcon from '~assets/images/icons/kyc/arrow-back.svg';
import { PartnerCPFProps } from '../Kyc.types';
import { colors } from '~styles';
import { cpfMask } from '~helpers/format/masks';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { splitByLastDot } from '~helpers/format/file';
import { usePartnersList } from '../store';
import { useTranslation } from 'react-i18next';
import { validateCPF } from '~validations/personalValidation';

export const PartnerCPF = ({
  gotToModalStep,
  partnersMethods,
}: PartnerCPFProps) => {
  const { field } = usePartnersList();
  const { t } = useTranslation();

  const {
    formState: { errors },
    getValues,
    register,
    setError,
  } = partnersMethods;

  const partnerKeyName = Array.isArray(field) ? field[0] : field;
  const getIndex = onlyNumbers(String(partnerKeyName));

  const isPartnerOfPartner = field.includes('partnerData');

  const errorIncase = isPartnerOfPartner
    ? errors?.allPartners?.[getIndex?.[0]]?.list?.[getIndex?.[1]]
        ?.partnerData?.[getIndex?.[2]]?.documentNumber
    : errors?.allPartners?.[getIndex?.[0]]?.list?.[getIndex?.[1]]
        ?.documentNumber;

  const partnerFirstName = getValues
    ? getValues(`${partnerKeyName}.registerName`)?.split(' ')?.[0]
    : '';

  const allPartners = getValues('allPartners');

  const onSubmit = () => {
    const documentNumber = getValues(`${partnerKeyName}.documentNumber`);

    const hasSameDocumentNumber = isPartnerOfPartner
      ? getValues(splitByLastDot(String(field)))?.filter(
          (p: any) => p.documentNumber === documentNumber
        )
      : allPartners?.[0]?.list?.filter(
          (p: any) => p.documentNumber === documentNumber
        );

    if (hasSameDocumentNumber?.length > 1) {
      setError(`${partnerKeyName}.documentNumber`, {
        type: 'pattern',
        message: 'Parceiro já cadastrado',
      });
    } else if (!validateCPF(documentNumber)) {
      setError(`${partnerKeyName}.documentNumber`, {
        type: 'pattern',
        message: 'CPF Inválido',
      });
    } else {
      gotToModalStep('howToFinishRegister');
    }
  };

  return (
    <Style.Container>
      <div className="content">
        <div
          className="back-button"
          onClick={() => gotToModalStep('partnerName')}
        >
          <ArrowBackIcon />
        </div>

        <Text.Heading5 color={colors.grayMedium} marginTop={30}>
          {t('Adicionar Sócio')}
        </Text.Heading5>

        <Text.Heading2Bold marginTop={10} marginBottom={30}>
          {t('Qual o CPF de', { partnerName: partnerFirstName })}
        </Text.Heading2Bold>

        <BigInput
          {...register(`${partnerKeyName}.documentNumber`)}
          maskFunction={cpfMask}
          textAlign="center"
          placeHolder="000.000.000-00"
          error={errorIncase?.message}
        />

        <Text.Heading5 marginTop={30} lineHeight={140} display="inline-block">
          {t('Você está adicionando')} <strong>{partnerFirstName}</strong>{' '}
          {t('como um dos sócios da sua empresa.')}
        </Text.Heading5>
      </div>

      <div className="footer">
        <div className="footer-btn">
          <GenericButton buttonType="secondary" width="100%" onClick={onSubmit}>
            Próximo
          </GenericButton>
        </div>
      </div>
    </Style.Container>
  );
};
