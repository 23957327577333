import { Contacts, CreateContactData } from '~reactQuery/types/contacts.types';
import { gql, request, useMutation } from '~reactQuery/index';

import { CONTACTS_QUERY_KEYS } from '~src/constants/contacts';

interface CreateContactInput {
  userOrganizationId: number;
  name: string;
  documentNumber: string;
  contactData: CreateContactData;
}

interface CreateContactResponse {
  createContact: {
    data: Contacts;
    messages: [string];
    isValid: boolean;
  };
}

export const useCreateContact = () => {
  const QUERY = gql`
    mutation CreateContact($params: CreateContactInput!) {
      createContact(params: $params) {
        data {
          id
          userOrganizationId
          name
          documentNumber
          fav
          contactData {
            id
            type
            accountType
            pixType
            pixValue
            bankCode
            bankIspb
            branch
            accountNumber
            isFromCapBiz
            capBizContact
            contactsId
          }
          deletedAt
          createdAt
          updatedAt
        }
        messages
        isValid
      }
    }
  `;

  const fetcher = async (params: CreateContactInput) => {
    const response = await request<CreateContactResponse>(QUERY, {
      params,
    });
    return response.createContact;
  };

  return useMutation([CONTACTS_QUERY_KEYS.CREATE_CONTACT], fetcher);
};
