import * as Style from '../EnableTwoFa.styles';
import * as Text from '~styles/text';

import { Else, If, Then } from 'react-if';
import React, { useEffect, useState } from 'react';
import { getSelectedUserOrganization, getSettingsId } from '~helpers/device';
import { GenericButton } from '~components/Buttons';
import LoadingSpinner from '~components/LoadingSpinner';
import QRCode from 'react-qr-code';
import colors from '~styles/colors';
import { encode } from 'hi-base32';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useTwoFAGenerateSecret } from '~reactQuery/mutations/settings/useTwoFAGenerateSecret';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import useDetectDevice from '~hooks/detectDevice';

const ShowQrCode: React.FC = ({ setStepsAndParams }) => {
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  const detect = useDetectDevice();

  const [twoFACode, setTwoFACode] = useState('');

  const orgId = getSelectedUserOrganization();
  const { data: accountInfos, isLoading: isLoadingAccInfo } =
    useGetCapAccountInfo({
      userOrganizationId: Number(orgId),
    });

  const organization = getSettingsId();

  const nameTwoFa = `Capitual-Biz-Acc-${accountInfos?.account}` || '';

  const { mutateAsync: generateTwoFACode, isLoading: isLoadingGenerate } =
    useTwoFAGenerateSecret();

  const generateCode = async () => {
    const resp = await generateTwoFACode({
      userOrganizationId: Number(orgId),
      settingsId: Number(organization),
    });

    if (resp?.data) setTwoFACode(encode(resp.data));
  };

  const copyCode = () => {
    navigator.clipboard.writeText(twoFACode);
  };

  useEffect(() => {
    generateCode();
  }, []);

  return (
    <>
      <ModalMultiStepHeader
        title="Autenticação de dois fatores"
        description="Abra seu App de autenticação para ativar o 2FA, leia ou copie o QRCode. (Recomendamos Authy ou Google Authenticator)."
      />
      <div className="enable-tow-fa-body h-600">
        <If condition={isLoadingGenerate || isLoadingAccInfo}>
          <Then>
            <div className="justify-items-center">
              <LoadingSpinner
                color={colors.primary}
                size={60}
                strokeSize={25}
              />
            </div>
          </Then>
          <Else>
            <div className="justify-items-center">
              <QRCode
                size={168}
                value={`otpauth://totp/${nameTwoFa}?secret=${twoFACode}`}
              />
            </div>
            <div className="container-codes">
              <Text.Heading4 fontSize={detect.isMobile() ? 0.85 : 0.93}>{twoFACode}</Text.Heading4>)
            </div>
            <div className="justify-items-center">
              <GenericButton onClick={copyCode} buttonType="default">
                Copiar código
              </GenericButton>
            </div>
          </Else>
        </If>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        doubleButtonTextOne="Cancelar"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => setStepsAndParams('askForCodes')}
        doubleButtonOneAction={() => setOpenModalMultiStep(false)}
        singleButtonLoading={isLoadingGenerate}
      />
    </>
  );
};

export default ShowQrCode;
