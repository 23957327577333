import * as Text from '~styles/text';
import * as yup from 'yup';
import { FooterButton, InputForm, PasswordChecker } from '~components';
import LockIcon from '~assets/images/lock-icon.svg';
import { breakpoints } from '~styles/metrics';
import { colors } from '~styles';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useWindowDimensions from '~hooks/windowDimension';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFirstLogin } from '~reactQuery/mutations/user/useFirstLogin';
import {
  strongPasswordRegex,
} from '~validations/stringsValidate';

interface Props {
  setStep: (step: 'change' | 'success' | 'failed') => void;
  sessKey: string;
}

export const Change = ({ setStep, sessKey }: Props) => {
  const [password, setPassword] = useState('');
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const { mutateAsync: firstLoginFn, isLoading: isLoadingFirstLogin } =
    useFirstLogin();

  const schema = yup.object({
    email: yup.string().required('Esse campo é obrigatório'),
    passwd: yup
      .string()
      .required('Esse campo é obrigatório')
      .matches(
        strongPasswordRegex,
        'Deve ao menos 8 caracteres, um maiúsculo, um minúsculo, um número e um caractere especial'
      ),
    confirmPassword: yup
      .string()
      .required('Esse campo é obrigatório')
      .matches(
        strongPasswordRegex,
        'Deve ao menos 8 caracteres, um maiúsculo, um minúsculo, um número e um caractere especial'
      )
      .oneOf([yup.ref('passwd')], 'As senhas não coincidem'),
    pin: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(4, 'O Pin precisa ter 4 caracteres'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await firstLoginFn({
        email: data.email,
        newPasswd: data.passwd,
        sessionKey: sessKey,
        pin: data.pin,
      });

      setStep('success');
    } catch (error) {
      setStep('failed');
    }
  });

  useEffect(() => {
    if (!sessKey) {
      navigate('/');
    }
  }, [navigate, sessKey]);

  const verifyIsDisabled = (isActive: boolean) => {
    if (!isActive) {
      return colors.grayLight;
    }

    return colors.black;
  };

  return (
    <>
      <div className="footer-avoid">
        <div className="medium-icon">
          <LockIcon />
        </div>
        <Text.Heading1Medium marginTop={10} marginBottom={30}>
          Vamos alterar a senha?
        </Text.Heading1Medium>

        <Text.Heading5
          color={colors.black}
          marginBottom={30}
          lineHeight={140}
          width={breakpoints.desktop < width ? '100%' : ''}
        >
          Esta senha vai servir para você acessar sua conta empresarial, então
          escolha
          <br />
          uma senha forte, e não a reutilize em outras contas.
          <br />
          <br />
          Para alterar sua senha, preencha os dados solicitados abaixo:
        </Text.Heading5>
        <div className="input-group">
          <InputForm
            {...register('email')}
            autoFocus
            label={t('E-mail')}
            error={errors.email?.message}
          />
          <InputForm
            {...register('passwd')}
            label={t('Nova Senha')}
            type="password"
            error={errors.passwd?.message}
            onChange={(event: any) => setPassword(event.target.value)}
          />
          <PasswordChecker password={password} />
          <InputForm
            {...register('confirmPassword')}
            type="password"
            label={t('Confirmar senha')}
            error={errors.confirmPassword?.message}
          />
          <InputForm
            {...register('pin')}
            maxLength={4}
            type="password"
            label={t('Pin')}
            error={errors.pin?.message}
          />
        </div>
      </div>
      <FooterButton
        onClickGenericButton={onSubmit}
        loadingGenericButton={isLoadingFirstLogin}
        leftButtonContent="Voltar"
        onClickLeftButton={() => navigate('auth/sign-in')}
        genericButtonType="secondary"
        genericButtonContent="Cadastrar nova senha"
      />
    </>
  );
};
