import * as Style from './SendNewPin.styles';
import { SetCurrentStepProps, StepParamsProps } from './SendNewPin.types';
import { useEffect, useState } from 'react';
import AskNewPin from './ModalSteps/2.AskNewPin.screen';
import NewPin from './ModalSteps/3.NewPin.screen';
import SendNewPin from './ModalSteps/1.SendNewPin.screen';
import SuccessRecoveryPin from './ModalSteps/4.SuccessRecoveryPin.screen';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import ModalMultiStep from '~components/ModalMultiStep';

function SendNewPinModalSteps() {
  const { openModalMultiStep, setOpenModalMultiStep } =
    useActionsOnModalMultiStep();

  const [currentStep, setCurrentStep] =
    useState<SetCurrentStepProps>('sendNewPin');
  const [stepParams, setStepParams] = useState<StepParamsProps>({});

  function setStepsAndParams(step: SetCurrentStepProps, params?: object) {
    if (params) {
      setStepParams((prev) => ({ ...prev, ...params } as StepParamsProps));
    }
    setCurrentStep(step);
  }

  const modalStepsAndDetails = {
    sendNewPin: {
      component: (
        <SendNewPin key="sendNewPin" setStepsAndParams={setStepsAndParams} />
      ),
      progressValue: 25,
    },
    askNewPin: {
      component: (
        <AskNewPin
          stepParams={stepParams}
          key="AskNewPin"
          setStepsAndParams={setStepsAndParams}
        />
      ),
      progressValue: 50,
    },
    newPin: {
      component: (
        <NewPin
          key="newPin"
          stepParams={stepParams}
          setStepsAndParams={setStepsAndParams}
        />
      ),
      progressValue: 75,
    },
    successRecovery: {
      component: <SuccessRecoveryPin key="successRecoveryPin" />,
      progressValue: 100,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  // CLEAR STEP ON MODAL CLOSE
  useEffect(() => {
    if (!openModalMultiStep) setCurrentStep('sendNewPin');
  }, [openModalMultiStep]);

  return (
    <Style.RecoveryPinContainer>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={currentStepInfos.progressValue}
      >
        {currentStepInfos.component}
      </ModalMultiStep>
    </Style.RecoveryPinContainer>
  );
}

export default SendNewPinModalSteps;
