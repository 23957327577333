import styled from 'styled-components';
import { colors } from '~styles';
import { GenericButton } from '../Buttons';
import * as Text from '~styles/text';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin: 24px 0px;
  width: 100%;
  min-height: 96px;
  background-color: ${colors.primaryOpacity10p};
  border: 1px solid ${colors.primaryOpacity20p};
  border-radius: 8px;
  padding: 24px 20px;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  .icon {
    min-width: 24px;
    margin-right: 16px;
  }
`;

export const Button = styled(GenericButton)`
  min-width: 250px;
  border: 1px solid ${colors.primary};
  margin-left: 20px;

  @media (max-width: 850px) {
    margin-top: 30px;
    margin-left: 0px;
  }
`;

export const Paragraph = styled(Text.Heading5)`
  color: ${colors.primary};
  padding: 0px;
  margin: 0px;

  .text1 {
    max-width: 70%;
  }
`;
