import styled, { css } from 'styled-components';
import colors from '~styles/colors';
import colorsSecondary from '~styles/colorsSecondary';

export const Container = styled.div<{ roundButtonBackground?: string }>`
  height: 100%;
  position: relative;
  overflow-x: hidden;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 25px 0px 25px;

    .title {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  .wrapper {
    height: calc(100% - 115px);

    .decription-transaction {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 11px;
    }
  }

  .details {
    height: 100%;
    padding: 0 25px 25px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon-details {
      .default {
        background-color: ${(props) => props.roundButtonBackground};

        &:hover {
          background-color: ${(props) =>
            props.roundButtonBackground} !important;
        }
      }
    }
  }

  .description {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .buttons-wrapper {
    padding: 0 25px;
  }

  .button-refund{
    .default{
      background-color: ${colors.mainGray};
      padding: 8px 16px;
      
    }
  }

  .loading-container {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${colors.gray};
  padding: 4px 10px;
  border-radius: 30px;
  margin-bottom: 20px;

  .circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colors.grayMedium};

    &.active,
    &.completed,
    &.done {
      background-color: ${colors.success};
    }

    &.in_process,
    &.created,
    &.approveed {
      background-color: ${colors.mainGray};
    }

    &.undone,
    &.reproved,
    &.canceled,
    &.cancelled {
      background-color: ${colors.danger};
    }
  }
`;

export const MoreDetails = styled.div<{ fadeIn: boolean }>`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: absolute;
  top: 0;
  padding: 0 25px 0 25px;
  display: flex;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;

  .barcode-component {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .goBackButton {
    cursor: pointer;
  }

  .detailsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    width: 100%;

    .item-right {
      display: flex;
      justify-content: flex-end;

      width: 55%;
    }
  }

  .detailsDivider {
    display: flex;
    align-items: center;
    gap: 11px;
    margin-bottom: 30px;

    .icon {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  hr {
    width: 100%;
    height: 1px;
    background-color: #00000015;
    border: none;
  }

  .details-column {
    display: flex;
    flex-direction: column;
  }

  .details-group {
    display: grid;
    grid-template: repeat(1, 1fr) / repeat(2, 1fr);
    gap: 20px;
  }

  .btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .pdf-button {
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    background-color: ${colors.gray};
    padding: 7px 13px;
    border-radius: 44px;
    cursor: pointer;
    color: #000;
  }

  ${(props) =>
    props.fadeIn
      ? css`
          right: 0;
          transition: 0.5s ease;
        `
      : css`
          right: -100%;
          transition: 0.5s ease;
        `}
`;
