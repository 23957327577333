import * as Style from '../../Kyc.styles';
import * as Text from '~styles/text';

import { GenericButton, InputDocForm } from '~components';
import { useEffect, useState } from 'react';

import { BusinessPartnerSendDocsProps } from '~pages/Kyc/Kyc.types';
import CompanyDataIcon from '~assets/images/icons/kyc/company-data.svg';
import { Controller } from 'react-hook-form';
import { formatFileName } from '~helpers/format/file';
import { getRequiredDocs } from '~data/kyc';
import { kycDocsSchema } from '../../validation/kyc.schema';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { usePartnersList } from '../../store';
import { useTranslation } from 'react-i18next';

export const BusinessPartnerSendDocs = ({
  setOpenModalAddPartners,
  partnerFormMethods,
  isCryptoTrader,
}: BusinessPartnerSendDocsProps) => {
  const { field: fieldArray } = usePartnersList();
  const { t } = useTranslation();

  const field = Array.isArray(fieldArray) ? fieldArray[1] : fieldArray;

  const [requiredDocs, setRequiredDocs] = useState<any>([]);

  const {
    control,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = partnerFormMethods;

  const bizPartnerInCase = getValues(field);

  const businessType = bizPartnerInCase?.businessType;
  const dataFields = bizPartnerInCase?.businessData?.fields;

  const getIndex = onlyNumbers(String(field));

  const errorIncase =
    errors?.allPartners?.[getIndex?.[0]]?.list?.[getIndex?.[1]]?.businessData;

  useEffect(() => {
    const filesList = bizPartnerInCase?.businessData;

    if (businessType)
      setRequiredDocs(getRequiredDocs(businessType, isCryptoTrader));

    if (filesList?.id) {
      const fileQSA = filesList?.fileQSA;
      const fileCNPJ = filesList?.fileCNPJ;
      const incomes = filesList?.incomes?.[0]?.income;
      const socialContract = filesList?.socialContract;
      const lastContractChange = filesList?.lastContractChange;
      const lastElection = filesList?.lastElection;
      const socialStatute = filesList?.socialStatute;
      const preventionPolicy = filesList?.preventionPolicy;
      const proofOfRegularity = filesList?.proofOfRegularity;
      const accountingBalance = filesList?.accountingBalance;

      const fieldNameBase = `${field}.businessData.`;

      if (isCryptoTrader) {
        if (preventionPolicy)
          setValue(
            'preventionPolicy',
            formatFileName('Política de Prevenção', preventionPolicy)
          );
        if (proofOfRegularity)
          setValue(
            'proofOfRegularity',
            formatFileName('Comprovante de regularidade', proofOfRegularity)
          );
        if (accountingBalance)
          setValue(
            'accountingBalance',
            formatFileName('Balanço contábil', accountingBalance)
          );
      }

      if (typeof fileQSA === 'string') {
        setValue(`${fieldNameBase}fileQSA`, formatFileName('QSA', fileQSA));
      }
      if (typeof fileCNPJ === 'string') {
        setValue(`${fieldNameBase}fileCNPJ`, formatFileName('CNPJ', fileCNPJ));
      }
      if (typeof incomes === 'string') {
        setValue(
          `${fieldNameBase}incomes`,
          formatFileName('Rendimento', incomes)
        );
      }

      switch (businessType) {
        case 'LTDA':
          if (typeof socialContract === 'string') {
            setValue(
              `${fieldNameBase}socialContract`,
              formatFileName('Contrato-Social', socialContract)
            );
          }

          if (typeof lastContractChange === 'string') {
            setValue(
              `${fieldNameBase}lastContractChange`,
              formatFileName('Última-Alteração', lastContractChange)
            );
          }
          break;

        case 'SA':
          if (typeof lastElection === 'string') {
            setValue(
              `${fieldNameBase}lastElection`,
              formatFileName('Última-Eleição', lastElection)
            );
          }

          if (typeof socialStatute === 'string') {
            setValue(
              `${fieldNameBase}socialStatute`,
              formatFileName('Estatuto-Social', socialStatute)
            );
          }

          break;

        default:
      }
    }
  }, [bizPartnerInCase, businessType]);

  const saveDocs = async () => {
    const partnerDataInCase = getValues(`${field}.businessData`);
    const currentValidation = kycDocsSchema(businessType);

    try {
      await currentValidation.validate(partnerDataInCase, {
        abortEarly: false,
      });

      setValue(`${field}.businessData.`, partnerDataInCase);
      setValue(`${field}.docStatus`, 'WAITINGTOSEND');
      clearErrors(`${field}.businessData`);

      setOpenModalAddPartners(false);
    } catch (err: any) {
      err.inner.forEach((e: any) => {
        //  console.log(`${field}.businessData.${e.path}`);
        setError(`${field}.businessData.${e.path}`, {
          type: 'required',
          message: e.message,
        });
      });
    }
  };

  return (
    <Style.PartnersTypeBusinessContainer>
      <div style={{ height: 48, width: 48 }}>
        <CompanyDataIcon style={{ borderRadius: 15 }} />
      </div>
      <Text.Heading1Medium marginTop={10} marginBottom={30}>
        {t('Documentos da Empresa')}
      </Text.Heading1Medium>

      <Text.Heading5 marginBottom={30} lineHeight={140}>
        {t(
          'Ótimo! Estamos ainda mais perto de concluir a sua solicitação. Insira abaixo os documentos solicitados, e lembre-se:'
        )}{' '}
        {t('é necessário que sejam as versões mais recentes de cada um.')}
      </Text.Heading5>

      <Style.FormContainer>
        {requiredDocs?.map((doc: { title: string; fieldName: string }) => {
          return (
            <div key={doc.title}>
              <Controller
                name={`${field}.businessData.${doc.fieldName}`}
                render={({ field }) => (
                  <InputDocForm
                    {...field}
                    getValues={getValues}
                    setValue={setValue}
                    docTitle={t(doc.title)}
                    trigger={trigger}
                    error={errorIncase?.[doc.fieldName]?.message}
                    status={
                      dataFields
                        ?.filter(
                          (singleField: any) =>
                            String(singleField?.field) === doc?.fieldName
                        )
                        ?.map((fields: any) => fields)?.[0]?.status
                    }
                    description={
                      dataFields
                        ?.filter(
                          (singleField: any) =>
                            String(singleField?.field) === doc?.fieldName
                        )
                        ?.map((fields: any) => fields)?.[0]?.description
                    }
                  />
                )}
                control={control}
              />
            </div>
          );
        })}
      </Style.FormContainer>

      <div className="footer">
        <div className="footer-btn">
          <GenericButton
            buttonLoading={false}
            buttonType="secondary"
            width="100%"
            onClick={saveDocs}
          >
            Continuar
          </GenericButton>
        </div>
      </div>

      <div style={{ height: 50 }} />
    </Style.PartnersTypeBusinessContainer>
  );
};
