import React, { useEffect, useState } from 'react';
import * as Style from './Bills.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import { EmptyTransactions, LoadingSpinner } from '~components';
import Container from '~components/Container';
import QuickActions from '~components/QuickActions';
import { breakpoints } from '~styles/metrics';
import useWindowDimensions from '~hooks/windowDimension';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import MoneyIcon from '~assets/svg/money';
import BillIcon from '~assets/svg/statement/bill';
import BillRowList from '~components/BillRowList';
import { useGetAllSlips } from '~reactQuery/queries/bills/useGetAllSlips';
import { getSelectedUserOrganization } from '~helpers/device';
import { usePermission } from '~helpers/permissionGate';
import { useNavigate } from 'react-router-dom';
import { If, Else, Then, When } from 'react-if';
import Tabs from '~components/Tabs/Tabs.component';
import { AnimatePresence, motion } from 'framer-motion';

const Bills = () => {
  const [filterSelected, setFilterSelected] = useState<string>('');
  const { width } = useWindowDimensions();
  const { section, params: paramsModal } = useActionsOnModalMultiStep();
  const userOrganizationId = getSelectedUserOrganization();
  const { granted: billPageGranted } = usePermission('billPage');
  const { granted: billButtonNewDepositGranted } = usePermission(
    'billButtonNewDeposit'
  );
  const navigate = useNavigate();

  const defaultSlipFilter = {
    from: '',
    to: '',
    userOrganizationId: Number(userOrganizationId),
  };

  const defaultPagination = {
    page: 1,
    perPage: 15,
  };

  const {
    data: allSlips,
    isLoading: isLoadingAllSlips,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useGetAllSlips({
    filter: {
      ...defaultSlipFilter,
      ...(filterSelected && { status: filterSelected }),
    },
    ...defaultPagination,
  });

  const getMoneyIconFill = () =>
    section === 'transfer' ? colors.white : colors.black;

  const getBillIconFill = () =>
    section === 'payWithPix' && !paramsModal.pixQrCode
      ? colors.white
      : colors.black;

  const quickActions = [
    {
      name: 'Nova Cobrança',
      icon: <MoneyIcon fill={getMoneyIconFill()} />,
    },
    {
      name: 'Novo Boleto de Depósito',
      icon: <BillIcon height={16} width={16} fill={getBillIconFill()} />,
      section: billButtonNewDepositGranted ? 'deposit' : '',
    },
  ];

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } =
      event.target as HTMLDivElement;
    const isAtBottom = scrollHeight - scrollTop - 10 < clientHeight;

    if (!allSlips) {
      return;
    }

    if (isAtBottom && hasNextPage && !isFetchingNextPage) {
      await fetchNextPage();
    }
  };

  const isLoading = Boolean(!allSlips?.pages || isLoadingAllSlips);

  useEffect(() => {
    if (!billPageGranted) {
      navigate('/');
    }
  }, []);

  const filterOptions = [
    { value: '', label: 'Todos' },
    { value: 'REGISTERED', label: 'Em aberto' },
    { value: 'CANCELLEDBYDEADLINE', label: 'Vencido' },
    { value: 'CANCELLED', label: 'Cancelado' },
    { value: 'SETTLED', label: 'Pago' },
  ];

  const selectedTab = filterOptions?.filter(
    (item) => item?.value === filterSelected
  )?.[0]?.label;

  const handleFilter = (value: string) => {
    setFilterSelected(value);
  };

  return (
    <Style.Container>
      <Container>
        <Text.Heading1Bold marginBottom={breakpoints.tablet < width ? 0 : 25}>
          Boletos e Cobranças
        </Text.Heading1Bold>

        <QuickActions quickActions={quickActions} />

        <Tabs
          width="100%"
          selectedTab={selectedTab}
          ulSize={29}
          height={44}
          style={{ marginTop: 25 }}
          borderBottom
          hasContent={false}
          tabs={[
            {
              tabName: 'Todos',
              tabAction: () => handleFilter(''),
            },
            {
              tabName: 'Em aberto',
              tabAction: () => handleFilter('REGISTERED'),
            },
            {
              tabName: 'Vencido',
              tabAction: () => handleFilter('CANCELLEDBYDEADLINE'),
            },
            {
              tabName: 'Cancelado',
              tabAction: () => handleFilter('CANCELLED'),
            },
            {
              tabName: 'Pago',
              tabAction: () => handleFilter('SETTLED'),
            },
          ]}
        />

        <If condition={isLoading}>
          <Then>
            <div className="container-loading">
              <LoadingSpinner
                color={colors.primary}
                size={60}
                strokeSize={25}
              />
            </div>
          </Then>

          <Else>
            <When condition={!allSlips?.pages?.[0]?.data?.slips?.length}>
              <div className="empty-transactions">
                <EmptyTransactions noTransactionButton />
              </div>
            </When>

            <When condition={allSlips?.pages?.[0]?.data?.slips?.length}>
              <AnimatePresence mode="wait">
                <motion.div
                  key="bill-statement"
                  style={{ width: '100%', height: '100%' }}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <BillRowList
                    allSlipsPages={allSlips?.pages || []}
                    onScroll={handleScroll}
                    isFetchingNextPage={isFetchingNextPage}
                  />
                </motion.div>
              </AnimatePresence>
            </When>
          </Else>
        </If>
      </Container>
    </Style.Container>
  );
};

export default Bills;
