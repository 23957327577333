import React, { SVGProps } from 'react';

const PixAndTedIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '20'}
      height={props.height || '21'}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3294 7.17737L17.5375 9.38547C18.1542 10.0021 18.1542 11.0018 17.5375 11.6184L15.3295 13.8265H13.6934C13.5631 13.8265 13.4382 13.7747 13.3461 13.6826L11.7616 12.0982C10.7887 11.1253 9.21138 11.1253 8.2385 12.0982L6.65407 13.6826C6.56195 13.7747 6.437 13.8265 6.30672 13.8265H4.67051L2.46246 11.6184C1.84585 11.0018 1.84585 10.0021 2.46246 9.38547L4.67056 7.17737L6.30672 7.17737C6.43701 7.17737 6.56195 7.22912 6.65407 7.32125L8.2385 8.90567C9.21139 9.87856 10.7887 9.87856 11.7616 8.90567L13.3461 7.32125C13.4382 7.22912 13.5631 7.17737 13.6934 7.17737L15.3294 7.17737ZM13.3528 5.20075L11.1165 2.96442C10.4999 2.3478 9.50013 2.3478 8.88352 2.96441L6.6472 5.20073C7.18215 5.27453 7.68209 5.52083 8.06829 5.90703L9.65271 7.49146C9.84455 7.6833 10.1556 7.6833 10.3474 7.49146L11.9318 5.90703C12.318 5.52086 12.8179 5.27456 13.3528 5.20075ZM6.64715 15.8031L8.88352 18.0395C9.50013 18.6561 10.4999 18.6561 11.1165 18.0395L13.3529 15.8031C12.8179 15.7293 12.318 15.483 11.9318 15.0968L10.3474 13.5124C10.1556 13.3206 9.84455 13.3206 9.65271 13.5124L8.06828 15.0968C7.68207 15.483 7.18212 15.7293 6.64715 15.8031ZM7.46931 1.5502C8.86697 0.152536 11.133 0.152538 12.5307 1.5502L18.9518 7.97126C20.3494 9.36892 20.3494 11.635 18.9518 13.0326L12.5307 19.4537C11.133 20.8514 8.86697 20.8514 7.4693 19.4537L1.04825 13.0326C-0.349417 11.635 -0.349415 9.36892 1.04825 7.97126L7.46931 1.5502Z"
        fill={props.fill || '#272937'}
      />
    </svg>
  );
};

export default PixAndTedIcon;
