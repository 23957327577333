import * as Styles from '../Convert.styles';
import * as Text from '~styles/text';

import { CheckSelectSmall, InputForm } from '~components/Inputs';

import CapAccountSVG from '~assets/svg/capAccount';
import { DestinationWalletProps } from '../Convert.types';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { WalletType } from '~reactQuery/types/wallets.types';
import { getSelectedUserOrganization } from '~helpers/device';
import { getWalletIcon } from '~helpers/format/currency';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';
import { useState } from 'react';
import { isLockedTransaction } from '~helpers/transaction/transactionUtils';

function DestinationWallet(props: DestinationWalletProps) {
  const { setCurrentStep, originWallet } = props;

  const previouslySelectedDestinationWallet = props.destinationWallet;

  const userOrganizationId = getSelectedUserOrganization();

  const { data: wallets = [] } = useGetWallets({
    prefCurrency: 'BRL',
    flag: 'list',
    userOrganizationId: Number(userOrganizationId),
  });

  const [destinationWallet, setDestinationWallet] = useState<WalletType>({});
  const [search, setSearch] = useState('');

  const goBack = () => {
    setCurrentStep('chooseWallets');
  };

  const goToNextStep = () => {
    setCurrentStep('chooseWallets', { destinationWallet });
  };

  const handleOnClickWallet = (w: WalletType) => {
    setDestinationWallet(w);
  };

  const disableNextButton = !destinationWallet.walletAddress;

  const { data: capAccountInfo } = useGetCapAccountInfo({
    userOrganizationId: Number(userOrganizationId),
  });

  const capAccountWallet: WalletType = {
    id: 0,
    owner: 0,
    createdAt: '',
    updatedAt: '',
    walletAddress: 'CAPCONTA',
    walletCurrency: 'BRL',
    walletName: 'CapConta',
    funds: Number(capAccountInfo?.available) || 0,
    isOwnerInfoPublic: 0,
    priceInPrefCurrency: 0,
    isFavorite: 0,
    isPublic: 0,
    currency: {
      id: 0,
      type: 'fiat',
      name: 'CapConta',
      symbol: 'R$',
      isoCode: 'BRL',
      precision: 2,
    },
    ordering: 1,
    enabled: 1,
    enabledTransfer: true,
    enabledConvert: true,
    enabledPay: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  };

  const filteredWallets = [capAccountWallet, ...wallets].filter((w) => {
    const query = search.toLowerCase();
    const walletIsoCode = w.currency.isoCode.toLowerCase();
    const walletName = w.currency.name.toLowerCase();

    let isSameWalletFromOrigin = false;

    if (originWallet) {
      isSameWalletFromOrigin = originWallet.walletAddress !== w.walletAddress;
    }

    if (walletIsoCode.includes(query) || walletName.includes(query))
      return isSameWalletFromOrigin;

    return false;
  });

  return (
    <>
      <ModalMultiStepHeader
        title="Destino da conversão"
        backButtonAction={goBack}
      />
      <div className="convert-body">
        <Styles.OriginWalletList>
          <InputForm
            marginBottom={25}
            placeholder="Pesquisar moeda"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          {previouslySelectedDestinationWallet && (
            <>
              <Text.Heading3 marginBottom={20}>Selecionado</Text.Heading3>
              <div className="origin-wallet-wrapper">
                <CheckSelectSmall
                  key={previouslySelectedDestinationWallet.walletAddress}
                  title={previouslySelectedDestinationWallet.walletName}
                  text={String(
                    previouslySelectedDestinationWallet.currency.isoCode
                  )}
                  iconType="bankIcon"
                  onClick={() => {}}
                  icon={getWalletIcon(
                    previouslySelectedDestinationWallet.currency.isoCode
                  )}
                />
              </div>
            </>
          )}

          <Text.Heading3 marginBottom={20}>Carteiras Disponíveis</Text.Heading3>
          {filteredWallets
            .filter((wallet) => {
              if (
                originWallet?.currency &&
                isLockedTransaction(
                  originWallet?.currency.isoCode,
                  wallet.currency.isoCode
                )
              ) {
                return false;
              }
              if (previouslySelectedDestinationWallet) {
                return (
                  wallet.walletAddress !==
                  previouslySelectedDestinationWallet.walletAddress
                );
              }
              return true;
            })
            .map((w) => (
              <div className="origin-wallet-wrapper">
                <CheckSelectSmall
                  key={w.walletAddress}
                  title={w.walletName}
                  text={w.currency.isoCode}
                  iconType="bankIcon"
                  selected={w.walletAddress === destinationWallet.walletAddress}
                  onClick={() => handleOnClickWallet(w)}
                  icon={
                    w.walletAddress === 'CAPCONTA' ? (
                      <CapAccountSVG />
                    ) : (
                      getWalletIcon(w.currency.isoCode)
                    )
                  }
                />
              </div>
            ))}
        </Styles.OriginWalletList>
      </div>
      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonAction={goToNextStep}
        disableSingleButton={disableNextButton}
      />
    </>
  );
}

export default DestinationWallet;
