import * as Style from '../Kyc.styles';
import * as Text from '~styles/text';

import { Else, If, Then, When } from 'react-if';

import CompanyDataIcon from '~assets/images/icons/kyc/company-data.svg';
import DocumentIcon from '~assets/images/icons/kyc/status/document.svg';
import { KycStatusPageProps } from '../Kyc.types';
import LoadingSpinner from '~components/LoadingSpinner';
import OptionSelect from '~components/OptionSelect';
import WarningIcon from '~assets/images/icons/kyc/status/alert.svg';
import { colors } from '~styles';
import dayjs from 'dayjs';
import { stepOfProcessStatus } from '~data/kyc';
import { useFormContext } from 'react-hook-form';
import { useGetCustomerData } from '~reactQuery/queries/user/useGetCustomerData';
import { useGetProcess } from '~reactQuery/queries/kyc/useGetProcess';
import { useTranslation } from 'react-i18next';

export const KycStatusPage = ({ goToStep }: KycStatusPageProps) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const { data: kycProcessList, isLoading: isLoadingGetProcess } =
    useGetProcess();

  const filterPending = kycProcessList?.filter(
    (p) => String(p.processStatus) === 'PENDING'
  )[0];

  const filterDenied = kycProcessList?.filter(
    (p) => String(p.processStatus) === 'DENIED'
  )[0];

  const kycProcessData = filterPending || filterDenied;

  const ownerDocNum = kycProcessData?.user?.documentNumber;

  const { data: kycOwnerData } = useGetCustomerData(ownerDocNum || '');

  const ownerName = kycOwnerData?.socialName;
  const businessStatus = kycProcessData?.business?.[0]?.status;
  const partnersStatus = kycProcessData?.business?.[0]?.partnerData?.map(
    (partner: any) => partner?.status
  );

  const businessDataStatus =
    kycProcessData?.business?.[0]?.businessData?.status;
  const hasPartners = Boolean(Number(watch('numberOfPartners')));

  const ownerStatus = kycProcessData?.business?.[0]?.partnerData
    ?.filter((p) => {
      return p?.documentNumber === ownerDocNum;
    })
    ?.map((p) => p?.status)?.[0];

  const businessUpdateTime = kycProcessData?.updatedAt;

  const businessDataUpdateTime =
    kycProcessData?.business?.[0]?.businessData?.updatedAt;

  const ownerUpdateTime = kycProcessData?.business?.[0]?.partnerData
    ?.filter((p) => {
      return p?.documentNumber === ownerDocNum;
    })
    ?.map((p) => p?.updatedAt)?.[0];

  const partnersUpdateTime = kycProcessData?.business?.[0]?.partnerData?.map(
    (p) => {
      return p.updatedAt;
    }
  )?.[0];

  const parseStatus = (sectionStatus: any, dateOfEvent?: string | string[]) => {
    if (Array.isArray(sectionStatus)) {
      if (
        ['PENDING', 'AWAITING', 'UNDERCHECKING', 'DENIED'].includes(
          String(sectionStatus)
        )
      ) {
        return stepOfProcessStatus.pending;
      }
      if (['REPROVED', 'DENIED'].includes(String(sectionStatus))) {
        return stepOfProcessStatus.reproved;
      }
      if (['DONE', 'APPROVED', 'VERIFIED'].includes(String(sectionStatus))) {
        return stepOfProcessStatus.approved;
      }
      if ([...sectionStatus].includes('PENDING')) {
        return stepOfProcessStatus.notSend;
      }
      if ([...sectionStatus].includes('DENIED')) {
        return stepOfProcessStatus.reproved;
      }
      if ([...sectionStatus].includes('APPROVED')) {
        return stepOfProcessStatus.approved;
      }
      return stepOfProcessStatus.notSend;
    }

    const dateOfEventParsed = dayjs(dateOfEvent?.slice(0, 10)).format(
      'DD/MM/YYYY'
    );

    const timeParsed = dayjs(dateOfEvent).format('HH:mm');

    switch (sectionStatus) {
      case 'DONE':
        return {
          ...stepOfProcessStatus.approved,
          message: dateOfEventParsed
            ? `Enviado em ${dateOfEventParsed} às ${timeParsed}`
            : '',
        };
      case 'PENDING':
        return {
          ...stepOfProcessStatus.pending,
          message: dateOfEventParsed
            ? `Enviado em ${dateOfEventParsed} às ${timeParsed}`
            : '',
        };
      case 'AWAITING':
        return {
          ...stepOfProcessStatus.pending,
          message: dateOfEventParsed
            ? `Enviado em ${dateOfEventParsed} às ${timeParsed}`
            : '',
        };
      case 'REPROVED':
        return stepOfProcessStatus.reproved;
      case 'APPROVED':
        return {
          ...stepOfProcessStatus.approved,
          message: dateOfEventParsed
            ? `Enviado em ${dateOfEventParsed} às ${timeParsed}`
            : '',
        };
      case 'DENIED':
        return stepOfProcessStatus.reproved;
      case 'UNDERCHECKING':
        return {
          ...stepOfProcessStatus.pending,
          message: dateOfEventParsed
            ? `Enviado em ${dateOfEventParsed} às ${timeParsed}`
            : '',
        };
      case 'VERIFIED':
        return {
          ...stepOfProcessStatus.approved,
          message: dateOfEventParsed
            ? `Enviado em ${dateOfEventParsed} às ${timeParsed}`
            : '',
        };
      default:
        return stepOfProcessStatus.notSend;
    }
  };

  const bizItens = [
    {
      title: 'Dados e Informações',
      status: parseStatus(businessStatus, businessUpdateTime),
      link: () => goToStep('companyData', { statusScreen: true }),
    },
    {
      title: 'Documentos',
      status: parseStatus(businessDataStatus, businessDataUpdateTime),
      link: () => goToStep('sendDocs', { statusScreen: true }),
    },
  ];

  const showPendingMessage = [
    ownerStatus,
    partnersStatus,
    businessDataStatus,
    businessStatus,
  ]
    .flat()
    .some((s) => s === 'PENDING');

  const partnerItens = [
    {
      title: 'Dados e Documentos do Proprietário',
      status: parseStatus(ownerStatus, ownerUpdateTime),
      link: () =>
        goToStep('registerPartners', {
          statusScreen: true,
          onlyShowOwner: true,
          hideOwner: false,
        }),
    },
    {
      title: 'Dados e Documentos dos Sócios',
      status: parseStatus(
        partnersStatus?.includes('REPROVED') ? 'REPROVED' : 'PENDING',
        partnersUpdateTime
      ),
      link: () =>
        goToStep('registerPartners', {
          statusScreen: true,
          onlyShowOwner: false,
          hideOwner: true,
        }),
    },
  ];

  return (
    <If condition={isLoadingGetProcess}>
      <Then>
        <div className="flex column w-100 h-100 align-center justify-center">
          <LoadingSpinner color={colors.primary} size={60} strokeSize={25} />
        </div>
      </Then>
      <Else>
        <Style.StatusContainer>
          <div style={{ height: 48, width: 48, marginBottom: 10 }}>
            <CompanyDataIcon style={{ borderRadius: 15 }} />
          </div>
          <When condition={!!ownerName}>
            <Text.Heading1Medium>{` Olá, ${ownerName}!`}</Text.Heading1Medium>
          </When>

          <Text.Heading1Medium marginBottom={30}>
            {t('Bem vindo de volta!')}
          </Text.Heading1Medium>

          <Text.Heading5 display="inline-block">
            {t(
              'Aqui você pode acompanhar o andamento da sua solicitação da conta Business, bem como editar e enviar documentos quando necessário.'
            )}
          </Text.Heading5>

          {showPendingMessage && (
            <div className="warning-message">
              <WarningIcon />

              <Text.Small color={colors.danger}>
                {t(
                  'Encontramos itens pendentes e/ou não aprovados na sua solicitação. Conclua o envio dessas informações para darmos continuidade com o seu cadastro.'
                )}
              </Text.Small>
            </div>
          )}

          <Text.Heading2 marginTop={30} marginBottom={10}>
            {t('Empresa')}
          </Text.Heading2>

          {bizItens.map((item) => (
            <OptionSelect
              key={item.title}
              paddingVertical={20}
              icon={<DocumentIcon />}
              title={item.title}
              subtitle={item.status?.message}
              subtitleColor={item.status?.color}
              rightIcon={item.status?.icon}
              onClick={item.status.enableRedirect ? item.link : () => {}}
              enableAnimation={item.status?.enableRedirect}
            />
          ))}

          <>
            <Text.Heading2 marginTop={30} marginBottom={10}>
              Proprietário
              {hasPartners ? ' e Sócios' : ''}
            </Text.Heading2>

            {partnerItens.map((item, index) => {
              if (!hasPartners && index === 1) return null;
              return (
                <OptionSelect
                  key={item.title}
                  paddingVertical={20}
                  icon={<DocumentIcon />}
                  title={item.title}
                  subtitle={item.status?.message}
                  subtitleColor={item.status?.color}
                  rightIcon={item.status?.icon}
                  onClick={item.status.enableRedirect ? item.link : () => {}}
                  enableAnimation={item.status?.enableRedirect}
                />
              );
            })}
          </>
        </Style.StatusContainer>
      </Else>
    </If>
  );
};
