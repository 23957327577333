import styled, { css } from 'styled-components';

import { GenericButtonProps } from './GenericButton.types';
import { colors } from '~styles';

export const titleColor: Record<GenericButtonProps['buttonType'], string> = {
  primary: colors.white,
  secondary: colors.white,
  gray: colors.black,
  inline: colors.black,
  default: colors.black,
  error: colors.white,
  inlineError: colors.danger,
};

export const bgColor: Record<GenericButtonProps['buttonType'], string> = {
  primary: colors.primary,
  secondary: colors.black,
  gray: '#F3F4F9',
  inline: colors.transparent,
  default: colors.transparent,
  error: colors.danger,
  inlineError: colors.transparent,
};

export const buttonHeight: Record<GenericButtonProps['buttonType'], number> = {
  primary: 48,
  secondary: 48,
  gray: 48,
  inline: 48,
  default: 38,
  error: 48,
  inlineError: 48,
};

export const Container = styled.button<GenericButtonProps>`
  width: ${(props) => props.width || ''};
  min-width: ${(props) => (props.buttonType !== 'default' ? '127px' : '')};
  height: ${(props) => props.height || buttonHeight[props.buttonType]}px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.93rem;
  cursor: ${(props) => (props.buttonLoading ? 'default' : 'pointer')};
  outline: none;

  transition: background-color 0.3s ease-out;

  background-color: ${(props) => bgColor[props.buttonType]};
  color: ${(props) => titleColor[props.buttonType]} !important;

  border: transparent;
  border-radius: ${(props) => props.borderRadius || 200}px;

  ${({ noPadding }) =>
    !noPadding &&
    css`
      padding: ${(props) => props.padding || 20}px
        ${(props) => (props.buttonType !== 'default' ? 35 : 20)}px;
    `}

  margin: ${(props) => props.marginTop || 0}px
    ${(props) => props.marginRight || 0}px
    ${(props) => props.marginBottom || 0}px
    ${(props) => props.marginLeft || 0}px;

  .move-top {
    display: flex;
    align-items: center;
    .icon-wrapper {
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }
  }

  &:active {
    .move-top {
      margin-top: 1px !important;
      transition: all ease-out 0.3s;
    }
  }

  &:hover {
    .move-top {
      margin-top: -2px;
      transition: all ease-out 0.3s;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;

    .move-top {
      margin-top: 0px !important;
    }
  }

  &.default:hover {
    background-color: ${colors.gray};
  }

  &.default:active {
    background-color: #c3c6db50;
  }

  &.primary:hover {
    background-color: #0f80ff;
    background-color: ${(props) =>
      props.buttonLoading ? colors.primary : '#0f80ff'};
  }

  &.primary:disabled:hover {
    background-color: ${colors.primary};
  }

  &.secondary:hover {
    background-color: #000000;
  }

  &.secondary:disabled:hover {
    background-color: ${colors.black};
  }

  &.gray:active {
    background-color: ${colors.grayRegular};
  }

  &.gray:hover {
    background-color: ${colors.grayLight};
  }

  &.inline {
    transition: 0.3s ease-out;
    color: ${colors.black} !important;
    outline: 1px solid ${colors.black};
    background-color: ${colors.transparent};
  }

  &.inline:hover {
    outline-width: 2px;
  }

  &.inline:disabled:hover {
    outline-width: 1px;
  }

  &.inlineError {
    transition: 0.3s ease-out;
    color: ${colors.danger} !important;
    outline: 1px solid ${colors.danger};
    background-color: ${colors.transparent};
  }

  &.inlineError:hover {
    outline-width: 2px;
  }

  &.inlineError:disabled:hover {
    outline-width: 1px;
  }
`;
