import { MenuItem } from '@szhsin/react-menu';
import {
  DropDownItemTitle,
  DropDownItemContainer,
  DropDownItemIconContainer,
  DropDownItemSubtitle,
} from './DropDownItem.styles';
import { DropDownItemProps } from './DropDownItem.types';

const DropDownItem = ({
  title,
  subtitle,
  icon,
  isNew,
  isRed,
  onClick,
  isDisabled,
  disableHover,
}: DropDownItemProps) => {
  return (
    <DropDownItemContainer disableHover={disableHover}>
      <MenuItem
        className={`menu-item ${isDisabled && 'disabled'}`}
        onClick={onClick}
        disabled={isDisabled}
      >
        {icon && (
          <DropDownItemIconContainer isRed={isRed}>
            {icon}
          </DropDownItemIconContainer>
        )}
        <div className="text-container">
          <DropDownItemTitle fontSize={0.938} isNew={isNew} isRed={isRed}>
            {title}
          </DropDownItemTitle>

          {subtitle && (
            <DropDownItemSubtitle fontSize={0.75}>
              {subtitle}
            </DropDownItemSubtitle>
          )}
        </div>
      </MenuItem>
    </DropDownItemContainer>
  );
};

export default DropDownItem;
