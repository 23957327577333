import * as Style from './Kyc.styles';

import {
  BusinessPartnerCnpj,
  BusinessPartnerForm,
  BusinessPartnerName,
  BusinessPartnerSendDocs,
  HowToFinishRegister,
  PartnerCPF,
  PartnerForm,
  PartnerName,
  SendEmailOrSms,
  StartModal,
} from './PartnersSteps';
import {
  CompanyDataForm,
  Finish,
  KycStatusPage,
  RegisterPartners,
  SendDocs,
  Start,
} from './KycSteps';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { GenericButton, LoadingSpinner, Modal } from '~components';
import { ModalSteps, Steps } from './Kyc.types';
import { defaultBusinessValues, stepsStatusProgressBar } from '~data/kyc';
import { memo, useCallback, useEffect, useState } from 'react';
import {
  parseBusinessData,
  parsePartnersSchema,
  parserBusiness,
} from '~helpers/format/parses';
import { useLocation, useNavigate } from 'react-router-dom';

import AlertModal from '~components/AlertModal';
import { BusinessType } from '~reactQuery/types/business.types';
import BusinessWhiteLogo from '~assets/images/business-white.svg';
import CapitualWhiteLogo from '../../assets/images/logo-white.svg';
import { When } from 'react-if';
import { breakpoints } from '~styles/metrics';
import { clearStorage } from '~helpers/device';
import colors from '~styles/colors';
import { kycCompanySchema } from './validation/kyc.schema';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { useGetBusiness } from '~reactQuery/queries/business/useGetBusiness';
import { useGetCustomerData } from '~reactQuery/queries/user/useGetCustomerData';
import { useGetPartnerData } from '~reactQuery/queries/kyc/useGetPartnerData';
import { useGetProcess } from '~reactQuery/queries/kyc/useGetProcess';
import { usePartnersList } from './store';
import { useQueryClient } from 'react-query';
import useWindowDimensions from '~hooks/windowDimension';
import { yupResolver } from '@hookform/resolvers/yup';
import { handleOnClickHelp } from '~helpers/generic';

const Kyc = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { field } = usePartnersList();
  const { width } = useWindowDimensions();
  const { state: navState } = useLocation();

  const [step, setStep] = useState<Steps>('loading');
  const [modalStep, setModalStep] = useState<ModalSteps>('start');
  const [openModal, setOpenModal] = useState(false);
  const [sureToCloseModal, setSureToCloseModal] = useState(false);
  const [stepsParams, setStepsParams] = useState({});
  const [loadingGetData, setLoadingGetData] = useState(true);
  const [manualAtt, setManualAtt] = useState(true);

  const { data: kycProcessList, isLoading: isLoadingProcess } = useGetProcess();

  const filterPending = kycProcessList?.filter(
    (p) => String(p.processStatus) === 'PENDING'
  )[0];

  const filterDenied = kycProcessList?.filter(
    (p) => String(p.processStatus) === 'DENIED'
  )[0];

  const kycProcessData = filterPending || filterDenied;

  const {
    data: kycPartnersData,
    isLoading: isLoadingPartners,
    isRefetching: isRefetchingPartnerData,
  } = useGetPartnerData(
    {
      processId: Number(kycProcessData?.id),
    },
    !kycProcessData?.business || kycProcessData?.business?.length === 0
  );

  const { data: businessInfos, isLoading: loadingGetBiz } = useGetBusiness({
    businessId: kycProcessData?.business?.[0]?.id,
  });

  const ownerDocNum = kycProcessData?.user?.documentNumber;

  const { data: kycOwnerData, isLoading: isLoadingGetOwnerInfos } =
    useGetCustomerData(ownerDocNum || '');

  // BUSINESS INFOS
  const businessFormMethods = useForm({
    defaultValues: defaultBusinessValues,
    mode: 'onSubmit',
    resolver: yupResolver(kycCompanySchema),
  });

  const goToModalStep = (stepPos: ModalSteps, disableGoBack?: boolean) => {
    setModalStep(stepPos);
  };

  const goToStep = useCallback((stepPos: Steps, params?: any) => {
    if (params) setStepsParams((prev) => ({ ...prev, ...params }));
    setStep(stepPos);
  }, []);

  const businessType =
    (businessFormMethods.getValues('businessType') as BusinessType) || '';

  const isCryptoTrader = kycProcessList?.filter(
    (p) => String(p.processStatus) === 'PENDING'
  )[0]?.business?.[0]?.isCryptoTrader;

  const logout = () => {
    clearStorage(queryClient);
    history.go(0);
  };

  const partnerList = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const partnerArrayMethods = useFieldArray({
    control: partnerList.control,
    name: 'allPartners',
  });

  const setOpenModalAddPartners = () => {
    const partnerInCase = partnerList.getValues(String(field));

    if (
      !partnerInCase?.powerOfAttorney ||
      partnerInCase?.powerOfAttorney.length === 0 ||
      !partnerInCase?.powerOfAttorneyEndDate ||
      !partnerInCase?.powerOfAttorneyStartDate ||
      !partnerInCase?.isPowerOfAttorney
    ) {
      partnerList.setValue(`${String(field)}.isPowerOfAttorney`, false);
      partnerList.setValue(`${String(field)}.powerOfAttorneyStartDate`, '');
      partnerList.setValue(`${String(field)}.powerOfAttorneyEndDate`, '');
      partnerList.setValue(`${String(field)}.powerOfAttorney`, '');
    }

    if (
      partnerInCase?.isSaved ||
      partnerInCase?.partnerType === 'owner' ||
      partnerInCase?.partnerId ||
      partnerInCase?.businessId
    ) {
      setOpenModal((prev) => !prev);
    } else {
      setSureToCloseModal(true);
    }
  };

  const sureToCloseAction = () => {
    const personalPartners = partnerList
      .getValues('allPartners.[0].list')
      .filter(
        (p: any) =>
          p?.isSaved || p.partnerType === 'owner' || p.partnerId || p.businessId
      );

    const bizPartners = partnerList
      .getValues('allPartners.[1].list')
      .filter((p: any) => p?.isSaved || p.businessId || p.partnerId);

    const arrayPartnerFiltered = [] as any;

    bizPartners.map((p: any) => {
      arrayPartnerFiltered.push({
        ...p,
        partnerData:
          p.partnerData &&
          p.partnerData.filter((p: any) => p.isSaved || p.partnerId),
      });
    });

    const onlySaved = [
      {
        type: 'personalPartner',
        list: [...personalPartners],
      },
      {
        type: 'businessPartner',
        list: arrayPartnerFiltered,
      },
    ];

    partnerList.setValue('allPartners', onlySaved);
    partnerList.clearErrors();

    setSureToCloseModal(false);
    setOpenModal((prev) => !prev);
  };

  const addPartnerModalSteps = {
    start: <StartModal key="StartModal" gotToModalStep={goToModalStep} />,
    partnerName: (
      <PartnerName
        key="PartnerName"
        partnersMethods={partnerList}
        gotToModalStep={goToModalStep}
      />
    ),
    businessPartnerName: (
      <BusinessPartnerName
        key="BusinessPartnerName"
        gotToModalStep={goToModalStep}
        partnerList={partnerList}
      />
    ),
    businessPartnerCnpj: (
      <BusinessPartnerCnpj
        key="BusinessPartnerCnpj"
        gotToModalStep={goToModalStep}
        partnerList={partnerList}
      />
    ),
    documentNumber: (
      <PartnerCPF
        key="DocumentNumber"
        partnersMethods={partnerList}
        gotToModalStep={goToModalStep}
      />
    ),
    sendEmailOrSms: (
      <SendEmailOrSms key="SendEmailOrSms" gotToModalStep={goToModalStep} />
    ),
    partnerForm: (
      <PartnerForm
        key="PartnerForm"
        partnersMethods={partnerList}
        gotToModalStep={goToModalStep}
        setOpenModal={setOpenModal}
      />
    ),
    howToFinishRegister: (
      <HowToFinishRegister
        key="howToFinishRegister"
        gotToModalStep={goToModalStep}
      />
    ),
    registerPartnerTypeBusiness: (
      <BusinessPartnerForm
        key="BusinessPartnerForm"
        partnerFormMethods={partnerList}
        gotToModalStep={goToModalStep}
        setOpenModal={setOpenModal}
      />
    ),
    businessPartnerSendDocs: (
      <BusinessPartnerSendDocs
        key="BusinessPartnerSendDocs"
        partnerArrayMethods={partnerArrayMethods}
        partnerFormMethods={partnerList}
        setOpenModalAddPartners={setOpenModalAddPartners}
      />
    ),
  };

  const kycPagesSteps = {
    loading: (
      <div className="flex column w-100 h-100 align-center justify-center">
        <LoadingSpinner color={colors.primary} size={60} strokeSize={25} />
      </div>
    ),
    status: <KycStatusPage key="KycStatusPage" goToStep={goToStep} />,
    start: (
      <Start kycProcessData={kycProcessData} key="Start" goToStep={goToStep} />
    ),
    companyData: (
      <CompanyDataForm
        key="CompanyDataForm"
        stepParams={stepsParams}
        goToStep={goToStep}
        processData={kycProcessData}
      />
    ),
    sendDocs: (
      <SendDocs
        key="SendDocs"
        stepParams={stepsParams}
        goToStep={goToStep}
        businessType={businessType}
        isCryptoTrader={isCryptoTrader}
        processData={kycProcessData}
        numberOfPartners={businessFormMethods.watch('numberOfPartners') || 0}
      />
    ),
    registerPartners: (
      <RegisterPartners
        key="RegisterPartners"
        partnerArrayMethods={partnerArrayMethods}
        partnersMethods={partnerList}
        processId={Number(kycProcessData?.id)}
        stepParams={stepsParams}
        partnersUseFieldsArrayMethods={partnerArrayMethods}
        businessInfos={businessInfos}
        setPartnerModal={setOpenModal}
        goToStep={goToStep}
        gotToModalStep={goToModalStep}
        kycProcessData={kycProcessData}
        kycOwnerData={kycOwnerData}
        openModal={openModal}
      />
    ),
    finish: <Finish key="Finish" goToStep={goToStep} />,
  };

  // SET LIST OF PARTNERS TYPE BIZ AND TYPE PERSONAL AND THIRD DIMENSION PARTNERS
  // SET FIELDS ERROS IN FORMS

  useEffect(() => {
    if (businessInfos && kycPartnersData && ownerDocNum && kycOwnerData) {
      const bizPartners = businessInfos?.businessesPartners;

      kycOwnerData.status = 'UNFINISHED';

      const ownerData = parsePartnersSchema(
        [kycOwnerData],
        'owner',
        ownerDocNum
      );

      const hasOwnerInPartners = kycPartnersData.map(
        (i) => onlyNumbers(i.documentNumber) === onlyNumbers(ownerDocNum)
      );

      const defaultValues = {
        allPartners: [
          {
            type: 'personalPartner',
            list:
              kycPartnersData.length > 0
                ? hasOwnerInPartners.includes(true)
                  ? [
                      ...parsePartnersSchema(
                        kycPartnersData,
                        'partner',
                        ownerDocNum
                      )
                        ?.sort((a: any, b: any) => (a?.id > b?.id ? 1 : -1))
                        ?.sort((p: any) =>
                          onlyNumbers(p.documentNumber) ===
                          onlyNumbers(ownerDocNum)
                            ? -1
                            : 1
                        ),
                    ]
                  : [
                      ...ownerData,
                      ...parsePartnersSchema(
                        kycPartnersData,
                        'partner',
                        ownerDocNum
                      )?.sort((a: any, b: any) => (a?.id > b?.id ? 1 : -1)),
                    ]
                : [...ownerData],
          },
          {
            type: 'businessPartner',
            list:
              bizPartners.length > 0
                ? bizPartners
                    ?.sort((a: any, b: any) => (a?.id > b?.id ? 1 : -1))
                    ?.map((business) => parserBusiness(business, true))
                : [],
          },
        ],
      };

      partnerList.reset(defaultValues);

      // P.PARTNERS TYPE

      const partnersFields = defaultValues.allPartners?.[0]?.list?.map(
        (partner: any) => partner?.fields
      );

      if (partnersFields) {
        partnersFields?.map((singlePartner: any, index: number) => {
          singlePartner?.forEach((field: any) => {
            if (String(field?.status) === 'DENIED') {
              partnerList.setError(
                `allPartners.[0].list.${index}.${field?.field}`,
                {
                  message: field?.description || 'Inválido',
                }
              );
            }
          });
        });
      }

      // BIZ PARTNER
      const bizPartnersFields = defaultValues.allPartners?.[1]?.list?.map(
        (p: any) => p?.fields
      );

      if (bizPartnersFields.length > 0) {
        bizPartnersFields?.map((fields: any, index: number) => {
          fields.map((field: any) => {
            if (String(field?.status) === 'DENIED') {
              partnerList.setError(
                `allPartners.[1].list.${index}.${field?.field}`,
                {
                  message: field?.description || 'Inválido',
                }
              );
            }
          });
        });
      }

      // PARTNERS PERSONAL INSIDE BIZ PARTNERS

      const partnersInsideAllBusiness =
        defaultValues.allPartners?.[1]?.list?.map(
          (partner: any) => partner?.partnerData
        );

      if (partnersInsideAllBusiness?.length > 0) {
        const listOfPartnersFields = partnersInsideAllBusiness?.map(
          (partnersSingleBiz: any) =>
            partnersSingleBiz?.map((singlePartner: any) => singlePartner.fields)
        );

        if (listOfPartnersFields?.length > 0) {
          listOfPartnersFields?.map(
            (singlePartner: any, indexOfBiz: number) => {
              singlePartner?.map((fields: any, indexOfSubPart: number) => {
                fields.map((field: any) => {
                  if (String(field?.status) === 'DENIED') {
                    partnerList.setError(
                      `allPartners.[1].list.${indexOfBiz}.partnerData.${indexOfSubPart}.${field?.field}`,
                      {
                        message: field?.description || 'Inválido',
                      }
                    );
                  }
                });
              });
            }
          );
        }
      }
      setLoadingGetData(false);
    } else if (!loadingGetBiz && !isLoadingPartners) {
      setLoadingGetData(false);
    }
  }, [
    loadingGetBiz,
    isLoadingPartners,
    isLoadingProcess,
    isLoadingGetOwnerInfos,
    businessInfos,
    kycPartnersData,
    ownerDocNum,
    kycOwnerData,
    isRefetchingPartnerData,
    manualAtt,
  ]);

  // SET SCREEN WHEN GET DATA

  const ableToCreateNewProcess =
    navState?.newOrg &&
    String(kycProcessData?.business?.[0]?.status) === 'APPROVED' &&
    String(kycProcessData?.business?.[0]?.partnerData?.[0]?.status) ===
      'APPROVED' &&
    String(kycProcessData?.business?.[0]?.businessData?.status) === 'APPROVED';

  useEffect(() => {
    if (!isLoadingProcess && kycProcessData && !loadingGetData) {
      if (ableToCreateNewProcess) {
        setStep('start');
      } else {
        setStep(kycProcessData?.business?.[0]?.id ? 'status' : 'start');
      }
    } else if (!kycProcessData && !isLoadingPartners) {
      setStep('start');
    }
  }, [isLoadingProcess, loadingGetData]);

  // SET BIZ INFO AND ERRORS ON FIELDS
  useEffect(() => {
    if (kycProcessData && !isLoadingProcess && !loadingGetBiz) {
      const { setValue, getValues, setError } = businessFormMethods;

      const kycFields = kycProcessData?.business?.[0]?.fields;
      if (kycFields) {
        kycFields.forEach((field: any) => {
          if (String(field?.status) === 'DENIED') {
            setError(field?.field, {
              message: field?.description || 'Inválido',
            });
          }
        });
      }

      const serverBusinessUpdateTime = kycProcessData?.business?.[0]?.updatedAt;
      const businessLocalValues = getValues()?.updatedAt;

      if (businessLocalValues !== serverBusinessUpdateTime) {
        parseBusinessData(setValue, kycProcessData?.business?.[0]);
      }
    }
  }, [kycProcessData, isLoadingProcess, loadingGetBiz]);

  const invalidQueryAndSetNewData = async () => {
    if (step === 'status') {
      await queryClient.invalidateQueries();
      setManualAtt((prev) => !prev);
    }
  };

  useEffect(() => {
    invalidQueryAndSetNewData();
  }, [step]);

  const showProgress = !['start', 'finish', 'status', 'loading'].includes(step);

  return (
    <FormProvider {...businessFormMethods}>
      <Style.KycContainer>
        <When condition={breakpoints.desktop < width}>
          <Style.SideImage>
            <Style.LogoContainer onClick={() => navigate('/')}>
              <CapitualWhiteLogo />
              <BusinessWhiteLogo />
            </Style.LogoContainer>
          </Style.SideImage>
        </When>

        <When condition={sureToCloseModal}>
          <AlertModal
            setOpenAlert={() => {
              setSureToCloseModal(false);
            }}
            primaryButtonAction={() => {
              setSureToCloseModal(false);
            }}
            secondaryButtonAction={sureToCloseAction}
            primaryButtonText="Não"
            secondaryButtonText="Sim"
            title="Tem certeza que deseja fechar?"
            bodyText="Ao fechar esta janela, você perderá todo o processo preenchido até agora. Você tem certeza que deseja fazer isso?"
          />
        </When>

        <Modal openModal={openModal} closeModal={setOpenModalAddPartners}>
          {addPartnerModalSteps[modalStep]}
        </Modal>

        <Style.Content
          screenWidth={width}
          justifyContent={
            ['start', 'finish'].includes(step) ? 'center' : 'flex-start'
          }
        >
          <Style.Header
            style={step === 'start' ? { position: 'absolute', top: 0 } : {}}
          >
            <GenericButton buttonType="default" onClick={logout}>
              Sair
            </GenericButton>
            <GenericButton buttonType="default" onClick={handleOnClickHelp}>
              Ajuda
            </GenericButton>
          </Style.Header>
          <div className="scroll-container">
            <Style.ContentWrapper
              screenWidth={width}
              marginTop={step !== 'start' ? 50 : 0}
              marginBottom={step !== 'start' ? 100 : 60}
            >
              {kycPagesSteps[step]}
            </Style.ContentWrapper>
          </div>

          <When condition={showProgress}>
            <Style.ProgressContainer>
              <div
                className="bar"
                style={{ width: `${stepsStatusProgressBar[step] || 1}%` }}
              />
            </Style.ProgressContainer>
          </When>
        </Style.Content>
      </Style.KycContainer>
    </FormProvider>
  );
};

export default memo(Kyc);
