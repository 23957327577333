const BillIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { fill = 'none', stroke = '#272937', height = 16, width = 18 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 18 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.00195C0 0.449668 0.447715 0.00195312 1 0.00195312H4H5V2.00195H4H2V4.00195V5.00195H0V4.00195V1.00195ZM0 15.002C0 15.5542 0.447715 16.002 1 16.002H4H5V14.002H4H2V12.002V11.002H0V12.002V15.002ZM17 0.00195312C17.5523 0.00195312 18 0.449668 18 1.00195V4.00195V5.00195H16V4.00195V2.00195H14H13V0.00195312H14H17ZM18 15.002C18 15.5542 17.5523 16.002 17 16.002H14H13V14.002H14H16V12.002V11.002H18V12.002V15.002ZM6 5.00195V4.00195H4V5.00195V11.002V12.002H6V11.002V5.00195ZM10 4.00195V5.00195V11.002V12.002H8V11.002V5.00195V4.00195H10ZM14 5.00195V4.00195H12V5.00195V11.002V12.002H14V11.002V5.00195Z"
        fill={stroke}
      />
    </svg>
  );
};

export default BillIcon;
