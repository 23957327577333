import { colors } from '~styles';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon-container {
    display: flex;
    align-items: center;
    margin-right: 10px;

    svg {
      color: ${colors.primary};
    }
  }

  span {
    font-family: caption;
    line-height: 135%;
  }

  a {
    display: flex;
    flex-direction: row;

    svg {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .vertical-hr {
    width: 3px;
    height: 20px;
    border-right: 1px solid ${transparentize(0.9, colors.black)};
  }

  .arrow-back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    width: 26px;
    height: 26px;
    background-color: ${colors.white};
    border: 1px solid ${transparentize(0.9, colors.black)};
    border-radius: 37px;
    transform: rotate(180deg);
    cursor: pointer;
  }
`;

export const BankInfoButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 26px;
  padding: 10px 15px;
  border: 1px solid ${transparentize(0.9, colors.black)};
  border-radius: 37px;
  cursor: pointer;

  p {
    margin-right: 10px;
  }
`;
