import { SVGProps } from 'react';

const BitcoinIncomeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '175'}
      height={props.height || '151'}
      fill="none"
    >
      <path
        d="M135.457 66.0684L104.709 139.613C103.555 132.735 97.9902 120.916 90.7851 120.916H68.145C65.7826 120.916 63.4829 120.618 61.2937 120.05C60.8514 119.939 60.4202 119.814 59.9927 119.681C51.4055 117.009 44.6278 110.232 41.9558 101.645C41.8232 101.217 41.6978 100.786 41.5873 100.344C41.0234 98.1544 40.7212 95.8546 40.7212 93.4922L40.7322 92.733C40.7359 92.5119 40.7433 92.2981 40.758 92.0806L41.0087 89.5155C41.0308 89.3644 41.0529 89.217 41.0787 89.0696L41.2556 88.0782C41.2814 87.9529 41.3072 87.8276 41.333 87.706L41.7015 86.1949C41.7421 86.0401 41.79 85.889 41.8342 85.7342C41.9264 85.4283 42.0222 85.1224 42.1217 84.8202C42.2249 84.518 42.3281 84.2121 42.4423 83.9173C42.5529 83.6187 42.6708 83.3239 42.7924 83.029C43.4006 81.5659 44.1303 80.1654 44.9706 78.8423V78.8202C46.0578 77.1138 47.3256 75.529 48.7519 74.1028C53.7163 69.1421 60.575 66.072 68.145 66.072H135.457V66.0684Z"
        fill="#272937"
      />
      <path
        d="M105.125 133.027V66.0684H40.721L5.40283 150.002H90.7996C98.8266 150.002 105.332 143.497 105.332 135.47V135.448C105.332 134.622 105.258 133.815 105.129 133.027H105.125Z"
        fill="#0F90FF"
      />
      <path
        d="M0 130.056H90.1658"
        stroke="white"
        strokeWidth="1.82801"
        strokeMiterlimit="10"
      />
      <path
        d="M7.89429 111.776H68.5577"
        stroke="white"
        strokeWidth="1.82801"
        strokeMiterlimit="10"
      />
      <path
        d="M15.3723 93.4922H68.5578"
        stroke="white"
        strokeWidth="1.82801"
        strokeMiterlimit="10"
      />
      <path
        d="M105.125 66.0684V133.027C103.972 126.149 97.9902 120.916 90.7851 120.916H68.145C65.7826 120.916 63.4829 120.618 61.2937 120.05C60.8514 119.939 60.4202 119.814 59.9927 119.681C51.4055 117.009 44.6278 110.232 41.9558 101.645C41.8232 101.217 41.6978 100.786 41.5873 100.344C41.0234 98.1544 40.7212 95.8546 40.7212 93.4922L40.7322 92.733C40.7359 92.5119 40.7433 92.2981 40.758 92.0806L41.0087 89.5155C41.0308 89.3644 41.0529 89.217 41.0787 89.0696L41.2556 88.0782C41.2814 87.9529 41.3072 87.8276 41.333 87.706L41.7015 86.1949C41.7421 86.0401 41.79 85.889 41.8342 85.7342C41.9264 85.4283 42.0222 85.1224 42.1217 84.8202C42.2249 84.518 42.3281 84.2121 42.4423 83.9173C42.5529 83.6187 42.6708 83.3239 42.7924 83.0291C43.4006 81.5659 44.1303 80.1654 44.9706 78.8423V78.8202C46.0578 77.1138 47.3256 75.529 48.7519 74.1028C53.7163 69.1421 60.575 66.072 68.145 66.072H105.125V66.0684Z"
        fill="#272937"
      />
      <path
        d="M44.6277 93.4074V36.1531H115.905L88.8574 101.449L44.6277 93.4074Z"
        fill="#F3BA2F"
      />
      <path
        d="M174.932 0.00195312L147.346 66.0683H68.145C60.575 66.0683 53.72 69.1383 48.7519 74.099C47.3256 75.5253 46.0578 77.1064 44.9743 78.8165V78.8386C44.1303 80.1653 43.4006 81.5658 42.7924 83.0253C42.6708 83.3201 42.5566 83.615 42.4423 83.9135C42.3318 84.2083 42.2249 84.5106 42.1217 84.8165C42.0222 85.1187 41.9264 85.4246 41.8342 85.7305C41.79 85.8852 41.7421 86.0363 41.7015 86.1911L41.333 87.7022C41.3035 87.8275 41.2777 87.9528 41.2556 88.0744L41.0787 89.0658C41.0529 89.2096 41.0271 89.3607 41.0087 89.5118L40.758 92.0769C40.747 92.2943 40.7359 92.5081 40.7322 92.7292L40.7212 93.4884V27.4258C40.7212 12.2821 52.9976 0.00195312 68.145 0.00195312H174.932Z"
        fill="#F3F4F9"
      />
      <path
        d="M168.284 15.7906H67.312"
        stroke="#0F90FF"
        strokeWidth="1.82801"
        strokeMiterlimit="10"
      />
      <path
        d="M160.389 34.0745H67.312"
        stroke="#0F90FF"
        strokeWidth="1.82801"
        strokeMiterlimit="10"
      />
      <path
        d="M152.908 52.3582H67.312"
        stroke="#0F90FF"
        strokeWidth="1.82801"
        strokeMiterlimit="10"
      />
      <path
        d="M67.3967 116.176C79.9717 116.176 90.1658 105.982 90.1658 93.4073C90.1658 80.8324 79.9717 70.6383 67.3967 70.6383C54.8217 70.6383 44.6277 80.8324 44.6277 93.4073C44.6277 105.982 54.8217 116.176 67.3967 116.176Z"
        fill="#F3F4F9"
      />
      <path
        d="M73.0871 91.1482C74.4213 90.846 75.5048 90.0204 76.1756 88.9332C76.8463 87.8423 77.108 86.4897 76.8021 85.1298C76.1866 82.4099 73.065 80.7993 70.3967 81.4037L68.2591 81.8865L67.1535 77.0143L63.9987 77.7293L65.1043 82.6015L63.3758 82.9922L62.2702 78.1199L59.1154 78.8349L60.221 83.7072L54.0146 85.115L54.7665 88.432L58.9385 87.4885L62.3476 102.525L58.1756 103.469L58.9274 106.786L65.1338 105.378L66.3316 110.667L69.4864 109.952L68.2886 104.663L70.0171 104.272L71.2149 109.561L74.3697 108.846L73.1719 103.557L75.7665 102.968C79.581 102.102 81.0146 99.459 80.3955 96.7391C80.3623 96.5511 80.3255 96.3669 80.2849 96.1863C79.2898 91.7932 76.5736 90.8791 73.0871 91.1445V91.1482ZM67.7542 84.8349C68.1486 84.7465 68.5392 84.7317 68.9188 84.7833C70.2714 84.9639 71.436 85.9848 71.7603 87.4148C71.9667 88.3325 71.7935 89.2428 71.3402 89.9762C71.0859 90.389 70.7431 90.7465 70.3304 91.0155C70.0097 91.2256 69.6486 91.3804 69.2579 91.4688L67.2235 91.9295L65.495 92.3202L63.9913 85.6826L65.7198 85.2919L67.7542 84.8312V84.8349ZM74.425 98.873C73.9901 99.5806 73.2972 100.122 72.4459 100.34C72.4127 100.347 72.3795 100.358 72.3463 100.366L69.2947 101.059L67.5662 101.449L66.0626 94.8116L67.7911 94.4209L70.8427 93.728C70.8758 93.7207 70.9127 93.7133 70.9459 93.7059C72.7075 93.3632 74.4434 94.5094 74.8525 96.3079C75.0589 97.2256 74.8857 98.1359 74.4323 98.8693L74.425 98.873Z"
        fill="#F3BA2F"
      />
    </svg>
  );
};

export default BitcoinIncomeIcon;
