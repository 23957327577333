import { colors, metrics } from '~styles';
import { transparentize } from 'polished';
import { GeneralStyleProps } from 'src/@types/general';
import styled, { css } from 'styled-components';

export const Container = styled.div<GeneralStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;

  padding: 15px;
  transition: all ease-out 0.3s;
  margin-top: ${(props) => props.marginTop || 0}px;
  width: 100%;
  height: ${(props) => props.height || 'auto'};
  padding: 18px 15px 18px 15px;
  border: 1px solid #9ea1b9;

  .right-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition: all ease-out 0.3s;
    width: 100%;
  }
`;

export const IconContainer = styled.div<GeneralStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  position: relative;
  width: 45px;
  height: 45px;
`;

export const ContainerStatus = styled.div<GeneralStyleProps>`
  position: absolute;
  bottom: -12px;
  right: -8px;
`;

export const TrashAndEditContainer = styled.div<GeneralStyleProps>`
  flex-direction: row;
  display: flex;
  align-items: center;
  height: 40px;
  position: relative;

  .tooltip-text {
    width: 75%;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    display: flex;
    background-color: ${transparentize(0.15, colors.black)};
    border-radius: 10px;
    padding: 4px 8px;
    position: absolute;
    z-index: 999;
    opacity: 1;
    bottom: -15px;
  }

  .on-select {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 40px;
    height: 40px;
    transition: all ease-out 0.3s;
    border-radius: 10px;
    margin-right: 5px;

    :hover {
      .tooltip-text {
        visibility: visible;
      }
      margin-top: -3px;
      transition: all ease-out 0.3s;
      cursor: pointer;
      background-color: ${transparentize(0.7, colors.grayLight)};
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 40px;
      height: 40px;
    }
  }
`;

export const ContainerExpanded = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const Tooltip = styled.div`
  width: 100%;
  margin-top: 10px;
  background: rgba(195, 198, 219, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
`;
