import * as Style from './DropDownList.styles';

import { useEffect, useMemo, useState } from 'react';

import { DropDownListProps } from './DropDownList.types';
import { Search } from '~components/Inputs';
import { When } from 'react-if';
import ArrowUp from '~assets/svg/arrowUp';

const DropDownList = (props: DropDownListProps) => {
  const [search, setSearch] = useState('');
  const [filteredList, setFilteredList] = useState(props.list || []);
  const [open, setOpen] = useState(false);

  useMemo(() => {
    const debounceTimeout = setTimeout(() => {
      if (search.length) {
        const exactMatch = [] as DropDownListProps['list'];
        const similarMatches = [] as DropDownListProps['list'];
        const differentMatches = [] as DropDownListProps['list'];

        props.list.forEach((i) => {

          const label = i.label.normalize('NFD');
          const query = search.normalize('NFD');

          if (
            label.toLocaleLowerCase() === query.toLocaleLowerCase() ||
            String(i.value) === query ||
            String(i.bankCode) === query
          ) {
            exactMatch.push(i);
          } else if (
            label.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
            String(i.value).includes(query) ||
            String(i.bankCode).includes(query)
          ) {
            similarMatches.push(i);
          } else {
            differentMatches.push(i);
          }
        });

        setFilteredList([
          ...exactMatch,
          ...similarMatches,
          ...differentMatches,
        ]);
      } else {
        setFilteredList(props.list);
      }
    }, 500); // Ajuste o tempo de debounce conforme necessário

    return () => clearTimeout(debounceTimeout);
  }, [props.list, search]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    window.addEventListener('click', (e: any) => {
      const list = document.getElementById('container-list');

      const input = document.getElementById('input-bank-list');

      if (!list || !input) return;

      if (!list.contains(e.target) && !input.contains(e.target)) {
        setOpen(false);
      }
    });
  }, []);

  return (
    <Style.ContainerDropDown visible isOpen={open}>
      <Search
        placeHolder={props.inputLabel}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onClick={() => setOpen(true)}
        id="input-bank-list"
        icon={
          <div className="search-arrow">
            <ArrowUp />
          </div>
        }
        removeBorders={props.removeBorders}
        removePadding={props.removePadding}
      />

      <When condition={open}>
        <div className="container-list" id="container-list">
          {filteredList?.map((i) => {
            const uniqueKey = i.label + i.bankIspb;
            return (
              <Style.ItemList
                selected={i.value === props.selected}
                key={uniqueKey}
                onClick={() => {
                  setOpen(false);
                  setSearch(`${i.bankCode || i.value} - ${i.label}`);
                  props.setSelected(i.value);
                }}
                onChange={handleSearchChange}
              >
                <div className="tail">
                  {i.bankCode || i.value} - {i.label}
                </div>
              </Style.ItemList>
            );
          })}
        </div>
      </When>
    </Style.ContainerDropDown>
  );
};

export default DropDownList;
