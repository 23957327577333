import * as Text from '~styles/text';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import { UserPermissionModalProps } from '../UserPermission.types';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { InputForm } from '~components/Inputs';
import { cpfMask, formatPhone } from '~helpers/format/masks';
import * as Style from '../UserPermission.styles';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { validateCPF, validateEmail } from '~validations/personalValidation';
import { useGetOrganizationPartners } from '~reactQuery/queries/user/useGetOrganizationPartners';
import { getSelectedOrg } from '~helpers/device';

function NewUserInfomartion(props: UserPermissionModalProps) {
  const {
    setStepUserPermission,
    errors,
    setErrors,
    registerName,
    setRegisterName,
    documentNumber,
    setDocumentNumber,
    phone,
    setPhone,
    email,
    setEmail,
    message,
    setMessage,
    handleCancelAndResetFields,
  } = props;

  const { data: registeredUsers = [] } = useGetOrganizationPartners();

  const isAlreadyRegistered = Boolean(
    registeredUsers.some(
      (user) => cpfMask(user.documentNumber) === documentNumber
    )
  );

  const documentNumberErrors = () => {
    if (!documentNumber || !validateCPF(documentNumber)) {
      return 'CPF deve ser preenchido';
    }

    if (isAlreadyRegistered) {
      return 'Já existe um usuário cadastrado com este CPF';
    }

    return '';
  };

  const handleNext = () => {
    setErrors({
      registerName: '',
      documentNumber: '',
      phone: '',
      email: '',
    });

    if (
      !registerName ||
      !documentNumber ||
      !phone ||
      !validateCPF(documentNumber) ||
      onlyNumbers(phone).length !== 13 ||
      !validateEmail(email) ||
      !email
    ) {
      return setErrors({
        ...errors,
        registerName: !registerName ? 'Nome completo deve ser preenchido' : '',
        documentNumber: documentNumberErrors(),
        phone:
          !phone || onlyNumbers(phone).length !== 13
            ? 'Telefone deve ser preenchido'
            : '',
        email:
          !email || !validateEmail(email) ? 'E-mail deve ser preenchido' : '',
      });
    }

    if (!message) {
      setMessage('Olá! Gostaria que você fizesse parte da Organização!');
    }

    return setStepUserPermission('definePermissions');
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Cadastrar Novo Usuário"
        description="Preencha os dados solicitados abaixo, para prosseguir com o cadastro de um novo usuário."
        style={{ padding: '0px 32px' }}
      />
      <Style.ContentModal>
        <Style.Column>
          <Text.Heading5Bold>Nome completo</Text.Heading5Bold>
          <InputForm
            value={registerName}
            onChange={(event: any) => setRegisterName(event.target.value)}
            autoFocus
            placeholder="Luís Santos Nascimento"
            error={errors?.registerName}
            makeErrorInBottom
          />
        </Style.Column>

        <div className="row">
          <Style.Column style={{ marginRight: 8 }}>
            <Text.Heading5Bold>CPF</Text.Heading5Bold>
            <InputForm
              value={documentNumber}
              onChange={(event: any) => setDocumentNumber(event.target.value)}
              placeholder="000.000.000-00"
              maskFunction={cpfMask}
              error={errors?.documentNumber}
            />
          </Style.Column>

          <Style.Column>
            <Text.Heading5Bold>Número de telefone</Text.Heading5Bold>
            <InputForm
              value={phone}
              onChange={(event: any) => setPhone(event.target.value)}
              placeholder="(00) 00000-0000"
              maskFunction={formatPhone}
              error={errors?.phone}
              maxLength={15}
            />
          </Style.Column>
        </div>

        <Style.Column style={{ marginTop: 10 }}>
          <Text.Heading5Bold>E-mail</Text.Heading5Bold>
          <InputForm
            value={email}
            onChange={(event: any) => setEmail(event.target.value)}
            placeholder="luis@mail.com"
            error={errors?.email}
            makeErrorInBottom
          />
        </Style.Column>

        <Style.Column style={{ marginTop: 10 }}>
          <Text.Heading5Bold>
            Mensagem personalizada (opcional)
          </Text.Heading5Bold>
          <InputForm
            value={message}
            onChange={(event: any) => setMessage(event.target.value)}
            placeholder="Olá! Gostaria que você fizesse parte da Organização!"
            style={{ border: 0 }}
          />
        </Style.Column>
      </Style.ContentModal>

      <ModalMultiStepBottom
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonText="Próximo"
        singleButtonAction={() => handleNext()}
        doubleButtonTextOne="Cancelar"
        doubleButtonOneAction={() => handleCancelAndResetFields()}
      />
    </>
  );
}

export default NewUserInfomartion;
