import { SVGProps } from 'react';

const BlueBankIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={props.width || 24} height={props.height || 25}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5882 0.699321C12.2375 0.444302 11.7625 0.444302 11.4118 0.699321L0.411828 8.69932C0.153084 8.8875 0 9.18812 0 9.50806V13.5081C0 14.0603 0.447715 14.5081 1 14.5081H2V22.5081H0V24.5081H24V22.5081H22V14.5081H23C23.5523 14.5081 24 14.0603 24 13.5081V9.50806C24 9.18812 23.8469 8.8875 23.5882 8.69932L12.5882 0.699321ZM8 22.5081H4V14.5081H8V22.5081ZM14 14.5081V22.5081H10V14.5081H14ZM20 22.5081H16V14.5081H20V22.5081ZM2 12.5081V10.0173L12 2.74455L22 10.0173V12.5081H2ZM12 6.50806C10.8954 6.50806 10 7.40349 10 8.50806C10 9.61263 10.8954 10.5081 12 10.5081C13.1046 10.5081 14 9.61263 14 8.50806C14 7.40349 13.1046 6.50806 12 6.50806Z"
        fill={props.fill || '#026FF4'}
      />
    </svg>
  );
};

export default BlueBankIcon;
