import React, { SVGProps } from 'react';

const ArrowRightSimpleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width={props.width || '16'}
      height={props.height || '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.75783 13.7424L11.5002 8L5.75783 2.25759"
        stroke={props.stroke || '#C3C6DB'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ArrowRightSimpleIcon;
