import { request, useMutation } from '~reactQuery';

import { gql } from 'graphql-request';

interface PinResetInput {
  userOrganizationId: number;
  documentNumber: string;
}

interface PinResetResponse {
  pinReset: {
    data: {
      email: string;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function usePinReset() {
  const mutation = gql`
    mutation PinReset($params: PinResetInput!) {
      pinReset(params: $params) {
        data {
          email
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: PinResetInput) => {
    const response = await request<PinResetResponse>(mutation, {
      params,
    });
    return response.pinReset;
  };

  return useMutation('pinReset', fetch);
}
