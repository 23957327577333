import { Keyframes, css, keyframes } from 'styled-components';
import type { IGetTooltipAnimation } from './Tooltip.types';

export const fade = (from: number, to: number): Keyframes => keyframes`
  from {
  opacity: ${from};
  }

  to {
  opacity: ${to};
  }
`;

export const getTooltipAnimation = (
  isActive: boolean
): IGetTooltipAnimation => {
  return css`
    animation: ${() => {
      const keyFrames = isActive ? fade(0, 1) : fade(1, 0);

      return css`
        ${keyFrames} 100ms ease-in-out
      `;
    }};
  `;
};
