import * as Text from '~styles/text';
import { useEffect, useState } from 'react';
import * as TextSecondary from '~styles/textSecondary';
import { DepositOptionsPageProps } from '../Deposit.types';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import colors from '~styles/colors';
import InfoSVG from '~assets/svg/info';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { SuccessToast } from '~components/Toast';
import { maskBillet } from '~validations/stringsValidate';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetSlipByAuthCode } from '~reactQuery/queries/bills/useGetSlipByAuthCode';
import { pdf } from '@react-pdf/renderer';
import { BillDocument } from '~assets/pdfs/bill/BillDocument';
import bwipjs from 'bwip-js';
import Boleto from 'boleto.js';
import { useSendNotification } from '~reactQuery/mutations/user/useSendNotifications';
import DownloadSVG from '~assets/svg/download';

function BilletDepositGenerated(props: DepositOptionsPageProps) {
  const { setCurrentStep, stepParams } = props;

  const { authCode, billetValue, email, billetDigitableValue } = stepParams;
  const billetMaskedValue = billetDigitableValue &&
    maskBillet(props?.stepParams?.billetDigitableValue);

  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();
  const userOrganizationId = getSelectedUserOrganization();
  const [barcode64, setBarcode64] = useState('');
  const [billInBase64, setBillInBase64] = useState('');

  const { data: slipByAuthCode } = useGetSlipByAuthCode({
    authCode,
    userOrganizationId: Number(userOrganizationId),
  });

  const { mutateAsync: handleSendNotification } = useSendNotification();

  const goNext = () => setOpenModalMultiStep(false)

  const newDeposit = () => setCurrentStep('depositOptions', {});

  const handleCopyBilletKeyToClipboard = (key: string) => {
    navigator?.clipboard?.writeText(key);
    SuccessToast({
      title: 'Código de boleto copiado com sucesso!',
      autoCloseEnable: true,
    });
  };

  useEffect(() => {
    if (slipByAuthCode?.digitable) {
      try {
        const canvas = document.createElement('canvas');
        canvas.style.display = 'none';
        canvas.style.visibility = 'hidden';

        document.body.appendChild(canvas);

        const barcode = new Boleto(slipByAuthCode?.digitable).barcode();
        const canva = bwipjs.toCanvas(canvas, {
          bcid: 'interleaved2of5',
          text: barcode,
          includetext: false,
        });

        const base64 = canva.toDataURL();

        setBarcode64(base64);
      } catch (error) {
        //  console.error(error);
      }
    }
  }, [slipByAuthCode]);

  function blobToBase64(blob: Blob) {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(String(reader?.result));
      reader.readAsDataURL(blob);
    });
  }

  useEffect(() => {
    if (barcode64) {
      try {
        pdf(<BillDocument details={slipByAuthCode} barcode={barcode64} />)
          .toBlob()
          .then((v) => {
            blobToBase64(v).then((result: string) => {
              setBillInBase64(result);
            });
          });
      } catch (error) {
        //   console.error(error);
      }
    }
  }, [barcode64]);

  useEffect(() => {
    if (billInBase64) {
      const sendNotification = async () => {
        try {
          await handleSendNotification({
            email,
            notificationKey: 'slip_emission',
            notificationService: 'banking',
            notificationType: 'email',
            attachments: [
              {
                filedata: billInBase64?.split(',')[1],
                filename: 'boleto_deposito.pdf',
              },
            ],
          });
        } catch (error) {
          //   console.error(error);
        }
      };

      sendNotification();
    }
  }, [billInBase64]);

  return (
    <>
      <ModalMultiStepHeader
        title={`O boleto de depósito de ${billetValue} foi gerado`}
        description={`Enviamos uma cópia desse boleto para ${email}.`}
      >
        <TextSecondary.Heading2Medium
          marginTop={7}
          marginBottom={13}
          className="billet-deposit-title"
        >
          O boleto de depósito de {billetValue} foi gerado
        </TextSecondary.Heading2Medium>

        <Text.Heading5>
          Enviamos uma cópia desse boleto para seu email {email}.
        </Text.Heading5>
      </ModalMultiStepHeader>

      <div className="container-deposit-generated">
        <div className="top">
          <TextSecondary.Heading3Medium className="billet-value">
            {billetMaskedValue}
          </TextSecondary.Heading3Medium>

          <Text.Smaller
            className="copy-value"
            onClick={() => handleCopyBilletKeyToClipboard(billetDigitableValue)}
          >
            Copiar linha digitável
          </Text.Smaller>
        </div>

        <div className="container-warning-message">
          <InfoSVG />

          <Text.Small color={colors.grayMedium}>
            Após o pagamento, o dinheiro estará disponível na sua Conta BRL em
            até 3 dias úteis.
          </Text.Small>
        </div>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Concluir"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        doubleButtonTextOne={
          <>
            <DownloadSVG /> Realizar novo depósito
          </>
        }
        doubleButtonOneAction={newDeposit}
        singleButtonAction={goNext}
      />
    </>
  );
}

export default BilletDepositGenerated;
