import { gql, request, useMutation } from '~reactQuery';

import { getSettingsId } from '~helpers/device';

interface BillConfirmInput {
  userOrganizationId: number;
  id: string;
  amount: number;
  description: string;
  pin: string;
  token?: string;
}

export interface BillConfirmResponse {
  data: {
    id: number;
    user: number;
    status: string;
    authCode: string;
    idValidate: string;
    dataValidate: {
      id: string;
      payer: {
        name: string;
        documentNumber: string;
      };
      amount: number;
      charges: {
        discountAmount: number;
        fineAmountCalculated: number;
        interestAmountCalculated: number;
      };
      dueDate: string;
      assignor: string;
      digitable: string;
      maxAmount: string;
      minAmount: string;
      recipient: {
        name: string;
        documentNumber: string;
      };
      nextSettle: boolean;
      settleDate: string;
      businessHours: {
        end: string;
        start: string;
      };
      originalAmount: number;
      allowChangeAmount: boolean;
    };
    dataConfirm: {
      authenticationCode: string;
      settleDate: string;
    };
    transactionResult: string;
    createdAt: string;
    updatedAt: string;
  };
  messages: Array<string>;
  isValid: boolean;
}

export function useBillConfirm() {
  const organization = getSettingsId();

  const mutation = gql`
    mutation BillConfirm($params: BillConfirmInput!) {
      billConfirm(params: $params) {
        data {
          id
          user
          status
          authCode
          idValidate
          dataValidate {
            id
            payer {
              name
              documentNumber
            }
            amount
            charges {
              discountAmount
              fineAmountCalculated
              interestAmountCalculated
            }
            dueDate
            assignor
            digitable
            maxAmount
            minAmount
            recipient {
              name
              documentNumber
            }
            nextSettle
            settleDate
            businessHours {
              end
              start
            }
            originalAmount
            allowChangeAmount
          }
          dataConfirm {
            authenticationCode
            settleDate
          }
          transactionResult
          createdAt
          updatedAt
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: BillConfirmInput) => {
    const response = await request<{ billConfirm: BillConfirmResponse }>(
      mutation,
      {
        params: {
          ...params,
          settingsId: Number(organization),
        },
      }
    );
    return response.billConfirm;
  };

  return useMutation('useBillConfirm', fetch);
}
