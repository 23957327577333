/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo } from 'react';
import ConvertSVG from '~assets/svg/statement/convert';
import PixTedReceivedSVG from '~assets/svg/statement/pix-ted-received';
import PixTedSentSVG from '~assets/svg/statement/pix-ted-sent';
import { formatCurrencyFn } from '~helpers/format/currency';
import { GetStatementDataResponse } from '~reactQuery/queries/bank/useGetStatement';
import { WalletType } from '~reactQuery/types/wallets.types';
import colors from '~styles/colors';
import * as Styles from './FullReceiptRow.styles';
import * as Text from '~styles/text';
import { formatCpfOrCnpj } from '~helpers/format/numbers';
import { receiptTitleParser } from '~helpers/transaction/transactionUtils';

interface Props {
  tx: GetStatementDataResponse['statement'][0];
  wallets: WalletType[];
  setCsv: (value: any) => void;
}

const FullReceiptRow = ({ tx, wallets, setCsv }: Props) => {
  const getWalletFiltered = useCallback(
    (walletAddress: string) => {
      if (walletAddress && wallets) {
        return wallets.filter((w) => w.walletAddress === walletAddress)?.[0];
      }
      return '';
    },
    [wallets]
  );

  const txParser = useMemo(() => {
    const parsedTx = {
      date: tx.timestamp
        ? dayjs(tx.timestamp).format('DD/MM/YYYY h:mm A')
        : '-',
      type: '',
      icon: <></>,
      associatedAccount: '',
      destination: '',
      value: `${tx.direction < 1 ? '- ' : '+ '} ${formatCurrencyFn(
        'BRL',
        String(Number(tx.data.value) / 100)
      )}`,
      show: true,
      authCode: tx.authCode || '-',
      status: tx.status,
      description: tx.data.description || '-',
    };

    if (tx.method === 'Transfer') {
      // @ts-ignore
      const isCapContaToWallet =
        tx?.data?.requisite?.type === 'EXCHANGE_CAPCONTA_TO_CAPWALLET';
      // @ts-ignore
      const isCapWalletToCapConta =
        tx?.data?.requisite?.type === 'EXCHANGE_CAPWALLET_TO_CAPCONTA';

      if (isCapContaToWallet) {
        const title = receiptTitleParser(tx.status, 'CAP-TO-WALLET', 1);
        parsedTx.type = title;
        parsedTx.icon = <ConvertSVG fill={colors.grayMedium} />;
        parsedTx.associatedAccount = 'Cap Conta';
        // @ts-ignore
        parsedTx.destination =
          getWalletFiltered(tx.data?.requisite?.walletAddress)?.walletName ||
          '-';
      } else if (isCapWalletToCapConta) {
        const title = receiptTitleParser(tx.status, 'WALLET-TO-CAP', 1);
        parsedTx.type = title;
        parsedTx.icon = <ConvertSVG fill={colors.grayMedium} />;
        parsedTx.destination = 'Cap Conta';
        parsedTx.associatedAccount = '-';
      } else {
        // @ts-ignore

        const title = receiptTitleParser(
          tx.status,
          String(tx?.data?.method).toUpperCase() === 'PIX'
            ? 'TRANSFER-PIX'
            : 'TRANSFER-TED',
          tx.direction,
          true
        );

        parsedTx.type = title;
        parsedTx.icon =
          tx.direction < 1 ? <PixTedSentSVG /> : <PixTedReceivedSVG />;
        parsedTx.associatedAccount = 'Cap Conta';
        parsedTx.destination =
          tx.direction > 1
            ? 'Cap Conta'
            : `${
                tx.data?.requisite?.key?.holderName ||
                tx.data?.requisite?.holderName
              } ${
                tx.direction > 1
                  ? ''
                  : `(${formatCpfOrCnpj(
                      tx.data?.requisite?.key?.holderDocument ||
                        tx.data?.requisite?.holderDocument
                    )})`
              }`;

        // PIX scenario
        if (tx.data?.requisite?.key) {
          parsedTx.destination += ` - Agência ${tx.data?.requisite?.key?.branch} - Conta ${tx.data?.requisite?.key?.account} - ${tx.data?.requisite?.key?.bank?.bankName}`;
        } else if (tx.data?.requisite) {
          parsedTx.destination += ` - Agência ${tx.data?.requisite?.branch} - Conta ${tx.data?.requisite?.account} - ${tx.data?.requisite?.bank?.bankName}`;
        }
      }
    }

    if (tx.method === 'BillSlip') {
      const title = receiptTitleParser(tx.status, 'BILL-SLIP', 1);
      parsedTx.type = title;
      parsedTx.associatedAccount = 'Cap Conta';
      parsedTx.destination = '-';

      const isCashIn = tx.direction === 1;

      if (!isCashIn) {
        parsedTx.show = false;
      }
    }

    if (tx.method === 'Refund') {
      const title = receiptTitleParser(tx.status, 'REFUND', 1);
      parsedTx.type = title;
      parsedTx.associatedAccount = '-';

      parsedTx.destination = 'Cap Conta';
    }

    if (tx.method === 'Bill') {
      const title = receiptTitleParser(tx.status, 'BILL', -1);
      parsedTx.type = title;
      // @ts-ignore
      parsedTx.associatedAccount = '-';
      parsedTx.destination = tx?.data?.recipientName || tx?.data?.assignor;
    }

    return parsedTx;
  }, [tx, getWalletFiltered]);

  useEffect(() => {
    if (txParser.show) {
      setCsv((prev: any) => {
        return [
          ...prev,
          [
            txParser.date,
            txParser.authCode,
            txParser.type,
            txParser.associatedAccount,
            txParser.destination,
            txParser.description,
            txParser.value,
          ],
        ];
      });
    }
  }, []);

  return (
    <>
      {txParser.show && (
        <Styles.TableRow>
          <td data-id="date" style={{ whiteSpace: 'nowrap' }}>
            <Text.Small>{txParser.date}</Text.Small>
          </td>
          <td data-id="authcode">
            <Text.Small>{txParser.authCode}</Text.Small>
          </td>
          <td data-id="type">
            <Text.Small>{txParser.type}</Text.Small>
          </td>
          <td data-id="associated-account">
            <Text.Small>{txParser.associatedAccount}</Text.Small>
          </td>
          <td data-id="destination-origin">
            <Text.Small>{txParser.destination}</Text.Small>
          </td>
          <td data-id="description">
            <Text.Small>{txParser.description}</Text.Small>
          </td>
          <td data-id="value" style={{ whiteSpace: 'nowrap' }}>
            <Text.Small>{txParser.value}</Text.Small>
          </td>
          {/* <td data-id="value-in-r$">value-in-r</td> */}
          {/* <td data-id="partial-balance">partial</td> */}
        </Styles.TableRow>
      )}
    </>
  );
};

export default FullReceiptRow;
