import * as Style from './CheckBox.styles';

import { CheckBoxProps } from './CheckBox.types';
import CheckSVG from '~assets/svg/check';
import MinusSVG from '~assets/svg/minus';
import { When } from 'react-if';

const CheckBox = (props: CheckBoxProps) => {
  return (
    <Style.Container
      {...props}
      onClick={props.disabled ? () => {} : props.onClick}
    >
      <div className="check">
        <When condition={props.checked}>
          {props.indeterminate ? <MinusSVG /> : <CheckSVG />}
        </When>
      </div>
    </Style.Container>
  );
};

export default CheckBox;
