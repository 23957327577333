import * as React from 'react';

import { SVGProps } from 'react';

const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '24'}
    height={props.height || '25'}
    viewBox="0 0 24 25"
    fill="none"
    {...props}
  >
    <g id="Artwork">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5439 5.18552C13.3689 3.30552 10.6309 3.30551 9.45588 5.18551L3.06432 15.4119C1.81547 17.4101 3.252 20.0019 5.60831 20.0019H18.3913C20.7476 20.0019 22.1842 17.4101 20.9353 15.4119L14.5439 5.18552ZM10.9999 12.0019V13.0019L12.9999 13.0019V12.0019V9.00192L12.9999 8.00192L10.9999 8.00192V9.00192L10.9999 12.0019ZM12.9999 15.5019C12.9999 16.0542 12.5522 16.5019 11.9999 16.5019C11.4476 16.5019 10.9999 16.0542 10.9999 15.5019C10.9999 14.9496 11.4476 14.5019 11.9999 14.5019C12.5522 14.5019 12.9999 14.9496 12.9999 15.5019Z"
        fill={props.fill || '#C85B69'}
      />
    </g>
  </svg>
);

export default WarningIcon;
