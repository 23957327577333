import { useEffect, useState } from 'react';
import * as Style from './CreatePixKeys.styles';
import { SetCurrentStepProps, StepParamsProps } from './CreatePixKeys.types';
import ModalMultiStep from '~components/ModalMultiStep';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import PossiblesKeys from './ModalSteps/1.PossiblesKeys';
import ShowKeyExample from './ModalSteps/2.ShowKeyExample';
import ConfirmPin from './ModalSteps/3.ConfirmPin';
import CreatePixStatus from './ModalSteps/4.CreatePixStatus';

function CreatePixKeys() {
  const { openModalMultiStep, setOpenModalMultiStep } =
    useActionsOnModalMultiStep();
  const [currentStep, setCurrentStep] =
    useState<SetCurrentStepProps>('possiblesKeys');
  const [stepParams, setStepParams] = useState<StepParamsProps>({});

  function setStepsAndParams(step: SetCurrentStepProps, params?: object) {
    if (params) {
      setStepParams((prev) => ({ prev, ...params } as StepParamsProps));
    }
    setCurrentStep(step);
  }

  const modalStepsAndDetails = {
    possiblesKeys: {
      component: (
        <PossiblesKeys key="possiblesKeys" setCurrentStep={setStepsAndParams} />
      ),
      progressValue: 25,
    },
    showKeyExample: {
      component: (
        <ShowKeyExample
          keyType={stepParams?.keyType}
          key="showKeyExample"
          setCurrentStep={setStepsAndParams}
          value={stepParams.value}
        />
      ),
      progressValue: 50,
    },
    confirmPin: {
      component: (
        <ConfirmPin
          key="confirmPin"
          setCurrentStep={setStepsAndParams}
          keyType={stepParams?.keyType}
          value={stepParams.value}
        />
      ),
      progressValue: 75,
    },
    createPixStatus: {
      component: (
        <CreatePixStatus
          statusOfCreation={stepParams?.statusOfCreation}
          pixKey={stepParams?.pixKey}
          key="createPixStatus"
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 100,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  // CLEAR STEP ON MODAL CLOSE
  useEffect(() => {
    if (!openModalMultiStep) setCurrentStep('possiblesKeys');
  }, [openModalMultiStep]);

  return (
    <Style.CreatePixKeyContainer>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={currentStepInfos.progressValue}
      >
        {currentStepInfos.component}
      </ModalMultiStep>
    </Style.CreatePixKeyContainer>
  );
}

export default CreatePixKeys;
