import * as Text from '~styles/text';
import { useEffect, useState } from 'react';
import { DepositOptionsPageProps } from '../Deposit.types';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import InputCurrency from '~components/Inputs/InputCurrency';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { useEmitSlip } from '~reactQuery/mutations/deposit/useEmitSlip';
import dayjs from 'dayjs';

function BilletDepositValue(props: DepositOptionsPageProps) {
  const { setCurrentStep } = props;
  const [depositValue, setDepositValue] = useState('');
  const [inputError, setInputError] = useState('');

  const userOrganizationId = getSelectedUserOrganization();
  const { data: userData } = useGetPartner();

  const { mutateAsync: requestEmitSLip, isLoading } = useEmitSlip();

  const goNext = async () => {
    try {
      const response = await requestEmitSLip({
        userOrganizationId: Number(userOrganizationId),
        documentNumber: String(userData?.data?.documentNumber),
        amount: Number(onlyNumbers(depositValue)),
        dueDate: dayjs(new Date()).add(3, 'days').format('YYYY-MM-DD'),
        type: 'Deposit',
      });

      if (response?.emitSlip?.data) {
        setCurrentStep('billetDepositGenerated', {
          billetDigitableValue: response.emitSlip.data?.digitableValue,
          billetValue: depositValue,
          email: userData?.data?.email,
          authCode: response.emitSlip.data?.authenticationCode,
        });
      }
    } catch (error) {
      // console.log('EmitSlip error =>', error);
    }
  };

  const verifyValue = (value: string) => {
    if (Number(onlyNumbers(value)) < 2000) {
      return setInputError('Valor abaixo do exigido.');
    }

    if (
      Number(onlyNumbers(value)) >= 2000 &&
      Number(onlyNumbers(value)) <= 1500000
    ) {
      return setInputError('');
    }

    if (Number(onlyNumbers(value)) > 1500000) {
      return setInputError('Valor acima do esperado.');
    }

    return setInputError('');
  };

  const onChangeInputValue = (value: string) => {
    setDepositValue(value);
    verifyValue(value);
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Qual o valor do depósito?"
        backButtonAction={() => setCurrentStep('depositOptions')}
      />

      <div className="container-deposit-value">
        <InputCurrency
          value={depositValue || 'R$ 0,00'}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={(event) => onChangeInputValue(event.target.value)}
          error={inputError}
          className={
            Number(onlyNumbers(depositValue || '0')) > 0
              ? 'margin-vertical'
              : 'margin-vertical without-value'
          }
          maxLength={12}
        />

        <Text.Heading5 className="observation-about-value-deposit">
          Digite um valor entre R$ 20,00 e R$ 15.000,00
        </Text.Heading5>

        {/* <div className="container-warning-message">
          <WarningIcon />

          <Text.Small color={colors.warning}>
            Seu limite mensal para emissão de boletos está perto de acabar! Você
            possui apenas 3 boletos restantes.
          </Text.Small>
        </div> */}
      </div>

      <ModalMultiStepBottom
        singleButtonText="Gerar boleto de depósito"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={Boolean(inputError) || !depositValue}
        singleButtonLoading={isLoading}
      />
    </>
  );
}

export default BilletDepositValue;
