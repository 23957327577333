import {
  PixKeyRequisiteSchema,
  TransferMethodPixEnum,
} from '~reactQuery/types/pix.types';
import { gql, request, useMutation } from '~reactQuery/index';
import { CAPACCOUNT_QUERY_KEYS } from '~src/constants/capaccount';
import { PIX_QUERY_TRANSFER } from '~src/constants/pix';
import { getSettingsId } from '~helpers/device';
import { useQueryClient } from 'react-query';

interface PixTransferUsingRefundInput {
  authCode: string;
  description: string;
  method: string;
  recovery?:boolean;
  pin: string;
  refundReason?: string;
  requisite: {
    keyType: 'refund';
  };
  token?: string;
  userOrganizationId: number;
  value: number;
}

interface PixTransferUsingRefundResponse {
  pixTransferUsingRefund: {
    authCode: string;
    method: TransferMethodPixEnum;
    description: string;
    requisite: PixKeyRequisiteSchema;
    value: number;
  };
}

export const usePixTransferUsingRefund = () => {
  const queryClient = useQueryClient();
  const settingsId = getSettingsId();

  const QUERY = gql`
    mutation PixTransferUsingRefund($params: PixRefundTransferInput!) {
      pixTransferUsingRefund(params: $params) {
        authCode
        method
        description
        requisite {
          e2eId
          keyType
          addressingKeyValue
        }
        value
      }
    }
  `;

  const fetcher = async (params: PixTransferUsingRefundInput) => {
    const response = await request<PixTransferUsingRefundResponse>(QUERY, {
      params: {
        ...params,
        settingsId: Number(settingsId),
      },
    });
    return response.pixTransferUsingRefund;
  };

  return useMutation([PIX_QUERY_TRANSFER.PIX_TRANSFER_REFUND], fetcher, {
    onSettled: () => {
      queryClient.invalidateQueries([
        CAPACCOUNT_QUERY_KEYS.GET_CAPACCOUNT_INFO,
      ]);
    },
  });
};
