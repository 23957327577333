import * as Styles from '../Convert.styles';
import * as Text from '~styles/text';

import { formatCurrencyFn, getWalletIcon } from '~helpers/format/currency';

import CapAccountSVG from '~assets/svg/capAccount';
import { CheckSelect } from '~components/index';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { OriginWalletProps } from '../Convert.types';
import { WalletType } from '~reactQuery/types/wallets.types';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';
import { useState } from 'react';
import { isLockedTransaction } from '~helpers/transaction/transactionUtils';

function OriginWallet(props: OriginWalletProps) {
  const { setCurrentStep, destinationWallet, comingFrom } = props;
  const isComingFromCapWallet = comingFrom === 'capWallet';
  const PreviouslySelectedOriginWallet = props.originWallet;

  const [originWallet, setOriginWallet] = useState<WalletType>({});

  const goBack = () => {
    setCurrentStep('chooseWallets');
  };

  const goToNextStep = () => {
    setCurrentStep('chooseWallets', { originWallet });
  };

  const handleOnClickWallet = (w: WalletType) => {
    setOriginWallet(w);
  };

  const userOrganizationId = getSelectedUserOrganization();

  const { data: capAccountInfo } = useGetCapAccountInfo({
    userOrganizationId: Number(userOrganizationId),
  });

  const disableNextButton = !originWallet.walletAddress;

  const { data: wallets = [] } = useGetWallets({
    prefCurrency: 'BRL',
    flag: 'list',
    userOrganizationId: Number(userOrganizationId),
  });

  const capAccountWallet: WalletType = {
    id: 0,
    owner: 0,
    createdAt: '',
    updatedAt: '',
    walletAddress: 'CAPCONTA',
    walletCurrency: 'BRL',
    walletName: 'CapConta',
    funds: Number(capAccountInfo?.available) || 0,
    isOwnerInfoPublic: 0,
    priceInPrefCurrency: 0,
    isFavorite: 0,
    isPublic: 0,
    currency: {
      id: 0,
      type: 'fiat',
      name: 'CapConta',
      symbol: 'R$',
      isoCode: 'BRL',
      precision: 2,
    },
    ordering: 1,
    enabled: 1,
    enabledTransfer: true,
    enabledConvert: true,
    enabledPay: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  };

  const filteredWallets = [capAccountWallet, ...wallets].filter((w) => {
    if (destinationWallet) {
      return destinationWallet.walletAddress !== w.walletAddress;
    }

    return true;
  });

  return (
    <>
      <ModalMultiStepHeader
        title={
          !isComingFromCapWallet
            ? 'Origem da conversão'
            : 'Com qual carteira você quer comprar?'
        }
        backButtonAction={goBack}
      />
      <div className="convert-body">
        <Styles.OriginWalletList>
          {PreviouslySelectedOriginWallet && (
            <>
              <Text.Heading3 marginBottom={20}>Selecionado</Text.Heading3>
              <div className="origin-wallet-wrapper">
                <CheckSelect
                  key={PreviouslySelectedOriginWallet.walletAddress}
                  title={PreviouslySelectedOriginWallet.walletName}
                  text={String(PreviouslySelectedOriginWallet.currency.isoCode)}
                  iconType="component"
                  walletList
                  rightText={formatCurrencyFn(
                    PreviouslySelectedOriginWallet.currency.isoCode,
                    String(PreviouslySelectedOriginWallet.funds),
                    false,
                    false,
                    PreviouslySelectedOriginWallet.walletAddress === 'CAPCONTA'
                  )}
                  onClick={() => {}}
                  iconComponent={
                    PreviouslySelectedOriginWallet.walletAddress ===
                    'CAPCONTA' ? (
                      <CapAccountSVG />
                    ) : (
                      getWalletIcon(
                        PreviouslySelectedOriginWallet?.currency?.isoCode
                      )
                    )
                  }
                />
              </div>
            </>
          )}

          <Text.Heading3 marginBottom={20}>Carteiras Disponíveis</Text.Heading3>
          {filteredWallets
            .filter((wallet) => {
              if (Number(wallet.funds) === 0) {
                return false;
              }

              if (
                destinationWallet?.currency &&
                isLockedTransaction(
                  wallet.currency.isoCode,
                  destinationWallet?.currency.isoCode
                )
              ) {
                return false;
              }

              if (PreviouslySelectedOriginWallet) {
                return (
                  wallet.walletAddress !==
                  PreviouslySelectedOriginWallet.walletAddress
                );
              }

              return true;
            })
            .map((w) => {
              if (w.walletName === 'CapConta') {
                return (
                  <CheckSelect
                    key={w.walletName}
                    title={w.walletName}
                    text={w.currency.isoCode}
                    rightText={formatCurrencyFn(
                      'BRL',
                      capAccountInfo?.available,
                      false,
                      false,
                      true
                    )}
                    onClick={() => handleOnClickWallet(w)}
                    selected={w.walletAddress === originWallet.walletAddress}
                    iconType="component"
                    iconComponent={<CapAccountSVG />}
                    walletList
                  />
                );
              }

              return (
                <div className="origin-wallet-wrapper">
                  <CheckSelect
                    key={`${w.walletAddress}-${w.funds}`}
                    title={w.walletName}
                    text={String(w?.currency?.isoCode)}
                    iconType="component"
                    walletList
                    wallet={w}
                    selected={w.walletAddress === originWallet.walletAddress}
                    onClick={() => handleOnClickWallet(w)}
                    iconComponent={getWalletIcon(w.currency.isoCode)}
                  />
                </div>
              );
            })}
        </Styles.OriginWalletList>
      </div>
      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonAction={goToNextStep}
        disableSingleButton={disableNextButton}
      />
    </>
  );
}

export default OriginWallet;
