import { gql, request, useInfiniteQuery } from '~reactQuery';
import { Slips } from '~reactQuery/types/bill.types';

export interface GetAllSlipsResponse {
  getAllSlips: {
    data: {
      slips: Slips[];
      total: number;
      totalPages: number;
    };
    isValid: boolean;
    messages: string[];
  };
}

export interface GetAllSlipsInput {
  filter: {
    status?: string;
    from: string;
    to: string;
    userOrganizationId: number;
  };
  page: number;
  perPage: number;
}

export const useGetAllSlips = (params: GetAllSlipsInput) => {
  const QUERY = gql`
    query GetAllSlips($params: GetAllSlipsInput) {
      getAllSlips(params: $params) {
        data {
          slips
          total
          totalPages
        }
        isValid
        messages
      }
    }
  `;

  const fetcher = async ({ pageParam = 1 }) => {
    if (!params.filter.userOrganizationId) return null;

    const response = await request<GetAllSlipsResponse>(QUERY, {
      params: { ...params, page: pageParam },
    });

    return response.getAllSlips;
  };

  return useInfiniteQuery(
    [
      'useGetAllSlips',
      params.filter.from,
      params.filter.status,
      params.filter.to,
      params.filter.userOrganizationId,
    ],
    fetcher,
    {
      getNextPageParam: (_, pages) => {
        if (pages.length === pages[0]?.data.totalPages) {
          return undefined;
        }
        return pages.length + 1;
      },
    }
  );
};
