import { gql, request, useMutation } from '~reactQuery/index';

interface ParamsSolicitLimit {
  document: string;
  email: string;
  operation: 'PIX' | 'TED' | 'BOLETO' | 'EXCHANGE' | 'CRYPTO_WITHDRAW';
  userOrganizationId: number;
  time: 'HORARIO' | 'DIARIO' | 'SEMANAL' | 'MENSAL' | 'ANUAL' | 'NOTURNO';
  value: number;
  reason?: string;
}

interface SolicitLimitData {
  solicitLimit: {
    data: null;
    isValid: boolean;
    messages: string[];
  };
}

export const useSolicitLimit = () => {
  const mutation = gql`
    mutation SolicitLimit($params: solicitLimitInput!) {
      solicitLimit(params: $params)
    }
  `;

  const fetch = async (params: ParamsSolicitLimit) => {
    if (!params.userOrganizationId) return null;

    const response = await request<SolicitLimitData>(mutation, {
      params,
    });

    return response;
  };

  return useMutation('useSolicitLimit', fetch);
};
