import styled from 'styled-components';

import { getTooltipAnimation } from './animation';
import { getContainerPositionStyles } from './positions';
import {
  TooltipBaloonProps,
  TooltipPositionContainerProps,
} from './Tooltip.types';
import { getTooltipVariant } from './variant';

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-flex;
`;

export const TooltipAnchor = styled.span`
  display: inline-flex;
  color: inherit;
  background: none;
  border: 0;
  cursor: inherit;
`;

export const TooltipPositionContainer = styled.div<TooltipPositionContainerProps>`
  position: absolute;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
  pointer-events: none;

  @media (prefers-reduced-motion: no-preference) {
    transition-timing-function: ease-in-out;
    transition-duration: 100ms;
    transition-property: opacity;
    will-change: opacity, visibility;
    ${({ isActive }) => getTooltipAnimation(isActive)};
  }

  ${({ position }) => getContainerPositionStyles(position ?? 'top')};
`;

export const TooltipBalloon = styled.div<TooltipBaloonProps>`
  position: relative;
  width: max-content;
  max-width: 226px;
  padding: 8px;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  border-radius: 0.375rem;

  ${({ variant }) => getTooltipVariant(variant || 'default')};
`;
