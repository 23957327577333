import { SVGProps } from 'react';

const BlueGlobeSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '36'}
    height={props.height || '36'}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="18"
      cy="18"
      r="14"
      stroke={props.stroke || '#026FF4'}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <ellipse
      cx="18"
      cy="18"
      rx="5.6"
      ry="14"
      stroke={props.stroke || '#026FF4'}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M5.39999 12.4H30.6"
      stroke={props.stroke || '#026FF4'}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M5.39999 23.6H30.6"
      stroke={props.stroke || '#026FF4'}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default BlueGlobeSVG;
