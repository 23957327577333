import styled, { css } from 'styled-components';
import colors from '~styles/colors';
import colorsSecondary from '~styles/colorsSecondary';

export const Container = styled.div`
  height: 100%;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 25px 0px 25px;

    .title {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  .wrapper {
    height: calc(100% - 115px);
  }

  .details {
    height: 100%;
    padding: 0 25px 25px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .description {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .buttons-wrapper {
    padding: 0 25px;
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: ${colors.gray};
  padding: 4px 10px;
  border-radius: 30px;
  margin-bottom: 20px;

  .circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colors.grayMedium};

    &.open {
      background-color: ${colors.primary};
    }

    &.canceled {
      background-color: ${colorsSecondary.gray};
    }

    &.expired {
      background-color: ${colorsSecondary.dangerDark};
    }

    &.paied {
      background-color: ${colorsSecondary.successDark};
    }
  }
`;

export const BillDetails = styled.div<{ fadeIn: boolean }>`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  position: absolute;
  top: 0;
  padding: 0 25px 0 25px;
  display: flex;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;

  .barcode-component {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .goBackButton {
    cursor: pointer;
  }

  .detailsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .detailsDivider {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 30px;

    .icon {
      min-width: 24px;
      height: 24px;
    }
  }

  hr {
    width: 100%;
    height: 1px;
    background-color: #00000015;
    border: none;
  }

  .details-column {
    display: flex;
    flex-direction: column;
  }

  .details-group {
    display: grid;
    grid-template: repeat(1, 1fr) / repeat(2, 1fr);
    gap: 20px;
  }

  .btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .pdf-button {
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    background-color: ${colors.gray};
    padding: 7px 13px;
    border-radius: 44px;
    cursor: pointer;
    color: #000;
  }

  ${(props) =>
    props.fadeIn
      ? css`
          right: 0;
          transition: 0.5s ease;
        `
      : css`
          right: -100%;
          transition: 0.5s ease;
        `}
`;
