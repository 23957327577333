import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Text from '~styles/text';
import * as Style from '../../../TransferBatch.styles';
import ListContacts from './steps/1.ListContacts';
import { When } from 'react-if';
import PersonDataForm from './steps/2.TransferByBankDataForm';
import { InputForm, InputCSV } from '~components/Inputs';
import { EmptyTransfer } from '../emptyTransfer/EmptyTransfer.component';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ListOfBeneficiaries from './steps/4.ListOfBeneficiaries';
import { TabInBatchProps } from '~src/common/modals/multiStep/TransferBatch/TransferBatch.types';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import TransferByPixForm from './steps/3.TransferByPixForm';
import { useResolvePixKey } from '~reactQuery/mutations/pix/useResolvePixKey';
import {
  validaCNPJ,
  validateCPF,
  validateEmaiDomainLength,
  validateEmail,
  validatePhoneNumber,
  validatePixRandomKey,
} from '~validations/personalValidation';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { getSelectedUserOrganization } from '~helpers/device';
import { useCreatePaymentList } from '~reactQuery/mutations/paymentList/useCreatePaymentList';
import { ErrorToast, SuccessToast } from '~components/Toast';
import ExcelSvg from '~assets/svg/excel';
import SpreeadsheetSvg from '~assets/svg/spreadsheet';
import colors from '~styles/colors';
import { GenericButton } from '~components/index';
import DownloadSVG from '~assets/svg/download';
import ArrowTransferIcon from '~assets/svg/arrowTransfer';
import AlertModal from '~components/AlertModal/AlertModal.component';
import { accountsTypes } from '~data/bankUtils';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { transferBatchPixDataSchema } from '~validations/transferBatchPixData';
import { transferBatchBankDataSchema } from '~validations/transferBatchBankData';
import {
  ErrorOnAttachListTypes,
  PixInfo,
  SelectedBankDataContact,
  StepSearchPerson,
} from './SearchRecipient.types';
import PhoneSVG from '~assets/svg/phone';
import PersonIcon from '~assets/svg/person';
import FileSVG from '~assets/svg/file';
import { useGetBrlBanks } from '~reactQuery/queries/bank/useGetBrlBank';
import WarningIcon from '~assets/svg/warning';
import { use } from 'i18next';
import { formatMonetaryAttachFile } from '~helpers/format/file';
import { validatePixNumber } from '~helpers/validate/genericValidations';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';

export const SearchRecipient = ({
  setStepTransferBatch,
  modalAttachListIsVisible,
  setModalAttachListIsVisible,
  listOfBeneficiaries,
  setListOfBeneficiaries,
}: TabInBatchProps) => {
  const [searchPerson, setSearchPerson] = useState('');
  const [stepPersonWillReceive, setStepPersonWillReceive] =
    useState<StepSearchPerson>('listContacts');
  const [selectedContact, setSelectedContact] = useState<any>();
  const { setParams } = useActionsOnModalMultiStep();
  const userOrganization = getSelectedUserOrganization();
  const [errorOnSearchIdentify, setErrorOnSearchIdentify] = useState('');
  const [debounceLoading, setDebounceLoading] = useState(false);
  const [pixInfo, setPixInfo] = useState<PixInfo | null>(null);
  const [newList, setNewList] = useState(false);
  const [nameNewList, setNameNewList] = useState('');
  const { mutateAsync: resolvePixKey, isLoading: loadingResolvePixKey } =
    useResolvePixKey();
  const [arrayByFile, setArrayByFile] = useState<any>(null);
  const [modalVerifyKeyPix, setModalVerifyPixKey] = useState(false);
  const [typeKeyPix, setTypeKeyPix] = useState('');
  const [errorOnAttachFile, setErrorOnAttachFile] =
    useState<ErrorOnAttachListTypes>({
      visible: false,
      errors: null,
      pixNotFound: false,
    });
  const [modalErrorOnAttachFileVisible, setModalErrorOnAttachFileVisible] =
    useState(false);
  const [progressUpload, setProgressUpload] = useState(0);

  const {
    mutateAsync: createPaymentList,
    isLoading: loadingCreatePaymentList,
  } = useCreatePaymentList();

  const { data: brlBankList, isLoading: isLoadingBankList } = useGetBrlBanks();

  const transferByPixFormMethods = useForm({
    defaultValues: {},
    mode: 'onChange',
    resolver: yupResolver(transferBatchPixDataSchema),
  });

  const transferByBankDataFormMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(transferBatchBankDataSchema),
  });

  const {
    reset: resetTransferByPixForm,
    handleSubmit: handleSubmitTransferByPixForm,
    watch,
  } = transferByPixFormMethods;

  const {
    reset: resetTransferByBankDataForm,
    handleSubmit: handleSubmitTransferByBankDataForm,
    watch: watchTransferByBank,
  } = transferByBankDataFormMethods;

  const searchIdentify = useCallback(async (value: string, type?: string) => {
    setErrorOnSearchIdentify('');
    setPixInfo(null);

    const isValidToContinue =
      validaCNPJ(value) ||
      validatePixRandomKey(value) ||
      validateEmaiDomainLength(value) ||
      isValidPhoneNumber(value, 'BR') ||
      validateCPF(value);

    const validatePhoneOrCpf = validatePixNumber(value);

    const isCpfOrPhoneNumber =
      validatePhoneOrCpf[0] === 'cpf' && validatePhoneOrCpf[1] === 'phone';

    if (isCpfOrPhoneNumber && !type) {
      setModalVerifyPixKey(true);
      return;
    }

    if (!value || !isValidToContinue) {
      return;
    }

    const newValue = type === 'PHONE' ? `55${value.trim()}` : value.trim();
    try {
      setErrorOnSearchIdentify('');
      const resolve = await resolvePixKey({
        key: validaCNPJ(value.trim()) ? onlyNumbers(value.trim()) : newValue,
        userOrganizationId: Number(userOrganization),
        documentNumber: String(value),
      });

      const objectPix = {
        ...resolve,
        ...resolve?.key,
        name: resolve?.key?.holderName,
        keyValue: newValue,
      };

      const { key, ...newObject } = objectPix;

      setPixInfo(newObject as any);
    } catch (error) {
      setErrorOnSearchIdentify(
        'Verifique se as informações inseridas estão corretas e tente novamente.'
      );
    }
  }, []);

  const parseBankList: Array<{
    value: string;
    label: string;
    bankCode?: string;
  }> = useMemo(() => {
    if (!brlBankList && isLoadingBankList)
      return [{ value: '', label: 'Carregando...' }];

    const mapBankList = (brlBankList || [])
      .map((bank) => ({
        value: bank.bankIspb,
        label: `${bank.bankName}`,
        bankCode: bank.bankCompe,
      }))
      .sort((a, b) => ((a.bankCode || '') < (b.bankCode || '') ? -1 : 1));

    return mapBankList;
  }, [brlBankList, isLoadingBankList]);

  const onSubmit = async (data: any) => {
    if (listOfBeneficiaries?.length > 19) {
      ErrorToast({
        title: 'Limite de 20 contatos excedida!',
        autoCloseEnable: true,
      });
      return;
    }

    setListOfBeneficiaries((prevList) => {
      const existingObjectIndex = prevList.findIndex(
        (item, index) => index === selectedContact?.indexInArray
      );

      if (existingObjectIndex !== -1) {
        const updatedList = [...prevList];
        updatedList[existingObjectIndex] = {
          ...updatedList[existingObjectIndex],
          ...data,
        };
        return updatedList;
      }

      return [...prevList, data];
    });
    setStepPersonWillReceive('listOfBeneficiaries');
    resetTransferByPixForm();
    resetTransferByBankDataForm();
  };

  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  let debounceTimer: ReturnType<typeof setTimeout>;
  let inactiveTimeout: ReturnType<typeof setTimeout>;

  const startInactiveTimeout = () => {
    if (inactiveTimeout) {
      clearTimeout(inactiveTimeout);
    }

    inactiveTimeout = setTimeout(() => {
      setDebounceLoading(false);
    }, 4000);
  };

  const onChangeInputSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPerson(event.target.value);
    setStepPersonWillReceive('listContacts');

    if (debounceTimerRef.current !== null) {
      clearTimeout(debounceTimerRef.current);
    }

    setDebounceLoading(true);
    startInactiveTimeout();

    debounceTimerRef.current = setTimeout(() => {
      searchIdentify(event.target.value);
    }, 1600);
  };

  useEffect(() => {
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
  }, []);

  const handleNext = (
    contact: SelectedBankDataContact,
    pixInfoParam: PixInfo,
    indexInArray: number
  ) => {
    if (pixInfoParam) {
      setSelectedContact({
        ...contact,
        ...pixInfoParam,
        indexInArray,
      });
      setStepPersonWillReceive('transferByPixForm');
      setSearchPerson('');
    } else {
      setSelectedContact({ ...contact, indexInArray });
      setStepPersonWillReceive('transferByBankDataForm');
      setSearchPerson('');
    }
  };

  const handleCreatePaymentList = async () => {
    try {
      const onlyBeneficiariesWithoutError = listOfBeneficiaries?.filter(
        (item) => !item?.error
      );

      const response = await createPaymentList({
        perUser: true,
        userOrganizationId: Number(userOrganization),
        data: {
          nameList: nameNewList,
          listOfBeneficiaries: onlyBeneficiariesWithoutError,
        },
      });
      if (response) {
        SuccessToast({
          title: 'Lista criada com sucesso!',
          autoCloseEnable: true,
        });
      }
    } catch (error) {
      ErrorToast({
        title: 'Não foi possível criar a lista!',
        autoCloseEnable: true,
      });
    }
  };

  const handleDefineTitleButton = () => {
    if (
      stepPersonWillReceive === 'transferByBankDataForm' ||
      stepPersonWillReceive === 'transferByPixForm'
    ) {
      return 'Confirmar e voltar';
    }
    return 'Resumo da transação';
  };

  const handleDefineFunction = async () => {
    if (stepPersonWillReceive === 'transferByBankDataForm') {
      await onSubmit(transferByBankDataFormMethods.getValues());
      return;
    }

    if (stepPersonWillReceive === 'transferByPixForm') {
      await onSubmit(transferByPixFormMethods.getValues());
      return;
    }

    if (newList && nameNewList) {
      handleCreatePaymentList();
    }

    const onlyBeneficiariesWithoutError = listOfBeneficiaries?.filter(
      (item) => !item.error
    );

    setParams({
      origin: 'inBatch',
      recipientsList: {
        listOfBeneficiaries: onlyBeneficiariesWithoutError,
        nameList: nameNewList,
      },
    });

    setStepTransferBatch('confirmPaymentList');
  };

  const verifyIsDisabled = useMemo(() => {
    const amount = watch('amount');
    const amountBank = watchTransferByBank('amount');
    const bank = watchTransferByBank('bank');
    const bankIspb = watchTransferByBank('bankIspb');
    const accountType = watchTransferByBank('accountType');
    const agency = watchTransferByBank('agency');
    const bankAccount = watchTransferByBank('bankAccount');
    const documentNumber = watchTransferByBank('documentNumber');
    const fullName = watchTransferByBank('fullName');

    if (stepPersonWillReceive === 'transferByPixForm') {
      if (amount === 'R$ 0,00' || !amount) {
        return true;
      }
      return false;
    }

    if (stepPersonWillReceive === 'transferByBankDataForm') {
      const dontHaveBankCodeAndBankIsp = !bank && !bankIspb;
      if (
        amountBank === 'R$ 0,00' ||
        !amountBank ||
        !accountType ||
        !agency ||
        !bankAccount ||
        !documentNumber ||
        !fullName ||
        dontHaveBankCodeAndBankIsp
      ) {
        return true;
      }

      return false;
    }

    return listOfBeneficiaries?.length === 0;
  }, [
    watch('amount'),
    watch('saveForNextTransfer'),
    stepPersonWillReceive,
    watchTransferByBank(),
  ]);

  const propsListOfBeneficiaries = {
    handleNext,
    listOfBeneficiaries,
    newList,
    setNewList,
    nameNewList,
    setNameNewList,
  };

  const propsListContacts = {
    handleNext,
    searchPerson,
    debounceLoading,
    pixInfo,
    errorOnSearchIdentify,
    loadingResolvePixKey,
  };

  const transferByBankDataProps = {
    selectedContact,
    listOfBeneficiaries,
    setListOfBeneficiaries,
    stepPersonWillReceive,
    setStepPersonWillReceive,
  };

  const transferByPixProps = {
    selectedContact,
    listOfBeneficiaries,
    setListOfBeneficiaries,
    stepPersonWillReceive,
    setStepPersonWillReceive,
  };

  const parseCSVLine = (line: string) => {
    const values = [];
    let insideQuotes = false;
    let currentValue = '';
    let currentIndex = 0;

    for (let i = 0; i < line.length; i += 1) {
      if (line[i] === '"' && !insideQuotes) {
        insideQuotes = true;
        currentValue = '';
      } else if (line[i] === '"' && insideQuotes) {
        insideQuotes = false;
      } else if (
        (line[i] === ';' && !insideQuotes) ||
        (line[i] === ',' && !insideQuotes)
      ) {
        values[currentIndex] = currentValue.trim();
        currentIndex += 1;
        currentValue = '';
      } else {
        currentValue += line[i];
      }
    }

    values[currentIndex] = currentValue.trim();

    return values;
  };

  const handleResolvePixKey = async (keyValue: string) => {
    try {
      const resolve = await resolvePixKey({
        key: validaCNPJ(keyValue.trim())
          ? onlyNumbers(keyValue.trim())
          : keyValue.trim(),
        userOrganizationId: Number(userOrganization),
        documentNumber: String(keyValue),
      });

      const objectPix = {
        ...resolve,
        keyValue,
      };

      return objectPix;
    } catch (error) {
      return { error: true };
    }
  };

  const processCSVData = async (csvText: string) => {
    setErrorOnAttachFile({ visible: false, errors: null, pixNotFound: false });
    const lines = csvText.split('\n');
    const linesSliced = lines?.slice(3, lines?.length);
    const validLines: any[] = [];

    const headers = [
      'keyValue',
      'amount',
      'fullName',
      'documentNumber',
      'agency',
      'bankAccount',
      'accountType',
      'bank',
    ];

    linesSliced?.forEach((line: string) => {
      const trimmedLine = formatMonetaryAttachFile(line.trim());

      if (trimmedLine) {
        const values = parseCSVLine(trimmedLine);

        if (values.length === headers.length) {
          const allStringsEmpty = values?.every((str: string) => str === '');

          const obj: any = {};

          if (allStringsEmpty) {
            return;
          }

          for (let i = 0; i < headers.length; i += 1) {
            obj[headers[i]] = values[i] || '';
          }

          const arrayEntries = Object.entries(obj);

          arrayEntries.forEach((key: any) => {
            if (obj[key] === '') {
              delete obj[key];
            }
          });

          validLines.push(obj);
        }
      }
    });

    const validateKeyType = (value: string) => {
      const validationFunctions = {
        CNPJ: validaCNPJ,
        CPF: validateCPF,
        EVP: validatePixRandomKey,
        EMAIL: validateEmail,
        PHONE: validatePhoneNumber,
      };

      const validationsArray = Object.entries(validationFunctions);

      const findKeyType = validationsArray.find(([key, validationFunction]) => {
        if (validationFunction(value)) {
          return key;
        }
        return null;
      });

      return findKeyType ? findKeyType[0] : null;
    };

    const arrayBeneficiaries = await Promise.all(
      validLines.map(async (item) => {
        const accountType = accountsTypes.find(
          (accType) =>
            accType?.label?.toLocaleLowerCase() ===
            item?.accountType?.toLocaleLowerCase()
        );

        if (
          item?.documentNumber &&
          item?.agency &&
          item?.bankAccount &&
          item?.accountType &&
          item?.bank
        ) {
          return {
            ...item,
            transferType: 'Pix Manual',
            accountType: accountType?.value,
            saveForNextTransfer: false,
          };
        }

        const transformOnlyNumbers =
          validateKeyType(item?.keyValue) === 'CPF' ||
          validateKeyType(item?.keyValue) === 'PHONE';

        const objectPix = await handleResolvePixKey(
          transformOnlyNumbers ? onlyNumbers(item?.keyValue) : item?.keyValue
        );

        const hasName = objectPix?.key?.holderName
          ? { fullName: objectPix?.key?.holderName }
          : {};

        return {
          ...item,
          keyType: validateKeyType(item?.keyValue),
          accountType: accountType?.value || 'customer',
          ...objectPix,
          ...hasName,
        };
      })
    );

    arrayBeneficiaries?.forEach((item) => {
      const findEmptyKey = Object?.entries(item)?.find(([key, value]) => {
        if (value === '') {
          return key;
        }
        return null;
      });

      if (findEmptyKey) {
        delete item[findEmptyKey[0]];
      }
    });

    const hasErrorOnSearchPixInfo = arrayBeneficiaries?.filter(
      (item) => item?.error
    );

    if (hasErrorOnSearchPixInfo?.length > 0) {
      setErrorOnAttachFile({ visible: true, errors: null, pixNotFound: true });
    }

    setArrayByFile(arrayBeneficiaries);
  };

  const readFileContents = (file: File) => {
    const reader = new FileReader();

    reader.onload = (event: any) => {
      const contents = event.target.result;
      processCSVData(contents);
    };

    reader.readAsText(file);
  };

  function verifyBankInArray(
    bank: string,
    array: Array<{
      value: string;
      label: string;
      bankCode?: string;
    }>
  ): boolean {
    return (
      array?.find((item) => {
        return item?.bankCode === bank || item?.value === bank;
      }) !== undefined
    );
  }

  const handleSeeAttachlist = () => {
    setErrorOnAttachFile({ visible: false, errors: null, pixNotFound: false });

    const errors: any = [];
    // eslint-disable-next-line array-callback-return
    arrayByFile?.map((item: any, index: number) => {
      const validatePix = !item?.amount || !item?.amount?.includes('R$');
      const validatePixManualOrTed =
        !item?.amount ||
        !item?.amount?.includes('R$') ||
        !item?.fullName ||
        !item?.documentNumber ||
        !item?.agency ||
        !item?.bankAccount ||
        !item?.accountType ||
        !item?.bank;
      if (
        (!item?.keyValue && !verifyBankInArray(item?.bank, parseBankList)) ||
        (!item.keyValue && validatePixManualOrTed)
      ) {
        errors.push({ ...item, indexInArray: index + 4 });
      }

      if (item.keyValue && validatePix) {
        errors.push({ ...item, indexInArray: index + 4 });
      }
    });

    if (arrayByFile?.length === 0) {
      setErrorOnAttachFile({ visible: true, errors: null, pixNotFound: false });
      ErrorToast({
        title: 'Arquivo anexado não possui nenhum contato!',
        autoCloseEnable: true,
      });
      return;
    }

    if (arrayByFile?.length > 20) {
      setErrorOnAttachFile({ visible: true, errors: null, pixNotFound: false });
      ErrorToast({
        title: 'Limite de 20 contatos excedida!',
        autoCloseEnable: true,
      });
      return;
    }

    if (errors?.length > 0) {
      setErrorOnAttachFile({
        visible: true,
        errors,
        pixNotFound: false,
      });
      ErrorToast({
        title: 'Verifique os campos preenchidos na planilha!',
        autoCloseEnable: true,
      });
      return;
    }

    setListOfBeneficiaries(arrayByFile);
    setModalAttachListIsVisible(false);
  };

  const isLoadingButton =
    loadingCreatePaymentList || loadingResolvePixKey || isLoadingBankList;

  const handleRedirectToGoogleSheets = () => {
    window.open(
      'https://docs.google.com/spreadsheets/d/1RZM9TiQXh9dAXOb9kUdN-3BBeX0gTJwTUbhWugc1SPk/edit?usp=sharing',
      '_blank'
    );
  };

  const handleDownloadTemplate = () => {
    window.open(
      'https://storage.googleapis.com/static.capitual.net/business/files/modelo-planilha-pagamento-em-lote.xlsx',
      '_blank'
    );
  };

  return (
    <>
      <form
        onSubmit={
          stepPersonWillReceive === 'transferByBankDataForm'
            ? handleSubmitTransferByBankDataForm(onSubmit)
            : handleSubmitTransferByPixForm(onSubmit)
        }
        className="container-form-payment-batch"
      >
        <When
          condition={
            stepPersonWillReceive !== 'transferByBankDataForm' &&
            stepPersonWillReceive !== 'transferByPixForm'
          }
        >
          <Text.Heading5 marginTop={32}>
            Encontre um contato ou inicie uma nova transferência
          </Text.Heading5>

          <InputForm
            value={searchPerson}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onChangeInputSearch(event)
            }
            autoFocus
            placeholder="Nome, chave Pix ou CPF/CNPJ"
          />
        </When>

        <When
          condition={
            stepPersonWillReceive === 'listContacts' && searchPerson?.length > 0
          }
        >
          <ListContacts {...propsListContacts} />
        </When>

        <When condition={stepPersonWillReceive === 'transferByPixForm'}>
          <FormProvider {...transferByPixFormMethods}>
            <TransferByPixForm {...transferByPixProps} />
          </FormProvider>
        </When>

        <When condition={stepPersonWillReceive === 'transferByBankDataForm'}>
          <FormProvider {...transferByBankDataFormMethods}>
            <PersonDataForm {...transferByBankDataProps} />
          </FormProvider>
        </When>

        <When
          condition={
            !searchPerson &&
            stepPersonWillReceive !== 'transferByBankDataForm' &&
            stepPersonWillReceive !== 'transferByPixForm' &&
            listOfBeneficiaries?.length > 0
          }
        >
          <ListOfBeneficiaries {...propsListOfBeneficiaries} />
        </When>

        <When
          condition={
            !searchPerson &&
            stepPersonWillReceive === 'listContacts' &&
            listOfBeneficiaries?.length === 0
          }
        >
          <EmptyTransfer
            title="Você não adicionou nenhum beneficiário"
            subtitle="Ao cadastrar novos beneficiários, eles serão exibidos aqui."
            style={{ marginTop: 35 }}
          />
        </When>

        <ModalMultiStepBottom
          singleButtonText={handleDefineTitleButton()}
          singleButtonWidth="236px"
          singleButtonAlignment="flex-end"
          singleButtonAction={handleDefineFunction}
          disableSingleButton={verifyIsDisabled}
          className="multi-step-bottom"
          singleButtonLoading={isLoadingButton}
          doubleButtonTextOne={
            <div className="button-row-transfer-batch">
              <FileSVG />
              <Text.Heading5>Anexar lista</Text.Heading5>
            </div>
          }
          doubleButtonOneAction={() => {
            setErrorOnAttachFile({
              visible: false,
              errors: null,
              pixNotFound: false,
            });
            setModalAttachListIsVisible(true);
          }}
          disableDoubleButtonOneAction={
            isLoadingButton || listOfBeneficiaries?.length > 0
          }
        />
      </form>

      <When condition={modalAttachListIsVisible}>
        <AlertModal
          setOpenAlert={setModalAttachListIsVisible}
          title="Anexar Lista para Transferência em Lote"
          primaryButtonText="Fechar"
          primaryButtonAction={() => {
            setModalAttachListIsVisible(false);
          }}
          secondaryButtonText="Visualizar Lista"
          secondaryButtonAction={() => handleSeeAttachlist()}
          secondaryButtonColor="secondary"
          secondaryButtonWidth="168px"
          bodyComponent={
            <>
              <InputCSV
                onUploadFile={(event) => readFileContents(event)}
                setProgressUpload={setProgressUpload}
              />

              <When condition={errorOnAttachFile?.visible}>
                <Style.ErrorOnAttachList>
                  <div className="icon">
                    <WarningIcon
                      fill={
                        errorOnAttachFile?.pixNotFound
                          ? colors.warning
                          : colors.redMatte
                      }
                    />
                  </div>
                  <Text.Heading5
                    marginLeft={8}
                    color={
                      errorOnAttachFile?.pixNotFound
                        ? colors.warning
                        : colors.redMatte
                    }
                    display="inline"
                  >
                    {errorOnAttachFile?.pixNotFound
                      ? 'Pode haver uma ou mais chaves Pix não reconhecidas em sua lista.'
                      : 'Identificamos erros na planilha inserida, por favor siga exatamente o padrão do modelo disponibilizado.'}{' '}
                    <When
                      condition={
                        errorOnAttachFile?.errors &&
                        errorOnAttachFile?.errors?.length > 0
                      }
                    >
                      <span
                        className="link"
                        onClick={() => setModalErrorOnAttachFileVisible(true)}
                      >
                        Veja os problemas encontrados.
                      </span>
                    </When>
                  </Text.Heading5>
                </Style.ErrorOnAttachList>
              </When>

              <Style.ContainerSpreadsheetTemplate>
                <div className="title-spreadsheet">
                  <ExcelSvg />
                  <SpreeadsheetSvg />
                  <Text.Heading5Bold marginLeft={4}>
                    Planilha Modelo
                  </Text.Heading5Bold>
                </div>
                <Text.Heading5Bold marginTop={16} color={colors.grayMedium}>
                  Baixe nosso modelo de exemplo do Excel ou edite online com a
                  nossa planilha do Google Sheets.
                </Text.Heading5Bold>
                <div className="row-buttons">
                  <GenericButton
                    buttonType="secondary"
                    width="101px"
                    onClick={handleDownloadTemplate}
                    leftIcon={<DownloadSVG stroke={colors.white} />}
                    hasLeftIcon
                  >
                    Excel
                  </GenericButton>
                  <GenericButton
                    buttonType="secondary"
                    width="101px"
                    onClick={handleRedirectToGoogleSheets}
                    leftIcon={
                      <ArrowTransferIcon
                        fill={colors.white}
                        width={24}
                        height={24}
                      />
                    }
                    hasLeftIcon
                  >
                    Sheets
                  </GenericButton>
                </div>
              </Style.ContainerSpreadsheetTemplate>
            </>
          }
          noBorderTop
          paddingVertical={20}
          titleFontSite={1.35}
          disableSecondaryButton={!arrayByFile || progressUpload !== 100}
        />
      </When>

      <When condition={modalVerifyKeyPix}>
        <AlertModal
          setOpenAlert={() => {
            setModalVerifyPixKey(false);
            setTypeKeyPix('');
          }}
          title={`A chave que você está inserindo é um CPF ${'\n'} ou um celular?`}
          primaryButtonText="Voltar"
          primaryButtonAction={() => {
            setModalVerifyPixKey(false);
            setTypeKeyPix('');
          }}
          secondaryButtonText="Confirmar"
          secondaryButtonAction={() => {
            setModalVerifyPixKey(false);
            setTypeKeyPix('');
          }}
          secondaryButtonColor="secondary"
          secondaryButtonWidth="137px"
          bodyComponent={
            <div className="content-add-beneficiary">
              <div
                className="row-type-key"
                onClick={async () => {
                  setTypeKeyPix('CPF');
                  await searchIdentify(searchPerson, 'CPF');
                }}
              >
                <div className="left">
                  <PersonIcon fill={colors.black} width={24} height={24} />
                  <Text.Heading5Bold>CPF</Text.Heading5Bold>
                </div>
                <div className="right">
                  <Style.CircleCheckbox
                    background="CPF"
                    type={typeKeyPix || ''}
                  />
                </div>
              </div>

              <div
                className="row-type-key"
                onClick={async () => {
                  setTypeKeyPix('PHONE');
                  await searchIdentify(searchPerson, 'PHONE');
                }}
              >
                <div className="left">
                  <PhoneSVG fill={colors.black} width={24} height={24} />
                  <Text.Heading5Bold>Celular</Text.Heading5Bold>
                </div>
                <div className="right">
                  <Style.CircleCheckbox
                    background="PHONE"
                    type={typeKeyPix || ''}
                  />
                </div>
              </div>
            </div>
          }
          noBorderTop
          paddingBottomTitle="0"
          paddingVertical={20}
          marginRight={50}
          disableSecondaryButton={!typeKeyPix}
        />
      </When>

      <When condition={modalErrorOnAttachFileVisible}>
        <AlertModal
          setOpenAlert={setModalErrorOnAttachFileVisible}
          title="Lista de erros"
          secondaryButtonWidth="153px"
          noBorderTop
          paddingVertical={10}
          paddingBottomTitle="0"
          secondaryButtonColor="secondary"
          secondaryButtonText="Entendi"
          primaryButtonText="Fechar"
          bodyComponent={
            <Style.ErrorItemAttachList>
              {errorOnAttachFile?.errors?.map((item) => (
                <div
                  className="item-error"
                  key={`${item.indexInArray}-${item.keyType}`}
                >
                  <div className="left">
                    <Text.Heading5Bold>
                      {item?.fullName || item?.documentNumber || item?.keyValue}
                    </Text.Heading5Bold>
                    <Text.Small color={colors.redMatte}>
                      Erro encontrado
                    </Text.Small>
                  </div>
                  <div className="right">
                    <Text.Heading5Bold color={colors.grayMedium}>
                      {`Linha ${item?.indexInArray}`}
                    </Text.Heading5Bold>
                  </div>
                </div>
              ))}
            </Style.ErrorItemAttachList>
          }
          paddingHorizontal={0}
          primaryButtonAction={() => setModalErrorOnAttachFileVisible(false)}
          secondaryButtonAction={() => setModalErrorOnAttachFileVisible(false)}
        />
      </When>
    </>
  );
};
