import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
// import * as Sentry from '@sentry/react';

// prevent clickjacking

if (window.top !== window.self) {
  window.top.location = window.self.location;
}

// Sentry.init({
//   dsn: 'https://9713a9ab3b6448c2b8ce547122b34da1@o236144.ingest.sentry.io/5598796',
//   environment:
//     import.meta.env.VITE_NODE_ENV === 'prod' ? 'production' : 'development',
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//         useEffect,
//         useLocation,
//         useNavigationType,
//         createRoutesFromChildren,
//         matchRoutes
//       ),
//     }),
//     new Sentry.Replay(),
//   ],

//   tracesSampleRate: 1.0,

//   tracePropagationTargets: [
//     'localhost',
//     'https://local-prod.biz-frontend.pages.dev',
//     'https://biz.capitual.com',
//     'https://bizbeta.capitual.com',
//   ],

//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
