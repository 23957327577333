import styled, { css } from 'styled-components';

import { FadeProps } from './Modal.types';
import { colors } from '~styles';
import { motion } from 'framer-motion';
import { screen } from '~styles/metrics';
import { transparentize } from 'polished';

export const ModalBackground = styled(motion.div)<FadeProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;

  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.35);
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;

  width: 865px;
  height: 566px;
  border-radius: 12px;
  background-color: ${colors.white};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 2;

  @media ${screen.tablet} {
    width: 100vw;
    height: 100vh;
    flex-direction: column;
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 31px;
  margin-top: 31px;

  @media ${screen.phone} {
    margin-right: 20px;
    margin-top: 20px;
  }

  width: 38px;
  height: 37px;
  border: none;
  color: ${colors.white};
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
  border-radius: 50%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(4px);
  background-color: ${transparentize(0.9, colors.white)};
  transition: all ease-out 0.3s;

  &.black {
    background-color: ${transparentize(0.95, colors.black)};
    svg {
      stroke: ${colors.black};
    }
  }

  :hover {
    transform: scale(1.2);
    transition: all ease-out 0.3s;

    svg {
      transform: scale(0.99);
      transition: all ease-out 0.3s;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-self: flex-end;

  position: relative;

  width: 35%;
  height: 566px;
  overflow: hidden;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  @media ${screen.tablet} {
    visibility: hidden;
    display: none;
  }
`;

export const BodyContainer = styled.div`
  width: 65%;
  height: 100%;
  background-color: ${colors.whiteLight};
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  @media ${screen.tablet} {
    width: 100%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`;
