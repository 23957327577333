import { gql, request, useMutation } from '~reactQuery';

interface TwoFAGetRescueCodesInput {
  userOrganizationId: number;
  pin: string;
  token: string;
  settingsId: number;
}

interface TwoFAGetRescueCodesResponse {
  twoFAGetRescueCodes: {
    data: string[];
    isValid: boolean;
    messages: string[];
  };
}

export function useTwoFAGetRescueCodes() {
  const mutation = gql`
    mutation TwoFAGetRescueCodes($params: twoFAGetRescueCodesInput!) {
      twoFAGetRescueCodes(params: $params) {
        data
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: TwoFAGetRescueCodesInput) => {
    if (!params.settingsId) return null;

    const response = await request<TwoFAGetRescueCodesResponse>(mutation, {
      params,
    });
    return response.twoFAGetRescueCodes;
  };

  return useMutation('twoFAGetRescueCodes', fetch);
}
