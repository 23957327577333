import { transparentize } from 'polished';
import styled from 'styled-components';
import colors from '~styles/colors';

export const ScrollBox = styled.div`
  width: 100%;
  max-height: 278px;
  overflow: auto;
`
export const ContainerFormSavedLists = styled.div`

.button-row-saved-lists{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

}

  .go-back-button-edit-list {
    padding-top: 30px;
    cursor: pointer;
  }

  .go-back-button-confirm-value-add-beneficiary {
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    gap: 16px;
    cursor: pointer;
  }

  .align-row-edit-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    border-bottom: 1px solid ${colors.blackOpacity10p};
    padding-bottom: 16px;

    .title-list {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  .go-back-button-edit-beneficiary {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 30px;
    gap: 15px;
    cursor: pointer;
  }

  .input-value {
    border: none !important;
    padding: 0px !important;
    margin-top: 10px;
  }

  .without-value {
    color: ${colors.grayMedium};
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
    gap: 20px;
  }

  .item {
    flex: 1;
  }

  .row-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;

    .input-switch {
      input {
        width: 45px;
        height: 25px;
        appearance: none;
        background-color: ${colors.grayRegular} !important;
        border-radius: 15.5px !important;
        cursor: pointer;
        transition: 0.3s ease-out all;

        &::before {
          display: flex;
          width: 21px;
          height: 21px;
          border-radius: 100%;
          background-color: ${colors.white} !important;
          content: '';
          position: relative;
          top: 2px;
          left: 2px;
          transition: 0.3s ease-out all;
          filter: drop-shadow(0px 2px 0px #00000010);
        }

        :checked {
          background-color: ${colors.newBlue} !important;
          &::before {
            left: 22px;
          }
        }

        :disabled {
          background-color: ${transparentize(
            0.7,
            colors.grayRegular
          )} !important;
          cursor: not-allowed;

          &::before {
            background-color: ${transparentize(0.2, colors.white)} !important;
            filter: drop-shadow(0px 2px 0px #00000005);
          }

          :checked {
            background-color: ${transparentize(0.7, colors.newBlue)} !important;
          }
        }
      }
    }
  }

 



  .input-alert-rename-list {
    height: 46px;
    background-color: ${colors.transparent};
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0;
    font-size: 0.9375rem;
    padding-left: 0;
    border-color: ${colors.grayRegular};
  }

  .container-loading{
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-dont-have-payment-list{
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-value {
    border: none !important;
    padding: 0px !important;
    margin-top: 10px;
  }

  .without-value {
    color: ${colors.grayMedium} !important;
  }

  .row-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    
    .input-switch {
    input {
      width: 45px;
      height: 25px;
      appearance: none;
      background-color: ${colors.grayRegular} !important;
      border-radius: 15.5px !important;
      cursor: pointer;
      transition: 0.3s ease-out all;

      &::before {
        display: flex;
        width: 21px;
        height: 21px;
        border-radius: 100%;
        background-color: ${colors.white} !important;
        content: '';
        position: relative;
        top: 2px;
        left: 2px;
        transition: 0.3s ease-out all;
        filter: drop-shadow(0px 2px 0px #00000010);
      }

      :checked {
        background-color: ${colors.newBlue} !important;
        &::before {
          left: 22px;
        }
      }

      :disabled {
        background-color: ${transparentize(0.7, colors.grayRegular)} !important;
        cursor: not-allowed;

        &::before {
          background-color: ${transparentize(0.2, colors.white)} !important;
          filter: drop-shadow(0px 2px 0px #00000005);
        }

        :checked {
          background-color: ${transparentize(0.7, colors.newBlue)} !important;
        }
      }
    }
  }
  }
`;

export const RowList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 27px;

  .left {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .align-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    .align-column {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .container-counting {
      min-width: 31px;
      height: 24px;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: ${colors.mainGray};
      padding: 0px 10px;
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
`;

export const ContainerSavedLists = styled.div`
  .input-alert-rename-list {
    height: 46px;
    background-color: ${colors.transparent};
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0;
    font-size: 0.9375rem;
    padding-left: 0;
    border-color: ${colors.grayRegular};
  }
`
