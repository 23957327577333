import * as React from 'react';

import { SVGProps } from 'react';

const XrpCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="M14.93 9.344h-2.89l6.015 5.956a5.622 5.622 0 0 0 7.891 0l6.018-5.956h-2.893l-4.57 4.524a3.559 3.559 0 0 1-4.997 0L14.93 9.344ZM29.108 25.906H32l-6.055-5.993c-2.178-2.156-5.71-2.156-7.89 0L12 25.906h2.89l4.61-4.561a3.559 3.559 0 0 1 4.997 0l4.61 4.561Z"
      fill="#23292F"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default XrpCoinSVG;
