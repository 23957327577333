import { SVGProps } from 'react';

const GoBackArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 20.4162L14.2929 19.7091L7.29289 12.7091C7.10536 12.5216 7 12.2672 7 12.002C7 11.7368 7.10536 11.4824 7.29289 11.2949L14.2929 4.29489L15 3.58778L16.4142 5.002L15.7071 5.7091L9.41421 12.002L15.7071 18.2949L16.4142 19.002L15 20.4162Z"
      fill="#272937"
    />
  </svg>
);

export default GoBackArrowIcon;
