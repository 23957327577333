import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import * as Text from '~styles/text';

import { formatCurrencyFn, getWalletIcon } from '~helpers/format/currency';
import { CriptoWithdrawStepsProps } from '~src/common/modals/multiStep/Transfer/Transfer.types';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { WalletType } from '~reactQuery/types/wallets.types';
import { useFormContext } from 'react-hook-form';
import { GenericRow, RoundButtonIcon } from '~components/index';
import EditSVG from '~assets/svg/edit';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

const Review = ({ setCriptoWithdrawStep }: CriptoWithdrawStepsProps) => {
  const goBack = () => setCriptoWithdrawStep('amountToWithdraw');
  const { params } = useActionsOnModalMultiStep();
  const isComingFromCapWallet = params?.comingFrom === 'capWallet';

  const { getValues } = useFormContext();

  const originWallet = getValues('originWallet') as WalletType;
  const selectedWallet = getValues('selectedWallet') as WalletType;
  const walletAddressToWithdraw = getValues(
    'walletAddressToWithdraw'
  ) as string;
  const amount = getValues('amount') as string;

  const originWalletIsoCode = originWallet.currency.isoCode;
  const walletName = `Carteira ${originWallet.walletName}`;

  const selectedWalletIsoCode = selectedWallet.currency.isoCode;

  const handleGoToNextStep = () => {
    setCriptoWithdrawStep('pin');
  };

  const handleEditValue = () => setCriptoWithdrawStep('amountToWithdraw');

  return (
    <>
      <ModalMultiStepHeader
        title={
          !isComingFromCapWallet
            ? 'Saque para Carteira de Cripto Externa'
            : 'Detalhes do Envio'
        }
        backButtonAction={goBack}
      />
      <div className="cripto-withdraw-container-step">
        <Text.Paragraph marginBottom={20} display="inline-block">
          {!isComingFromCapWallet
            ? 'Você está sacando'
            : 'Verifique os detalhes do envio de '}
          <strong>
            {' '}
            {formatCurrencyFn(
              originWalletIsoCode,
              amount,
              false,
              false,
              true
            )}{' '}
          </strong>
          {!isComingFromCapWallet ? 'para ' : 'para o endereço '}
          <strong>{walletAddressToWithdraw}</strong>
        </Text.Paragraph>

        <GenericRow
          overText="Origem"
          title={walletName}
          rightContentType="component"
          rightComponent={
            <RoundButtonIcon
              buttonType="default"
              buttonSize="iconWallet"
              noHover
            >
              <div className="scale-icon">
                {getWalletIcon(originWalletIsoCode)}
              </div>
            </RoundButtonIcon>
          }
          noHorizontalPadding
        />

        <GenericRow
          overText="Para"
          title={walletAddressToWithdraw}
          rightContentType="component"
          rightComponent={
            <RoundButtonIcon
              buttonType="default"
              buttonSize="iconWallet"
              noHover
            >
              <div className="scale-icon">
                {getWalletIcon(selectedWalletIsoCode)}
              </div>
            </RoundButtonIcon>
          }
          noHorizontalPadding
        />

        <GenericRow
          overText="Valor"
          title={formatCurrencyFn(
            originWalletIsoCode,
            amount,
            false,
            false,
            true
          )}
          rightContentType="component"
          rightComponent={
            <RoundButtonIcon
              buttonType="default"
              onClick={handleEditValue}
              buttonSize="icon24"
            >
              <EditSVG />
            </RoundButtonIcon>
          }
          noHorizontalPadding
        />

        <ModalMultiStepBottom
          singleButtonText="Próximo"
          singleButtonWidth="100%"
          singleButtonAlignment="flex-end"
          disableSingleButton={!amount}
          singleButtonAction={handleGoToNextStep}
        />
      </div>
    </>
  );
};

export default Review;
