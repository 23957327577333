import * as React from 'react';
import { SVGProps } from 'react';

const ArrowLeftCarrouselSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={32} fill="none" {...props}>
    <path
      stroke="#757893"
      strokeLinecap="round"
      strokeWidth={2}
      d="M17 1 2 16l15 15"
    />
  </svg>
);
export default ArrowLeftCarrouselSVG;
