import * as Style from './Transfer.styles';
import {
  AmountToWithdraw,
  ConfirmPin,
  OriginWallet,
  Review,
  SelectWalletToWithdraw,
} from './ModalSteps/CriptoWithdrawSteps';
import { CriptoWithdrawSteps, Tabs, TransferSteps } from './Transfer.types';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import ChooseOriginWallet from './ModalSteps/PixModalSteps/2.ChooseOriginWallet.component';
import ChooseTransferMethod from './ModalSteps/1.ChooseTransferMethod.component';
import ConfirmDetails from './ModalSteps/PixModalSteps/4.ConfirmDetails.component';
import FillManualTransferInfo from './ModalSteps/PixModalSteps/1.3.FillManualTransferInfo.component';
import LoadingSpinner from '~components/LoadingSpinner';
import ManualTransfer from './ModalSteps/PixModalSteps/1.2.ManualTransfer.component';
import ManualTransferDocument from './ModalSteps/PixModalSteps/1.3.1.ManualTransferDocument';
import ModalMultiStep from '~components/ModalMultiStep';
import Pin from './ModalSteps/PixModalSteps/5.Pin.component';
import PixKeyIdentified from './ModalSteps/PixModalSteps/1.1.PixKeyIdentified.component';
import Status from './ModalSteps/PixModalSteps/6.status.component';
import ValueSended from './ModalSteps/PixModalSteps/3.ValueSended.component';
import { When } from 'react-if';
import WithdrawStatus from './ModalSteps/CriptoWithdrawSteps/6.WithdrawStatus.component';
import colors from '~styles/colors';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { usePermission } from '~helpers/permissionGate';

const Transfer = () => {
  const { openModalMultiStep, setOpenModalMultiStep, params } =
    useActionsOnModalMultiStep();

  const { granted: criptoTransactionsGranted } =
    usePermission('criptoTransactions');

  const { granted: buttonsPixGranted } = usePermission('buttonsPix');

  // Pix Tab \ CriptoWithdraw Tab \ Exchange Tab
  const [selectedTab, setSelectedTab] = useState<Tabs>(
    buttonsPixGranted ? 'pix' : 'criptoWithdraw'
  );

  // Pix Tab Steps
  const [currentPixStep, setCurrentPixStep] = useState<TransferSteps>(
    'chooseTransferMethod'
  );

  // CriptoWithdraw Steps
  const [currCriptoWithdrawStep, setCurrCriptoWithdrawStep] =
    useState<CriptoWithdrawSteps>('chooseTransferMethod');

  const setPixSteps = (step: TransferSteps) => setCurrentPixStep(step);

  const setCriptoWithdrawStep = (step: CriptoWithdrawSteps) =>
    setCurrCriptoWithdrawStep(step);

  const transferFormMethods = useForm({
    shouldUnregister: false,
  });

  const criptoWithdrawFormMethods = useForm({
    shouldUnregister: false,
  });

  const pixStepsComponents = {
    chooseTransferMethod: {
      component: (
        <ChooseTransferMethod
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          key="chooseTransferMethod"
          setPixStep={setPixSteps}
          setCriptoWithdrawStep={setCriptoWithdrawStep}
          buttonsPixGranted={buttonsPixGranted}
          criptoTransactionsGranted={criptoTransactionsGranted}
        />
      ),
      progressValue: 10,
    },
    pixMethod: {
      component: <PixKeyIdentified key="pixMethod" setPixStep={setPixSteps} />,
      progressValue: 15,
    },
    manualTransfer: {
      component: (
        <ManualTransfer key="manualTransfer" setPixStep={setPixSteps} />
      ),
      progressValue: 17,
    },
    manualTransferDocument: {
      component: (
        <ManualTransferDocument
          key="manualTransferDocument"
          setPixStep={setPixSteps}
        />
      ),
      progressValue: 17,
    },
    fillManualTransferInfo: {
      component: (
        <FillManualTransferInfo
          key="fillManualTransferInfo"
          setPixStep={setPixSteps}
        />
      ),
      progressValue: 25,
    },
    chooseOriginWallet: {
      component: (
        <ChooseOriginWallet
          key="chooseOriginWallet"
          setPixStep={setPixSteps}
          criptoTransactionsGranted={criptoTransactionsGranted}
        />
      ),
      progressValue: 30,
    },
    valueSended: {
      component: <ValueSended key="valueSended" setPixStep={setPixSteps} />,
      progressValue: 50,
    },
    confirmDetails: {
      component: (
        <ConfirmDetails key="confirmDetails" setPixStep={setPixSteps} />
      ),
      progressValue: 70,
    },
    pin: {
      component: <Pin key="pin" setPixStep={setPixSteps} />,
      progressValue: 90,
    },
    status: {
      component: <Status key="status" setPixStep={setPixSteps} />,
      progressValue: 100,
    },
    loading: {
      component: (
        <div className="center-loading">
          <LoadingSpinner size={60} strokeSize={25} color={colors.primary} />
        </div>
      ),
      progressValue: 10,
    },
  };

  const criptoWithdrawComponents = {
    chooseTransferMethod: {
      component: (
        <ChooseTransferMethod
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          key="chooseTransferMethod"
          setPixStep={setPixSteps}
          setCriptoWithdrawStep={setCriptoWithdrawStep}
          buttonsPixGranted={buttonsPixGranted}
          criptoTransactionsGranted={criptoTransactionsGranted}
        />
      ),
      progressValue: 1,
    },
    selectWalletToWithdraw: {
      component: (
        <SelectWalletToWithdraw
          key="selectWalletToWithdraw"
          setCriptoWithdrawStep={setCriptoWithdrawStep}
        />
      ),
      progressValue: 1,
    },
    originWallet: {
      component: (
        <OriginWallet
          key="originWallet"
          setCriptoWithdrawStep={setCriptoWithdrawStep}
        />
      ),
      progressValue: 20,
    },
    amountToWithdraw: {
      component: (
        <AmountToWithdraw
          key="amountToWithdraw"
          setCriptoWithdrawStep={setCriptoWithdrawStep}
        />
      ),
      progressValue: 40,
    },
    review: {
      component: (
        <Review key="review" setCriptoWithdrawStep={setCriptoWithdrawStep} />
      ),
      progressValue: 60,
    },
    pin: {
      component: (
        <ConfirmPin key="pin" setCriptoWithdrawStep={setCriptoWithdrawStep} />
      ),
      progressValue: 80,
    },
    withdrawStatus: {
      component: (
        <WithdrawStatus
          key="withdrawStatus"
          setCriptoWithdrawStep={setCriptoWithdrawStep}
        />
      ),
      progressValue: 100,
    },
  };

  const pixStepInfos =
    pixStepsComponents[currentPixStep as keyof typeof pixStepsComponents];

  const criptoWithdrawStepInfos =
    criptoWithdrawComponents[
      currCriptoWithdrawStep as keyof typeof criptoWithdrawComponents
    ];

  useEffect(() => {
    if (!openModalMultiStep || selectedTab) {
      setCurrentPixStep('chooseTransferMethod');
      setCurrCriptoWithdrawStep('chooseTransferMethod');

      transferFormMethods.reset();
      criptoWithdrawFormMethods.reset();
    }
  }, [
    criptoWithdrawFormMethods,
    openModalMultiStep,
    selectedTab,
    transferFormMethods,
  ]);

  useEffect(() => {
    if (params?.contact && openModalMultiStep) {
      setCurrentPixStep('pixMethod');
    }
  }, [params?.contact, openModalMultiStep]);

  useEffect(() => {
    if (currentPixStep === 'chooseTransferMethod' && openModalMultiStep) {
      transferFormMethods.reset();
    }
  }, [currentPixStep]);

  const progressBar = () => {
    if (selectedTab === 'pix') return pixStepInfos.progressValue;

    if (selectedTab === 'criptoWithdraw') {
      return criptoWithdrawStepInfos.progressValue;
    }

    return 0;
  };

  return (
    <Style.Container>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={progressBar()}
      >
        <When condition={selectedTab === 'pix'}>
          <FormProvider {...transferFormMethods}>
            {pixStepInfos.component}
          </FormProvider>
        </When>

        <When condition={selectedTab === 'criptoWithdraw'}>
          <FormProvider {...criptoWithdrawFormMethods}>
            {criptoWithdrawStepInfos.component}
          </FormProvider>
        </When>
      </ModalMultiStep>
    </Style.Container>
  );
};

export default Transfer;
