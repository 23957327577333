import * as Style from '../PartnersSteps.styles';
import * as Text from '~styles/text';

import { BigInput, GenericButton } from '~components';
import { useEffect, useState } from 'react';

import ArrowBackIcon from '~assets/images/icons/kyc/arrow-back.svg';
import { BizPartNameProps } from '../../Kyc.types';
import { When } from 'react-if';
import { colors } from '~styles';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { splitByLastDot } from '~helpers/format/file';
import { usePartnersList } from '../../store';
import { useTranslation } from 'react-i18next';

export const BusinessPartnerName = ({
  gotToModalStep,
  partnerList,
}: BizPartNameProps) => {
  const { field } = usePartnersList();
  const { t } = useTranslation();

  const [fieldToEdit, setFieldToEdit] = useState('');

  const {
    register,
    getValues,
    setValue,
    unregister,
    formState: { errors },
    setError,
  } = partnerList;

  useEffect(() => {
    setFieldToEdit(Array.isArray(field) ? field[1] : field);
  }, [field]);

  const getIndex = onlyNumbers(String(fieldToEdit));

  const errorIncase =
    errors?.allPartners?.[getIndex?.[0]]?.list?.[getIndex?.[1]]?.businessName;

  const nextStep = () => {
    const bizName = getValues(`${fieldToEdit}.businessName`);

    if (bizName?.length >= 4) {
      setValue(`${fieldToEdit}.businessName`, bizName);
      gotToModalStep('businessPartnerCnpj');
    } else {
      setError(`${fieldToEdit}.businessName`, {
        type: 'required',
        message: 'Esse é realmente um nome empresarial?',
      });
    }
  };

  const goBack = () => {
    const currentBizPartner = getValues(fieldToEdit);

    if (!currentBizPartner?.partnerId) {
      const newArray = getValues()?.allPartners?.[getIndex?.[0]]?.list?.filter(
        (item: any) => {
          return item !== currentBizPartner;
        }
      );

      setValue(splitByLastDot(fieldToEdit), newArray);
    }

    gotToModalStep('partnerName');
    unregister(fieldToEdit);
  };

  const partnerIncase = getValues(fieldToEdit);

  const disableGoBack =
    partnerIncase?.isSaved ||
    partnerIncase?.partnerId ||
    partnerIncase?.businessId;

  if (fieldToEdit.length <= 0) return null;

  return (
    <Style.Container>
      <div className="content">
        <When condition={!disableGoBack}>
          <div className="back-button" onClick={goBack}>
            <ArrowBackIcon />
          </div>
        </When>

        <Text.Heading5 color={colors.grayMedium} marginTop={30}>
          {t('Adicionar Sócio')}
        </Text.Heading5>

        <Text.Heading2Bold marginTop={10} marginBottom={30}>
          {t('Digite a Razão Social da empresa sócia que deseja adicionar')}
        </Text.Heading2Bold>

        <BigInput
          {...register(`${fieldToEdit}.businessName`)}
          paddingBottom={104}
          placeHolder="Razão Social"
          autoFocus
          error={errorIncase?.message}
        />

        <Text.Heading5 marginTop={25} lineHeight={140}>
          Importante: É necessário que a Razão Social esteja exatamente como no
          cartão CNPJ da empresa.
        </Text.Heading5>
      </div>

      <div className="footer">
        <div className="footer-btn">
          <GenericButton buttonType="secondary" width="100%" onClick={nextStep}>
            Próximo
          </GenericButton>
        </div>
      </div>
    </Style.Container>
  );
};
