import { transparentize } from 'polished';
import styled from 'styled-components';
import colors from '~styles/colors';

export const ScrollBox = styled.div`
  width: 100%;
  max-height: 190px;
  overflow: auto;
`
export const ContainerListContacts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  background: ${colors.white};
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
`;

export const Row = styled.div`
  width: 100%;
  height: 63px;
  background-color: ${colors.white};
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 16px;
  cursor: pointer;

  .circle {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .description {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }

  .last-item {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }
`;

export const FormDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  background-color: transparent;

  .go-back-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    cursor: pointer;
  }

  .input-value {
    border: none !important;
    padding: 0px !important;
    margin-top: 10px;
  }

  .without-value {
    color: ${colors.grayMedium};
  }

  .bank-list-input {
    border-radius: 0px !important;
    outline: none !important;
    border-bottom: ${colors.grayLight} solid 1px;
    padding-left: 0px;
    background-color: transparent !important;
    font-feature-settings: 'ss02' on, 'zero' on;

    input {
      border: none;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
    gap: 20px;
  }

  .item {
    flex: 1;
  }

  .row-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    
    .input-switch {
    input {
      width: 45px;
      height: 25px;
      appearance: none;
      background-color: ${colors.grayRegular} !important;
      border-radius: 15.5px !important;
      cursor: pointer;
      transition: 0.3s ease-out all;

      &::before {
        display: flex;
        width: 21px;
        height: 21px;
        border-radius: 100%;
        background-color: ${colors.white} !important;
        content: '';
        position: relative;
        top: 2px;
        left: 2px;
        transition: 0.3s ease-out all;
        filter: drop-shadow(0px 2px 0px #00000010);
      }

      :checked {
        background-color: ${colors.newBlue} !important;
        &::before {
          left: 22px;
        }
      }

      :disabled {
        background-color: ${transparentize(0.7, colors.grayRegular)} !important;
        cursor: not-allowed;

        &::before {
          background-color: ${transparentize(0.2, colors.white)} !important;
          filter: drop-shadow(0px 2px 0px #00000005);
        }

        :checked {
          background-color: ${transparentize(0.7, colors.newBlue)} !important;
        }
      }
    }
  }
  }

  .hidden-div {
    display: none;
  }
`;

export const ContainerListOfBeneficiaries = styled.div`
display: flex;
flex-direction: column;

.container-title-list-of-beneficiaries{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
 }

 .container-switch{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  .input-switch {
    input {
      width: 45px;
      height: 25px;
      appearance: none;
      background-color: ${colors.grayRegular} !important;
      border-radius: 15.5px !important;
      cursor: pointer;
      transition: 0.3s ease-out all;

      &::before {
        display: flex;
        width: 21px;
        height: 21px;
        border-radius: 100%;
        background-color: ${colors.white} !important;
        content: '';
        position: relative;
        top: 2px;
        left: 2px;
        transition: 0.3s ease-out all;
        filter: drop-shadow(0px 2px 0px #00000010);
      }

      :checked {
        background-color: ${colors.newBlue} !important;
        &::before {
          left: 22px;
        }
      }

      :disabled {
        background-color: ${transparentize(0.7, colors.grayRegular)} !important;
        cursor: not-allowed;

        &::before {
          background-color: ${transparentize(0.2, colors.white)} !important;
          filter: drop-shadow(0px 2px 0px #00000005);
        }

        :checked {
          background-color: ${transparentize(0.7, colors.newBlue)} !important;
        }
      }
    }
  }
 }

 
`;
