import { colors } from '~styles';
import { screen } from '~styles/metrics';
import styled from 'styled-components';

export const SecurityContainer = styled.div`
  display: flex;
  flex-direction: column;

  .input-switch-container {
    display: flex;
    flex-direction: row;
    gap: 17px;
    margin-top: 25px;
    margin-bottom: 5px;
  }

  .divider {
    border-width: 0.5px 0px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.05);
    margin-bottom: 25px;
    margin-top: 50px;
  }

  .icon-title-gap {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .buttons-container {
    display: flex;

    @media ${screen.tablet} {
      flex-direction: column;
    }
    gap: 10px;
  }
`;


export const RowSecurity = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;

  .label-container {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  
  .content-container {
    display: flex;
    flex-direction: column;

    width: 100%;
  }

  .icon-title-gap {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
  }

  @media (max-width: 940px) {
    flex-direction: column;

    .label-container {
      width: 100%;
    }
  }`