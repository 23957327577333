import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from '@react-pdf/renderer';

import blackLogo from '~assets/images/logo-black.png';
import colors from '~styles/colors';
import dayjs from 'dayjs';
import { formatCodeBar } from '~helpers/format/masks';
import { formatCurrencyFn } from '~helpers/format/currency';
import { getTxStatus } from '~helpers/transaction/transactionUtils';
import HelveticaNowDisplayRegular from '../../fonts/HelveticaNowDisplay-Regular.ttf';
import HelveticaNowDisplayMedium from '../../fonts/HelveticaNowDisplay-Medium.ttf';
import HelveticaNowDisplayBold from '../../fonts/HelveticaNowDisplay-Bold.ttf';

interface TransferVoucherPixProps {
  authCode?: string;
  accountDetails: any;
  billDetails?: any;
  value?: string;
}

const styles = StyleSheet.create({
  body: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    fontWeight: 400,
  },
  title: {
    fontSize: 26,
    textAlign: 'left',
    fontWeight: 700,
    lineHeight: '120%',

    color: '#272937',
  },
  logo: {
    width: 125,
    height: 33,
  },
  container: {
    width: '100%',
    marginTop: 30,
    padding: '25px 25px 25px 25px',
  },
  date: {
    marginTop: 15,
    marginBottom: 40,
    fontWeight: 500,
    fontSize: 16,
    color: colors.grayMedium,
  },
  valueContainer: {
    flexDirection: 'column',
  },
  divider: {
    width: 50,
    border: '1px solid #C3C6DB',
    marginBottom: 30,
    marginTop: 30,
  },
  footer: {
    backgroundColor: colors.mainGray,
    padding: 20,
    borderRadius: 15,
    bottom: 15,
    position: 'absolute',
    width: '90%',
  },
  label: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '100%',
    color: colors.grayRegular,
    marginBottom: 10,
  },
  link: {
    color: colors.primary,
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '150%',
    marginTop: 12,
    marginBottom: 5,
    textAlign: 'center',
  },
  containerImg: {
    height: 33,
    top: 30,
    left: 25,
    width: '100%',
    zIndex: 99,
  },
  transferValue: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '100%',
    marginBottom: 7,

    color: '#272937',
  },
  textNames: {
    fontWeight: 700,
    fontSize: 18,
    marginBottom: 7,

    color: '#272937',
  },
  bankNameText: {
    fontWeight: 500,
    fontSize: 15,
    marginBottom: 20,

    color: '#272937',
  },
  displayRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  marginLeft: {
    marginLeft: 20,
  },
  marginBottom: {
    marginBottom: 25,
  },
  colorGrayMedium: {
    color: colors.grayMedium,
  },
  text: {
    fontWeight: 500,
    fontSize: 15,

    color: '#272937',
  },
  footerText: {
    fontWeight: 500,
    fontSize: 14,

    color: '#757893',
  },
  footerTitle: {
    fontWeight: 500,
    fontSize: 15,

    textAlign: 'center',
    marginTop: 10,
  },
  footerSubtitle: {
    fontWeight: 500,
    fontSize: 15,

    textAlign: 'center',
  },
});

export const BillSlipReceipt = ({
  billDetails,
  accountDetails,
  authCode,
  value,
}: TransferVoucherPixProps) => {
  const dataToDisplay = {
    value: value || '',
    senderBranch: accountDetails?.branch || '',
    senderAcc: accountDetails?.account || '',
    senderName: accountDetails?.name || '',
    billCode:
      formatCodeBar(billDetails?.data?.digitableLine) ||
      billDetails?.data?.digitableLine ||
      '',
    receiverName: billDetails?.data?.recipientName || '',
    assignor: billDetails?.data?.assignor || '',
    dueDate:
      dayjs(billDetails?.data?.dueDate).format('DD/MM/YYYY h:mm A') || '',
    paymentDate: billDetails?.timestamp
      ? dayjs(billDetails?.timestamp).format('DD/MM/YYYY h:mm A')
      : '--/--/--',
  };

  return (
    <Document>
      <Page wrap style={styles.body} size={{ width: 375, height: 1048 }}>
        <View fixed style={styles.containerImg}>
          <Image src={blackLogo} style={styles.logo} />
        </View>
        <View style={styles.container}>
          <View>
            <Text style={styles.title}>Comprovante de Depósito de Boleto</Text>
          </View>
          <Text style={styles.date}>{dataToDisplay.paymentDate}</Text>

          <View style={styles.valueContainer}>
            <Text style={styles.label}>Valor</Text>
            <Text style={styles.transferValue}>
              {formatCurrencyFn(
                'BRL',
                dataToDisplay?.value,
                false,
                false,
                true
              )}
            </Text>
          </View>

          <View>
            <Text style={styles.label}>Status</Text>

            {billDetails?.status ? (
              <Text style={styles.text}>
                {getTxStatus(billDetails?.status)}
              </Text>
            ) : null}
          </View>

          <View style={styles.divider} />
          <View>
            <Text style={styles.label}>Enviado por</Text>
            <Text style={styles.textNames}>{dataToDisplay?.senderName}</Text>
          </View>
          <View>
            <Text style={styles.label}>Instituição</Text>
            <Text style={styles.bankNameText}>
              Acesso Soluções de Pagamento S. A.
            </Text>
          </View>
          <View style={styles.displayRow}>
            <View>
              <Text style={styles.label}>Agência</Text>
              <Text style={styles.text}>{dataToDisplay?.senderBranch}</Text>
            </View>
            <View style={styles.marginLeft}>
              <Text style={styles.label}>Conta</Text>
              <Text style={styles.text}>{dataToDisplay?.senderAcc}</Text>
            </View>
          </View>
          <View style={styles.divider} />

          <View style={styles.displayRow}>
            <View style={styles.marginBottom}>
              <Text style={styles.label}>Data do Vencimento</Text>
              <Text style={styles.text}>{dataToDisplay?.dueDate}</Text>
            </View>
            <View style={styles.marginLeft}>
              <Text style={styles.label}>Data do Pagamento</Text>
              <Text style={styles.text}>{dataToDisplay?.paymentDate}</Text>
            </View>
          </View>
        </View>

        <View fixed style={styles.footer}>
          <View style={styles.marginBottom}>
            <Text style={styles.label}>Protocolo</Text>
            <Text style={styles.footerText}>{authCode}</Text>
          </View>

          <Text style={styles.footerTitle}>Precisa de ajuda?</Text>
          <Text style={styles.footerSubtitle}>
            Estamos aqui se você tiver alguma dúvida.
          </Text>
          <Text style={styles.link}>help.capitual.com</Text>
        </View>
      </Page>
    </Document>
  );
};
