import * as Style from '../ChangePin.styles';
import * as Text from '~styles/text';
import * as yup from 'yup';

import React, { useState } from 'react';

import DialogBottom from '~components/Dialog/DialogBottom.component';
import DialogHeader from '~components/Dialog/DialogHeader.component';
import { InputPin } from '~components/Inputs';
import colors from '~styles/colors';
import { getSelectedUserOrganization } from '~helpers/device';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useChangeOrganizationPin } from '~reactQuery/mutations/business/useChangeOrganizationPin';

interface ErrorList {
  oldPin: string;
  newPin: string;
  confirmPin: string;
}

const AskPin: React.FC = ({ setStepsAndParams }) => {
  const orgId = getSelectedUserOrganization();
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();
  const { mutateAsync: changeOrgPin, isLoading: loadingChangePin } =
    useChangeOrganizationPin();

  const [pins, setPins] = useState({
    oldPin: '',
    newPin: '',
    confirmPin: '',
  });

  const [erros, setErros] = useState({
    oldPin: '',
    newPin: '',
    confirmPin: '',
  });

  const schema = yup.object({
    oldPin: yup
      .string()
      .length(4, 'O pin deve ter 4 caracteres')
      .required('Esse campo é obrigatório'),
    newPin: yup
      .string()
      .length(4, 'O pin deve ter 4 caracteres')
      .required('Esse campo é obrigatório'),
    confirmPin: yup
      .string()
      .length(4, 'O pin deve ter 4 caracteres')
      .required('Esse campo é obrigatório')
      .oneOf([yup.ref('newPin'), null], 'Os pins não coincidem'),
  });

  const changePin = async () => {
    let errorList: ErrorList = {
      oldPin: '',
      newPin: '',
      confirmPin: '',
    };
    setErros({ ...errorList });

    await schema
      .validate(pins, { abortEarly: false })
      .catch((validationError) => {
        validationError?.inner?.forEach((e: any) => {
          errorList = {
            ...errorList,
            [e.path]: e.message,
          };
        });

        setErros({ ...errorList });
      });

    if (pins?.newPin !== pins?.confirmPin) return;
    if (Object.values(errorList).some((x) => x !== null && x !== '')) return;

    try {
      await changeOrgPin({
        userOrganizationId: Number(orgId),
        newPin: pins.newPin,
        oldPin: pins.oldPin,
      });

      setStepsAndParams('successChangePin');
    } catch (error: any) {
      // console.log('Change Pin Error -> ', error);
    }
  };

  return (
    <>
      <DialogHeader
        title="Alterar PIN de 4 dígitos"
        description="Crie um PIN forte e deixe sua conta ainda mais segura."
      />

      <div className="change-pin-body">
        <Text.Heading5 marginBottom={10} marginTop={25}>
          PIN atual
        </Text.Heading5>

        <InputPin
          autoFocus
          size={4}
          secret
          onChange={(pin) => {
            setPins((prev) => ({ ...prev, oldPin: pin }));
          }}
          onFinish={(pin) => {
            setPins((prev) => ({ ...prev, oldPin: pin }));
          }}
          error={erros?.oldPin?.length > 1}
        />

        {erros?.oldPin && (
          <Text.Heading5 marginTop={5} color={colors.danger}>
            {erros.oldPin}
          </Text.Heading5>
        )}

        <Text.Heading5 marginBottom={10} marginTop={25}>
          Novo PIN
        </Text.Heading5>

        <InputPin
          size={4}
          secret
          onChange={(pin) => {
            setPins((prev) => ({ ...prev, newPin: pin }));
          }}
          onFinish={(pin) => {
            setPins((prev) => ({ ...prev, newPin: pin }));
          }}
          error={erros?.newPin?.length > 1}
          errorMessage="ss"
        />
        {erros?.newPin && (
          <Text.Heading5 marginTop={5} color={colors.danger}>
            {erros.newPin}
          </Text.Heading5>
        )}

        <Text.Heading5 marginBottom={10} marginTop={25}>
          Repetir novo PIN
        </Text.Heading5>

        <InputPin
          size={4}
          secret
          onChange={(pin) => {
            setPins((prev) => ({ ...prev, confirmPin: pin }));
          }}
          onFinish={(pin) => {
            setPins((prev) => ({ ...prev, confirmPin: pin }));
          }}
          error={erros?.confirmPin?.length > 1}
        />
        {erros?.confirmPin && (
          <Text.Heading5 marginTop={5} color={colors.danger}>
            {erros.confirmPin}
          </Text.Heading5>
        )}
      </div>

      <DialogBottom
        singleButtonText="Confirmar novo PIN"
        doubleButtonTextOne="Cancelar"
        singleButtonAlignment="flex-end"
        singleButtonAction={changePin}
        doubleButtonOneAction={() => setOpenModalMultiStep(false)}
        singleButtonLoading={loadingChangePin}
        disableSingleButton={false}
      />
    </>
  );
};

export default AskPin;
