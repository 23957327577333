import * as Text from '~styles/text';

import AuthValidate from '~components/AuthValidate';
import { ConfirmPinPageProps } from '../DeletePixKey.types';
import Inbox from '~assets/images/icons/24x24/group.svg';
import { InputPin } from '~components/Inputs';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { getSelectedUserOrganization } from '~helpers/device';
import { parseError } from '~reactQuery/index';
import { useDeletePixKey } from '~reactQuery/mutations/pix/useDeletePixKey';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { useState } from 'react';
import { useUserAuthTypes } from '~hooks/useUserAuthTypes';

function ConfirmPin(props: ConfirmPinPageProps) {
  const { setCurrentStep, pixKey } = props;

  const [hasError, setHasError] = useState({
    pin: '',
    twoFa: '',
  });

  const { auth } = useUserAuthTypes();
  const userOrganizationId = getSelectedUserOrganization();

  const { data: partnerInfos } = useGetPartner();
  const { mutateAsync: handleDeletePixKey } = useDeletePixKey();

  const userDocumentNumber = partnerInfos?.data.documentNumber;

  const validateAndGoNext = async (pin: string, twoFa?: string) => {
    if (!pixKey) return;

    if (!userOrganizationId || !userDocumentNumber) return;

    setHasError({
      pin: '',
      twoFa: '',
    });

    if ((auth.includes('2-FA') && twoFa?.length !== 6) || pin.length !== 4)
      return;

    const twoFA = twoFa ? { token: twoFa } : {};

    try {
      await handleDeletePixKey({
        documentNumber: userDocumentNumber,
        userOrganizationId: Number(userOrganizationId),
        value: pixKey,
        pin,
        ...twoFA,
      });

      setCurrentStep('deletePixKeyStatus', { statusOfCreation: 'OK' });
    } catch (error: any) {
      const errorKey = parseError(error);

      if (errorKey === 'INVALID_PIN') {
        setHasError((prev) => ({ ...prev, pin: 'Pin inválido' }));
      } else if (errorKey === 'INVALID_2FA_TOKEN') {
        setHasError((prev) => ({ ...prev, twoFa: '2-FA inválido' }));
      } else {
        setCurrentStep('deletePixKeyStatus', { statusOfCreation: 'NOT_OK' });
      }
    }
  };

  const renderTitle = () => {
    return (
      <Text.Heading5 display="inline">
        Você está excluindo a Chave aleatória: <strong>{pixKey}</strong>.
      </Text.Heading5>
    );
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Confirmação de segurança"
        description={renderTitle()}
        backButtonAction={() => setCurrentStep('areYouSure')}
      />
      <div className="delete-pix-body">
        <AuthValidate
          authTypes={auth}
          onChangeCodes={(pin, twoFa) => validateAndGoNext(pin, twoFa)}
          pinErrorMessage={hasError.pin}
          twoFaErrorMessage={hasError.twoFa}
        />
      </div>
      <ModalMultiStepBottom />
    </>
  );
}

export default ConfirmPin;
