import * as Style from './Home.styles';
import * as Text from '~styles/text';
// import * as Sentry from '@sentry/react';

import { Area, AreaChart, ResponsiveContainer, Tooltip, YAxis } from 'recharts';
import {
  CardWallet,
  Container,
  EmptyAssets,
  EmptyWallets,
  GraphButtonPeriod,
  LoadingSpinner,
  Profits,
  QuickActions,
  SideBar,
  TransactionsList,
} from '~components';
import { Else, If, Then, When } from 'react-if';
import { formatCurrencyFn, getWalletIcon } from '~helpers/format/currency';
import { useEffect, useMemo, useState } from 'react';

import LabIcon from '~assets/images/icons/24x24/lab-erlenmeyer.svg';
import TipIcon from '~assets/images/icons/question.svg';
import YieldArrowUpIcon from '~assets/images/icons/mini-arrow-up.svg';
import { breakpoints } from '~styles/metrics';
import { chartData } from '~data/chartData';
import { colors } from '~styles';
import dayjs, { formatDate } from '~helpers/format/date';
import { getSelectedUserOrganization } from '~helpers/device';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useCurrenciesVariations } from '~reactQuery/queries/wallet/useCurrenciesVariations';
import { TooltipProps } from './Home.types';
import Message from '~components/Message';

import { useCurrencyExchange } from '~reactQuery/queries/wallet/useCurrencyExchange';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';
import { useNativeCarouselScrolling } from '~hooks/useNativeCarouselScrolling';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import useWindowDimensions from '~hooks/windowDimension';
import { PermissionGate } from '~helpers/permissionGate';
import { formatCpfOrCnpj } from '~helpers/format/numbers';
import GiftIcon from '~assets/svg/gift';
import { WalletType } from '~reactQuery/types/wallets.types';
import { CurrencyTypes } from '~src/@types/currency.types';
import { initFreshDeskChat } from '~helpers/messages';
import { currenciesList } from '~data/wallets';
import { renderUserName } from '~helpers/generic';
import { useGetBalanceHistory } from '~reactQuery/queries/capAccount/useGetBalanceHistory';
import { quickActions } from '~data/quickAction';
import PixSVG from '~assets/svg/pix';
import ArrowLeftCarrouselSVG from '~assets/svg/arrowLeftCarrousel';
import ArrowRightCarrouselSVG from '~assets/svg/arrowRightCarrousel';
import { useUserWalletsFunds } from '~reactQuery/queries/user/useUserWalletsFunds';

const prefCurrency = 'BRL';

const Home: React.FC = () => {
  const { width } = useWindowDimensions();
  const userOrganizationId = getSelectedUserOrganization();
  const { section } = useActionsOnModalMultiStep();

  const [cashIn, setCashIn] = useState(0);
  const [cashOut, setCashOut] = useState(0);
  const [hasBalance, setHasBalance] = useState(true);
  const [chartValue, setChartValue] = useState(0);
  const [chartDate, setChartDate] = useState('');
  const [graphArray, setGraphArray] = useState<any>([]);
  const [auxiliarData, setAuxiliarData] = useState([{ totalValue: 0, date: '' }]);
  const [hasMessage, setHasMessage] = useState(false);
  const [period, setPeriod] = useState(30); // No dia 23/10/2023, alterar para 7 dias e voltar com GraphButtonPeriod

  const { setOpenModalSideBar, setModalSidebarContent, setParams } =
    useOpenModalSideBar();

  const { data: userData, isLoading: userDataLoading } = useGetPartner();
  const { containerRef, next, prev, onScroll, showNextButton, showPrevButton } =
    useNativeCarouselScrolling();

  const { data: userFunds = 0, isLoading: loadingUserFunds } =
    useUserWalletsFunds('BRL', Number(userOrganizationId));

  const { data: walletList, isLoading: isWalletsLoading } = useGetWallets({
    prefCurrency: 'BRL',
    flag: 'list',
    userOrganizationId: Number(userOrganizationId),
  });

  const { data: variationsList } = useCurrenciesVariations({
    convert: 'BRL',
    symbol: currenciesList as CurrencyTypes[],
    period: 'today',
  });

  const { data: accountInfos, isLoading: accountInfosLoading } = useGetCapAccountInfo({
    userOrganizationId: Number(userOrganizationId),
  });

  const walletsParsed = useMemo(() => {
    if (walletList) {
      return walletList.sort((a, b) => {
        if (a.funds > b.funds) {
          return -1;
        }
        if (a.funds < b.funds) {
          return 1;
        }
        return 0;
      });
    }
    return [];
  }, [walletList]);

  const hasWallets = walletsParsed && walletsParsed?.length > 0;
  const getSections = quickActions(section);

  const handleOpenWallet = (wallet: WalletType) => {
    setModalSidebarContent('walletModal');
    setOpenModalSideBar(true);

    setParams({ ...wallet, icon: getWalletIcon(wallet.currency.isoCode) });
  };

  const getCapB = () => {
    let auxiliarCapB = '';

    // eslint-disable-next-line array-callback-return
    userData?.data?.userOrganizations?.map((org) => {
      if (org.organizations.account === accountInfos?.account) {
        auxiliarCapB = String(
          org?.organizations.walletsInternalCredentials.userBusinessId
        );
      }
    });

    return auxiliarCapB;
  };

  const CustomTooltip = ({ active, payload }: TooltipProps) => {  
    useEffect(() => {
      if (loadingUserFunds || accountInfosLoading) return;
      if(!payload) return;

      if (active) {
        setChartValue(Number(payload[0].payload.balance) || 0);
        setChartDate(payload[0].payload.date);
      } else {
        if (loadingUserFunds) {
          setChartValue(0);
        } else {
          setChartValue(parseFloat((userFunds + Number(accountInfos?.available) / 100).toFixed(2)));
        }
        setChartDate('');
      }
    }, [active, payload, loadingUserFunds, userFunds, accountInfos]);
  
    return null;
  };

  const { data: balanceHistoryList, refetch: refetchingBalanceHistory } = useGetBalanceHistory({
    from: dayjs().subtract(period, 'day').format('YYYY-MM-DD'),
    to: String(dayjs().format('YYYY-MM-DD')),
    userOrganizationId: Number(userOrganizationId),
  });

  useEffect(() => {
    refetchingBalanceHistory();
  }, [period])

  const totalIncomes = `+ ${formatCurrencyFn('BRL', String(cashIn / 100))} `;
  const totalExits = `- ${formatCurrencyFn('BRL', String(cashOut / 100))} `;

  useEffect(() => {
  if (balanceHistoryList?.length === undefined) return;

    const calcSumBalanceInBrl = () => {
      const auxiliarArray = balanceHistoryList?.sort((a, b) => {
          return a.id < b.id ? -1 : 1 
        })?.flatMap((item) => {
        return item.history.balances.map((balanceItem, position) => {
          const criptoBalance = balanceItem.walletsBalance.reduce((soma, { balanceBrl }) => {
            return soma + balanceBrl;
          }, 0);
          
          const date = item.history.dates[position];
          const { acessoBalance } = item.history.balances[position];
          
          return { totalValue: Number(parseFloat(String(criptoBalance + acessoBalance)).toFixed(2)), date };
        });
      });
  
      return auxiliarArray;
    };
  
    setAuxiliarData(calcSumBalanceInBrl());

  }, [balanceHistoryList]);

  useEffect(() => {
    const auxiliarArray: { balance: string, date: string }[] = [];
    if (loadingUserFunds || accountInfosLoading) return;
    
    auxiliarData.forEach(({ totalValue, date }) => {
      auxiliarArray.push({
        balance: String(totalValue),
        date: String(dayjs(date).format('YYYY-MM-DD HH:mm:ss')),
      });
    });

    auxiliarArray.push({
      balance: String(parseFloat((userFunds + Number(accountInfos?.available) / 100).toFixed(2))),
      date: String(dayjs().format('YYYY-MM-DD HH:mm:ss')),
    });

    setGraphArray(auxiliarArray);
  }, [auxiliarData, loadingUserFunds, accountInfosLoading]);

  return (
    <Style.HomeContainer>
      <Container paddingHorizontal={0.1} paddingVertical={0.1}>
        <div className="p-30">
          <Text.Heading1Bold>{accountInfos?.name}</Text.Heading1Bold>

          <Text.Heading3>
            CAP-B
            {getCapB()}
          </Text.Heading3>

          <Text.Heading5 marginTop={13} display="inline-block">
            Bem-vindo, &nbsp;
            <strong>{renderUserName(userData?.data)}!</strong>
            &nbsp; O que gostaria de fazer?
          </Text.Heading5>

          <QuickActions quickActions={getSections} />
           
          <When condition={hasMessage}>
            <Message
              icon={<GiftIcon className="icon" />}
              text="Chegou nossos brindes exclusivos! Preencha as informações necessárias e receba nosso presente feito especialmente para você."
              buttonText='Preencher'
              onClick={() => {
                window.open(
                  'https://brindescapitual.startquestion.com/',
                  '_blank',
                );
              }}
            />
          </When>

          <Style.MainCards>
            <If condition={hasBalance}>
              <Then>
                {/* <div className="coming-soon-message-container">
                  <div className="coming-soon-icon">
                    <LabIcon />
                  </div>

                  <Text.Heading4 marginTop={10} marginBottom={10}>
                    Cards temporariamente indisponíveis
                  </Text.Heading4>

                  <Text.Paragraph color={colors.grayMedium}>
                    Esta funcionalidade encontra-se em fase de testes.
                  </Text.Paragraph>
                </div> */}

                <div className="coming-soon">
                  <div className="total-value2">
                    <If condition={loadingUserFunds || accountInfosLoading}>
                      <Then>
                        <div className="empty-transactions">
                          <div className="container-loading">
                            <LoadingSpinner
                              color={colors.primary}
                              size={60}
                              strokeSize={25}
                            />
                          </div>
                        </div>
                      </Then>

                      <Else>
                        <>
                          <div className="text-container">
                            <div className="tip-container">
                              <div className="column">
                                <div className="wall-top" />
                                <div
                                  className={`row ${
                                    chartDate !== '' ? 'move-to-wall' : 'move-back'
                                  }`}
                                >
                                  <Text.Paragraph marginRight={5}>
                                    Saldo total aproximado
                                  </Text.Paragraph>
                                  <div className="tip-icon-container">
                                    <TipIcon />
                                    <div className="tooltip-text">
                                      <Text.Smaller color={colors.white}>
                                        Valor aproximado do saldo da sua
                                        CapConta e Carteiras convertido em sua moeda padrão (BRL).
                                      </Text.Smaller>
                                    </div>
                                  </div>
                                </div>
                                <div className="wall-bottom" />
                                <Text.Paragraph
                                  className={`chart-date-text ${
                                    chartDate !== '' && 'visible'
                                  }`}
                                >
                                  {formatDate(chartDate, 'LLL')}
                                </Text.Paragraph>
                              </div>
                            </div>
                            <div className="text-content">
                              <Text.Heading1Regular marginBottom={7}>
                                {formatCurrencyFn('BRL', String(chartValue))}
                              </Text.Heading1Regular>
                              {/* <div className="yield-container">
                                <div className="yield-icon-container">
                                  {Number((totalChartValue - chartValue).toFixed(1))
                                      > chartValue ? <YieldArrowDownIcon /> : <YieldArrowUpIcon />}
                                  <Text.SmallBold
                                    color={Number((totalChartValue - chartValue).toFixed(1))
                                      > chartValue ? colors.danger : colors.success}
                                    marginLeft={5.67}
                                  >
                                    {formatCurrencyFn(
                                      'BRL',
                                      String(Number((totalChartValue - chartValue).toFixed(1)) * 100),
                                      false,
                                      false,
                                      true,
                                    )}
                                  </Text.SmallBold>
                                </div>
                                <div className="yield-smaller-container">
                                  <Text.Smaller color={colors.grayRegular}>
                                    24h
                                  </Text.Smaller>
                                </div>
                              </div> */}
                            </div>
                          </div>

                          <ResponsiveContainer width="100%" height="65%">
                            <AreaChart
                              margin={{
                                top: 5,
                                right: 0,
                                left: 0,
                                bottom: 70,
                              }}
                              data={graphArray}
                            >
                              <defs>
                                <linearGradient
                                  id="colorbalance"
                                  x1="0"
                                  y1="0"
                                  x2="0"
                                  y2="1"
                                >
                                  <stop
                                    offset="10%"
                                    stopColor={colors.grayLight}
                                    stopOpacity={0.35}
                                  />
                                  <stop
                                    offset="90%"
                                    stopColor={colors.whiteLight}
                                    stopOpacity={0}
                                  />
                                </linearGradient>
                              </defs>
                              <Tooltip content={CustomTooltip} />
                              <YAxis
                                type="number"
                                hide
                                domain={['dataMin', 'dataMax']}
                              />
                              <Area
                                type="monotone"
                                dataKey="balance"
                                stroke={colors.grayRegular}
                                strokeWidth={2}
                                fillOpacity={1}
                                fill="url(#colorbalance)"
                              />
                            </AreaChart>
                          </ResponsiveContainer>
                          {/* <GraphButtonPeriod setPeriod={setPeriod} period={period} /> */}
                        </>
                      </Else>
                  </If>

                  {/* <Profits
                    profit="- R$ 1.298,75"
                    percentage="2,52%"
                    cashinValue={totalIncomes}
                    cashoutValue={totalExits}
                    totalValue={`${formatCurrencyFn(
                      'BRL',
                      String((cashIn + cashOut) / 100),
                    )}`}
                    period={period}
                  /> */}
                  </div>
                </div>
              </Then>

              <Else>
                <div className="empty-assets">
                  <EmptyAssets />
                </div>
              </Else>
            </If>
          </Style.MainCards>

          <Style.ScrollContainer
            className={hasWallets ? ' ' : 'empty-container'}
            marginTop={hasMessage ? 690 : 570}
          >
            <If condition={hasWallets}>
              <Then>
                <When condition={showPrevButton}>
                  <span className="prev-btn" onClick={prev}>
                    <ArrowLeftCarrouselSVG />
                  </span>
                </When>

                <div
                  className="wallets-row"
                  ref={containerRef}
                  onScroll={onScroll}
                >
                  {walletsParsed?.map((wallets) => (
                    <Style.Card key={wallets.id}>
                      <CardWallet
                        key={wallets.id}
                        profit="negative"
                        wallet={wallets}
                        onClick={() => handleOpenWallet(wallets)}
                        walletName={wallets.walletName}
                        amount={formatCurrencyFn(
                          wallets.currency.isoCode,
                          String(wallets.funds)
                        )}
                        iconWallet={getWalletIcon(wallets.currency.isoCode)}
                        currency={wallets.currency}
                        variation={Number(
                          variationsList?.data?.filter(
                            (item) =>
                              item?.isoCode === wallets.currency?.isoCode
                          )?.[0]?.variation
                        )}
                      />
                    </Style.Card>
                  ))}
                </div>
                <When condition={showNextButton}>
                  <span className="next-btn" onClick={next}>
                    <ArrowRightCarrouselSVG />
                  </span>
                </When>
              </Then>
              <Else>
                <div className="empty-wallets">
                  <If condition={isWalletsLoading}>
                    <Then>
                      <LoadingSpinner
                        size={60}
                        strokeSize={25}
                        color={colors.primary}
                      />
                    </Then>
                    <Else>
                      <EmptyWallets />
                    </Else>
                  </If>
                </div>
              </Else>
            </If>
          </Style.ScrollContainer>
        </div>

        <div className="heading mt-270 mt-mobile-45">
          <Text.Heading2 marginLeft={30}>CapConta e Carteiras</Text.Heading2>
        </div>

        <PermissionGate name="statementList">
          <TransactionsList />
        </PermissionGate>
      </Container>

      <When condition={breakpoints.tablet < width}>
        <SideBar />
      </When>
    </Style.HomeContainer>
  );
};

export default Home;
