import * as React from 'react';
import { SVGProps } from 'react';

const ArrowLeftCurveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '17'}
    height={props.height || '17'}
    viewBox="0 0 17 17"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.248 6.5061V3.5061H14.748V6.5061C14.748 8.57717 13.069 10.2561 10.998 10.2561H5.80864L8.52831 12.9758L7.46765 14.0364L3.46765 10.0364L2.93732 9.5061L3.46765 8.97577L7.46765 4.97577L8.52831 6.03643L5.80864 8.7561H10.998C12.2406 8.7561 13.248 7.74874 13.248 6.5061Z"
      fill="#272937"
    />
  </svg>
);

export default ArrowLeftCurveIcon;
