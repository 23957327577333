import { When } from 'react-if';
import { colors } from '~styles';
import * as Text from '~styles/text';
import * as Style from './ItemList.styles';
import { ItemListProps } from './ItemList.types';
import ArrowRightIcon from '~assets/images/icons/select-arrow-right.svg';
import DotsFloatingMenu from '~assets/images/icons/kyc/dots-vertical.svg';

const ItemList = ({
  label,
  icon,
  labelColor,
  arrowIcon,
  status,
  statusColor,
  listType,
  dotsOptions,
  onClick,
  noHover,
}: ItemListProps) => {
  return (
    <Style.ItemListContainer
      onClick={dotsOptions ? () => {} : onClick}
      listType={listType}
      disableHover={!!dotsOptions || !!noHover}
    >
      <div style={{ alignItems: 'center' }} className="row">
        <div className="icon-container">{icon}</div>
        <Text.Heading5 color={labelColor || colors.black}>
          {label}
        </Text.Heading5>
      </div>
      <div style={{ alignItems: 'center' }} className="display-end">
        <Text.Small color={statusColor || colors.grayMedium}>
          {status}
        </Text.Small>
        <div className="ml-25 row">
          <When condition={arrowIcon}>
            <ArrowRightIcon />
          </When>
          <When condition={dotsOptions}>
            <div onClick={onClick} className="dot-hover">
              <DotsFloatingMenu />
            </div>
          </When>
        </div>
      </div>
    </Style.ItemListContainer>
  );
};

export default ItemList;
