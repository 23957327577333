import * as yup from 'yup';

export const transferBatchBankDataSchema = yup
  .object()
  .shape({
    amount: yup
      .string()
      .test(
        'is-nonzero',
        'O valor não pode ser R$ 0,00',
        (value) => value !== 'R$ 0,00'
      )
      .required('Esse campo é obrigatório'),
    fullName: yup.string().required('Esse campo é obrigatório'),
    documentNumber: yup.string().required('Esse campo é obrigatório'),
    bank: yup.string().required('Esse campo é obrigatório'),
    bankIspb: yup.string().optional(),
    accountType: yup.string().required('Esse campo é obrigatório'),
    transferType: yup.string().required('Esse campo é obrigatório'),
    agency: yup.string().required('Esse campo é obrigatório'),
    bankAccount: yup.string().required('Esse campo é obrigatório'),
    saveForNextTransfer: yup.boolean(),
  })
  .required();