import * as Text from '~styles/text';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { PixModalStepsProps } from '../../Transfer.types';
import WalletIcon from '~assets/images/icons/16x16/wallet.svg';
import { capitalizeName } from '~helpers/format/user';
import { formatCurrencyFn } from '~helpers/format/currency';
import { getSelectedUserOrganization } from '~helpers/device';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { useFormContext } from 'react-hook-form';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import InputCurrency from '~components/Inputs/InputCurrency';

function ValueSended(props: PixModalStepsProps) {
  const { setPixStep: setCurrentStep } = props;
  const { getValues, register, watch } = useFormContext();
  const userOrganizationId = getSelectedUserOrganization();
  const formValues = getValues();

  const { data: accountInfos, isLoading: loadingCapAccount } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  const transferMethod =
    formValues?.keyResult || !formValues?.manualTransfer
      ? 'PIX'
      : 'TED ou Pix Manual';

  const nameCapitalized = capitalizeName(
    formValues?.keyResult?.resolve?.key?.holderName ||
      formValues?.name ||
      formValues?.contact?.name
  );

  const goBack = () => setCurrentStep('chooseOriginWallet');
  const goNext = () => setCurrentStep('confirmDetails');

  const valueParsed = watch('amount')
    ?.replace('R$ ', '')
    ?.replace(/\./g, '')
    ?.replace(',', '.');

  const noEnoughFunds =
    Number(accountInfos?.available) / 100 < Number(valueParsed);
  const disableNext = Number(onlyNumbers(formValues?.amount)) <= 0;

  return (
    <>
      <ModalMultiStepHeader
        title="Qual o valor a ser transferido?"
        description={`Transferindo para ${nameCapitalized} via ${transferMethod}`}
        backButtonAction={goBack}
      />

      <div className="content-container mt-30">
        <InputCurrency {...register('amount')} placeholder="R$ 0,00" />

        <div className="row align-center justify-space-between mt-10">
          <div className="row align-center">
            <WalletIcon />

            <Text.Heading5 marginTop={4} marginLeft={10}>
              Saldo disponível
            </Text.Heading5>
          </div>

          <Text.Heading5>
            {formatCurrencyFn(
              'BRL',
              accountInfos?.available,
              false,
              false,
              true
            )}
          </Text.Heading5>
        </div>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={disableNext || loadingCapAccount || noEnoughFunds}
      />
    </>
  );
}

export default ValueSended;
