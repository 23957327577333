import 'react-datepicker/dist/react-datepicker.css';
import * as Style from './FilterActivitySideBarModal.styles';
import * as Text from '~styles/text';
import {
  Dialog,
  ErrorToast,
  GenericButton,
  SuccessToast,
} from '~components/index';
import { FC, useEffect, useState } from 'react';
import Accordion from '~components/Accordion';
import CalendarSVG from '~assets/svg/calendar';
import Chip from '~components/Chip';
import CloseSVG from '~assets/svg/close';
import DatePicker from 'react-datepicker';
import RadioSelect from '~components/RadioSelect';
import Tabs from '~components/Tabs';
import colors from '~styles/colors';
import dayjs from 'dayjs';
import { getSelectedUserOrganization } from '~helpers/device';
import { mothsToFilter } from '~data/dates';
import { transparentize } from 'polished';
import DialogHeader from '~components/Dialog/DialogHeader.component';
import DialogBottom from '~components/Dialog/DialogBottom.component';
import { useScheduledStatement } from '~reactQuery/queries/bank/useScheduledStatement';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { daysAmountFromMonth } from '~helpers/format/date';
import { FilterActivitySideBarModalProps } from './FilterActivitySideBarModal.types';
import { FilterStateParams, FiltersChip, Period } from '../Activities.types';

const FilterActivitySideBarModal: FC<FilterActivitySideBarModalProps> = ({
  setParams,
  filtersChip,
}) => {
  const isSmall = window.screen.width < 500;
  const userOrganizationId = getSelectedUserOrganization();

  const { data: partnerInfos } = useGetPartner();
  const userDocumentNumber = partnerInfos?.data.documentNumber;

  // const { data: wallets, isLoading: loadingGetWallets } = useGetWallets({ -----> pertence ao filtro de carteiras
  //   userOrganizationId: Number(userOrganizationId),
  //   prefCurrency: 'BRL',
  //   flag: 'list',
  // });

  const [openDateFilter, setOpenDateFilter] = useState(true);
  // const [openWalletFilter, setOpenWalletFilter] = useState(true); -----> pertence ao filtro de carteiras
  const [useCustom, setUseCustom] = useState(false);
  const [selectedDirections, setSelectedDirections] = useState([
    filtersChip[0],
  ]);
  // const [selectedWallets, setSelectedWallets] = useState<string[]>( -----> pertence ao filtro de carteiras
  //   params?.walletsToShow || ['CapConta']
  // );

  const getFirstDayOfMonth = (year: number, month: number) => {
    return new Date(year, month, 1);
  };

  const date = new Date();
  const [startDate, setStartDate] = useState<Date | null>(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const [openTooLongModal, setOpenTooLongModal] = useState(false);
  const [confirmScheduledStatement, setConfirmScheduledStatement] =
    useState(false);

  const { data: statementGenerated } = useScheduledStatement({
    params: {
      page: 0,
      perPage: 100,
      from: dayjs(startDate).format('YYYY-MM-DD'),
      to: dayjs(endDate).format('YYYY-MM-DD'),
      userOrganizationId: Number(userOrganizationId),
      documentNumber: String(userDocumentNumber),
      restrict: false,
    },
    enabled: confirmScheduledStatement,
    callback: () => setConfirmScheduledStatement(false),
  });

  const [selectedPeriod, setSelectedPeriod] = useState<Period>({
    label: '',
    period: {
      from: '',
      to: '',
    },
  });

  const setFilterOptions = () => {
    if (
      dayjs(endDate).diff(dayjs(startDate), 'd') > 31
    ) {
      // setOpenTooLongModal(true); -----> modal de relatório que irá entrar em futuras atualizações
      return ErrorToast({
        title: 'A data não pode ultrapassar o período \n de 31 dias',
        subtitle: '',
        autoCloseEnable: true,
      });
    }

    return setParams({
      date: selectedPeriod,
      // walletsToShow: selectedWallets, -----> pertence ao filtro de carteiras
      directions: selectedDirections,
    });
  };

  const selectPeriod = (m: Period) => {
    setStartDate(null);

    if (m.label === selectedPeriod?.label) {
      setSelectedPeriod({
        label: '',
        period: {
          from: '',
          to: '',
        },
      });

      setParams((prev: FilterStateParams) => ({
        ...prev,
        date: undefined,
      }));
    } else {
      setSelectedPeriod(m);
    }
  };

  const handleClearFilter = (filter: 'period' | 'wallet') => {
    if (filter === 'period') {
      setSelectedPeriod({
        label: '',
        period: {
          from: '',
          to: '',
        },
      });
    }
    // if (filter === 'wallet') { pertence ao filtro de carteiras
    //   setSelectedWallets([]);
    // }
  };

  const selectedDirection = (title: string) => {
    return selectedDirections.find((d) => d.title === title);
  };

  const selectDirection = (filterDirection: FiltersChip) => {
    setSelectedDirections([filterDirection]);
  };

  useEffect(() => {
    if (statementGenerated) {
      SuccessToast({
        title: 'Relatório gerado com sucesso!',
        subtitle: 'Você receberá uma notificação',
        autoCloseEnable: true,
      });
    }
  }, [statementGenerated]);

  useEffect(() => {
    if (endDate && startDate) {
      setSelectedPeriod({
        label: '',
        period: {
          from: dayjs(startDate).format('YYYY-MM-DD'),
          to: dayjs(endDate).format('YYYY-MM-DD'),
        },
      });
    }
  }, [endDate, startDate, useCustom]);

  const disableSearchButton =
    // selectedWallets.length === 0 || -----> pertence ao filtro de carteiras
    selectedDirections.length === 0 ||
    (selectedPeriod?.label === '' && (!startDate || !endDate));

  // const closeWalletList = () => {  -------------------------------> pertence ao filtro de carteiras
  //   setSelectedWallets([]);
  //   setParams((prev) => ({ ...prev, walletsToShow: undefined }));
  // };

  // const selectWallets = (address: string) => {
  //   if (selectedWallets.includes(address)) {
  //     setSelectedWallets((prev) => {
  //       if ([...prev.filter((prevv) => prevv !== address)].length === 0) {
  //         setParams((previous: any) => ({
  //           ...previous,
  //           walletsToShow: undefined,
  //         }));
  //       }

  //       return [...prev.filter((prevv) => prevv !== address)];
  //     });
  //   } else {
  //     setSelectedWallets((prev) => [...prev, address]);
  //   }
  // };

  return (
    <>
      <Style.Container>
        <Text.Heading2>Filtros</Text.Heading2>
        <div className="chips-list">
          {filtersChip.map((filter) => {
            return (
              <Chip
                key={filter.title}
                onClick={() => selectDirection(filter)}
                textComponent={
                  <Text.Small
                    color={
                      selectedDirection(filter.title)
                        ? colors.white
                        : colors.black
                    }
                  >
                    {filter.title}
                  </Text.Small>
                }
                bgColor={
                  selectedDirection(filter.title) ? colors.black : colors.white
                }
                borderColor={transparentize(0.85, colors.grayMedium)}
                height={31}
              />
            );
          })}
        </div>
        <Accordion
          leftIcon={<CalendarSVG stroke={colors.primary} />}
          subtitle="Período"
          isOpen={openDateFilter}
          paddingVertical={0.1}
          onClick={() => setOpenDateFilter((prev) => !prev)}
          rightComponent={
            selectedPeriod?.period?.from ? (
              <Chip
                bgColor={transparentize(0.95, colors.primary)}
                textComponent={
                  <Text.Small
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    color={colors.primary}
                  >
                    {dayjs(selectedPeriod?.period?.from).format('DD MMM')} -{' '}
                    {dayjs(selectedPeriod?.period?.to).format('DD MMM')}
                  </Text.Small>
                }
                rightIcon={
                  <CloseSVG
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClearFilter('period');
                    }}
                  />
                }
                height={31}
              />
            ) : null
          }
        >
          <div className="container-accordion-column">
            <Tabs
              width="100%"
              borderBottom
              selectedTab="Por Mês"
              style={{ padding: 0 }}
              tabs={[
                {
                  tabName: 'Por Mês',
                  tabContent: (
                    <div className="content-wrapper">
                      {mothsToFilter?.map((m, index) => {
                        const currentMonth = dayjs().month();

                        if (currentMonth === index) {
                          return (
                            <div className="item" key={dayjs().format('MM')}>
                              <RadioSelect
                                title={dayjs().format('MMMM')}
                                subtitle={dayjs().format('[até] DD [de] MMM')}
                                icon={<CalendarSVG stroke={colors.grayLight} />}
                                onClick={() => selectPeriod(m)}
                                checked={selectedPeriod?.label === m.label}
                                width="100%"
                              />
                            </div>
                          );
                        }

                        if (currentMonth < index) return null;

                        return (
                          <div className="item" key={m.label}>
                            <RadioSelect
                              title={m.label}
                              subtitle={String(dayjs().year())}
                              icon={<CalendarSVG stroke={colors.grayLight} />}
                              onClick={() => selectPeriod(m)}
                              checked={selectedPeriod?.label === m.label}
                              width="100%"
                            />
                          </div>
                        );
                      })}
                    </div>
                  ),
                },
                {
                  tabName: 'Personalizado',
                  tabAction: () => {
                    setUseCustom((prev) => !prev);
                  },
                  tabContent: (
                    <div className="container-picker">
                      <Text.Paragraph
                        marginBottom={7}
                        color={colors.grayMedium}
                      >
                        Data Inicial
                      </Text.Paragraph>
                      <DatePicker
                        selected={startDate}
                        onChange={(event) => setStartDate(event)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Digite uma data inicial"
                        maxDate={endDate}
                      />
                      <Text.Paragraph
                        color={colors.grayMedium}
                        marginBottom={7}
                        marginTop={20}
                      >
                        Data Final
                      </Text.Paragraph>
                      <DatePicker
                        selected={endDate}
                        onChange={(event) => setEndDate(event)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </Accordion>

        {/* <Accordion
          leftIcon={<BlueWalletSVG />}
          subtitle="Conta e Carteiras"
          isOpen={openWalletFilter}
          paddingVertical={0.1}
          onClick={() => setOpenWalletFilter((prev) => !prev)}
          rightComponent={
            selectedWallets.length ? (
              <Chip
                bgColor={transparentize(0.95, colors.primary)}
                textComponent={
                  <Text.Small
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    color={colors.primary}
                  >
                    {selectedWallets.length} Seleci...
                  </Text.Small>
                }
                rightIcon={
                  <CloseSVG
                    onClick={(e) => {
                      e.stopPropagation();
                      closeWalletList();
                    }}
                  />
                }
                height={31}
              />
            ) : null
          }
        >
          <div className="margin-top-20" />
          <div
            onClick={() => selectWallets('CapConta')}
            className="container-wallet-list"
          >
            <CheckSelectSmall
              title="CapConta"
              text="BRL"
              iconType="bankIcon"
              selected={false}
              onClick={() => {}}
              icon={<CapAccountSVG />}
            />
            <CheckBox
              checked={selectedWallets.includes('CapConta')}
              onClick={() => {}}
            />
          </div>
          <When condition={!loadingGetWallets}>
            {wallets?.map((w) => {
              return (
                <div
                  onClick={() =>
                    w.walletAddress !== 'CAP_CONTA'
                      ? {}
                      : selectWallets(w.walletAddress)
                  }
                  className="container-wallet-list"
                  key={w.walletAddress}
                >
                  <CheckSelectSmall
                    key={w.walletAddress}
                    title={w.walletName}
                    text={w.currency.isoCode}
                    iconType="bankIcon"
                    selected={false}
                    onClick={() => {}}
                    icon={
                      w.walletAddress === 'CAP_CONTA' ? (
                        <CapAccountSVG />
                      ) : (
                        getWalletIcon(w.currency.isoCode)
                      )
                    }
                    disabled={w.walletAddress !== 'CAP_CONTA'}
                    rightTextDisabled
                  />
                  <CheckBox
                    checked={selectedWallets.includes(w.walletAddress)}
                    onClick={() => {}}
                    disabled={w.walletAddress !== 'CAP_CONTA'}
                  />
                </div>
              );
            })}
          </When>
        </Accordion> */}
        {openTooLongModal && (
          <Dialog openModal setOpenModal={setOpenTooLongModal}>
            <DialogHeader title="Período selecionado muito longo" />
            <div className="tooLongPeriodContent">
              <Text.Heading5>
                O período selecionado pode estar muito extenso, o que pode
                tornar difícil para o nosso sistema carregar rapidamente todas
                as informações desse intervalo. Para resolver isso, oferecemos
                duas opções:
              </Text.Heading5>
              <Text.Heading5>
                1. Reduzir o período selecionado para aproximadamente um mês, o
                que permitirá que as informações sejam carregadas mais
                rapidamente.
              </Text.Heading5>

              <Text.Heading5>
                {`2. Se você deseja manter o período selecionado, pode clicar no
                botão "Gerar relatório completo" e aguardar a notificação no
                topo da página enquanto o nosso sistema trabalha para gerar as
                informações necessárias.`}
              </Text.Heading5>
            </div>

            <DialogBottom
              doubleButtonTextOne="Alterar Período"
              doubleButtonTextTwo="Gerar relatório completo"
              doubleButtonOneAction={() => setOpenTooLongModal(false)}
              doubleButtonTwoAction={() => {
                setConfirmScheduledStatement(true);
                setOpenTooLongModal(false);
              }}
            />
          </Dialog>
        )}
      </Style.Container>
      <Style.FixedBottom isMobile={isSmall}>
        <GenericButton
          disabled={disableSearchButton}
          onClick={setFilterOptions}
          width="100%"
          buttonType="secondary"
        >
          <Text.Heading5 color={colors.white}>Atualizar filtros</Text.Heading5>
        </GenericButton>
      </Style.FixedBottom>
    </>
  );
};

export default FilterActivitySideBarModal;
