import { colors } from '~styles';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  width: 85px;
  height: 100%;
  background-color: ${colors.white};
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 18px 10px;
  border-right: 1px solid ${transparentize(0.92, colors.black)};
  box-shadow: 6px 0px 10px rgba(0, 0, 0, 0.02);

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      cursor: pointer;
    }
  }
`;

export const ListMenu = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin-top: 42px;

  li {
    position: relative;
    width: 65px;
    height: 50px;
    padding: 13px 20px;
    background-color: ${colors.transparent};
    border-radius: 10px;
    margin: 5px 0;
    list-style: none;
    transition: all ease-out 0.3s;
    cursor: pointer;

    :hover {
      background-color: ${colors.whiteLight};
      transition: all ease-out 0.3s;

      a {
        svg {
          margin-top: -1px;
          transition: all ease-out 0.3s;
        }
      }
    }

    &.active {
      background-color: ${transparentize(0.9, colors.primary)};

      a {
        svg {
          color: ${colors.primary};
        }
      }
    }

    .soon {
      color: ${transparentize(0.7, colors.black)};
    }

    .tooltip-text {
      visibility: hidden;
      transition: all ease-out 0.3s;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: ${transparentize(0.15, colors.black)};
      border-radius: 10px;
      padding: 11px 13px;

      position: absolute;
      top: 8px;
      left: 59px;
      z-index: 99999999999;

      opacity: 0;

      &.w-100 {
        width: 100px;
      }

      &.w-190 {
        width: 190px;
      }
    }

    :hover {
      .tooltip-text {
        visibility: visible;
        left: 67px;
        opacity: 1;
        transition: all ease-out 0.3s;
      }
    }
  }

  @media (max-height: 600px) {
    li {
      width: 65px;
      height: 40px;
      padding: 8px 20px;
    }
  }
`;

export const MobileContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  position: absolute;
  left: -100%;
  top: 0;
  z-index: 9999999999999999;
  overflow-y: scroll;
  transition: all ease-out 0.3s;

  &.active {
    left: 0;
    transition: all ease-out 0.3s;
  }

  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 16px;
    height: 85px;

    .logo-container {

    }

    .close {
      padding: 8px;
    }
  }
`;

export const ListMobileMenu = styled.ul`  
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(39, 41, 55, 0.10);

  &.no-border-bottom {
    border-bottom: none;
  }

  .list-menu-bottom {
    padding: 13px 21px;
    margin: 0;

    &:first-child {
      margin-top: 20px;
    }
  }

  .list-menu-bottom.logout {
    svg {
      color: ${colors.danger};
    }
  }

  li {
    padding: 16px 21px;
    background-color: ${colors.transparent};
    border-radius: 10px;
    margin: 10px 0;
    list-style: none;

    &:first-child {
      margin-top: 0px;
    }

    &.active {
      a {
        svg {
          color: ${colors.primary};
        }
      }
    }

    a {
      display: flex;
      flex-direction: row;
      align-items: center;

      .soon {
        color: ${transparentize(0.7, colors.black)};
      }

      svg {
        color: ${colors.black};
        margin-right: 15px;

        .soon {
          color: ${transparentize(0.7, colors.black)};
        }
      }
    }
  }
`;

