import * as Style from '../SignTemplate.styles';
import * as yup from 'yup';
import { ContentWrapper, ScrollContainer } from '../SignTemplate.styles';
import {
  InformCPF,
  QuestionsAlertMsg,
  RegisterNewUser,
  UserHasNoAccount,
} from './Steps';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Steps } from './SignUp.types';
import { When } from 'react-if';
import useWindowDimensions from '~hooks/windowDimension';
import { FormProvider, useForm } from 'react-hook-form';
import { validateCPF } from '~validations/personalValidation';
import { yupResolver } from '@hookform/resolvers/yup';

export const SingUp = () => {
  const { width, height } = useWindowDimensions();
  const { search } = useLocation();

  const parsedToken = search.substring(5);

  const [step, setStep] = useState<{ stepPosition: Steps; params?: object }>({
    stepPosition: parsedToken ? 'registerNewUser' : 'start',
    params: {},
  });

  const goToStep = useCallback((selectedStep: Steps, params?: object) => {
    setStep((prev) => ({ stepPosition: selectedStep, params: { ...params } }));
  }, []);

  const stepsStatusProgressBar = {
    start: 0,
    questionsAlertMsg: 1,
    registerNewUser: 75,
    userHasNoAccount: 75,
  };

  const schema = yup.object({
    email: yup.string().email('Email inválido').required('Email obrigatório'),
    cpf: yup
      .string()
      .test('cpf', 'CPF inválido', (value) => {
        if (value) {
          return validateCPF(value);
        }
        return true;
      })
      .required('CPF obrigatório'),
  });

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      cpf: '',
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const modalSteps = {
    start: {
      component: <InformCPF goToStep={goToStep} />,
    },
    questionsAlertMsg: {
      component: <QuestionsAlertMsg />,
    },
    userHasNoAccount: {
      component: <UserHasNoAccount />,
    },
    registerNewUser: {
      component: <RegisterNewUser token={parsedToken} />,
    },
  };

  const isNotThisSteps = !(
    step.stepPosition === 'questionsAlertMsg' ||
    step.stepPosition === 'userHasNoAccount'
  );

  const customLayout = step.stepPosition === 'registerNewUser';

  return (
    <ScrollContainer
      marginTop={customLayout ? 55 : 0}
      justifyContent={customLayout ? 'flex-start' : 'center'}
    >
      <ContentWrapper screenWidth={width} screenHeight={height}>
        <FormProvider {...methods}>
          {modalSteps[step.stepPosition].component}
        </FormProvider>

        <When condition={isNotThisSteps}>
          <Style.ProgressContainer>
            <div
              className="bar"
              style={{
                width: `${stepsStatusProgressBar[step.stepPosition] || 1}%`,
              }}
            />
          </Style.ProgressContainer>
        </When>
      </ContentWrapper>
    </ScrollContainer>
  );
};

export default SingUp;
