import React, { SVGProps } from 'react';

const LockIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '12'}
      height={props.height || '12'}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 4C3.75 2.75736 4.75736 1.75 6 1.75C7.24264 1.75 8.25 2.75736 8.25 4V5H3.75V4ZM2.25 5H1C0.447715 5 0 5.44772 0 6V11C0 11.5523 0.447715 12 1 12H11C11.5523 12 12 11.5523 12 11V6C12 5.44772 11.5523 5 11 5H9.75V4C9.75 1.92893 8.07107 0.25 6 0.25C3.92893 0.25 2.25 1.92893 2.25 4V5ZM1.5 10.5V6.5H10.5V10.5H1.5Z"
        fill={props.stroke || '#272937'}
      />
    </svg>
  );
};

export default LockIcon;
