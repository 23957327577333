import styled, { css } from 'styled-components';

import { GeneralStyleProps } from 'src/@types/general';
import { colors } from '~styles';

interface OthersProps {
  disabledCursor?: boolean;
}

export const ProfileButton = styled.div<GeneralStyleProps & OthersProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ disabledCursor }) =>
    disabledCursor
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}

  width: ${(props) => props.width || 36}px;
  height: ${(props) => props.height || 36}px;
  background-color: ${(props) => props.backgroundColor || colors.primary};
  border-radius: ${(props) => props.borderRadius || 200}px;
  margin-right: ${(props) => props.marginRight || 0}px;
  margin-left: ${(props) => props.marginLeft || 0}px;

  .avatar {
    width: ${(props) => props.width || 36}px;
    height: ${(props) => props.height || 36}px;
    object-fit: cover;
    border-radius: 50%;
  }
`;
