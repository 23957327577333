import * as React from 'react';
import { SVGProps } from 'react';

const ArrowRightCarrouselSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={32} fill="none" {...props}>
    <path
      stroke="#757893"
      strokeLinecap="round"
      strokeWidth={2}
      d="m1 1 15 15L1 31"
    />
  </svg>
);
export default ArrowRightCarrouselSVG;
