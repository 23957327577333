import { GeneralStyleProps } from 'src/@types/general';
import { colors } from '~styles';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div<GeneralStyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 15px 20px;
  min-width: 210px;
  width: ${(props) => props.width || '210px'};
  height: 210px;
  border: 1px solid ${transparentize(0.9, colors.black)};
  background-color: ${colors.white};
  border-radius: 10px;
  user-select: none;

  cursor: pointer;

  &.active {
    border: 1px solid ${transparentize(0.7, colors.primary)};
    background-color: ${transparentize(0.95, colors.primary)};
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    margin-bottom: 50px;

    .scale-icon {
      transform: scale(0.8);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .tip-icon-container {
      align-items: center;
      cursor: pointer;

      width: 20px;
      height: 20px;

      .tooltip-text {
        visibility: hidden;
      }

      :hover {
        .tooltip-text {
          visibility: visible;
          opacity: 1;
          transition: all ease-out 0.3s;
          position: absolute;
          z-index: 99;

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 9px 10px 10px;
          gap: 10px;

          background: rgba(39, 41, 55, 0.85);
          border-radius: 6px;
        }
      }
    }
  }

  .profit-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }
`;
