import * as Style from './Dialog.styles';
import * as Text from '~styles/text';

import { DialogHeaderProps } from './Dialog.types';
import GoBackModal from '~assets/images/icons/go-back-modal.svg';
import React from 'react';
import colors from '~styles/colors';

function DialogHeader(props: DialogHeaderProps) {
  const { backButtonAction, aboveTitleText, title, description } = props;
  return (
    <Style.HeaderContainer>
      {backButtonAction ? (
        <div>
          <GoBackModal onClick={backButtonAction} />
        </div>
      ) : null}
      <Text.Heading5 marginTop={10} color={colors.grayMedium}>
        {aboveTitleText}
      </Text.Heading5>
      <Text.Heading2 marginTop={7} marginBottom={13}>
        {title}
      </Text.Heading2>
      <Text.Heading5>{description}</Text.Heading5>
    </Style.HeaderContainer>
  );
}

export default DialogHeader;
