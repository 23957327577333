/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import BankIcon from '~assets/images/icons/bank.svg';
import BlueIconFiled from '~assets/images/icons/kyc/blue-check-full-rounded.svg';
import LoadingSpinner from '~components/LoadingSpinner';
import { colors } from '~styles';
import * as Text from '~styles/text';
import { forwardRef } from 'react';
import { If, Then, Else, When } from 'react-if';
import { RoundButtonIcon } from '~components/Buttons';
import * as Style from './CheckSelectSmall.styles';
import { CheckSelectProps } from './CheckSelectSmall.types';

const CheckSelectSmall = forwardRef((props: CheckSelectProps, _ref) => {
  const textColor = () => {
    if (props.disabled || props.isLoading) {
      return colors.grayLight;
    }
    return colors.grayMedium;
  };

  const titleColor = () => {
    if (props.disabled || props.isLoading) {
      return colors.grayLight;
    }
    return colors.black;
  };
  return (
    <Style.Container
      onClick={props.disabled ? () => {} : props.onClick}
      disabled={props.disabled}
    >
      <Style.ContainerIcon
        disabled={props.disabled}
        isLoading={props.isLoading}
      >
        <RoundButtonIcon
          buttonSize="iconWallet"
          selected={props.selected || props.isLoading}
        >
          <div className="scale-icon">{props.icon}</div>
        </RoundButtonIcon>
      </Style.ContainerIcon>
      <Style.ContainerText>
        <div className="text-wrapper">
          <Text.Heading5 color={titleColor()}>{props.title}</Text.Heading5>
          <Text.Small color={textColor()} marginTop={1}>
            {props.text}
          </Text.Small>
        </div>
      </Style.ContainerText>
      {!props.rightTextDisabled && (
        <div className="right-text">
          <When condition={props.isLoading}>
            <LoadingSpinner color={colors.black} />
          </When>

          <When condition={props.disabled}>
            <Text.Paragraph color={colors.grayRegular}>
              Inabilitado
            </Text.Paragraph>
          </When>

          <When condition={props.selected}>
            <Text.Paragraph color={colors.newBlue}>Selecionada</Text.Paragraph>
          </When>
        </div>
      )}
    </Style.Container>
  );
});

CheckSelectSmall.displayName = 'CheckSelect';

export default CheckSelectSmall;
