import { PIX_QUERY_KEYS } from '~src/constants/pix';
import { gql, request, useQuery } from '~reactQuery/index';

interface GetPixKeyInput {
  userOrganizationId: number;
  documentNumber: string | undefined;
}

interface PixKey {
  id: number;
  type: 'CPF' | 'CNPJ' | 'EVP';
  value: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  userOrganizationId: number;
}

interface GetPixKeyResponse {
  getPixKey: {
    data: PixKey[];
    messages: string[];
    isValid: boolean;
  };
}

export const useGetPixKey = (params: GetPixKeyInput) => {
  const QUERY = gql`
    query GetPixKey($params: GetPixKeyInput!) {
      getPixKey(params: $params) {
        data {
          id
          type
          value
          createdAt
          updatedAt
          deletedAt
          userOrganizationId
        }
        messages
        isValid
      }
    }
  `;

  const fetcher = async () => {
    if (!params.documentNumber) return null;

    const response = await request<GetPixKeyResponse>(QUERY, { params });

    return response.getPixKey.data;
  };

  return useQuery([PIX_QUERY_KEYS.GET_PIX_KEY], fetcher, {
    cacheTime: 0,
    staleTime: 0,
  });
};
