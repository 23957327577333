import EmptyTransactionsIcon from '~assets/images/icons/empty/no-transaction.svg';
import { colors } from '~styles';
import * as Text from '~styles/text';
import { useNavigate } from 'react-router-dom';
import * as Style from './EmptyTransactions.styles';
import { EmptyTransactionsProps } from './EmptyTransactions.types';
import { APP_ROUTES } from '~data/navigation';

const EmptyTransactions: React.FC<EmptyTransactionsProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Style.Container>
      <EmptyTransactionsIcon />

      <Text.Heading2Bold
        color={colors.grayRegular}
        marginTop={18}
        textAlign="center"
      >
        Nenhuma movimentação
      </Text.Heading2Bold>
      <Text.Paragraph
        color={colors.grayLight}
        marginTop={14}
        textAlign="center"
      >
        Suas movimentações serão listadas aqui. Você ainda não fez nenhuma.
      </Text.Paragraph>

      {!props.noTransactionButton && (
        <a
          className="outline-button"
          onClick={() => navigate(APP_ROUTES.ACTIVITIES)}
        >
          <Text.ParagraphBold color={colors.primary}>
            Todas as Movimentações
          </Text.ParagraphBold>
        </a>
      )}
    </Style.Container>
  );
};

export default EmptyTransactions;
