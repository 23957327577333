import * as Style from '../PartnersSteps.styles';
import * as Text from '~styles/text';

import { BigInput, GenericButton } from '~components';

import ArrowBackIcon from '~assets/images/icons/kyc/arrow-back.svg';
import { BusinessPartnerCnpjProps } from '../../Kyc.types';
import { cnpjMask } from '~helpers/format/masks';
import { colors } from '~styles';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { usePartnersList } from '../../store';
import { useTranslation } from 'react-i18next';
import { validaCNPJ } from '~validations/personalValidation';

export const BusinessPartnerCnpj = ({
  gotToModalStep,
  partnerList,
}: BusinessPartnerCnpjProps) => {
  const { t } = useTranslation();
  const { field } = usePartnersList();
  const {
    register,
    getValues,
    setError,
    formState: { errors },
  } = partnerList;

  const fieldToEdit = Array.isArray(field) ? field[1] : field;

  const getIndex = onlyNumbers(String(fieldToEdit));

  const errorIncase =
    errors?.allPartners?.[getIndex?.[0]]?.list?.[getIndex?.[1]]?.documentNumber;

  const bizName = getValues(`${fieldToEdit}.businessName`);

  const allPartners = getValues('allPartners');

  const nextStep = () => {
    const bizDocNum = getValues(`${fieldToEdit}.documentNumber`);

    const hasSameDocumentNumber = allPartners?.[1]?.list?.filter(
      (p: any) => onlyNumbers(p.documentNumber) === onlyNumbers(bizDocNum)
    );

    if (hasSameDocumentNumber?.length > 1) {
      setError(`${fieldToEdit}.documentNumber`, {
        type: 'pattern',
        message: 'Parceiro já cadastrado',
      });
    } else if (validaCNPJ(bizDocNum)) {
      gotToModalStep('registerPartnerTypeBusiness');
    } else {
      setError(`${fieldToEdit}.documentNumber`, {
        type: 'required',
        message: 'CNPJ Inválido',
      });
    }
  };

  return (
    <Style.Container>
      <div style={{ padding: 50 }}>
        <div
          className="back-button"
          onClick={() => gotToModalStep('businessPartnerName')}
        >
          <ArrowBackIcon />
        </div>

        <Text.Heading5 color={colors.grayMedium} marginTop={30}>
          {t('Adicionar Sócio')}
        </Text.Heading5>

        <Text.Heading2Bold marginTop={10} marginBottom={30}>
          Digite o CNPJ da empresa sócia
        </Text.Heading2Bold>

        <BigInput
          {...register(`${fieldToEdit}.documentNumber`)}
          maskFunction={cnpjMask}
          textAlign="center"
          placeHolder="000.000.000/0000-00"
          autoFocus
          error={errorIncase?.message}
        />

        <Text.Heading5 marginTop={30} lineHeight={140}>
          Você está adicionando {bizName} como um dos sócios da sua empresa.
        </Text.Heading5>
      </div>

      <div className="footer">
        <div className="footer-btn">
          <GenericButton buttonType="secondary" width="100%" onClick={nextStep}>
            Próximo
          </GenericButton>
        </div>
      </div>
    </Style.Container>
  );
};
