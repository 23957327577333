import { gql, request, useMutation } from '~reactQuery';

interface FirstLoginInput {
  email: string;
  newPasswd: string;
  sessionKey: string;
  pin: string;
}

interface FirstLoginResponse {
  signUpFirstLogin: {
    data: {
      id: number;
      capu: string;
      registerName: string;
      socialName: string;
      phone: string;
      email: string;
      documentNumber: string;
      deletedAt: string;
      createdAt: string;
      updatedAt: string;
      active: boolean;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useFirstLogin() {
  const mutation = gql`
    mutation SignUpFirstLogin($params: SignUpFirstLoginInput!) {
      signUpFirstLogin(params: $params) {
        data {
          id
          capu
          registerName
          socialName
          phone
          email
          documentNumber
          deletedAt
          createdAt
          updatedAt
          active
        }
        isValid
        messages
      }
    }
  `;

  const fetch = async (params: FirstLoginInput) => {
    const response = await request<FirstLoginResponse>(mutation, {
      params,
    });
    return response.signUpFirstLogin;
  };

  return useMutation('useFirstLogin', fetch);
}
