import * as React from 'react';
import { SVGProps } from 'react';

const SpreeadsheetSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '13'}
    height={props.height || '17'}
    viewBox="0 0 13 17"
    fill="none"
  >
    <path
      d="M2.0032 0.00799561C1.4001 0.00799561 0.911194 0.496902 0.911194 1.1V14.932C0.911194 15.5351 1.4001 16.024 2.0032 16.024H11.4672C12.0703 16.024 12.5592 15.5351 12.5592 14.932V4.37601L8.19121 0.00799561H2.0032Z"
      fill="#20A464"
    />
    <path
      d="M2.0032 16.0241C1.4001 16.0241 0.911194 15.5352 0.911194 14.9321V14.7501C0.911194 15.3532 1.4001 15.8421 2.0032 15.8421H11.4672C12.0703 15.8421 12.5592 15.3532 12.5592 14.7501V14.9321C12.5592 15.5352 12.0703 16.0241 11.4672 16.0241H2.0032Z"
      fill="#149456"
    />
    <path
      d="M2.0032 0.00799561C1.4001 0.00799561 0.911194 0.496902 0.911194 1.1V1.282C0.911194 0.678902 1.4001 0.189996 2.0032 0.189996H8.37321L8.19121 0.00799561H2.0032Z"
      fill="#38AE74"
    />
    <path
      d="M12.5589 8.10696V4.37595L11.9219 3.73895H8.84999C8.60678 3.73895 8.48497 4.03301 8.65695 4.20499L12.5589 8.10696Z"
      fill="url(#paint0_linear_17512_5355)"
    />
    <path
      d="M8.19107 0.00799561L12.5591 4.37601H9.28307C8.67998 4.37601 8.19107 3.8871 8.19107 3.284V0.00799561Z"
      fill="#8ED1B1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.64744 7.83398V13.112H3.82343V7.83398H9.64744ZM6.37143 8.56199H4.55143V9.47199H6.37143V8.56199ZM7.09943 9.47199V8.56199H8.91944V9.47199H7.09943ZM6.37143 10.018H4.55143V10.928H6.37143V10.018ZM7.09943 10.928V10.018H8.91944V10.928H7.09943ZM6.37143 11.474H4.55143V12.384H6.37143V11.474ZM7.09943 12.384V11.474H8.91944V12.384H7.09943Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_17512_5355"
        x1="10.5677"
        y1="8.10696"
        x2="10.5677"
        y2="3.64795"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#20A464" />
        <stop offset="1" stopColor="#207E55" />
      </linearGradient>
    </defs>
  </svg>
);

export default SpreeadsheetSvg;
