import * as Style from '../SearchRecipient.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import { InputForm, InputSwitch } from '~components/Inputs';
import { UserPic } from '~components/index';
import { capitalizeInitialsAndRemoveNumbers } from '~helpers/format/capitalize';
import ArrowRightSimpleIcon from '~assets/svg/arrowRightSimple';
import { ListOfBeneficiariesProps } from '../SearchRecipient.types';
import { Else, If, Then } from 'react-if';
import { ChangeEvent } from 'react';

const ListOfBeneficiaries = (props: ListOfBeneficiariesProps) => {
  const {
    handleNext,
    listOfBeneficiaries,
    newList,
    setNewList,
    nameNewList,
    setNameNewList,
  } = props;
  const handleTranslatorKeyType = (value: string) => {
    const translatorKeyType: { [key: string]: string } = {
      PHONE: 'Pix',
      CPF: 'Pix',
      CNPJ: 'Pix',
      EMAIL: 'Pix',
      EVP: 'Pix',
    };

    return translatorKeyType[value] ? translatorKeyType[value] : value;
  };

  return (
    <Style.ContainerListOfBeneficiaries>
      <div className="container-title-list-of-beneficiaries">
        <If condition={newList}>
          <Then>
            <InputForm
              placeholder="Nomear lista"
              value={nameNewList}
              style={{ padding: 0, margin: 0, width: '100%', maxWidth: 260 }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setNameNewList(e.target.value)
              }
            />
          </Then>
          <Else>
            <Text.Heading4Bold>
              Lista de beneficiários ({listOfBeneficiaries?.length}/20)
            </Text.Heading4Bold>
          </Else>
        </If>

        <div className="container-switch">
          <Text.Paragraph>Criar nova lista?</Text.Paragraph>
          <InputSwitch
            className="input-switch"
            checked={newList}
            onChange={() => setNewList(!newList)}
          />
        </div>
      </div>
      {listOfBeneficiaries?.map((item, index) => {
        const pixInfo = item?.keyValue
          ? {
              e2eId: item?.e2eId,
              accountType: item?.accountType,
              holderDocument: item?.holderDocument,
              holderName: item?.holderName,
              keyType: item?.keyType,
              keyValue: item?.keyValue,
              name: item?.fullName,
              fullName: item?.fullName,
            }
          : null;
        const key = `${item?.fullName}-${item?.keyType}-${index}`;

        const titleLineThrough = item?.error
          ? { textDecorationLine: 'line-through' }
          : {};
        const colorDescription = item?.error
          ? colors.redMatte
          : colors.grayMedium;
        const hasErrorMessage = item?.error && (
          <>
            Destinatário não identificado
          </>
        );
        return (
          <Style.Row
            key={key}
            onClick={() => handleNext(item, pixInfo, index)}
            style={{ padding: '13px 0px' }}
          >
            <UserPic name={item?.fullName} borderRadius={40} />
            <div className="description">
              <Text.Heading5 style={titleLineThrough}>
                {capitalizeInitialsAndRemoveNumbers(item?.fullName || '') ||
                  item?.keyValue}
              </Text.Heading5>
              <Text.Small color={colorDescription}>
                {hasErrorMessage ||
                  handleTranslatorKeyType(item?.transferType) ||
                  handleTranslatorKeyType(item?.keyType)}
              </Text.Small>
            </div>
            <div className="last-item">
              <Text.Heading5 style={titleLineThrough}>
                {item?.amount}
              </Text.Heading5>
              <ArrowRightSimpleIcon stroke={colors.naturalBlack} />
            </div>
          </Style.Row>
        );
      })}
    </Style.ContainerListOfBeneficiaries>
  );
};

export default ListOfBeneficiaries;
