export const getBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function moveArrayPosition(from: number, to: number, arr: []) {
  const newArr = [...arr];
  const item = newArr.splice(from, 1)[0];
  newArr.splice(to, 0, item);
  return newArr;
}

export const formatFileName = (name: any, valueToFormat: any) => {
  if (typeof valueToFormat === 'string' && name) {
    return `${name}.${valueToFormat.split('.').pop()}`;
  }
  if (typeof valueToFormat?.[0]?.name === 'string' && name) {
    return `${name}.${valueToFormat?.[0]?.name?.split('.').pop()}`;
  }
  return '';
};

export const splitByLastDot = function (text: string) {
  const index = text.lastIndexOf('.');
  return text.slice(0, index);
};


export const formatMonetaryAttachFile=(inputString: string)=> {
  if (inputString.includes('"')) {
    return inputString;
  }
  const regex = /R\$ ([0-9]+,[0-9]+)/g;

  const outputString = inputString.replace(regex, '"R$ $1"');

  return outputString;
}