import { gql, request, useQuery } from '~reactQuery';

import { CurrencyTypes } from 'src/@types/currency.types';

export interface Response {
  currencyEstimateUserFunds: number;
}

export const useUserWalletsFunds = (
  inCurrency: CurrencyTypes,
  userOrganizationId: number
) => {
  const QUERY = gql`
    query Query($params: CurrencyEstimateUserFundsInput!) {
      currencyEstimateUserFunds(params: $params)
    }
  `;

  const fetcher = async () => {
    const { currencyEstimateUserFunds } = await request<Response>(QUERY, {
      params: { inCurrency, userOrganizationId },
    });
    return currencyEstimateUserFunds;
  };

  return useQuery(['UserFunds'], fetcher, {
    refetchInterval: 60000,
  });
};
