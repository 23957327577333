import { colors } from '~styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .receipt-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 25px;
  }

  .content-container {
    padding: 0 35px;

    .insert-qr-code {
      margin-top: 35px;
      display: inline-block;
      width: 100%;
      padding: 120px 0 0 0;
      height: 250px;
      overflow: hidden;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      background: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!-- Uploaded to: SVG Repo  www.svgrepo.com  Generator: SVG Repo Mixer Tools --%3E%3Csvg fill='%23000000' width='800px' height='800px' viewBox='0 0 512 512' data-name='Layer 1' id='Layer_1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M173.73 159.06l-21.39-21.4L256 34 359.66 137.66l-21.39 21.4L271.13 91.92V408.67H240.87V91.92ZM445.4 351.31v93a3.6 3.6 0 0 1-3.47 3.46H69.66a3.29 3.29 0 0 1-3.06-3.46v-93H36.34v93A33.56 33.56 0 0 0 69.66 478H441.93a33.76 33.76 0 0 0 33.73-33.72v-93Z'/%3E%3C/svg%3E")
        center center no-repeat #e4e4e4;
      background-color: ${colors.gray};
      border-radius: 20px;
      background-size: 60px 60px;

      color: transparent;

      ::-webkit-file-upload-button {
        display: none;
      }

      ::file-selector-button {
        display: none;
      }
    }
  }

  .big-input {
    border-color: ${colors.grayRegular};
    border-width: 1px;
    background-color: ${colors.white};

    margin-top: 25px;
    height: 100%;

    &.pix-code {
      font-size: 17px !important;
    }
  }

  .double-button-one-container {
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
  }

  .payment-status {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .wallet-icon {
    transform: scale(0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pin-container {
    margin-top: 35px;
    .container-loading {
      width: 100%;
      padding-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .receipt-link {
    display: flex;
    align-items: center;
    margin-top: 25px;
    color: ${colors.newBlue};
    svg {
      margin-right: 13px;
    }
  }
`;
