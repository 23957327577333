import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from '@react-pdf/renderer';
import dayjs from 'dayjs';

import blackLogo from '~assets/images/logo-black.png';
import { formatCurrencyFn } from '~helpers/format/currency';
import { cepMask } from '~helpers/format/masks';
import { formatCpfOrCnpj } from '~helpers/format/numbers';
import colors from '~styles/colors';
import Boleto from 'boleto.js';

import AeonikFont from '../../fonts/Aeonik-Regular.otf';
import AeonikBoldFont from '../../fonts/Aeonik-Bold.otf';

const styles = StyleSheet.create({
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 25px 10px 25px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerRightContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  paragraph: {
    fontSize: 9,
    lineHeight: '120%',
  },
  paragraphBold: {
    fontSize: 9,
    lineHeight: '120%',

    fontWeight: 700,
  },
  logo: {
    width: 125,
    height: 33,
  },
  detailsRow: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px',
    borderRadius: '4px',
    border: `0.5px solid ${colors.grayRegular}`,
    fontSize: 9,
    width: '100%',
  },
  detailsSubRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: 8,
    borderBottom: `0.5px solid ${colors.grayRegular}`,
  },
  detailsItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    height: 22,
    width: 0.5,
    backgroundColor: colors.grayRegular,
    marginLeft: 10,
    marginRight: 10,
  },
  rightDetailsWrapper: {
    width: 160,
    maxWidth: 160,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px',
    borderRadius: '4px',
    border: `0.5px solid ${colors.grayRegular}`,
    fontSize: 9,
    marginLeft: 8,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  payerDetailsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 9,
    marginTop: 12,
  },
  dashDivider: {
    width: '100%',
    height: 1,
    borderBottom: `1px dashed ${colors.grayRegular}`,
    marginTop: 50,
    marginBottom: 15,
  },
  secondHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
  },
  instructionsWrapper: {
    width: 390,
    fontSize: 9,
    marginTop: 10,
    marginBottom: 10,
  },
});

export const BillDocument = ({
  details,
  barcode,
}: {
  details: any;
  barcode: string;
}) => {
  const recipientName = details?.recipientFinal?.name;
  const recipientBranch = '0001';
  const recipientAccount = details?.account?.number;
  const recipientDocumentNumber = formatCpfOrCnpj(
    details?.recipientFinal?.document
  );

  const boleto =
    details?.digitable && new Boleto(details?.digitable).prettyNumber();

  const documentNumber = details?.document;
  const ourNumber = details?.ourNumber;
  const digitable = boleto;

  const discountValue = details?.discount?.value || <>&nbsp;</>;
  const interestValue = details?.interest?.value || <>&nbsp;</>;

  const dueDate = dayjs(details?.dueDate).format('DD/MM/YYYY');
  const emissionDate = dayjs(details?.emissionDate).format('DD/MM/YYYY');

  const value = formatCurrencyFn('BRL', details?.amount?.value);

  const payerName = details?.payer?.name;
  const payerDocumentNumber = formatCpfOrCnpj(details?.payer?.document);
  const payerAddressLine = details?.payer?.address?.addressLine;
  const payerCity = details?.payer?.address?.city;
  const payerState = details?.payer?.address?.state;
  const payerZipCode = cepMask(details?.payer?.address?.zipCode);
  const payerCountry = 'Brasil';

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View id="barcode" />
        <View style={styles.header}>
          <Image src={blackLogo} style={styles.logo} />
          <View style={styles.headerRightContent}>
            <Text style={styles.paragraph}>
              Boleto para depósito na conta do Capitual de
            </Text>
            <Text style={styles.paragraph}>
              <Text style={styles.paragraphBold}>{recipientName}</Text> -
              Agência{' '}
              <Text style={styles.paragraphBold}>{recipientBranch}</Text> -
              Conta <Text style={styles.paragraphBold}>{recipientAccount}</Text>
            </Text>
          </View>
        </View>

        <View style={styles.contentWrapper}>
          <View style={[styles.detailsRow, { marginTop: 20 }]}>
            <View style={styles.detailsSubRow}>
              <View style={[styles.detailsItem, { width: 200 }]}>
                <Text style={[{ marginBottom: 5 }]}>Beneficiário</Text>
                <Text style={styles.paragraphBold}>{recipientName}</Text>
              </View>

              <View style={styles.divider} />

              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>CPF/CNPJ</Text>
                <Text style={styles.paragraphBold}>
                  {recipientDocumentNumber}
                </Text>
              </View>

              <View style={styles.divider} />

              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>Espécie</Text>
                <Text style={styles.paragraphBold}>R$</Text>
              </View>
            </View>

            <View style={styles.detailsSubRow}>
              <View style={[styles.detailsItem, { width: 133 }]}>
                <Text style={[{ marginBottom: 5 }]}>Nº do Documento</Text>
                <Text style={styles.paragraphBold}>{documentNumber}</Text>
              </View>

              <View style={styles.divider} />

              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>Quantidade</Text>
                <Text>&nbsp;</Text>
              </View>

              <View style={styles.divider} />

              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>Nosso Número</Text>
                <Text style={styles.paragraphBold}>{ourNumber}</Text>
              </View>
            </View>

            <View style={[styles.detailsSubRow, { border: 'none' }]}>
              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>
                  (-) Desconto / Abatimento
                </Text>
                <Text style={styles.paragraphBold}>{discountValue}</Text>
              </View>
              <View style={styles.divider} />

              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>(-) Outras Deduções</Text>
                <Text>&nbsp;</Text>
              </View>
              <View style={styles.divider} />

              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>(+) Mora / Multa</Text>
                <Text style={styles.paragraphBold}>{interestValue}</Text>
              </View>
              <View style={styles.divider} />

              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>(+) Outros Acréscimos</Text>
                <Text>&nbsp;</Text>
              </View>
            </View>
          </View>

          <View style={[styles.rightDetailsWrapper, { marginTop: 20 }]}>
            <View
              style={[
                styles.detailsSubRow,
                { backgroundColor: `${colors.grayRegular}20` },
              ]}
            >
              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>Vencimento</Text>
                <Text style={styles.paragraphBold}>{dueDate}</Text>
              </View>
            </View>

            <View style={styles.detailsSubRow}>
              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>Valor do Documento</Text>
                <Text style={styles.paragraphBold}>{value}</Text>
              </View>
            </View>

            <View
              style={[
                styles.detailsSubRow,
                { border: 'none', backgroundColor: `${colors.grayRegular}20` },
              ]}
            >
              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>(=) Valor Cobrado</Text>
                <Text style={styles.paragraphBold}>{value}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.payerDetailsWrapper}>
          <Text style={[{ marginBottom: 5 }]}>Pagador</Text>
          <Text style={styles.paragraphBold}>
            {payerName} | {payerDocumentNumber}
          </Text>

          <Text style={styles.paragraphBold}>
            {payerAddressLine}, CEP: {payerZipCode}
          </Text>

          <Text style={styles.paragraphBold}>
            {payerCity} - {payerState} - {payerCountry}
          </Text>
        </View>

        <View style={styles.dashDivider} />

        <View style={styles.secondHeader}>
          <Image src={blackLogo} style={styles.logo} />
          <View style={[styles.divider, { margin: '0 25px' }]} />
          <Text style={[styles.paragraphBold, { fontSize: 10 }]}>332-8</Text>
          <View style={[styles.divider, { margin: '0 25px' }]} />
          <Text style={[styles.paragraphBold, { fontSize: 10 }]}>
            {digitable}
          </Text>
        </View>

        <View style={styles.contentWrapper}>
          <View style={[{ width: '100%' }]}>
            <View style={[styles.detailsRow, { marginTop: 20 }]}>
              <View style={styles.detailsSubRow}>
                <View style={styles.detailsItem}>
                  <Text style={[{ marginBottom: 5 }]}>Local de Pagamento</Text>
                  <Text style={styles.paragraphBold}>
                    Em qualquer banco até o vencimento.
                  </Text>
                </View>
              </View>

              <View style={styles.detailsSubRow}>
                <View style={[styles.detailsItem, { width: 250 }]}>
                  <Text style={[{ marginBottom: 5 }]}>Beneficiário</Text>
                  <Text style={styles.paragraphBold}>{recipientName}</Text>
                </View>

                <View style={styles.divider} />

                <View style={styles.detailsItem}>
                  <Text style={[{ marginBottom: 5 }]}>CPF/CNPJ</Text>
                  <Text style={styles.paragraphBold}>
                    {recipientDocumentNumber}
                  </Text>
                </View>
              </View>

              <View style={styles.detailsSubRow}>
                <View style={styles.detailsItem}>
                  <Text style={[{ marginBottom: 5 }]}>Data do Documento</Text>
                  <Text style={styles.paragraphBold}>{emissionDate}</Text>
                </View>
                <View style={styles.divider} />

                <View style={styles.detailsItem}>
                  <Text style={[{ marginBottom: 5 }]}>Nº do Documento</Text>
                  <Text style={styles.paragraphBold}>{documentNumber}</Text>
                </View>
                <View style={styles.divider} />

                <View style={styles.detailsItem}>
                  <Text style={[{ marginBottom: 5 }]}>Espécie Doc.</Text>
                  <Text style={styles.paragraphBold}>OUTROS</Text>
                </View>
                <View style={styles.divider} />

                <View style={styles.detailsItem}>
                  <Text style={[{ marginBottom: 5 }]}>Aceite</Text>
                  <Text style={styles.paragraphBold}>N</Text>
                </View>

                <View style={styles.divider} />

                <View style={styles.detailsItem}>
                  <Text style={[{ marginBottom: 5 }]}>Data Processamento</Text>
                  <Text style={styles.paragraphBold}>{emissionDate}</Text>
                </View>
              </View>

              <View style={[styles.detailsSubRow, { border: 'none' }]}>
                <View style={styles.detailsItem}>
                  <Text style={[{ marginBottom: 5 }]}>Uso do Banco</Text>
                  <Text>&nbsp;</Text>
                </View>
                <View style={styles.divider} />

                <View style={styles.detailsItem}>
                  <Text style={[{ marginBottom: 5 }]}>Carteira</Text>
                  <Text style={styles.paragraphBold}>11</Text>
                </View>
                <View style={styles.divider} />

                <View style={styles.detailsItem}>
                  <Text style={[{ marginBottom: 5 }]}>Espécie</Text>
                  <Text style={styles.paragraphBold}>OUTROS</Text>
                </View>
                <View style={styles.divider} />

                <View style={styles.detailsItem}>
                  <Text style={[{ marginBottom: 5 }]}>Quantidade</Text>
                  <Text>&nbsp;</Text>
                </View>
              </View>
            </View>
            <View style={styles.instructionsWrapper}>
              <Text style={[{ marginBottom: 5 }]}>Instruções</Text>
              <Text style={[{ marginBottom: 20 }]}>
                1. Este boleto é válido apenas uma única vez e seu pagamento não
                pode ser realizado com cheque;
              </Text>
              <Text>
                2. Em caso de vencimento no fim de semana ou feriado, aceitar o
                pagamento até o primeiro dia útil posterior.
              </Text>
            </View>

            <View style={styles.payerDetailsWrapper}>
              <Text style={[{ marginBottom: 5 }]}>Pagador</Text>
              <Text style={styles.paragraphBold}>
                {payerName} | CNPJ: {payerDocumentNumber}
              </Text>

              <Text style={styles.paragraphBold}>
                {payerAddressLine}, CEP: {payerZipCode}
              </Text>

              <Text style={styles.paragraphBold}>
                {payerCity} - {payerState} - {payerCountry}
              </Text>
            </View>

            <View style={[styles.detailsRow, { marginTop: 20 }]}>
              <View style={[styles.detailsSubRow, { border: 'none' }]}>
                <View style={styles.detailsItem}>
                  <Text style={[{ marginBottom: 5, width: 250 }]}>
                    Sacador / Avalista
                  </Text>
                  <Text>&nbsp;</Text>
                </View>
                <View style={styles.divider} />

                <View style={styles.detailsItem}>
                  <Text style={[{ marginBottom: 5 }]}>Código Baixa</Text>
                  <Text>&nbsp;</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={[styles.rightDetailsWrapper, { marginTop: 20 }]}>
            <View
              style={[
                styles.detailsSubRow,
                { backgroundColor: `${colors.grayRegular}20` },
              ]}
            >
              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>Vencimento</Text>
                <Text style={styles.paragraphBold}>{dueDate}</Text>
              </View>
            </View>

            <View style={styles.detailsSubRow}>
              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>Agência / Conta</Text>
                <Text style={styles.paragraphBold}>
                  {recipientBranch} / {recipientAccount}
                </Text>
              </View>
            </View>

            <View style={styles.detailsSubRow}>
              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>Nosso Número</Text>
                <Text style={styles.paragraphBold}>{ourNumber}</Text>
              </View>
            </View>

            <View style={styles.detailsSubRow}>
              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>Valor do Documento</Text>
                <Text style={styles.paragraphBold}>{value}</Text>
              </View>
            </View>

            <View style={styles.detailsSubRow}>
              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>
                  (-) Desconto / Abatimento
                </Text>
                <Text style={styles.paragraphBold}>{discountValue}</Text>
              </View>
            </View>

            <View style={styles.detailsSubRow}>
              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>(-) Outras Deduções</Text>
                <Text>&nbsp;</Text>
              </View>
            </View>

            <View style={styles.detailsSubRow}>
              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>(+) Mora / Multa</Text>
                <Text>&nbsp;</Text>
              </View>
            </View>

            <View style={styles.detailsSubRow}>
              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>(+) Outros Acréscimos</Text>
                <Text>&nbsp;</Text>
              </View>
            </View>

            <View
              style={[
                styles.detailsSubRow,
                { border: 'none', backgroundColor: `${colors.grayRegular}20` },
              ]}
            >
              <View style={styles.detailsItem}>
                <Text style={[{ marginBottom: 5 }]}>(=) Valor Cobrado</Text>
                <Text style={styles.paragraphBold}>{value}</Text>
              </View>
            </View>
          </View>
        </View>

        {barcode ? (
          <Image
            src={barcode}
            style={{ height: 40, width: 350, marginTop: 10 }}
          />
        ) : null}
      </Page>
    </Document>
  );
};
