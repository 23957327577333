import * as Style from './CapContaModal.styles';
import * as Text from '~styles/text';
import { GenericButton, GenericRow, RoundButtonIcon } from '~components';
import { ActionButton } from '~components/Buttons/ActionButton';
import BarCodeIcon from '~assets/images/icons/barcode.svg';
import BillSVG from '~assets/svg/statement/bill';
import CapContaSVG from '~assets/images/icons/wallets/CapConta';
import ConvertSVG from '~assets/svg/statement/convert';
import CopyIcon from '~assets/images/icons/copy.svg';
import { ExchangeIcon } from '~assets/svg/exchange';
import MoneyReceivedSVG from '~assets/svg/statement/moneyReceived';
import PixTedReceivedSVG from '~assets/svg/statement/pix-ted-received';
import PixTedSentSVG from '~assets/svg/statement/pix-ted-sent';
import TransferIcon from '~assets/images/icons/pix/transfer.svg';
import colors from '~styles/colors';
import date from '~helpers/format/date';
import dayjs from 'dayjs';
import { formatCurrencyFn } from '~helpers/format/currency';
import { getSelectedUserOrganization } from '~helpers/device';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetStatement } from '~reactQuery/queries/bank/useGetStatement';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import { WalletType } from '~reactQuery/types/wallets.types';
import { usePermission } from '~helpers/permissionGate';
import {
  Statement,
  StatementWhenTransfer,
  StatementListParsed,
} from '../CapAccountAndWallets.types';
import BlockedIcon from '~assets/svg/statement/blocked';

const CapContaModal = () => {
  const { setOpenModalMultiStep, setSection, setParams } =
    useActionsOnModalMultiStep();

  const { setOpenModalSideBar } = useOpenModalSideBar();

  const navigate = useNavigate();

  const userOrganizationId = getSelectedUserOrganization();

  const { data: capAccountInfo } = useGetCapAccountInfo({
    userOrganizationId: Number(userOrganizationId),
  });

  const INITIAL_DATE = '2000-11-01';
  const LAST_MOVEMENTS = 6;
  const INITIAL_PAGE = 6;

  const { data: statement } = useGetStatement({
    to: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    from: INITIAL_DATE,
    perPage: LAST_MOVEMENTS,
    page: INITIAL_PAGE,
  });

  const capAccountWallet: WalletType = {
    id: 0,
    owner: 0,
    createdAt: '',
    updatedAt: '',
    walletAddress: 'CAPCONTA',
    walletCurrency: 'BRL',
    walletName: 'CapConta',
    funds: Number(capAccountInfo?.available) || 0,
    isOwnerInfoPublic: 0,
    priceInPrefCurrency: 0,
    isFavorite: 0,
    isPublic: 0,
    currency: {
      id: 0,
      type: 'fiat',
      name: 'CapConta',
      symbol: 'R$',
      isoCode: 'BRL',
      precision: 2,
    },
    ordering: 1,
    enabled: 1,
    enabledTransfer: true,
    enabledConvert: true,
    enabledPay: true,
    enabledDeposit: true,
    enabledWithdraw: true,
  };

  const openConvertModal = () => {
    setSection('convert');
    setOpenModalMultiStep(true);
    setParams({ isComingFrom: 'capConta', originWallet: capAccountWallet });
  };

  const bankInfo = [
    {
      title: 'Agência',
      info: capAccountInfo?.branch || '--',
      onClick: () => {
        navigator.clipboard.writeText(`Agência: ${capAccountInfo?.branch}`);
      },
    },
    {
      title: 'Conta Corrente',
      info: capAccountInfo?.account || '--',
      onClick: () => {
        navigator.clipboard.writeText(
          `Conta Corrente: ${capAccountInfo?.account}`
        );
      },
    },
    {
      title: 'Banco',
      info: '332 - Acesso Soluções',
      onClick: () => {
        navigator.clipboard.writeText('Banco: 332 - Acesso Soluções');
      },
    },
  ];

  const statementList = useMemo(() => {
    return statement?.pages?.flat();
  }, [statement]);

  const parsedTX = (tx: Statement | null) => {
    if (!tx) {
      return null;
    }

    const { direction, data, timestamp, method, authCode } = tx;
    const formattedValue = formatCurrencyFn(
      'BRL',
      String(Number(data.value) / 100)
    );
    const daysDiff = date(timestamp).diff(date(), 'days');

    const statementListParsed = {
      value: direction < 1 ? `- ${formattedValue}` : formattedValue,
      description:
        daysDiff > -2
          ? date(timestamp).fromNow()
          : date(timestamp).format('DD MMM YY'),
      txId: authCode,
    } as StatementListParsed;

    const typeMappings: Record<string, { title: string; icon: JSX.Element }> = {
      Transfer: (() => {
        const transfer = tx as unknown as StatementWhenTransfer;
        const isCapContaToWallet =
          transfer?.data?.requisite?.type === 'EXCHANGE_CAPCONTA_TO_CAPWALLET';
        const isCapWalletToCapConta =
          transfer?.data?.requisite?.type === 'EXCHANGE_CAPWALLET_TO_CAPCONTA';

        if (isCapContaToWallet) {
          return {
            title: 'Conversão CapConta Para CapWallet',
            icon: <ConvertSVG fill={colors.grayMedium} />,
          };
        }
        if (isCapWalletToCapConta) {
          return {
            title: 'Conversão CapWallet Para CapConta',
            icon: <ConvertSVG fill={colors.grayMedium} />,
          };
        }
        return {
          title: `Transferência ${String(transfer.data.method).toUpperCase()} ${
            direction < 1 ? 'Enviada' : 'Recebida'
          }`,
          icon:
            direction < 1 ? (
              <PixTedSentSVG />
            ) : (
              <PixTedReceivedSVG stroke={colors.success} />
            ),
        };
      })(),
      Bill: {
        title: 'Pagamento de Boleto',
        icon: <BillSVG />,
      },
      Refund: {
        title: 'Transferência Reembolsada',
        icon: <MoneyReceivedSVG fill={colors.success} />,
      },
    };

    const typeMapping = typeMappings[method] || {
      title: 'Tipo de Transação Desconhecido',
      icon: <BlockedIcon stroke={colors.danger} />,
    };

    return { ...statementListParsed, ...typeMapping };
  };

  const lastTransactions = statementList?.map((tx) => parsedTX(tx));
  const { granted: convertGranted } = usePermission('convertButton');
  const { granted: transferGranted } = usePermission('transferButtonCapConta');
  const { granted: buttonsPixGranted } = usePermission('buttonsPix');
  const { granted: payBillsGranted } = usePermission('payBillsButton');
  const paymentGranted = buttonsPixGranted || payBillsGranted;

  return (
    <Style.Container>
      <div className="header">
        <RoundButtonIcon buttonSize="iconWallet">
          <CapContaSVG />
        </RoundButtonIcon>
        <div className="title">
          <Text.Heading2>CapConta</Text.Heading2>
          <Text.Paragraph color={colors.grayMedium} marginTop={3}>
            BRL
          </Text.Paragraph>
        </div>
      </div>

      <div className="details">
        <Text.Paragraph color={colors.grayMedium} marginBottom={6}>
          Saldo disponível
        </Text.Paragraph>
        <Text.Heading2 marginBottom={40}>
          {formatCurrencyFn(
            'BRL',
            capAccountInfo?.available,
            false,
            false,
            true
          )}
        </Text.Heading2>

        {bankInfo.map((info) => (
          <div className="bank-info" key={info.title}>
            <Text.Paragraph color={colors.grayMedium}>
              {info.title}
            </Text.Paragraph>
            <div className="right-content">
              <Text.Heading5 marginRight={5}>{info.info}</Text.Heading5>
              <RoundButtonIcon buttonType="flat" onClick={info.onClick}>
                <CopyIcon />
              </RoundButtonIcon>
            </div>
          </div>
        ))}
      </div>

      <div className="action-buttons-wrapper">
        <ActionButton
          icon={<TransferIcon />}
          onClick={() => {
            setSection('transfer');
            setOpenModalMultiStep(true);
            setParams({
              isComingFrom: 'capConta',
            });
          }}
          disabled={!transferGranted}
        >
          Transferir
        </ActionButton>
        <ActionButton
          icon={<BarCodeIcon />}
          onClick={() => {
            setSection('payBills');
            setOpenModalMultiStep(true);
            setParams({
              payBillsGranted: Boolean(payBillsGranted),
            });
          }}
          disabled={!paymentGranted}
        >
          Pagar
        </ActionButton>
        <ActionButton
          icon={<ExchangeIcon />}
          onClick={openConvertModal}
          disabled={!convertGranted}
        >
          Converter
        </ActionButton>
      </div>

      <div className="last-transactions">
        <Text.Heading3 marginBottom={15}>Últimas Movimentações</Text.Heading3>

        {lastTransactions?.map((transaction) => {
          if (!transaction) return null;

          return (
            <GenericRow
              key={transaction?.txId}
              title={transaction?.title}
              description={transaction?.description}
              rightContentType="text"
              rightText={transaction?.value}
              leftContentIconType="icon"
              leftComponent={transaction?.icon}
              padding="18px 0"
            />
          );
        })}

        <GenericButton
          buttonType="inline"
          width="100%"
          onClick={() => {
            setOpenModalSideBar(false);
            setTimeout(() => {
              navigate('/dashboard/activities');
            }, 200);
          }}
          marginTop={25}
        >
          Extrato completo
        </GenericButton>
      </div>
    </Style.Container>
  );
};

export default CapContaModal;
