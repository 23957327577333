import { colors } from '~styles';
import { GeneralStyleProps } from 'src/@types/general';
import styled from 'styled-components';

export const Container = styled.div<GeneralStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

  user-select: none;
  text-align: center;

  .icon-wrapper {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${colors.gray};
    transition: background-color 0.3s ease-in-out;

    :hover {
      transition: background-color 0.3s ease-in-out;
      background-color: #e7e8ed;
    }
  }
`;
