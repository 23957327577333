import React from 'react';
import MaintenanceSVG from '~assets/svg/maintenance';
import { FooterDashboard } from '~components/Footers';
import { ContainerMaintenance, Content } from './Maintenance.styles';
import * as Text from '~styles/text';

const Maintenance: React.FC = () => {
  return (
    <ContainerMaintenance>
      <Content>
        <div className="description">
          <Text.Heading2Bold fontSize={3.75}>Oops!</Text.Heading2Bold>
          <Text.Heading2Bold fontSize={1.83}>
            Estamos em manutenção!
          </Text.Heading2Bold>

          <Text.Heading4 marginTop={20}>
            Desculpe pelo transtorno causado. <br />
            Voltaremos em breve.
          </Text.Heading4>
        </div>
        <MaintenanceSVG />
      </Content>
      <FooterDashboard />
    </ContainerMaintenance>
  );
};

export default Maintenance;
