import * as KycStyle from '../Kyc.styles';
import * as Style from './PartnersSteps.styles';
import * as Text from '~styles/text';

import {
  DDIOptions,
  countryOptions,
  documentsOptions,
  memberQualificationOptions,
  politicallyExposedTypes,
  declaredIncomeOptions,
  occupationValues,
} from '~data/kyc';
import {
  GenericButton,
  InputDocForm,
  InputForm,
  InputPhone,
  InputSelect,
  InputSwitch,
} from '~components';
import { cellphoneMask, cepMask, dateMask } from '~helpers/format/masks';
import { moneyMask, percentageRange } from '~helpers/format/currency';

import ArrowBackIcon from '~assets/images/icons/kyc/arrow-back.svg';
import ContactIcon from '~assets/images/icons/contact.svg';
import FileIcon from '~assets/images/icons/file.svg';
import { PartnerFormProps } from '../Kyc.types';
import { When } from 'react-if';
import { colors } from '~styles';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { partnerSingleValidation } from '../validation/kyc.schema';
import { useEffect } from 'react';
import { usePartnersList } from '../store';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import dayjs from 'dayjs';

export const PartnerForm = ({
  gotToModalStep,
  setOpenModal,
  partnersMethods,
}: PartnerFormProps) => {
  const { t } = useTranslation();
  const { field } = usePartnersList();

  const {
    setValue,
    watch,
    getValues,
    trigger,
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = partnersMethods;

  const partnerKeyName = Array.isArray(field) ? field[0] : field;
  const getIndex = onlyNumbers(String(partnerKeyName));

  const isPartnerOfPartner = field.includes('partnerData');

  const errorIncase = isPartnerOfPartner
    ? errors?.allPartners?.[getIndex?.[0]]?.list?.[getIndex?.[1]]
        ?.partnerData?.[getIndex?.[2]]
    : errors?.allPartners?.[getIndex?.[0]]?.list?.[getIndex?.[1]];

  const registerName = getValues(`${partnerKeyName}.registerName`) || '';
  const partnerFirstName =
    getValues(`${partnerKeyName}.registerName`)?.split(' ')[0] || '';

  const isPowerOfAttorney = watch(`${partnerKeyName}.isPowerOfAttorney`);

  const isPowerOfAttorneyEndDateMoreThanMonth = () => {
    const powerOfAttorneyEndDate = watch(
      `${partnerKeyName}.powerOfAttorneyEndDate`
    ) as string;

    const parsed = powerOfAttorneyEndDate.split('/');

    const date = dayjs(`${parsed[2]}-${parsed[1]}-${parsed[0]}`);

    const today = dayjs(new Date());

    const dateDiffInMonth = date.diff(today, 'month', true);

    if (dateDiffInMonth > 1) return true;

    return false;
  };

  const onSavePartner = async (allPartners: any) => {
    const partnerDataInCase = getValues(`${partnerKeyName}`);

    if (!partnerDataInCase) return;

    if (isPowerOfAttorney && !isPowerOfAttorneyEndDateMoreThanMonth()) {
      setError(`${partnerKeyName}.powerOfAttorneyEndDate`, {
        type: 'required',
        message:
          'Faltam menos de 1 mês para o término da validade da sua procuração',
      });
      return;
    }

    try {
      await partnerSingleValidation(isPowerOfAttorney).validate(
        partnerDataInCase,
        {
          abortEarly: false,
        }
      );

      setValue(partnerKeyName, {
        ...partnerDataInCase,
        isSaved: true,
        status: 'WAITINGTOSEND',
        partnerOfPartner: isPartnerOfPartner,
        uniqueFieldId: v4(),
      });

      setOpenModal(false);
      gotToModalStep('partnerForm');

      clearErrors(`${partnerKeyName}`);
    } catch (err: any) {
      err.inner.forEach((e: any) => {
        setError(`${partnerKeyName}.${e.path}`, {
          type: 'required',
          message: e.message,
        });
      });
    }
  };

  const setAddrValues = (obj: any) => {
    if (!obj) return;

    setValue(`${partnerKeyName}.state`, obj.uf);
    setValue(`${partnerKeyName}.city`, obj.localidade);
    setValue(`${partnerKeyName}.addressLine`, obj.logradouro);
    setValue(`${partnerKeyName}.neighborhood`, obj.bairro);

    if (trigger) {
      trigger([
        `${partnerKeyName}.state`,
        `${partnerKeyName}.city`,
        `${partnerKeyName}.addressLine`,
        `${partnerKeyName}.neighborhood`,
        `${partnerKeyName}.addressLine`,
      ]);
    }
  };

  useEffect(() => {
    if (getValues(`${partnerKeyName}.zipCode`)?.length > 9) {
      if (getValues(`${partnerKeyName}.city`)) return;
      try {
        fetch(
          `https://viacep.com.br/ws/${getValues(
            `${partnerKeyName}.zipCode`
          )?.replace(/[\.\-]/g, '')}/json/`
        ).then((resp) => {
          resp.json().then(setAddrValues);
        });
      } catch (error) {
        //  console.log('Error in viaCep ->', error);
      }
    }
  }, [watch(`${partnerKeyName}.zipCode`)]);

  return (
    <Style.Container>
      <div className="content scroll">
        {true && (
          <div
            className="back-button"
            onClick={() => gotToModalStep('howToFinishRegister')}
          >
            <ArrowBackIcon />
          </div>
        )}

        <Text.Heading5 color={colors.grayMedium} marginTop={30}>
          {t('Adicionar Sócio')}
        </Text.Heading5>

        <Text.Heading2Bold marginTop={10} marginBottom={25}>
          {t('Complete os campos abaixo com as informações pessoais de', {
            partnerName: registerName,
          })}
        </Text.Heading2Bold>

        <KycStyle.FormContainer className="mb-30">
          <div>
            <InputForm
              autoFocus
              {...register(`${partnerKeyName}.socialName`)}
              label={t('Nome Social')}
              error={errorIncase?.socialName?.message}
              marginTop={20}
              marginBottom={20}
            />
          </div>

          <div>
            <InputForm
              label={t('Data de Nascimento')}
              placeholder="00/00/0000"
              maskFunction={dateMask}
              {...register(`${partnerKeyName}.birthDate`)}
              error={errorIncase?.birthDate?.message}
              marginBottom={20}
            />
          </div>
          <div>
            <InputForm
              label={t('E-mail')}
              placeholder="example@gmail.com"
              {...register(`${partnerKeyName}.email`)}
              error={errorIncase?.email?.message}
              marginBottom={20}
            />
          </div>

          <div>
            <InputForm
              label={t('Nome da mãe')}
              {...register(`${partnerKeyName}.motherName`)}
              error={errorIncase?.motherName?.message}
              marginBottom={20}
            />
          </div>

          <div className="mb-20">
            <InputSelect
              label={t('Qualificação do Representante')}
              sections={memberQualificationOptions}
              placeholder="Selecione"
              hookForm={{
                ...register(`${partnerKeyName}.memberQualification`),
              }}
              defaultValue={
                watch(`${partnerKeyName}.memberQualification`) || ''
              }
              error={errorIncase?.memberQualification?.message}
            />
          </div>

          <div>
            <InputSelect
              {...register(`${partnerKeyName}.declaredIncome`)}
              label={t('Renda Declarada')}
              options={declaredIncomeOptions}
              error={errorIncase?.declaredIncome?.message}
              marginBottom={20}
              placeholder="Selecione"
            />
          </div>

          <div>
            <InputForm
              {...register(`${partnerKeyName}.participationPercentage`)}
              label={t('Percentual de Participação')}
              placeholder="0,00 %"
              min={0}
              max={100}
              type="number"
              marginBottom={20}
              error={errorIncase?.participationPercentage?.message}
              maskFunction={percentageRange}
            />

            <InputSelect
              {...register(`${partnerKeyName}.occupation`)}
              label="Occupation"
              options={occupationValues}
              error={errorIncase?.occupation?.message}
              marginBottom={20}
              placeholder="Selecione"
              style={{ display: 'none' }}
            />

            <InputSelect
              label={t('é exposto politicamente', {
                partnerName: partnerFirstName,
              })}
              options={politicallyExposedTypes}
              placeholder="Selecione"
              defaultValue={watch(`${partnerKeyName}.pep`) || ''}
              hookForm={{
                ...register(`${partnerKeyName}.pep`),
              }}
              error={errorIncase?.pep?.message}
            />
          </div>

          <KycStyle.Line marginTop={20} marginBottom={20} />

          <div className="toggle-content-wrapper">
            <Text.Heading5>
              {t('é o Representante Legal desta empresa', {
                partnerName: partnerFirstName,
              })}
            </Text.Heading5>
            {errorIncase?.legalRepresent?.message && (
              <Text.Small color={colors.danger} display="inline">
                {errorIncase?.legalRepresent?.message}
              </Text.Small>
            )}

            <InputSwitch
              hookForm={{
                ...register(`${partnerKeyName}.legalRepresent`),
              }}
            />
          </div>

          <KycStyle.Line marginTop={20} marginBottom={20} />

          <div className="toggle-content-wrapper">
            <Text.Heading5>
              {`${partnerFirstName} ${t('é o Procurador desta empresa')}`}
            </Text.Heading5>
            {errorIncase?.isPowerOfAttorney?.message && (
              <Text.Small color={colors.danger} display="inline">
                {errorIncase?.isPowerOfAttorney?.message}
              </Text.Small>
            )}
            <InputSwitch
              hookForm={{
                ...register(`${partnerKeyName}.isPowerOfAttorney`),
              }}
              onChange={(e: any) => {
                setValue(`${partnerKeyName}.powerOfAttorneyStartDate`, '');
                setValue(`${partnerKeyName}.powerOfAttorneyEndDate`, '');
                setValue(`${partnerKeyName}.powerOfAttorney`, '');
              }}
            />
          </div>

          <When condition={isPowerOfAttorney}>
            <div
              style={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
              }}
            >
              <div className="input-group">
                <InputForm
                  {...register(`${partnerKeyName}.powerOfAttorneyStartDate`)}
                  label="Data de Ínicio da Procuração"
                  placeholder="00/00/0000"
                  maskFunction={dateMask}
                  error={errorIncase?.powerOfAttorneyStartDate?.message}
                />
                <InputForm
                  {...register(`${partnerKeyName}.powerOfAttorneyEndDate`)}
                  label="Data do Término da Procuração"
                  placeholder="00/00/0000"
                  maskFunction={dateMask}
                  error={errorIncase?.powerOfAttorneyEndDate?.message}
                />
              </div>

              <InputDocForm
                docTitle={t('Procuração Pública Legal')}
                hookForm={{
                  ...register(`${partnerKeyName}.powerOfAttorney`),
                }}
                name={`${partnerKeyName}.powerOfAttorney`}
                getValues={getValues}
                setValue={setValue}
                error={errorIncase?.powerOfAttorney?.message}
                mobileVersion
              />
            </div>
          </When>

          <KycStyle.Line marginTop={20} />

          <KycStyle.SubtitleWrapper marginTop={30} marginBottom={30}>
            <div className="icon-wrapper">
              <ContactIcon />
            </div>
            <Text.Heading4 marginLeft={10}>
              {t('Contato e Endereço')}
            </Text.Heading4>
          </KycStyle.SubtitleWrapper>

          <div className="mb-20">
            <InputSelect
              label={t('Código do País')}
              options={DDIOptions}
              hookForm={{
                ...register(`${partnerKeyName}.countryCode`),
              }}
              error={errorIncase?.countryCode?.message}
            />
          </div>

          <div className="mb-20">
            <InputPhone
              label={t('Telefone')}
              placeholder="00 00000-0000"
              maskFunction={cellphoneMask}
              ddi={watch && watch(`${partnerKeyName}.countryCode`)}
              hookForm={{
                ...register(`${partnerKeyName}.number`),
              }}
              error={errorIncase?.number?.message}
            />
          </div>

          <div className="mb-20">
            <InputSelect
              label={t('País/Região')}
              placeholder="Selecione"
              hookForm={{
                ...register(`${partnerKeyName}.country`),
              }}
              options={countryOptions}
              error={errorIncase?.country?.message}
            />
          </div>

          <div className="mb-20">
            <InputForm
              {...register(`${partnerKeyName}.zipCode`)}
              label={t('CEP')}
              placeholder="000.000-000"
              maskFunction={cepMask}
              error={errorIncase?.zipCode?.message}
            />
          </div>

          <div className="mb-20">
            <InputForm
              label={t('Cidade')}
              {...register(`${partnerKeyName}.city`)}
              error={errorIncase?.city?.message}
            />
          </div>

          <div className="mb-20">
            <InputForm
              label={t('Estado')}
              {...register(`${partnerKeyName}.state`)}
              error={errorIncase?.state?.message}
            />
          </div>

          <div className="mb-20">
            <InputForm
              label={t('Endereço')}
              {...register(`${partnerKeyName}.addressLine`)}
              marginTop={20}
              marginBottom={20}
              error={errorIncase?.addressLine?.message}
            />
          </div>

          <div className="mb-20">
            <InputForm
              label={t('Número')}
              {...register(`${partnerKeyName}.buildingNumber`)}
              error={errorIncase?.buildingNumber?.message}
              maxLength={4}
            />
          </div>

          <div className="mb-20">
            <InputForm
              label={t('Bairro')}
              {...register(`${partnerKeyName}.neighborhood`)}
              error={errorIncase?.neighborhood?.message}
            />
          </div>

          <div className="mb-20">
            <InputForm
              label={t('Complemento (Opcional)')}
              {...register(`${partnerKeyName}.complement`)}
              error={errorIncase?.complement?.message}
            />
          </div>
          <div>
            <Style.SubtitleWrapper className="mobile">
              <div>
                <FileIcon />
              </div>
              <Text.Heading4 marginLeft={15}>
                {t('Documento de Identificação')}
              </Text.Heading4>
            </Style.SubtitleWrapper>
          </div>

          <div>
            <InputSelect
              hookForm={{
                ...register(`${partnerKeyName}.documentType`),
              }}
              label={t('Tipo de documento')}
              placeholder="Selecione"
              options={documentsOptions}
              error={errorIncase?.documentType?.message}
            />
          </div>

          <div>
            <InputDocForm
              docTitle={t('Frente do Documento de Identificação')}
              hookForm={{
                ...register(`${partnerKeyName}.fileFrontDocument`),
              }}
              error={errorIncase?.fileFrontDocument?.message}
              name={`${partnerKeyName}.fileFrontDocument`}
              getValues={getValues}
              setValue={setValue}
              mobileVersion
            />
          </div>

          <div>
            <InputDocForm
              docTitle={t('Verso do Documento de Identificação')}
              hookForm={{
                ...register(`${partnerKeyName}.fileBackDocument`),
              }}
              name={`${partnerKeyName}.fileBackDocument`}
              getValues={getValues}
              setValue={setValue}
              error={errorIncase?.fileBackDocument?.message}
              mobileVersion
            />
          </div>
        </KycStyle.FormContainer>
      </div>

      <div className="footer">
        <div className="footer-btn">
          <GenericButton
            buttonType="secondary"
            width="100%"
            onClick={handleSubmit(onSavePartner)}
          >
            Continuar
          </GenericButton>
        </div>
      </div>
    </Style.Container>
  );
};
