import * as Style from '../DetailsActivitySideBarModal.styles';
import * as Text from '~styles/text';

import PaymentBill from './PaymentBillActivitie';
import DepositBill from './DepositBillActivitie';
import DownloadSVG from '~assets/svg/download';
import Exchange from './ExchangeActivitie';
import Transfer from './TransferActivitie';
import {
  TransactionDetailsTypes,
  WalletInfoTypes,
} from '../../Activities.types';
import GoBackArrowIcon from '~assets/svg/goBackArrow';

const MoreDetailsModal = ({
  openDetails,
  setOpenDetails,
  transactionDetails,
  exchangeResult,
  walletInfo,
  downloadSingle,
}: {
  openDetails: boolean;
  setOpenDetails: (state: boolean) => void;
  transactionDetails: TransactionDetailsTypes;
  exchangeResult: number | null | undefined;
  walletInfo: WalletInfoTypes | null;
  downloadSingle: () => void;
}) => {
  const goBack = () => {
    setOpenDetails(false);
  };

  const handleTransactionTitle = () => {
    if (
      transactionDetails?.data?.requisite?.type &&
      transactionDetails?.data?.requisite?.type?.includes('EXCHANGE')
    ) {
      return 'Detalhes da conversão';
    }

    const translatorTitle: { [key: string]: string } = {
      'TRANSFER-PIX': 'Detalhes da transferência',
      BILL: 'Detalhes do pagamento',
      BILLSLIP: 'Detalhes do depósito',
      TRANSFER: 'Detalhes da transferência',
      REFUND: 'Detalhes do Reembolso',
    };

    return translatorTitle[transactionDetails?.method.toUpperCase()];
  };

  const handleDefineTypeDetails = () => {
    if (
      transactionDetails?.data?.requisite?.type &&
      transactionDetails?.data?.requisite?.type?.includes('EXCHANGE')
    ) {
      return (
        <Exchange
          transactionDetails={transactionDetails}
          exchangeResult={exchangeResult}
          walletInfo={walletInfo}
        />
      );
    }

    const translatorDetails: { [key: string]: JSX.Element } = {
      BILL: <PaymentBill transactionDetails={transactionDetails} />,
      BILLSLIP: <DepositBill transactionDetails={transactionDetails} />,
      TRANSFER: <Transfer transactionDetails={transactionDetails} />,
      'TRANSFER-PIX': <Transfer transactionDetails={transactionDetails} />,
    };

    return translatorDetails[transactionDetails?.method?.toUpperCase()];
  };

  return (
    <Style.MoreDetails fadeIn={openDetails}>
      <div className="goBackButton" onClick={goBack}>
        <GoBackArrowIcon />
      </div>
      <Text.Heading2 marginBottom={35} marginTop={10}>
        {handleTransactionTitle()}
      </Text.Heading2>

      {handleDefineTypeDetails()}

      <div className="btn-wrapper mt-30 mb-10">
        <div className="pdf-button" onClick={downloadSingle}>
          <DownloadSVG />
          Baixar comprovante
        </div>
      </div>
    </Style.MoreDetails>
  );
};

export default MoreDetailsModal;
