import colors from '~styles/colors';
import * as Style from './EmptyTransfer.styles';
import * as Text from '~styles/text';
import GroupPersonIcon from '~assets/svg/groupPerson';
import { ReactElement } from 'react';
import DocumentDuplicateIcon from '~assets/svg/documentDuplicate';
import { CSSProperties } from 'styled-components';

export const EmptyTransfer = ({
  title,
  subtitle,
  style,
  icon = 'person',
}: {
  title: string;
  subtitle: string;
  style?: CSSProperties;
  icon?: string;
}) => {
  const translatorIcon: { [key: string]: ReactElement } = {
    person: <GroupPersonIcon />,
    document: <DocumentDuplicateIcon />,
  };
  return (
    <Style.Container style={style}>
      <div className="circle">{translatorIcon[icon]}</div>

      <Text.Heading4 color={colors.grayMedium}>{title}</Text.Heading4>
      <Text.Heading5 color={colors.grayRegular}>{subtitle}</Text.Heading5>
    </Style.Container>
  );
};
