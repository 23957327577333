import { SVGProps } from 'react';

const PhoneSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 14}
    height={props.height || 20}
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3ZM0 3a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3Zm4 0h6v2H4V3Zm2 12.5a1 1 0 0 1 1-1h.07a1 1 0 0 1 1 1v.072a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V15.5Z"
      fill={props.fill || '#757893'}
    />
  </svg>
);

export default PhoneSVG;
