import { SVGProps } from 'react';

const QRCodeWithoutBorderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2.25h-.75v5h5v-5H3Zm.75 3.5v-2h2v2h-2ZM13 2.25h.75v5h-5v-5H13Zm-.75 3.5v-2h-2v2h2Zm1.241 4.5h.009v-1.5H9v1.5H13.492ZM6.5 8.75h.75v5h-5v-5H6.5Zm-.75 3.5v-2h-2v2h2Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default QRCodeWithoutBorderIcon;
