import styled from 'styled-components';

export const Container = styled.div`
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    padding: 0 25px 25px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .scale-icon {
      transform: scale(0.8);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      margin-left: 15px;
    }
  }

  .details {
    padding: 0 25px 25px 25px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .right-side {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .variation {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

  .action-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .title-mov {
    padding: 0 25px;
    margin-top: 25px;
  }

  .last-transactions {
    width: 100%;
    padding: 0 25px;
    max-height: 500px;
    overflow: scroll;

    .loading-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .config-content {
    width: 100%;
    padding: 0 25px;
    margin-top: 25px;
  }
`;
