import * as React from 'react';
import { SVGProps } from 'react';

const DocumentSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_14687_112048)">
      <rect
        opacity="0.1"
        width={props.width || 48}
        height={props.height || 48}
        fill={props.fill || '#0F90FF'}
      />
      <path
        d="M26.5 12H16.5C15.837 12 15.2011 12.2634 14.7322 12.7322C14.2634 13.2011 14 13.837 14 14.5V34.5C14 35.163 14.2634 35.7989 14.7322 36.2678C15.2011 36.7366 15.837 37 16.5 37H31.5C32.163 37 32.7989 36.7366 33.2678 36.2678C33.7366 35.7989 34 35.163 34 34.5V19.5L26.5 12Z"
        stroke={props.color || '#026FF4'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5 12V19.5H34"
        stroke={props.color || '#026FF4'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 25.75H19"
        stroke={props.color || '#026FF4'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 30.75H19"
        stroke={props.color || '#026FF4'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 20.75H20.25H19"
        stroke={props.color || '#026FF4'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14687_112048">
        <rect width="48" height="48" rx="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DocumentSVG;
