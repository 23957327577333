import * as Style from '../Kyc.styles';
import * as Text from '~styles/text';

import ClockEndProcessIcon from '~assets/images/icons/kyc/clock-end-process.svg';
import { FinishProps } from '../Kyc.types';
import { GenericButton } from '~components';

export const Finish = ({ goToStep }: FinishProps) => {
  const goBack = () => {
    goToStep('status');
  };

  return (
    <Style.FinalScreenContainer>
      <div style={{ height: 130 }}>
        <ClockEndProcessIcon />
      </div>
      <Text.Heading1Medium fontSize={2.563} marginBottom={30} marginTop={30}>
        Agora é com a gente!
      </Text.Heading1Medium>
      <Text.Heading5 marginBottom={30}>
        Seus documentos foram enviados com sucesso e assim que o processo de
        análise for concluído enviaremos um e-mail com mais informações.
      </Text.Heading5>
      <Text.Heading5 marginBottom={30}>
        Aproveitamos para te assegurar que todos os documentos e dados enviados
        são criptografados e arquivados em um ambiente seguro.
      </Text.Heading5>

      <GenericButton buttonType="inline" onClick={goBack}>
        Voltar para o início
      </GenericButton>
    </Style.FinalScreenContainer>
  );
};
