import * as Text from '~styles/text';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import { UserPermissionModalProps } from '../UserPermission.types';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import PersonIcon from '~assets/svg/person';
import { CircleCheckbox } from '../UserPermission.styles';
import colors from '~styles/colors';
import SettingsIcon from '~assets/svg/settingsIcon';
import PersonalizeIcon from '~assets/svg/personalizeIcon';
import ArrowRightSimpleIcon from '~assets/svg/arrowRightSimple';
import * as Style from '../UserPermission.styles';
import { ErrorToast } from '~components/Toast';
import {
  permissionAnalyst,
  permissionManager,
} from '~src/constants/permissions';

function DefinePermissions(props: UserPermissionModalProps) {
  const {
    setStepUserPermission,
    userPermission,
    setUserPermission,
    params,
    handleCancelAndResetFields,
  } = props;

  const personalizedParams = params?.useOrganizations?.filter(
    (item) => item.id === params?.userOrganizationId
  )?.[0]?.permissions;

  const handleNext = () => {
    if (!userPermission?.permission) {
      ErrorToast({
        title: 'Erro encontrado!',
        subtitle: 'Deve ser selecionado uma permissão',
        autoCloseEnable: true,
      });
    }
    setStepUserPermission('confirmRegisterUser');
  };

  const handleNextPersonalize = () => {
    if (params?.type === 'updateUserPermissions') {
      setUserPermission({
        permission: 'personalize',
        scopes: personalizedParams,
      });
      setStepUserPermission('personalizeUserPermissions');
    } else {
      setUserPermission({
        permission: 'personalize',
        scopes: [],
      });
      setStepUserPermission('personalizeUserPermissions');
    }
  };

  return (
    <>
      <ModalMultiStepHeader
        title={
          params?.type === 'updateUserPermissions'
            ? 'Gerenciar Permissões'
            : 'Atribuir Permissões'
        }
        description={
          params?.type === 'updateUserPermissions'
            ? 'Você pode controlar quem pode visualizar ou fazer movimentações dentro da aplicação.'
            : 'Selecione a opção de permissão, que melhor se enquadra para a utilização da aplicação do usuário.'
        }
        backButtonAction={
          params?.type !== 'updateUserPermissions'
            ? () => setStepUserPermission('newUserInformation')
            : false
        }
        style={{ padding: '0px 32px' }}
      />

      <Style.ContentModal>
        <div
          className="row-define-permissions"
          onClick={() =>
            setUserPermission({
              permission: 'analyst',
              scopes: permissionAnalyst,
            })
          }
        >
          <div className="left">
            <PersonIcon fill={colors.black} width={18} height={18} />

            <div className="column">
              <Text.Heading5Bold>Analista</Text.Heading5Bold>
              <Text.Small color={colors.grayMedium}>
                Permite visualizar e emitir relatórios da conta.
              </Text.Small>
            </div>
          </div>
          <div className="right">
            <CircleCheckbox
              background={
                userPermission?.permission ||
                (params?.type === 'updateUserPermissions' &&
                params?.userPermission === 'Analista'
                  ? 'analyst'
                  : '')
              }
              type="circle-analyst"
            />
          </div>
        </div>

        <div
          className="row-define-permissions"
          onClick={() =>
            setUserPermission({
              permission: 'manager',
              scopes: permissionManager,
            })
          }
        >
          <div className="left">
            <SettingsIcon fill={colors.black} width={18} height={18} />

            <div className="column">
              <Text.Heading5Bold>Gerente</Text.Heading5Bold>
              <Text.Small color={colors.grayMedium}>
                Acessa todas as funcionalidades da conta, com exceção a funções
                administrativas.
              </Text.Small>
            </div>
          </div>
          <div className="right">
            <CircleCheckbox
              background={
                userPermission?.permission ||
                (params?.type === 'updateUserPermissions' &&
                params?.userPermission === 'Gerente'
                  ? 'manager'
                  : '')
              }
              type="circle-manager"
            />
          </div>
        </div>

        <div
          className="row-define-permissions"
          onClick={() => handleNextPersonalize()}
        >
          <div className="left">
            <PersonalizeIcon fill={colors.black} width={18} height={18} />

            <div className="column">
              <Text.Heading5Bold>Personalizado</Text.Heading5Bold>
              <Text.Small color={colors.grayMedium}>
                Ajuste as permissões para este usuário.
              </Text.Small>
            </div>
          </div>
          <div className="right">
            <ArrowRightSimpleIcon stroke={colors.black} />
          </div>
        </div>
      </Style.ContentModal>

      <ModalMultiStepBottom
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonText="Próximo"
        singleButtonAction={() => handleNext()}
        doubleButtonTextOne="Cancelar"
        doubleButtonOneAction={() => handleCancelAndResetFields()}
      />
    </>
  );
}

export default DefinePermissions;
