import styled, { css } from 'styled-components';

import { StyleProps } from './RoundButtonIcon.types';
import { colors } from '~styles';
import { transparentize } from 'polished';

export const RoundBtnContainer = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 44px;
  /* cursor: ${({ disabled }) =>
    disabled ? 'not-allowed' : 'pointer'} !important; */

  ${({ noHover, disabled }) => !noHover && !disabled ? css`
    &:hover {
      background-color: ${colors.mainGray} !important;
      cursor: pointer !important;
    }
  ` : css`
    &:hover {
      cursor: ${disabled ? 'not-allowed' : 'default'} !important;
    }
  `}

  .center-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.icon16 {
    width: 30px;
    height: 30px;
    padding: 7px;
    background-color: ${colors.mainGray};

    ${({ onClick }) =>
    onClick &&
    css`
        :hover {
          background: #e7e8ed;
        }
      `}

    ${({ selected }) =>
    selected &&
    css`
        background-color: ${colors.newBlue};
        border: none;

        svg {
          color: ${colors.white};
        }

        :hover {
          background: ${colors.newBlue};
        }
      `}

    &.outlined {
      background: ${colors.transparent};
      border: 1px solid ${transparentize(0.7, colors.grayLight)};

      &:hover {
        background: ${colors.transparent};
      }
    }

    &.flat {
      background: ${colors.transparent};
      border: none;

      ${({ onClick }) =>
    onClick &&
    css`
          cursor: pointer;
          :hover {
            background: #e7e8ed;
          }
        `}
    }
  }

  &.icon24 {
    width: 38px;
    height: 38px;
    padding: 7px;
    background-color: ${colors.mainGray};

    ${({ onClick }) =>
    onClick &&
    css`
        cursor: pointer;
        :hover {
          background: #e7e8ed;
        }
      `}

    ${({ selected }) =>
    selected &&
    css`
        background-color: ${colors.newBlue};
        border: none;

        svg {
          color: ${colors.white};
        }

        :hover {
          background: ${colors.newBlue};
        }
      `}

    &.outlined {
      background: ${colors.transparent};
      border: 1px solid ${transparentize(0.7, colors.grayLight)};

      &:hover {
        background: ${colors.transparent};
      }
    }

    &.flat {
      background: ${colors.transparent};
      border: none;

      ${({ onClick }) =>
    onClick &&
    css`
          cursor: pointer;
          :hover {
            background: #e7e8ed;
          }
        `}
    }
  }

  &.iconWallet {
    width: 50px;
    height: 50px;
    padding: 12px 10px;
    background-color: ${colors.mainGray};
    border: none;

    ${({ onClick }) =>
    onClick &&
    css`
        cursor: pointer;
        :hover {
          background: #e7e8ed;
        }
      `}
    ${({ selected }) =>
    selected &&
    css`
        background-color: ${colors.newBlue};
        border: none;

        :hover {
          background: ${colors.newBlue};
        }

        svg {
          color: ${colors.white};
        }
      `}

    &.outlined {
      background: ${colors.transparent};
      border: 1px solid ${transparentize(0.7, colors.grayLight)};

      &:hover {
        background: ${colors.transparent};
      }
    }

    &.flat {
      background: ${colors.transparent};
      border: none;

      ${({ onClick }) =>
    onClick &&
    css`
          cursor: pointer;
          :hover {
            background: #e7e8ed;
          }
        `}
    }
  }

  &.icon24v2 {
    width: 50px;
    height: 50px;
    padding: 13px;
    background-color: ${colors.mainGray};

    ${({ onClick }) =>
    onClick &&
    css`
        cursor: pointer;
        :hover {
          background: #e7e8ed;
        }
      `}

    ${({ selected }) =>
    selected &&
    css`
        background-color: ${colors.newBlue};
        border: none;

        svg {
          color: ${colors.white};
        }

        :hover {
          background: ${colors.newBlue};
        }
      `}


    &.outlined {
      background: ${colors.transparent};
      border: 1px solid ${transparentize(0.7, colors.grayLight)};

      &:hover {
        background: ${colors.transparent};
      }
    }

    &.flat {
      background: ${colors.transparent};
      border: none;

      ${({ onClick }) =>
    onClick &&
    css`
          cursor: pointer;
          :hover {
            background: #e7e8ed;
          }
        `}
    }
  }
`;
