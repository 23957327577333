import * as React from 'react';

import { SVGProps } from 'react';

const BtcCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="M27.672 18.42a3.352 3.352 0 0 0 1.642-1.174 3.4 3.4 0 0 0 .679-1.911c.153-2.176-2.191-3.491-4.127-4.07l.647-3.188-1.946-.403-.642 3.154-1.302-.27.638-3.155L21.314 7l-.639 3.157-3.917-.814-.412 2.04 1.459.303c.653.135.932.397.825.93l-1.778 8.738c-.148.73-.554.878-.943.797l-1.488-.308L14 23.92l3.783.784-.657 3.23 1.961.404.655-3.23 1.306.27-.657 3.23 1.906.393.654-3.23.417.087c4.002.83 5.738-.873 6.285-3.57a3.598 3.598 0 0 0-.335-2.274 3.552 3.552 0 0 0-1.641-1.597l-.005.004Zm-5.49-5.695 1.39.288c.972.2 2.865.765 2.528 2.364-.07.51-.33.972-.727 1.295-.396.323-.9.48-1.409.441l-2.57-.531.789-3.857Zm.103 10.54v-.014l-2.148-.445.856-4.207 2.499.51c.585.12 2.74.772 2.42 2.342-.317 1.57-1.537 2.25-3.614 1.818l-.013-.004Z"
      fill="#FFB251"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BtcCoinSVG;
