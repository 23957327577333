import * as Text from '~styles/text';
import DangerError from '~assets/images/icons/danger.svg';
import { GenericButton } from '~components';
import { breakpoints } from '~styles/metrics';
import { colors } from '~styles';
import useWindowDimensions from '~hooks/windowDimension';
import { handleOnClickHelp } from '~helpers/generic';

export const UserBlocked = () => {
  const { width } = useWindowDimensions();

  return (
    <>
      <div className="medium-icon">
        <DangerError />
      </div>
      <Text.Heading1Medium marginTop={20} marginBottom={30}>
        Atualização de senha não foi concluída
      </Text.Heading1Medium>

      <Text.Heading5
        color={colors.black}
        marginBottom={30}
        lineHeight={140}
        width={breakpoints.desktop < width ? '100%' : ''}
      >
        Limite de tentativas atingido, caso precise de ajuda para concluir essa
        etapa, sinta-se a vontade para entrar em contato com nosso suporte.
      </Text.Heading5>

      <div className="buttons-wrapper">
        <GenericButton
          buttonType="default"
          width="auto"
          onClick={handleOnClickHelp}
        >
          Precisa de ajuda?
        </GenericButton>
      </div>
    </>
  );
};
