import { SVGProps } from 'react';

const DeleteSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75 8.5C2.75 5.60051 5.10051 3.25 8 3.25C10.8995 3.25 13.25 5.60051 13.25 8.5C13.25 11.3995 10.8995 13.75 8 13.75C5.10051 13.75 2.75 11.3995 2.75 8.5ZM8 1.75C4.27208 1.75 1.25 4.77208 1.25 8.5C1.25 12.2279 4.27208 15.25 8 15.25C11.7279 15.25 14.75 12.2279 14.75 8.5C14.75 4.77208 11.7279 1.75 8 1.75ZM5 9.25H11V7.75H5V9.25Z"
      fill={props.fill || '#FF6378'}
    />
  </svg>
);

export default DeleteSVG;
