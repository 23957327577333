import * as Text from '~styles/text';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { useFormContext } from 'react-hook-form';
import InputCurrency from '~components/Inputs/InputCurrency';
import { formatCurrencyFn } from '~helpers/format/currency';
import WalletIcon from '~assets/images/icons/16x16/wallet.svg';
import colors from '~styles/colors';
import WarningIcon from '~assets/svg/warning';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { getSelectedUserOrganization } from '~helpers/device';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import { When } from 'react-if';
import { ErrorToast } from '~components/Toast';
import { RefundProps } from '../Refund.types';
import { formatDataShowMonth } from '~validations/stringsValidate';
import dayjs from 'dayjs';

const AmountToRefund = ({
  setCurrentRefundStep,
  transactionDetails,
}: RefundProps) => {
  const userOrganizationId = getSelectedUserOrganization();
  const { getValues, register, watch } = useFormContext();
  const formValues = getValues();

  const { data: accountInfos, isLoading: loadingCapAccount } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  const valueParsed = watch('amount')
    ?.replace('R$ ', '')
    ?.replace(/\./g, '')
    ?.replace(',', '.');

  const handleNext = () => {
    if (Number(valueParsed) > Number(transactionDetails?.data?.value) / 100) {
      ErrorToast({
        title: 'Valor inserido é maior que o recebido na transferência.',
        autoCloseEnable: true,
      });
      return;
    }
    setCurrentRefundStep('confirmDetails');
  };

  const noEnoughFunds =
    Number(accountInfos?.available) / 100 < Number(valueParsed);
  const noEnoughFundsToRefound =
    Number(accountInfos?.available) / 100 <
    Number(transactionDetails?.data?.value) / 100;
  const disableNext = Number(onlyNumbers(formValues?.amount)) <= 0;
  const dateAdd90 = dayjs(transactionDetails?.timestamp).add(90, 'day');

  return (
    <>
      <ModalMultiStepHeader
        title="Qual valor a ser reembolsado?"
        description={`Realize o reembolso parcial ou integral até o dia ${formatDataShowMonth(
          String(dateAdd90)
        )}.`}
      />

      <div className="content-container">
        <InputCurrency {...register('amount')} placeholder="R$ 0,00" />

        <When condition={noEnoughFundsToRefound}>
          <div className="error-message">
            <WarningIcon />
            <Text.Heading5 color={colors.danger} fontSize={0.8125}>
              Você não possui saldo disponível para realizar o reembolso
              integral.
            </Text.Heading5>
          </div>
        </When>

        <div className="container-amount-wallet">
          <div className="icon-amount-wallet">
            <WalletIcon />

            <Text.Heading5 marginTop={4} marginLeft={10}>
              Saldo disponível
            </Text.Heading5>
          </div>

          <Text.Heading5>
            {formatCurrencyFn(
              'BRL',
              accountInfos?.available,
              false,
              false,
              true
            )}
          </Text.Heading5>
        </div>

        <ModalMultiStepBottom
          singleButtonText="Próximo"
          singleButtonWidth="100%"
          singleButtonAction={() => handleNext()}
          disableSingleButton={
            disableNext || loadingCapAccount || noEnoughFunds
          }
        />
      </div>
    </>
  );
};

export default AmountToRefund;
