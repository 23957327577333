import * as Styles from './Settings.styles';
import * as Text from '~styles/text';

import Container from '~components/Container';
import NotificationsSettings from './TabsScreens/Notifications/NotificationsSettings.screen';
import React, { useEffect, useState } from 'react';
import SecuritySettings from './TabsScreens/Security/SecuritySettings.screen';
import Tabs from '~components/Tabs';
import UsersSettings from './TabsScreens/Users/UsersSettings.screen';
import LimitsSettings from './TabsScreens/Limits/LimitsSettings.screen';
import OrganizationsSettings from './TabsScreens/Organizations/OrganizationsSettings.screen';
import { usePermission } from '~helpers/permissionGate';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { useLocation } from 'react-router-dom';
import YourProfile from './TabsScreens/YourProfile/YourProfile.screen';

const Settings: React.FC = () => {
  const userOrganizationId = getSelectedUserOrganization();
  const { granted: settingsGranted } = usePermission('twofaSection');
  const [preSelectedTab, setPreSelectedTab] = useState('Dados da Organização');
  const location = useLocation();

  const { data: currentUser } = useGetPartner();

  const isCurrentUserAdmin = currentUser?.data?.userOrganizations.filter(
    (org) => org?.id === Number(userOrganizationId)
  )[0]?.admin;

  useEffect(() => {
    if (location.search.includes('notif')) {
      setPreSelectedTab('Notificações');
    }
  }, []);

  return (
    <Container>
      <Styles.SettingsContainer>
        <Text.Heading1Bold marginBottom={25}>Configurações</Text.Heading1Bold>

        <Tabs
          width="100%"
          borderBottom
          selectedTab={preSelectedTab}
          ulSize={29}
          height={44}
          tabs={[
            {
              tabName: 'Dados da Organização',
              tabContent: (
                <Styles.TabContainer>
                  <OrganizationsSettings />
                </Styles.TabContainer>
              )
            },
            {
              tabName: 'Seu perfil',
              tabContent: (
                <Styles.TabContainer>
                  <YourProfile />
                </Styles.TabContainer>
              )
            },
            {
              tabName: 'Limites',
              tabContent: (
                <Styles.TabContainer>
                  <LimitsSettings />
                </Styles.TabContainer>
              ),
            },
            {
              tabName: 'Usuários',
              tabContent: (
                <Styles.TabContainer>
                  <UsersSettings />
                </Styles.TabContainer>
              ),
              disable: !isCurrentUserAdmin,
            },
            {
              tabName: 'Notificações',
              tabContent: (
                <Styles.TabContainer>
                  <NotificationsSettings />
                </Styles.TabContainer>
              ),
            },
            {
              tabName: 'Segurança',
              tabContent: (
                <Styles.TabContainer>
                  <SecuritySettings />
                </Styles.TabContainer>
              ),
              disable: !settingsGranted,
            },
            {
              tabName: 'Plano',
              tabAction: () => { },
              disable: true,
              tabContent: null,
            },
          ]}
        />
      </Styles.SettingsContainer>
    </Container>
  );
};

export default Settings;
