import { gql, request, useMutation } from '~reactQuery/index';

import { CAPACCOUNT_QUERY_KEYS } from '~src/constants/capaccount';
import { getSettingsId } from '~helpers/device';
import { useQueryClient } from 'react-query';

interface RealizeTransactionInput {
  amount: string;
  amountInCurrency: string;
  toWallet: string;
  fromWallet: string;
  pin: string;
  schedule?: string;
  senderPays?: boolean;
  comments?: string;
  userOrganizationId: number;
  token?: string;
}

interface RealizeTransactionResponse {
  realizeTransaction: {
    data: string;
  };
}

export const useRealizeTransaction = () => {
  const queryClient = useQueryClient();
  const organization = getSettingsId();

  const QUERY = gql`
    mutation RealizeTransaction($params: RealizeTransactionInput!) {
      realizeTransaction(params: $params)
    }
  `;

  const fetcher = async (params: RealizeTransactionInput) => {
    const response = await request<RealizeTransactionResponse>(QUERY, {
      params: {
        ...params,
        settingsId: Number(organization),
      },
    });
    return response.realizeTransaction;
  };

  return useMutation(['RealizeTransaction'], fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getWallets']);
      queryClient.invalidateQueries([
        CAPACCOUNT_QUERY_KEYS.GET_CAPACCOUNT_INFO,
      ]);
    },
  });
};
