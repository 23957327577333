import { colors } from '~styles';
import { transparentize } from 'polished';
import { GeneralStyleProps } from 'src/@types/general';
import styled from 'styled-components';

export const Container = styled.div<GeneralStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 210px;
  width: ${({ width }) => width || '210px'};
  height: 210px;
  border: 1px solid ${transparentize(0.9, colors.black)};
  background-color: ${colors.white};
  border-radius: 10px;
  user-select: none;
  cursor: pointer;
  transition: background-color 0.3s;

  .circle {
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: 50%;
    transition: background-color 0.3s;

    background-color: ${colors.gray};

    align-items: center;
    justify-content: center;
  }

  :hover {
    background-color: ${colors.gray};

    .circle {
      background-color: #e7e8ed;
    }
  }
`;
