import * as React from 'react';
import { SVGProps } from 'react';

const WalletAxsSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#axs)">
      <path
        d="M25.294 16.046a2.316 2.316 0 0 1 0 1.908 18.707 18.707 0 0 1-9.34 9.34 2.316 2.316 0 0 1-1.908 0 18.708 18.708 0 0 1-9.34-9.34 2.316 2.316 0 0 1 0-1.908 18.708 18.708 0 0 1 9.34-9.34 2.316 2.316 0 0 1 1.908 0 18.708 18.708 0 0 1 9.34 9.34Z"
        fill="#0055D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.936 13.222s-1.126-.9-1.204 1.323c-.077 1.722-.075 5.067.722 6.647.337.594.787.412 1.624-.18.729-.538 3.364-2.966 4.479-4.031 1.115-1.065 3.378-4.121 3.849-2.045.186.873.416 1.614.24 3.55-.054.596-.31 1.048-1.473.24-.879-.565-1.714-1.172-1.714-1.172s-.161-.288-.542.18c-.3.375-.45.467.03.752.48.284 2.915 1.963 3.848 2.224.77.224 1.21.22 1.233-1.293-.004-1.201-.073-4.64-.51-6.105-.332-.912-.817-1.327-1.866-.301-1.048 1.025-4.355 4.232-6.163 5.684-1.2.9-1.712 1.712-2.164.211-.318-1.136-.73-4.673.571-4 .814.39 1.22.388.902.633-.318.243-1.232 1.082-1.232 1.082s-.283.228-.151.722c.132.345.132.517.841-.15.71-.668 1.233-1.173 1.233-1.173s.113-.178.45.15c.337.33.391.422.391.422s.09.244.45-.18c.362-.425.595-.685-.09-1.082-.682-.402-3.754-2.108-3.754-2.108Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="axs">
        <path fill="#fff" transform="translate(4.5 6.5)" d="M0 0h21v21H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default WalletAxsSVG;
