import * as Style from './Refund.styles';
import { FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import ModalMultiStep from '~components/ModalMultiStep';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import AmountToRefund from './ModalSteps/1.AmountToRefund.component';
import ConfirmDetails from './ModalSteps/2.ConfirmDetails.component';
import InsertPin from './ModalSteps/3.insertPin.component';
import Status from './ModalSteps/4.status.component';
import { TransactionDetailsTypes } from './Refund.types';

const Refund = () => {
  const { openModalMultiStep, setOpenModalMultiStep, params } =
    useActionsOnModalMultiStep();
  const [currentRefundStep, setCurrentRefundStep] =
    useState<string>('amountToRefund');

  const [transactionDetails, setTransactionDetails] =
    useState<TransactionDetailsTypes>(params?.transactionDetails);

  const transferFormMethods = useForm({
    shouldUnregister: false,
  });

  const refundStepsComponents = {
    amountToRefund: {
      component: (
        <AmountToRefund
          key="amountToRefund"
          setCurrentRefundStep={setCurrentRefundStep}
          transactionDetails={transactionDetails}
        />
      ),
      progressValue: 10,
    },
    confirmDetails: {
      component: (
        <ConfirmDetails
          key="confirmDetails"
          setCurrentRefundStep={setCurrentRefundStep}
          transactionDetails={transactionDetails}
        />
      ),
      progressValue: 40,
    },
    insertPin: {
      component: (
        <InsertPin
          key="insertPin"
          setCurrentRefundStep={setCurrentRefundStep}
          transactionDetails={transactionDetails}
        />
      ),
      progressValue: 70,
    },
    status: {
      component: (
        <Status
          key="status"
          setCurrentRefundStep={setCurrentRefundStep}
          transactionDetails={transactionDetails}
        />
      ),
      progressValue: 100,
    },
  };

  const refundStepIfons =
    refundStepsComponents[
      currentRefundStep as keyof typeof refundStepsComponents
    ];

  const progressBar = () => {
    return refundStepIfons.progressValue || 0;
  };

  useEffect(() => {
    setTransactionDetails(params?.transactionDetails);
  }, [params?.transactionDetails]);

  return (
    <Style.Container>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={progressBar()}
        onClose={() => {
          transferFormMethods?.reset();
          setCurrentRefundStep('amountToRefund');
          setOpenModalMultiStep(false);
        }}
      >
        <FormProvider {...transferFormMethods}>
          {refundStepIfons.component}
        </FormProvider>
      </ModalMultiStep>
    </Style.Container>
  );
};

export default Refund;
