import { gql, request, useQuery } from '~reactQuery';

import { BrlBank } from '~reactQuery/types/bank.types';

export interface GetBusinessDataResponse {
  brlBanks: {
    data: [BrlBank];
    messages: [string];
    isValid: boolean;
  };
}

export const useGetBrlBanks = () => {
  const QUERY = gql`
    query BrlBanks {
      brlBanks {
        data {
          bankIspb
          bankCompe
          bankName
          bankShortName
          SPB
          SPI
        }
        messages
        isValid
      }
    }
  `;

  const fetcher = async () => {
    const response = await request<GetBusinessDataResponse>(QUERY);
    return response.brlBanks.data;
  };

  return useQuery(['useGetBrlBanks'], fetcher);
};
