import React, { SVGProps } from 'react';

function AdminSVG(props: SVGProps<SVGSVGElement>) {
  const { fill = 'none', stroke = '#11D593', height = 24, width = 24 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M17.6237 17.7805C17.5706 17.8357 17.5153 17.8888 17.4601 17.942C15.9212 19.3194 13.9129 20.0547 11.8485 19.9968C9.78398 19.9389 7.82015 19.0921 6.36086 17.6305C4.90156 16.169 4.05772 14.2038 4.00288 12.1392C3.94804 10.0745 4.68636 8.06729 6.06601 6.53033L6.22751 6.36457L6.39113 6.20732C6.457 6.14144 6.52713 6.07981 6.60363 6.01819C7.54261 5.18874 8.66211 4.58974 9.87315 4.2688C11.0842 3.94785 12.3534 3.91381 13.5799 4.16937L13.293 6.50695C13.2561 6.80171 13.1102 7.07199 12.8841 7.26467C12.658 7.45735 12.368 7.5585 12.0712 7.54824C11.1659 7.521 10.2733 7.76637 9.50911 8.25255C8.74495 8.73873 8.14446 9.44329 7.78552 10.2749C7.42658 11.1065 7.32574 12.0267 7.49607 12.9163C7.6664 13.8059 8.10005 14.6238 8.74079 15.2639C9.38153 15.9041 10.1998 16.3369 11.0895 16.5064C11.9792 16.6759 12.8993 16.5741 13.7305 16.2144C14.5617 15.8546 15.2656 15.2534 15.7511 14.4887C16.2365 13.7241 16.481 12.8312 16.4529 11.9259C16.4432 11.6301 16.5444 11.3415 16.7367 11.1166C16.9289 10.8917 17.1983 10.7468 17.492 10.7103L19.8294 10.4213C20.0863 11.65 20.0527 12.9217 19.7314 14.1351C19.4101 15.3485 18.8099 16.4702 17.9786 17.4107C17.917 17.4808 17.8553 17.5488 17.7916 17.6232C17.7278 17.6976 17.6875 17.7273 17.6237 17.7805Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default AdminSVG;
