import * as React from 'react';

import { SVGProps } from 'react';

const BttCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="M32.835 15.813a10.983 10.983 0 0 0-.645-2.07 10.962 10.962 0 0 0-2.364-3.507 10.96 10.96 0 0 0-3.507-2.364 10.981 10.981 0 0 0-8.576 0 10.96 10.96 0 0 0-3.507 2.365c-.5.498-.95 1.043-1.346 1.628a11.563 11.563 0 0 0-1.018 1.878 10.982 10.982 0 0 0 0 8.576 10.986 10.986 0 0 0 2.364 3.507 10.957 10.957 0 0 0 3.507 2.364 10.982 10.982 0 0 0 8.576 0 10.957 10.957 0 0 0 3.507-2.364 10.957 10.957 0 0 0 2.364-3.507 10.982 10.982 0 0 0 .645-6.505ZM22.04 27.681c-5.328 0-9.638-4.322-9.638-9.639 0-5.317 4.322-9.64 9.638-9.64 5.317.001 9.64 4.323 9.64 9.64s-4.322 9.639-9.64 9.639Z"
      fill="#000"
    />
    <path
      d="M22.212 26.844h.26c.046 0 .09 0 .135-.012h.012c.046 0 .079 0 .124-.011h.034c.034 0 .069 0 .102-.011h.034c.034 0 .069-.011.114-.011h.022c.045 0 .09-.012.136-.012.045 0 .08-.011.125-.022h.022c.034 0 .068-.012.101-.012h.023c.046-.012.08-.012.125-.023.09-.01.18-.034.26-.056h.023c.033-.011.078-.011.113-.023h.01c.091-.022.17-.045.261-.068a10.116 10.116 0 0 1-2.522-.237c-1.245-.272-2.433-.758-3.44-1.596a6.97 6.97 0 0 1-2.51-5.418 6.585 6.585 0 0 1 1.549-4.209c1.29-1.527 3.122-2.33 5-2.376h.26v-1.504h-.294c-.57.01-1.14.079-1.697.203a8.16 8.16 0 0 0-1.629.555c-.532.25-1.03.554-1.494.905a7.96 7.96 0 0 0-1.3 1.244 7.699 7.699 0 0 0-.94 1.392 7.955 7.955 0 0 0-.622 1.504 8.213 8.213 0 0 0-.305 1.573l-.034.497v.294c.011.578.08 1.143.203 1.698.125.566.317 1.108.555 1.63.283.621.792 1.538 1.55 2.228a8.75 8.75 0 0 0 5.43 1.878h.204Z"
      fill="#000"
    />
    <path
      d="M24.678 25.395c-.558-.02-1.114-.08-1.663-.18-2.33-.42-4.333-1.845-5.068-3.994-.894-2.614.475-5.442 3.077-6.3a4.814 4.814 0 0 1 1.538-.25 5.2 5.2 0 0 1 1.947.384l.633-1.346c-.19-.076-.383-.148-.577-.214a6.666 6.666 0 0 0-2.003-.306 6.347 6.347 0 0 0-2.002.328 6.292 6.292 0 0 0-2.228 1.29 6.48 6.48 0 0 0-.848.916 6.379 6.379 0 0 0-1.279 3.44 6.43 6.43 0 0 0 .339 2.533 6.255 6.255 0 0 0 1.358 2.252c.554.6 1.2 1.052 1.98 1.47.814.43 1.821.656 2.872.804.68.09 1.585.113 2.116.113.464-.17.656-.25.905-.351.216-.102.43-.204.645-.328.216-.125.25-.148.611-.384a.635.635 0 0 0 .09-.069c-1.244.215-1.56.203-2.443.192Z"
      fill="#000"
    />
    <path
      d="M27.462 23.71c-.352.022-.85.056-1.415.056-1.007 0-2.24-.09-3.292-.441-1.63-.542-3.168-1.934-3.168-3.654a3.097 3.097 0 0 1 3.1-3.1c1.165 0 2.172.645 2.704 1.584l1.346-.667a4.374 4.374 0 0 0-.804-1.075 4.589 4.589 0 0 0-7.49 1.49 4.588 4.588 0 0 0-.35 1.757c.003.632.139 1.256.398 1.833.236.52.565 1.007.983 1.46.736.79 1.742 1.424 2.817 1.786 1.03.34 2.546.464 4.017.363a8.426 8.426 0 0 0 1.232-.181 8.485 8.485 0 0 0 1.358-1.347c-.35.035-.996.102-1.436.136Z"
      fill="#000"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BttCoinSVG;
