import { Business, Status, User } from '~reactQuery/types/business.types';
import { gql, request, useQuery } from '~reactQuery';

export interface GetProcessInput {
  userId: number;
  documentNumber: string;
}

export interface GetProcessResponse {
  getProcess: {
    id: number;
    userId: number;
    createdAt: string;
    updatedAt: string;
    deleted: boolean;
    deletedAt: string;
    processStatus: Status;
    business: Business[];
    user: User;
  }[];
}

export const useGetProcess = (disableQuery?: boolean) => {
  const QUERY = gql`
    query GetProcess($params: ProcessInput!) {
      getProcess(params: $params) {
        id
        userId
        createdAt
        updatedAt
        deleted
        deletedAt
        processStatus
        business {
          id
          documentNumber
          businessName
          tradingName
          businessType
          businessSize
          businessCnae
          countryCode
          phone
          site
          businessEmail
          zipCode
          addressLine
          buildingNumber
          complement
          isCryptoTrader
          neighborhood
          city
          state
          country
          numberOfPartners
          createdAt
          updatedAt
          deleted
          deletedAt
          status
          companyStartDate
          declaredAnnualBilling
          participationPercentage
          businessData {
            id
            fileCNPJ
            fileQSA
            incomes {
              id
              income
              createdAt
              updatedAt
              deleted
              deletedAt
              businessDataId
            }
            createdAt
            updatedAt
            deleted
            deletedAt
            status
            socialContract
            lastContractChange
            socialStatute
            lastElection
            preventionPolicy
            proofOfRegularity
            accountingBalance
          }
          partnerData {
            id
            legalRepresent
            documentNumber
            fileFrontDocument
            fileBackDocument
            registerName
            socialName
            countryCode
            number
            zipCode
            addressLine
            buildingNumber
            complement
            neighborhood
            city
            state
            country
            birthDate
            motherName
            email
            documentType
            createdAt
            updatedAt
            deleted
            deletedAt
            status
            gender
            businessId
            memberQualification
            participationPercentage
            isPowerOfAttorney
            powerOfAttorney
            powerOfAttorneyStartDate
            powerOfAttorneyEndDate
            declaredIncome
            occupation
            pep
          }
          fields {
            id
            field
            description
            status
            integratorHasApproved
            businessId
            businessDataId
            partnerDataId
            value
          }
          businessPartnerId
          businessesPartners {
            id
            documentNumber
            businessName
            tradingName
            businessType
            businessSize
            businessCnae
            countryCode
            phone
            site
            businessEmail
            zipCode
            addressLine
            buildingNumber
            complement
            neighborhood
            city
            state
            country
            numberOfPartners
            createdAt
            updatedAt
            deleted
            deletedAt
            status
            companyStartDate
            declaredAnnualBilling
            participationPercentage
            businessPartnerId
          }
        }
        integratorStatus {
          id
          type
          status
          response
          processId
          createdAt
          updatedAt
        }
        user {
          id
          capu
          migrated
          registerName
          socialName
          phone
          email
          documentNumber
          deletedAt
          createdAt
          updatedAt
          active
          userOrganizations {
            id
            flags
            admin
            active
            createdAt
            updatedAt
            organizationsId
            organizations {
              id
              name
              country
              businessCode
              setup
              bankCompe
              branch
              account
              image
              deletedAt
              createdAt
              updatedAt
              walletsInternalCredentialsId
              banklyPartnersId
              walletsInternalCredentials {
                id
                internalPin
                sessionKey
                userBusinessId
              }
              partner {
                id
                companyKey
                grantType
              }
            }
          }
          settings {
            id
            enablePin
            twoFA
            language
            notificationFlags
            createdAt
            updatedAt
          }
        }
      }
    }
  `;

  const fetcher = async () => {
    if (disableQuery) return null;
    const response = await request<GetProcessResponse>(QUERY, {
      params: {
        documentNumber: localStorage.getItem('@CapBiz:user-documentNumber'),
        userId: Number(localStorage.getItem('@CapBiz:user-id')),
      },
    });
    return response.getProcess;
  };

  return useQuery(['getProcess'], fetcher);
};
