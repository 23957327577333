import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import * as Text from '~styles/text';
import WalletIcon from '~assets/images/icons/16x16/wallet.svg';

import {
  formatCurrencyFn,
  formatExponentialNumber,
} from '~helpers/format/currency';
import { CriptoWithdrawStepsProps } from '~src/common/modals/multiStep/Transfer/Transfer.types';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { WalletType } from '~reactQuery/types/wallets.types';
import { useFormContext } from 'react-hook-form';
import { CurrencyBigInputStyled } from '~src/common/modals/multiStep/Transfer/Transfer.styles';
import { getSelectedUserOrganization } from '~helpers/device';
import { useEffect, useState } from 'react';
import { useGetLimits } from '~reactQuery/queries/wallet/useGetLimits';
import colors from '~styles/colors';
import { useCurrencyExchange } from '~reactQuery/queries/wallet/useCurrencyExchange';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

const AmountToWithdraw = ({
  setCriptoWithdrawStep,
}: CriptoWithdrawStepsProps) => {
  const { params } = useActionsOnModalMultiStep();
  const isComingFromCapWallet = params?.comingFrom === 'capWallet';
  const goBack = () => setCriptoWithdrawStep('originWallet');
  const userOrganizationId = getSelectedUserOrganization();

  const [inputError, setInputError] = useState('');
  const [debounceLoading, setDebounceLoading] = useState(false);

  const { getValues, setValue, watch } = useFormContext();

  const originWallet = getValues('originWallet') as WalletType;
  const selectedWallet = getValues('selectedWallet') as WalletType;

  const selectedWalletIsoCode = selectedWallet.currency.isoCode;

  const originWalletIsoCode = originWallet.currency.isoCode;
  const originWalletFunds = originWallet.funds;

  const areSameWallets = selectedWalletIsoCode === originWalletIsoCode;

  const amount = watch('amount');

  const { data: convertedValue } = useCurrencyExchange(
    {
      amount: amount || '0',
      fromCurrency: originWalletIsoCode,
      toCurrency: selectedWalletIsoCode,
      userOrganizationId: Number(userOrganizationId),
    },
    Boolean(!areSameWallets && amount && !debounceLoading)
  );

  const handleGoToNextStep = () => {
    if (areSameWallets) {
      setValue('amountToWithdraw', amount);
    } else {
      setValue(
        'amountToWithdraw',
        formatExponentialNumber(
          String(convertedValue),
          selectedWallet.currency.precision
        )
      );
    }
    setCriptoWithdrawStep('review');
  };

  const { data: limits } = useGetLimits({
    action: 'crypto_withdraw',
    currency: originWalletIsoCode || '',
    userOrganizationId: Number(userOrganizationId),
  });

  const onChangeOriginWalletValue = (value: string) => {
    setInputError('');
    if (Number(value) > (originWallet?.funds || 0)) {
      setInputError('Saldo insuficiente');
      return;
    }

    if (Number(value) < (limits?.minLimit || 0)) {
      setInputError(
        `Abaixo do valor mínimo ${limits?.minLimit} ${originWalletIsoCode}`
      );
      return;
    }

    if (Boolean(limits?.maxLimit) && (limits?.maxLimit || 0) !== -1) {
      if (Number(value) > (limits?.maxLimit || 0)) {
        setInputError(
          `Valor acima do permitido ${limits?.maxLimit} ${originWalletIsoCode}`
        );
      }
    }
  };

  const onChangeInputValue = (value: any) => {
    setValue('amount', value);
    setTimeout(() => {
      setDebounceLoading(false);
    }, 1000);
    setDebounceLoading(true);
  };

  useEffect(() => {
    if (!debounceLoading) {
      onChangeOriginWalletValue(amount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceLoading, amount]);

  return (
    <>
      <ModalMultiStepHeader
        title={
          !isComingFromCapWallet
            ? 'Qual o valor deseja sacar?'
            : 'Qual o valor a ser enviado?'
        }
        backButtonAction={goBack}
      />
      <div className="cripto-withdraw-container-step">
        <Text.Paragraph marginBottom={20}>
          Transferindo para uma Carteira Externa
        </Text.Paragraph>

        <div className="currency-input-container">
          <CurrencyBigInputStyled
            lessPadding={!areSameWallets}
            onValueChange={onChangeInputValue}
            defaultValue={amount}
            autoComplete="off"
            allowNegativeValue={false}
            placeholder={`${originWalletIsoCode} 0.00`}
            decimalsLimit={Number(originWallet?.currency.precision)}
            intlConfig={{ locale: 'en-US' }}
            maxLength={11}
            prefix={`${originWalletIsoCode} `}
          />
          {!areSameWallets && (
            <Text.Paragraph className="convertedValue">
              {formatCurrencyFn(
                selectedWalletIsoCode,
                formatExponentialNumber(
                  String(convertedValue),
                  selectedWallet.currency.precision
                )
              ) || '0.00'}
            </Text.Paragraph>
          )}
        </div>

        {inputError && (
          <Text.Paragraph marginTop={10} color={colors.danger}>
            {inputError}
          </Text.Paragraph>
        )}

        <div className="row align-center justify-space-between mt-10">
          <div className="row align-center">
            <WalletIcon />

            <Text.Heading5 marginTop={2} marginLeft={10}>
              Saldo disponível
            </Text.Heading5>
          </div>

          <Text.Heading5>
            {formatCurrencyFn(
              originWalletIsoCode,
              String(originWalletFunds),
              false,
              false,
              true
            )}
          </Text.Heading5>
        </div>

        <ModalMultiStepBottom
          singleButtonText="Próximo"
          singleButtonWidth="100%"
          singleButtonAlignment="flex-end"
          disableSingleButton={!amount || !!inputError || debounceLoading}
          singleButtonAction={handleGoToNextStep}
        />
      </div>
    </>
  );
};

export default AmountToWithdraw;
