import dayjs from 'dayjs';

export function onlyLetters(str: string, withSpace?: boolean) {
  const RegExpression = withSpace ? /^[a-zA-Z\s]*$/ : /^[a-zA-Z]+$/;
  return RegExpression.test(str);
}

export const strongPasswordRegex =
  /(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g;

export const hasUpper = (str: string) => /[A-Z]/.test(str);
export const hasLowerCase = (str: string) => /[a-z]/.test(str);
export const hasNumber = (str: string) => /[0-9]/.test(str);
export const hasCharacterEspecial = (str: string) => /[^a-zA-Z 0-9]+/g.test(str);

export const transformValueBRLinEnglishFormat = (str: string) => {
  const formatValue = str.replace('R$', '').replace(/[.]/g, '');
  const value = formatValue.replace(',', '.');

  return value.replace(' ', '');
};

export const maskBillet = (value: string) => {
  return value.replace(/\B(?=(\d{12})+(?!\d))/g, ' ');
};

export const formatDataShowMonth = (data?: string) => {
  const date = data
    ? dayjs(data)?.format('ll')
    : dayjs()?.startOf('year')?.format('ll');

  const parts = date.split(' ');
  const lastIndexOf = parts.lastIndexOf('de');

  if (lastIndexOf !== -1) {
    parts.splice(lastIndexOf, 1);
  }

  const newData = parts.join(' ');
  const partsData = newData.split(' ');
  const month = partsData[2].substring(0, 3);
  return `${partsData[0]} ${partsData[1]} ${month}, ${partsData[3]}`;
};

export function removeNumbersFromString(textWithNumbers: string): string {
  return textWithNumbers?.replace(/\d+/g, '');
}

export const formatData = (data?:string) => {
  const date = data ? dayjs(data)?.format('ll') : dayjs()?.startOf('year')?.format('ll');

  const parts = date.split(' ');
  const lastIndexOf = parts.lastIndexOf('de');

  if (lastIndexOf !== -1) {
    parts.splice(lastIndexOf, 1);
  }

  const newData = parts.join(' ');
  const partsData = newData.split(' ');
  const month = partsData[2].substring(0, 3);
  return `${partsData[0]} ${partsData[1]} ${month}, ${partsData[3]}`;
};

export function containsOnlyNumbers(str:string) {
  return /^[0-9]+$/.test(str);
}
