import * as Text from '~styles/text';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import SupportSVG from '~assets/svg/support';
import AuthValidate from '~components/AuthValidate/AuthValidate.component';
import { useEffect, useState } from 'react';
import { useUserAuthTypes } from '~hooks/useUserAuthTypes';
import { parseError } from '~reactQuery/index';
import { useGetBrlBanks } from '~reactQuery/queries/bank/useGetBrlBank';
import { usePixTransferUsingPixKey } from '~reactQuery/mutations/pix/usePixTransferUsingPixKey';
import { usePixTransferUsingPixManual } from '~reactQuery/mutations/pix/usePixTransferUsingPixManual';
import { usePixTransferUsingTed } from '~reactQuery/mutations/pix/usePixTransferUsingTed';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { formatCurrencyFn } from '~helpers/format/currency';
import { PixKeys } from '~reactQuery/types/pix.types';
import { getSelectedUserOrganization, getSettingsId } from '~helpers/device';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import EndStepStatus from '~components/EndStepStatus/EndStepStatus.component';
import * as Style from '../TransferBatch.styles';
import colors from '~styles/colors';
import { ErrorToast } from '~components/Toast';
import {
  ConfirmPinProps,
  ListOfBeneficiariesTransferProps,
  RecipientListProps,
} from '../TransferBatch.types';
import { handleOnClickHelp } from '~helpers/generic';

const ConfirmPin = (props: ConfirmPinProps) => {
  const { params, setParams } = useActionsOnModalMultiStep();

  const { setStepTransferBatch } = props;
  const { balance } = params;

  const recipientList = params?.recipientsList as RecipientListProps;
  const beneficiariesList = recipientList?.listOfBeneficiaries;

  const { auth } = useUserAuthTypes();
  const userOrganizationId = getSelectedUserOrganization();
  const settingsId = getSettingsId();
  const [listTransfers, setListTransfers] =
    useState<ListOfBeneficiariesTransferProps>(beneficiariesList);
  const [transferNumber, setTransferNumber] = useState(1);
  const [progressBarNumber, setProgressBarNumber] = useState(0);
  const [stepConfirmPin, setStepConfirmPin] = useState('pin');
  const [hasError, setHasError] = useState({
    pin: '',
    twoFa: '',
  });

  const { data: bankList } = useGetBrlBanks();
  const { mutateAsync: makePixByKey, isLoading: loadingPix } =
    usePixTransferUsingPixKey();
  const { mutateAsync: makePixManual, isLoading: loadingManual } =
    usePixTransferUsingPixManual();
  const { mutateAsync: makePixTed, isLoading: loadingPixTed } =
    usePixTransferUsingTed();
  const isLoading = loadingPix || loadingManual || loadingPixTed;

  const delayFunction = async () => {
    await new Promise((resolve) => {
      setTimeout(resolve, 4000);
    });
  };

  const incrementProgressBar = () => {
    const max = 100;
    const numberOfTransfer = beneficiariesList?.length;
    const valueToIncrement = max / numberOfTransfer;

    setTimeout(() => {
      setProgressBarNumber((prevProgress) => prevProgress + valueToIncrement);
    }, 10);
  };

  const totalValue = beneficiariesList?.reduce((accumulator: number, item) => {
    const amount = parseFloat(
      item?.amount?.replace('R$ ', '').replace(',', '.')
    );
    return accumulator + amount;
  }, 0);

  const makeTransfer = async (pin: string, twoFa?: string) => {
    const totalValueTransfer =
      Number(
        formatCurrencyFn('BRL', String(totalValue))
          .replace('R$ ', '')
          ?.replace(/\./g, '')
          ?.replace(',', '.')
      ) * 100;

    const balanceParsed =
      Number(
        balance?.replace('R$ ', '')?.replace(/\./g, '')?.replace(',', '.')
      ) * 100;

    if (
      !listTransfers ||
      listTransfers.length === 0 ||
      !beneficiariesList ||
      beneficiariesList?.length === 0
    ) {
      ErrorToast({
        title: 'Não há beneficiário na lista.',
        autoCloseEnable: true,
      });
      return;
    }

    if (balanceParsed < totalValueTransfer) {
      ErrorToast({
        title: 'Não há saldo suficiente.',
        autoCloseEnable: true,
      });
      return;
    }

    if ((auth.includes('2-FA') && twoFa?.length !== 6) || pin.length !== 4) {
      return;
    }

    setStepConfirmPin('transferring');

    beneficiariesList.forEach(async (transfer, index) => {
      try {
        await delayFunction();
        setTransferNumber(index + 1);
        setListTransfers((prevListTransfers) => {
          const updatedList = prevListTransfers.map((item, i: number) => {
            if (i === index) {
              return {
                ...item,
                status: 'processing',
              };
            }
            return item;
          });
          return updatedList;
        });

        const valueParsed =
          Number(
            transfer?.amount
              ?.replace('R$ ', '')
              ?.replace(/\./g, '')
              ?.replace(',', '.')
          ) * 100;

        if (!String(valueParsed)) {
          return;
        }

        setHasError({
          pin: '',
          twoFa: '',
        });

        const translatorKeyType: { [key: string]: string } = {
          EMAIL: 'PIX',
          PHONE: 'PIX',
          CPF: 'PIX',
          EVP: 'PIX',
          CNPJ: 'PIX',
          TED: 'TED',
          'Pix Manual': 'PIX Manual',
        };

        const methodOfTransfer =
          translatorKeyType[transfer?.keyType || transfer?.transferType];

        const bankInfoByBankCode = bankList?.filter(
          (b) => b?.bankCompe === transfer?.bank
        )?.[0];

        const bankInfoByBankIspb = bankList?.filter(
          (b) => b?.bankIspb === transfer?.bankIspb
        )?.[0];

        const bankInfosFiltered = bankInfoByBankIspb || bankInfoByBankCode;

        let resp;

        const twoFA = twoFa ? { token: twoFa } : {};

        if (methodOfTransfer === 'PIX') {
          const keyType = String(
            transfer?.keyType || transfer?.transferType
          ).toLocaleLowerCase() as PixKeys;
          const key = String(transfer?.keyValue);

          resp = await makePixByKey({
            method: 'pix',
            value: Number(valueParsed.toFixed(2)),
            description: '',
            userOrganizationId: Number(userOrganizationId),
            pin,
            ...twoFA,
            requisite: {
              key: ['cpf', 'cnpj', 'phone'].includes(keyType)
                ? onlyNumbers(key)
                : key,
              keyType,
            },
          });
        }

        if (methodOfTransfer === 'TED' && bankInfosFiltered) {
          resp = await makePixTed({
            userOrganizationId: Number(userOrganizationId),
            value: Number(valueParsed.toFixed(2)),
            description: '',
            method: 'ted',
            requisite: {
              bank: {
                bankCompe: bankInfosFiltered?.bankCompe,
                bankIspb: bankInfosFiltered?.bankIspb,
                bankName: bankInfosFiltered?.bankName,
              },
              branch: transfer?.agency,
              account: onlyNumbers(transfer?.bankAccount),
              holderName: transfer?.fullName,
              holderDocument: onlyNumbers(transfer?.documentNumber),
              accountType: transfer?.accountType,
            },
            pin,
            ...twoFA,
          });
        }

        if (methodOfTransfer === 'PIX Manual' && bankInfosFiltered) {
          resp = await makePixManual({
            userOrganizationId: Number(userOrganizationId),
            value: Number(valueParsed.toFixed(2)),
            description: '',
            method: 'pix',
            settingsId: Number(settingsId),
            requisite: {
              key: {
                bank: {
                  bankName: bankInfosFiltered?.bankName,
                  bankIspb: bankInfosFiltered?.bankIspb,
                  bankCompe: bankInfosFiltered?.bankCompe,
                },
                branch: transfer?.agency,
                account: onlyNumbers(transfer?.bankAccount),
                holderName: transfer?.fullName,
                holderDocument: onlyNumbers(transfer?.documentNumber),
                accountType: transfer?.accountType,
              },
              keyType: 'bankaccount',
            },
            pin,
            ...twoFA,
          });
        }

        if (resp) {
          incrementProgressBar();
          setListTransfers((prevListTransfers) => {
            const updatedList = prevListTransfers.map((item, i: number) => {
              if (i === index) {
                return {
                  ...item,
                  status: 'completed',
                };
              }
              return item;
            });
            return updatedList;
          });
        }

        if (!resp) {
          incrementProgressBar();
          setListTransfers((prevListTransfers) => {
            const updatedList = prevListTransfers.map((item, i: number) => {
              if (i === index) {
                return {
                  ...item,
                  status: 'failed',
                };
              }
              return item;
            });

            return updatedList;
          });
        }
      } catch (error: any) {
        const errorKey = parseError(error);

        if (errorKey === 'INVALID_PIN') {
          setHasError((prev) => ({ ...prev, pin: 'Pin inválido' }));
          setStepConfirmPin('pin');
          return;
        }

        if (errorKey === 'INVALID_2FA_TOKEN') {
          setHasError((prev) => ({ ...prev, twoFa: '2-FA inválido' }));
          setStepConfirmPin('pin');
          return;
        }

        incrementProgressBar();
        setListTransfers((prevListTransfers) => {
          const updatedList = prevListTransfers.map((item, i: number) => {
            if (i === index) {
              return {
                ...item,
                status: 'failed',
              };
            }
            return item;
          });
          return updatedList;
        });

        if (errorKey === 'NOT_FOUND_PIX_KEY') {
          return;
        }
        console.log('error', error);
      }
    });
  };

  const translatorColor = (status: string) => {
    if (status === 'completed') {
      return colors.primary;
    }
    if (status === 'failed') {
      return colors.danger;
    }

    return colors.black;
  };

  const translatorStatus: { [key: string]: string } = {
    failed: 'Falha',
    processing: 'Processando...',
    completed: 'Concluído',
    queue: 'Na fila',
  };

  useEffect(() => {
    if (listTransfers && listTransfers.length > 0) {
      const allHaveStatus = listTransfers.every((item) => !!item?.status);
      const allStatusNotProcessingOrQueue = listTransfers.every(
        (item) => item?.status !== 'processing' && item?.status !== 'queue'
      );

      if (allHaveStatus && allStatusNotProcessingOrQueue && !isLoading) {
        setTimeout(() => {
          setParams({
            listTransfers,
            nameList: recipientList?.nameList,
          });
          setStepTransferBatch('statusPayment');
        }, 3000);
      }
    }
  }, [listTransfers, isLoading]);

  const translatorStepPin: { [key: string]: JSX.Element } = {
    pin: (
      <>
        <ModalMultiStepHeader
          title={`Transferindo ${formatCurrencyFn(
            'BRL',
            String(totalValue)
          )} para ${recipientList?.nameList || listTransfers?.[0]?.fullName}`}
          backButtonAction={() => setStepTransferBatch('confirmPaymentList')}
        />

        <div className="content-transfer-batch">
          <Text.Paragraph marginBottom={20}>
            {`   Para confirmar a transferência em lote preencha com seu código PIN
            de 4 dígitos ${auth?.includes('2-FA') ? 'e verificação 2FA.' : ''}`}
          </Text.Paragraph>

          <AuthValidate
            authTypes={auth}
            onChangeCodes={(pin, twoFa) => makeTransfer(pin, twoFa)}
            pinErrorMessage={hasError.pin}
            twoFaErrorMessage={hasError.twoFa}
          />
        </div>
      </>
    ),

    transferring: (
      <div className="transfer-batch-status-container">
        <EndStepStatus animType="success" />

        <Text.Heading1Bold marginTop={24}>
          Estamos transferindo: {transferNumber}/{listTransfers?.length}
        </Text.Heading1Bold>

        <Text.Heading5 marginTop={16}>
          Aguarde enquanto estamos realizando a sua transferência...
        </Text.Heading5>

        <Style.ProgressContainer>
          <div
            className="bar"
            style={{ width: `${progressBarNumber || 1}%` }}
          />
        </Style.ProgressContainer>

        <Style.TransferQueue>
          {listTransfers?.map((item) => (
            <div
              className="item-transfer"
              key={`${item?.fullName}-${item?.keyType}`}
            >
              <div className="left">
                <Text.Heading5Bold>
                  {item?.fullName || item?.holderName || item?.keyValue}
                </Text.Heading5Bold>
                <Text.Small
                  color={translatorColor(
                    item.status ? item.status : 'processing'
                  )}
                >
                  {translatorStatus[item?.status ? item?.status : 'queue']}
                </Text.Small>
              </div>
              <div className="right">
                <Text.Heading5Bold color={colors.grayMedium}>
                  {item?.amount}
                </Text.Heading5Bold>
              </div>
            </div>
          ))}
        </Style.TransferQueue>
      </div>
    ),
  };

  return (
    <>
      {translatorStepPin[stepConfirmPin]}

      <ModalMultiStepBottom
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        doubleButtonTextOne={
          <div className="button-row-transfer-batch">
            <SupportSVG />
            <Text.Heading5>Preciso de Ajuda</Text.Heading5>
          </div>
        }
        doubleButtonOneAction={handleOnClickHelp}
        singleButtonLoading={isLoading}
      />
    </>
  );
};

export default ConfirmPin;
