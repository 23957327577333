import { SVGProps } from 'react';

const ShieldFillSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.8515 11.179C27.8596 10.1619 27.8214 9.54565 27.8214 9.54565L15.7263 3.6001L3.6312 9.54565C3.6312 9.54565 2.6325 25.6703 11.9368 29.1078"
      stroke={props.stroke || '#272937'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2063 15.9354L21.7895 11.179L32.3727 15.9354C32.3727 15.9354 33.4676 32.4 21.7895 32.4C10.1115 32.4 11.2063 15.9354 11.2063 15.9354Z"
      fill={props.fill || '#FF6378'}
      stroke={props.fill || '#FF6378'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShieldFillSVG;
