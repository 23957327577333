import * as React from 'react';

import { SVGProps } from 'react';

const CalendarMonthIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width || '23'}
    height={props?.height || '24'}
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00196 0.0119629L7.00195 3.01196L5.00195 3.01196L5.00195 0.0119629H7.00196ZM7.00195 3.01196L3.75195 3.01196C1.68088 3.01196 0.00195312 4.6909 0.00195312 6.76196V19.262C0.00195312 21.333 1.68089 23.012 3.75195 23.012H18.252C20.323 23.012 22.002 21.333 22.002 19.262V6.76196C22.002 4.69089 20.323 3.01196 18.252 3.01196H15.7824L17.002 3.01196L17.002 0.0119629H15.002L15.002 3.01196H7.00195ZM2.01967 6.51198C2.008 6.59362 2.00195 6.67709 2.00195 6.76196V19.262C2.00195 20.2285 2.78545 21.012 3.75195 21.012H18.252C19.2185 21.012 20.002 20.2285 20.002 19.262V6.76196C20.002 6.67709 19.9959 6.59362 19.9842 6.51198H2.01967ZM4.00195 11.012H18.002V9.01196H4.00195V11.012ZM18.002 15.012H4.00195V13.012H18.002V15.012ZM4.00195 19.012H18.002V17.012H4.00195V19.012Z"
      fill={props?.fill || '#026FF4'}
    />
  </svg>
);

export default CalendarMonthIcon;
