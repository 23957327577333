import * as React from 'react';
import { SVGProps } from 'react';

const WalletDotSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.588 8.338a6.614 6.614 0 0 0-6.24 8.727c.171.47.68.722 1.158.577a.938.938 0 0 0 .56-1.155 4.347 4.347 0 0 1-.256-1.679 4.752 4.752 0 1 1 5.038 4.886s-.925.056-1.385.113c-.169.024-.337.057-.503.1a.06.06 0 0 1-.086 0h-.001a.06.06 0 0 1 0-.074l.143-.78.868-3.906a.903.903 0 1 0-1.766-.378s-2.065 9.56-2.065 9.647a.868.868 0 0 0 .643 1.058h.048a.867.867 0 0 0 1.057-.623l.006-.023a.222.222 0 0 1 0-.044c.025-.112.286-1.384.286-1.384a2.343 2.343 0 0 1 1.94-1.845c.199-.03 1.037-.087 1.037-.087a6.583 6.583 0 0 0-.482-13.13Z"
      fill="#000"
    />
    <path
      d="M15.987 23.526a1.098 1.098 0 0 0-1.298.851l-.004.017a1.093 1.093 0 0 0 .838 1.302h.03c.578.14 1.16-.216 1.299-.793l.003-.014v-.06a1.138 1.138 0 0 0-.868-1.303Z"
      fill="#FF4CAB"
    />
  </svg>
);

export default WalletDotSVG;
