import { SVGProps } from 'react';

const GlobeSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.4614 5.46919C4.51357 4.11864 6.15534 3.25 8 3.25C9.14413 3.25 10.2102 3.58416 11.106 4.16021C11.0254 4.30957 10.9196 4.41371 10.8118 4.49135C10.55 4.67984 10.2133 4.75 10 4.75H9.25V5.5C9.25 5.87186 9.1008 6.15285 8.89358 6.37112C8.67426 6.60214 8.4084 6.73997 8.26283 6.78849L7.75 6.95943V7.5V9V9.75H8.5H9.75V11V11.9149L10.6471 11.7354C11.4665 11.5716 11.9593 11.7519 12.2521 11.9607C12.4316 12.0887 12.56 12.2447 12.6458 12.3887C11.6458 13.7573 10.0558 14.6674 8.25 14.7447V12.5V12.1893L8.03033 11.9697L6.25 10.1893V9V8.72256L6.06944 8.51191L3.4614 5.46919ZM2.66669 6.84691C2.39796 7.51193 2.25 8.23868 2.25 9C2.25 11.7464 4.17541 14.043 6.75 14.6137V12.8107L4.96967 11.0303L4.75 10.8107V10.5V9.27744L2.66669 6.84691ZM13.4044 10.9679C13.628 10.354 13.75 9.69123 13.75 9C13.75 7.51347 13.1859 6.15869 12.2601 5.13805C12.0975 5.36457 11.9026 5.55429 11.6882 5.70865C11.3598 5.94509 11.0012 6.08854 10.6694 6.1678C10.5423 6.6782 10.2788 7.09061 9.98142 7.40388C9.75025 7.64738 9.49361 7.83726 9.25 7.97741V8.25H10.5H11.25V9V10.172C12.0119 10.1728 12.6364 10.3924 13.1229 10.7393C13.2241 10.8115 13.3178 10.8881 13.4044 10.9679ZM12.7499 3.52256C14.2815 4.85185 15.25 6.81282 15.25 9C15.25 13.0041 12.0041 16.25 8 16.25C3.99594 16.25 0.75 13.0041 0.75 9C0.75 4.99594 3.99594 1.75 8 1.75C9.80447 1.75 11.455 2.40923 12.7238 3.5H12.75C12.75 3.50754 12.75 3.51506 12.7499 3.52256Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default GlobeSVG;
