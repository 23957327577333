const PaymentSentSVG = (props: React.SVGProps<SVGSVGElement>) => {
  const { fill = 'none', stroke = '#757893', height = 24, width = 24 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill}>
      <path
        d="M4 14V15C4 17.7614 6.23858 20 9 20H15C17.7614 20 20 17.7614 20 15V14"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12 15L12 4"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.6572 8.65601L12.7074 3.70698C12.3168 3.31652 11.6837 3.31655 11.2932 3.70705L6.34326 8.65698"
        stroke={stroke}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default PaymentSentSVG;
