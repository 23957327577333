import * as Text from '~styles/text';
import RoundButtonIcon from '../RoundButtonIcon';
import * as Style from './ActionButton.styles';
import { QuickButtonProps } from './ActionButton.types';

const ActionButton = (props: QuickButtonProps) => {
  const { children, icon, marginBottom, marginTop, onClick } = props;

  return (
    <Style.Container
      marginTop={marginTop}
      marginBottom={marginBottom}
      onClick={props.disabled ? () => {} : onClick}
      disabled={props.disabled}
    >
      <RoundButtonIcon
        buttonSize="icon24v2"
        onClick={props.disabled ? () => {} : onClick}
        disabled={props.disabled}
      >
        {icon}
      </RoundButtonIcon>
      <Text.Paragraph marginTop={10} fontWeight={500}>
        {children}
      </Text.Paragraph>
    </Style.Container>
  );
};

export default ActionButton;
