import { Navigate, Route, Routes } from 'react-router-dom';

import ChangePassword from '~pages/Sign/ChangePassword';
import ForgotPassword from '~pages/Sign/ForgotPassword';
import Sign from '~pages/Sign';
import SignIn from '~pages/Sign/SignIn';
import SignUp from '~pages/Sign/SignUp';
import { TwoFaAuth, TwoFaAuthRecovery } from '~pages/Sign/SignIn/TwoFaAuth';
import GuestRegister from '~pages/Sign/GuestRegister';
import ForgetPasswordGuest from '~pages/Sign/ForgetPasswordGuest';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Sign />}>
        <Route path="sign-in" element={<SignIn />} />
        <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="sign-up?:userToken" element={<SignUp />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="forgot-password-guest" element={<ForgetPasswordGuest />} />

        <Route path="forgot-password?:userToken" element={<ForgotPassword />} />
        <Route path="two-fa-auth" element={<TwoFaAuth />} />
        <Route path="two-fa-auth/forgot" element={<TwoFaAuthRecovery />} />
        <Route path="guest-register" element={<GuestRegister />} />
      </Route>

      <Route path="*" element={<Navigate to="/sign-in" replace />} />
    </Routes>
  );
};
