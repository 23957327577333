import * as Style from './CapAccountAndWallets.styles';
import * as Text from '~styles/text';

import {
  AddWalletButton,
  CardWallet,
  Container,
  LoadingSpinner,
} from '~components';
import { Else, If, Then, When } from 'react-if';
import { formatCurrencyFn, getWalletIcon } from '~helpers/format/currency';
import CreateWallet from './CreateWallet';
import Wallet from '~components/WalletRowList/Wallet.component';
import { WalletType } from '~reactQuery/types/wallets.types';
import { breakpoints } from '~styles/metrics';
import colors from '~styles/colors';
import { currenciesList } from '~data/wallets';
import { getSelectedUserOrganization } from '~helpers/device';
import { useCurrenciesVariations } from '~reactQuery/queries/wallet/useCurrenciesVariations';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import { useEffect, useState } from 'react';
import useWindowDimensions from '~hooks/windowDimension';
import { PermissionGate, usePermission } from '~helpers/permissionGate';
import { useNavigate } from 'react-router-dom';

const CapAccountAndWallets: React.FC = () => {
  const { width } = useWindowDimensions();
  const [createWalletModalVisible, setCreateWalletModalVisible] =
    useState(false);
  const userOrganizationId = getSelectedUserOrganization();
  const { setOpenModalSideBar, setModalSidebarContent, setParams, params } =
    useOpenModalSideBar();
  const { granted: capAccountPageGranted } = usePermission('capAccountPage');
  const { granted: cryptoTransactionsGranted } =
    usePermission('criptoTransactions');
  const navigate = useNavigate();

  const { data: walletList, isLoading: loadingWallets } = useGetWallets({
    prefCurrency: 'BRL',
    flag: 'list',
    userOrganizationId: Number(userOrganizationId),
  });

  const { data: capAccountInfo, isLoading: loadingCapAcc } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  const { data: variationsList, isLoading: loadingVariations } =
    useCurrenciesVariations({
      convert: 'BRL',
      symbol: currenciesList,
      period: 'today',
    });

  const handleOpenCapConta = () => {
    if (params.walletAddress === 'capConta') {
      setModalSidebarContent('noContent');
      setOpenModalSideBar(false);
      setParams({});
      return;
    }

    setModalSidebarContent('capContaModal');
    setOpenModalSideBar(true);
    setParams({ walletAddress: 'capConta' });
  };

  const handleOpenWallet = (wallet: WalletType) => {
    const modalParams = params as WalletType;

    if (modalParams.walletAddress === wallet.walletAddress) {
      setModalSidebarContent('noContent');
      setOpenModalSideBar(false);
      setParams({});
      return;
    }

    setModalSidebarContent('walletModal');
    setOpenModalSideBar(true);

    setParams({ ...wallet, icon: getWalletIcon(wallet.currency.isoCode) });
  };

  const handleCreateWallet = () => {
    setCreateWalletModalVisible(!createWalletModalVisible);
  };

  const walletsWithFoundsNumber = walletList?.filter(
    (w) => w?.funds > 0
  )?.length;

  const hasWallets = walletList && walletList?.length > 0;

  const loadingPage = loadingWallets || loadingCapAcc;

  const modalCreateWalletIsVisible =
    createWalletModalVisible && cryptoTransactionsGranted;

  useEffect(() => {
    if (!capAccountPageGranted) {
      navigate('/');
    }
  }, []);

  return (
    <>
      <Style.Container>
        <Container>
          <Text.Heading1Bold marginBottom={breakpoints.tablet < width ? 0 : 25}>
            CapConta e Carteiras
          </Text.Heading1Bold>

          <If condition={!loadingPage}>
            <Then>
              <When condition={breakpoints.tablet < width}>
                <Text.Heading5
                  marginTop={10}
                  marginBottom={25}
                  display="inline-block"
                >
                  Você possui{' '}
                  <strong>{walletsWithFoundsNumber} carteiras</strong> com saldo
                  e CapConta ativas
                </Text.Heading5>
              </When>

              <Style.CardWalletsWrapper>
                <div className="capAccount">
                  <CardWallet
                    capConta
                    cardWidth="100%"
                    amount={formatCurrencyFn(
                      'BRL',
                      capAccountInfo?.available,
                      false,
                      false,
                      true
                    )}
                    selected={params.walletAddress === 'capConta'}
                    onClick={handleOpenCapConta}
                    infoText="Esta é a sua conta bancária do Capitual!"
                  />
                </div>
                <When condition={hasWallets}>
                  {walletList?.map((w) => {
                    if (w.funds <= 0) return null;
                    return (
                      <CardWallet
                        key={w.walletAddress}
                        loadingVariations={loadingVariations}
                        variationsList={variationsList || []}
                        wallet={w}
                        walletName={w.walletName}
                        amount={formatCurrencyFn(
                          w.currency.isoCode,
                          String(w.funds)
                        )}
                        iconWallet={getWalletIcon(w.currency.isoCode)}
                        selected={params.walletAddress === w.walletAddress}
                        cardWidth="100%"
                        onClick={() => handleOpenWallet(w)}
                      />
                    );
                  })}
                </When>
                <PermissionGate name="criptoTransactions">
                  <AddWalletButton onClick={handleCreateWallet} width="100%" />
                </PermissionGate>
              </Style.CardWalletsWrapper>
              <Wallet
                onlyNoFunds
                handleOpenWallet={handleOpenWallet}
                title="Carteiras sem saldo"
                walletList={walletList}
                loadingVariations={loadingVariations}
                variationsList={variationsList}
              />
            </Then>
            <Else>
              <div className="loading-container">
                <LoadingSpinner
                  color={colors.primary}
                  size={60}
                  strokeSize={25}
                />
              </div>
            </Else>
          </If>
        </Container>
      </Style.Container>

      <When condition={modalCreateWalletIsVisible}>
        <CreateWallet
          openModal={createWalletModalVisible}
          setOpenModal={setCreateWalletModalVisible}
        />
      </When>
    </>
  );
};

export default CapAccountAndWallets;
