import * as React from 'react';
import { SVGProps } from 'react';

const WalletDogeSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.889 24.99h5.357s7.532.63 7.532-7.861c0-8.163-6.878-8.134-8.208-8.129H9.888v7.123H8v1.744H9.89v7.122Zm3.017-13.034h2.114c.79 0 4.767.318 4.774 5.23.006 4.854-4.004 4.849-4.649 4.848h-2.239v-4.167h3.325v-1.744h-3.325v-4.167Z"
      fill="#C2A633"
    />
  </svg>
);

export default WalletDogeSVG;
