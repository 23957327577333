import { gql, request, useMutation } from '~reactQuery/index';

import { PIX_QUERY_KEYS } from '~src/constants/pix';
import { getSettingsId } from '~helpers/device';
import { useQueryClient } from 'react-query';

interface DeletePixKeyInput {
  userOrganizationId: number;
  value: string;
  documentNumber: string;
  pin: string;
  token?: string;
}

interface DeletePixKeyResponse {
  deletePixKey: {
    data: null;
    messages: string[];
    isValid: boolean;
  };
}

export const useDeletePixKey = () => {
  const organization = getSettingsId();
  const queryClient = useQueryClient();

  const QUERY = gql`
    mutation DeletePixKey($params: DeletePixKeyInput!) {
      deletePixKey(params: $params) {
        data {
          id
          type
          value
          createdAt
          updatedAt
          deletedAt
          userOrganizationId
        }
        messages
        isValid
      }
    }
  `;

  const fetcher = async (params: DeletePixKeyInput) => {
    const response = await request<DeletePixKeyResponse>(QUERY, {
      params: {
        ...params,
        settingsId: Number(organization),
      },
    });
    return response.deletePixKey.data;
  };

  return useMutation([PIX_QUERY_KEYS.DELETE_PIX_KEY], fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries([PIX_QUERY_KEYS.GET_PIX_KEY]);
    },
  });
};
