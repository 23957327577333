import * as Text from '~styles/text';
import * as Style from '../TransferBatch.styles';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import colors from '~styles/colors';
import Tooltip from '~components/Tooltip/Tooltip.component';
import InfoSVG from '~assets/svg/info';
import BankIcon from '~assets/svg/bank';
import { SearchRecipient } from './components/SearchRecipient/SearchRecipient.component';
import { SavedLists } from './components/savedLists/SavedLists.component';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { getSelectedUserOrganization } from '~helpers/device';
import { formatCurrencyFn } from '~helpers/format/currency';
import { useState, useEffect } from 'react';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { SelectRecipientsOrSeeListsProps } from '../TransferBatch.types';

const SelectRecipientsOrSeeLists = (
  handlers: SelectRecipientsOrSeeListsProps
) => {
  const {
    tabSelected,
    setTabSelected,
    setCloseModalVisible,
    listOfBeneficiaries,
    setListOfBeneficiaries,
    ...props
  } = handlers;
  const [modalAttachListIsVisible, setModalAttachListIsVisible] =
    useState(false);

  const userOrganizationId = getSelectedUserOrganization();
  const { params, setParams } = useActionsOnModalMultiStep();

  const { data: capAccountInfo, isLoading: loadingCapAccountInfo } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  const { setStepTransferBatch } = props;

  const propsTabSavedLists = {
    setModalAttachListIsVisible,
    setTabSelected,
    setStepTransferBatch,
  };

  const propsInBatch = {
    ...props,
    modalAttachListIsVisible,
    setModalAttachListIsVisible,
    listOfBeneficiaries,
    setListOfBeneficiaries,
  };

  const renderTotalValue = () => {
    if (loadingCapAccountInfo) return '0.00';

    if (!capAccountInfo?.available) return '0.00';

    if (capAccountInfo?.available) {
      return formatCurrencyFn(
        'BRL',
        Number.parseFloat(
          String(Number(capAccountInfo?.available) / 100)
        ).toFixed(2)
      );
    }

    return '0.00';
  };

  const redirectWhenOriginIsConfirmDetails = () => {
    if (
      params?.origin === 'confirmListAndValues' ||
      params?.origin === 'confirmPaymentList'
    ) {
      setTabSelected(params?.sendTo);

      return;
    }
    setTabSelected('inBatch');
    setParams({});
  };

  useEffect(() => {
    redirectWhenOriginIsConfirmDetails();
  }, [params?.origin, params?.sendTo, setParams]);

  const translatorTab: { [key: string]: JSX.Element } = {
    inBatch: <SearchRecipient {...propsInBatch} />,
    savedLists: <SavedLists {...propsTabSavedLists} />,
  };

  const handleChangeToSaveList = () => {
    if (listOfBeneficiaries?.length > 0) {
      setCloseModalVisible({ comingFrom: 'savedLists', visible: true });
      return;
    }
    setTabSelected('savedLists');
  };
  return (
    <>
      <ModalMultiStepHeader title="Transferências em Lote" />

      <div className="content-transfer-batch">
        <div className="row-title">
          <div className="left">
            <div className="circle">
              <BankIcon />
            </div>
            <Text.Heading5 marginLeft={8}>Conta BRL</Text.Heading5>
          </div>

          <div className="right">
            <Text.Heading5 color={colors.grayMedium} marginRight={10}>
              {renderTotalValue()}
            </Text.Heading5>

            <Tooltip
              position="left"
              content="Por enquanto somente a Conta BRL tem a opção de pagamento em lote."
              variant="default"
            >
              <InfoSVG />
            </Tooltip>
          </div>
        </div>

        <Style.TabMenu style={{ marginTop: 28, padding: 0 }}>
          <Text.Heading5
            onClick={() => setTabSelected('inBatch')}
            className={tabSelected === 'inBatch' ? 'active' : 'disabled'}
          >
            Em lote
          </Text.Heading5>

          <Text.Heading5
            onClick={() => handleChangeToSaveList()}
            className={tabSelected === 'savedLists' ? 'active' : 'disabled'}
          >
            Listas Salvas
          </Text.Heading5>
        </Style.TabMenu>

        {translatorTab[tabSelected]}
      </div>
    </>
  );
};

export default SelectRecipientsOrSeeLists;
