import { SVGProps } from 'react';

const BlueInfoAlert = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '18'}
      height={props.height || '19'}
      className={props.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0.00799561C4.02944 0.00799561 0 4.03743 0 9.008C0 13.9786 4.02944 18.008 9 18.008C13.9706 18.008 18 13.9786 18 9.008C18 4.03743 13.9706 0.00799561 9 0.00799561ZM10 9.00798V8.00798H8V9.00798V12.008V13.008H10V12.008V9.00798ZM8 5.50798C8 4.9557 8.44772 4.50798 9 4.50798C9.55229 4.50798 10 4.9557 10 5.50798C10 6.06027 9.55229 6.50798 9 6.50798C8.44772 6.50798 8 6.06027 8 5.50798Z"
        fill={props.color || '#026FF4'}
      />
    </svg>
  );
};

export default BlueInfoAlert;
