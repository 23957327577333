import { Fields, Incomes, Status } from '~reactQuery/types/business.types';
import { gql, request, useMutation } from '~reactQuery';

import { useQueryClient } from 'react-query';

interface PutBusinessDataInput {
  businessDataId: number;
  fileCNPJ: string;
  fileQSA: string;
  socialContract?: string;
  lastContractChange?: string;
  socialStatute?: string;
  lastElection?: string;
  preventionPolicy?: string;
  proofOfRegularity?: string;
  accountingBalance?: string;
}

interface PutBusinessDataResponse {
  putBusinessData: {
    data: {
      id: number;
      fileCNPJ: string;
      fileQSA: string;
      socialContract: string;
      lastContractChange: string;
      socialStatute: string;
      lastElection: string;
      preventionPolicy?: string;
      proofOfRegularity?: string;
      accountingBalance?: string;
      incomes: [Incomes];
      fields: [Fields];
      createdAt: string;
      updatedAt: string;
      deleted: boolean;
      deletedAt: string;
      status: Status;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function usePutBusinessDataMutation() {
  const queryClient = useQueryClient();

  const mutation = gql`
    mutation PutBusinessData($params: putBusinessDataInput!) {
      putBusinessData(params: $params) {
        data {
          id
          fileCNPJ
          fileQSA
          socialContract
          lastContractChange
          socialStatute
          lastElection
          preventionPolicy
          proofOfRegularity
          accountingBalance
          incomes {
            id
            income
            createdAt
            updatedAt
            deleted
            deletedAt
            businessDataId
          }
          fields {
            id
            field
            description
            status
            integratorHasApproved
            businessId
            businessDataId
            partnerDataId
          }
          createdAt
          updatedAt
          deleted
          deletedAt
          status
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: PutBusinessDataInput) => {
    const response = await request<PutBusinessDataResponse>(mutation, {
      params,
    });

    return response.putBusinessData;
  };

  return useMutation('PutBusinessData', fetch, {
    onSettled: () => queryClient.invalidateQueries(['getProcess']),
  });
}
