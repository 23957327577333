import * as Text from '~styles/text';

import CopyIcon from '~assets/images/icons/copy.svg';
import { DepositOptionsPageProps } from '../Deposit.types';
import GenericRow from '~components/GenericRow';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import PixKeySVG from '~assets/svg/pixKey';
import PixSVG from '~assets/svg/pix';
import { cnpjMask } from '~helpers/format/masks';
import colors from '~styles/colors';
import { getSelectedUserOrganization } from '~helpers/device';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { useGetPixKey } from '~reactQuery/queries/pix/useGetPixKey';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import { useNavigate } from 'react-router-dom';

function BankDeposit(props: DepositOptionsPageProps) {
  const { setCurrentStep } = props;
  const { setOpenModalMultiStep, setSection } = useActionsOnModalMultiStep();
  const { setOpenModalSideBar, setModalSidebarContent } = useOpenModalSideBar();

  const userOrganizationId = getSelectedUserOrganization();

  const { data: accountInfos } = useGetCapAccountInfo({
    userOrganizationId: Number(userOrganizationId),
  });

  const navigate = useNavigate();

  const { data: partnerInfos } = useGetPartner();
  const userDocumentNumber = partnerInfos?.data.documentNumber;

  const { data: pixKeys } = useGetPixKey({
    documentNumber: userDocumentNumber,
    userOrganizationId: Number(userOrganizationId),
  });

  const goBack = () => setCurrentStep('depositOptions');

  const redirectPix = () => {
    setOpenModalMultiStep(false);
    navigate('/dashboard/pixarea');
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Depósito bancário"
        description="As transferências Pix são instantâneas e estão disponíveis 24h por dia, enquanto os TEDs podem levar algumas horas."
        backButtonAction={goBack}
      />
      <div className="content-container">
        <div className="container-title">
          <PixKeySVG stroke={colors.primary} />
          <Text.Heading3>Chaves Pix cadastradas</Text.Heading3>
        </div>

        {pixKeys?.map((k) => (
          <GenericRow
            key={k.value}
            rightComponent={<CopyIcon />}
            title={`Chave ${k.type === 'EVP' ? 'Aleatória' : k.type}`}
            rightContentType="component"
            padding="18px 10px 18px 10px"
            description={k.value}
            onClick={() => {
              navigator.clipboard.writeText(
                `Chave ${k.type === 'EVP' ? 'Aleatória' : k.type}: ${k.value}`
              );
            }}
          />
        ))}

        <div className="container-title">
          <PixSVG width={24} height={24} />
          <Text.Heading3>TED ou Pix Manual</Text.Heading3>
        </div>

        <div className="row">
          <GenericRow
            rightComponent={<CopyIcon />}
            title="Agência"
            rightContentType="component"
            padding="18px 10px 18px 10px"
            description={accountInfos?.branch}
            onClick={() => {
              navigator.clipboard.writeText(`Agência: ${accountInfos?.branch}`);
            }}
          />

          <GenericRow
            rightComponent={<CopyIcon />}
            title="Conta Corrente"
            rightContentType="component"
            padding="18px 10px 18px 10px"
            description={accountInfos?.account}
            onClick={() => {
              navigator.clipboard.writeText(
                `Conta Corrente: ${accountInfos?.account}`
              );
            }}
          />
        </div>

        <GenericRow
          rightComponent={<CopyIcon />}
          title="CNPJ"
          rightContentType="component"
          padding="18px 10px 18px 10px"
          description={cnpjMask(accountInfos?.documentNumber || '')}
          onClick={() => {
            navigator.clipboard.writeText(
              `CNPJ ${cnpjMask(accountInfos?.documentNumber || '')}`
            );
          }}
        />

        <GenericRow
          rightComponent={<CopyIcon />}
          title="Instituição Financeira"
          rightContentType="component"
          padding="18px 10px 18px 10px"
          description="332 - Acesso Soluções de Pagamento S. A"
          onClick={() => {
            navigator.clipboard.writeText(
              'Instituição Financeira: 332 - Acesso Soluções de Pagamento S. A'
            );
          }}
        />
      </div>
      <ModalMultiStepBottom
        doubleButtonTextOne={
          <div className="double-button-one-container">
            <PixSVG />
            <Text.Heading5>Gerenciar chaves Pix</Text.Heading5>
          </div>
        }
        doubleButtonOneAction={() => redirectPix()}
        singleButtonText="Concluir"
        singleButtonWidth="1"
        singleButtonAction={() => setOpenModalMultiStep(false)}
      />
    </>
  );
}

export default BankDeposit;
