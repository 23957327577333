import * as Text from '~styles/text';

import { CheckSelect, RoundButtonIcon, Search } from '~components/index';
import { useMemo, useState } from 'react';

import { DepositOptionsPageProps } from '../Deposit.types';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { coinList } from '~data/wallets';

function CriptoDepositCurrency(props: DepositOptionsPageProps) {
  const { setCurrentStep } = props;
  const selectedWallet = props.stepParams?.walletSelected;

  const [search, setSearch] = useState('');
  const [optionSelect, setOptionSelect] = useState(
    props.stepParams?.currencySelected ||
      String(selectedWallet?.currency?.isoCode).toLowerCase()
  );

  function goNext() {
    setCurrentStep('criptoDepositQR', { currencySelected: optionSelect });
  }
  const goBack = () => {
    setCurrentStep('criptoDepositQR');
  };

  function selectBehavior(keyType: string) {
    if (optionSelect === keyType) {
      setOptionSelect('');
    } else {
      setOptionSelect(keyType);
    }
  }

  const listFiltered = useMemo(() => {
    return coinList.filter(
      (c) =>
        c.name.toLocaleUpperCase().includes(search.toLocaleUpperCase()) ||
        c.title.toLocaleUpperCase().includes(search.toLocaleUpperCase())
    );
  }, [search]);

  return (
    <>
      <ModalMultiStepHeader
        title="Qual carteira deseja depositar?"
        description="Deposite a partir de transferências bancárias ou por meio da blockchain."
        backButtonAction={goBack}
      />
      <div className="content-container">
        <Search
          containerStyle={{ marginBottom: 25, marginTop: 10 }}
          onChange={(e) => setSearch(e.target.value)}
          placeHolder="Pesquisar moeda"
        />

        <Text.Heading3 marginTop={25} marginBottom={15}>
          Criptomoedas
        </Text.Heading3>

        {listFiltered?.map((w) => {
          if (w.visible) {
            return (
              <CheckSelect
                key={w.id}
                title={w.title}
                text={w.name.toLocaleUpperCase()}
                iconType="component"
                onClick={() => selectBehavior(w.name)}
                selected={optionSelect === w.name}
                iconComponent={
                  <RoundButtonIcon buttonSize="iconWallet" buttonType="active">
                    <div className="wallet-icon">{w.icon()}</div>
                  </RoundButtonIcon>
                }
              />
            );
          }
          return null;
        })}
      </div>
      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={optionSelect.length <= 0}
      />
    </>
  );
}

export default CriptoDepositCurrency;
