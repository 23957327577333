import * as Style from '../DisableTwoFa.styles';
import * as Text from '~styles/text';
import React from 'react';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import UmbrellaSVG from '~assets/svg/umbrella';

const ShowAdviceDisableTwoFa: React.FC = ({ setStepsAndParams }) => {
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  return (
    <>
      <ModalMultiStepHeader title="Não desabilite seu 2FA e mantenha sua segurança!" />
      <div className="disable-two-fa-body">
        <UmbrellaSVG />

        <Text.Heading5 marginTop={10}>
          Ao desativar a autenticação de dois fatores (2FA), você remove uma
          importante camada de segurança que protege seus dados pessoais. Isso
          expõe sua conta a ameaças e a torna vulnerável a invasões.
        </Text.Heading5>
        <Text.Heading5 marginTop={20}>
          O 2FA requer uma verificação adicional, além da senha, como um código
          enviado ao seu dispositivo móvel. Para te manter seguro, recomendamos
          que você não faça isso.
        </Text.Heading5>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Desabilitar mesmo assim"
        singleButtonWidth="240px"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => setStepsAndParams('askPinForDisable')}
      />
    </>
  );
};

export default ShowAdviceDisableTwoFa;
