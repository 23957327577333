import { colors } from '~styles';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { InputSwitchProps } from './InputSwitch.types';

export const Container = styled.div<InputSwitchProps>`
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;
`;

export const Input = styled.input`
  width: 45px;
  height: 25px;
  appearance: none;
  background-color: ${colors.grayRegular};
  border-radius: 15.5px;
  cursor: pointer;
  transition: 0.3s ease-out all;

  &::before {
    display: flex;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    background-color: ${colors.white};
    content: '';
    position: relative;
    top: 2px;
    left: 2px;
    transition: 0.3s ease-out all;
    filter: drop-shadow(0px 2px 0px #00000010);
  }

  :checked {
    background-color: ${colors.newBlue};
    &::before {
      left: 22px;
    }
  }

  :disabled {
    background-color: ${transparentize(0.7, colors.grayRegular)};
    cursor: not-allowed;

    &::before {
      background-color: ${transparentize(0.2, colors.white)};
      filter: drop-shadow(0px 2px 0px #00000005);
    }

    :checked {
      background-color: ${transparentize(0.7, colors.newBlue)};
    }
  }
`;
