import * as React from 'react';
import { SVGProps } from 'react';

const BlueBuildSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={45}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.214 12.071a.286.286 0 0 0-.285.286v19.571h11.5V12.357a.286.286 0 0 0-.286-.286H12.214ZM25.43 31.928h7.642V19.96a.286.286 0 0 0-.285-.285h-7.357v12.254Zm0-14.254v-5.317a2.286 2.286 0 0 0-2.286-2.286H12.214a2.286 2.286 0 0 0-2.285 2.286v19.571H9a1 1 0 0 0 0 2H36a1 1 0 1 0 0-2h-.929V19.96a2.286 2.286 0 0 0-2.285-2.285h-7.357Zm-11.643-1.851a1 1 0 0 1 1-1h3.857a1 1 0 0 1 0 2h-3.857a1 1 0 0 1-1-1Zm0 6.177a1 1 0 0 1 1-1h3.857a1 1 0 0 1 0 2h-3.857a1 1 0 0 1-1-1Zm13.5 1.425a1 1 0 0 1 1-1h1.928a1 1 0 1 1 0 2h-1.928a1 1 0 0 1-1-1Zm-13.5 4.752a1 1 0 0 1 1-1h3.857a1 1 0 0 1 0 2h-3.857a1 1 0 0 1-1-1Zm13.5 0a1 1 0 0 1 1-1h1.928a1 1 0 1 1 0 2h-1.928a1 1 0 0 1-1-1Z"
      fill="#026FF4"
    />
  </svg>
);

export default BlueBuildSVG;
