import { colors } from '~styles';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  th {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  thead {
    border-top: 1px solid ${transparentize(0.92, colors.grayMedium)};
    border-bottom: 1px solid ${transparentize(0.92, colors.grayMedium)};
  }

  .name-and-icon-td {
    cursor: pointer;
  }

  .display-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .scale-icon {
    transform: scale(0.8);
  }

  .gap-20 {
    gap: 20px;
  }

  .text-green {
    color: ${colors.success};
  }

  .text-red {
    color: ${colors.danger};
  }

  .padding-left-20 {
    padding-left: 20px;
  }

  &.align-center {
    justify-content: center;
    display: grid;
  }

  .hover {
    cursor: pointer;
  }

  .margin-16 {
    gap: 16px;
    display: flex;
    padding-left: 5px;
  }

  .flex {
    display: flex;
    margin-top: 20px;
    align-items: center;
  }

  .w-full {
    width: 100%;
  }

  .border-collapse {
    border-collapse: collapse;
  }

  .rounded-div {
    width: 100%;
  }

  .hide {
    @media (max-width: 900px) {
      display: none;
    }
  }
  .mobile-hide {
    @media (max-width: 500px) {
      display: none;
    }
  }
`;
