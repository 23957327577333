import { gql, request, useMutation } from '~reactQuery/index';

import { CAPACCOUNT_QUERY_KEYS } from '~src/constants/capaccount';
import { getSettingsId } from '~helpers/device';
import { useQueryClient } from 'react-query';

interface PixTransferScheduleBankingInput {
  userOrganizationId: number;
  type?: string;
  value?: string;
  currency?: string;
  walletAddress?: string;
  pin?: string;
  promo?: boolean;
  token?: string;
}

interface PixTransferScheduleBankingResponse {
  pixTransferScheduleBanking: {
    data: JSON;
  };
}

export const usePixTransferScheduleBanking = () => {
  const queryClient = useQueryClient();
  const organization = getSettingsId();

  const QUERY = gql`
    mutation PixTransferScheduleBanking($params: PixTransferScheduleInput!) {
      pixTransferScheduleBanking(params: $params)
    }
  `;

  const fetcher = async (params: PixTransferScheduleBankingInput) => {
    const response = await request<PixTransferScheduleBankingResponse>(QUERY, {
      params: {
        ...params,
        settingsId: Number(organization),
      },
    });
    return response.pixTransferScheduleBanking;
  };

  return useMutation(['PixTransferScheduleBanking'], fetcher, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        CAPACCOUNT_QUERY_KEYS.GET_CAPACCOUNT_INFO,
      ]);
      queryClient.invalidateQueries(['getWallets']);
    },
  });
};
