import * as Style from './CardWallet.styles';
import * as Text from '~styles/text';

import ArrowDownRightIcon from '~assets/images/icons/16x16/arrow-down-right.svg';
import ArrowUpRightIcon from '~assets/images/icons/16x16/arrow-up-right.svg';
import CapContaSVG from '~assets/images/icons/wallets/CapConta';
import { CardWalletProps } from './CardWallet.types';
import InfoIcon from '~assets/images/icons/16x16/info-f.svg';
import { RoundButtonIcon } from '~components/Buttons';
import { Else, If, Then, When } from 'react-if';
import colors from '~styles/colors';
import { formatCurrencyFn } from '~helpers/format/currency';
import { getSelectedUserOrganization } from '~helpers/device';
import { useCurrencyExchange } from '~reactQuery/queries/wallet/useCurrencyExchange';

const CardWallet = (props: CardWalletProps) => {
  const {
    wallet,
    variationsList,
    onClick,
    selected,
    cardWidth,
    iconWallet,
    capConta,
    infoText,
    walletName,
    amount,
  } = props;

  const userOrganizationId = getSelectedUserOrganization();

  const prefCurrency = 'BRL';

  const { data: valueInPrefCurrency } = useCurrencyExchange({
    fromCurrency: wallet?.currency?.isoCode,
    toCurrency: prefCurrency,
    amount: String(wallet?.funds),
    senderPays: false,
    forcePivot: true,
    userOrganizationId: Number(userOrganizationId),
  });

  const sameCurrency =
    prefCurrency.toUpperCase() === wallet?.currency?.isoCode?.toUpperCase();

  const currencyVariation = variationsList?.data?.filter((item) => {
    const walletIsoCode = wallet?.currency?.isoCode?.toUpperCase();
    const variationIsoCode = item?.isoCode?.toUpperCase();

    if (
      ['EUSDT', 'TUSDT'].includes(walletIsoCode) &&
      variationIsoCode === 'USDT'
    ) {
      return true;
    }
    return variationIsoCode === walletIsoCode;
  })?.[0];

  return (
    <Style.Container
      onClick={onClick}
      className={selected ? 'active' : ''}
      width={cardWidth}
    >
      <div className="header">
        <RoundButtonIcon buttonSize="iconWallet" selected={selected}>
          <div className="scale-icon">
            {capConta ? <CapContaSVG /> : iconWallet}
          </div>
        </RoundButtonIcon>
        {capConta && (
          <div className="tip-icon-container">
            <InfoIcon />
            <div className="tooltip-text">
              <Text.Smaller color={colors.white}>{infoText || ''}</Text.Smaller>
            </div>
          </div>
        )}
      </div>

      <div className="column">
        <Text.Paragraph>
          <If condition={capConta}>
            <Then>CapConta BRL</Then>
            <Else>{walletName}</Else>
          </If>
        </Text.Paragraph>

        <Text.Heading3>
          <If condition={capConta}>
            <Then>{amount}</Then>
            <Else>{formatCurrencyFn('BRL', String(valueInPrefCurrency))}</Else>
          </If>
        </Text.Heading3>

        <div className="row">
          <When condition={!capConta && !sameCurrency}>
            <Text.Small
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              color={colors.grayMedium}
            >
              {amount}
            </Text.Small>

            <div className="profit-container ml-5">
              <When condition={currencyVariation?.variation < 0}>
                <ArrowDownRightIcon color={colors.danger} />
              </When>

              <When condition={currencyVariation?.variation > 0}>
                <ArrowUpRightIcon color={colors.success} />
              </When>

              <Text.SmallerBold
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                color={
                  currencyVariation?.variation > 0
                    ? colors.success
                    : colors.danger
                }
              >
                {currencyVariation?.variation
                  ? `${currencyVariation?.variation}%`
                  : ''}
              </Text.SmallerBold>
            </div>
          </When>
        </div>
      </div>
    </Style.Container>
  );
};

export default CardWallet;
