import { InputFormProps, InputFormStyleProps } from './InputForm.types';
import styled, { css } from 'styled-components';

import { colors } from '~styles';

export const Container = styled.div<InputFormStyleProps>`
  width: ${(props) => props.width || ''};
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;

  .label-container {
    width: auto;
    display: flex;
  }

  .error-container {
    width: auto;

    display: flex;
    justify-content: ${(props) =>
      props.errorInBottom ? 'flex-start' : 'flex-end'};
    align-items: flex-end;
    margin-bottom: ${(props) => (props.errorInBottom ? '7px' : '')};
  }

  .helper-container {
    display: flex;
    align-items: center;
  }

  .helper-icon {
    width: auto;
  }
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  align-items: baseline;
`;

export const BottomContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 7px;
`;

export const Input = styled.input<Pick<InputFormProps, 'error'>>`
  height: 46px;
  background-color: ${(props) => (props.error ? '#ff637810' : colors.transparent)};
  padding: 0 0px;
  outline: none !important;
  border: 1px solid ${colors.grayRegular};
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  transition: 0.2s ease-out;
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};

  ::placeholder {
    color: ${colors.grayRegular};
  }

  &:focus {
    border: 2px solid ${colors.black} !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    !props.error &&
    css`
      &:hover {
        border: 1px solid ${colors.grayMedium};
        border-top: 0px !important;
        border-left: 0px !important;
        border-right: 0px !important;
      }
    `}

  ${(props) =>
    props.error &&
    css`
      border: 1px solid ${colors.danger};
      border-top: 0px !important;
      border-left: 0px !important;
      border-right: 0px !important;

      &:focus {
        border: 2px solid ${colors.danger} !important;
        border-top: 0px !important;
        border-left: 0px !important;
        border-right: 0px !important;
      }

      &:hover {
        background-color: ${colors.whiteLight};
      }
    `}
`;
