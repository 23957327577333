import * as Style from '../SendNewPin.styles';
import * as Text from '~styles/text';
import React from 'react';
import ShinyHappyOutdoorsSVG from '~assets/svg/shinyHappyOutdoors';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import EndStepStatus from '~components/EndStepStatus';

const SuccessRecoveryPin: React.FC = () => {
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  return (
    <>
      <div className="container-success-recovery-pin">
        <EndStepStatus animType="success" />
        <Text.Heading1Bold marginTop={24}>
          PIN alterado com sucesso!
        </Text.Heading1Bold>
        <Text.Heading5 marginTop={16}>
          Use seu novo PIN para confirmar todas as suas novas transações
          bancárias!
        </Text.Heading5>
      </div>

      <ModalMultiStepBottom
        singleButtonWidth="10%"
        singleButtonText="Concluir"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => setOpenModalMultiStep(false)}
      />
    </>
  );
};

export default SuccessRecoveryPin;
