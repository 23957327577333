import { colors } from '~styles';
import styled from 'styled-components';
import { GeneralStyleProps } from '~src/@types/general';

export const ModalBackGround = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  left: 0;
`;

export const ModalContainer = styled.div`
  width: 460px;
  border-radius: 12px;
  background-color: ${colors.white};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  animation: fadein 0.3s;
  position: relative;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const TitleCloseBtnContainer = styled.div<{ paddingBottomTitle?: string }>`
  display: flex;
  margin-top: 20px;
  margin-right: 60px;
  justify-content: space-between;
  padding-bottom: ${(props) => props.paddingBottomTitle || 20}px;

  .close-padding {
    width: 28.5px;
    height: 28.5px;
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

export const BodyContainer = styled.div<GeneralStyleProps>`
  padding: ${(props) => props.paddingVertical || 30}px
    ${(props) => props.paddingHorizontal || 30}px;
  border-bottom: ${(props) =>
    props.noBorderBottom ? 'none' : '1px solid rgba(0, 0, 0, 0.08)'};
  border-top: ${(props) =>
    props.noBorderTop ? 'none' : '1px solid rgba(0, 0, 0, 0.08)'};
`;

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
  height: 77px;
`;
