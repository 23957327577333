import { colors } from '~styles';
import styled, { css } from 'styled-components';

interface ContainerProps {
  marginTop?: number;
  marginBottom?: number;
  removeBorders?: boolean;
  removePadding?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;

  margin-top: ${(props) => props.marginTop || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;

  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 0px 15px 0px 20px;
  border: 1px solid ${colors.grayRegular};

  ${({ removeBorders }) => {
    if (removeBorders) {
      return css`
        border: none;
      `;
    }
    
    return css``;
  }}

  ${({ removePadding }) => {
    if (removePadding) {
      return css`
        padding: 0px;
      `;
    }

    return css``;
  }}
`;

export const Input = styled.input<{ removeBorders?: boolean }>`
  width: 100%;
  height: 46px;
  border: 0px solid transparent;

  font-size: 0.93rem;
  line-height: 18px;
  color: ${colors.black};

  &::placeholder {
    font-size: 0.93rem;
    line-height: 18px;
    color: ${colors.grayRegular};
  }
`;
