import { CheckSelect, ErrorToast, LoadingSpinner } from '~components';
import { Else, If, Then } from 'react-if';

import CapAccountSVG from '~assets/svg/capAccount';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { SelectWalletPageProps } from '../PayBills.types';
import { WalletType } from '~reactQuery/types/wallets.types';
import { WalletsList } from '~data/wallets';
import colors from '~styles/colors';
import { formatCurrencyFn } from '~helpers/format/currency';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';
import { useState } from 'react';

function SelectWallet(props: SelectWalletPageProps) {
  const {
    setCurrentStep,
    keyType = 'Pagar um boleto',
    billData,
    capAccountWallet,
  } = props;

  const [selectedOption, setSelectedOption] = useState<WalletType | ''>('');

  const labelsAndConfigs = {
    'Pagar um boleto': {
      description: (
        <p>
          Pagamento de{' '}
          {formatCurrencyFn(
            'BRL',
            String(Number(billData?.data?.dataValidate?.amount) / 100),
            false,
            true
          )}{' '}
          para {billData?.data?.dataValidate?.payer?.name}
        </p>
      ),
    },
    'Pagar com Pix': {
      description: (
        <p>
          Transferindo <strong>R$ 1.300,00</strong> para{' '}
          <strong>Thais Karoline Caetano</strong>
        </p>
      ),
    },
  };
  const userOrganizationId = getSelectedUserOrganization();

  const { data: wallets, isLoading: loadingWallets } = useGetWallets({
    prefCurrency: 'BRL',
    flag: 'list',
    userOrganizationId: Number(userOrganizationId),
  });

  const getWalletIcon = (walletName: string) => {
    return WalletsList.find((wallet) => wallet?.name === walletName)?.icon;
  };

  const noEnoughFunds =
    Number(billData?.data?.dataValidate?.amount) > capAccountWallet?.funds;

  const goNext = () => {
    if (noEnoughFunds) {
      ErrorToast({
        title: 'Saldo da CapConta insuficiente',
        subtitle: '',
        autoCloseEnable: true,
      });
      return;
    }
    setCurrentStep('paymentDetails', { billData, keyType, selectedOption });
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Escolher a carteira de origem"
        description={labelsAndConfigs[keyType].description}
        backButtonAction={() => setCurrentStep('showKeyExample')}
      />
      <div className="content-container">
        <p style={{ margin: '35px 0 15px' }}>Carteiras disponível</p>
        <CheckSelect
          iconType="component"
          disabled={!capAccountWallet.enabled}
          key={capAccountWallet.id}
          title={capAccountWallet.walletName}
          text={capAccountWallet.currency.isoCode}
          iconComponent={<CapAccountSVG />}
          rightText={formatCurrencyFn(
            capAccountWallet.currency.isoCode,
            String(capAccountWallet.funds),
            false,
            false,
            true
          )}
          onClick={() => setSelectedOption(capAccountWallet)}
          selected={capAccountWallet.id === selectedOption.id}
        />

        <p style={{ margin: '35px 0 15px' }}>Carteiras indisponíveis</p>
        <If condition={!loadingWallets}>
          <Then>
            {wallets?.map((item) => {
              return (
                <CheckSelect
                  iconType="component"
                  key={item.id}
                  disabled
                  title={item.walletName}
                  text={item.currency.isoCode}
                  iconComponent={getWalletIcon(
                    item.currency.isoCode.toLowerCase()
                  )}
                  rightText={formatCurrencyFn(
                    item.currency.isoCode,
                    String(item?.funds)
                  )}
                  onClick={() => setSelectedOption(item)}
                />
              );
            })}
          </Then>

          <Else>
            <LoadingSpinner
              color={colors.primary}
              size={30}
              strokeSize={12.5}
            />
          </Else>
        </If>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={selectedOption === ''}
      />
    </>
  );
}

export default SelectWallet;
