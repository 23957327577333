import styled, { css } from 'styled-components';
import colors from '~styles/colors';

export const Container = styled.div`
  display: flex;
  padding: 16px;
  gap: 14px;
  border-bottom: 1px solid rgba(39, 41, 55, 0.1);

  ${(props) => {
    if (props.onClick) {
      return css`
        cursor: pointer;
        &:hover {
          background-color: ${colors.gray};
        }
      `;
    }
    return css``;
  }}

  .notif-icon {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      width: 8px;
      height: 8px;
      border-radius: 10px;
      background-color: ${colors.primary};
    }
  }

  .buttons-row {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 18px;
  }
`;
