import { useQueryClient } from 'react-query';
import { gql, request, useMutation } from '~reactQuery';

interface PutNotificationsInput {
  userOrganizationId: number;
  notificationId: number;
  read: boolean;
}

export interface BillConfirmResponse {
  putNotifications: {
    data: {
      id: number;
      key: 'STATEMENT' | 'PIX_KEY' | 'PIX_MANUAL' | 'TED';
      data: string;
      read: boolean;
      status: 'PROCESSING' | 'PENDING' | 'DONE' | 'ERROR';
      createdAt: string;
      updatedAt: string;
      accountId: number;
    };
  };

  messages: Array<string>;
  isValid: boolean;
}

export function usePutNotifications() {
  const queryClient = useQueryClient();
  const mutation = gql`
    mutation PutNotifications($params: NotificationsPutInput!) {
      putNotifications(params: $params) {
        data {
          id
          key
          data
          read
          status
          createdAt
          updatedAt
          accountId
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: PutNotificationsInput) => {
    const response = await request<BillConfirmResponse>(mutation, {
      params,
    });
    return response.putNotifications;
  };

  return useMutation('usePutNotifications', fetch, {
    onSuccess: () => {
      queryClient.invalidateQueries('getNotifications');
    },
  });
}
