import { colors } from '~styles';

import styled from 'styled-components';
import { transparentize } from 'polished';
import { GeneralStyleProps } from '~src/@types/general';

export const Container = styled.div<GeneralStyleProps>`
  width: 100%;
  padding: 20px ${(props) => props.paddingVertical || 20}px;
  border-top: 1px solid ${transparentize(0.95, colors.grayMedium)};
`;

export const Header = styled.div`
  width: 100%;

  :hover {
    cursor: pointer;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .right-side {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .left-side {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .container-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .container-arrow {
    margin-left: 10px;
    cursor: pointer;
  }
`;
