import React, { SVGProps } from 'react';

const EyeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '14'}
      height={props.height || '11'}
      viewBox="0 0 14 11"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00001 0.25C3.85375 0.25 1.21199 2.4019 0.462708 5.31306L1.91536 5.68694C2.49821 3.42242 4.55475 1.75 7.00001 1.75C9.44527 1.75 11.5018 3.42242 12.0847 5.68694L13.5373 5.31306C12.788 2.4019 10.1463 0.25 7.00001 0.25ZM7.00001 3.75C5.20508 3.75 3.75001 5.20507 3.75001 7C3.75001 8.79493 5.20508 10.25 7.00001 10.25C8.79493 10.25 10.25 8.79493 10.25 7C10.25 5.20507 8.79493 3.75 7.00001 3.75ZM5.25001 7C5.25001 6.0335 6.03351 5.25 7.00001 5.25C7.96651 5.25 8.75001 6.0335 8.75001 7C8.75001 7.9665 7.96651 8.75 7.00001 8.75C6.03351 8.75 5.25001 7.9665 5.25001 7Z"
        fill={props.fill || '#272937'}
      />
    </svg>
  );
};

export default EyeIcon;
