import * as React from 'react';
import { SVGProps } from 'react';

const WalletMaticSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.583 13.888a1.123 1.123 0 0 0-1.129 0l-2.59 1.556-1.76 1.015-2.59 1.556a1.123 1.123 0 0 1-1.13 0l-2.059-1.218a1.17 1.17 0 0 1-.565-.98v-2.403c0-.405.2-.778.565-.98l2.026-1.184a1.122 1.122 0 0 1 1.129 0l2.026 1.183c.332.203.564.575.564.981v1.556l1.76-1.048v-1.556a1.1 1.1 0 0 0-.564-.981l-3.753-2.233a1.123 1.123 0 0 0-1.129 0l-3.82 2.233a1.1 1.1 0 0 0-.564.98v4.5c0 .406.2.778.565.98l3.819 2.233c.332.203.764.203 1.13 0l2.59-1.522 1.76-1.049 2.59-1.522a1.123 1.123 0 0 1 1.13 0l2.025 1.184c.332.203.565.575.565.981v2.402c0 .406-.2.778-.565.98l-2.026 1.219a1.123 1.123 0 0 1-1.129 0l-2.026-1.184a1.17 1.17 0 0 1-.564-.981V19.03l-1.76 1.048v1.556c0 .406.199.778.564.981l3.82 2.233c.331.203.763.203 1.128 0l3.82-2.233a1.17 1.17 0 0 0 .564-.98v-4.5a1.1 1.1 0 0 0-.564-.98l-3.853-2.267Z"
      fill="#8247E5"
    />
  </svg>
);

export default WalletMaticSVG;
