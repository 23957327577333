import * as Text from '~styles/text';
import * as Style from '../../../TransferBatch.styles';
import PersonIcon from '~assets/svg/person';
import colors from '~styles/colors';
import PhoneSVG from '~assets/svg/phone';
import { SelectKeyTypeProps } from '../../../TransferBatch.types';

const SelectKeyType = ({
  beneficiaryPerPix,
  setBeneficiaryPerPix,
}: SelectKeyTypeProps) => {
  const handleSelectKeyType = (value: string) => {
    const updatedBeneficiary = {
      ...beneficiaryPerPix,
      keyType: value,
    };
    setBeneficiaryPerPix(updatedBeneficiary);
  };

  return (
    <div className="content-add-beneficiary">
      <Text.Heading5Bold marginBottom={24}>
        Não conseguimos identificar o tipo número que você digitou.
      </Text.Heading5Bold>

      <div className="row-type-key" onClick={() => handleSelectKeyType('CPF')}>
        <div className="left">
          <PersonIcon fill={colors.black} width={24} height={24} />
          <Text.Heading5Bold>CPF</Text.Heading5Bold>
        </div>
        <div className="right">
          <Style.CircleCheckbox
            background="CPF"
            type={beneficiaryPerPix?.keyType}
          />
        </div>
      </div>

      <div
        className="row-type-key"
        onClick={() => handleSelectKeyType('PHONE')}
      >
        <div className="left">
          <PhoneSVG fill={colors.black} width={24} height={24} />
          <Text.Heading5Bold>Celular</Text.Heading5Bold>
        </div>
        <div className="right">
          <Style.CircleCheckbox
            background="PHONE"
            type={beneficiaryPerPix?.keyType}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectKeyType;
