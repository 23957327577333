const MoneyReceivedSVG = (props: React.SVGProps<SVGSVGElement>) => {
  const { fill = '#11D593', height = 25, width = 26 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 26 25" fill="none">
      <path
        d="M24 9L21 12L18 9"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M21 11L21 4.00004"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.7822 7H4C2.89543 7 2 7.89543 2 9V18C2 19.1046 2.89543 20 4 20H21.2822C22.3868 20 23.2822 19.1046 23.2822 18V17.042"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M16 13.5C16 15.1569 14.6569 16.5 13 16.5C11.3431 16.5 10 15.1569 10 13.5C10 11.8431 11.3431 10.5 13 10.5C14.6569 10.5 16 11.8431 16 13.5Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MoneyReceivedSVG;
