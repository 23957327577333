import colors from '~styles/colors';
import { Small, Smaller } from '~styles/text';
import styled, { css } from 'styled-components';

export const DropDownItemContainer = styled.div<{
  isDisabled?: boolean;
  disableHover?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start;
  width: 100%;
  z-index: 99999;

  .menu-button {
    border: none;
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0px;
    font-family: 'Helvetica Now Display Medium', sans-serif;
    font-size: 0.938rem;
    line-height: 120%;
    background-color: none;
    width: 100%;
  }

  .disabled {
    cursor: not-allowed;
    background-color: initial;
    opacity: 30%;
  }

  .szh-menu__item {
    padding: 13px 16px;
  }

  .szh-menu__item--hover {
    background-color: #f8f9fb;

    ${({ disableHover }) =>
      disableHover &&
      css`
        background-color: initial;
      `}
  }

  .is-red {
    color: ${colors.danger};
  }

  .text-container {
    width: auto !important;
  }
`;

export const DropDownItemTitle = styled(Small)<{
  isNew?: boolean;
  isRed?: boolean;
}>`
  position: relative;
  display: inline-block;

  ${({ isRed }) =>
    isRed &&
    css`
      color: ${colors.danger};
    `}

  ${({ isNew }) =>
    isNew &&
    css`
      ::after {
        right: -6px;
        content: '';
        z-index: 999999;
        position: absolute;
        background-color: ${colors.newBlue};
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    `}
`;

export const DropDownItemSubtitle = styled(Smaller)`
  color: ${colors.grayMedium};
`;

export const DropDownItemIconContainer = styled.div<{ isRed?: boolean }>`
  margin-right: 12px;
  margin-bottom: 1px;
  display: flex;
  width: auto !important;

  ${({ isRed }) =>
    isRed &&
    css`
      color: ${colors.danger};
    `}
`;
