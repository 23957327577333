import * as Text from '~styles/text';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import { UserPermissionModalProps } from '../UserPermission.types';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import AuthValidate from '~components/AuthValidate/AuthValidate.component';
import { useUserAuthTypes } from '~hooks/useUserAuthTypes';
import SupportSVG from '~assets/svg/support';
import * as Style from '../UserPermission.styles';
import { handleOnClickHelp } from '~helpers/generic';

function ConfirmRegisterUser(props: UserPermissionModalProps) {
  const { auth } = useUserAuthTypes();
  const { setStepUserPermission, handleFinish, hasError, params } = props;

  return (
    <>
      <ModalMultiStepHeader
        title={
          params?.type === 'updateUserPermissions'
            ? 'Confirmar Alterações'
            : 'Confirmar Cadastro de Usuário'
        }
        description={
          params?.type === 'updateUserPermissions'
            ? 'Preencha com seu número PIN e código 2-FA para confirmar as alterações realizadas.'
            : 'Preencha com seu número PIN e código 2-FA para confirmar o cadastro do novo usuário.'
        }
        backButtonAction={() => setStepUserPermission('definePermissions')}
        style={{ padding: '0px 32px' }}
      />
      <Style.ContentModal>
        <AuthValidate
          authTypes={auth}
          pinErrorMessage={hasError.pin}
          twoFaErrorMessage={hasError.twoFa}
          onChangeCodes={(pin, twoFa) => {
            if (
              (auth.includes('2-FA') && twoFa?.length !== 6) ||
              pin.length !== 4
            ) {
              return;
            }
            handleFinish(pin, twoFa);
          }}
        />
      </Style.ContentModal>

      <ModalMultiStepBottom
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        doubleButtonTextOne={
          <div className="double-button-one-container">
            <SupportSVG />
            <Text.Heading5>Preciso de ajuda</Text.Heading5>
          </div>
        }
        doubleButtonOneAction={handleOnClickHelp}
      />
    </>
  );
}

export default ConfirmRegisterUser;
