import { FC, useState } from 'react';
import {
  TooltipAnchor,
  TooltipBalloon,
  TooltipContainer,
  TooltipPositionContainer,
} from './Tooltip.styles';
import type { TooltipProps } from './Tooltip.types';

const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  position = 'top',
  variant = 'informative',
}) => {
  const [isActive, setIsActive] = useState(false);

  const handleShowTooltip = () => {
    setIsActive(true);
  };

  const handleHideTooltip = () => {
    setIsActive(false);
  };

  return (
    <TooltipContainer>
      <TooltipAnchor
        onBlur={handleHideTooltip}
        onFocus={handleShowTooltip}
        onMouseEnter={handleShowTooltip}
        onMouseLeave={handleHideTooltip}
        role="tooltip"
        tabIndex={0}
      >
        {children}
      </TooltipAnchor>
      <TooltipPositionContainer isActive={isActive} position={position}>
        <TooltipBalloon aria-hidden={!isActive} variant={variant}>
          {content}
        </TooltipBalloon>
      </TooltipPositionContainer>
    </TooltipContainer>
  );
};

export default Tooltip;
