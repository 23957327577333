import { SVGProps } from 'react';

const ArrowTransferIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    fill={props.fill || '#272937'}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.19 4.75-6.72 6.72 1.06 1.06 6.72-6.72v4.59h1.5V3.25H5.6v1.5h4.59Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default ArrowTransferIcon;
