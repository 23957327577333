import { SVGProps } from 'react';

const PixSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m9.216 7.542 2.578 2.578 2.577-2.578a3.369 3.369 0 0 1 2.353-.99l-2.72-2.722a2.832 2.832 0 0 0-4.004 0L7.241 6.59a3.354 3.354 0 0 1 1.975.952v0ZM5.139 15.314h1.678a1.249 1.249 0 0 0 .887-.367l2.643-2.643c.118-.115.25-.216.391-.3a2.021 2.021 0 0 1-.391-.299L7.704 9.061a1.25 1.25 0 0 0-.887-.366H5.139l-1.31 1.31a2.832 2.832 0 0 0 0 4.003l1.31 1.306ZM13.244 12.3l2.643 2.643c.235.234.554.366.886.367h2.089L20.17 14a2.831 2.831 0 0 0 0-4.003l-1.31-1.31h-2.088c-.333.002-.651.133-.887.366l-2.644 2.645a2.052 2.052 0 0 1-.39.299c.142.085.274.187.392.303v0ZM14.371 16.457l-2.577-2.577-2.578 2.577c-.533.531-1.23.867-1.978.954l2.757 2.76a2.832 2.832 0 0 0 4.004 0l2.723-2.723a3.417 3.417 0 0 1-2.35-.99v0Z"
      stroke={props.stroke || 'currentColor'}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);

export default PixSVG;
