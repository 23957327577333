import { MenuContainer } from './DropDown.styles';
import { DropDownPositionContainerProps } from './DropDown.types';
import { Menu, MenuButton } from '@szhsin/react-menu';

const DropDown = ({
  children,
  content,
  widthContainer,
  fixedGoToUp,
  align,
  gap,
}: DropDownPositionContainerProps) => {
  return (
    <MenuContainer widthContainer={widthContainer}>
      <Menu
        menuButton={<MenuButton className="menu-button">{children}</MenuButton>}
        className="menu"
        direction={fixedGoToUp ? 'top' : 'bottom'}
        align={align || 'start'}
        gap={gap || 0}
      >
        {content}
      </Menu>
    </MenuContainer>
  );
};

export default DropDown;
