import * as Style from './InputDocForm.styles';
import * as Text from '~styles/text';

import { Else, If, Then, When } from 'react-if';
import { forwardRef, memo, useEffect, useState } from 'react';

import BlueCheckIcon from '~assets/images/icons/kyc/blue-check.svg';
import ClipIcon from '~assets/images/icons/kyc/black-clip-stroke.svg';
import CloseDeleteIcon from '~assets/images/icons/kyc/close-delete.svg';
import FileIcon from '~assets/images/icons/file.svg';
import { InputDocFormProps } from './InputDocForm.types';
import { colors } from '~styles';

const InputDocForm = forwardRef((props: InputDocFormProps, _ref) => {
  const acceptFileTypes = 'image/jpg,image/jpeg,image/png,application/pdf';

  const [fileName, setFileName] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [showFileSizeAlert, setShowFileSizeAlert] = useState(false);

  const isEmpty = fileName.length < 0;

  useEffect(() => {
    let formattedFileName = '';
    if (props.getValues && props.name) {
      const file = props.getValues(props.name);
      if (!file) return;

      if (typeof file === 'string') {
        formattedFileName = file;
      } else {
        formattedFileName = file[0]?.name;
      }
    }

    if (formattedFileName) {
      if (formattedFileName?.length > 10) {
        setFileName(
          `${
            formattedFileName.substring(0, 1).toUpperCase() +
            formattedFileName.substring(1, 10)
          }..${formattedFileName.substring(formattedFileName.lastIndexOf('.'))}`
        );
      } else {
        setFileName(formattedFileName);
      }
    }

    if (props.trigger) props.trigger(props.name);
  }, [fileName]);

  const setFileNameFromEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowFileSizeAlert(false);

    if (
      event?.target?.files?.[0] &&
      event.target.files[0].size > 5 * 1024 * 1024
    ) {
      event.target.value = '';
      event.target.files = null;
      if (props.setValue && props.name) props.setValue(props.name, undefined);

      setShowFileSizeAlert(true);

      return;
    }

    if (event.target.files && event.target.files.length !== 0) {
      if (props?.onChange) props?.onChange(event.target.files);

      setFileName(event.target.files[0].name);
    }
  };

  const clearFileInput = () => {
    if (fileName.length > 0) {
      setShowDelete(true);
    }
  };

  const onClickAddOrDelete = (event: any) => {
    if (showDelete && props.setValue && props.name) {
      event.preventDefault();
      setFileName('');
      setShowDelete(false);
      event.target.value = '';
      event.target.files = null;
      props.setValue(props.name, undefined);
    }

    if (props.trigger) props.trigger(props.name);
  };

  return (
    <>
      <Style.DocsContainer
        className={String(!!props.mobileVersion && 'mobile')}
      >
        <Style.SubtitleWrapper
          className={String(!!props.mobileVersion && 'mobile')}
        >
          <div>
            <FileIcon />
          </div>
          <Text.Heading4 marginLeft={15}>{props.docTitle}</Text.Heading4>
        </Style.SubtitleWrapper>

        <Style.ContainerSelect
          className={String(!!props.mobileVersion && 'mobile')}
        >
          <Style.ButtonUpload
            className={`${showDelete ? 'fade-anim' : 'button-upload-anim'} ${
              !!props.mobileVersion && 'mobile'
            }`}
          >
            <Style.ContainerIcons>
              <If condition={fileName?.length < 1}>
                <Then>
                  <ClipIcon />
                </Then>
                <Else>
                  <If condition={showDelete}>
                    <Then>
                      <CloseDeleteIcon />
                    </Then>
                    <Else>
                      <BlueCheckIcon />
                    </Else>
                  </If>
                </Else>
              </If>
            </Style.ContainerIcons>
            <Text.Small
              color={isEmpty ? colors.black : colors.grayMedium}
              marginLeft={10}
            >
              {showDelete ? 'Apagar Anexo' : fileName || 'Anexar Documento'}
            </Text.Small>
          </Style.ButtonUpload>

          <Style.InputDocs
            {...props}
            {...props.hookForm}
            value={undefined}
            onChange={setFileNameFromEvent}
            onMouseOver={clearFileInput}
            onMouseOut={() => setShowDelete(false)}
            onClick={onClickAddOrDelete}
            type="file"
            accept={acceptFileTypes}
          />
        </Style.ContainerSelect>
      </Style.DocsContainer>
      <When
        condition={
          ['DENIED'].includes(props.status) || props?.error || showFileSizeAlert
        }
      >
        <div
          style={{
            width: '100%',
            justifyContent: 'flex-end',
            display: 'flex',
            paddingBottom: 2,
          }}
        >
          <Text.Small color={colors.danger}>
            {showFileSizeAlert
              ? 'Somente arquivos com menos de 5 mb'
              : props?.error || props.description || 'Inválido'}
          </Text.Small>
        </div>
      </When>
    </>
  );
});

InputDocForm.displayName = 'InputDocForm';

export default memo(InputDocForm);
