/* eslint-disable jsx-a11y/control-has-associated-label */
import * as Style from './StatementExtract.styles';
import * as Text from '~styles/text';

import StatementItem from './StatementExtractItem.component';
import { StatementProps } from './StatementExtract.types';
import { When } from 'react-if';
import colors from '~styles/colors';

function StatementExtract({
  title,
  statementList,
  setPdf,
  setCsv,
  handleScroll,
  hideCpfCnpj,
}: StatementProps) {
  return (
    <>
      <When condition={!!title}>
        <Text.Heading2 marginBottom={20} marginTop={40}>
          {title}
        </Text.Heading2>
      </When>

      <Style.Container>
        <div className="tableFixHead" onScroll={handleScroll}>
          <table id="my-table" className="w-full border-collapse">
            <thead>
              <tr>
                <th>
                  <Text.Paragraph color={colors.grayMedium}>
                    Detalhes da transação
                  </Text.Paragraph>
                </th>
                <th>
                  <Text.Paragraph color={colors.grayMedium}>
                    Data
                  </Text.Paragraph>
                </th>
                <th className="hide">
                  <Text.Paragraph color={colors.grayMedium}>
                    Conta associada
                  </Text.Paragraph>
                </th>
                <th className="hide">
                  <Text.Paragraph color={colors.grayMedium}>
                    Destino/Origem
                  </Text.Paragraph>
                </th>

                <th className="hide">
                  <Text.Paragraph color={colors.grayMedium}>
                    Descrição
                  </Text.Paragraph>
                </th>
                <th className="mobile-hide">
                  <Text.Paragraph color={colors.grayMedium}>
                    Valor
                  </Text.Paragraph>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {statementList?.map((item) => {
                if (!item) return null;
                return (
                  <StatementItem
                    setPdf={setPdf}
                    key={item?.authCode}
                    tx={item}
                    hideCpfCnpj={hideCpfCnpj}
                    setCsv={setCsv}
                  />
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div className="footer-space">&nbsp;</div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </Style.Container>
    </>
  );
}

export default StatementExtract;
