import { GenericRow, InputForm, RoundButtonIcon } from '~components';

import CalendarSVG from '~assets/svg/calendar';
import CapAccountSVG from '~assets/svg/capAccount';
import EditSVG from '~assets/svg/edit';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { PaymentDetailsPageProps } from '../PayBills.types';
import { WalletsList } from '~data/wallets';
import dayjs from '~helpers/format/date';
import { formatCodeBar } from '~helpers/format/masks';
import { formatCurrencyFn } from '~helpers/format/currency';
import { useState } from 'react';
import { Else, If, Then } from 'react-if';

function PaymentDetails(props: PaymentDetailsPageProps) {
  const {
    setCurrentStep,
    keyType = 'Pagar um boleto',
    billData,
    selectedOption,
  } = props;

  const [showDecriptionModal, setShowDescriptionModal] = useState(false);
  const [description, setDescription] = useState('');

  const barCodeComponent = (
    <p style={{ width: '120px', lineBreak: 'auto', textAlign: 'end' }}>
      {formatCodeBar(billData?.data?.dataValidate?.digitable)}
    </p>
  );

  const getWalletIcon = (walletName: string) => {
    const icon = WalletsList.find(
      (wallet) => wallet?.name === walletName
    )?.icon;

    return <span className="wallet-icon">{icon || <CapAccountSVG />}</span>;
  };

  const amount = Number(billData?.data?.dataValidate?.amount) / 100;

  const labelsAndConfigs = {
    'Pagar um boleto': {
      description: (
        <p>
          Pagamento de{' '}
          <strong>
            &nbsp;
            {formatCurrencyFn('BRL', String(amount), false, true)}
          </strong>{' '}
          para <strong>{billData?.data?.dataValidate?.payer?.name}</strong>
        </p>
      ),
      content: [
        {
          overText: 'Origem',
          title: selectedOption.walletName,
          rightComponent: (
            <RoundButtonIcon
              noHover
              buttonType="outlined"
              buttonSize="iconWallet"
            >
              {getWalletIcon(
                selectedOption.walletName === 'CapConta'
                  ? 'CapConta'
                  : selectedOption.currency.isoCode.toLocaleLowerCase()
              )}
            </RoundButtonIcon>
          ),
          rightContentType: 'component',
        },
        {
          overText: 'Valor',
          title: `${formatCurrencyFn('BRL', String(amount), false, true)}`,
          rightComponent: null,
          rightContentType: 'component',
        },
        {
          overText: 'Data de Validade',
          title: `${dayjs(billData?.data?.dataValidate?.dueDate).format(
            'DD/MM/YYYY'
          )}`,
        },
        {
          overText: 'Data do Pagamento',
          title: `${dayjs(new Date()).format('DD/MM/YYYY')}`,
          rightComponent: (
            <RoundButtonIcon
              noHover
              buttonType="default"
              onClick={() => { }}
              buttonSize="icon24"
            >
              <CalendarSVG />
            </RoundButtonIcon>
          ),
          rightContentType: 'component',
        },
        {
          overText: 'Descrição',
          title: description,
          rightComponent: (
            <RoundButtonIcon
              buttonType="default"
              onClick={() => setShowDescriptionModal(true)}
              buttonSize="icon24"
            >
              <EditSVG />
            </RoundButtonIcon>
          ),
          rightContentType: 'component',
        },
        {
          overText: 'Para',
          title: `${billData?.data?.dataValidate?.recipient?.name ||
            billData?.data?.dataValidate?.assignor
            }`,
        },
        {
          overText: 'Código do boleto',
          rightComponent: barCodeComponent,
          rightContentType: 'component',
          alignItems: 'flex-start',
        },
      ],
    },
    'Pagar com Pix': {
      description: (
        <p>
          Transferindo <strong>R$ 1.300,00</strong> para{' '}
          <strong>Thais Karoline Caetano</strong>
        </p>
      ),
      content: [
        {
          overText: 'Origem',
          title: 'CapConta',
          rightComponent: (
            <RoundButtonIcon
              buttonType="outlined"
              buttonSize="iconWallet"
            >
              {getWalletIcon('capAccount')}
            </RoundButtonIcon>
          ),
          rightContentType: 'component',
        },
        { overText: 'CPF', title: '•••.654.175-••' },
        {
          overText: 'Data do Pagamento',
          title: '12/08/2022',
          rightComponent: (
            <RoundButtonIcon
              buttonType="default"
              onClick={() => { }}
              buttonSize="icon24"
            >
              <CalendarSVG />
            </RoundButtonIcon>
          ),
          rightContentType: 'component',
        },
        { overText: 'Instituição', title: 'Nubank Pagamentos S.A' },
      ],
    },
  };

  const goNext = () => {
    if (showDecriptionModal) {
      setShowDescriptionModal(false);
      return;
    }
    setCurrentStep('confirmPin', { description });
  };

  const title = showDecriptionModal
    ? 'Adicionar descrição'
    : 'Detalhes do boleto';

  const handleDescription = showDecriptionModal
    ? 'Adicione uma descrição ao seu boleto'
    : labelsAndConfigs[keyType].description;

  const handleBackButton = () => {
    if (showDecriptionModal) {
      setShowDescriptionModal(false);
      return;
    }
    setCurrentStep('selectWallet');
  };

  return (
    <>
      <ModalMultiStepHeader
        title={title}
        description={handleDescription}
        backButtonAction={handleBackButton}
      />

      <If condition={!showDecriptionModal}>
        <Then>
          <div className="content-container" style={{ marginTop: '30px' }}>
            {labelsAndConfigs[keyType].content.map((config, index) => {
              return (
                <>
                  {keyType === 'Pagar um boleto' &&
                    config.overText === 'Data de Validade' && (
                      <div style={{ display: 'flex' }}>
                        <GenericRow {...config} noHorizontalPadding />
                        <GenericRow
                          {...labelsAndConfigs[keyType].content[index + 1]}
                          noHorizontalPadding
                        />
                      </div>
                    )}
                  {keyType === 'Pagar um boleto' &&
                    config.overText !== 'Data de Validade' &&
                    config.overText !== 'Data do Pagamento' && (
                      <GenericRow {...config} noHorizontalPadding />
                    )}

                  {keyType === 'Pagar com Pix' && (
                    <GenericRow {...config} noHorizontalPadding />
                  )}
                </>
              );
            })}
          </div>
        </Then>

        <Else>
          <div className="content-container" style={{ marginTop: '30px' }}>
            <InputForm
              placeholder="Descrição"
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
            />
          </div>
        </Else>
      </If>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
      />
    </>
  );
}

export default PaymentDetails;
