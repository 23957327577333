import { SVGProps } from 'react';

const MaintenanceSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 319}
    height={props.height || 327}
    fill="none"
    {...props}
  >
    <path
      d="M59.851 142.233L54.3914 152.232C46.701 166.318 60.9519 182.26 75.8049 176.19L86.3529 171.881C95.7092 168.058 106.315 173.168 109.164 182.862L112.376 193.793C116.9 209.191 138.248 210.387 144.462 195.591L148.874 185.085C152.787 175.77 163.9 171.877 172.77 176.722L182.769 182.182C196.855 189.872 212.797 175.621 206.727 160.768L202.418 150.22C198.595 140.864 203.704 130.258 213.399 127.409L224.33 124.197C239.728 119.673 240.923 98.3255 226.128 92.1113L215.621 87.6992C206.306 83.7859 202.414 72.673 207.259 63.8033L212.718 53.8037C220.409 39.7178 206.158 23.7762 191.305 29.846L180.757 34.1551C171.401 37.9776 160.795 32.8686 157.946 23.1742L154.733 12.2427C150.21 -3.15457 128.862 -4.3504 122.648 10.4448L118.236 20.9516C114.323 30.2666 103.21 34.1592 94.3402 29.3141L84.3406 23.8545C70.2546 16.1642 54.3131 30.4151 60.3829 45.268L64.692 55.816C68.5145 65.1723 63.4054 75.778 53.711 78.6274L42.7796 81.8396C27.3823 86.3631 26.1865 107.711 40.9817 113.925L51.4884 118.337C60.8035 122.25 64.6961 133.363 59.851 142.233Z"
      fill="#CED6EF"
    />
    <path
      d="M133.736 42.5631C166.918 42.5631 193.857 69.5021 193.857 102.684V326.505H73.6154V102.684C73.6154 69.5021 100.554 42.5631 133.736 42.5631Z"
      fill="#F3F4F9"
    />
    <path
      d="M14.1829 264.252L8.67795 270.516C0.925711 279.336 8.53775 293.009 20.1207 291.063L28.3431 289.682C35.6376 288.457 42.3878 293.805 42.8661 301.186L43.4022 309.508C44.1568 321.227 59.2077 325.511 66.0197 315.944L70.8566 309.153C75.1492 303.129 83.7056 302.139 89.2599 307.021L95.5236 312.526C104.344 320.278 118.017 312.666 116.071 301.083L114.69 292.861C113.465 285.566 118.813 278.816 126.194 278.338L134.516 277.802C146.235 277.047 150.519 261.996 140.953 255.184L134.161 250.347C128.137 246.055 127.147 237.498 132.029 231.944L137.534 225.68C145.286 216.86 137.674 203.187 126.091 205.133L117.869 206.514C110.574 207.739 103.824 202.391 103.346 195.01L102.81 186.688C102.055 174.969 87.0044 170.685 80.1923 180.251L75.3554 187.043C71.0628 193.067 62.5065 194.057 56.9521 189.175L50.6885 183.67C41.8682 175.918 28.1946 183.53 30.1409 195.113L31.5223 203.335C32.747 210.63 27.3988 217.38 20.0177 217.858L11.6964 218.394C-0.0227016 219.149 -4.30705 234.2 5.25954 241.012L12.051 245.849C18.0755 250.141 19.0651 258.698 14.1829 264.252Z"
      fill="#CED6EF"
    />
    <path
      d="M73.1081 289.381C95.9091 289.381 114.393 270.897 114.393 248.096C114.393 225.295 95.9091 206.811 73.1081 206.811C50.3072 206.811 31.8234 225.295 31.8234 248.096C31.8234 270.897 50.3072 289.381 73.1081 289.381Z"
      fill="#F3F4F9"
    />
    <path
      d="M133.555 130.885C148.943 130.885 161.418 118.41 161.418 103.022C161.418 87.6338 148.943 75.1593 133.555 75.1593C118.167 75.1593 105.692 87.6338 105.692 103.022C105.692 118.41 118.167 130.885 133.555 130.885Z"
      fill="#CED6EF"
    />
    <path d="M318.289 326.699V103.17L225.703 326.699H318.289Z" fill="#0F90FF" />
    <path
      d="M318.289 103.17L284.802 184.012L262.539 237.762L236.767 299.986L225.699 326.699L203.152 326.736L151.562 326.818L106.719 326.892L40.0209 327L73.137 247.04L95.4041 193.29L121.019 131.445L132.734 103.17H318.289Z"
      fill="#F3BA2F"
    />
    <path
      d="M284.802 184.012L262.539 237.762L205.94 103.17H250.812L284.802 184.012Z"
      fill="#3F4251"
    />
    <path
      d="M236.767 299.986L225.699 326.699L203.152 326.736L121.019 131.445L132.734 103.17H153.995L236.767 299.986Z"
      fill="#3F4251"
    />
    <path
      d="M151.562 326.818L106.719 326.893L73.1371 247.04L95.4041 193.29L151.562 326.818Z"
      fill="#3F4251"
    />
    <path
      d="M198.323 310.873H67.1207"
      stroke="#F3F4F9"
      strokeWidth="2.28031"
      strokeMiterlimit="10"
    />
  </svg>
);

export default MaintenanceSVG;
