import * as Style from '../SignTemplate.styles';
import { ContentWrapper, ScrollContainer } from '../SignTemplate.styles';
import { useEffect, useState } from 'react';
import { Change } from './ChangeSteps/2.Change';
import { Failed } from './ChangeSteps/4.Failed';
import { Infos } from './ChangeSteps/1.Infos';
import { Success } from './ChangeSteps/3.Success';
import { When } from 'react-if';
import { useLocation } from 'react-router-dom';
import useWindowDimensions from '~hooks/windowDimension';

const ChangePassword = () => {
  const [step, setStep] = useState<'infos' | 'change' | 'success' | 'failed'>(
    'infos'
  );
  const location = useLocation();
  const { width } = useWindowDimensions();

  const customLayout = step === 'change';

  const sessKey = location.search.split('=')[1];

  const stepsStatusProgressBar = {
    infos: 33,
    change: 66,
    success: 100,
    failed: 66,
  };

  const recoverySteps: Record<
    'infos' | 'change' | 'success' | 'failed',
    JSX.Element
  > = {
    infos: <Infos />,
    change: <Change setStep={setStep} sessKey={sessKey} />,
    failed: <Failed setStep={setStep} />,
    success: <Success />,
  };

  useEffect(() => {
    if (sessKey) {
      setStep('change');
    }
  }, [sessKey]);

  return (
    <ScrollContainer
      marginTop={customLayout ? 150 : 0}
      justifyContent={customLayout ? 'flex-start' : 'center'}
    >
      <ContentWrapper screenWidth={width}>
        {recoverySteps[step]}
        <When condition={!['infos', 'success'].includes(step)}>
          <Style.ProgressContainer>
            <div
              className="bar"
              style={{ width: `${stepsStatusProgressBar[step] || 1}%` }}
            />
          </Style.ProgressContainer>
        </When>
      </ContentWrapper>
    </ScrollContainer>
  );
};

export default ChangePassword;
