import * as Style from './Statement.styles';
import * as Text from '~styles/text';
import StatementItem from './StatementItem.component';
import { StatementProps } from './Statement.types';
import { When } from 'react-if';
import colors from '~styles/colors';
import { LineWave } from 'react-loader-spinner';

function Statement({
  title,
  withSearch,
  statementList,

  handleScroll,
  isFetchingNextPage,
  hideCpfCnpj,
  wallets,
}: StatementProps) {
  return (
    <>
      <When condition={!!title}>
        <Text.Heading2 marginBottom={20} marginTop={40}>
          {title}
        </Text.Heading2>
      </When>

      <Style.Container>
        <div className="rounded-div">
          <When condition={withSearch}>
            <div className="">
              <input
                type="text"
                className=""
                placeholder="Buscar Transferência"
              />
            </div>
          </When>

          <div className="tableFixHead" onScroll={handleScroll}>
            <table id="my-table" className="w-full border-collapse">
              <thead>
                <tr>
                  <th>
                    <Text.Paragraph color={colors.grayMedium}>
                      Tipo
                    </Text.Paragraph>
                  </th>
                  <th className="hide">
                    <Text.Paragraph color={colors.grayMedium}>
                      Conta associada
                    </Text.Paragraph>
                  </th>
                  <th className="hide">
                    <Text.Paragraph color={colors.grayMedium}>
                      Destino/Origem
                    </Text.Paragraph>
                  </th>
                  <th>
                    <Text.Paragraph color={colors.grayMedium}>
                      Data
                    </Text.Paragraph>
                  </th>
                  <th className="mobile-hide">
                    <Text.Paragraph color={colors.grayMedium}>
                      Valor
                    </Text.Paragraph>
                  </th>
                  <th>
                    <p />
                  </th>
                </tr>
              </thead>
              <tbody>
                {statementList?.map((item) => {
                  if (!item) return null;
                  return (
                    <StatementItem
                      key={item?.authCode}
                      tx={item}
                      hideCpfCnpj={hideCpfCnpj}
                      wallets={wallets}
                    />
                  );
                })}
              </tbody>
            </table>
            <When condition={isFetchingNextPage}>
              <div className="loading-container">
                <LineWave width={80} height={80} color={colors.primary} />
              </div>
            </When>
          </div>
        </div>
      </Style.Container>
    </>
  );
}

export default Statement;
