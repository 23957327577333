import { Button, ContainerError } from './AppErrorFallBack.styles';
import * as Text from '~styles/text';
import NotFoundSVG from '~assets/svg/notFound';

const AppErrorFallBack = () => {
  const redirectToStart = () => {
    window.location.href = '/auth';
  };

  return (
    <ContainerError>
      <div className="description">
        <Text.Heading2Bold fontSize={3.75}>Oops!</Text.Heading2Bold>
        <Text.Heading2Bold fontSize={1.83}>
          Alguma coisa deu errado.
        </Text.Heading2Bold>

        <Text.Heading4 marginTop={20}>
          A página que você acessou esta com problemas, <br />
          estamos trabalhando para ajustar isso.
        </Text.Heading4>
        <div className="row-buttons">
          <Button buttonType="primary" onClick={redirectToStart}>
            <Text.Heading5>Retornar ao início</Text.Heading5>
          </Button>
        </div>
      </div>
      <NotFoundSVG />
    </ContainerError>
  );
};

export default AppErrorFallBack;
