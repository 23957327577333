import styled, { css } from 'styled-components';
import colors from '~styles/colors';

export const Container = styled.div<{ hasFile: boolean; progress: number }>`
  width: 100%;
  max-width: 400px;
  border: 1px dashed #909090;
  border-radius: 8px;
  padding: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.2s ease-in-out;
  gap: 10px;

  ${({ hasFile }) =>
    hasFile &&
    css`
      border: none !important;
      cursor: default;
      &:hover {
        background-color: transparent !important;
      }
    `}

  input {
    display: none;
  }

  .blue-text {
    color: ${colors.primary};
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f9;
    border-radius: 50%;
    padding: 10px;
    transition: 0.2s ease-in-out;
  }

  &:hover {
    background-color: #f3f4f9;
    border: 1px dashed ${colors.primary};

    .icon-container {
      background-color: #000000;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  .uploaded-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .left-content {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .footer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .loading-backdrop {
        width: 85%;
        height: 6px;
        border-radius: 5px;
        background-color: #2729371a;
      }

      .loading {
        width: ${(props) => props.progress}%;
        height: 6px;
        border-radius: 5px;
        background-color: #272937;
      }
    }
  }
`;
