export const rules = Object.freeze({
  labelStatement: ['ALL'], // component to show if role matches
  billPage: ['statement.read', 'slip.read'],
  billButtonNewDeposit: [
    'bill.payment.write',
    'slip.write',
    'pix.write',
    'currencies.crytpoaddress.write',
  ],
  activitiesPage: ['statement.read'],
  capAccountPage: ['statement.read'],
  criptoTransactions: [
    'currencies.withdraw.write',
    'currencies.crytpoaddress.write',
  ],
  pixPage: ['statement.read'],

  transferButton: ['transaction.write'],
  buttonsPix: [
    'pix.write',
    'pix.key.write',
    'pix.manual.write',
    'pix.brcode.write',
    'pix.delete',
    'ted.write',
  ],
  convertButton: [
    'currencies.withdraw.write',
    'currencies.crytpoaddress.write',
  ],
  payBillsButton: ['bill.payment.write'],
  depositButton: ['slip.write', 'currencies.crytpoaddress.write', 'pix.write'],

  statementList: ['statement.read'],
  cardSection: ['card.read'],
  fastPaymentSection: ['transaction.write'],
  twofaSection: ['twofa.read'],
  twofaDelete: ['twofa.delete'],

  paymentPix: ['pix.write'],
  createKeyPix: ['pix.key.write'],
  transferButtonCapConta: ['pix.write'],
  paymentButton: ['bill.payment.write', 'pix.write'],
});
