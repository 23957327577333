import { create } from 'zustand';

export type ModalSideBarContents =
  | 'areaPix'
  | 'walletModal'
  | 'capContaModal'
  | 'billModal'
  | 'detailsActivityModal'
  | 'notifications'
  | 'noContent';

interface OpenModalSideBarProps {
  openModalSideBar: boolean;
  setOpenModalSideBar: (boolean: boolean) => void;
  content: ModalSideBarContents;
  setModalSidebarContent: (content: ModalSideBarContents) => void;
  params: {
    [key: string]: any;
  };
  setParams: (value: object) => void;
}

export const useOpenModalSideBar = create<OpenModalSideBarProps>((set) => ({
  openModalSideBar: false,
  content: 'noContent',
  params: {},
  setParams: (value) => set(() => ({ params: { ...value } })),
  setOpenModalSideBar: (boolean) => set(() => ({ openModalSideBar: boolean })),
  setModalSidebarContent: (content) => set(() => ({ content })),
}));
