import * as Text from '~styles/text';
import React, { useState } from 'react';
import AlertModal from '~components/AlertModal';
import { ErrorToast } from '~components/Toast';
import { RoundButtonIcon } from '~components/Buttons';
import { WalletsList } from '~data/wallets';
import { When } from 'react-if';
import { colors } from '~styles';
import { getSelectedUserOrganization } from '~helpers/device';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import { useWalletsDelete } from '~reactQuery/mutations/wallet/useWalletsDelete';
import {
  ModalWalletProps,
  WalletTypeCapAccount,
} from '../CapAccountAndWallets.types';

function DeleteWallet({ setOpenModal, wallet }: ModalWalletProps) {
  const { mutateAsync: deleteWallet, isLoading: loadingDeleteWallet } =
    useWalletsDelete();
  const [step, setStep] = useState(0);

  const { setOpenModalSideBar } = useOpenModalSideBar();

  const WalletIcon = WalletsList.filter(
    (w: WalletTypeCapAccount) =>
      w.name === wallet?.currency?.isoCode?.toLowerCase()
  )?.[0]?.icon;

  const userOrganizationId = getSelectedUserOrganization();

  async function deleteWalletAction(walletAddress: string) {
    if (wallet.funds > 0) {
      return setStep(1);
    }

    try {
      await deleteWallet({
        walletAddress,
        userOrganizationId: Number(userOrganizationId),
      });

      setOpenModal(false);

      setTimeout(() => {
        setOpenModalSideBar(false);

        ErrorToast({
          title: 'Sua carteira foi excluida',
          subtitle: `Sua carteira de ${wallet.walletName} foi excluída com sucesso.`,
          autoCloseEnable: true,
        });
      }, 1000);
    } catch (error) {
      // console.log(error);
    }

    return Promise.resolve();
  }

  return (
    <>
      <When condition={step === 0}>
        <AlertModal
          setOpenAlert={setOpenModal}
          primaryButtonAction={() => setOpenModal(false)}
          secondaryButtonAction={() => deleteWalletAction(wallet.walletAddress)}
          primaryButtonText="Cancelar"
          secondaryButtonText="Excluir Carteira"
          secondaryButtonColor="error"
          paddingVertical={20}
          noBorderTop
          buttonLoading={loadingDeleteWallet}
          title="Deseja mesmo excluir esta carteira?"
          bodyComponent={
            <>
              <Text.Heading5>
                Após sua exclusão, você não poderá mais receber ou enviar
                pagamentos através dela.
              </Text.Heading5>

              <div className="row align-center mt-20">
                <RoundButtonIcon buttonType="default" buttonSize="iconWallet">
                  <div className="scale-icon">{WalletIcon}</div>
                </RoundButtonIcon>

                <div className="column ml-10">
                  <Text.Heading5>{wallet.walletName}</Text.Heading5>

                  <Text.Small color={colors.grayMedium}>
                    {wallet.currency.isoCode}
                  </Text.Small>
                </div>
              </div>
            </>
          }
        />
      </When>

      <When condition={step === 1}>
        <AlertModal
          setOpenAlert={setOpenModal}
          primaryButtonAction={() => setOpenModal(false)}
          secondaryButtonAction={() => setOpenModal(false)}
          primaryButtonText="Cancelar"
          secondaryButtonText="Entendi"
          secondaryButtonColor="secondary"
          paddingVertical={20}
          noBorderTop
          title="Não é possível excluir esta carteira"
          bodyText={`Você possui ${wallet.currency.isoCode} ${wallet.funds} de saldo nesta carteira. Transfira esse valor e tente novamente.`}
        />
      </When>
    </>
  );
}

export default DeleteWallet;
