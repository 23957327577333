import * as Text from '~styles/text';
import { Else, If, Then, When } from 'react-if';
import { ErrorToast, GenericButton, RoundButtonIcon, SuccessToast } from '..';
import UserPic from '~components/UserPic';
import dayjs from 'dayjs';
import DeleteIcon from '~assets/svg/delete';
import { DropDown } from '~components/DropDown';
import { DropDownItem } from '~components/DropDown/DropDownItem';
import DotsFloatingMenu from '~assets/images/icons/kyc/dots-vertical.svg';
import colors from '~styles/colors';
import LockIcon from '~assets/svg/lock';
import LogListIcon from '~assets/svg/logList';
import ShieldSVG from '~assets/svg/shield';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useEffect, useRef, useState } from 'react';
import TrashSVG from '~assets/svg/trash';
import { useSignUpAdm } from '~reactQuery/mutations/settings/useSignUpAdm';
import { getSelectedOrg, getSelectedUserOrganization } from '~helpers/device';
import AlertModal from '~components/AlertModal/AlertModal.component';
import { useDisableUserByAdmin } from '~reactQuery/mutations/settings/useDisableUserByAdmin';
import { usePutUserOrgCustomEvents } from '~reactQuery/mutations/settings/usePutUserOrgCustomEvents';
import {
  InvitedUsersResp,
  UserPartnerResp,
} from '~reactQuery/types/business.types';

function UserItem({
  item,
  index,
  hideStatus,
}: {
  item: InvitedUsersResp;
  index: number;
  hideStatus?: boolean;
}) {
  const [count, setCount] = useState(0);
  const [hasResendInvite, setHasResendInvite] = useState(false);
  const userOrganizationId = getSelectedUserOrganization();
  const orgId = getSelectedOrg();
  const [isModalAlertRemoveUser, setIsModalAlertRemoveUser] = useState(false);
  const [topDiv, setTopDiv] = useState<number>(250);

  const { setSection, setOpenModalMultiStep, setParams, params } =
    useActionsOnModalMultiStep();

  const { mutateAsync: requestSignUpAdm, isLoading } = useSignUpAdm();
  const {
    mutateAsync: requestDisableUserByAdmin,
    isLoading: isLoadingDisableUserByAdmin,
  } = useDisableUserByAdmin();

  const { mutateAsync: requestPutUserOrgCustomEvents } =
    usePutUserOrgCustomEvents();

  const handleChangePermission = () => {
    setSection('createUserWithPermission');
    setParams({
      type: 'updateUserPermissions',
      userPermission: item?.permission,
      useOrganizations: item?.userOrganizations,
      userOrganizationId: item?.userOrganizations.filter(
        (i) => i?.organizationsId === Number(orgId)
      )?.[0]?.id,
    });

    setOpenModalMultiStep(true);
  };

  const divRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleResendEmail = async () => {
    try {
      const baseURL = window.location.origin;
      const urlCallback = `${baseURL}/auth/guest-register`;

      const response = await requestSignUpAdm({
        userOrganizationId: Number(userOrganizationId),
        documentNumber: item?.documentNumber,
        email: item?.email,
        phone: item?.phone,
        registerName: item?.registerName,
        scopes: item?.userOrganizations?.[0]?.permissions,
        socialName: item?.registerName,
        urlCallback,
        message: 'Olá! Gostaria que você fizesse parte da Organização!',
      });

      if (response) {
        SuccessToast({
          title: 'Convite reenviado!',
          subtitle: `Um novo convite foi reenviado para o email de ${item?.email}.`,
          autoCloseEnable: true,
        });
      }

      if (!response) {
        ErrorToast({
          title: 'Não foi possível reenviar o email!',
          autoCloseEnable: true,
        });
      }
    } catch (error: any) {
      // console.log('ResendEmail error =>', error);
    }
  };

  const handleRemoveUser = async () => {
    try {
      const response = await requestDisableUserByAdmin({
        userId: item?.id,
        userOrganizationId: item?.userOrganizationId,
      });

      if (response) {
        setIsModalAlertRemoveUser(false);
        return ErrorToast({
          title: 'Usuário removido.',
          autoCloseEnable: true,
        });
      }

      return ErrorToast({
        title: 'Não foi possível remover o usuário!',
        autoCloseEnable: true,
      });
    } catch (error) {
      // console.log('OnOffUserByAdm error =>', error);
      return ErrorToast({
        title: 'Não foi possível remover o usuário!',
        autoCloseEnable: true,
      });
    }
  };

  const handleCancelUserInvitation = async () => {
    try {
      const invitedUserOrganizationsId = item?.userOrganizationId;

      const response = await requestPutUserOrgCustomEvents({
        userOrgCustomEventsId: Number(item?.userOrOrgCustomEvents?.[0]?.id),
        status: 'CANCELED',
        userOrganizationsId: Number(userOrganizationId),
        invitedUserOrganizationsId,
      });

      if (response) {
        return ErrorToast({
          title: 'Convite cancelado do usuário.',
          autoCloseEnable: true,
        });
      }

      return ErrorToast({
        title: 'Não foi possível cancelar o convite do usuário!',
        autoCloseEnable: true,
      });
    } catch (error) {
      // console.log('OnOffUserByAdm error =>', error);
      return ErrorToast({
        title: 'Não foi possível cancelar o convite do usuário!',
        autoCloseEnable: true,
      });
    }
  };

  const handleTimer = () => {
    if (!hasResendInvite) {
      return;
    }

    handleResendEmail();
    let c = 1800;

    const interval = setInterval(() => {
      if (c >= 1) {
        c -= 1;
        setCount(c);
      }
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  };

  useEffect(() => {
    handleTimer();
  }, [hasResendInvite]);

  useEffect(() => {
    if (count === 0) {
      setHasResendInvite(false);
    }
  }, [count]);

  useEffect(() => {
    const handleCheckPosition = () => {
      divRefs.current.forEach((divRef: any) => {
        if (divRef) {
          const rect = divRef.getBoundingClientRect();
          setTopDiv(rect.top - 30);
        }
      });
    };

    handleCheckPosition();
  }, []);

  const getStatus = (status: string) => {
    if (status === 'EXPIRED') {
      return 'Expirado';
    }

    if (status === 'CANCELED') {
      return 'Cancelado';
    }

    if (status === 'PENDING') {
      return 'Pendente';
    }

    return '';
  };

  return (
    <>
      <If condition={false}>
        <Then>{null}</Then>
        <Else>
          <tr>
            <td className="row-item">
              <div className="flex items-center gap-10">
                <UserPic name={item?.name} borderRadius={30} />

                <div className="column">
                  <Text.Heading5 data-html2canvas-ignore="true">
                    {item?.name}
                  </Text.Heading5>
                  <Text.Small
                    data-html2canvas-ignore="true"
                    color={colors.grayMedium}
                  >
                    {item?.email}
                  </Text.Small>
                </div>
              </div>
            </td>
            <td className="row-item">
              <Text.Heading5 data-html2canvas-ignore="true">
                {dayjs(item?.admission).format('DD/MM/YYYY')}
              </Text.Heading5>
            </td>
            <td className="row-item">
              <Text.Heading5 data-html2canvas-ignore="true">
                {item?.permission}
              </Text.Heading5>
            </td>
            {!hideStatus && (
              <td className="row-item">
                <Text.Heading5 data-html2canvas-ignore="true">
                  {!item.userOrOrgCustomEvents
                    ? 'Cancelado'
                    : getStatus(item?.userOrOrgCustomEvents?.[0]?.status)}
                </Text.Heading5>
              </td>
            )}
            <td>
              <div
                ref={(ref) => {
                  divRefs.current[index] = ref;
                }}
              >
                {item?.buttonContent ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: '16px',
                    }}
                  >
                    <GenericButton
                      width={hasResendInvite ? '207px' : '160px'}
                      buttonType="default"
                      onClick={() => setHasResendInvite(true)}
                      borderRadius={200}
                      disabled={hasResendInvite}
                    >
                      {hasResendInvite && count
                        ? `Reenviar convite (${Math.round(count / 60)}m)`
                        : 'Reenviar convite'}
                    </GenericButton>
                    <RoundButtonIcon
                      onClick={() => handleCancelUserInvitation()}
                      buttonSize="icon24"
                      disabled={!item?.userOrOrgCustomEvents}
                    >
                      <TrashSVG />
                    </RoundButtonIcon>
                  </div>
                ) : (
                  !item.name.includes('(Você)') && (
                    <DropDown
                      align="end"
                      content={
                        <div className="dropdown-container">
                          <DropDownItem
                            icon={<LockIcon />}
                            title="Alterar permissões"
                            onClick={handleChangePermission}
                          />
                          <DropDownItem
                            icon={<ShieldSVG width={16} height={16} />}
                            title="Redefinir senha"
                            onClick={() => {}}
                            isDisabled
                          />
                          <DropDownItem
                            icon={<LogListIcon />}
                            title="Log de atividade"
                            onClick={() => {}}
                            isDisabled
                          />
                          <DropDownItem
                            icon={<DeleteIcon />}
                            title="Remover usuário"
                            onClick={() => setIsModalAlertRemoveUser(true)}
                            isRed
                          />
                        </div>
                      }
                    >
                      <RoundButtonIcon buttonType="flat">
                        <div
                          className="display-center"
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <DotsFloatingMenu />
                        </div>
                      </RoundButtonIcon>
                    </DropDown>
                  )
                )}
              </div>
            </td>
          </tr>
        </Else>
      </If>
      <When condition={isModalAlertRemoveUser}>
        <AlertModal
          setOpenAlert={setIsModalAlertRemoveUser}
          title={
            !item?.buttonContent
              ? 'Você tem certeza que deseja remover esse usuário?'
              : 'Você tem certeza que deseja remover o usuário pendente?'
          }
          primaryButtonText="Fechar"
          secondaryButtonText="Remover usuário"
          primaryButtonAction={() => setIsModalAlertRemoveUser(false)}
          secondaryButtonAction={() => handleRemoveUser()}
          secondaryButtonColor="error"
          secondaryButtonWidth="254px"
          bodyComponent={
            !item?.buttonContent ? (
              <Text.Heading5 display="inline-block">
                O usuário <strong>{item?.name}</strong> será removido da sua
                equipe de membros e não terá mais acesso a plataforma.
              </Text.Heading5>
            ) : (
              <Text.Heading5 display="inline-block">
                Ao remover <strong>{item?.name}</strong>, o convite será
                automaticamente cancelado e não poderá mais fazer parte da
                equipe. O convite pode ser reencaminhado, se necessário.
              </Text.Heading5>
            )
          }
          buttonLoading={isLoadingDisableUserByAdmin}
        />
      </When>
    </>
  );
}

export default UserItem;
