import * as React from 'react';

import { SVGProps } from 'react';

const MaticCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="M27.156 14.499c-.374-.228-.86-.228-1.27 0l-2.915 1.75-1.98 1.142-2.914 1.75c-.374.229-.86.229-1.27 0l-2.317-1.37a1.316 1.316 0 0 1-.635-1.103v-2.702c0-.457.224-.875.635-1.103l2.28-1.332c.373-.229.859-.229 1.27 0l2.279 1.332c.373.228.635.646.635 1.103v1.75l1.98-1.179v-1.75c0-.457-.224-.876-.635-1.104L18.077 9.17c-.374-.228-.86-.228-1.27 0l-4.297 2.512c-.41.228-.635.647-.635 1.104v5.06c0 .457.224.876.635 1.104l4.297 2.512c.373.228.859.228 1.27 0l2.914-1.713 1.98-1.18 2.915-1.712c.373-.228.859-.228 1.27 0l2.279 1.332c.373.229.635.647.635 1.104v2.702c0 .457-.224.875-.635 1.103l-2.28 1.37c-.373.229-.858.229-1.27 0l-2.278-1.332a1.317 1.317 0 0 1-.636-1.103v-1.75l-1.98 1.179v1.75c0 .457.224.876.635 1.104l4.297 2.512c.374.228.86.228 1.27 0l4.297-2.512c.373-.228.635-.647.635-1.104v-5.06c0-.457-.224-.876-.635-1.104l-4.334-2.55Z"
      fill="#8247E5"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MaticCoinSVG;
