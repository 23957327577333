import { gql, request, useQuery } from '~reactQuery/index';

interface GetPaymentListInput {
  id?: number;
  page?: number;
  perPage?: number;
  perUser: boolean;
  userOrganizationId: number;
}

export interface Beneficiary {
  accountType: string;
  amount: string;
  e2eId?: string;
  fullName: string;
  holderDocument?: string;
  holderName?: string;
  keyType?: string;
  saveForNextTransfer: boolean;
  agency?: string;
  bank?: string;
  bankAccount?: string;
  documentNumber?: string;
  transferType?: string;
}

interface GetPaymentListResponse {
  getPaymentList: Array<{
    accountId: number;
    data: {
      listOfBeneficiaries: Beneficiary[];
      nameList: string;
    };
    deletedAt?: string;
    id: number;
    updatedAt?: string;
    userOrganizationId: number;
  }>;
}

export const useGetPaymentList = (params: GetPaymentListInput) => {
  const QUERY = gql`
    query GetPaymentList($params: GetPaymentListInput) {
      getPaymentList(params: $params) {
        id
        userOrganizationId
        accountId
        data
        deletedAt
        createdAt
        updatedAt
      }
    }
  `;

  const fetcher = async () => {
    const response = await request<GetPaymentListResponse>(QUERY, { params });
    return response;
  };

  return useQuery(['useGetPaymentList'], fetcher);
};
