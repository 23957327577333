import { DocumentType, Status } from '~reactQuery/types/business.types';
import { gql, request, useMutation } from '~reactQuery';

import { useQueryClient } from 'react-query';

interface UpdatePartnerDataInput {
  partnerId: number;
  legalRepresent: string;
  memberQualification: string;
  declaredIncome: string;
  isPowerOfAttorney: boolean;
  participationPercentage: string;
  powerOfAttorney: string;
  powerOfAttorneyStartDate: string;
  powerOfAttorneyEndDate: string;
  politicallyExposed: string;
  pep: string;
  documentNumber: string;
  fileFrontDocument: string;
  fileBackDocument: string;
  socialName: string;
  registerName: string;
  countryCode: string;
  phone: string;
  number: string;
  zipCode: string;
  buildingNumber: string;
  addressLine: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  birthDate: string;
  motherName: string;
  email: string;
  documentType: DocumentType;
}

interface UpdatePartnerDataResponse {
  updatePartnerData: {
    data: {
      id: number;
      legalRepresent: string;
      politicallyExposed: string;
      pep: string;
      documentNumber: string;
      fileFrontDocument: string;
      fileBackDocument: string;
      registerName: string;
      socialName: string;
      countryCode: string;
      number: string;
      zipCode: string;
      addressLine: string;
      buildingNumber: string;
      complement: string;
      neighborhood: string;
      city: string;
      state: string;
      country: string;
      birthDate: string;
      motherName: string;
      email: string;
      documentType: DocumentType;
      createdAt: string;
      updatedAt: string;
      deleted: boolean;
      deletedAt: string;
      status: Status;
      businessId: number;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useUpdatePartnerDataMutation() {
  const queryClient = useQueryClient();

  const mutation = gql`
    mutation UpdatePartnerData($params: updatePartnerDataInput!) {
      updatePartnerData(params: $params) {
        data {
          id
          memberQualification
          declaredIncome
          isPowerOfAttorney
          powerOfAttorney
          powerOfAttorneyStartDate
          powerOfAttorneyEndDate
          participationPercentage
          legalRepresent
          pep
          documentNumber
          fileFrontDocument
          fileBackDocument
          registerName
          socialName
          countryCode
          number
          zipCode
          addressLine
          buildingNumber
          complement
          neighborhood
          city
          state
          country
          birthDate
          motherName
          email
          documentType
          createdAt
          updatedAt
          deleted
          deletedAt
          status
          businessId
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: UpdatePartnerDataInput) => {
    const response = await request<UpdatePartnerDataResponse>(mutation, {
      params,
    });

    return response.updatePartnerData;
  };

  return useMutation('UpdatePartnerData', fetch, {
    onSettled: () => {
      queryClient.invalidateQueries(['getCustomerData', 'getPartnerData']);
    },
  });
}
