import * as React from 'react';

import { SVGProps } from 'react';

const RecoverPinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '128'}
    height={props.height || '121'}
    viewBox="0 0 128 121"
    fill="none"
    {...props}
  >
    <g id="Ativo 1 1" clipPath="url(#clip0_20801_7250)">
      <g id="Camada 1">
        <g id="Group">
          <path
            id="Vector"
            d="M128.002 30.0671V114.493C125.672 116.664 123.164 118.644 120.498 120.408H57.1426C41.5484 110.099 31.2573 92.394 31.2573 72.2842C31.2573 40.454 57.0284 14.6528 88.8215 14.6528C103.959 14.6528 117.73 20.5061 128.005 30.0699L128.002 30.0671Z"
            fill="#CED6EF"
          />
          <path
            id="Vector_2"
            d="M88.8187 104.187C106.419 104.187 120.687 89.9025 120.687 72.2815C120.687 54.6606 106.419 40.376 88.8187 40.376C71.2183 40.376 56.9504 54.6606 56.9504 72.2815C56.9504 89.9025 71.2183 104.187 88.8187 104.187Z"
            fill="#FFE000"
          />
          <path
            id="Vector_3"
            d="M88.8187 104.187C106.419 104.187 120.687 89.9025 120.687 72.2815C120.687 54.6606 106.419 40.376 88.8187 40.376C71.2183 40.376 56.9504 54.6606 56.9504 72.2815C56.9504 89.9025 71.2183 104.187 88.8187 104.187Z"
            fill="#F3BA2F"
          />
          <path
            id="Vector_4"
            d="M128.002 40.6354V120.408H0.00201416V94.0345C0.00201416 89.9166 1.66844 86.1893 4.36315 83.4914C7.05786 80.7936 10.7808 79.1252 14.894 79.1252C23.1174 79.1252 29.7859 85.7987 29.7859 94.0345V96.6068C29.7859 99.2768 30.8644 101.696 32.6144 103.448C34.3616 105.197 36.7805 106.28 39.4473 106.28C44.7838 106.28 49.1115 101.947 49.1115 96.604V77.2504C49.1115 73.1324 50.7779 69.4051 53.4726 66.7073C54.8102 65.3681 56.3986 64.2856 58.1626 63.5351C59.9572 62.7679 61.9301 62.3438 64.0034 62.3438C72.2269 62.3438 78.8954 69.0173 78.8954 77.2532V85.6229C78.8954 88.2929 79.9794 90.7173 81.7238 92.4638C83.4766 94.2187 85.8899 95.2956 88.5567 95.2956C93.8988 95.2956 98.2209 90.9684 98.2209 85.6201V40.6354C98.2209 36.5231 99.8873 32.7958 102.582 30.0979C105.277 27.4001 109 25.7317 113.113 25.7317C121.336 25.7317 128.005 32.4052 128.005 40.6354H128.002Z"
            fill="#006FF4"
          />
          <path
            id="Vector_5"
            d="M120.69 72.2816C120.69 89.9055 106.419 104.187 88.8215 104.187C71.2237 104.187 56.9476 89.9055 56.9476 72.2816C56.9476 69.2489 57.3684 66.3139 58.1598 63.5352C59.9544 62.7679 61.9273 62.3439 64.0006 62.3439C72.2241 62.3439 78.8926 69.0174 78.8926 77.2532V85.623C78.8926 88.2929 79.9766 90.7174 81.721 92.4639C83.4739 94.2187 85.8871 95.2956 88.554 95.2956C93.896 95.2956 98.2181 90.9685 98.2181 85.6202V41.7905C111.229 45.8052 120.687 57.9358 120.687 72.2816H120.69Z"
            fill="#3F4251"
          />
          <path
            id="Vector_6"
            d="M0.00201416 113.201H97.0143"
            stroke="white"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
          <path
            id="Vector_7"
            d="M60.8238 43.3305L59.3302 40.8419L52.8345 44.7534V37.1648H49.9363V44.7534L43.4406 40.8419L41.947 43.3305L48.5737 47.3201L41.947 51.3097L43.4406 53.7983L49.9363 49.8868V57.4754H52.8345V49.8868L59.3302 53.7983L60.8238 51.3097L54.1971 47.3201L60.8238 43.3305Z"
            fill="white"
          />
          <path
            id="Vector_8"
            d="M28.6155 11.1488L28.6239 8.24729L21.0441 8.22497L24.9706 1.73282L22.4932 0.229053L18.5668 6.7212L15.0333 0.00585938L12.4696 1.35618L16.0727 8.20823L8.33972 8.18591L8.33136 11.0846L15.9111 11.1097L11.9847 17.5991L14.462 19.1029L18.3884 12.6135L21.9219 19.3261L24.4857 17.9757L20.8825 11.1237L28.6155 11.1488Z"
            fill="#CED6EF"
          />
          <path
            id="Vector_9"
            d="M28.6155 48.8016L28.6239 45.9001L21.0441 45.8778L24.9706 39.3856L22.4932 37.8847L18.5668 44.374L15.0333 37.6587L12.4696 39.0118L16.0727 45.8611L8.33972 45.8387L8.33136 48.7403L15.9111 48.7626L11.9847 55.2547L14.462 56.7557L18.3884 50.2663L21.9219 56.9817L24.4857 55.6286L20.8825 48.7793L28.6155 48.8016Z"
            fill="#CED6EF"
          />
          <path
            id="Vector_10"
            d="M61.5233 11.1488L61.5317 8.24729L53.9519 8.22497L57.8783 1.73282L55.401 0.229053L51.4746 6.7212L47.9411 0.00585938L45.3773 1.35618L48.9805 8.20823L41.2475 8.18591L41.2391 11.0846L48.8189 11.1097L44.8925 17.5991L47.3698 19.1029L51.2962 12.6135L54.8297 19.3261L57.3934 17.9757L53.7903 11.1237L61.5233 11.1488Z"
            fill="#CED6EF"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_20801_7250">
        <rect
          width="128"
          height="120.402"
          fill="white"
          transform="translate(0.00201416 0.00585938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RecoverPinSVG;
