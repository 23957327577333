import { breakpoints, screen } from '~styles/metrics';

import { transparentize } from 'polished';
import styled from 'styled-components';
import { colors } from '~styles';
import KycBanner from '../../assets/images/kyc/kyc-banner.jpg';
import { StylesProps } from './Kyc.types';

export const KycContainer = styled.main`
  display: flex;
  width: 100%;
  height: 100%;

  .footer-wrapper {
    position: fixed;
    width: auto;
    height: 75px;
    bottom: 0;
  }
`;

export const SideImage = styled.div`
  width: 47%;
  max-width: 640px;
  height: 100vh;
  background: url(${KycBanner});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 25px;
  margin-left: 30px;
  cursor: pointer;

  svg:last-child {
    margin-top: 5px;
    margin-left: 7.75px;
  }
`;

export const Content = styled.div<
  Pick<StylesProps, 'screenWidth' | 'justifyContent'>
>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  .scroll-container {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${(props) => props.justifyContent};
  }
`;

export const ContentWrapper = styled.div<
  Pick<StylesProps, 'screenWidth' | 'marginTop' | 'marginBottom'>
>`
  width: ${(props) =>
    props.screenWidth < breakpoints.tablet ? '100%' : '560px'};
  padding: 0
    ${(props) => (props.screenWidth < breakpoints.tablet ? '20px' : '')};
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;

  .link {
    text-decoration: underline;
    color: ${colors.primary};
  }
`;

export const DocItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const SubtitleWrapper = styled.div<Omit<StylesProps, 'screenWidth'>>`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;

  .icon-wrapper {
    height: 24px;
    width: 24px;
  }
`;

export const FormContainer = styled.form`
  width: 100%;

  .input-group {
    display: grid;
    grid-template: repeat(1, 1fr) / repeat(2, 1fr);
    gap: 20px;

    @media ${screen.tablet} {
      display: flex;
      flex-direction: column;
    }
  }

  .toggle-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: fadein 0.3s;
    position: relative;

    @keyframes fadein {
      from {
        opacity: 0;
        top: -10px;
      }
      to {
        opacity: 1;
        top: 0;
      }
    }
  }
`;

export const Line = styled.div<Omit<StylesProps, 'screenWidth'>>`
  width: 100%;
  height: 1px;
  background-color: ${transparentize(0.75, colors.grayLight)};

  margin-top: ${(props) => props.marginTop || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;
`;

export const ProgressContainer = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${transparentize(0.7, colors.grayLight)};
  position: absolute;
  bottom: 74px;
  right: 0;

  &.kyc-modal {
    width: 65%;
    left: 0;

    @media ${screen.tablet} {
      width: 100%;
    }
  }

  .bar {
    transition: width 0.3s ease-out;
    // width está sendo setado na prop styles da propria div,
    // por motivos de screen flickering, ao passar props real-time dynamic ao styled-components;
    max-width: 100%;
    height: 3px;
    background-color: ${colors.black};
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 15px 20px 0 20px;

  .traslate-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
  }

  .translate-select {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    appearance: none;
    border: none;
    color: transparent;
    width: 24px;
    height: 24px;
    background-color: transparent;
  }
`;

export const FinalScreenContainer = styled.header`
  img {
    width: 130px;
    height: 130px;
    margin-bottom: 30px;
  }
`;

export const StatusContainer = styled.div`
  .warning-message {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
    padding: 10px;
    background-color: ${transparentize(0.9, colors.danger)};
    border-radius: 10px;
    margin-top: 30px;

    p {
      width: 94%;
    }
  }
`;

export const PartnersTypeBusinessContainer = styled.div`
  padding: 50px;
  overflow-y: scroll;
  height: 100%;

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    border-bottom-left-radius: 10px;
    border-top: 1px solid ${transparentize(0.7, colors.grayLight)};
    width: 65%;
    padding: 14px 20px;
    background-color: ${colors.whiteLight};
    @media ${screen.tablet} {
      width: 100%;
    }

    .footer-btn {
      width: auto;

      @media ${screen.tablet} {
        width: 100%;
      }
    }

    .button-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 155px;
    }
  }
`;
