import { CounterInputStyleProps } from './CounterInput.types';
import { colors } from '~styles';
import styled from 'styled-components';

export const ContainerInput = styled.div<CounterInputStyleProps>`
  .count {
    color: ${colors.grayRegular};
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    font-weight: 600;
    min-width: 35px;
    text-align: center;
  }
  .plus {
    background-color: ${(props) =>
      props.backgroundColorPlus && colors.mainGray};
    color: ${colors.grayRegular};
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    text-align: center;
    justify-content: center;
    border-radius: 50%;
  }
  .minus {
    background-color: ${(props) =>
      props.backgroundColorMinus && colors.mainGray};
    color: ${colors.grayRegular};
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50%;
  }
  div {
    text-align: center;
  }
  .minus:hover {
    background-color: ${colors.gray} !important;
  }
  .plus:hover {
    background-color: ${colors.gray} !important;
  }
  /*Prevent text selection*/
  span {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  input {
    border: 0;
    width: 50%;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  border: 1px solid
    ${({ errorColor }) => (errorColor ? colors.danger : '#9ea1b9')};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const Container = styled.div`
  .error-container {
    width: auto;
    display: flex;
    align-items: flex-end;
    margin-top: 7px;
  }
`;
