import * as Text from '~styles/text';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import {
  TranslatorFullAccessSetStatesTypings,
  TranslatorTextTypings,
  UserPermissionModalProps,
} from '../UserPermission.types';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import colors from '~styles/colors';
import CardIcon from '~assets/svg/cardIcon';
import CriptoCoinIcon from '~assets/svg/criptoCoin';
import BillIcon from '~assets/svg/statement/billIcon';
import PixAndTedIcon from '~assets/svg/pixAndTed';
import { DropDown } from '~components/DropDown';
import { DropDownItem } from '~components/DropDown/DropDownItem';
import { useEffect, useState } from 'react';
import ArrowDownSimpleIcon from '~assets/svg/arrowDownSimple';
import EyeIcon from '~assets/svg/eyeIcon';
import GearIcon from '~assets/svg/gearIcon';
import BlockedSimpleIcon from '~assets/svg/blockedSimple';
import * as Style from '../UserPermission.styles';
import { ErrorToast } from '~components/Toast';
import { When } from 'react-if';
import AlertModal from '~components/AlertModal/AlertModal.component';

function PersonalizeUserPermission(props: UserPermissionModalProps) {
  const [cardPermission, setCardPermission] = useState('none');
  const [criptoPermission, setCriptoPermission] = useState('none');
  const [billAndChargePermission, setBillAndChargePermission] =
    useState('none');
  const [pixAndTedPermission, setPixAndTedPermission] = useState('none');
  const [modalAlertPermissionTotal, setModalAlertPermissionTotal] =
    useState(false);
  const [permissionPressed, setPermissionPressed] = useState('');

  const {
    setStepUserPermission,
    userPermission,
    setUserPermission,
    handleCancelAndResetFields,
  } = props;

  useEffect(() => {
    if (userPermission?.scopes?.includes('card.read')) {
      setCardPermission('onlyRead');
    }

    if (
      userPermission?.scopes?.includes(
        'card.write',
        'card.activate.write',
        'card.password.write',
        'card.status.write',
        'card.contactless.write'
      )
    ) {
      setCardPermission('fullAccess');
    }

    if (userPermission?.scopes?.includes('statement.read')) {
      setPixAndTedPermission('onlyRead');
      setCriptoPermission('onlyRead');
    }

    if (
      userPermission?.scopes?.includes(
        'currencies.withdraw.write',
        'currencies.crytpoaddress.write'
      )
    ) {
      setCriptoPermission('fullAccess');
    }

    if (userPermission?.scopes?.includes('bill.payment.read', 'slip.read')) {
      setBillAndChargePermission('onlyRead');
    }

    if (
      userPermission?.scopes?.includes(
        'bill.payment.write',
        'bill.payment.read',
        'slip.write',
        'slip.delete'
      )
    ) {
      setBillAndChargePermission('fullAccess');
    }

    if (
      userPermission?.scopes?.includes(
        'pix.write',
        'pix.key.write',
        'pix.manual.write',
        'pix.brcode.write',
        'pix.delete',
        'ted.write',
        'pix.refund.write'
      )
    ) {
      setPixAndTedPermission('fullAccess');
    }
  }, []);

  const handleNext = () => {
    const arrayScopes: Array<string> = [];

    if (cardPermission === 'onlyRead') {
      arrayScopes.push('card.read');
    }

    if (cardPermission === 'fullAccess') {
      arrayScopes.push(
        'card.read',
        'card.write',
        'card.activate.write',
        'card.password.write',
        'card.status.write',
        'card.contactless.write'
      );
    }

    if (criptoPermission === 'onlyRead') {
      arrayScopes.push();
    }

    if (criptoPermission === 'fullAccess') {
      arrayScopes.push(
        'currencies.withdraw.write',
        'currencies.crytpoaddress.write'
      );
    }

    if (billAndChargePermission === 'onlyRead') {
      arrayScopes.push('bill.payment.read', 'slip.read');
    }

    if (billAndChargePermission === 'fullAccess') {
      arrayScopes.push(
        'bill.confirm.write',
        'bill.payment.write',
        'bill.payment.read',
        'slip.write',
        'slip.delete',
        'slip.read'
      );
    }

    if (pixAndTedPermission === 'onlyRead') {
      arrayScopes.push();
    }

    if (pixAndTedPermission === 'fullAccess') {
      arrayScopes.push(
        'pix.write',
        'pix.key.write',
        'pix.manual.write',
        'pix.brcode.write',
        'pix.delete',
        'ted.write',
        'pix.refund.write'
      );
    }

    if (
      cardPermission === 'fullAccess' &&
      criptoPermission === 'fullAccess' &&
      billAndChargePermission === 'fullAccess' &&
      pixAndTedPermission === 'fullAccess'
    ) {
      arrayScopes.push('twofa.read', 'twofa.delete');
    }

    if (
      criptoPermission === 'fullAccess' ||
      pixAndTedPermission === 'fullAccess'
    ) {
      arrayScopes.push(
        'transaction.write',
        'transfers.write',
        'pix.schedule.write'
      );
    }

    if (
      criptoPermission === 'fullAccess' ||
      billAndChargePermission === 'fullAccess' ||
      pixAndTedPermission === 'fullAccess' ||
      criptoPermission === 'onlyRead' ||
      billAndChargePermission === 'onlyRead' ||
      pixAndTedPermission === 'onlyRead'
    ) {
      arrayScopes.push('statement.read', 'limits.quotas.read');
    }

    setUserPermission({ permission: 'personalize', scopes: arrayScopes });

    if (
      (cardPermission === 'none' &&
        criptoPermission === 'none' &&
        billAndChargePermission === 'none' &&
        pixAndTedPermission === 'none') ||
      arrayScopes?.length === 0
    ) {
      ErrorToast({
        title: 'Erro encontrado!',
        subtitle: 'Deve ser selecionado pelo menos uma permissão',
        autoCloseEnable: true,
      });

      return;
    }

    setStepUserPermission('confirmRegisterUser');
  };

  const handleGoBack = () => {
    setUserPermission({ permission: '', scopes: [] });
    setStepUserPermission('definePermissions');
  };

  const translatorButtonDropdown: TranslatorTextTypings = {
    none: 'Nenhuma',
    onlyRead: 'Ver',
    fullAccess: 'Total',
  };

  const cardText: TranslatorTextTypings = {
    none: 'Não tem permissões.',
    onlyRead:
      'Poderá ver extratos e movimentações, mas não terá acesso aos dados dos cartões.',
    fullAccess:
      'Terá acesso total às funcionalidades dessa seção, incluindo os dados dos cartões.',
  };

  const criptoText: TranslatorTextTypings = {
    none: 'Não tem permissões.',
    onlyRead: 'Poderá ver saldos, extratos e movimentações dessa seção.',
    fullAccess: 'Terá acesso total às funcionalidades dessa seção.',
  };

  const billAndChargeText: TranslatorTextTypings = {
    none: 'Não tem permissões.',
    onlyRead: 'Poderá ver detalhes de boletos e cobranças emitidos.',
    fullAccess: 'Terá acesso total às funcionalidades dessa seção.',
  };

  const pixAndTedText: TranslatorTextTypings = {
    none: 'Não tem permissões.',
    onlyRead: 'Poderá ver chaves Pix, extratos e movimentações dessa seção.',
    fullAccess: 'Terá acesso total às funcionalidades dessa seção.',
  };

  const translatorFullAccessSetStates: TranslatorFullAccessSetStatesTypings = {
    card: setCardPermission,
    cripto: setCriptoPermission,
    billAndCharge: setBillAndChargePermission,
    pixAndTed: setPixAndTedPermission,
  };

  const handleFullAccess = (whatPermissionWasClicked: string) => {
    setPermissionPressed(whatPermissionWasClicked);
    setModalAlertPermissionTotal(true);
  };

  const handleFinishModalAlert = () => {
    translatorFullAccessSetStates[permissionPressed]('fullAccess');
    setModalAlertPermissionTotal(false);
  };
  const handleCancelModalAlert = () => {
    setModalAlertPermissionTotal(false);
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Personalizar Permissões"
        description="Selecione as opções de acordo com sua preferência para cada tipo de permissão."
        backButtonAction={() => handleGoBack()}
        style={{ padding: '0px 32px' }}
      />
      <Style.ContentModal>
        {/* <div className="row-define-permissions">
          <div className="left">
            <CardIcon fill={colors.black} width={18} height={18} />

            <div className="column">
              <Text.Heading5Bold>Cartões Virtuais</Text.Heading5Bold>
              <Text.Small color={colors.grayMedium}>
                {cardText[cardPermission]}
              </Text.Small>
            </div>
          </div>
          <div className="right">
            <DropDown
              content={
                <>
                  <DropDownItem
                    icon={<EyeIcon />}
                    title="Apenas visualizar"
                    onClick={() => setCardPermission("onlyRead")}
                  />
                  <DropDownItem
                    icon={<GearIcon width={14} />}
                    title="Permissão total"
                    onClick={() =>
                      cardPermission !== "fullAccess" &&
                      handleFullAccess("card")
                    }
                  />
                  <DropDownItem
                    icon={<BlockedSimpleIcon />}
                    title="Nenhuma permissão"
                    onClick={() => setCardPermission("none")}
                  />
                </>
              }
            >
              <div className="dropwdown">
                <Text.Heading5>
                  {translatorButtonDropdown[cardPermission]}
                </Text.Heading5>

                <ArrowDownSimpleIcon />
              </div>
            </DropDown>
          </div>
        </div> */}

        <div className="row-define-permissions">
          <div className="left">
            <CriptoCoinIcon fill={colors.black} width={18} height={18} />

            <div className="column">
              <Text.Heading5Bold>Criptomoedas</Text.Heading5Bold>
              <Text.Small color={colors.grayMedium}>
                {criptoText[criptoPermission]}
              </Text.Small>
            </div>
          </div>
          <div className="right">
            <DropDown
              content={
                <>
                  <DropDownItem
                    icon={<EyeIcon />}
                    title="Apenas visualizar"
                    onClick={() => setCriptoPermission('onlyRead')}
                  />
                  <DropDownItem
                    icon={<GearIcon width={14} />}
                    title="Permissão total"
                    onClick={() =>
                      criptoPermission !== 'fullAccess' &&
                      handleFullAccess('cripto')
                    }
                  />
                  <DropDownItem
                    icon={<BlockedSimpleIcon />}
                    title="Nenhuma permissão"
                    onClick={() => setCriptoPermission('none')}
                  />
                </>
              }
            >
              <div className="dropwdown">
                <Text.Heading5>
                  {translatorButtonDropdown[criptoPermission]}
                </Text.Heading5>

                <ArrowDownSimpleIcon />
              </div>
            </DropDown>
          </div>
        </div>

        <div className="row-define-permissions">
          <div className="left">
            <BillIcon fill={colors.black} width={18} height={18} />

            <div className="column">
              <Text.Heading5Bold>Boletos e Cobranças</Text.Heading5Bold>
              <Text.Small color={colors.grayMedium}>
                {billAndChargeText[billAndChargePermission]}
              </Text.Small>
            </div>
          </div>
          <div className="right">
            <DropDown
              content={
                <>
                  <DropDownItem
                    icon={<EyeIcon />}
                    title="Apenas visualizar"
                    onClick={() => setBillAndChargePermission('onlyRead')}
                  />
                  <DropDownItem
                    icon={<GearIcon width={14} />}
                    title="Permissão total"
                    onClick={() =>
                      billAndChargePermission !== 'fullAccess' &&
                      handleFullAccess('billAndCharge')
                    }
                  />
                  <DropDownItem
                    icon={<BlockedSimpleIcon />}
                    title="Nenhuma permissão"
                    onClick={() => setBillAndChargePermission('none')}
                  />
                </>
              }
            >
              <div className="dropwdown">
                <Text.Heading5>
                  {translatorButtonDropdown[billAndChargePermission]}
                </Text.Heading5>

                <ArrowDownSimpleIcon />
              </div>
            </DropDown>
          </div>
        </div>

        <div className="row-define-permissions">
          <div className="left">
            <PixAndTedIcon fill={colors.black} width={18} height={18} />

            <div className="column">
              <Text.Heading5Bold>Pix/TED e Pagamentos</Text.Heading5Bold>
              <Text.Small color={colors.grayMedium}>
                {pixAndTedText[pixAndTedPermission]}
              </Text.Small>
            </div>
          </div>
          <div className="right">
            <DropDown
              content={
                <>
                  <DropDownItem
                    icon={<EyeIcon />}
                    title="Apenas visualizar"
                    onClick={() => setPixAndTedPermission('onlyRead')}
                  />
                  <DropDownItem
                    icon={<GearIcon width={14} />}
                    title="Permissão total"
                    onClick={() =>
                      pixAndTedPermission !== 'fullAccess' &&
                      handleFullAccess('pixAndTed')
                    }
                  />
                  <DropDownItem
                    icon={<BlockedSimpleIcon />}
                    title="Nenhuma permissão"
                    onClick={() => setPixAndTedPermission('none')}
                  />
                </>
              }
            >
              <div className="dropwdown">
                <Text.Heading5>
                  {translatorButtonDropdown[pixAndTedPermission]}
                </Text.Heading5>

                <ArrowDownSimpleIcon />
              </div>
            </DropDown>
          </div>
        </div>
      </Style.ContentModal>

      <ModalMultiStepBottom
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonText="Próximo"
        singleButtonAction={() => handleNext()}
        doubleButtonTextOne="Cancelar"
        doubleButtonOneAction={() => handleCancelAndResetFields()}
      />

      <When condition={modalAlertPermissionTotal}>
        <AlertModal
          setOpenAlert={setModalAlertPermissionTotal}
          title="Confirme a permissão selecionada"
          primaryButtonText="Cancelar"
          secondaryButtonText="Sim, confirmar permissões"
          primaryButtonAction={() => handleCancelModalAlert()}
          secondaryButtonAction={() => handleFinishModalAlert()}
          secondaryButtonColor="secondary"
          secondaryButtonWidth="254px"
          bodyComponent={
            <Text.Heading5>
              Uma ou mais opções foram definidas como `&#39;`permissão
              total`&#39;`. Ao conceder esse tipo de permissão, o usuário tem
              acesso completo às funcionalidades selecionadas. Você tem certeza
              que deseja continuar?
            </Text.Heading5>
          }
        />
      </When>
    </>
  );
}

export default PersonalizeUserPermission;
