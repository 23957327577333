import { gql, request, useMutation } from '~reactQuery';

interface ValidateSignIn2FAInput {
  recovery?: boolean;
  settingsId: number;
  token: string;
  userId: number;
}

interface ValidateSignIn2FAResponse {
  validateSignIn2FA: {
    data: {
      access_token: string;
      exp: number;
      iat: number;
      isValid: boolean;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useValidateSignInTwoFA() {
  const mutation = gql`
    mutation ValidateSignIn2FA($params: ValidateSignIn2FAInput!) {
      validateSignIn2FA(params: $params) {
        data {
          isValid
          access_token
          iat
          exp
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: ValidateSignIn2FAInput) => {
    const response = await request<ValidateSignIn2FAResponse>(mutation, {
      params,
    });
    return response.validateSignIn2FA;
  };

  return useMutation('validateSignIn2FA', fetch);
}
