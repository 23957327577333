import { gql, request, useMutation } from '~reactQuery';

interface Beneficiary {
  accountType: string;
  amount: string;
  e2eId?: string;
  fullName: string;
  holderDocument?: string;
  holderName?: string;
  keyType?: string;
  saveForNextTransfer: boolean;
  agency?: string;
  bank?: string;
  bankAccount?: string;
  documentNumber?: string;
  transferType?: string;
}

interface UpdatePaymentListInput {
  id: number;
  data: {
    nameList: string;
    listOfBeneficiaries: Beneficiary[];
  };
}

interface UpdatePaymentList {
  id: null;
  userOrganizationId: null;
  accountId: null;
  data: {
    id: number;
    accountId: number;
    data: {
      nameList: string;
      listOfBeneficiaries: Beneficiary[];
    };
    userOrganizationId: number;
    createdAt: string;
    updatedAt: string;
    deletedAt: null;
  };
  deletedAt: null;
  createdAt: null;
  updatedAt: null;
}

export interface UpdatePaymentListResponse {
  data: UpdatePaymentList;
}

export function useUpdatePaymentList() {
  const mutation = gql`
    mutation UpdatePaymentList($params: UpdatePaymentListInput) {
      updatePaymentList(params: $params) {
        id
        userOrganizationId
        accountId
        data
        deletedAt
        createdAt
        updatedAt
      }
    }
  `;

  const fetch = async (params: UpdatePaymentListInput) => {
    const response = await request<UpdatePaymentListResponse>(mutation, {
      params,
    });
    return response;
  };

  return useMutation('useUpdatePaymentList', fetch);
}
