import * as Style from './BillModal.styles';
import * as Text from '~styles/text';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { GenericRow, RoundButtonIcon } from '~components';
import colors from '~styles/colors';
import { useEffect, useState } from 'react';
import GoBackArrowIcon from '~assets/svg/goBackArrow';
import CashInIcon from '~assets/svg/cashIn';
import CashOutIcon from '~assets/svg/cashOut';
import CopySVG from '~assets/svg/copy';
import DownloadSVG from '~assets/svg/download';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import { formatCurrencyFn } from '~helpers/format/currency';
import dayjs from 'dayjs';
import { formatCpfOrCnpj } from '~helpers/format/numbers';
import { getSelectedUserOrganization } from '~helpers/device';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { BillDocument } from '~assets/pdfs/bill/BillDocument';
import { copyText } from '~helpers/messages';
import bwipjs from 'bwip-js';
import Boleto from 'boleto.js';

const BillDetailsModal = ({
  openDetails,
  setOpenDetails,
}: {
  openDetails: boolean;
  setOpenDetails: (boolean: boolean) => void;
}) => {
  const goBack = () => setOpenDetails(false);
  const {
    params: { slipByAuthCode },
  } = useOpenModalSideBar();
  const [barcode64, setBarcode64] = useState('');

  const barCodeComponent = (
    <div className="barcode-component">
      <p style={{ width: 120, color: colors.grayMedium }}>
        {`${slipByAuthCode?.digitable.slice(0, 13)}...`}
      </p>

      <RoundButtonIcon
        buttonSize="icon16"
        onClick={() =>
          copyText(
            slipByAuthCode?.digitable,
            'Código de boleto copiado com sucesso!'
          )
        }
      >
        <CopySVG />
      </RoundButtonIcon>
    </div>
  );

  const userOrganizationId = getSelectedUserOrganization();

  const billPdfName = `boleto-${dayjs(slipByAuthCode?.emissionDate).format(
    'DD-MM-YYYY hh:mm'
  )}.pdf`;

  useEffect(() => {
    return () => setOpenDetails(false);
  }, [setOpenDetails]);
  const { data: capAccountInfo, isLoading: loadingCapAcc } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  useEffect(() => {
    if (!slipByAuthCode) return;

    const barcode =
      slipByAuthCode.digitable &&
      new Boleto(slipByAuthCode.digitable).barcode();

    const canvas = bwipjs.toCanvas('#barcode-canva', {
      bcid: 'interleaved2of5',
      text: barcode,
      includetext: false,
    });

    const base64 = canvas.toDataURL();
    setBarcode64(base64);
  }, []);

  return (
    <Style.BillDetails fadeIn={openDetails}>
      <canvas
        id="barcode-canva"
        style={{ display: 'none', visibility: 'hidden' }}
      />
      <div className="goBackButton" onClick={goBack}>
        <GoBackArrowIcon />
      </div>
      <Text.Heading2 marginBottom={35} marginTop={10}>
        Detalhes do depósito
      </Text.Heading2>

      <div className="detailsRow">
        <Text.Paragraph>Valor</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>
          {formatCurrencyFn(
            'BRL',
            String(Number(slipByAuthCode?.amount?.value))
          )}
        </Text.Paragraph>
      </div>
      <div className="detailsRow">
        <Text.Paragraph>Vencimento</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>
          {dayjs(slipByAuthCode?.dueDate).format('DD/MM/YYYY')}
        </Text.Paragraph>
      </div>
      <div className="detailsRow">
        <Text.Paragraph>Data e Horário</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>
          {dayjs(slipByAuthCode?.emissionDate).format('DD/MM/YYYY')} •{' '}
          {dayjs(slipByAuthCode?.emissionDate).format('H:mm')}
        </Text.Paragraph>
      </div>

      <div className="detailsDivider">
        <div className="icon">
          <CashInIcon />
        </div>
        <Text.Paragraph>Pagador</Text.Paragraph>
        <hr />
      </div>

      <div className="details-group">
        <div className="details-column">
          <Text.Paragraph>Nome</Text.Paragraph>
          <Text.Paragraph color={colors.grayMedium}>
            {slipByAuthCode?.payer?.name}
          </Text.Paragraph>
        </div>

        <div className="details-column">
          <Text.Paragraph>CPF/CNPJ</Text.Paragraph>
          <Text.Paragraph color={colors.grayMedium}>
            {formatCpfOrCnpj(slipByAuthCode?.payer?.document)}
          </Text.Paragraph>
        </div>

        <div className="details-column">
          <Text.Paragraph>Nome fantasia</Text.Paragraph>
          <Text.Paragraph color={colors.grayMedium}>
            {slipByAuthCode?.payer?.tradeName}
          </Text.Paragraph>
        </div>

        <div className="details-column">
          <Text.Paragraph>Endereço</Text.Paragraph>
          <Text.Paragraph color={colors.grayMedium}>
            {slipByAuthCode?.payer?.address?.addressLine}
          </Text.Paragraph>
        </div>

        <div className="details-column">
          <Text.Paragraph>Cidade</Text.Paragraph>
          <Text.Paragraph color={colors.grayMedium}>
            {slipByAuthCode?.payer?.address?.city}
          </Text.Paragraph>
        </div>

        <div className="details-column">
          <Text.Paragraph>CEP</Text.Paragraph>
          <Text.Paragraph color={colors.grayMedium}>
            {slipByAuthCode?.payer?.address?.zipCode}
          </Text.Paragraph>
        </div>

        <div className="details-column">
          <Text.Paragraph>Estado</Text.Paragraph>
          <Text.Paragraph color={colors.grayMedium}>
            {slipByAuthCode?.payer?.address?.state}
          </Text.Paragraph>
        </div>
      </div>

      <div className="detailsDivider mt-30">
        <div className="icon">
          <CashOutIcon />
        </div>
        <Text.Paragraph>Beneficiário</Text.Paragraph>
        <hr />
      </div>

      <div className="details-group mb-30">
        <div className="details-column">
          <Text.Paragraph>Nome</Text.Paragraph>
          <Text.Paragraph color={colors.grayMedium}>
            {slipByAuthCode?.recipientFinal?.name}
          </Text.Paragraph>
        </div>

        <div className="details-column">
          <Text.Paragraph>CPF/CNPJ</Text.Paragraph>
          <Text.Paragraph color={colors.grayMedium}>
            {slipByAuthCode?.recipientFinal?.document}
          </Text.Paragraph>
        </div>

        <div className="details-column">
          <Text.Paragraph>Agência</Text.Paragraph>
          <Text.Paragraph color={colors.grayMedium}>
            {capAccountInfo?.branch}
          </Text.Paragraph>
        </div>

        <div className="details-column">
          <Text.Paragraph>Conta</Text.Paragraph>
          <Text.Paragraph color={colors.grayMedium}>
            {capAccountInfo?.account}
          </Text.Paragraph>
        </div>
      </div>

      <GenericRow
        title="Linha digitável"
        noHorizontalPadding
        leftContentIconType="none"
        rightComponent={barCodeComponent}
        rightContentType="component"
      />

      <GenericRow
        title="Protocolo"
        description={slipByAuthCode?.authenticationCode}
        noBorderTop
        noHorizontalPadding
        leftContentIconType="none"
        rightComponent={
          <RoundButtonIcon
            buttonSize="icon16"
            onClick={() =>
              copyText(
                slipByAuthCode?.authenticationCode,
                'Código de Protocolo copiado com sucesso!'
              )
            }
          >
            <CopySVG />
          </RoundButtonIcon>
        }
        rightContentType="component"
      />

      <PDFDownloadLink
        document={<BillDocument details={slipByAuthCode} barcode={barcode64} />}
        fileName={billPdfName}
      >
        <div className="btn-wrapper mt-30 mb-10">
          <div className="pdf-button">
            <DownloadSVG />
            Baixar boleto em PDF
          </div>
        </div>
      </PDFDownloadLink>
    </Style.BillDetails>
  );
};

export default BillDetailsModal;
