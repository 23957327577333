import { SVGProps } from 'react';

const CashInIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 2.00598V1.00598H11V2.00598V4.00598V5.00598H13V4.00598V2.00598ZM9 3.00598V2.00598H7V3.00598V4.00598V5.00598H9V4.00598V3.00598ZM17 3.00598V2.00598H15V3.00598V4.00598V5.00598H17V4.00598V3.00598ZM4 7.50598C3.44772 7.50598 3 7.9537 3 8.50598V19.006C3 19.5583 3.44772 20.006 4 20.006H20C20.5523 20.006 21 19.5583 21 19.006V8.50598C21 7.9537 20.5523 7.50598 20 7.50598H4ZM5 18.006V9.50598H19V18.006H5ZM12 16.006C10.7574 16.006 9.75 14.9986 9.75 13.756C9.75 12.5133 10.7574 11.506 12 11.506C13.2426 11.506 14.25 12.5133 14.25 13.756C14.25 14.9986 13.2426 16.006 12 16.006Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default CashInIcon;
