import * as React from 'react';
import { SVGProps } from 'react';

const PersonIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '16'}
      height={props.height || '19'}
      viewBox="0 0 16 19"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 5.50604C2.5 2.45252 4.94895 0.00604248 8.00854 0.00604248C11.0731 0.00604248 13.5 2.45748 13.5 5.50604C13.5 7.58397 12.3656 9.38171 10.6783 10.3177C11.442 10.5043 12.2257 10.7923 12.952 11.2267C14.6841 12.2627 16 14.0848 16 17.006V18.006L15 18.006L1 18.006L0 18.006V17.006C0 14.0848 1.31591 12.2627 3.04804 11.2266C3.77426 10.7923 4.55797 10.5043 5.32171 10.3177C3.63442 9.38171 2.5 7.58397 2.5 5.50604ZM8.00854 2.00604C6.05105 2.00604 4.5 3.55956 4.5 5.50604C4.5 7.45376 6.05229 9.00604 8 9.00604C9.94772 9.00604 11.5 7.45376 11.5 5.50604C11.5 3.5546 9.96107 2.00604 8.00854 2.00604ZM2.07159 16.006L13.9284 16.006C13.6895 14.4323 12.8807 13.5145 11.9253 12.943C10.7094 12.2157 9.16739 12.006 8 12.006C6.83261 12.006 5.29065 12.2157 4.07469 12.943C3.11926 13.5145 2.31047 14.4322 2.07159 16.006Z"
        fill={props.fill || '#757893'}
      />
    </svg>
  );
};

export default PersonIcon;
