import * as Text from '~styles/text';
import * as yup from 'yup';
import { Dispatch, SetStateAction, useCallback, useMemo, useRef } from 'react';
import { FooterButton, InputForm, PasswordChecker } from '~components';
import { cpfMask } from '~helpers/format/masks';
import { PossiblesSteps } from '../ForgotPasswordGuest.types';
import { breakpoints } from '~styles/metrics';
import { colors } from '~styles';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '~hooks/windowDimension';
import { yupResolver } from '@hookform/resolvers/yup';
import { strongPasswordRegex } from '~validations/stringsValidate';

import LockIcon from '~assets/images/lock-icon.svg';
import { usePasswordResetGuest } from '~reactQuery/mutations/user/usePasswordResetGuest';

export const ResetPassword = ({
  setStep,
  emailToken,
}: {
  setStep: Dispatch<SetStateAction<PossiblesSteps>>;
  emailToken: string;
}) => {
  const { mutateAsync: resetPassword, isLoading: loadingResetPassword } =
    usePasswordResetGuest();

  const navigate = useNavigate();

  const { width } = useWindowDimensions();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<any>({
    mode: 'onSubmit',
    resolver: yupResolver(
      yup.object({
        documentNumber: yup
          .string()
          .required('Esse campo é obrigatório')
          .min(14, 'Deve conter 11 números'),
        passwd: yup
          .string()
          .required('Esse campo é obrigatório')
          .matches(
            strongPasswordRegex,
            'Deve ao menos 8 caracteres, um maiúsculo, um minúsculo, um número e um caractere especial'
          ),
        confirmPassword: yup
          .string()
          .required('Esse campo é obrigatório')
          .matches(
            strongPasswordRegex,
            'Deve ao menos 8 caracteres, um maiúsculo, um minúsculo, um número e um caractere especial'
          )
          .oneOf([yup.ref('passwd')], 'Senhas não coincidem'),
      })
    ),
  });

  const password = watch('passwd');

  const onSubmit = handleSubmit(async (data) => {
    const parsedDocNumber = data.documentNumber
      .replaceAll('.', '')
      .replace('-', '');

    const parsedData = {
      ...data,
      documentNumber: parsedDocNumber,
      confirmPasswd: data.confirmPassword,
      emailToken,
    };

    try {
      await resetPassword(parsedData);

      setStep('success');
    } catch (error: any) {
      if (error.message.includes('UNKNOWN_USER')) {
        setStep('failed');
      }
    }
  });

  return (
    <>
      <div className="footer-avoid header-avoid">
        <div className="main-icon">
          <LockIcon />
        </div>
        <Text.Heading1Medium marginTop={10} marginBottom={30}>
          Vamos alterar a senha?
        </Text.Heading1Medium>

        <Text.Heading5
          color={colors.black}
          marginBottom={30}
          lineHeight={140}
          width={breakpoints.desktop < width ? '100%' : ''}
        >
          Esta senha vai servir para você acessar sua conta empresarial, então
          escolha
          <br />
          uma senha forte, e não a reutilize em outras contas.
          <br />
          <br />
          Para alterar sua senha, preencha os dados solicitados abaixo:
        </Text.Heading5>
        <div className="display-flex-column-24">
          <InputForm
            {...register('documentNumber')}
            label="Seu CPF"
            placeholder="Digite seu CPF"
            error={errors.documentNumber?.message}
            maskFunction={cpfMask}
            autoFocus
          />

          <InputForm
            {...register('passwd')}
            label={t('Nova senha')}
            placeholder="digite sua nova senha"
            type="password"
            error={errors.passwd?.message}
          />
          <PasswordChecker password={password} marginTop={-20} />

          <InputForm
            {...register('confirmPassword')}
            type="password"
            placeholder="Confirme sua nova senha"
            label={t('Confirmar nova senha')}
            error={errors.confirmPassword?.message}
          />
        </div>
      </div>
      <FooterButton
        onClickGenericButton={onSubmit}
        loadingGenericButton={loadingResetPassword}
        leftButtonContent="Voltar"
        onClickLeftButton={() => navigate('/')}
        genericButtonType="secondary"
        genericButtonContent="Alterar senha"
      />
    </>
  );
};
