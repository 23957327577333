import colors from '~styles/colors';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .bar-percentage {
    z-index: 100 !important;
  }

  .multi-step-bottom {
    z-index: 100;
    position: relative;
  }

  .container-form-payment-batch {
    input {
      border-radius: 0px !important;
      outline: none !important;
      border-bottom: ${colors.grayLight} solid 1px;
      padding-left: 0px;
      background-color: transparent !important;
      font-feature-settings: 'ss02' on, 'zero' on;
    }
  }

  .content-transfer-batch {
    display: flex;
    flex-direction: column;
    padding: 10px 38px 0px 38px;

    .row-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .circle {
        width: 40px;
        height: 40px;
        background: ${colors.mainGray};
        border-radius: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .left,
      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    .border {
      border-bottom: 1px solid #eeeff6;
    }

    .row-confirm-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 18px 0px;

      .circle-confirm-list {
        width: 40px;
        height: 40px;
        background: ${colors.mainGray};
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .left-confirm-list {
        display: flex;
        flex-direction: row;
        align-items: center;

        .description {
          display: flex;
          flex-direction: column;
          margin-left: 8px;

          .item-with-tooltip {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
          }
        }
      }

      .right-confirm-list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }

    .container-divider {
      display: flex;
      flex-direction: row;
      align-items: center;

      .container-arrow-down {
        width: 44px;
        height: 44px;
        border-radius: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1.5px solid #eeeff6;

        svg {
          transform-origin: center;
          transform: rotate(270deg);
          -webkit-transform: rotate(270deg);
        }
      }
      .divider {
        flex: 1;
        min-height: 1px;
        background-color: #eeeff6;
      }
    }
  }

  .button-row-transfer-batch {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  .content-add-beneficiary {
    display: flex;
    flex-direction: column;

    .input-search-beneficiary {
      height: 46px;

      padding: 0 17px;
      outline: 1px solid ${colors.grayRegular};
      border: none;
      border-bottom: 1px solid ${colors.grayRegular};
      border-radius: 7px;
      transition: 0.2s ease-out;
      box-sizing: border-box;
      cursor: text;

      ::placeholder {
        color: ${colors.grayRegular};
      }
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 5px;
    }

    input {
      border-radius: 0px !important;
      outline: none !important;
      border-bottom: ${colors.grayRegular} solid 1px;
      padding-left: 0px;
      background-color: transparent !important;
      font-feature-settings: 'ss02' on, 'zero' on;
    }

    .modal-step-bottom-add-beneficiary {
      background-color: ${colors.white};
      height: 78px;
      align-items: center;
    }

    .row-type-key {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 18px 0px;
      justify-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.04);
      cursor: pointer;

      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }

      .right {
      }
    }
  }

  .input-select {
    background-color: transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
    border-color: ${colors.grayRegular};
    padding: 0px !important;
  }

  .transfer-batch-status-container {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
    align-items: center;

    .justify-center {
      width: 100%;
      height: 440px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .description-status-transfer {
      width: 100%;
      max-width: 420px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .container-loading {
      margin-top: 15px;
    }
  }

  .container-pagination-contacts {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
    .left-arrow {
      transform: rotate(180deg);
    }
    .box {
      padding: 4px;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
`;

export const TabMenu = styled.div`
  display: flex;
  flex-direction: row;

  padding: 0 35px;
  border-bottom: 1px solid ${transparentize(0.9, colors.black)};

  & p {
    padding: 15px 13px;
    cursor: pointer;

    &.active {
      border-bottom: 2px solid ${colors.black};
    }

    &.disabled {
      opacity: 0.4;
    }
  }
`;

export const CircleCheckbox = styled.div<{
  background?: string;
  type?: string;
}>`
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: 1.5px solid ${colors.black};
  background-color: ${(props) => {
    if (props.type === props.background) {
      return colors.black;
    }

    return colors.transparent;
  }};
`;

export const Row = styled.div`
  width: 100%;
  height: 63px;
  background-color: ${colors.white};
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 16px;
  cursor: pointer;

  .circle {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .description {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }

  .last-item {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
  }
`;

export const ContainerSpreadsheetTemplate = styled.div`
  width: 100%;
  min-height: 128px;
  background-color: ${colors.whiteLight};
  margin-top: 20px;
  border-radius: 10px;
  padding: 16px;

  .title-spreadsheet {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  .row-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    gap: 8px;
  }
`;

export const ContainerError = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ProgressContainer = styled.div`
  width: 100%;
  height: 6px;
  margin-top: 16px;
  border-radius: 8px;
  background-color: ${transparentize(0.7, colors.grayLight)};

  .bar {
    transition: width 0.3s ease-out;
    // width está sendo setado na prop styles da propria div,
    // por motivos de screen flickering, ao passar props real-time dynamic ao styled-components;
    max-width: 100%;
    height: 6px;
    background-color: ${colors.black};
    border-radius: 8px;
  }
`;

export const TransferQueue = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  max-height: 179px;
  overflow-y: auto;

  .item-transfer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    .left {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .right {
    }

    &:first-of-type {
      border-top: none;
    }
  }
`;

export const TransferFailed = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .right {
    cursor: pointer;
  }

  .number-of-failures {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 400px;
    background-color: ${colors.grayOpacity20p};
  }
`;

export const TransferRealized = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  max-height: 179px;
  overflow-y: auto;

  .item-transfer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    .left {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .right {
    }
  }
`;

export const ErrorOnAttachList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0px;

  .icon{
    width: 24px;
    height: 24px;
  }

  .link{
    color: ${colors.primary};
    cursor:pointer;
    text-decoration: underline;
  }
`;

export const ErrorItemAttachList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  max-height: 179px;
  overflow-y: auto;

  .item-error {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    .left {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .right {
    }
  }
`;
