import { colors } from '~styles';
import styled, { css } from 'styled-components';

import { transparentize } from 'polished';

export const Container = styled.div<{
  width?: number;
  disabled?: boolean;
  checked?: boolean;
}>`
  width: ${({ width }) => width || '142px'};

  border-radius: 8px;
  padding: 15px 10px 15px 15px;
  display: flex;

  .right-side {
    width: 25%;
    margin-top: -7px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .left-side {
    width: 75%;
  }

  ${({ checked }) =>
    checked
      ? css`
          border: 1px solid ${colors.black};
        `
      : css`
          border: 1px solid ${colors.grayLight};
        `}

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          opacity: 1;
          :hover {
            cursor: pointer;
            background-color: ${transparentize(0.95, colors.grayLight)};
          }
        `};
`;
