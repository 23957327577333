import { gql, request, useQuery } from '~reactQuery';

export interface CurrencyCryptoAddressInput {
  crypto: string;
  forceNew?: boolean;
  takenBy: string;
  takenByType: string;
  userOrganizationId: number;
}

export interface CurrencyCryptoAddressResponse {
  currencyCryptoAddress: {
    createdAt: string;
    currency: number;
    id: number;
    takenBy: number;
    takenByType: string;
    updatedAt: string;
    walletAddress: string;
  };
}

export const useCurrencyCryptoAddress = (
  params: CurrencyCryptoAddressInput
) => {
  const QUERY = gql`
    query CurrencyCryptoAddress($params: CurrencyCryptoAddressInput!) {
      currencyCryptoAddress(params: $params) {
        id
        takenByType
        takenBy
        currency
        walletAddress
        createdAt
        updatedAt
      }
    }
  `;

  const fetcher = async () => {
    const response = await request<CurrencyCryptoAddressResponse>(QUERY, {
      params,
    });
    return response.currencyCryptoAddress;
  };

  return useQuery(
    [
      'useCurrencyCryptoAddress',
      params.crypto,
      params.userOrganizationId,
      params.takenBy,
    ],
    fetcher,
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );
};
