import * as React from 'react';
import { SVGProps } from 'react';

const WalletBrlSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.649 23.187v-1.176a4.185 4.185 0 0 1-2.742-.951 3.163 3.163 0 0 1-.982-2.436h2.538c-.01.4.098.795.31 1.135a1.032 1.032 0 0 0 .827.366l.007-2.282a6.254 6.254 0 0 1-2.71-1.138 2.414 2.414 0 0 1-.835-1.92 2.626 2.626 0 0 1 .998-2.14 4.26 4.26 0 0 1 2.685-.847v-.894h1.102v.88a3.847 3.847 0 0 1 2.518.833 2.938 2.938 0 0 1 .959 2.21H20.91a1.526 1.526 0 0 0-.304-.927 1.014 1.014 0 0 0-.782-.332v2.094c1.023.18 1.99.597 2.825 1.219a2.56 2.56 0 0 1 .832 2.008 2.747 2.747 0 0 1-.943 2.245 4.85 4.85 0 0 1-2.834.889v1.162l-1.055.002Zm.166-7.768v-1.882h-.055a1.476 1.476 0 0 0-.901.229.812.812 0 0 0-.304.692.69.69 0 0 0 .28.57c.298.193.63.326.98.392v-.001Zm.82 4.703c.351.023.7-.058 1.005-.234a.87.87 0 0 0 .051-1.34 3.048 3.048 0 0 0-1.055-.453v2.027ZM11.745 22.097l-2.212-4.07h-.838v4.07H6.266v-10.26h3.9c1.966 0 3.918.753 3.918 3.1a2.784 2.784 0 0 1-2.125 2.769l2.672 4.39-2.886.001Zm-1.75-8.258H8.68v2.39h1.17c.797 0 1.792-.203 1.792-1.246 0-.957-.908-1.144-1.646-1.144Z"
      fill="#639C55"
    />
  </svg>
);

export default WalletBrlSVG;
