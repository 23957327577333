import * as Style from './EnableTwoFa.styles';

import { SetCurrentStepProps, StepParamsProps } from './EnableTwoFa.types';
import { useEffect, useState } from 'react';

import AskForCodes from './ModalSteps/2.AskForCodes.screen';
import Dialog from '~components/Dialog';
import ShowQrCode from './ModalSteps/1.ShowQrCode.screen';
import ShowRecoveryCodes from './ModalSteps/3.ShowRecoveryCodes';
import SuccessActiveTwoFa from './ModalSteps/4.SuccessActive.screen';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import ModalMultiStep from '~components/ModalMultiStep';

function EnableTwoFaModals() {
  const { openModalMultiStep, setOpenModalMultiStep } =
    useActionsOnModalMultiStep();

  const [currentStep, setCurrentStep] =
    useState<SetCurrentStepProps>('showQrCode');
  const [stepParams, setStepParams] = useState<StepParamsProps>({});

  function setStepsAndParams(step: SetCurrentStepProps, params?: object) {
    if (params) {
      setStepParams((prev) => ({ prev, ...params } as StepParamsProps));
    }
    setCurrentStep(step);
  }

  const modalStepsAndDetails = {
    showQrCode: {
      component: (
        <ShowQrCode key="showQrCode" setStepsAndParams={setStepsAndParams} />
      ),
      progressValue: 25,
    },
    askForCodes: {
      component: (
        <AskForCodes key="askForCodes" setStepsAndParams={setStepsAndParams} />
      ),
      progressValue: 50,
    },
    showRecoveryCodes: {
      component: (
        <ShowRecoveryCodes
          key="showRecoveryCode"
          setStepsAndParams={setStepsAndParams}
          stepParams={stepParams}
        />
      ),
      progressValue: 75,
    },
    successActiveTwoFa: {
      component: <SuccessActiveTwoFa key="successActiveTwoFa" />,
      progressValue: 100,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  // CLEAR STEP ON MODAL CLOSE
  useEffect(() => {
    if (!openModalMultiStep) setCurrentStep('showQrCode');
  }, [openModalMultiStep]);

  return (
    <Style.EnableTwoFaContainer>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={currentStepInfos.progressValue}
      >
        {currentStepInfos.component}
      </ModalMultiStep>
    </Style.EnableTwoFaContainer>
  );
}

export default EnableTwoFaModals;
