import * as Text from '~styles/text';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ArrowLeftSVG from '~assets/svg/arrowLeft';
import InputCurrency from '~components/Inputs/InputCurrency/InputCurrency.component';
import { useEffect, useState, ChangeEvent } from 'react';
import { InputForm, InputSwitch } from '~components/Inputs';
import { SavedListsProps } from '../SavedLists.types';
import { When } from 'react-if';
import { ErrorToast } from '~components/Toast';

const ConfirmValueAddBeneficiary = ({
  beneficiaryPerPix,
  setStepSavedLists,
  handleAddBeneficiary,
  setBeneficiaryPerPix,
}: SavedListsProps) => {
  const [amount, setAmount] = useState('R$ 0,00');
  const [fullName, setFullName] = useState('');
  const [errorFullName, setErrorFullName] = useState('');
  const [saveForNextTransfer, setSaveForNextTransfer] = useState(false);

  useEffect(() => {
    const object = {
      ...beneficiaryPerPix,
      transferType: 'Pix',
    };

    setBeneficiaryPerPix(object);
  }, []);

  const handleNext = () => {
    setErrorFullName('');

    if (!fullName && !beneficiaryPerPix?.fullName) {
      setErrorFullName('Campo de nome deve ser preenchido.');
      ErrorToast({
        title: 'Deve ser inserido um nome ao beneficiário!',
      });
      return;
    }

    handleAddBeneficiary({ amount, saveForNextTransfer, fullName });
  };

  return (
    <>
      <div
        className="go-back-button-confirm-value-add-beneficiary"
        onClick={() => setStepSavedLists('editList')}
      >
        <ArrowLeftSVG />
        <Text.Heading2Bold>
          Transferir para{' '}
          {beneficiaryPerPix?.fullName || beneficiaryPerPix?.keyValue}
        </Text.Heading2Bold>
      </div>

      <InputCurrency
        defaultValue="R$ 0,00"
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setAmount(event.target.value)
        }
        className={
          amount !== 'R$ 0,00' ? 'input-value' : 'input-value without-value'
        }
      />

      <When condition={!beneficiaryPerPix?.fullName}>
        <Text.Heading5Bold>Nome completo do beneficiário</Text.Heading5Bold>
        <InputForm
          placeholder=""
          value={fullName}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setFullName(event.target.value)
          }
          error={errorFullName}
        />
      </When>

      <div className="row-switch">
        <Text.Heading5Bold>
          Salvar contato para próximas transações
        </Text.Heading5Bold>
        <InputSwitch
          checked={saveForNextTransfer}
          onChange={() => setSaveForNextTransfer(!saveForNextTransfer)}
          className="input-switch"
        />
      </div>

      <ModalMultiStepBottom
        singleButtonText="Confirmar"
        singleButtonWidth="133px"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => handleNext()}
        disableSingleButton={!amount || amount === 'R$ 0,00'}
      />
    </>
  );
};

export default ConfirmValueAddBeneficiary;
