import { colors } from '~styles';
import { GeneralStyleProps } from 'src/@types/general';
import styled from 'styled-components';

export const ContainerInput = styled.div<GeneralStyleProps>`
  display: flex;
  align-items: baseline;
  flex-direction: column;

  .inputWrapper {
    display: flex;
    align-items: baseline;
  }

  .text {
    font-family: 'Helvetica Now Display Medium';
    font-weight: 500;
    font-size: 31px;
    color: ${colors.black};
  }
  .input {
    width: 100%;
    font-family: 'Helvetica Now Display Medium';
    font-weight: 500;
    font-size: 31px;
    background-color: ${colors.transparent};
    padding-bottom: ${(props) => props.paddingBottom || 0}px;
    resize: none;
    outline: none;
    border: none;
    overflow: hidden;
    margin-top: ${(props) => props.marginTop || 0}px;
    margin-bottom: ${(props) => props.marginBottom || 0}px;

    ::placeholder {
      color: ${colors.black};
      opacity: 1;
    }
  }
`;

export const Input = styled.input<GeneralStyleProps>`
  width: 100%;
  font-family: 'Helvetica Now Display Medium';
  font-weight: 500;
  font-size: 31px;
  background-color: ${colors.transparent};
  padding-bottom: ${(props) => props.paddingBottom || 0}px;
  resize: none;
  outline: none;
  border: none;
  overflow: hidden;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;

  ::placeholder {
    color: ${colors.grayRegular};
    opacity: 1;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
`;
