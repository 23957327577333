import * as Style from './Dialog.styles';

import { DialogBottomProps } from './Dialog.types';
import { GenericButton } from '~components/Buttons';
import { When } from 'react-if';

function DialogBottom(props: DialogBottomProps) {
  const {
    singleButtonText,
    doubleButtonTextOne,
    singleButtonAction,
    doubleButtonTextTwo,
    doubleButtonOneAction,
    doubleButtonTwoAction,
    singleButtonWidth,
    singleButtonAlignment,
    disableSingleButton,
    singleButtonLoading,
    disableDoubleButtonOneAction,
  } = props;

  return (
    <Style.ContainerDialogBottomPart>
      <Style.BottomContainer>
        <When condition={!!singleButtonText}>
          <div
            style={{
              justifyContent: singleButtonAlignment || 'flex-end',
              display: 'flex',
              width: singleButtonWidth || '100%',
            }}
          >
            <GenericButton
              disabled={disableSingleButton}
              width={singleButtonWidth || '100%'}
              buttonType="secondary"
              onClick={singleButtonAction || (() => {})}
              buttonLoading={singleButtonLoading}
              borderRadius={200}
            >
              {singleButtonText}
            </GenericButton>
          </div>
        </When>

        <When condition={!!doubleButtonTextOne}>
          <Style.TwoButtonsContainer>
            <When condition={!!doubleButtonTextOne}>
              <GenericButton
                onClick={doubleButtonOneAction || (() => {})}
                buttonType="default"
                disabled={disableDoubleButtonOneAction}
                borderRadius={200}
              >
                {doubleButtonTextOne}
              </GenericButton>
            </When>
            <When condition={!!doubleButtonTextTwo}>
              <GenericButton
                onClick={doubleButtonTwoAction || (() => {})}
                buttonType="secondary"
                borderRadius={200}
              >
                {doubleButtonTextTwo}
              </GenericButton>
            </When>
          </Style.TwoButtonsContainer>
        </When>
      </Style.BottomContainer>
    </Style.ContainerDialogBottomPart>
  );
}

export default DialogBottom;
