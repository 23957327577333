import { useRef } from 'react';
import * as Style from './AvatarUpload.styles';
import { AvatarUploadProps } from './AvatarUpload.types';
import FileUpload from '~assets/images/icons/24x24/file-upload-white.svg';
import { useGetOrganizationPartners } from '~reactQuery/queries/user/useGetOrganizationPartners';
import { getSelectedOrg, getSelectedUserOrganization, getUserId } from '~helpers/device';
import { Else, If, Then } from 'react-if';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import UserPic from '~components/UserPic';

const AvatarUpload = (props: AvatarUploadProps) => {
  const {
    onUpload,
    style,
  } = props;
  
  const { data: registeredUsers = [] } = useGetOrganizationPartners();

  const userOrganizationId = getSelectedUserOrganization();
  const orgId = getSelectedOrg();
  const userId = getUserId();


  const { data: capAccountInfo, isLoading: loadingCapAccountInfo } =
  useGetCapAccountInfo({
    userOrganizationId: Number(userOrganizationId),
  });

  const companyName = capAccountInfo?.name;
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const getUrlAvatarImage = registeredUsers.reduce((avatarUrl, user) => {
    if (avatarUrl) {
      return avatarUrl;
    }
  
    if (user.id === Number(userId)) {
      const userOrganization = user.userOrganizations.find((userOrganizationItem) => {
        return userOrganizationItem.organizationsId === Number(orgId) && userOrganizationItem.organizations.image;
      });
  
      if (userOrganization) {
        return userOrganization.organizations.image;
      }
    }
  
    return avatarUrl;
  }, null);

  return (
    <Style.Container style={style}>
      <div
        onClick={() => uploadInputRef.current?.click()}
        className="container-upload"
      >
        <If condition={getUrlAvatarImage !== null}>
          <Then>
            <img className="avatar" src={String(getUrlAvatarImage)} alt='logo' />
          </Then>

          <Else>
            <UserPic className="avatar" width={64} height={64} borderRadius={100} name={companyName} />
          </Else>
        </If>


        <div className="container-loading">
          <div className="upload-icon">
            <FileUpload />
          </div>

        </div>
      </div>

      <input
        ref={uploadInputRef}
        type="file"
        accept="image/*"
        className="upload-input"
        onChange={onUpload}
      />
    </Style.Container>
  );
};

export default AvatarUpload;
