import * as React from 'react';
import { SVGProps } from 'react';

const EmailIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '20'}
      height={props.height || '16'}
      viewBox="0 0 20 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.73823 2.63827C1.80712 2.15449 2.24354 1.75083 2.79991 1.75083H17.1999C17.7563 1.75083 18.1927 2.15449 18.2616 2.63827L9.99991 8.14605L1.73823 2.63827ZM0.271074 3.0107C0.27097 3.01779 0.27097 3.02488 0.271074 3.03197V13.272C0.271074 14.6562 1.42687 15.7508 2.79991 15.7508H17.1999C18.5729 15.7508 19.7287 14.6562 19.7287 13.272V3.03194C19.7288 3.02487 19.7288 3.0178 19.7287 3.01072V2.772C19.7287 1.38776 18.5729 0.293167 17.1999 0.293167H2.79991C1.42687 0.293167 0.271074 1.38776 0.271074 2.772V3.0107ZM18.2711 4.38384V13.272C18.2711 13.8128 17.8069 14.2932 17.1999 14.2932H2.79991C2.19294 14.2932 1.72874 13.8128 1.72874 13.272V4.38384L9.59562 9.62843L9.99991 9.89795L10.4042 9.62843L18.2711 4.38384Z"
        fill={props.fill || '#757893'}
      />
    </svg>
  );
};

export default EmailIcon;
