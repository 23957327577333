import * as Text from '~styles/text';
import { When } from 'react-if';
import { formatCurrencyFn } from '~helpers/format/currency';
import { useEffect, useState } from 'react';
import BankAccountIcon from '~assets/images/icons/24x24/capconta.svg';
import { RoundButtonIcon } from '..';
import UserPic from '~components/UserPic';
import dayjs from 'dayjs';
import { getSelectedUserOrganization, getSettingsId } from '~helpers/device';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CopyIcon from '~assets/svg/copy';
import DownloadIcon from '~assets/images/icons/16x16/arrow-to-down.svg';
import DeleteIcon from '~assets/svg/delete';
import { parseError } from '~reactQuery/index';
import { SuccessToast } from '../Toast';
import { DropDown } from '~components/DropDown';
import { DropDownItem } from '~components/DropDown/DropDownItem';
import DotsFloatingMenu from '~assets/images/icons/kyc/dots-vertical.svg';
import BarcodeIcon from '~assets/images/icons/24x24/barcodev2.svg';
import { Slips } from '~reactQuery/types/bill.types';
import { useGetSlipByAuthCode } from '~reactQuery/queries/bills/useGetSlipByAuthCode';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import { useDeleteSlip } from '~reactQuery/mutations/deposit/useDeleteSlip';
import { useUserAuthTypes } from '~hooks/useUserAuthTypes';
import { BillDocument } from '~assets/pdfs/bill/BillDocument';
import BwipJs from 'bwip-js';
import Boleto from 'boleto.js';
import { billLabelStatus } from '~data/bill';
import AskPinTwoFa from '~src/common/modals/generic/AskPinTwoFa/AskPinTwoFa.index';

function BillItem({ item }: { item: Slips }) {
  const [barcode64, setBarcode64] = useState('');
  const [pinModal, setPinModal] = useState(false);
  const { auth } = useUserAuthTypes();
  const [hasError, setHasError] = useState({
    pin: '',
    twoFa: '',
  });

  const userOrganizationId = getSelectedUserOrganization();
  const settingsId = getSettingsId();

  const { mutateAsync: deleteBill, isLoading: isLoadingDeleteBill } = useDeleteSlip();
  const { setOpenModalSideBar, setModalSidebarContent, setParams } =
    useOpenModalSideBar();

  const { data: slipByAuthCode } = useGetSlipByAuthCode({
    userOrganizationId: Number(userOrganizationId),
    authCode: item.authCode,
  });

  const filteredLabelStatus = billLabelStatus.filter((s) => {
    if (s.status === item.status) {
      return s.label;
    }
    return '';
  });

  const copyDigCode = () => {
    navigator.clipboard.writeText(`${item?.digitableValue}`);

    SuccessToast({
      title: 'Código de boleto copiado com sucesso!',
      autoCloseEnable: true,
    });
  };

  const handleOpenDetailsModal = () => {
    setModalSidebarContent('billModal');
    setOpenModalSideBar(true);
    setParams({ slipByAuthCode, filteredLabelStatus });
  };

  const deleteBillItem = async (pin: string, twoFa?: string) => {
    setHasError({
      pin: '',
      twoFa: '',
    });

    if ((auth.includes('2-FA') && twoFa?.length !== 6) || pin.length !== 4) {
      return;
    }

    try {
      const twoFA = twoFa ? { token: twoFa } : {};

      const resp = await deleteBill({
        authCode: String(item.authCode),
        pin,
        ...twoFA,
        settingsId: Number(settingsId),
        userOrganizationId: Number(userOrganizationId),
      });

      if (resp) {
        SuccessToast({
          title: 'Cancelamento de boleto',
          subtitle: `Seu boleto no valor de ${formatCurrencyFn(
            'BRL',
            String(Number(item.original_value / 100))
          )} 
            foi cancelado com sucesso!`,
          autoCloseEnable: true,
        });

        setPinModal(false);
      }
    } catch (error: any) {
      const errorKey = parseError(error);

      if (errorKey === 'INVALID_PIN') {
        setHasError((prev) => ({ ...prev, pin: 'Pin inválido' }));
      }

      if (errorKey === 'INVALID_2FA_TOKEN') {
        setHasError((prev) => ({ ...prev, twoFa: '2-FA inválido' }));
      }
    }
  };

  const billPdfName = `boleto-${dayjs(slipByAuthCode?.emissionDate).format(
    'DD-MM-YYYY hh:mm'
  )}.pdf`;

  useEffect(() => {
    if (slipByAuthCode?.digitable) {
      const barcode = new Boleto(slipByAuthCode?.digitable).barcode();
      const canva = BwipJs.toCanvas('#barcode-canva-item', {
        bcid: 'interleaved2of5',
        text: barcode,
        includetext: false,
      });

      const base64 = canva.toDataURL();
      setBarcode64(base64);
    }
  }, [slipByAuthCode?.digitable]);

  return (
    <>
      <canvas
        id="barcode-canva-item"
        style={{ display: 'none', visibility: 'hidden' }}
      />

      <tr>
        <td className="row-item" onClick={() => handleOpenDetailsModal()}>
          <div className="flex items-center gap-10">
            <div className="txIcon">
              <BarcodeIcon />
            </div>

            <div>
              <Text.Heading5 data-html2canvas-ignore="true">
                Boleto de Depósito
              </Text.Heading5>
            </div>
          </div>
        </td>
        <td className="hide row-item" onClick={() => handleOpenDetailsModal()}>
          <div className="flex items-center gap-10">
            <div className="txIcon">
              <BankAccountIcon />
            </div>

            <Text.Heading5 data-html2canvas-ignore="true">
              Conta Principal
            </Text.Heading5>
          </div>
        </td>
        <td className="hide row-item" onClick={() => handleOpenDetailsModal()}>
          <div className="flex items-center gap-10">
            <UserPic name={slipByAuthCode?.payer.name} />

            <Text.Heading5 data-html2canvas-ignore="true">
              {slipByAuthCode?.payer.tradeName}
            </Text.Heading5>
          </div>
        </td>
        <td className="hide row-item" onClick={() => handleOpenDetailsModal()}>
          <Text.Heading5 data-html2canvas-ignore="true">
            {dayjs(item.createdAt).format('DD/MM/YYYY')}
          </Text.Heading5>
        </td>
        <td
          className="mobile-hide row-item"
          onClick={() => handleOpenDetailsModal()}
        >
          <Text.Heading5 data-html2canvas-ignore="true">
            {dayjs(item.dueDate.slice(0, 10)).format('DD/MM/YYYY')}
          </Text.Heading5>
        </td>

        <td className="row-item" onClick={() => handleOpenDetailsModal()}>
          <div className="flex items-center gap-10">
            <div className="txIcon">
              <div
                className={`rounded-flag ${filteredLabelStatus[0]?.itemColor}`}
              />
            </div>

            <Text.Heading5 data-html2canvas-ignore="true">
              {filteredLabelStatus[0]?.label}
            </Text.Heading5>
          </div>
        </td>

        <td className="row-item" onClick={() => handleOpenDetailsModal()}>
          <Text.Heading5 data-html2canvas-ignore="true">
            {formatCurrencyFn('BRL', String(Number(item.original_value / 100)))}
          </Text.Heading5>
        </td>

        <td>
          <DropDown
            content={
              <>
                <DropDownItem
                  icon={<CopyIcon />}
                  title="Copiar código do boleto"
                  onClick={() => copyDigCode()}
                />
                <PDFDownloadLink
                  document={
                    <BillDocument
                      details={slipByAuthCode}
                      barcode={barcode64}
                    />
                  }
                  fileName={billPdfName}
                >
                  <DropDownItem
                    icon={<DownloadIcon width={16} height={16} />}
                    title="Baixar boleto em PDF"
                  />
                </PDFDownloadLink>

                <When condition={filteredLabelStatus[0]?.label.toLowerCase() === 'em aberto'}>
                  <DropDownItem
                    icon={<DeleteIcon />}
                    title="Cancelar boleto"
                    onClick={() => setPinModal(true)}
                    isRed
                  />
                </When>
              </>
            }
          >
            <RoundButtonIcon buttonType="flat">
              <div className="display-center" style={{ cursor: 'pointer' }}>
                <DotsFloatingMenu />
              </div>
            </RoundButtonIcon>
          </DropDown>
        </td>
      </tr>

      <AskPinTwoFa
        isOpen={pinModal}
        setIsOpen={setPinModal}
        title="Cancelar boleto de depósito"
        description="Para iniciar o cancelamento do seu boleto, insira seu PIN de 4 dígitos abaixo."
        auth={auth}
        loading={isLoadingDeleteBill}
        hasError={hasError}
        onChange={(pin, twoFa) => deleteBillItem(pin, twoFa)}
      />
    </>
  );
}

export default BillItem;
