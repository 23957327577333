import * as Style from './Tabs.styles';
import * as Text from '~styles/text';

import { AnimatePresence, motion } from 'framer-motion';

import { TabsProps } from './Tabs.types';
import colors from '~styles/colors';
import { useEffect, useState } from 'react';
import { When } from 'react-if';

const Tabs = (props: TabsProps) => {
  const { hasContent = true } = props;
  const [selectedTab, setSelectedTab] = useState({
    tab: props.selectedTab,
  });

  function handleTab(item: TabsProps['tabs'][0]) {
    if (item.tabAction) item.tabAction();
    setSelectedTab({ tab: item.tabName });
  }

  const preSelectedTab = props.tabs.filter(
    (tab) => tab.tabName === selectedTab.tab
  )?.[0]?.tabContent;

  useEffect(() => {
    if (props.selectedTab) {
      setSelectedTab({ tab: props.selectedTab });
    }
  }, [props.selectedTab]);

  return (
    <Style.Container
      ulSize={props.ulSize}
      width={props.width}
      borderBottom={props.borderBottom}
      style={props.style}
      height={props.height}
    >
      <nav>
        <ul>
          {props.tabs?.map((item) => (
            <li
              key={item.tabName}
              className={item.tabName === selectedTab.tab ? 'selected' : ''}
              onClick={item.disable ? () => {} : () => handleTab(item)}
            >
              <div>
                {item.tabIcon}
                <Text.Heading5
                  color={item.disable ? colors.grayMedium : colors.black}
                >
                  {item.tabName}
                </Text.Heading5>
              </div>
              {item.tabName === selectedTab.tab ? (
                <motion.div className="underline" layoutId="underline" />
              ) : null}
            </li>
          ))}
        </ul>
      </nav>
      <When condition={hasContent}>
        <main>
          <AnimatePresence mode="wait">
            <motion.div
              key={selectedTab.tab ? selectedTab.tab : 'empty'}
              style={{ width: '100%', height: '100%' }}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {selectedTab ? preSelectedTab : 'Vazio'}
            </motion.div>
          </AnimatePresence>
        </main>
      </When>
    </Style.Container>
  );
};

export default Tabs;
