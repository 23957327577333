import { gql, request, useMutation } from '~reactQuery';

import { CreatePartnerDataInput } from '~reactQuery/types/business.types';
import { useQueryClient } from 'react-query';

interface CreatePartnerInput {
  processId?: number;
  businessId?: number;
  legalRepresent?: string;
  memberQualification?: string;
  declaredIncome?: string;
  isPowerOfAttorney?: boolean;
  powerOfAttorney?: string;
  powerOfAttorneyStartDate?: string;
  powerOfAttorneyEndDate: string;
  participationPercentage?: string;
  politicallyExposed?: string;
  pep?: string;
  documentNumber?: string;
  fileFrontDocument?: string;
  fileBackDocument?: string;
  registerName?: string;
  socialName?: string;
  countryCode?: string;
  number?: string;
  zipCode?: string;
  addressLine?: string;
  buildingNumber?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  country?: string;
  birthDate?: string;
  motherName?: string;
  email?: string;
  documentType?: 'RG' | 'CNH';
  partner?: [CreatePartnerDataInput];
}

interface CreatePartnerResponse {
  createPartnerData: {
    data: boolean;
    messages: [string];
    isValid: boolean;
  };
}

export function useCreatePartnerMutation() {
  const queryClient = useQueryClient();

  const mutation = gql`
    mutation CreatePartnerData($params: createPartnerDataInput!) {
      createPartnerData(params: $params) {
        data
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: CreatePartnerInput) => {
    const response = await request<CreatePartnerResponse>(mutation, {
      params,
    });
    return response.createPartnerData;
  };

  return useMutation('CreatePartner', fetch, {
    onSettled: () => {
      queryClient.invalidateQueries(['getCustomerData', 'getPartnerData']);
    },
  });
}
