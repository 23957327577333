import {
  PixBrCodeRequisite,
  PixKeyRequisiteSchema,
  TransferMethodPixEnum,
} from '~reactQuery/types/pix.types';
import { gql, request, useMutation } from '~reactQuery/index';

import { PIX_QUERY_TRANSFER } from '~src/constants/pix';

interface PixTransferUsingBrCodeInput {
  description: string | undefined;
  method: TransferMethodPixEnum;
  pin: string;
  requisite: PixBrCodeRequisite;
  userOrganizationId: number;
  value: number;
  token: string;
  settingsId: number;
  recovery: boolean;
}

export interface PixTransferUsingBrCodeResponse {
  pixTransferUsingBrCode: {
    authCode: string;
    description: string;
    method: TransferMethodPixEnum;
    value: number;
    requisite: PixKeyRequisiteSchema;
  };
}

export const usePixTransferUsingBrCode = () => {
  const QUERY = gql`
    mutation PixTransferScheduleBanking($params: PixTransferUsingBrCodeInput!) {
      pixTransferUsingBrCode(params: $params) {
        authCode
        value
        description
        method
        requisite {
          keyType
          addressingKeyValue
          e2eId
        }
      }
    }
  `;

  const fetcher = async (params: PixTransferUsingBrCodeInput) => {
    const response = await request<PixTransferUsingBrCodeResponse>(QUERY, {
      params,
    });
    return response;
  };

  return useMutation([PIX_QUERY_TRANSFER.PIX_TRANSFER_BR_CODE], fetcher);
};
