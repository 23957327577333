import { gql, request, useQuery } from '~reactQuery';

export interface GetNotificationsResponse {
  getGlobalNotificationsWarning: {
    data: any[];
    messages: [string];
    isValid: boolean;
  };
}

export function useGetGlobalNotificationsWarning() {
  const QUERY = gql`
    query GetGlobalNotificationsWarning {
      getGlobalNotificationsWarning {
        data {
          id
          key
          data
          active
          start
          finish
          createdAt
          updatedAt
        }
        messages
        isValid
      }
    }
  `;

  const fetcher = async () => {
    const response = await request<GetNotificationsResponse>(QUERY);

    return response?.getGlobalNotificationsWarning?.data || [];
  };

  return useQuery(['getGlobalNotificationsWarning'], fetcher, {
    refetchInterval: 60000, // 1 minute
  });
}
