import * as React from 'react';

import { SVGProps } from 'react';

const CopySVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 3.75h6.25V10h1.5V2.25H6.5v1.5Zm-3 1.5h-.75v8.5h8.5v-8.5H3.5Zm.75 7v-5.5h5.5v5.5h-5.5Z"
      fill="#272937"
    />
  </svg>
);

export default CopySVG;
