import styled from 'styled-components';

export const ToastCustom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;

export const IconInformationContainer = styled.div`
  display: flex;
  align-self: start;
  svg {
    width: 36px;
    height: 36px;
  }
`;

export const ButtonCloseToast = styled.div`
  margin-right: 5.75px;
  margin-top: 2.75px;
`;
