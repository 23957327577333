import * as S from '../Organizations.styles';
import * as Text from '~styles/text';

import {
  clearStorage,
  setSelectedOrg,
  setSelectedUserOrganization,
} from '~helpers/device';

import ArrowPointLeftSVG from '~assets/svg/arrowPointLeft';
import CardOrganization from '~components/CardOrganization';
import ExitSvg from '~assets/svg/exit';
import { InputOrgPinProps } from '../Organizations.types';
import { InputPin } from '~components/Inputs';
import PixKeySVG from '~assets/svg/pixKey';
import { RoundButtonIcon } from '~components/Buttons';
import UserSVG from '~assets/svg/user';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useGetProcess } from '~reactQuery/queries/kyc/useGetProcess';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useSignInOrganizationMutation } from '~reactQuery/mutations/business/useSignInOrganization';

const InputOrgPin = (props: InputOrgPinProps) => {
  const { partnerInfos, orgId, setCurrentStep } = props;
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { setOpenModalMultiStep, setSection, setParams } =
    useActionsOnModalMultiStep();

  const { mutateAsync: logInOrg } = useSignInOrganizationMutation();

  const { data: process } = useGetProcess();

  const userOrganizations = partnerInfos?.data?.userOrganizations?.filter(
    (orgs) => orgs.organizationsId === Number(orgId)
  )?.[0];

  const openRecoveryPin = () => {
    setSection('recoveryPin');
    setOpenModalMultiStep(true);
    setParams({
      orgId,
      organizationsId: userOrganizations?.organizations?.id,
      orgName: userOrganizations?.organizations?.name,
      userOrganizationsId: userOrganizations?.id,
    });
  };

  async function handleLoginOrg(pin: string) {
    try {
      await logInOrg({
        organizationId: userOrganizations?.organizations?.id as number,
        pin,
      });

      setSelectedUserOrganization(String(userOrganizations?.id));
      setSelectedOrg(String(orgId));

      const currOrg = process?.filter((org) => org.id === orgId)[0];

      const isOrgProcessDone = String(currOrg?.processStatus) === 'DONE';

      if (isOrgProcessDone) {
        navigate('/dashboard');
        return;
      }

      navigate('/kyc');
    } catch (error) {
      // console.error('error on logInOrg', error);
    }
  }

  function goBack() {
    setCurrentStep(0);
  }

  function logout() {
    clearStorage(queryClient);
    navigate('/auth');
  }

  if (!userOrganizations) return null;

  return (
    <S.Container>
      <div className="content-pin-step">
        <div className="container-logo-back">
          <div onClick={goBack} className="go-back">
            <ArrowPointLeftSVG />
          </div>

          <CardOrganization
            onClick={() => {}}
            isAdmin={userOrganizations?.admin}
            organizationId={userOrganizations?.organizationsId}
            name={userOrganizations?.organizations?.name}
            cardImage={userOrganizations?.organizations?.image}
            noBorder
          />
        </div>
        <Text.Heading1Regular marginTop={20} marginBottom={50}>
          Digite seu PIN de 4 dígitos
        </Text.Heading1Regular>
        <InputPin
          size={4}
          secret
          autoFocus
          onFinish={(pin) => handleLoginOrg(pin)}
        />

        <div className="container-account-actions mt-150">
          <div className="action-item disable">
            <RoundButtonIcon
              noHover
              onClick={() => {}}
              buttonSize="iconWallet"
              buttonType="default"
            >
              <UserSVG />
            </RoundButtonIcon>
            <Text.Paragraph>Minha conta</Text.Paragraph>
          </div>

          <div className="action-item ">
            <RoundButtonIcon
              onClick={openRecoveryPin}
              buttonSize="iconWallet"
              buttonType="default"
            >
              <PixKeySVG />
            </RoundButtonIcon>
            <Text.Paragraph>Recuperar PIN</Text.Paragraph>
          </div>
          <div className="action-item">
            <RoundButtonIcon
              onClick={logout}
              buttonSize="iconWallet"
              buttonType="default"
            >
              <ExitSvg />
            </RoundButtonIcon>
            <Text.Paragraph>Sair</Text.Paragraph>
          </div>
        </div>
      </div>
    </S.Container>
  );
};

export default InputOrgPin;
