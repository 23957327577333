import { SVGProps } from 'react';

const InfoSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox="0 0 16 16"
    fill={props.fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9558_4491)">
      <path
        d="M5.6 -0.000320015H10.4C11.867 -0.018221 13.2811 0.547277 14.3313 1.57181C15.3814 2.59635 15.9817 3.99604 16 5.46306V10.5363C15.9817 12.0033 15.3814 13.403 14.3313 14.4275C13.2811 15.4521 11.867 16.0176 10.4 15.9997H5.6C4.13297 16.0176 2.71889 15.4521 1.66873 14.4275C0.618573 13.403 0.01833 12.0033 0 10.5363L0 5.46306C0.01833 3.99604 0.618573 2.59635 1.66873 1.57181C2.71889 0.547277 4.13297 -0.018221 5.6 -0.000320015Z"
        fill="#C3C6DB"
      />
      <path
        d="M9.45233 11.5811C9.25071 11.6464 9.04111 11.6839 8.82935 11.6925C8.74516 11.7008 8.66039 11.6842 8.58549 11.6449C8.48452 11.5744 8.29972 11.4439 8.61788 10.8057L9.21705 9.61022C9.38896 9.32104 9.49165 8.99601 9.5171 8.66056C9.54255 8.3251 9.49006 7.98831 9.36374 7.6765C9.25163 7.45474 9.08901 7.26241 8.88898 7.115C8.68895 6.96758 8.45709 6.86919 8.21209 6.82776C8.024 6.78928 7.83253 6.76981 7.64055 6.76965C6.95371 6.77585 6.28911 7.01383 5.75446 7.44503C5.70267 7.49098 5.66837 7.55344 5.65737 7.6218C5.64638 7.69016 5.65936 7.76022 5.69412 7.8201C5.72888 7.87998 5.78329 7.926 5.8481 7.95035C5.91292 7.9747 5.98416 7.97588 6.04975 7.9537C6.25128 7.88787 6.4609 7.85004 6.67273 7.84129C6.75589 7.83325 6.8396 7.84946 6.91373 7.88797C7.01471 7.95846 7.19951 8.09182 6.88325 8.72814L6.28408 9.92457C6.11204 10.2137 6.00927 10.5387 5.98382 10.8742C5.95837 11.2097 6.01093 11.5465 6.13739 11.8583C6.24944 12.0801 6.41204 12.2725 6.61209 12.4199C6.81213 12.5673 7.04401 12.6657 7.28905 12.707C7.47719 12.7451 7.66863 12.7646 7.86059 12.7651C8.54775 12.7591 9.21273 12.5212 9.74763 12.0898C9.79915 12.0438 9.83324 11.9815 9.84417 11.9133C9.85509 11.8452 9.84218 11.7753 9.8076 11.7156C9.77302 11.6558 9.7189 11.6098 9.65436 11.5853C9.58982 11.5608 9.51785 11.5593 9.45233 11.5811Z"
        fill="white"
      />
      <path
        d="M8.57116 6.09428C9.36029 6.09428 10 5.45456 10 4.66543C10 3.87629 9.36029 3.23657 8.57116 3.23657C7.78202 3.23657 7.1423 3.87629 7.1423 4.66543C7.1423 5.45456 7.78202 6.09428 8.57116 6.09428Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_9558_4491">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default InfoSVG;
