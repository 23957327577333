import EmptyWalletsIcon from '~assets/images/icons/empty/no-wallets.svg';
import { colors } from '~styles';
import * as Text from '~styles/text';
import * as Style from './EmptyWallets.styles';
import { EmptyWalletsProps } from './EmptyWallets.types';

const EmptyWallets: React.FC<EmptyWalletsProps> = (props) => {
  return (
    <Style.Container>
      <div>
        <EmptyWalletsIcon />
      </div>
      <div className="column">
        <Text.Heading2Bold color={colors.grayRegular} textAlign="center">
          Nenhuma carteira encontrada
        </Text.Heading2Bold>
        <Text.Paragraph
          color={colors.grayLight}
          marginTop={8}
          textAlign="center"
        >
          Suas carteiras serão listadas aqui.
        </Text.Paragraph>
      </div>
    </Style.Container>
  );
};

export default EmptyWallets;
