import { breakpoints, screen } from '~styles/metrics';

import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import styled from 'styled-components';
import colors from '~styles/colors';
import KyCBanner from '../../assets/images/kyc/kyc-banner.jpg';
import { StylesProps } from './SignTemplate.types';

export const LoginContainer = styled(motion.main)`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const SideImage = styled(motion.div)`
  width: 47%;
  max-width: 640px;
  height: 100vh;
  background: url(${KyCBanner});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  max-width: 245px;
  margin-top: 25px;
  margin-left: 30px;

  :hover {
    cursor: pointer;
  }

  svg:last-child {
    margin-top: 5px;
    margin-left: 7.75px;
  }
`;

export const Content = styled.div<StylesProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  width: 100%;
  height: 100%;
`;

export const ScrollContainer = styled.div<StylesProps>`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: ${(props) => props.marginTop || 0}px;

  justify-content: ${(props) => props.justifyContent || 'center'};

  .header-avoid {
    margin-top: 100px;
  }

  .footer-avoid {
    margin-bottom: 100px;

    .container-validations {
      display: grid;

      grid-template-columns: 180px 160px 160px 100px;
      gap: 10px;
      margin-bottom: 15px;

      @media (max-width: 780px) {
        grid-template-columns: 180px 180px 160px;
      }

      @media (max-width: 640px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 576px) {
        grid-template-columns: 1fr;
      }
    }

    .main-icon {
      svg {
        border-radius: 15px;
      }
    }
  }
`;

export const ContentWrapper = styled.div<StylesProps>`
  width: ${(props) =>
    props.screenWidth && props.screenWidth < breakpoints.tablet
      ? '100%'
      : '560px'};
  padding: 0
    ${(props) =>
      props.screenWidth && props.screenWidth < breakpoints.tablet ? '20px' : 0};
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;

  .input-group {
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(1, 1fr);
    gap: 24px;

    @media ${screen.tablet} {
      display: flex;
      flex-direction: column;
    }
  }

  .forget-pass-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    margin-top: ${(props) =>
      props.screenWidth && props.screenWidth < breakpoints.tablet && '20px'};
    margin-bottom: 20px;
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .medium-icon {
    width: 48px;
    height: 48px;

    svg {
      border-radius: 15px;
    }
  }

  .warning-message {
    background-color: rgba(255, 99, 120, 0.1);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    gap: 5px;
    margin-bottom: 20px;

    .icon {
      min-width: 24px;
    }

    .link {
      color: ${colors.primary};
      cursor: pointer;
    }
  }

  .warning-message-two-factor {
    background-color: rgba(255, 99, 120, 0.1);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    gap: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    max-width: 280px;

    .icon {
      min-width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .link {
      color: ${colors.primary};
      cursor: pointer;
    }
  }

  .display-flex-column-24 {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const ErrorContainer = styled.p`
  margin-top: 7px;
  width: 100%;
  color: ${colors.danger};
  display: flex;
  align-items: flex-start;
  gap: 6px;
  justify-content: flex-start;

  svg {
    font-size: 16px;
    width: 16px;
    height: 16px;
  }

  span {
    width: 100%;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.025em;
  }
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 15px 20px 0 20px;
  background-color: ${colors.white};
  position: absolute;
  top: 0;

  .traslate-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
  }

  .translate-select {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    appearance: none;
    border: none;
    color: transparent;
    width: 24px;
    height: 24px;
    background-color: transparent;
  }
`;

export const ProgressContainer = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${transparentize(0.7, colors.grayLight)};
  position: absolute;
  bottom: 74px;
  right: 0;
  z-index: 1;

  .bar {
    transition: width 0.3s ease-out;
    // width está sendo setado na prop styles da propria div,
    // por motivos de screen flickering, ao passar props real-time dynamic ao styled-components;
    max-width: 100%;
    height: 3px;
    background-color: ${colors.black};
  }
`;
