import { SVGProps } from 'react';

const CnpjSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.143 5.964a.107.107 0 0 0-.107.107v12.965h7.5V6.07a.107.107 0 0 0-.107-.107H5.143Zm8.893 13.072h4.928V11.14a.107.107 0 0 0-.107-.107h-4.821v8.003Zm0-9.503V6.07c0-.887-.72-1.607-1.607-1.607H5.143c-.888 0-1.607.72-1.607 1.607v12.965H3a.75.75 0 0 0 0 1.5h18a.75.75 0 0 0 0-1.5h-.536V11.14c0-.888-.72-1.607-1.607-1.607h-4.821ZM6.107 8.382a.75.75 0 0 1 .75-.75H9.43a.75.75 0 1 1 0 1.5H6.857a.75.75 0 0 1-.75-.75Zm0 4.118a.75.75 0 0 1 .75-.75H9.43a.75.75 0 0 1 0 1.5H6.857a.75.75 0 0 1-.75-.75Zm9 .95a.75.75 0 0 1 .75-.75h1.286a.75.75 0 0 1 0 1.5h-1.286a.75.75 0 0 1-.75-.75Zm-9 3.168a.75.75 0 0 1 .75-.75H9.43a.75.75 0 0 1 0 1.5H6.857a.75.75 0 0 1-.75-.75Zm9 0a.75.75 0 0 1 .75-.75h1.286a.75.75 0 0 1 0 1.5h-1.286a.75.75 0 0 1-.75-.75Z"
      fill={props.fill || '#026FF4'}
    />
  </svg>
);

export default CnpjSVG;
