import { breakpoints, screen } from '~styles/metrics';
import styled, { css } from 'styled-components';

import colors from '~styles/colors';

export const Container = styled.div<{ isModalOpen: boolean; width: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;

  .period-dialog {
    padding: 5px 15px 0 15px;
    display: flex;
    flex-direction: column;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    bottom: 15px;
    padding: 14px 15px 0;

    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  .resize-container {
    width: 100%;
    height: calc(100% - 65px);

    transition: width 0.2s;

    ${({ isModalOpen, width }) =>
      isModalOpen &&
      breakpoints.tablet < width &&
      css`
        transition: width 1s ease !important;
        width: calc(100% - 400px) !important;
      `}
  }

  @media ${screen.tablet} {
    .scroll {
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const Content = styled.div`
  .container-loading {
    width: 100%;
    min-height: calc(100vh - 320px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filter-list {
    display: flex;
    width: 100%;
    flex-direction: row;

    @media ${screen.phone} {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
    }

    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
  }

  .button-v2 {
    padding: 10px, 15px, 10px, 15px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 104px;
    height: 42px;
    border-radius: 44px;
    justify-content: center;
    align-items: center;
    background-color: #f3f4f9;

    :hover {
      background-color: #e7e8ed;
      cursor: pointer;
    }
  }

  .gap-10 {
    gap: 10px;
  }

  .center-all {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .empty-transactions {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: calc(100vh - 320px);
    background-color: ${colors.white};
    border-radius: 10px;
  }

  .hide-doc-button {
    @media ${screen.tablet} {
      display: none;
    }
  }

  .container-chips-filter {
    gap: 10px;
    max-width: 100%;
    width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;

    justify-content: flex-end;

    @media ${screen.phone} {
      justify-content: flex-start;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .container-chips-filter::-webkit-scrollbar {
    display: none;
  }

  .paginator {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    margin-top: 20px;

    .flip-arrow {
      transform: rotate(180deg);
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .arrow {
      width: 20px;
      height: 20px;
      margin-top: 6px;
      cursor: pointer;
    }

    .page-index {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${colors.grayLight};
      border-radius: 5px;
      width: 20px;
      height: 20px;
      margin-left: 3px;
      margin-right: 3px;
      margin-top: 2px;
    }
  }
`;
