import { gql, request, useMutation } from '~reactQuery';

interface DeletePaymentListInput {
  id: number;
}

export interface DeletePaymentListResponse {
  data: {
    deletePaymentList: string;
  };
}

export function useDeletePaymentList() {
  const mutation = gql`
    mutation DeletePaymentList($params: DeletePaymentListInput) {
      deletePaymentList(params: $params)
    }
  `;

  const fetch = async (params: DeletePaymentListInput) => {
    const response = await request<DeletePaymentListResponse>(mutation, {
      params,
    });
    return response;
  };

  return useMutation('useDeletePaymentList', fetch);
}
