import BlueBarcodeIcon from '~assets/svg/blueBarcode';
import { CheckSelect } from '~components/index';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import PixSVG from '~assets/svg/pix';
import { PossiblesKeysPageProps } from '../PayBills.types';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useEffect, useState } from 'react';

function PossiblesKeys(props: PossiblesKeysPageProps) {
  const { setCurrentStep, buttonsPixGranted, payBillsGranted } = props;
  const [optionSelect, setOptionSelect] = useState('');
  const { setSection, setParams } = useActionsOnModalMultiStep();

  function goNext() {
    if (optionSelect?.length) {
      if (optionSelect === 'Pagar com Pix') {
        setSection('payWithPix');
        setParams({
          buttonsPixGranted,
          payBillsGranted,
          isComingFrom: 'payBills',
        });
      } else {
        setCurrentStep('showKeyExample', { keyType: optionSelect });
      }
    }
  }

  function selectBehavior(keyType: string) {
    if (optionSelect === keyType) {
      setOptionSelect('');
    } else {
      setOptionSelect(keyType);
    }
  }

  const possiblesKeysList = [
    {
      keyType: 'Pagar um boleto',
      keyValue:
        'Pague contas (água, luz, internet) através de um código de barras.',
      enabled: !!payBillsGranted,
      chipLabel: '',
      icon: <BlueBarcodeIcon />,
    },
    {
      keyType: 'Pagar com Pix',
      keyValue: 'Pague com Pix através de QR Code ou código Copia e Cola.',
      enabled: !!buttonsPixGranted,
      chipLabel: '',
      icon: <PixSVG />,
    },
  ];

  return (
    <>
      <ModalMultiStepHeader
        title="Pagar"
        description="Escolha o tipo de pagamento"
      />
      <div className="content-container">
        {possiblesKeysList?.map((item) => {
          return (
            <CheckSelect
              iconType="component"
              disabled={!item.enabled}
              key={item.keyType}
              title={item.keyType}
              text={item.keyValue}
              iconComponent={item.icon}
              chipText={item.chipLabel}
              onClick={() => selectBehavior(item.keyType)}
              selected={optionSelect === item.keyType}
            />
          );
        })}
      </div>
      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={optionSelect.length <= 0}
      />
    </>
  );
}

export default PossiblesKeys;
