import * as Style from './ChangePin.styles';

import { SetCurrentStepProps, StepParamsProps } from './ChangePin.types';
import { useEffect, useState } from 'react';

import AskPin from './ModalSteps/1.AskPins.screen';
import Dialog from '~components/Dialog';
import ModalMultiStep from '~components/ModalMultiStep';
import SuccessChangePin from './ModalSteps/2.Success.screen';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

function ChangePinModalSteps() {
  const { openModalMultiStep, setOpenModalMultiStep } =
    useActionsOnModalMultiStep();

  const [currentStep, setCurrentStep] = useState<SetCurrentStepProps>('askPin');
  const [stepParams, setStepParams] = useState<StepParamsProps>({});

  function setStepsAndParams(step: SetCurrentStepProps, params?: object) {
    if (params) {
      setStepParams((prev) => ({ prev, ...params } as StepParamsProps));
    }
    setCurrentStep(step);
  }

  const modalStepsAndDetails = {
    askPin: {
      component: (
        <AskPin key="askPassword" setStepsAndParams={setStepsAndParams} />
      ),
      progressValue: 50,
    },
    successChangePin: {
      component: <SuccessChangePin key="successChangePassword" />,
      progressValue: 100,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  // CLEAR STEP ON MODAL CLOSE
  useEffect(() => {
    if (!openModalMultiStep) setCurrentStep('askPin');
  }, [openModalMultiStep]);

  return (
    <Style.ChangePinContainer>
      <Dialog
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
      >
        {currentStepInfos.component}
      </Dialog>
    </Style.ChangePinContainer>
  );
}

export default ChangePinModalSteps;
