import { getSelectedUserOrganization, getSettingsId } from '~helpers/device';
import { gql, request, useMutation } from '~reactQuery';

interface Two2faValidadeInput {
  token: string;
  isGuest: boolean;
  emailToken: string;
}

interface TwoFaResponseType {
  twoFAValidate: {
    data: {
      isValid: boolean;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useTwo2faValidade() {
  const mutation = gql`
    mutation TwoFAValidate($params: twoFAValidateInput!) {
      twoFAValidate(params: $params) {
        data {
          isValid
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: Two2faValidadeInput) => {
    const response = await request<TwoFaResponseType>(
      mutation,
      {
        params: {
          token: params.token,
          isGuest: params.isGuest,
        },
      },
      {
        Authorization: `Bearer ${params?.emailToken}`,
      }
    );
    return response.twoFAValidate;
  };

  return useMutation('useTwo2faValidade', fetch);
}
