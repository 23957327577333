import * as Style from './RadioButton.styles';
import * as Text from '~styles/text';

import { RadioButtonProps } from './RadioButton.types';

const RadioButton = (props: RadioButtonProps) => {
  return (
    <Style.Container {...props}>
      <div className="shadow">
        <div className="circle" />
      </div>
    </Style.Container>
  );
};

export default RadioButton;
