import * as Style from './NewLimit.styles';
import { useState } from 'react';
import ModalMultiStep from '~components/ModalMultiStep';
import { SetCurrentStepProps } from './NewLimit.types';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import Success from './NewLimitSteps/3.Success';
import LimitValue from './NewLimitSteps/2.LimitValue';
import LimitOptions from './NewLimitSteps/1.LimitOptions';

const NewLimit = () => {
  const { openModalMultiStep, setOpenModalMultiStep } =
    useActionsOnModalMultiStep();

  const [currentStep, setCurrentStep] =
    useState<SetCurrentStepProps>('limitOptions');
  const [stepParams, setStepParams] = useState({});

  const setStepsAndParams = (step: SetCurrentStepProps, paramsFn?: object) => {
    if (paramsFn) {
      setStepParams((prev) => ({ ...prev, ...paramsFn }));
    }
    setCurrentStep(step);
  };

  const modalStepsAndDetails = {
    limitOptions: {
      component: (
        <LimitOptions
          key="limitOptions"
          setCurrentStep={setStepsAndParams}
          stepParams={stepParams}
        />
      ),
      progressValue: 25,
    },
    limitValue: {
      component: (
        <LimitValue
          key="limitValue"
          setCurrentStep={setStepsAndParams}
          stepParams={stepParams}
        />
      ),
      progressValue: 50,
    },
    success: {
      component: <Success key="success" setCurrentStep={setStepsAndParams} />,
      progressValue: 100,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  return (
    <Style.DepositContainer>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={currentStepInfos?.progressValue}
      >
        {currentStepInfos?.component}
      </ModalMultiStep>
    </Style.DepositContainer>
  );
};

export default NewLimit;
