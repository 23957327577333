import { SVGProps } from 'react';

const PixKeySVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={props.fill || 'transparent'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2504 8.55712L16.8079 6.02659M18.2693 4.58057L16.8079 6.02659L18.2693 4.58057ZM11.2472 11.5287C11.6245 11.897 11.9244 12.3356 12.1297 12.8191C12.335 13.3026 12.4416 13.8216 12.4434 14.346C12.4451 14.8705 12.342 15.3901 12.14 15.8749C11.938 16.3598 11.6411 16.8003 11.2663 17.1712C10.8915 17.542 10.4462 17.8358 9.95622 18.0357C9.46619 18.2356 8.94104 18.3376 8.41101 18.3359C7.88098 18.3341 7.35653 18.2286 6.86787 18.0255C6.3792 17.8224 5.93597 17.5256 5.5637 17.1523C4.83163 16.4023 4.42654 15.3978 4.4357 14.3552C4.44486 13.3125 4.86752 12.3151 5.61266 11.5779C6.35779 10.8406 7.36579 10.4224 8.41953 10.4133C9.47328 10.4042 10.4885 10.8051 11.2464 11.5294L11.2472 11.5287ZM11.2472 11.5287L14.2504 8.55712L11.2472 11.5287ZM14.2504 8.55712L16.4425 10.7262L19 8.19562L16.8079 6.02659L14.2504 8.55712Z"
      stroke={props.stroke || '#272937'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PixKeySVG;
