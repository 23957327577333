import {
  BusinessSize,
  BusinessType,
  Status,
} from '~reactQuery/types/business.types';
import { gql, request, useMutation } from '~reactQuery';

import { useQueryClient } from 'react-query';

interface PutBusinessInput {
  participationPercentage: string;
  declaredAnnualBilling: string;
  businessId: number;
  addressLine: string;
  buildingNumber: string;
  businessCnae: string;
  businessEmail: string;
  businessName: string;
  businessSize: string;
  city: string;
  country: string;
  countryCode: string;
  documentNumber: string;
  neighborhood: string;
  phone: string;
  state: string;
  tradingName: string;
  zipCode: string;
  businessType: string;
  complement?: string;
  companyStartDate: string;
  site: string;
}

interface PutBusinessResponse {
  putBusiness: {
    data: {
      id: number;
      documentNumber: string;
      businessName: string;
      tradingName: string;
      declaredAnnualBilling: string;
      participationPercentage: string;
      businessType: BusinessType;
      businessSize: BusinessSize;
      businessCnae: string;
      countryCode: string;
      phone: string;
      site: string;
      businessEmail: string;
      zipCode: string;
      addressLine: string;
      buildingNumber: string;
      complement: string;
      neighborhood: string;
      city: string;
      state: string;
      country: string;
      numberOfPartners: number;
      companyStartDate: string;
      createdAt: string;
      updatedAt: string;
      deleted: boolean;
      deletedAt: string;
      status: Status;
      isCryptoTrader: boolean;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function usePutBusinessMutation() {
  const queryClient = useQueryClient();

  const mutation = gql`
    mutation PutBusiness($params: putBusinessInput!) {
      putBusiness(params: $params) {
        data {
          id
          declaredAnnualBilling
          participationPercentage
          documentNumber
          businessName
          tradingName
          businessType
          businessSize
          businessCnae
          countryCode
          phone
          site
          businessEmail
          zipCode
          addressLine
          buildingNumber
          complement
          neighborhood
          city
          state
          country
          numberOfPartners
          createdAt
          updatedAt
          deleted
          deletedAt
          status
          companyStartDate
          isCryptoTrader
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: PutBusinessInput) => {
    const response = await request<PutBusinessResponse>(mutation, {
      params,
    });
    return response.putBusiness;
  };

  return useMutation('PutBusiness', fetch, {
    onSettled: () => {
      queryClient.invalidateQueries(['getProcess']);
    },
  });
}
