import * as React from 'react';

import { SVGProps } from 'react';

const ArrowPointLeftSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.188 6.873a.75.75 0 1 0-1.06-1.06l-4.95 4.95a1.75 1.75 0 0 0 0 2.474l4.948 4.95a.75.75 0 1 0 1.061-1.06L5.811 12.75H20a.75.75 0 0 0 0-1.5H5.81l4.377-4.377Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default ArrowPointLeftSVG;
