import colors from '~styles/colors';
import { screen } from '~styles/metrics';
import styled from 'styled-components';

export const Container = styled.div`
  .main-icon {
    svg {
      border-radius: 15px;
    }
  }

  .margin-progress-for-scroll {
    margin-bottom: 80px;
  }

  .input-group {
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(1, 1fr);
    gap: 30px;

    @media ${screen.tablet} {
      display: flex;
      flex-direction: column;
    }

    .container-validations {
      display: grid;

      grid-template-columns: 180px 180px 160px;
      gap: 10px;
      margin-bottom: 15px;

      @media (max-width: 780px) {
        grid-template-columns: 180px 180px 160px 100px;
      }

      @media (max-width: 700px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 576px) {
        grid-template-columns: 1fr;
      }
    }
  }

  .instructions-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    margin: 30px 0;

    svg {
      width: 260px;
      height: 166px;
    }

    .instructions {
      margin-left: 30px;

      .instructions-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-bottom: 25px;

        .number-item {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 28px;
          height: 28px;
          background-color: ${colors.black};
          padding: 4px 11px;
          border-radius: 50px;
          color: ${colors.white};
          margin-right: 10px;
        }
      }
    }
  }
`;
