import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

import blackLogo from '~assets/images/logo-black.png';
import colors from '~styles/colors';
import dayjs from 'dayjs';
import { formatCurrencyFn } from '~helpers/format/currency';

interface InvoiceProps {
  authCode?: string;
  accountDetails: any;
  invoiceDetails?: any;
  status?: string;
}

const styles = StyleSheet.create({
  body: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    fontWeight: 400,
  },
  title: {
    fontSize: 26,
    textAlign: 'left',
    fontWeight: 700,
    lineHeight: '120%',
  },
  logo: {
    width: 125,
    height: 33,
  },
  container: {
    width: '100%',
    marginTop: 30,
    padding: '25px 25px 25px 25px',
  },
  date: {
    marginTop: 15,
    marginBottom: 40,
    fontWeight: 400,
    fontSize: 16,
    color: colors.grayMedium,
  },
  valueContainer: {
    flexDirection: 'column',
  },
  divider: {
    width: 50,
    border: '1px solid #C3C6DB',
    marginBottom: 30,
    marginTop: 30,
  },
  footer: {
    backgroundColor: colors.mainGray,
    padding: 20,
    borderRadius: 15,
    bottom: 15,
    position: 'absolute',
    width: '90%',
  },
  label: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '100%',
    color: colors.grayRegular,
    marginBottom: 10,
  },
  link: {
    color: colors.primary,
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '150%',
    marginTop: 12,
    marginBottom: 5,
    textAlign: 'center',
  },
  containerImg: {
    height: 33,
    top: 30,
    left: 25,
    width: '100%',
    zIndex: 99,
  },
  transferValue: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '100%',
    marginBottom: 7,
  },
  textNames: {
    fontWeight: 700,
    fontSize: 18,
    marginBottom: 7,
  },
  bankNameText: {
    fontWeight: 400,
    fontSize: 15,
    marginBottom: 20,
  },
  displayRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  marginLeft: {
    marginLeft: 20,
  },
  marginBottom: {
    marginBottom: 25,
  },
  colorGrayMedium: {
    color: colors.grayMedium,
  },
});

export const InvoiceReceipt = ({
  invoiceDetails,
  accountDetails,
  authCode,
  status,
}: InvoiceProps) => {
  const dataToDisplay = {
    value: invoiceDetails?.data?.value,
    senderBranch: accountDetails?.branch,
    senderAcc: accountDetails?.account,
    senderName: accountDetails?.name || '',
    timeStamp: invoiceDetails?.timestamp,
    description: invoiceDetails?.data?.description,
  };

  return (
    <Document>
      <Page wrap style={styles.body} size={{ width: 375, height: 850 }}>
        <View fixed style={styles.containerImg}>
          <Image src={blackLogo} style={styles.logo} />
        </View>
        <View style={styles.container}>
          <View>
            <Text style={styles.title}>
              Comprovante de Pagamento de Invoice
            </Text>
          </View>
          <Text style={styles.date}>
            {dayjs(dataToDisplay?.timeStamp).format('DD/MM/YYYY h:mm A')}
          </Text>

          <View style={styles.valueContainer}>
            <Text style={styles.label}>Valor</Text>
            <Text style={styles.transferValue}>
              {formatCurrencyFn(
                'BRL',
                dataToDisplay?.value,
                false,
                false,
                true
              )}
            </Text>
          </View>

          <View>
            <Text style={styles.label}>Status</Text>
            {status ? <Text>{status}</Text> : null}
          </View>
          <View>
            <Text style={{ ...styles.label, ...{ marginTop: 10 } }}>
              Descrição
            </Text>
            {dataToDisplay.description ? (
              <Text>{dataToDisplay.description}</Text>
            ) : null}
          </View>

          <View style={styles.divider} />
          <View>
            <Text style={styles.label}>Enviado por</Text>
            <Text style={styles.textNames}>{dataToDisplay?.senderName}</Text>
          </View>
          <View>
            <Text style={styles.label}>Instituição</Text>
            <Text style={styles.bankNameText}>
              Acesso Soluções de Pagamento S. A.
            </Text>
          </View>
          <View style={styles.displayRow}>
            <View>
              <Text style={styles.label}>Agência</Text>
              <Text>{dataToDisplay?.senderBranch}</Text>
            </View>
            <View style={styles.marginLeft}>
              <Text style={styles.label}>Conta</Text>
              <Text>{dataToDisplay?.senderAcc}</Text>
            </View>
          </View>
        </View>

        <View fixed style={styles.footer}>
          <View style={styles.marginBottom}>
            <Text style={styles.label}>Protocolo</Text>
            <Text>{authCode}</Text>
          </View>

          <Text style={styles.link}>help.capitual.com</Text>
        </View>
      </Page>
    </Document>
  );
};
