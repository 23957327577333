import React from 'react';
import { GenericButton, LoadingSpinner } from '~components';
import * as Style from './LimitsCard.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import { Else, If, Then, When } from 'react-if';
import WarningIcon from '~assets/images/icons/24x24/warning.svg';
import Tooltip from '~components/Tooltip/Tooltip.component';
import { LimitCardProps } from './LimitsCard.types';

const LimitsCard: React.FC<LimitCardProps> = (props) => {
  const {
    isLoading,
    title,
    TransactionalValue,
    MaxTransactionalValue,
    DailyValue,
    MaxDailyValue,
    MonthlyValue,
    MaxMonthlyValue,
    ProgressBarDaily,
    ProgressBarMonthly,
    BillValue,
    hasNightly,
    linkButton,
  } = props;

  const whenNotBillDeposit = title !== 'Boletos de depósito';
  const whenIsBillDeposit = title === 'Boletos de depósito';

  return (
    <Style.Card>
      <div className="header-card">
        <Text.Heading3Bold>{title}</Text.Heading3Bold>

        <When condition={whenNotBillDeposit}>
          <GenericButton
            buttonType="secondary"
            onClick={() => (linkButton ? linkButton() : {})}
            borderRadius={200}
          >
            <Text.Heading3Bold fontSize={0.938} color={colors.white}>
              Ajustar limite
            </Text.Heading3Bold>
          </GenericButton>
        </When>
      </div>

      <If condition={isLoading}>
        <Then>
          <LoadingSpinner color={colors.primary} size={30} strokeSize={12.5} />
        </Then>

        <Else>
          <If condition={whenIsBillDeposit}>
            <Then>
              <div className="column-card">
                <div className="row-card">
                  <Text.Paragraph>
                    Emissão de boletos de depósito
                  </Text.Paragraph>

                  <div className="row-right">
                    <Text.Paragraph marginLeft={8} color={colors.grayMedium}>
                      {BillValue}
                    </Text.Paragraph>
                  </div>
                </div>

                <Style.ProgressContainer>
                  <div
                    className="bar"
                    style={{
                      width: `${ProgressBarMonthly || 0}%`,
                      backgroundColor: colors.naturalBlack,
                    }}
                  />
                </Style.ProgressContainer>
              </div>
            </Then>

            <Else>
              <div className="column-card">
                <div className="row-card">
                  <Text.Paragraph>Transacional</Text.Paragraph>

                  <div className="row-right">
                    <Text.Heading4Bold className="value">
                      {TransactionalValue}
                    </Text.Heading4Bold>

                    <Text.Paragraph marginLeft={8} color={colors.grayMedium}>
                      {MaxTransactionalValue}
                    </Text.Paragraph>
                  </div>
                </div>
              </div>

              <div className="column-card">
                <div className="row-card">
                  <Text.Paragraph>Diário</Text.Paragraph>

                  <div className="row-right">
                    <Text.Heading4Bold className="value">
                      {DailyValue}
                    </Text.Heading4Bold>

                    <Text.Paragraph marginLeft={8} color={colors.grayMedium}>
                      {MaxDailyValue}
                    </Text.Paragraph>
                  </div>
                </div>

                <Style.ProgressContainer>
                  <div
                    className="bar"
                    style={{
                      width: `${ProgressBarDaily || 0}%`,
                      backgroundColor: colors.naturalBlack,
                    }}
                  />
                </Style.ProgressContainer>
              </div>

              <div className="column-card">
                <div className="row-card">
                  <Text.Paragraph>Mensal</Text.Paragraph>

                  <div className="row-right">
                    <Text.Heading4Bold className="value">
                      {MonthlyValue}
                    </Text.Heading4Bold>

                    <Text.Paragraph marginLeft={8} color={colors.grayMedium}>
                      {MaxMonthlyValue}
                    </Text.Paragraph>
                  </div>
                </div>

                <Style.ProgressContainer>
                  <div
                    className="bar"
                    style={{
                      width: `${ProgressBarMonthly || 0}%`,
                      backgroundColor: colors.naturalBlack,
                    }}
                  />
                </Style.ProgressContainer>
              </div>
            </Else>
          </If>

          <When condition={hasNightly}>
            <div className="column-card">
              <div className="row-card">
                <div className="row-left">
                  <Text.Paragraph>Noturno</Text.Paragraph>

                  <Tooltip
                    variant="default"
                    position="right"
                    content="Pode ser que este limite não seja exibido corretamente em alguns casos."
                  >
                    <WarningIcon />
                  </Tooltip>
                </div>

                <div className="row-right">
                  <Text.Heading4Bold className="value">
                    R$ 1.000,00
                  </Text.Heading4Bold>

                  <Text.Paragraph marginLeft={8} color={colors.grayMedium}>
                    por noite
                  </Text.Paragraph>
                </div>
              </div>
            </div>
          </When>
        </Else>
      </If>
    </Style.Card>
  );
};

export default LimitsCard;
