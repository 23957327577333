import { gql, request, useMutation } from '~reactQuery/index';
import { queryClient } from '~src/query';

interface ParamsEmitSlip {
  userOrganizationId: number;
  documentNumber: string;
  amount: number;
  dueDate: string;
  type: string;
}

interface SlipData {
  emitSlip: {
    data: {
      account: string | null;
      authenticationCode: string;
      digitableValue: string;
    };
    isValid: boolean;
    messages: string[];
  };
}

export const useEmitSlip = () => {
  const mutation = gql`
    mutation EmitSlip($params: EmitSlipInput!) {
      emitSlip(params: $params) {
        data {
          account {
            branch
            number
          }
          authenticationCode
          digitableValue
        }
        isValid
        messages
      }
    }
  `;

  const fetch = async (params: ParamsEmitSlip) => {
    if (!params.userOrganizationId) return null;
    const response = await request<SlipData>(mutation, {
      params,
    });

    return response;
  };

  return useMutation('useEmitSlip', fetch, {
    onSuccess: () => {
      queryClient.invalidateQueries(['useGetAllSlips']);
    },
  });
};
