import * as Style from './ModalMultiStep.styles';
import * as Text from '~styles/text';

import GoBackModal from '~assets/images/icons/go-back-modal.svg';
import { ModalMultiStepHeaderProps } from './ModalMultiStep.types';
import React from 'react';
import colors from '~styles/colors';

function ModalMultiStepHeader(props: ModalMultiStepHeaderProps) {
  const {
    backButtonAction,
    aboveTitleText,
    title,
    description,
    children,
    style,
  } = props;
  return (
    <Style.HeaderContainer style={style}>
      {backButtonAction ? (
        <div>
          <GoBackModal onClick={backButtonAction} />
        </div>
      ) : null}

      {!children ? (
        <>
          <Text.Heading5 marginTop={10} color={colors.grayMedium}>
            {aboveTitleText}
          </Text.Heading5>
          <Text.Heading1Medium marginTop={7} marginBottom={13}>
            {title}
          </Text.Heading1Medium>
          <Text.Heading5>{description}</Text.Heading5>
        </>
      ) : (
        children
      )}
    </Style.HeaderContainer>
  );
}

export default ModalMultiStepHeader;
