import CurrencyInput from 'react-currency-input-field';
import colors from '~styles/colors';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .cripto-withdraw-container-tab {
    width: 100%;
    padding: 20px 0 0 0;
  }

  .cripto-withdraw-container-step {
    width: 100%;
    padding: 0 38px;

    .container-loading {
      width: 100%;
      padding-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .wallet-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .currency-input-container {
      height: fit-content;
      position: relative;

      .convertedValue {
        position: absolute;
        bottom: 8px;
        left: 16px;
        color: #757575;
      }
    }
  }

  .status-container {
    padding: 0 38px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .receipt-button {
      margin-top: 25px;
      display: flex;
      align-items: center;
    }
  }

  .center-loading {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .content-container {
    padding: 0 38px;

    .wallet-icon {
      transform: scale(0.8);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .empty-contacts {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 310px;
      padding: 49px 0;
      background-color: ${colors.white};
      border-radius: 10px;
    }
  }

  .bank-list-content {
    width: 100%;
    margin-top: 25px;
  }

  .double-button-one-container {
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
  }

  .display-contacts {
    min-height: 250px;
    overflow-y: scroll;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .paginator {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .flip-arrow {
      transform: rotate(180deg);
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .arrow {
      width: 20px;
      height: 20px;
      margin-top: 3px;
      cursor: pointer;
    }

    .page-index {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${colors.grayLight};
      border-radius: 5px;
      width: 20px;
      height: 20px;
      margin-left: 3px;
      margin-right: 3px;
      margin-top: 2px;
    }
  }

  .container-form-payment-batch {
    input {
      border-radius: 0px !important;
      outline: none !important;
      border-bottom: ${colors.grayRegular} solid 1px;
      padding-left: 0px;
      background-color: transparent !important;
      font-feature-settings: 'ss02' on, 'zero' on;
    }
  }

  .content-transfer-batch {
    display: flex;
    flex-direction: column;
    padding: 10px 32px 0px 32px;

    .row-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .circle {
        width: 40px;
        height: 40px;
        background: ${colors.mainGray};
        border-radius: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .left,
      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    .border {
      border-bottom: 1px solid #eeeff6;
    }

    .row-confirm-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 18px 0px;

      .circle-confirm-list {
        width: 40px;
        height: 40px;
        background: ${colors.mainGray};
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .left-confirm-list {
        display: flex;
        flex-direction: row;
        align-items: center;

        .description {
          display: flex;
          flex-direction: column;
          margin-left: 8px;

          .item-with-tooltip {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
          }
        }
      }

      .right-confirm-list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }

    .container-divider {
      display: flex;
      flex-direction: row;
      align-items: center;

      .container-arrow-down {
        width: 44px;
        height: 44px;
        border-radius: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1.5px solid #eeeff6;

        svg {
          transform-origin: center;
          transform: rotate(270deg);
          -webkit-transform: rotate(270deg);
        }
      }
      .divider {
        flex: 1;
        min-height: 1px;
        background-color: #eeeff6;
      }
    }
  }

  .button-row-transfer-batch {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  .content-add-beneficiary {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 0px 30px;

    input {
      border-radius: 0px !important;
      outline: none !important;
      border-bottom: ${colors.grayRegular} solid 1px;
      padding-left: 0px;
      background-color: transparent !important;
      font-feature-settings: 'ss02' on, 'zero' on;
    }

    .modal-step-bottom-add-beneficiary {
      background-color: ${colors.white};
      height: 78px;
      align-items: center;
    }

    .row-type-key {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 18px 0px;
      justify-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.04);

      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }

      .right {
      }
    }
  }
`;

export const TabMenu = styled.div`
  display: flex;
  flex-direction: row;

  padding: 0 35px;
  border-bottom: 1px solid ${transparentize(0.9, colors.black)};

  & p {
    padding: 15px 13px;
    cursor: pointer;

    &.active {
      border-bottom: 2px solid ${colors.black};
    }

    &.disabled {
      opacity: 0.4;
    }
  }
`;

export const BankList = styled.div`
  width: 100%;
  padding: 0 38px;

  .align-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 25px;
  }

  .bank-list-wrapper {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const PinContentWrapper = styled.div`
  width: 100%;
  padding: 0 38px;
  display: flex;
  flex-direction: column;

  .container-loading {
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StatusContentWrapper = styled.div`
  width: 100%;
  padding: 0 38px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .receipt-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 25px;
  }
`;

export const CurrencyBigInputStyled = styled(CurrencyInput)<{
  lessPadding?: boolean;
}>`
  height: 80px;
  width: 100%;
  border-radius: 10px;
  font-size: 27px;
  border: 1px solid #757893;
  background-color: #fff;
  padding: ${({ lessPadding }) =>
    lessPadding ? '1px 16px 16px 16px' : '16px'};
`;

export const CircleCheckbox = styled.div<{
  background?: string;
  type?: string;
}>`
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: 1.5px solid ${colors.black};
  background-color: ${(props) => {
    return colors.transparent;
  }};
`;
