import React from 'react';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';

const PartnerExchangeTabContent: React.FC = () => {
  return (
    <ModalMultiStepBottom
      singleButtonText="Próximo"
      singleButtonWidth="100%"
      singleButtonAlignment="flex-end"
      singleButtonAction={() => {}}
    />
  );
};

export default PartnerExchangeTabContent;
