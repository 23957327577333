import { gql, request, useQuery } from '~reactQuery';

import { UserPartnerResp } from '~reactQuery/types/business.types';
import { useUserAuthTypes } from '~hooks/useUserAuthTypes';

interface GetPartnerResponse {
  getPartner: {
    data: UserPartnerResp;
    messages: [string];
    isValid: boolean;
  };
}

export function useGetPartner(disableQuery?: boolean) {
  const { setAuthTypes } = useUserAuthTypes();

  const mutation = gql`
    query GetPartner($params: GetPartnerInput) {
      getPartner(params: $params) {
        data {
          id
          registerName
          socialName
          capu
          documentNumber
          email
          phone
          active
          settings {
            id
            enablePin
            twoFA
            language
            notificationFlags
            internalNotificationFlags
            createdAt
            updatedAt
          }
          userOrganizations {
            id
            flags
            admin
            active
            createdAt
            updatedAt
            organizationsId
            organizations {
              id
              name
              country
              businessCode
              setup
              bankCompe
              branch
              account
              image
              deletedAt
              createdAt
              updatedAt
              walletsInternalCredentialsId
              banklyPartnersId
              walletsInternalCredentials {
                id
                internalPin
                sessionKey
                userBusinessId
              }
              partner {
                id
                companyKey
                grantType
              }
              settings {
                id
                enablePin
                twoFA
                language
                notificationFlags
                createdAt
                updatedAt
              }
            }
            settings {
              id
              enablePin
              twoFA
              language
              notificationFlags
              createdAt
              updatedAt
            }
            permissions
          }
          userOrOrgCustomEvents {
            id
            customEventsId
            deletedAt
            createdAt
            status
          }
        }
        isValid
        messages
      }
    }
  `;

  const fetch = async () => {
    if (disableQuery) return null;
    const response = await request<GetPartnerResponse>(mutation, {
      params: {
        documentNumber: localStorage.getItem('@CapBiz:user-documentNumber'),
        userId: Number(localStorage.getItem('@CapBiz:user-id')),
      },
    });

    const { getPartner } = response;

    const isTwoFaActive = getPartner?.data?.settings?.twoFA;

    if (isTwoFaActive) {
      setAuthTypes(['PIN', '2-FA']);
    } else {
      setAuthTypes(['PIN']);
    }

    //
    return getPartner;
  };

  return useQuery('useGetPartner', fetch);
}
