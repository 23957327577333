import { boolean } from 'yup';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { mask } from 'remask';

export const cellphoneMask = (value: string) => {
  if (!value) return '';

  const v = String(value);

  const maskNow =
    v.replace(/\D/g, '').length > 10 ? '(99) 9 9999-9999' : '(99) 9999-99999';

  return mask(value, maskNow);
};

export const rgMask = (value: string) => {
  if (!value) return '';
  const v = String(value);
  return mask(v, '9.999.999');
};

export const cnpjMask = (value: string) => {
  if (!value) return '';
  const v = String(value);
  return mask(v, '99.999.999/9999-99');
};

export const dateMask = (value: string) => {
  if (!value) return '';
  const v = String(value);
  return mask(v, '99/99/9999');
};

export const cnaeMask = (value: string) => {
  if (!value) return '';
  const v = String(value);
  return mask(v, '9999-9/99');
};

export const cepMask = (value: string) => {
  if (!value) return '';
  const v = String(value);
  return mask(v, '99.999-999');
};

export const cpfMask = (value: string) => {
  if (!value) return '';
  const v = String(value);
  return mask(v, '999.999.999-99');
};

export const formatPhone = (phone: string) => {
  if (!phone) return '';
  const p = String(phone).includes('+') ? String(phone) : `+55 ${phone}`;

  return (
    p
      // the regex below replace "( )" and/or "-" and/or empty spaces by ''
      .replace(/\(|\)| |-/gm, '')
      // this regex maps the given string and separate portions of that string
      // Exemple: (\d{2}) means the first 2 characters (which can be any digit characters (numbers))
      // should be replaced by the following example, given 1234567890 ====> 12 34567890, and so on,
      // following the pattern '$1 ($2) $3 $4',
      .replace(/(\d{2})(\d{2})(\d{5})(\d{4,})/g, '$1 ($2) $3 $4')
  );
};

export const formatCodeBar = (codebar: string) => {
  if (!codebar) return '';

  const codeBarPattern =
    '99999.99999 99999.999999 99999.999999 9 99999999999999';
  return mask(codebar, codeBarPattern);
};
