import * as React from 'react';
import { SVGProps } from 'react';

const SuccessBlueSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={130}
    height={131}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      opacity={0.1}
      y={0.5}
      width={130}
      height={130}
      rx={65}
      fill="#0F90FF"
    />
    <rect x={20} y={20.5} width={90} height={90} rx={45} fill="#0F90FF" />
    <path
      d="M47 66.625 58.538 77.5 84 53.5"
      stroke="#fff"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SuccessBlueSVG;
