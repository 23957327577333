import styled from 'styled-components';

export const Container = styled.div`
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    padding: 0 25px 25px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .title {
      margin-left: 15px;
    }
  }

  .details {
    padding: 0 25px 25px 25px;
    margin-bottom: 25px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .bank-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      .right-content {
        display: flex;
        align-items: center;
      }
    }
  }

  .action-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .last-transactions {
    width: 100%;
    padding: 0 25px;
    margin-top: 25px;
  }
`;
