import * as Style from './ModalMultiStep.styles';

import { GenericButton } from '~components/Buttons';
import { ModalMultiStepBottomProps } from './ModalMultiStep.types';
import { When } from 'react-if';

function ModalMultiStepBottom(props: ModalMultiStepBottomProps) {
  const {
    singleButtonText,
    doubleButtonTextOne,
    singleButtonAction,
    doubleButtonTextTwo,
    doubleButtonOneAction,
    doubleButtonTwoAction,
    singleButtonWidth,
    singleButtonAlignment,
    disableSingleButton,
    singleButtonLoading,
    disableDoubleButtonOneAction,
    className,
  } = props;

  return (
    <Style.ContainerModalMultiStepBottomPart>
      <Style.BottomContainer className={className}>
        <When condition={!!singleButtonText}>
          <div
            style={{
              justifyContent: singleButtonAlignment || 'flex-end',
              display: 'flex',
              width: singleButtonWidth || '100%',
            }}
          >
            <GenericButton
              disabled={disableSingleButton}
              width={singleButtonWidth || '100%'}
              buttonType="secondary"
              onClick={singleButtonAction || (() => {})}
              buttonLoading={singleButtonLoading}
            >
              {singleButtonText}
            </GenericButton>
          </div>
        </When>

        <When condition={!!doubleButtonTextOne}>
          <Style.TwoButtonsContainer>
            <When condition={!!doubleButtonTextOne}>
              <GenericButton
                onClick={doubleButtonOneAction || (() => {})}
                buttonType="default"
                disabled={disableDoubleButtonOneAction}
              >
                {doubleButtonTextOne}
              </GenericButton>
            </When>
            <When condition={!!doubleButtonTextTwo}>
              <GenericButton
                onClick={doubleButtonTwoAction || (() => {})}
                buttonType="secondary"
              >
                {doubleButtonTextTwo}
              </GenericButton>
            </When>
          </Style.TwoButtonsContainer>
        </When>
      </Style.BottomContainer>
    </Style.ContainerModalMultiStepBottomPart>
  );
}

export default ModalMultiStepBottom;
