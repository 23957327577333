import * as React from 'react';

import { SVGProps } from 'react';

const WorldFavoriteSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2ZM3.928 5.72a4.665 4.665 0 0 1 7.05-1.312 1.36 1.36 0 0 1-.378.415 1.751 1.751 0 0 1-.984.318h-.044v.044c0 .95-.786 1.455-1.182 1.588l-.03.01V8.08h1.616v1.64l.053-.01c.797-.162 1.357.006 1.733.277.122.088.226.187.312.29a4.665 4.665 0 0 1-4.434 2.375v-1.782L6.024 9.24V8.02l-2.096-2.3Z"
      fill="#272937"
    />
  </svg>
);

export default WorldFavoriteSVG;
