import * as Text from '~styles/text';
import * as Style from '../SavedLists.styles';
import colors from '~styles/colors';
import EditListIcon from '~assets/svg/editList';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import FileSVG from '~assets/svg/fileIcon';
import { LoadingSpinner, RoundButtonIcon } from '~components/index';
import ArrowTransferIcon from '~assets/svg/arrowTransfer';
import { RowList } from '../SavedLists.styles';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useGetPaymentList } from '~reactQuery/queries/paymentList/useGetPaymentList';
import { getSelectedUserOrganization } from '~helpers/device';
import { formatCurrencyFn } from '~helpers/format/currency';
import { Else, If, Then } from 'react-if';
import { EmptyTransfer } from '../../emptyTransfer/EmptyTransfer.component';
import { ShowAllSavedListsProps } from '../SavedLists.types';

const ShowAllSavedLists = ({
  setSelectedList,
  setStepSavedLists,
  setStepTransferBatch,
  setModalAttachListIsVisible,
  setTabSelected,
}: ShowAllSavedListsProps) => {
  const { setParams } = useActionsOnModalMultiStep();
  const userOrganizationId = getSelectedUserOrganization();
  const { data: paymentList, isLoading: loadingPaymentList } =
    useGetPaymentList({
      page: 1,
      perPage: 100,
      perUser: true,
      userOrganizationId: Number(userOrganizationId),
    });

  const dontHavePaymentList = paymentList?.getPaymentList?.length === 0;

  return (
    <>
      <Style.ScrollBox>
        <If condition={dontHavePaymentList}>
          <Then>
            <div className="container-dont-have-payment-list">
              <EmptyTransfer
                title="Você não tem nenhuma lista de transferência salva"
                subtitle="Ao criar suas listas, elas serão exibidas aqui"
                icon="document"
                style={{ marginTop: 35 }}
              />
            </div>
          </Then>

          <Else>
            <If condition={loadingPaymentList}>
              <Then>
                <div className="container-loading">
                  <LoadingSpinner
                    color={colors.primary}
                    size={40}
                    strokeSize={17}
                  />
                </div>
              </Then>
              <Else>
                {paymentList?.getPaymentList?.map((payment) => {
                  const totalValue = payment?.data?.listOfBeneficiaries?.reduce(
                    (accumulator: number, item) => {
                      const amount = parseFloat(
                        item?.amount?.replace('R$ ', '').replace(',', '.')
                      );
                      return accumulator + amount;
                    },
                    0
                  );
                  const confirmPaymentDisabled =
                    totalValue === 0 ||
                    payment?.data?.listOfBeneficiaries?.length === 0;

                  const handleOnClick = () => {
                    if (confirmPaymentDisabled) {
                      return;
                    }
                    setStepTransferBatch('confirmPaymentList');
                    setParams({
                      origin: 'savedLists',
                      recipientsList: payment?.data,
                    });
                  };

                  return (
                    <RowList
                      key={payment?.id}
                      onClick={() => setSelectedList(payment)}
                    >
                      <div className="left">
                        <div className="align-row">
                          <Text.Heading5>
                            {payment?.data?.nameList}
                          </Text.Heading5>
                          <div className="container-counting">
                            <Text.Smaller color={colors.grayMedium}>
                              {payment?.data?.listOfBeneficiaries?.length}
                            </Text.Smaller>
                          </div>
                        </div>
                        <Text.Small fontSize={0.8125} color={colors.grayMedium}>
                          {formatCurrencyFn('BRL', String(totalValue))}
                        </Text.Small>
                      </div>

                      <div className="right">
                        <RoundButtonIcon
                          buttonSize="icon24"
                          onClick={() => setStepSavedLists('editList')}
                        >
                          <EditListIcon
                            fill={colors.black}
                            width={18}
                            height={18}
                          />
                        </RoundButtonIcon>
                        <RoundButtonIcon
                          buttonSize="icon24"
                          onClick={() => handleOnClick()}
                          disabled={confirmPaymentDisabled}
                        >
                          <ArrowTransferIcon
                            fill={colors.black}
                            width={18}
                            height={18}
                          />
                        </RoundButtonIcon>
                      </div>
                    </RowList>
                  );
                })}
              </Else>
            </If>
          </Else>
        </If>
      </Style.ScrollBox>

      <ModalMultiStepBottom
        singleButtonText="Resumo da transação"
        singleButtonWidth="236px"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => {}}
        disableSingleButton
        singleButtonLoading={loadingPaymentList}
        disableDoubleButtonOneAction={loadingPaymentList}
      />
    </>
  );
};

export default ShowAllSavedLists;
