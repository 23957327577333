import * as Text from '~styles/text';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import { UserPermissionModalProps } from '../UserPermission.types';
import SuccessBlueSVG from '~assets/svg/successBlue';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

function SuccessUserRegistered(props: UserPermissionModalProps) {
  const {
    setStepUserPermission,
    setErrors,
    setRegisterName,
    setDocumentNumber,
    setPhone,
    setEmail,
    setMessage,
    setUserPermission,
    params,
  } = props;
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  const handleCompleteAndResetFields = () => {
    setStepUserPermission('newUserInformation');
    setErrors({
      registerName: '',
      documentNumber: '',
      phone: '',
      email: '',
    });
    setRegisterName('');
    setDocumentNumber('');
    setPhone('');
    setEmail('');
    setMessage('');
    setUserPermission({ permission: '', scopes: [] });
    setOpenModalMultiStep(false);
  };

  return (
    <>
      <div className="content-success-modal">
        <SuccessBlueSVG />
        <Text.Heading1Bold marginTop={24}>
          {params?.type === 'updateUserPermissions'
            ? 'Permissões Alteradas!'
            : 'Usuário Cadastrado!'}
        </Text.Heading1Bold>
        <Text.Heading5 marginTop={16}>
          {params?.type === 'updateUserPermissions'
            ? 'As informações do usuário foram atualizadas. Você pode'
            : 'Enviamos um e-mail para o novo usuário com todas'}
        </Text.Heading5>
        <Text.Heading5>
          {params?.type === 'updateUserPermissions'
            ? 'fazer alterações sempre que necessário.'
            : 'as informações necessárias para finalizar o cadastro.'}
        </Text.Heading5>
      </div>
      <ModalMultiStepBottom
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonText="Concluir"
        doubleButtonTextOne=" "
        doubleButtonOneAction={() => {}}
        singleButtonAction={() => handleCompleteAndResetFields()}
      />
    </>
  );
}

export default SuccessUserRegistered;
