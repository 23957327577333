import styled from 'styled-components';
import { GeneralStyleProps } from '~src/@types/general';

export const Container = styled.div<GeneralStyleProps>`
  .upload-input {
    display: none;
  }

  .container-upload {
    position: relative;
    display: inline-block;

    .avatar {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      vertical-align: middle;
      object-fit: cover;
      -webkit-filter: brightness(100%);
    }

    .container-loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .upload-icon {
      display: none;
    }

    :hover {
      .upload-icon {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }

      .avatar {
        -webkit-filter: brightness(70%);
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        transition: all 0.2s ease;
        cursor: pointer;
      }
    }
  }
`;