export default {
  transparent: 'transparent',

  primaryLight: '#0F90FF',
  primary: '#026FF4',
  primaryDark: '#0264DC',
  primaryOpacity5p: 'rgba(2, 111, 244, 0.05)',
  primaryOpacity10p: 'rgba(2, 111, 244, 0.1)',
  primaryOpacity20p: 'rgba(2, 111, 244, 0.2)',
  primaryOpacity30p: 'rgba(2, 111, 244, 0.3)',
  primaryOpacity50p: 'rgba(2, 111, 244, 0.5)',

  warningLight: '#F3BA2F',
  warning: '#DBA72A',
  warningDark: '#B08E39',
  warningOpacity5p: 'rgba(243, 186, 47, 0.05)',
  warningOpacity10p: 'rgba(243, 186, 47, 0.1)',
  warningOpacity20p: 'rgba(243, 186, 47, 0.2)',
  warningOpacity30p: 'rgba(243, 186, 47, 0.3)',
  warningOpacity50p: 'rgba(243, 186, 47, 0.5)',

  dangerLight: '#FF6378',
  danger: '#E5596C',
  dangerDark: '#C85B69',
  dangerOpacity5p: 'rgba(255, 99, 120, 0.05)',
  dangerOpacity10p: 'rgba(255, 99, 120, 0.1)',
  dangerOpacity20p: 'rgba(255, 99, 120, 0.2)',
  dangerOpacity30p: 'rgba(255, 99, 120, 0.3)',
  dangerOpacity50p: 'rgba(255, 99, 120, 0.5)',

  successLight: '#00E699',
  success: '#00CF8A',
  successDark: '#3AAC86',
  successOpacity5p: 'rgba(0, 207, 138, 0.05)',
  successOpacity10p: 'rgba(0, 207, 138, 0.1)',
  successOpacity20p: 'rgba(0, 207, 138, 0.2)',
  successOpacity30p: 'rgba(0, 207, 138, 0.3)',
  successOpacity50p: 'rgba(0, 207, 138, 0.5)',

  whiteLight: '#FFFFFF',
  white: '#F8F9FB',
  whiteDark: '#F3F4F9',
  whiteOpacity5p: 'rgba(248, 249, 251, 0.05)',
  whiteOpacity10p: 'rgba(248, 249, 251, 0.1)',
  whiteOpacity20p: 'rgba(248, 249, 251, 0.2)',
  whiteOpacity30p: 'rgba(248, 249, 251, 0.3)',
  whiteOpacity50p: 'rgba(248, 249, 251, 0.5)',

  grayLight: '#C3C6DB',
  gray: '#9EA1B9',
  grayDark: '#757893',
  grayOpacity5p: 'rgba(158, 161, 185, 0.05)',
  grayOpacity10p: 'rgba(158, 161, 185, 0.1)',
  grayOpacity20p: 'rgba(158, 161, 185, 0.2)',
  grayOpacity30p: 'rgba(158, 161, 185, 0.3)',
  grayOpacity50p: 'rgba(158, 161, 185, 0.5)',

  blackLight: '#272937',
  black: '#272937',
  blackDark: '#000000',
  blackOpacity5p: 'rgba(39, 41, 55, 0.05)',
  blackOpacity10p: 'rgba(39, 41, 55, 0.1)',
  blackOpacity20p: 'rgba(39, 41, 55, 0.2)',
  blackOpacity30p: 'rgba(39, 41, 55, 0.3)',
  blackOpacity50p: 'rgba(39, 41, 55, 0.5)',

  randomColors: [
    '#FBC0A0',
    '#9DE6F7',
    '#627BB4',
    '#55BCF2',
    '#5D46B0',
    '#6377FC',
    '#3983FE',
    '#FE829C',
    '#E6BDE7',
    '#41B2FF',
    '#FE7275',
    '#9AA9FE',
  ],
};
