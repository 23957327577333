import { SVGProps } from 'react';

const MenuSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 38}
    height={props.height || 39}
    viewBox="0 0 38 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.5 15.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM19.5 19.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM19.5 23.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
      stroke={props.stroke || '#272937'}
    />
  </svg>
);

export default MenuSVG;
