import * as Text from '~styles/text';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import EditListIcon from '~assets/svg/editList';
import BankIcon from '~assets/svg/bank';
import colors from '~styles/colors';
import ArrowPointLeftSVG from '~assets/svg/arrowPointLeft';
import { UserPic } from '~components/index';
import Tooltip from '~components/Tooltip/Tooltip.component';
import InfoSVG from '~assets/svg/info';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { formatCurrencyFn } from '~helpers/format/currency';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetCapAccountInfo } from '~reactQuery/queries/capAccount/useGetCapAccountInfo';
import { capitalizeInitialsAndRemoveNumbers } from '~helpers/format/capitalize';
import {
  ConfirmPaymentListProps,
  RecipientListProps,
} from '../TransferBatch.types';
import {
  formatDocumentComingFromAcesso,
  suprimeCpfOrCnpj,
} from '~helpers/format/numbers';
import {
  validateCPF,
  validatePhoneNumber,
} from '~validations/personalValidation';

const ConfirmPaymentList = (props: ConfirmPaymentListProps) => {
  const { params, setParams } = useActionsOnModalMultiStep();
  const userOrganizationId = getSelectedUserOrganization();

  const { data: capAccountInfo, isLoading: loadingCapAccountInfo } =
    useGetCapAccountInfo({
      userOrganizationId: Number(userOrganizationId),
    });

  const { setStepTransferBatch, setCloseModalVisible } = props;

  const recipientsList = params?.recipientsList as RecipientListProps;

  const renderBalanceTotalValue = () => {
    if (loadingCapAccountInfo) return '0.00';

    if (!capAccountInfo?.available) return '0.00';

    if (capAccountInfo?.available) {
      return formatCurrencyFn(
        'BRL',
        Number?.parseFloat(
          String(Number(capAccountInfo?.available) / 100)
        ).toFixed(2)
      );
    }

    return '0.00';
  };

  const handleGoBack = () => {
    setCloseModalVisible({ comingFrom: 'confirmPaymentList', visible: true });
  };

  const totalValue = recipientsList?.listOfBeneficiaries?.reduce(
    (accumulator: number, item) => {
      const amount = parseFloat(
        item?.amount?.replace('R$ ', '').replace(',', '.')
      );
      return accumulator + amount;
    },
    0
  );

  const handleTranslatorKeyType = (value: string) => {
    const translatorKeyType: { [key: string]: string } = {
      PHONE: 'Pix',
      CPF: 'Pix',
      CNPJ: 'Pix',
      EMAIL: 'Pix',
    };

    return translatorKeyType[value] ? translatorKeyType[value] : value;
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Você está transferindo para os beneficiários"
        backButtonAction={() => handleGoBack()}
      />

      <div className="content-transfer-batch">
        <div className="row-confirm-list">
          <div className="left-confirm-list">
            <div className="circle-confirm-list">
              <BankIcon />
            </div>

            <div className="description">
              <Text.Heading5 marginBottom={5}>Conta BRL</Text.Heading5>
              <Text.Heading5 color={colors.grayMedium} marginRight={10}>
                Saldo: {renderBalanceTotalValue()}
              </Text.Heading5>
            </div>
          </div>

          <div className="right-confirm-list">
            <Text.Heading5 color={colors.grayMedium} marginBottom={5}>
              Você está transferindo:
            </Text.Heading5>
            <Text.Heading2Bold
              style={{ fontFeatureSettings: '"ss02", "zero"' }}
            >
              {formatCurrencyFn('BRL', String(totalValue))}
            </Text.Heading2Bold>
          </div>
        </div>

        <div className="container-divider">
          <div className="divider" />
          <div className="container-arrow-down">
            <ArrowPointLeftSVG fill={colors.newBlue} />
          </div>
          <div className="divider" />
        </div>

        {recipientsList?.listOfBeneficiaries?.map((item) => {
          const isDocumentNumberOrPhone =
            validatePhoneNumber(item?.keyValue || '') ||
            validateCPF(item?.keyValue || '');
          const keyValue = isDocumentNumberOrPhone
            ? suprimeCpfOrCnpj(String(item?.keyValue || ''))
            : item?.keyValue;
          return (
            <div
              className="row-confirm-list border"
              key={`${item?.fullName}-${item?.keyType}`}
            >
              <div className="left-confirm-list">
                <div className="circle-confirm-list">
                  <UserPic
                    name={item?.fullName || item?.holderName || ''}
                    borderRadius={20}
                  />
                </div>

                <div className="description">
                  <Text.Heading4Bold marginBottom={5}>
                    {capitalizeInitialsAndRemoveNumbers(
                      item?.fullName || item?.holderName || ''
                    )}
                  </Text.Heading4Bold>
                  <div className="item-with-tooltip">
                    <Text.Heading5 color={colors.grayMedium} marginRight={10}>
                      {formatDocumentComingFromAcesso(
                        item?.holderDocument || ''
                      ) ||
                        suprimeCpfOrCnpj(String(item?.documentNumber || '')) ||
                        formatDocumentComingFromAcesso(
                          item?.key?.holderDocument || ''
                        ) ||
                        keyValue}
                    </Text.Heading5>

                    <Tooltip
                      position="right"
                      content="Por enquanto somente a Conta BRL tem a opção de pagamento em lote."
                      variant="default"
                    >
                      <InfoSVG />
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div className="right-confirm-list">
                <Text.Heading4Bold marginBottom={5}>
                  {item?.amount}
                </Text.Heading4Bold>
                <Text.Heading5 color={colors.grayMedium}>
                  {handleTranslatorKeyType(item?.transferType) ||
                    handleTranslatorKeyType(item?.keyType)}
                </Text.Heading5>
              </div>
            </div>
          );
        })}
      </div>

      <ModalMultiStepBottom
        singleButtonText="Confirmar transação"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => {
          setParams({ ...params, balance: renderBalanceTotalValue() });
          setStepTransferBatch('confirmPin');
        }}
        doubleButtonOneAction={() => handleGoBack()}
        doubleButtonTextOne={
          <div className="button-row-transfer-batch">
            <EditListIcon />
            <Text.Heading5>Editar Lista</Text.Heading5>
          </div>
        }
      />
    </>
  );
};

export default ConfirmPaymentList;
