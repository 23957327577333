import { colors, metrics } from '~styles';
import styled, { css } from 'styled-components';

import { transparentize } from 'polished';

export const Container = styled.div<{
  borderColor?: string;
  disabled?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
}>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ disabled, borderColor }) =>
    !disabled
      ? css`
          :hover {
            background-color: ${() =>
              transparentize(0.95, borderColor || colors.black)};
            border-radius: 50%;
          }
        `
      : css`
          opacity: 0.5;
        `}

  .check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: ${({ checked, indeterminate, borderColor }) =>
      checked || indeterminate ? borderColor || colors.black : 'transparent'};
    border: 1.5px solid ${({ borderColor }) => borderColor || colors.black};
    ${({ disabled }) => (!disabled ? 'cursor: pointer' : 'cursor: unset')}
  }
`;
