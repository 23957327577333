import styled from 'styled-components';
import colors from '~styles/colors';

export const Main = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isLoading }) => (isLoading ? '' : '40px 35px 0 35px')};

  .loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }

  .float-button {
    position: fixed;
    width: 48px;
    height: 48px;
    bottom: 80px;
    right: 15px;
    border-radius: 50%;
    box-shadow: 1px 1px 10px #00000075;
    cursor: pointer;
    z-index: 9999;
  }

  .dropdown {
    position: absolute;
    right: 0;
    bottom: 60px;
    width: fit-content;
    word-break: keep-all;
    background-color: ${colors.whiteLight};
    border: 1px solid ${colors.grayLight};
    border-radius: 5px;
    box-shadow: 1px 1px 8px #00000050;
    cursor: pointer;

    .item {
      width: 100%;
      padding: 12px;
      white-space: nowrap;
      text-decoration: none;
      color: #000;
      &:hover {
        background-color: ${`${colors.grayLight}50`};
      }
    }
  }

  @page {
    margin: 30px 0px;
    size: landscape;
  }

  .footer,
  .footer-space {
    height: 50px;
    visibility: hidden;
  }

  .footer {
    width: calc(100% - 35px);
    border-top: 0.5px solid ${colors.grayLight};
    position: fixed;
    bottom: 0;
    left: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media print {
    .footer,
    .footer-space {
      visibility: visible;
    }

    .footer {
      width: calc(100% - 70px);
    }

    .float-button {
      visibility: hidden;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;

  border-bottom: 0.5px solid #000;

  .company-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
  }

  .company-details {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  max-width: 245px;

  svg:last-child {
    margin-top: 5px;
    margin-left: 7.75px;
  }
`;

export const Overview = styled.div`
  width: 100%;
  display: flex;
  margin-top: 60px;
  justify-content: space-between;

  .overview-title {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .overview-stats {
    display: flex;
    flex-direction: column;
    width: 500px;
    gap: 10px;

    .stats-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .separator {
      width: 100%;
      border-bottom: 0.5px solid #000;
    }
  }
`;

export const Table = styled.table`
  margin-top: 70px;
  width: 100%;
  border-collapse: collapse;
  position: relative;

  .empty-transaction {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 160px;
    left: 0;
  }

  thead {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    margin-top: 30px;
  }

  th {
    padding: 15px 5px;
  }
`;
