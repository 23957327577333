import * as Style from './Dialog.styles';

import { useEffect, useState } from 'react';

import CloseButton from '~components/Buttons/CloseButton';
import { ModalProps } from './Dialog.types';
import { When } from 'react-if';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

const Dialog = (props: ModalProps) => {
  const { openModal, setOpenModal, children } = props;
  const [showAnim, setShowAnim] = useState(false);
  const { setSection } = useActionsOnModalMultiStep();

  useEffect(() => {
    if (openModal) {
      setShowAnim(true);
    } else {
      setSection('default');
    }
  }, [openModal]);

  const closeModal = () => {
    setShowAnim(false);
    setTimeout(() => {
      setOpenModal(false);
    }, 300);
  };

  return (
    <When condition={openModal}>
      <Style.ModalBackground fadeIn={showAnim}>
        <Style.ModalContainer height={props.modalHeight}>
          <CloseButton closeIconSize="small" onClick={closeModal} />
          <Style.BodyContainer>{children}</Style.BodyContainer>
        </Style.ModalContainer>
      </Style.ModalBackground>
    </When>
  );
};

export default Dialog;
