import * as Style from './GenericRow.styles';
import * as Text from '~styles/text';

import ArrowOpenNavIcon from '~assets/images/icons/openNavArrow.svg';
import ArrowRightIcon from '~assets/images/icons/select-arrow-right-black.svg';
import { DropDown } from '~components/DropDown';
import { GenericRowProps } from './GenericRow.types';
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import MenuSVG from '~assets/images/icons/menu';
import { ReactNode } from 'react';
import { When } from 'react-if';
import colors from '~styles/colors';

/**
 * GenericRow Component
 * @param marginTop Add margin-top to the component.
 * @param alignItems Set container items alignment ('flex-start' | 'center' | 'flex-end').
 * @param title Add a title.
 * @param titleColor Add a color to the title.
 * @param description Add a Description.
 * @param overText Add an over text.
 * @param noHorizontalPadding Remove padding-left and padding-right from the component.
 * @param rightContentType Set the type ("arrow" | "arrowOpenNav" | "component" | "text" | "menu")
 * of the content that will be placed at the right position
 * (the component is divided by 3 parts: left, center, right).
 * @param rightText If you setted "rightContentType" as "text",
 * you might want to add the text using this prop.
 * @param rightComponent If you setted "rightContentType" as "component",
 * you might want to add the component using this prop.
 * @param leftContentIconType Set the type ("userPic" | "icon")
 * of the content that will be placed at the left position.
 * @param leftComponent If you setted "leftContentIconType" as "userPic",
 * you might want to add the userPic component using this prop. Same to Icon type
 * @param onClick If you setted "rightContentType" as "arrow" or "text",
 * you can click in the whole component and trigger some function using this props.
 * @param padding Add padding as string (e.g 10px 10px 10px 10px).
 * @param secondRightComponent add a second right component
 * @param borderTop boolean to select if the component will have border
 * @param dropDownContent add the dropDown content (only in the rightContentType is "menu")
 */

const GenericRow = (props: GenericRowProps) => {
  const {
    marginTop,
    title,
    description,
    rightContentType,
    rightText,
    rightComponent,
    leftComponent,
    leftContentIconType,
    onClickMenu,
    onClick,
    noHorizontalPadding,
    padding,
    overText,
    secondRightComponent,
    titleColor,
    alignItems,
    noBorderTop,
    dropDownContent,
  } = props;

  const renderRightContent: Record<
    GenericRowProps['rightContentType'],
    ReactNode
  > = {
    text: <Text.Heading5>{rightText}</Text.Heading5>,
    arrow: <ArrowRightIcon />,
    arrowOpenNav: <ArrowOpenNavIcon />,
    component: rightComponent,
    menu: <MenuSVG />,
  };

  const enableOnClick = () => {
    if (rightContentType === 'component' || rightContentType === 'menu') {
      return false;
    }
    return true;
  };

  return (
    <Style.Container
      alignItems={alignItems}
      padding={padding}
      marginTop={marginTop}
      rightContentType={rightContentType}
      leftContentType={leftContentIconType}
      noHorizontalPadding={noHorizontalPadding}
      noBorderTop={noBorderTop}
      enableOnClick={enableOnClick()}
      onClick={enableOnClick() ? onClick : () => {}}
    >
      <div className="left-content">{leftComponent}</div>
      <div className="center-content">
        <When condition={overText && !description}>
          <Text.Paragraph marginBottom={5} color={colors.grayMedium}>
            {overText}
          </Text.Paragraph>
        </When>

        <Text.Heading5 color={titleColor || colors.black}>
          {title}
        </Text.Heading5>

        <When condition={description && !overText}>
          <Text.Small color={colors.grayMedium} marginTop={5}>
            {description}
          </Text.Small>
        </When>
      </div>

      <div className="second-right-content">{secondRightComponent}</div>

      {rightContentType === 'menu' ? (
        <DropDown content={dropDownContent}>
          <div className="right-content" onClick={onClickMenu}>
            {renderRightContent[rightContentType]}
          </div>
        </DropDown>
      ) : (
        <div className="right-content" onClick={onClick}>
          {renderRightContent[rightContentType]}
        </div>
      )}
    </Style.Container>
  );
};

export default GenericRow;
