import * as React from 'react';

import { SVGProps } from 'react';

const StarFavoriteSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.593 2.466a.511.511 0 0 0-.917 0L6.217 5.422a.511.511 0 0 1-.385.28l-3.259.47a.511.511 0 0 0-.284.873l2.36 2.301c.12.118.175.287.146.453l-.558 3.247a.511.511 0 0 0 .742.54l2.917-1.533a.512.512 0 0 1 .476 0l2.917 1.532a.511.511 0 0 0 .742-.539l-.558-3.247a.512.512 0 0 1 .148-.453l2.358-2.297a.511.511 0 0 0-.283-.873l-3.26-.474a.511.511 0 0 1-.384-.28l-1.46-2.956Z"
      fill="#272937"
    />
  </svg>
);

export default StarFavoriteSVG;
