import { SVGProps } from 'react';

const CashOutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '24'}
    height={props.height || '25'}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 22.014V23.014H11V22.014V20.014V19.014H13V20.014V22.014ZM9 21.014V22.014H7V21.014V20.014V19.014H9V20.014V21.014ZM17 21.014V22.014H15V21.014V20.014V19.014H17V20.014V21.014ZM4 16.514C3.44772 16.514 3 16.0663 3 15.514V5.01404C3 4.46175 3.44772 4.01404 4 4.01404H20C20.5523 4.01404 21 4.46175 21 5.01404V15.514C21 16.0663 20.5523 16.514 20 16.514H4ZM5 6.01404V14.514H19V6.01404H5ZM12 8.01404C10.7574 8.01404 9.75 9.0214 9.75 10.264C9.75 11.5067 10.7574 12.514 12 12.514C13.2426 12.514 14.25 11.5067 14.25 10.264C14.25 9.0214 13.2426 8.01404 12 8.01404Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default CashOutIcon;
