import { InputForm, InputSwitch } from '~components/Inputs';
import { useState } from 'react';

import CalendarSVG from '~assets/svg/calendar';
import CapAccountSVG from '~assets/svg/capAccount';
import EditSVG from '~assets/svg/edit';
import GenericRow from '~components/GenericRow';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { PixModalStepsProps } from '../../Transfer.types';
import PlusSVG from '~assets/svg/plus';
import { RoundButtonIcon } from '~components/Buttons';
import { When } from 'react-if';
import { accountsTypes } from '~data/bankUtils';
import { capitalizeName } from '~helpers/format/user';
import { suprimeCpfOrCnpj } from '~helpers/format/numbers';
import { useFormContext } from 'react-hook-form';
import { useGetBrlBanks } from '~reactQuery/queries/bank/useGetBrlBank';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';

function ConfirmDetails(props: PixModalStepsProps) {
  const { setPixStep: setCurrentStep } = props;
  const { setValue, getValues } = useFormContext();
  const formValues = getValues();

  const [saveContact, setSaveContact] = useState(false);
  const [methodOfTransfer, setMethodOfTransfer] = useState(0);
  const [comment, setComment] = useState({
    open: false,
    text: '',
  });
  const { data: bankList } = useGetBrlBanks();

  const isTedOrPixManual = formValues?.manualTransfer;
  const methods = ['PIX Manual', 'TED'];

  const selectTransferType = () => {
    setMethodOfTransfer((prev) => {
      if (prev === 1) return 0;
      return prev + 1;
    });
  };

  const isComingFromASavedContact = Boolean(getValues('contact'));

  const goBack = () => setCurrentStep('valueSended');
  const goNext = () => {
    setValue('description', comment.text);
    setValue('saveContact', saveContact);
    setValue(
      'methodOfTransfer',
      isTedOrPixManual ? methods[methodOfTransfer] : 'PIX'
    );
    setCurrentStep('pin');
  };

  const nameCapitalized = capitalizeName(
    formValues?.keyResult?.resolve?.key?.holderName ||
      formValues?.manualDetails?.name ||
      formValues?.contact?.name
  );

  const getBankName = bankList?.filter(
    (b) =>
      b?.bankCompe === formValues?.manualDetails?.bankCode ||
      b?.bankIspb === formValues?.manualDetails?.bankIspb
  )?.[0]?.bankName;

  const selectDataToDisplay = {
    cpfOrCnpj:
      formValues?.keyResult?.resolve?.key?.holderDocument ||
      formValues?.manualDetails?.docNum ||
      formValues?.contact?.documentNumber,
    bankName: getBankName,
    branch: formValues?.manualDetails?.branch,
    account: formValues?.manualDetails?.accountNumber,
    accountType: accountsTypes.filter((a) =>
      [formValues?.manualDetails?.accountType]?.includes(a?.value)
    )?.[0]?.label,
  };

  return (
    <>
      <ModalMultiStepHeader
        title={`Transferindo ${formValues?.amount} para ${nameCapitalized}`}
        description=""
        backButtonAction={goBack}
      />

      <div className="content-container mt-30">
        <GenericRow
          overText="Origem"
          title="CapConta"
          rightContentType="component"
          rightComponent={
            <RoundButtonIcon buttonType="outlined" buttonSize="iconWallet">
              <CapAccountSVG />
            </RoundButtonIcon>
          }
          noHorizontalPadding
        />

        <GenericRow
          overText="Data do Pagamento"
          title="Hoje"
          rightContentType="component"
          rightComponent={
            <RoundButtonIcon
              buttonType="default"
              onClick={() => {}}
              buttonSize="icon24"
              noHover
            >
              <CalendarSVG />
            </RoundButtonIcon>
          }
          noHorizontalPadding
        />

        <GenericRow
          overText="Tipo de Transferência"
          title={isTedOrPixManual ? methods[methodOfTransfer] : 'Pix'}
          rightContentType="component"
          rightComponent={
            <RoundButtonIcon
              buttonType="default"
              onClick={selectTransferType}
              buttonSize="icon24"
              noHover={!isTedOrPixManual}
            >
              <EditSVG />
            </RoundButtonIcon>
          }
          noHorizontalPadding
        />

        <GenericRow
          title="CPF/CNPJ"
          rightContentType="text"
          rightText={suprimeCpfOrCnpj(
            String(onlyNumbers(selectDataToDisplay?.cpfOrCnpj))
          )}
          noHorizontalPadding
          padding="20px 0px 10px"
        />

        <When condition={isTedOrPixManual}>
          <GenericRow
            title="Instituição"
            rightContentType="text"
            rightText={`${selectDataToDisplay?.bankName?.slice(0, 40)} ${
              selectDataToDisplay?.bankName &&
              selectDataToDisplay?.bankName?.length > 40
                ? '...'
                : ''
            }`}
            noHorizontalPadding
            padding="10px 0px"
            noBorderTop
          />

          <GenericRow
            title="Agência"
            rightContentType="text"
            rightText={selectDataToDisplay?.branch}
            noHorizontalPadding
            padding="10px 0px"
            noBorderTop
          />

          <GenericRow
            title={selectDataToDisplay?.accountType}
            rightContentType="text"
            rightText={selectDataToDisplay?.account}
            noHorizontalPadding
            padding="10px 0px 20px"
            noBorderTop
          />
        </When>

        <GenericRow
          overText="Comentário"
          title={comment.text === '' ? 'Nenhuma nota' : comment.text}
          rightContentType="component"
          rightComponent={
            <RoundButtonIcon
              buttonType="default"
              onClick={() =>
                setComment((prev) => ({ ...prev, open: !prev.open }))
              }
              buttonSize="icon24"
            >
              <PlusSVG />
            </RoundButtonIcon>
          }
          noHorizontalPadding
        />
        <When condition={comment.open}>
          <div className="pl-10 pr-10">
            <InputForm
              marginBottom={10}
              defaultValue={comment.text}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={(e) =>
                setComment((prev) => ({ ...prev, text: e.target.value }))
              }
            />
          </div>
        </When>

        {!isComingFromASavedContact && (
          <GenericRow
            title="Salvar para próximas transações"
            rightContentType="component"
            noHorizontalPadding
            rightComponent={
              <InputSwitch
                checked={false}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onChange={(e) => setSaveContact(e.target.checked)}
              />
            }
          />
        )}
      </div>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
      />
    </>
  );
}

export default ConfirmDetails;
