import * as Text from '~styles/text';
import { Dispatch, SetStateAction } from 'react';
import { FooterButton } from '~components';
import { PossiblesSteps } from '../ForgotPasswordGuest.types';
import { breakpoints } from '~styles/metrics';
import { colors } from '~styles';
import useWindowDimensions from '~hooks/windowDimension';
import LockIcon from '~assets/images/lock-icon.svg';

export const Warning = ({
  setStep,
}: {
  setStep: Dispatch<SetStateAction<PossiblesSteps>>;
}) => {
  const { width } = useWindowDimensions();

  const goTo2fa = () => {
    setStep('2fa');
  };

  return (
    <>
      <div className="medium-icon">
        <LockIcon />
      </div>
      <Text.Heading1Medium marginTop={10} marginBottom={30}>
        Atualizar senha de acesso
      </Text.Heading1Medium>

      <Text.Heading5
        color={colors.black}
        marginBottom={40}
        lineHeight={140}
        marginTop={10}
        width={breakpoints.desktop < width ? '100%' : ''}
      >
        As atualizações periódicas de senha são necessárias para manter a
        segurança dos seus dados. E queremos que seus dados estejam sempre
        seguros!
        <br />
        <br />
        Lembre-se de criar uma senha fácil de memorizar, mas não óbvia. Utilize
        letras maiúsculas, minúsculas, números e caracteres especiais. Não
        compartilhe ela com ninguém e sempre conte com o Capitual para te
        ajudar!
        <br />
        <br />
        Clique no botão avançar para continuar.
      </Text.Heading5>

      <FooterButton
        genericButtonContent="Avançar"
        genericButtonType="secondary"
        onClickGenericButton={goTo2fa}
      />
    </>
  );
};
