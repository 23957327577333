import * as Style from './RoundButtonIcon.styles';
import { RoundButtonIconProps } from './RoundButtonIcon.types';

const RoundButtonIcon = (props: RoundButtonIconProps) => {
  const {
    selected,
    noHover,
    onClick,
    disabled,
    buttonSize,
    buttonType,
    style,
    children,
  } = props;

  return (
    <Style.RoundBtnContainer
      selected={selected}
      onClick={noHover || disabled ? undefined : onClick}
      disabled={disabled}
      className={`${buttonSize || 'icon16'} ${buttonType || 'default'}`}
      noHover={noHover}
    >
      <div className="center-icon" style={style}>
        {children}
      </div>
    </Style.RoundBtnContainer>
  );
};

export default RoundButtonIcon;
