import * as React from 'react';

import { SVGProps } from 'react';

const CalendarDayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '23'}
    height={props.height || '24'}
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00196 0.0098877L7.00195 3.00989L5.00195 3.00989L5.00195 0.0098877H7.00196ZM7.00195 3.00989L3.75195 3.00989C1.68088 3.00989 0.00195312 4.68882 0.00195312 6.75989V19.2599C0.00195312 21.331 1.68089 23.0099 3.75195 23.0099H18.252C20.323 23.0099 22.002 21.331 22.002 19.2599V6.75989C22.002 4.68882 20.323 3.00989 18.252 3.00989H15.7824L17.002 3.00989L17.002 0.0098877H15.002L15.002 3.00989H7.00195ZM2.01967 6.5099C2.008 6.59155 2.00195 6.67501 2.00195 6.75989V19.2599C2.00195 20.2264 2.78545 21.0099 3.75195 21.0099H18.252C19.2185 21.0099 20.002 20.2264 20.002 19.2599V6.75989C20.002 6.67501 19.9959 6.59155 19.9842 6.5099H2.01967ZM4.00195 9.00989V11.0099H7.00195V9.00989H4.00195Z"
      fill={props.fill || '#026FF4'}
    />
  </svg>
);

export default CalendarDayIcon;
