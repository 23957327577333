import { colors } from '~styles';
import styled from 'styled-components';
import { GeneralStyleProps } from 'src/@types/general';

export const Container = styled.div<GeneralStyleProps>`
  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;
  padding: ${(props) => props.paddingVertical || 25}px
    ${(props) => props.paddingHorizontal || 30}px;
  background: ${(props) => props.backgroundColor || colors.white};
  overflow-x: hidden;
`;
