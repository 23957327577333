import { colors } from '~styles';
import { breakpoints, screen } from '~styles/metrics';
import styled, { css } from 'styled-components';
import { FadeProps } from './ModalSideBar.types';

export const ModalContainer = styled.aside<FadeProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 418px;
  height: calc(100% - 75px);
  background-color: ${colors.white};
  z-index: 99;
  bottom: 0;
  right: 0;
  padding: 25px 0 20px 0;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: ${breakpoints.tablet}px) {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    height: 100%;
  }

  @media (max-width: ${breakpoints.phone}px) {
    width: 100%;
    height: 100%;
  }

  overflow-y: auto;

  .close-button-wrapper {
    width: 28.5px;
    height: 28.5px;
    position: absolute;
    top: 24px;
    right: 15px;
  }

  ${(props) =>
    props.fadeIn
      ? css`
          right: 0;
          transition: 0.5s ease;
        `
      : css`
          right: -100%;
          transition: 0.5s ease;
        `}/* @media ${screen.tablet} {
    width: 100vw;
    height: 100vh;
    flex-direction: column;
  } */
`;
