import * as Text from '~styles/text';
import { ChangeEvent, useState } from 'react';
import { LimitOptionsPageProps } from '../NewLimit.types';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import InputCurrency from '~components/Inputs/InputCurrency';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';
import MoneyIconSettings from '~assets/svg/moneyIconSettings';
import { InputSelect } from '~components/Inputs';
import { formatCurrencyFn } from '~helpers/format/currency';
import { daysAmountFromMonth } from '~helpers/format/date';
import { useSolicitLimit } from '~reactQuery/mutations/settings/useSolicitLimit';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { getSelectedUserOrganization } from '~helpers/device';
import { useOrgInfo } from '~reactQuery/queries/business/useGetBusiness';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { When } from 'react-if';
import dayjs from 'dayjs';
import { optionsReason } from '~data/limits';

type OptionSelected = 'PIX' | 'TED' | 'BOLETO' | 'EXCHANGE' | 'CRYPTO_WITHDRAW';
type OptionSelectedType = 'Transacional' | 'Diário' | 'Mensal' | 'Pix noturno';

const LimitValue = (props: LimitOptionsPageProps) => {
  const { setCurrentStep, stepParams } = props;
  const { params } = useActionsOnModalMultiStep();
  const orgId = getSelectedUserOrganization();
  const optSelected: OptionSelected = params?.origin;

  const { data: partnerData, isLoading: isLoadingPartnerData } =
    useGetPartner();

  const { data: businessInfos, isLoading: loadingBizInfos } = useOrgInfo({
    userOrganizationId: Number(orgId),
  });

  const { mutateAsync: requestSolicitLimit, isLoading: isLoadingRequest } =
    useSolicitLimit();

  const [reason, setReason] = useState('');
  const [depositValue, setDepositValue] = useState('');

  const currentMonth = dayjs().get('month');

  const optionsCripto = optionsReason?.filter(
    (i) => !i?.label?.includes('investimentos')
  );

  const options: {
    [key: string]: Array<{
      value: string;
      label: string;
    }>;
  } = {
    pix: optionsReason,
    ted: optionsReason,
    exchange: optionsCripto,
    cripto: optionsCripto,
  };

  const transactionalLimit = params?.limits?.maxLimit;
  const dailyLimit = params?.quotas?.daily?.limit;
  const monthlyLimit = params?.quotas?.monthly?.limit;
  const isTransactionalUnlimited =
    params?.limits?.maxLimit === -1 || params?.limits?.maxLimit === 0;
  const isDailyUnlimited =
    params?.quotas?.daily?.used === -1 ||
    params?.quotas?.daily?.limit === -1 ||
    params?.quotas?.daily?.limit === 0;
  const isMonthlyUnlimited =
    params?.quotas?.monthly?.used === -1 ||
    params?.quotas?.monthly?.limit === -1 ||
    params?.quotas?.monthly?.limit === 0;

  const translatorLimit: {
    [key in OptionSelectedType]: {
      label: string | boolean;
      value: number;
      type: 'HORARIO' | 'DIARIO' | 'MENSAL' | 'NOTURNO';
      isUnlimited?: boolean;
    };
  } = {
    Transacional: {
      label: isTransactionalUnlimited
        ? 'Indefinido'
        : formatCurrencyFn('BRL', String(transactionalLimit)),
      value: transactionalLimit,
      type: 'HORARIO',
      isUnlimited: isTransactionalUnlimited,
    },
    Diário: {
      label: isDailyUnlimited
        ? 'Indefinido'
        : formatCurrencyFn('BRL', String(dailyLimit)),
      value: dailyLimit,
      type: 'DIARIO',
      isUnlimited: isDailyUnlimited,
    },
    Mensal: {
      label: isMonthlyUnlimited
        ? 'Indefinido'
        : formatCurrencyFn(
            'BRL',
            String(monthlyLimit * Number(daysAmountFromMonth(currentMonth)))
          ),
      value: monthlyLimit * Number(daysAmountFromMonth(currentMonth)),
      type: 'MENSAL',
      isUnlimited: isMonthlyUnlimited,
    },
    'Pix noturno': {
      label: false,
      value: 0,
      type: 'NOTURNO',
    },
  };

  const showReason =
    params?.origin === 'pix' ||
    params?.origin === 'ted' ||
    params?.origin === 'cripto' ||
    params?.origin === 'exchange';

  const formatToFloat = (number: string) => {
    const numberString: string = number.toString();
    const lastDigits: string = numberString.slice(-2);
    const remainingDigits: string = numberString.slice(0, -2);
    const formattedNumber = `${remainingDigits}.${lastDigits}`;

    return formattedNumber;
  };

  const translatorOperation: {
    [key in string]: OptionSelected;
  } = {
    pix: 'PIX',
    ted: 'TED',
    bill: 'BOLETO',
    exchange: 'EXCHANGE',
    cripto: 'CRYPTO_WITHDRAW',
  };

  const optionSelectedType = stepParams.optionSelect as OptionSelectedType;

  const goNext = async () => {
    try {
      requestSolicitLimit({
        document: String(businessInfos?.organizations?.businessCode),
        email: String(partnerData?.data?.email),
        operation: translatorOperation[optSelected],
        userOrganizationId: Number(orgId),
        time: translatorLimit[optionSelectedType]?.type,
        value: Number.parseFloat(formatToFloat(onlyNumbers(depositValue))),
        reason,
      });

      setCurrentStep('success');
    } catch (error) {
      // console.log(error)
    }
  };

  const loadingNextButton =
    isLoadingRequest || isLoadingPartnerData || loadingBizInfos;

  const disableNextButton =
    depositValue === '' ||
    depositValue === 'R$ 0,00' ||
    (showReason && reason === '');

  return (
    <>
      <ModalMultiStepHeader
        title="Qual valor você quer ajustar?"
        description="Digite seu novo limite desejado e selecione o motivo que corresponde à solicitação."
        backButtonAction={() => setCurrentStep('limitOptions')}
      />

      <div className="container-deposit-value">
        <InputCurrency
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setDepositValue(event.target.value)
          }
          className={`margin-vertical ${
            !Number(onlyNumbers(depositValue)) && 'without-value'
          } `}
          placeholder="R$ 0,00"
          maxLength={24}
        />

        <div className="solicit-reason">
          <div className="icon">
            <MoneyIconSettings />

            <Text.Heading5>Limite atual</Text.Heading5>
          </div>

          <Text.Heading5>
            {translatorLimit[optionSelectedType]?.label || 'Indefinido'}
          </Text.Heading5>
        </div>

        <When condition={showReason}>
          <div className="container-input">
            <Text.Heading5 style={{ marginTop: 36 }}>
              Qual é o motivo da sua solicitação?
            </Text.Heading5>

            <InputSelect
              options={options[params?.origin]}
              className="input-select"
              styleIcon={{ right: -15 }}
              value={reason}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setReason(event.target.value)
              }
            />
          </div>
        </When>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Solicitar novo limite"
        singleButtonAlignment="flex-end"
        singleButtonWidth="210px"
        singleButtonAction={goNext}
        singleButtonLoading={loadingNextButton}
        disableSingleButton={disableNextButton}
      />
    </>
  );
};

export default LimitValue;
