import PixAndTedIcon from '~assets/svg/pixAndTed';
import colors from '~styles/colors';
import ConvertSettingsIcon from '~assets/svg/convertSettingsIcon';
import CalendarDayIcon from '~assets/svg/calendarDay';
import CalendarMonthIcon from '~assets/svg/calendarMonth';

export const translatorDescription: { [key: string]: string } = {
  pix: 'Selecione o tipo de transação que você deseja ajustar em Transferências via Pix.',
  ted: 'Selecione o tipo de transação que você deseja ajustar em Transferências via TED.',
  exchange:
    'Selecione o tipo de transação que você deseja ajustar em Transferências e Conversões com Criptomoedas.',
  cripto:
    'Selecione o tipo de transação que você deseja ajustar em Saques e Retiradas com Criptomoedas.',
  bill: 'Selecione o tipo de transação que você deseja ajustar em Pagamento de Boletos.',
};

export const optionsList = [
  {
    keyType: 'Transacional',
    keyValue: 'Limite para realização de uma única transação.',
    enabled: true,
    chipLabel: '',
    icon: <ConvertSettingsIcon width={24} height={24} />,
  },
  {
    keyType: 'Diário',
    keyValue: 'Limite para realização de transações durante o dia.',
    enabled: true,
    chipLabel: '',
    icon: <CalendarDayIcon width={24} height={24} />,
  },
  {
    keyType: 'Mensal',
    keyValue: 'Limite para realização de transações durante o mês.',
    enabled: true,
    chipLabel: '',
    icon: <CalendarMonthIcon width={24} height={24} />,
  },
  {
    keyType: 'Pix noturno',
    keyValue: 'Limite para realização de transações noturnas.',
    enabled: true,
    chipLabel: '',
    icon: <PixAndTedIcon width={24} height={24} fill={colors.primary} />,
  },
];

export const optionsReason = [
  {
    value: 'Enviar valores maiores à minha corretora de criptomoedas',
    label: 'Enviar valores maiores à minha corretora de criptomoedas',
  },
  {
    value: 'Enviar valores maiores à minha corretora de investimentos',
    label: 'Enviar valores maiores à minha corretora de investimentos',
  },
  {
    value: 'Meu limite é baixo para negociações OTC',
    label: 'Meu limite é baixo para negociações OTC',
  },
  {
    value: 'Meu limite é baixo para negociações P2P',
    label: 'Meu limite é baixo para negociações P2P',
  },
  {
    value: 'Outras oportunidades de negociações',
    label: 'Outras oportunidades de negociações',
  },
];
