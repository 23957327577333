import * as Style from './DialogPopUp.styles';
import * as Text from '~styles/text';

import CloseButton from '~components/Buttons/CloseButton';
import { DialogProps } from './DialogPopUp.types';
import { GenericButton } from '..';
import { When } from 'react-if';

const DialogPopUp = (props: DialogProps) => {
  return (
    <When condition={props.openDialog}>
      <Style.Container>
        <Style.Dialog style={{ marginBottom: props.marginBottom || 0 }}>
          <Style.Header>
            <Text.Heading5>{props.title}</Text.Heading5>
            <div className="close-padding">
              <CloseButton
                closeIconSize="small"
                onClick={() => props.setModal(!props.openDialog)}
                marginTop="0px"
                marginBottom="0px"
              />
            </div>
          </Style.Header>
          <Style.Body>
            <Text.Heading4>{props.description || props.children}</Text.Heading4>
          </Style.Body>
          <Style.Bottom>
            {props.firstButtonText ? (
              <GenericButton
                buttonType="default"
                onClick={props.firstButtonAction}
              >
                {props.firstButtonText}
              </GenericButton>
            ) : null}
            {props.secondButtonText ? (
              <GenericButton
                buttonType="secondary"
                onClick={props.secondButtonAction}
              >
                {props.secondButtonText}
              </GenericButton>
            ) : null}
          </Style.Bottom>
        </Style.Dialog>
      </Style.Container>
    </When>
  );
};

export default DialogPopUp;
