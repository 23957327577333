import { SVGProps } from 'react';

const BillBarCodeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0.497009C0.447715 0.497009 0 0.944725 0 1.49701V6.49701H2V2.49701H6V0.497009H1ZM1 24.497C0.447715 24.497 0 24.0493 0 23.497V18.497H2V22.497H6V24.497H1ZM24 1.49701C24 0.944725 23.5523 0.497009 23 0.497009H18V2.49701H22V6.49701H24V1.49701ZM23 24.497C23.5523 24.497 24 24.0493 24 23.497V18.497H22V22.497H18V24.497H23ZM4 18.497V6.49701H6V18.497H4ZM10 6.49701V18.497H12V6.49701H10ZM13 18.497V6.49701H14V18.497H13ZM7 6.49701V18.497H8V6.49701H7ZM15 18.497V6.49701H17V18.497H15ZM19 6.49701V18.497H20V6.49701H19Z"
      fill={props.fill || '#757893'}
    />
  </svg>
);

export default BillBarCodeIcon;
