import AdminSVG from '~assets/svg/statement/admin';
import BillSVG from '~assets/svg/statement/bill';
import CapContaConversionSVG from '~assets/svg/statement/capContaConversion';
import ConvertSVG from '~assets/svg/statement/convert';
import ExternalWalletReceiveSVG from '~assets/svg/statement/externalWalletReceive';
import ExternalWalletSentSVG from '~assets/svg/statement/externalWalletSent';
import MoneyReceivedSVG from '~assets/svg/statement/moneyReceived';
import PartnerSVG from '~assets/svg/statement/partner';
import PaymentSentSVG from '~assets/svg/statement/paymentSent';
import PixTedReceivedSVG from '~assets/svg/statement/pix-ted-received';
import PixTedSentSVG from '~assets/svg/statement/pix-ted-sent';
import PromoIconSVG from '~assets/svg/statement/promo';

import WalletMaticSVG from '~assets/images/icons/wallets/WalletMatic';
import WithdrawIcon from '~assets/images/icons/24x24/withdraw.svg';
import { colors } from '~styles';

export const TransactionsList = [
  {
    id: 1,
    iconTransaction: <WithdrawIcon />,
    titleTransaction: 'Transferência Enviada',
    dateTransaction: 'Hoje • 16:25',
    iconFrom: <WalletMaticSVG />,
    titleFrom: 'Polygon',
    descriptionFrom: 'CapWallet',
    iconTo: undefined,
    titleTo: 'Ayla Grilo Garcia',
    descriptionTo: '038.309.616-26',
    value: '- 12.066632 MATIC',
    valueConverted: '≈ R$ 137,59',
    received: false,
  },
  {
    id: 2,
    iconTransaction: <WithdrawIcon />,
    titleTransaction: 'Transferência Recebida',
    dateTransaction: 'Hoje • 16:26',
    iconFrom: undefined,
    titleFrom: 'Santhiago Lemos Corte',
    descriptionFrom: '133.060.676-01',
    iconTo: <WalletMaticSVG />,
    titleTo: 'Polygon',
    descriptionTo: 'CapWallet',
    value: '+ 12.066632 MATIC',
    valueConverted: '≈ R$ 137,59',
    received: true,
  },
];

export const TRANSFER_ICONS = [
  {
    id: 1,
    icon: <AdminSVG />,
    type: 'admin',
    label: 'Transferência Recebida',
  },
  {
    id: 2,
    icon: <PixTedReceivedSVG />,
    type: 'transfer_recv',
    label: 'Transferência recebida',
  },
  {
    id: 3,
    icon: <MoneyReceivedSVG />,
    type: 'invoice_recv',
    label: 'Cobrança recebida',
  },
  {
    id: 4,
    icon: <MoneyReceivedSVG />,
    type: 'fiat_deposit',
    label: 'Depósito de fiduciária',
  },
  {
    id: 5,
    icon: <PaymentSentSVG />,
    type: 'tecban_withdraw',
    label: 'Saque em Banco24horas',
  },
  {
    id: 6,
    icon: <ExternalWalletReceiveSVG />,
    type: 'crypto_deposit',
    label: 'Depósito de criptomoeda',
  },
  {
    id: 7,
    icon: <PixTedSentSVG />,
    type: 'ted_withdraw',
    label: 'Saque em TED',
  },
  {
    id: 8,
    icon: <ConvertSVG fill={colors.grayMedium} />,
    type: 'exchange_sent',
    label: 'Conversão realizada',
  },
  {
    id: 9,
    icon: <ConvertSVG fill={colors.success} />,
    type: 'exchange_recv',
    label: 'Conversão recebida',
  },
  {
    id: 10,
    icon: <PixTedSentSVG />,
    type: 'transfer_sent',
    label: 'Transferência enviada',
  },
  {
    id: 11,
    icon: <MoneyReceivedSVG fill={colors.success} />,
    type: 'interest',
    label: 'Rendimento diário',
  },
  {
    id: 12,
    icon: <ExternalWalletSentSVG stroke={colors.grayMedium} />,
    type: 'crypto_withdraw',
    label: 'Saque de cripto',
  },
  {
    id: 13,
    icon: <BillSVG />,
    type: 'boleto_withdraw',
    label: 'Pagamento de Boleto',
  },
  {
    id: 17,
    icon: <PartnerSVG stroke={colors.grayMedium} />,
    type: 'settle_withdrawal',
    label: 'Transferência Parceira',
  },
  {
    id: 18,
    icon: <CapContaConversionSVG stroke={colors.grayMedium} />,
    type: 'cap_exchange_sent',
    label: 'Conversão Realizada',
  },
  {
    id: 19,
    icon: <CapContaConversionSVG />,
    type: 'cap_exchange_recv',
    label: 'Conversão Recebida',
  },
  {
    id: 20,
    icon: <PromoIconSVG />,
    type: 'promotion_bonus',
    label: 'Criptoback Recebido',
  },
  {
    id: 21,
    icon: <MoneyReceivedSVG fill={colors.success} />,
    type: 'refund',
    label: 'Reembolso Recebido',
  },
];
