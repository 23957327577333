import * as Style from './PartnersSteps.styles';
import * as Text from '~styles/text';
import * as yup from 'yup';

import { Else, If, Then } from 'react-if';
import { GenericButton, InputForm, InputPhone } from '~components';
import { useEffect, useState } from 'react';

import ArrowBackIcon from '~assets/images/icons/kyc/arrow-back.svg';
import MessageSent from '~assets/images/kyc/message-sent.svg';
import { SendEmailProps } from '../Kyc.types';
import SwitchOptions from '~components/Buttons/SwitchOptions';
import { cellphoneMask } from '~helpers/format/masks';
import { colors } from '~styles';
import { useTranslation } from 'react-i18next';

export const SendEmailOrSms = ({ gotToModalStep }: SendEmailProps) => {
  const [selectedSendTypeOption, setSelectedSendTypeOption] = useState(1);
  const [showWaitingSender, setShowWaitingSender] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState({
    email: '',
    phone: '',
  });

  const { t } = useTranslation();

  const goNextStep = () => {
    if (showWaitingSender) {
      // quando o email/sms foi enviado, lembrar de tirar obrigatoriedade da tela de sócios
    } else {
      if (selectedSendTypeOption === 1) {
        // use Sms
      } else {
        // use email
      }
      setShowWaitingSender(true);
    }
  };

  const emailSchema = yup
    .string()
    .trim()
    .required('Campo obrigatório')
    .email('Email inválido');
  const phoneSchema = yup
    .string()
    .trim()
    .required('Campo obrigatório')
    .min(16, 'Compo incompleto');

  useEffect(() => {
    if (selectedSendTypeOption === 1) {
      phoneSchema
        .validate(emailOrPhone.phone)
        .then(() => setEnableButton(true))
        .catch(() => setEnableButton(false));
    } else {
      emailSchema
        .validate(emailOrPhone.email)
        .then(() => setEnableButton(true))
        .catch(() => setEnableButton(false));
    }
  }, [emailOrPhone]);

  return (
    <Style.Container>
      <div className="content">
        <If condition={!showWaitingSender}>
          <Then>
            <div
              className="back-button"
              onClick={() => gotToModalStep('howToFinishRegister')}
            >
              <ArrowBackIcon />
            </div>

            <Text.Heading5 color={colors.grayMedium} marginTop={30}>
              {t('Adicionar Sócio')}
            </Text.Heading5>

            <Text.Heading2Bold marginTop={10} marginBottom={25}>
              {selectedSendTypeOption === 1
                ? t('Digite o número de celular de Luís')
                : t('Digite o email de Luís')}
            </Text.Heading2Bold>

            <SwitchOptions
              selectedOption={selectedSendTypeOption}
              setSelectedOption={setSelectedSendTypeOption}
            />
            <If condition={selectedSendTypeOption === 1}>
              <Then>
                <InputPhone
                  label={t('Telefone')}
                  ddi="+55"
                  placeholder="00 00000-0000"
                  maskFunction={cellphoneMask}
                  onChange={(cel: any) =>
                    setEmailOrPhone((prev) => ({
                      email: prev.email,
                      phone: cel.target.value,
                    }))
                  }
                />
              </Then>
              <Else>
                <InputForm
                  label={t('E-mail')}
                  onChange={(email: any) =>
                    setEmailOrPhone((prev) => ({
                      email: email.target.value,
                      phone: prev.phone,
                    }))
                  }
                  placeholder="email@email.com"
                />
              </Else>
            </If>

            <Text.Heading5 marginTop={40}>
              Um link via {selectedSendTypeOption === 1 ? 'SMS' : 'E-mail'} foi
              enviado para Luis Andrade Cavalcante. Agora é só ele fornecer os
              dados solicitados para que o cadastro seja concluído.
            </Text.Heading5>
          </Then>

          <Else>
            <div
              className="back-button"
              onClick={() => {
                setShowWaitingSender(false);
                setEnableButton(false);
              }}
            >
              <ArrowBackIcon />
            </div>
            <Text.Heading5 color={colors.grayMedium} marginTop={30}>
              {t('Adicionar Sócio')}
            </Text.Heading5>

            <div className="message-sent">
              <MessageSent />
            </div>

            <Text.Heading2Bold marginBottom={20}>
              Agora é só aguardar!
            </Text.Heading2Bold>
            <Text.Heading5>
              {`Enviamos um link via ${
                selectedSendTypeOption === 1 ? 'SMS' : 'E-mail'
              } para Luis Andrade Cavalcante, no número +55 11  99130 4223. Você pode ver o andamento dessa solicitação na página de sócios.`}
            </Text.Heading5>
          </Else>
        </If>
      </div>

      <div className="footer">
        <div className="footer-btn">
          <GenericButton
            buttonType={showWaitingSender ? 'primary' : 'secondary'}
            width="100%"
            onClick={goNextStep}
            disabled={!enableButton}
          >
            {showWaitingSender ? 'Concluir' : 'Próximo'}
          </GenericButton>
        </div>
      </div>
    </Style.Container>
  );
};
