import * as Style from '../SendNewPin.styles';
import * as Text from '~styles/text';

import React, { useEffect } from 'react';

import DialogBottom from '~components/Dialog/DialogBottom.component';
import DialogHeader from '~components/Dialog/DialogHeader.component';
import { InputPin } from '~components/Inputs';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { usePinReset } from '~reactQuery/mutations/business/usePinReset';
import useTimer from '~hooks/useTimer';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';

const AskNewPin: React.FC = ({ setStepsAndParams, stepParams }) => {
  const { timeLeft, restartTimer, counting } = useTimer(60);
  const { mutateAsync: sendEmailReset } = usePinReset();
  const { data: partnerInfos } = useGetPartner();
  const orgId = getSelectedUserOrganization();

  const cpf = partnerInfos?.data?.documentNumber;

  async function resendPin() {
    if (cpf && cpf?.length > 0) {
      try {
        await sendEmailReset({
          userOrganizationId: Number(orgId),
          documentNumber: String(cpf),
        });
        restartTimer();
      } catch (error) {
        // console.log('Error Resend Pin -> ', error);
      }
    }
  }

  const goNextWithPin = (pin: string) => {
    if (pin.length === 4) {
      setStepsAndParams('newPin', { oldPin: pin });
    }
  };

  useEffect(() => {
    restartTimer();
  }, []);

  return (
    <>
      <ModalMultiStepHeader
        title="Digite o código recebido do e-mail"
        backButtonAction={() => setStepsAndParams('sendNewPin')}
        description={`Enviamos um código de segurança para o e-mail cadastrado: ${stepParams?.email}.`}
      />

      <div className="recovery-pin-body">
        <Text.Heading5 marginBottom={10}>
          Digite o código recebido
        </Text.Heading5>
        <InputPin
          autoFocus
          size={4}
          secret
          onChange={(pin) => {
            goNextWithPin(pin);
          }}
          onFinish={(pin) => {
            goNextWithPin(pin);
          }}
        />

        <Text.Heading5 marginTop={10}>
          Caso não tenha recebido o código de segurança, solicite o reenvio ou
          então peça ajuda no chat do suporte.
        </Text.Heading5>
      </div>

      <ModalMultiStepBottom
        doubleButtonTextOne={`Enviar novamente ${
          counting ? `(${timeLeft}s)` : ''
        }`}
        singleButtonAlignment="flex-end"
        disableDoubleButtonOneAction={timeLeft !== 0}
        doubleButtonOneAction={resendPin}
      />
    </>
  );
};

export default AskNewPin;
