import * as React from 'react';
import { SVGProps } from 'react';

const WalletXrpSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.344 10.875H8.032l4.812 4.765a4.498 4.498 0 0 0 6.313 0l4.814-4.765h-2.314L18 14.495a2.847 2.847 0 0 1-3.998 0l-3.659-3.62ZM21.686 24.125H24l-4.844-4.794a4.497 4.497 0 0 0-6.312 0L8 24.125h2.313L14 20.475a2.847 2.847 0 0 1 3.997 0l3.689 3.65Z"
      fill="#23292F"
    />
  </svg>
);

export default WalletXrpSVG;
