import * as Style from './DashboardPage.styles';
import * as Text from '~styles/text';

import { Else, If, Then, When } from 'react-if';
import {
  FooterDashboard,
  HeadBar,
  Menu,
  ModalSideBar,
  Navbar,
  SuccessToast,
} from '~components';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { getSelectedUserOrganization, getToken } from '~helpers/device';

import { APP_ROUTES } from '~data/navigation';
import { breakpoints } from '~styles/metrics';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import useWindowDimensions from '~hooks/windowDimension';
import AlertModal from '~components/AlertModal';
import BitcoinIncomeIcon from '~assets/svg/bitcoinIncome';
import { useGetGenerateIncomes } from '~reactQuery/queries/bank/useGetGenerateIncomes';
import { modalContent } from '~src/common/modals/sideRight/sideRight';

const DashboardPage = () => {
  const token = getToken();
  const selectedOrg = getSelectedUserOrganization();
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();
  const { setOpenModalSideBar, openModalSideBar, content, params, setParams } =
    useOpenModalSideBar();
  const showMenu = useMemo(() => breakpoints.tablet < width, [width]);

  const [modalToOldBizIsVisible, setModalToOldBizIsVisible] = useState(false);
  const [modalIncomeIsVisible, setModalIncomeIsVisible] = useState(false);

  const isHome = pathname === APP_ROUTES.DASHBOARD;
  const isActivitiesPage = pathname === APP_ROUTES.ACTIVITIES;

  const { data: userData } = useGetPartner();

  const { data: generateIncomes, refetch } = useGetGenerateIncomes({
    userOrganizationId: Number(selectedOrg),
    calendar: Number(new Date()?.getFullYear()) - 1,
  });

  const onCloseModal = () => {
    setParams({});
    setOpenModalSideBar(false);
  };

  if (!token) {
    return <Navigate to="/" replace />;
  }

  if (!selectedOrg) {
    return <Navigate to="/organizations" replace />;
  }

  const redirectToOldBIz = () => {
    window.location.href = import.meta.env.VITE_PLATAFORMURL;
  };

  const handleSendIncome = () => {
    refetch();

    setModalIncomeIsVisible(false);

    SuccessToast({
      title: 'Informe de rendimentos enviado!',
      subtitle:
        'O informe de rendimentos da sua conta foi enviado por email com sucesso!',
      autoCloseEnable: true,
    });
  };

  return (
    <Style.Container id="dash-container">
      <When condition={showMenu}>
        <Menu />
      </When>

      <Style.ContentWrapper
        width={width}
        isModalOpen={openModalSideBar}
        isHome={isHome || isActivitiesPage}
      >
        <div className="topbar-wrapper">
          <If condition={showMenu}>
            <Then>
              <HeadBar
                setModalToOldBizIsVisible={setModalToOldBizIsVisible}
                setModalIncomeIsVisible={setModalIncomeIsVisible}
              />
            </Then>

            <Else>
              <Navbar />
            </Else>
          </If>
        </div>

        {/* Wrap the page content - has automatic scroll */}
        <div id="page-wrapper" className="page-wrapper">
          {/* Div that will be resized when modalSideBar Open/close  */}
          <div className="resize-container">
            <Outlet />
            <FooterDashboard />
          </div>
        </div>

        <ModalSideBar open={openModalSideBar} onClose={onCloseModal}>
          {modalContent[content]}
        </ModalSideBar>
      </Style.ContentWrapper>

      <When condition={modalToOldBizIsVisible}>
        <AlertModal
          setOpenAlert={setModalToOldBizIsVisible}
          title="Acessando a Plataforma Antiga"
          primaryButtonText="Cancelar"
          secondaryButtonText="Sim, estou ciente."
          primaryButtonAction={() => setModalToOldBizIsVisible(false)}
          secondaryButtonAction={() => redirectToOldBIz()}
          secondaryButtonColor="secondary"
          secondaryButtonWidth="190px"
          bodyComponent={
            <>
              <Text.Heading4 marginBottom={30}>
                Caso você precise acessar a plataforma antiga, basta clicar no
                botão abaixo para ser redirecionado. Porém, por favor, fique
                ciente de que as credenciais de login e senha da plataforma
                antiga são diferentes da nova.
              </Text.Heading4>

              <Text.Heading4 marginBottom={30}>
                A plataforma antiga não possui as mesmas funcionalidades e
                comodidades da plataforma mais nova, e é por isso que
                recomendamos ficar nela a menos que seja extremamente
                necessário.
              </Text.Heading4>

              <Text.Heading4>
                Antes de prosseguir, você está ciente de que precisará usar
                credenciais diferentes na plataforma antiga?
              </Text.Heading4>
            </>
          }
        />
      </When>

      <When condition={modalIncomeIsVisible}>
        <AlertModal
          setOpenAlert={setModalIncomeIsVisible}
          title="Informe de Rendimentos disponível!"
          primaryButtonText=""
          secondaryButtonText="Enviar informe por e-mail"
          primaryButtonAction={() => {}}
          secondaryButtonAction={() => handleSendIncome()}
          secondaryButtonColor="secondary"
          secondaryButtonWidth="243px"
          bodyComponent={
            <>
              <BitcoinIncomeIcon />

              <Text.Heading4 marginBottom={30} marginTop={20}>
                O informe de rendimentos da sua conta bancária e investimentos
                em criptomoedas já está disponível e pode ajudar na elaboração
                da sua declaração.
              </Text.Heading4>

              <Text.Heading4 marginBottom={30}>
                Lembre-se de que a falta de declaração dos seus investimentos
                pode resultar em multas e penalidades. Verifique com o seu
                contador se os seus investimentos em criptomoedas precisam ser
                declarados no Imposto de Renda.
              </Text.Heading4>

              <Text.Heading4 style={{ display: 'inline-block' }}>
                Clique no botão abaixo para receber o seu informe de rendimentos
                no email{' '}
                <span style={{ fontWeight: 600 }}>{userData?.data?.email}</span>
                .
              </Text.Heading4>
            </>
          }
        />
      </When>
    </Style.Container>
  );
};

export default DashboardPage;
