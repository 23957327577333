import { colors } from '~styles';
import { GeneralStyleProps } from 'src/@types/general';
import styled from 'styled-components';

export const ContainerSelect = styled.div`
  position: relative;
  width: 200px;
  transition: all ease-out 0.3s;

  @media (max-width: 660px) {
    align-self: center;
    width: 100%;
  }

  &.mobile {
    align-self: center;
    width: 100%;
  }

  &:hover {
    .button-upload-anim {
      background-color: ${colors.gray};
      transition: all ease-out 0.3s;

      svg,
      p {
        margin-top: -2px;
        transition: all ease-out 0.3s;
      }
    }
    .fade-anim {
      transition: all ease-out 0.3s;
    }
  }
`;

export const ContainerIcons = styled.div`
  margin-top: 6px;
  padding-left: 28px;
`;

export const ButtonUpload = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 50px;
  background-color: #f3f4f9;
  transition: all ease-out 0.3s;

  @media (max-width: 660px) {
    justify-content: center;
  }

  &.mobile {
    justify-content: center;
  }
`;

export const InputDocs = styled.input`
  width: 100%;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;

  ::-webkit-file-upload-button {
    display: none;
  }
  ::file-selector-button {
    display: none;
  }
`;

export const DocsContainer = styled.div<GeneralStyleProps>`
  margin-bottom: 5px;
  height: 90px;
  border-top: 1px solid rgba(195, 198, 219, 0.25);
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: 660px) {
    flex-direction: column;
    height: 125px;
    padding-bottom: 10px;
    align-items: flex-start;
  }

  &.mobile {
    flex-direction: column;
    height: 125px;
    padding-bottom: 10px;
    align-items: flex-start;
  }
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 56%;

  @media (max-width: 660px) {
    padding-top: 15px;
    max-width: 100%;
  }

  &.mobile {
    padding-top: 15px;
    max-width: 100%;
  }
`;
