import * as Style from './RadioSelect.styles';
import * as Text from '~styles/text';

import RadioButton from '~components/RadioButton';
import { RadioSelectProps } from './RadioSelect.types';
import colors from '~styles/colors';

const RadioSelect = (props: RadioSelectProps) => {
  return (
    <Style.Container
      {...props}
      onClick={props.disabled ? () => {} : props.onClick}
    >
      <div className="left-side">
        {props.icon}
        <Text.Paragraph>{props.title}</Text.Paragraph>
        <Text.Small marginTop={5} color={colors.grayLight}>
          {props.subtitle}
        </Text.Small>
      </div>
      <div className="right-side">
        <RadioButton size={18} checked={props.checked} />
      </div>
    </Style.Container>
  );
};

export default RadioSelect;
