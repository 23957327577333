import * as React from 'react';
import { SVGProps } from 'react';

const CapContaSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.965 1h16.07c1.868.024 3.65.851 4.957 2.298C30.297 4.746 31.02 6.695 31 8.718v10.563c.02 2.024-.703 3.973-2.008 5.42-1.306 1.448-3.088 2.274-4.956 2.299H7.965c-1.868-.024-3.65-.851-4.957-2.298C1.703 23.254.98 21.305 1 19.282V8.718c-.02-2.024.703-3.973 2.008-5.42C4.314 1.85 6.097 1.023 7.965 1Z"
      fill="#F8F9FB"
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g
      clipPath="url(#a)"
      stroke="#272937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9.79 21.418h12.619M23.025 12.03H9.172l7.047-5.579 6.806 5.58ZM16.21 18.777v-6.75M20.72 18.777v-6.75M11.697 18.777v-6.75" />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(7.528 4.823)"
          d="M0 0h17.143v18.353H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CapContaSVG;
