import * as React from 'react';

import { SVGProps } from 'react';

const UsdtCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="M24.216 18.58c-.56.055-1.12.08-1.682.078a8.75 8.75 0 0 1-1.752-.078c-3.42-.154-5.943-.772-5.943-1.468s2.521-1.315 5.945-1.47v2.397c.23 0 .84.078 1.754.078.56.002 1.122-.024 1.68-.078V15.56c3.431.155 5.945.775 5.945 1.47 0 .778-2.594 1.394-5.947 1.55Zm0-3.253v-2.073h4.721V10h-12.95v3.254h4.797v2.079c-3.88.155-6.784 1.006-6.784 1.933 0 .928 2.904 1.705 6.784 1.933V26h3.432v-6.801c3.887-.155 6.784-.93 6.784-1.933-.076-.93-2.972-1.778-6.784-1.933v-.006Z"
      fill="#50AF95"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UsdtCoinSVG;
