import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import SuccessBlueSVG from '~assets/svg/successBlue';
import * as Text from '~styles/text';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { LimitOptionsPageProps } from '../NewLimit.types';

const Success = (props: LimitOptionsPageProps) => {
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  return (
    <>
      <div className="content-success-modal">
        <SuccessBlueSVG />
        <Text.Heading1Bold marginTop={24}>
          Solicitação enviada!
        </Text.Heading1Bold>
        <Text.Heading5 marginTop={16}>
          Nossos agentes foram informados sobre sua solicitação de ajuste. Fique
          ligado, em pouco tempo você será notificado.
        </Text.Heading5>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Concluir"
        singleButtonWidth="210px"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => {
          setOpenModalMultiStep(false);
          props?.setCurrentStep('limitOptions');
        }}
      />
    </>
  );
};

export default Success;
