import { gql, request, useQuery } from '~reactQuery/index';

import { CAPACCOUNT_QUERY_KEYS } from '~src/constants/capaccount';

interface GetCapAccountInfoInput {
  userOrganizationId: number;
}

export interface GetCapAccountInfoResponse {
  balance: {
    account: string;
    available: string;
    blocked: string;
    branch: string;
    documentNumber: string;
    inProcess: string;
    name: string;
    status: string;
  };
}

export const useGetCapAccountInfo = (params: GetCapAccountInfoInput) => {
  const QUERY = gql`
    query Balance($params: GetBalanceInput!) {
      balance(params: $params) {
        inProcess
        available
        blocked
        branch
        account
        status
        name
        documentNumber
      }
    }
  `;

  const fetcher = async () => {
    const response = await request<GetCapAccountInfoResponse>(QUERY, {
      params,
    });
    return response.balance;
  };

  return useQuery([CAPACCOUNT_QUERY_KEYS.GET_CAPACCOUNT_INFO], fetcher, {
    refetchInterval: 60000,
  });
};
