import { request, useMutation } from '~reactQuery';

import { gql } from 'graphql-request';
import { useQueryClient } from 'react-query';

interface WalletsDeleteInput {
  walletAddress: string;
  userOrganizationId: number;
}

interface WalletsDeleteResponse {
  walletsDelete: boolean;
}

export function useWalletsDelete() {
  const queryClient = useQueryClient();

  const mutation = gql`
    mutation WalletsDelete($params: WalletsDeleteInput!) {
      walletsDelete(params: $params)
    }
  `;

  const fetch = async (params: WalletsDeleteInput) => {
    const response = await request<WalletsDeleteResponse>(mutation, {
      params,
    });
    return response.walletsDelete;
  };

  return useMutation('WalletsDelete', fetch, {
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(['getWallets']);
      }, 1000);
    },
  });
}
