import { colors, metrics } from '~styles';
import styled, { css } from 'styled-components';

import { screen } from '~styles/metrics';
import { transparentize } from 'polished';

export const Container = styled.div<{
  width?: string;
  borderBottom?: boolean;
  ulSize?: number;
  height?: number;
}>`
  display: flex;
  flex-direction: column;

  width: ${({ width }) => width || 'auto'};

  .underline {
    position: absolute;
    bottom: -11px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${colors.black};
  }

  nav {
    padding: 5px 5px 0;

    ${({ borderBottom }) =>
      borderBottom
        ? css`
            border-bottom: 1px solid ${transparentize(0.9, colors.black)};
          `
        : css``}

    height: ${(props) => props.height || 54}px;
    display: flex;

    @media ${screen.phone} {
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  nav::-webkit-scrollbar {
    display: none;
  }

  main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    user-select: none;
    margin-top: 35px;
  }

  ul,
  li {
    list-style: none;
    margin: 0;

    div {
      width: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }

  ul {
    display: flex;
    width: 100%;
    ${({ ulSize }) =>
      ulSize
        ? css`
            width: ${ulSize}%;
          `
        : css``}
  }

  li {
    padding: 13px 10px 15px;
    position: relative;
    cursor: pointer;
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    position: relative;
    user-select: none;

    p {
      word-wrap: break-all;
      white-space: nowrap;
      word-break: break-all;
    }
  }
`;
