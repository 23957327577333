import { SVGProps } from 'react';

const MoneyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 8a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0ZM15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0ZM8.667 4.222v.116c.8.053 1.51.545 1.838 1.285l.105.237a.667.667 0 0 1-1.22.54l-.104-.237a.833.833 0 0 0-.763-.496H7.5c-.399 0-.833.428-.833.889 0 .43.348.777.777.777h1.112c1.165 0 2.11.946 2.11 2.111 0 1.068-.83 2.154-2 2.22v.114a.667.667 0 0 1-1.333 0v-.116a2.167 2.167 0 0 1-1.838-1.285l-.105-.237a.667.667 0 0 1 1.22-.54l.104.237a.833.833 0 0 0 .763.496H8.556c.367 0 .777-.394.777-.889a.778.778 0 0 0-.777-.777H7.444a2.111 2.111 0 0 1-2.11-2.111c0-1.082.88-2.12 2-2.216v-.118a.667.667 0 0 1 1.333 0Z"
      fill={props.fill || '#272937'}
    />
  </svg>
);

export default MoneyIcon;
