import { GeneralStyleProps } from 'src/@types/general';
import { colors } from '~styles';
import styled from 'styled-components';

export const ContainerInput = styled.div``;

export const Input = styled.input<GeneralStyleProps>`
  height: 80px;
  width: 100%;
  border-radius: 10px;
  font-size: 27px;
  border: 1px solid #757893;
  background-color: #fff;
  padding: 16px;

  ::placeholder {
    color: ${colors.grayRegular};
    opacity: 1;
  }
`;
