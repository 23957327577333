import { useState } from 'react';
import BlueBarcodeIcon from '~assets/svg/blueBarcode';
import BlueGlobeSVG from '~assets/svg/blueGlobe';
import PixSVG from '~assets/svg/pix';
import { CheckSelect } from '~components/index';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { Sections, useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { SendOptionsPageProps } from '../Send.types';

function SendOptions(props: SendOptionsPageProps) {
  const { setSection, params, setParams } = useActionsOnModalMultiStep();
  const isComingFromCapWallet = params?.comingFrom === 'capWallet';
  const receivedParams = params;
  const [optionSelect, setOptionSelect] = useState('');

  const openModal = (itemSection: Sections) => {
    setSection(itemSection);

    setParams(receivedParams);
  };

  function goNext() {
    openModal('transfer');
  }

  function selectBehavior(keyType: string) {
    if (optionSelect === keyType) {
      setOptionSelect('');
    } else {
      setOptionSelect(keyType);
    }
  }

  const sendOptionsList = [
    {
      keyType: 'Enviar através da rede de criptomoeda',
      keyValue: 'Envie criptomoedas com segurança para endereços externos.',
      enabled: true,
      chipLabel: '',
      icon: <BlueBarcodeIcon />,
    },
    {
      keyType: 'Enviar para carteira interna (CAP-U)',
      keyValue:
        'Envie criptomoedas, metais preciosos e outros, para usuários do Capitual usando o CAP-U.',
      enabled: false,
      chipLabel: '',
      icon: <PixSVG />,
    },
  ];

  return (
    <>
      <ModalMultiStepHeader
        title={`Enviar ${
          !isComingFromCapWallet
            ? 'Fundos'
            : params?.destinationWallet?.walletName
        }`}
        description="Envie criptomoedas ou moedas fiat para carteiras internas ou externas. "
      />
      <div className="content-container">
        {sendOptionsList?.map((item) => {
          return (
            <CheckSelect
              iconType="component"
              disabled={!item.enabled}
              key={item.keyType}
              title={item.keyType}
              text={item.keyValue}
              iconComponent={item.icon}
              chipText={item.chipLabel}
              onClick={() => selectBehavior(item.keyType)}
              selected={optionSelect === item.keyType}
            />
          );
        })}
      </div>
      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={optionSelect.length <= 0}
      />
    </>
  );
}

export default SendOptions;
