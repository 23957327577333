import { SVGProps } from 'react';

const IncomeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || 16}
      height={props.height || 16}
      fill={props.fill || 'none'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.88893 3.41663C4.42938 3.41663 4.08337 3.77758 4.08337 4.19044V11.8095C4.08337 12.2223 4.42938 12.5833 4.88893 12.5833H11.1112C11.5707 12.5833 11.9167 12.2223 11.9167 11.8095V4.19044C11.9167 3.77758 11.5707 3.41663 11.1112 3.41663H4.88893ZM2.58337 4.19044C2.58337 2.92014 3.63026 1.91663 4.88893 1.91663H11.1112C12.3698 1.91663 13.4167 2.92014 13.4167 4.19044V11.8095C13.4167 13.0798 12.3698 14.0833 11.1112 14.0833H4.88893C3.63026 14.0833 2.58337 13.0798 2.58337 11.8095V4.19044ZM4.91671 5.33329C4.91671 4.91908 5.25249 4.58329 5.66671 4.58329H9.16671C9.58092 4.58329 9.91671 4.91908 9.91671 5.33329C9.91671 5.74751 9.58092 6.08329 9.16671 6.08329H5.66671C5.25249 6.08329 4.91671 5.74751 4.91671 5.33329ZM4.91671 7.61901C4.91671 7.20479 5.25249 6.86901 5.66671 6.86901H10.7223C11.1365 6.86901 11.4723 7.20479 11.4723 7.61901C11.4723 8.03322 11.1365 8.36901 10.7223 8.36901H5.66671C5.25249 8.36901 4.91671 8.03322 4.91671 7.61901ZM4.91671 9.90472C4.91671 9.49051 5.25249 9.15472 5.66671 9.15472H7.22226C7.63648 9.15472 7.97226 9.49051 7.97226 9.90472C7.97226 10.3189 7.63648 10.6547 7.22226 10.6547H5.66671C5.25249 10.6547 4.91671 10.3189 4.91671 9.90472Z"
        fill={props.fill || '#272937'}
      />
    </svg>
  );
};

export default IncomeIcon;
