import MovimentsArrowDownIcon from '~assets/images/icons/arrow-down.svg';
import MovimentsArrowUpIcon from '~assets/images/icons/arrow-up.svg';
import YieldArrowDownIcon from '~assets/images/icons/mini-arrow-down.svg';
import InfoIcon from '~assets/images/icons/16x16/info-f.svg';
import MovimentsTotalTransactionsIcon from '~assets/images/icons/transactions/total-transactions.svg';
import { colors } from '~styles';
import * as Text from '~styles/text';
import React, { memo } from 'react';
import { ProfitsProps } from './Profits.types';

const Profits: React.FC<ProfitsProps> = ({
  percentage,
  cashinValue,
  cashoutValue,
  totalValue,
  profit,
  period,
}) => {
  return (
    <div className="profits">
      <div className="text-container">
        {/* <div className="profits-header">
          <div className="tip-container">
            <Text.Paragraph marginRight={5}>
              Lucros em {period} dias
            </Text.Paragraph>

            <div className="tip-icon-container">
              <InfoIcon />

              <div className="tooltip-text">
                <Text.Smaller color={colors.white}>
                  A soma de lucros e perdas em {period} dias, considerando a
                  variação dos seus investimentos durante esse período.
                </Text.Smaller>
              </div>
            </div>
          </div>

          <Text.Heading1Regular color={colors.grayMedium} marginTop={11}>
            {profit}
          </Text.Heading1Regular>

          <div className="yield-container mt-7">
            <div className="yield-icon-container">
              <YieldArrowDownIcon />
              <Text.SmallBold color={colors.danger} marginLeft={5.67}>
                {percentage}
              </Text.SmallBold>
            </div>
          </div>
        </div> */}

        <div className="profits-content">
          <Text.Paragraph marginBottom={10}>
            Movimentações em {period} dias
          </Text.Paragraph>

          <div className="moviments-container">
            <div className="moviments-items">
              <div className="left-moviments-container">
                <MovimentsArrowDownIcon />

                <Text.Small color={colors.grayMedium}>Entradas</Text.Small>
              </div>

              <Text.Paragraph>{cashinValue}</Text.Paragraph>
            </div>

            <div className="moviments-items">
              <div className="left-moviments-container">
                <MovimentsArrowUpIcon />

                <Text.Small color={colors.grayMedium}>Saídas</Text.Small>
              </div>

              <Text.Paragraph>{cashoutValue}</Text.Paragraph>
            </div>

            <div className="moviments-items">
              <div className="left-moviments-container">
                <MovimentsTotalTransactionsIcon />

                <Text.Small color={colors.grayMedium}>Total</Text.Small>
              </div>

              <Text.Paragraph>{totalValue}</Text.Paragraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Profits);