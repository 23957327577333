import * as React from 'react';
import { SVGProps } from 'react';

const CheckIconRounded = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Artwork">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM16.7071 10.7071L17.4142 10L16 8.58578L15.2929 9.29289L11 13.5858L8.70711 11.2929L8 10.5858L6.58579 12L7.29289 12.7071L10.2929 15.7071C10.4804 15.8947 10.7348 16 11 16C11.2652 16 11.5196 15.8947 11.7071 15.7071L16.7071 10.7071Z"
        fill={props.fill || '#272937'}
      />
    </g>
  </svg>
);

export default CheckIconRounded;
