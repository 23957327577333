import { gql, request, useQuery } from '~reactQuery';

interface IBillStatus {
  total: number;
  status: string;
}

interface IBillsEmissionMonthly {
  billsStatus: IBillStatus[];
  total: number;
}

export interface GetBillsEmissionMonthlyResponse {
  getBillsEmissionMonthly: {
    data: IBillsEmissionMonthly;
    isValid: boolean;
    messages: string[];
  };
}

export interface GetBillsEmissionMonthlyInput {
  userOrganizationId: number;
}

export const UseGetBillsEmissionMonthly = (
  params: GetBillsEmissionMonthlyInput
) => {
  const QUERY = gql`
    query GetBillsEmissionMonthly($params: GetBillsEmissionMonthlyInput) {
      getBillsEmissionMonthly(params: $params) {
        data {
          billsStatus {
            total
            status
          }
          total
        }
        isValid
        messages
      }
    }
  `;

  const fetcher = async () => {
    if (!params.userOrganizationId) return null;

    const response = await request<GetBillsEmissionMonthlyResponse>(QUERY, {
      params,
    });

    return response?.getBillsEmissionMonthly?.data;
  };

  return useQuery(['useGetBillsEmissionMonthly'], fetcher);
};
