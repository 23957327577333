import { ContentWrapper, ScrollContainer } from '../SignTemplate.styles';
import { Failed } from './ForgotSteps/5.Failed';
import { PossiblesSteps } from './ForgotPassword.types';
import { ResetPassword } from './ForgotSteps/3.Reset';
import { Start } from './ForgotSteps/1.Start';
import { Success } from './ForgotSteps/6.Success';
import { UserBlocked } from './ForgotSteps/4.UserBlocked';
import { VerifyEmail } from './ForgotSteps/2.VerifyEmail';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import useWindowDimensions from '~hooks/windowDimension';
import * as Styles from '../SignTemplate.styles';

const ForgotPassword = () => {
  const { width } = useWindowDimensions();
  const location = useLocation();

  const parsedToken = location?.search?.substring(5);

  const [step, setStep] = useState<PossiblesSteps>(
    parsedToken ? 'reset' : 'start'
  );
  const [userEmail, setUserEmail] = useState('');

  const recoverySteps: Record<
    PossiblesSteps,
    { content: JSX.Element; progress: number }
  > = {
    start: {
      content: <Start setStep={setStep} setUserEmail={setUserEmail} />,
      progress: 10,
    },
    verifyEmail: {
      content: <VerifyEmail setStep={setStep} userEmail={userEmail} />,
      progress: 25,
    },
    userBlocked: { content: <UserBlocked />, progress: 100 },
    reset: {
      content: <ResetPassword setStep={setStep} token={parsedToken} />,
      progress: 50,
    },
    failed: { content: <Failed setStep={setStep} />, progress: 100 },
    success: { content: <Success />, progress: 100 },
  };

  return (
    <ScrollContainer
      justifyContent={step === 'reset' ? 'flex-start' : 'center'}
    >
      <Styles.ProgressContainer>
        <div
          className="bar"
          style={{ width: `${recoverySteps[step].progress || 1}%` }}
        />
      </Styles.ProgressContainer>
      <ContentWrapper screenWidth={width}>
        {recoverySteps[step].content}
      </ContentWrapper>
    </ScrollContainer>
  );
};

export default ForgotPassword;
