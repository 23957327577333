import { colors } from '~styles';
import * as Text from '~styles/text';
import { forwardRef } from 'react';
import { When } from 'react-if';
import * as Style from './BigInput.styles';
import { BigInputProps } from './BigInput.types';

const BigInput = forwardRef<HTMLTextAreaElement, BigInputProps>(
  (
    {
      onChange,
      maskFunction,
      paddingBottom,
      textAlign,
      placeHolder,
      error,
      ...props
    },
    _ref
  ) => {
    return (
      <Style.ContainerInput>
        <Style.Input
          ref={_ref}
          onChange={(event) => {
            if (onChange) {
              onChange(event);
            }
            const { value } = event.target;
            if (maskFunction) {
              event.target.value = maskFunction(value);
            }

            return event;
          }}
          paddingBottom={paddingBottom}
          textAlign={textAlign}
          placeholder={placeHolder}
          maxLength={50}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              return false;
            }

            return e;
          }}
          {...props}
        />
        <When condition={!!error}>
          <div>
            <Text.Small color={colors.danger}>{error}</Text.Small>
          </div>
        </When>
      </Style.ContainerInput>
    );
  }
);

BigInput.displayName = 'BigInput';

export default BigInput;
