import * as Style from './ModalMultiStep.styles';
import CloseButton from '~components/Buttons/CloseButton';
import { ModalProps } from './ModalMultiStep.types';
import { When } from 'react-if';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useState } from 'react';

const ModalMultiStep = (props: ModalProps) => {
  const {
    openModal,
    setOpenModal,
    children,
    barProgressPercent,
    styleBody,
    classNameBar,
  } = props;
  const [showAnim, setShowAnim] = useState(false);
  const { setSection } = useActionsOnModalMultiStep();

  const closeModal = () => {
    if (props.onClose) {
      props.onClose();
      return;
    }
    setSection('default');
    setTimeout(() => {
      setOpenModal(false);
    }, 300);
  };

  return (
    <When condition={openModal}>
      <Style.ModalBackground fadeIn>
        <Style.ModalContainer height={props.modalHeight}>
          <CloseButton closeIconSize="small" onClick={closeModal} />
          <Style.BodyContainer style={styleBody}>
            {children}
          </Style.BodyContainer>
          {!props.noProgressBar ? (
            <Style.ProgressContainer className={classNameBar}>
              <div
                className="bar"
                style={{ width: `${barProgressPercent || 1}%` }}
              />
            </Style.ProgressContainer>
          ) : (
            <Style.ProgressContainer>
              <div className="divider" />
            </Style.ProgressContainer>
          )}
        </Style.ModalContainer>
      </Style.ModalBackground>
    </When>
  );
};

export default ModalMultiStep;
