import * as Style from './BillRowList.styles';
import * as Text from '~styles/text';

import { LoadingSpinner } from '~components';
import BillItem from './BillItem.component';
import colors from '~styles/colors';
import { Slips } from '~reactQuery/types/bill.types';

function BillRowList({
  allSlipsPages,
  onScroll,
  isFetchingNextPage,
}: {
  allSlipsPages: ({
    data: {
      slips: Slips[];
      total: number;
      totalPages: number;
    };
    isValid: boolean;
    messages: string[];
  } | null)[];

  onScroll: (event: React.UIEvent<HTMLDivElement>) => void;
  isFetchingNextPage: boolean;
}) {
  return (
    <Style.Container>
      <div className="rounded-div">
        <div className="tableFixHead" onScroll={onScroll}>
          <table id="my-table" className="w-full border-collapse">
            <thead>
              <tr>
                <th>
                  <Text.Paragraph color={colors.grayMedium}>
                    Tipo
                  </Text.Paragraph>
                </th>
                <th className="hide">
                  <Text.Paragraph color={colors.grayMedium}>
                    Conta associada
                  </Text.Paragraph>
                </th>
                <th className="hide">
                  <Text.Paragraph color={colors.grayMedium}>
                    Pagador
                  </Text.Paragraph>
                </th>
                <th>
                  <Text.Paragraph color={colors.grayMedium}>
                    Emissão
                  </Text.Paragraph>
                </th>
                <th>
                  <Text.Paragraph color={colors.grayMedium}>
                    Vencimento
                  </Text.Paragraph>
                </th>
                <th>
                  <Text.Paragraph color={colors.grayMedium}>
                    Status
                  </Text.Paragraph>
                </th>
                <th>
                  <Text.Paragraph color={colors.grayMedium}>
                    Valor
                  </Text.Paragraph>
                </th>
              </tr>
            </thead>
            <tbody>
              {allSlipsPages.map((page: any) =>
                page?.data.slips.map((item: any) => (
                  <BillItem key={item.createdAt} item={item} />
                ))
              )}
            </tbody>
          </table>
          {isFetchingNextPage && (
            <div className="load-more">
              <LoadingSpinner
                color={colors.primary}
                size={30}
                strokeSize={12.5}
              />
            </div>
          )}
        </div>
      </div>
    </Style.Container>
  );
}

export default BillRowList;
