import { gql, request, useQuery } from '~reactQuery';

export interface GetPartnerStatusInput {
  documentNumber: string;
  token: string;
}

export interface GetPartnerStatusResponse {
  getPartnerStatus: {
    data: {
      active: boolean;
      capu: null;
      documentNumber: string;
      email: string;
      id: number;
      phone: string;
      registerName: string;
      settings: null;
      socialName: string;
      userOrganizations: null;
    };
    isValid: boolean;
    messages: [string];
  };
}

export const useGetPartnerStatus = (params: GetPartnerStatusInput) => {
  const QUERY = gql`
    query GetPartnerStatus($params: GetPartnerStatusInput) {
      getPartnerStatus(params: $params) {
        data {
          active
          capu
          documentNumber
          email
          id
          registerName
          phone
          settings {
            id
            enablePin
            twoFA
            language
            notificationFlags
            createdAt
            updatedAt
          }
          socialName
          userOrganizations {
            id
            flags
            admin
            active
            createdAt
            updatedAt
            organizationsId
            organizations {
              id
              name
              country
              businessCode
              setup
              bankCompe
              branch
              account
              image
              deletedAt
              createdAt
              updatedAt
              walletsInternalCredentialsId
              banklyPartnersId
              walletsInternalCredentials {
                id
                internalPin
                sessionKey
                userBusinessId
              }
              partner {
                id
                companyKey
                grantType
              }
              settings {
                id
                enablePin
                twoFA
                language
                notificationFlags
                createdAt
                updatedAt
              }
            }
            settings {
              id
              enablePin
              twoFA
              language
              notificationFlags
              createdAt
              updatedAt
            }
            permissions
          }
        }
        isValid
        messages
      }
    }
  `;

  const fetcher = async () => {
    try {
      if (!params.documentNumber || !params?.token) return null;
      const { documentNumber, token } = params;

      const response = await request<GetPartnerStatusResponse>(
        QUERY,
        { params: { documentNumber } },
        { Authorization: `Bearer ${token}` }
      );

      return { data: response?.getPartnerStatus?.data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  };

  return useQuery(['useGetPartnerStatus'], fetcher, {
    refetchOnWindowFocus: false,
    enabled: false,
  });
};
