import * as React from 'react';

const AxsCoinSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={44}
    height={36}
    viewBox="0 0 44 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={1} y={1} width={42} height={34} rx={10} fill="white" />
    <g clipPath="url(#clip0_1_1487)">
      <path
        d="M33.7747 17.4549C34.0751 18.1193 34.0751 18.8807 33.7747 19.5451C31.7228 24.0848 28.0848 27.7228 23.5451 29.7747C22.8807 30.0751 22.1193 30.0751 21.4549 29.7747C16.9152 27.7228 13.2772 24.0848 11.2253 19.5451C10.9249 18.8807 10.9249 18.1193 11.2253 17.4549C13.2772 12.9152 16.9152 9.27725 21.4549 7.22525C22.1193 6.92492 22.8807 6.92492 23.5451 7.22525C28.0848 9.27725 31.7228 12.9152 33.7747 17.4549Z"
        fill="#0055D5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9532 14.3622C16.9532 14.3622 15.72 13.3766 15.6352 15.8109C15.5504 17.6974 15.5527 21.3603 16.426 23.0909C16.7951 23.7419 17.2879 23.5424 18.2048 22.8938C19.0024 22.3047 21.8883 19.6457 23.1101 18.479C24.3318 17.3123 26.8097 13.9657 27.3254 16.2395C27.5294 17.1954 27.7815 18.0068 27.589 20.1271C27.5294 20.7804 27.2498 21.2755 25.9753 20.3907C25.0126 19.7718 24.098 19.1071 24.098 19.1071C24.098 19.1071 23.9215 18.7907 23.5043 19.3042C23.1765 19.7145 23.0115 19.8153 23.5364 20.1271C24.0636 20.4388 26.7294 22.2772 27.7517 22.5637C28.5953 22.8089 29.0766 22.8044 29.1019 21.1471C29.0973 19.8314 29.0216 16.0653 28.5426 14.4608C28.1804 13.4614 27.6486 13.0075 26.5002 14.1307C25.3518 15.2539 21.7302 18.7655 19.7497 20.3563C18.4363 21.3419 17.8747 22.2313 17.3796 20.5878C17.0312 19.3431 16.5796 15.4693 18.0053 16.2074C18.897 16.6338 19.3417 16.6315 18.9933 16.8997C18.6449 17.1656 17.6432 18.0847 17.6432 18.0847C17.6432 18.0847 17.3337 18.3346 17.4781 18.8755C17.6225 19.2538 17.6225 19.4417 18.3996 18.7105C19.1766 17.9793 19.7497 17.4269 19.7497 17.4269C19.7497 17.4269 19.8735 17.232 20.2425 17.5919C20.6116 17.9518 20.6712 18.0526 20.6712 18.0526C20.6712 18.0526 20.7697 18.3208 21.164 17.8555C21.5605 17.3902 21.815 17.106 21.0654 16.6704C20.3182 16.2303 16.9532 14.3622 16.9532 14.3622Z"
        fill="white"
      />
    </g>
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_1_1487">
        <rect width={23} height={23} fill="white" transform="translate(11 7)" />
      </clipPath>
    </defs>
  </svg>
);
export default AxsCoinSVG;
