import BigClose from '~assets/images/icons/big-close.svg';
import SmallClose from '~assets/images/icons/small-close.svg';
import * as Style from './CloseButton.styles';
import { CloseButtonProps } from './CloseButton.types';

const CloseButton = (props: CloseButtonProps) => {
  const { onClick, closeIconSize, invertColors, marginTop, marginBottom } =
    props;

  return (
    <Style.CloseBtn
      invertColors={!!invertColors}
      circleSize={closeIconSize}
      onClick={onClick}
      marginTop={marginTop || '20px'}
      marginBottom={marginBottom || '20px'}
    >
      {closeIconSize === 'small' ? <SmallClose /> : <BigClose />}
    </Style.CloseBtn>
  );
};

export default CloseButton;
