import * as React from 'react';
import { SVGProps } from 'react';

const WalletKcsSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m12.374 17.002 5.515 5.595 3.48-3.531a1.558 1.558 0 0 1 2.167.058c.592.6.617 1.566.057 2.198L19 25.982a1.57 1.57 0 0 1-2.224 0l-6.627-6.724v3.997c0 .882-.705 1.597-1.575 1.597S7 24.137 7 23.255V10.744c0-.882.705-1.598 1.574-1.598.87 0 1.575.716 1.575 1.598v3.997l6.626-6.723a1.569 1.569 0 0 1 2.224 0l4.597 4.66c.56.631.535 1.597-.057 2.198-.592.6-1.545.626-2.167.058l-3.48-3.532-5.518 5.6Zm5.517-1.6c-.637 0-1.212.39-1.457.987a1.616 1.616 0 0 0 .342 1.743 1.56 1.56 0 0 0 1.717.347 1.6 1.6 0 0 0 .974-1.477c0-.424-.166-.831-.461-1.131a1.565 1.565 0 0 0-1.115-.469Z"
      fill="#23AF91"
    />
  </svg>
);

export default WalletKcsSVG;
