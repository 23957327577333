import { gql, request, useQuery } from '~reactQuery';

export interface CurrencyExchangeInput {
  fromCurrency: string;
  toCurrency: string;
  amount: string;
  senderPays?: boolean;
  forcePivot?: boolean;
  userOrganizationId: number;
}

export interface CurrencyExchangeResponse {
  currencyExchange: number;
}

export const useCurrencyExchange = (
  params: CurrencyExchangeInput,
  enabled?: boolean,
  refetchInterval?: number
) => {
  const QUERY = gql`
    query Query($params: CurrencyExchangeInput!) {
      currencyExchange(params: $params)
    }
  `;

  const fetcher = async () => {
    if (params.amount === '0' || !params.fromCurrency) return null;
    const response = await request<CurrencyExchangeResponse>(QUERY, {
      params,
    });
    return response.currencyExchange;
  };

  return useQuery(
    [
      `CurrencyExchange-${params.fromCurrency}`,
      params.amount,
      params.fromCurrency,
      params.toCurrency,
    ],
    fetcher,
    {
      enabled,
      refetchInterval: refetchInterval || false,
    }
  );
};
