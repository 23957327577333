import * as Style from './NotificationsSettings.styles';
import * as Text from '~styles/text';
import colors from '~styles/colors';
import InputSwitch from '~components/Inputs/InputSwitch';
import { useEffect, useState } from 'react';
import NotificationsSvg from '~assets/images/icons/headbar/notifications';
import MailIcon from '~assets/svg/notifications/Mail';
import { usePutSettings } from '~reactQuery/mutations/settings/usePutSettings';
import { getSettingsId } from '~helpers/device';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { NotificationFlags } from '~reactQuery/types/business.types';
import LoadingSpinner from '~components/LoadingSpinner';

const EMPTY_ARRAY: never[] = [];

const NotificationsSettings = () => {
  const [emailFlags, setEmailFlags] = useState<string[]>([]);
  const [internalFlags, setInternalFlags] = useState<string[]>([]);
  const [forcedLoading, setForcedLoading] = useState(true);
  const { mutateAsync: handlePutSettings } = usePutSettings();
  const { data: partnerData, isLoading } = useGetPartner();

  const userSettings = partnerData?.data?.settings;
  const userNotificationsFlags = userSettings?.notificationFlags || EMPTY_ARRAY;
  const userInternalNotificationsFlags =
    userSettings?.internalNotificationFlags || EMPTY_ARRAY;

  useEffect(() => {
    setEmailFlags(userNotificationsFlags);
    setInternalFlags(userInternalNotificationsFlags);
  }, [userNotificationsFlags, userInternalNotificationsFlags]);

  useEffect(() => {
    const timeout = setTimeout(() => setForcedLoading(false), 1000);

    return () => clearTimeout(timeout);
  }, []);

  const handleUpdateEmailFlags = (flag: NotificationFlags) => {
    let updatedFlags = emailFlags;

    const alreadyHasTheFlag = emailFlags.some((f) => f === flag);

    if (alreadyHasTheFlag) {
      updatedFlags = updatedFlags.filter((f) => f !== flag);
    }

    if (!alreadyHasTheFlag) {
      updatedFlags.push(flag);
    }

    handlePutSettings({
      settingsId: Number(getSettingsId()),
      notificationFlags: updatedFlags as [NotificationFlags],
      internalNotificationFlags: internalFlags as [NotificationFlags],
    });
    setEmailFlags([...updatedFlags]);
  };

  const handleUpdateInternalFlags = (flag: NotificationFlags) => {
    let updatedFlags = internalFlags;

    const alreadyHasTheFlag = internalFlags.some((f) => f === flag);

    if (alreadyHasTheFlag) {
      updatedFlags = updatedFlags.filter((f) => f !== flag);
    }

    if (!alreadyHasTheFlag) {
      updatedFlags.push(flag);
    }

    handlePutSettings({
      settingsId: Number(getSettingsId()),
      notificationFlags: emailFlags as [NotificationFlags],
      internalNotificationFlags: updatedFlags as [NotificationFlags],
    });
    setInternalFlags([...updatedFlags]);
  };

  const isEmailFlagChecked = (flag: NotificationFlags) => {
    if (
      emailFlags[0] === NotificationFlags.ALL ||
      emailFlags[0] === NotificationFlags.DEFAULT
    ) {
      return true;
    }
    return (emailFlags || [])?.some((f) => f === flag);
  };

  const isInternalFlagChecked = (flag: NotificationFlags) => {
    if (
      internalFlags[0] === NotificationFlags.ALL ||
      internalFlags[0] === NotificationFlags.DEFAULT
    ) {
      return true;
    }
    return (internalFlags || [])?.some((f) => f === flag);
  };

  if (isLoading || forcedLoading) {
    return (
      <Style.ContainerLoading>
        <LoadingSpinner size={50} strokeSize={21} color={colors.primary} />
      </Style.ContainerLoading>
    );
  }

  return (
    <Style.NotificationsContainer>
      <Style.TypeNotificationContainer>
        <div className="label-container">
          <div className="icon-title-gap">
            <NotificationsSvg fill={colors.grayMedium} />
            <Text.Heading2>Notificações Internas</Text.Heading2>
          </div>

          <Text.Heading5 marginTop={15}>
            Personalize as notificações dentro da plataforma de acordo com suas
            preferências, assim você nunca perderá nenhuma atualização
            importante.
          </Text.Heading5>
        </div>

        <div className="buttons-container">
          {/* <Style.InputDetailContainer>
            <InputSwitch
              checked={isInternalFlagChecked(NotificationFlags.NEWS)}
              onClick={() => handleUpdateInternalFlags(NotificationFlags.NEWS)}
            />

            <div className="detail-description">
              <Text.Heading4>Novidades e atualizações</Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                Novas funcionalidades, novidades e dicas para aproveitar ainda
                mais a sua conta PJ.
              </Text.Paragraph>
            </div>
          </Style.InputDetailContainer> */}

          <Style.InputDetailContainer>
            <InputSwitch
              checked={isInternalFlagChecked(
                NotificationFlags.TRANSACTION
              )}
              onClick={() =>
                handleUpdateInternalFlags(NotificationFlags.TRANSACTION)
              }
            />

            <div className="detail-description">
              <Text.Heading4>
                Transferências e outras movimentações
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                Transferências recebidas via Pix e TED, transferências internas
                e demais movimentações.
              </Text.Paragraph>
            </div>
          </Style.InputDetailContainer>

          {/* <Style.InputDetailContainer>
            <InputSwitch
              checked={isInternalFlagChecked(NotificationFlags.PROMOTION)}
              onClick={() =>
                handleUpdateInternalFlags(NotificationFlags.PROMOTION)
              }
            />

            <div className="detail-description">
              <Text.Heading4>Promoções e pesquisas</Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                Promoções disponíveis para a sua conta e pesquisas para ajudar a
                melhorar nossos serviços.
              </Text.Paragraph>
            </div>
          </Style.InputDetailContainer> */}
        </div>
      </Style.TypeNotificationContainer>

      <div className="divider-notif" />

      <Style.TypeNotificationContainer>
        <div className="label-container">
          <div className="icon-title-gap">
            <MailIcon />
            <Text.Heading2>Notificações por E-mail</Text.Heading2>
          </div>

          <Text.Heading5 marginTop={15}>
            Fique informado sobre o que está acontecendo mesmo quando estiver
            fora de sua conta com as notificações por e-mail.
          </Text.Heading5>
        </div>

        <div className="buttons-container">
          {/* <Style.InputDetailContainer>
            <InputSwitch
              checked={isEmailFlagChecked(NotificationFlags.NEWS)}
              onClick={() => handleUpdateEmailFlags(NotificationFlags.NEWS)}
            />

            <div className="detail-description">
              <Text.Heading4>Novidades e atualizações</Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                Novas funcionalidades e dicas para aproveitar ainda mais a sua
                conta PJ.
              </Text.Paragraph>
            </div>
          </Style.InputDetailContainer> */}

          <Style.InputDetailContainer>
            <InputSwitch
              checked={isEmailFlagChecked(NotificationFlags.TRANSACTION)}
              onClick={() =>
                handleUpdateEmailFlags(NotificationFlags.TRANSACTION)
              }
            />

            <div className="detail-description">
              <Text.Heading4>
                Transferências e outras movimentações
              </Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                Transferências recebidas via Pix e TED, transferências internas
                e demais movimentações.
              </Text.Paragraph>
            </div>
          </Style.InputDetailContainer>

          {/* <Style.InputDetailContainer>
            <InputSwitch
              checked={isEmailFlagChecked(NotificationFlags.PROMOTION)}
              onClick={() =>
                handleUpdateEmailFlags(NotificationFlags.PROMOTION)
              }
            />

            <div className="detail-description">
              <Text.Heading4>Promoções e pesquisas</Text.Heading4>

              <Text.Paragraph color={colors.grayMedium}>
                Promoções disponíveis para a sua conta e pesquisas para ajudar a
                melhorar nossos serviços.
              </Text.Paragraph>
            </div>
          </Style.InputDetailContainer> */}
        </div>
      </Style.TypeNotificationContainer>
    </Style.NotificationsContainer>
  );
};

export default NotificationsSettings;
