import * as yup from 'yup';

export const transferBatchPixDataSchema = yup
  .object()
  .shape({
    e2eId: yup.string().required('Esse campo é obrigatório'),
    accountType: yup.string(),
    holderDocument: yup.string(),
    holderName: yup.string(),
    keyType: yup.string(),
    name: yup.string(),
    amount: yup
      .string()
      .test(
        'is-nonzero',
        'O valor não pode ser R$ 0,00',
        (value) => value !== 'R$ 0,00'
      )
      .required('Esse campo é obrigatório'),
    fullName: yup.string(),
    saveForNextTransfer: yup.boolean(),
    keyValue: yup.string(),
  })
  .required();