import * as React from 'react';

import { SVGProps } from 'react';

const SendSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 14v1a5 5 0 0 0 5 5h6a5 5 0 0 0 5-5v-1M12 15V4M17.657 8.656l-4.95-4.949a1 1 0 0 0-1.414 0l-4.95 4.95"
      stroke="#272937"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);

export default SendSVG;
