import * as React from 'react';
import { SVGProps } from 'react';

const WarningIconSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox="0 0 24 24"
    fill="none"
  >
    <g id="Artwork">
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.544 5.18345C13.369 3.30344 10.631 3.30343 9.45597 5.18343L3.06442 15.4098C1.81557 17.408 3.25209 19.9998 5.6084 19.9998H18.3914C20.7477 19.9998 22.1842 17.408 20.9354 15.4099L14.544 5.18345ZM11 11.9998V12.9998L13 12.9998V11.9998V8.99985L13 7.99985L11 7.99985V8.99985V11.9998ZM13 15.4998C13 16.0521 12.5523 16.4998 12 16.4998C11.4477 16.4998 11 16.0521 11 15.4998C11 14.9476 11.4477 14.4998 12 14.4998C12.5523 14.4998 13 14.9476 13 15.4998Z"
        fill={props.fill || 'white'}
      />
    </g>
  </svg>
);

export default WarningIconSVG;
