import styled from 'styled-components';

export const Container = styled.div<{ marginTop?: number }>`
  display: flex;
  flex-wrap: wrap;

  gap: 10px;

  margin-top: ${({ marginTop }) => marginTop || 0}px;
  margin-bottom: 15px;
`;
