import * as React from 'react';
import { SVGProps } from 'react';

const WalletTrxSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.855 15.014a9.988 9.988 0 0 0-.586-1.882 9.976 9.976 0 0 0-2.15-3.19 9.975 9.975 0 0 0-3.189-2.15 9.986 9.986 0 0 0-7.798 0 9.967 9.967 0 0 0-3.19 2.151 9.966 9.966 0 0 0-1.223 1.48c-.361.54-.671 1.112-.926 1.709a9.986 9.986 0 0 0 0 7.798 9.984 9.984 0 0 0 2.15 3.19 9.972 9.972 0 0 0 3.189 2.15 9.986 9.986 0 0 0 7.798 0 9.982 9.982 0 0 0 3.19-2.15 9.982 9.982 0 0 0 2.15-3.19 9.986 9.986 0 0 0 .585-5.915Z"
      fill="#FF060A"
    />
    <g clipPath="url(#a)">
      <path
        d="M21.502 14.916c-.61-.563-1.452-1.422-2.139-2.031l-.04-.028a.773.773 0 0 0-.226-.126c-1.655-.31-9.36-1.75-9.51-1.731a.285.285 0 0 0-.118.045l-.038.03a.453.453 0 0 0-.106.17l-.01.027V11.44c.867 2.415 4.292 10.327 4.966 12.183.04.126.118.366.262.378h.033c.077 0 .406-.435.406-.435s5.882-7.134 6.478-7.893c.077-.094.145-.194.203-.3a.49.49 0 0 0-.16-.456Zm-5.01.831 2.51-2.082 1.472 1.357-3.983.725Zm-.976-.136-4.322-3.543 6.993 1.29-2.67 2.253Zm.39.928 4.424-.713-5.058 6.094.634-5.38Zm-5.3-4.117 4.549 3.86-.659 5.642-3.89-9.502Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(9 11)" d="M0 0h13v13H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default WalletTrxSVG;
