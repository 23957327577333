import * as React from 'react';
import { SVGProps } from 'react';

const CloseIconRounded = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || '25'}
    height={props.height || '25'}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.002 2.50305C6.47911 2.50305 2.00195 6.9802 2.00195 12.5031C2.00195 18.0259 6.47911 22.5031 12.002 22.5031C17.5248 22.5031 22.002 18.0259 22.002 12.5031C22.002 6.9802 17.5248 2.50305 12.002 2.50305ZM7.29509 9.2104L10.5877 12.5031L7.29509 15.7957L8.7093 17.2099L12.002 13.9173L15.2946 17.2099L16.7088 15.7957L13.4162 12.5031L16.7088 9.2104L15.2946 7.79619L12.002 11.0888L8.7093 7.79619L7.29509 9.2104Z"
      fill={props.fill || '#C85B69'}
    />
  </svg>
);

export default CloseIconRounded;
