import * as React from 'react';

import { SVGProps } from 'react';

const ShieldSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.59 3.088a1 1 0 0 1 .82 0l7.98 3.586a1 1 0 0 1 .587.846l-.998.066.998-.065v.004l.001.01.002.032.006.116a22.106 22.106 0 0 1-.016 1.895c-.063 1.21-.242 2.842-.718 4.492-.473 1.643-1.26 3.37-2.583 4.697C16.32 20.119 14.466 21 12 21c-2.466 0-4.32-.88-5.67-2.233-1.323-1.327-2.11-3.054-2.582-4.697-.476-1.65-.655-3.283-.717-4.492a22.035 22.035 0 0 1-.017-1.895l.006-.116.002-.033V7.521l.999.065-.998-.066a1 1 0 0 1 .588-.846l7.98-3.586ZM5 8.242c-.002.313.002.735.028 1.232.058 1.118.222 2.588.641 4.042.421 1.461 1.08 2.837 2.078 3.838C8.719 18.33 10.063 19 12 19c1.937 0 3.281-.671 4.253-1.646.999-1 1.657-2.377 2.078-3.838.419-1.454.583-2.924.641-4.042.026-.497.03-.92.027-1.232L12 5.096 5 8.242Z"
      fill="#757893"
    />
  </svg>
);

export default ShieldSVG;
