import { useMutation, request, gql } from '~reactQuery';

interface CreateBusinessPartnerIncomesInput {
  businessDataId: number;
  incomes: [string];
}

interface CreateBusinessPartnerIncomesResponse {
  createBusinessPartnerIncomes: {
    data: {
      id: number;
      income: string;
      createdAt: string;
      updatedAt: string;
      deleted: boolean;
      deletedAt: string;
      businessDataId: number;
    };
    messages: [string];
    isValid: boolean;
  };
}

export function useCreateBusinessPartnerIncomesMutation() {
  const mutation = gql`
    mutation CreateBusinessPartnerIncomes(
      $params: createBusinessPartnerIncomesInput!
    ) {
      createBusinessPartnerIncomes(params: $params) {
        isValid
        messages
        data {
          id
          income
          createdAt
          updatedAt
          deleted
          deletedAt
          businessDataId
        }
      }
    }
  `;

  const fetch = async (params: CreateBusinessPartnerIncomesInput) => {
    const response = await request<CreateBusinessPartnerIncomesResponse>(
      mutation,
      {
        params,
      }
    );
    return response.createBusinessPartnerIncomes;
  };

  return useMutation('CreateBusinessPartnerIncomes', fetch);
}
