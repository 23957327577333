import { gql, request, useMutation } from '~reactQuery';

interface TwoFAGenerateSecretInput {
  userOrganizationId: number;
  settingsId: number;
  token?: string;
}

interface TwoFAGenerateSecretResponse {
  twoFAGenerateSecret: {
    data: string;
    isValid: boolean;
    messages: string[];
  };
}

export function useTwoFAGenerateSecret() {
  const mutation = gql`
    mutation TwoFAGenerateSecret($params: twoFAGenerateSecretInput!) {
      twoFAGenerateSecret(params: $params) {
        data
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: TwoFAGenerateSecretInput) => {
    if (!params.settingsId) return null;
    const { settingsId, userOrganizationId } = params;

    const body = {
      settingsId,
      userOrganizationId,
    };

    const response = await request<TwoFAGenerateSecretResponse>(
      mutation,
      {
        params: body,
      },
      params?.token
        ? {
            Authorization: `Bearer ${params?.token}`,
            'x-token-context': 'change_user',
          }
        : {}
    );
    return response?.twoFAGenerateSecret;
  };

  return useMutation('twoFAGenerateSecret', fetch);
}
