import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Font,
} from '@react-pdf/renderer';
import { ConversionDetails } from '~src/common/modals/multiStep/Convert/Convert.types';
import { When } from 'react-if';
import blackLogo from '~assets/images/logo-black.png';
import colors from '~styles/colors';
import dayjs from 'dayjs';
import { formatCurrencyFn } from '~helpers/format/currency';
import { getTxStatus } from '~helpers/transaction/transactionUtils';
import HelveticaNowDisplayRegular from '../../fonts/HelveticaNowDisplay-Regular.ttf';
import HelveticaNowDisplayMedium from '../../fonts/HelveticaNowDisplay-Medium.ttf';
import HelveticaNowDisplayBold from '../../fonts/HelveticaNowDisplay-Bold.ttf';

interface ConvertPixProps {
  convertDetails?: {
    timestamp?: string;
    authCode?: string;
    amount?: string;
    originWallet?: string;
    destinationWallet?: string;
    status?: string;
    walletInfo?: any;
    valueInCrypto?: string;
    valueQuotation?: string;
    type?: string;
  };
  accountDetails?:
    | {
        account: string;
        available: string;
        blocked: string;
        branch: string;
        documentNumber: string;
        inProcess: string;
        name: string;
        status: string;
      }
    | undefined;
}

const styles = StyleSheet.create({
  body: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    fontWeight: 400,
  },
  title: {
    fontSize: 26,
    textAlign: 'left',
    fontWeight: 700,
    lineHeight: '120%',

    color: '#272937',
  },
  logo: {
    width: 125,
    height: 33,
  },
  container: {
    width: '100%',
    marginTop: 30,
    padding: '25px 25px 25px 25px',
  },
  date: {
    marginTop: 15,
    marginBottom: 40,
    fontWeight: 500,
    fontSize: 16,
    color: colors.grayMedium,
  },
  valueContainer: {
    flexDirection: 'column',
    marginBottom: 20,
  },
  divider: {
    width: 50,
    border: '1px solid #C3C6DB',
    marginBottom: 30,
    marginTop: 30,
  },
  footer: {
    backgroundColor: colors.mainGray,
    padding: 20,
    borderRadius: 15,
    bottom: 15,
    position: 'absolute',
    width: '90%',
  },
  label: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '100%',
    color: colors.grayRegular,
    marginBottom: 10,
  },
  link: {
    color: colors.primary,
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '150%',
    marginTop: 12,
    marginBottom: 5,
    textAlign: 'center',
    textDecoration: 'none',
  },
  containerImg: {
    height: 33,
    top: 30,
    left: 25,
    width: '100%',
    zIndex: 99,
  },
  convertedValue: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '100%',
    marginBottom: 7,

    color: '#272937',
  },
  userName: {
    fontWeight: 700,
    fontSize: 18,
  },
  textNames: {
    fontWeight: 700,
    fontSize: 18,
    marginBottom: 7,

    color: '#272937',
  },
  bankNameText: {
    fontWeight: 500,
    fontSize: 15,
    marginBottom: 20,

    color: '#272937',
  },
  displayRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  marginLeft: {
    marginLeft: 20,
  },
  marginBottom: {
    marginBottom: 25,
  },
  colorGrayMedium: {
    color: colors.grayMedium,
  },
  view: {
    marginBottom: 20,
  },
  help: {
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '150%',
    textAlign: 'center',
    marginBottom: 12,
  },
  helpText: {
    fontWeight: 400,
    fontSize: 15,
    lineHeight: '150%',
    textAlign: 'center',
  },
  text: {
    fontWeight: 500,
    fontSize: 15,

    color: '#272937',
  },
  footerText: {
    fontWeight: 500,
    fontSize: 14,

    color: '#757893',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  copyIcon: {
    width: 16,
    height: 16,
  },
});

export const ConvertBankingReceipt = ({
  convertDetails,
  accountDetails,
}: ConvertPixProps) => {
  const amount = convertDetails?.amount;
  const date = dayjs(convertDetails?.timestamp).format('LLL');
  const authCode = convertDetails?.authCode;
  const originWallet = convertDetails?.originWallet;
  const destinationWallet = convertDetails?.destinationWallet;

  const senderBranch = accountDetails?.branch;
  const senderAcc = accountDetails?.account;
  const senderName = accountDetails?.name;
  const valueInCrypto = convertDetails?.valueInCrypto;
  const valueQuotation = convertDetails?.valueQuotation;

  const renderCotation = () => {
    return (
      (convertDetails?.type === 'EXCHANGE_CAPCONTA_TO_CAPWALLET' &&
        'CapConta') ||
      `${
        convertDetails?.type === 'EXCHANGE_CAPWALLET_TO_CAPCONTA' &&
        convertDetails?.walletInfo?.walletName !== undefined
          ? `${convertDetails?.walletInfo?.walletName}`
          : 'Não encontrado'
      }/${
        convertDetails?.type === 'EXCHANGE_CAPWALLET_TO_CAPCONTA' && 'CapConta'
      }` ||
      (convertDetails?.type === 'EXCHANGE_CAPCONTA_TO_CAPWALLET' &&
      convertDetails?.walletInfo?.walletName !== undefined
        ? `${convertDetails?.walletInfo?.walletName}`
        : 'Não encontrado')
    );
  };

  return (
    <Document>
      <Page wrap style={styles.body} size={{ width: 375, height: 1220 }}>
        <View fixed style={styles.containerImg}>
          <Image src={blackLogo} style={styles.logo} />
        </View>
        <View style={styles.container}>
          <View>
            <Text style={styles.title}>
              Comprovante de
              {'\n'}
              Conversão Interna
            </Text>
          </View>
          <Text style={styles.date}>{date}</Text>

          <View style={styles.valueContainer}>
            <Text style={styles.label}>Valor Convertido</Text>
            <Text style={styles.convertedValue}>{valueInCrypto}</Text>
          </View>

          <View style={{ marginBottom: 30 }}>
            <Text style={styles.label}>Valor Aproximado em BRL</Text>
            <Text style={styles.text}>
              {formatCurrencyFn('BRL', amount, false, false, true)}
            </Text>
          </View>

          <View style={{ marginBottom: 30 }}>
            <Text style={styles.label}>Cotação {renderCotation()}</Text>
            <Text style={styles.text}>{valueQuotation}</Text>
          </View>

          <View>
            <Text style={styles.label}>Status</Text>
            {convertDetails?.status ? (
              <Text style={styles.text}>
                {getTxStatus(convertDetails?.status)}
              </Text>
            ) : null}
          </View>

          <View style={styles.divider} />

          <View>
            <Text style={styles.label}>
              {originWallet === 'Cap Wallet' ? 'Recebido por' : 'Enviado por'}
            </Text>
            <Text style={styles.textNames}>{senderName}</Text>
          </View>

          <View style={{ marginTop: 30 }}>
            <Text style={styles.label}>CapWallet de Origem</Text>
            <Text style={styles.text}>
              {(convertDetails?.type === 'EXCHANGE_CAPCONTA_TO_CAPWALLET' &&
                'Carteira CapConta') ||
                (convertDetails?.type === 'EXCHANGE_CAPWALLET_TO_CAPCONTA' &&
                convertDetails?.walletInfo?.walletName !== undefined
                  ? `Carteira de ${convertDetails?.walletInfo?.walletName}`
                  : 'Não encontrado')}
            </Text>
          </View>

          <View style={{ marginTop: 30 }}>
            <Text style={styles.label}>Valor Aproximado em BRL</Text>
            <Text style={styles.text}>
              {formatCurrencyFn('BRL', amount, false, false, true)}
            </Text>
          </View>

          {/* <View>
            <Text style={styles.label}>Instituição</Text>
            <Text style={styles.bankNameText}>
              Acesso Soluções de Pagamento S. A.
            </Text>
          </View>
          <View style={styles.displayRow}>
            <View>
              <Text style={styles.label}>Agência</Text>
              <Text style={styles.text}>{senderBranch}</Text>
            </View>
            <View style={styles.marginLeft}>
              <Text style={styles.label}>Conta</Text>
              <Text style={styles.text}>{senderAcc}</Text>
            </View>
          </View> */}

          <View style={styles.divider} />

          {/* <View>
            <View>
              <Text style={styles.label}>Origem</Text>
              <Text style={styles.textNames}>{originWallet}</Text>
            </View>
          </View>
          <When condition={originWallet !== "Cap Wallet"}>
            <View>
              <View>
                <Text style={styles.label}>Destino</Text>
                <Text style={styles.textNames}>{destinationWallet}</Text>
              </View>
            </View>
          </When> */}

          <View>
            <Text style={styles.label}>CapWallet de Destino</Text>
            <Text style={styles.text}>
              {(convertDetails?.type === 'EXCHANGE_CAPWALLET_TO_CAPCONTA' &&
                'Carteira CapConta') ||
                (convertDetails?.type === 'EXCHANGE_CAPCONTA_TO_CAPWALLET' &&
                convertDetails?.walletInfo?.walletName !== undefined
                  ? `Carteira de ${convertDetails?.walletInfo?.walletName}`
                  : 'Não encontrado')}
            </Text>
          </View>
        </View>

        <View fixed style={styles.footer}>
          {authCode && (
            <View style={styles.marginBottom}>
              <Text style={styles.label}>Protocolo</Text>
              <View style={styles.row}>
                <Text style={styles.footerText}>{authCode}</Text>
              </View>
            </View>
          )}

          <Text style={styles.help}>Precisa de ajuda?</Text>
          <Text style={styles.helpText}>
            Estamos aqui se você tiver alguma dúvida.
          </Text>
          <Text style={styles.link}>help.capitual.com</Text>
        </View>
      </Page>
    </Document>
  );
};
