import { SVGProps } from 'react';

const DocumentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00005 3C5.3432 3 4.00006 4.34314 4.00005 5.99999L4 18C3.99999 19.6568 5.34313 21 6.99999 21L16.9568 21C18.608 21 19.9488 19.6656 19.9568 18.0144L20 9.0048V9V8C20 7.73478 19.8946 7.48043 19.7071 7.29289L15.7071 3.29289C15.5196 3.10536 15.2652 3 15 3H14H7.00005ZM6.00005 6C6.00005 5.44771 6.44777 5 7.00005 5H13V9C13 9.55228 13.4477 10 14 10H17.9952L17.9568 18.0048C17.9541 18.5552 17.5072 19 16.9568 19L7 19C6.44771 19 6 18.5523 6 18L6.00005 6ZM15 8V5.41421L17.5858 8H15ZM7.99999 13H16V11H7.99999V13ZM11 9H7.99999V7H11V9ZM7.99999 17H16V15H7.99999V17Z"
      fill={props.color || '#272937'}
    />
  </svg>
);

export default DocumentIcon;
