const PartnerSVG = (props: React.SVGProps<SVGSVGElement>) => {
  const { stroke = '#0F90FF', height = 25, width = 25 } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M6.5 9V15.5L12 18M6.5 9L12 6.5L17.5 9M6.5 9L12 11.5M17.5 9V15.5L12 18M17.5 9L12 11.5M12 18V11.5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.21777 2.74001C9.38454 2.26295 10.6616 2 12 2C13.3384 2 14.6155 2.26295 15.7822 2.74001M2.81391 15.9581C2.29018 14.7443 2 13.406 2 12C2 10.9394 2.16512 9.91732 2.47105 8.95813M21.1861 15.9581C21.7098 14.7443 22 13.406 22 12C22 10.9394 21.8349 9.91732 21.5289 8.95813M15.7822 21.26C14.6155 21.7371 13.3384 22 12 22C10.6616 22 9.38454 21.7371 8.21777 21.26"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default PartnerSVG;
