import * as S from './Organizations.styles';
import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FooterDashboard } from '~components/Footers';
import InputOrgPin from './OrgSteps/1.InputOrgPin.component';
import LoadingSpinner from '~components/LoadingSpinner';
import SelectOrg from './OrgSteps/0.SelectOrg.component';
import colors from '~styles/colors';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { useGetProcess } from '~reactQuery/queries/kyc/useGetProcess';
import { useNavigate } from 'react-router-dom';

const Organizations = () => {
  const [orgId, setOrgId] = useState(0);
  const [currentStep, setCurrentStep] = useState(2);
  const navigate = useNavigate();

  const { data: partnerInfos, isLoading: loadingPartnerInfos } =
    useGetPartner();

  const { data: kycProcessData, isLoading: isLoadingGetProcess } =
    useGetProcess();

  const userOrganizationsActive = partnerInfos?.data?.userOrganizations
    ?.filter((org) => {
      return org?.active;
    })
    ?.map((org) => org?.organizations?.id);

  const hasOnlyOne =
    userOrganizationsActive?.length === 1 && kycProcessData?.length === 1;
  const isLoading = isLoadingGetProcess || loadingPartnerInfos;

  useEffect(() => {
    if (!isLoading) {
      setCurrentStep(hasOnlyOne ? 1 : 0);
    }
  }, [hasOnlyOne, isLoading]);

  const steps = [
    <SelectOrg
      partnerInfos={partnerInfos}
      setOrgId={setOrgId}
      setCurrentStep={setCurrentStep}
      kycProcessData={kycProcessData}
      key="select-org"
    />,
    <InputOrgPin
      orgId={hasOnlyOne ? userOrganizationsActive[0] : orgId}
      partnerInfos={partnerInfos}
      setCurrentStep={setCurrentStep}
      hasOnlyOne={hasOnlyOne}
      key="input-pin-org"
    />,
    <S.ContainerLoading>
      <LoadingSpinner size={60} strokeSize={25} color={colors.primary} />
    </S.ContainerLoading>,
  ];

  return (
    <>
      <AnimatePresence initial={false}>
        <S.ContainerAnim
          key={currentStep}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.1, ease: 'easeOut' }}
        >
          {steps[currentStep]}
        </S.ContainerAnim>
      </AnimatePresence>
      {isLoading ? null : <FooterDashboard />}
    </>
  );
};

export default Organizations;
