import { screen } from '~styles/metrics';
import styled from 'styled-components';

export const Container = styled.div`
  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 25px;

    .button-item {
      margin: 0px 5px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  @media ${screen.tablet} {
    .button-container {
      overflow-y: scroll;
      margin-left: -30px;
      margin-right: -30px;
      transition: scroll ease-out 0.3s;

      &::-webkit-scrollbar {
        display: none;
      }

      .button-item {
        &:first-child {
          margin-left: 30px;
        }

        &:last-child {
          margin-right: 30px;
        }
      }
    }
  }
`;
