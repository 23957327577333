import { colors } from '~styles';
import { screen } from '~styles/metrics';
import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;
  height: 100vh;
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  border-radius: 50px 0px 0px 50px;
  background-color: ${colors.white};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: unset;
  justify-content: center;
  align-items: center;
  display: flex;

  @media ${screen.phone} {
    width: 90%;
    overflow-y: auto;
  }
`;

export const ContainerBackGround = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.35);
`;

export const QrCodeContainer = styled.div`
  align-self: center;
  margin: 46px 0px 46px 0px;
`;

export const Header = styled.div`
  right: 41px;
  top: 41px;
  position: absolute;

  @media ${screen.phone} {
    right: 25px;
    top: 25px;
  }
`;

export const ContainerCloseClick = styled.div`
  cursor: pointer;
`;

export const Body = styled.div`
  padding: 0px 60px 0px 60px;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media ${screen.phone} {
    padding: 0px 25px 0px 25px;
  }
`;
