import * as React from 'react';

import { SVGProps } from 'react';

const MinusSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.25 7.25h9.5v1.5h-9.5v-1.5Z"
      fill={props.fill || '#fff'}
    />
  </svg>
);

export default MinusSVG;
