import {
  PixKey,
  TedRequisite,
  TedRequisiteSchema,
  TransferMethodTedEnum,
} from '~reactQuery/types/pix.types';
import { gql, request, useMutation } from '~reactQuery/index';

import { CAPACCOUNT_QUERY_KEYS } from '~src/constants/capaccount';
import { PIX_QUERY_TRANSFER } from '~src/constants/pix';
import { getSettingsId } from '~helpers/device';
import { useQueryClient } from 'react-query';

interface PixTransferUsingTedInput {
  userOrganizationId: number;
  value: number;
  description: string;
  method: TransferMethodTedEnum;
  requisite: TedRequisite;
  pin: string;
  token?: string;
}

interface PixTransferUsingTedResponse {
  pixTransferUsingTed: {
    authCode: string;
    value: number;
    description: string;
    method: TransferMethodTedEnum;
    requisite: TedRequisiteSchema;
  };
}

export const usePixTransferUsingTed = () => {
  const queryClient = useQueryClient();
  const organization = getSettingsId();

  const QUERY = gql`
    mutation PixTransferUsingTed($params: PixTedTransferInput!) {
      pixTransferUsingTed(params: $params) {
        authCode
        value
        description
        method
        requisite {
          bank {
            bankName
            bankIspb
            bankCompe
          }
          branch
          account
          holderName
          holderDocument
          accountType
        }
      }
    }
  `;

  const fetcher = async (params: PixTransferUsingTedInput) => {
    const response = await request<PixTransferUsingTedResponse>(QUERY, {
      params: {
        ...params,
        settingsId: Number(organization),
      },
    });
    return response.pixTransferUsingTed;
  };

  return useMutation([PIX_QUERY_TRANSFER.PIX_TED_TRANSFER], fetcher, {
    onSettled: () => {
      queryClient.invalidateQueries([
        CAPACCOUNT_QUERY_KEYS.GET_CAPACCOUNT_INFO,
      ]);
    },
  });
};
