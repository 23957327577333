import CloseButton from '~components/Buttons/CloseButton';
import { useEffect, useState } from 'react';
import * as Style from './ModalSideBar.styles';
import { ModalProps } from './ModalSideBar.types';

const ModalSideBar = (props: ModalProps) => {
  const { open, onClose, children } = props;

  const [showAnim, setShowAnim] = useState(false);

  useEffect(() => {
    if (open) {
      setShowAnim(true);
    } else {
      setShowAnim(false);
    }
  }, [open]);

  const closeModal = () => {
    setShowAnim(false);
    if (onClose) onClose();
  };

  return (
    <Style.ModalContainer fadeIn={showAnim}>
      <div className="close-button-wrapper">
        <CloseButton
          closeIconSize="small"
          onClick={closeModal}
          marginTop="0px"
          marginBottom="0px"
        />
      </div>
      {children}
    </Style.ModalContainer>
  );
};

export default ModalSideBar;
