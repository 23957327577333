import * as Style from '../SearchRecipient.styles';
import * as Text from '~styles/text';
import { useMemo, useState } from 'react';
import { LoadingSpinner, UserPic } from '~components/index';
import { getSelectedUserOrganization } from '~helpers/device';
import { useGetContacts } from '~reactQuery/queries/contacts/useGetContacts';
import colors from '~styles/colors';
import { capitalizeInitialsAndRemoveNumbers } from '~helpers/format/capitalize';
import ArrowRightSimpleIcon from '~assets/svg/arrowRightSimple';
import { PropsListContactsTypes } from '../SearchRecipient.types';
import { validateCPF, validateEmail } from '~validations/personalValidation';
import { hasNumber } from '~validations/stringsValidate';
import { Else, If, Then, When } from 'react-if';
import { cnpjMask, cpfMask, formatPhone } from '~helpers/format/masks';

const ListContacts = (props: PropsListContactsTypes) => {
  const {
    handleNext,
    searchPerson,
    debounceLoading,
    pixInfo,
    loadingResolvePixKey,
    errorOnSearchIdentify,
  } = props;
  const userOrganization = getSelectedUserOrganization();
  const { data: contactsList, isLoading: loadingContacts } = useGetContacts({
    userOrganizationId: Number(userOrganization),
    page: 1,
  });

  const contactsListFiltered = useMemo(() => {
    if (contactsList && searchPerson !== '') {
      return contactsList?.pages?.flat()?.[0]?.contacts?.filter((value) => {
        return value.name
          .toLowerCase()
          .includes(searchPerson?.toLowerCase() || '');
      });
    }

    return contactsList?.pages?.flat()?.[0]?.contacts;
  }, [contactsList, searchPerson]);

  const translator: { [key: string]: string } = {
    PIX_KEY: 'Pix',
    PIX_MANUAL: 'Pix Manual',
    TED: 'TED',
  };

  const isLoading = loadingContacts || loadingResolvePixKey || debounceLoading;

  const isPixKey =
    hasNumber(searchPerson || '') || validateEmail(searchPerson || '');

  const filteredLength = contactsListFiltered?.length ?? 0;

  const boxIsVisible =
    Boolean(filteredLength > 0) ||
    (!isPixKey && !errorOnSearchIdentify) ||
    (isPixKey && !errorOnSearchIdentify) ||
    (isPixKey && !pixInfo && validateCPF(searchPerson || ''));

  const keyMasked = (pixType: string, type: string) => {
    switch (type) {
      case 'CPF':
        return cpfMask(pixType);

      case 'PHONE':
        return String(formatPhone(pixType)).replace('+55', '');

      case 'CNPJ':
        return cnpjMask(pixType);
      default:
        return pixType;
    }
  };

  return (
    <>
      <Style.ScrollBox>
        <When condition={boxIsVisible}>
          <Style.ContainerListContacts>
            <If condition={isLoading}>
              <Then>
                <LoadingSpinner
                  color={colors.primary}
                  size={30}
                  strokeSize={12.5}
                />
              </Then>
              <Else>
                {contactsListFiltered?.map((contact, index) => {
                  const contactData = contact?.contactData || [];
                  return contactData?.map((item) => {
                    const objectContactTransferByBank = {
                      name: contact?.name,
                      documentNumber: contact?.documentNumber,
                      bank: item?.bankCode,
                      bankIspb: item?.bankIspb,
                      bankCode: item?.bankCode,
                      accountType: item?.accountType,
                      transferType: item?.type,
                      agency: item?.branch,
                      bankAccount: item?.accountNumber,
                    };

                    const objectPixInfo = item?.pixValue
                      ? {
                          holderName: contact?.name,
                          keyValue: item?.pixValue,
                          keyType: item?.pixType,
                        }
                      : null;

                    const key = `${
                      item?.accountNumber ?? item?.pixValue
                    }-${index}`;

                    return (
                      <Style.Row
                        key={key}
                        onClick={() =>
                          handleNext(objectContactTransferByBank, objectPixInfo)
                        }
                      >
                        <UserPic name={contact?.name} borderRadius={40} />

                        <div className="description">
                          <Text.Heading5>
                            {capitalizeInitialsAndRemoveNumbers(
                              contact?.name || ''
                            )}
                          </Text.Heading5>
                          <Text.Small color={colors.grayMedium}>
                            {translator[item?.type]}
                            {objectPixInfo
                              ? ` - ${keyMasked(item?.pixValue, item?.pixType)}`
                              : ` - ${item?.bankCode} - ${item?.branch} - ${item?.accountNumber}`}
                          </Text.Small>
                        </div>

                        <div className="last-item">
                          <ArrowRightSimpleIcon stroke={colors.naturalBlack} />
                        </div>
                      </Style.Row>
                    );
                  });
                })}

                <When condition={!isPixKey}>
                  <Style.Row
                    onClick={() =>
                      handleNext({
                        name: capitalizeInitialsAndRemoveNumbers(
                          searchPerson || ''
                        ),
                      })
                    }
                  >
                    <UserPic name={searchPerson} borderRadius={40} />
                    <div className="description">
                      <Text.Heading5>
                        {capitalizeInitialsAndRemoveNumbers(searchPerson || '')}
                      </Text.Heading5>
                    </div>
                    <div className="last-item">
                      <ArrowRightSimpleIcon stroke={colors.naturalBlack} />
                    </div>
                  </Style.Row>
                </When>

                <When condition={isPixKey}>
                  <If condition={!!pixInfo}>
                    <Then>
                      <Style.Row
                        onClick={() =>
                          handleNext(
                            {
                              name: pixInfo?.holderName,
                            },
                            pixInfo
                          )
                        }
                      >
                        <UserPic name={pixInfo?.holderName} borderRadius={40} />
                        <div className="description">
                          <Text.Heading5>{pixInfo?.holderName}</Text.Heading5>
                        </div>
                        <div className="last-item">
                          <ArrowRightSimpleIcon stroke={colors.naturalBlack} />
                        </div>
                      </Style.Row>
                    </Then>
                    <Else>
                      <When condition={validateCPF(searchPerson || '')}>
                        <Style.Row
                          onClick={() =>
                            handleNext({
                              documentNumber: searchPerson,
                            })
                          }
                        >
                          <UserPic name={searchPerson} borderRadius={40} />
                          <div className="description">
                            <Text.Heading5>{searchPerson}</Text.Heading5>
                          </div>
                          <div className="last-item">
                            <ArrowRightSimpleIcon
                              stroke={colors.naturalBlack}
                            />
                          </div>
                        </Style.Row>
                      </When>
                    </Else>
                  </If>
                </When>
              </Else>
            </If>
          </Style.ContainerListContacts>
        </When>
      </Style.ScrollBox>
      <When
        condition={errorOnSearchIdentify && !validateCPF(searchPerson || '')}
      >
        <Text.Heading5 color={colors.danger}>
          {errorOnSearchIdentify}
        </Text.Heading5>
      </When>
    </>
  );
};

export default ListContacts;
