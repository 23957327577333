import * as Style from './Search.styles';

import SearchIcon from '~assets/images/icons/search-input/search.svg';
import { SearchProps } from './Search.types';

const Search = (props: SearchProps) => {
  return (
    <Style.Container
      {...props.containerStyle}
      onClick={props.onClick}
      className={props.className}
      id={props.id}
      removeBorders={props.removeBorders}
      removePadding={props.removePadding}
    >
      <Style.Input
        onChange={props.onChange}
        placeholder={props.placeHolder}
        value={props.value}
        removeBorders={props.removeBorders}
      />
      {props.icon ? props.icon : <SearchIcon />}
    </Style.Container>
  );
};

export default Search;
