export function alphabetPosition(text: string): string {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';

  const positions = text
    .toLowerCase()
    .split('')
    .map((char) => {
      const charIndex = alphabet.indexOf(char);
      return charIndex !== -1 ? charIndex + 1 : -1;
    })
    .filter((position) => position !== -1);

  return positions.join(' ');
}

export const randomColors = [
  '#41B2FF',
  '#9AA9FE',
  '#FE829C',
  '#3983FE',
  '#6377FC',
  '#FBC0A0',
  '#55BCF2',
  '#5D46B0',
  '#E6BDE7',
  '#627BB4',
  '#9DE6F7',
  '#FE7275',
  '#41B2FF',
  '#9AA9FE',
  '#FE829C',
  '#3983FE',
  '#6377FC',
  '#FBC0A0',
  '#55BCF2',
  '#5D46B0',
  '#E6BDE7',
  '#627BB4',
  '#9DE6F7',
  '#FE7275',
  '#9AA9FE',
];

export default {
  transparent: 'transparent',

  white: '#ffffff',
  whiteLight: '#f8f9fb',
  whiteOpacity50p: 'rgba(248, 249, 251, 0.5)',

  gray: '#f3f4f9',
  grayLight: '#c3c6db',
  grayRegular: '#9ea1b9',
  grayMedium: '#757893',
  mainGray: '#f3f4f9',
  grayOpacity20p: 'rgba(158, 161, 185, 0.2)',

  newBlue: '#026FF4',
  blueViolet: '#ECDDFF',
  blueOpacity10p: 'rgba(2, 111, 244, 0.1)',

  primary: '#026FF4',
  primaryDark: '#0264DC',
  warning: '#f3bA2f',
  danger: '#ff6378',
  success: '#00e699',

  redMatte: '#C85B69',
  greenMatte: '#3AAC86',
  primaryOpacity10p: 'rgba(2, 111, 244, 0.1)',
  primaryOpacity20p: 'rgba(2, 111, 244, 0.2)',

  black: '#272937',
  naturalBlack: '#000000',
  blackOpacity10p: 'rgba(39, 41, 55, 0.1)',
  blackOpacity50p: 'rgba(39, 41, 55, 0.5)',
};
