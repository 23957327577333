import { gql, request, useMutation } from '~reactQuery/index';
import { queryClient } from '~src/query';

interface DeleteOrganizationImageParams {
  organizationId: number;
}

interface OrganizationImageResponse {
  deleteOrganizationImage: {
    data: {
      imageUrl: string;
    };
    messages: string[];
    isValid: boolean;
  };
}

export const useDeleteOrganizationImage = () => {
  const mutation = gql`
    mutation DeleteOrganizationImage($params: DeleteOrganizationImageInput) {
      deleteOrganizationImage(params: $params) {
        data {
          imageUrl
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: DeleteOrganizationImageParams) => {
    if (!params.organizationId) return null;

    const response = await request<OrganizationImageResponse>(mutation, {
      params,
    });

    return response.deleteOrganizationImage;
  };

  return useMutation(['useDeleteOrganizationImage'], fetch, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(['useGetOrganizationPartners']);
      }, 1000);
    }
  });
};
