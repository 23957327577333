import { BigInput } from '~components/Inputs';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { PixModalStepsProps } from '../../Transfer.types';
import { useFormContext } from 'react-hook-form';
import { validaCNPJ, validateCPF } from '~validations/personalValidation';
import { formatCpfOrCnpj } from '~helpers/format/numbers';
import { useMemo } from 'react';
import { onlyNumbers } from '~helpers/transaction/transactionUtils';

function ManualTransfer(props: PixModalStepsProps) {
  const { setPixStep: setCurrentStep } = props;
  const { watch, getValues, register } = useFormContext();

  const formValues = getValues();

  const goNext = () => setCurrentStep('fillManualTransferInfo');
  const goBack = () => {
    if (formValues?.contact || formValues?.keyResult) {
      setCurrentStep('pixMethod');
    } else {
      setCurrentStep('chooseTransferMethod');
    }
  };

  const disableButton = useMemo(() => {
    const isCpf = validateCPF(watch('manualDetails.docNum'));
    const isCnpj = validaCNPJ(onlyNumbers(watch('manualDetails.docNum')));

    if (isCpf || isCnpj) return false;

    return true;
  }, [watch('manualDetails.docNum')]);

  return (
    <>
      <ModalMultiStepHeader
        title="Qual o CPF ou CNPJ do titular da conta?"
        description="Digite o CPF completo:"
        backButtonAction={goBack}
      />

      <div className="content-container mt-20">
        <BigInput
          {...register('manualDetails.docNum')}
          defaultValue={getValues('manualDetails.docNum')}
          paddingBottom={104}
          placeHolder="CPF ou CNPJ"
          maskFunction={formatCpfOrCnpj}
          maxLength={18}
        />
      </div>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={disableButton}
      />
    </>
  );
}

export default ManualTransfer;
