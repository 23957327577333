import { SVGProps } from 'react';

const TransferIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 2V1H11V2V4V5H13V4V2ZM9 3V2H7V3V4V5H9V4V3ZM17 3V2H15V3V4V5H17V4V3ZM4 7.5C3.44772 7.5 3 7.94772 3 8.5V19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19V8.5C21 7.94772 20.5523 7.5 20 7.5H4ZM5 18V9.5H19V18H5ZM12 16C10.7574 16 9.75 14.9926 9.75 13.75C9.75 12.5074 10.7574 11.5 12 11.5C13.2426 11.5 14.25 12.5074 14.25 13.75C14.25 14.9926 13.2426 16 12 16Z"
      fill={props.color || '#272937'}
    />
  </svg>
);

export default TransferIcon;
