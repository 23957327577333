import * as Text from '~styles/text';

import { CreatePixStatusPageProps } from '../DeletePixKey.types';
import EndStepStatus from '~components/EndStepStatus';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import RandomKeySVG from '~assets/images/icons/pix/randomKey';
import colors from '~styles/colors';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';

function DeletePixStatus(props: CreatePixStatusPageProps) {
  const { statusOfCreation } = props;
  const { setOpenModalMultiStep, setSection } = useActionsOnModalMultiStep();

  function createAnotherKey() {
    setSection('createPix');
    setOpenModalMultiStep(true);
  }

  const infosOfStatus = {
    NOT_OK: {
      icon: <EndStepStatus animType="error" />,
      title: 'Não foi possível excluir Chave Pix',
      singleButtonText: 'Fechar',
      singleButtonAction: () => setOpenModalMultiStep(false),
      doubleButtonTextOne: 'Cadastrar uma nova',
      buttonIcon: <RandomKeySVG fill={colors.black} />,
      doubleButtonOneAction: createAnotherKey,
      description:
        'Identificamos que essa chave está atrelada a outra instituição bancária. Para utilizar será necessário remover da mesma e gerar uma nova chave no Capitual.',
    },
    OK: {
      icon: <EndStepStatus animType="success" />,
      title: 'Chave Pix excluída com sucesso!',
      singleButtonText: 'Fechar',
      singleButtonAction: () => setOpenModalMultiStep(false),
      doubleButtonTextOne: 'Cadastrar uma nova',
      buttonIcon: <RandomKeySVG fill={colors.black} />,
      doubleButtonOneAction: createAnotherKey,
      description:
        'Sua Chave aleatória foi excluída e não poderá mais recuperá-la. Continue gerenciando suas Chaves na área Pix. ',
    },
  };

  if (!statusOfCreation) return <p>give the statusOfCreation ex: OK</p>;

  return (
    <>
      <ModalMultiStepHeader />
      <div className="delete-pix-body">
        <div className="justify-items-center">
          {infosOfStatus[statusOfCreation].icon}
          <Text.Heading1Bold
            textAlign="center"
            marginBottom={25}
            marginTop={25}
          >
            {infosOfStatus[statusOfCreation].title}
          </Text.Heading1Bold>
          <Text.Heading4 textAlign="center">
            {infosOfStatus[statusOfCreation].description}
          </Text.Heading4>
        </div>
      </div>
      <ModalMultiStepBottom
        singleButtonText={infosOfStatus[statusOfCreation].singleButtonText}
        singleButtonWidth="1"
        singleButtonAction={infosOfStatus[statusOfCreation].singleButtonAction}
        doubleButtonTextOne={
          <div className="double-button-one-container">
            {infosOfStatus[statusOfCreation].buttonIcon}
            <Text.Heading5>
              {infosOfStatus[statusOfCreation].doubleButtonTextOne}
            </Text.Heading5>
          </div>
        }
        doubleButtonOneAction={
          infosOfStatus[statusOfCreation].doubleButtonOneAction
        }
      />
    </>
  );
}

export default DeletePixStatus;
