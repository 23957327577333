import * as Text from '~styles/text';

import { useEffect, useState } from 'react';

import { AuthValidateProps } from './AuthValidate.types';
import { InputPin } from '..';
import { When } from 'react-if';
import colors from '~styles/colors';

const AuthValidate = (props: AuthValidateProps) => {
  const {
    authTypes,
    firstInputLabel,
    secondInputLabel,
    twoFaErrorMessage,
    pinErrorMessage,
    onChangeCodes,
    showTwoFaRecoveryOption,
    setRecovery,
  } = props;

  const [elementTwoFactor, setElementTwoFactor] = useState<any>();
  const [secondLabel, setSecondLabel] = useState(secondInputLabel || '2-FA');
  const [codes, setCodes] = useState({
    pin: '',
    twoFa: '',
  });

  const switchToRecoveryLabel = () => {
    if (secondLabel === 'Código de Recuperação') {
      setSecondLabel(secondInputLabel || '2-FA');
      if (setRecovery) setRecovery(false);
    } else {
      setSecondLabel('Código de Recuperação');
      if (setRecovery) setRecovery(true);
    }
  };

  useEffect(() => {
    onChangeCodes(codes.pin, codes.twoFa);
  }, [codes]);

  useEffect(() => {
    setElementTwoFactor(document.getElementsByTagName('input')[4]);
  }, []);

  return (
    <>
      <When condition={authTypes.includes('PIN')}>
        <Text.Heading5 marginBottom={10} marginTop={25}>
          {firstInputLabel || 'PIN'}
        </Text.Heading5>

        <InputPin
          autoFocus
          size={4}
          secret
          onChange={(pin) => {
            setCodes((prev) => ({ ...prev, pin }));
          }}
          onFinish={(pin) => {
            if (authTypes.includes('2-FA')) {
              elementTwoFactor.focus();
            }
            setCodes((prev) => ({ ...prev, pin }));
          }}
          error={!!pinErrorMessage && pinErrorMessage?.length > 1}
        />

        {pinErrorMessage && (
          <Text.Heading5 marginTop={5} color={colors.danger}>
            {pinErrorMessage}
          </Text.Heading5>
        )}
      </When>

      <When condition={authTypes.includes('2-FA')}>
        <Text.Heading5 marginBottom={10} marginTop={25}>
          {secondLabel}
        </Text.Heading5>

        <InputPin
          size={6}
          secret
          onChange={(twoFa) => {
            setCodes((prev) => ({ ...prev, twoFa }));
          }}
          onFinish={(twoFa) => {
            setCodes((prev) => ({ ...prev, twoFa }));
          }}
          error={!!twoFaErrorMessage && twoFaErrorMessage?.length > 1}
        />

        <When condition={showTwoFaRecoveryOption}>
          <Text.Heading5
            style={{ cursor: 'pointer' }}
            onClick={switchToRecoveryLabel}
            alignSelf="flex-end"
            marginTop={5}
            color={colors.primary}
          >
            {secondLabel === 'Código de Recuperação'
              ? 'Usar 2-FA'
              : 'Usar Código de Recuperação'}
          </Text.Heading5>
        </When>

        {twoFaErrorMessage && (
          <Text.Heading5 marginTop={5} color={colors.danger}>
            {twoFaErrorMessage}
          </Text.Heading5>
        )}
      </When>
    </>
  );
};

export default AuthValidate;
