const BlockedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { fill = 'none', stroke = '#272937', height = 19, width = 18 } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 18 19">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0.00195312C4.02944 0.00195312 0 4.03139 0 9.00195C0 13.9725 4.02944 18.002 9 18.002C13.9706 18.002 18 13.9725 18 9.00195C18 4.03139 13.9706 0.00195312 9 0.00195312ZM2 9.00195C2 5.13596 5.13401 2.00195 9 2.00195C10.5723 2.00195 12.0236 2.52036 13.1922 3.39557L3.39362 13.1941C2.5184 12.0255 2 10.5743 2 9.00195ZM4.80783 14.6083C5.97641 15.4835 7.42766 16.002 9 16.002C12.866 16.002 16 12.8679 16 9.00195C16 7.42962 15.4816 5.97836 14.6064 4.80978L4.80783 14.6083Z"
        fill={stroke}
      />
    </svg>
  );
};

export default BlockedIcon;
