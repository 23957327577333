import * as Style from '../DisableTwoFa.styles';
import * as Text from '~styles/text';

import DialogBottom from '~components/Dialog/DialogBottom.component';
import React from 'react';
import ShinyHappyOutdoorsSVG from '~assets/svg/shinyHappyOutdoors';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import EndStepStatus from '~components/EndStepStatus';

const SuccessDisableTwoFa: React.FC = () => {
  const { setOpenModalMultiStep, setSection } = useActionsOnModalMultiStep();

  const openEnableTwoFa = () => {
    setOpenModalMultiStep(false);

    setTimeout(() => {
      setSection('enableTwoFaModals');

      setOpenModalMultiStep(true);
    }, 500);
  };

  return (
    <>
      <div className="container-success-disable-two-fa">
        <EndStepStatus animType="success" />
        <Text.Heading1Bold marginTop={25}>
          Seu 2FA foi desabilitado!
        </Text.Heading1Bold>
        <Text.Heading5 marginTop={20}>
          Poxa, ficamos tristes que agora você está sem sua dupla camada de
          proteção. Mas é fácil resolver isso, é só habilitar novamente!
        </Text.Heading5>
      </div>

      <ModalMultiStepBottom
        singleButtonWidth="10%"
        singleButtonText="Concluir"
        doubleButtonTextOne="Habilitar novamente"
        singleButtonAlignment="flex-end"
        singleButtonAction={() => setOpenModalMultiStep(false)}
        doubleButtonOneAction={openEnableTwoFa}
      />
    </>
  );
};

export default SuccessDisableTwoFa;
