import styled from 'styled-components';

export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .divider-notif {
    border-width: 0.5px 0px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.05);
    margin-bottom: 40px;
    margin-top: 50px;
  }
`;

export const TypeNotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;

  .label-container {
    display: flex;
    flex-direction: column;
    width: 70%;
  }

  .icon-title-gap {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
  }

  @media (max-width: 940px) {
    flex-direction: column;

    .label-container {
      max-width: 100%;
    }
  }
`;

export const InputDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;

  .detail-description {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

export const ContainerLoading = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
