import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;

  .header {
    padding: 0px 20px 26px 20px;
    border-bottom: 1px solid rgba(39, 41, 55, 0.1);
  }

  .no-notifications {
    height: calc(100% - 88px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    text-align: center;
  }

  .notifications-row {
    height: calc(100% - 88px);
    overflow-y: auto;
  }

  .clearNotifWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin: 24px 0px;
  }
`;
