import * as Style from './ItensMenu.styles';
import * as Text from '~styles/text';

import { Case, Else, If, Switch, Then, When } from 'react-if';

import Approved from '~assets/images/icons/kyc/partners/approved.svg';
import ArrowRightIcon from '~assets/images/icons/select-arrow-right.svg';
import Denied from '~assets/images/icons/kyc/partners/denied.svg';
import { ItensMenuProps } from './ItensMenu.types';
import Pending from '~assets/images/icons/kyc/partners/pending.svg';
import Submitted from '~assets/images/icons/kyc/partners/submitted.svg';
import TipIcon from '~assets/images/icons/question.svg';
import { colors } from '~styles';
import { useTranslation } from 'react-i18next';

const ItensMenu: React.FC<ItensMenuProps> = (props) => {
  const { t } = useTranslation();

  const { subtitleStatus } = props;

  return (
    <Style.Container
      onClick={props.onClick}
      marginTop={props.containerMarginTop}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          marginTop: 5,
        }}
      >
        {/* ICON AND BADGED STATUS */}
        <Style.IconContainer>
          <div className="right-container">
            {props.icon}
            <Style.ContainerStatus>
              <Switch>
                <Case
                  condition={['AWAITING', 'PENDING']?.includes(
                    String(props.status)
                  )}
                >
                  <Submitted />
                </Case>
                <Case
                  condition={
                    ['DONE', 'VERIFIED', 'APPROVED']?.includes(
                      String(props.status)
                    ) && !props.needReview
                  }
                >
                  <Approved />
                </Case>
                <Case
                  condition={[
                    'FAILED',
                    'REPROVED',
                    'DENIED',
                    'UNFINISHED',
                  ]?.includes(String(props.status))}
                >
                  <Denied />
                </Case>
                <Case
                  condition={
                    ['AWAITING', 'PENDING', 'WAITINGTOSEND']?.includes(
                      String(props.status)
                    ) || props.needReview
                  }
                >
                  <Pending />
                </Case>
              </Switch>
            </Style.ContainerStatus>
          </div>
        </Style.IconContainer>
        {/* STATUS AND SUBTITLE */}
        <div className="right-container">
          <div className="column">
            <Text.Heading4
              marginLeft={5}
              color={props.titleColor || colors.black}
            >
              {props.title}
            </Text.Heading4>
            <If condition={!!props.subtitle}>
              <Then>
                <Text.Small marginLeft={5} color={colors.grayMedium}>
                  {props.subtitle}
                </Text.Small>
              </Then>
              <Else>
                <Text.Small marginLeft={5} color={colors.grayMedium}>
                  {props.expanded
                    ? props?.item?.documentNumber
                    : subtitleStatus}
                </Text.Small>
              </Else>
            </If>
          </div>
        </div>
        {/* RIGHT SIDE BUTTONS */}
        <When
          condition={
            !['DONE', 'VERIFIED', 'APPROVED']?.includes(String(props.status)) ||
            props.isOwner
          }
        >
          <When condition={!!props.rightIcon}>
            <div style={{ cursor: 'pointer' }} onClick={props.rightIconAction}>
              {props.rightIcon}
            </div>
          </When>
        </When>

        <When condition={props.arrowIcon}>
          <ArrowRightIcon />
        </When>
      </div>
      <When condition={!!props.expanded}>
        <Style.ContainerExpanded>
          <Text.Paragraph marginBottom={10}>
            {props.expandedDescription}
          </Text.Paragraph>
          <When condition={props.expandedTooltipDescription}>
            <Style.Tooltip>
              <div className="mr-10">
                <TipIcon />
              </div>
              <Text.Small color={colors.grayMedium}>
                {props.expandedTooltipDescription || ''}
              </Text.Small>
            </Style.Tooltip>
          </When>
          {props.children}
        </Style.ContainerExpanded>
      </When>
    </Style.Container>
  );
};

export default ItensMenu;
