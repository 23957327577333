import { ClientError, request as originalRequest } from 'graphql-request';
import { RequestDocument, Variables } from 'graphql-request/dist/types';
import {
  browserInfo,
  fingerprint,
  getLanguage,
  getToken,
} from '~helpers/device';

import { ENDPOINT } from '../config';
import { QueryClient } from 'react-query';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { v4 } from 'uuid';

export {
  QueryClientProvider,
  useQuery,
  useInfiniteQuery,
  useMutation,
} from 'react-query';

export { gql, ClientError } from 'graphql-request';

export const parseError = (error: ClientError) =>
  error?.response?.errors ? error?.response.errors[0].message : 'Error';

export async function request<T = any, V = Variables>(
  document: RequestDocument,
  variables?: V,
  requestHeaders?: RequestInit['headers']
): Promise<T> {
  const token = `Bearer ${getToken()}` || '';
  const device = browserInfo().os.name || 'unknown';
  const deviceId = fingerprint();
  const locale = getLanguage();
  const clientInfo = browserInfo().ua;
  const idempotencyKey = v4();

  const headers = {
    Authorization: token,
    clientInfo,
    device,
    deviceId,
    locale,
    'idempotency-key': idempotencyKey,
    ...requestHeaders,
  };
  return originalRequest(ENDPOINT, document, variables, headers);
}
