import { gql, request, useQuery } from '~reactQuery/index';

import { DecodeData } from '~reactQuery/types/pix.types';
import { PIX_QUERY_TRANSFER } from '~src/constants/pix';

interface DecodeQrCodePixInput {
  userOrganizationId: number;
  qrCode: string;
  documentNumber: string;
}

export interface DecodeQrCodePixResponse {
  data: {
    key: DecodeData;
    keyType: string;
    e2eId: string;
  };
  messages: Array<string>;
  isValid: boolean;
}

export const useDecodeQrCodePix = (
  params: DecodeQrCodePixInput,
  skip?: boolean
) => {
  const QUERY = gql`
    query DecodeQrCodePix($params: DecodeQrCodePixInput!) {
      decodeQrCodePix(params: $params) {
        data {
          key {
            qrCodeType
            addressingKeyValue
            payment {
              totalValue
            }
            bankName
            holderName
            holderDocument
            accountType
            identificator
          }
          keyType
          e2eId
        }
        messages
        isValid
      }
    }
  `;

  const fetcher = async () => {
    if (skip) return null;
    const response = await request<{
      decodeQrCodePix: DecodeQrCodePixResponse;
    }>(QUERY, { params });
    return response.decodeQrCodePix;
  };

  return useQuery([PIX_QUERY_TRANSFER.DECODE_QR_CODE_PIX], fetcher);
};
