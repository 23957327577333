import * as React from 'react';
import { SVGProps } from 'react';

const WalletBtcSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.157.761h21.686a9.5 9.5 0 0 1 6.688 2.871 9.555 9.555 0 0 1 2.71 6.771v13.194a9.555 9.555 0 0 1-2.71 6.77 9.5 9.5 0 0 1-6.687 2.872H10.157a9.5 9.5 0 0 1-6.688-2.871 9.555 9.555 0 0 1-2.71-6.77V10.402a9.555 9.555 0 0 1 2.71-6.77A9.5 9.5 0 0 1 10.157.76Z"
      fill="#F3F4F9"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.241 21.712H33.99a4.927 4.927 0 0 1-3.448-1.421 4.955 4.955 0 0 1-.13-6.922 4.93 4.93 0 0 1 3.392-1.55h7.435M34.811 16.645h-.558"
      stroke="#E2E4EE"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.506 17.575a2.684 2.684 0 0 0 1.86-2.456c.122-1.731-1.756-2.779-3.306-3.24l.518-2.537-1.558-.32-.514 2.51-1.044-.216.511-2.51-1.56-.321-.51 2.513-3.138-.648-.33 1.624 1.168.24c.523.108.747.317.661.741l-1.424 6.956c-.118.58-.443.699-.755.634L8.893 20.3l-.337 1.653 3.03.624-.526 2.57 1.57.322.525-2.57 1.046.215-.526 2.57 1.526.313.524-2.57.334.068c3.205.661 4.596-.695 5.034-2.841a2.849 2.849 0 0 0-1.583-3.082l-.004.003Zm-4.396-4.533 1.113.229c.778.16 2.294.609 2.024 1.882a1.609 1.609 0 0 1-1.71 1.382l-2.059-.423.632-3.07Zm.082 8.39v-.011l-1.72-.354.684-3.35 2.002.406c.469.097 2.194.615 1.94 1.865-.256 1.25-1.232 1.791-2.896 1.447l-.01-.003Z"
      fill="#FFB251"
    />
  </svg>
);

export default WalletBtcSVG;
