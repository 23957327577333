import { gql, request, useQuery } from '~reactQuery';

export interface GetSlipByAuthCodeResponse {
  getSlipByAuthCode: {
    data: {
      digitable: string;
      emissionDate: string;
      payer: {
        name: string;
        tradeName: string;
      };
    };
    isValid: boolean;
    messages: string[];
  };
}

export interface GetSlipByAuthCodeInput {
  userOrganizationId: number;
  authCode: string;
}

export const useGetSlipByAuthCode = (params: GetSlipByAuthCodeInput) => {
  const QUERY = gql`
    query GetSlipByAuthCode($params: GetSlipByAuthCodeInput!) {
      getSlipByAuthCode(params: $params) {
        data
        messages
        isValid
      }
    }
  `;

  const fetcher = async () => {
    if (!params.userOrganizationId) return null;

    const response = await request<GetSlipByAuthCodeResponse>(QUERY, {
      params,
    });

    return response.getSlipByAuthCode.data;
  };

  return useQuery(['useGetSlipByAuthCode', params.authCode], fetcher);
};
