import React, { useState } from 'react';
import * as Text from '~styles/text';
import AlertModal from '~components/AlertModal';
import { BigInput } from '~components/Inputs';
import { SuccessToast } from '~components/Toast';
import { getSelectedUserOrganization } from '~helpers/device';
import { useWalletsModify } from '~reactQuery/mutations/wallet/useWalletsModify';
import { ModalWalletProps } from '../CapAccountAndWallets.types';

const EditWalletName = ({ setOpenModal, wallet }: ModalWalletProps) => {
  const [walletName, setWalletName] = useState('');

  const { mutateAsync: modifyWallet, isLoading: loadingEditWalletName } =
    useWalletsModify();
  const userOrganizationId = getSelectedUserOrganization();

  async function modifyWalletAction(name: string) {
    const oldName = wallet.walletName;

    try {
      await modifyWallet({
        walletAddress: wallet.walletAddress,
        name,
        userOrganizationId: Number(userOrganizationId),
        data: {
          isOwnerInfoPublic: Boolean(wallet.isOwnerInfoPublic),
          isPublic: Boolean(wallet.isPublic),
          isFavorite: Boolean(wallet.isFavorite),
        },
      });

      setOpenModal(false);

      SuccessToast({
        title: 'Carteira renomeada com sucesso!',
        subtitle: `A sua carteira ${oldName} agora se chama ${name}.`,
        autoCloseEnable: true,
      });
    } catch (error) {
      // console.log(error);
    }
  }

  return (
    <AlertModal
      setOpenAlert={setOpenModal}
      primaryButtonAction={() => setOpenModal(false)}
      secondaryButtonAction={() => modifyWalletAction(walletName)}
      primaryButtonText="Cancelar"
      secondaryButtonText="Renomear"
      secondaryButtonColor="secondary"
      paddingVertical={20}
      noBorderTop
      disableSecondaryButton={walletName === ''}
      buttonLoading={loadingEditWalletName}
      title="Editar título da carteira"
      bodyComponent={
        <>
          <Text.Heading5>
            Escolha uma descrição que represente a funcionalidade desta carteira
            (ex. Investimentos, Viagem).
          </Text.Heading5>

          <div className="mt-20">
            <BigInput
              paddingBottom={64}
              placeHolder="Nome Completo"
              value={walletName}
              onChange={(e) => setWalletName(e.target.value)}
            />
          </div>
        </>
      }
    />
  );
};

export default EditWalletName;
