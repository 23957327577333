import * as React from 'react';

import { SVGProps } from 'react';

const RecipeSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    fill={props.fill || 'none'}
    {...props}
  >
    <path
      d="M14 15V3a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12M5 4.5h6M5 8h3m0 5.833V15m3-1.167V15m-6-1.167V15"
      stroke={props.stroke || '#0F90FF'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RecipeSVG;
