import * as Style from './CardOrganization.styles';
import * as Text from '~styles/text';

import { Else, If, Then, When } from 'react-if';

import AdminKingSVG from '~assets/svg/adminKing';
import { CardOrganizationProps } from './CardOrganization.types';
import Chip from '~components/Chip';
import { UserPic } from '..';
import colors from '~styles/colors';
import { transparentize } from 'polished';
import { useGetOrganizationPartners } from '~reactQuery/queries/user/useGetOrganizationPartners';
import { getUserId } from '~helpers/device';
import { InvitedUsersResp } from '~reactQuery/types/business.types';

const CardOrganization = (props: CardOrganizationProps) => {
  const {
    name,
    cardImage,
    noBorder,
    inReview,
    onClick,
    svgImage,
    isAdmin,
    disable,
    chipText,
    organizationId,
  } = props;

  const noImg = cardImage ? cardImage.length < 1 : true;

  const { data: registeredUsers } = useGetOrganizationPartners();
  const userId = getUserId();

  const getUrlAvatarImage = registeredUsers?.reduce(
    (avatarUrl: string | null, user: InvitedUsersResp) => {
      if (!organizationId) return null;

      if (avatarUrl) {
        return avatarUrl;
      }

      if (user.id === Number(userId)) {
        const userOrganization = user.userOrganizations.find(
          (userOrganizationItem) => {
            return (
              userOrganizationItem.organizationsId === Number(organizationId) &&
              userOrganizationItem.organizations.image
            );
          }
        );

        if (userOrganization) {
          return userOrganization.organizations.image;
        }
      }

      return avatarUrl;
    },
    null
  );

  return (
    <Style.Container noImg={noImg} noBorder={!!noBorder} inReview={inReview}>
      <div className="card" onClick={noBorder ? () => { } : onClick}>
        <div className="logo-container">
          <If condition={noImg}>
            <Then>
              <If condition={!!svgImage}>
                <Then>
                  <UserPic
                    svgIcon={svgImage}
                    fontSize={2.625}
                    name={props?.name?.[0]}
                    height={75}
                    width={75}
                    borderRadius={37.5}
                    disabledCursor={inReview}
                    avatarUrl={getUrlAvatarImage || cardImage}
                  />
                </Then>

                <Else>
                  <UserPic
                    fontSize={2.625}
                    name={props?.name?.[0]}
                    height={75}
                    width={75}
                    borderRadius={37.5}
                    avatarUrl={getUrlAvatarImage}
                  />
                </Else>
              </If>
            </Then>

            <Else>
              <img className="org-logo" src={cardImage} alt="org-icon" />
            </Else>
          </If>

          <div className="admin-icon">
            <When condition={isAdmin}>
              <AdminKingSVG />
            </When>
          </div>
        </div>
      </div>
      <div className="name-container">
        <Text.Heading4 marginTop={noBorder ? 10 : 15} textAlign="center">
          {name}
        </Text.Heading4>

        <When condition={disable}>
          <Text.Small color={colors.danger}>Acesso desabilitado </Text.Small>
        </When>

        <When condition={!!chipText}>
          <Chip
            marginTop={15}
            bgColor={transparentize(0.95, colors.primary)}
            textComponent={
              <Text.Heading5 color={colors.primary}>{chipText}</Text.Heading5>
            }
          />
        </When>
      </div>
    </Style.Container>
  );
};

export default CardOrganization;
