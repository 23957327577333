import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';

import blackLogo from '~assets/images/logo-black.png';
import colors from '~styles/colors';
import dayjs from 'dayjs';
import { formatCurrencyFn } from '~helpers/format/currency';

interface RefundReceiptProps {
  authCode?: string;
  accountDetails: any;
  refundDetails?: any;
}

const styles = StyleSheet.create({
  body: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 15,
    fontWeight: 400,
  },
  title: {
    fontSize: 24,
    textAlign: 'left',
    fontWeight: 700,
    lineHeight: '120%',
  },
  logo: {
    width: 125,
    height: 33,
  },
  container: {
    width: '100%',
    marginTop: 30,
    padding: '25px 25px 25px 25px',
  },
  date: {
    marginTop: 15,
    marginBottom: 40,
    fontWeight: 400,
    fontSize: 16,
    color: colors.grayMedium,
  },
  valueContainer: {
    flexDirection: 'column',
  },
  divider: {
    width: 50,
    border: '1px solid #C3C6DB',
    marginBottom: 30,
    marginTop: 30,
  },
  footer: {
    backgroundColor: colors.mainGray,
    padding: 20,
    borderRadius: 15,
    bottom: 15,
    position: 'absolute',
    width: '90%',
  },
  label: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '100%',
    color: colors.grayRegular,
    marginBottom: 10,
  },
  link: {
    color: colors.primary,
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '150%',
    marginTop: 12,
    marginBottom: 5,
    textAlign: 'center',
  },
  containerImg: {
    height: 33,
    top: 30,
    left: 25,
    width: '100%',
    zIndex: 99,
  },
  transferValue: {
    fontWeight: 700,
    fontSize: 22,
    lineHeight: '100%',
    marginBottom: 7,
  },
  textNames: {
    fontWeight: 700,
    fontSize: 18,
    marginBottom: 7,
  },
  bankNameText: {
    fontWeight: 400,
    fontSize: 15,
    marginBottom: 20,
  },
  displayRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  marginLeft: {
    marginLeft: 20,
  },
  marginBottom: {
    marginBottom: 25,
  },
  colorGrayMedium: {
    color: colors.grayMedium,
  },
});

export const RefundReceipt = ({
  refundDetails,
  accountDetails,
  authCode,
}: RefundReceiptProps) => {
  const dataToDisplay = {
    value: refundDetails?.data?.value,
    receiverBranch: accountDetails?.branch,
    receiverAcc: accountDetails?.account,
    receiverName: accountDetails?.name || '',
    date: refundDetails?.timestamp
      ? dayjs(refundDetails?.timestamp).format('DD/MM/YYYY h:mm A')
      : '--/--/--',
    e2eId: refundDetails?.data?.requisite?.key?.e2eId,
  };

  return (
    <Document>
      <Page wrap style={styles.body} size={{ width: 375, height: 1048 }}>
        <View fixed style={styles.containerImg}>
          <Image src={blackLogo} style={styles.logo} />
        </View>
        <View style={styles.container}>
          <View>
            <Text style={styles.title}>Comprovante de Reembolso</Text>
          </View>
          <Text style={styles.date}>{dataToDisplay.date}</Text>

          <View style={styles.valueContainer}>
            <Text style={styles.label}>Valor</Text>
            <Text style={styles.transferValue}>
              {formatCurrencyFn(
                'BRL',
                dataToDisplay?.value,
                false,
                false,
                true
              )}
            </Text>
          </View>

          <View style={styles.divider} />
          <View>
            <Text style={styles.label}>Favorecido</Text>
            <Text style={styles.textNames}>{dataToDisplay?.receiverName}</Text>
          </View>
          <View>
            <Text style={styles.label}>Instituição</Text>
            <Text style={styles.bankNameText}>
              Acesso Soluções de Pagamento S. A.
            </Text>
          </View>
          <View style={styles.displayRow}>
            <View>
              <Text style={styles.label}>Agência</Text>
              <Text>{dataToDisplay?.receiverBranch}</Text>
            </View>
            <View style={styles.marginLeft}>
              <Text style={styles.label}>Conta</Text>
              <Text>{dataToDisplay?.receiverAcc}</Text>
            </View>
          </View>
        </View>

        <View fixed style={styles.footer}>
          <View style={styles.marginBottom}>
            <Text style={styles.label}>Protocolo</Text>
            <Text>{authCode}</Text>
          </View>

          <View style={styles.marginBottom}>
            <Text style={styles.label}>e2e Id</Text>
            <Text>{dataToDisplay?.e2eId}</Text>
          </View>

          <Text style={styles.link}>help.capitual.com</Text>
        </View>
      </Page>
    </Document>
  );
};
