import * as React from 'react';
import { SVGProps } from 'react';

const SecuritySVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_14687_112080)">
      <rect
        opacity="0.1"
        width={props.width || 48}
        height={props.height || 48}
        fill={props.fill || '#0F90FF'}
      />
      <path
        d="M24 10L10.036 16.2759C10.036 16.2759 8.59146 38 24 38C39.4085 38 37.964 16.2759 37.964 16.2759L24 10Z"
        stroke={props.color || '#026FF4'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_14687_112080">
        <rect width="48" height="48" rx="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SecuritySVG;
