import * as Style from './PartnersSteps.styles';
import * as Text from '~styles/text';

import { Else, If, Then } from 'react-if';

import BlueInfo from '~assets/images/icons/kyc/blue-info.svg';
import { GenericButton } from '~components';
import { StartModalProps } from '../Kyc.types';
import { breakpoints } from '~styles/metrics';
import { infoArray } from '~data/kyc';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '~hooks/windowDimension';

export const StartModal = ({ gotToModalStep }: StartModalProps) => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  return (
    <Style.Container>
      <div className="content">
        <div>
          <BlueInfo style={{ borderRadius: 15 }} />
        </div>

        <Text.Heading1Medium marginTop={10} marginBottom={10}>
          {t('Adicionar Sócio')}
        </Text.Heading1Medium>

        <Text.Heading5 marginBottom={30} lineHeight={140}>
          {t(
            'Vamos precisar de algumas informações para adição de um novo sócio:'
          )}
        </Text.Heading5>

        <Style.HowItWorksContainer>
          {infoArray.map((item) => (
            <div className="how-it-works-item" key={item.title}>
              <div className="how-it-works-icon">{item.image}</div>
              <div className="how-it-works-text-container">
                <If condition={breakpoints.phone < width}>
                  <Then>
                    <Text.Small
                      textAlign="center"
                      marginTop={10}
                      marginBottom={30}
                    >
                      {t(item.title)}
                    </Text.Small>
                  </Then>
                  <Else>
                    <Text.Heading5 marginLeft={20}>
                      {t(item.title)}
                    </Text.Heading5>
                  </Else>
                </If>
              </div>
            </div>
          ))}
        </Style.HowItWorksContainer>

        <Text.Heading5 marginBottom={10} lineHeight={140}>
          {t(
            'Se você não tiver esses dados em mãos, não tem problema, enviaremos um link para o próprio sócio preencher essa parte do formulário.'
          )}
        </Text.Heading5>
      </div>
      <div className="footer">
        <GenericButton
          buttonType="secondary"
          onClick={() => gotToModalStep('partnerName')}
        >
          Próximo
        </GenericButton>
      </div>
    </Style.Container>
  );
};
