import * as Style from './Activities.styles';
import * as Text from '~styles/text';
import {
  Container,
  Dialog,
  EmptyTransactions,
  GenericButton,
  LoadingSpinner,
  ModalSideBar,
  RoundButtonIcon,
} from '~components/index';
import {
  getHideCpfCnpj,
  getSelectedUserOrganization,
  setHideCpfCnpj,
} from '~helpers/device';
import { useEffect, useMemo, useState } from 'react';
import Chip from '~components/Chip';
import CloseSVG from '~assets/svg/close';
import ClosedEyeSVG from '~assets/images/icons/transactions/closedEye';
import DownloadSVG from '~assets/svg/download';
import FilterActivitySideBarModal from './FilterActivitySideBarModal/FilterActivitySideBarModal.component';
import FilterSVG from '~assets/svg/filter';
import OpenedEyeSVG from '~assets/images/icons/transactions/openedEye';
import Statement from '~components/StatementRowList/Statement.component';
import colors from '~styles/colors';
import dayjs from 'dayjs';
import { transparentize } from 'polished';
import {
  useGetStatement,
  Statement as StatementType,
} from '~reactQuery/queries/bank/useGetStatement';
import { useOrgInfo } from '~reactQuery/queries/business/useGetBusiness';
import useWindowDimensions from '~hooks/windowDimension';
import { FilterStateParams, FiltersChip } from './Activities.types';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import { usePermission } from '~helpers/permissionGate';
import { useNavigate } from 'react-router-dom';
import { Else, If, Then } from 'react-if';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';

const Activities: React.FC = () => {
  const [params, setParams] = useState<FilterStateParams>();
  const [openModalSideBar, setOpenModalSideBar] = useState(false);
  const [openPeriodDialog, setOpenPeriodDialog] = useState(false);
  const hideDoc = getHideCpfCnpj() === 'true';
  const [hideCpfCnpj, setHideCpfCnpjState] = useState(hideDoc);
  const { granted: ActivitiesPageGranted } = usePermission('activitiesPage');
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { openModalSideBar: isOpenDetailsActivitySideBar } =
    useOpenModalSideBar();

  const to = params?.date?.period?.to || '';
  const from = params?.date?.period?.from || '';

  const userOrganizationId = getSelectedUserOrganization();

  const { data: businessInfos } = useOrgInfo({
    userOrganizationId: Number(userOrganizationId),
  });

  const { data: wallets, isLoading: loadingWallets } = useGetWallets({
    prefCurrency: 'BRL',
    flag: 'list',
    userOrganizationId: Number(userOrganizationId),
  });

  const {
    data: statementListPages,
    isLoading: isLoadingStatements,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
  } = useGetStatement({
    to: to ? dayjs(to).add(1, 'day').format('YYYY-MM-DD') : '',
    from: from ? dayjs(from).format('YYYY-MM-DD') : '',
    perPage: 50,
  });

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } =
      event.target as HTMLDivElement;
    const isAtBottom = scrollHeight - scrollTop - 10 < clientHeight;

    if (!statementListPages) {
      return;
    }

    const lastPage =
      statementListPages.pages[statementListPages.pages.length - 1];
    const hasMorePages = lastPage?.length !== undefined && lastPage.length > 0;

    if (isAtBottom && hasMorePages && !isFetchingNextPage) {
      await fetchNextPage();
    }
  };

  const handleHideCpfCnpj = () => {
    setHideCpfCnpj(!hideCpfCnpj);
    setHideCpfCnpjState(!hideCpfCnpj);
  };

  const openFilterModal = () => setOpenModalSideBar(true);

  const closePeriodTime = () => {
    setParams((prev) => ({
      ...prev,
      date: undefined,
    }));
  };

  const removeFromDirection = (label: string) => {
    setParams((prev) => ({
      ...prev,
      directions: prev?.directions?.filter((d) => d.title !== label),
    }));
  };

  const filtersChip = [
    { title: 'Todas', action: () => removeFromDirection('Todas') },
    {
      title: 'Entradas',
      action: () => removeFromDirection('Entradas'),
    },
    { title: 'Saídas', action: () => removeFromDirection('Saídas') },
  ];

  // const closeWalletList = () => {
  //   setParams((prev: any) => ({
  //     ...prev,
  //     walletsToShow: undefined,
  //   }));
  // };

  const directionList: FiltersChip[] = params?.directions || [];

  const labelMoth = params?.date?.period?.from
    ? [
        {
          title: `${dayjs(params?.date.period.from, 'YYYY/MM/DD').format(
            'DD/MM/YYYY'
          )}  - ${dayjs(params?.date.period.to, 'YYYY/MM/DD').format(
            'DD/MM/YYYY'
          )} `,
          action: closePeriodTime,
        },
      ]
    : [];

  // const labelDate =
  //   params?.walletsToShow?.length >= 1
  //     ? [
  //       {
  //         title: `${params.walletsToShow.length} Carteiras`,
  //         action: closeWalletList,
  //       },
  //     ]
  //     : [];

  function filterByDirectList(
    statement: StatementType,
    directListMap?: string
  ) {
    const txDirection = statement?.direction;

    if (!directListMap || directListMap === 'Todas') {
      return true;
    }
    if (txDirection === 1 && directListMap === 'Entradas') {
      return true;
    }
    if (txDirection === -1 && directListMap === 'Saídas') {
      return true;
    }

    return false;
  }

  // function filterByWallet(
  //   statement: StatementActivities,
  //   walletsToShow: string[]
  // ) {
  //   if (!walletsToShow || walletsToShow?.length === 0) {
  //     return statement;
  //   }

  //   return statement;
  // }

  function statementFilter(
    statement?: StatementType[],
    parameters?: FilterStateParams
  ) {
    // const { directions, walletsToShow } = params || {};
    const { directions } = parameters || {};

    const directListMap = directions?.[0]?.title;

    if (!statement || !Array.isArray(statement)) {
      return [];
    }

    return statement.filter((s) => filterByDirectList(s, directListMap));

    // return statement.filter((s) => {
    //   const filterByDirectListMap = filterByDirectList(s, directListMap);

    //   console.log("filterByDirectListMap", filterByDirectListMap)
    //   // const filterByWalletsToShow = filterByWallet(s, walletsToShow);
    //   return (
    //     // filterByDirectListMap === filterByWalletsToShow && filterByDirectListMap
    //     filterByDirectListMap
    //   );
    // });
  }

  const handleSeeFullReceipt = () => {
    if (!from || !to) {
      setOpenPeriodDialog(true);
      return;
    }

    const formatedFrom = dayjs(from).format('YYYY-MM-DD') || '';
    const formatedTo = dayjs(to).format('YYYY-MM-DD') || '';

    window
      ?.open(
        `/full-receipt?fromDate=${formatedFrom}&toDate=${formatedTo}`,
        '_blank'
      )
      ?.focus();
  };

  const handleGoToSelectPeriod = () => {
    setOpenPeriodDialog(false);
    setOpenModalSideBar(true);
  };

  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  const statement: StatementType[] = useMemo(() => {
    const statementList: StatementType[] =
      statementListPages?.pages?.flat()?.filter(notEmpty) || [];

    return statementList;
  }, [statementListPages, isFetchingNextPage, isFetching]);

  const statementFiltered = useMemo(() => {
    return statementFilter(statement, params);
  }, [statement, params]);

  const isLoading = Boolean(
    !statement ||
      !businessInfos ||
      isLoadingStatements ||
      !statementFiltered ||
      loadingWallets
  );

  useEffect(() => {
    if (!ActivitiesPageGranted) {
      navigate('/');
    }
  }, []);

  return (
    <>
      <Style.Container
        isModalOpen={openModalSideBar || isOpenDetailsActivitySideBar}
        width={width}
      >
        <div className="resize-container">
          <Container>
            <Style.Content>
              <Text.Heading1Medium>Movimentações da Conta</Text.Heading1Medium>

              <div className="filter-list">
                <div className="gap-10 center-all">
                  <div className="button-v2" onClick={openFilterModal}>
                    <FilterSVG />
                    <Text.Small>Filtros</Text.Small>
                  </div>

                  <div className="hide-doc-button">
                    <RoundButtonIcon
                      buttonSize="icon24"
                      onClick={handleHideCpfCnpj}
                    >
                      {hideCpfCnpj ? <ClosedEyeSVG /> : <OpenedEyeSVG />}
                    </RoundButtonIcon>
                  </div>

                  <RoundButtonIcon
                    buttonSize="icon24"
                    onClick={handleSeeFullReceipt}
                  >
                    <DownloadSVG />
                  </RoundButtonIcon>
                </div>

                <Dialog
                  openModal={openPeriodDialog}
                  setOpenModal={setOpenPeriodDialog}
                >
                  <div className="period-dialog">
                    <div>
                      <Text.Heading2>
                        Selecione um período para baixar
                      </Text.Heading2>
                      <Text.Heading5 marginTop={20}>
                        Selecione um período antes de prosseguir.
                      </Text.Heading5>
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <GenericButton
                      buttonType="default"
                      onClick={() => setOpenPeriodDialog(false)}
                    >
                      Cancelar
                    </GenericButton>

                    <GenericButton
                      buttonType="secondary"
                      onClick={handleGoToSelectPeriod}
                    >
                      Ir para Selecionar Período
                    </GenericButton>
                  </div>
                </Dialog>

                <div className="container-chips-filter">
                  {[/* ...labelDate, */ ...labelMoth, ...directionList].map(
                    (filter) => {
                      return (
                        <Chip
                          key={filter.title}
                          textComponent={
                            <Text.Small
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {filter.title}
                            </Text.Small>
                          }
                          rightIcon={<CloseSVG onClick={filter.action} />}
                          borderColor={transparentize(0.85, colors.grayMedium)}
                          height={31}
                        />
                      );
                    }
                  )}
                </div>
              </div>
              <If condition={isLoading}>
                <Then>
                  <div className="container-loading">
                    <LoadingSpinner
                      color={colors.primary}
                      size={60}
                      strokeSize={25}
                    />
                  </div>
                </Then>

                <Else>
                  <If condition={!statementFiltered.length}>
                    <Then>
                      <div className="empty-transactions">
                        <EmptyTransactions noTransactionButton />
                      </div>
                    </Then>

                    <Else>
                      <Statement
                        statementList={statementFiltered}
                        handleScroll={handleScroll}
                        isFetchingNextPage={isFetchingNextPage}
                        hideCpfCnpj={hideCpfCnpj}
                        wallets={wallets}
                      />
                    </Else>
                  </If>
                </Else>
              </If>
            </Style.Content>
          </Container>
        </div>
      </Style.Container>

      <ModalSideBar
        open={openModalSideBar}
        onClose={() => setOpenModalSideBar(false)}
      >
        <FilterActivitySideBarModal
          filtersChip={filtersChip}
          params={params}
          setParams={setParams}
        />
      </ModalSideBar>
    </>
  );
};

export default Activities;
