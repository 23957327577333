import * as React from 'react';

import { SVGProps } from 'react';

const LinkCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="M22 7.693 20.096 8.79l-5.19 3L13 12.884v10.38l1.904 1.096 5.238 3 1.905 1.095 1.905-1.095 5.142-3L31 23.264v-10.38l-1.905-1.095-5.19-3L22 7.693Zm-5.19 13.38v-5.999l5.19-3 5.19 3v6l-5.19 3-5.19-3Z"
      fill="#2A5ADA"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LinkCoinSVG;
