import { gql, request, useMutation } from '~reactQuery/index';
import { CAPACCOUNT_QUERY_KEYS } from '~src/constants/capaccount';
import { queryClient } from '~src/query';

interface PutOrganizationImageParams {
  organizationId: number;
  organizationImage: string;
}

interface OrganizationImageResponse {
  putOrganizationImage: {
    data: {
      imageUrl: string;
    };
    messages: string[];
    isValid: boolean;
  };
}

export const usePutOrganizationImage = () => {
  const mutation = gql`
    mutation PutOrganizationImage($params: OrganizationImageInput) {
      putOrganizationImage(params: $params) {
        data {
          imageUrl
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: PutOrganizationImageParams) => {
    if (!params.organizationId) return null;

    const response = await request<OrganizationImageResponse>(mutation, {
      params,
    });

    return response.putOrganizationImage;
  };

  return useMutation(['usePutOrganizationImage'], fetch, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(['useGetOrganizationPartners']);
      }, 1000);
    }
  });
};
