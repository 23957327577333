import * as Styles from '../Convert.styles';
import * as Text from '~styles/text';

import AuthValidate from '~components/AuthValidate';
import { EXCHANGE_TYPES } from '~src/constants/conversion';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { PinProps } from '../Convert.types';
import { formatCurrencyFn } from '~helpers/format/currency';
import { getSelectedUserOrganization } from '~helpers/device';
import { ClientError, parseError } from '~reactQuery/index';
import { useCurrencyExchange } from '~reactQuery/queries/wallet/useCurrencyExchange';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { usePixTransferScheduleBanking } from '~reactQuery/mutations/conversion/usePixTransferScheduleBanking';
import { useRealizeTransaction } from '~reactQuery/mutations/conversion/useRealizeTransaction';
import { useState } from 'react';
import { useUserAuthTypes } from '~hooks/useUserAuthTypes';
import { LoadingSpinner } from '~components/index';
import colors from '~styles/colors';

function Pin(props: PinProps) {
  const [loading, setLoading] = useState(false);
  const { setCurrentStep, conversionDetails, comingFrom } = props;
  const { auth } = useUserAuthTypes();
  const isComingFromCapWallet = comingFrom === 'capWallet';
  const [hasError, setHasError] = useState({
    pin: '',
    twoFa: '',
  });

  const goBack = () => {
    setCurrentStep('details');
  };

  const { data: userData } = useGetPartner();

  const userOrganizationId = getSelectedUserOrganization();
  const originWalletAddress = conversionDetails.originWallet.walletAddress;
  const destinationWalletAddress =
    conversionDetails.destinationWallet.walletAddress;

  const isCapContaToWallet = originWalletAddress === 'CAPCONTA';

  const isWalletToCapConta = destinationWalletAddress === 'CAPCONTA';

  const isConversionWithCapConta = isCapContaToWallet || isWalletToCapConta;

  // FOR CAPCONTA X WALLET || WALLET X CAPCONTA
  const { mutateAsync: handleCapContaConversion } =
    usePixTransferScheduleBanking();

  // FOR WALLET X WALLET
  const { mutateAsync: handleWalletConversion } = useRealizeTransaction();

  const {
    data: updatedConvertedValueResult,
    isLoading: isUpdatedValueLoading,
    refetch: refetchUpdatedConvertedValue,
  } = useCurrencyExchange(
    {
      amount: conversionDetails.amount,
      fromCurrency: conversionDetails.originWallet.currency.isoCode,
      toCurrency: conversionDetails.destinationWallet?.currency?.isoCode,
      userOrganizationId: Number(userOrganizationId),
    },
    true,
    5000
  );

  const { refetch: refetchExchangeRatio } = useCurrencyExchange({
    amount: '1',
    fromCurrency: conversionDetails.originWallet?.currency?.isoCode || '',
    toCurrency: conversionDetails.destinationWallet?.currency?.isoCode || '',
    userOrganizationId: Number(userOrganizationId),
  });

  const handleMakeConversion = async (pin: string, twoFa?: string) => {
    setHasError({
      pin: '',
      twoFa: '',
    });

    if ((auth.includes('2-FA') && twoFa?.length !== 6) || pin.length !== 4) {
      return;
    }
    setLoading(true);
    const twoFA = twoFa ? { token: twoFa } : {};

    try {
      const { data: convertedValue } = await refetchUpdatedConvertedValue();

      const { data: exchangeRatio } = await refetchExchangeRatio();

      const conversionResults = {
        ...conversionDetails,
        convertedAmount: convertedValue,
      };

      // FOR CAPCONTA X WALLET || WALLET X CAPCONTA
      if (isConversionWithCapConta) {
        await handleCapContaConversion({
          userOrganizationId: Number(userOrganizationId),
          pin,
          ...twoFA,
          type: isCapContaToWallet
            ? EXCHANGE_TYPES.EXCHANGE_CAPCONTA_TO_CAPWALLET
            : EXCHANGE_TYPES.EXCHANGE_CAPWALLET_TO_CAPCONTA,
          currency: conversionDetails.originWallet.currency.isoCode,
          value: conversionDetails.amount,
          walletAddress: isCapContaToWallet
            ? destinationWalletAddress
            : originWalletAddress,
        });
        setLoading(false);
        setCurrentStep('status', {
          conversionStatus: 'OK',
          conversionDetails: {
            ...conversionResults,
            timestamp: new Date().toString(),
            ratio: exchangeRatio,
            userName: userData?.data.registerName,
          },
        });

        return; // if you remove this line you're in trouble
      }

      // FOR WALLET X WALLET
      const authCode = await handleWalletConversion({
        pin,
        ...twoFA,
        amountInCurrency: 'from',
        amount: conversionDetails.amount,
        fromWallet: originWalletAddress,
        toWallet: destinationWalletAddress,
        comments: '',
        userOrganizationId: Number(userOrganizationId),
      });
      setLoading(false);
      setCurrentStep('status', {
        conversionStatus: 'OK',
        conversionDetails: {
          ...conversionResults,
          authCode,
          timestamp: new Date().toString(),
          ratio: exchangeRatio,
          userName: userData?.data.registerName,
        },
      });
    } catch (error) {
      setLoading(false);
      const errorKey = parseError(error as ClientError);

      if (errorKey === 'INVALID_PIN') {
        setHasError((prev) => ({ ...prev, pin: 'Pin inválido' }));
      } else if (errorKey === 'INVALID_2FA_TOKEN') {
        setHasError((prev) => ({ ...prev, twoFa: '2-FA inválido' }));
      } else {
        setCurrentStep('status', { conversionStatus: 'NOT_OK' });
      }
    }
  };

  const renderSubtitle = !isComingFromCapWallet ? (
    <Text.Heading5 display="inline">
      Preencha as confirmações para converter{' '}
      {isUpdatedValueLoading
        ? '0.00'
        : formatCurrencyFn(
            conversionDetails.originWallet.currency.isoCode,
            String(conversionDetails.amount || 0)
          )}{' '}
      em{' '}
      {formatCurrencyFn(
        conversionDetails.destinationWallet.currency.isoCode,
        String(updatedConvertedValueResult || 0)
      )}
      .
    </Text.Heading5>
  ) : (
    <Text.Heading5 display="inline">
      Preencha as confirmações de segurança para finalizar.
    </Text.Heading5>
  );

  const renderTitle = () => {
    if (!isComingFromCapWallet) {
      return `Convertendo ${conversionDetails.originWallet.currency.isoCode} para ${conversionDetails.destinationWallet.currency.isoCode}`;
    }
    return 'Confirmação de compra';
  };

  return (
    <>
      <ModalMultiStepHeader
        title={renderTitle()}
        description={renderSubtitle}
        backButtonAction={goBack}
      />
      <div className="convert-body">
        <Styles.PinContentWrapper>
          {!loading ? (
            <AuthValidate
              authTypes={auth}
              onChangeCodes={(pin, twoFa) => handleMakeConversion(pin, twoFa)}
              pinErrorMessage={hasError.pin}
              twoFaErrorMessage={hasError.twoFa}
            />
          ) : (
            <div className="container-loading">
              <LoadingSpinner
                color={colors.primary}
                size={60}
                strokeSize={25}
              />
            </div>
          )}
        </Styles.PinContentWrapper>
      </div>
    </>
  );
}

export default Pin;
