import * as Styles from '../Convert.styles';
import * as Text from '~styles/text';
import {
  formatCurrencyFn,
  formatExponentialNumber,
  getWalletIcon,
  isExponentialNumber,
  isFiatCurrency,
} from '~helpers/format/currency';
import { useEffect, useState } from 'react';
import ArrowDownIcon from '~assets/images/icons/arrow-down-convert.svg';
import CapAccountSVG from '~assets/svg/capAccount';
import { ChooseWalletsProps } from '../Convert.types';
import InputConvert from '~components/Inputs/InputConvert';
import LoadingSpinner from '~components/LoadingSpinner';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { RoundButtonIcon } from '~components/Buttons';
import WalletBlankSVG from '~assets/images/icons/wallets/WalletBlank';
import colors from '~styles/colors';
import { getSelectedUserOrganization } from '~helpers/device';
import { useCurrencyExchange } from '~reactQuery/queries/wallet/useCurrencyExchange';
import { useGetLimits } from '~reactQuery/queries/wallet/useGetLimits';
import InputCurrency from '~components/Inputs/InputCurrency';
import { Else, If, Then, When } from 'react-if';
import { transformValueBRLinEnglishFormat } from '~validations/stringsValidate';
import CurrencyJS from 'currency.js';

function ChooseWallets(props: ChooseWalletsProps) {
  const {
    setCurrentStep,
    title,
    originWallet,
    destinationWallet,
    conversionDetails,
    comingFrom,
  } = props;

  const isComingFromCapWallet = comingFrom === 'capWallet';
  const isBRL = originWallet?.walletCurrency === 'BRL';
  const originWalletIsoCode = originWallet?.currency?.isoCode;
  const destinationWalletIsoCode = destinationWallet?.currency?.isoCode;
  const isOriginCapAccount = originWallet?.walletAddress === 'CAPCONTA';
  const isDestinationCapAccount =
    destinationWallet?.walletAddress === 'CAPCONTA';

  const userOrganizationId = getSelectedUserOrganization();
  const [valueToExchange, setValueToExchange] = useState(
    conversionDetails?.amount || ''
  );
  const [inputError, setInputError] = useState('');

  const amount = isBRL
    ? transformValueBRLinEnglishFormat(valueToExchange)
    : valueToExchange;

  const { data: exchangeResult, isLoading } = useCurrencyExchange(
    {
      amount,
      fromCurrency: originWalletIsoCode || '',
      toCurrency: destinationWalletIsoCode || '',
      userOrganizationId: Number(userOrganizationId),
    },
    Boolean(valueToExchange)
  );

  const { data: exchangeRatio } = useCurrencyExchange(
    {
      amount: '1',
      fromCurrency: originWalletIsoCode || '',
      toCurrency: destinationWalletIsoCode || '',
      userOrganizationId: Number(userOrganizationId),
    },
    Boolean(originWallet && destinationWallet)
  );

  const {
    data: limits,
    refetch: refetchLimits,
    isLoading: isLoadingLimits,
  } = useGetLimits({
    action: 'exchange',
    currency: originWalletIsoCode || '',
    userOrganizationId: Number(userOrganizationId),
  });

  const isInputDisabled = !(originWallet && destinationWallet);

  const isNextButtonDisabled =
    !valueToExchange ||
    isInputDisabled ||
    !exchangeResult ||
    Boolean(inputError) ||
    originWallet?.walletAddress === destinationWallet?.walletAddress ||
    isLoadingLimits;

  const isExchangeResultLoading = isLoading;

  const onChangeOriginWalletValue = (value: string) => {
    if (isOriginCapAccount) {
      if (Number(value) > (originWallet?.funds || 0) / 100) {
        setInputError('Saldo insuficiente');
        return;
      }
    }
    if (Number(value) > (originWallet?.funds || 0)) {
      setInputError('Saldo insuficiente');
      return;
    }

    if (Number(value) < (limits?.minLimit || 0) && !isDestinationCapAccount) {
      setInputError(
        `Abaixo do valor mínimo ${limits?.minLimit} ${originWalletIsoCode}`
      );
      return;
    }

    if (Boolean(limits?.maxLimit) && (limits?.maxLimit || 0) !== -1) {
      if (Number(value) > (limits?.maxLimit || 0)) {
        setInputError(
          `Valor acima do permitido ${limits?.maxLimit} ${originWalletIsoCode}`
        );
        return;
      }
    }
    setInputError('');
  };

  const onChangeInputValue = (value: any) => {
    setValueToExchange(value);

    onChangeOriginWalletValue(
      isBRL ? transformValueBRLinEnglishFormat(value) : value
    );
  };

  const goToSelectOriginWalletStep = () => {
    setCurrentStep('originWallet');
  };

  const goToSelectDestinationWalletStep = () => {
    setCurrentStep('destinationWallet');
  };

  const goToDetailsStep = async () => {
    setCurrentStep('details', {
      conversionDetails: {
        amount,
        originWallet,
        destinationWallet,
      },
    });
  };

  const conversionPair =
    originWallet && destinationWallet
      ? `${originWalletIsoCode}/${destinationWalletIsoCode}`
      : '-';

  const renderInputConvertLabel = () => {
    if (!originWallet) {
      return 'Converte';
    }

    if (isOriginCapAccount) {
      return `Saldo: ${formatCurrencyFn(
        originWalletIsoCode,
        String(originWallet.funds),
        false,
        false,
        true
      )}`;
    }

    return `Saldo: ${formatCurrencyFn(
      originWalletIsoCode,
      String(originWallet.funds)
    )}`;
  };

  const renderInputReceiveLabel = () => {
    if (!destinationWallet) {
      return 'Recebe';
    }

    if (isDestinationCapAccount) {
      return `Saldo: ${formatCurrencyFn(
        destinationWalletIsoCode,
        String(destinationWallet.funds),
        false,
        false,
        true
      )}`;
    }

    return `Saldo: ${formatCurrencyFn(
      destinationWalletIsoCode,
      String(destinationWallet.funds)
    )}`;
  };

  const renderWalletPrefix = (wallet: typeof originWallet) => {
    if (wallet && isFiatCurrency(wallet.currency.isoCode)) {
      return wallet?.currency.symbol;
    }

    return wallet?.currency.isoCode;
  };

  const renderOriginWalletIcon = () => {
    if (!originWallet || !originWalletIsoCode) {
      return <WalletBlankSVG />;
    }

    if (originWallet.walletAddress === 'CAPCONTA') {
      return <CapAccountSVG />;
    }

    return getWalletIcon(originWalletIsoCode);
  };

  const renderDestinationWalletIcon = () => {
    if (!destinationWallet || !destinationWalletIsoCode) {
      return <WalletBlankSVG />;
    }

    if (isDestinationCapAccount) {
      return <CapAccountSVG />;
    }

    return getWalletIcon(destinationWalletIsoCode);
  };

  const renderInputResult = () => {
    if (!destinationWallet) return '0.00';

    if (!exchangeResult || !valueToExchange) {
      return `${renderWalletPrefix(destinationWallet)} 0.00`;
    }

    if (isExponentialNumber(String(exchangeResult))) {
      const value = formatExponentialNumber(
        String(exchangeResult),
        destinationWallet?.currency.precision || 0
      );
      return `${renderWalletPrefix(destinationWallet)} ${formatCurrencyFn(
        destinationWalletIsoCode,
        String(value),
        true
      )}`;
    }

    return `${renderWalletPrefix(destinationWallet)} ${formatCurrencyFn(
      destinationWalletIsoCode,
      String(exchangeResult),
      true
    )}`;
  };

  const currencyMask = (value: string) => {
    const formatedInput = CurrencyJS(value.replace('.', '').replace(',', ''), {
      fromCents: true,
      precision: 2,
      symbol: '',
      separator: '.',
      decimal: ',',
    }).format();

    return `R$ ${formatedInput}`;
  };

  useEffect(() => {
    if (valueToExchange) {
      if (isBRL) onChangeInputValue(currencyMask(valueToExchange));
      refetchLimits();
    }
  }, []);

  useEffect(() => {
    if (!isLoadingLimits && valueToExchange) {
      if (isBRL) {
        onChangeInputValue(currencyMask(valueToExchange));
      } else {
        onChangeOriginWalletValue(valueToExchange);
      }
    }
  }, [isLoadingLimits]);

  return (
    <>
      <ModalMultiStepHeader
        title={title}
        description={
          !isComingFromCapWallet
            ? 'Crie o seu próprio par de conversão.'
            : 'Selecione com qual carteira você deseja comprar a criptomoeda.'
        }
      />
      <div className="convert-body">
        <Styles.ConversionAreaContainer>
          <div className="convert-row-origin">
            <div className="input-container">
              <If condition={isBRL}>
                <Then>
                  <InputCurrency
                    value={valueToExchange || 'R$ 0,00'}
                    onChange={(event: any) =>
                      onChangeInputValue(event.target.value)
                    }
                    error={inputError}
                    disabled={!originWallet || !originWalletIsoCode}
                    styleInput={{ border: 'none', padding: '0px' }}
                  />
                </Then>
                <Else>
                  <InputConvert
                    placeHolder="0.00"
                    placeholderColor={colors.black}
                    value={valueToExchange}
                    disabled={!originWallet || !originWalletIsoCode}
                    prefix={renderWalletPrefix(originWallet)}
                    precision={Number(originWallet?.currency.precision)}
                    onChange={onChangeInputValue}
                    error={inputError}
                  />
                </Else>
              </If>

              <Text.Small color={colors.grayMedium}>
                {renderInputConvertLabel()}
              </Text.Small>
            </div>
            <div className="select-wallet-container">
              <Text.Heading5
                color={colors.primary}
                className="select-button"
                onClick={goToSelectOriginWalletStep}
              >
                <If condition={!originWallet || !originWalletIsoCode}>
                  <Then>Selecionar</Then>

                  <Else>{originWallet?.walletName}</Else>
                </If>
              </Text.Heading5>
              <RoundButtonIcon buttonSize="iconWallet">
                <div className="scale-icon">{renderOriginWalletIcon()}</div>
              </RoundButtonIcon>
            </div>
          </div>

          <div className="separator">
            <div className="arrow-icon">
              <ArrowDownIcon />
            </div>
          </div>

          <div className="convert-row-destination">
            <div className="input-container">
              <div className="input-wrapper">
                <Text.Heading1Medium color={colors.grayMedium} marginRight={10}>
                  {renderInputResult()}
                </Text.Heading1Medium>
                {isExchangeResultLoading && (
                  <LoadingSpinner
                    color={colors.primary}
                    size={20}
                    strokeSize={8}
                  />
                )}
              </div>

              <Text.Small color={colors.grayMedium}>
                {renderInputReceiveLabel()}
              </Text.Small>
            </div>
            <div className="select-wallet-container">
              <Text.Heading5
                color={
                  originWallet && title === 'Converter'
                    ? colors.primary
                    : colors.grayMedium
                }
                className="select-button"
                onClick={() => {
                  if (originWallet && title === 'Converter') {
                    goToSelectDestinationWalletStep();
                  }
                }}
              >
                <If condition={!destinationWallet || !destinationWalletIsoCode}>
                  <Then>Selecionar</Then>

                  <Else>{destinationWallet?.walletName}</Else>
                </If>
              </Text.Heading5>
              <RoundButtonIcon buttonSize="iconWallet">
                <div className="scale-icon">
                  {renderDestinationWalletIcon()}
                </div>
              </RoundButtonIcon>
            </div>
          </div>
        </Styles.ConversionAreaContainer>
        <Styles.ResumeContainer>
          <div className="content-wrapper">
            <div className="top-content">
              <Text.Paragraph color={colors.grayMedium}>
                Par de conversão
              </Text.Paragraph>
              <Text.Heading5>{conversionPair}</Text.Heading5>
            </div>
            <div className="bottom-content">
              <Text.Paragraph color={colors.grayMedium}>Cotação</Text.Paragraph>
              <Text.Heading5>
                {formatCurrencyFn(
                  destinationWalletIsoCode,
                  String(exchangeRatio || 0)
                )}
              </Text.Heading5>
            </div>
          </div>
        </Styles.ResumeContainer>
      </div>
      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonAction={goToDetailsStep}
        disableSingleButton={isNextButtonDisabled}
      />
    </>
  );
}

export default ChooseWallets;
