export const PIX_QUERY_KEYS = {
  CREATE_PIX_KEY: 'CREATE_PIX_KEY',
  GET_PIX_KEY: 'GET_PIX_KEY',
  DELETE_PIX_KEY: 'DELETE_PIX_KEY',
  RESOLVE_PIX_KEY: 'RESOLVE_PIX_KEY',
};

export const PIX_QUERY_TRANSFER = {
  DECODE_QR_CODE_PIX: 'DECODE_QR_CODE_PIX',
  RESOLVE_PIX_KEY: 'RESOLVE_PIX_KEY',
  PIX_TED_TRANSFER: 'PIX_TED_TRANSFER',
  PIX_TRANSFER_BY_KEY: 'PIX_TRANSFER_BY_KEY',
  PIX_TRANSFER_MANUAL: 'PIX_TRANSFER_MANUAL',
  PIX_TRANSFER_BR_CODE: 'PIX_TRANSFER_BR_CODE',
  PIX_TRANSFER_REFUND: 'PIX_TRANSFER_REFUND'
};
