import React, { SVGProps } from 'react';

const BlockedSimpleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '12'}
      height={props.height || '12'}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.33194 8.6074C1.80809 7.87179 1.5 6.97187 1.5 6C1.5 3.51472 3.51472 1.5 6 1.5C6.97187 1.5 7.87179 1.80809 8.6074 2.33194L2.33194 8.6074ZM3.3926 9.66806C4.12821 10.1919 5.02813 10.5 6 10.5C8.48528 10.5 10.5 8.48528 10.5 6C10.5 5.02813 10.1919 4.12821 9.66806 3.3926L3.3926 9.66806ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z"
        fill={props.fill || '#272937'}
      />
    </svg>
  );
};

export default BlockedSimpleIcon;
