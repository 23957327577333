import { gql, request, useMutation } from '~reactQuery';

import { useQueryClient } from 'react-query';

interface TwoFAEnableInput {
  token: string;
  userOrganizationId: number;
  settingsId: number;
  tokenAuth?: string;
}

interface TwoFAEnableResponse {
  twoFAEnable: {
    data: {
      status: string;
      rescueCodes: string[];
    };
    isValid: boolean;
    messages: string[];
  };
}

export function useTwoFAEnable() {
  const queryClient = useQueryClient();

  const mutation = gql`
    mutation TwoFAEnable($params: twoFAEnableInput!) {
      twoFAEnable(params: $params) {
        data {
          status
          rescueCodes
        }
        messages
        isValid
      }
    }
  `;

  const fetch = async (params: TwoFAEnableInput) => {
    if (!params.settingsId) return null;

    const { settingsId, userOrganizationId, token } = params;

    const body = {
      settingsId,
      userOrganizationId,
      token,
    };

    const response = await request<TwoFAEnableResponse>(
      mutation,
      {
        params: body,
      },
      params?.tokenAuth
        ? {
            Authorization: `Bearer ${params?.tokenAuth}`,
            'x-token-context': 'change_user',
          }
        : {}
    );

    return response.twoFAEnable;
  };

  return useMutation('useTwoFAEnable', fetch, {
    onSettled: () => {
      queryClient.invalidateQueries(['useGetPartner']);
    },
  });
}
