import * as Text from '~styles/text';

import AuthValidate from '~components/AuthValidate';
import { ConfirmPinPageProps } from '../CreatePixKeys.types';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import { getSelectedUserOrganization } from '~helpers/device';
import { parseError } from '~reactQuery/index';
import { useCreatePixKey } from '~reactQuery/mutations/pix/useCreatePixKey';
import { useGetPartner } from '~reactQuery/queries/user/useGetPartner';
import { useState } from 'react';
import { useUserAuthTypes } from '~hooks/useUserAuthTypes';

function ConfirmPin(props: ConfirmPinPageProps) {
  const { setCurrentStep, keyType, value } = props;
  const { auth } = useUserAuthTypes();
  const userOrganizationId = getSelectedUserOrganization();

  const [hasError, setHasError] = useState({
    pin: '',
    twoFa: '',
  });

  const { mutateAsync: handleCreatePixKey } = useCreatePixKey();
  const { data: partnerInfos } = useGetPartner();

  const userDocumentNumber = partnerInfos?.data.documentNumber;

  const validateAndGoNext = async (pin: string, twoFa?: string) => {
    if (!keyType) return;

    if (!userOrganizationId || !userDocumentNumber) return;

    setHasError({
      pin: '',
      twoFa: '',
    });

    if ((auth.includes('2-FA') && twoFa?.length !== 6) || pin.length !== 4)
      return;

    const twoFA = twoFa ? { token: twoFa } : {};

    try {
      const res = await handleCreatePixKey({
        documentNumber: userDocumentNumber,
        userOrganizationId: Number(userOrganizationId),
        type: keyType,
        pin,
        value,
        ...twoFA,
      });

      setCurrentStep('createPixStatus', {
        statusOfCreation: 'OK',
        pixKey: res.value,
      });
    } catch (error: any) {
      const errorKey = parseError(error);

      if (errorKey === 'INVALID_PIN') {
        setHasError((prev) => ({ ...prev, pin: 'Pin inválido' }));
      } else if (errorKey === 'INVALID_2FA_TOKEN') {
        setHasError((prev) => ({ ...prev, twoFa: '2-FA inválido' }));
      } else {
        setCurrentStep('createPixStatus', { statusOfCreation: 'NOT_OK' });
      }
    }
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Confirmação de segurança"
        description="Preencha as confirmações de segurança"
        backButtonAction={() => setCurrentStep('showKeyExample')}
      />
      <div className="create-pix-body">
        <AuthValidate
          authTypes={auth}
          onChangeCodes={(pin, twoFa) => validateAndGoNext(pin, twoFa)}
          pinErrorMessage={hasError.pin}
          twoFaErrorMessage={hasError.twoFa}
        />
      </div>
      <ModalMultiStepBottom />
    </>
  );
}

export default ConfirmPin;
