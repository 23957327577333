import { Address, Phone } from '~reactQuery/types/business.types';
import { gql, request, useQuery } from '~reactQuery';

export interface GetCustomerDataResponse {
  getCustomerData: {
    data: {
      phone: Phone;
      address: Address;
      email: string;
      motherName: string;
      birthDate: string;
      isPoliticallyExposedPerson: boolean;
      documentNumber: string;
      registerName: string;
      socialName: string;
      status: string;
      profile: string;
      createdAt: string;
      updatedAt: string;
    };
  };
}

export function useGetCustomerData(documentNumber: string) {
  const QUERY = gql`
    query Data($params: getCustomerDataInput!) {
      getCustomerData(params: $params) {
        data {
          phone {
            countryCode
            number
          }
          address {
            zipCode
            addressLine
            buildingNumber
            complement
            neighborhood
            city
            state
            country
          }
          email
          motherName
          birthDate
          documentNumber
          registerName
          socialName
          status
          profile
          createdAt
          updatedAt
        }
      }
    }
  `;

  const fetcher = async () => {
    if (documentNumber?.length < 11) return null;
    const response = await request<GetCustomerDataResponse>(QUERY, {
      params: {
        documentNumber,
      },
    });

    return response?.getCustomerData?.data || null;
  };

  return useQuery(['getCustomerData', documentNumber], fetcher);
}
