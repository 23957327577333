import styled, { css } from 'styled-components';

import { FadeProps } from './ModalMultiStep.types';
import { GeneralStyleProps } from '~src/@types/general';
import { colors } from '~styles';
import { screen } from '~styles/metrics';
import { transparentize } from 'polished';

export const ModalBackground = styled.div<FadeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  left: 0;

  ${(props) =>
    props.fadeIn
      ? css`
          opacity: 1;
          transition: opacity 0.2s ease-in;
        `
      : css`
          opacity: 0;
          transition: opacity 0.2s ease-out;
        `}
`;

export const ModalContainer = styled.div<GeneralStyleProps>`
  display: flex;
  position: relative;
  width: 520px;
  height: ${({ height }) => (height ? `${height}px` : '100%')};

  max-height: 647px;

  border-radius: 12px;
  background-color: ${colors.white};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 999999999999;

  @media ${screen.tablet} {
    max-height: 2500px;
    width: 100vw;
    height: 100vh;
  }
`;

export const BodyContainer = styled.div`
  background-color: ${colors.white};
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 50px 0;
  border-radius: 12px 12px 0 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-bottom: 125px;

  @media ${screen.tablet} {
    border-radius: 0;
  }

  @media ${screen.phone} {
    padding-bottom: 190px;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 0 38px;

  svg {
    :hover {
      cursor: pointer;
    }
  }
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: 100px;
  background-color: ${colors.whiteLight};
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 26px 24px;
  display: flex;
  flex-direction: row-reverse;
`;

export const TwoButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ProgressContainer = styled.div`
  width: 100%;
  background-color: ${transparentize(0.7, colors.grayLight)};
  position: absolute;
  bottom: 100px;
  right: 0;

  &.kyc-modal {
    width: 65%;
    left: 0;

    @media ${screen.tablet} {
      width: 100%;
    }
  }

  .bar {
    transition: width 0.3s ease-out;
    max-width: 100%;
    height: 3px;
    background-color: ${colors.primary};
  }

  .divider {
    width: 100%;
    border: 1px solid ${colors.gray};
  }

  @media ${screen.phone} {
    position: fixed;
  }
`;

export const ContainerModalMultiStepBottomPart = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  @media ${screen.phone} {
    position: fixed;
  }
`;
