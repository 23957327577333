import * as Style from './WalletModal.styles';
import * as Text from '~styles/text';
import { Area, AreaChart, ResponsiveContainer, Tooltip, YAxis } from 'recharts';
import { CurrencyPeriod, WalletType } from '~reactQuery/types/wallets.types';
import { Else, If, Then, When } from 'react-if';
import {
  GenericRow,
  InputSwitch,
  LoadingSpinner,
  RoundButtonIcon,
  SuccessToast,
} from '~components';
import { formatCurrencyFn, getWalletColor } from '~helpers/format/currency';
import { useMemo, useState } from 'react';
import { ActionButton } from '~components/Buttons/ActionButton';
import BuyCardSVG from '~assets/svg/buyCard';
import DeleteSVG from '~assets/svg/delete';
import DeleteWallet from '../DeleteWallet';
import EditSVG from '~assets/svg/edit';
import EditWalletName from '../EditWalletName';
import FavoriteOutlineSVG from '~assets/svg/favoriteOutline';
import GlobeSVG from '~assets/svg/globe';
import PositiveIcon from '~assets/images/icons/transactions/positive-variation.svg';
import ReceiveSVG from '~assets/svg/receive';
import SendSVG from '~assets/svg/send';
import { TRANSFER_ICONS } from '~data/transactions';
import colors from '~styles/colors';
import date from '~helpers/format/date';
import dayjs from 'dayjs';
import { getSelectedUserOrganization } from '~helpers/device';
import { Sections, useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useCurrencyExchange } from '~reactQuery/queries/wallet/useCurrencyExchange';
import { useCurrencyVariation } from '~reactQuery/queries/wallet/useCurrencyVariation';
import { useGetWallets } from '~reactQuery/queries/wallet/useGetWallets';
import { useGetWalletsTransaction } from '~reactQuery/queries/wallet/useWalletTransactions';
import { useWalletsModify } from '~reactQuery/mutations/wallet/useWalletsModify';
import { usePermission } from '~helpers/permissionGate';
import { useOpenModalSideBar } from '~hooks/useOpenSideBar';
import { ChartDataPropsCapAccount } from '../CapAccountAndWallets.types';

const prefCurrency = 'BRL';

const CustomTooltip = ({
  active,
  payload,
}: {
  active: boolean;
  payload: ChartDataPropsCapAccount[];
}) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">
          {dayjs(payload[0].payload.date).format('DD/MM/YYYY h:mm A')}
        </p>
        <p className="intro">
          {formatCurrencyFn(prefCurrency, payload[0].payload.balance)}
        </p>
      </div>
    );
  }

  return null;
};

const WalletModal = () => {
  const { params } = useOpenModalSideBar();

  const userOrganizationId = getSelectedUserOrganization();
  const { mutateAsync: modifyWallet } = useWalletsModify();
  const { setOpenModalMultiStep, setSection, setParams } =
    useActionsOnModalMultiStep();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [period, setPeriod] = useState<CurrencyPeriod>('today');
  const [sectionLoading, setSectionLoading] = useState('');
  const [openEditWalletNameModal, setOpenEditWalletNameModal] = useState(false);

  const { data: wallets } = useGetWallets({
    prefCurrency,
    flag: 'list',
    userOrganizationId: Number(userOrganizationId),
  });

  const filteredWallet = wallets?.filter(
    (w) => w.walletAddress === params.walletAddress
  )?.[0];

  const { data: currencyVariation } = useCurrencyVariation({
    prefCurrency,
    currencyISO: params?.currency?.isoCode,
    period: 'today',
  });

  const { data: valueInPrefCurrency } = useCurrencyExchange({
    fromCurrency: params?.currency?.isoCode,
    toCurrency: prefCurrency,
    amount: '1',
    senderPays: false,
    forcePivot: true,
    userOrganizationId: Number(userOrganizationId),
  });

  const { data: amountUpdated } = useCurrencyExchange({
    fromCurrency: params?.currency?.isoCode,
    toCurrency: prefCurrency,
    amount: String(params?.funds),
    senderPays: false,
    forcePivot: true,
    userOrganizationId: Number(userOrganizationId),
  });

  const {
    data: walletTxs,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetWalletsTransaction({
    userOrganizationId: Number(userOrganizationId),
    walletAddr: params.walletAddress,
    disableQuery: !params.walletAddress,
    perPage: 20,
  });

  const handleScroll = async (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } =
      e.target as HTMLDivElement;

    const bottom = scrollHeight - scrollTop === clientHeight;

    if (bottom) {
      await fetchNextPage();
    }
  };

  const handleOpenModal = (
    item: boolean,
    setItem: (value: boolean) => void
  ) => {
    setItem(!item);
  };

  const lastTransactions = useMemo(() => {
    return walletTxs?.pages
      ?.map((page) => page?.txes)
      ?.map((txes) => txes?.map((tx) => tx))
      .flat()
      ?.map((tx) => {
        const getTxDetails = TRANSFER_ICONS.filter(
          (t) => t?.type === tx?.history
        )?.[0];

        return {
          title: getTxDetails?.label,
          description:
            date(tx?.createdAt).diff(date(), 'days') > -2
              ? date(tx?.createdAt).fromNow()
              : date(tx?.createdAt).format('DD MMM YY'),
          value: formatCurrencyFn(params?.currency?.isoCode, tx?.amount),
          icon: getTxDetails?.icon,
          txId: tx?.txid,
        };
      });
  }, [walletTxs]);

  const openConvert = (section: Sections) => {
    setSection(section);

    setParams({ comingFrom: 'capWallet', destinationWallet: params });
    setOpenModalMultiStep(true);
  };

  const graphData = useMemo(() => {
    if (currencyVariation) {
      return currencyVariation?.prices?.map((price, i) => ({
        balance: Number(price).toFixed(2),
        date: currencyVariation?.dates?.[i],
      }));
    }
    return [];
  }, [currencyVariation]);

  async function modifyWalletAction(field: string, wallet: WalletType) {
    if (!wallet) {
      return;
    }
    setSectionLoading(field);

    const isOwnerInfoPublic = !wallet.isOwnerInfoPublic;
    const isPublic = !wallet.isPublic;
    const isFavorite = !wallet.isFavorite;

    try {
      await modifyWallet({
        walletAddress: wallet.walletAddress,
        name: wallet.walletName,
        userOrganizationId: Number(userOrganizationId),
        data: {
          isOwnerInfoPublic:
            field === 'isOwnerInfoPublic'
              ? Boolean(isOwnerInfoPublic)
              : Boolean(wallet.isOwnerInfoPublic),
          isPublic:
            field === 'isPublic'
              ? Boolean(isPublic)
              : Boolean(wallet.isPublic),
          isFavorite:
            field === 'isFavorite'
              ? Boolean(isFavorite)
              : Boolean(wallet.isFavorite),
        },
      });

      setTimeout(() => {
        setSectionLoading('');
      }, 2000);

      if (field === 'isPublic') {
        SuccessToast({
          title: 'Alteração realizada com sucesso!',
          subtitle:
            isPublic
              ? `A sua carteira ${wallet.walletName} agora pode receber transferências externas.`
              : `A sua carteira ${wallet.walletName} não pode mais receber transferências externas.`,
          autoCloseEnable: true,
        });
      }

      if (field === 'isFavorite') {
        SuccessToast({
          title: 'Alteração realizada com sucesso!',
          subtitle:
            isFavorite
              ? `A carteira ${wallet.walletName} foi adicionada às favoritas.`
              : `A carteira ${wallet.walletName} foi removida das favoritas.`,
          autoCloseEnable: true,
        });
      }
    } catch (error) {
      /* empty */
    }
  }

  const { granted: cryptoTransactionsGranted } =
    usePermission('criptoTransactions');

  return (
    <Style.Container>
      <div className="header">
        <RoundButtonIcon buttonSize="iconWallet">
          <div className="scale-icon">{params.icon}</div>
        </RoundButtonIcon>
        <div className="title">
          <Text.Heading2>{filteredWallet?.walletName}</Text.Heading2>
          <Text.Paragraph color={colors.grayMedium} marginTop={3}>
            {params?.currency?.isoCode}
          </Text.Paragraph>
        </div>
      </div>

      <div className="details">
        <div className="left-side">
          <Text.Small color={colors.black} marginBottom={6}>
            Saldo da Carteira
          </Text.Small>
          <Text.Heading2 marginBottom={5}>
            {formatCurrencyFn('BRL', String(amountUpdated))}
          </Text.Heading2>
          <Text.Small color={colors.grayMedium}>
            {filteredWallet?.funds} {params?.currency?.isoCode}
          </Text.Small>
        </div>

        <When
          condition={
            !['USD', 'BRL', 'GBP', 'EUR'].includes(params?.currency?.isoCode)
          }
        >
          <div className="right-side">
            <Text.Small color={colors.black} marginBottom={6}>
              Cotação Atual
            </Text.Small>
            <Text.Heading2 marginBottom={5} color={colors.grayMedium}>
              {formatCurrencyFn('BRL', String(valueInPrefCurrency))}
            </Text.Heading2>
            <div className="variation">
              {Number(currencyVariation?.variation) > 0 ? (
                <PositiveIcon />
              ) : null}
              <Text.Small
                color={
                  Number(currencyVariation?.variation) > 0
                    ? colors.success
                    : colors.danger
                }
              >
                {currencyVariation ? `${currencyVariation?.variation}%` : ''}
              </Text.Small>
            </div>
          </div>
        </When>
      </div>

      <When
        condition={
          graphData &&
          !['BRL', 'USD', 'EUR', 'GBP'].includes(params?.currency?.isoCode)
        }
      >
        <ResponsiveContainer width="100%" height={180}>
          <AreaChart
            margin={{
              top: 5,
              right: 0,
              left: 0,
              bottom: 70,
            }}
            data={graphData}
          >
            <defs>
              <linearGradient id="colorbalance" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="10%"
                  stopColor={getWalletColor(params?.currency?.isoCode)}
                  stopOpacity={0.35}
                />
                <stop
                  offset="90%"
                  stopColor={colors.whiteLight}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <Tooltip content={<CustomTooltip active={false} payload={[]} />} />
            <YAxis type="number" hide domain={['dataMin', 'dataMax']} />
            <Area
              type="monotone"
              dataKey="balance"
              stroke={getWalletColor(params?.currency?.isoCode)}
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorbalance)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </When>
      <div className="action-buttons-wrapper">
        <ActionButton
          icon={<BuyCardSVG />}
          onClick={() => openConvert('convert')}
          disabled={!cryptoTransactionsGranted}
        >
          Comprar
        </ActionButton>
        <ActionButton
          icon={<SendSVG />}
          onClick={() => openConvert('send')}
          disabled={!cryptoTransactionsGranted}
        >
          Enviar
        </ActionButton>
        <ActionButton
          icon={<ReceiveSVG />}
          onClick={() => openConvert('deposit')}
          disabled={!cryptoTransactionsGranted}
        >
          Depositar
        </ActionButton>
      </div>
      {lastTransactions && lastTransactions?.length > 0 ? (
        <div className="title-mov">
          <Text.Heading3 onClick={() => fetchNextPage()} marginBottom={15}>
            Últimas Movimentações
          </Text.Heading3>
        </div>
      ) : null}
      <div className="last-transactions" onScroll={handleScroll}>
        {lastTransactions?.map((transaction) => (
          <GenericRow
            key={transaction.txId}
            title={transaction.title}
            description={transaction.description}
            rightContentType="text"
            rightText={transaction.value}
            leftContentIconType="icon"
            leftComponent={transaction.icon}
            padding="18px 0"
          />
        ))}

        {/* <GenericButton
          buttonType="inline"
          width="100%"
          onClick={() => {}}
          marginTop={25}
        >
          Extrato completo
        </GenericButton> */}
        <When condition={isFetchingNextPage}>
          <div className="loading-container">
            <LoadingSpinner color={colors.primary} size={60} strokeSize={25} />
          </div>
        </When>
      </div>

      <div className="config-content">
        <Text.Heading3 marginBottom={15}>Configurar CapWallet</Text.Heading3>

        <GenericRow
          leftContentIconType="icon"
          leftComponent={<EditSVG fill={colors.black} />}
          title="Alterar nome da carteira"
          rightContentType="arrow"
          padding="18px 10px 18px 10px"
          onClick={() =>
            !cryptoTransactionsGranted
              ? {}
              : handleOpenModal(
                openEditWalletNameModal,
                setOpenEditWalletNameModal
              )
          }
        />

        <GenericRow
          leftContentIconType="icon"
          leftComponent={<FavoriteOutlineSVG />}
          rightComponent={
            <If condition={sectionLoading === 'isFavorite'}>
              <Then>
                <LoadingSpinner
                  color={colors.primary}
                  size={30}
                  strokeSize={10}
                />
              </Then>

              <Else>
                <InputSwitch
                  checked={Boolean(filteredWallet?.isFavorite)}
                  disabled={!cryptoTransactionsGranted}
                  onClick={() => {
                    if (!cryptoTransactionsGranted) {
                      return {};
                    }

                    return filteredWallet
                      ? modifyWalletAction('isFavorite', filteredWallet)
                      : {};
                  }}
                />
              </Else>
            </If>
          }
          title="Carteira Favorita"
          rightContentType="component"
          padding="18px 10px 18px 10px"
        />

        <GenericRow
          leftContentIconType="icon"
          leftComponent={<GlobeSVG />}
          rightComponent={
            <If condition={sectionLoading === 'isPublic'}>
              <Then>
                <LoadingSpinner
                  color={colors.primary}
                  size={30}
                  strokeSize={10}
                />
              </Then>

              <Else>
                <InputSwitch
                  checked={Boolean(filteredWallet?.isPublic)}
                  disabled={!cryptoTransactionsGranted}
                  onClick={() => {
                    if (!cryptoTransactionsGranted) {
                      return {};
                    }

                    return filteredWallet
                      ? modifyWalletAction('isPublic', filteredWallet)
                      : {};
                  }}
                />
              </Else>
            </If>
          }
          title="Receber transferências externas"
          description="Receba de outros usuários do Capitual compartilhando seu CAP-U."
          rightContentType="component"
          padding="18px 10px 18px 10px"
        />

        <GenericRow
          leftContentIconType="icon"
          leftComponent={<DeleteSVG />}
          title="Excluir Carteira"
          rightContentType="arrow"
          padding="18px 10px 18px 10px"
          titleColor={colors.danger}
          onClick={() =>
            !cryptoTransactionsGranted
              ? {}
              : handleOpenModal(openDeleteModal, setOpenDeleteModal)
          }
        />
      </div>

      <When condition={openDeleteModal}>
        <DeleteWallet
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          wallet={params as WalletType}
        />
      </When>

      <When condition={openEditWalletNameModal}>
        <EditWalletName
          openModal={openEditWalletNameModal}
          setOpenModal={setOpenEditWalletNameModal}
          wallet={filteredWallet as WalletType}
        />
      </When>
    </Style.Container>
  );
};

export default WalletModal;
