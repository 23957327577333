import * as Style from './RecoveryCodes.styles';

import { SetCurrentStepProps, StepParamsProps } from './RecoveryCodes.types';
import { useEffect, useState } from 'react';

import AskForPins from './ModalSteps/2.AskForPins.screen';
import Dialog from '~components/Dialog';
import ExplainCodes from './ModalSteps/1.ExplainCodes.screen';
import GenerateCodes from './ModalSteps/3.GenerateCodes.screen';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import ModalMultiStep from '~components/ModalMultiStep';

function RecoveryCodesModals() {
  const { openModalMultiStep, setOpenModalMultiStep } =
    useActionsOnModalMultiStep();

  const [currentStep, setCurrentStep] =
    useState<SetCurrentStepProps>('explainCodes');
  const [stepParams, setStepParams] = useState<StepParamsProps>({});

  function setStepsAndParams(step: SetCurrentStepProps, params?: object) {
    if (params) {
      setStepParams((prev) => ({ ...prev, ...params } as StepParamsProps));
    }
    setCurrentStep(step);
  }

  const modalStepsAndDetails = {
    explainCodes: {
      component: (
        <ExplainCodes
          key="explainCodes"
          setStepsAndParams={setStepsAndParams}
        />
      ),
      progressValue: 25,
    },
    askForPin: {
      component: (
        <AskForPins key="askForPin" setStepsAndParams={setStepsAndParams} />
      ),
      progressValue: 50,
    },
    generateCodes: {
      component: (
        <GenerateCodes
          key="generateCodes"
          stepParams={stepParams}
          setStepsAndParams={setStepsAndParams}
        />
      ),
      progressValue: 100,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  // CLEAR STEP ON MODAL CLOSE
  useEffect(() => {
    if (!openModalMultiStep) setCurrentStep('explainCodes');
  }, [openModalMultiStep]);

  return (
    <Style.RecoveryCodesContainer>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={currentStepInfos.component}
      >
        {currentStepInfos.component}
      </ModalMultiStep>
    </Style.RecoveryCodesContainer>
  );
}

export default RecoveryCodesModals;
