import * as React from 'react';
import { SVGProps } from 'react';

const ArrowUpGreenSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m4.143 12 8-8m0 0v6.4m0-6.4h-6.4"
      stroke="#00E699"
      strokeWidth={1.5}
    />
  </svg>
);

export default ArrowUpGreenSVG;
