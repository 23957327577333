import * as Style from '../SignUp.styles';
import * as Text from '~styles/text';
import * as yup from 'yup';
import { FooterButton, InputForm, PasswordChecker } from '~components';
import { cpfMask, formatPhone } from '~helpers/format/masks';
import RegisterPartnerIcon from '~assets/images/kyc/register-partner.svg';
import { StepProps } from '../SignUp.types';
import { SuccessToast } from '~components/Toast';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSignUpMutation } from '~reactQuery/mutations/user/useSignUp';
import { validateCPF } from '~validations/personalValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import colors from '~styles/colors';
import {
  strongPasswordRegex
} from '~validations/stringsValidate';

export const RegisterNewUser = ({ token }: StepProps) => {
  const navigate = useNavigate();

  const { mutateAsync: SignUpMutation, isLoading: loadingSingUp } =
    useSignUpMutation(String(token));

  const schema = yup.object({
    email: yup.string().email('Email inválido').required('Email obrigatório'),
    passwd: yup
      .string()
      .required('Esse campo é obrigatório')
      .matches(
        strongPasswordRegex,
        'Deve ao menos 8 caracteres, um maiúsculo, um minúsculo, um número e um caractere especial'
      ),
    confirmPassword: yup
      .string()
      .required('Esse campo é obrigatório')
      .matches(
        strongPasswordRegex,
        'Deve ao menos 8 caracteres, um maiúsculo, um minúsculo, um número e um caractere especial'
      )
      .oneOf([yup.ref('passwd')], 'Senhas não coincidem'),
    phone: yup
      .string()
      .min(14, 'Deve conter 11 números')
      .required('Esse campo é obrigatório'),
    registerName: yup
      .string()
      .min(6, 'Esse é realmente seu nome completo?')
      .required(),
    socialName: yup
      .string()
      .min(3, 'O nome social deve ter no mínimo 4 caracteres'),
    cpf: yup
      .string()
      .test('cpf', 'CPF inválido', (value) => {
        if (value) {
          return validateCPF(value);
        }
        return true;
      })
      .required('CPF obrigatório'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    const cleanDoc = data.cpf.replace(/\D/g, '');
    const cleanPhone = data.phone.replace(/\D/g, '');

    const parsedData = {
      email: data.email,
      passwd: data.passwd,
      registerName: data.registerName,
      socialName: data.socialName,
      documentNumber: String(cleanDoc),
      phone: String(cleanPhone),
    };

    try {
      await SignUpMutation(parsedData);

      SuccessToast({
        title: 'Conta criada com sucesso',
        autoCloseEnable: true,
      });

      setTimeout(() => {
        navigate('/kyc');
      }, 500);
    } catch (error) {
      //  console.log(error);
    }
  });

  const verifyIsDisabled = (isActive: boolean) => {
    if (!isActive) {
      return colors.grayLight;
    }

    return colors.black;
  };

  const password = watch('passwd');

  return (
    <>
      <Style.Container>
        <div className="margin-progress-for-scroll">
          <div className="main-icon">
            <RegisterPartnerIcon />
          </div>

          <Text.Heading1Medium
            marginBottom={30}
            fontSize={1.938}
            lineHeight={130}
          >
            Que bom ver você aqui!
          </Text.Heading1Medium>

          <Text.Heading5 marginBottom={30}>
            Aqui você irá fornecer os dados necessários para realizar login na
            sua conta empresarial. Se você precisar interromper o processo de
            cadastro, com o login e senha, poderá continuar de onde parou!
          </Text.Heading5>

          <div className="input-group">
            <InputForm
              {...register('email')}
              autoFocus
              label="E-mail"
              error={errors.email?.message}
            />

            <InputForm
              {...register('cpf')}
              label="CPF"
              error={errors.cpf?.message}
              maskFunction={cpfMask}
              maxLength={14}
            />

            <InputForm
              {...register('phone')}
              label="Telefone"
              error={errors.phone?.message}
              maskFunction={formatPhone}
              maxLength={19}
            />

            <InputForm
              {...register('registerName')}
              label="Nome Completo"
              error={errors.registerName?.message}
              maxLength={50}
            />

            <InputForm
              {...register('socialName')}
              label="Nome Social"
              error={errors.socialName?.message}
              maxLength={50}
            />

            <InputForm
              {...register('passwd')}
              label="Senha"
              error={errors.passwd?.message}
              type="password"
            />

            <PasswordChecker password={password} />

            <InputForm
              {...register('confirmPassword')}
              label="Repetir Senha"
              error={errors.confirmPassword?.message}
              type="password"
              style={{ marginBottom: 10 }}
            />
          </div>
        </div>
      </Style.Container>

      <FooterButton
        onClickGenericButton={onSubmit}
        onClickLeftButton={() => navigate('/')}
        loadingGenericButton={loadingSingUp}
        leftButtonContent="Voltar"
        genericButtonType="secondary"
        genericButtonContent="Avançar"
      />
    </>
  );
};

export default RegisterNewUser;
