import * as React from 'react';

import { SVGProps } from 'react';

const UserSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 50}
    height={props.height || 50}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={50} height={50} rx={25} fill={props.fill || 'transparent'} />
    <path
      d="M29.518 20.518a4.518 4.518 0 1 1-9.036 0 4.518 4.518 0 0 1 9.037 0ZM15.963 33.429l.215-.353c4.024-6.611 13.62-6.611 17.644 0l.215.353"
      stroke="#272937"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);

export default UserSVG;
