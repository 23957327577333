import React, { SVGProps } from 'react';

const PersonalizeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '16'}
      height={props.height || '19'}
      viewBox="0 0 16 19"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.50195C10 2.94967 10.4477 2.50195 11 2.50195C11.5523 2.50195 12 2.94967 12 3.50195C12 4.05424 11.5523 4.50195 11 4.50195C10.4477 4.50195 10 4.05424 10 3.50195ZM11 0.501953C9.34315 0.501953 8 1.8451 8 3.50195C8 5.15881 9.34315 6.50195 11 6.50195C12.3062 6.50195 13.4175 5.66715 13.8293 4.50195L16 4.50195V2.50195L13.8293 2.50195C13.4175 1.33676 12.3062 0.501953 11 0.501953ZM5 6.50195C3.69378 6.50195 2.58254 7.33676 2.17071 8.50195H0V10.502H2.17071C2.58254 11.6671 3.69378 12.502 5 12.502C6.65685 12.502 8 11.1588 8 9.50195C8 7.8451 6.65685 6.50195 5 6.50195ZM13.8293 16.502C13.4175 17.6671 12.3062 18.502 11 18.502C9.34315 18.502 8 17.1588 8 15.502C8 13.8451 9.34315 12.502 11 12.502C12.3062 12.502 13.4175 13.3368 13.8293 14.502H16V16.502H13.8293ZM0 2.50195H6V4.50195H0V2.50195ZM10 10.502L16 10.502V8.50195H10V10.502ZM6 14.502V16.502H0V14.502H6ZM5 8.50195C4.44772 8.50195 4 8.94967 4 9.50195C4 10.0542 4.44772 10.502 5 10.502C5.55228 10.502 6 10.0542 6 9.50195C6 8.94967 5.55228 8.50195 5 8.50195ZM10 15.502C10 14.9497 10.4477 14.502 11 14.502C11.5523 14.502 12 14.9497 12 15.502C12 16.0542 11.5523 16.502 11 16.502C10.4477 16.502 10 16.0542 10 15.502Z"
        fill={props.fill || '#272937'}
      />
    </svg>
  );
};

export default PersonalizeIcon;
