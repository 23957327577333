import { breakpoints, screen } from '~styles/metrics';

import { GeneralStyleProps } from 'src/@types/general';
import { colors } from '~styles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 410px;

  min-width: 410px;
  max-width: 410px;
  height: inherit;
  position: relative;
  padding: 25px 0px;
  background: ${colors.white};
  z-index: 1;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  overflow-x: hidden;

  .container-contacts {
    max-height: ${window.innerHeight}px;
    overflow-y: scroll;
  }

  .container-coming-soon {
    display: flex;
    gap: 3px;
    justify-content: center;
    align-items: center;
  }

  .head-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 27px;
  }

  .outline-button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px 0px;
  }

  .empty-purchases,
  .empty-contacts {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 310px;
    padding: 49px 0;
    background-color: ${colors.white};
    border-radius: 10px;
  }

  @media (max-width: 1520px) {
    width: 400px;
  }

  @media ${screen.desktop} {
    width: 390px;
  }

  @media (min-width: ${breakpoints.tablet}px) and (max-width: 1257px) {
    display: none;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.5;
  width: 100%;
  /* margin-right: 20px; */
  /* gap: 15px; */

  .card-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    width: 120px;
    height: 175px;
    padding: 20px 15px;
    background: linear-gradient(154.85deg, #fcfcfd 2.28%, #dde3ee 97.23%);
    border-radius: 15px;
    border: 1px solid ${colors.grayOpacity20p};

    margin-right: 15px;
    cursor: pointer;

    &.virtual-card {
      background: linear-gradient(154.85deg, #90c1fe 2.28%, #2b89fd 97.23%);
    }
  }

  .alice-carousel__wrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .alice-carousel__dots {
    display: none;
  }

  .alice-carousel__next-btn,
  .alice-carousel__prev-btn {
    display: none;
  }

  .alice-carousel {
    cursor: grab;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    width: 100%;
  }

  .alice-carousel__stage {
    display: flex;
    flex-direction: row;
    align-items: center;

    .alice-carousel__stage-item {
      max-width: 150px;
      width: 150px !important;
      white-space: nowrap;
    }

    .alice-carousel__stage-item.__active:first-child {
      margin-left: 20px;
    }

    .alice-carousel__stage-item.__active:last-child {
      display: flex;
      flex-direction: row;
      align-items: center;

      max-width: 400px !important;
      width: 400px !important;
    }
  }

  .plus-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 65px;
    cursor: not-allowed;

    .plus-button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 50px;
      height: 50px;
      padding: 12px;
      border-radius: 50px;
      background-color: ${colors.gray};

      margin-bottom: 10px;
      transition: all ease-out 0.3s;

      svg {
        color: ${colors.black};
        transition: all ease-out 0.3s;
      }

      :hover {
        filter: brightness(0.9);
      }
    }
  }
`;

export const ProfileButton = styled.div<GeneralStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 35px;
  height: 35px;
  padding: 8px 13px;
  background-color: ${(props) => props.backgroundColor || colors.primary};
  border-radius: 10px;
`;

export const Hr = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.04);
  margin-top: 10px;
`;
