import * as React from 'react';

import { SVGProps } from 'react';

const BuildingSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 36}
    height={props.height || 36}
    fill={props.fill || '#fff'}
    {...props}
  >
    <path
      d="M27.25 5H13.593c-1.52 0-2.751 1.214-2.751 2.713v7.61L4.406 21.59a1.333 1.333 0 0 0-.304 1.472c.103.248.278.46.504.609.225.149.49.229.76.229v6.75c0 .358.145.701.402.955.256.253.604.395.967.395h21.896c.363 0 .712-.142.968-.395.257-.254.401-.597.401-.955V7.712C30 6.214 28.771 5 27.25 5ZM16.209 22.7v6.6H8.104v-7.517l4.088-3.981 4.016 4.054v.844Zm2.844-8.25h-2.737v-2.7h2.737v2.7Zm5.474 10.8h-2.737v-2.7h2.737v2.7Zm0-5.4h-2.737v-2.7h2.737v2.7Zm0-5.4h-2.737v-2.7h2.737v2.7Z"
      fill={props.fill || '#fff'}
    />
    <path d="M11 23h2v3h-2v-3Z" fill={props.fill || '#fff'} />
  </svg>
);

export default BuildingSVG;
