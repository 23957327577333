import { SVGProps } from 'react';

const DocumentDuplicateIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '24'}
      height={props.height || '25'}
      viewBox="0 0 24 25"
      fill="none"
    >
      <g id="Artwork">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99999 2.01001H8.99999H16C16.2651 2.01001 16.5194 2.1153 16.7069 2.30273C16.9619 2.55757 17.3534 2.95017 17.7984 3.39641C18.8185 4.41939 20.1198 5.7243 20.7017 6.29774C20.8924 6.48568 20.9998 6.74225 20.9998 7.01001V16.01C20.9998 17.6668 19.6567 19.0099 18 19.01C18 20.6668 16.6568 22.01 15 22.01H6C4.34314 22.01 2.99999 20.6668 3 19.01L3.00005 8.01C3.00005 6.35315 4.3432 5.01001 6.00005 5.01001H12H14C14.2651 5.01001 14.5194 5.1153 14.7069 5.30273L16.2992 6.89494L17.2729 7.86867L17.5883 8.18408L17.6761 8.27192L17.6992 8.29501L17.7051 8.30092L17.7066 8.30242L17.707 8.30279L17.7071 8.30289L17 9.01001L17.7071 8.30292C17.8946 8.49045 18 8.7448 18 9.01001V10.01V17.01C18.5522 17.0099 18.9998 16.5622 18.9998 16.01V7.42717C18.3345 6.76643 17.2444 5.67325 16.3697 4.79616C16.0759 4.50153 15.8064 4.23129 15.5855 4.01001H8.99999H7.99999V2.01001ZM16 17.01V11.01H14C12.8954 11.01 12 10.1146 12 9.01001V7.01001H6.00005C5.44776 7.01001 5.00005 7.45772 5.00005 8.01001L5 19.01C5 19.5623 5.44771 20.01 6 20.01H15C15.5523 20.01 16 19.5623 16 19.01M14 7.4242V9.01001H15.5858L14.8849 8.30913L14 7.4242ZM16 17.01V19.01V17.01ZM6.99999 12.01H7.99999H13H14V14.01H13H7.99999H6.99999V12.01ZM6.99999 16.01H7.99999H13H14V18.01H13H7.99999H6.99999V16.01Z"
          fill={props.fill || '#9EA1B9'}
        />
      </g>
    </svg>
  );
};

export default DocumentDuplicateIcon;
