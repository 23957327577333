import * as Text from '~styles/text';

import React, { useState } from 'react';
import { getSelectedUserOrganization, getSettingsId } from '~helpers/device';
import { InputPin } from '~components/Inputs';
import colors from '~styles/colors';
import { useTwoFAEnable } from '~reactQuery/mutations/settings/useTwoFAEnable';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';

const AskForCodes: React.FC = ({ setStepsAndParams }) => {
  const orgId = getSelectedUserOrganization();

  const [isCodeValid, setIsCodeValid] = useState({
    isValid: '',
    code: '',
  });

  const { mutateAsync: enableTwoFACode, isLoading: isLoadingEnable } =
    useTwoFAEnable();

  const organization = getSettingsId();

  const enableTwoFA = async () => {
    if (isCodeValid.code.length !== 6) {
      setIsCodeValid({
        isValid: 'false',
        code: '',
      });
    }

    try {
      setIsCodeValid((prev) => ({
        ...prev,
        isValid: 'true',
      }));

      const enableResp = await enableTwoFACode({
        token: isCodeValid.code,
        userOrganizationId: Number(orgId),
        settingsId: Number(organization),
      });

      setStepsAndParams('showRecoveryCodes', {
        rescueCodes: enableResp?.data?.rescueCodes,
      });
    } catch (error) {
      // console.log('TwoFa error: ', error);

      setIsCodeValid({
        isValid: 'false',
        code: '',
      });
    }
  };

  return (
    <>
      <ModalMultiStepHeader
        title="Insira seu código de validação"
        description="Digite o código de 6 dígitos gerado pelo seu aplicativo de autenticação."
        backButtonAction={() => setStepsAndParams('showQrCode')}
      />

      <div className="enable-tow-fa-body">
        <InputPin
          autoFocus
          size={6}
          secret
          onChange={(code) => {
            setIsCodeValid({
              code,
              isValid: '',
            });
          }}
          onFinish={(code) => {
            setIsCodeValid({
              code,
              isValid: '',
            });
          }}
          error={isCodeValid.isValid === 'false'}
        />

        {isCodeValid.isValid === 'false' && (
          <Text.Heading5 marginTop={5} color={colors.danger}>
            Código Invalido
          </Text.Heading5>
        )}

        <Text.Heading5 marginTop={20}>
          Se estiver com dificuldades para encontrar o código, você pode pedir
          ajuda no nosso chat do suporte.
        </Text.Heading5>
      </div>

      <ModalMultiStepBottom
        singleButtonText="Habilitar 2FA"
        doubleButtonTextOne="Voltar"
        singleButtonAlignment="flex-end"
        singleButtonAction={enableTwoFA}
        doubleButtonOneAction={() => setStepsAndParams('showQrCode')}
        singleButtonLoading={isLoadingEnable}
      />
    </>
  );
};

export default AskForCodes;
