import * as Style from './Deposit.styles';
import { useEffect, useState } from 'react';
import BankDeposit from './DepositSteps/3.BankDeposit';
import CriptoDepositCurrency from './DepositSteps/5.CriptoDepositCurrency';
import CriptoDepositQR from './DepositSteps/4.CriptoDepositQR';
import DepositOptions from './DepositSteps/1.DepositOptions';
import CriptoOptions from './DepositSteps/2.CriptoOptions';
import ModalMultiStep from '~components/ModalMultiStep';
import { SetCurrentStepProps } from './Deposit.types';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import BilletDepositValue from './DepositSteps/6.BilletDepositValue';
import BilletDepositGenerated from './DepositSteps/7.BilletDepositGenerated';
import { When } from 'react-if';
import AlertModal from '~components/AlertModal';
import * as Text from '~styles/text';
import ReverseIcon from '~assets/svg/reverse';

function Deposit() {
  const { openModalMultiStep, setOpenModalMultiStep, params } =
    useActionsOnModalMultiStep();
  const isoCode = params?.destinationWallet?.currency?.isoCode;
  const walletSelected = isoCode
    ? { walletSelected: params?.destinationWallet }
    : {};

  const [currentStep, setCurrentStep] = useState<SetCurrentStepProps>(
    isoCode ? 'criptoDepositCurrency' : 'depositOptions'
  );

  const [stepParams, setStepParams] = useState<any>(walletSelected);
  const [isModalLimitReachedVisible, setIsModalLimitReachedVisible] =
    useState(false);

  function setStepsAndParams(step: SetCurrentStepProps, paramsFn?: object) {
    if (paramsFn) {
      setStepParams((prev: any) => ({ ...prev, ...paramsFn } as any));
    }
    setCurrentStep(step);
  }

  const modalStepsAndDetails = {
    depositOptions: {
      component: (
        <DepositOptions
          key="depositOptions"
          setCurrentStep={setStepsAndParams}
          setStepParams={setStepParams}
          params={params}
        />
      ),
      progressValue: 25,
    },
    criptoOptions: {
      component: (
        <CriptoOptions
          key="criptoOptions"
          setCurrentStep={setStepsAndParams}
          setStepParams={setStepParams}
        />
      ),
      progressValue: 50,
    },
    bankDeposit: {
      component: (
        <BankDeposit key="bankDeposit" setCurrentStep={setStepsAndParams} />
      ),
      progressValue: 100,
    },
    criptoDepositQR: {
      component: (
        <CriptoDepositQR
          key="criptoDepositQR"
          stepParams={stepParams}
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 100,
    },
    criptoDepositCurrency: {
      component: (
        <CriptoDepositCurrency
          key="criptoDepositCurrency"
          stepParams={stepParams}
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 100,
    },
    billetDepositValue: {
      component: (
        <BilletDepositValue
          key="billetDepositValue"
          stepParams={stepParams}
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 75,
    },
    billetDepositGenerated: {
      component: (
        <BilletDepositGenerated
          key="billetDepositGenerated"
          stepParams={stepParams}
          setCurrentStep={setStepsAndParams}
        />
      ),
      progressValue: 100,
    },
    criptoDepositNetwork: {
      component: null,
      progressValue: 100,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  // CLEAR STEP ON MODAL CLOSE
  useEffect(() => {
    if (!openModalMultiStep) {
      setCurrentStep('depositOptions');
      setStepParams({});
    }
  }, [openModalMultiStep]);

  useEffect(() => {
    if (params?.origin === 'billsScreen') {
      setCurrentStep('billetDepositValue');
      setStepParams({});
    }
  }, []);

  const handleChangeDepositWay = () => {
    setCurrentStep('depositOptions');
    setIsModalLimitReachedVisible(false);
  };

  return (
    <Style.DepositContainer>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={currentStepInfos.progressValue}
      >
        {currentStepInfos.component}
      </ModalMultiStep>

      <When condition={isModalLimitReachedVisible}>
        <AlertModal
          setOpenAlert={setIsModalLimitReachedVisible}
          title="Limite de boletos atingido!"
          primaryButtonText={
            <>
              <ReverseIcon style={{ marginRight: 5 }} /> Alterar método de
              depósito.
            </>
          }
          primaryButtonAction={() => handleChangeDepositWay()}
          secondaryButtonText="Entendi"
          secondaryButtonAction={() => setIsModalLimitReachedVisible(false)}
          secondaryButtonColor="secondary"
          secondaryButtonWidth="123px"
          primaryButtonWidth="250px"
          bodyComponent={
            <>
              <Text.Heading5 marginBottom={30}>
                Você já alcançou o limite de 15 boletos bancários deste mês, mas
                não se desespere. No começo do próximo mês seu saldo será
                redefinido.
              </Text.Heading5>

              <Text.Heading5>
                Se você procura por uma forma de pagamento mais acessível, pode
                optar por transferências bancárias, como TED ou Pix. Se precisar
                de mais detalhes, não hesite em entrar em contato conosco.
              </Text.Heading5>
            </>
          }
        />
      </When>
    </Style.DepositContainer>
  );
}

export default Deposit;
