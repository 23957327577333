import { CurrencyTypes } from '~src/@types/currency.types';
import colors from '~styles/colors';

export function range(n: number) {
  return Array.from({ length: n }, (_, i) => i);
}

// Check if string only
export const isNumeric = (value: string) => /^-?\d+$/.test(value);

export const onlyNumbers = (value: string) => {
  if (!value) return '';

  // Replace anything that is not a number (e.g., '1234-0' => '12340')
  return value?.replace(/\D/g, '');
};

export const onlyNumbersAndLetters = (value: string) => {
  if (!value) return '';

  // Replace anything that is not a number or a letter (e.g., 'a123.0' => 'a123.0')
  return value.replace(/\W/g, '');
};

export const makeAbleToBeFilePath = (value: string) => {
  if (!value) return '';

  // Replace all '/' from formatted date (e.g., '08/10/2022' => '08102022')
  return value.replace(/([^a-z0-9\s]+)/gi, '');
};

export const formatExponentialNumber = (
  value: string | number | null | undefined,
  precision: number
) => {
  if (
    typeof value !== 'string' ||
    typeof precision !== 'number' ||
    value === ''
  ) {
    return '-';
  }
  return Number.parseFloat(value).toFixed(precision);
};

export const isExponentialNumber = (value: string) =>
  !!value?.toString().includes('e-') || !!value?.toString().includes('e+');

export const transactionStatus = [
  { status: 'CREATED', label: 'Em processamento' },
  { status: 'IN_PROCESS', label: 'Em processamento' },
  { status: 'APPROVED', label: 'Em processamento' },
  { status: 'REPROVED', label: 'Transação reprovada' },
  { status: 'DONE', label: 'Transação concluída' },
  { status: 'UNDONE', label: 'Reembolsado' },
  { status: 'CANCELED', label: 'Transação cancelada' },
  { status: 'CANCELLED', label: 'Transação cancelada' },
  { status: 'ACTIVE', label: 'Transação ativa' },
  { status: 'COMPLETED', label: 'Transação Processada' },
];

type ReceiptParserMethods =
  | 'CAP-TO-WALLET'
  | 'WALLET-TO-CAP'
  | 'TRANSFER-TED'
  | 'TRANSFER-PIX'
  | 'REFUND'
  | 'BILL-SLIP'
  | 'BILL';

export const receiptTitleParser = (
  status: string,
  method: ReceiptParserMethods,
  direction: number,
  isReceipt?: boolean
) => {
  let label = '-';

  const isDone =
    status === 'DONE' || status === 'ACTIVE' || status === 'COMPLETED';

  const isProcessing =
    status === 'IN_PROCESS' || status === 'CREATED' || status === 'APPROVED';

  const isCanceled = status === 'CANCELED' || status === 'CANCELLED';

  const isReproved = status === 'REPROVED';

  const isUndone = status === 'UNDONE';

  switch (method) {
    case 'CAP-TO-WALLET': {
      if (isDone) label = 'Conversão enviada';
      if (isUndone) label = 'Falha na conversão';
      if (isProcessing) label = 'Processando conversão';
      if (isCanceled) label = 'Falha na conversão';
      if (isReproved) label = 'Falha na conversão';
      break;
    }

    case 'WALLET-TO-CAP': {
      if (isDone) label = 'Conversão enviada';
      if (isUndone) label = 'Falha na conversão';
      if (isProcessing) label = 'Processando conversão';
      if (isCanceled) label = 'Falha na conversão';
      if (isReproved) label = 'Falha na conversão';
      break;
    }

    case 'TRANSFER-TED': {
      if (isReceipt) {
        if (isDone)
          label =
            direction < 1
              ? 'Transferência enviada TED'
              : 'Transferência recebida TED';
        if (isUndone) label = 'Falha na transferência TED';
        if (isProcessing) label = 'Processando transferência TED';
        if (isCanceled) label = 'Falha na transferência TED';
        if (isReproved) label = 'Falha na transferência TED';
        break;
      } else {
        if (isDone)
          label =
            direction < 1 ? 'Transferência enviada' : 'Transferência recebida';
        if (isUndone) label = 'Falha na transferência';
        if (isProcessing) label = 'Processando transferência';
        if (isCanceled) label = 'Falha na transferência';
        if (isReproved) label = 'Falha na transferência';
        break;
      }
    }

    case 'TRANSFER-PIX': {
      if (isReceipt) {
        if (isDone)
          label =
            direction < 1
              ? 'Transferência enviada Pix'
              : 'Transferência recebida Pix';
        if (isUndone) label = 'Falha na transferência Pix';
        if (isProcessing) label = 'Processando transferência Pix';
        if (isCanceled) label = 'Falha na transferência Pix';
        if (isReproved) label = 'Falha na transferência Pix';
        break;
      } else {
        if (isDone)
          label =
            direction < 1 ? 'Transferência enviada' : 'Transferência recebida';
        if (isUndone) label = 'Falha na transferência';
        if (isProcessing) label = 'Processando transferência';
        if (isCanceled) label = 'Falha na transferência';
        if (isReproved) label = 'Falha na transferência';
        break;
      }
    }

    case 'REFUND': {
      label = 'Transferência Reembolsada ';

      if (isDone) label += 'Concluída';
      if (isUndone) label += 'Não Realizada';
      if (isProcessing) label += 'Em Processamento';
      if (isCanceled) label += 'Cancelada';
      if (isReproved) label += 'Reprovada';
      break;
    }

    case 'BILL-SLIP': {
      if (isDone) label = 'Boleto de depósito recebido';
      if (isUndone) label = 'Falha no depósito por boleto';
      if (isProcessing) label = 'Processando boleto de depósito';
      if (isCanceled) label = 'Falha no depósito por boleto';
      if (isReproved) label = 'Falha no depósito por boleto';
      break;
    }

    case 'BILL': {
      if (isDone) label = 'Pagamento efetuado';
      if (isUndone) label = 'Falha no pagamento';
      if (isProcessing) label = 'Processando pagamento';
      if (isCanceled) label = 'Falha no pagamento';
      if (isReproved) label = 'Falha no pagamento';
      break;
    }

    default: {
      label = '-';
    }
  }

  return label;
};

export const getTxStatus = (status?: string) => {
  if (!status) return '';
  return transactionStatus.filter((s) => s.status === status)?.[0].label;
};

export const isLockedTransaction = (
  origin: CurrencyTypes,
  destination: CurrencyTypes
) => {
  if (!origin || !destination) return true;

  const denied = [
    ['BRL', 'USD'],
    ['BRL', 'GBP'],
    ['BRL', 'EUR'],
    ['EUR', 'USD'],
    ['EUR', 'GBP'],
    ['GBP', 'USD'],
  ];

  const deniedInverted = denied.map((arr) => [...arr].reverse());
  const blacklist = [...denied, ...deniedInverted];

  const locked = blacklist.some(
    (curr) => origin === curr[0] && destination === curr[1]
  );

  return locked;
};

export const receiptTranslatorIconColor = (
  status: string,
  direction: number
) => {
  const translator: any = {
    DONE: direction < 1 ? colors.black : colors.success,
    ACTIVE: direction < 1 ? colors.black : colors.success,
    COMPLETED: direction < 1 ? colors.black : colors.success,
    IN_PROCESS: colors.grayMedium,
    CREATED: colors.grayMedium,
    APPROVED: colors.grayMedium,
    CANCELED: colors.danger,
    CANCELLED: colors.danger,
    REPROVED: colors.danger,
    UNDONE: colors.danger,
  };

  return translator[status] ? translator[status] : colors.grayMedium;
};
