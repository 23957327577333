import {
  Check,
  UserForm,
  CreatePin,
  Register2FA,
  Validate2FA,
  GetRecoveryCode,
  Finish,
} from './Steps';
import { ContentWrapper, ScrollContainer } from '../SignTemplate.styles';
import { Steps } from './GuestRegister.types';
import { memo, useCallback, useState } from 'react';
import * as Style from '../SignTemplate.styles';

import useWindowDimensions from '~hooks/windowDimension';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { strongPasswordRegex } from '~validations/stringsValidate';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateCPF } from '~validations/personalValidation';

const GuestRegister = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();

  const queryParamsKey = new URLSearchParams(location.search.split('?')?.[1]);
  const queryParamsCompany = new URLSearchParams(
    location.search.split('?')?.[1]
  );
  const tokenInPathname = queryParamsKey.get('key');
  const orgName = queryParamsCompany.get('orgName');

  const [step, setStep] = useState<Steps>('check');
  const [stepsParams, setStepsParams] = useState({
    token: tokenInPathname,
    orgName,
  });

  const goToStep = useCallback((stepPos: Steps, params?: any) => {
    if (params) setStepsParams((prev) => ({ ...prev, ...params }));
    setStep(stepPos);
  }, []);

  const PartnerSchema = yup
    .object()
    .shape({
      fullName: yup.string().required('Esse campo é obrigatório'),
      socialName: yup.string().required('Esse campo é obrigatório'),
      passwd: yup
        .string()
        .required('Esse campo é obrigatório')
        .matches(
          strongPasswordRegex,
          'Deve ao menos 8 caracteres, um maiúsculo, um minúsculo, um número e um caractere especial'
        ),
      confirmPassword: yup
        .string()
        .required('Esse campo é obrigatório')
        .matches(
          strongPasswordRegex,
          'Deve ao menos 8 caracteres, um maiúsculo, um minúsculo, um número e um caractere especial'
        )
        .oneOf([yup.ref('passwd')], 'As senhas não coincidem'),
      phone: yup.string().required('Esse campo é obrigatório'),
      email: yup
        .string()
        .email('Email inválido')
        .required('Esse campo é obrigatório'),
      cpf: yup
        .string()
        .test('cpf', 'CPF inválido', (value) => {
          if (value) {
            return validateCPF(value);
          }
          return true;
        })
        .required('Esse campo é obrigatório'),
    })
    .required();

  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(PartnerSchema),
  });

  // console.log({ form: methods.getValues() }, { stepsParams });

  const scrollPage = ['userForm'].includes(step);

  const GuestRegisterSteps = {
    check: { content: <Check key="Check" goToStep={goToStep} stepParams={stepsParams} />, progress: 10 },
    userForm: { 
      content: <UserForm key="UserForm" goToStep={goToStep} stepParams={stepsParams} />,
      progress: 25,
    },
    createPin: {
      content: <CreatePin key="CreatePin" goToStep={goToStep} stepParams={stepsParams} />,
      progress: 35,
    },
    register2FA: {
      content: <Register2FA
        key="Register2FA"
        goToStep={goToStep}
        stepParams={stepsParams}
      />,
      progress: 50,
    },
    validate2FA: {
      content: <Validate2FA
        key="Validate2FA"
        goToStep={goToStep}
        stepParams={stepsParams}
      />,
      progress: 60,
    },
    getRecoveryCode: {
      content: <GetRecoveryCode
        key="GetRecoveryCode"
        goToStep={goToStep}
        stepParams={stepsParams}
      />,
      progress: 80,
    },
    finish: {
      content: <Finish key="Finish" stepParams={stepsParams} goToStep={goToStep} />,
      progress: 100,
    },
  };

  if (!tokenInPathname) navigate('/');

  return (
    <ScrollContainer
      marginTop={scrollPage ? 55 : 0}
      justifyContent={scrollPage ? 'flex-start' : 'center'}
    >
      <Style.ProgressContainer>
        <div
          className="bar"
          style={{ width: `${GuestRegisterSteps[step].progress || 1}%` }}
        />
      </Style.ProgressContainer>
      <ContentWrapper screenWidth={width} screenHeight={height}>
        <FormProvider {...methods}>{GuestRegisterSteps[step].content}</FormProvider>
      </ContentWrapper>
    </ScrollContainer>
  );
};

export default memo(GuestRegister);
