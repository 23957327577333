export const permissionAnalyst = [
  'bill.payment.read',
  'card.read',
  'slip.read',
  'statement.read',
  'limits.quotas.read',
];
export const permissionManager = [
  'bill.confirm.write',
  'bill.payment.write',
  'bill.payment.read',
  'card.write',
  'card.activate.write',
  'card.password.write',
  'card.status.write',
  'card.contactless.write',
  'card.read',
  'currencies.withdraw.write',
  'currencies.crytpoaddress.write',
  'pix.write',
  'pix.key.write',
  'pix.manual.write',
  'pix.schedule.write',
  'pix.brcode.write',
  'pix.delete',
  'ted.write',
  'slip.write',
  'slip.delete',
  'slip.read',
  'transaction.write',
  'transfers.write',
  'twofa.delete',
  'twofa.read',
  'statement.read',
  'limits.quotas.read',
  'pix.refund.write'
];
