import EditIcon from '~assets/images/icons/edit.svg';
import Share from '~assets/images/icons/kyc/gray-share.svg';
import Approved from '~assets/images/icons/kyc/partners/approved.svg';
import Denied from '~assets/images/icons/kyc/partners/denied.svg';
import Pending from '~assets/images/icons/kyc/partners/pending.svg';
import Submitted from '~assets/images/icons/kyc/partners/submitted.svg';
import ArrowRightIcon from '~assets/images/icons/select-arrow-right.svg';
import TrashIcon from '~assets/images/icons/trash.svg';
import useWindowDimensions from '~hooks/windowDimension';
import { colors } from '~styles';
import { breakpoints } from '~styles/metrics';
import * as Text from '~styles/text';
import { useTranslation } from 'react-i18next';
import { Case, Switch, When } from 'react-if';
import * as Style from './OptionSelect.styles';
import { OptionSelectProps } from './OptionSelect.types';

const OptionSelect: React.FC<OptionSelectProps> = (props) => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  return (
    <Style.Container
      {...props}
      enabledBorder={props.enabledBorder}
      onClick={props.onClick}
      marginTop={props.containerMarginTop}
      enableAnimation={props.enableAnimation}
      borderTop={props.borderTop}
      paddingVertical={props.paddingVertical}
    >
      <Style.IconContainer>
        <div className="right-container">
          <div>{props.icon}</div>
          <Style.ContainerStatus>
            <Switch>
              <Case condition={props.status === 'Preenchido'}>
                <Submitted />
              </Case>
              <Case condition={props.status === 'Preenchido e Aprovado'}>
                <Approved />
              </Case>
              <Case condition={props.status === 'Não aprovado'}>
                <Denied />
              </Case>
              <Case condition={props.status === 'Aguardando envio'}>
                <Pending />
              </Case>
              <Case condition={props.status === 'UNFINISHED'}>
                <Pending />
              </Case>
            </Switch>
          </Style.ContainerStatus>
        </div>
      </Style.IconContainer>

      <div className="right-container">
        <div className="column">
          <Text.Paragraph>{props.title}</Text.Paragraph>
          <When condition={!!props.subtitle || props.status === 'UNFINISHED'}>
            <Text.Smaller
              color={
                props.subtitleColor ||
                (props.status === 'UNFINISHED'
                  ? colors.danger
                  : colors.grayMedium)
              }
            >
              {props.status || props.subtitle || t('Campos incompletos')}
            </Text.Smaller>
          </When>
        </div>
      </div>

      <Style.TrashAndEditContainer>
        <When condition={!!props.shareAction}>
          <div className="on-select" onClick={props.shareAction}>
            <Share />
            <When condition={breakpoints.tablet < width}>
              <div className="tooltip-text">
                <Text.Small color={colors.white}>Mandar Link</Text.Small>
              </div>
            </When>
          </div>
        </When>
        <When condition={!!props.editAction}>
          <div className="on-select" onClick={props.editAction}>
            <EditIcon />
            <When condition={breakpoints.tablet < width}>
              <div className="tooltip-text">
                <Text.Small color={colors.white}>Editar Dados</Text.Small>
              </div>
            </When>
          </div>
        </When>
        <When condition={!!props.deleteAction}>
          <div className="on-select" onClick={props.deleteAction}>
            <TrashIcon />
            <When condition={breakpoints.tablet < width}>
              <div className="tooltip-text">
                <Text.Small color={colors.white}>Excluir Sócio</Text.Small>
              </div>
            </When>
          </div>
        </When>
      </Style.TrashAndEditContainer>

      <When condition={props.arrowIcon}>
        <ArrowRightIcon />
      </When>

      <When condition={!!props.rightIcon}>{props.rightIcon}</When>
    </Style.Container>
  );
};

export default OptionSelect;
