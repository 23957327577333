import { gql, request, useMutation } from '~reactQuery/index';

import { CONTACTS_QUERY_KEYS } from '~src/constants/contacts';

interface DeleteContactInput {
  userOrganizationId?: number;
  contactId: number;
  contactDataId?: number;
}

interface DeleteContactResponse {
  deleteContact: {
    data: string;
    messages: [string];
    isValid: boolean;
  };
}

export const useDeleteContact = () => {
  const QUERY = gql`
    mutation DeleteContact($params: DeleteContactInput!) {
      deleteContact(params: $params) {
        data
        messages
        isValid
      }
    }
  `;

  const fetcher = async (params: DeleteContactInput) => {
    const response = await request<DeleteContactResponse>(QUERY, {
      params,
    });
    return response.deleteContact;
  };

  return useMutation([CONTACTS_QUERY_KEYS.DELETE_CONTACT], fetcher);
};
