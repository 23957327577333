import styled, { css } from 'styled-components';
import colors from '~styles/colors';
import { breakpoints } from '~styles/metrics';
import { StyleProp } from './DashboardPage.types';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentWrapper = styled.div<StyleProp>`
  width: 100%;
  height: 100%;
  background-color: ${colors.white};

  .topbar-wrapper {
    width: 100%;
  }

  .page-wrapper {
    width: 100%;
    overflow-y: auto;

    ${({ width }) =>
      breakpoints.tablet < width &&
      css`
        height: calc(100% - 75px);
      `}

    overflow-x: hidden;

    .resize-container {
      width: 100%;
      height: calc(100% - 65px);

      transition: width 0.2s;

      ${({ isModalOpen, isHome, width }) =>
        isModalOpen &&
        !isHome &&
        breakpoints.tablet < width &&
        css`
          transition: width 1s ease;
          width: calc(100% - 400px);
        `}
    }
  }
`;
