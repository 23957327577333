import { CheckSelectSmall, InputForm } from '~components/Inputs';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import * as Text from '~styles/text';
import { getWalletIcon } from '~helpers/format/currency';
import { CriptoWithdrawStepsProps } from '../Transfer.types';
import colors from '~styles/colors';
import { Controller, useFormContext } from 'react-hook-form';
import WalletBlankSVG from '~assets/images/icons/wallets/WalletBlank';
import { WalletType } from '~reactQuery/types/wallets.types';

const CriptoTabContent = ({
  setCriptoWithdrawStep,
}: CriptoWithdrawStepsProps) => {
  const handleGoToSelectWalletStep = () =>
    setCriptoWithdrawStep('selectWalletToWithdraw');

  const { getValues, control, watch } = useFormContext();

  const selectedWallet = getValues('selectedWallet') as WalletType;

  const walletIsoCode = getWalletIcon(selectedWallet?.currency?.isoCode) || (
    <WalletBlankSVG />
  );
  const walletName = selectedWallet?.walletName || 'Selecionar';

  const disableNextButton =
    !watch('walletAddressToWithdraw') || !watch('selectedWallet');

  const handleGoToNextStep = () => {
    setCriptoWithdrawStep('originWallet');
  };

  return (
    <div className="cripto-withdraw-container-tab">
      <CheckSelectSmall
        icon={walletIsoCode}
        title={walletName}
        text={selectedWallet ? '' : 'Moeda'}
        selected={!!selectedWallet}
        onClick={handleGoToSelectWalletStep}
        iconType="component"
      />
      <Text.Paragraph color={colors.grayMedium} marginTop={20}>
        Endereço de destino
      </Text.Paragraph>

      <Controller
        control={control}
        name="walletAddressToWithdraw"
        render={({ field }) => <InputForm {...field} placeholder="0xa..." />}
      />

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        disableSingleButton={disableNextButton}
        singleButtonAction={handleGoToNextStep}
      />
    </div>
  );
};

export default CriptoTabContent;
