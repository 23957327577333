import { colors } from '~styles';
import styled, { css } from 'styled-components';
import { InputPhoneProps, InputPhoneStyleProps } from './InputPhone.types';

export const Container = styled.div<InputPhoneStyleProps & { error?: string }>`
  width: ${(props) => props.width || ''};
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin-bottom: ${(props) => props.marginBottom || 0}px;

  .label-container {
    width: auto;
    display: flex;
  }

  .error-container {
    width: auto;

    display: flex;
    justify-content: ${(props) =>
      props.errorInBottom ? 'flex-start' : 'flex-end'};
    align-items: flex-end;
    margin-bottom: ${(props) => (props.errorInBottom ? '7px' : '')};
  }

  .input-container {
    display: flex;
    flex-direction: row-reverse;
  }

  .helper-container {
    display: flex;
  }

  .helper-icon {
    width: auto;
  }
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  align-items: baseline;
`;

export const BottomContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 7px;
`;

export const Input = styled.input<Pick<InputPhoneProps, 'error'>>`
  width: 100%;
  height: 46px;
  background-color: ${(props) =>
    props.error ? '#ff637810' : colors.whiteLight};
  padding: 0 15px;
  border: 1px solid ${colors.grayRegular};
  border-width: 1px 1px 1px 0px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 0px; // needed for mobile version
  border-top-left-radius: 0px; // needed for mobile version
  transition: 0.2s ease-out;
  box-sizing: border-box;

  ::placeholder {
    color: ${colors.grayRegular};
  }

  /* DDI CONTAINER */
  & + div {
    min-width: auto;
    display: flex;
    align-items: center;
    margin-right: -1px; // we need

    background-color: ${(props) =>
      props.error ? '#ff637810' : colors.whiteLight};

    padding: 0 0 0 20px;
    border: 1px solid ${colors.grayRegular};
    border-width: 1px 0px 1px 1px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    color: ${colors.grayRegular};
    transition: 0.2s ease-out;
    user-select: none;

    ::after {
      content: '';
      width: 1px;
      height: 22px;
      margin-left: 10px;
      background-color: ${colors.grayLight};
    }
  }

  &:focus {
    border-color: ${colors.black} !important;
    border-width: 2px 2px 2px 0px !important;

    /* DDI CONTAINER */
    & + div {
      border-color: ${colors.black} !important;
      border-width: 2px 0px 2px 2px !important;
    }
  }

  ${(props) =>
    props.error
      ? css`
          border-color: ${colors.danger};

          & + div {
            border: solid 1px ${colors.danger} !important;
            border-width: 1px 0px 1px 1px !important;
          }

          &:focus {
            border-color: ${colors.danger} !important;
            border-width: 2px 2px 2px 0px;

            /* DDI CONTAINER */
            & + div {
              border-color: ${colors.danger} !important;
              border-width: 2px 0px 2px 2px !important;
            }
          }

          &:hover {
            background-color: ${colors.whiteLight};

            /* DDI CONTAINER */
            & + div {
              background-color: ${colors.whiteLight};
            }
          }
        `
      : css`
          &:hover {
            border-color: ${colors.grayMedium};
            border-width: 1px 1px 1px 0px;

            /* DDI CONTAINER */
            & + div {
              border-color: ${colors.grayMedium};
              border-width: 1px 0px 1px 1px;
            }
          }
        `}
`;
