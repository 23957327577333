import { screen } from '~styles/metrics';
import { colors } from '~styles';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div`
  .container-form-userguest {
    width: 100%;
    height: calc(100vh - 140px);
    overflow-y: auto;
    padding: 0 5px;

    ::-webkit-scrollbar {
      width: 0.5em;
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0);
    }
  }
  .main-icon {
    svg {
      border-radius: 15px;
    }
  }

  .input-container {
    margin-bottom: 20px;
  }

  .item-separation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .enable-two-fa {
    width: 100%;
    margin-top: 25px;
    padding: 0 38px;

    .justify-items-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .container-codes {
      min-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      background-color: ${colors.white};
      border-radius: 50px;
      margin-top: 35px;
      margin-bottom: 30px;
      padding: 13px 20px;
    }
  }

  .recovery-codes {
    width: 100%;
    margin-top: 25px;

    @media print {
      .hidden-print {
        display: none !important;
      }
    }

    .actions-buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 15px;
      margin-top: 25px;
      padding-left: 10px;
      padding-right: 10px;
    }

    ul {
      list-style-position: outside;

      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      gap: 50px;

      -webkit-column-rule: 1px single grey;
      -moz-column-rule: 1px single grey;
      column-rule: 1px single grey;

      li {
        margin-bottom: 5px;
      }
    }

    .justify-items-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .container-codes {
      min-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      background-color: ${colors.whiteLight};
      border-radius: 50px;
      margin-top: 35px;
      margin-bottom: 30px;
      padding: 13px 20px;
    }
  }

  .container-validations2 {
    display: grid;

    grid-template-columns: 180px 180px 160px;
    gap: 10px;
    margin-bottom: 15px;

    @media (max-width: 780px) {
      grid-template-columns: 180px 180px 160px 100px;
    }

    @media (max-width: 640px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }
`;

export const StatusContainer = styled.div`
  .warning-message {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
    padding: 10px;
    background-color: ${transparentize(0.9, colors.danger)};
    border-radius: 10px;
    margin-top: 10px;

    p {
      width: 94%;
    }
  }
`;

export const ProgressContainer = styled.div`
  width: 100%;
  height: 3px;
  background-color: ${transparentize(0.7, colors.grayLight)};
  position: absolute;
  bottom: 74px;
  right: 0;

  &.kyc-modal {
    width: 65%;
    left: 0;

    @media ${screen.tablet} {
      width: 100%;
    }
  }

  .bar {
    transition: width 0.3s ease-out;
    // width está sendo setado na prop styles da propria div,
    // por motivos de screen flickering, ao passar props real-time dynamic ao styled-components;
    max-width: 100%;
    height: 3px;
    background-color: ${colors.black};
  }
`;
