import { CheckSelect } from '~components/index';
import { useEffect, useState } from 'react';
import { DepositOptionsPageProps } from '../Deposit.types';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import BlueBarcodeIcon from '~assets/svg/blueBarcode';
import BlueBankIcon from '~assets/svg/blueBank';
import BlueCoinIcon from '~assets/svg/blueCoin';
import { usePermission } from '~helpers/permissionGate';

function DepositOptions(props: DepositOptionsPageProps) {
  const { setCurrentStep, params } = props;
  const [optionSelect, setOptionSelect] = useState<any>('');
  const { granted: criptoTransactionsGranted } =
    usePermission('criptoTransactions');
  const { granted: payBillsGranted } = usePermission('payBillsButton');

  const { granted: buttonsPixGranted } = usePermission('buttonsPix');

  const translatorStep: any = {
    'Utilize seus dados bancários': 'bankDeposit',
    'Realize o depósito em criptomoedas': 'criptoOptions',
    'Deposite com boleto bancário': 'billetDepositValue',
  };

  function goNext() {
    if (!optionSelect) {
      return;
    }

    setCurrentStep(
      translatorStep[optionSelect],
      optionSelect === 'criptoOptions'
        ? {
            walletSelected: optionSelect,
          }
        : {}
    );
  }

  function selectBehavior(keyType: any) {
    if (optionSelect === keyType) {
      return setOptionSelect('');
    }

    return setOptionSelect(keyType);
  }

  useEffect(() => {
    if (props.setStepParams) {
      props.setStepParams({});
    }
  }, []);

  const optionsList = [
    {
      keyType: 'Utilize seus dados bancários',
      keyValue:
        'Faça transferências utilizando seus dados bancários para transferências via Pix, TED ou DOC.',
      enabled: !!buttonsPixGranted,
      chipLabel: '',
      icon: <BlueBankIcon />,
    },
    {
      keyType: 'Deposite com boleto bancário',
      keyValue:
        'Receba em até 3 dias úteis ao gerar um boleto bancário para pagamento em agências ou casas lotéricas.',
      enabled: !!payBillsGranted,
      chipLabel: '',
      icon: <BlueBarcodeIcon width={24} height={24} />,
    },
    {
      keyType: 'Realize o depósito em criptomoedas',
      keyValue:
        'Utilize a blockchain para realizar depósitos com suas criptomoedas.',
      enabled: !!criptoTransactionsGranted,
      chipLabel: '',
      icon: <BlueCoinIcon style={{ marginLeft: -4 }} />,
    },
  ];

  return (
    <>
      <ModalMultiStepHeader title="Como deseja depositar na sua conta?" />

      <div className="content-container">
        {optionsList?.map((item) => {
          return (
            <CheckSelect
              iconType="component"
              disabled={!item.enabled}
              key={item.keyType}
              title={item.keyType}
              text={item.keyValue}
              iconComponent={item.icon}
              chipText={item.chipLabel}
              onClick={() => selectBehavior(item.keyType)}
              selected={optionSelect === item.keyType}
            />
          );
        })}
      </div>

      <ModalMultiStepBottom
        singleButtonText="Próximo"
        singleButtonWidth="100%"
        singleButtonAlignment="flex-end"
        singleButtonAction={goNext}
        disableSingleButton={optionSelect.length <= 0}
      />
    </>
  );
}

export default DepositOptions;
