import * as Text from '~styles/text';
import { GenericRow, RoundButtonIcon } from '~components';
import colors from '~styles/colors';
import CopySVG from '~assets/svg/copy';
import { formatCurrencyFn } from '~helpers/format/currency';
import dayjs from 'dayjs';
import BillIcon from '~assets/svg/statement/billIcon';
import { formatDataShowMonth } from '~validations/stringsValidate';
import { copyText } from '~helpers/messages';
import { TransactionDetailsTypes } from '../../Activities.types';

const PaymentBill = ({
  transactionDetails,
}: {
  transactionDetails: TransactionDetailsTypes;
}) => {
  function applyMask(input: string): string {
    const firstSegment = input?.slice(0, 11);
    const secondSegment = input?.slice(11, 22);
    const thirdSegment = input?.slice(22, 37);
    const fourthSegment = input?.slice(37);

    return `${firstSegment}-4 ${secondSegment}-3 ${thirdSegment?.slice(
      0,
      5
    )} ${thirdSegment?.slice(5)}-${fourthSegment}`;
  }

  return (
    <>
      <div className="detailsRow">
        <Text.Paragraph>Valor</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph color={colors.grayMedium}>
            {typeof transactionDetails?.data?.value === 'string' &&
              formatCurrencyFn(
                'BRL',
                String(Number(transactionDetails.data.value) / 100)
              )}
          </Text.Paragraph>
        </div>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Data e Horário</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>
          {formatDataShowMonth(transactionDetails?.timestamp)} •{' '}
          {dayjs(transactionDetails?.timestamp).format('H:mm')}
        </Text.Paragraph>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Descrição</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph color={colors.grayMedium}>
            {transactionDetails?.data?.description || '----'}
          </Text.Paragraph>
        </div>
      </div>

      <div className="detailsDivider">
        <div className="icon">
          <BillIcon width={18} height={16} />
        </div>
        <Text.Paragraph>Documento</Text.Paragraph>
        <hr />
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Favorecido</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph color={colors.grayMedium}>
            {transactionDetails?.data?.recipientName}
          </Text.Paragraph>
        </div>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Emissor</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph color={colors.grayMedium}>
            {transactionDetails?.data?.assignor}
          </Text.Paragraph>
        </div>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Vencimento</Text.Paragraph>
        <Text.Paragraph color={colors.grayMedium}>
          {formatDataShowMonth(transactionDetails?.data?.settleDate)}
        </Text.Paragraph>
      </div>

      <div className="detailsRow">
        <Text.Paragraph>Linha digitável</Text.Paragraph>
        <div className="item-right">
          <Text.Paragraph
            color={colors.grayMedium}
            style={{ maxWidth: 120, wordBreak: 'break-all' }}
          >
            {applyMask(transactionDetails?.data?.digitableLine)}
          </Text.Paragraph>
        </div>
      </div>

      <GenericRow
        title="Protocolo"
        description={transactionDetails?.authCode}
        noHorizontalPadding
        leftContentIconType="none"
        rightComponent={
          <RoundButtonIcon
            buttonSize="icon16"
            onClick={() =>
              copyText(
                transactionDetails?.authCode,
                'Código de Protocolo copiado com sucesso!'
              )
            }
          >
            <CopySVG />
          </RoundButtonIcon>
        }
        rightContentType="component"
      />
    </>
  );
};

export default PaymentBill;
