import * as Text from '~styles/text';

import { Else, If, Then } from 'react-if';
import { coinList, cryptoNetworks } from '~data/wallets';

import BlueGlobeSVG from '~assets/svg/blueGlobe';
import CopyIcon from '~assets/images/icons/pix/copypaste.svg';
import { DepositOptionsPageProps } from '../Deposit.types';
import GenericRow from '~components/GenericRow';
import InfoSVG from '~assets/svg/info';
import LoadingSpinner from '~components/LoadingSpinner';
import ModalMultiStepBottom from '~components/ModalMultiStep/ModalMultiStepBottom.component';
import ModalMultiStepHeader from '~components/ModalMultiStep/ModalMultiStepHeader.component';
import QRCode from 'react-qr-code';
import { RoundButtonIcon } from '~components/index';
import colors from '~styles/colors';
import { getSelectedUserOrganization } from '~helpers/device';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import { useCurrencyCryptoAddress } from '~reactQuery/queries/deposit/useCurrencyCryptoAddress';

function CriptoDepositQR(props: DepositOptionsPageProps) {
  const { setCurrentStep } = props;
  const userOrganizationId = getSelectedUserOrganization();
  const { setOpenModalMultiStep } = useActionsOnModalMultiStep();

  const goBack = () => setCurrentStep('depositOptions');
  const goCoinList = () => setCurrentStep('criptoDepositCurrency');

  const selectedWallet = props.stepParams?.walletSelected;
  const manualCurrencySelected = props.stepParams?.currencySelected
    ? String(props.stepParams?.currencySelected).toUpperCase()
    : undefined;

  const { data: WalletAddressDeposit } = useCurrencyCryptoAddress({
    crypto: manualCurrencySelected || selectedWallet?.currency?.isoCode,
    forceNew: false,
    takenBy: selectedWallet?.walletAddress,
    takenByType: 'wallet',
    userOrganizationId: Number(userOrganizationId),
  });

  const copyAddress = () => {
    navigator.clipboard.writeText(`${WalletAddressDeposit?.walletAddress}`);
  };

  const currentNetwork = cryptoNetworks.filter(
    (n) =>
      n.name === (manualCurrencySelected || selectedWallet?.currency?.isoCode)
  )?.[0];

  const getCoinName = coinList.filter(
    (c) =>
      c.name.toLocaleUpperCase() ===
      (manualCurrencySelected ||
        selectedWallet?.currency?.isoCode?.toLocaleUpperCase())
  )?.[0];

  // security
  if (!selectedWallet?.walletAddress) return null;

  return (
    <>
      <ModalMultiStepHeader
        title="Depósito de criptomoeda"
        description={`O valor enviado será creditado em sua CapWallet ${selectedWallet?.walletName}.`}
        backButtonAction={goBack}
      />
      <div className="content-container">
        <div className="container-qr-and-options">
          <div className="btt-currency">
            <RoundButtonIcon buttonSize="icon24v2" onClick={goCoinList}>
              <div className="scale-icon">{getCoinName?.icon()}</div>
            </RoundButtonIcon>
            <Text.Heading5 textAlign="center" marginTop={10}>
              {getCoinName?.title}
            </Text.Heading5>
            <Text.Smaller
              textAlign="center"
              marginTop={2}
              color={colors.grayMedium}
            >
              Moeda
            </Text.Smaller>
          </div>
          <div className="qr-container">
            <If
              condition={
                WalletAddressDeposit?.walletAddress &&
                WalletAddressDeposit?.walletAddress !== 'undefined'
              }
            >
              <Then>
                <QRCode
                  size={175}
                  style={{ height: 'auto', maxWidth: '175px', width: '100%' }}
                  viewBox="0 0 256 256"
                  value={String(WalletAddressDeposit?.walletAddress)}
                />
              </Then>

              <Else>
                <div className="loading-container">
                  <LoadingSpinner
                    color={colors.primary}
                    size={30}
                    strokeSize={12.5}
                  />
                </div>
              </Else>
            </If>
          </div>
          <div className="btt-currency">
            <RoundButtonIcon buttonSize="icon24v2" onClick={() => {}} disabled>
              <BlueGlobeSVG stroke={colors.black} width={24} height={24} />
            </RoundButtonIcon>

            <Text.Heading5 textAlign="center" marginTop={10}>
              {currentNetwork?.network}
            </Text.Heading5>
            <Text.Smaller marginTop={2} color={colors.grayMedium}>
              Rede
            </Text.Smaller>
          </div>
        </div>
        <GenericRow
          rightComponent={<CopyIcon />}
          title="Endereço"
          rightContentType="component"
          padding="5px 0px 0px 0px"
          description={WalletAddressDeposit?.walletAddress}
          onClick={copyAddress}
        />

        <div className="warning-message">
          <InfoSVG />

          <Text.Small color={colors.grayMedium}>
            {`Enviar apenas ${
              getCoinName?.title
            } (${getCoinName?.name?.toUpperCase()}) para este endereço.`}
          </Text.Small>
        </div>
      </div>
      <ModalMultiStepBottom
        doubleButtonTextOne={
          <div className="double-button-one-container">
            <CopyIcon />
            <Text.Heading5>Copiar endereço</Text.Heading5>
          </div>
        }
        doubleButtonOneAction={copyAddress}
        singleButtonText="Concluir"
        singleButtonWidth="1"
        singleButtonAction={() => setOpenModalMultiStep(false)}
      />
    </>
  );
}

export default CriptoDepositQR;
