import * as React from 'react';

import { SVGProps } from 'react';

const EthCoinSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={36} fill="none" {...props}>
    <rect x={1} y={1} width={42} height={34} rx={10} fill="#fff" />
    <path
      d="m22.307 20.982 5.752-3.41L22.307 8l-5.754 9.573 5.754 3.41Z"
      fill="#808696"
    />
    <path
      d="m22.306 27.313 5.756-8.13-5.756 3.407-5.753-3.411 5.753 8.134Z"
      fill="#808696"
    />
    <rect
      x={1}
      y={1}
      width={42}
      height={34}
      rx={10}
      stroke="#F3F4F9"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EthCoinSVG;
