import { SVGProps } from 'react';

const MovementsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={props.width || '18'} height={props.height || '18'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.79964 0.593811L5.50674 1.30092L9.50674 5.30092L8.09253 6.71513L5.79964 4.42224V15.008H3.79964L3.79964 4.42224L1.50674 6.71513L0.0925293 5.30092L4.09253 1.30092L4.79964 0.593811ZM13.7996 13.5938L16.0925 11.3009L17.5067 12.7151L13.5067 16.7151L12.7996 17.4222L12.0925 16.7151L8.09253 12.7151L9.50674 11.3009L11.7996 13.5938L11.7996 3.00802H13.7996V13.5938Z"
        fill={props.fill || '#757893'}
      />
    </svg>
  );
};

export default MovementsIcon;
