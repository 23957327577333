import { EndStepStatusProps } from './EndStepStatus.types';
import Lottie from 'react-lottie';
import awaiting from '~assets/lotties/awaiting.json';
import error from '~assets/lotties/error.json';
import success from '~assets/lotties/success.json';

const EndStepStatus = (props: EndStepStatusProps) => {
  const lottieFiles = [
    { file: awaiting, name: 'awaiting' },
    { file: error, name: 'error' },
    { file: success, name: 'success' },
  ];

  const selectLottie = lottieFiles.filter(
    (lottie) => lottie.name === props.animType
  )?.[0];

  const defaultOptions = {
    loop: selectLottie.name === 'awaiting',
    autoplay: true,
    animationData: selectLottie?.file || awaiting,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={props.height || 130}
      width={props.width || 130}
    />
  );
};

export default EndStepStatus;
