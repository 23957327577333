import { useEffect, useState } from 'react';
import * as Style from './Convert.styles';
import { ConvertSteps, StepParamsProps } from './Convert.types';
import ModalMultiStep from '~components/ModalMultiStep';
import { useActionsOnModalMultiStep } from '~hooks/useModalMultiStep';
import ChooseWallets from './ModalSteps/1.ChooseWallets';
import OriginWallet from './ModalSteps/2.OriginWallet';
import DestinationWallet from './ModalSteps/3.DestinationWallet';
import Details from './ModalSteps/4.Details';
import Pin from './ModalSteps/5.Pin';
import Status from './ModalSteps/6.Status';
import { WalletType } from '~reactQuery/types/wallets.types';

function Convert() {
  const { openModalMultiStep, setOpenModalMultiStep, params } =
    useActionsOnModalMultiStep();

  const modalParams = params as {
    isComingFrom?: string;
    comingFrom?: string;
    destinationWallet?: WalletType;
    originWallet?: WalletType;
  };

  const [currentStep, setCurrentStep] = useState<ConvertSteps>('chooseWallets');
  const [stepParams, setStepParams] = useState<StepParamsProps>({});

  const setStepsAndParams = (step: ConvertSteps, parameters?: object) => {
    if (params) {
      setStepParams((prev) => ({ ...prev, ...parameters } as StepParamsProps));
    }
    setCurrentStep(step);
  };

  const userWantsToBuyForSpecificWallet =
    modalParams?.comingFrom === 'capWallet' && modalParams.destinationWallet;

  const userWantsToSendFromSpecificWallet =
    modalParams?.comingFrom === 'capWallet' && modalParams.originWallet;

  const userWantsToConvertFromCapConta =
    modalParams?.isComingFrom === 'capConta' && modalParams.originWallet;

  const renderFirstStepTitle = () => {
    if (userWantsToSendFromSpecificWallet) return 'Enviar';
    if (userWantsToBuyForSpecificWallet) {
      return modalParams?.destinationWallet
        ? `Comprar ${modalParams?.destinationWallet?.walletName}`
        : 'Comprar';
    }

    return 'Converter';
  };

  useEffect(() => {
    // Set Destination Wallet if user comes from a specific wallet (when want to buy)
    if (userWantsToBuyForSpecificWallet) {
      setStepParams((prev) => ({
        ...prev,
        destinationWallet: modalParams.destinationWallet,
      }));
    }

    // Set Origin Wallet if user comes from a specific wallet (when want to send)
    if (userWantsToSendFromSpecificWallet) {
      setStepParams((prev) => ({
        ...prev,
        originWallet: modalParams.originWallet,
      }));
    }

    if (userWantsToConvertFromCapConta) {
      setStepParams((prev) => ({
        ...prev,
        originWallet: modalParams.originWallet,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalMultiStep]);

  const modalStepsAndDetails = {
    chooseWallets: {
      component: (
        <ChooseWallets
          title={renderFirstStepTitle()}
          key="chooseWallets"
          setCurrentStep={setStepsAndParams}
          destinationWallet={stepParams.destinationWallet}
          originWallet={stepParams.originWallet}
          conversionDetails={stepParams.conversionDetails}
          comingFrom={params?.comingFrom}
        />
      ),
      progressValue: 1,
    },
    originWallet: {
      component: (
        <OriginWallet
          key="originWallet"
          setCurrentStep={setStepsAndParams}
          originWallet={stepParams.originWallet}
          destinationWallet={stepParams.destinationWallet}
          comingFrom={params?.comingFrom}
        />
      ),
      progressValue: 1,
    },
    destinationWallet: {
      component: (
        <DestinationWallet
          key="destinationWallet"
          setCurrentStep={setStepsAndParams}
          originWallet={stepParams.originWallet}
          destinationWallet={stepParams.destinationWallet}
        />
      ),
      progressValue: 1,
    },
    details: {
      component: (
        <Details
          key="details"
          setCurrentStep={setStepsAndParams}
          conversionDetails={stepParams.conversionDetails!}
        />
      ),
      progressValue: 33,
    },
    pin: {
      component: (
        <Pin
          key="pin"
          setCurrentStep={setStepsAndParams}
          conversionDetails={stepParams.conversionDetails!}
          comingFrom={params?.comingFrom}
        />
      ),
      progressValue: 66,
    },
    status: {
      component: (
        <Status
          key="status"
          setCurrentStep={setStepsAndParams}
          conversionStatus={stepParams.conversionStatus!}
          conversionDetails={stepParams.conversionDetails!}
        />
      ),
      progressValue: 100,
    },
  };

  const currentStepInfos =
    modalStepsAndDetails[currentStep as keyof typeof modalStepsAndDetails];

  // CLEAR STEP ON MODAL CLOSE
  useEffect(() => {
    if (!openModalMultiStep) {
      setCurrentStep('chooseWallets');
      setStepParams({});
    }
  }, [openModalMultiStep]);

  return (
    <Style.ConvertContainer>
      <ModalMultiStep
        openModal={openModalMultiStep}
        setOpenModal={setOpenModalMultiStep}
        barProgressPercent={currentStepInfos.progressValue}
      >
        {currentStepInfos.component}
      </ModalMultiStep>
    </Style.ConvertContainer>
  );
}

export default Convert;
