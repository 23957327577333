import dayjs from 'dayjs';

const year = dayjs().year();

export const mothsToFilter = [
  {
    label: 'Janeiro',
    period: {
      from: `${year}-01-01`,
      to: dayjs(`${year}-01-01`).endOf('month').format('YYYY-MM-DD'),
    },
  },
  {
    label: 'Fevereiro',
    period: {
      from: `${year}-02-01`,
      to: dayjs(`${year}-02-01`).endOf('month').format('YYYY-MM-DD'),
    },
  },
  {
    label: 'Março',
    period: {
      from: `${year}-03-01`,
      to: dayjs(`${year}-03-01`).endOf('month').format('YYYY-MM-DD'),
    },
  },
  {
    label: 'Abril',
    period: {
      from: `${year}-04-01`,
      to: dayjs(`${year}-04-01`).endOf('month').format('YYYY-MM-DD'),
    },
  },
  {
    label: 'Maio',
    period: {
      from: `${year}-05-01`,
      to: dayjs(`${year}-05-01`).endOf('month').format('YYYY-MM-DD'),
    },
  },
  {
    label: 'Junho',
    period: {
      from: `${year}-06-01`,
      to: dayjs(`${year}-06-01`).endOf('month').format('YYYY-MM-DD'),
    },
  },
  {
    label: 'Julho',
    period: {
      from: `${year}-07-01`,
      to: dayjs(`${year}-07-01`).endOf('month').format('YYYY-MM-DD'),
    },
  },
  {
    label: 'Agosto',
    period: {
      from: `${year}-08-01`,
      to: dayjs(`${year}-08-01`).endOf('month').format('YYYY-MM-DD'),
    },
  },
  {
    label: 'Setembro',
    period: {
      from: `${year}-09-01`,
      to: dayjs(`${year}-09-01`).endOf('month').format('YYYY-MM-DD'),
    },
  },
  {
    label: 'Outubro',
    period: {
      from: `${year}-10-01`,
      to: dayjs(`${year}-10-01`).endOf('month').format('YYYY-MM-DD'),
    },
  },
  {
    label: 'Novembro',
    period: {
      from: `${year}-11-01`,
      to: dayjs(`${year}-11-01`).endOf('month').format('YYYY-MM-DD'),
    },
  },
  {
    label: 'Dezembro',
    period: {
      from: `${year}-12-01`,
      to: dayjs(`${year}-12-01`).endOf('month').format('YYYY-MM-DD'),
    },
  },
];
