import ChangePassWordModalSteps from '~src/common/modals/multiStep/ChangePassword/ChangePassword.screen';
import ChangePinModalSteps from '~src/common/modals/multiStep/ChangePin/ChangePin.screen';
import { Convert } from '~src/common/modals/multiStep/Convert';
import { CreatePixKeys } from '~src/common/modals/multiStep/CreatePixKeys';
import { DeletePixKey } from '~src/common/modals/multiStep/DeletePixKey';
import Deposit from '~src/common/modals/multiStep/Deposit/Deposit.component';
import DisableTwoFaModals from '~src/common/modals/multiStep/DisableTwoFa/DisableTwoFa.screen';
import EnableTwoFaModals from '~src/common/modals/multiStep/EnableTwoFa/EnableTwoFa.screen';

import PayBills from '~src/common/modals/multiStep/PayBillsModal/PayBills.component';
import PayWithPix from '~src/common/modals/multiStep/PayWithPixModal/PayWithPix.component';

import RecoveryCodesModals from '~src/common/modals/multiStep/RecoveryCodes/RecoveryCodes.screen';
import RecoveryPin from '~src/common/modals/multiStep/RecoveryPin/RecoveryPin.component';
import SendNewPinModalSteps from '~src/common/modals/multiStep/SendNewPin/SendNewPin.screen';
import Send from '~src/common/modals/multiStep/Send/Send.component';
import { Transfer } from '~src/common/modals/multiStep/Transfer';
import { UserPermission } from '~src/common/modals/multiStep/UserPermission';
import NewLimit from '~src/common/modals/multiStep/NewLimit/NewLimit.component';
import { TransferBatch } from '~src/common/modals/multiStep/TransferBatch/TransferBatch.index';
import { Refund } from './Refund';

export const ModalMultiStepSections = {
  createPix: <CreatePixKeys key="createPixSteps" />,
  deletePixKey: <DeletePixKey key="deletePixKeySteps" />,
  payBills: <PayBills key="payBillsSteps" />,
  payWithPix: <PayWithPix key="payWithPixSteps" />,
  send: <Send key="sendOptions" />,
  convert: <Convert key="convert" />,
  recoveryPin: <RecoveryPin key="recoveryPinSteps" />,
  transfer: <Transfer key="transferPixOrTed" />,
  deposit: <Deposit key="deposit" />,
  changePinModals: <ChangePinModalSteps key="changePinModalSteps" />,
  enableTwoFaModals: <EnableTwoFaModals key="enableTwoFaModals" />,
  disableTwoFaModals: <DisableTwoFaModals key="disableTwoFaModals" />,
  sendNewPin: <SendNewPinModalSteps key="sendNewPin" />,
  recoveryCodesModals: <RecoveryCodesModals key="recoveryCodesModals" />,
  createUserWithPermission: <UserPermission key="createUserWithPermission" />,
  newLimit: <NewLimit key="newLimit" />,
  transferBatch: <TransferBatch key="transferBatch" />,
  changePasswordModals: (
    <ChangePassWordModalSteps key="changePassWordModalSteps" />
  ),
  refund: <Refund key="refund" />,

  default: null,
};
